import { store } from '../store';
import ProcessAPIResponse from "../modules/processAPIResponse";
import {
    getAccessToken,
    getRefreshToken
} from "../services/TokenService";
import {
    ACCESS_TKN_REFRESH_SUCCESS,
} from "../constants/authTypes";
import { replace } from 'connected-react-router';
import {
    RESET,
} from '../constants/appTypes';
import Notification from '../modules/notifications';
import { apiUrl } from "./util";


const {
    RefreshAccessTokenRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_pb.js");

const {
    BasicAuthAPIClient
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_grpc_web_pb.js");

const auth_client = new BasicAuthAPIClient(
    apiUrl(),
    null,
    null
);

const apiCall = (client, name, request, cb, subscriptionHooks = null) => {
    const setSubscriptionHooks = (call) => {
        if (!subscriptionHooks) {
            return;
        }
        call.on("data", subscriptionHooks.data);
        call.on("status", subscriptionHooks.status);
        call.on("end", subscriptionHooks.end);
        call.on("error", subscriptionHooks.error);
    };

    const performCall = () => {
        const call = client[name](request, { authorization: getAccessToken() }, (err, response) => {
            refreshAndRetryHandler(err, response, cb, client, name, request);
        });

        if (!!subscriptionHooks) {
            setSubscriptionHooks(call);
        } else {
            !!call && !!call.on && call.on("status", (resp) => {
                // DO NOTHING
            });
        }

        return call;
    }

    return performCall();
}

const refreshAccessTokenCallback = (res, cb, client, name, request) => {
    if (res) {
        var accessToken = res.getAccessToken();
        store.dispatch({
            type: ACCESS_TKN_REFRESH_SUCCESS,
            payload: { accessToken }
        });
        client[name](request, { authorization: getAccessToken() }, (err, response) =>
            refreshAndRetryHandler(err, response, cb, client, name, request)
        );
    }
};

function refreshAndRetryHandler(err, response, cb, client, name, request) {
    if (err && err.code === 16) {
        const refreshReq = new RefreshAccessTokenRequest();
        refreshReq.setRefreshToken(getRefreshToken());
        auth_client.refreshAccessToken(refreshReq, {}, (err, response) => {
            processRefreshAccessTokenResponse(err, response, cb, client, name, request)
        });
    } else {
        ProcessAPIResponse(err, response, cb);
    }
}

const processRefreshAccessTokenResponse = (err, res, cb, client, name, request) => {
    if (err) {
        store.dispatch({ type: RESET });
        store.dispatch(replace('/'));
        Notification.error('Your credentials have expired. You have been logged out.');
        return;
    }
    refreshAccessTokenCallback(res, cb, client, name, request);
}

export default apiCall;
