import React from "react";
import PropTypes from "prop-types";
import { FormGroup, FormLabel } from "react-bootstrap";
import "./MultiItem.css"
import Tooltip from '../tooltip-html/tooltip-html';

const MultiItem = ({
  id,
  name,
  data,
  label,
  isRequired,
  errors,
  onAddNew,
  onRemove,
  readOnly = false,
  plaintext = false,
  toolTip
}) => {

  let listData = [];

  if (!!data) {
    listData = [...data];
  }

  return (
    <FormGroup id={id}>
      {label && <FormLabel>{label}</FormLabel>}
      {(label && toolTip) && <Tooltip
          text={toolTip}
          id="multiItemToolTip"
        ></Tooltip>
      }
      {isRequired && <label className="form-label-required"> Required</label>}

      {plaintext && <div className="multiselect">{listData ? listData.join(" > ") : ""}</div>}

      {!plaintext && <>
        <div className="mi-form-control">

          <div style={{ display: "flex" }}>
            <ul className="mi-item-list">

              {listData && listData.map((row, idx) => {
                return (<>
                  {idx > 0 && <i className="fa fa-fw fa-angle-right mi-item-arr" ></i>}
                  <li role="option" aria-selected className="mi-item"><span>{row}</span>
                    {!readOnly && <div><button tabIndex="-1" type="button" className="mi-item-btn-del" onClick={() => { onRemove(row) }}><span aria-hidden="true" className="mi-item-btn-del-icon">×</span></button></div>}
                  </li>
                </>)
              })}

            </ul>
          </div>

          {!readOnly && <div style={{ width: "23px", alignSelf: "center" }}>
            <button tabIndex="-1" type="button" onClick={onAddNew} className="mi-item-add"><i className="fa fa-fw fa-plus orange-icon"></i></button>
          </div>}

        </div>

        {errors && errors[name] && (
          <div className="form-error"><i className="fa fa-exclamation-triangle orange-icon" aria-hidden="true"></i>{" "}
            {errors[name]}
          </div>
        )}

      </>}
    </FormGroup>
  )
};

MultiItem.defaultProps = {
  readOnly: false,
  plaintext: false,
};

MultiItem.propTypes = {
  isRequired: PropTypes.bool,
};

export default MultiItem;
