import { convertEnumToDropdownList } from "../modules/util";
import {
  TradeState
} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/trades/v1beta1/trades_pb";

export default class StaticDataUtils {
  static get TradeStateOptions() {
    return convertEnumToDropdownList(TradeState);
  }

  static GetTradeStateOptions(excludeIds) {
    return convertEnumToDropdownList(TradeState, excludeIds);
  }
}
