import React from 'react';
import PropTypes from "prop-types";
import Filter from "../core/filter/Filter";
import FieldGroup from "../core/form/FieldGroup";
import DropdownListGroup from "../core/form/DropdownListGroup";
import { convertEnumToDropdownList, getEnumName, isNullOrUndefined } from "../../modules/util";
import { Form, Col } from 'react-bootstrap';
import { Side, OrderStateFilter, ExecutionType } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb';
import _ from 'lodash'

const OrderHistoryAdditionalFiltersForm = ({
    values,
    setFieldValue,
    setAction,
    parent,
}) => {

  const sideEnum = convertEnumToDropdownList(Side, [Side.SIDE_UNDEFINED]);
  const orderStateFilterEnum = convertEnumToDropdownList(OrderStateFilter, [OrderStateFilter.ORDER_STATE_FILTER_UNDEFINED]);
  const executionTypeEnum = convertEnumToDropdownList(ExecutionType);

  const onApply = () => {
    if (!!values) {
      Object.keys(values).forEach((key) => {
        setFieldValue(key, values[key])
      });
    }
  }

  const onRemoveFilterItem = (filterItem) => {
    if (typeof filterItem === 'object' && filterItem.hasOwnProperty("id")) {
      setFieldValue(filterItem.id, null);
    }
    else {
      setFieldValue(filterItem, null);
    }
  };

  const handleOnChange = (key, value) => {
    setFieldValue(key, value)
  };

  const getFilterObjects = (currentValues) => {
    return !!currentValues ? {
      participantDisplayName: !!currentValues.participantDisplayName ? {id: "participantDisplayName", value: currentValues.participantDisplayName, text: "User", name: currentValues.participantDisplayName } : null,
      clordid: !!currentValues.clordid ? {id: "clordid", value: currentValues.clordid, text: "Client Order ID", name: currentValues.clordid} : null,
      clientaccountid: !!currentValues.clientaccountid ? {id: "clientaccountid", value: currentValues.clientaccountid, text: "Client Account ID", name: currentValues.clientaccountid} : null,
      clientparticipantid: !!currentValues.clientparticipantid ? {id: "clientparticipantid", value: currentValues.clientparticipantid, text: "Client User ID", name: currentValues.clientparticipantid} : null,
      crossid: !!currentValues.crossid ? {id: "crossid", value: currentValues.crossid, text: "Client Cross ID", name: currentValues.crossid} : null,
      accountDisplayNames: !!currentValues.accountDisplayNames && !_.isEmpty(currentValues.accountDisplayNames) ? {id: "accountDisplayNames", value: currentValues.accountDisplayNames, text: "Accounts", name: Array.isArray(currentValues.accountDisplayNames) ? currentValues.accountDisplayNames.join(", "): currentValues.accountDisplayNames} : null,
      accountDisplayName: !!currentValues.accountDisplayName ? {id: "accountDisplayName", value: currentValues.accountDisplayName, text: "Accounts", name: currentValues.accountDisplayName} : null,
      side: !!currentValues.side ? {id: "side", value: getEnumName(Side, currentValues.side), text: "Side", name: getEnumName(Side, currentValues.side), state: currentValues.side} : null,
      orderStateFilter: !!currentValues.orderStateFilter ? {id: "orderStateFilter", value: getEnumName(OrderStateFilter, currentValues.orderStateFilter), text: "Order Status", name: getEnumName(OrderStateFilter, currentValues.orderStateFilter), state: currentValues.orderStateFilter} : null,
      parentorderid: !!currentValues.parentorderid ? {id: "parentorderid", value: currentValues.parentorderid, text: "Parent Order ID", name: currentValues.parentorderid} : null,
      symbolsubtype: !!currentValues.symbolsubtype ? {id: "symbolsubtype", value: currentValues.symbolsubtype, text: "Symbol Sub Type", name: currentValues.symbolsubtype} : null,
      hostcrossid: !!currentValues.hostcrossid ? {id: "hostcrossid", value: currentValues.hostcrossid, text: "Exchange Cross ID", name: currentValues.hostcrossid} : null,
      executionType: !isNullOrUndefined(currentValues.executionType) ? {id: "executionType", value: getEnumName(ExecutionType, currentValues.executionType), text: "Execution Type", name: getEnumName(ExecutionType, currentValues.executionType), state: currentValues.executionType} : null,
    } : { }
  }

  return (
    <Filter
        className="order-history-filter"
        onApply={onApply}
        applyButtonText={"Save"}
        onRemoveFilterItem={onRemoveFilterItem}
        filterItems={getFilterObjects(values)}
        onFlip={() => { setAction() }}
        isAdditional={true}
    >
      <Form.Row >
      {parent !== "ExecutionsSearchPage" && <Col lg={3} xs={3} md={3}>
          <DropdownListGroup
              id="orderStateFilter"
              name="orderStateFilter"
              label="Order Status"
              value={!!values.orderStateFilter ? values.orderStateFilter : "All"}
              onChange={(e) => { handleOnChange('orderStateFilter', e.id)}}
              data={['All', ...orderStateFilterEnum]}
          />
        </Col>}
        <Col lg={3} xs={3} md={3}>
          <FieldGroup
              id="symbolsubtype"
              name="symbolsubtype"
              type="text"
              label="Symbol Sub Type"
              value={values.symbolsubtype}
              setFieldValue={() => { }}
              placeholder="Symbol Sub Type"
              onChange={(e) => { handleOnChange('symbolsubtype', e.target.value)}}
          />
        </Col>
        {parent !== "ExecutionsSearchPage" && <Col lg={3} xs={3} md={3}>
          <DropdownListGroup
              id="side"
              name="side"
              label="Side"
              value={!!values.side ? values.side : "All"}
              onChange={(e) => { handleOnChange('side', e.id)}}
              data={['All', ...sideEnum]}
          />
        </Col>}
        {parent === "ExecutionsSearchPage" && <Col lg={3} xs={3} md={3}>
          <DropdownListGroup
              id="executionType"
              name="executionType"
              label="Execution Type"
              value={!!values.executionType ? values.executionType : "All"}
              onChange={(e) => { handleOnChange('executionType', e.id)}}
              data={['All', ...executionTypeEnum]}
          />
        </Col>}
        {parent === "ExecutionsSearchPage" && <Col lg={3} xs={3} md={3}>
          <FieldGroup
              id="accounts"
              name="accounts"
              type="text"
              label="Accounts"
              value={values.accountDisplayNames}
              setFieldValue={() => { }}
              placeholder="Accounts"
              onChange={(e) => { handleOnChange('accountDisplayNames', e.target.value)}}
              tooltip = "Searches for exact matches with an account ID or Display Name. Multiple accounts can be entered using a comma separated list."
          />
        </Col>}
        {parent === "OrdersSearchPage" && <Col lg={3} xs={3} md={3}>
          <FieldGroup
              id="account"
              name="account"
              type="text"
              label="Account"
              value={values.accountDisplayName}
              setFieldValue={() => { }}
              placeholder="Account"
              onChange={(e) => { handleOnChange('accountDisplayName', e.target.value)}}
              tooltip = "Searches for exact matches with an account ID or Display Name for a single account."
          />
        </Col>}
        <Col lg={3} xs={3} md={3}>
          <FieldGroup
              id="participant"
              name="participant"
              type="text"
              label="User"
              value={values.participantDisplayName}
              setFieldValue={() => { }}
              placeholder="User"
              onChange={(e) => { handleOnChange('participantDisplayName', e.target.value)}}
          />
        </Col>
        <Col lg={3} xs={3} md={3}>
          <FieldGroup
              id="clientaccountid"
              name="clientaccountid"
              type="text"
              label="Client Account ID"
              value={values.clientaccountid}
              setFieldValue={() => { }}
              placeholder="Client Account ID"
              onChange={(e) => { handleOnChange('clientaccountid', e.target.value)}}
          />
        </Col>
        <Col lg={3} xs={3} md={3}>
          <FieldGroup
              id="clientparticipantid"
              name="clientparticipantid"
              type="text"
              label="Client User ID"
              value={values.clientparticipantid}
              setFieldValue={() => { }}
              placeholder="Client User ID"
              onChange={(e) => { handleOnChange('clientparticipantid', e.target.value)}}
          />
        </Col>
        <Col lg={3} xs={3} md={3}>
          <FieldGroup
              id="clordid"
              name="clordid"
              type="text"
              label="Client Order ID"
              value={values.clordid}
              setFieldValue={() => { }}
              placeholder="Client Order ID"
              onChange={(e) => { handleOnChange('clordid', e.target.value)}}
          />
        </Col>
        <Col lg={3} xs={3} md={3}>
          <FieldGroup
              id="parentorderid"
              name="parentorderid"
              type="text"
              label="Parent Order ID"
              value={values.parentorderid}
              setFieldValue={() => { }}
              placeholder="Parent Order ID"
              onChange={(e) => { handleOnChange('parentorderid', e.target.value)}}
          />
        </Col>
        {parent !== "ExecutionsSearchPage" && <Col lg={3} xs={3} md={3}>
          <FieldGroup
              id="hostcrossid"
              name="hostcrossid"
              type="text"
              label="Exchange Cross ID"
              value={values.hostcrossid}
              setFieldValue={() => { }}
              placeholder="Exchange Cross ID"
              onChange={(e) => { handleOnChange('hostcrossid', e.target.value)}}
          />
        </Col>}
        {parent !== "ExecutionsSearchPage" && <Col lg={3} xs={3} md={3}>
          <FieldGroup
              id="crossid"
              name="crossid"
              type="text"
              label="Client Cross ID"
              value={values.crossid}
              setFieldValue={() => { }}
              placeholder="Client Cross ID"
              onChange={(e) => { handleOnChange('crossid', e.target.value)}}
          />
        </Col>}
      </Form.Row>
    </Filter>
  );
};

OrderHistoryAdditionalFiltersForm.propTypes = {
  values: PropTypes.object.isRequired
};

export default OrderHistoryAdditionalFiltersForm;
