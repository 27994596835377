export default class FormatString {

  static stringFormats = {
    undefined: 0,
    noFormat: 1,
    titleCase: 2,
    capitalizeCase: 3,
    capitalizeAllCase: 4,
  };

  // converts string "title case" to "Title Case"
  static toTitleCase(word) {
    word = word.toLowerCase();
    return word.replace(/(\b[a-z](?!\s))/g, (c) => {
      return c.toUpperCase()
    });
  };

  // converts string "hello dear world" to "Hello dear world"
  static toCapitalize(word) {
    word = word.toLowerCase();
    return word.replace(/^\w/, (c) => {
      return c.toUpperCase()
    });
  };

  // converts string "hello dear world" to "HELLO DEAR WORLD"
  static capitalizeAll(word) {
    word = word.toUpperCase();
    return word;
  }

  // converts string "Hello dear world" to "Hello-dear-world"
  static hyphenate(word) {
    if (word) {
      return word.replace(/\s/g, (c) => "-");
    }

    return word;
  }
}

export class StringFormatOptions {
  constructor(stringFormat, hyphenate) {
    this.stringFormat = stringFormat ? stringFormat : FormatString.stringFormats.capitalizeCase;
    this.hyphenate = hyphenate ? hyphenate : false;
  }
}

