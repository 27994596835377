

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  fetchProduct,
  CleanProduct
} from '../actions/products';
import {fetchMetadata, listHolidayCalendars} from '../actions/instruments';
import './Content.css';
import Loader from '../components/core/loader/Loader';
import InstrumentHeader from '../components/instrument/InstrumentHeader';
import ProductFormContainer from '../containers/ProductFormContainer';
import { AdminDocumentTitle } from '../constants/strings';
import {CleanInstrument, fetchInstruments} from "../actions/instruments";

function mapStateToProps(state) {
  return {
    fetchingProduct: state.products.fetchingProduct,
    instruments: state.instruments
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchProduct: (id) => {
    dispatch(fetchProduct(id))
  },
  cleanProduct: () => {
    dispatch(CleanProduct())
  },
  cleanInstrumentForm: () => {
    dispatch(CleanInstrument())
  },
  fetchInstruments: (productId) => {
    dispatch(fetchInstruments(productId))
  },
  fetchMetadata: () => {
    dispatch(fetchMetadata())
  },
  listHolidayCalendars: () => {
    dispatch(listHolidayCalendars())
  }
});

class ProductFormPage extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.props.cleanProduct();
    this.props.cleanInstrumentForm();
    this.props.fetchMetadata();
    this.props.listHolidayCalendars();
    this.productId = this.props.match.params.id;
    if (this.productId) {
      this.props.fetchProduct(decodeURIComponent(this.productId));
      this.props.fetchInstruments(this.productId);
    }
    window.scrollTo(0, 0);
  }

  openCreateProduct = () => {
    this.context.router.history.push(`/products/new`);
  }

  openViewInstrument = (instrumentId) => {
    this.context.router.history.push(`/instruments/${encodeURIComponent(instrumentId)}`);
  }

  openChangeStateInstrument = (instrumentId) => {
    var getVal = '';
    var dest = '';
    if (Array.isArray(instrumentId)) {
      const instsJSON = JSON.stringify(instrumentId);
      getVal = encodeURIComponent(instsJSON);
      dest = 'multiState';
    } else {
      getVal = encodeURIComponent(instrumentId);
      dest = 'changeState';
    }

    this.context.router.history.push(`/instruments/${getVal}/${dest}`);
  }

  openEditInstrument = (instrumentId) => {
    this.context.router.history.push(`/instruments/${encodeURIComponent(instrumentId)}/edit`);
  }

  openBookInstrument = (instrumentId) => {
    this.context.router.history.push(`/books?instrumentId=${encodeURIComponent(instrumentId)}`);
  }

  render() {
    return (
      <div className="with-callback">
        <InstrumentHeader
          buttonText="Add New Product"
          buttonClick={() => { this.openCreateProduct() }}
          isProductsSelected={true}
          instrumentId={this.productId}
        />
        <Loader show={this.props.fetchingProduct}/>
        <ProductFormContainer />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductFormPage));