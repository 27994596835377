import BookSnapshot from "./BookSnapshot";

export default class Book {
  constructor(item) {
    if (item) {
      const object = item.toObject();
      this.seqNumber = object.seqNum;
      this.symbol = object.partition;
      this.snapshot = new BookSnapshot(item.getSnapshot());
      this.partition = object.partition;
      return;
    }
    this.seqNumber = null;
    this.symbol = null;
    this.snapshot = null;
    this.partition = null;
  }
}