import { capitalizeWord, getEnumName } from "../modules/util";
import State from "./dto/State";
import { FirmParticipantType, OrganizationType, Personnel, PersonnelRole } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";

export default class FirmAttributes {
  constructor(item) {
    if (item) {
      this.legalName = item.getLegalName();
      this.phoneNumber = item.getPhoneNumber();
      this.email = item.getEmail();
      this.legalEntityIdentifier = item.getLegalEntityIdentifier();
      this.businessContactName = item.getBusinessContactName();
      var participantType = new State();
      participantType.id = item.getParticipantType();
      participantType.name = capitalizeWord(getEnumName(FirmParticipantType, participantType.id));
      this.participantType = participantType;
      var organizationType = new State();
      organizationType.id = item.getOrganizationType();
      organizationType.name = capitalizeWord(getEnumName(OrganizationType, organizationType.id));
      this.organizationType = organizationType;
      this.usTaxpayerIdentificationNumber = item.getUsTaxpayerIdentificationNumber();
      let mainAddress = item.getMainAddress()
      if (mainAddress) {
        this.mainAddress1 = mainAddress.getAddress1();
        this.mainAddress2 = mainAddress.getAddress2();
        this.mainAddressCity = mainAddress.getCity();
        this.mainAddressState = mainAddress.getState();
        this.mainAddressPostalCode = mainAddress.getPostalCode();
        this.mainAddressCountryCode = mainAddress.getCountryCode();
      } else {
        this.mainAddress1 = null;
        this.mainAddress2 = null;
        this.mainAddressCity = null;
        this.mainAddressState = null;
        this.mainAddressPostalCode = null;
        this.mainAddressCountryCode = null;
      }
      this.designatedSelfRegulatoryOrganization = item.getDesignatedSelfRegulatoryOrganization();
      this.designatedExaminingAuthority = item.getDesignatedExaminingAuthority();

      this.keyPersonnel = [];
      const keyPersonnelList = item.getKeyPersonnelList();
      if (Array.isArray(keyPersonnelList)) {
        keyPersonnelList.forEach(entry => {
          let personnel = new Personnel()
          personnel.name = entry.getName()
          personnel.phoneNumber = entry.getPhoneNumber()
          personnel.email = entry.getEmail()

          let address = entry.getAddress()
          if (address) {
            personnel.address1 = address.getAddress1();
            personnel.address2 = address.getAddress2();
            personnel.addressCity = address.getCity();
            personnel.addressState = address.getState();
            personnel.addressPostalCode = address.getPostalCode();
            personnel.addressCountryCode = address.getCountryCode();
          } else {
            personnel.address1 = null;
            personnel.address2 = null;
            personnel.addressCity = null;
            personnel.addressState = null;
            personnel.addressPostalCode = null;
            personnel.addressCountryCode = null;
          }

          var role = new State();
          role.id = entry.getRole();
          role.name = getEnumName(PersonnelRole, role.id);
          personnel.role = role;

          this.keyPersonnel.push(personnel)
        });
      }

      this.alternateLegalNames = item.getAlternateLegalNamesList();

      return;
    }
    this.legalName = null;
    this.phoneNumber = null;
    this.email = null;
    this.legalEntityIdentifier = null;
    this.participantType = null;
    this.organizationType = null;
    this.businessContactName = null;
    this.usTaxpayerIdentificationNumber = null;
    this.mainAddress1 = null;
    this.mainAddress2 = null;
    this.mainAddressCity = null;
    this.mainAddressState = null;
    this.mainAddressPostalCode = null;
    this.mainAddressCountryCode = null;
    this.designatedSelfRegulatoryOrganization = null;
    this.designatedExaminingAuthority = null;
    this.keyPersonnel = [];
  }
}
