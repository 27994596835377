import { getAttributes, getFirm } from "../../src/services/FirmService";
import Participant from "../../src/entities/Participant";
import ParticipantService from "../../src/services/ParticipantService";
import FirmAttributes from "../../src/entities/FirmAttributes";
import Account from "../../src/entities/Account";
import { isNullOrUndefined } from "../modules/util";
import FirmEntity from "../../src/entities/Firm";
import AccountService from "../../src/services/AccountService";

export async function getParticipant(state, name) {
    let participant = null;

    try {
        let participants = state.participants.participants || [];

        participant = participants.find(
            (_participant) => _participant.name === name
        );

        if (!participant) {
            participant = await ParticipantService.get2(name);
            participant = new Participant(participant.getParticipant());
        }
    } catch (error) {
        console.error(`Could not find participant ${name}`, error);
    }

    return Object.assign({}, participant);
}

export async function loadFirmAttributes(firm) {
    if (firm.attributes) {
        return firm;
    }
    try {
        let response = await getAttributes(firm.name);

        if (isNullOrUndefined(getAttributes)) {
            console.error(`Could not load attributes of firm: ${firm.name}`);
            return firm;
        }

        let attributes = new FirmAttributes(response.getAttributes());
        firm.attributes = attributes;
    } catch (error) {
        console.error(`Could not load firm's attributes ${firm.name}`, error);
    }

    return firm;
}

export async function getAccountFirm(state, name) {
    if (name.length === 0) {
        return {}
    }

    let account = await getAccount(state, name)

    let firm = new FirmEntity()
    if (!!account && account.associatedFirm.length > 0) {
        firm = await loadFirmAttributes(
            await getFirm(
                state,
                account.associatedFirm
            )
        );
    }

    return Object.assign({}, { account: account, firm: firm });
}

export async function getParticipantFirm(state, name) {
    if (name.length === 0) {
        return {}
    }

    let participant = await getParticipant(state, name)

    let firm = new FirmEntity()
    if (!!participant && participant.firm.length > 0) {
        firm = await loadFirmAttributes(
            await getFirm(
                state,
                participant.firm
            )
        );
    }

    return Object.assign({}, { participant: participant, firm: firm });
}

async function getAccount(state, name) {
    let account = null;
  
    try {
      let accounts = state.accounts.accounts || [];
  
      account = accounts.find((acc) => acc.name === name);
  
      if (!account) {
        account = await AccountService.get2(name);
        account = new Account(account.getAccount());
      }
    } catch (error) {
      console.error(`Could not find account ${name}`, error);
    }
  
    return account;
  }