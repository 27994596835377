export default class State {
  constructor(item) {
    if (item) {
      this.id = item.id;
      this.name = item.name;
      return;
    }
    this.id = null;
    this.name = null;    
  }
}