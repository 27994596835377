import { useMemo } from "react";

export const useTimeByInterval = (startTime, endTime, interval) => {
	const timeArray = useMemo(() => generateTimeArray(startTime, endTime, interval), [startTime, endTime, interval]);

	return [timeArray];
}

const generateTimeArray = (startTime, endTime, interval) => {
	const times = [];
	let currentTime = new Date(`1970-01-01T${startTime}:00`);
	const end = new Date(`1970-01-01T${endTime}:00`);

	while (currentTime <= end) {
		let hours = currentTime.getHours();
		let minutes = currentTime.getMinutes();

		const period = hours < 12 ? 'AM' : 'PM';
		let formattedHours = hours % 12 === 0 ? 12 : hours % 12;
		let formattedMinutes = minutes === 0 ? '00' : minutes;

		// 24 Hrs format
		hours = hours.toString().padStart(2, '0');
		minutes = minutes.toString().padStart(2, '0');

		// 12 Hrs format
		formattedHours = formattedHours.toString().padStart(2, '0');
		formattedMinutes = formattedMinutes.toString().padStart(2, '0');

		times.push({
			value: `${hours}:${minutes}`,
			label: `${formattedHours}:${formattedMinutes} ${period}`,
		});

		currentTime.setMinutes(currentTime.getMinutes() + interval);
	}

	return times;
}

