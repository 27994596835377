import { OrderStatuses } from '../../../src/constants/enumerations'
import {capitalizeWord, getEnumName} from "../util";
import { OrderCapacity } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
export function getOrderStateName(value) {
    switch (value) {
        case 0:
            return OrderStatuses.NEW_ORDER
        case 1:
            return OrderStatuses.PARTIALLY_FILLED_ORDER
        case 2:
            return OrderStatuses.FILLED_ORDER
        case 3:
            return OrderStatuses.CANCELED_ORDER
        case 4:
            return OrderStatuses.REPLACED_ORDER
        case 5:
            return OrderStatuses.REJECTED_ORDER
        case 6:
            return OrderStatuses.EXPIRED_ORDER
        case 7:
            return OrderStatuses.PENDING_NEW_ORDER
        case 8:
            return OrderStatuses.PENDING_REPLACE_ORDER
        case 9:
            return OrderStatuses.PENDING_CANCEL_ORDER
        case 10:
            return OrderStatuses.PENDING_RISK_ORDER

        default:
            return null;
    }
}
export function getOrderTimeInForceName(value) {
    switch (value) {
        case 0:
            return 'Undefined'
        case 1:
            return 'Day'
        case 2:
            return 'GoodTillCancel';
        case 3:
            return 'ImmediateOrCancel';
        case 4:
            return 'GoodTillTime';
        case 5:
            return 'FillOrKill';
        default:
            return null;
    }
}
export function getOrderTypeName(value) {
    switch (value) {
        case 0:
            return 'Undefined'
        case 1:
            return 'MarketToLimit'
        case 2:
            return 'Limit';
        case 3:
            return 'Stop';
        case 4:
            return 'StopLimit';
        default:
            return null;
    }
}
export function getOrderSideName(value) {
    switch (value) {
        case 0:
            return 'Undefined'
        case 1:
            return 'Buy'
        case 2:
            return 'Sell';
        default:
            return null;
    }
}

export function getOrdRejectReasonName(value) {
    switch (value) {
        case 0:
            return 'ExchangeOption';
        case 1:
            return 'UnknownSymbol';
        case 2:
            return 'ExchangeClosed';
        case 3:
            return 'IncorrectQuantity';
        case 4:
            return 'InvalidPriceIncrement';
        case 5:
            return 'IncorrectOrderType';
        case 6:
            return 'PriceOutOfBounds';
        case 7:
            return 'NoLiquidity';
        default:
            return null;
    }
}

export function getExecutionTypeName(value) {
    switch (value) {
        case 0:
            return 'New'
        case 1:
            return 'PartialFill'
        case 2:
            return 'Fill';
        case 3:
            return 'Canceled';
        case 4:
            return 'Replace';
        case 5:
            return 'Rejected';
        case 6:
            return 'Expired';
        case 7:
            return 'DoneForDay';
        default:
            return null;

    }
}

export function getOrderCapacityName(value) {
    return capitalizeWord(getEnumName(OrderCapacity, value));
}
