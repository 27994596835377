import ApiCall from "../modules/apiCall";
import {apiUrl} from "../modules/util";

const {
  UpdateAdminRequestRequest,
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js');
const { AdminAPIClient } = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js');

const client = new AdminAPIClient(apiUrl(), null, null);

export default class AdminRequestsService {

  static getAll(listAdminRequests, cb) {
    ApiCall(client, "listAdminRequests", listAdminRequests, cb);
  }

  
  static updateAdminRequest(id, approve, cb) {

    var request = new UpdateAdminRequestRequest();
    request.setId(id);
    request.setApprove(approve);

    ApiCall(client, "updateAdminRequest", request, cb);
  }

}
