import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withFormik } from "formik";
import Modal from "react-bootstrap4-modal";
import { FormLabel, FormControl } from "react-bootstrap";

import TimeSelect from "../TimeSelect";
import FieldGroup from "../form/FieldGroup";
import DropdownListGroup from "../form/DropdownListGroup";
import ButtonMain from "../form/ButtonMain";
import ButtonSecondary from "../form/ButtonSecondary";

import { TradingHourPeriodicity } from "../../../constants/enumerations";

import "./index.css";
import State from "../../../entities/dto/State";
import {InstrumentState} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import {capitalizeWord, getEnumName} from "../../../modules/util";
import InstrumentStateListContainer from "../../../containers/InstrumentStateListContainer";

const allDays = [0, 1, 2, 3, 4, 5, 6];
const weekDays = [1, 2, 3, 4, 5];

class BasicScheduleForm extends Component {
  constructor(props) {
    super();

    this.state = {
      selectedDays: props.values.days,
      repeatNum: "1",
      repeatSelect: "week",
    };
  }

  addDay = (day) => {
    let days = this.state.selectedDays;
    days.push(day);
    this.setState({ days: days });
  };

  removeDay = (day) => {
    let days = this.state.selectedDays;
    const index = days.indexOf(day);
    days.splice(index, 1);
    this.setState({ days: days });
  };

  beforeSubmitForm = () => {
    const { values } = this.props;

    switch (values.periodicity) {
      case TradingHourPeriodicity.EVERYDAY:
        values.days = allDays;
        break;
      case TradingHourPeriodicity.EVERYWEEKDAY:
        values.days = weekDays;
        break;
      case TradingHourPeriodicity.CUSTOM:
        values.days = this.state.selectedDays.sort();
        break;
      default:
        break;
    }

    values.to = parseInt(values.to);
    values.from = parseInt(values.from);

    values.duration = values.to - values.from;

    if (values.from > values.to) {
      // it means- values.to is next day-
      // 24 hours in seconds = 24*60*60= 86400;
      values.duration = 86400 - values.from + values.to;
    }

    this.props.submitForm(values);
  };

  componentWillReceiveProps(props) {
    this.setState({
      selectedDays: props.values.days,
    });
  }

  render() {
    const {
      values,
      errors,
      handleBlur,
      setFieldValue,
      touched,
    } = this.props;

    const days = ["S", "M", "T", "W", "T", "F", "S"]; // for day select buttons

    return (
      <form>
        <div className="form-group">
          <InstrumentStateListContainer
              id="state"
              name="state"
              label="State"
              selectedState={values.state ? values.state.id : 0}
              enabled={true}
              onChange={(_, e) => setFieldValue("state", e)}
              notAllowedInstrumentStates={[
                  InstrumentState.INSTRUMENT_STATE_PENDING,
                  InstrumentState.INSTRUMENT_STATE_CLOSED,
                  InstrumentState.INSTRUMENT_STATE_EXPIRED,
                  InstrumentState.INSTRUMENT_STATE_TERMINATED,
              ]}
          />
        </div>
        <div className="form-row text-center font-weight-bold align-center">
          <TimeSelect
            id="from"
            name="from"
            start="0:00"
            end="24:00"
            step={15}
            initialValue={values.from}
            onChange={(value) => {
              setFieldValue("from", value);
            }}
          />{" "}
          <span className="timeList-colon">:</span>
          <TimeSelect
            id="to"
            name="to"
            start="0:00"
            end="24:00"
            step={15}
            initialValue={values.to}
            onChange={(value) => {
              setFieldValue("to", value);
            }}
          />
        </div>
        <DropdownListGroup
          id="periodicity"
          name="periodicity"
          label=""
          value={values.periodicity}
          onChange={(e) => setFieldValue("periodicity", e.id)}
          data={[
            { id: TradingHourPeriodicity.EVERYDAY, name: "Every Day" },
            {
              id: TradingHourPeriodicity.EVERYWEEKDAY,
              name: "Every Week Day (M-F)",
            },
            { id: TradingHourPeriodicity.CUSTOM, name: "Custom" },
          ]}
        />
        {values.periodicity === TradingHourPeriodicity.CUSTOM && (
          <React.Fragment>
            <div className="form-row">
              <div className="col-md-4">
                <FormLabel
                  style={{
                    color: "#5B5B5B",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "23px",
                    paddingTop: "14px",
                  }}
                >
                  Repeat Every
                </FormLabel>
              </div>
              <div className="col-md-4">
                <FieldGroup
                  id="repeatNum"
                  name="repeatNum"
                  type="number"
                  min="1"
                  step="1"
                  value={this.state.repeatNum}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  onChange={(e) => {
                    this.setState({ repeatNum: e.target.value });
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className="col-md-4">
                <FormControl
                  as="select"
                  onChange={(e) => {
                    this.setState({ repeatSelect: e.target.value });
                  }}
                  value={this.state.repeatSelect}
                >
                  <option value="week">Weeks</option>
                  <option value="month">Months</option>
                </FormControl>
              </div>
            </div>
            <div className="form-row">
              <FormLabel className="repeatOn">Repeat On</FormLabel>
            </div>
            <div className="form-row">
              <ul className="dayList">
                {days.map((day, index) => {
                  if (values.days.indexOf(index) === -1) {
                    return (
                      <li
                        key={`${day}${index}`}
                        className="dayButton"
                        onClick={() => this.addDay(index)}
                      >
                        {day}
                      </li>
                    );
                  } else {
                    return (
                      <li
                        key={`${day}${index}`}
                        className="dayButtonActive"
                        onClick={() => this.removeDay(index)}
                      >
                        {day}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </React.Fragment>
        )}
        <div className="form-row mr-auto">
          <ButtonMain
            type="button"
            onClick={this.beforeSubmitForm}
            text="Done"
          />
          <ButtonSecondary
            type="button"
            onClick={this.props.onCancel}
            text="Cancel"
          />
        </div>
      </form>
    );
  }
}

const ScheduleForm = withFormik({
  mapPropsToValues: (props) => {
    let from = 28800; // seconds //8:0 am
    let to = 61200; // seconds   //17:00 pm
    let state = new State();
    state.id = InstrumentState.INSTRUMENT_STATE_OPEN;
    state.name = capitalizeWord(getEnumName(InstrumentState, state.id));
    let days = [];
    let periodicity = TradingHourPeriodicity.EVERYDAY;

    if (props.schedule) {
      const { schedule } = props;

      if (schedule.state) {
        state.id = schedule.state.id;
        state.name = schedule.state.name;
      }
      days = schedule.daysOfWeekList;
      periodicity = schedule.periodicity;

      // calculate start and end times as durations in seconds
      // this is for the start and end time dropdowns - <TimeSelect/> requires values in seconds
      if (schedule.timeOfDay) {
        const { hours, minutes, seconds } = schedule.timeOfDay;
        const startTime = moment();
        startTime.hours(hours).minutes(minutes).seconds(seconds);
        const endTime = moment(startTime).add(
          schedule.duration.seconds,
          "seconds"
        );

        const startDayBegin = moment(startTime).hours(0).minutes(0).seconds(0);
        const endDayBegin = moment(endTime).hours(0).minutes(0).seconds(0);

        from = moment.duration(startTime.diff(startDayBegin)).asSeconds();
        to = moment.duration(endTime.diff(endDayBegin)).asSeconds();

        if (from === to) {
          to += 60 * 60 * 24;
        }
      }
    }

    const values = {
      days,
      from,
      periodicity,
      to,
      state,
    };
    return values;
  },
  validate: (values) => {
    let errors = {};

    return errors;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmitForm(values);
  },
  displayName: "schedule-form",
  enableReinitialize: true,
})(BasicScheduleForm);

class TradingScheduleDialog extends Component {
  constructor() {
    super();

    this.state = {
      from: "",
      to: "",
      dayOfWeek: "",
      state: new State(),
    };
  }

  render() {
    let returnValue = null;
    if (this.props.show) {
      returnValue = (
        <Modal visible={true}>
          <div className="modal-header">
            <h5 className="modal-title">{this.props.title}</h5>
          </div>
          <div className="modal-body">
            <ScheduleForm
              onSubmitForm={this.props.onSave}
              onCancel={this.props.onCancel}
              schedule={this.props.schedule}
            />
          </div>
        </Modal>
      );
    }
    return returnValue;
  }
}

TradingScheduleDialog.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.string,
};

export default TradingScheduleDialog;
