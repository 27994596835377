import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./Content.css";
import QuotesDataGrid from "../components/rfq-history/QuotesDataGrid";
import Pagination, {
  withPageToken,
} from "../components/core/data-grid/Pagination";
import { searchQuotes, cleanSearch } from "../actions/quotes";
import Notification from "../modules/notifications";
import AccountService from "../services/AccountService";
import Account from "../entities/Account";
import {
  FetchAccountsStart,
  FetchAccountsSucceed,
  FetchAccountsFailed,
  CleanAccount,
} from "../actions/accounts";

import {
  FetchParticipantsStart,
  FetchParticipantsSucceed,
  FetchParticipantsFailed,
} from "../actions/participants";
import { LoadFirms } from "../actions/firms";
import ParticipantService from "../services/ParticipantService";
import Participant from "../entities/Participant";
import QuoteMessageType from "../entities/dto/QuoteMessageType";
import { searchRequestForQuotes } from "../actions/requestForQuotes";
import { TitleBreadcrumb } from "../components/core/title-breadcrumb/title-breadcrumb";

function mapStateToProps(state) {

  let rfq = state.requestForQuotes.requestForQuotes[0];

  if (rfq) {
    rfq = {...rfq};

    const account = state.accounts.accounts.find( (item) => item.name === rfq.account );
    if (account) { rfq.account = account.displayName; }

    const firm = state.firms.firms.find( (item) => item.name === rfq.firm );
    if (firm) { rfq.firm = firm.displayName; }

    const participant = state.participants.participants.find( (item) => item.name === rfq.user );
    if (participant) { rfq.user = participant.displayName; }

    const agent = state.participants.participants.find( (item) => item.name === rfq.submittingUser );
    if (agent) {
      rfq.submittingUser = agent.displayName;
      rfq.submittingFirm = agent.firm;
      const agentFirm = state.firms.firms.find( (item) => item.name === rfq.submittingFirm );
      if (agentFirm) { rfq.submittingFirm = agentFirm.displayName; }
    }
  }

  return {
    rfq: rfq,
    fetchingQuotes: state.quotes.fetchingQuotes,
    quotes: state.quotes.quotes,
    tokens: state.quotes.tokens,
    pageToken: state.quotes.pageToken,
    accounts: state.accounts.accounts,
    participants: state.participants.participants,
    firms: state.firms.firms
  };
}

const mapDispatchToProps = (dispatch) => ({
  searchQuotes: (filters, pageToken) => {
    dispatch(searchQuotes(filters, pageToken));
  },
  cleanSearch: () => {
    dispatch(cleanSearch());
  },
  fetchRFQ: (rfqid) => {
    dispatch(searchRequestForQuotes({rfqid: rfqid}));
  },
  fetchGroupsStart: () => {
    dispatch(FetchAccountsStart());
  },
  fetchGroupsSucceed: (accountList) => {
    dispatch(FetchAccountsSucceed(accountList));
  },
  fetchGroupsFailed: () => {
    dispatch(FetchAccountsFailed());
  },
  cleanAccount: () => {
    dispatch(CleanAccount());
  },
  fetchParticipantsStart: () => {
    dispatch(FetchParticipantsStart());
  },
  fetchParticipantsSucceed: (participanttList) => {
    dispatch(FetchParticipantsSucceed(participanttList));
  },
  fetchParticipantsFailed: () => {
    dispatch(FetchParticipantsFailed());
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
});

class QuotesPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();
    var start = new Date().setHours(0, 0, 0, 0);
    var end = new Date().setHours(23, 59, 59, 999);
    this.state = {
      fromDate: start,
      toDate: end,
      instrumentId: null,
      participant: null,
      account: null,
      clientId: null,
      qid: null,
      rfqid: null,
      tokens: {},
      side: 0,
      qStateFilter: 0,
      messageType: QuoteMessageType.All,
      firm: null,
    };
    this.timer = null;
  }

  //REFACTOR TODO: Move load processes to ../actions; too many dup functions
  loadAccounts = () => {
    this.props.fetchGroupsStart();
    const cb = (err, response) => {
      if (response) {
        const accountsList = response.getAccountsList().map((acct) => {
          return new Account(acct);
        });
        this.props.fetchGroupsSucceed(accountsList);
      }

      if (err) {
        this.props.fetchGroupsFailed();
        Notification.error("Cannot get accounts list.");
      }
    };

    AccountService.getAll(cb);
  };

  //REFACTOR TODO: same as above
  loadParticipants = () => {
    this.props.fetchParticipantsStart();

    const cb = (err, response) => {
      if (response) {
        var participantsList = response
          .getParticipantsList()
          .map((participant) => {
            return new Participant(participant);
          });

        this.props.fetchParticipantsSucceed(participantsList);
      }

      if (err) {
        this.props.fetchParticipantsFailed();
        Notification.error("Cannot get list of participants.");
      }
    };

    ParticipantService.getAll(cb);
  };

  componentDidMount() {
    this.props.cleanSearch();
    this.props.cleanAccount();
    this.loadAccounts();
    this.loadParticipants();
    this.props.loadFirms();

    let { participants, accounts, computedMatch, firms } = this.props;
    if (participants && accounts && computedMatch && firms) {
      this.search(this.state);
      this.props.fetchRFQ(this.state.rfqid);
    }

    window.scrollTo(0, 0);
  }

  search = (values) => {
    let { participants, accounts, computedMatch, firms } = this.props;

    if (values.participant) {
      let participantId = participants.find(
        (p) =>
          p.displayName === values.participant ||
          p.name === values.participant,
      );
      if (participantId) {
        values.participant = participantId.name;
      } else {
        Notification.error("User not found");
      }
    }

    if (values.account) {
      let accountId = accounts.find(
        (a) => (a.displayName === values.account) | (a.name === values.account),
      );

      if (accountId) {
        values.account = accountId.name;
      } else {
        Notification.error("Account not found");
      }
    }

    const rfqid = computedMatch.params.id;
    if (rfqid) {
      values.rfqid = `requestForQuotes/${rfqid}`;
    }

    if (values.qid) {
      values.qid = `requestForQuotes/${rfqid}/quotes/${values.qid}`;
    }

    if (values.firm) {
      let firmId = firms.find(
        (firm) => firm.displayName === values.firm || firm.name === values.firm,
      );

      if (firmId) {
        values.firm = firmId.name;
      } else {
        Notification.error("Firm not found");
      }
    }

    this.setState({
      instrumentId: values.instrumentId,
      fromDate: values.fromDate,
      toDate: values.toDate,
      participant: values.participant,
      account: values.account,
      clientId: values.clientId,
      qid: values.qid,
      rfqid: values.rfqid,
      side: values.side,
      qStateFilter: values.qStateFilter,
      messageType: values.messageType,
      firm: values.firm,
    });

    if (!this.props.fetchingQuotes) {
      this.props.searchQuotes(this.state);
    }
  };

  onPageChange = (pageToken) => {
    this.props.searchQuotes(this.state, pageToken);
  };

  getPaginationComponent = () => {
    const pageToken = this.props.pageToken;
    const tokens = this.props.tokens;
    return withPageToken(Pagination, { pageToken, tokens });
  };

  parseDisplayNames = () => {
    let { quotes, accounts, participants, firms } = this.props;
    if (quotes) {
      quotes.forEach((q) => {
        const matchedAccount = accounts.find(
          (account) => account.name === q.account,
        );
        if (matchedAccount) q.account = matchedAccount.displayName;

        const matchedParticipant = participants.find(
          (participant) => participant.name === q.user,
        );
        if (matchedParticipant) q.user = matchedParticipant.displayName;

        q.name = q.name.replace(/.*\/quotes\//, "");

        const matchedFirms = firms.find((firm) => firm.name === q.firm);

        if (matchedFirms) q.firm = matchedFirms.displayName;

        const matchedAgent = participants.find(
            (participant) => participant.name === q.submittingUser,
        );
        if (matchedAgent) {
          q.submittingUser = matchedAgent.displayName;
          q.submittingFirm = matchedAgent.firm;
          const matchedAgentFirm = firms.find((firm) => firm.name === q.submittingFirm);
          if (matchedAgentFirm) q.submittingFirm = matchedAgentFirm.displayName;
        }
      });
    }
  };

  render() {
    const getTitle = (quote) => {
      if (rfq) {
        const rid = rfq.id.substring(rfq.id.indexOf("/") + 1, rfq.id.length)
        let fields = `${rfq.side.name} ${rfq.symbol} ${rfq.status.name} ${rfq.firm} ${rfq.account} ${rfq.user}`
        let rid_trimmied = rid.substr(rid.length - 4, rid.length)
      return (<h6>
                <span title={rid}>{rid_trimmied} &nbsp;</span>
                <span>{fields}</span>
              </h6>)
       }
    }
    let { quotes, computedMatch, rfq } = this.props;
    this.parseDisplayNames();
    return (
      <div className="with-callback">
        <TitleBreadcrumb titles={[
          { 'title': 'Quotes', 'link': `${window.location.origin}/requestForQuotes/quotes` },
          computedMatch.params.id ? { 'title': computedMatch.params.id, 'link': `${window.location.origin}/requestForQuotes/quotes/${computedMatch.params.id}` } : null
        ]} />
        <div>
          {getTitle(rfq)}
        </div>
        <QuotesDataGrid
          data={quotes}
          messageType={this.state.messageType}
          linkBack={true}
          paginationComponent={this.getPaginationComponent()}
          onPageChange={this.onPageChange}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesPage);
