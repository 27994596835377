import React from "react";
import { Row, Col } from "react-bootstrap";
import FirmsStaticData from "../../modules/firmsStaticData";
import DropdownListGroup from "../core/form/DropdownListGroup";
import { useState } from "react";
import { useEffect } from "react";
import FieldGroup from "../core/form/FieldGroup";
import ButtonSecondary from "../core/form/ButtonSecondary";

export const FirmsFilter = (props) => {
    const [firmTypes, setFirmTypes] = useState();
    const [firmType, setFirmType] = useState();
    const [firmName, setFirmName] = useState();
    const [firmState, setFirmState] = useState();
    const [firmStates, setFirmStates] = useState([]);

    useEffect(() => {
        setFirmTypes([{ id: "", name: "" }, ...FirmsStaticData.FirmTypes([0])]);
        setFirmStates([{ id: "", name: "" }, ...FirmsStaticData.FirmStates()]);
    }, []);

    return (
        <div className="with-callback">
            <Row>
                <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                        id="firmName"
                        name="firmName"
                        type="text"
                        label="Firm Name"
                        value={firmName}
                        setFieldValue={() => { }}
                        placeholder="Partial or complete Firm Name."
                        onChange={(e) => { setFirmName(e.target.value); }}
                    />
                </Col>
                <Col lg={2} xs={2} md={2}>
                    <DropdownListGroup
                        id="firmType"
                        name="firmType"
                        label="Firm Type"
                        value={firmType}
                        onChange={(e) => { setFirmType(e.id); }}
                        data={firmTypes}
                    ></DropdownListGroup>
                </Col>
                <Col lg={2} xs={2} md={2}>
                    <DropdownListGroup
                        id="userState"
                        name="userState"
                        label="Firm State"
                        value={firmState}
                        onChange={(e) => { setFirmState(e.id); }}
                        data={firmStates}
                    ></DropdownListGroup>
                </Col>

                <Col lg={3} xs={3} md={3}>
                    <Row>
                        <Col lg={4} xs={4} md={4} style={{ marginTop: "35px" }} ><button style={{ width: "100px" }}
                            className="Filter-Button Filter-Apply"
                            onClick={() => {
                                props.onApplyFilter(firmType, firmName, firmState);
                            }}>Filter</button>
                        </Col>
                        <Col lg={4} xs={4} md={4} style={{ marginTop: "35px" }} ><ButtonSecondary style={{ width: "100px" }}
                            text="Reset"
                            onClick={() => {
                                setFirmName("");
                                setFirmState("");
                                setFirmType("");
                                props.onApplyFilter("");
                            }} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
