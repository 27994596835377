import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormLabel
} from 'react-bootstrap';
import DateTimePickerControl from '../DateTimePickerControl';
import './DateGroup.css';
import Tooltip from '../tooltip-html/tooltip-html';
import DateTimeParser from "../../../modules/dateTimeParser";
import { isNullOrUndefined } from '../../../modules/util';

const DateGroup = ({
  id,
  name,
  label,
  enableTime,
  step,
  isRequired,
  value,
  dropUp,
  minDate,
  maxDate,
  enabled,
  defaultCurrentDate,
  errors,
  touched,
  onChange,
  onBlur,
  plaintext,
  tooltip,
  tooltipPlace,
  timeFormat,
  notEditable,
}) => (
  <FormGroup id={id}>
    {label ? <FormLabel>{label}</FormLabel> : null}

    {!isNullOrUndefined(tooltip) &&
      <Tooltip
        text={tooltip}
        id={`tip-${id}`}
        place={!!tooltipPlace ? tooltipPlace : 'top'}
      ></Tooltip>
    }

    {plaintext && <div className="form-readonly-label">{value ? DateTimeParser.toDisplay(value, !!enableTime) : ""}</div>}

    {!plaintext &&
      <>
        {isRequired && <label className="form-label-required"> Required</label>}
        <DateTimePickerControl
          value={value}
          dropUp={dropUp}
          minDate={minDate}
          maxDate={maxDate}
          enableTime={enableTime}
          step={step}
          enabled={enabled}
          onChange={onChange}
          onBlur={onBlur}
          defaultCurrentDate={defaultCurrentDate}
          timeFormat={timeFormat}
          notEditable={notEditable}
        />
        {errors && errors[name] && <div className="form-error">
          <i className="fa fa-exclamation-triangle orange-icon" aria-hidden="true"></i> {errors[name]}</div>}
      </>
    }
  </FormGroup>
)

DateGroup.defaultProps = {
  enableTime: false,
  isRequired: false,
  enabled: true,
  step: 30,
  value: null,
  defaultCurrentDate: null
}

DateGroup.propTypes = {
  enableTime: PropTypes.bool,
  value: PropTypes.any,
  isRequired: PropTypes.bool,
  enabled: PropTypes.bool,
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

export default DateGroup;