import ApiCall from "../modules/apiCall";
import apiCallPromise from "../modules/apiCallPromise";
import { apiUrl, isEmptyString, isNullOrUndefined } from "../modules/util";
import ProtobufParser from "../modules/protobufParser";

const {
  GetAccountRequest,
  ListAccountsRequest,
  ListAccountBalancesRequest,
  ListPendingWithdrawalsRequest,
  ListPositionsRequest,
  GetHistoricalPositionsRequest,
  DownloadPositionsRequest,
  DownloadPositionLedgerRequest,
  DownloadBalanceLedgerRequest,
  CreateAccountRequest,
  SetAccountSuspendStateRequest,
  DeleteAccountRequest,
  AddUserToAccountRequest,
  RemoveUserFromAccountRequest,
  ListParticipantsRequest,
  UpdateAccountRequest,
  UndeleteAccountRequest,
  AdjustPositionRequest,
  SetAccountBalanceRequest,
  AdjustAccountBalanceRequest,
  CreatePendingWithdrawalRequest,
  ApprovePendingWithdrawalRequest,
  DeletePendingWithdrawalRequest,
  GetLicenseRequest,
  SetAccountCapitalRequirementRequest,
  SetAccountSecurityMarketValueRequest,
  SetAccountSecurityBalanceRequest,
  SetSecurityDefinitionRequest,
  ListSecurityDefinitionsRequest,
  CreatePendingCreditRequest,
  ListPendingCreditsRequest,
  ApprovePendingCreditsRequest,
  DeletePendingCreditsRequest,
  ListPendingSettlementRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");
const { PositionAdjustment } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/positions/v1beta1/positions_pb.js");
const { SecurityDefinition } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/risk/v1beta1/risk_pb.js");
const { AdminAPIClient, AdminAPIPromiseClient } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");
const { BankDetails } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb.js");
const { LedgerEntryType } = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/type/v1beta1/type_pb.js');

const client = new AdminAPIClient(apiUrl(), null, null);

const clientPromise = new AdminAPIPromiseClient(
  apiUrl(),
  null,
  null
);

export default class AccountService {
  static get(id, cb) {
    var request = new GetAccountRequest();
    request.setName(id);

    ApiCall(client, "getAccount", request, cb);
  }

  static async get2(id) {
    var request = new GetAccountRequest();
    request.setName(id);

    return await apiCallPromise(clientPromise, "getAccount", request);
  }

  static getPromise(id) {
    var request = new GetAccountRequest();
    request.setName(id);

    return new Promise((resolve, reject) => {
      apiCallPromise(clientPromise, "getAccount", request)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getAll(cb, firm = "", showDeleted = false) {
    var request = new ListAccountsRequest();
    request.setFirm(firm);
    if (showDeleted) {
      request.setShowDeleted(true);
    }
    ApiCall(client, "listAccounts", request, cb);
  }

  static listAccounts(listAccountsRequest, cb) {
    ApiCall(client, "listAccounts", listAccountsRequest, cb);
  }

  static async listAccountsAsync(listAccountsRequest) {
    return await apiCallPromise(clientPromise, "listAccounts", listAccountsRequest);
  }

  static post(account, cb) {
    var request = new CreateAccountRequest();
    request.setParent(account.getParentFirm());
    request.setAccount(account);
    ApiCall(client, "createAccount", request, cb);
  }

  static update(account, cb) {
    var request = new UpdateAccountRequest();
    request.setAccount(account);
    ApiCall(client, "updateAccount", request, cb);
  }

  static suspend(data, cb) {
    var request = new SetAccountSuspendStateRequest();
    request.setName(data.id);
    request.setIsSuspended(data.isSuspended);

    ApiCall(client, "setAccountSuspendState", request, cb);
  }

  static delete(id, cb) {
    var request = new DeleteAccountRequest();
    request.setName(id);

    ApiCall(client, "deleteAccount", request, cb);
  }

  static listParticipantsInAccount(groupId, cb) {
    var request = new ListParticipantsRequest();
    request.setAccount(groupId);

    ApiCall(client, "listParticipants", request, cb);
  }

  static listPositions(name, cb) {
    var request = new ListPositionsRequest();
    request.setAccountsList(name instanceof Array ? name : [name]);

    ApiCall(client, "listPositions", request, cb);
  }

  static listPendingSettlement(name, cb) {
    var request = new ListPendingSettlementRequest();
    request.setName(name);

    ApiCall(client, "listPendingSettlement", request, cb);
  }

  static getHistoricalPositions(name, time, cb) {
    var request = new GetHistoricalPositionsRequest();
    request.setAccount(name);
    let asOfTimeProto = time ? ProtobufParser.toTimestamp(time) : null;
    request.setAsOfTime(asOfTimeProto);

    ApiCall(client, "getHistoricalPositions", request, cb);
  }

  static downloadPositions(name, time, onSuccess, onError) {
    let positionsRequest = new GetHistoricalPositionsRequest();
    positionsRequest.setAccount(name);
    let asOfTimeProto = time ? ProtobufParser.toTimestamp(time) : null;
    positionsRequest.setAsOfTime(asOfTimeProto);

    let request = new DownloadPositionsRequest();
    request.setPositionsRequest(positionsRequest);

    var call = ApiCall(client, "downloadPositions", request); 
    var chunks = [];
    call.on("data", data => chunks.push(data.getFilechunk()));
    call.on("status", sts => {
      if (sts && sts.code === 0) onSuccess(chunks.join(""));
    });
    call.on("error", onError);
  }

  static adjustPosition(name, symbol, adjustment, symbolSubType, description, cb) {
    var adj = new PositionAdjustment();
    adj.setName(name);
    adj.setSymbol(symbol);
    adj.setDelta(adjustment);
    adj.setDescription(description);

    if (!!symbolSubType) adj.setSymbolSubType(symbolSubType);

    var request = new AdjustPositionRequest();
    request.setAdjustment(adj);

    ApiCall(client, "adjustPosition", request, cb);
  }

  static transferPosition(reqObj, cb) {
    const { entryType, allocationGroupId, referenceTradeIds, sourceAccountName, destinationAccount, symbol, subType, description, quantity, costPerUnit } = reqObj;
    var adj = new PositionAdjustment();
    adj.setSourceAccountName(sourceAccountName);
    adj.setName(destinationAccount);
    adj.setSymbol(symbol);
    adj.setSymbolSubType(subType);
    adj.setDelta(quantity);
    adj.setCostPerUnit(costPerUnit);
    adj.setDescription(description);
    adj.setEntryType(entryType);

    if (entryType === LedgerEntryType.LEDGER_ENTRY_TYPE_AVERAGE_PRICE_TRANSFER || entryType === LedgerEntryType.LEDGER_ENTRY_TYPE_GIVE_UP) {
      adj.setAllocationGroupId(allocationGroupId);
      adj.setReferenceTradeIdsList(referenceTradeIds)
    }

    var request = new AdjustPositionRequest();
    request.setAdjustment(adj);

    ApiCall(client, "adjustPosition", request, cb);
  }

  static setBalance(name, currency, balance, description, cb) {
    var request = new SetAccountBalanceRequest();
    request.setName(name);
    request.setCurrency(currency);
    request.setBalance(balance);
    request.setDescription(description);

    ApiCall(client, "setAccountBalance", request, cb);
  }

  static adjustBalance(name, currency, delta, description, cb) {
    var request = new AdjustAccountBalanceRequest();
    request.setName(name);
    request.setCurrency(currency);
    request.setDelta(delta);
    request.setDescription(description);

    ApiCall(client, "adjustAccountBalance", request, cb);
  }

  static approvePendingWithdrawal(id, cb) {
    var request = new ApprovePendingWithdrawalRequest();
    request.setId(id);

    ApiCall(client, "approvePendingWithdrawal", request, cb);
  }

  static deletePendingWithdrawal(id, desc, cb) {
    var request = new DeletePendingWithdrawalRequest();
    request.setId(id);
    request.setDescription(desc);

    ApiCall(client, "deletePendingWithdrawal", request, cb);
  }

  static createPendingWithdrawal(name, currency, balance, description, securityId, bankRecipient, cb) {
    var request = new CreatePendingWithdrawalRequest();
    request.setName(name);
    request.setCurrency(currency);
    request.setBalance(!isNullOrUndefined(balance) && !isEmptyString(balance) ? balance : "0");
    request.setDescription(description);
    request.setSecurityId(securityId);

    let bankDetails = new BankDetails();
    bankDetails.setBankId(bankRecipient.bankId);
    bankDetails.setBankIdType(bankRecipient.bankIdType);
    bankDetails.setCashAccountNumber(bankRecipient.cashAccountNumber);
    bankDetails.setSecuritiesAccountNumber(bankRecipient.securitiesAccountNumber);
    bankDetails.setBeneficiaryName(bankRecipient.beneficiaryName);
    bankDetails.setBeneficiaryAddress1(bankRecipient.beneficiaryAddress1);
    bankDetails.setBeneficiaryAddress2(bankRecipient.beneficiaryAddress2);
    bankDetails.setBeneficiaryCity(bankRecipient.beneficiaryCity);
    bankDetails.setBeneficiaryState(bankRecipient.beneficiaryState);
    bankDetails.setBeneficiaryPostalCode(bankRecipient.beneficiaryPostalCode);
    bankDetails.setBeneficiaryCountryCode(bankRecipient.beneficiaryCountryCode);
    bankDetails.setBankName(bankRecipient.bankName);
    bankDetails.setBankAddress1(bankRecipient.bankAddress1);
    bankDetails.setBankAddress2(bankRecipient.bankAddress2);
    bankDetails.setBankCity(bankRecipient.bankCity);
    bankDetails.setBankState(bankRecipient.bankState);
    bankDetails.setBankPostalCode(bankRecipient.bankPostalCode);
    bankDetails.setBankCountryCode(bankRecipient.bankCountryCode);
    request.setBankRecipient(bankDetails);

    ApiCall(client, "createPendingWithdrawal", request, cb);
  }

  static setAccountSecurityMarketValue(account, currency, securityId, marketValue, haircut, description, cb) {
    var request = new SetAccountSecurityMarketValueRequest();
    request.setName(account);
    request.setCurrency(currency);
    request.setSecurityId(securityId);
    request.setMarketValue(marketValue);
    if (!!haircut) request.setHaircut(haircut);
    request.setDescription(description);

    ApiCall(client, "setAccountSecurityMarketValue", request, cb);
  }


  static setAccountSecurityBalance(account, currency, securityId, balance, description, cb) {
    let request = new SetAccountSecurityBalanceRequest();
    request.setName(account);
    request.setCurrency(currency);
    request.setSecurityId(securityId);
    request.setBalance(balance);
    request.setDescription(description);

    ApiCall(client, "setAccountSecurityBalance", request, cb);
  }

  static setCapitalRequirement(name, currency, capitalRequirement, description, alternateCurrency, cb) {
    var request = new SetAccountCapitalRequirementRequest();
    request.setName(name);
    request.setCurrency(currency);
    request.setCapitalRequirement(capitalRequirement);
    request.setDescription(description);
    request.setAlternateCapitalRequirementCurrency(alternateCurrency);

    ApiCall(client, "setAccountCapitalRequirement", request, cb);
  }

  static setSecurityDefinition(securityId, description, securityType, cb) {
    var request = new SetSecurityDefinitionRequest();
    var def = new SecurityDefinition();
    def.setSecurityId(securityId);
    def.setDescription(description);
    def.setSecurityType(securityType)
    request.setDefinition(def);

    ApiCall(client, "setSecurityDefinition", request, cb);
  }

  static listSecurityDefinitions(cb) {
    var request = new ListSecurityDefinitionsRequest();
    ApiCall(client, "listSecurityDefinitions", request, cb);
  }

  static listBalances(name, cb) {
    var request = new ListAccountBalancesRequest();
    request.setName(name);

    ApiCall(client, "listAccountBalances", request, cb);
  }

  static listPendingWithdrawals(name, cb) {
    var request = new ListPendingWithdrawalsRequest();
    request.setName(name);

    ApiCall(client, "listPendingWithdrawals", request, cb);
  }

  static getParticipants(id, cb) {
    var request = new ListParticipantsRequest();
    request.setAccount(id);

    ApiCall(client, "listParticipants", request, cb);
  }

  static listParticipants(reqObj, cb) {
    var request = new ListParticipantsRequest();
    if (!!reqObj.account) request.setAccount(reqObj.account);
    if (!!reqObj.role) request.setRole(reqObj.role);
    if (!!reqObj.firm) request.setFirm(reqObj.firm);

    ApiCall(client, "listParticipants", request, cb);
  }

  static addParticipants(id, participantIds, customerOrderCapacities, cb) {
    var request = new AddUserToAccountRequest();
    request.setUsersList(participantIds);
    request.setAccount(id);
    request.setCustomerOrderCapacitiesList(customerOrderCapacities);

    ApiCall(client, "addUserToAccount", request, cb);
  }

  static removeParticipant(id, participantIds, cb) {
    var request = new RemoveUserFromAccountRequest();
    request.setUsersList(participantIds);
    request.setAccount(id);

    ApiCall(client, "removeUserFromAccount", request, cb);
  }

  static undelete(name, cb) {
    var request = new UndeleteAccountRequest();
    request.setName(name);

    ApiCall(client, "undeleteAccount", request, cb);
  }

  static getLicense(cb) {
    let req = new GetLicenseRequest();
    ApiCall(client, "getLicense", req, cb);
  }

  static getAccountBalanceLedger(req, cb) {
    ApiCall(client, "getAccountBalanceLedger", req, cb);
  }

  static downloadBalanceLedger(req, onSuccess, onError) {
    let request = new DownloadBalanceLedgerRequest();
    request.setBalanceLedgerRequest(req);

    var call = ApiCall(client, "downloadBalanceLedger", request); 
    var chunks = [];
    call.on("data", data => chunks.push(data.getFilechunk()));
    call.on("status", sts => {
      if (sts && sts.code === 0) onSuccess(chunks.join(""));
    });
    call.on("error", onError);
  }

  static createPendingCredit(name, currency, balance, description, correlationId, cb) {
    let req = new CreatePendingCreditRequest();
    req.setName(name);
    req.setCurrency(currency);
    req.setBalance(balance);
    req.setDescription(description);
    req.setCorrelationId(correlationId);

    ApiCall(client, "createPendingCredit", req, cb);
  }

  static listPendingCredits(account, correlationId, cb) {
    let req = new ListPendingCreditsRequest();
    req.setName(account);

    if (!!correlationId) req.setCorrelationId(correlationId)

    ApiCall(client, "listPendingCredits", req, cb);
  }

  static approvePendingCredits(id, correlationId, cb) {
    let req = new ApprovePendingCreditsRequest();
    if (!!id) req.setId(id)
    if (!!correlationId) req.setCorrelationId(correlationId)

    ApiCall(client, "approvePendingCredits", req, cb);
  }

  static deletePendingCredits(id, correlationId, cb) {
    let req = new DeletePendingCreditsRequest();
    if (!!id) req.setId(id)
    if (!!correlationId) req.setCorrelationId(correlationId)

    ApiCall(client, "deletePendingCredits", req, cb);
  }

  static getPositionLedger(req, cb) {
    ApiCall(client, "getPositionLedger", req, cb);
  }

  static downloadPositionLedger(req, onSuccess, onError) {
    let request = new DownloadPositionLedgerRequest();
    request.setPositionLedgerRequest(req);

    var call = ApiCall(client, "downloadPositionLedger", request); 
    var chunks = [];
    call.on("data", data => chunks.push(data.getFilechunk()));
    call.on("status", sts => {
      if (sts && sts.code === 0) onSuccess(chunks.join(""));
    });
    call.on("error", onError);
  }

  static setAssociatedAccounts(req, cb) {
    ApiCall(client, "setAssociatedAccounts", req, cb);
  }

  static getAssociatedAccounts(req, cb) {
    ApiCall(client, "getAssociatedAccounts", req, cb);
  }
}
