

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  getTradeCapture
} from '../actions/tradesActions';
import './Content.css';
import Loader from '../components/core/loader/Loader';
import { AdminDocumentTitle } from '../constants/strings';
import TradeFormContainer from '../containers/TradeFormContainer';

function mapStateToProps(state) {
  return {
    processingData: state.trades.processingData
  };
}

const mapDispatchToProps = (dispatch) => ({
  getTradeCapture: (tradeId) => {
    dispatch(getTradeCapture(tradeId))
  }
});

class TimeSalesTradeFormPage extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    //this.props.cleanTrade();
    const id = this.props.match.params.tradecaptureid;
    if (id) {
      this.props.getTradeCapture(id);
    }
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="with-callback">
        <Loader show={this.props.processingData}></Loader>
        <TradeFormContainer></TradeFormContainer>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeSalesTradeFormPage));