import ApiCall from "../modules/apiCall";
import { apiUrl } from "../modules/util";
const { AlertAPIClient } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/alert/v1beta1/alert_api_grpc_web_pb.js")
const { AdminAPIClient } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");

const alertClient = new AlertAPIClient(apiUrl(), null, null);
const adminClient = new AdminAPIClient(apiUrl(), null, null);

export default class AlertService {

  static raiseAlert(raiseAlertRequest, cb) {
    ApiCall(alertClient, "raiseAlert", raiseAlertRequest, cb);
  }

  static updateAlert(updateAlertRequest, cb) {
    ApiCall(alertClient, "updateAlert", updateAlertRequest, cb);
  }

  static updateAlertNotes(updateAlertNotesRequest, cb) {
    ApiCall(alertClient, "updateAlertNotes", updateAlertNotesRequest, cb);
  }

  static listAlerts(listAlertsRequest, cb) {
    ApiCall(alertClient, "listAlerts", listAlertsRequest, cb)
  }

  static downloadAlerts(downloadAlertsRequest, onSuccess, onError) {
    var call = ApiCall(adminClient, "downloadAlerts", downloadAlertsRequest);
    var chunks = [];
    call.on("data", data => chunks.push(data.getFilechunk()));
    call.on("status", sts => {
      if (sts && sts.code === 0) onSuccess(chunks.join(""));
    });
    call.on("error", onError);
  }
}