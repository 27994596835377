import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  FetchFirmStart,
  FetchFirmSucceed,
  FetchFirmFailed,
  FetchFirmServicesStart,
  FetchFirmServicesSucceed,
  FetchFirmServicesFailed,
  FetchFirmServiceKeysStart,
  FetchFirmServiceKeysSucceed,
  FetchFirmServiceKeysFailed,
  CleanFirm,
} from "../actions/firms";
import "./Content.css";
import Loader from "../components/core/loader/Loader";
import ParticipantHeader from "../components/participant/ParticipantHeader";
import Notification from "../modules/notifications";
import Firm from "../entities/Firm";
import Participant from "../entities/Participant";
import ServiceKey from "../entities/ServiceKey";
import UserAttributes from "../entities/UserAttributes"
import FirmFormContainer from "../containers/FirmFormContainer";
import { AdminDocumentTitle } from "../constants/strings";
import * as firmService from "../services/FirmService";
import { getHelmet } from "../modules/util";
import ParticipantService from "../services/ParticipantService";

function mapStateToProps(state) {
  return {
    fetchingFirms: state.firms.fetchingFirms,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchFirmStart: (firm) => {
    dispatch(FetchFirmStart(firm));
  },
  fetchFirmSucceed: (firm) => {
    dispatch(FetchFirmSucceed(firm));
  },
  fetchFirmFailed: (firm) => {
    dispatch(FetchFirmFailed(firm));
  },
  fetchFirmServicesStart: (services) => {
    dispatch(FetchFirmServicesStart(services));
  },
  fetchFirmServicesSucceed: (services) => {
    dispatch(FetchFirmServicesSucceed(services));
  },
  fetchFirmServicesFailed: (services) => {
    dispatch(FetchFirmServicesFailed(services));
  },
  fetchFirmServiceKeysStart: (keys) => {
    dispatch(FetchFirmServiceKeysStart(keys));
  },
  fetchFirmServiceKeysSucceed: (keys) => {
    dispatch(FetchFirmServiceKeysSucceed(keys));
  },
  fetchFirmServiceKeysFailed: (keys) => {
    dispatch(FetchFirmServiceKeysFailed(keys));
  },
  cleanFirm: () => {
    dispatch(CleanFirm());
  },
});

class FirmFormPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  state = {
    id: null,
    firmType: null,
  };

  static tryGetJsonString(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    const id = this.props.match.params.id;
    const firmType = this.props.match.params.firmType;
    if (id !== undefined) {
      const decodedId = decodeURIComponent(id);
      this.loadFirm(decodedId);
      this.loadFirmServices(decodedId);
    }
    if (firmType) {
      this.setState({ firmType: firmType });
    }
    window.scrollTo(0, 0);
  }

  loadFirm = (id) => {
    return new Promise((resolve, reject) => {
      this.props.fetchFirmStart();
      const cb = (err, response) => {
        if (response) {
          const firm = new Firm(response.getFirm());
          this.props.fetchFirmSucceed(firm);
          resolve(firm);
        }
        if (err) {
          this.props.fetchFirmFailed();
          Notification.error(`Cannot get firm information for id ${id}`);
          reject();
        }
      };

      firmService.get(id, cb);
    });
  };

  loadFirmServices = (id) => {
    return new Promise((resolve, reject) => {
      this.props.fetchFirmServicesStart();
      const cb = (err, response) => {
        if (response) {
          const services = response
            .getParticipantsList()
            .map((val) => new Participant(val));
          this.props.fetchFirmServicesSucceed(services);

          if (services && services.length > 0) {
            const srvcAttribs = services.map((srvc) => ParticipantService.getAttributes(srvc.name));

            Promise.all(srvcAttribs).then((values) => {
              values.forEach((svcAttrib, idx) => {
                const attribs = new UserAttributes(svcAttrib.getAttributes());
                services[idx].attributes = attribs;
              });

              this.props.fetchFirmServicesSucceed(services);
            });
          }

          resolve(services);
        }
        if (err) {
          this.props.fetchFirmServicesFailed();
          Notification.error(`Cannot get firm services for id ${id}`);
          reject();
        }
      };

      firmService.getServices(id, cb);
    });
  };

  loadFirmServiceKeys = (id) => {
    return new Promise((resolve, reject) => {
      this.props.fetchFirmServiceKeysStart();
      const cb = (err, response) => {
        if (response) {
          const keys = response.getKeysList().map((val) => new ServiceKey(val));
          this.props.fetchFirmServiceKeysSucceed(keys);
          resolve(keys);
        }
        if (err) {
          this.props.fetchFirmServiceKeysFailed();
          Notification.error(`Cannot get firm service keys for id ${id}`);
          reject();
        }
      };

      firmService.getServiceKeys(id, cb);
    });
  };

  openCreateFirm = () => {
    this.context.router.history.push(`/firms/new`);
  };

  render() {
    let { firmType } = this.state;
    const { id, firmServiceId } = this.props.match.params;
    return (
      <div className="with-callback">
        <ParticipantHeader
          headerText={getHelmet({
            id: id,
            firmServiceId: firmServiceId,
          })}
          buttonText="Add New Firm"
          buttonClick={() => {
            this.openCreateFirm();
          }}
          isFirmSelected={true}
        />
        <Loader show={this.props.fetchingFirm}></Loader>
        <FirmFormContainer
          decodedId={id}
          loadFirm={this.loadFirm}
          loadFirmServices={this.loadFirmServices}
          loadFirmServiceKeys={this.loadFirmServiceKeys}
          firmType={firmType}
          params={this.props.match.params}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FirmFormPage)
);
