import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormLabel
} from 'react-bootstrap';

import './TotalNotionalLimit.css';

const TotalNotionalLimitReadOnly = ({
  lowLimitValue,
  highLimitValue,
  setLowLimit,
  setHighLimit,
}) => {
  var lowLimit = lowLimitValue || 0;
  var highLimit = highLimitValue || 0;
  return (
    <FormGroup id="">
      <FormLabel>Total Notional Limits</FormLabel>
      <div>
        <div className="form-readonly-label total-notional-limit-readonly-field">{lowLimit}</div>
        <div className="form-readonly-label total-notional-limit-readonly-field">{highLimit}</div>
      </div>
      <div>
        <div className="total-notional-limit-readonly-field-text">Low Limit {setLowLimit ? '' : 'Not'} Set</div>
        <div className="total-notional-limit-readonly-field-text">High Limit {setHighLimit ? '' : 'Not'} Set</div>
      </div>
    </FormGroup>
  )
}

TotalNotionalLimitReadOnly.propTypes = {
  lowLimitValue: PropTypes.number,
  highLimitValue: PropTypes.number,
  setLowLimit: PropTypes.bool,
  setHighLimit: PropTypes.bool,
  priceIncrement: PropTypes.number,
  minTradeQty: PropTypes.number,
}

export default TotalNotionalLimitReadOnly;