import React from 'react';

const NoMatch = () => (
  <div className="content-login-box">
    <div className="login-box">
      <h1 className="title">WHOOPS!</h1>
      <div className="content">
        <h3>The page you requested does not exist.</h3>
      </div>
    </div>
  </div>
);

export default NoMatch;
