import {
    FETCH_ACCOUNTS_START,
    FETCH_ACCOUNTS_SUCCEED,
    FETCH_ACCOUNTS_FAILED,
    FETCH_POSITIONS_START,
    FETCH_POSITIONS_SUCCEED,
    FETCH_POSITIONS_FAILED,
    FETCH_PENDING_SETTLEMENTS_START,
    FETCH_PENDING_SETTLEMENTS_FAILED,
    FETCH_PENDING_SETTLEMENTS_SUCCEED,
    FETCH_BALANCES_START,
    FETCH_BALANCES_SUCCEED,
    FETCH_BALANCES_FAILED,
    FETCH_WITHDRAWALS_START,
    FETCH_WITHDRAWALS_SUCCEED,
    FETCH_WITHDRAWALS_FAILED,
    FETCH_ACCOUNT_START,
    FETCH_ACCOUNT_SUCCEED,
    FETCH_ACCOUNT_FAILED,
    CLEAN_ACCOUNT,
    SAVE_ACCOUNT_START,
    SAVE_ACCOUNT_SUCCEED,
    SAVE_ACCOUNT_FAILED,
    CREATE_ACCOUNT_STATE,
    FETCH_PARTICIPANTS_LIST_START,
    FETCH_PARTICIPANTS_LIST_SUCCEED,
    FETCH_PARTICIPANTS_LIST_FAILED,
    CLEAN_PARTICIPANTS_LIST_GROUP,
    TOGGLE_SHOW_DELETED_ACCOUNTS,
    GET_USER_LICENSE_SUCCEED,
    GET_USER_LICENSE_FAILED,
    FETCH_SECURITIES_SUCCEED,
    FETCH_SECURITIES_START,
    FETCH_SECURITIES_FAILED,
    FETCH_PENDING_CREDITS_START,
    FETCH_PENDING_CREDITS_SUCCEED,
    FETCH_PENDING_CREDITS_FAILED,
    FETCH_POSITION_LEDGER_START,
    FETCH_POSITION_LEDGER_SUCCEED,
    FETCH_POSITION_LEDGER_FAILED,
    FETCH_BALANCE_LEDGER_START,
    FETCH_BALANCE_LEDGER_SUCCEED,
    FETCH_BALANCE_LEDGER_FAILED,
    FETCH_ASSOCIATED_ACCOUNTS_START,
    FETCH_ASSOCIATED_ACCOUNTS_SUCCEED,
    FETCH_ASSOCIATED_ACCOUNTS_FAILED,
} from '../constants/accountTypes';
import { RESET } from '../constants/appTypes';
import AccountFormItem from '../entities/dto/AccountFormItem';

const initialState = {
    fetchingSecurities: false,
    fetchingAccounts: false,
    fetchingAccount: false,
    fetchingPositions: false,
    fetchingPendingSettlements: false,
    fetchingBalances: false,
    fetchingWithdrawals: false,
    fetchingParticipantsList: false,
    savingAccount: false,
    isCreating: false,
    accounts: [],
    positions: [],
    pendingSettlements: [],
    balances: [],
    withdrawals: [],
    participantsList: [],
    account: new AccountFormItem(),
    showDeletedAccounts: false,
    securities: {},
    positionLedger: {
        ledgerList: [],
        pageToken: "",
        nextPageToken: "",
        pageTokens: [],
    },
    balanceLedger: {
        ledgerList: [],
        pageToken: "",
        nextPageToken: "",
        pageTokens: [],
    },
    associatedAccounts: {
        fetchingAssociatedAccounts: false,
        associatedAccounts: [],
    },
}

const Accounts = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACCOUNTS_START:
            return {
                ...state,
                fetchingAccounts: true
            };
        case FETCH_ACCOUNTS_SUCCEED:
            return {
                ...state,
                fetchingAccounts: false,
                accounts: action.payload
            };
        case FETCH_ACCOUNTS_FAILED:
            return {
                ...state,
                fetchingAccounts: false,
                accounts: []
            };
        case FETCH_POSITIONS_START:
            return {
                ...state,
                fetchingPositions: true
            };
        case FETCH_POSITIONS_SUCCEED:
            return {
                ...state,
                fetchingPositions: false,
                positions: action.payload
            };
        case FETCH_POSITIONS_FAILED:
            return {
                ...state,
                fetchingPositions: false,
                positions: []
            };
        case FETCH_PENDING_SETTLEMENTS_START:
            return {
                ...state,
                fetchingPendingSettlements: true
            };
        case FETCH_PENDING_SETTLEMENTS_SUCCEED:
            return {
                ...state,
                fetchingPendingSettlements: false,
                pendingSettlements: action.payload
            };
        case FETCH_PENDING_SETTLEMENTS_FAILED:
            return {
                ...state,
                fetchingPendingSettlements: false,
                pendingSettlements: []
            };
        case FETCH_BALANCES_START:
            return {
                ...state,
                fetchingBalances: true
            };
        case FETCH_BALANCES_SUCCEED:
            return {
                ...state,
                fetchingBalances: false,
                balances: action.payload
            };
        case FETCH_BALANCES_FAILED:
            return {
                ...state,
                fetchingBalances: false,
                balances: []
            };
        case FETCH_POSITION_LEDGER_START:
            return {
                ...state,
                fetchingPositionLedger: true
            };
        case FETCH_POSITION_LEDGER_SUCCEED:
            let pageTokens = { ...state.positionLedger.pageTokens };
            pageTokens[action.payload.pageToken] = action.payload.nextPageToken;
            return {
                ...state,
                fetchingPositionLedger: false,
                positionLedger: {
                    ledgerList: action.payload.ledgerList,
                    pageToken: action.payload.pageToken,
                    nextPageToken: action.payload.nextPageToken,
                    pageTokens: pageTokens,
                }
            };
        case FETCH_POSITION_LEDGER_FAILED:
            return {
                ...state,
                fetchingPositionLedger: false,
                positionLedger: {
                    ledgerList: [],
                    pageToken: "",
                    nextPageToken: "",
                    pageTokens: [],
                }
            };
        case FETCH_BALANCE_LEDGER_START:
            return {
                ...state,
                fetchingBalanceLedger: true
            };
        case FETCH_BALANCE_LEDGER_SUCCEED: {
            let pageTokens = { ...state.balanceLedger.pageTokens };
            pageTokens[action.payload.pageToken] = action.payload.nextPageToken;
            return {
                ...state,
                fetchingBalanceLedger: false,
                balanceLedger: {
                    ledgerList: action.payload.balanceLender.ledgerList,
                    pageToken: action.payload.pageToken,
                    nextPageToken: action.payload.nextPageToken,
                    pageTokens: pageTokens,
                }
            };
        }
        case FETCH_BALANCE_LEDGER_FAILED:
            return {
                ...state,
                fetchingBalanceLedger: false,
                balanceLedger: {
                    ledgerList: [],
                    pageToken: "",
                    nextPageToken: "",
                    pageTokens: [],
                }
            };
        case FETCH_WITHDRAWALS_START:
            return {
                ...state,
                fetchingWithdrawals: true
            };
        case FETCH_WITHDRAWALS_SUCCEED:
            return {
                ...state,
                fetchingWithdrawals: false,
                withdrawals: action.payload
            };
        case FETCH_WITHDRAWALS_FAILED:
            return {
                ...state,
                fetchingWithdrawals: false,
                withdrawals: []
            };
        case FETCH_PENDING_CREDITS_START:
            return {
                ...state,
                fetchingPendingCredits: true
            };
        case FETCH_PENDING_CREDITS_SUCCEED:
            return {
                ...state,
                fetchingPendingCredits: false,
                pendingCredits: action.payload
            };
        case FETCH_PENDING_CREDITS_FAILED:
            return {
                ...state,
                fetchingPendingCredits: false,
                pendingCredits: []
            };
        case RESET:
            state = Object.assign({}, state, initialState);
            return state;
        case FETCH_ACCOUNT_START:
            return {
                ...state,
                fetchingAccount: true
            };
        case FETCH_ACCOUNT_SUCCEED:
            return {
                ...state,
                fetchingAccount: false,
                account: action.payload
            };
        case FETCH_ACCOUNT_FAILED:
            return {
                ...state,
                fetchingAccount: false
            };
        case CLEAN_ACCOUNT:
            return {
                ...state,
                account: new AccountFormItem()
            };
        case SAVE_ACCOUNT_START:
            return {
                ...state,
                savingAccount: true
            };
        case SAVE_ACCOUNT_SUCCEED:
            return {
                ...state,
                savingAccount: false
            };
        case SAVE_ACCOUNT_FAILED:
            return {
                ...state,
                savingAccount: false
            };
        case CREATE_ACCOUNT_STATE:
            return {
                ...state,
                isCreating: action.value
            };
        case FETCH_PARTICIPANTS_LIST_START:
            return {
                ...state,
                fetchingParticipantsList: true,
                participantsList: []
            };
        case FETCH_PARTICIPANTS_LIST_SUCCEED:
            return {
                ...state,
                fetchingParticipantsList: false,
                participantsList: action.payload
            };
        case FETCH_PARTICIPANTS_LIST_FAILED:
            return {
                ...state,
                fetchingParticipantsList: false,
                participantsList: []
            };
        case CLEAN_PARTICIPANTS_LIST_GROUP:
            return {
                ...state,
                participantsList: []
            };
        case TOGGLE_SHOW_DELETED_ACCOUNTS:
            return {
                ...state,
                showDeletedAccounts: !state.showDeletedAccounts,
            };
        case GET_USER_LICENSE_SUCCEED:
            return {
                ...state,
                userLicense: action.payload
            };
        case GET_USER_LICENSE_FAILED:
            return {
                ...state,
                userLicenseErr: action.payload
            };
        case FETCH_SECURITIES_START:
            return {
                ...state,
                fetchingSecurities: true
            };
        case FETCH_SECURITIES_SUCCEED:
            return {
                ...state,
                fetchingSecurities: false,
                securities: action.payload
            };
        case FETCH_SECURITIES_FAILED:
            return {
                ...state,
                fetchingSecurities: false,
                securities: {}
            };
        case FETCH_ASSOCIATED_ACCOUNTS_START:
            return {
                ...state,
                fetchingAssociatedAccounts: true
            };
        case FETCH_ASSOCIATED_ACCOUNTS_SUCCEED:
            return {
                ...state,
                associatedAccounts: {
                    fetchingAssociatedAccounts: false,
                    associatedAccounts: action.payload.associatedAccounts,
                }
            };
        case FETCH_ASSOCIATED_ACCOUNTS_FAILED:
            return {
                ...state,
                fetchingAssociatedAccounts: false,
                associatedAccounts: [],
            };
        default:
            return state;
    }
}

export default Accounts;
