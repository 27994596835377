import React, { useState } from "react";
import { connect } from "react-redux";
import Collapse from 'react-bootstrap/Collapse'
import { Row, Col } from "react-bootstrap";
import DateGroup from "../../../core/form/DateGroup";
import DateTimeParser from "../../../../modules/dateTimeParser";
import FieldGroup from "../../../core/form/FieldGroup";
import MultiSelectDropdownListGroup from "../../../core/form/MultiSelectDropdownListGroup"
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import { createZeroCouponInflationSwapAtts,setZeroCouponInflationSwapAtts } from "../../../../modules/instrumentUtils";
import { makeDropdownOptions, SELECTED_DEFAULT } from "../../instrumentFormUtils";
import DateAdjustmentService from '../../../../services/DateAdjustmentService';
import { FormModes } from "../../../../constants/enumerations"
import _ from 'lodash';
import ButtonMain from "../../../core/form/ButtonMain";
import CheckboxGroup from "../../../core/form/CheckboxGroup";

const DateCalculatorZCIS = ({
  values,
  isEditing,
  touched,
  setFieldValue,
  setFieldError,
  handleBlur,
  handleChange,
  enableStartDate,
  calculateDates,
  errors,
  mode
}) => {
  const onDateCalculator = (values, setFieldValue, setFieldError, calculateDates) => {

    const successHandler = (attributes) => {
      let atts = {};

      setZeroCouponInflationSwapAtts(attributes, atts);

      _.map(atts, (val, key) => {
        setFieldValue(key, val);
      });
    }

    const handleError = err => {
      setFieldError('dateCalculator', err);
    }

    if (values.startDate) {
      let atts = createZeroCouponInflationSwapAtts(values);
      calculateDates(handleError, successHandler, atts, values.startDate);
    } else {
      setFieldError('dateCalculator', "Trade Date missing");
    }
  }

  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Date Calculator</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>

      <Collapse in={open}>
        <div className="collapse-content">
          <h6>Swap Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="tenor" name="tenor" type="textarea" label="Tenor" isRequired={true}
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.tenor}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="sideConvention" name="sideConvention" label="Side Convention"
                value={values.sideConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("sideConvention", e.id)}
                data={InstrumentStaticData.SideConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="swapEffectiveDate" name="swapEffectiveDate" label="Swap Effective Date" isRequired={true}
                value={values.swapEffectiveDate ? new Date(values.swapEffectiveDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(values.isPartialEditable, isEditing, values.startDate)}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue('swapEffectiveDate', e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="forwardStartingPeriodTimePeriod"
                name="forwardStartingPeriodTimePeriod"
                label="Forward Starting Period Time Period"
                value={values.forwardStartingPeriodTimePeriod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("forwardStartingPeriodTimePeriod", e.id)
                }
                data={InstrumentStaticData.TimePeriodOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="tenorTimePeriod"
                name="tenorTimePeriod"
                label="Tenor Time Period"
                value={values.tenorTimePeriod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("tenorTimePeriod", e.id)}
                data={InstrumentStaticData.TimePeriodOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="indexSource"
                name="indexSource"
                label="Index Source"
                value={values.indexSource}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>{
                  setFieldValue("indexSource", e)
                }}
                data={InstrumentStaticData.IndexSourceList}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup
                id="forwardStartingPeriod"
                name="forwardStartingPeriod"
                type="textarea"
                label="Forward Starting Period"
                setFieldValue={setFieldValue}
                value={values.forwardStartingPeriod}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup id="rollAndPaymentDateHolidayCalendarsList" name="rollAndPaymentDateHolidayCalendarsList" label="Roll and Payment Date Holiday Calendar List"
                labelWrapperClass="label-wrapper"
                value={values.rollAndPaymentDateHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("rollAndPaymentDateHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"} valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
          <Col lg={3} xs={3} md={3}>
              <FieldGroup
                id="inflationLag"
                name="inflationLag"
                type="textarea"
                label="Inflation Lag"
                setFieldValue={setFieldValue}
                value={values.inflationLag}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="unadjustedEffectiveDate"
                name="unadjustedEffectiveDate"
                label="Unadjusted Effective Date"
                value={values.unadjustedEffectiveDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) =>
                  setFieldValue("unadjustedEffectiveDate", e)
                }
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="effectiveDateOffset" name="effectiveDateOffset" type="textarea" label="Swap Effective Date Offset" isRequired={true}
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.effectiveDateOffset}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="effectiveDateBusinessDayConvention" name="effectiveDateBusinessDayConvention" label="Effective Date Business Day Convention"
                value={values.effectiveDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("effectiveDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
                />
            </Col>
          <Row>

          </Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup
                id="interpolationMethod"
                name="interpolationMethod"
                type="textarea"
                label="Interpolation Method"
                setFieldValue={setFieldValue}
                value={values.interpolationMethod}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="maturityDate" name="maturityDate" label="Maturity Date" isRequired={true}
                value={values.maturityDate ? new Date(values.maturityDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(values.isPartialEditable, isEditing, values.startDate)}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue('maturityDate', e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="unadjustedMaturityDate"
                name="unadjustedMaturityDate"
                label="Unadjusted Maturity Date"
                value={values.unadjustedMaturityDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) =>
                  setFieldValue("unadjustedMaturityDate", e)
                }
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="maturityDateBusinessDayConvention" name="maturityDateBusinessDayConvention" label="Maturity Date Business Day Convention"
                value={values.maturityDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("maturityDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup
                id="inflationInstrumentName"
                name="inflationInstrumentName"
                type="textarea"
                label="Inflation Instrument Name"
                setFieldValue={setFieldValue}
                value={values.inflationInstrumentName}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3} style={{alignSelf:"center"}}>
              <CheckboxGroup
                checked={values.fallbackBondApplicable}
                onChange={(e) => {
                  setFieldValue("fallbackBondApplicable", e.target.checked)
                }}
                label="Fallback Bond Applicable"
                disabled={isViewing}
              />
            </Col>
          </Row>
          {!isViewing &&
            <Row>
              <Col lg={9} xs={9} md={3}></Col>
              <Col lg={3} xs={3} md={3}>
                <ButtonMain type="button" text="Calculate Dates" onClick={() => onDateCalculator(values, setFieldValue, setFieldError, calculateDates)}
                />
                {errors && errors.dateCalculator && (
                  <div className="form-error mt-2">
                    <i className="fa fa-exclamation-triangle orange-icon" aria-hidden="true"></i>{" "}
                    {errors.dateCalculator}
                  </div>
                )}
              </Col>
            </Row>
          }
          <hr />
          <h6>Fixed Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedPaymentFrequency"
                name="fixedPaymentFrequency"
                label="Fixed Payment Frequency"
                value={values.fixedPaymentFrequency}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("fixedPaymentFrequency", e.id)
                }
                data={InstrumentStaticData.FixedPaymentFrequency}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedRollConvention"
                name="fixedRollConvention"
                label="Fixed Roll Convention"
                isRequired={true}
                value={values.fixedRollConvention || SELECTED_DEFAULT}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("fixedRollConvention", e)}
                data={InstrumentStaticData.FixedRollConventionList}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedPaymentDateRelativeTo"
                name="fixedPaymentDateRelativeTo"
                label="Fixed Payment Date Relative To"
                value={values.fixedPaymentDateRelativeTo}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) =>
                  setFieldValue("fixedPaymentDateRelativeTo", e.id)
                }
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="fixedFirstUnadjustedPaymentDate"
                name="fixedFirstUnadjustedPaymentDate"
                label="Fixed First Unadjusted Payment Date"
                value={values.fixedFirstUnadjustedPaymentDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) =>
                  setFieldValue("fixedFirstUnadjustedPaymentDate", e)
                }
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedDayCountConvention"
                name="fixedDayCountConvention"
                label="Float Day Count Convention"
                value={values.fixedDayCountConvention}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) =>
                  setFieldValue("fixedDayCountConvention", e.id)
                }
                data={InstrumentStaticData.DayCountConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedCompoundingMethod"
                name="fixedCompoundingMethod"
                label="Fixed Compounding Method"
                value={values.fixedCompoundingMethod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("fixedCompoundingMethod", e.id)
                }
                data={InstrumentStaticData.CompoundingOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedBusinessDayConvention"
                name="fixedBusinessDayConvention"
                label="Fixed Business Day Convention"
                value={values.fixedBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("fixedBusinessDayConvention", e.id)
                }
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
                />
            </Col>
          </Row>
          <hr />
          <h6>Float Input</h6>
          <Row>
            
            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup
                id="floatHolidayCalendarsList"
                name="floatHolidayCalendarsList"
                label="Float Holiday Calendars"
                labelWrapperClass="label-wrapper"
                value={values.floatHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("floatHolidayCalendarsList", e)
                }
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"}
                valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatPaymentFrequency"
                name="floatPaymentFrequency"
                label="Float Payment Frequency"
                value={values.floatPaymentFrequency}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("floatPaymentFrequency", e.id)
                }
                data={InstrumentStaticData.FloatPaymentFrequency}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatingRateIndex"
                name="floatingRateIndex"
                label="Floating Rate Index"
                value={values.floatingRateIndex}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatingRateIndex", e)}
                data={InstrumentStaticData.FloatingRateIndexList}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="floatFirstUnadjustedPaymentDate"
                name="floatFirstUnadjustedPaymentDate"
                label="Float First Unadjusted Payment Date"
                value={values.floatFirstUnadjustedPaymentDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) =>
                  setFieldValue("floatFirstUnadjustedPaymentDate", e)
                }
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatRollConvention"
                name="floatRollConvention"
                label="Float Roll Convention"
                isRequired={true}
                value={values.floatRollConvention || SELECTED_DEFAULT}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("floatRollConvention", e)}
                data={makeDropdownOptions(
                  InstrumentStaticData.FixedRollConventionList,
                  false
                )}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatPaymentDateRelativeTo"
                name="floatPaymentDateRelativeTo"
                label="Float Payment Date Relative To"
                value={values.floatPaymentDateRelativeTo}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) =>
                  setFieldValue("floatPaymentDateRelativeTo", e.id)
                }
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatBusinessDayConvention"
                name="floatBusinessDayConvention"
                label="Float Business Day Convention"
                value={values.floatBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("floatBusinessDayConvention", e.id)
                }
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
                />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatDayCountConvention"
                name="floatDayCountConvention"
                label="Float Day Count Convention"
                value={values.floatDayCountConvention}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) =>
                  setFieldValue("floatDayCountConvention", e.id)
                }
                data={InstrumentStaticData.DayCountConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
                />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatCompoundingMethod"
                name="floatCompoundingMethod"
                label="Calculation Period Compounding Method"
                value={values.floatCompoundingMethod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("floatCompoundingMethod", e.id)
                }
                data={InstrumentStaticData.CompoundingOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) => ({
  calculateDates: (handleError, successHandler, atts, startDate) => {
    dispatch(DateAdjustmentService.adjustDates(handleError, successHandler, atts, startDate));
  }
});

export default connect(null, mapDispatchToProps)(DateCalculatorZCIS);

export const ValidateDateCalculatorZCIS = (values, errors) => {
  if (!values.unadjustedEffectiveDate || !values.unadjustedMaturityDate || !values.floatFirstUnadjustedPaymentDate || !values.maturityDate || !values.fixedFirstUnadjustedPaymentDate || !values.swapEffectiveDate) {
    errors["dateCalculator"] = "You must calculate dates";
  }
  return errors;
}