import {
  BLOCK_TRADE_USERS_UPDATE,
  BLOCK_TRADE_BROKER_USERS_UPDATE,
} from '../constants/blockTradeTypes';

export const blockTradeUsersUpdate = (payload) => {
  return (dispatch) => {
    dispatch({
      type: BLOCK_TRADE_USERS_UPDATE,
      payload: payload,
    })
  }
}

export const blockTradeBrokerUsersUpdate = (payload) => {
  return (dispatch) => {
    dispatch({
      type: BLOCK_TRADE_BROKER_USERS_UPDATE,
      payload: payload,
    })
  }
}
