import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import FieldGroup from "../core/form/FieldGroup";
import DateGroup from "../core/form/DateGroup";
import { withFormik } from "formik";
import ButtonBorderless from "../core/form/ButtonBorderless";
import ButtonMain from "../core/form/ButtonMain";
import * as Yup from "yup";

import { resetBooks, getUnaggregatedBooks } from "../../actions/books";
import { validScaledPrice } from "../../modules/util";
import { hasWriteAccess } from "../../services/TokenService";
import CheckboxGroup from '.././core/form/CheckboxGroup';

function mapStateToProps(state) {
  return {
    instrument: state.instruments.instrument,
    firms: state.firms.firms,
    books: state.books,
  };
}

function tryGetJsonString(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetBooks: () => dispatch(resetBooks()),
    fetchBook: (symbol) => {
      dispatch(getUnaggregatedBooks(symbol))
    }
  }
}

export class BasicInstrumentMarketStats extends Component {
  openSetTime = "";
  closeSetTime = "";
  highSetTime = "";
  lowSetTime = "";
  lastTradeSetTime = "";
  notionalSetTime = "";
  indicativeOpenSetTime = "";
  settlementSetTime = "";
  openInterestSetTime = "";

  static contextTypes = {
    router: PropTypes.object,
  };


  componentDidMount() {
    // eslint-disable-next-line no-restricted-globals
    let urlParts = location.pathname.split("/");
    let symbol = urlParts[urlParts.length - 2];
    this.props.resetBooks();

    const decodedInstId = decodeURIComponent(symbol);
    if (tryGetJsonString(decodedInstId) === null) {
      this.props.fetchBook(decodedInstId);
    }
  }

  intiBooksStats = (books) => {
    if (books && books.books && books.books.stats) {
      this.openSetTime = books.books.stats.openSetTime;
      this.closeSetTime = books.books.stats.closeSetTime;
      this.highSetTime = books.books.stats.highSetTime;
      this.lowSetTime = books.books.stats.lowSetTime;
      this.lastTradeSetTime = books.books.stats.lastTradeSetTime;
      this.notionalSetTime = books.books.stats.notionalSetTime;
      this.indicativeOpenSetTime = books.books.stats.indicativeOpenSetTime;
      this.settlementSetTime = books.books.stats.settlementSetTime;
      this.openInterestSetTime = books.books.stats.openInterestSetTime
    }
  }

  render() {
    const { instrument, setFieldValue, values, handleChange, errors, touched, handleBlur, handleSubmit, books } = this.props;
    this.intiBooksStats(books);
    return (
      <form onSubmit={handleSubmit} id="instrument-stats-form">
        <Row>
          <Col lg={6} xs={6} md={6}>
            <h1>Instrument Market Stats</h1>
          </Col>
          <Col lg={6} xs={6} md={6}>
            <div style={{ float: "right" }}>
              <ButtonMain
                text="Historical Market Stats"
                type="button"
                onClick={() => {this.context.router.history.push(`/instruments/${encodeURIComponent(this.props.instrument.id)}/historicalMarketStats`) }}
              />
            </div>
          </Col>
        </Row>
        <Container>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Instrument ID (Symbol)"
                value={instrument.id}
              />
            </Col>
            <Col lg={6} xs={6} md={6}>
              <FieldGroupReadOnly
                label="Description"
                value={instrument.description}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() && this.props.instrument.nonTradable ?
                <FieldGroup id="open" name="open" type="text" label="Open Price"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.open}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="open" name="open" type="text" label="Open Price" value={values.open}></FieldGroupReadOnly>
              }
            </Col>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() && this.props.instrument.nonTradable ?
                <FieldGroup id="indicativeOpen" name="indicativeOpen" type="text" label="Indicative Open Price"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.indicativeOpen}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="indicativeOpen" name="indicativeOpen" type="text" label="Indicative Open Price" value={values.indicativeOpen}></FieldGroupReadOnly>
              }
            </Col>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() && this.props.instrument.nonTradable ?
                <FieldGroup id="high" name="high" type="text" label="High Price"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.high}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="high" name="high" type="text" label="High Price" value={values.high}></FieldGroupReadOnly>
              }
            </Col>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() && this.props.instrument.nonTradable ?
                <FieldGroup id="low" name="low" type="text" label="Low Price"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.low}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="low" name="low" type="text" label="Low Price" value={values.low}></FieldGroupReadOnly>
              }
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() && this.props.instrument.nonTradable ?
                <FieldGroup id="close" name="close" type="text" label="Close Price"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.close}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="close" name="close" type="text" label="Close Price" value={values.close}></FieldGroupReadOnly>
              }
            </Col>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() && this.props.instrument.nonTradable ?
                <FieldGroup id="lastPrice" name="lastPrice" type="text" label="Last Price"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.lastPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="lastPrice" name="lastPrice" type="text" label="Last Price" value={values.lastPrice}></FieldGroupReadOnly>
              }
            </Col>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() && this.props.instrument.nonTradable ?
                <FieldGroup id="lastQuantity" name="lastQuantity" type="text" label="Last Quantity"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.lastQuantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="lastQuantity" name="lastQuantity" type="text" label="Last Quantity" value={values.lastQuantity}></FieldGroupReadOnly>
              }
            </Col>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() && this.props.instrument.nonTradable ?
                <FieldGroup id="notionalTraded" name="notionalTraded" type="text" label="Total Notional Volume"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.notionalTraded}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="notionalTraded" name="notionalTraded" type="text" label="Total Notional Volume" value={values.notionalTraded}></FieldGroupReadOnly>
              }
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() && this.props.instrument.nonTradable ?
                <FieldGroup id="sharesTraded" name="sharesTraded" type="text" label="Total Shares Traded"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.sharesTraded}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="sharesTraded" name="sharesTraded" type="text" label="Total Shares Traded" value={values.sharesTraded}></FieldGroupReadOnly>
              }
            </Col>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() ?
                <FieldGroup id="settlementPrice" name="settlementPrice" type="text" label="Settlement Price"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.settlementPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="settlementPrice" name="settlementPrice" type="text" label="Settlement Price" value={values.settlementPrice}></FieldGroupReadOnly>
              }
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly id="settlementPriceCalculationMethod" name="settlementPriceCalculationMethod" type="text" label="Settlement Calculation Method" value={values.settlementPriceCalculationMethod}></FieldGroupReadOnly>
            </Col>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() ?
                <CheckboxGroup
                  checked={values.settlementPreliminary}
                  onChange={(e) => {
                    setFieldValue('settlementPreliminary', e.target.checked)
                  }}
                  label="Settlement Preliminary"
                  labelPlacementAbove={true}
                  tickStyle={{marginLeft:"10px"}}
                /> :
                <FieldGroupReadOnly id="settlementPreliminary" name="settlementPreliminary" type="text" label="Settlement Preliminary" value={values.settlementPreliminary}></FieldGroupReadOnly>
              }
            </Col>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() ?
                <FieldGroup id="openInterest" name="openInterest" type="text" label="Open Interest"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  value={values.openInterest}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> :
                <FieldGroupReadOnly id="openInterest" name="openInterest" type="text" label="Open Interest" value={values.openInterest}></FieldGroupReadOnly>
              }
            </Col>
            <Col lg={3} xs={3} md={3}>
              {hasWriteAccess() ?
                <DateGroup id="transactTime" name="transactTime" label="Transact Time"
                  value={values.transactTime ? new Date(values.transactTime) : null}
                  errors={errors}
                  dropUp={true}
                  touched={touched}
                  onChange={(e) => setFieldValue("transactTime", e)}
                  onBlur={handleBlur}
                  enableTime={true}
                />
                :
                <FieldGroupReadOnly id="transactTime" name="transactTime" type="text" label="Transact Time" value={values.transactTime}></FieldGroupReadOnly>
              }
            </Col>
          </Row>


          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Open Update Time" value={this.openSetTime} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Indicative Open Update Time" value={this.indicativeOpenSetTime} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="High Update Time" value={this.highSetTime} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Low Update Time" value={this.lowSetTime} />
            </Col>
          </Row>

          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Close Update Time" value={this.closeSetTime} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Last Trade Update Time" value={this.lastTradeSetTime} />
            </Col>
          </Row>

          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Notional Volume Update Time" value={this.notionalSetTime} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Settlement Update Time" value={this.settlementSetTime} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Open Interest Update Time" value={this.openInterestSetTime} />
            </Col>
          </Row>


          <Row>
            <Col lg={5} xs={5} md={5}>
              {errors['form'] && (
                <div class="form-error"><i class="fa fa-exclamation-triangle orange-icon" aria-hidden="true"></i> {errors['form']}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={1} xs={1} md={1}>
              {hasWriteAccess() ?
                <ButtonMain type="submit" text="SAVE" /> : null
              }
            </Col>

            <Col lg={1} xs={1} md={1}>
              <ButtonBorderless type="button" text={hasWriteAccess() ? "Cancel" : "Back"} icon={hasWriteAccess() ? "times-circle" : "chevron-left"}
                customClassName="grey-icon"
                onClick={(e) => {
                  e.preventDefault();
                  const history = this.context.router.history;
                  if (history.length > 1) {
                    history.goBack();
                  } else {
                    history.push("/instruments");
                  }
                }}
              />
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

const InstrumentMarketStats = withFormik({
  mapPropsToValues: (props) => {
    let values = {
      low: null,
      high: null,
      open: null,
      indicativeOpen: null,
      close: null,
      lastPrice: null,
      lastQuantity: null,
      settlementPrice: null,
      sharesTraded: null,
      notionalTraded: null,
      openInterest: null,
      transactTime: null,
      settlementPreliminary: false,
      settlementPriceCalculationMethod: '',
    }

    if (props.books && props.books.books && props.books.books.stats) {
      let stats = props.books.books.stats;
      if (!!stats.lowSetTime) values.low = stats.lowPx;
      if (!!stats.highSetTime) values.high = stats.highPx;
      if (!!stats.openSetTime) values.open = stats.openPx;
      if (!!stats.indicativeOpenSetTime) values.indicativeOpen = stats.indicativeOpenPx;
      if (!!stats.closeSetTime) values.close = stats.closePx;
      if (!!stats.lastTradeSetTime) values.lastPrice = stats.lastTradePx;
      if (!!stats.lastTradeSetTime) values.lastQuantity = stats.lastTradeQty;
      if (!!stats.settlementSetTime) values.settlementPrice = stats.settlementPx;
      if (!!stats.settlementPreliminary) values.settlementPreliminary = stats.settlementPreliminary;
      if (!!stats.settlementPriceCalculationMethod) values.settlementPriceCalculationMethod = stats.settlementPriceCalculationMethod;
      values.sharesTraded = stats.sharesTraded;
      values.notionalTraded = stats.notionalTraded;
      values.openInterest = stats.openInterest;
      if (!!props.books.books.status) values.transactTime = props.books.books.status.transactTime;
    }
    return values;
  },

  validationSchema: Yup.object().shape({
    open: Yup.number()
      .typeError('Open Price must be a number')
      .nullable(),
    indicativeOpen: Yup.number()
      .typeError('Indicative Open Price must be a number')
      .nullable(),
    notionalTraded: Yup.number()
      .typeError('Total Notional volume must be a number')
      .nullable(),
    sharesTraded: Yup.number()
      .typeError('Total Shares traded must be a number')
      .nullable(),
    low: Yup.number()
      .typeError('Low Price must be a number')
      .nullable(),
    high: Yup.number()
      .typeError('High Price must be a number')
      .nullable(),
    close: Yup.number()
      .typeError('Close Price must be a number')
      .nullable(),
    lastPrice: Yup.number()
      .typeError('Last Price must be a number')
      .nullable(),
    lastQuantity: Yup.number()
      .typeError('Last Quantity must be a number')
      .nullable(),
    settlementPrice: Yup.number()
      .typeError('Settlement Price must be a number')
      .nullable(),
    openInterest: Yup.number()
      .typeError('Open Interest must be a number')
      .nullable(),
  }),

  validate: (values, props) => {
    let errors = {};
    let validateField = function (name, label, scale) {
      let value = values[name];

      if (value && value.trim() !== "" && !isNaN(value) && !validScaledPrice(value, scale)) {
        errors[name] = label + " scale is not valid"
      }
    }

    const pxScale = props.instrument.priceScale !== 0 ? props.instrument.priceScale : 1;
    const qtyScale = props.instrument.fractionalQtyScale !== 0 ? props.instrument.fractionalQtyScale : 1;
    validateField("high", `High price`, pxScale);
    validateField("low", `Low price`, pxScale);
    validateField("open", `Open price`, pxScale);
    validateField("indicativeOpen", `Indicative Open price`, pxScale);
    validateField("close", `Close price`, pxScale);
    validateField("lastPrice", `Last price`, pxScale);
    validateField("lastQuantity", `Last quantity`, qtyScale);
    validateField("settlementPrice", `Settlement price`, pxScale);
    validateField("notionalTraded", `Total Notional volume`, pxScale * qtyScale);
    validateField("sharesTraded", `Total Shares traded`, qtyScale);
    validateField("openInterest", `Open interest`, qtyScale);
    return errors;
  },

  handleSubmit: (values, { props, setFieldError }) => {
    props.onSetInstrumentStats(props.instrument.id, values, setFieldError);
  },
  enableReinitialize: true

})(BasicInstrumentMarketStats);

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentMarketStats);
