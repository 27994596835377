import { store } from "../store";
import { getAccessToken, getRefreshToken } from "../services/TokenService";
import { ACCESS_TKN_REFRESH_SUCCESS } from "../constants/authTypes";
import { replace } from "connected-react-router";
import { RESET } from "../constants/appTypes";
import Notification from "./notifications";
import {apiUrl} from "./util";

const {
  RefreshAccessTokenRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_pb.js");

const {
  BasicAuthAPIPromiseClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_grpc_web_pb.js");

const auth_client = new BasicAuthAPIPromiseClient(
  apiUrl(),
  null,
  null
);

const apiCallPromise = (client, name, request) => {
  return new Promise((resolve, reject) => {
    client[name](request, { authorization: getAccessToken() })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error) {
          if (error.code === 16) {
            const refreshReq = new RefreshAccessTokenRequest();
            refreshReq.setRefreshToken(getRefreshToken());
            auth_client
              .refreshAccessToken(refreshReq)
              .then((response) => {
                var accessToken = response.getAccessToken();
                store.dispatch({
                  type: ACCESS_TKN_REFRESH_SUCCESS,
                  payload: { accessToken },
                });

                client[name](request, { authorization: getAccessToken() }).then(
                  (response) => {
                    resolve(response);
                  }
                );
              })
              .catch((error) => {
                store.dispatch({ type: RESET });
                store.dispatch(replace("/"));
                Notification.error(
                  "Your credentials have expired. You have been logged out."
                );
                return reject(error);
              });
          } else {
            return reject(error);
          }
        }
      });
  });
};

export default apiCallPromise;
