const { Firm } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb.js");

export default class FirmMapper {
  static mapToFirmUpdate(data) {
    var firm = new Firm();
    firm.setId(data.id);
    firm.setDisplayName(data.displayName);
    firm.setName(data.name);
    firm.setApplyCommissionsAsSpread(!!data.applyCommissionsAsSpread);
    firm.setSkipCommissionBalanceAdjustment(!!data.skipCommissionBalanceAdjustment);

    if (!!data.aliases && Array.isArray(data.aliases) && data.aliases.length > 0) {
      firm.clearAliasesMap();
      data.aliases.forEach(alias => {
        firm.getAliasesMap().set(alias.option, alias.alias);
      })
    }

    return firm;
  }
}
