import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import { validateField, getOptionText, NUMBER_TYPE, DATE_TYPE, STRING_TYPE, ARRAY_TYPE } from '../../instrumentFormUtils'
import DateTimeParser from "../../../../modules/dateTimeParser";
import FieldGroup from "../../../core/form/FieldGroup";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import { FormModes } from "../../../../constants/enumerations";

export const SwapInfoZCIS = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  errors,
  mode
}) => {
  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;


  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Swap Information</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Tenor" value={values.tenor} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Side Convention" value={getOptionText(InstrumentStaticData.SideConventionOptions, values.sideConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Swap Effective Date" value={ values.swapEffectiveDate ? DateTimeParser.toDisplay(values.swapEffectiveDate) : null } />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                  id="forwardStartingPeriodTimePeriod"
                  name="forwardStartingPeriodTimePeriod"
                  label="Forward Starting Period Time Period"
                  value={values.forwardStartingPeriodTimePeriod}
                  enabled={values.isEditable}
                  errors={errors}
                  onChange={(e) => setFieldValue("forwardStartingPeriodTimePeriod", e.id)}
                  data={InstrumentStaticData.TimePeriodOptions}
                  textField="name"
                  valueField="id"
                  plaintext={isViewing}
                  />
            </Col>           
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                  id="tenorTimePeriod"
                  name="tenorTimePeriod"
                  label="Tenor Time Period"
                  value={values.tenorTimePeriod}
                  enabled={values.isEditable}
                  errors={errors}
                  onChange={(e) => setFieldValue("tenorTimePeriod", e.id)}
                  data={InstrumentStaticData.TimePeriodOptions}
                  textField="name"
                  valueField="id"
                  plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Index Source" value={values.indexSource} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="forwardStartingPeriod" name="forwardStartingPeriod" type="textarea" label="Forward Starting Period"
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.forwardStartingPeriod}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("forwardStartingPeriod", e.target.value)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly id="id" label="Roll and Payment Date Holiday Calendars" value={values.rollAndPaymentDateHolidayCalendarsList ? values.rollAndPaymentDateHolidayCalendarsList.join(", ") : ""} />
            </Col>
          </Row>
          <Row>  
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Inflation Lag" value={values.inflationLag} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Unadjusted Effective Date" value={ values.unadjustedEffectiveDate ? DateTimeParser.toDisplay(values.unadjustedEffectiveDate) : null }  />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Effective Date Offset" value={values.effectiveDateOffset} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Effective Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.effectiveDateBusinessDayConvention)} />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Interpolation Method" value={values.interpolationMethod} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Maturity Date" value={ values.maturityDate ? DateTimeParser.toDisplay(values.maturityDate) : null } />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Unadjusted Maturity Date" value={values.unadjustedMaturityDate ? DateTimeParser.toDisplay(values.unadjustedMaturityDate) : null } />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Maturity Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.maturityDateBusinessDayConvention)} />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Inflation Instrument Name" value={values.inflationInstrumentName} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Fallback Bond Applicable" value={values.fallbackBondApplicable + ""} />
            </Col>
          </Row>
        </div>
      </Collapse>

    </React.Fragment>
  )
}

export const ValidateSwapInfoZCIS = (values, errors) => {
  errors = validateField(values, errors, "tenor", "Tenor", NUMBER_TYPE);
  errors = validateField(values, errors,  "sideConvention", "Side Convention", STRING_TYPE, values);
  errors = validateField(values, errors,  "swapEffectiveDate", "Swap Effective Date", DATE_TYPE);
  errors = validateField(values, errors,  "tenorTimePeriod", "Tenor Time Period", NUMBER_TYPE);
  errors = validateField(values, errors,  "forwardStartingPeriod", "Forward Starting Period", NUMBER_TYPE);
  errors = validateField(values, errors,  "forwardStartingPeriodTimePeriod", "Forward Starting Period Time Period", NUMBER_TYPE);
  errors = validateField(values, errors,  "rollAndPaymentDateHolidayCalendarsList", "Roll and Payment Date Holiday Calendars", ARRAY_TYPE);
  errors = validateField(values, errors,  "inflationLag", "Inflation Lag", STRING_TYPE);
  errors = validateField(values, errors,  "unadjustedEffectiveDate", "Unadjusted Effective Date", DATE_TYPE);
  errors = validateField(values, errors,  "effectiveDateOffset", "Effective Date Offset", NUMBER_TYPE);
  errors = validateField(values, errors,  "effectiveDateBusinessDayConvention", "Effective Date Business Day Convention", NUMBER_TYPE);
  errors = validateField(values, errors,  "maturityDate", "Maturity Date", DATE_TYPE);
  errors = validateField(values, errors,  "unadjustedMaturityDate", "Unadjusted Maturity Date", DATE_TYPE);
  errors = validateField(values, errors,  "indexSource", "Index Source", STRING_TYPE);
  errors = validateField(values, errors,  "interpolationMethod", "Interpolation Method", STRING_TYPE);
  // errors = validateField(values, errors,  "fallbackBondApplicable", "Fallback Bond Applicable", STRING_TYPE);
  errors = validateField(values, errors,  "inflationLag", "Inflation Instrument Name", STRING_TYPE);
  return validateField(values, errors,  "maturityDateBusinessDayConvention", "Maturity Date Business Day Convention", NUMBER_TYPE);
}