import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from '../components/core/loader/Loader';
import AdminRequestsDataGrid from "../components/admin-requests/AdminRequestsDataGrid";
import {
  loadAdminRequests,
  updateAdminRequest,
} from "../actions/adminRequests";
import AdminRequestsFilter from "../components/admin-requests/AdminRequestsFilter";
import AdminRequestsStaticData from "../modules/adminRequestsStaticData";
import { ListAdminRequest } from "../entities/AdminRequest";
import PaginationWithPageSize, { withPageToken } from "../components/core/data-grid/PaginationWithPageSize";
import querystring from 'query-string';
import PropTypes from 'prop-types';
import { TitleBreadcrumb } from "../components/core/title-breadcrumb/title-breadcrumb";

const STORAGE_PAGE_SIZE_KEY = "adminRequestsGridPageSize";
const DEFAULT_PAGE_SIZE = 10;

function mapStateToProps(state) {
  const { nextPageToken, pageToken, pageTokens } = state.adminRequests;

  return {
    fetchingAdminRequest: state.adminRequests.fetchingAdminRequest,
    adminRequests: state.adminRequests.requests,
    nextPageToken,
    pageToken,
    pageTokens
  };
}

const mapDispatchToProps = (dispatch) => ({
  loadAdminRequests: (listAdminRequestsRequest, cb) => {
    dispatch(loadAdminRequests(listAdminRequestsRequest, cb))
  },
  updateAdminRequest: (id, approve, cb) => {
    dispatch(updateAdminRequest(id, approve, cb))
  }
});

class AdminRequestsPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.statusOptions = AdminRequestsStaticData.AdminRequestStatus([])

    const state = querystring.parseUrl(window.location.href);

    if (Object.keys(state.query).length === 0) {
      this.state = { ...this.initState }
    } else {
      let status = state.query.statusesList ? this.statusOptions.filter(status => state.query.statusesList.includes(status.id.toString())) : []

      this.state = {
        filter: {
          statusesList: status,
          requestName: state.query.requestName,
          requestUser: state.query.requestUser,
          startTime: state.query.startTime ? new Date(state.query.startTime) : null,
          endTime: state.query.endTime ? new Date(state.query.endTime) : null,
        }
      }
    }
  }

  initState = {
    filter: {
      pageSize: null,
      pageToken: null,
      requestName: null,
      requestUser: null,
      startTime: null,
      endTime: null,
      statusesList: [AdminRequestsStaticData.AdminRequestStatus([])[0]],
    }
  }

  componentDidMount() {
    this.applyFilter(this.state.filter);
    window.scrollTo(0, 0);
  }

  setPageSizeInStorage = (pageSize) => {
    window.localStorage.setItem(STORAGE_PAGE_SIZE_KEY, pageSize)
  }

  getPageSizeFromStorage = () => {
    const pageSize = window.localStorage.getItem(STORAGE_PAGE_SIZE_KEY)

    if (pageSize)
      return Number(pageSize);

    return Number(DEFAULT_PAGE_SIZE);
  }

  onPageSizeChange = (pageSize) => {
    this.setPageSizeInStorage(pageSize);
    this.setState({ pageSize });
    const pageToken = "";

    let adminReq = new ListAdminRequest();

    if (!!this.state.adminReq)
      adminReq = this.state.adminReq;

    adminReq.pageToken = pageToken;
    adminReq.pageSize = pageSize;

    const protoReq = adminReq.toProto();

    this.props.loadAdminRequests(protoReq);
  }

  onPageChange = (pageToken) => {
    let adminReq = new ListAdminRequest();

    if (!!this.state.adminReq)
      adminReq = this.state.adminReq;

    adminReq.pageToken = pageToken;
    adminReq.pageSize = this.getPageSizeFromStorage();

    const protoReq = adminReq.toProto();

    this.props.loadAdminRequests(protoReq);
  }

  getPaginationComponent = () => {
    const pageToken = this.props.pageToken;
    const tokens = this.props.pageTokens;
    const pageSize = this.getPageSizeFromStorage();
    return withPageToken(PaginationWithPageSize, { pageToken, tokens }, pageSize);
  }

  applyFilter = (filter) => {
    const pageSize = this.getPageSizeFromStorage();

    let adminReq = new ListAdminRequest();
    adminReq.pageSize = pageSize;
    adminReq.pageToken = "";

    if (!!filter.statusesList) adminReq.statusesList = filter.statusesList.map(item => item.id);
    if (!!filter.requestName) adminReq.requestName = filter.requestName;
    if (!!filter.requestUser) adminReq.requestUser = filter.requestUser;
    if (!!filter.startTime) adminReq.startTime = filter.startTime;
    if (!!filter.endTime) adminReq.endTime = filter.endTime;

    const protoReq = adminReq.toProto();

    this.setState({ adminReq: adminReq });

    this.props.loadAdminRequests(protoReq);
  }

  render() {
    return (
      <div className="with-callback">
        <TitleBreadcrumb titles={[{'title':'Admin Requests', link:`${window.location.origin}/admin-requests`} ]} />
        <AdminRequestsFilter onFilter={this.applyFilter} filter={this.state.filter} statusOptions={this.statusOptions} router={this.context.router} />
        <Loader show={this.props.fetchingAdminRequest}></Loader>
        <AdminRequestsDataGrid
          data={this.props.adminRequests}
          onUpdate={this.props.updateAdminRequest}
          onPageChange={this.onPageChange}
          onPageSizeChange={this.onPageSizeChange}
          paginationComponent={this.getPaginationComponent()}
          onRefreshData={() => this.applyFilter(this.state.filter)}
        ></AdminRequestsDataGrid>
      </div>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminRequestsPage);