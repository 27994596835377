import { isNullOrUndefined } from "./util";

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  if (isNullOrUndefined(base64Url)) return {};

  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const getRole = (accessToken) => {
  if (isNullOrUndefined(accessToken)) return null;

  const token = parseJwt(accessToken);
  return token.role;
}

export const AuthUtil = {
  parseJwt,
  getRole
};