import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import PriceLimit from "../../price-limit/PriceLimit";
import OrderLimit from "../../order-limit/OrderLimit";
import PriceLimitReadOnly from "../../../components/price-limit/PriceLimitReadOnly";
import OrderLimitReadOnly from "../../../components/order-limit/OrderLimitReadOnly";
import RelativePriceLimit from "../../relative-price-limit/RelativePriceLimit";
import RelativePriceLimitReadOnly from "../../../components/relative-price-limit/RelativePriceLimitReadOnly";
import * as Yup from "yup";
import { FormModes } from "../../../constants/enumerations";
import TotalNotionalLimitReadOnly from "../../total-notional-limit/TotalNotionalLimitReadOnly";
import TotalNotionalLimit from "../../total-notional-limit/TotalNotionalLimit";
import ErrorLabel from "../../core/form/ErrorLabel";
import { isNumber } from "../../../modules/util";
import CheckboxGroup from "../../core/form/CheckboxGroup";
import * as toolTips from '../../../constants/instrumentToolTip'

export const AdditionalDetails = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  handleChange,
  errors,
  mode
}) => {

  const [open, setOpen] = useState(true);
  const isViewing = mode === FormModes.view;

  const showErrorIfAnyRequiredFieldMissing = () => {
    const errPresent = !!errors["lowPriceLimit"] || !!errors["highPriceLimit"];

    if (errPresent && !open) {
      setOpen(errPresent);
    }

    return errPresent;
  }

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={8} xs={8} md={8}>
            <h6><strong>Risk Settings</strong></h6>
          </Col>
          <Col lg={3} xs={3} md={3}>
            {showErrorIfAnyRequiredFieldMissing() && <ErrorLabel text={"Required field(s) missing"} />}
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>

      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            {!isViewing && values.isPartialEditable && !values.nonTradable && (
              <React.Fragment>
                <Col lg={3} xs={3} md={3}>
                  <PriceLimit
                    lowPrice={values.lowPriceLimit}
                    highPrice={values.highPriceLimit}
                    setLow={values.setLowPriceLimit}
                    setHigh={values.setHighPriceLimit}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSetLow={(checked) => { setFieldValue("setLowPriceLimit", checked); }}
                    onSetHigh={(checked) => { setFieldValue("setHighPriceLimit", checked); }}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <RelativePriceLimit
                    lowPrice={values.relativeLow}
                    highPrice={values.relativeHigh}
                    setLow={values.relativeLowSet}
                    setHigh={values.relativeHighSet}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSetLow={(checked) => { setFieldValue("relativeLowSet", checked); }}
                    onSetHigh={(checked) => { setFieldValue("relativeHighSet", checked); }}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <OrderLimit
                    lowOrder={values.lowOrderLimit}
                    highOrder={values.highOrderLimit}
                    setLow={values.setLowOrderLimit}
                    setHigh={values.setHighOrderLimit}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSetLow={(checked) => { setFieldValue("setLowOrderLimit", checked); }}
                    onSetHigh={(checked) => { setFieldValue("setHighOrderLimit", checked); }}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <TotalNotionalLimit
                    lowTotalNotional={values.lowTotalNotionalLimit}
                    highTotalNotional={values.highTotalNotionalLimit}
                    setLowTotalNotional={values.setLowTotalNotionalLimit}
                    setHighTotalNotional={values.setHighTotalNotionalLimit}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSetLowTotalNotional={(checked) => { setFieldValue("setLowTotalNotionalLimit", checked); }}
                    onSetHighTotalNotional={(checked) => { setFieldValue("setHighTotalNotionalLimit", checked); }}
                  />
                </Col>
              </React.Fragment>
            )}

            {isViewing && (
              <React.Fragment>
                <Col lg={3} xs={3} md={3}>
                  <PriceLimitReadOnly
                    lowLimitValue={values.lowPriceLimit}
                    highLimitValue={values.highPriceLimit}
                    setLowLimit={values.setLowPriceLimit}
                    setHighLimit={values.setHighPriceLimit}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <RelativePriceLimitReadOnly
                    lowLimitValue={values.relativeLow}
                    highLimitValue={values.relativeHigh}
                    setLowLimit={values.relativeLowSet}
                    setHighLimit={values.relativeHighSet}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <OrderLimitReadOnly
                    lowLimitValue={Number(values.lowOrderLimit)}
                    highLimitValue={Number(values.highOrderLimit)}
                    setLowLimit={values.setLowOrderLimit}
                    setHighLimit={values.setHighOrderLimit}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <TotalNotionalLimitReadOnly
                    lowLimitValue={values.lowTotalNotionalLimit}
                    highLimitValue={values.highTotalNotionalLimit}
                    setLowLimit={values.setLowTotalNotionalLimit}
                    setHighLimit={values.setHighTotalNotionalLimit}
                  />
                </Col>
              </React.Fragment>
            )}
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <CheckboxGroup
                id={"chkSkipRiskAndCurrencyChecks"}
                checked={values.skipRiskAndCurrencyChecks}
                disabled={isViewing}
                onClick={(e) => {
                  setFieldValue("skipRiskAndCurrencyChecks", e.target.checked);
                }}
                label={"Skip Risk Checks and Currency Balance Updates"}
                tooltip={toolTips.SKIP_RISK_AND_CURRENCY_CHECKS}
                tooltipPlace={'right'}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

export const ValidateAdditionalDetails = (values, errors, instrumentType) => {
  if (values.setLowPriceLimit) {
    if (!isNumber(values.lowPriceLimit)) {
      errors.lowPriceLimit = "Low limit is required.";
    }

    if (values.setHighPriceLimit && isNumber(values.highPriceLimit) && Number(values.lowPriceLimit) >= Number(values.highPriceLimit)) {
      errors.lowPriceLimit = "Low limit must be less than High Limit.";
    }
  }

  if (values.setHighPriceLimit) {
    if (!isNumber(values.highPriceLimit)) {
      errors.highPriceLimit = "High limit is required.";
    } else if (Number(values.highPriceLimit) <= 0) {
      errors.highPriceLimit = "High limit must be greater than 0.";
    } else if (values.setLowPriceLimit && isNumber(values.lowPriceLimit) && Number(values.lowPriceLimit) >= Number(values.highPriceLimit)) {
      errors.highPriceLimit = "High limit must be greater than Low Limit.";
    }
  }

  return errors;
}

export const AdditionalDetailsSchemaValidation = () => {
  return {
    state: Yup.object().nullable().required("State is required"),
  }
}
