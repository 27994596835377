import { getEnumName, capitalizeWord } from "../../modules/util";
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import State from "./State";
import { INTEREST_RATE_SWAP, FORWARD_RATE_AGREEMENT_SWAP, FOREX, FUTURE, OPTION, MULTILEG, FORWARD_STARTING_INTEREST_RATE_SWAP, BASIS_SWAP, EVENT, OVER_NIGHT_INDEX_SWAP, SINGLE_PERIOD_SWAP, NON_DELIVERABLE_FORWARDS, ZERO_COUPON_INFLATION_SWAP } from "../../constants/strings";
import {
  setIRSAtts,
  setFRAAtts,
  setFutureAtts,
  setOptionAtts,
  setDefaultAtts,
  setMultilegAtts,
  setFsIRSAtts,
  setBasisAtts,
  setUIEventAtts,
  setOISAtts,
  setSpsAtts,
  setNDFAtts,
  setForexAtts,
  setZeroCouponInflationSwapAtts
} from "../../modules/instrumentUtils";

let state = new State();

export default class InstrumentFormItem {
  constructor(item) {
    if (item) {
      this.id = item.id;
      this.state = item.state;
      this.clearingHouse = item.clearingHouse;
      this.description = item.description;
      this.cfiCode = item.cfiCode;
      this.instrumentProductType = item.instrumentProductType;
      this.routingkey = item.routingkey;
      this.externalSymbol = item.externalSymbol;
      this.nonTradable = item.nonTradable;
      this.hideMarketData = item.hideMarketData;
      this.isScheduled = item.isScheduled;
      this.isDeletable = item.isDeletable;
      this.isEditable = item.isEditable;
      this.isPartialEditable = item.isPartialEditable;
      this.isStateEditable = item.isStateEditable;
      this.minimumPriceIncrement = item.minimumPriceIncrement;
      this.minimumTradeQuantity = item.minimumTradeQuantity;
      this.fractionalQtyScale = item.fractionalQtyScale;
      this.minimumUnaffiliatedFirms = item.minimumUnaffiliatedFirms;
      this.crossOrderRestingDuration = item.crossOrderRestingDuration;
      this.blockTradeThreshold = item.blockTradeThreshold;
      this.setBlockTradeThreshold = !!item.blockTradeThreshold ? Object.keys(item.blockTradeThreshold).length > 0 : false;
      this.multiplier = item.multiplier;
      this.tradeSettlementPeriod = item.tradeSettlementPeriod;
      this.holidayCalendars = item.holidayCalendars;
      this.basecurrency = item.basecurrency;
      this.startDate = item.startDate;
      this.lastTradeDate = item.lastTradeDate;
      this.expirationDate = item.expirationDate;
      this.terminationDate = item.terminationDate;

      this.lowPriceLimit = item.lowPriceLimit;
      this.highPriceLimit = item.highPriceLimit;
      this.setLowPriceLimit = item.setLowPriceLimit;
      this.setHighPriceLimit = item.setHighPriceLimit;

      this.lowOrderLimit = item.lowOrderLimit;
      this.highOrderLimit = item.highOrderLimit;
      this.setLowOrderLimit = item.setLowOrderLimit;
      this.setHighOrderLimit = item.setHighOrderLimit;

      this.lowTotalNotionalLimit = item.lowTotalNotionalLimit;
      this.highTotalNotionalLimit = item.highTotalNotionalLimit;
      this.setLowTotalNotionalLimit = item.setLowTotalNotionalLimit;
      this.setHighTotalNotionalLimit = item.setHighTotalNotionalLimit;
      this.skipRiskAndCurrencyChecks = item.skipRiskAndCurrencyChecks;

      this.relativeLow = item.relativeLow !== 0 ? item.relativeLow * 100 : null;
      this.relativeHigh =
        item.relativeHigh !== 0 ? item.relativeHigh * 100 : null;
      this.relativeLowSet = item.relativeLowSet;
      this.relativeHighSet = item.relativeHighSet;

      this.tradingScheduleList = Array.isArray(item.tradingScheduleList)
        ? item.tradingScheduleList
        : item.tradingSchedule;

      this.tradeDayRollSchedule = item.tradeDayRollSchedule;

      this.productId = item.productId;
      this.priceScale = item.priceScale;
      this.settlementPeriodStartOffsetFromCloseDuration = item.settlementPeriodStartOffsetFromCloseDuration;
      this.settlementPeriodEndOffsetFromCloseDuration = item.settlementPeriodEndOffsetFromCloseDuration;
      this.minimumTradeQtyForVwapSettlementCalculation = item.minimumTradeQtyForVwapSettlementCalculation;
      this.bufferFromEndOfSettlementPeriodDuration = item.bufferFromEndOfSettlementPeriodDuration;
      this.settlementPriceLogic = item.settlementPriceLogic;
      this.settlementPriceCalculationRequiresApproval = item.settlementPriceCalculationRequiresApproval;
      this.settlementPriceCalculationEndingMethod = item.settlementPriceCalculationEndingMethod;
      this.metadata = item.metadata;


      if (item.type === INTEREST_RATE_SWAP) setIRSAtts(item, this);
      else if (item.type === FORWARD_RATE_AGREEMENT_SWAP)
        setFRAAtts(item, this);
      else if (item.type === FORWARD_STARTING_INTEREST_RATE_SWAP)
        setFsIRSAtts(item, this);
      else if (item.type === FUTURE) setFutureAtts(item, this);
      else if (item.type === OPTION) setOptionAtts(item, this);
      else if (item.type === FOREX) setForexAtts(item, this);
      else if (item.type === MULTILEG) setMultilegAtts(item, this);
      else if (item.type === BASIS_SWAP) setBasisAtts(item, this);
      else if (item.type === ZERO_COUPON_INFLATION_SWAP) setZeroCouponInflationSwapAtts(item, this);
      else if (item.type === EVENT) {
        setUIEventAtts(item, this);
      } else if (item.type === OVER_NIGHT_INDEX_SWAP) {
        setOISAtts(item, this);
      } else if (item.type === SINGLE_PERIOD_SWAP) {
        setSpsAtts(item, this);
      } else if (item.type === NON_DELIVERABLE_FORWARDS) {
        setNDFAtts(item, this);
      } else {
        setDefaultAtts(this);
      }

      return;
    }

    this.id = null;
    state.id = InstrumentState.INSTRUMENT_STATE_PENDING;
    state.name = capitalizeWord(getEnumName(InstrumentState, state.id));
    this.clearingHouse = null;
    this.state = state;
    this.description = null;
    this.cfiCode = null;
    this.instrumentProductType = null;
    this.routingkey = null;
    this.nonTradable = false;
    this.hideMarketData = false;
    this.isScheduled = null;
    this.isDeletable = false;
    this.isEditable = true;
    this.isPartialEditable = true;
    this.isStateEditable = true;
    this.minimumPriceIncrement = null;
    this.minimumTradeQuantity = null;
    this.minimumUnaffiliatedFirms = null;
    this.crossOrderRestingDuration = null;
    this.blockTradeThreshold = {};
    this.setBlockTradeThreshold = false;
    this.multiplier = null;
    this.tradeSettlementPeriod = null;
    this.holidayCalendars = null;
    this.basecurrency = null;
    this.startDate = null;
    this.expirationDate = null;
    this.terminationDate = null;
    this.lowPriceLimit = null;
    this.highPriceLimit = null;
    this.setLowPriceLimit = null;
    this.setHighPriceLimit = null;
    this.lowOrderLimit = null;
    this.highOrderLimit = null;
    this.setLowOrderLimit = null;
    this.setHighOrderLimit = null;
    this.lowTotalNotionalLimit = null;
    this.highTotalNotionalLimit = null;
    this.setLowTotalNotionalLimit = null;
    this.setHighTotalNotionalLimit = null;
    this.tradingScheduleList = null;
    this.productId = null;
    this.priceScale = null;
    this.settlementPeriodStartOffsetFromCloseDuration = null;
    this.settlementPeriodEndOffsetFromCloseDuration = null;
    this.minimumTradeQtyForVwapSettlementCalculation = null;
    this.bufferFromEndOfSettlementPeriodDuration = null;
    this.settlementPriceLogic = null;
    this.settlementPriceCalculationRequiresApproval = false;
    this.settlementPriceCalculationEndingMethod = null;
    this.metadata = [];
  }
}
