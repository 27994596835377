import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

const Dialog = ({
  children,
  title,
  show,
  okButtonText,
  cancelButtonText,
  onOk,
  closeButton,
  onClose,
  dialogClassName,
}) => {
  return (
    <Modal show={!!show} onHide={onClose} dialogClassName={dialogClassName} size="lg">
      {(closeButton || title) && (
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {(okButtonText || cancelButtonText) && (
        <Modal.Footer>
          {okButtonText && (
            <Button onClick={onOk} className="btn-main">
              {okButtonText}
            </Button>
          )}
          {cancelButtonText && (
            <Button onClick={onClose} className="btn-borderless">
              {cancelButtonText}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

Dialog.propTypes = {
  title: PropTypes.string,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  closeButton: PropTypes.bool,
};

Dialog.defaultProps = {
  closeButton: true,
};

export default Dialog;