import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import { validateField, getOptionText, NUMBER_TYPE, DATE_TYPE } from '../../instrumentFormUtils'
import DateTimeParser from "../../../../modules/dateTimeParser";

export const SwapInfoBasis = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  errors,
  mode
}) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Swap Information</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Tenor" value={values.tenor} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Side Convention" value={getOptionText(InstrumentStaticData.SideConventionOptions, values.sideConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Effective Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.effectiveDateBusinessDayConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Maturity Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.maturityDateBusinessDayConvention)} />
            </Col>
          </Row>

          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Swap Effective Date" value={ values.swapEffectiveDate ? DateTimeParser.toDisplay(values.swapEffectiveDate) : null } />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Maturity Date" value={ values.maturityDate ? DateTimeParser.toDisplay(values.maturityDate) : null } />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Swap Effective Date Offset" value={values.effectiveDateOffset} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly id="id" label="Roll and Payment Date Holiday Calendar List" value={values.rollAndPaymentDateHolidayCalendarsList ? values.rollAndPaymentDateHolidayCalendarsList.join(", ") : ""} />
            </Col>
          </Row>
        </div>
      </Collapse>

    </React.Fragment>
  )
}

export const ValidateSwapInfoBasis = (values, errors) => {
  errors = validateField(values, errors, "tenor", "Tenor", NUMBER_TYPE);
  errors = validateField(values, errors,  "sideConvention", "Side Convention", NUMBER_TYPE, values);
  errors = validateField(values, errors,  "maturityDateBusinessDayConvention", "Maturity Date Business Day Convention", NUMBER_TYPE);
  errors = validateField(values, errors,  "effectiveDateBusinessDayConvention", "Effective Date Business Day Convention", NUMBER_TYPE);
  errors = validateField(values, errors,  "swapEffectiveDate", "Swap Effective Date", DATE_TYPE);
  errors = validateField(values, errors,  "maturityDate", "Swap Maturity Date", DATE_TYPE);
  return validateField(values, errors,  "effectiveDateOffset", "Swap Effective Date Offset", NUMBER_TYPE);
}