export default class AccountFormItem {
  constructor(item) {
    if (item) {
      this.id = item.id;
      this.name = item.name;
      this.displayName = item.displayName;
      this.accountType = item.accountType;
      this.isSuspended = item.isSuspended;
      this.parentFirm = item.parentFirm;
      this.associatedFirm = item.associatedFirm;
      this.riskSystem = item.riskSystem;
      this.collateralAccount = item.collateralAccount;
      this.priorityWeight = item.priorityWeight;
      this.beneficialOwnership = item.beneficialOwnership.map((be) => be);
      this.aliases = item.aliases.map((alias) => alias);
      this.whitelist = item.whitelist;
      const orderLimits = item.orderSizeLimit;
      this.lowOrderLimit = orderLimits ? orderLimits.low : null;
      this.highOrderLimit = orderLimits ? orderLimits.high : null;
      this.setLowOrderLimit = orderLimits ? orderLimits.lowSet : null;
      this.setHighOrderLimit = orderLimits ? orderLimits.highSet : null;
      this.bankDetails = item.bankDetails;
      this.propertiesList = item.propertiesList.map((prop) => prop);
      return;
    }
    this.id = null;
    this.name = null;
    this.displayName = null;
    this.isSuspended = null;
    this.associatedFirm = null;
    this.riskSystem = null;
    this.collateralAccount = null;
    this.priorityWeight = null;
    this.beneficialOwnership = [];
    this.propertiesList = [];
    this.parentFirm = null;
    this.aliases = [];
    this.lowOrderLimit = null;
    this.highOrderLimit = null;
    this.setLowOrderLimit = null;
    this.setHighOrderLimit = null;
  }
}