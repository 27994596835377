import {
  FETCH_QUOTES_START,
  FETCH_QUOTES_SUCCEED,
  FETCH_QUOTES_FAILED,
  CLEAN_QUOTES_SEARCH,
  RESET_TOKEN,
  FETCH_QUOTE_HISTORY_START,
  FETCH_QUOTE_HISTORY_SUCCEED
} from "../constants/quoteTypes";

const initialState = {
  fetchingQuotes: false,
  quotes: [],
  pageToken: {},
  tokens: {},
};

const quotes = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUOTES_START:
    case FETCH_QUOTE_HISTORY_START:
      return {
        ...state,
        fetchingQuotes: true,
        quotes: [],
      };
    case FETCH_QUOTES_SUCCEED:
      return {
        ...state,
        fetchingQuotes: false,
        quotes: state.quotes.concat(action.payload),
        pageToken: action.pageToken,
        tokens: { ...state.tokens, [action.pageToken]: action.nextPageToken },
      };
    case FETCH_QUOTES_FAILED:
      return {
        ...state,
        fetchingQuotes: false,
      };
    case CLEAN_QUOTES_SEARCH:
      return {
        ...state,
        fetchingQuotes: false,
        quotes: [],
      };
    case FETCH_QUOTE_HISTORY_SUCCEED:
      return {
        ...state,
        fetchingQuotes: false,
        quotes: action.payload,
        pageToken: action.pageToken,
        tokens: { ...state.tokens, [action.pageToken]: action.nextPageToken },
      };

    case RESET_TOKEN:
      return {
        ...state,
        tokens: {},
      };
    default:
      return state;
  }
};

export default quotes;
