import { SET_CURRENT_TAB, SET_CURRENT_SELECTION, ADD_BROWSER_HISTORY } from "../constants/appTypes";
import { store } from "../store";

export const SetCurrentTab = (SetCurrentTab) => ({
  payload: SetCurrentTab,
  type: SET_CURRENT_TAB,
});

export const SetCurrentSelection = (SetCurrentSelection) => ({
  payload: SetCurrentSelection,
  type: SET_CURRENT_SELECTION,
});

export const AddBrowserHistory = (url) => {
  store.dispatch({
    payload: url,
    type: ADD_BROWSER_HISTORY,
  });
}