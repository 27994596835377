import { getObjectValue, isNullOrUndefined, validScaledPrice } from "../../modules/util"

export const SELECTED_DEFAULT = "Select";
export const NUMBER_TYPE = "number";
export const DATE_TYPE = "date";
export const STRING_TYPE = "string";
export const ARRAY_TYPE = "array";
export const DATE_CALCULATOR = "dateCalculate"

export const validateField = (values, errors, name, label, type, required = true, scale = null, opts = {}) => {
  let value = getObjectValue(values, name);

  if (required) {
    if (isNullOrUndefined(value) || value === SELECTED_DEFAULT || value.toString().trim().length === 0) {
      errors[name] = label + " is required";
      return errors;
    }
  }

  switch (type) {
    case NUMBER_TYPE:
      if (isNaN(value)) {
        errors[name] = label + " must be a `number` type";
      } else if (!isNullOrUndefined(scale) && !validScaledPrice(value, scale)) {
        errors[name] = label + " scale is not valid"
      } else if (!isNullOrUndefined(opts['greaterThan']) && parseFloat(value) <= parseFloat(opts['greaterThan'])) {
        errors[name] = label + " must be greater than " + opts['greaterThan']
      } else if (!isNullOrUndefined(opts['greaterThanOrEqualTo']) && parseFloat(value) < parseFloat(opts['greaterThanOrEqualTo'])) {
        errors[name] = label + " must be >= " + opts['greaterThanOrEqualTo']
      }
      break;
    case DATE_TYPE:
      if (isNaN(new Date(value))) {
        errors[name] = label + " must be a `date` type";
      }
      break;
    case ARRAY_TYPE:
      if (value.length === 0) {
        errors[name] = "You must select at least one item in " + label;
      }
      break;
    default:
    // do nothing
  }

  return errors;
}

export const getOptionText = (options, value) => {
  if (!isNullOrUndefined(value)) {
    let found = options.find((item) => { return item.id === value; });

    if (found) {
      return found.name;
    }
  }

  return "";
}

export function makeDropdownOptions(options, isObjectList = true, showSelect = true) {
  if (isObjectList) {
    if (showSelect) {
      let arr = [{ text: SELECTED_DEFAULT, value: "" }];
      arr.splice(1, 0, ...options);
      return arr;
    }
    else {
      return options;
    }
  }
  else {
    if (showSelect)
      return [SELECTED_DEFAULT, ...options];

    return options;
  }
}

export default validateField;
