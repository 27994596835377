import React from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import { changePassword } from "../../actions/auth";
import { parseJwt } from "../../modules/authUtil";
import { Container, Row, Col, FormLabel, FormGroup } from "react-bootstrap";
import ButtonMain from "../core/form/ButtonMain";

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeUserPassword: (user, oldPassword, newPassword, cb) => {
    dispatch(changePassword(user, oldPassword, newPassword, cb));
  }
});

const ChangePassword = (props) => {
  const {
    handleSubmit,
    errors
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col lg={6} xs={6} md={12}>
            <FormGroup id="currentPassword">
              <FormLabel>Current Password</FormLabel>
              <Field
                id="currentPassword"
                name="currentPassword"
                className="text-input form-control"
                type="password"
              />
              {errors.currentPassword && <div className="form-input-error">{errors.currentPassword}</div>}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={6} xs={6} md={12}>
            <FormGroup id="newPassword">
              <FormLabel>New Password</FormLabel>
              <Field
                id="newPassword"
                name="newPassword"
                className="text-input form-control"
                type="password"
              />
              {errors.newPassword && <div className="form-input-error">{errors.newPassword}</div>}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={6} xs={6} md={12}>
            <FormGroup id="reEnterPassword">
              <FormLabel>Confirm Password</FormLabel>
              <Field
                id="reEnterPassword"
                name="reEnterPassword"
                className="text-input form-control"
                type="password"
              />
              {errors.reEnterPassword && <div className="form-input-error">{errors.reEnterPassword}</div>}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={6} xs={6} md={12}>
            <ButtonMain type="submit" text={"Change"} />
          </Col>
        </Row>
      </Container>
    </form>
  );
};

const ChangePasswordForm = withFormik({
  mapPropsToValues: () => ({
    currentPassword: '',
    newPassword: '',
    reEnterPassword: ''
  }),

  validate: (values) => {
    let errors = {};

    if (!values.currentPassword || !String(values.currentPassword).trim()) {
      errors.currentPassword = "Field is required";
    }

    if (!values.newPassword || !String(values.newPassword).trim()) {
      errors.newPassword = "Field is required";
    }

    if (!values.reEnterPassword || !String(values.reEnterPassword).trim()) {
      errors.reEnterPassword = "Field is required";
    }

    if (values.newPassword !== values.reEnterPassword) {
      errors.reEnterPassword = "Password is not same as new password.";
    }

    return errors;
  },

  handleSubmit: (values, { props }) => {
    const accessToken = props.auth.accessToken;
    const token = parseJwt(accessToken);
    props.changeUserPassword(token.sub, values.currentPassword, values.newPassword, (err, res) => {
      if (!err) {
        values.currentPassword = '';
        values.newPassword = '';
        values.reEnterPassword = '';
      }
    });
  },

  displayName: "ChangePassword",
})(ChangePassword);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
