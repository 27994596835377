import {
  LIST_COMMISSION_SUCCEED,
} from "../constants/commissionTypes";

const initialState = {
  settingCommission: false,
  deletingCommission: false,
  listingCommission: false,
  commissions: []
}

const commissions = (state = initialState, action) => {
  switch (action.type) {
    case LIST_COMMISSION_SUCCEED:
      return {
        ...state,
        listingCommission: false,
        commissions: action.payload
      };
    default:
      return state;
  }
}

export default commissions;