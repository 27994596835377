import React from 'react';
import PropTypes from 'prop-types';
import LinkWithRouter from '../core/link-with-router/LinkWithRouter';
import {
  Row,
  Col
} from 'react-bootstrap';
import ButtonMain from '../core/form/ButtonMain';
import ButtonSecondary from "../core/form/ButtonSecondary";
import { Link } from "react-router-dom";
import { TitleBreadcrumb } from '../core/title-breadcrumb/title-breadcrumb';

const InstrumentHeader = ({
  custonHeaderText,
  buttonText,
  buttonClick,
  isAddButtonVisible,
  secondaryButtonText,
  secondaryButtonClick,
  isSecondaryButtonVisible,
  isProductsSelected,
  isInstrumentsSelected,
  isCalendarsSelected,
  instrumentId
}) => {
  const linkClass = 'link-with-router link-tab';
  const selectedLinkClass = `${linkClass} link-tab-selected`;
  const productsLinkClass = isProductsSelected ? selectedLinkClass : linkClass;
  const instrumentsLinkClass = isInstrumentsSelected ? selectedLinkClass : linkClass;
  const calendarsLinkClass = isCalendarsSelected ? selectedLinkClass : linkClass;
  return (
    <div>
      {!!instrumentId && <div>{custonHeaderText ? custonHeaderText : <TitleBreadcrumb titles={[{ 'title': 'Instruments', link: `${window.location.origin}/instruments` }, { 'title': instrumentId }]} />}</div>}
      {!instrumentId && <div>{custonHeaderText ? custonHeaderText : <TitleBreadcrumb titles={[{ 'title': 'Instruments', link: `${window.location.origin}/instruments` }]} />}</div>}
      <Row className="page-header">
        <Col lg={6} xs={6} md={6}>
          <Link to='/products' className="tabLink"><LinkWithRouter label="Products" to="/products" className={productsLinkClass}></LinkWithRouter></Link>
          <Link to='/instruments' className="tabLink"><LinkWithRouter label="Instruments" to="/instruments" className={instrumentsLinkClass}></LinkWithRouter></Link>
          <Link to='/calendars' className="tabLink"><LinkWithRouter label="Calendars" to="/calendars" className={calendarsLinkClass}></LinkWithRouter></Link>
        </Col>
        <Col lg={6} xs={6} md={6}>
          <div style={{ float: "right" }}>
          {isAddButtonVisible &&
              <ButtonMain
                  text={buttonText}
                  type="button"
                  onClick={buttonClick}
              />
          }
          {isSecondaryButtonVisible &&
              <ButtonSecondary
                  text={secondaryButtonText}
                  type="button"
                  onClick={secondaryButtonClick}
              />
          }
          </div>
        </Col>
      </Row>
    </div>
  )
};

InstrumentHeader.defaultProps = {
  isProductsSelected: false,
  isInstrumentsSelected: false,
  isCalendarsSelected: false,
  isAddButtonVisible: false,
  buttonText: '',
  buttonClick: null
}

InstrumentHeader.propTypes = {
  custonHeaderText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonClick: PropTypes.func,
  isAddButtonVisible: PropTypes.bool,
  isProductsSelected: PropTypes.bool,
  isInstrumentsSelected: PropTypes.bool,
  isCalendarsSelected: PropTypes.bool,
}

export default InstrumentHeader;