import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import Loader from "../components/core/loader/Loader";
import PropTypes from "prop-types";
import confirm from "../modules/confirmDialog";
import CheckListPopup from "../components/core/checklist-popup";
import {
  FetchParticipantsListStart,
  FetchParticipantsListSucceed,
  FetchParticipantsListFailed,
} from "../actions/accounts";
import { Row, Col } from "react-bootstrap";
import "../pages/Content.css";
import Participant from "../entities/Participant";
import ParticipantsInGroupDataGrid from "../components/participants-in-group/ParticipantsInGroupDataGrid";
import ParticipantCheckListItem from "../entities/dto/ParticipantCheckListItem";
import Notification from "../modules/notifications";
import AccountService from "../services/AccountService";
import ParticipantService from "../services/ParticipantService";
import ButtonMain from "../components/core/form/ButtonMain";
import { isArrayWithValues } from "../modules/util";

const ModalMessage = ({ displayName }) => {
  return (
    <div>
      Select User to associate with Account <strong>"{displayName}"</strong>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    fetchingParticipantsList: state.participantGroups.fetchingParticipantsList,
    participants: state.participantGroups.participantsList,
    account: state.accounts.account,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchParticipantsStart: () => {
    dispatch(FetchParticipantsListStart());
  },
  fetchParticipantsSucceed: (participants) => {
    dispatch(FetchParticipantsListSucceed(participants));
  },
  fetchParticipantsFailed: () => {
    dispatch(FetchParticipantsListFailed());
  },
});

class ParticipantsInAccountContainer extends Component {
  state = {
    showParticipantPopup: false,
    selectedParticipantsToAdd: [],
    participantsToSelect: [],
    groupName: "",
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    const accountId = this.props.match.params.id;
    const firmId = this.props.match.params.firmid;
    if (!!firmId && !!accountId) {
      const resourceId = "firms/" + firmId + "/accounts/" + accountId;
      this.setState({ groupName: resourceId });
      this.loadAccounts(resourceId);
    }
  }

  loadAccounts = (id) => {
    this.props.fetchParticipantsStart();
    const cb = (err, response) => {
      if (response) {
        var participantsList = response
          .getParticipantsList()
          .map((participant) => {
            return new Participant(participant);
          });
        this.props.fetchParticipantsSucceed(participantsList);
        this.loadParticipantsNotInAccount();
      }

      if (err) {
        this.props.fetchParticipantsFailed();
        Notification.error("Cannot get participants list.");
      }
    };

    AccountService.getParticipants(id, cb);
  };

  loadParticipantsNotInAccount = () => {
    const cb = (err, response) => {
      if (response) {
        let participants = response.getParticipantsList();
        var participantsList = participants
          .map((participant) => {
            return new ParticipantCheckListItem(new Participant(participant));
          })
          .filter((item) =>
            item.name.startsWith(this.props.account.associatedFirm)
          );

        if (this.props.participants) {
          for (let index = 0; index < this.props.participants.length; index++) {
            const participantId = this.props.participants[index].id;
            const element = participantsList.find(
              (p) => p.id === participantId
            );
            if (element) {
              var removeIndex = participantsList
                .map(function (item) {
                  return item.id;
                })
                .indexOf(participantId);
              participantsList.splice(removeIndex, 1);
            }
          }
        }
        this.setState({ participantsToSelect: participantsList });
      }

      if (err) {
        Notification.error("Cannot check participants list.");
      }
    };

    ParticipantService.getAll(cb);
  };

  saveParticipants = () => {
    const participants = this.state.participantsToSelect.filter(function (item) {
      return item['isSelected'];
    });

    if (!isArrayWithValues(participants)) {
      this.closeParticipantsPoup();
      return;
    }

    const participantIdNames = participants.map((item) => {
      return item['name']
    })

    const customerOrderCapacities = participants.map((item) => {
      return item['customerOrderCapacity'] ? item['customerOrderCapacity'] : 0;
    })

    const cb = (err, response) => {
      if (response) {
        Notification.success("User/s successfully added.");
        this.closeParticipantsPoup();
        this.loadAccounts(this.state.groupName);
      }

      if (err) {
        Notification.error(`An error occurred while adding users: ${err.message}`);
      }
    };

    AccountService.addParticipants(this.state.groupName, participantIdNames, customerOrderCapacities, cb);
  };

  closeParticipantsPoup = () => {
    this.setState({
      showParticipantPopup: false,
      selectedParticipantsToAdd: [],
    });
  };

  removeParticipant = (participantId, infoObj) => {
    confirm(`Are you sure you want to remove User "${participantId}" from this Account?`, {
        title: "Confirm Associated User Removal",
        okButtonText: "Yes",
        cancelButtonText: "No",
      }
    ).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            Notification.success("User successfully removed.");
            this.loadAccounts(this.state.groupName);
          }

          if (err) {
            Notification.error(`An error occurred while removing the user: ${err.message}`);
          }
        };

        let participantIdArray = [];
        participantIdArray.push(participantId);

        AccountService.removeParticipant(
          this.state.groupName,
          participantIdArray,
          cb
        );
      },
      () => { }
    );
  };

  onSelectParticipant = (participant) => {
    let existingList = _.clone(this.state.selectedParticipantsToAdd);
    const checkItem = this.state.participantsToSelect.find(
      (item) => item.id === participant.id
    );
    let pushItem = _.clone(checkItem);
    pushItem.isSelected = participant.isSelected;
    if (pushItem.isSelected) {
      existingList.push(pushItem);
    } else {
      const existingItem = existingList.find((x) => x.id === participant.id);
      if (existingItem) {
        const removeIndex = existingList
          .map(function (item) {
            return item.id;
          })
          .indexOf(participant.id);
        existingList.splice(removeIndex, 1);
      }
    }

    this.setState({ selectedParticipantsToAdd: existingList });

    const copy = _.clone(this.state.participantsToSelect);
    const currentItem = copy.find((x) => x.id === participant.id);
    const itemIndex = copy.indexOf(currentItem);
    copy[itemIndex] = pushItem;
    this.setState({ participantsToSelect: copy });
  };

  openViewParticipant = (name) => {
    if (name.startsWith("firms/")) {
      this.context.router.history.push(`/${name}`);
    } else {
      //support legacy users
      this.context.router.history.push(`/users/${name}`);
    }
  };

  openEditParticipant = (name) => {
    if (name.startsWith("firms/")) {
      this.context.router.history.push(`/${name}/edit`);
    } else {
      //support legacy users
      this.context.router.history.push(`/users/${name}/edit`);
    }
  };

  render() {
    return (
      <div>
        <Row>
          <Col lg={9} xs={9} md={9}>
            <div className="page-sub-title">Associated Users</div>
          </Col>
          <Col lg={3} xs={3} md={3}>
            <ButtonMain
              text="Add Associated User"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showParticipantPopup: true });
              }}
              customClassName="btn-main-header"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} xs={12} md={12}>
            <Loader show={this.props.fetchingParticipantsList}></Loader>
            <div className="with-callback">
              <ParticipantsInGroupDataGrid
                resource={this.state.groupName}
                data={this.props.participants}
                onView={this.openViewParticipant}
                onEdit={this.openEditParticipant}
                onDelete={this.removeParticipant}
              />
            </div>
          </Col>
        </Row>
        <CheckListPopup
          show={this.state.showParticipantPopup}
          title="Add User"
          className="modal-dialog-xl"
          onOk={() => {
            this.saveParticipants();
          }}
          onCancel={() => {
            this.closeParticipantsPoup();
          }}
          okButtonText="Confirm"
          cancelButtonText="Cancel"
          onSelectItem={(participant) => {
            this.onSelectParticipant(participant);
          }}
          columnText="USER NAME"
          columnAccesor="displayName"
          selectorId="displayName"
          customText={
            <ModalMessage
              displayName={this.props.account.displayName}
            ></ModalMessage>
          }
          data={this.state.participantsToSelect}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ParticipantsInAccountContainer)
);
