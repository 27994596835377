import React from "react";

/**
 * 
 * @param {[{'title', 'link'}]} titles - An array of {'title', 'link'}
 * @returns 
 */
export const TitleBreadcrumb = ({ titles }) => {
  return (!!titles && <div className="page-title">
    {
      titles.map((obj, idx) => {
        if (!!obj) {
          if (idx > 0) {
            return (<div key={idx} className="sub-title"><span className="breadcrum-separator" />
              {!!obj.link && <a href={obj.link}>{obj.title}</a>}
              {!obj.link && <span>{obj.title}</span>}
            </div>);
          }
          return <div key={idx} className="title">
            {!!obj.link && <a className="title" href={obj.link}>{obj.title}</a>}
            {!obj.link && <span>{obj.title}</span>}
          </div>;
        }

        return null;
      })
    }
  </div>
  );
}
