import React, { Component } from "react";
import { connect } from "react-redux";
import { NavIcon, NavItem, NavText } from "@trendmicro/react-sidenav";
import { Tooltip } from "@trendmicro/react-tooltip";
import { HAS_ALERTS_SUCCESS, HAS_ALERTS_FAILED } from "../../constants/AlertTypes";
import Notification from "../../modules/notifications";
import AlertService from "../../services/AlertService";
import { ListAlertsReq } from "../../entities/Alert";
import AlertStaticData from "../../modules/alertsStaticData";
import { Link } from "react-router-dom";
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import UserLicense from "../users/UserLicense";
import "./shared.css";
import {ListEp3Versions} from "../../actions/jobs";

const pingFrequency = 5;
class PageRoute extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLicense: false
        }
    }

    componentDidMount() {
        if (this.props.routeKey === "alerts") {
            this.props.checkAlert(false);
            this.timer = setInterval(() => {
                this.props.checkAlert(!!this.props.hasAlert);
            }, pingFrequency * 1000);
        }
    }

    componentWillUnmount() {
        if (this.props.routeKey === "alerts") {
            clearInterval(this.timer);
        }
    }

    showLicense = () => {
        this.props.listEp3Versions();
        this.setState({ showLicense: true });
        return false;
    }

    hideLicense = () => {
        this.setState({ showLicense: false });
        return false;
    }

    onNavItemClick = (routeKey) => {
        if (!!this.props.routeKey) {
          this.props.onSelect(routeKey)
        }
    }
    render() {
        const routeLabel = this.props.routeLabel ? this.props.routeLabel : "";
        const routeKey = this.props.routeKey ? this.props.routeKey : "";
        const routeIcon = this.props.routeIcon ? this.props.routeIcon : "";
        const routeIconComponent = this.props.routeIconComponent ? this.props.routeIconComponent : null;
        const hasAlert = !!this.props.hasAlert ? routeKey === "alerts" : false;
        const url = `/${routeKey}`;

        return (
            <>
                <NavItem key={routeLabel} eventKey={routeKey} onClick={() => this.onNavItemClick(routeKey)}>
                    <NavIcon>
                        <Tooltip
                            content={() => {
                                return (<span style={{ whiteSpace: 'nowrap', color: "#fff" }}>{routeLabel}</span>);
                            }}
                        >
                            <>
                                {routeIcon && <Link to={url}>
                                    <i
                                        className={`fa fa-fw ${routeIcon} custom-icon`}
                                        style={{ fontSize: "1.75em" }}
                                    />
                                    {!!hasAlert && <span
                                        className={"badge badge-pill badge-primary"}
                                    >&#x2022;</span>}
                                </Link>}

                                {routeIconComponent && <span onClick={() => this.showLicense()} >
                                    {routeIconComponent}
                                </span>}
                            </>
                        </Tooltip>
                    </NavIcon>
                    <NavText className="nav-text">
                        <>
                            {routeKey === "" && < span className="sidenav-item" onClick={() => this.showLicense()}>{routeLabel}</span>}
                            {!!routeKey && <Link to={url}>{routeLabel}</Link>}
                        </>
                    </NavText>
                </NavItem >
                <ConfirmDialog
                    show={this.state.showLicense}
                    confirmation={
                        <UserLicense />
                    }
                    proceed={() => this.hideLicense()}
                    reject={() => this.hideLicense()}
                    resolve={() => this.hideLicense()}

                    options={{
                        title: "LICENSE",
                        okButtonText: "OK",
                        cancelButtonText: null,
                    }}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        hasAlert: state.alerts.hasAlert,
        versions: state.jobs.versions,
    };
}

const mapDispatchToProps = (dispatch) => ({
    listEp3Versions: () => dispatch(ListEp3Versions()),
    checkAlert: (currHasAlert) => {
        const reqCb = (err, resp) => {
            if (!!err) {
                dispatch({ type: HAS_ALERTS_FAILED });
                Notification.error(`Cannot get list of alerts: ${err.message}`);
            }

            if (!!resp) {
                const alertsList = resp.getAlertsList();
                const hasAlert = Array.isArray(alertsList) && alertsList.length > 0;
                if (currHasAlert !== hasAlert) {
                    dispatch({ type: HAS_ALERTS_SUCCESS, payload: hasAlert });
                }
            }
        }
        let alertsReq = new ListAlertsReq();
        alertsReq.pageSize = 1
        alertsReq.pageToken = "";
        alertsReq.statusesList = [AlertStaticData.AlertStatuses([0])[0].id];
        AlertService.listAlerts(alertsReq.toProto(), reqCb);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PageRoute)