import { push } from "connected-react-router";
import {
  FETCH_TRADE_CAPTURE_START,
  FETCH_TRADE_CAPTURE_SUCCEED,
  FETCH_TRADE_CAPTURE_FAILED,
  BUST_TRADE_START,
  BUST_TRADE_SUCCEED,
  BUST_TRADE_FAILED,
} from "../constants/tradeTypes";
import { StatusCode } from "grpc-web";
import { parsePrice } from "../modules/util";

import TradeService from "../services/TradeService";
import InstrumentService from "../services/InstrumentService";
import Notification from "../modules/notifications";
import TradeCapture from "../entities/TradeCapture";
import Instrument from "../entities/Instrument";
import TimeSalesTradeCaptureFormItem from "../entities/dto/TimeSalesTradeCaptureFormItem";
import { getAccountFirm, getParticipant, getParticipantFirm } from "../modules/actionUtil";

const {
  TradeState,
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/trades/v1beta1/trades_pb');

export function getTradeCapture(tradeId) {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_TRADE_CAPTURE_START });
    try {
      let response = await TradeService.getTradeCaptureReport2(tradeId);
      if (response) {
        const tradeCapture = new TradeCapture(response.getTradeCaptureReport());
        let tradeCaptureItem = new TimeSalesTradeCaptureFormItem(tradeCapture);

        if (tradeCapture.orders.length > 0) {
          let state = getState();
          const order = tradeCapture.orders[0];
          let instrumentResponse = null;

          const aggressorAcct = !!tradeCapture.trade.aggressor.order ? tradeCapture.trade.aggressor.order.account : null;
          const passiveAcct = !!tradeCapture.trade.passive.order ? tradeCapture.trade.passive.order.account : null;

          const instrumentPromise = InstrumentService.get2(order.symbol);

          const aggAccountPromise = getAccountFirm(state, aggressorAcct);
          const passAccountPromise = getAccountFirm(state, passiveAcct);

          const aggParticipantPromise = getParticipant(state, tradeCapture.trade.aggressor.order.participant);
          const passParticipantPromise = getParticipant(state, tradeCapture.trade.passive.order.participant);
          const aggSubmittingParticipantPromise = getParticipantFirm(state, tradeCaptureItem.aggressorSubmittingParticipant);
          const passSubmittingParticipantPromise = getParticipantFirm(state, tradeCaptureItem.passiveSubmittingParticipant);

          const [
            instrumentPromiseResp,
            aggAccountPromiseResp,
            passAccountPromiseResp,
            aggParticipantPromiseResp,
            aggSubmittingParticipantPromiseResp,
            passSubmittingParticipantPromiseResp,
            passParticipantPromiseResp
          ] = await Promise.all([
            instrumentPromise,
            aggAccountPromise,
            passAccountPromise,
            aggParticipantPromise,
            aggSubmittingParticipantPromise,
            passSubmittingParticipantPromise,
            passParticipantPromise
          ]).catch((err) => {
            if (err.code === 5) {
              dispatch({ type: FETCH_TRADE_CAPTURE_FAILED });
              Notification.error(err.message);
            }
            console.log(err);
          });

          instrumentResponse = instrumentPromiseResp;
          tradeCaptureItem.aggressorAccountObj = aggAccountPromiseResp.account;
          tradeCaptureItem.aggressorFirm = aggAccountPromiseResp.firm;
          tradeCaptureItem.passiveAccountObj = passAccountPromiseResp.account;
          tradeCaptureItem.passiveFirm = passAccountPromiseResp.firm;
          tradeCaptureItem.aggressorParticipantObj = aggParticipantPromiseResp;
          tradeCaptureItem.passiveParticipantObj = passParticipantPromiseResp;

          if (instrumentResponse) {
            const instrument = new Instrument(
              instrumentResponse.getInstrument()
            );

            tradeCaptureItem.instrument = instrument;

            tradeCaptureItem.priceScale = instrument.priceScale;
            tradeCaptureItem.tradePrice = parsePrice(
              !!tradeCapture.trade.aggressor.lastPx ? tradeCapture.trade.aggressor.lastPx : tradeCapture.trade.passive.lastPx,
              instrument.priceScale
            );

            tradeCaptureItem.aggressorLastPrice = parsePrice(
              !!tradeCapture.trade.aggressor.lastPx ? tradeCapture.trade.aggressor.lastPx : 0,
              instrument.priceScale
            );

            tradeCaptureItem.passiveLastPrice = parsePrice(
              !!tradeCapture.trade.passive.lastPx ? tradeCapture.trade.passive.lastPx : 0,
              instrument.priceScale
            );

            tradeCaptureItem.aggressorCommissionNotionalCollected = parsePrice(
                !!tradeCapture.trade.aggressor.commissionNotionalCollected ? tradeCapture.trade.aggressor.commissionNotionalCollected : 0,
                instrument.priceScale,
                instrument.fractionalQtyScale,
            );

            tradeCaptureItem.passiveCommissionNotionalCollected = parsePrice(
                !!tradeCapture.trade.passive.commissionNotionalCollected ? tradeCapture.trade.passive.commissionNotionalCollected : 0,
                instrument.priceScale,
                instrument.fractionalQtyScale,
            );

            tradeCaptureItem.fractionalQtyScale = instrument.fractionalQtyScale;
            tradeCaptureItem.tradeQty = parsePrice(
              !!tradeCapture.trade.aggressor.lastShares ? tradeCapture.trade.aggressor.lastShares : tradeCapture.trade.passive.lastShares,
              instrument.fractionalQtyScale
            );

            if (!!aggressorAcct) {
              tradeCaptureItem.aggressorAccountObj.legPricesList = tradeCapture.trade.aggressor.legPricesList
              if (!!tradeCaptureItem.aggressorAccountObj.legPricesList) {
                tradeCaptureItem.aggressorAccountObj.legPricesList.forEach(legPrice => {
                  legPrice.tradePrice = parsePrice(legPrice.px, instrument.priceScale);
                });
              }

              if (tradeCaptureItem.aggressorSubmittingParticipant) {
                tradeCaptureItem.aggressorSubmittingParticipantObj = aggSubmittingParticipantPromiseResp.participant;
                tradeCaptureItem.aggressorSubmittingParticipantObj.firm = aggSubmittingParticipantPromiseResp.firm;
              }
            }

            if (!!passiveAcct) {
              tradeCaptureItem.passiveAccountObj.legPricesList = tradeCapture.trade.passive.legPricesList;
              if (!!tradeCaptureItem.passiveAccountObj.legPricesList) {
                tradeCaptureItem.passiveAccountObj.legPricesList.forEach(legPrice => {
                  legPrice.tradePrice = parsePrice(legPrice.px, instrument.priceScale);
                });
              }

              if (tradeCaptureItem.passiveSubmittingParticipant) {
                tradeCaptureItem.passiveSubmittingParticipantObj = passSubmittingParticipantPromiseResp.participant;
                tradeCaptureItem.passiveSubmittingParticipantObj.firm = passSubmittingParticipantPromiseResp.firm;
              }
            }

            dispatch({
              type: FETCH_TRADE_CAPTURE_SUCCEED,
              payload: tradeCaptureItem,
            });
          }
        }
      }

    } catch (err) {
      if (err) {
        console.error(tradeId, err);
        if (err.code !== StatusCode.UNAVAILABLE) {
          switch (err.code) {
            case StatusCode.NOT_FOUND:
              Notification.error("Cannot find this trade.");
              break;
            default:
              Notification.error("Cannot get trade information.");
              break;
          }
        }
        dispatch({ type: FETCH_TRADE_CAPTURE_FAILED });
      }
    }
  };
}

export function bustTrade(tradeId, bustReason = "") {
  return (dispatch) => {
    dispatch({ type: BUST_TRADE_START });

    const cb = (err, response) => {
      if (response) {
        dispatch({ type: BUST_TRADE_SUCCEED });
        dispatch(push("/timeandsales"));
        Notification.success("Trade bust successful.");
      }

      if (err) {
        if (err.code !== StatusCode.UNAVAILABLE) {
          switch (err.code) {
            case StatusCode.FAILED_PRECONDITION:
              Notification.error(err.message);
              break;
            default:
              Notification.error(`Error when busting trade.`);
              break;
          }
        }
        dispatch({ type: BUST_TRADE_FAILED });
      }
    };

    TradeService.updateTrade(tradeId, TradeState.TRADE_STATE_BUSTED, bustReason, cb);
  };
}

export function updateReportingCounterparty(tradeId, reportingCounterParty) {
  return async (dispatch) => {
    dispatch({ type: BUST_TRADE_START });
    try {
      await TradeService.updateReportingCounterParty(
        tradeId,
        reportingCounterParty
      );
      dispatch({ type: BUST_TRADE_SUCCEED });
      dispatch(push("/timeandsales"));
      Notification.success("Updated reporting counter party successfully.");
    } catch (err) {
      if (err) {
        if (err.code !== StatusCode.UNAVAILABLE) {
          switch (err.code) {
            case StatusCode.FAILED_PRECONDITION:
              Notification.error(err.message);
              break;
            default:
              Notification.error(
                `Error when updating trade reporting counter party.`
              );
              break;
          }
        }
        dispatch({ type: BUST_TRADE_FAILED });
      }
    }
  };
}
