import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormLabel
} from 'react-bootstrap';
import Tooltip from '../tooltip-html/tooltip-html';
import {isNullOrUndefined} from "../../../modules/util";

const FieldGroupReadOnly = ({
  id,
  label,
  value,
  tooltip
}) => (
    <FormGroup id={id}>
      <FormLabel>{label}</FormLabel>
      {!isNullOrUndefined(tooltip) &&
      <Tooltip
        text = {tooltip}
        id={id+"Tooltip"}
      ></Tooltip>
      }
      <div className="form-readonly-label">{value}</div>
    </FormGroup>
  )

FieldGroupReadOnly.defaultProps = {
  label: ''
}

FieldGroupReadOnly.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.any
}

export default FieldGroupReadOnly;