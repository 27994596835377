import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import slugify from "slugify";
import FieldGroup from "../core/form/FieldGroup";
import { convertEnumToDropdownList } from "../../modules/util";
import { Container, Row, Col } from "react-bootstrap";
import ButtonMain from "../core/form/ButtonMain";
import ButtonBorderless from "../core/form/ButtonBorderless";
import UserRoleListContainer from "../../containers/UserRoleListContainer";
import { ParticipantRole } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import DropdownListGroup from "../core/form/DropdownListGroup";
import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import Aliases from "../../components/shared/Aliases";
import { Env } from "../../constants/environment";
import UserAttributesForm from "./UserAttributesForm";
import * as toolTips from '../../constants/userToolTip';
import ErrorLabel from "../core/form/ErrorLabel";

export class BasicFirmForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      title,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      firms,
      participantGroups,
      isNewService,
      preloadFirm,
    } = this.props;

    let preloadFirmDisplayName = null;

    if (preloadFirm) {
      values.firm = preloadFirm;
      let _firm = firms.find((firm) => firm.id === preloadFirm);
      preloadFirmDisplayName = _firm ? _firm.displayName : null;
    }

    if (isNewService) values.serviceUser = true;

    const aliasOnChange = (aliases) => {
      values.aliases = aliases;
    };

    return (
      <form onSubmit={handleSubmit} name="firmForm">
        <h1>{title}</h1>
        <Container>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FormGroup id="displayName">
                <FormLabel>
                  {!isNewService ? "User Name" : "Firm Service Name"}
                </FormLabel>
                <label className="form-label-required"> Required</label>
                <FormControl
                  id="displayName"
                  type="text"
                  name="displayName"
                  value={values.displayName}
                  placeholder=""
                  onChange={(e) => {
                    handleChange(e);
                    if (!touched.userId) {
                      setFieldValue("userId", slugify(e.target.value));
                    }
                  }}
                ></FormControl>
                {errors && errors["displayName"] && (
                  <div className="form-error">
                    <i
                      className="fa fa-exclamation-triangle orange-icon"
                      aria-hidden="true"
                    ></i>{" "}
                    {errors["displayName"]}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroup
                id="userId"
                name="userId"
                type="text"
                label={!isNewService ? "User ID" : "Firm Service ID"}
                placeholder=""
                errors={errors}
                touched={touched}
                value={values.userId}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
                isRequired={true}
                help={
                  !isNewService
                    ? "User ID must be unique."
                    : "Firm Service ID must be unique."
                }
              />
            </Col>
          </Row>
          {isNewService ? (
            <Row>
              <Col lg={6} xs={6} md={12}>
                <FieldGroup
                  id="description"
                  name="description"
                  type="text"
                  label={"Description"}
                  placeholder=""
                  errors={errors}
                  touched={touched}
                  value={values.description}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col lg={6} xs={6} md={12}>
              {preloadFirm ? (
                <FieldGroupReadOnly
                  id="firm"
                  name="firm"
                  label="Firm"
                  value={preloadFirmDisplayName}
                />
              ) : (
                <DropdownListGroup
                  id="firm"
                  name="firm"
                  textField="displayName"
                  valueField="name"
                  label="Firm"
                  isRequired={true}
                  enabled={true}
                  value={values.firm}
                  onChange={(e) => setFieldValue("firm", e.name)}
                  data={["", ...firms]}
                  errors={errors}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <UserRoleListContainer
                id="role"
                name="role"
                label="Role"
                isRequired={true}
                errors={errors}
                touched={touched}
                value={values.role}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                onBlur={handleBlur}
                firm={
                  values.firm
                    ? firms
                      .filter(
                        (firm) =>
                          firm.name === values.firm ||
                          firm.displayName === values.firm ||
                          firm.id === values.firm
                      )
                      .shift()
                    : null
                }
              />
            </Col>
          </Row>
          <UserAttributesForm
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              isNewService={isNewService}
              isEditable={true}
          />
          {!isNewService ? (
            <>
              <Row>
                <Col lg={6} xs={6} md={12}>
                  <FieldGroup
                    id="groups"
                    name="groups"
                    label="Group Name"
                    as="select"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.groups}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={
                      values.firm
                        ? participantGroups
                          .filter(
                            (participantGroup) =>
                              participantGroup.firm === values.firm
                          )
                          .map((participantGroup) => participantGroup.id)
                        : participantGroups.map(
                          (participantGroup) => participantGroup.id
                        )
                    }
                  />
                </Col>
              </Row>{" "}
            </>
          ) : null}

          <Row>
            <Col lg={12} xs={12} md={12}>
              <Aliases onChange={aliasOnChange} options={Env.getAliasOptions()} tooltip={toolTips.ALIAS} />
            </Col>
          </Row>

          <Row>
            <Col lg={1} xs={1} md={1}>
              <ButtonMain type="submit" text="SAVE" />
            </Col>
            <Col lg={2} xs={2} md={2}>
              <ButtonBorderless
                type="button"
                text="Cancel"
                icon="times-circle"
                customClassName="grey-icon"
                onClick={(e) => {
                  e.preventDefault();
                  this.context.router.history.goBack();
                }}
              />
            </Col>
            <Col lg={9} xs={9} md={9}>
              {Object.keys(errors).length > 0 && <div style={{ marginTop: "10px" }} ><ErrorLabel text="At least one required field is missing. Please review." /></div>}
            </Col>
          </Row>

        </Container>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    isEditing: !state.firms.isCreating,
  };
}

const roleEnum = convertEnumToDropdownList(ParticipantRole);
roleEnum.splice(0, 1);

const formatValues = (values, props) => {
  return {
    ...values,
    groups: [values.groups],
    role: roleEnum.filter((role) => role.name === values.role).shift(),
    firm: props.firms
      .filter(
        (firm) =>
          firm.displayName === values.firm ||
          firm.name === values.firm ||
          firm.id === values.firm
      )
      .shift().name,
  };
};

const FirmForm = withFormik({
  mapPropsToValues: (item) => ({
    ...item.item,
  }),
  validationSchema: Yup.object().shape({
    displayName: Yup.string().nullable().required("User Name is required."),
    email: Yup.string().email("Invalid email format"),
  }),
  validate: (values) => {
    let errors = {};
    if (!values.firm) {
      errors.firm = "Firm must be selected.";
    }
    if (!values.role) {
      errors.role = "Role must be selected.";
    }

    if (!values.userId) {
      errors.userId = !values.serviceUser
        ? "User ID is required."
        : "Firm Service ID is required.";
    }
    return errors;
  },
  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onSubmitForm(formatValues(values, props), setFieldError);
  },
  displayName: "firm-form",
  enableReinitialize: true,
})(BasicFirmForm);

export default connect(mapStateToProps, null)(FirmForm);
