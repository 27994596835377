import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Tooltip.css';

const bubblePosition = {
  left: 'left',
  right: 'right'
}

class Tooltip extends Component {
  state = {
    show: false,
    message: null
  }

  componentWillReceiveProps(nextProps) {
    const { show, message } = nextProps;
    this.setState({ show, message });
  }

  render() {
    if (!this.state.show) return null;
    let tooltipClass = `custom-tooltip-${this.props.position}`;
    return (
      <div className={tooltipClass}>
        {this.state.message}
        <div className="custom-tooltip-close" onClick={() => {
          this.setState({ message: null, show: false });
          if(this.props.onClick){
            this.props.onClick();
          }
        }}></div>
      </div>
    )
  }
}

Tooltip.bubblePosition = bubblePosition;

Tooltip.defaultProps = {
  show: false
}

Tooltip.propTypes = {
  show: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(Object.keys(bubblePosition)).isRequired,
}

export default Tooltip;