
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { listAlerts, updateAlert, updateAlertNotes, exportAlerts } from "../actions/alerts";
import AlertsDataGrid from "../components/alerts/AlertsDataGrid";
import AlertsFilterForm from "../components/alerts/AlertsFilterForm";
import PaginationWithPageSize, { withPageToken } from "../components/core/data-grid/PaginationWithPageSize";
import { ListAlertsReq, UpdateAlertReq, UpdateAlertNotesReq } from "../entities/Alert";
import { fetchInstrumentIdsList } from "../actions/instruments";
import { LoadFirms } from "../actions/firms";
import AlertStaticData from "../modules/alertsStaticData";
import confirm from "../modules/confirmDialog";
import querystring from 'query-string';
import { TitleBreadcrumb } from "../components/core/title-breadcrumb/title-breadcrumb";

const STORAGE_PAGE_SIZE_KEY = "alertsGridPageSize-Alerts";
const DEFAULT_PAGE_SIZE = 10;

const mapStateToProps = (state) => {
  const instrumentIdsList = state.instruments.instrumentIdsList;
  const accounts = state.accounts.accounts;
  const firms = state.firms.firms;
  const firmsOptions = !!firms ? [{ id: "", name: "" }, ...firms.map((firm) => ({ id: firm.name, name: firm.displayName }))] : [];
  const { alerts, nextPageToken, pageToken, pageTokens } = state.alerts

  return {
    alerts,
    nextPageToken,
    pageToken,
    pageTokens,
    instrumentIdsList,
    accounts,
    firms,
    firmsOptions,
  };
}

const mapDispatchToProps = (dispatch) => ({
  listAlerts: (listAlertsRequest, cb) => {
    dispatch(listAlerts(listAlertsRequest, cb));
  },
  updateAlert: (updateAlertRequest, cb) => {
    dispatch(updateAlert(updateAlertRequest, cb));
  },
  updateAlertNotes: (updateAlertNotesRequest, cb) => {
    dispatch(updateAlertNotes(updateAlertNotesRequest, cb));
  },
  exportAlerts: (listAlertsRequest, cb) => {
    dispatch(exportAlerts(listAlertsRequest, cb));
  },
  fetchInstrumentIdsList: () => {
    dispatch(fetchInstrumentIdsList())
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
});

class AlertsPage extends Component {

  constructor(props) {
    super(props)
    const alertStatuses = AlertStaticData.AlertStatuses([0]);

    const state = querystring.parseUrl(window.location.href);

    if (Object.keys(state.query).length === 0) {
      this.state = { ...this.initState }
    } else {
      let status = state.query.statusesList ? alertStatuses.filter(status => state.query.statusesList.includes(status.id.toString())) : []

      this.state = {
        alertFilter: {
          id: state.query.id,
          statusesList: status,
          tradeId: state.query.tradeId,
          venue: state.query.venue,
          alertName: state.query.alertName,
          startTime: state.query.startTime ? new Date(state.query.startTime) : null,
          endTime: state.query.endTime ? new Date(state.query.endTime) : null,
          symbol: state.query.symbol,
          account: state.query.account,
          firm: state.query.firm,
        }
      }
    }

  }

  static contextTypes = {
    router: PropTypes.object
  } 

  initState = {
    alertFilter: {
      pageSize: null,
      pageToken: null,
      id: null,
      statusesList: [AlertStaticData.AlertStatuses([0])[0]],
      tradeId: null,
      venue: null,
      description: null,
      startTime: null,
      endTime: null,
      symbol: null,
      account: null,
      firm: null,
    }
  }

  componentDidMount() {
    this.props.loadFirms();
    this.applyFilter(this.state.alertFilter);
    window.scrollTo(0, 0);
  }

  setPageSizeInStorage = (pageSize) => {
    window.localStorage.setItem(STORAGE_PAGE_SIZE_KEY, pageSize)
  }

  getPageSizeFromStorage = () => {
    const pageSize = window.localStorage.getItem(STORAGE_PAGE_SIZE_KEY)

    if (pageSize)
      return Number(pageSize);

    return Number(DEFAULT_PAGE_SIZE);
  }

  onPageSizeChange = (pageSize) => {
    this.setPageSizeInStorage(pageSize);
    this.setState({ pageSize });
    const pageToken = "";

    let alertsReq = new ListAlertsReq();

    if (!!this.state.alertsReq)
      alertsReq = this.state.alertsReq;

    alertsReq.pageToken = pageToken;
    alertsReq.pageSize = pageSize;

    const protoReq = alertsReq.toProto();

    this.props.listAlerts(protoReq, () => {

    });
  }

  onPageChange = (pageToken) => {
    let alertsReq = new ListAlertsReq();

    if (!!this.state.alertsReq)
      alertsReq = this.state.alertsReq;

    alertsReq.pageToken = pageToken;
    alertsReq.pageSize = this.getPageSizeFromStorage();

    const protoReq = alertsReq.toProto();

    this.props.listAlerts(protoReq, () => {

    });
  }

  getPaginationComponent = () => {
    const pageToken = this.props.pageToken;
    const tokens = this.props.pageTokens;
    const pageSize = this.getPageSizeFromStorage();
    return withPageToken(PaginationWithPageSize, { pageToken, tokens }, pageSize);
  }

  applyFilter = (filter) => {
    const pageSize = this.getPageSizeFromStorage();

    let alertsReq = this.buildListAlertsReq(filter)
    alertsReq.pageSize = pageSize;
    alertsReq.pageToken = "";
    const protoReq = alertsReq.toProto();

    this.setState({ alertsReq: alertsReq });

    this.props.listAlerts(protoReq);
  }

  exportAlerts = (filter) => {
    let alertsReq = this.buildListAlertsReq(filter)
    const protoReq = alertsReq.toProto();
    this.props.exportAlerts(protoReq);
  }

  buildListAlertsReq = (filter) => {
    let alertsReq = new ListAlertsReq();
    if (!!filter.id) alertsReq.id = filter.id;
    if (!!filter.statusesList) alertsReq.statusesList = filter.statusesList.map(item => item.id);
    if (!!filter.tradeId) alertsReq.tradeId = filter.tradeId;
    if (!!filter.venue) alertsReq.venue = filter.venue;
    if (!!filter.alertName) alertsReq.alertName = filter.alertName;
    if (!!filter.description) alertsReq.description = filter.description;
    if (!!filter.startTime) alertsReq.startTime = filter.startTime;
    if (!!filter.endTime) alertsReq.endTime = filter.endTime;
    if (!!filter.symbol) alertsReq.symbol = filter.symbol;
    if (!!filter.account) alertsReq.account = filter.account;
    if (!!filter.firm) alertsReq.firm = filter.firm;

    return alertsReq
  }

  updateAlert = (alertIds, status, cb) => {
    const ids = typeof alertIds === 'string' || alertIds instanceof String ? [alertIds] : Array.from(alertIds);

    confirm(`${ids.length > 1 ? "Are you sure you want to edit these alerts?" : "Are you sure you want to edit this alert?"}`,
      {
        title: "Alert Confirmation",
        okButtonText: "Yes",
        cancelButtonText: "No",
      }).then(() => {
        ids.forEach(alertId => {
          const updateReq = new UpdateAlertReq();
          updateReq.id = alertId;
          updateReq.status = status;

          const protoReq = updateReq.toProto()
          this.props.updateAlert(protoReq, cb);
        })
      },
        () => { }
      )
  }

  updateAlertNotes = (alertId, notes, cb) => {
    const updateReq = new UpdateAlertNotesReq();
    updateReq.id = alertId;
    updateReq.notes = notes;

    const protoReq = updateReq.toProto()

    this.props.updateAlertNotes(protoReq, cb);
  }

  render() {
    const { alerts } = this.props;

    return (
      <>
        <div>
          <TitleBreadcrumb titles={[{ 'title': 'Alerts', link: `${window.location.origin}/alerts` }]} />
        </div>
        <div className="with-callback">
          <AlertsFilterForm
            filter={this.state.alertFilter}
            onFilter={this.applyFilter}
            onExport={this.exportAlerts}
            onRefreshInstruments={() => { this.props.fetchInstrumentIdsList() }}
            instrumentIdsList={this.props.instrumentIdsList}
            firmsOptions={this.props.firmsOptions}
            context={this.context}
          />

          <AlertsDataGrid
            data={alerts}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            paginationComponent={this.getPaginationComponent()}
            firmsOptions={this.props.firmsOptions}
            onUpdateAlert={this.updateAlert}
            onUpdateAlertNotes={this.updateAlertNotes}
            onRefreshData={() => this.applyFilter(this.state.alertFilter)}
          />
        </div>
      </>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AlertsPage);