import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash"
import Filter from "./Filter";
import DateGroup from "../../core/form/DateGroup";
import moment from "moment";
import { Col, FormControl, Form, Row } from "react-bootstrap";
import LoaderIcon from "../../core/loader/LoaderIcon";

class QuotesFilter extends Component {
  static emptyFilterObj = {
    fromDate: null,
    toDate: null,
    instrumentId: null,
    participant: null,
    account: null,
    clientId: null,
    tokens: {},
    firm: null,
    accounts: [],
    quid: null,
  };

  constructor() {
    super();
    this.state = { ...this.getDefaultState() };
    this.onApply = this.onApply.bind(this);
    this.onRemoveFilterItem = this.onRemoveFilterItem.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onFlip = this.onFlip.bind(this);
    this.validate = this.validate.bind(this);
    this.oldState = {};
    this.fetchMetadata = false;
    this.currentFilterItems = {};
    this.accounts = [];
    this.participants = [];
  }

  getDefaultState() {
    const fromDt = moment().startOf("day").toDate();
    const toDt = moment().endOf("day").toDate();

    return {
      ...QuotesFilter.emptyFilterObj,
      fromDate: {
        id: "fromDate",
        value: fromDt,
        text: "From Date",
        name: fromDt.toString(),
      },
      toDate: {
        id: "toDate",
        value: toDt,
        text: "To Date",
        name: toDt.toString(),
      },
      errors: null,
    }
  }

  componentDidMount() {
    this.setState(this.props.filters);

    let currentFilters = {};
    Object.keys(this.props.filters).forEach((key) => {
      let tempObj = this.props.filters[key];
      if (!_.isEmpty(tempObj) && typeof tempObj === 'object') {
        currentFilters[key] = tempObj;
      }
    });

    this.currentFilterItems = { ...currentFilters };
  }

  filteredList = (allParticipants, allAccounts) => {
    this.participants = allParticipants;
    this.accounts = allAccounts;

    if (this.state.firm) {
      this.participants = allParticipants.filter(
        (participant) => participant.firm === this.state.firm.value
      );
      this.accounts = allAccounts.filter(
        (account) => account.associatedFirm === this.state.firm.value
      );
    } else if (this.state.participant) {
      let participant = allParticipants.find(
        (participant) => participant.name === this.state.participant.value
      );
      this.accounts = allAccounts.filter(
        (account) => account.associatedFirm === participant.firm
      );
    }
  };

  onFlip = (state) => {
    if (state.visible) {
      this.oldState = Object.assign({}, { ...this.state });
    } else {
      if (state.isDirty) {
        this.setState({ ...this.oldState });
      }
    }
  };

  updateFilter = (filter) => {
    let filterItems = this.state;
    if (
      filter.value === null ||
      filter.value === undefined ||
      filter.value.toString().trim() === ""
    ) {
      delete filterItems[filter.id];
    } else {
      filterItems[filter.id] = filter;
    }

    this.setState(filterItems);
  };

  onReset = () => {
    this.setState({ ...this.getDefaultState() }, () => {
      this.initiateSearch();
    });
  };

  onApply = () => {
    if (this.props.onApply) {
      this.initiateSearch();
    }
  };

  onRemoveFilterItem = (filterItemId) => {
    delete this.state[filterItemId];
    this.setState(this.state, () => {
      this.initiateSearch();
    });
  };

  initiateSearch() {
    this.props.onApply({ ...this.state });
  }

  validate() {
    if (
      this.state.fromDate &&
      this.state.toDate &&
      this.state.fromDate > this.state.toDate
    ) {
      this.setState({
        errors: {
          fromDate: "Start date must be before End Date.",
        },
      });
      return false;
    } else {
      this.setState({
        errors: null,
      });
      return true;
    }
  }

  render() {
    this.filteredList(this.props.allParticipants, this.props.allAccounts);
    return (
      <Filter
        className="quotes-filter"
        onApply={this.onApply}
        onReset={this.onReset}
        onRemoveFilterItem={this.onRemoveFilterItem}
        filterItems={this.currentFilterItems}
        onFlip={this.onFlip}
        validate={this.validate}
      >
        <div style={{ marginBottom: "20px" }}>
          <Row>
            <Col as={Col} lg={4} xs={4} md={4}>
              <Form.Label>Quote ID</Form.Label>
              <FormControl
                id="qid"
                placeholder="Quote ID"
                defaultValue={this.state.qid && this.state.qid.value}
                value={this.state.qid && this.state.qid.value}
                onChange={(e) => {
                  let value = e.target.value;
                  this.updateFilter({
                    id: "qid",
                    value: value,
                    text: "Quote ID",
                    name: value,
                  });
                }}
              />
            </Col>

            <Col lg={4} xs={6} md={4}>
              <Form.Label>Insert Time From</Form.Label>
              <DateGroup
                id="fromDate"
                name="fromDate"
                enableTime={true}
                step={15}
                value={
                  this.state.fromDate ? new Date(this.state.fromDate.value) : null
                }
                errors={this.state.errors}
                touched={this.state.touches}
                onChange={(e) => {

                  this.updateFilter({
                    id: "fromDate",
                    value: e,
                    text: "From Date",
                    name: e ? e.toString() : "",
                  });
                }}
                defaultCurrentDate={moment().startOf("day").toDate()}
              />
            </Col>
            <Col as={Col} lg={4} xs={6} md={4}>
              <Form.Label>Insert Time To</Form.Label>
              <DateGroup
                id="toDate"
                name="toDate"
                enableTime={true}
                step={15}
                value={this.state.toDate ? new Date(this.state.toDate.value) : null}
                onChange={(e) => {
                  this.updateFilter({
                    id: "toDate",
                    value: e,
                    text: "To Date",
                    name: e ? e.toString() : "",
                  });
                }}
                defaultCurrentDate={moment().endOf("day").toDate()}
              />
            </Col>
          </Row>
          <Row>
            <Col as={Col} lg={4} xs={4} md={4}>
              <Form.Label>Firm</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  this.updateFilter({
                    id: "firm",
                    value: e.target.value,
                    text: "Firm",
                    name: e.target.options[e.target.selectedIndex].innerText,
                  });
                }}
                value={this.state.firm && this.state.firm.value}
              >
                <option value={""}>All</option>
                {this.props.firms.map((firm, index) => (
                  <option value={firm.name} key={index}>
                    {firm.displayName}
                  </option>
                ))}
              </Form.Control>
            </Col>
            <Col lg={4} xs={4} md={4}>
              <Form.Label>Instrument ID</Form.Label>
              <FormControl
                id="instrumentId"
                name="instrumentId"
                placeholder="Instrument ID"
                defaultValue={
                  this.state.instrumentId ? this.state.instrumentId.value : null
                }
                onChange={(e) => {
                  let value = e.target.value;
                  this.updateFilter({
                    id: "instrumentId",
                    value: value,
                    text: "Instrument ID",
                    name: value,
                  });
                }}
                value={this.state.instrumentId && this.state.instrumentId.value}
              />
            </Col>
            <Col as={Col} lg={4} xs={4} md={4}>
              <Form.Label>Client ID</Form.Label>
              <FormControl
                id="clientId"
                name="clientId"
                type="text"
                defaultValue={this.state.clientId && this.state.clientId.value}
                placeholder="Client ID"
                onChange={(e) => {
                  this.updateFilter({
                    id: "clientId",
                    value: e.target.value,
                    text: "Client ID",
                    name: e.target.value,
                  });
                }}
                value={this.state.clientId && this.state.clientId.value}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '15px' }}>
            <Col as={Col} lg={4} xs={4} md={4}>
              <Form.Label>Account</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  this.updateFilter({
                    id: "account",
                    value: e.target.value,
                    text: "Account",
                    name: e.target.options[e.target.selectedIndex].innerText,
                  });
                }}
                value={this.state.account && this.state.account.value}
              >
                <option value={""}>All</option>
                {this.accounts.map((account, index) => (
                  <option value={account.name} key={index}>
                    {account.displayName}
                  </option>
                ))}
              </Form.Control>
            </Col>
            <Col as={Col} lg={4} xs={4} md={4}>
              <Form.Label>Participant</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  this.updateFilter({
                    id: "participant",
                    value: e.target.value,
                    text: "Participant",
                    name: e.target.options[e.target.selectedIndex].innerText,
                  });
                }}
                value={this.state.participant && this.state.participant.value}
              >
                <option value={""}>All</option>
                {this.participants.map((participant, index) => (
                  <option value={participant.name} key={index}>
                    {participant.displayName}
                  </option>
                ))}
              </Form.Control>
            </Col>
            <div className="form-group col-auto align-self-end">
              <LoaderIcon show={this.props.loading}></LoaderIcon>
            </div>
          </Row>
        </div>
      </Filter>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesFilter);
