const {
  ParticipantGroup, Account, ParticipantGroupState
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb.js');

export default class ParticipantGroupMapper {
  static mapToParticipantGroup(data) {
    var participantGroupToSave = data.account ? new Account() : new ParticipantGroup();

    participantGroupToSave.setId(data.id);
    participantGroupToSave.setState(data.state);
    if (data.account) {
      participantGroupToSave.setAssociatedFirm(data.associatedFirm);
      participantGroupToSave.setParentFirm(data.parentFirm);
    } else {
      participantGroupToSave.setFirm(data.firm.name);
      participantGroupToSave.setState(ParticipantGroupState.PARTICIPANT_GROUP_STATE_ACTIVE);
    }
    
    return participantGroupToSave;
  }
}
