import { convertEnumToDropdownList } from "./util";
import FormatString, { StringFormatOptions } from "./formatString"

const { AlertStatus } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/alert/v1beta1/alert_pb");

export default class AlertStaticData {

  /**
   * 
   * @param {[ids:int]} excludeIds 
   * @returns {[{id:int, name:string}]} options list
   */
  static AlertStatuses(excludeIds) {
    return convertEnumToDropdownList(AlertStatus, excludeIds, new StringFormatOptions(FormatString.stringFormats.titleCase, false));
  }
}