import React, { useState } from "react";
import { connect } from "react-redux";
import Collapse from "react-bootstrap/Collapse";
import { Row, Col } from "react-bootstrap";
import DateGroup from "../../../core/form/DateGroup";
import FieldGroup from "../../../core/form/FieldGroup";
import MultiSelectDropdownListGroup from "../../../core/form/MultiSelectDropdownListGroup";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import ButtonMain from "../../../core/form/ButtonMain";
import { setOISAtts, createOISAtts } from "../../../../modules/instrumentUtils";
import DateAdjustmentService from "../../../../services/DateAdjustmentService";
import DateTimeParser from "../../../../modules/dateTimeParser";
import { FormModes } from "../../../../constants/enumerations";
import {
  makeDropdownOptions,
} from "../../instrumentFormUtils";

const calculationFields = [
  "swapEffectiveDate",
  "maturityDate",
  "unadjustedEffectiveDate",
  "unadjustedMaturityDate",
  "calculationPeriodNumberOfDays",
  "floatFixingDate",
];

const DateCalculatorOis = ({
  values,
  isEditing,
  touched,
  setFieldValue,
  setFieldError,
  handleBlur,
  handleChange,
  enableStartDate,
  calculateDates,
  errors,
  mode,
}) => {
  const onDateCalculator = (
    values,
    setFieldValue,
    setFieldError,
    calculateDates
  ) => {
    const successHandler = (attributes) => {
      let atts = {};

      setOISAtts(attributes, atts);

      calculationFields.forEach((name) => {
        setFieldValue(name, atts[name]);
      });
    };

    const handleError = (err) => {
      setFieldError("dateCalculator", err);
    };

    if (values.startDate) {
      let atts = createOISAtts(values);
      calculateDates(handleError, successHandler, atts, values.startDate);
    } else {
      setFieldError("dateCalculator", "Missing Trade Date");
    }
  };

  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6>
              <strong>Date Calculator</strong>
            </h6>
          </Col>
          <Col>
            <i
              className={`fa fa-fw ${
                open ? "fa-minus" : "fa-plus"
              } custom-plus`}
            />
          </Col>
        </Row>
      </div>

      <Collapse in={open}>
        <div className="collapse-content">
          <h6>Swap Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="startDate"
                name="startDate"
                label="Trade date"
                isRequired={true}
                value={values.startDate ? new Date(values.startDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate
                      ? DateTimeParser.addDays(values.terminationDate, -2)
                      : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(
                  values.isPartialEditable,
                  isEditing,
                  values.startDate
                )}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("startDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup
                id="tenor"
                name="tenor"
                type="textarea"
                label="Tenor"
                setFieldValue={setFieldValue}
                value={values.tenor}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="tenorTimePeriod"
                name="tenorTimePeriod"
                label="Tenor Time Period"
                value={values.tenorTimePeriod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("tenorTimePeriod", e.id)}
                data={InstrumentStaticData.TimePeriodOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="sideConvention"
                name="sideConvention"
                label="Side Convention"
                value={values.sideConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("sideConvention", e.id)}
                data={InstrumentStaticData.SideConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="effectiveDateBusinessDayConvention"
                name="effectiveDateBusinessDayConvention"
                label="Effective Date Business Day Convention"
                value={values.effectiveDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("effectiveDateBusinessDayConvention", e.id)
                }
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="maturityDateBusinessDayConvention"
                name="maturityDateBusinessDayConvention"
                label="Maturity Date Business Day Convention"
                value={values.maturityDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("maturityDateBusinessDayConvention", e.id)
                }
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <FieldGroup
                id="effectiveDateOffset"
                name="effectiveDateOffset"
                type="textarea"
                label="Swap Effective Date Offset"
                isRequired={true}
                setFieldValue={setFieldValue}
                value={values.effectiveDateOffset}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="swapEffectiveDate"
                name="swapEffectiveDate"
                label="Swap Effective Date"
                value={values.swapEffectiveDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("swapEffectiveDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="paymentDateDayType"
                name="paymentDateDayType"
                label="Payment Date Day Type"
                value={values.paymentDateDayType}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("paymentDateDayType", e.id)}
                data={InstrumentStaticData.FixingDatesDayType}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>
          <hr />
          <h6>Fixed Leg Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedPaymentFrequency"
                name="fixedPaymentFrequency"
                label="Fixed Payment Frequency"
                value={values.fixedPaymentFrequency}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("fixedPaymentFrequency", e.id)}
                data={makeDropdownOptions(
                  InstrumentStaticData.FixedPaymentFrequency,
                  false,
                  false
                )}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedCompoundingMethod"
                name="fixedCompoundingMethod"
                label="Fixed Compounding Method"
                value={values.fixedCompoundingMethod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("fixedCompoundingMethod", e.id)}
                data={InstrumentStaticData.CompoundingOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedDayCountConvention"
                name="fixedDayCountConvention"
                label="Fixed Day Count Convention"
                value={values.fixedDayCountConvention}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("fixedDayCountConvention", e.id)}
                data={InstrumentStaticData.DayCountConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedBusinessDayConvention"
                name="fixedBusinessDayConvention"
                label="Fixed Business Day Convention"
                value={values.fixedBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("fixedBusinessDayConvention", e.id)
                }
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="fixedPaymentDateRelativeTo"
                name="fixedPaymentDateRelativeTo"
                label="Fixed Payment Date Relative To"
                value={values.fixedPaymentDateRelativeTo}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) =>
                  setFieldValue("fixedPaymentDateRelativeTo", e.id)
                }
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>

          <hr />
          <h6>Float Leg Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatCompoundingMethod"
                name="floatCompoundingMethod"
                label="Float Compounding Method"
                value={values.floatCompoundingMethod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatCompoundingMethod", e.id)}
                data={InstrumentStaticData.CompoundingOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatBusinessDayConvention"
                name="floatBusinessDayConvention"
                label="Float Business Day Convention"
                value={values.floatBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("floatBusinessDayConvention", e.id)
                }
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup
                id="floatHolidayCalendarsList"
                name="floatHolidayCalendarsList"
                label="Float Holiday Calendars"
                labelWrapperClass="label-wrapper"
                value={values.floatHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"}
                valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatPaymentFrequency"
                name="floatPaymentFrequency"
                label="Float Payment Frequency"
                value={values.floatPaymentFrequency}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatPaymentFrequency", e.id)}
                data={InstrumentStaticData.FloatPaymentFrequency}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatRateResetFrequency"
                name="floatRateResetFrequency"
                label="Float Rate Reset Frequency"
                value={values.floatRateResetFrequency}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatRateResetFrequency", e.id)}
                data={InstrumentStaticData.FloatRateResetFrequency}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatResetDateBusinessDayConvention"
                name="floatResetDateBusinessDayConvention"
                label="Reset Date Business Day Convention"
                value={values.floatResetDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("floatResetDateBusinessDayConvention", e.id)
                }
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>

            <Col>
              <DropdownListGroup
                id="floatFixingDatesDayType"
                name="floatFixingDatesDayType"
                label="Float Fixing Dates Day Type"
                value={values.floatFixingDatesDayType}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatFixingDatesDayType", e.id)}
                data={InstrumentStaticData.FixingDatesDayType}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col>
              <DropdownListGroup
                id="floatPaymentDateRelativeTo"
                name="floatPaymentDateRelativeTo"
                label="Float Payment Date Relative To"
                value={values.floatPaymentDateRelativeTo}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) =>
                  setFieldValue("floatPaymentDateRelativeTo", e.id)
                }
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatResetDateRelativeTo"
                name="floatResetDateRelativeTo"
                label="Float Reset Date Relative To"
                value={values.floatResetDateRelativeTo}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) =>
                  setFieldValue("floatResetDateRelativeTo", e.id)
                }
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col>
              <FieldGroup
                id="floatFixingDatesOffset"
                name="floatFixingDatesOffset"
                type="textarea"
                label="Float Fixing Dates Offset"
                value={values.floatFixingDatesOffset}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatFixingDateBusinessDayConvention"
                name="floatFixingDateBusinessDayConvention"
                label="Fixing Date Business Day Convention"
                value={values.floatFixingDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("floatFixingDateBusinessDayConvention", e.id)
                }
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup
                id="floatFixingDateHolidayCalendarsList"
                name="floatFixingDateHolidayCalendarsList"
                label="Float Fixing Date Holiday Calendars"
                labelWrapperClass="label-wrapper"
                value={values.floatFixingDateHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("floatFixingDateHolidayCalendarsList", e)
                }
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"}
                valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          {!isViewing && (
            <Row>
              <Col lg={9} xs={9} md={3}></Col>
              <Col lg={3} xs={3} md={3}>
                <ButtonMain
                  type="button"
                  text="Calculate Dates"
                  onClick={() =>
                    onDateCalculator(
                      values,
                      setFieldValue,
                      setFieldError,
                      calculateDates
                    )
                  }
                />
                {errors && errors.dateCalculator && (
                  <div className="form-error mt-2">
                    <i
                      className="fa fa-exclamation-triangle orange-icon"
                      aria-hidden="true"
                    ></i>{" "}
                    {errors.dateCalculator}
                  </div>
                )}
              </Col>
            </Row>
          )}
        </div>
      </Collapse>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  calculateDates: (handleError, successHandler, atts, startDate) => {
    dispatch(
      DateAdjustmentService.adjustDates(
        handleError,
        successHandler,
        atts,
        startDate
      )
    );
  },
});

export const ValidateDateCalculatorOis = (values, errors) => {
  calculationFields.forEach((field) => {
    if (!values[field]) {
      errors["dateCalculator"] = "You must calculate dates";
    }
  });
  return errors;
};

export default connect(null, mapDispatchToProps)(DateCalculatorOis);
