import {
  FETCH_FIRMS_START,
  FETCH_FIRMS_SUCCEED,
  FETCH_FIRMS_FAILED,
  FETCH_FIRM_START,
  FETCH_FIRM_SUCCEED,
  FETCH_FIRM_FAILED,
  FETCH_FIRM_SERVICES_START,
  FETCH_FIRM_SERVICES_SUCCEED,
  FETCH_FIRM_SERVICES_FAILED,
  FETCH_FIRM_SERVICE_KEYS_START,
  FETCH_FIRM_SERVICE_KEYS_SUCCEED,
  FETCH_FIRM_SERVICE_KEYS_FAILED,
  CLEAN_FIRM,
  SAVE_FIRM_START,
  SAVE_FIRM_SUCCEED,
  SAVE_FIRM_FAILED,
  CREATE_FIRM_START,
  UPDATE_FIRM_ITEM,
  DELETE_FIRM_FAILED,
  DELETE_FIRM_START,
  DELETE_FIRM_SUCCEED,
  UPDATE_FIRM_FAILED,
  UPDATE_FIRM_START,
  UPDATE_FIRM_SUCCEED,
  FETCH_ASSOCIATED_FIRMS_START,
  FETCH_AFFILIATED_FIRMS_SUCCEED,
  FETCH_ASSOCIATED_RFQ_FIRMS_SUCCEED,
  FETCH_ASSOCIATED_AGENT_FIRMS_SUCCEED,
  FETCH_ASSOCIATED_FIRMS_SUCCEED,
  FETCH_ASSOCIATED_CLEARING_HOUSES_SUCCEED,
  FETCH_ASSOCIATED_FIRMS_FAILED,
  UPDATE_ASSOCIATED_FIRMS_START,
  UPDATE_ASSOCIATED_FIRMS_SUCCEED,
  UPDATE_ASSOCIATED_FIRMS_FAILED,
  TOGGLE_SHOW_DELETED_FIRMS,
  RESET_FIRMS,
} from "../constants/firmTypes";

import Firm from "../entities/Firm";
import FirmAttributes from "../entities/FirmAttributes";
import * as firmService from "../services/FirmService";

export const FetchFirmsStart = () => ({
  type: FETCH_FIRMS_START,
});

export const FetchFirmsSucceed = (firms) => ({
  type: FETCH_FIRMS_SUCCEED,
  payload: firms,
});

export const FetchFirmsFailed = (error) => ({
  type: FETCH_FIRMS_FAILED,
  payload: error,
});

export const FetchFirmStart = () => ({
  type: FETCH_FIRM_START,
});

export const FetchFirmSucceed = (firm) => ({
  type: FETCH_FIRM_SUCCEED,
  payload: firm,
});

export const FetchFirmFailed = (error) => ({
  type: FETCH_FIRM_FAILED,
  payload: error,
});

export const FetchFirmServicesStart = () => ({
  type: FETCH_FIRM_SERVICES_START,
});

export const FetchFirmServicesSucceed = (services) => ({
  type: FETCH_FIRM_SERVICES_SUCCEED,
  payload: services,
});

export const FetchFirmServicesFailed = (error) => ({
  type: FETCH_FIRM_SERVICES_FAILED,
  payload: error,
});

export const FetchFirmServiceKeysStart = () => ({
  type: FETCH_FIRM_SERVICE_KEYS_START,
});

export const FetchFirmServiceKeysSucceed = (keys) => ({
  type: FETCH_FIRM_SERVICE_KEYS_SUCCEED,
  payload: keys,
});

export const FetchFirmServiceKeysFailed = (error) => ({
  type: FETCH_FIRM_SERVICE_KEYS_FAILED,
  payload: error,
});

export const CleanFirm = () => ({
  type: CLEAN_FIRM,
});

export const SaveFirmStart = () => ({
  type: SAVE_FIRM_START,
});

export const SaveFirmSucceed = () => ({
  type: SAVE_FIRM_SUCCEED,
});

export const SaveFirmFailed = (error) => ({
  type: SAVE_FIRM_FAILED,
  payload: error,
});

export const IsCreatingFirm = (isCreating) => ({
  type: CREATE_FIRM_START,
  value: isCreating,
});

export const UpdateFirmItem = (key, value) => ({
  type: UPDATE_FIRM_ITEM,
  key: key,
  value: value,
});

export const DeleteFirmStart = () => ({
  type: DELETE_FIRM_START,
});

export const DeleteFirmSucceed = () => ({
  type: DELETE_FIRM_SUCCEED,
});

export const DeleteFirmFailed = (error) => ({
  type: DELETE_FIRM_FAILED,
  payload: error,
});

export const UpdateFirmStart = () => ({
  type: UPDATE_FIRM_START,
});

export const UpdateFirmSucceed = () => ({
  type: UPDATE_FIRM_SUCCEED,
});

export const UpdateFirmFailed = (error) => ({
  type: UPDATE_FIRM_FAILED,
  payload: error,
});

export const FetchAssociatedFirmsStart = () => ({
  type: FETCH_ASSOCIATED_FIRMS_START,
});

export const FetchAffiliatedFirmsSucceed = (affiliatedFirms) => ({
  type: FETCH_AFFILIATED_FIRMS_SUCCEED,
  payload: affiliatedFirms,
});

export const FetchAssociatedRFQFirmsSucceed = (associatedFirms) => ({
  type: FETCH_ASSOCIATED_RFQ_FIRMS_SUCCEED,
  payload: associatedFirms,
});

export const FetchAssociatedAgentFirmsSucceed = (associatedFirms) => ({
  type: FETCH_ASSOCIATED_AGENT_FIRMS_SUCCEED,
  payload: associatedFirms,
});

export const FetchAssociatedFirmsSucceed = (associatedFirms) => ({
  type: FETCH_ASSOCIATED_FIRMS_SUCCEED,
  payload: associatedFirms,
});

export const FetchAssociatedClearingHousesSucceed = (
  associatedClearingHouses
) => ({
  type: FETCH_ASSOCIATED_CLEARING_HOUSES_SUCCEED,
  payload: associatedClearingHouses,
});

export const FetchAssociatedFirmsFailed = (error) => ({
  type: FETCH_ASSOCIATED_FIRMS_FAILED,
  payload: error,
});

export const UpdateAssociatedFirmStart = (id, associatedFirms) => ({
  type: UPDATE_ASSOCIATED_FIRMS_START,
  id: id,
  associatedFirms: associatedFirms,
});

export const UpdateAssociatedFirmSucceed = () => ({
  type: UPDATE_ASSOCIATED_FIRMS_SUCCEED,
});

export const UpdateAssociatedFirmFailed = (error) => ({
  type: UPDATE_ASSOCIATED_FIRMS_FAILED,
  payload: error,
});

export const ToggleShowDeletedFirms = () => {
  return {
    type: TOGGLE_SHOW_DELETED_FIRMS,
  };
};

export const ResetFirms = () => ({
  type: RESET_FIRMS,
});

export const LoadFirms = (firmType = null, showDeleted = false, srcCb = null) => {
  return (dispatch) => {
    dispatch(FetchFirmsStart());

    const cb = (err, response) => {
      if (response) {
        var firmsList = response.getFirmsList().map((firm) => {
          return new Firm(firm);
        });
        dispatch(FetchFirmsSucceed(firmsList));
      }

      if (err) {
        dispatch(FetchFirmsFailed());
      }

      if (!!srcCb) srcCb(err, response);
    };

    firmService.getAll(cb, firmType, showDeleted);
  };
};

export const LoadFirmAttributes = (resourceName) => {
  return (dispatch) => {
    firmService
      .getAttributes(resourceName)
      .catch((err) => {
        console.error("couldn't get firm attributes");
      })
      .then((response) => {
        if (response) {
          dispatch({
            type: UPDATE_FIRM_ITEM,
            key: "attributes",
            value: new FirmAttributes(response.getAttributes()),
          });
        }
      });
  };
};

export const UnDeleteFirm = async (resourceName) => {
  return await firmService.unDeleteFirm(resourceName);
};
