import {
  FETCH_BOOKS_START,
  FETCH_BOOKS_SUCCEED,
  FETCH_BOOKS_FAILED,
  SEARCH_BOOKS_NO_ITEMS,
  RESET_BOOKS

} from '../constants/bookTypes';
import {
  RESET,
} from '../constants/appTypes';

const initialState = {
  fetchingBooks: false,
  books: [],
  noItemsFound: false
}

const books = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BOOKS_START:
      return {
        ...state,
        fetchingBooks: true,
        noItemsFound: false
      };
    case FETCH_BOOKS_SUCCEED:
      return {
        ...state,
        fetchingBooks: false,
        books: action.payload
      };
    case FETCH_BOOKS_FAILED:
      return {
        ...state,
        fetchingBooks: false,
        books: [],
        noItemsFound : false
      };
    case SEARCH_BOOKS_NO_ITEMS:
      return {
        ...state,
        noItemsFound: true
      };
    case RESET_BOOKS:
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
  
    default:
      return state;
  }
}

export default books;
