import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import { push } from "connected-react-router";

import { logout } from "../../../actions/auth";
import { store } from "../../../store";

import UserLicense from "../../users/UserLicense"

import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import '@trendmicro/react-tooltip/dist/react-tooltip.css';
import "./index.css";
import "../../../App.css";
import PageRoute from "../PageRoute";
import { Container } from "react-bootstrap";
import { license } from "../../core/icons";
import {Env} from "../../../constants/environment";

const Menu = ({ auth, location, logout }) => {
  let routes = [
    {
      route: "firms",
      label: "Firms",
      icon: "fa-city",
    },
    {
      route: "users",
      label: "Users",
      icon: "fa-users",
    },
    {
      route: "accounts",
      label: "Accounts",
      icon: "fa-id-card",
    },
    {
      route: "participant-groups",
      label: "Groups",
      icon: "fa-handshake",
    },
    {
      route: "instruments",
      label: "Instruments",
      icon: "fa-list-ul",
    },
    {
      route: "timeandsales",
      label: "Time & Sales",
      icon: "fa-calendar-check",
    },
    {
      route: "order-search/orders",
      label: "Order Search",
      icon: "fa-search",
    },
    {
      route: "requestForQuotes/search",
      label: "RFQ Search",
      icon: "fa-search-dollar",
    },
    {
      route: "admin-requests",
      label: "Admin Requests",
      icon: "fa-check",
    },
    {
      route: "jobs",
      label: "Jobs",
      icon: "fa-terminal",
    },
    {
      route: "alerts",
      label: "Alerts",
      icon: "fa-bell",
    },
    {
      route: "change-password",
      label: "Change Password",
      icon: "fa-shield-alt",
    },
    {
      route: "",
      label: "License",
      icon: null,
      iconComponent: license,
    },
    {
      route: "logout",
      label: "Logoff",
      icon: "fa-sign-out-alt",
    },
  ];

  let currentPage;
  if (window.location.pathname.includes("/accounts")) {
    currentPage = "accounts";
  } else if (window.location.pathname.includes("/users")) {
    currentPage = "users";
  } else if (window.location.pathname.includes("/participant-groups")) {
    currentPage = "participant-groups";
  } else if (window.location.pathname.includes("/instruments")) {
    currentPage = "instruments";
  } else if (window.location.pathname.includes("/timeandsales")) {
    currentPage = "timeandsales";
  } else if (window.location.pathname.includes("/order-search")) {
    currentPage = "order-search/orders";
  } else if (window.location.pathname.includes("/requestForQuotes/search")) {
    currentPage = "requestForQuotes/search";
  } else if (window.location.pathname.includes("/firms")) {
    currentPage = "firms";
  } else if (window.location.pathname.includes("/jobs")) {
    currentPage = "jobs";
  } else if (window.location.pathname.includes("/alerts")) {
    currentPage = "alerts";
  } else if (window.location.pathname.includes("/change-password")) {
    currentPage = "change-password";
  } else if (window.location.pathname.includes("/admin-requests")) {
    currentPage = "admin-requests";
  }

  routes = routes.map((route) => {
    if (route.route === currentPage) route.icon = `${route.icon} selected-icon`;
    return route;
  });

  const onSelect = (selected) => {
    if (selected === "logout") {
      logout(auth.accessToken, auth.refreshToken);
    } else {
      let to = "/" + selected;
      if (location.pathname !== to) {
        store.dispatch(push(to));
      }
    }
  };

  return (
    <React.Fragment>
      <SideNav
        onSelect={(selected) => {
          if (selected) {
            onSelect(selected);
          }
        }}
        className="side-bar-navigation non-printable"
      >
        <SideNav.Toggle className="side-bar-toggle-button" />
        <SideNav.Nav defaultSelected="">
          <NavItem
            className="side-bar-navigation nav-logo-cont"
            disabled={true}
            active={true}
          >
            <NavIcon>
              <img
                className="nav-logo-icon"
                src={"/images/4-color-icon.png"}
                alt="connamara logo"
              />
            </NavIcon>
            <NavText className="nav-text" id="nav-logo-text">
              CONNAMARA
            </NavText>
          </NavItem>

          {routes.map((route) => (
            <PageRoute
              key={route.route}
              routeLabel={route.label}
              routeKey={route.route}
              routeIcon={route.icon}
              routeIconComponent={route.iconComponent}
              onSelect={onSelect}
            />
          ))}

          <NavItem disabled={true} active={false} className="hoverDisabled">
            <NavIcon />
            <NavText className="nav-text">
              <div><Container>{Env.getEnv("REACT_APP_VERSION")}</Container></div>
            </NavText>
          </NavItem>

          <NavItem disabled={true} active={false} className="hoverDisabled">
            <NavIcon />
            <NavText className="nav-text"><UserLicense /></NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, { logout, push })(Menu));
