const {
  TimeOfDay
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/type/v1beta1/type_pb.js');

export default class TimeOfDayMapper {
  static mapToTimeOfDay(data) {
    var timeOfDayToSave = new TimeOfDay();

    timeOfDayToSave.setHours(data.hours);
    timeOfDayToSave.setMinutes(data.minutes);
    timeOfDayToSave.setSeconds(data.seconds);

    return timeOfDayToSave;
  }
}
