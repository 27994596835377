import React, { Component } from "react";
import { withFormik } from "formik";
import Filter from "../core/filter/Filter";
import TimeSalesFilterForm from "./TimeSalesFilterForm";

class BasicTimeSalesFilter extends Component {

  applyFilter = () => {
    if (!!this.props.onApplyFilter) {
      let vals = { ...this.props.values }
      if (vals.fromDate instanceof Date && !isNaN(vals.fromDate)) {
        vals.fromDate = vals.fromDate.getTime(); // Convert to milliseconds
      }

      if (vals.toDate instanceof Date && !isNaN(vals.toDate)) {
        vals.toDate = vals.toDate.getTime(); // Convert to milliseconds
      }

      this.props.onApplyFilter(vals)
    }

    if (this.props.onApply) {
      this.props.onApply(this.state);
    }
  };

  render() {
    const {
      values,
      errors,
      handleChange,
      setFieldValue,
      autoCompleteDataSource,
      onRefreshAutoComplete,
    } = this.props;

    return (
      <Filter
        className="instruments-filter"
        onApply={this.applyFilter}
        onReset={this.props.onResetFilter}
        onRemoveFilterItem={this.onRemoveFilterItem}
        filterItems={this.currentFilterItems}
        onFlip={this.onFlip}
      >
        <TimeSalesFilterForm
          values={this.props.values}
          setFieldValue={setFieldValue}
          errors={errors}
          handleChange={handleChange}
          loading={this.props.loading}
          autoCompleteDataSource={autoCompleteDataSource}
          onRefreshAutoComplete={onRefreshAutoComplete}
        />
      </Filter>
    );
  }
}

const TimeSalesFilter = withFormik({
  mapPropsToValues: (props) => ({
    ...props.filters,
  }),
  validate: (values) => {
    let errors = {};
    if (
      values.fromDate !== null &&
      values.toDate !== null &&
      values.fromDate >= values.toDate
    )
      errors.fromDate = "Start date must be before End Date.";

    return errors;
  }
})(BasicTimeSalesFilter);

export default TimeSalesFilter;
