import React from "react";
import { Route, Switch } from "react-router";

import PublicRoute from "./components/shared/PublicRoute";
import PrivateRoute from "./components/shared/PrivateRoute";
import AdminWrapper from "./components/shared/AdminWrapper";
import DefaultWrapper from "./components/shared/DefaultWrapper";

import Login from "./pages/Login";
import Products from "./pages/Products";
import ProductFormPage from "./pages/ProductFormPage";
import Instruments from "./pages/Instruments";
import InstrumentFormPage from "./pages/InstrumentFormPage";
import Calendars from "./pages/Calendars";
import ParticipantGroups from "./pages/ParticipantGroups";
import ParticipantGroupFormPage from "./pages/ParticipantGroupFormPage";
import OrdersSearchFormPage from "./pages/OrdersSearchFormPage";
import BooksPage from "./pages/BooksPage";
import Users from "./pages/Users";
import UsersFormPage from "./pages/UsersFormPage";
import TimeSalesPage from "./pages/TimeSalesPage";
import TimeSalesTradeFormPage from "./pages/TimeSalesTradeFormPage";
import NoMatch from "./pages/NoMatch";
import Firms from "./pages/Firms";
import FirmFormPage from "./pages/FirmFormPage";
import AccountFormPage from "./pages/AccountFormPage";
import Accounts from "./pages/Accounts";
import RFQSearchPage from "./pages/RFQSearchPage";
import QuotesPage from "./pages/QuotesPage";
import QuotesSearchPage from "./pages/QuotesSearchPage";
import QuoteHistory from "./pages/QuoteHistory";
import ChangePassword from "./pages/ChangePassword";
import { AddBrowserHistory } from "./actions/app"
import AlertsPage from "./pages/AlertsPage";
import Jobs from "./pages/Jobs";
import AdminRequestsPage from "./pages/AdminRequestsPage";
import HistoricalMarketStatsPage from "./pages/HistoricalMarketStatsPage";

const wrapComponent = (Component, Wrapper) => {
  return class Wrapped extends React.Component {
    render() {
      return (
        <Wrapper {...this.props}>
          <Component {...this.props} />
        </Wrapper>
      );
    }
  };
};

const privateRoutes = [
  { "/firms": Firms },
  { "/firms/new": FirmFormPage },
  { "/firms/new/:firmType": FirmFormPage },
  { "/firms/:id": FirmFormPage },
  { "/firms/:id/edit": FirmFormPage },
  { "/firms/:id/users": FirmFormPage },
  { "/firms/:id/accounts": FirmFormPage },
  { "/firms/:id/association": FirmFormPage },
  { "/firms/:id/services": FirmFormPage },
  { "/firms/:firmid/services/newService": UsersFormPage },
  { "/firms/:firmid/services/:id": UsersFormPage },
  { "/firms/:firmid/services/:id/edit": UsersFormPage },
  { "/firms/:id/services/:firmServiceId/keys": FirmFormPage },
  { "/firms/:id/services/:firmServiceId/keys/new": FirmFormPage },
  { "/firms/:id/services/:firmServiceId/keys/:apiKeyId": FirmFormPage },
  { "/firms/:id/services/:firmServiceId/keys/:apiKeyId/edit": FirmFormPage },

  { "/users": Users },
  { "/users/new": UsersFormPage },
  { "/users/newService": UsersFormPage },
  { "/users/:id": UsersFormPage },
  { "/users/:id/new": UsersFormPage },
  { "/users/:id/newService": UsersFormPage },
  { "/users/:id/edit": UsersFormPage },
  { "/firms/:firmid/users/:id": UsersFormPage },
  { "/firms/:firmid/users/:id/edit": UsersFormPage },
  { "/firms/:firmid/users/:id/accounts": UsersFormPage },

  { "/accounts": Accounts },
  { "/accounts/new": AccountFormPage },
  { "/firms/:firmid/accounts/:id": AccountFormPage },
  { "/firms/:firmid/accounts/:id/edit": AccountFormPage },
  { "/firms/:firmid/accounts/:id/users": AccountFormPage },
  { "/firms/:firmid/accounts/:id/positions": AccountFormPage },
  { "/firms/:firmid/accounts/:id/balances": AccountFormPage },
  { "/firms/:firmid/accounts/:id/ledger": AccountFormPage },
  { "/firms/:firmid/accounts/:id/ledger/balance-ledger": AccountFormPage },
  { "/firms/:firmid/accounts/:id/ledger/position-ledger": AccountFormPage },
  { "/firms/:firmid/accounts/:id/whitelist": AccountFormPage },
  { "/firms/:firmid/accounts/:id/commissions": AccountFormPage },
  { "/firms/:firmid/accounts/:id/commissions/new": AccountFormPage },
  { "/firms/:firmid/accounts/:id/commissions/:commid": AccountFormPage },
  { "/firms/:firmid/accounts/:id/association": AccountFormPage },
  { "/firms/:firmid/accounts/:id/bankDetails": AccountFormPage },

  { "/participant-groups": ParticipantGroups },
  { "/participant-groups/new": ParticipantGroupFormPage },
  { "/participant-groups/:id": ParticipantGroupFormPage },
  { "/participant-groups/:id/edit": ParticipantGroupFormPage },

  { "/instruments": Instruments },
  { "/instruments/new": InstrumentFormPage },
  { "/instruments/:id": InstrumentFormPage },
  { "/instruments/:id/edit": InstrumentFormPage },
  { "/instruments/:id/copy": InstrumentFormPage },
  { "/instruments/:id/changeState": InstrumentFormPage },
  { "/instruments/:id/multiState": InstrumentFormPage },
  { "/instruments/:id/marketStats": InstrumentFormPage },
  { "/instruments/:id/historicalMarketStats": HistoricalMarketStatsPage},

  { "/products": Products },
  { "/products/new": ProductFormPage },
  { "/products/:id": ProductFormPage },
  { "/products/:id/edit": ProductFormPage },
  { "/products/:id/copy": ProductFormPage },

  { "/calendars": Calendars },

  { "/timeandsales": TimeSalesPage },
  { "/timeandsales/:tradecaptureid/correct": TimeSalesTradeFormPage },
  { "/timeandsales/:tradecaptureid/bust": TimeSalesTradeFormPage },
  { "/timeandsales/:tradecaptureid/details": TimeSalesTradeFormPage },

  { "/order-search/orders": OrdersSearchFormPage },
  { "/order-search/executions": OrdersSearchFormPage },

  { "/requestForQuotes/search": RFQSearchPage },
  { "/requestForQuotes/quotes": QuotesPage },
  { "/requestForQuotes/quotes/:id": QuotesPage },
  { "/requestForQuotes/quoteHistory/:id": QuoteHistory },

  { "/quotes/search": QuotesSearchPage },

  { "/books": BooksPage },

  { "/change-password": ChangePassword },
  { "/alerts": AlertsPage },
  { "/jobs": Jobs },

  { "/admin-requests": AdminRequestsPage}
];

const Routes = () => {
  AddBrowserHistory(window.location.pathname);
  return (
    <Switch>
      {/* non-authenticated routes */}
      <PublicRoute
        exact
        path="/"
        authRedirectTo="/firms"
        component={wrapComponent(Login, DefaultWrapper)}
      />

      {/* normal user routes */}
      {privateRoutes.map((route) => {
        const [path, component] = Object.entries(route)[0];
        return (
          <PrivateRoute
            key={path}
            path={path}
            exact
            component={wrapComponent(component, AdminWrapper)}
          />
        );
      })}

      {/* Four oh Four */}
      <Route component={wrapComponent(NoMatch, DefaultWrapper)} />
    </Switch>
  );
};

export default Routes;
