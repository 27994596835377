import ApiCall from "../modules/apiCall";
import apiCallPromise from "../modules/apiCallPromise";
import {apiUrl} from "../modules/util";

const {
  GetTradeCaptureReportRequest,
  UpdateTradeStateRequest,
  UpdateReportingCounterpartyRequest,
  ListTradeCaptureReportsRequest
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");
const {
  AdminAPIClient,
  AdminAPIPromiseClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");

const client = new AdminAPIClient(apiUrl(), null, null);
const promiseClient = new AdminAPIPromiseClient(
  apiUrl(),
  null,
  null
);

export default class TradeService {
  static getTradeCaptureReport(tradeCaptureId, cb) {
    var request = new GetTradeCaptureReportRequest();
    request.setTcrId(tradeCaptureId);

    ApiCall(client, "getTradeCaptureReport", request, cb);
  }

  static updateTrade(tradeId, state, txt, cb) {
    var request = new UpdateTradeStateRequest();
    request.setTradeId(tradeId);
    request.setState(state);
    request.setText(txt);

    ApiCall(client, "updateTradeState", request, cb);
  }

  static async updateReportingCounterParty(tradeId, reportingCounterParty) {
    var request = new UpdateReportingCounterpartyRequest();
    request.setReportingCounterparty(reportingCounterParty);
    request.setTradeId(tradeId);
    return await apiCallPromise(
      promiseClient,
      "updateReportingCounterparty",
      request
    );
  }

  static async getTradeCaptureReport2(tradeCaptureId) {
    var request = new GetTradeCaptureReportRequest();
    request.setTcrId(tradeCaptureId);

    return await apiCallPromise(
      promiseClient,
      "getTradeCaptureReport",
      request
    );
  }

  static async listTradeCaptureReports(tradeId) {
    let request = new ListTradeCaptureReportsRequest();
    request.setTradeId(tradeId);
    request.setNewestFirst(true);
    
    return await apiCallPromise(
      promiseClient,
      "listTradeCaptureReports",
      request
    );
  }
}
