import React, { useEffect, useState } from 'react';
import { Col, Row, } from "react-bootstrap";
import Notification from "../../modules/notifications";
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import AccountService from "../../services/AccountService";
import AutoComplete from "../core/auto-complete/AutoComplete";
import { FormGroup, FormLabel, FormControl, Container } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import DropdownListGroup from "../core/form/DropdownListGroup";
import InstrumentStaticData from "../../modules/instrumentStaticData";
import {isNullOrUndefined} from "../../modules/util";

export default function SecurityForm(props) {
  const { show, reload, account, onComplete, currencies, securities } = props;

  const formData = !!props.data ? {
    ...props.data,
    securityDescription: !isNullOrUndefined(securities[props.data.securityId]) ? securities[props.data.securityId].description : '',
    securityType: !isNullOrUndefined(securities[props.data.securityId]) ? securities[props.data.securityId].securityType : '',
  } : {};

  const [securityId, setSecurityId] = useState(formData.securityId);
  const [securityDescription, setSecurityDescription] = useState(formData.securityDescription);
  const [securityType, setSecurityType] = useState(formData.securityType);
  const [balance, setBalance] = useState(formData.balance);
  const [marketValue, setMarketValue] = useState(formData.marketValue);
  const [currency, setCurrency] = useState(formData.currency);
  const [haircut, setHaircut] = useState(formData.haircut);
  const [description, setDescription] = useState(formData.description);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setSecurityId(formData.securityId);
    setSecurityDescription(formData.securityDescription);
    setSecurityType(formData.securityType);
    setBalance(formData.balance);
    setMarketValue(formData.marketValue);
    setCurrency(formData.currency);
    setHaircut(formData.haircut);
    setDescription(formData.description);
    setSubmitted(false);
  }, [props.data]);


  const validateForm = () => {
    const errors = { securityId: "", balance: "", marketValue: "", currency: "", haircut: "", description: "", }
    let isFormValid = true;

    if (!securityId || securityId.length === 0) {
      errors.securityId = "Required.";
      isFormValid = false;
    }

    if (!balance || balance.length === 0) {
      errors.balance = "Required.";
      isFormValid = false;
    }

    if (!marketValue || marketValue.length === 0) {
      errors.marketValue = "Required.";
      isFormValid = false;
    }

    if (!currency || currency.length === 0) {
      errors.currency = "Required.";
      isFormValid = false;
    }

    if (!description || description.length === 0) {
      errors.description = "Required.";
      isFormValid = false;
    }

    setErrors(errors);
    return isFormValid;
  }

  return (<ConfirmDialog
    show={!!show}
    proceed={() => {
      if (!!submitted || !validateForm()) return;
      setSubmitted(true);

      AccountService.setAccountSecurityMarketValue(account, currency, securityId, marketValue, haircut, description, (err, response) => {
        if (err) {
          Notification.error(err.message);
        }

        AccountService.setAccountSecurityBalance(account, currency, securityId, balance, description, (err, response) => {
          if (response) {
            if (!formData.securityId && isNullOrUndefined(securities[securityId])) {
              //If we are in the context of an add, write the security definition
              AccountService.setSecurityDefinition(securityId, securityDescription, securityType, (err, response) => {
                if (response) {
                  Notification.success("Security details and descriptions updated!");
                  reload();
                }
                if (err) {
                  Notification.error(err.message);
                }
              })
            } else {
              //If we are in the context of an edit, do not update the security description
              Notification.success("Security details updated!");
              reload();
            }
          }
          if (err) {
            Notification.error(err.message);
          }

          onComplete();
        });
      });
    }}

    cancel={() => {
      onComplete();
    }}

    confirmation={
      <Container>
        <Row>
          <Col>

            <FormGroup>
              <FormLabel>Security Identifier</FormLabel>
              <AutoComplete
                  id="securityId"
                  placeholder="Enter Security ID"
                  noItemsMessage="No results found"
                  dataSource={Object.keys(securities)}
                  defaultValue={securityId}
                  disabled={!!formData.securityId}
                  onChange={(e) => setSecurityId(e.target.value)}
                  onEnter={(value) => setSecurityId(value)}
                  onSelect={(value) => setSecurityId(value)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <FormLabel>Security Description</FormLabel>
              <FormControl
                  type="text"
                  value={!isNullOrUndefined(securities[securityId]) ? securities[securityId].description : securityDescription}
                  onChange={(e) => setSecurityDescription(e.target.value)}
                  disabled={!!formData.securityId || !isNullOrUndefined(securities[securityId])}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <DropdownListGroup
                id="securityType"
                name="securityType"
                label="Security Type"
                value={!isNullOrUndefined(securities[securityId]) ? securities[securityId].securityType : securityType}
                onChange={(e) => setSecurityType(e.id)}
                data={InstrumentStaticData.SecurityTypeOptions}
                enabled={!formData.securityId && isNullOrUndefined(securities[securityId])}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <FormLabel>Balance (quantity of the security)</FormLabel>
              <FormControl
                type="text"
                value={balance || formData.balance}
                onChange={(e) => setBalance(e.target.value)}
              />
              <div>The total face value of the security you are adding.</div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FieldGroup id="marketValue" name="marketValue" type="textarea" label="Market Value (for a single security)"
              value={marketValue}
              onChange={(e) => setMarketValue(e.target.value)}
              onBlur={(e) => setMarketValue(e.target.value)}
              errors={errors}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <FormLabel>Currency for Market Value</FormLabel>
              <AutoComplete
                id="currency"
                placeholder="Enter Currency"
                noItemsMessage="No results found"
                dataSource={currencies}
                defaultValue={currency}
                disabled={!!formData.securityId}
                onChange={(e) => setCurrency(e.target.value)}
                onEnter={(value) => setCurrency(value)}
                onSelect={(value) => setCurrency(value)}
                errors={errors}
              />
            </FormGroup>
          </Col>
        </Row>



        <Row>
          <Col>
            <FieldGroup id="haircut" name="haircut" type="textarea" label="Haircut (in bps, optional)"
              value={haircut}
              onChange={(e) => setHaircut(e.target.value)}
              onBlur={(e) => setHaircut(e.target.value)}
              errors={errors}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FieldGroup id="description" name="description" type="textarea" label="Description of Change"
              value={description}
              onChange={(e) => { setDescription(e.target.value) }}
              onBlur={(e) => { setDescription(e.target.value) }}
              errors={errors}
              help={"This description will show in the Ledger entry for this change."}
            />
          </Col>
        </Row>
      </Container>
    }

    options={{
      title: formData.securityId ? "Edit Security" : "Add Security",
      okButtonText: "Submit",
      cancelButtonText: "Cancel",
    }}
  />);
}