import React, { Component } from "react";
import { connect } from "react-redux";
import "./Content.css";
import ChangePasswordForm from "../components/change-password/ChangePasswordForm";
import Loader from "../components/core/loader/Loader";
import { TitleBreadcrumb } from "../components/core/title-breadcrumb/title-breadcrumb";

class ChangePassword extends Component {

  render() {
    return (
      <div className="with-callback">
        <TitleBreadcrumb titles={[{ 'title': 'Change Password', 'link': `${window.location.origin}/change-password` }]} />
        <Loader show={this.props.updatingPassword} />
        <div>
          <ChangePasswordForm />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    updatingPassword: state.auth.updatingPassword
  }
}

export default connect(mapStateToProps, null)(ChangePassword);
