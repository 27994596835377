import { AccountState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { convertEnumToDropdownList } from "../modules/util";
import FormatString, { StringFormatOptions } from "./formatString"

const { BankIdType, BankDetailsType } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb.js");

export default class AccountStaticData {
  static getBankIdTypes(excludeIds) {
    return convertEnumToDropdownList(BankIdType, excludeIds);
  }

  static getBankAccountTypes(excludeIds) {
    return convertEnumToDropdownList(BankDetailsType, excludeIds);
  }

  static AccountStates(excludeIds) {
    return convertEnumToDropdownList(AccountState, excludeIds, new StringFormatOptions(FormatString.stringFormats.titleCase, false));
  }

}