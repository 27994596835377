import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import { connect } from "react-redux";
import ButtonMain from "../core/form/ButtonMain";
import { Col, Row } from "react-bootstrap";
import DataGridActions from "../core/data-grid/DataGridActions";
import { hasWriteAccess } from "../../services/TokenService";

import { isNullOrUndefined, getEnumName } from "../../modules/util";
import CreateBankDetailsForm from './CreateBankDetailsForm';
import _ from 'lodash';
import { FormModes } from "../../constants/enumerations";
import confirm from "../../modules/confirmDialog";
import FormatString from "../../modules/formatString"


const { BankIdType, BankDetailsType } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb.js");

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = (dispatch) => ({
});

export class BankDetailsDataGrid extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    openCreateBankDetails = () => {
        this.setState({  showCreateBankDetails: true, mode: FormModes.creation, curBankDetails: {}});
    };

    resetDialogState = () => {
        this.setState({  showCreateBankDetails: null, mode: null, curBankDetailsIdx: null, curBankDetails: {} });
    }

    onView = (row) => {
        this.setState({  showCreateBankDetails: true, mode: FormModes.view, curBankDetailsIdx: row.index, curBankDetails: {...row.original} });
    }

    onEdit = (row) => {
        this.setState({  showCreateBankDetails: true, mode: FormModes.edition, curBankDetailsIdx: row.index, curBankDetails: {...row.original} });
    }

    onRemove = (row, account, onUpdate) => {
        confirm("Are you sure you want to delete this bank account?", {
            title: "Bank Account Deletion",
            okButtonText: "Yes",
            cancelButtonText: "No",
          }).then(
            () => {
                let copy = _.cloneDeep(account)
                copy.bankDetails[row.index] = null
                onUpdate(copy, false, "Bank Account Deleted")
            },
            () => {}
          );
    }

    render() {
        const { account, editable, onUpdate } = this.props;

        let columns = [
            {
                Header: 'BANK ID',
                accessor: 'bankId',
                minWidth: 140,
            },
            {
                Header: 'BANK ID TYPE',
                id: 'bankIdType',
                accessor: (d) => !!(d.bankIdType) ? FormatString.toTitleCase(getEnumName(BankIdType, d.bankIdType)) : '',
                maxWidth: 120,
            },
            {
                Header: 'ACCOUNT TITLE',
                accessor: 'accountTitle',
            },
            {
                Header: 'CASH ACCOUNT NUMBER',
                accessor: 'cashAccountNumber',
                minWidth: 120,
            },
            {
                Header: 'SECURITIES ACCOUNT NUMBER',
                accessor: 'securitiesAccountNumber',
                minWidth: 120,
            },
            {
                Header: 'BENEFICIARY NAME',
                accessor: 'beneficiaryName',
            },
            {
                Header: 'BANK ACCOUNT TYPE',
                id: 'bankAccountType',
                accessor: (d) => !isNullOrUndefined(d.bankAccountType) ? FormatString.toTitleCase(getEnumName(BankDetailsType, d.bankAccountType)) : '',
            },
            {
                Header: 'DEFAULT',
                id: 'pb_default',
                accessor: (d) => (!!d.pb_default) ? (!!d.pb_default).toString() : '',
                maxWidth: 90,
            },
        ];

        if (!!editable && hasWriteAccess()) {
            columns.push({
                Header: () => {
                    return (
                        <DataGridActions
                        />
                    );
                },
                maxWidth: 125,
                minWidth: 125,
                sortable: false,
                Cell: (row) => {
                    return (
                        <DataGridActions
                            onView={() => this.onView(row)}
                            onEdit={() => this.onEdit(row)}
                            onRemove={() => this.onRemove(row, account, onUpdate)}
                        />
                    );
                },
            })
        }

        return (
            <Row>
                <Col>
                    <div>
                    <Row className="page-header">
                        <Col lg={12} xs={12} md={12}>
                            {hasWriteAccess() ? (
                                <ButtonMain
                                    text="Add New Bank Account"
                                    type="button"
                                    onClick={this.openCreateBankDetails}
                                    style={{ float: "right" }}
                                    customClassName="btn-main-header"
                                />
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <CreateBankDetailsForm
                            show={!isNullOrUndefined(this.state.showCreateBankDetails)}
                            mode={!isNullOrUndefined(this.state.mode) ? this.state.mode : FormModes.view}
                            createBankDetails={(details) => {
                                let copy = _.cloneDeep(account)
                                copy.bankDetails.push(details)
                                onUpdate(copy, false, "Bank Account Created")
                            }}
                            updateBankDetails={(details) => {
                                let copy = _.cloneDeep(account)
                                if (!isNullOrUndefined(this.state.curBankDetailsIdx)) {
                                    copy.bankDetails[this.state.curBankDetailsIdx] = details
                                    onUpdate(copy, false, "Bank Account Updated")
                                }
                            }}
                            onComplete={() => this.resetDialogState()}
                            curBankDetails={!isNullOrUndefined(this.state.curBankDetails) ? this.state.curBankDetails : {}}
                        />
                        <Col>
                            <DataGrid
                                data={account.bankDetails}
                                columns={columns}
                                filterable={false}
                            />
                        </Col>
                    </Row>
                    </div>
                </Col>
            </Row>
        );
    }
}

BankDetailsDataGrid.propTypes = {
    account: PropTypes.object.isRequired,
    editable: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsDataGrid);
