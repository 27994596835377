import * as React from "react";
export const Spinner = ({ onUpButtonPress, onDownButtonPress, disabled }) => {
    return (<div className="spinner-button-container">
        <button disabled={disabled} className="spinner-button spinner-button-up" onClick={(e) => {
            e.preventDefault();
            onUpButtonPress();
        }}>
            ▴
        </button>
        <button disabled={disabled} className="spinner-button spinner-button-down" onClick={(e) => {
            e.preventDefault();
            onDownButtonPress()
        }}>
            ▾
        </button>
    </div>);
};