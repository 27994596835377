import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import { validateField, NUMBER_TYPE, STRING_TYPE } from '../instrumentFormUtils'
import TradingHours from "../../core/TradingHours";
import FieldGroup from "../../core/form/FieldGroup";
import FirmsListContainer from "../../../containers/FirmsListContainer";
import { FirmType } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb';
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import { TradingHourPeriodicity } from "../../../constants/enumerations";
import * as Yup from "yup";
import State from "../../../entities/dto/State";
import { capitalizeWord, getEnumName } from "../../../modules/util";
import { FormModes } from "../../../constants/enumerations"
import * as toolTips from '../../../constants/instrumentToolTip';
import ErrorLabel from "../../core/form/ErrorLabel";
import DropdownListGroup from "../../core/form/DropdownListGroup";
import InstrumentStaticData from "../../../modules/instrumentStaticData";
import TradeDayRollSchedule from "../../core/TradeDayRollSchedule/TradeDayRollSchedule";
import Tooltip from '../../core/tooltip-html/tooltip-html';

export const TradingInfo = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  metadata,
  holidays,
  handleChange,
  updateState,
  errors,
  mode
}) => {

  const timezone = metadata && metadata.location ? metadata.location : "UTC";
  const [open, setOpen] = useState(true);
  const isViewing = mode === FormModes.view;

  let filteredScheduleList = [];

  if (values.tradingScheduleList) {
    filteredScheduleList = values.tradingScheduleList.filter((schedule) => schedule.daysOfWeekList.length !== 0);
  }

  const removeSchedule = (index) => {
    const tradingScheduleList = values.tradingScheduleList;
    tradingScheduleList.splice(index, 1);
    setFieldValue("tradingScheduleList", tradingScheduleList);
  };

  const addHolidayCalendar = (cal) => {
    const holidayCalendars = Array.isArray(values.holidayCalendars) ? values.holidayCalendars : [];
    holidayCalendars.push(cal);
    setFieldValue("holidayCalendars", holidayCalendars);
  }

  const removeHolidayCalendar = (index) => {
    if (Array.isArray(values.holidayCalendars)) {
      const holidayCalendars = values.holidayCalendars;
      holidayCalendars.splice(index, 1);
      setFieldValue("holidayCalendars", holidayCalendars);
    }
  }

  const addSchedule = () => {
    let state = new State();
    state.id = InstrumentState.INSTRUMENT_STATE_OPEN;
    state.name = capitalizeWord(getEnumName(InstrumentState, state.id));
    const newSchedule = {
      daysOfWeekList: [],
      duration: { seconds: 28800, nanos: 0 },
      state: state,
      periodicity: TradingHourPeriodicity.EVERYDAY,
      timeOfDay: { hours: 8, minutes: 0, seconds: 0 },
    };

    let tradingScheduleList = values.tradingScheduleList;

    if (!tradingScheduleList) {
      tradingScheduleList = [];
    }

    tradingScheduleList.push(newSchedule);

    setFieldValue("tradingScheduleList", tradingScheduleList);

    updateState({
      isNew: true,
      selectedSchedule: newSchedule,
      selectedScheduleIndex: tradingScheduleList.length - 1,
      showTradingShedule: true,
    });
  };

  const showErrorIfAnyRequiredFieldMissing = () => {
    const errPresent = !!errors["basecurrency"]
      || !!errors["clearingHouse"]
      || !!errors["multiplier"]
      || !!errors["minimumPriceIncrement"]
      || !!errors["minimumTradeQuantity"];

    if (errPresent && !open) {
      setOpen(errPresent);
    }

    return errPresent;
  }

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={8} xs={8} md={8}>
            <h6><strong>Trading Information</strong></h6>
          </Col>
          <Col lg={3} xs={3} md={3}>
            {showErrorIfAnyRequiredFieldMissing() && <ErrorLabel text={"Required field(s) missing"} />}
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>

      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={12} xs={12} md={12}>
              <TradingHours
                scheduleList={filteredScheduleList}
                holidayCalendars={values.holidayCalendars}
                addHolidayCalendar={(cal) => addHolidayCalendar(cal)}
                removeHolidayCalendar={(index) => removeHolidayCalendar(index)}
                enableEdit={values.isPartialEditable}
                addSchedule={() => addSchedule()}
                editSchedule={(schedule, index) => updateState({ showTradingShedule: true, selectedSchedule: schedule, selectedScheduleIndex: index })}
                removeSchedule={(index) => removeSchedule(index)}
                metadata={metadata}
                holidays={holidays}
                mode={mode}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <label><h6><strong>{"Trade Day Roll Schedule (" + timezone + ")"}</strong></h6></label>
              <Tooltip
                text={toolTips.TRADE_DAY_ROLL_SCHEDULE}
                id="tipTradeDayRollSchedule"
                place='right'
              ></Tooltip>
            </Col>
          </Row>

          <TradeDayRollSchedule
            values={values}
            setFieldValue={setFieldValue}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            errors={errors}
            mode={mode}
          />

          <Row>
            <Col>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="basecurrency" name="basecurrency" as="select" label="Currency" isRequired={true}
                value={values.basecurrency}
                setFieldValue={setFieldValue}
                errors={errors}
                onChange={handleChange}
                options={metadata && metadata.currencies ? Object.keys(metadata.currencies) : []}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FirmsListContainer id="clearingHouse" errors={errors} name="clearingHouse" label="Clearing House"
                firmType={FirmType.FIRM_TYPE_CLEARING_HOUSE}
                enabled={!values.nonTradable}
                isRequired={!values.nonTradable}
                value={values.clearingHouse}
                onChange={(firm) => setFieldValue("clearingHouse", firm.name)}
                plaintext={isViewing}
              ></FirmsListContainer>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="multiplier" name="multiplier" type="textarea" label="Multiplier" isRequired={true}
                tooltip={toolTips.MULTIPLIER}
                disabled={!values.isEditable}
                value={values.multiplier}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="tradeSettlementPeriod" name="tradeSettlementPeriod" type="textarea" label="Trade Settlement Period"
                tooltip={toolTips.TRADE_SETTLEMENT_PERIOD}
                disabled={!values.isEditable || !!values.nonTradable}
                value={values.tradeSettlementPeriod}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="instrumentProductType"
                name="instrumentProductType"
                label="Underlying Type"
                value={values.instrumentProductType}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("instrumentProductType", e.id)
                }
                data={InstrumentStaticData.InstrumentProductTypes()}
                textField="name"
                valueField="id"
                enabled={!isViewing}
                tooltip="Optionally indicates the type of underlying product the security is associated with. Maps to FIX Tag 460."
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="minimumPriceIncrement" name="minimumPriceIncrement" type="textarea" label="Min Price Increment" isRequired={true}
                tooltip={toolTips.MINIMUM_PRICE_INCREMENT}
                tooltipPlace='right'
                disabled={!values.isEditable}
                value={values.minimumPriceIncrement}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                min={1}
                max={10}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="minimumTradeQuantity" name="minimumTradeQuantity" type="textarea" label="Min Trade Quantity" isRequired={true}
                tooltip={toolTips.MINIMUM_TRADE_QUANTITY}
                disabled={!values.isEditable || !!values.nonTradable}
                value={values.minimumTradeQuantity}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
                placeholder={"0"}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="minimumUnaffiliatedFirms" name="minimumUnaffiliatedFirms" type="textarea" label="Minimum Unaffiliated Firms"
                tooltip={toolTips.MINIMUM_UNAFFILIATED_FIRMS}
                disabled={!values.isEditable || !!values.nonTradable}
                value={values.minimumUnaffiliatedFirms}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={"0"}
                plaintext={isViewing}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

export const ValidateTradingInfo = (values, errors) => {
  errors = validateField(values, errors, "clearingHouse", "Clearing House", STRING_TYPE, !values.nonTradable);
  errors = validateField(values, errors, "minimumUnaffiliatedFirms", "Minimum Unaffiliated Firms", NUMBER_TYPE, !values.nonTradable);
  errors = validateField(values, errors, "tradeSettlementPeriod", "Trade Settlement Period", NUMBER_TYPE, false, null, { greaterThanOrEqualTo: 0 });

  return errors;
}

export const TradingInfoSchemaValidation = () => {
  return {
    nonTradable: Yup.boolean(),
    minimumPriceIncrement: Yup.number()
      .moreThan(0, "Minimum Price Increment must be greater than 0.")
      .max(1, "Minimum Price Increment is not valid.")
      .nullable()
      .required("Minimum Price Increment is required."),
    basecurrency: Yup.string()
      .nullable()
      .required("Currency is required"),
    multiplier: Yup.number()
      .nullable()
      .moreThan(0, "Multiplier must be greater than 0.")
      .required("Multiplier is required."),
    minimumTradeQuantity: Yup.number().when("nonTradable", {
      is: val => val,
      then: schema => schema.optional(),
      otherwise: schema => schema.required('Minimum Trade Quantity is required.'),
    })
  }
}
