export const FormModes = {
  view: "view",
  edition: "edition",
  creation: "creation",
  multiedit: "multiedit",
  association: "association",
  users: "users",
  accounts: "accounts",
  services: "services",
  serviceCreation: "serviceCreation",
  serviceView: "serviceView",
  serviceEdit: "serviceEdit",
  serviceKeys: "keys",
  serviceKeysCreation: "serviceKeysCreation",
  serviceKeysView: "serviceKeysView",
  serviceKeysEdit: "serviceKeysEdit",
  position: "position",
  balance: "balance",
  ledger: "ledger",
  positionLedger: "positionLedger",
  whitelist: "whitelist",
  commissions: "commissions",
  listing: "listItems",
  bankDetails: "bankDetails",
  orders: "orders",
  executions: "executions"
};

export const TradingHourPeriodicity = {
  EVERYDAY: 1,
  EVERYWEEKDAY: 2,
  CUSTOM: 3,
};

export const OrderStatuses = {
  NEW_ORDER: "New",
  PARTIALLY_FILLED_ORDER: "PartiallyFilled",
  FILLED_ORDER: "Filled",
  CANCELED_ORDER: "Canceled",
  REPLACED_ORDER: "Replaced",
  REJECTED_ORDER: "Rejected",
  EXPIRED_ORDER: "Expired",
  PENDING_NEW_ORDER: "Pending New",
  PENDING_REPLACE_ORDER: "Pending Replace",
  PENDING_CANCEL_ORDER: "Pending Cancel",
  PENDING_RISK_ORDER: "Pending Risk",
};