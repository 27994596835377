import querystring from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  searchInstrumentStats,
} from '../actions/instruments';
import './Content.css';
import { AdminDocumentTitle } from '../constants/strings';
import PaginationWithPageSize, { withPageToken } from '../components/core/data-grid/PaginationWithPageSize';
import moment from 'moment';
import { isNullOrUndefined } from '../modules/util';
import MarketStatsFilterForm from "../components/instrument/components/historical-market-stats/MarketStatsFilterForm";
import MarketStatsDataGrid from "../components/instrument/components/historical-market-stats/MarketStatsDataGrid";
import { TitleBreadcrumb } from '../components/core/title-breadcrumb/title-breadcrumb';

const STORAGE_PAGE_SIZE_KEY = "dataGridPageSize-instrumentStatsPage";
const DEFAULT_PAGE_SIZE = 10;

function mapStateToProps(state) {

  return {
    stats: state.instrumentStats.stats,
    fetchInstrumentStats: state.instrumentStats.fetchInstrumentStats,
    tokens: state.instrumentStats.tokens,
    pageToken: state.instrumentStats.pageToken,
  };
}

const mapDispatchToProps = (dispatch) => ({
  searchInstrumentStats: (symbol, startDate, endDate, pageToken, pageSize) => {
    dispatch(searchInstrumentStats(symbol, startDate, endDate, pageToken, pageSize))
  }
})

class HistoricalMarketStatsPage extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor() {
    super();
    var start = moment().startOf("day").toDate()
    var end = moment().endOf("day").toDate()
    this.state = {
      fromDate: start,
      toDate: end,
      instrumentId: null,
      tokens: {},
      pageSize: Number(this.getPageSizeFromStorage())
    }
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.getPageSizeFromStorage = this.getPageSizeFromStorage.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    const state = querystring.parseUrl(window.location.href);
    const instrumentId = decodeURIComponent(this.props.match.params.id);

    if(instrumentId) {
        this.setState({
            instrumentId: instrumentId,
            fromDate: isNullOrUndefined(state.query.fromDate) ? this.state.fromDate : new Date(state.query.fromDate),
            toDate: isNullOrUndefined(state.query.toDate) ? this.state.toDate : new Date(state.query.toDate),
            viewStatsDetails: false,
          }, () => {
            if (!this.props.fetchInstrumentStats) {
              this.props.searchInstrumentStats(this.state.instrumentId, this.state.fromDate, this.state.toDate, "", this.state.pageSize);
            }
        });
    }
  }

  search = (values) => {
    
    let searchState = {
      instrumentId: this.state.instrumentId,
      fromDate: values.fromDate,
      toDate: values.toDate,
    };

    this.setState(searchState);
    this.props.searchInstrumentStats(this.state.instrumentId, this.state.fromDate, this.state.toDate, "", this.state.pageSize);
    this.context.router.history.push(`/instruments/${this.state.instrumentId}/historicalMarketStats?${querystring.stringify(searchState)}`);
  }

  setPageSizeInStorage = (pageSize) => {
    window.localStorage.setItem(STORAGE_PAGE_SIZE_KEY, pageSize)
  }

  getPageSizeFromStorage = () => {
    const pageSize = window.localStorage.getItem(STORAGE_PAGE_SIZE_KEY)

    if (pageSize)
      return pageSize;

    return DEFAULT_PAGE_SIZE;
  }

  onPageChange = (pageToken) => {
    this.props.searchInstrumentStats(this.state.instrumentId, this.state.fromDate, this.state.toDate, pageToken, this.state.pageSize);
  }

  onPageSizeChange = (pageSize) => {
    this.setPageSizeInStorage(pageSize);
    this.setState({ pageSize: pageSize });
    this.props.searchInstrumentStats(this.state.instrumentId, this.state.fromDate, this.state.toDate, "", pageSize);
  }

  getPaginationComponent = () => {
    const pageToken = this.props.pageToken;
    const tokens = this.props.tokens;
    const pageSize = Number(this.getPageSizeFromStorage());
    return withPageToken(PaginationWithPageSize, { pageToken, tokens }, pageSize);
  }

  updateStatsViewState = (showStats, statsData) => {
    this.setState({
      viewStatsDetails: showStats,
      marketStatsData: statsData
    });
  }

  transformStatsData = (statsData) => {
    // If no timestamp is present, do not fill the price
    const statsList = statsData.map(item => {
      item.closePx = !!item.closeSetTime ? item.closePx : "";
      item.highPx = !!item.highSetTime ? item.highPx : "";
      item.indicativeOpenPx = !!item.indicativeOpenSetTime ? item.indicativeOpenPx : "";
      item.lastTradePx = !!item.lastTradeSetTime ? item.lastTradePx : "";
      item.lowPx = !!item.lowSetTime ? item.lowPx : "";
      item.notionalTraded = !!item.notionalSetTime ? item.notionalTraded : "";
      item.openInterest = !!item.openInterestSetTime ? item.openInterest : "";
      item.openPx = !!item.openSetTime ? item.openPx : "";
      item.settlementPx = !!item.settlementSetTime ? item.settlementPx : "";

      return item;
    });

    return statsList;
  }

  render() {
    return (
      <div className="with-callback">
        <TitleBreadcrumb titles={[{ 'title': 'Historical Market Stats' }, { 'title': this.state.instrumentId }]} />
        <MarketStatsFilterForm
          filters={this.state}
          onSubmitForm={this.search}
        />
        <MarketStatsDataGrid
          data={this.transformStatsData(this.props.stats)}
          paginationComponent={this.getPaginationComponent()}
          onPageChange={this.onPageChange}
          onPageSizeChange={this.onPageSizeChange}
          updateStatsViewState={this.updateStatsViewState}
          showStatsDetail={this.state.viewStatsDetails}
          marketStatsData={this.state.marketStatsData}
        />
      </div>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HistoricalMarketStatsPage)
)
