import React from "react";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import GridActions from '../core/data-grid/GridActions';
import { GridActionDelete, GridActionEdit, GridActionRestore, GridActionView } from "../core/data-grid/GridAction";
import { useMemo } from "react";
import { GridActionMenu } from "../core/menu-items/menu-items";

const UsersDataGrid = ({ data, onView, onEdit, onRemove, onUnDelete }) => {
  const gridCtxMenu = useMemo(() => GridActionMenu, []);

  const columns = [
    {
      Header: "NAME",
      accessor: "displayName",
    },
    {
      Header: "USER ROLE",
      id: "role",
      accessor: (d) => (d.role ? d.role.name : null),
    },
    {
      Header: "STATE",
      id: "state",
      accessor: (d) => (d.isSuspended ? "Suspended" : "Active"),
      maxWidth: 120,
      minWidth: 120,
    },
    {
      Header: "FIRM",
      id: "firm",
      accessor: (d) => (d.firm.displayName ? d.firm.displayName : d.firm),
    },
    {
      Header: () => {
        return <div>ACTIONS</div>;
      },
      maxWidth: 120,
      minWidth: 120,
      sortable: false,
      Cell: (row) => (
        <GridActions>
          <GridActionView contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onView(row.original.name, actionContext); }} />

          {!row.original.deleted && <GridActionEdit contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onEdit(row.original.name, actionContext) }} />}

          {!row.original.deleted && <GridActionDelete onClick={(e, actionContext) => { onRemove(row.original.name, false) }} />}

          {!!row.original.deleted && <GridActionRestore onClick={(e, actionContext) => { onUnDelete(row.original.name, actionContext) }} />}

        </GridActions>),
    },
  ];
  return (
    <div>
      <DataGrid
        data={data}
        columns={columns}
        filterable={false}
        selectable={false}
      />
    </div>
  );
};

UsersDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default UsersDataGrid;
