import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { isNullOrUndefined } from "../../modules/util";
import { assignTokens } from "../../actions/auth";

const queryString = require("query-string");

class PublicRoute extends Component {
  render() {
    const query = queryString.parse(window.location.hash);
    const hasTokensInHash = !isNullOrUndefined(query.access_token) || !isNullOrUndefined(query.refresh_token);
    if (hasTokensInHash) {
      this.props.assignTokens(query.refresh_token, query.access_token);
      return null;
    }

    const { component: Component, ...rest } = this.props;
    const { auth, authRedirectTo } = this.props;
    const { token, expires } = auth;
    const isAuthenticated = token !== null && expires !== null && moment().isBefore(expires); // the user is logged in and token is not expired

    // session is no longer valid, force logout
    if(isAuthenticated && authRedirectTo) {
      return <Redirect to={authRedirectTo}/>
    }

    return <Route exact {...rest} render={(props) => (
      <Component {...this.props} />
    )} />
  }
};

const mapDispatchToProps = (dispatch) => ({
  assignTokens: (refreshToken, accessToken) => {
    dispatch(assignTokens(refreshToken, accessToken))
  }
})

const mapStateToProps = ({ auth, history }) => {
  return ({
    auth,
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicRoute);