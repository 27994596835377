import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import { validateField, makeDropdownOptions, getOptionText, SELECTED_DEFAULT, NUMBER_TYPE, STRING_TYPE, ARRAY_TYPE } from '../../instrumentFormUtils'
import DateGroup from "../../../core/form/DateGroup";
import FieldGroup from "../../../core/form/FieldGroup";
import { FormModes } from "../../../../constants/enumerations";
import DateTimeParser from "../../../../modules/dateTimeParser";
import MultiSelectDropdownListGroup from "../../../core/form/MultiSelectDropdownListGroup"

export const FloatingLegInfoBasis = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  handleChange,
  errors,
  mode
}) => {

  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Floating Leg Information</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatingRateIndex" name="leg1FloatingRateIndex" label="Leg1 Floating Rate Index" isRequired={true}
                value={values.leg1FloatingRateIndex}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg1FloatingRateIndex", e)}
                data={InstrumentStaticData.FloatingRateIndexList}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg2FloatingRateIndex" name="leg2FloatingRateIndex" label="Leg2 Floating Rate Index" isRequired={true}
                value={values.leg2FloatingRateIndex}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg2FloatingRateIndex", e)}
                data={InstrumentStaticData.FloatingRateIndexList}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatDayCountConvention" name="leg1FloatDayCountConvention" label="Leg1 Float Day Count Convention"
                errors={errors}
                value={values.leg1FloatDayCountConvention}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("leg1FloatDayCountConvention", e.id)}
                data={InstrumentStaticData.DayCountConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg2FloatDayCountConvention" name="leg2FloatDayCountConvention" label="Leg2 Float Day Count Convention"
                errors={errors}
                value={values.leg2FloatDayCountConvention}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("leg2FloatDayCountConvention", e.id)}
                data={InstrumentStaticData.DayCountConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="leg1FirstFloatFixingDate" name="leg1FirstFloatFixingDate" label="Leg1 First Float Fixing Date"
                value={values.leg1FirstFloatFixingDate ? new Date(values.leg1FirstFloatFixingDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("leg1FirstFloatFixingDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="leg2FirstFloatFixingDate" name="leg2FirstFloatFixingDate" label="Leg2 First Float Fixing Date"
                value={values.leg2FirstFloatFixingDate ? new Date(values.leg2FirstFloatFixingDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("leg2FirstFloatFixingDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col>
              <DateGroup id="leg1FirstFloatResetDate" name="leg1FirstFloatResetDate" label="Leg1 First Float Reset Date" isRequired={true}
                value={values.leg1FirstFloatResetDate ? new Date(values.leg1FirstFloatResetDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={values.isPartialEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("leg1FirstFloatResetDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col>
              <DateGroup id="leg2FirstFloatResetDate" name="leg2FirstFloatResetDate" label="Leg2 First Float Reset Date" isRequired={true}
                value={values.leg2FirstFloatResetDate ? new Date(values.leg2FirstFloatResetDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={values.isPartialEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("leg2FirstFloatResetDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg1 Float Payment Frequency" value={getOptionText(InstrumentStaticData.FixedPaymentFrequency, values.leg1FloatPaymentFrequency)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg2 Float Payment Frequency" value={getOptionText(InstrumentStaticData.FixedPaymentFrequency, values.leg2FloatPaymentFrequency)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg1 Float Rate Reset Frequency" value={getOptionText(InstrumentStaticData.FloatRateResetFrequency, values.leg1FloatRateResetFrequency)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg2 Float Rate Reset Frequency" value={getOptionText(InstrumentStaticData.FloatRateResetFrequency, values.leg2FloatRateResetFrequency)} />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg1 Float Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.leg1FloatBusinessDayConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg2FloatBusinessDayConvention" name="leg2FloatBusinessDayConvention" label="Leg2 Float Business Day Convention"
                value={values.leg2FloatBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg2FloatBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg1 Reset Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.leg1FloatResetDateBusinessDayConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg2FloatResetDateBusinessDayConvention" name="leg2FloatResetDateBusinessDayConvention" label="Leg2 Reset Date Business Day Convention"
                value={values.leg2FloatResetDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg2FloatResetDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg1 Float Compounding Method" value={getOptionText(InstrumentStaticData.CompoundingOptions, values.leg1FloatCompoundingMethod)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg2FloatCompoundingMethod" name="leg2FloatCompoundingMethod" label="Leg2 Float Compounding Method"
                value={values.leg2FloatCompoundingMethod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg2FloatCompoundingMethod", e.id)}
                data={InstrumentStaticData.CompoundingOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg1 Float Fixing Dates Day Type" value={getOptionText(InstrumentStaticData.FixingDatesDayType, values.leg1FloatFixingDatesDayType)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg2FloatFixingDatesDayType" name="leg2FloatFixingDatesDayType" label="Leg2 Float Fixing Dates Day Type"
                value={values.leg2FloatFixingDatesDayType}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg2FloatFixingDatesDayType", e.id)}
                data={InstrumentStaticData.FixingDatesDayType}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FieldGroupReadOnly label="Leg1 Float Fixing Dates Offset" value={values.leg1FloatFixingDatesOffset} />
            </Col>
            <Col>
              <FieldGroup id="leg2FloatFixingDatesOffset" name="leg2FloatFixingDatesOffset" type="textarea" label="Leg2 Float Fixing Dates Offset"
                disabled={!values.isEditable}
                value={values.leg2FloatFixingDatesOffset}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col>
              <FieldGroupReadOnly label="Leg1 Float Reset Date Relative To" value={getOptionText(InstrumentStaticData.DateRelativeToOptions, values.leg1FloatResetDateRelativeTo)} />
            </Col>
            <Col>
              <DropdownListGroup id="leg2FloatResetDateRelativeTo" name="leg2FloatResetDateRelativeTo" label="Leg2 Float Reset Date Relative To"
                value={values.leg2FloatResetDateRelativeTo}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg2FloatResetDateRelativeTo", e.id)}
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatRollConvention" name="leg1FloatRollConvention" label="Leg1 Float Roll Convention" isRequired={true}
                value={values.leg1FloatRollConvention || SELECTED_DEFAULT}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("leg1FloatRollConvention", e)}
                data={makeDropdownOptions(InstrumentStaticData.FixedRollConventionList, false)}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg2FloatRollConvention" name="leg2FloatRollConvention" label="Leg2 Float Roll Convention" isRequired={true}
                value={values.leg2FloatRollConvention || SELECTED_DEFAULT}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("leg2FloatRollConvention", e)}
                data={makeDropdownOptions(InstrumentStaticData.FixedRollConventionList, false)}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg1 Fixing Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.leg1FloatFixingDateBusinessDayConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg2FloatFixingDateBusinessDayConvention" name="leg2FloatFixingDateBusinessDayConvention" label="Leg2 Fixing Date Business Day Convention"
                value={values.leg2FloatFixingDateBusinessDayConvention || SELECTED_DEFAULT}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("leg2FloatFixingDateBusinessDayConvention", e)}
                data={makeDropdownOptions(InstrumentStaticData.BusinessDayConventionOptions, false)}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg1 Float Payment Date Relative To" value={getOptionText(InstrumentStaticData.DateRelativeToOptions, values.leg1FloatPaymentDateRelativeTo)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg2FloatPaymentDateRelativeTo" name="leg2FloatPaymentDateRelativeTo" label="Leg2 Float Payment Date Relative To"
                value={values.leg2FloatPaymentDateRelativeTo || SELECTED_DEFAULT }
                errors={errors}
                enabled={values.isEditable}
                valueField="id"
                textField="name"
                onChange={(e) => setFieldValue("leg2FloatPaymentDateRelativeTo", e)}
                data={makeDropdownOptions(InstrumentStaticData.DateRelativeToOptions, false)}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="leg1FloatingRateIndexTenor" name="leg1FloatingRateIndexTenor" type="textarea" label="Leg1 Floating Rate Index Tenor"
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.leg1FloatingRateIndexTenor}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="leg2FloatingRateIndexTenor" name="leg2FloatingRateIndexTenor" type="textarea" label="Leg2 Floating Rate Index Tenor"
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.leg2FloatingRateIndexTenor}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Leg1 Float Fixing Date Holiday Calendar" value={values.leg1FloatFixingDateHolidayCalendarsList ? values.leg1FloatFixingDateHolidayCalendarsList.join(", ") : ""} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup id="leg2FloatFixingDateHolidayCalendarsList" name="leg2FloatFixingDateHolidayCalendarsList" label="Leg2 Float Fixing Date Holiday Calendar"
                labelWrapperClass="label-wrapper"
                value={values.leg2FloatFixingDateHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg2FloatFixingDateHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"} valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="leg1FloatFirstUnadjustedPaymentDate" name="leg1FloatFirstUnadjustedPaymentDate" label="Leg1 Float First Unadjusted Payment Date"
                value={values.leg1FloatFirstUnadjustedPaymentDate ? new Date(values.leg1FloatFirstUnadjustedPaymentDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={values.isPartialEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("leg1FloatFirstUnadjustedPaymentDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="leg2FloatFirstUnadjustedPaymentDate" name="leg2FloatFirstUnadjustedPaymentDate" label="leg2 Float First Unadjusted Payment Date"
                value={values.leg2FloatFirstUnadjustedPaymentDate ? new Date(values.leg2FloatFirstUnadjustedPaymentDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={values.isPartialEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("leg2FloatFirstUnadjustedPaymentDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="floatFirstUnadjustedEffectiveDate" name="floatFirstUnadjustedEffectiveDate" label="Float First Unadjusted Effective Date"
                value={values.floatFirstUnadjustedEffectiveDate ? new Date(values.floatFirstUnadjustedEffectiveDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("floatFirstUnadjustedEffectiveDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="unadjustedMaturityDate" name="unadjustedMaturityDate" label="Unadjusted Maturity Date"
                value={values.unadjustedMaturityDate ? new Date(values.unadjustedMaturityDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("unadjustedMaturityDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

export const ValidateFloatingLegBasis = (values, errors) => {
  errors = validateField(values, errors,  "leg1FloatingRateIndex", "Leg1 Floating Rate Index", STRING_TYPE);
  errors = validateField(values, errors,  "leg1FloatDayCountConvention", "Leg1 Day Count Convention", NUMBER_TYPE);
  errors = validateField(values, errors,  "leg1FloatPaymentFrequency", "Leg1 Payment Frequency", NUMBER_TYPE);
  errors = validateField(values, errors,  "leg1FloatRateResetFrequency", "Leg1 Rate Reset Frequency", NUMBER_TYPE);
  errors = validateField(values, errors,  "leg1FloatResetDateBusinessDayConvention", "Leg1 Reset Date Business Day Convention", NUMBER_TYPE);
  errors = validateField(values, errors,  "leg1FloatBusinessDayConvention", "Leg1 Float Business Day Convention", NUMBER_TYPE);
  errors = validateField(values, errors,  "leg1FloatCompoundingMethod", "Leg1 Compounding Method", NUMBER_TYPE);
  errors = validateField(values, errors,  "leg1FloatFixingDatesOffset", "Leg1 Float Fixing Dates Offset", NUMBER_TYPE);
  errors = validateField(values, errors,  "leg1FloatResetDateRelativeTo", "Leg1 Float Reset Date Relative To", NUMBER_TYPE);
  errors = validateField(values, errors,  "leg1FloatFixingDateBusinessDayConvention", "Leg1 Float Fixing Date Business Day Convention", ARRAY_TYPE);
  errors = validateField(values, errors,  "leg1FloatFixingDatesDayType", "Leg1 Float Fixing Dates Day Type", ARRAY_TYPE);
  errors = validateField(values, errors,  "leg1FloatingRateIndexTenor", "Leg1 Floating Rate Index Tenor", STRING_TYPE);
  errors = validateField(values, errors,  "leg1FloatRollConvention", "Leg1 Float Roll Convention", STRING_TYPE);
  errors = validateField(values, errors,  "leg2FloatPaymentDateRelativeTo", "Leg2 Float Payment Date Relative To", ARRAY_TYPE);
  return validateField(values, errors,  "leg1FloatFixingDateHolidayCalendarsList", "Leg1 Float Fixing Date Holiday Calendar", ARRAY_TYPE);
}