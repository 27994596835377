import React, { Component } from "react";
import _ from "lodash"
import "./filter.css";
import FilterItem from "../../../components/core/filter/FilterItem";
import { Col, Row } from "react-bootstrap";
import ButtonSecondary from "../form/ButtonSecondary";
import { isArrayWithValues } from "../../../modules/util";

class Filter extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      isDirty: false,
    };
    this.filpShow = this.filpShow.bind(this);
    this.onApply = this.onApply.bind(this);
    this.filterItemClose = this.filterItemClose.bind(this);
  }

  filpShow(caller) {
    if (!this.state.show) {
      //whenever open, set isDirty true;
      this.setState({ isDirty: true });
    }

    this.setState({ show: !this.state.show }, () => {
      if (this.props.onFlip)
        this.props.onFlip({
          visible: this.state.show,
          isDirty: this.state.isDirty,
        });
    });
  }

  onApply() {
    if (this.props.validate && !this.props.validate()) {
      return;
    }

    this.setState({ isDirty: false });
    this.filpShow();
    this.props.onApply();
  }

  filterItemClose(args) {
    if (this.state.show) return;
    this.props.onRemoveFilterItem(args);
  }

  render() {
    return (
      <div className={`Filter-Container ${this.props.className}`}>
        <div className="Filter-Header">
          <Row>
            <Col lg={6} xs={6} md={6}>
              <button className={this.props.isAdditional ? "Additional-Filters-Button" : "Filter-Button" } onClick={this.filpShow} type="button">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="Filter-Button-Text">{this.props.isAdditional ? "Additional Filters" : "Filter"}</span>
                  {this.state.show ? (
                    <i className="fa fa-fw fa-chevron-up Arrow-Down"></i>
                  ) : (
                    <i className="fa fa-fw fa-chevron-down Arrow-Down"></i>
                  )}
                </div>
              </button>
            </Col>
            {!!this.props.onReset &&
              <Col lg={6} xs={6} md={6}>
                <ButtonSecondary type="button" onClick={this.props.onReset} text="Reset" style={{ width: "100%" }} />
              </Col>
            }
          </Row>
        </div>

        {this.props.filterItems && <Row>
          <Col>
            <div className="Filter-Items-Container">
              {this.props.filterItems
                ? Object.values(this.props.filterItems).map((filterItem) => {
                  if (!filterItem || _.isEmpty(filterItem)) return null;
                  
                  if (isArrayWithValues(filterItem)) {
                    return filterItem.map(filterItem => <FilterItem
                      {...filterItem}
                      key={filterItem.id}
                      associatedObj={filterItem}
                      onClose={this.filterItemClose}
                    />)
                  }
                  
                  return (
                    <FilterItem
                      {...filterItem}
                      key={filterItem.id}
                      associatedObj={filterItem}
                      onClose={this.filterItemClose}
                    ></FilterItem>
                  );
                })
                : null}
            </div>
          </Col>
        </Row>}

        {this.state.show ? (
          <>
            <div className="Filter-Body">
              <div className="Filter-Body-Content">{this.props.children}</div>
              <div className="Filter-Bottom d-flex justify-content-between">
                <button
                  className="Filter-Button Filter-Apply"
                  onClick={this.onApply}
                >
                  {!!this.props.applyButtonText ? this.props.applyButtonText : "Apply"}
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

export default Filter;
