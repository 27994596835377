import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  FetchParticipantGroupStart,
  FetchParticipantGroupSucceed,
  FetchParticipantGroupFailed,
  FetchParticipantsListSucceed,
  CleanParticipantGroup,
  CleanParticipantsListGroup
} from "../actions/participantGroups";
import "./Content.css";
import Loader from "../components/core/loader/Loader";
import Participant from "../entities/Participant";
import ParticipantHeader from "../components/participant/ParticipantHeader";
import ParticipantGroup from "../entities/ParticipantGroup";
import Notification from "../modules/notifications";
import ParticipantGroupFormItem from "../entities/dto/ParticipantGroupFormItem";
import ParticipantGroupFormContainer from "../containers/ParticipantGroupFormContainer";
import { AdminDocumentTitle } from "../constants/strings";
import ParticipantGroupService from "../services/ParticipantGroupService";
import { getHelmet } from "../modules/util";
import { TitleBreadcrumb } from "../components/core/title-breadcrumb/title-breadcrumb";

function mapStateToProps(state) {
  return {
    fetchingParticipantGroup: state.participantGroups.fetchingParticipantGroup,
    participantGroup: state.participantGroups.participantGroup,
    firms: state.firms.firms,
  };
}

const mapDispatchToProps = dispatch => ({
  fetchParticipantGroupStart: product => {
    dispatch(FetchParticipantGroupStart(product));
  },
  fetchParticipantGroupSucceed: product => {
    dispatch(FetchParticipantGroupSucceed(product));
  },
  fetchParticipantGroupFailed: product => {
    dispatch(FetchParticipantGroupFailed(product));
  },
  fetchParticipantsSucceed: participants => {
    dispatch(FetchParticipantsListSucceed(participants));
  },
  clearGroup: () => {
    dispatch(CleanParticipantGroup());
  },
  clearParticipantsList: () => {
    dispatch(CleanParticipantsListGroup());
  }
});

class ParticipantGroupFormPage extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.participantId = this.props.match.params.id;
    if (this.participantId) {
      this.getParticipantGroupInformation(this.participantId);
    }

    window.scrollTo(0, 0);
  }

  getParticipantGroupInformation = id => {
    this.props.clearGroup();
    this.props.clearParticipantsList();
    this.props.fetchParticipantGroupStart();

    const cb = (err, response) => {
      if (response) {
        const participantGroup = new ParticipantGroupFormItem(
          new ParticipantGroup(response.getParticipantGroup(), this.props.firms)
        );
        this.props.fetchParticipantGroupSucceed(participantGroup);
        this.loadParticipants(id);
      }

      if (err) {
        this.props.fetchParticipantGroupFailed();
        Notification.error("Cannot get participant group information.");
      }
    };

    ParticipantGroupService.get(id, cb);
  };

  loadParticipants = id => {
    const cb = (err, response) => {
      if (response) {
        var participantsList = response
          .getParticipantsList()
          .map(participant => {
            return new Participant(participant);
          });
        this.props.fetchParticipantsSucceed(participantsList);
      }

      if (err) {
        console.log(err);
      }
    };

    ParticipantGroupService.listParticipantsInGroup(id, cb);
  };

  openCreateParticipantGroup = () => {
    this.context.router.history.push(`/accounts/new`);
  };

  render() {
    return (
      <div className="with-callback">
        <div>
          <TitleBreadcrumb titles={[{ 'title': "Participant Groups", link: `${window.location.origin}/participant-groups` }, { 'title': this.participantId }]} />
        </div>
        <Loader show={this.props.fetchingParticipantGroup}></Loader>
        <ParticipantGroupFormContainer item={this.props.participantGroup} />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ParticipantGroupFormPage)
);
