import React, { Component } from "react";
import DropdownListGroup from "../components/core/form/DropdownListGroup";
import Notification from "../modules/notifications";
import Firm from "../entities/Firm";
import * as firmService from "../services/FirmService";
import Loader from "../components/core/loader/Loader";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";

class FirmsListContainer extends Component {
  state = {
    firms: [],
    enabled: true,
    loadingFirms: false,
  };

  componentDidMount() {
    this.loadFirms();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.firmParent !== this.props.firmParent) {
      this.loadFirms();
      this.props.onChange("");
    }
  }

  loadFirms = () => {
    if (!!this.props.firmParent) {
      if (this.props.firmType === FirmType.FIRM_TYPE_PARTICIPANT) {
        const cmcb = (err, response) => {
          if (response) {
            let associatedFirms = response
              .getAssociatedFirmsList()
              .map((firm) => {
                return this.state.firms.find((curr) => curr.name === firm);
              });
            this.setState({ firms: associatedFirms });
          }
          if (err) {
            Notification.error("Cannot get list of firm associations.");
          }
        };
        return firmService.getClearingMemberRequest(
          this.props.firmParent.replace("firms/", ""),
          cmcb
        );
      } else if (this.props.firmType === FirmType.FIRM_TYPE_CLEARING_HOUSE) {
        const chmb = (err, response) => {
          if (response) {
            let associatedFirms = response
              .getAssociatedFirmsList()
              .map((firm) => {
                return this.state.firms.find((curr) => curr.name === firm);
              });
            this.setState({ firms: associatedFirms });
          }
          if (err) {
            Notification.error("Cannot get list of firm associations.");
          }
        };
        return firmService.getClearingHouseRequest(
          this.props.firmParent.replace("firms/", ""),
          chmb
        );
      }
    }

    const cb = (err, response) => {
      if (response) {
        var firmsList = response.getFirmsList().map((firm) => {
          return new Firm(firm);
        });
        firmsList = firmsList.filter((firm) => !firm.isSuspended);
        this.setState({ firms: firmsList });
      }
      if (err) {
        Notification.error("Cannot get list of firms.");
      }
      this.setState({ loadingFirms: false });
    };
    this.setState({ loadingFirms: true });
    return firmService.getAll(cb, this.props.firmType);
  };

  render() {
    const plaintext = this.props.plaintext ? this.props.plaintext : false;
    return (
      <>
        <Loader show={this.state.loadingFirms}></Loader>
        <DropdownListGroup
          id={this.props.id}
          name={this.props.name}
          textField="displayName"
          valueField="name"
          label={this.props.label}
          isRequired={this.props.isRequired}
          enabled={
            this.props.enabled &&
            (this.props.firmParent === undefined || !!this.props.firmParent)
          }
          value={this.props.value ? this.props.value : null}
          onChange={(event) => {
            this.props.onChange(event);
          }}
          data={["", ...this.state.firms]}
          errors={this.props.errors}
          plaintext={plaintext}
        />
      </>
    );
  }
}

export default FirmsListContainer;
