import React, { Component } from 'react';
import isEqual from 'lodash/isEqual'
import DropdownTreeSelect from "../../vendor/react-dropdown-tree-select/react-dropdown-tree-select";

export default class DropdownTree extends Component {
	constructor(props) {
		super(props)
		this.state = { data: props.data }
	}

	componentWillReceiveProps = (nextProps) => {
		if (!isEqual(nextProps.data, this.state.data)) {
			this.setState({ data: nextProps.data })
		}
	}

	shouldComponentUpdate = (nextProps) => {
		return !isEqual(nextProps.data, this.state.data)
	}

	render() {
		return (
			<DropdownTreeSelect className="tree-select"
				mode="radioSelect"
				data={this.state.data || []}
				keepTreeOnSearch={true}
				onChange={(currentNode, selectedNodes) => {
					!!this.props.onChange && this.props.onChange(currentNode, selectedNodes);
				}} />
		)
	}
}
