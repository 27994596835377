import { StatusCode } from "grpc-web";

import {
  FETCH_TIME_SALES_START,
  FETCH_TIME_SALES_SUCCEED,
  FETCH_TIME_SALES_FAILED,
  REFRESH_TIME_SALES_ITEMS,
  RESET_TIME_SALES_TOKENS,
  FETCH_TIME_SALES_REPORT_SUCCEED,
  FETCH_TIME_SALES_REPORT_FAILED,
  FETCH_TIME_SALES_REPORT_START,
  FETCH_TRADE_START,
  TRADE_ITEM,
  FETCH_TRADE_SUCCEED,
  FETCH_TRADE_FAILED
} from "../constants/timeSalesTypes";
import TimeSalesService from "../services/TimeSalesService";
import Notification from "../modules/notifications";
import ProtobufParser from "../modules/protobufParser";
import Trade from "../entities/Trade";
import TimeSalesGridItem from "../entities/dto/TimeSalesGridItem";
import {inTest, parsePrice} from "../modules/util";
import { ensureAndApplyInstrumentScales } from "../modules/scaleUtils";
import InstrumentService from "../services/InstrumentService";
import Instrument from "../entities/Instrument";
import { getAccountFirm, getParticipant, getParticipantFirm } from "../modules/actionUtil";

export function cleanSearch() {
  return (dispatch) => {
    dispatch({
      type: REFRESH_TIME_SALES_ITEMS,
      payload: [],
      pageToken: null,
      nextPageToken: null,
      eof: true,
    });
    dispatch({ type: RESET_TIME_SALES_TOKENS });
  };
}

export function exportData(
  startDate,
  endDate,
  symbol,
  participant,
  account,
  orderid,
  execid,
  tradeid,
  clientaccountid,
  clientparticipantid,
  parentOrderId,
  symbolSubType,
  tradeStates,
) {
  return (dispatch) => {
    let onSuccess = (data) => {
      dispatch({ type: FETCH_TIME_SALES_REPORT_SUCCEED });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `time_sales.csv`);
      document.body.appendChild(link);
      link.click();
    };
    let onError = (error) => {
      console.error(error);
      dispatch({ type: FETCH_TIME_SALES_REPORT_FAILED });
      Notification.error("Could not download report.");
    };

    dispatch({ type: FETCH_TIME_SALES_REPORT_START });
    TimeSalesService.downloadTradesReport(
      startDate ? ProtobufParser.toTimestamp(startDate) : null,
      endDate ? ProtobufParser.toTimestamp(endDate) : null,
      symbol,
      participant,
      account,
      orderid,
      execid,
      tradeid,
      clientaccountid,
      clientparticipantid,
      parentOrderId,
      symbolSubType,
      tradeStates,
      onSuccess,
      onError
    );
  };
}

export function getFiltered(
  startDate,
  endDate,
  symbol,
  participant,
  account,
  orderid,
  execid,
  tradeid,
  clientaccountid,
  clientparticipantid,
  parentOrderId,
  symbolSubType,
  tradeStates,
  pageToken,
  pageSize
) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_TIME_SALES_START });

    const onRefresh = async function (trades, pageToken, nextPageToken, eof) {
      if (!pageToken) {
        dispatch({ type: RESET_TIME_SALES_TOKENS });
      }

      if (trades.length === 0) {
        dispatch({ type: FETCH_TIME_SALES_FAILED });
        Notification.error("No records found.");
        return;
      }

      const items = trades.map(
        (trade) => new TimeSalesGridItem(new Trade(trade))
      );

      const state = getState();
      const { scales, qtyScales } = state.instrumentsScales; 

      ensureAndApplyInstrumentScales(
        items,
        scales,
        qtyScales,
        (item) => item.symbol,
        dispatch,
        (item, priceScale, qtyScale) => {
          item.tradePrice = parsePrice(item.tradePrice, priceScale);
          item.tradeQty = parsePrice(item.tradeQty, qtyScale);
          item.aggressorLastPrice = parsePrice(item.aggressorLastPrice, priceScale);
          item.passiveLastPrice = parsePrice(item.passiveLastPrice, priceScale);
        }
      ).then(
        async(trades, newScales) => {
          dispatch({
            type: REFRESH_TIME_SALES_ITEMS,
            payload: items,
            pageToken: pageToken,
            nextPageToken: eof ? undefined : nextPageToken,
          });

          dispatch({ type: FETCH_TIME_SALES_SUCCEED });
        },
        (err) => {
          dispatch({ type: FETCH_TIME_SALES_FAILED });
          Notification.error(err);
        }
      );
    };

    const onError = function (error) {
      if (error && error.message !== "Http response at 400 or 500 level") {
        switch (error.code) {
          case StatusCode.NOT_FOUND:
            dispatch({ type: FETCH_TIME_SALES_FAILED });
            Notification.error("No records found.");
            break;
          default:
            console.log(error.message);
            dispatch({ type: FETCH_TIME_SALES_FAILED });
            break;
        }
      } else {
        dispatch({ type: FETCH_TIME_SALES_SUCCEED });
      }
    };

    TimeSalesService.getFiltered(
      startDate ? ProtobufParser.toTimestamp(startDate) : null,
      endDate ? ProtobufParser.toTimestamp(endDate) : null,
      symbol,
      participant,
      account,
      orderid,
      execid,
      tradeid,
      clientaccountid,
      clientparticipantid,
      parentOrderId,
      symbolSubType,
      tradeStates,
      pageToken,
      pageSize,
      onRefresh,
      onError
    );
  };
}

export function getTrade(tradeId) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_TRADE_START });

    const onRefresh = async function (trades) {
      if (trades.length === 0) {
        dispatch({ type: FETCH_TRADE_FAILED });
        Notification.error("No records found.");
        return;
      }

      const items = trades.map(
        (trade) => new TimeSalesGridItem(new Trade(trade))
      );

      const state = getState();
      const { scales, qtyScales } = state.instrumentsScales; 

      ensureAndApplyInstrumentScales(
        items,
        scales,
        qtyScales,
        (item) => item.symbol,
        dispatch,
        (item, priceScale, qtyScale) => {
          item.tradePrice = parsePrice(item.tradePrice, priceScale);
          item.tradeQty = parsePrice(item.tradeQty, qtyScale);
          item.aggressorLastPrice = parsePrice(item.aggressorLastPrice, priceScale);
          item.passiveLastPrice = parsePrice(item.passiveLastPrice, priceScale);
        }
      ).then(
        async(trades, newScales) => {
            const item = items[0];
            const aggressorAcct = !!item.aggressorAccount ? item.aggressorAccount : null;
            const passiveAcct = !!item.passiveAccount ? item.passiveAccount : null;
  
            let instrumentResponse = null;
  
            const instrumentPromise = InstrumentService.get2(item.symbol);
            const aggAccountPromise = getAccountFirm(state, item.aggressorAccount);
            const passAccountPromise = getAccountFirm(state, item.passiveAccount);
  
            const aggParticipantPromise = getParticipant(state, item.aggressorData.order.participant);
            const passParticipantPromise = getParticipant(state, item.passiveData.order.participant);
            const aggSubmittingParticipantPromise = getParticipantFirm(state, item.aggressorData.order.submittingParticipant);
            const passSubmittingParticipantPromise = getParticipantFirm(state, item.passiveData.order.submittingParticipant);
  
            const [
              instrumentPromiseResp,
              aggAccountPromiseResp,
              passAccountPromiseResp,
              aggParticipantPromiseResp,
              aggSubmittingParticipantPromiseResp,
              passSubmittingParticipantPromiseResp,
              passParticipantPromiseResp
            ] = await Promise.all([
              instrumentPromise,
              aggAccountPromise,
              passAccountPromise,
              aggParticipantPromise,
              aggSubmittingParticipantPromise,
              passSubmittingParticipantPromise,
              passParticipantPromise
            ]).catch((err) => {
              console.log(err);
            });
            instrumentResponse = instrumentPromiseResp;
            item.aggressorAccountObj = { ...aggAccountPromiseResp.account };
            item.aggressorFirm = aggAccountPromiseResp.firm;
            item.passiveAccountObj = { ...passAccountPromiseResp.account };
            item.passiveFirm = passAccountPromiseResp.firm;
            item.aggressorParticipantObj = aggParticipantPromiseResp;
            item.passiveParticipantObj = passParticipantPromiseResp;
  
            const instrument = new Instrument(
              instrumentResponse.getInstrument()
            );
  
            item.instrument = instrument;
  
            item.priceScale = instrument.priceScale;
            item.tradePrice = parsePrice(
              !!item.aggressorLastPrice ? item.aggressorLastPrice : item.passiveLastPrice,
              instrument.priceScale
            );
  
            item.aggressorLastPrice = parsePrice(
              !!item.aggressorData.lastPx ? item.aggressorData.lastPx : 0,
              instrument.priceScale
            );
  
            item.passiveLastPrice = parsePrice(
              !!item.passiveData.lastPx ? item.passiveData.lastPx : 0,
              instrument.priceScale
            );
  
            item.aggressorCommissionNotionalCollected = parsePrice(
                !!item.aggressorCommissionNotionalCollected ? item.aggressorCommissionNotionalCollected : 0,
                instrument.priceScale,
                instrument.fractionalQtyScale,
            );
  
            item.passiveCommissionNotionalCollected = parsePrice(
                !!item.passiveCommissionNotionalCollected ? item.passiveCommissionNotionalCollected : 0,
                instrument.priceScale,
                instrument.fractionalQtyScale,
            );
  
            item.fractionalQtyScale = instrument.fractionalQtyScale;
            item.tradeQty = parsePrice(
              !!item.aggressorData.lastShares ? item.aggressorData.lastShares : item.passiveData.lastShares,
              instrument.fractionalQtyScale
            );
  
            if (!!aggressorAcct) {
              item.aggressorAccountObj.legPricesList = item.aggressorData.legPricesList
              if (!!item.aggressorAccountObj.legPricesList) {
                item.aggressorAccountObj.legPricesList.forEach(legPrice => {
                  legPrice.tradePrice = parsePrice(legPrice.px, instrument.priceScale);
                });
              }
  
              if (item.aggressorSubmittingParticipant) {
                item.aggressorSubmittingParticipantObj = aggSubmittingParticipantPromiseResp.participant;
                item.aggressorSubmittingParticipantObj.firm = aggSubmittingParticipantPromiseResp.firm;
              }
            }
  
            if (!!passiveAcct) {
              item.passiveAccountObj.legPricesList = item.passiveData.legPricesList;
              if (!!item.passiveAccountObj.legPricesList) {
                item.passiveAccountObj.legPricesList.forEach(legPrice => {
                  legPrice.tradePrice = parsePrice(legPrice.px, instrument.priceScale);
                });
              }
  
              if (item.passiveSubmittingParticipant) {
                item.passiveSubmittingParticipantObj = passSubmittingParticipantPromiseResp.participant;
                item.passiveSubmittingParticipantObj.firm = passSubmittingParticipantPromiseResp.firm;
              }
            }
          dispatch({
            type: TRADE_ITEM,
            payload: item,
          });

          dispatch({ type: FETCH_TRADE_SUCCEED });
        },
        (err) => {
          dispatch({ type: FETCH_TRADE_FAILED });
          Notification.error(err);
        }
      );
    };

    const onError = function (error) {
      if (error && error.message !== "Http response at 400 or 500 level") {
        switch (error.code) {
          case StatusCode.NOT_FOUND:
            dispatch({ type: FETCH_TRADE_FAILED });
            Notification.error("No records found.");
            break;
          default:
            if (!inTest()) console.log(error.message);
            dispatch({ type: FETCH_TRADE_FAILED });
            break;
        }
      } else {
        dispatch({ type: FETCH_TRADE_SUCCEED });
      }
    };

    TimeSalesService.getTrade(tradeId, onRefresh, onError);
  };
}
