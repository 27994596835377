import Order from "./Order";
import BookStatus from "./BookStatus";

export default class BookSnapshot {
  constructor(item) {
    if (item) {
      const pushBids = item.getBidsList();
      const pushOffers = item.getOffersList();
      const pushStopBids = item.getStopBidsList();
      const pushStopOffers = item.getStopOffersList();
      let bids = [];
      let offers = [];
      let stopBids = [];
      let stopOffers = [];
      for(let i=0; pushBids.length > i; i++)
      {
        bids.push(new Order(pushBids[i]));
      }
      this.bids = bids;
      for(let i=0; pushOffers.length > i; i++)
      {
        offers.push(new Order(pushOffers[i]));
      }
      this.offers = offers;
      for(let i=0; pushStopBids.length > i; i++)
      {
        stopBids.push(new Order(pushStopBids[i]));
      }
      this.stopBids = stopBids;
      for(let i=0; pushStopOffers.length > i; i++)
      {
        stopOffers.push(new Order(pushStopOffers[i]));
      }
      this.stopOffers = stopOffers;
      this.status = new BookStatus(item.getStatus());
      return;
    }
    this.bids = null;
    this.offers = null;
    this.status = null;
  }
}