import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import FieldGroup from "../core/form/FieldGroup";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import ButtonBorderless from "../core/form/ButtonBorderless";
import FirmStateListContainer from "../../containers/FirmStateListContainer";
import ButtonMain from "../core/form/ButtonMain";
import { withFormik } from "formik";
import * as Yup from "yup";
import PhoneNumber from "../core/form/PhoneNumber";
import FirmParticipantTypeListContainer from "../../containers/FirmParticipantTypeListContainer";
import OrganizationTypeListContainer from "../../containers/OrganizationTypeListContainer";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import Aliases from "../../components/shared/Aliases";
import { Env } from "../../constants/environment"
import CheckboxGroup from "../core/form/CheckboxGroup";
import Collapse from "react-bootstrap/Collapse";
import PersonnelForm from "./PersonnelForm";
import AlternateLegalNames from "../shared/AlternateLegalNames";

function mapStateToProps(state) {
  return {
    firm: state.firms.firm,
  };
}

export class BasicFirmEditForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  setPersonnelOpen(isPersonnelOpen) {
    this.setState({ isPersonnelOpen: isPersonnelOpen });
  }

  getPersonnelOpen = () => {
    return !!this.state && !!this.state.isPersonnelOpen;
  }

  render() {
    const {
      firm,
      handleSubmit,
      setFieldValue,
      onEditForm,
      values,
      touched,
      handleChange,
      handleBlur,
      errors,
    } = this.props;

    const aliasOnChange = (aliases) => {
      values.aliases = aliases;
    }

    const alternateLegalNamesOnChange = (alternateLegalNames) => {
      values.alternateLegalNames = alternateLegalNames;
    }

    if (!firm) return null;
    return (
      <div>
        <form onSubmit={handleSubmit} name="firmEditForm">
          <Container>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="firmName"
                    name="displayName"
                    label="Firm Name"
                    type="text"
                    errors={errors}
                    touched={touched}
                    value={values.displayName}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isRequired={true}
                  />
                ) :
                  <FieldGroupReadOnly
                    id="firmName"
                    label="Firm Name"
                    value={firm.displayName}
                  />
                }
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                <FieldGroupReadOnly
                  id="firmId"
                  label="Firm ID"
                  value={firm.id}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                <FieldGroupReadOnly
                  id="firmType"
                  label="Firm Type"
                  value={firm.firmType ? firm.firmType.name : null}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FirmStateListContainer
                    id="state"
                    name="state"
                    label="Firm State"
                    value={values.state ? values.state.id : null}
                    enabled={true}
                    errors={errors}
                    onChange={(e) => {
                      setFieldValue("state", e);
                    }}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="firmState"
                    label="Firm State"
                    value={firm.state ? firm.state.name : null}
                  />
                )}
              </Col>
            </Row>
            {firm && firm.firmType && firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT && <Row>
              <Col lg={6} xs={6} md={6}>
                <CheckboxGroup
                    checked={!!values && !!values.applyCommissionsAsSpread}
                    disabled={!onEditForm}
                    onClick={(e) => {
                      setFieldValue("applyCommissionsAsSpread", e.target.checked);
                    }}
                    label={"Apply Commissions as Spread"} />
              </Col>
            </Row>}
            {firm && firm.firmType && firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT && <Row>
              <Col lg={6} xs={6} md={6}>
                <CheckboxGroup
                    checked={!!values && !!values.skipCommissionBalanceAdjustment}
                    disabled={!onEditForm}
                    onClick={(e) => {
                      setFieldValue("skipCommissionBalanceAdjustment", e.target.checked);
                    }}
                    label={"Skip Commission Balance Adjustment"} />
              </Col>
            </Row>}
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm && values.firmIsParticipant ? (
                  <FirmParticipantTypeListContainer
                    id="participantType"
                    name="participantType"
                    label="Participant Type"
                    isRequired={false}
                    value={values.participantType ? values.participantType.id : 0}
                    errors={errors}
                    onChange={(e) => setFieldValue("participantType", e)}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="participantType"
                    label="Participant Type"
                    value={firm.attributes ? firm.attributes.participantType.name : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <OrganizationTypeListContainer
                    id="organizationType"
                    name="organizationType"
                    label="Organization Type"
                    value={values.organizationType ? values.organizationType.id : 0}
                    enabled={true}
                    errors={errors}
                    onChange={(e) => {
                      setFieldValue("organizationType", e);
                    }}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="organizationType"
                    label="Organization Type"
                    value={firm.attributes ? firm.attributes.organizationType.name : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="legalEntityIdentifier"
                    name="legalEntityIdentifier"
                    type="text"
                    label="Legal Entity Identifier"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.legalEntityIdentifier}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="legalEntityIdentifier"
                    label="Legal Entity Identifier"
                    value={firm.attributes ? firm.attributes.legalEntityIdentifier : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="legalName"
                    name="legalName"
                    type="text"
                    label="Legal Name"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.legalName}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="legalName"
                    label="Legal Name"
                    value={firm.attributes ? firm.attributes.legalName : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={6}>
                {onEditForm ? (
                  <FieldGroup
                    id="businessContactName"
                    name="businessContactName"
                    type="text"
                    label="Business Contact Name"
                    placeholder=""
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.businessContactName}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="businessContactName"
                    label="Business Contact Name"
                    value={firm.attributes ? firm.attributes.businessContactName : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="email"
                    name="email"
                    type="email"
                    label="Email"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.email}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="email"
                    label="Email"
                    value={firm.attributes ? firm.attributes.email : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <PhoneNumber
                    id="phoneNumber"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={(e) => setFieldValue("phoneNumber", e)}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="phoneNumber"
                    label="Phone Number"
                    value={firm.attributes ? firm.attributes.phoneNumber : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="usTaxpayerIdentificationNumber"
                    name="usTaxpayerIdentificationNumber"
                    type="text"
                    label="US Taxpayer Identification Number"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.usTaxpayerIdentificationNumber}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="usTaxpayerIdentificationNumber"
                    label="US Taxpayer Identification Number"
                    value={firm.attributes ? firm.attributes.usTaxpayerIdentificationNumber : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="designatedSelfRegulatoryOrganization"
                    name="designatedSelfRegulatoryOrganization"
                    type="text"
                    label="Designated Self Regulatory Organization"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.designatedSelfRegulatoryOrganization}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="designatedSelfRegulatoryOrganization"
                    label="Designated Self Regulatory Organization"
                    value={firm.attributes ? firm.attributes.designatedSelfRegulatoryOrganization : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="designatedExaminingAuthority"
                    name="designatedExaminingAuthority"
                    type="text"
                    label="Designated Examining Authority"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.designatedExaminingAuthority}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="designatedExaminingAuthority"
                    label="Designated Examining Authority"
                    value={firm.attributes ? firm.attributes.designatedExaminingAuthority : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="mainAddress1"
                    name="mainAddress1"
                    type="text"
                    label="Main Address 1"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.mainAddress1}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="mainAddress1"
                    label="Main Address 1"
                    value={firm.attributes ? firm.attributes.mainAddress1 : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="mainAddress2"
                    name="mainAddress2"
                    type="text"
                    label="Main Address 2"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.mainAddress2}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="mainAddress2"
                    label="Main Address 2"
                    value={firm.attributes ? firm.attributes.mainAddress2 : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="mainAddressCity"
                    name="mainAddressCity"
                    type="text"
                    label="Main Address City"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.mainAddressCity}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="mainAddressCity"
                    label="Main Address City"
                    value={firm.attributes ? firm.attributes.mainAddressCity : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="mainAddressState"
                    name="mainAddressState"
                    type="text"
                    label="Main Address State"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.mainAddressState}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="mainAddressState"
                    label="Main Address State"
                    value={firm.attributes ? firm.attributes.mainAddressState : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="mainAddressPostalCode"
                    name="mainAddressPostalCode"
                    type="text"
                    label="Main Address Postal Code"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.mainAddressPostalCode}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="mainAddressPostalCode"
                    label="Main Address Postal Code"
                    value={firm.attributes ? firm.attributes.mainAddressPostalCode : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="mainAddressCountryCode"
                    name="mainAddressCountryCode"
                    type="text"
                    label="Main Address Country Code"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.mainAddressCountryCode}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="mainAddressCountryCode"
                    label="Main Address Country Code"
                    value={firm.attributes ? firm.attributes.mainAddressCountryCode : null}
                  />
                )}
              </Col>
            </Row>

            <Row>
              <Col><AlternateLegalNames data={values?.alternateLegalNames} onChange={alternateLegalNamesOnChange} readOnly={!onEditForm} tooltip={null} /></Col>
            </Row>

            <Row>
              <Col>
                <React.Fragment>
                  <div onClick={() => this.setPersonnelOpen(!this.getPersonnelOpen())}>
                    <Row className="accordian-style">
                      <Col lg={11} xs={11} md={11}>
                        <h6>
                          <strong>Key Personnel</strong>
                        </h6>
                      </Col>
                      <Col>
                        <i
                          className={`fa fa-fw ${this.getPersonnelOpen() ? "fa-minus" : "fa-plus"
                            } custom-plus`}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Collapse in={this.getPersonnelOpen()}>
                      <div className="collapse-content">
                        {!!values && Array.isArray(values.keyPersonnel) ?
                          values.keyPersonnel.map((keyPerson, idx) => (
                            <div>
                              {(onEditForm) ? <button
                                type="button"
                                className="btn-add-alias"
                                onClick={() => {
                                  setFieldValue("keyPersonnel", values.keyPersonnel.filter((_, i) => i !== idx))
                                }}
                              >
                                Remove Entry {idx}
                              </button> : null}
                              <PersonnelForm
                                setFieldValue={(field, val) => setFieldValue("keyPersonnel", values.keyPersonnel.map((entry, i) => {
                                  let obj = entry;
                                  if (i === idx) obj[field] = val;
                                  return obj
                                }))}
                                handleBlur={handleBlur}
                                handleChange={c => setFieldValue("keyPersonnel", values.keyPersonnel.map((entry, i) => {
                                  let obj = entry;
                                  if (i === idx) obj[c.target.id] = c.target.value;
                                  return obj
                                }))}
                                errors={errors}
                                touched={touched}
                                onEditForm={onEditForm}
                                values={keyPerson}
                              />
                            </div>
                          ))
                          : null}
                        {(onEditForm) ? <button
                          type="button"
                          className="btn-add-alias"
                          onClick={() => {
                            setFieldValue("keyPersonnel", (!!values && Array.isArray(values.keyPersonnel) ? values.keyPersonnel : []).concat({}))
                          }}
                        >
                          Add Entry
                        </button> : null}
                        <Row><Col></Col> </Row>
                      </div>
                    </Collapse>
                  </div>
                </React.Fragment>
              </Col>
            </Row>

            <Row>
              <Col><Aliases onChange={aliasOnChange} aliases={(!!values && !!values.aliases) ? values.aliases : []} readOnly={!onEditForm} options={Env.getAliasOptions()} /></Col>
            </Row>

            {onEditForm ? (
              <div>
                <ButtonMain type="submit" text="SAVE" />
                <ButtonBorderless
                  type="button"
                  text="Cancel"
                  icon="times-circle"
                  customClassName="grey-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    this.context.router.history.push("/firms");
                  }}
                />
              </div>
            ) : null}
          </Container>
        </form>
      </div>
    );
  }
}

const FirmEditForm = withFormik({
  mapPropsToValues: (firm) => {
    return ({
    ...firm.firm,
    state: firm.firm.state,
    applyCommissionsAsSpread: !!firm.firm.applyCommissionsAsSpread,
    skipCommissionBalanceAdjustment: !!firm.firm.skipCommissionBalanceAdjustment,
    legalName: firm.firm.attributes ? firm.firm.attributes.legalName : null,
    legalEntityIdentifier: firm.firm.attributes ? firm.firm.attributes.legalEntityIdentifier : null,
    email: firm.firm.attributes ? firm.firm.attributes.email : "",
    phoneNumber: firm.firm.attributes ? firm.firm.attributes.phoneNumber : null,
    participantType: firm.firm.attributes ? firm.firm.attributes.participantType : null,
    organizationType: firm.firm.attributes ? firm.firm.attributes.organizationType : null,
    firmIsParticipant: firm.firm.firmType ? (firm.firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT) : false,
    displayName: firm.firm.displayName,
    businessContactName: firm.firm.attributes ? firm.firm.attributes.businessContactName : "",
    usTaxpayerIdentificationNumber: firm.firm.attributes ? firm.firm.attributes.usTaxpayerIdentificationNumber : null,
    designatedSelfRegulatoryOrganization: firm.firm.attributes ? firm.firm.attributes.designatedSelfRegulatoryOrganization : null,
    designatedExaminingAuthority: firm.firm.attributes ? firm.firm.attributes.designatedExaminingAuthority : null,
    mainAddress1: firm.firm.attributes ? firm.firm.attributes.mainAddress1 : null,
    mainAddress2: firm.firm.attributes ? firm.firm.attributes.mainAddress2 : null,
    mainAddressCity: firm.firm.attributes ? firm.firm.attributes.mainAddressCity : null,
    mainAddressState: firm.firm.attributes ? firm.firm.attributes.mainAddressState : null,
    mainAddressPostalCode: firm.firm.attributes ? firm.firm.attributes.mainAddressPostalCode : null,
    mainAddressCountryCode: firm.firm.attributes ? firm.firm.attributes.mainAddressCountryCode : null,
    keyPersonnel: firm.firm.attributes ? firm.firm.attributes.keyPersonnel : null,
    alternateLegalNames: firm.firm.attributes ? firm.firm.attributes.alternateLegalNames : null,
  })},
  validationSchema: Yup.object().shape({
    id: Yup.string().nullable().required("Firm name is required."),
    email: Yup.string().email("Invalid email format"),
    displayName: Yup.string().required("Display name is required"),
    keyPersonnel: Yup.array().of(Yup.object().shape({
      email: Yup.string().email("Invalid email format"),
    }))
  }),
  validate: (values) => {
    let errors = {};
    if (!values.state) {
      errors.state = "State must be defined.";
    }
    return errors;
  },

  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onEditForm(values, props.firm, setFieldError);
  },
  displayName: "firm-edit-form",
  enableReinitialize: true,
})(BasicFirmEditForm);

export default connect(mapStateToProps, null)(FirmEditForm);
