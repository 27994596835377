import ApiCall from "../modules/apiCall";
import { apiUrl } from "../modules/util";
import _ from "lodash";

const {
  ListTradesRequest,
  DownloadTradesRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");
const {
  AdminAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");

const client = new AdminAPIClient(apiUrl(), null, null);

export default class TimeSalesService {
  static getFiltered(
    startDate,
    endDate,
    symbol,
    participant,
    accounts,
    orderid,
    execid,
    tradeid,
    clientaccountid,
    clientparticipantid,
    parentOrderId,
    symbolSubType,
    tradeStates,
    pageToken,
    pageSize,
    onRefresh,
    onError
  ) {
    const cbLoop = (err, response) => {
      if (response) {
        const trades = response.getTradesList();
        const nextPageToken = response.getNextPageToken();
        const eof = response.getEof();
        onRefresh(trades, pageToken, nextPageToken, eof);
      }
      if (err) {
        onError(err);
      }
    };
    var request = this.buildTradeRequest(startDate, endDate, symbol, participant, accounts, orderid, execid, tradeid, clientaccountid, clientparticipantid, parentOrderId, symbolSubType, tradeStates, pageToken, pageSize)

    ApiCall(client, "listTrades", request, cbLoop);
  }

  static getTrade(
    tradeId,
    onRefresh,
    onError,
    pageSize=1
  ) {
    const cbLoop = (err, response) => {
      if (response) {
        const trades = response.getTradesList();
        onRefresh(trades);
      }
      if (err) {
        onError(err);
      }
    };
    var request = new ListTradesRequest();
    request.setTradeId(tradeId);
    request.setNewestFirst(true);
    request.setPageSize(pageSize);

    ApiCall(client, "listTrades", request, cbLoop);
  }

  static async downloadTradesReport(
    startDate,
    endDate,
    symbol,
    participant,
    accounts,
    orderid,
    execid,
    tradeid,
    clientaccountid,
    clientparticipantid,
    parentOrderId,
    symbolSubType,
    tradeStates,
    onSuccess,
    onError
  ) {
    let request = new DownloadTradesRequest();

    let tradeRequest = this.buildTradeRequest(startDate, endDate, symbol, participant, accounts, orderid, execid, tradeid, clientaccountid, clientparticipantid, parentOrderId, symbolSubType, tradeStates, null, null)

    request.setTradesRequest(tradeRequest);

    var call = ApiCall(client, "downloadTrades", request);
    var chunks = [];
    call.on("data", data => chunks.push(data.getFilechunk()));
    call.on("status", sts => {
      if (sts && sts.code === 0) onSuccess(chunks.join(""));
    });
    call.on("error", onError);
  }


  static buildTradeRequest(
    startDate,
    endDate,
    symbol,
    participant,
    accounts,
    orderid,
    execid,
    tradeid,
    clientaccountid,
    clientparticipantid,
    parentOrderId,
    symbolSubType,
    tradeStates,
    pageToken,
    pageSize) {

      var request = new ListTradesRequest();
      request.setStartTime(startDate);
      request.setEndTime(endDate);
      request.setSymbol(symbol);
      // Participant accepts regular expression using ignore case.
      if (participant) request.setParticipant("/"+participant+"/i");
      request.setOrderId(orderid);
      request.setExecId(execid);
      request.setTradeId(tradeid);
      request.setPageSize(pageSize ? pageSize : 10);
      request.setNewestFirst(true);
      if (pageToken) request.setPageToken(pageToken);
      if (!!accounts && !_.isEmpty(accounts))  {
        if (!Array.isArray(accounts)) {
          accounts = accounts.split(",")
        }
        // Account accepts regular expression using ignore case when only a single account is searched.
        if (accounts.length === 1) {
          request.setAccountsList(["/"+accounts[0].trim()+"/i"])
        } else {
          let trimmed = []
          accounts.forEach((acc) => {
            trimmed.push(acc.trim())
          })
          request.setAccountsList(trimmed)
        }
      }
      request.setClientAccountId(clientaccountid);
      request.setClientParticipantId(clientparticipantid);
      request.setParentOrderId(parentOrderId);
      request.setSymbolSubType(symbolSubType);
  
      if (tradeStates && Array.isArray(tradeStates)) {
        request.setStatesList(tradeStates);
      }
      return request
  }
}

