import { isArrayWithValues } from "../util";

const {
  FirmAttributes,
  Address,
  Personnel,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb.js");

export default class FirmAttributesMapper {
  static mapToFirmAttributes(data) {
    var firmAttributes = new FirmAttributes();
    if (data.legalName) firmAttributes.setLegalName(data.legalName);
    if (data.phoneNumber) firmAttributes.setPhoneNumber(data.phoneNumber);
    if (data.email) firmAttributes.setEmail(data.email);
    if (data.legalEntityIdentifier) firmAttributes.setLegalEntityIdentifier(data.legalEntityIdentifier);
    if (data.participantType) firmAttributes.setParticipantType(data.participantType.id);
    if (data.organizationType) firmAttributes.setOrganizationType(data.organizationType.id);
    if (data.businessContactName) firmAttributes.setBusinessContactName(data.businessContactName);
    if (data.usTaxpayerIdentificationNumber) firmAttributes.setUsTaxpayerIdentificationNumber(data.usTaxpayerIdentificationNumber);
    if (data.designatedSelfRegulatoryOrganization) firmAttributes.setDesignatedSelfRegulatoryOrganization(data.designatedSelfRegulatoryOrganization);
    if (data.designatedExaminingAuthority) firmAttributes.setDesignatedExaminingAuthority(data.designatedExaminingAuthority);
  
    var address = new Address();
    if (data.mainAddress1) address.setAddress1(data.mainAddress1)
    if (data.mainAddress2) address.setAddress2(data.mainAddress2)
    if (data.mainAddressCity) address.setCity(data.mainAddressCity)
    if (data.mainAddressState) address.setState(data.mainAddressState)
    if (data.mainAddressPostalCode) address.setPostalCode(data.mainAddressPostalCode)
    if (data.mainAddressCountryCode) address.setCountryCode(data.mainAddressCountryCode)

    if (isArrayWithValues(data.alternateLegalNames)) {
      firmAttributes.setAlternateLegalNamesList(data.alternateLegalNames);
    }

    firmAttributes.setMainAddress(address);

    firmAttributes.clearKeyPersonnelList();
    if (isArrayWithValues(data.keyPersonnel)) {
      let personnel = []
      data.keyPersonnel.forEach((entry) => {
        var person = new Personnel();
        if (entry.name) person.setName(entry.name)
        if (entry.phoneNumber) person.setPhoneNumber(entry.phoneNumber)
        if (entry.email) person.setEmail(entry.email)
        if (entry.role) person.setRole(entry.role.id)

        var address = new Address();
        if (entry.address1) address.setAddress1(entry.address1)
        if (entry.address2) address.setAddress2(entry.address2)
        if (entry.addressCity) address.setCity(entry.addressCity)
        if (entry.addressState) address.setState(entry.addressState)
        if (entry.addressPostalCode) address.setPostalCode(entry.addressPostalCode)
        if (entry.addressCountryCode) address.setCountryCode(entry.addressCountryCode)
        person.setAddress(address);

        personnel.push(person)
      });

      if (personnel.length > 0) {
        firmAttributes.setKeyPersonnelList(Array.from(personnel));
      }
    }

    return firmAttributes;
  }
}
