import React, { Component } from "react";
import DropdownListGroup from "../components/core/form/DropdownListGroup";
import { convertEnumToDropdownList } from "../modules/util";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";

class FirmTypeListContainer extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    let types = Object.assign({}, FirmType);
    delete types.FIRM_TYPE_UNDEFINED
    const data = convertEnumToDropdownList(types);
    this.setState({ data });
  }

  render() {
    return (
      <DropdownListGroup
        id="firmType"
        name="firmType"
        label="Firm Type"
        isRequired={this.props.isRequired}
        value={this.props.value}
        errors={this.props.errors}
        onChange={(event) => {
          this.props.onChange(event);
        }}
        data={this.state.data}
      />
    );
  }
}

export default FirmTypeListContainer;
