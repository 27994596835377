import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from "react-bootstrap";
import { confirmable } from 'react-confirm';
import ButtonMain from '../form/ButtonMain';
import ButtonSecondary from '../form/ButtonSecondary';

const BasicModal = ({
  children,
  title,
  show,
  okButtonText,
  cancelButtonText,
  onOk,
  onClose,
  additionalBtnText,
  additionalBtnClick,
  className,
  size
}) => {
  return (<Modal show={show} onHide={onClose} size={size} dialogClassName={className} >
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {children}
    </Modal.Body>
    <Modal.Footer>
      {okButtonText && <ButtonMain
        type="button"
        onClick={onOk}
        text={okButtonText}
      />}

      {additionalBtnText && <ButtonMain
        type="button"
        onClick={additionalBtnClick}
        text={additionalBtnText}
      />}

      {cancelButtonText && <ButtonSecondary
        type="button"
        onClick={onClose}
        text={cancelButtonText}
      />}
    </Modal.Footer>
  </Modal>)
};

const ConfirmDialog = ({
  show,
  proceed,
  dismiss,
  cancel,
  confirmation,
  options,
  className,
  size //'sm' | 'lg' | 'xl';
}) => {
  if (!show) return null;
  return (
    <div>
      <BasicModal
        show={show}
        title={options.title}
        onClose={cancel}
        onOk={proceed}
        className={className}
        size={size}
        okButtonText={options.okButtonText}
        cancelButtonText={options.cancelButtonText}
        additionalBtnText={options.additionalBtnText}
        additionalBtnClick={options.additionalBtnClick}
      >
        {confirmation}
      </BasicModal>
    </div>
  )
}

ConfirmDialog.propTypes = {
  show: PropTypes.bool,
  proceed: PropTypes.func,
  cancel: PropTypes.func,
  dismiss: PropTypes.func,
  confirmation: PropTypes.any,
  options: PropTypes.shape({
    title: PropTypes.string.isRequired
  })
}

export default confirmable(ConfirmDialog);