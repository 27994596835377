import ApiCall from "../modules/apiCall";
import {apiUrl} from "../modules/util";

const {
  GetUnAggregatedBookRequest,
  ListCurrentInstrumentStatusesRequest,
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js');
const { AdminAPIClient } = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js');

const client = new AdminAPIClient(apiUrl(), null, null);

export default class BookService {
  static getUnaggregatedBooks(symbol, includeStatusOnly, cb) {
    var request = new GetUnAggregatedBookRequest();
    request.setSymbol(symbol);
    request.setStatusOnly(!!includeStatusOnly);

    ApiCall(client, "getUnAggregatedBook", request, cb);
  }

  static listCurrentInstrumentStatuses(symbols, cb) {
    var request = new ListCurrentInstrumentStatusesRequest();
    request.setSymbolsList(symbols);

    ApiCall(client, "listCurrentInstrumentStatuses", request, cb);
  }
}
