import React, { Component } from "react";
import BookDataGrid from "../components/book/BookDataGrid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getUnaggregatedBooks,
} from "../actions/books";
import {
  fetchInstrumentIdsList,
  cleanInstrumentsList,
} from "../actions/instruments";
import {
  LoadParticipants,
} from "../actions/participants";
import {
  LoadFirms,
} from "../actions/firms";
import {
  Row,
  Col,
} from "react-bootstrap";
import "./Content.css";
import InstrumentHeader from "../components/instrument/InstrumentHeader";
import { AdminDocumentTitle } from "../constants/strings";
import SearchNoItemsResult from "../components/core/search-no-items/SearchNoItemsResult";
import LoaderIcon from "../components/core/loader/LoaderIcon";
import AutoComplete from "../components/core/auto-complete/AutoComplete";
import confirm from "../modules/confirmDialog";
import OrderService from "../services/OrderService";
import Notification from "../modules/notifications";

import "./BooksPage.css"
import { TitleBreadcrumb } from "../components/core/title-breadcrumb/title-breadcrumb";

const queryString = require("query-string");

function mapStateToProps(state) {
  return {
    fetchingBooks: state.books.fetchingBooks,
    limitBook: state.books.books.limit,
    stopBook: state.books.books.stop,
    noItemsFound: state.books.noItemsFound,
    instruments: state.instruments.instrumentIdsList,
    participants: state.participants.participants.map((p) => {
      var firm = state.firms.firms.find((f) => f.name === p.firm);
      p.firm = firm ? firm : p.firm;
      return p;
    }),
  };
}

const mapDispatchToProps = (dispatch) => ({
  getUnaggregatedBooks: (symbol) => {
    dispatch(getUnaggregatedBooks(symbol));
  },
  fetchInstrumentIdsList: () => {
    dispatch(fetchInstrumentIdsList());
  },
  cleanInstrumentsList: () => {
    dispatch(cleanInstrumentsList());
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
  loadParticipants: () => {
    dispatch(LoadParticipants());
  },
});

const searchFrequency = 5; //(seconds)

const CancelOrderMessage = () => {
  return (
    <div>
      Are you sure you want to cancel?
    </div>
  );
};

class BooksPage extends Component {
  searchBooksTimer = 0;
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();
    this.state = {
      searchSymbol: "",
      enteredSymbol: "",
    };
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    const params = queryString.parse(this.props.location.search);
    if (params.instrumentId) {
      this.search(params.instrumentId);
      this.searchBooksTimer = setInterval(() => {
        this.props.getUnaggregatedBooks(params.instrumentId);
      }, searchFrequency * 1000);
    }

    this.props.cleanInstrumentsList();
    this.props.loadFirms();
    this.props.loadParticipants();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearInterval(this.searchBooksTimer);
  }

  onSearch = (symbol) => {
    this.context.router.history.push(`/books?instrumentId=${encodeURIComponent(symbol)}`);
  };

  onCancel = (id) => {
    confirm(<CancelOrderMessage />, {
      title: id,
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const symbol = this.state.searchSymbol;
        const cb = (err, response) => {
          if (response) {
            Notification.success(`Submitted cancel for ${id}`);
            this.props.getUnaggregatedBooks(symbol);
          }

          if (err) {
            Notification.error(`Cannot cancel ${id}: ${err.message}`);
          }
        };
        OrderService.cancelOrder(id, cb);
      },
      () => {
      },
    );
  };

  onFindAndCancelOrders = (symbol) => {
    confirm(<CancelOrderMessage />, {
      title: symbol,
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
        () => {
          const cb = (err, response) => {
            if (response) {
              Notification.success(`Submitted mass cancel for ${symbol}`);
              this.props.getUnaggregatedBooks(symbol);
            }

            if (err) {
              Notification.error(`Cannot mass cancel ${symbol}: ${err.message}`);
            }
          };
          OrderService.findAndCancelOrders(symbol, cb);
        },
        () => {
        },
    );
  };

  search = (symbol) => {
    this.props.getUnaggregatedBooks(symbol);
    this.setState({ searchSymbol: symbol, enteredSymbol: symbol });
  };

  render() {
    return (
      <div className="with-callback">
        <InstrumentHeader
          isInstrumentsSelected={true}
          isAddButtonVisible={false}
          secondaryButtonText={"Cancel All Orders"}
          secondaryButtonClick={() => this.onFindAndCancelOrders(this.state.searchSymbol)}
          isSecondaryButtonVisible={!this.props.noItemsFound}
          custonHeaderText={this.props.noItemsFound
            ? <TitleBreadcrumb titles={[{ 'title': `Instruments`, link: `${window.location.origin}/instruments` }, { 'title': 'Unaggregated Book' }]} />
            : this.state.searchSymbol
              ? <TitleBreadcrumb titles={[{ 'title': 'Instruments', link: `${window.location.origin}/instruments` }, { 'title': this.state.searchSymbol }, { 'title': 'Book' }]} />
              : <TitleBreadcrumb titles={[{ 'title': "Instruments", link: `${window.location.origin}/instruments` }]} />}
        />
        <Row className="page-header">
          <Col className="form-group book-auto-complete-icon-position" lg={4} xs={4} md={4}>
            <AutoComplete
              id="instrumentId"
              placeholder="Instrument ID"
              noItemsMessage="No instruments found"
              dataSource={this.props.instruments}
              refreshDataSource={() => {
                this.props.fetchInstrumentIdsList();
              }}
              defaultValue={this.state.searchSymbol}
              onChange={(e) => {
                this.setState({ enteredSymbol: e.target.value });
              }}
              onEnter={() => {
                this.onSearch(this.state.enteredSymbol);
              }}
              onSelect={(value) => {
                this.onSearch(value);
              }}
            />
          </Col>
          <Col lg={1} xs={1} md={1}>
            <LoaderIcon show={this.props.fetchingBooks} />
          </Col>
        </Row>
        {this.props.noItemsFound
          ? <SearchNoItemsResult
            message="Sorry, but we didn't find any Instrument with that ID."
          />
          : <div>
            <h3>Limit Book</h3>
            <BookDataGrid
              data={this.props.limitBook}
              isStop={false}
              onCancel={(id) => {
                this.onCancel(id);
              }}
              participants={this.props.participants}
            />
            <br />
            <h3>Stop Book</h3>
            <BookDataGrid
              data={this.props.stopBook}
              isStop={true}
              onCancel={(id) => {
                this.onCancel(id);
              }}
              participants={this.props.participants}
            />
          </div>}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BooksPage);
