import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Col, FormLabel, } from "react-bootstrap";

import FieldGroup from "../core/form/FieldGroup";
import Fraction from "../../modules/fraction";
import TradingScheduleDialog from "../core/TradingScheduleDialog";
import OrderLimit from "../order-limit/OrderLimit";
import RelativePriceLimit from "../relative-price-limit/RelativePriceLimit";
import TradingHours from "../core/TradingHours";
import DropdownListGroup from "../core/form/DropdownListGroup";
import MultiSelectDropdownListGroup from "../core/form/MultiSelectDropdownListGroup";
import MultiItem from "../core/form/MultiItem";
import {FormModes, TradingHourPeriodicity} from "../../constants/enumerations";
import ButtonSecondary from "../core/form/ButtonSecondary";
import ButtonMain from "../core/form/ButtonMain";
import ButtonBorderless from "../core/form/ButtonBorderless";
import {
  capitalizeWord,
  countDecimals,
  getEnumName,
  isEmptyString,
  isNumber,
  numberOrDefault,
  parsePrice,
} from "../../modules/util";
import FirmsListContainer from "../../containers/FirmsListContainer";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import {
  INTEREST_RATE_SWAP,
  DEFAULT,
  FUTURE,
  OPTION,
  MULTILEG,
  FORWARD_STARTING_INTEREST_RATE_SWAP,
  BASIS_SWAP,
  FORWARD_RATE_AGREEMENT_SWAP,
  EVENT,
  OVER_NIGHT_INDEX_SWAP,
  SINGLE_PERIOD_SWAP,
  NON_DELIVERABLE_FORWARDS,
  FOREX,
  ZERO_COUPON_INFLATION_SWAP
} from "../../constants/strings";
import InstrumentStaticData from "../../modules/instrumentStaticData";
import { isNullOrUndefined } from "../../modules/util";
import State from "../../entities/dto/State";
import DateTimeParser from "../../modules/dateTimeParser";
import DateGroup from "../core/form/DateGroup";
import CheckboxGroup from "../core/form/CheckboxGroup";
import {
  SetSyncAdditionalInfo,
  SetSyncTradingInfo,
  SetSyncCrossOrderInfo,
  SetSyncSettlementPriceCalcInfo,
  SetSyncTradeDayRollSchedule,
} from "../../actions/products";
import { valueOrDefault } from "../../modules/util";
import AutoComplete from "../core/auto-complete/AutoComplete";
import { Env } from "../../constants/environment";
import CreateProductCategoryLevel from "./components/CreateProductCategoryLevel";
import PriceLimit from "../price-limit/PriceLimit";
import TotalNotionalLimit from "../total-notional-limit/TotalNotionalLimit";
import { SubTypes, SubTypesSchemaValidation } from "../instrument/components/event/SubTypes";

import TimeDuration from  "../../components/core/TimeDuration";
import * as toolTips from '../../constants/productToolTip'
import {validateField as validate} from "../instrument/instrumentFormUtils";
import ProtobufParser from "../../modules/protobufParser";
import Tooltip from "../core/tooltip-html/tooltip-html";
import TradeDayRollSchedule from "../core/TradeDayRollSchedule/TradeDayRollSchedule";

const SELECTED_DEFAULT = "Select";
const NUMBER_TYPE = "number";
const DATE_TYPE = "date";
const STRING_TYPE = "string";
const ARRAY_TYPE = "array";

const EVENT_ATTR_YES_BUY_PRICE_INCREMENT = valueOrDefault(
  Env.getEnv("REACT_APP_EVENT_ATTR_YES_BUY_PRICE_INCREMENT"),
  0
);
const EVENT_ATTR_YES_SELL_PRICE_INCREMENT = valueOrDefault(
  Env.getEnv("REACT_APP_EVENT_ATTR_YES_SELL_PRICE_INCREMENT"),
  0
);
const EVENT_ATTR_YES_BUY_SCALE = valueOrDefault(
  Env.getEnv("REACT_APP_EVENT_ATTR_YES_BUY_SCALE"),
  1
);
const EVENT_ATTR_YES_SELL_SCALE = valueOrDefault(
  Env.getEnv("REACT_APP_EVENT_ATTR_YES_SELL_SCALE"),
  0
);

const EVENT_ATTR_NO_BUY_PRICE_INCREMENT = valueOrDefault(
  Env.getEnv("REACT_APP_EVENT_ATTR_NO_BUY_PRICE_INCREMENT"),
  1.01
);
const EVENT_ATTR_NO_SELL_PRICE_INCREMENT = valueOrDefault(
  Env.getEnv("REACT_APP_EVENT_ATTR_NO_SELL_PRICE_INCREMENT"),
  0
);
const EVENT_ATTR_NO_BUY_SCALE = valueOrDefault(
  Env.getEnv("REACT_APP_EVENT_ATTR_NO_BUY_SCALE"),
  -1
);
const EVENT_ATTR_NO_SELL_SCALE = valueOrDefault(
  Env.getEnv("REACT_APP_EVENT_ATTR_NO_SELL_SCALE"),
  0
);
const calculationMethods = InstrumentStaticData.EventCalculationMethod([0]);
const payoutTypes = InstrumentStaticData.EventPayoutType;

const EVENT_ATTR_STRIKE_UNIT = Env.getEventAttrStrikeUnits();

const submitHandler = (processName, onBeforeSubmit, submitForm) => {
  onBeforeSubmit(processName);
  submitForm();
};

const initialState = {
  selectedSchedule: null,
  selectedScheduleIndex: null,
  showTradingShedule: false,
  isNew: false,
  syncTradingWithChildInstruments: false,
  syncAdditionalInfoWithChildInstruments: false,
  syncCrossOrderInfo: false,
  syncSettlementPriceCalcInfo: false,
  calculationMethods: [],
  categoryLevels: null,
  categoryLevelEdit: {
    isEditing: false,
    categoryLevelsList: [],
    editIndex: 0
  }
};

class BasicProductForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };


  constructor() {
    super();
    this.state = initialState;
  }

  componentDidMount() {
    this.setState({ calculationMethods: InstrumentStaticData.EventCalculationMethod([0]) })
  }

  static getDerivedStateFromProps(props, state) {
    if (state.categoryLevels === null && (!!props.product.categoryLevels && props.product.categoryLevels.length > 0)) {
      const categoryLevels = new Set(props.product.categoryLevels);
      return {
        categoryLevels: categoryLevels
      }
    }

    return null;
  }

  toggleSchedule = () => {
    this.setState(initialState);
  };

  addSchedule = () => {
    let state = new State();
    state.id = InstrumentState.INSTRUMENT_STATE_OPEN;
    state.name = capitalizeWord(getEnumName(InstrumentState, state.id));
    const newSchedule = {
      daysOfWeekList: [],
      duration: { seconds: 28800, nanos: 0 },
      state: state,
      periodicity: TradingHourPeriodicity.EVERYDAY,
      timeOfDay: { hours: 8, minutes: 0, seconds: 0 },
    };
    let tradingScheduleList = this.props.values.tradingScheduleList;

    if (!tradingScheduleList) {
      tradingScheduleList = [];
    }
    tradingScheduleList.push(newSchedule);
    this.props.setFieldValue("tradingScheduleList", tradingScheduleList);
    this.setState({
      isNew: true,
      selectedSchedule: newSchedule,
      selectedScheduleIndex: tradingScheduleList.length - 1,
      showTradingShedule: true,
    });
  };

  cancelAddSchedule = () => {
    const tradingScheduleList = this.props.values.tradingScheduleList;
    tradingScheduleList.splice(this.state.selectedScheduleIndex, 1);
    this.props.setFieldValue("tradingScheduleList", tradingScheduleList);
    this.setState(initialState);
  };

  removeSchedule = (index) => {
    const tradingScheduleList = this.props.values.tradingScheduleList;
    tradingScheduleList.splice(index, 1);
    this.props.setFieldValue("tradingScheduleList", tradingScheduleList);
  };

  addHolidayCalendar = (cal) => {
    const holidayCalendars = this.props.values.holidayCalendars || [];
    holidayCalendars.push(cal);
    this.props.setFieldValue("holidayCalendars", holidayCalendars);
  };

  removeHolidayCalendar = (index) => {
    const holidayCalendars = this.props.values.holidayCalendars;
    holidayCalendars.splice(index, 1);
    this.props.setFieldValue("holidayCalendars", holidayCalendars);
  };

  saveSchedule = (schedule) => {
    const { days, state, from, periodicity, duration } = schedule;

    const fromTime = moment().startOf("day").add(from, "seconds");
    let stateObj = new State();
    stateObj.id = state ? state.id : 0;
    stateObj.name = state ? state.name : "";
    const updatedSchedule = {
      daysOfWeekList: days,
      state: stateObj,
      periodicity: periodicity,
      duration: { seconds: duration, nanos: 0 },
      timeOfDay: {
        hours: fromTime.hours(),
        minutes: fromTime.minutes(),
        seconds: fromTime.seconds(),
      },
    };

    const tradingScheduleList = this.props.values.tradingScheduleList;
    tradingScheduleList[this.state.selectedScheduleIndex] = updatedSchedule;
    this.props.setFieldValue("tradingScheduleList", tradingScheduleList);
    this.toggleSchedule();
  };

  enableStartDate = (isPartialEditable, isEditing, startDate) => {
    if (isEditing) {
      if (startDate && isPartialEditable) {
        return startDate && startDate > new Date();
      }
      return false;
    }
    return true;
  };

  onAddCategoryLevel = () => {
    const products = this.props.products;
    const categoryLevelsList = [];
    products.forEach(prod => {
      if (!!prod.categoryLevels && prod.categoryLevels.length > 0) {
        categoryLevelsList.push(prod.categoryLevels);
      }
    });

    const categoryLevels = this.state.categoryLevels;
    let categoryLevelIdx = 0;
    if (!!categoryLevels && categoryLevels.size > 0)
      categoryLevelIdx = categoryLevels.size;

    this.setState({
      categoryLevelEdit: {
        isEditing: true,
        categoryLevelsList: categoryLevelsList,
        editIndex: categoryLevelIdx
      }
    });
  }

  onAddCategoryLevelDialogClose = () => {
    this.setState({
      categoryLevelEdit: {
        isEditing: false,
        categoryLevelsList: [],
        editIndex: 0
      }
    });
  }

  addCategoryLevel = (category) => {
    if (!!category && category.trim().length > 0) {
      let categories = this.state.categoryLevels;

      if (isNullOrUndefined(categories))
        categories = new Set();

      categories.add(category);
      this.setState({ categoryLevels: categories });
      this.props.setFieldValue("categoryLevels", categories);
    }
  }

  removeCategoryLevel = (category) => {
    if (!!category && category.trim().length > 0) {
      const categories = this.state.categoryLevels;
      categories.delete(category);
      this.setState({ categoryLevels: categories });
      this.props.setFieldValue("categoryLevels", categories);
    }
  }


  render() {
    const {
      title,
      isEditing,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      onDelete,
      onBeforeSubmit,
      submitForm,
      setFieldValue,
      metadata,
      holidays,
    } = this.props;
    const showIRS = this.state.type
      ? this.state.type === INTEREST_RATE_SWAP
      : values.type === INTEREST_RATE_SWAP;
    const showFsIRS = this.state.type
      ? this.state.type === FORWARD_STARTING_INTEREST_RATE_SWAP
      : values.type === FORWARD_STARTING_INTEREST_RATE_SWAP;
    const showBasisSwap = this.state.type
      ? this.state.type === BASIS_SWAP
      : values.type === BASIS_SWAP;
    const showZCIS = this.state.type
      ? this.state.type === ZERO_COUPON_INFLATION_SWAP
      : values.type === ZERO_COUPON_INFLATION_SWAP;

    const isForex = this.state.type
        ? this.state.type === FOREX
        : values.type === FOREX;
    const showFRAS = this.state.type
      ? this.state.type === FORWARD_RATE_AGREEMENT_SWAP
      : values.type === FORWARD_RATE_AGREEMENT_SWAP;
    const showOIS = this.state.type
      ? this.state.type === OVER_NIGHT_INDEX_SWAP
      : values.type === OVER_NIGHT_INDEX_SWAP;
    const showEvent = this.state.type
      ? this.state.type === EVENT
      : values.type === EVENT;

    const showSps = this.state.type
      ? this.state.type === SINGLE_PERIOD_SWAP
      : values.type === SINGLE_PERIOD_SWAP;

    const showNdf = this.state.type
      ? this.state.type === NON_DELIVERABLE_FORWARDS
      : values.type === NON_DELIVERABLE_FORWARDS;

    let filteredScheduleList = [];

    if (values.tradingScheduleList) {
      filteredScheduleList = values.tradingScheduleList.filter(
        (schedule) => schedule.daysOfWeekList.length !== 0
      );
    }

    const timezone = metadata && metadata.location ? metadata.location : "UTC";

    const setIsContinuous = (id) => {
      if (id === payoutTypes[1].id) {
        return true;
      } else {
        return false;
      }
    }

    const getEvtAttQuestions = () => {
      if (!this.state.evtAttQuestionsList) {
        const questions = new Set();
        this.props.products.forEach((prod) => {
          if (!!prod.evtAttQuestion) {
            questions.add(prod.evtAttQuestion)
          }
        });

        const questionsList = Array.from(questions)
        this.setState({ evtAttQuestionsList: questionsList });

        return questionsList;
      }

      return this.state.evtAttQuestionsList;
    }

    return (
      <React.Fragment>
        <form id="product-form">
          <h1>{title}</h1>
          <Container>
            <Row>
              <Col lg={3} xs={3} md={3}>
                <DropdownListGroup
                  id="type"
                  name="type"
                  label="Product Type"
                  value={this.state.type || values.type || DEFAULT}
                  enabled={!isEditing}
                  errors={errors}
                  onChange={(e) => { this.setState({ "type": e }); setFieldValue("type", e) }}
                  data={[DEFAULT, FUTURE, OPTION, FOREX, INTEREST_RATE_SWAP, FORWARD_STARTING_INTEREST_RATE_SWAP, MULTILEG, BASIS_SWAP, FORWARD_RATE_AGREEMENT_SWAP, EVENT, OVER_NIGHT_INDEX_SWAP, SINGLE_PERIOD_SWAP, NON_DELIVERABLE_FORWARDS, ZERO_COUPON_INFLATION_SWAP]}
                ></DropdownListGroup>
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroup
                  id="name"
                  name="name"
                  type="text"
                  label="Product Name (ID)"
                  help="The Product Name must be unique."
                  placeholder=""
                  isRequired={true}
                  disabled={isEditing}
                  errors={errors}
                  touched={touched}
                  value={values.name}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroup
                  id="description"
                  name="description"
                  type="textarea"
                  label="Description"
                  tooltip={toolTips.PRODUCT_DESCRIPTION}
                  isRequired={true}
                  setFieldValue={setFieldValue}
                  value={values.description}
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroup
                  id="cfiCode"
                  name="cfiCode"
                  type="textarea"
                  label="CFI Code"
                  tooltip={toolTips.CFI_CODE}
                  isRequired={false}
                  setFieldValue={setFieldValue}
                  value={values.cfiCode}
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={6}>
                <MultiItem
                  id="categoryLevels"
                  name="categoryLevels"
                  label="Category Levels"
                  labelWrapperClass="label-wrapper"
                  errors={errors}
                  data={this.state.categoryLevels}
                  onAddNew={(e) => this.onAddCategoryLevel(e)}
                  onRemove={(e) => this.removeCategoryLevel(e)}
                  toolTip={toolTips.CATEGORY_LEVELS}
                />
              </Col>
              <Col>
                <CreateProductCategoryLevel
                  show={this.state.categoryLevelEdit.isEditing}
                  categoryLevelsList={this.state.categoryLevelEdit.categoryLevelsList}
                  workingIndex={this.state.categoryLevelEdit.editIndex || 0}
                  onComplete={(category) => this.addCategoryLevel(category)}
                  onCancel={() => this.onAddCategoryLevelDialogClose()}
                />
              </Col>

              <Col lg={3} xs={3} md={3}>
                <FieldGroup
                    id="externalSymbol"
                    name="externalSymbol"
                    type="textarea"
                    label="External Symbol"
                    isRequired={false}
                    setFieldValue={setFieldValue}
                    value={values.externalSymbol}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
              </Col>

            </Row>

            <React.Fragment>
              <hr />

              <Row>
                <Col lg={9} xs={9} md={9}>
                  <h6>
                    <strong>Trading Information:</strong>
                  </h6>
                </Col>

                {isEditing && (
                  <Col lg={3} xs={3} md={3}>
                    <strong>
                      <CheckboxGroup
                        checked={this.state.syncTradingWithChildInstruments}
                        onClick={(e) => {
                          const val = e.currentTarget.checked;
                          this.props.setSyncTradingInfo(val);
                          this.setState({
                            syncTradingWithChildInstruments: val,
                          });
                        }}
                        label="Sync Child Instruments?"
                      />
                    </strong>
                  </Col>
                )}
              </Row>

              <Row>
                <Col lg={12} xs={12} md={12}>
                  <TradingHours
                    scheduleList={filteredScheduleList}
                    holidayCalendars={values.holidayCalendars}
                    addHolidayCalendar={(cal) => this.addHolidayCalendar(cal)}
                    removeHolidayCalendar={(index) =>
                      this.removeHolidayCalendar(index)
                    }
                    enableEdit={true}
                    addSchedule={() => this.addSchedule()}
                    editSchedule={(schedule, index) =>
                      this.setState({
                        showTradingShedule: true,
                        selectedSchedule: schedule,
                        selectedScheduleIndex: index,
                      })
                    }
                    removeSchedule={(index) => this.removeSchedule(index)}
                    metadata={metadata}
                    holidays={holidays}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <label><h6><strong>{"Trade Day Roll Schedule (" + timezone + ")"}</strong></h6></label>
                  <Tooltip
                    text={toolTips.TRADE_DAY_ROLL_SCHEDULE}
                    id="tipTradeDayRollSchedule"
                    place='right'
                  ></Tooltip>
                </Col>
                {isEditing && (
                  <Col lg={3} xs={3} md={3}>
                    <strong>
                      <CheckboxGroup
                        checked={this.state.syncTradeDayRollScheduleWithChildInstruments}
                        onClick={(e) => {
                          const val = e.currentTarget.checked;
                          this.props.setSyncTradeDayRollSchedule(val);
                          this.setState({
                            syncTradeDayRollScheduleWithChildInstruments: val,
                          });
                        }}
                        label="Sync Child Instruments?"
                      />
                    </strong>
                  </Col>
                )}
              </Row>
              <TradeDayRollSchedule
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                mode={FormModes.creation}
              />
              <hr />
              <Row>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup
                    id="basecurrency"
                    name="basecurrency"
                    as="select"
                    label="Currency"
                    isRequired={true}
                    value={values.basecurrency}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    onChange={handleChange}
                    options={
                      metadata && metadata.currencies
                        ? Object.keys(metadata.currencies)
                        : []
                    }
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FirmsListContainer
                    id="clearingHouse"
                    errors={errors}
                    name="clearingHouse"
                    label="Clearing House"
                    firmType={FirmType.FIRM_TYPE_CLEARING_HOUSE}
                    isRequired={false}
                    value={values.clearingHouse}
                    onChange={(firm) =>
                      setFieldValue("clearingHouse", firm.name)
                    }
                  ></FirmsListContainer>
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup
                    id="multiplier"
                    name="multiplier"
                    type="textarea"
                    label="Multiplier"
                    tooltip={toolTips.MULTIPLIER}
                    isRequired={true}
                    disabled={isEditing}
                    value={values.multiplier}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup
                    id="tradeSettlementPeriod"
                    name="tradeSettlementPeriod"
                    type="textarea"
                    label="Trade Settlement Period"
                    tooltip={toolTips.TRADE_SETTLEMENT_PERIOD}
                    disabled={isEditing}
                    value={values.tradeSettlementPeriod}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} xs={3} md={3}>
                  <DropdownListGroup
                      id="instrumentProductType"
                      name="instrumentProductType"
                      label="Underlying Type"
                      value={values.instrumentProductType}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                          setFieldValue("instrumentProductType", e.id)
                      }
                      data={InstrumentStaticData.InstrumentProductTypes()}
                      textField="name"
                      valueField="id"
                      tooltip="Optionally indicates the type of underlying product the security is associated with. Maps to FIX Tag 460."
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup
                    id="minimumPriceIncrement"
                    name="minimumPriceIncrement"
                    type="textarea"
                    label="Min Price Increment"
                    tooltip={toolTips.MINIMUM_PRICE_INCREMENT}
                    isRequired={true}
                    disabled={isEditing}
                    value={values.minimumPriceIncrement}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={1}
                    max={10}
                    step={1}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup
                    id="minimumTradeQuantity"
                    name="minimumTradeQuantity"
                    type="textarea"
                    label="Minimum Trade Quantity"
                    tooltip={toolTips.MINIMUM_TRADE_QUANTITY}
                    disabled={isEditing}
                    value={values.minimumTradeQuantity}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup
                    id="minimumUnaffiliatedFirms"
                    name="minimumUnaffiliatedFirms"
                    type="textarea"
                    label="Minimum Unaffiliated Firms"
                    tooltip={toolTips.MINIMUM_UNAFFILIATED_FIRMS}
                    value={values.minimumUnaffiliatedFirms}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

              </Row>
            </React.Fragment>

            <React.Fragment>
              <hr />
              <Row>
                <Col lg={9} xs={9} md={9}><h6><strong>Cross Order Information:</strong></h6></Col>
                {isEditing && (
                  <Col lg={3} xs={3} md={3}>
                    <strong>
                      <CheckboxGroup
                        checked={this.state.syncCrossOrderInfo}
                        onClick={(e) => {
                          const val = e.currentTarget.checked;
                          this.props.setSyncCrossOrderInfo(val);
                          this.setState({
                            syncCrossOrderInfo: val,
                          });
                        }}
                        label="Sync Child Instruments?"
                      />
                    </strong>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg={5} xs={5} md={5}>
                  <TimeDuration
                    id="crossOrderRestingDuration"
                    name="crossOrderRestingDuration"
                    type="textarea"
                    label="Cross Order Resting Duration"
                    tooltip={toolTips.CROSS_ORDER_RESTING_DURATION}
                    value={values.crossOrderRestingDuration}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={(value) => {
                      setFieldValue("crossOrderRestingDuration", value)
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col >
                  <hr style={{ opacity: "0.3" }} />
                  <h6><strong>Instrument-Level Block Trade Thresholds</strong></h6>
                </Col>
              </Row>

              <Row>
              <Col lg={2} xs={2} md={2}>
                  <div style={{ height: "100%" }}>
                    <div style={{ top: "30%", position: "relative" }}>
                      <CheckboxGroup
                        label="Enabled"
                        checked={values.setBlockTradeThreshold}
                        onClick={(e) => setFieldValue("setBlockTradeThreshold", e.target.checked)}
                        tooltip={toolTips.BLOCK_TRADE_CAN_EDIT}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup
                    id="blockTradeThreshold.minimumQuantity"
                    name="blockTradeThreshold.minimumQuantity"
                    type="textarea"
                    label="Block Trade Minimum Quantity"
                    tooltip={toolTips.BLOCK_TRADE_MINIMUM_QUANTITY}
                    value={!!values.blockTradeThreshold ? values.blockTradeThreshold.minimumQuantity : ""}
                    errors={errors}
                    touched={touched}
                    onChange={(e) => {
                      setFieldValue("blockTradeThreshold.minimumQuantity", e.target.value);
                    }}
                    onBlur={handleBlur}
                    disabled={!values.setBlockTradeThreshold}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup
                    id="blockTradeThreshold.minimumNotional"
                    name="blockTradeThreshold.minimumNotional"
                    type="textarea"
                    label="Block Trade Minimum Notional"
                    tooltip={toolTips.BLOCK_TRADE_MINIMUM_NOTIONAL}
                    value={!!values.blockTradeThreshold ? values.blockTradeThreshold.minimumNotional : ""}
                    errors={errors}
                    touched={touched}
                    onChange={(e) => {
                      setFieldValue("blockTradeThreshold.minimumNotional", e.target.value);
                    }}
                    onBlur={handleBlur}
                    disabled={!values.setBlockTradeThreshold}
                  />
                </Col>
              </Row>
            </React.Fragment>

            <React.Fragment>
              <hr />

              <Row>
                <Col lg={9} xs={9} md={9}>
                  <h6>
                    <strong>Settlement Price Calculation Information:</strong>
                  </h6>
                </Col>

                {isEditing && (
                  <Col lg={3} xs={3} md={3}>
                    <strong>
                      <CheckboxGroup
                        checked={this.state.syncSettlementPriceCalcInfo}
                        onClick={(e) => {
                          const val = e.currentTarget.checked;
                          this.props.setSyncSettlementPriceCalcInfo(val);
                          this.setState({
                            syncSettlementPriceCalcInfo: val,
                          });
                        }}
                        label="Sync Child Instruments?"
                      />
                    </strong>
                  </Col>
                )}
              </Row>

              <Row>
                <Col lg={5} xs={5} md={5}>
                  <TimeDuration
                    id="settlementPeriodStartOffsetFromCloseDuration"
                    name="settlementPeriodStartOffsetFromCloseDuration"
                    label="Settlement Start Period Offset from Market Close"
                    onChange={(value) => {
                      setFieldValue("settlementPeriodStartOffsetFromCloseDuration", value)
                    }}
                    value={values.settlementPeriodStartOffsetFromCloseDuration}
                    tooltip={toolTips.SETTLEMENT_PERIOD_START_OFFSET_FROM_CLOSE_DURATION}
                  />
                </Col>

                <Col lg={5} xs={5} md={5}>
                  <TimeDuration
                    id="settlementPeriodEndOffsetFromCloseDuration"
                    name="settlementPeriodEndOffsetFromCloseDuration"
                    label="Settlement End Period Offset from Market Close"
                    onChange={(value) => {
                      setFieldValue("settlementPeriodEndOffsetFromCloseDuration", value)
                    }}
                    value={values.settlementPeriodEndOffsetFromCloseDuration}
                    tooltip={toolTips.SETTLEMENT_PERIOD_END_OFFSET_FROM_CLOSE_DURATION}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={2} xs={2} md={2}>
                  <DropdownListGroup
                    id="settlementPriceLogic"
                    name="settlementPriceLogic"
                    label="Settlement Price Logic"
                    value={values.settlementPriceLogic}
                    enabled={values.isEditable}
                    errors={errors}
                    onChange={(e) =>
                      setFieldValue("settlementPriceLogic", e.id)
                    }
                    data={InstrumentStaticData.SettlementPriceLogics()}
                    textField="name"
                    valueField="id"
                  />
                </Col>
                <Col lg={4} xs={4} md={4}>
                  <DropdownListGroup
                    id="settlementPriceCalculationEndingMethod"
                    name="settlementPriceCalculationEndingMethod"
                    label="Settlement Price Calculation Ending Method"
                    value={values.settlementPriceCalculationEndingMethod}
                    enabled={values.isEditable}
                    errors={errors}
                    onChange={(e) =>
                      setFieldValue("settlementPriceCalculationEndingMethod", e.id)
                    }
                    data={InstrumentStaticData.SettlementPriceCalculationEndingMethods()}
                    textField="name"
                    valueField="id"
                    tooltip={toolTips.SETTLEMENT_PRICE_CALCULATION_ENDING_METHOD}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={5} xs={5} md={5}>
                  <FieldGroup
                    id="minimumTradeQtyForVwapSettlementCalculation"
                    name="minimumTradeQtyForVwapSettlementCalculation"
                    type="textarea"
                    label="Minimum Trade Quantity for VWAP"
                    tooltip={toolTips.MINIMUM_TRADE_QTY_FOR_VWAP_SETTLEMENT_CALCULATION}
                    value={values.minimumTradeQtyForVwapSettlementCalculation}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={1}
                    max={10}
                    step={1}
                  />
                </Col>
                <Col lg={5} xs={5} md={5}>
                  <TimeDuration
                    id="bufferFromEndOfSettlementPeriodDuration"
                    name="bufferFromEndOfSettlementPeriodDuration"
                    label="Minimum Order Time in Market"
                    onChange={(value) => {
                      setFieldValue("bufferFromEndOfSettlementPeriodDuration", value)
                    }}
                    value={values.bufferFromEndOfSettlementPeriodDuration}
                    tooltip={toolTips.BUFFER_FROM_END_OF_SETTLEMENT_PERIOD_DURATION}
                  />
                </Col>
                <Col lg={2} xs={2} md={2} style={{ marginTop: "35px", padding: "0px" }}>
                  <CheckboxGroup
                    id="settlementPriceCalculationRequiresApproval"
                    checked={values.settlementPriceCalculationRequiresApproval}
                    onChange={(e) => { setFieldValue('settlementPriceCalculationRequiresApproval', e.target.checked) }}
                    label="Requires Approval"
                    labelPlacementAbove={true}
                    tickStyle={{ marginLeft: "5px" }}
                    tooltip={toolTips.SETTLEMENT_PRICE_CALCULATION_REQUIRES_APPROVAL}
                  />
                </Col>
              </Row>
            </React.Fragment>

            {isForex ? (
              <React.Fragment>
                <hr />
                <h6>
                  <strong>Forex Information</strong>
                </h6>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                        id="fxBaseCurrency"
                        name="fxBaseCurrency"
                        as="select"
                        label="Base Currency"
                        isRequired={true}
                        value={values.fxBaseCurrency}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        onChange={handleChange}
                        options={
                          metadata && metadata.currencies
                              ? Object.keys(metadata.currencies)
                              : []
                        }
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                        id="fxQuoteCurrency"
                        name="fxQuoteCurrency"
                        as="select"
                        label="Quote Currency"
                        isRequired={true}
                        value={values.fxQuoteCurrency}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        onChange={handleChange}
                        options={
                          values && !!values.basecurrency
                              ? [values.basecurrency]
                              : []
                        }
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : null}

            {showIRS ? (
              <React.Fragment>
                <hr />
                <h6>
                  <strong>Swap Information</strong>
                </h6>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="effectiveDateBusinessDayConvention"
                      name="effectiveDateBusinessDayConvention"
                      label="Effective Date Business Day Convention"
                      value={values.effectiveDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "effectiveDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="maturityDateBusinessDayConvention"
                      name="maturityDateBusinessDayConvention"
                      label="Maturity Date Business Day Convention"
                      value={values.maturityDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("maturityDateBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="effectiveDateOffset"
                      name="effectiveDateOffset"
                      type="textarea"
                      label="Swap Effective Date Offset"
                      isRequired={true}
                      setFieldValue={setFieldValue}
                      value={values.effectiveDateOffset}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="rollAndPaymentDateHolidayCalendarsList"
                      name="rollAndPaymentDateHolidayCalendarsList"
                      label="Roll and Payment Date Holiday Calendar List"
                      labelWrapperClass="label-wrapper"
                      value={values.rollAndPaymentDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "rollAndPaymentDateHolidayCalendarsList",
                          e
                        )
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Fixed Leg Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentFrequency"
                      name="fixedPaymentFrequency"
                      label="Fixed Payment Frequency"
                      value={values.fixedPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentFrequency", e.id)
                      }
                      data={makeDropdownOptions(
                        InstrumentStaticData.FixedPaymentFrequency,
                        false,
                        false
                      )}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedCompoundingMethod"
                      name="fixedCompoundingMethod"
                      label="Compounding Method"
                      value={values.fixedCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedRollConvention"
                      name="fixedRollConvention"
                      label="Fixed Roll Convention"
                      isRequired={true}
                      value={values.fixedRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("fixedRollConvention", e)}
                      data={makeDropdownOptions(
                        InstrumentStaticData.FixedRollConventionList,
                        false
                      )}
                    ></DropdownListGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedDayCountConvention"
                      name="fixedDayCountConvention"
                      label="Day Count Convention"
                      isRequired={true}
                      value={values.fixedDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedBusinessDayConvention"
                      name="fixedBusinessDayConvention"
                      label="Fixed Business Day Convention"
                      isRequired={true}
                      value={values.fixedBusinessDayConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentDateRelativeTo"
                      name="fixedPaymentDateRelativeTo"
                      label="Fixed Payment Date Relative To"
                      value={values.fixedPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Floating Leg Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatingRateIndex"
                      name="floatingRateIndex"
                      label="Floating Rate Index"
                      value={values.floatingRateIndex}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("floatingRateIndex", e)}
                      data={InstrumentStaticData.FloatingRateIndexList}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="floatHolidayCalendarsList"
                      name="floatHolidayCalendarsList"
                      label="Float Holiday Calendar List"
                      labelWrapperClass="label-wrapper"
                      value={values.floatHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatDayCountConvention"
                      name="floatDayCountConvention"
                      label="Day Count Convention"
                      isRequired={true}
                      errors={errors}
                      value={values.floatDayCountConvention}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatCompoundingMethod"
                      name="floatCompoundingMethod"
                      label="Compounding Method"
                      value={values.floatCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatPaymentFrequency"
                      name="floatPaymentFrequency"
                      label="Payment Frequency"
                      value={values.floatPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatPaymentFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatPaymentFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatRateResetFrequency"
                      name="floatRateResetFrequency"
                      label="Rate Reset Frequency"
                      value={values.floatRateResetFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatRateResetFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatRateResetFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatResetDateBusinessDayConvention"
                      name="floatResetDateBusinessDayConvention"
                      label="Reset Date Business Day Convention"
                      value={values.floatResetDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "floatResetDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatRollConvention"
                      name="floatRollConvention"
                      label="Float Roll Convention"
                      isRequired={true}
                      value={values.floatRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("floatRollConvention", e)}
                      data={makeDropdownOptions(
                        InstrumentStaticData.FixedRollConventionList,
                        false
                      )}
                    ></DropdownListGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FieldGroup
                      id="floatFixingDatesOffset"
                      name="floatFixingDatesOffset"
                      type="textarea"
                      label="Float Fixing Dates Offset"
                      isRequired={true}
                      value={values.floatFixingDatesOffset}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col>
                    <DropdownListGroup
                      id="floatFixingDateBusinessDayConvention"
                      name="floatFixingDateBusinessDayConvention"
                      label="Fixing Date Business Day Convention"
                      value={values.floatFixingDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "floatFixingDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col>
                    <DropdownListGroup
                      id="floatPaymentDateRelativeTo"
                      name="floatPaymentDateRelativeTo"
                      label="Float Payment Date Relative To"
                      value={values.floatPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col>
                    <DropdownListGroup
                      id="floatFixingDatesDayType"
                      name="floatFixingDatesDayType"
                      label="Float Fixing Dates Day Type"
                      value={values.floatFixingDatesDayType}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatFixingDatesDayType", e.id)
                      }
                      data={InstrumentStaticData.FixingDatesDayType}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="floatFixingDateHolidayCalendarsList"
                      name="floatFixingDateHolidayCalendarsList"
                      label="Float Fixing Date Holiday Calendar List"
                      labelWrapperClass="label-wrapper"
                      value={values.floatFixingDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatFixingDateHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="floatingRateIndexTenor"
                      name="floatingRateIndexTenor"
                      type="textarea"
                      label="Floating Rate Index Tenor"
                      value={values.floatingRateIndexTenor}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : null}

            {showFsIRS && (
              <>
                <hr />
                <h6>
                  <strong>Forward Starting Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="tenor"
                      name="tenor"
                      type="textarea"
                      label="Tenor"
                      setFieldValue={setFieldValue}
                      value={values.tenor}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="sideConvention"
                      name="sideConvention"
                      label="Side Convention"
                      value={values.sideConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("sideConvention", e.id)}
                      data={InstrumentStaticData.SideConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="swapEffectiveDate"
                      name="swapEffectiveDate"
                      label="Swap Effective Date"
                      value={values.swapEffectiveDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("swapEffectiveDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="effectiveDateBusinessDayConvention"
                      name="effectiveDateBusinessDayConvention"
                      label="Effective Date Business Day Convention"
                      value={values.effectiveDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "effectiveDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="maturityDate"
                      name="maturityDate"
                      label="Maturity Date"
                      value={values.maturityDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("maturityDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="maturityDateBusinessDayConvention"
                      name="maturityDateBusinessDayConvention"
                      label="Maturity Date Business Day Convention"
                      value={values.maturityDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("maturityDateBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedEffectiveDate"
                      name="unadjustedEffectiveDate"
                      label="Unadjusted Effective Date"
                      value={values.unadjustedEffectiveDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedEffectiveDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="rollAndPaymentDateHolidayCalendarsList"
                      name="rollAndPaymentDateHolidayCalendarsList"
                      label="Roll and Payment Date Holiday Calendar List"
                      labelWrapperClass="label-wrapper"
                      value={values.rollAndPaymentDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "rollAndPaymentDateHolidayCalendarsList",
                          e
                        )
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedMaturityDate"
                      name="unadjustedMaturityDate"
                      label="Unadjusted Maturity Date"
                      value={values.unadjustedMaturityDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedMaturityDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="effectiveDateOffset"
                      name="effectiveDateOffset"
                      type="textarea"
                      label="Swap Effective Date Offset"
                      setFieldValue={setFieldValue}
                      value={values.effectiveDateOffset}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="forwardStartingPeriod"
                      name="forwardStartingPeriod"
                      type="textarea"
                      label="Forward Starting Period"
                      setFieldValue={setFieldValue}
                      value={values.forwardStartingPeriod}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Fixed Leg Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentFrequency"
                      name="fixedPaymentFrequency"
                      label="Fixed Payment Frequency"
                      value={values.fixedPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentFrequency", e.id)
                      }
                      data={InstrumentStaticData.FixedPaymentFrequency}
                      textField="name"
                      valueField="id"
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedCompoundingMethod"
                      name="fixedCompoundingMethod"
                      label="Fixed Compounding Method"
                      value={values.fixedCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedDayCountConvention"
                      name="fixedDayCountConvention"
                      label="Fixed Day Count Convention"
                      value={values.fixedDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedBusinessDayConvention"
                      name="fixedBusinessDayConvention"
                      label="Fixed Business Day Convention"
                      value={values.fixedBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedRollConvention"
                      name="fixedRollConvention"
                      label="Fixed Roll Convention"
                      value={values.fixedRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("fixedRollConvention", e)}
                      data={makeDropdownOptions(
                        InstrumentStaticData.FixedRollConventionList,
                        false
                      )}
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="fixedFirstUnadjustedPaymentDate"
                      name="fixedFirstUnadjustedPaymentDate"
                      label="Fixed First Unadjusted Payment Date"
                      value={values.fixedFirstUnadjustedPaymentDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("fixedFirstUnadjustedPaymentDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentDateRelativeTo"
                      name="fixedPaymentDateRelativeTo"
                      label="Fixed Payment Date Relative To"
                      value={values.fixedPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Float Leg Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatCompoundingMethod"
                      name="floatCompoundingMethod"
                      label="Float Compounding Method"
                      value={values.floatCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatDayCountConvention"
                      name="floatDayCountConvention"
                      label="Float Day Count Convention"
                      value={values.floatDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="floatHolidayCalendarsList"
                      name="floatHolidayCalendarsList"
                      label="Float Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.floatHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatPaymentFrequency"
                      name="floatPaymentFrequency"
                      label="Float Payment Frequency"
                      value={values.floatPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatPaymentFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatPaymentFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="firstFloatResetDate"
                      name="firstFloatResetDate"
                      label="First Float Reset Date"
                      value={values.firstFloatResetDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("firstFloatResetDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="firstFloatFixingDate"
                      name="firstFloatFixingDate"
                      label="First Float Fixing Date"
                      value={values.firstFloatFixingDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("firstFloatFixingDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatingRateIndex"
                      name="floatingRateIndex"
                      label="Floating Rate Index"
                      value={values.floatingRateIndex}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("floatingRateIndex", e)}
                      data={InstrumentStaticData.FloatingRateIndexList}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatResetDateBusinessDayConvention"
                      name="floatResetDateBusinessDayConvention"
                      label="Float Reset Date Business Day Convention"
                      value={values.floatResetDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "floatResetDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatRollConvention"
                      name="floatRollConvention"
                      label="Float Roll Convention"
                      value={values.floatRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("floatRollConvention", e)}
                      data={makeDropdownOptions(
                        InstrumentStaticData.FixedRollConventionList,
                        false
                      )}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="floatFirstUnadjustedPaymentDate"
                      name="floatFirstUnadjustedPaymentDate"
                      label="Float First Unadjusted Payment Date"
                      value={values.floatFirstUnadjustedPaymentDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("floatFirstUnadjustedPaymentDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatPaymentDateRelativeTo"
                      name="floatPaymentDateRelativeTo"
                      label="Float Payment Date Relative To"
                      value={values.floatPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col>
                    <DropdownListGroup
                      id="floatFixingDatesDayType"
                      name="floatFixingDatesDayType"
                      label="Float Fixing Dates Day Type"
                      value={values.floatFixingDatesDayType}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatFixingDatesDayType", e.id)
                      }
                      data={InstrumentStaticData.FixingDatesDayType}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col>
                    <FieldGroup
                      id="floatFixingDatesOffset"
                      name="floatFixingDatesOffset"
                      type="textarea"
                      label="Float Fixing Dates Offset"
                      value={values.floatFixingDatesOffset}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="floatingRateIndexTenor"
                      name="floatingRateIndexTenor"
                      type="textarea"
                      label="Floating Rate Index Tenor"
                      value={values.floatingRateIndexTenor}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatResetDateRelativeTo"
                      name="floatResetDateRelativeTo"
                      label="Float Reset Date Relative To"
                      value={values.floatResetDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatResetDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatFixingDateBusinessDayConvention"
                      name="floatFixingDateBusinessDayConvention"
                      label="Float Fixing Date Business Day Convention"
                      value={values.floatFixingDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "floatFixingDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatBusinessDayConvention"
                      name="floatBusinessDayConvention"
                      label="Float Business Day Convention"
                      value={values.floatBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="floatFixingDateHolidayCalendarsList"
                      name="floatFixingDateHolidayCalendarsList"
                      label="Float Fixing Date Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.floatFixingDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatFixingDateHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatRateResetFrequency"
                      name="floatRateResetFrequency"
                      label="Float Rate Reset Frequency"
                      value={values.floatRateResetFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatRateResetFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatRateResetFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>
              </>
            )}

            {showBasisSwap ? (
              <React.Fragment>
                <hr />
                <h6>
                  <strong>Swap Information</strong>
                </h6>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="tenor"
                      name="tenor"
                      type="textarea"
                      label="Tenor"
                      setFieldValue={setFieldValue}
                      value={values.tenor}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="sideConvention"
                      name="sideConvention"
                      label="Side Convention"
                      value={values.sideConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("sideConvention", e.id)}
                      data={InstrumentStaticData.SideConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="effectiveDateBusinessDayConvention"
                      name="effectiveDateBusinessDayConvention"
                      label="Effective Date Business Day Convention"
                      value={values.effectiveDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "effectiveDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="maturityDateBusinessDayConvention"
                      name="maturityDateBusinessDayConvention"
                      label="Maturity Date Business Day Convention"
                      value={values.maturityDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("maturityDateBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="maturityDate"
                      name="maturityDate"
                      label="Maturity Date"
                      isRequired={true}
                      value={
                        values.maturityDate
                          ? new Date(values.maturityDate)
                          : null
                      }
                      maxDate={
                        !values.expirationDate
                          ? values.terminationDate
                            ? DateTimeParser.addDays(values.terminationDate, -2)
                            : null
                          : DateTimeParser.addDays(values.expirationDate, -1)
                      }
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("maturityDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="effectiveDateOffset"
                      name="effectiveDateOffset"
                      type="textarea"
                      label="Swap Effective Date Offset"
                      isRequired={true}
                      setFieldValue={setFieldValue}
                      value={values.effectiveDateOffset}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="swapEffectiveDate"
                      name="swapEffectiveDate"
                      label="Swap Effective Date"
                      isRequired={true}
                      value={
                        values.swapEffectiveDate
                          ? new Date(values.swapEffectiveDate)
                          : null
                      }
                      maxDate={
                        !values.expirationDate
                          ? values.terminationDate
                            ? DateTimeParser.addDays(values.terminationDate, -2)
                            : null
                          : DateTimeParser.addDays(values.expirationDate, -1)
                      }
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("swapEffectiveDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="rollAndPaymentDateHolidayCalendarsList"
                      name="rollAndPaymentDateHolidayCalendarsList"
                      label="Roll and Payment Date Holiday Calendar List"
                      labelWrapperClass="label-wrapper"
                      value={values.rollAndPaymentDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "rollAndPaymentDateHolidayCalendarsList",
                          e
                        )
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Floating Leg Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatingRateIndex"
                      name="leg1FloatingRateIndex"
                      label="Leg1 Floating Rate Index"
                      value={values.leg1FloatingRateIndex}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg1FloatingRateIndex", e)
                      }
                      data={InstrumentStaticData.FloatingRateIndexList}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatingRateIndex"
                      name="leg2FloatingRateIndex"
                      label="Leg2 Floating Rate Index"
                      value={values.leg2FloatingRateIndex}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg2FloatingRateIndex", e)
                      }
                      data={InstrumentStaticData.FloatingRateIndexList}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatDayCountConvention"
                      name="leg1FloatDayCountConvention"
                      label="Leg1 Day Count Convention"
                      isRequired={true}
                      errors={errors}
                      value={values.leg1FloatDayCountConvention}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("leg1FloatDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatDayCountConvention"
                      name="leg2FloatDayCountConvention"
                      label="Leg2 Day Count Convention"
                      isRequired={true}
                      errors={errors}
                      value={values.leg2FloatDayCountConvention}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("leg2FloatDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatCompoundingMethod"
                      name="leg1FloatCompoundingMethod"
                      label="Leg1 Compounding Method"
                      value={values.leg1FloatCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg1FloatCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatCompoundingMethod"
                      name="leg2FloatCompoundingMethod"
                      label="Leg2 Compounding Method"
                      value={values.leg2FloatCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg2FloatCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatRateResetFrequency"
                      name="leg1FloatRateResetFrequency"
                      label="Leg1 Float Rate Reset Frequency"
                      value={values.leg1FloatRateResetFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg1FloatRateResetFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatRateResetFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatRateResetFrequency"
                      name="leg2FloatRateResetFrequency"
                      label="Leg2 Float Rate Reset Frequency"
                      value={values.leg2FloatRateResetFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg2FloatRateResetFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatRateResetFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatPaymentFrequency"
                      name="leg1FloatPaymentFrequency"
                      label="Leg1 Float Payment Frequency"
                      value={values.leg1FloatPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg1FloatPaymentFrequency", e.id)
                      }
                      data={InstrumentStaticData.FixedPaymentFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatPaymentFrequency"
                      name="leg2FloatPaymentFrequency"
                      label="Leg2 Float Payment Frequency"
                      value={values.leg2FloatPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg2FloatPaymentFrequency", e.id)
                      }
                      data={InstrumentStaticData.FixedPaymentFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatBusinessDayConvention"
                      name="leg1FloatBusinessDayConvention"
                      label="Leg1 Float Business Day Convention"
                      value={values.leg1FloatBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg1FloatBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatBusinessDayConvention"
                      name="leg2FloatBusinessDayConvention"
                      label="Leg2 Float Business Day Convention"
                      value={values.leg2FloatBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg2FloatBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatResetDateBusinessDayConvention"
                      name="leg1FloatResetDateBusinessDayConvention"
                      label="Leg1 Reset Date Business Day Convention"
                      value={values.leg1FloatResetDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "leg1FloatResetDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatResetDateBusinessDayConvention"
                      name="leg2FloatResetDateBusinessDayConvention"
                      label="Leg2 Reset Date Business Day Convention"
                      value={values.leg2FloatResetDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "leg2FloatResetDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatResetDateRelativeTo"
                      name="leg1FloatResetDateRelativeTo"
                      label="Leg1 Float Reset Date Relative To"
                      value={values.leg1FloatResetDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("leg1FloatResetDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatResetDateRelativeTo"
                      name="leg2FloatResetDateRelativeTo"
                      label="Leg2 Float Reset Date Relative To"
                      value={values.leg2FloatResetDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("leg2FloatResetDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatFixingDateBusinessDayConvention"
                      name="leg1FloatFixingDateBusinessDayConvention"
                      label="Leg1 Fixing Date Business Day Convention"
                      value={values.leg1FloatFixingDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "leg1FloatFixingDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatFixingDateBusinessDayConvention"
                      name="leg2FloatFixingDateBusinessDayConvention"
                      label="Leg2 Fixing Date Business Day Convention"
                      value={values.leg2FloatFixingDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "leg2FloatFixingDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="leg1FloatFixingDateHolidayCalendarsList"
                      name="leg1FloatFixingDateHolidayCalendarsList"
                      label="Leg1 Float Fixing Date Holiday Calendar"
                      labelWrapperClass="label-wrapper"
                      value={values.leg1FloatFixingDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "leg1FloatFixingDateHolidayCalendarsList",
                          e
                        )
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="leg2FloatFixingDateHolidayCalendarsList"
                      name="leg2FloatFixingDateHolidayCalendarsList"
                      label="Leg2 Float Fixing Date Holiday Calendar"
                      labelWrapperClass="label-wrapper"
                      value={values.leg2FloatFixingDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "leg2FloatFixingDateHolidayCalendarsList",
                          e
                        )
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="leg1FirstFloatResetDate"
                      name="leg1FirstFloatResetDate"
                      label="Leg1 First Float Reset Date"
                      isRequired={true}
                      value={
                        values.leg1FirstFloatResetDate
                          ? new Date(values.leg1FirstFloatResetDate)
                          : null
                      }
                      maxDate={
                        !values.expirationDate
                          ? values.terminationDate
                            ? DateTimeParser.addDays(values.terminationDate, -2)
                            : null
                          : DateTimeParser.addDays(values.expirationDate, -1)
                      }
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("leg1FirstFloatResetDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="leg2FirstFloatResetDate"
                      name="leg2FirstFloatResetDate"
                      label="Leg2 First Float Reset Date"
                      isRequired={true}
                      value={
                        values.leg2FirstFloatResetDate
                          ? new Date(values.leg2FirstFloatResetDate)
                          : null
                      }
                      maxDate={
                        !values.expirationDate
                          ? values.terminationDate
                            ? DateTimeParser.addDays(values.terminationDate, -2)
                            : null
                          : DateTimeParser.addDays(values.expirationDate, -1)
                      }
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("leg2FirstFloatResetDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="leg1FirstFloatFixingDate"
                      name="leg1FirstFloatFixingDate"
                      label="Leg1 First Float Fixing Date"
                      isRequired={true}
                      value={
                        values.leg1FirstFloatFixingDate
                          ? new Date(values.leg1FirstFloatFixingDate)
                          : null
                      }
                      maxDate={
                        !values.expirationDate
                          ? values.terminationDate
                            ? DateTimeParser.addDays(values.terminationDate, -2)
                            : null
                          : DateTimeParser.addDays(values.expirationDate, -1)
                      }
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("leg1FirstFloatFixingDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="leg2FirstFloatFixingDate"
                      name="leg2FirstFloatFixingDate"
                      label="Leg2 First Float Fixing Date"
                      isRequired={true}
                      value={
                        values.leg2FirstFloatFixingDate
                          ? new Date(values.leg2FirstFloatFixingDate)
                          : null
                      }
                      maxDate={
                        !values.expirationDate
                          ? values.terminationDate
                            ? DateTimeParser.addDays(values.terminationDate, -2)
                            : null
                          : DateTimeParser.addDays(values.expirationDate, -1)
                      }
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("leg2FirstFloatFixingDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatRollConvention"
                      name="leg1FloatRollConvention"
                      label="Leg1 Float Roll Convention"
                      isRequired={true}
                      value={values.leg1FloatRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("leg1FloatRollConvention", e)
                      }
                      data={makeDropdownOptions(
                        InstrumentStaticData.FixedRollConventionList,
                        false
                      )}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatRollConvention"
                      name="leg2FloatRollConvention"
                      label="Leg2 Float Roll Convention"
                      isRequired={true}
                      value={values.leg2FloatRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("leg2FloatRollConvention", e)
                      }
                      data={makeDropdownOptions(
                        InstrumentStaticData.FixedRollConventionList,
                        false
                      )}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="leg1FloatFixingDatesOffset"
                      name="leg1FloatFixingDatesOffset"
                      type="textarea"
                      label="Leg1 Float Fixing Dates Offset"
                      value={values.leg1FloatFixingDatesOffset}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="leg2FloatFixingDatesOffset"
                      name="leg2FloatFixingDatesOffset"
                      type="textarea"
                      label="Leg2 Float Fixing Dates Offset"
                      value={values.leg2FloatFixingDatesOffset}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatPaymentDateRelativeTo"
                      name="leg1FloatPaymentDateRelativeTo"
                      label="Leg1 Float Payment Date Relative To"
                      value={values.leg1FloatPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("leg1FloatPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatPaymentDateRelativeTo"
                      name="leg2FloatPaymentDateRelativeTo"
                      label="Leg2 Float Payment Date Relative To"
                      value={values.leg2FloatPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("leg2FloatPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg1FloatFixingDatesDayType"
                      name="leg1FloatFixingDatesDayType"
                      label="Leg1 Float Fixing Dates Day Type"
                      value={values.leg1FloatFixingDatesDayType}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg1FloatFixingDatesDayType", e.id)
                      }
                      data={InstrumentStaticData.FixingDatesDayType}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="leg2FloatFixingDatesDayType"
                      name="leg2FloatFixingDatesDayType"
                      label="Leg2 Float Fixing Dates Day Type"
                      value={values.leg2FloatFixingDatesDayType}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("leg2FloatFixingDatesDayType", e.id)
                      }
                      data={InstrumentStaticData.FixingDatesDayType}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="leg1FloatingRateIndexTenor"
                      name="leg1FloatingRateIndexTenor"
                      type="textarea"
                      label="Leg1 Floating Rate Index Tenor"
                      value={values.leg1FloatingRateIndexTenor}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="leg2FloatingRateIndexTenor"
                      name="leg2FloatingRateIndexTenor"
                      type="textarea"
                      label="Leg2 Floating Rate Index Tenor"
                      value={values.leg2FloatingRateIndexTenor}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="leg1FloatFirstUnadjustedPaymentDate"
                      name="leg1FloatFirstUnadjustedPaymentDate"
                      label="Leg1 Float First Unadjusted Payment Date"
                      value={
                        values.leg1FloatFirstUnadjustedPaymentDate
                          ? new Date(values.leg1FloatFirstUnadjustedPaymentDate)
                          : null
                      }
                      maxDate={
                        !values.expirationDate
                          ? values.terminationDate
                            ? DateTimeParser.addDays(values.terminationDate, -2)
                            : null
                          : DateTimeParser.addDays(values.expirationDate, -1)
                      }
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("leg1FloatFirstUnadjustedPaymentDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="leg2FloatFirstUnadjustedPaymentDate"
                      name="leg2FloatFirstUnadjustedPaymentDate"
                      label="Leg2 Float First Unadjusted Payment Date"
                      value={
                        values.leg2FloatFirstUnadjustedPaymentDate
                          ? new Date(values.leg2FloatFirstUnadjustedPaymentDate)
                          : null
                      }
                      maxDate={
                        !values.expirationDate
                          ? values.terminationDate
                            ? DateTimeParser.addDays(values.terminationDate, -2)
                            : null
                          : DateTimeParser.addDays(values.expirationDate, -1)
                      }
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("leg2FloatFirstUnadjustedPaymentDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="floatFirstUnadjustedEffectiveDate"
                      name="floatFirstUnadjustedEffectiveDate"
                      label="Float First Unadjusted Effective Date"
                      value={
                        values.floatFirstUnadjustedEffectiveDate
                          ? new Date(values.floatFirstUnadjustedEffectiveDate)
                          : null
                      }
                      maxDate={
                        !values.expirationDate
                          ? values.terminationDate
                            ? DateTimeParser.addDays(values.terminationDate, -2)
                            : null
                          : DateTimeParser.addDays(values.expirationDate, -1)
                      }
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("floatFirstUnadjustedEffectiveDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedMaturityDate"
                      name="unadjustedMaturityDate"
                      label="Unadjusted Maturity Date"
                      value={
                        values.unadjustedMaturityDate
                          ? new Date(values.unadjustedMaturityDate)
                          : null
                      }
                      maxDate={
                        !values.expirationDate
                          ? values.terminationDate
                            ? DateTimeParser.addDays(values.terminationDate, -2)
                            : null
                          : DateTimeParser.addDays(values.expirationDate, -1)
                      }
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedMaturityDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : null}

            {showZCIS ? (
              <React.Fragment>
                <hr />
                <h6>
                  <strong>Swap Information</strong>
                </h6>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="tenor"
                      name="tenor"
                      type="textarea"
                      label="Tenor"
                      setFieldValue={setFieldValue}
                      value={values.tenor}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="sideConvention"
                      name="sideConvention"
                      label="Side Convention"
                      value={values.sideConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("sideConvention", e.id)}
                      data={InstrumentStaticData.SideConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="swapEffectiveDate"
                      name="swapEffectiveDate"
                      label="Swap Effective Date"
                      value={values.swapEffectiveDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("swapEffectiveDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="forwardStartingPeriodTimePeriod"
                      name="forwardStartingPeriodTimePeriod"
                      label="Forward Starting Period Time Period"
                      value={values.forwardStartingPeriodTimePeriod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("forwardStartingPeriodTimePeriod", e.id)
                      }
                      data={InstrumentStaticData.TimePeriodOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="tenorTimePeriod"
                      name="tenorTimePeriod"
                      label="Tenor Time Period"
                      value={values.tenorTimePeriod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("tenorTimePeriod", e.id)}
                      data={InstrumentStaticData.TimePeriodOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="indexSource"
                      name="indexSource"
                      label="Index Source"
                      value={values.indexSource}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>{
                        setFieldValue("indexSource", e)
                      }}
                      data={InstrumentStaticData.IndexSourceList}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="forwardStartingPeriod"
                      name="forwardStartingPeriod"
                      type="textarea"
                      label="Forward Starting Period"
                      setFieldValue={setFieldValue}
                      value={values.forwardStartingPeriod}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="rollAndPaymentDateHolidayCalendarsList"
                      name="rollAndPaymentDateHolidayCalendarsList"
                      label="Roll and Payment Date Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.rollAndPaymentDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "rollAndPaymentDateHolidayCalendarsList",
                          e
                        )
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="inflationLag"
                      name="inflationLag"
                      type="textarea"
                      label="Inflation Lag"
                      setFieldValue={setFieldValue}
                      value={values.inflationLag}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedEffectiveDate"
                      name="unadjustedEffectiveDate"
                      label="Unadjusted Effective Date"
                      value={values.unadjustedEffectiveDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedEffectiveDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="effectiveDateOffset"
                      name="effectiveDateOffset"
                      type="textarea"
                      label="Effective Date Offset"
                      isRequired={true}
                      setFieldValue={setFieldValue}
                      value={values.effectiveDateOffset}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="effectiveDateBusinessDayConvention"
                      name="effectiveDateBusinessDayConvention"
                      label="Effective Date Business Day Convention"
                      value={values.effectiveDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "effectiveDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="interpolationMethod"
                      name="interpolationMethod"
                      type="textarea"
                      label="Interpolation Method"
                      setFieldValue={setFieldValue}
                      value={values.interpolationMethod}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="maturityDate"
                      name="maturityDate"
                      label="Maturity Date"
                      value={values.maturityDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("maturityDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedMaturityDate"
                      name="unadjustedMaturityDate"
                      label="Unadjusted Maturity Date"
                      value={values.unadjustedMaturityDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedMaturityDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="maturityDateBusinessDayConvention"
                      name="maturityDateBusinessDayConvention"
                      label="Maturity Date Business Day Convention"
                      value={values.maturityDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("maturityDateBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="inflationInstrumentName"
                      name="inflationInstrumentName"
                      type="textarea"
                      label="Inflation Instrument Name"
                      setFieldValue={setFieldValue}
                      value={values.inflationInstrumentName}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3} style={{alignSelf: "center"}}>
                    <CheckboxGroup
                      checked={values.fallbackBondApplicable}
                      onChange={(e) => {
                        setFieldValue("fallbackBondApplicable", e.target.checked)
                      }}
                      label="Fallback Bond Applicable"
                    />
                  </Col>
                </Row>
                <hr />
                <h6>
                  <strong>Fixed Leg Information</strong>
                </h6>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentFrequency"
                      name="fixedPaymentFrequency"
                      label="Fixed Payment Frequency"
                      value={values.fixedPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentFrequency", e.id)
                      }
                      data={InstrumentStaticData.FixedPaymentFrequency}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedRollConvention"
                      name="fixedRollConvention"
                      label="Fixed Roll Convention"
                      isRequired={true}
                      value={values.fixedRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("fixedRollConvention", e)}
                      data={InstrumentStaticData.FixedRollConventionList}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentDateRelativeTo"
                      name="fixedPaymentDateRelativeTo"
                      label="Fixed Payment Date Relative To"
                      value={values.fixedPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="fixedFirstUnadjustedPaymentDate"
                      name="fixedFirstUnadjustedPaymentDate"
                      label="Fixed First Unadjusted Payment Date"
                      value={values.fixedFirstUnadjustedPaymentDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("fixedFirstUnadjustedPaymentDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedDayCountConvention"
                      name="fixedDayCountConvention"
                      label="Fixed Day Count Convention"
                      value={values.fixedDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedCompoundingMethod"
                      name="fixedCompoundingMethod"
                      label="Fixed Compounding Method"
                      value={values.fixedCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentDayConvention"
                      name="fixedPaymentDayConvention"
                      label="Fixed Payment Day Convention"
                      value={values.fixedPaymentDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedCalculationPeriodDayConvention"
                      name="fixedCalculationPeriodDayConvention"
                      label="Fixed Calculation Period Day Convention"
                      value={values.fixedCalculationPeriodDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedCalculationPeriodDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                      />
                  </Col>
                </Row>
                <hr />
                <h6>
                  <strong>Float Leg Information</strong>
                </h6>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="floatHolidayCalendarsList"
                      name="floatHolidayCalendarsList"
                      label="Float Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.floatHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatPaymentFrequency"
                      name="floatPaymentFrequency"
                      label="Float Payment Frequency"
                      value={values.floatPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatPaymentFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatPaymentFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatingRateIndex"
                      name="floatingRateIndex"
                      label="Floating Rate Index"
                      value={values.floatingRateIndex}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("floatingRateIndex", e)}
                      data={InstrumentStaticData.FloatingRateIndexList}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="floatFirstUnadjustedPaymentDate"
                      name="floatFirstUnadjustedPaymentDate"
                      label="Float First Unadjusted Payment Date"
                      value={values.floatFirstUnadjustedPaymentDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("floatFirstUnadjustedPaymentDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatRollConvention"
                      name="floatRollConvention"
                      label="Float Roll Convention"
                      isRequired={true}
                      value={values.floatRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("floatRollConvention", e)}
                      data={makeDropdownOptions(
                        InstrumentStaticData.FixedRollConventionList,
                        false
                      )}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatPaymentDateRelativeTo"
                      name="floatPaymentDateRelativeTo"
                      label="Float Payment Date Relative To"
                      value={values.floatPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                      ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatPaymentDayConvention"
                      name="floatPaymentDayConvention"
                      label="Float Payment Day Convention"
                      value={values.floatPaymentDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatPaymentDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatCalculationPeriodDayConvention"
                      name="floatCalculationPeriodDayConvention"
                      label="Float Calculation Period Day Convention"
                      value={values.floatCalculationPeriodDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatCalculationPeriodDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatDayCountConvention"
                      name="floatDayCountConvention"
                      label="Float Day Count Convention"
                      value={values.floatDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatCompoundingMethod"
                      name="floatCompoundingMethod"
                      label="Float Compounding Method"
                      value={values.floatCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>
              </React.Fragment>
            ) : null}

            {showFRAS && (
              <>
                <hr />
                <h6>
                  <strong>Forward Rate Agreement Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="tenor"
                      name="tenor"
                      type="textarea"
                      label="Tenor"
                      setFieldValue={setFieldValue}
                      value={values.tenor}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="sideConvention"
                      name="sideConvention"
                      label="Side Convention"
                      value={values.sideConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("sideConvention", e.id)}
                      data={InstrumentStaticData.SideConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="forwardStartingPeriod"
                      name="forwardStartingPeriod"
                      type="textarea"
                      label="Forward Starting Period"
                      setFieldValue={setFieldValue}
                      value={values.forwardStartingPeriod}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="swapEffectiveDate"
                      name="swapEffectiveDate"
                      label="Swap Effective Date"
                      value={values.swapEffectiveDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("swapEffectiveDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="effectiveDateBusinessDayConvention"
                      name="effectiveDateBusinessDayConvention"
                      label="Effective Date Business Day Convention"
                      value={values.effectiveDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "effectiveDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="maturityDateBusinessDayConvention"
                      name="maturityDateBusinessDayConvention"
                      label="Maturity Date Business Day Convention"
                      value={values.maturityDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("maturityDateBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="calculationPeriodNumberOfDays"
                      name="calculationPeriodNumberOfDays"
                      type="textarea"
                      label="Calculation Period Number of Days"
                      setFieldValue={setFieldValue}
                      value={values.calculationPeriodNumberOfDays}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="rollAndPaymentDateHolidayCalendarsList"
                      name="rollAndPaymentDateHolidayCalendarsList"
                      label="Roll and Payment Date Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.rollAndPaymentDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "rollAndPaymentDateHolidayCalendarsList",
                          e
                        )
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedEffectiveDate"
                      name="unadjustedEffectiveDate"
                      label="Unadjusted Effective Date"
                      value={values.unadjustedEffectiveDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedEffectiveDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedMaturityDate"
                      name="unadjustedMaturityDate"
                      label="Unadjusted Maturity Date"
                      value={values.unadjustedMaturityDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedMaturityDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="maturityDate"
                      name="maturityDate"
                      label="Maturity Date"
                      value={values.maturityDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("maturityDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Fixed Leg Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedDayCountConvention"
                      name="fixedDayCountConvention"
                      label="Fixed Day Count Convention"
                      value={values.fixedDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedBusinessDayConvention"
                      name="fixedBusinessDayConvention"
                      label="Fixed Business Day Convention"
                      value={values.fixedBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="fixedFixingDateHolidayCalendarsList"
                      name="fixedFixingDateHolidayCalendarsList"
                      label="Fixed Fixing Date Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.fixedFixingDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedFixingDateHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Float Leg Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatDayCountConvention"
                      name="floatDayCountConvention"
                      label="Float Day Count Convention"
                      value={values.floatDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatBusinessDayConvention"
                      name="floatBusinessDayConvention"
                      label="Float Business Day Convention"
                      value={values.floatBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="floatFixingDateHolidayCalendarsList"
                      name="floatFixingDateHolidayCalendarsList"
                      label="Float Fixing Date Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.floatFixingDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatFixingDateHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatFixingDateBusinessDayConvention"
                      name="floatFixingDateBusinessDayConvention"
                      label="Float Fixing Date Business Day Convention"
                      value={values.floatFixingDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "floatFixingDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatingRateIndex"
                      name="floatingRateIndex"
                      label="Floating Rate Index"
                      value={values.floatingRateIndex}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("floatingRateIndex", e)}
                      data={InstrumentStaticData.FloatingRateIndexList}
                    ></DropdownListGroup>
                  </Col>
                  <Col>
                    <DropdownListGroup
                      id="floatFixingDatesDayType"
                      name="floatFixingDatesDayType"
                      label="Float Fixing Dates Day Type"
                      value={values.floatFixingDatesDayType}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatFixingDatesDayType", e.id)
                      }
                      data={InstrumentStaticData.FixingDatesDayType}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>

                  <Col>
                    <FieldGroup
                      id="floatFixingDatesOffset"
                      name="floatFixingDatesOffset"
                      type="textarea"
                      label="Float Fixing Dates Offset"
                      value={values.floatFixingDatesOffset}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="floatFixingDate"
                      name="floatFixingDate"
                      label="Float Fixing Date"
                      value={values.floatFixingDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("floatFixingDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="floatingRateIndexTenor"
                      name="floatingRateIndexTenor"
                      type="textarea"
                      label="Floating Rate Index Tenor"
                      value={values.floatingRateIndexTenor}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
              </>
            )}

            {showEvent && <>
              <hr />
              <Row>
                <Col lg={3} xs={3} md={3}>
                  <h6><strong>Event Information</strong></h6>
                </Col>
              </Row>
              <Row>
                <Col lg={3} xs={3} md={3}>
                  <h6>Attributes</h6>
                </Col>
              </Row>

              <Row>
                <Col lg={3} xs={3} md={3}>
                  <DropdownListGroup
                    id="evtAttPayoutType"
                    name="evtAttPayoutType"
                    label="Payout Type"
                    isRequired={true}
                    value={values.evtAttPayoutType || payoutTypes[0].id}
                    errors={errors}
                    onChange={(e) => {
                      setFieldValue("evtAttPayoutType", e.id);
                      setFieldValue("isContinuous", setIsContinuous(e.id));
                    }}
                    data={payoutTypes}
                    textField="name"
                    valueField="id"
                    tooltip={toolTips.EVENT_PAYOUT_TYPE}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="evtAttPositionAccountability" name="evtAttPositionAccountability" type="textarea" label="Position Accountability Value"
                    value={values.evtAttPositionAccountability}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    tooltip={toolTips.EVENT_POSITION_ACCOUNTABILITY_VALUE}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="evtAttPositionLimit" name="evtAttPositionLimit" type="textarea" label="Position Limit"
                    value={values.evtAttPositionLimit}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    tooltip={toolTips.EVENT_POSITION_LIMIT}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="evtAttSourceAgency" name="evtAttSourceAgency" type="textarea" label="Source Agency"
                    value={values.evtAttSourceAgency}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="evtAttSourceAgencyUrl" name="evtAttSourceAgencyUrl" type="textarea" label="Source Agency URL"
                    value={values.evtAttSourceAgencyUrl}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="evtAttPayoutValue" name="evtAttPayoutValue" type="textarea" label="Payout Value"
                    isRequired={!values.isContinuous}
                    disabled={values.isContinuous}
                    value={!values.isContinuous ? values.evtAttPayoutValue : null}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="evtAttPayoutLowerBound" name="evtAttPayoutLowerBound" type="textarea" label="Payout Lower Bound" isRequired={values.isContinuous}
                    disabled={!values.isContinuous}
                    value={values.isContinuous ? values.evtAttPayoutLowerBound : null}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="evtAttPayoutUpperBound" name="evtAttPayoutUpperBound" type="textarea" label="Payout Upper Bound" isRequired={values.isContinuous}
                    disabled={!values.isContinuous}
                    value={values.isContinuous ? values.evtAttPayoutUpperBound : null}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <DropdownListGroup
                    id="evtAttCalculationMethod"
                    name="evtAttCalculationMethod"
                    label="Outcome Value Calculation Method"
                    value={values.evtAttCalculationMethod || null}
                    errors={errors}
                    onChange={(e) => setFieldValue("evtAttCalculationMethod", e.id)}
                    data={calculationMethods}
                    textField="name"
                    valueField="id"
                    tooltip={toolTips.EVENT_CALCULATION_METHOD}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <DropdownListGroup
                    id="evtAttStrikeUnit"
                    name="evtAttStrikeUnit"
                    label="Strike Unit"
                    enabled={!values.isContinuous}
                    isRequired={!values.isContinuous}
                    value={!values.isContinuous ? values.evtAttStrikeUnit : null}
                    errors={errors}
                    onChange={(e) => setFieldValue("evtAttStrikeUnit", e)}
                    data={EVENT_ATTR_STRIKE_UNIT}
                    tooltip={toolTips.EVENT_STRIKE_UNIT}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="evtAttStrikeEvaluationLowerLimit" name="evtAttStrikeEvaluationLowerLimit" type="textarea" label="Strike Evaluation Lower Limit"
                    disabled={values.isContinuous}
                    value={values.evtAttStrikeEvaluationLowerLimit}
                    isRequired={false}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    tooltip={toolTips.EVENT_STRIKE_EVALUATION_LOWER_LIMIT}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="evtAttStrikeEvaluationUpperLimit" name="evtAttStrikeEvaluationUpperLimit" type="textarea" label="Strike Evaluation Upper Limit"
                    disabled={values.isContinuous}
                    value={values.evtAttStrikeEvaluationUpperLimit}
                    isRequired={false}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    tooltip={toolTips.EVENT_STRIKE_EVALUATION_UPPER_LIMIT}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup
                      id="evtAttFrequencyType"
                      name="evtAttFrequencyType"
                      type="textarea"
                      label="Frequency Type"
                      isRequired={false}
                      setFieldValue={setFieldValue}
                      value={values.evtAttFrequencyType}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                  />
                </Col>
                <Col lg={9} xs={9} md={9}>
                  <FormLabel>Question Template</FormLabel>
                  <Tooltip
                    text = {toolTips.EVENT_QUESTION_TEMPLATE}
                    place={'top'}
                  />
                  <AutoComplete
                    id="evtAttQuestion"
                    name="evtAttQuestion"
                    placeholder="Enter Question Template"
                    noItemsMessage="No results found"
                    dataSource={getEvtAttQuestions()}
                    defaultValue={values.evtAttQuestion}
                    onEnter={(value) => setFieldValue("evtAttQuestion", value)}
                    onSelect={(value) => setFieldValue("evtAttQuestion", value)}
                    onChange={(e) => setFieldValue("evtAttQuestion", e.target.value)}
                  />
                </Col>
              </Row>

              <Row>
                <Col >
                  <FieldGroup id="evtAttUnderlyingEventSpecification" name="evtAttUnderlyingEventSpecification" type="textarea" label="Underlying Event Specification"
                    value={values.evtAttUnderlyingEventSpecification}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    style={{ "height": "90px" }}
                    help={"The legal language describing how the market outcome and expiration is determined."}
                  />
                </Col>
              </Row>

              {!!values.subTypesList && values.subTypesList.length > 0 && <Row>
                <Col lg={3} xs={3} md={3}>
                  <h6>Sub Types</h6>
                </Col>
              </Row>}

              <SubTypes
                values={values}
                setFieldValue={setFieldValue}
                touched={touched} 
                handleBlur={handleBlur}
                onChange={handleChange}
                errors={errors} 
                mode={FormModes.creation} />
            </>}

            {showOIS && (
              <>
                <hr />
                <h6>
                  <strong>Overnight Index Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="tenor"
                      name="tenor"
                      type="textarea"
                      label="Tenor"
                      setFieldValue={setFieldValue}
                      value={values.tenor}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="tenorTimePeriod"
                      name="tenorTimePeriod"
                      label="Tenor Time Period"
                      value={values.tenorTimePeriod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("tenorTimePeriod", e.id)}
                      data={InstrumentStaticData.TimePeriodOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="sideConvention"
                      name="sideConvention"
                      label="Side Convention"
                      value={values.sideConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("sideConvention", e.id)}
                      data={InstrumentStaticData.SideConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="swapEffectiveDate"
                      name="swapEffectiveDate"
                      label="Swap Effective Date"
                      value={values.swapEffectiveDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("swapEffectiveDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="effectiveDateBusinessDayConvention"
                      name="effectiveDateBusinessDayConvention"
                      label="Effective Date Business Day Convention"
                      value={values.effectiveDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "effectiveDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="maturityDateBusinessDayConvention"
                      name="maturityDateBusinessDayConvention"
                      label="Maturity Date Business Day Convention"
                      value={values.maturityDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("maturityDateBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedEffectiveDate"
                      name="unadjustedEffectiveDate"
                      label="Unadjusted Effective Date"
                      value={values.unadjustedEffectiveDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedEffectiveDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedMaturityDate"
                      name="unadjustedMaturityDate"
                      label="Unadjusted Maturity Date"
                      value={values.unadjustedMaturityDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedMaturityDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="maturityDate"
                      name="maturityDate"
                      label="Maturity Date"
                      value={values.maturityDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("maturityDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="effectiveDateOffset"
                      name="effectiveDateOffset"
                      type="textarea"
                      label="Swap Effective Date Offset"
                      isRequired={true}
                      setFieldValue={setFieldValue}
                      value={values.effectiveDateOffset}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="forwardStartingPeriod"
                      name="forwardStartingPeriod"
                      type="textarea"
                      label="Forward Starting Period"
                      setFieldValue={setFieldValue}
                      value={values.forwardStartingPeriod}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="forwardStartingPeriodTimePeriod"
                      name="forwardStartingPeriodTimePeriod"
                      label="Forward Starting Period Time Period"
                      value={values.forwardStartingPeriodTimePeriod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("forwardStartingPeriodTimePeriod", e.id)}
                      data={InstrumentStaticData.TimePeriodOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="paymentDateDayType"
                      name="paymentDateDayType"
                      label="Payment Date Day Type"
                      value={values.paymentDateDayType}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("paymentDateDayType", e.id)
                      }
                      data={InstrumentStaticData.FixingDatesDayType}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Fixed Leg Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentFrequency"
                      name="fixedPaymentFrequency"
                      label="Fixed Payment Frequency"
                      value={values.fixedPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentFrequency", e.id)
                      }
                      data={makeDropdownOptions(
                        InstrumentStaticData.FixedPaymentFrequency,
                        false,
                        false
                      )}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedCompoundingMethod"
                      name="fixedCompoundingMethod"
                      label="Fixed Compounding Method"
                      value={values.fixedCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedDayCountConvention"
                      name="fixedDayCountConvention"
                      label="Fixed Day Count Convention"
                      value={values.fixedDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedBusinessDayConvention"
                      name="fixedBusinessDayConvention"
                      label="Fixed Business Day Convention"
                      value={values.fixedBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="fixedHolidayCalendarsList"
                      name="fixedHolidayCalendarsList"
                      label="Fixed Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.fixedHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedRollConvention"
                      name="fixedRollConvention"
                      label="Fixed Roll Convention"
                      isRequired={true}
                      value={values.fixedRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("fixedRollConvention", e)}
                      data={InstrumentStaticData.FixedRollConventionList}
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="fixedPaymentDateOffset"
                      name="fixedPaymentDateOffset"
                      type="textarea"
                      label="Fixed Payment Date Offset"
                      value={values.fixedPaymentDateOffset}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="fixedFirstUnadjustedPaymentDate"
                      name="fixedFirstUnadjustedPaymentDate"
                      label="Fixed First Unadjusted Payment Date"
                      value={values.fixedFirstUnadjustedPaymentDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("fixedFirstUnadjustedPaymentDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentDateRelativeTo"
                      name="fixedPaymentDateRelativeTo"
                      label="Fixed Payment Date Relative To"
                      value={values.fixedPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Float Leg Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatCompoundingMethod"
                      name="floatCompoundingMethod"
                      label="Float Compounding Method"
                      value={values.floatCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatDayCountConvention"
                      name="floatDayCountConvention"
                      label="Float Day Count Convention"
                      value={values.floatDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatBusinessDayConvention"
                      name="floatBusinessDayConvention"
                      label="Float Business Day Convention"
                      value={values.floatBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="floatHolidayCalendarsList"
                      name="floatHolidayCalendarsList"
                      label="Float Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.floatHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatPaymentFrequency"
                      name="floatPaymentFrequency"
                      label="Float Payment Frequency"
                      value={values.floatPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatPaymentFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatPaymentFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatRateResetFrequency"
                      name="floatRateResetFrequency"
                      label="Float Rate Reset Frequency"
                      value={values.floatRateResetFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatRateResetFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatRateResetFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatResetDateBusinessDayConvention"
                      name="floatResetDateBusinessDayConvention"
                      label="Reset Date Business Day Convention"
                      value={values.floatResetDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "floatResetDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="firstFloatResetDate"
                      name="firstFloatResetDate"
                      label="First Float Reset Date"
                      value={values.firstFloatResetDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("firstFloatResetDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="firstFloatFixingDate"
                      name="firstFloatFixingDate"
                      label="First Float Fixing Date"
                      value={values.firstFloatFixingDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("firstFloatFixingDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col>
                    <DropdownListGroup
                      id="floatFixingDatesDayType"
                      name="floatFixingDatesDayType"
                      label="Float Fixing Dates Day Type"
                      value={values.floatFixingDatesDayType}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatFixingDatesDayType", e.id)
                      }
                      data={InstrumentStaticData.FixingDatesDayType}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatingRateIndex"
                      name="floatingRateIndex"
                      label="Floating Rate Index"
                      value={values.floatingRateIndex}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("floatingRateIndex", e)}
                      data={InstrumentStaticData.FloatingRateIndexList}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatRollConvention"
                      name="floatRollConvention"
                      label="Float Roll Convention"
                      isRequired={true}
                      value={values.floatRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("floatRollConvention", e)}
                      data={makeDropdownOptions(
                        InstrumentStaticData.FixedRollConventionList,
                        false
                      )}
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="floatFirstUnadjustedPaymentDate"
                      name="floatFirstUnadjustedPaymentDate"
                      label="Float First Unadjusted Payment Date"
                      value={values.floatFirstUnadjustedPaymentDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("floatFirstUnadjustedPaymentDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col>
                    <DropdownListGroup
                      id="floatPaymentDateRelativeTo"
                      name="floatPaymentDateRelativeTo"
                      label="Float Payment Date Relative To"
                      value={values.floatPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatResetDateRelativeTo"
                      name="floatResetDateRelativeTo"
                      label="Float Reset Date Relative To"
                      value={values.floatResetDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatResetDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>

                  <Col>
                    <FieldGroup
                      id="floatFixingDatesOffset"
                      name="floatFixingDatesOffset"
                      type="textarea"
                      label="Float Fixing Dates Offset"
                      value={values.floatFixingDatesOffset}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatFixingDateBusinessDayConvention"
                      name="floatFixingDateBusinessDayConvention"
                      label="Fixing Date Business Day Convention"
                      value={values.floatFixingDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "floatFixingDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="floatFixingDateHolidayCalendarsList"
                      name="floatFixingDateHolidayCalendarsList"
                      label="Float Fixing Date Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.floatFixingDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatFixingDateHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="floatPaymentDateOffset"
                      name="floatPaymentDateOffset"
                      type="textarea"
                      label="Float Payment Date Offset"
                      value={values.floatPaymentDateOffset}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="floatingRateIndexTenor"
                      name="floatingRateIndexTenor"
                      type="textarea"
                      label="Floating Rate Index Tenor"
                      value={values.floatingRateIndexTenor}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
              </>
            )}

            {showNdf && (
              <>
                <hr />
                <h6>
                  <strong>Non Deliverable Forward Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="tenor"
                      name="tenor"
                      type="textarea"
                      label="Tenor"
                      setFieldValue={setFieldValue}
                      value={values.tenor}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="sideConvention"
                      name="sideConvention"
                      label="Side Convention"
                      value={values.sideConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("sideConvention", e.id)}
                      data={InstrumentStaticData.SideConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="forwardStartingPeriod"
                      name="forwardStartingPeriod"
                      type="textarea"
                      label="Forward Starting Period"
                      setFieldValue={setFieldValue}
                      value={values.forwardStartingPeriod}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="forwardStartingPeriodTimePeriod"
                      name="forwardStartingPeriodTimePeriod"
                      label="Forward Starting Period Time Period"
                      value={values.forwardStartingPeriodTimePeriod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("forwardStartingPeriodTimePeriod", e.id)}
                      data={InstrumentStaticData.TimePeriodOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="currency1"
                      name="currency1"
                      as="select"
                      label="Currency 1"
                      value={values.currency1}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      onChange={handleChange}
                      options={
                        metadata && metadata.currencies
                          ? Object.keys(metadata.currencies)
                          : []
                      }
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="currency2"
                      name="currency2"
                      as="select"
                      label="Currency 2"
                      value={values.currency2}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      onChange={handleChange}
                      options={
                        metadata && metadata.currencies
                          ? Object.keys(metadata.currencies)
                          : []
                      }
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="exchangedCurrency1"
                      name="exchangedCurrency1"
                      as="select"
                      label="Exchanged Currency 1"
                      value={values.exchangedCurrency1}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      onChange={handleChange}
                      options={
                        metadata && metadata.currencies
                          ? Object.keys(metadata.currencies)
                          : []
                      }
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="exchangedCurrency2"
                      name="exchangedCurrency2"
                      as="select"
                      label="Exchanged Currency 2"
                      value={values.exchangedCurrency2}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      onChange={handleChange}
                      options={
                        metadata && metadata.currencies
                          ? Object.keys(metadata.currencies)
                          : []
                      }
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="exchangeRateCurrency1"
                      name="exchangeRateCurrency1"
                      as="select"
                      label="Exchange Rate Currency 1"
                      value={values.exchangeRateCurrency1}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      onChange={handleChange}
                      options={
                        metadata && metadata.currencies
                          ? Object.keys(metadata.currencies)
                          : []
                      }
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="exchangeRateCurrency2"
                      name="exchangeRateCurrency2"
                      as="select"
                      label="Exchange Rate Currency 2"
                      value={values.exchangeRateCurrency2}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      onChange={handleChange}
                      options={
                        metadata && metadata.currencies
                          ? Object.keys(metadata.currencies)
                          : []
                      }
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="valuationDate"
                      name="valuationDate"
                      label="Valuation Date"
                      value={values.valuationDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("valuationDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="fixingDate"
                      name="fixingDate"
                      label="Fixing Date"
                      value={values.fixingDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("fixingDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="exchangeRateQuoteBasis"
                      name="exchangeRateQuoteBasis"
                      label="Exchange Rate Quote Basis"
                      value={values.exchangeRateQuoteBasis}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("exchangeRateQuoteBasis", e.id)}
                      data={InstrumentStaticData.QuoteBasisOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixingQuoteBasis"
                      name="fixingQuoteBasis"
                      label="Fixing Quote Basis"
                      value={values.fixingQuoteBasis}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("fixingQuoteBasis", e.id)}
                      data={InstrumentStaticData.QuoteBasisOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="settlementCurrency"
                      name="settlementCurrency"
                      as="select"
                      label="Settlement Currency"
                      value={values.settlementCurrency}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      onChange={handleChange}
                      options={["USD"]}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="tenorTimePeriod"
                      name="tenorTimePeriod"
                      label="Tenor Time Period"
                      value={values.tenorTimePeriod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("tenorTimePeriod", e.id)}
                      data={InstrumentStaticData.TimePeriodOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="fixingCurrency1"
                      name="fixingCurrency1"
                      as="select"
                      label="Fixing Currency 1"
                      value={values.fixingCurrency1}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      onChange={handleChange}
                      options={
                        metadata && metadata.currencies
                          ? Object.keys(metadata.currencies)
                          : []
                      }
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="fixingCurrency2"
                      name="fixingCurrency2"
                      as="select"
                      label="Fixing Currency 2"
                      value={values.fixingCurrency2}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      onChange={handleChange}
                      options={
                        metadata && metadata.currencies
                          ? Object.keys(metadata.currencies)
                          : []
                      }
                    />
                  </Col>
                </Row>
              </>
            )}

            {showSps && (
              <>
                <hr />
                <h6>
                  <strong>Single Period Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="tenor"
                      name="tenor"
                      type="textarea"
                      label="Tenor"
                      setFieldValue={setFieldValue}
                      value={values.tenor}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isRequired={true}
                    />
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="sideConvention"
                      name="sideConvention"
                      label="Side Convention"
                      value={values.sideConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("sideConvention", e.id)}
                      data={InstrumentStaticData.SideConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="tenorTimePeriod"
                      name="tenorTimePeriod"
                      label="Tenor Time Period"
                      value={values.tenorTimePeriod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("tenorTimePeriod", e.id)}
                      data={InstrumentStaticData.TimePeriodOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="swapEffectiveDate"
                      name="swapEffectiveDate"
                      label="Swap Effective Date"
                      value={values.swapEffectiveDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("swapEffectiveDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="effectiveDateBusinessDayConvention"
                      name="effectiveDateBusinessDayConvention"
                      label="Effective Date Business Day Convention"
                      value={values.effectiveDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "effectiveDateBusinessDayConvention",
                          e.id
                        )
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="maturityDateBusinessDayConvention"
                      name="maturityDateBusinessDayConvention"
                      label="Maturity Date Business Day Convention"
                      value={values.maturityDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("maturityDateBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="rollAndPaymentDateHolidayCalendarsList"
                      name="rollAndPaymentDateHolidayCalendarsList"
                      label="Roll and Payment Date Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.rollAndPaymentDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue(
                          "rollAndPaymentDateHolidayCalendarsList",
                          e
                        )
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedEffectiveDate"
                      name="unadjustedEffectiveDate"
                      label="Unadjusted Effective Date"
                      value={values.unadjustedEffectiveDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedEffectiveDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="unadjustedMaturityDate"
                      name="unadjustedMaturityDate"
                      label="Unadjusted Maturity Date"
                      value={values.unadjustedMaturityDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("unadjustedMaturityDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="maturityDate"
                      name="maturityDate"
                      label="Maturity Date"
                      value={values.maturityDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("maturityDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Fixed Leg Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentFrequency"
                      name="fixedPaymentFrequency"
                      label="Fixed Payment Frequency"
                      value={values.fixedPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentFrequency", e.id)
                      }
                      data={InstrumentStaticData.FixedPaymentFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedCompoundingMethod"
                      name="fixedCompoundingMethod"
                      label="Fixed Compounding Method"
                      value={values.fixedCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedDayCountConvention"
                      name="fixedDayCountConvention"
                      label="Fixed Day Count Convention"
                      value={values.fixedDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedBusinessDayConvention"
                      name="fixedBusinessDayConvention"
                      label="Fixed Business Day Convention"
                      value={values.fixedBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("fixedBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedRollConvention"
                      name="fixedRollConvention"
                      label="Fixed Roll Convention"
                      isRequired={true}
                      value={values.fixedRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("fixedRollConvention", e)}
                      data={InstrumentStaticData.FixedRollConventionList}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="fixedFirstUnadjustedPaymentDate"
                      name="fixedFirstUnadjustedPaymentDate"
                      label="Fixed First Unadjusted Payment Date"
                      value={values.fixedFirstUnadjustedPaymentDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) =>
                        setFieldValue("fixedFirstUnadjustedPaymentDate", e)
                      }
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="fixedPaymentDateRelativeTo"
                      name="fixedPaymentDateRelativeTo"
                      label="Fixed Payment Date Relative To"
                      value={values.fixedPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("fixedPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                </Row>

                <hr />
                <h6>
                  <strong>Float Leg Swap Information</strong>
                </h6>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatCompoundingMethod"
                      name="floatCompoundingMethod"
                      label="Float Compounding Method"
                      value={values.floatCompoundingMethod}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatCompoundingMethod", e.id)
                      }
                      data={InstrumentStaticData.CompoundingOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatDayCountConvention"
                      name="floatDayCountConvention"
                      label="Float Day Count Convention"
                      value={values.floatDayCountConvention}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatDayCountConvention", e.id)
                      }
                      data={InstrumentStaticData.DayCountConventionOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatBusinessDayConvention"
                      name="floatBusinessDayConvention"
                      label="Float Business Day Convention"
                      value={values.floatBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatBusinessDayConvention", e.id)
                      }
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="floatHolidayCalendarsList"
                      name="floatHolidayCalendarsList"
                      label="Float Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.floatHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatHolidayCalendarsList", e)
                      }
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatPaymentFrequency"
                      name="floatPaymentFrequency"
                      label="Float Payment Frequency"
                      value={values.floatPaymentFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatPaymentFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatPaymentFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatRateResetFrequency"
                      name="floatRateResetFrequency"
                      label="Float Rate Reset Frequency"
                      value={values.floatRateResetFrequency}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) =>
                        setFieldValue("floatRateResetFrequency", e.id)
                      }
                      data={InstrumentStaticData.FloatRateResetFrequency}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatResetDateBusinessDayConvention"
                      name="floatResetDateBusinessDayConvention"
                      label="Reset Date Business Day Convention"
                      value={values.floatResetDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("floatResetDateBusinessDayConvention", e.id)}
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="firstFloatResetDate"
                      name="firstFloatResetDate"
                      label="First Float Reset Date"
                      value={values.firstFloatResetDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("firstFloatResetDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="firstFloatFixingDate"
                      name="firstFloatFixingDate"
                      label="First Float Fixing Date"
                      value={values.firstFloatFixingDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("firstFloatFixingDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatingRateIndex"
                      name="floatingRateIndex"
                      label="Floating Rate Index"
                      value={values.floatingRateIndex}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("floatingRateIndex", e)}
                      data={InstrumentStaticData.FloatingRateIndexList}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatRollConvention"
                      name="floatRollConvention"
                      label="Float Roll Convention"
                      isRequired={true}
                      value={values.floatRollConvention || SELECTED_DEFAULT}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("floatRollConvention", e)}
                      data={InstrumentStaticData.FixedRollConventionList}
                    ></DropdownListGroup>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DateGroup
                      id="floatFirstUnadjustedPaymentDate"
                      name="floatFirstUnadjustedPaymentDate"
                      label="Float First Unadjusted Payment Date"
                      value={values.floatFirstUnadjustedPaymentDate}
                      maxDate={null}
                      dropUp={true}
                      enabled={values.isEditable}
                      errors={errors}
                      touched={touched}
                      onChange={(e) => setFieldValue("floatFirstUnadjustedPaymentDate", e)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatPaymentDateRelativeTo"
                      name="floatPaymentDateRelativeTo"
                      label="Float Payment Date Relative To"
                      value={values.floatPaymentDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) =>
                        setFieldValue("floatPaymentDateRelativeTo", e.id)
                      }
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col>
                    <DropdownListGroup
                      id="floatFixingDatesDayType"
                      name="floatFixingDatesDayType"
                      label="Float Fixing Dates Day Type"
                      value={values.floatFixingDatesDayType}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("floatFixingDatesDayType", e.id)}
                      data={InstrumentStaticData.FixingDatesDayType}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>
                  <Col>
                    <FieldGroup
                      id="floatFixingDatesOffset"
                      name="floatFixingDatesOffset"
                      type="textarea"
                      label="Float Fixing Dates Offset"
                      value={values.floatFixingDatesOffset}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroup
                      id="floatingRateIndexTenor"
                      name="floatingRateIndexTenor"
                      type="textarea"
                      label="Floating Rate Index Tenor"
                      value={values.floatingRateIndexTenor}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatResetDateRelativeTo"
                      name="floatResetDateRelativeTo"
                      label="Float Reset Date Relative To"
                      value={values.floatResetDateRelativeTo}
                      errors={errors}
                      enabled={values.isEditable}
                      onChange={(e) => setFieldValue("floatResetDateRelativeTo", e.id)}
                      data={InstrumentStaticData.DateRelativeToOptions}
                      textField="name"
                      valueField="id"
                    ></DropdownListGroup>
                  </Col>

                  <Col lg={3} xs={3} md={3}>
                    <MultiSelectDropdownListGroup
                      id="floatFixingDateHolidayCalendarsList"
                      name="floatFixingDateHolidayCalendarsList"
                      label="Float Fixing Date Holiday Calendars"
                      labelWrapperClass="label-wrapper"
                      value={values.floatFixingDateHolidayCalendarsList}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("floatFixingDateHolidayCalendarsList", e)}
                      data={InstrumentStaticData.HolidayCalendarList}
                      textField={"text"}
                      valueField={"value"}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <DropdownListGroup
                      id="floatFixingDateBusinessDayConvention"
                      name="floatFixingDateBusinessDayConvention"
                      label="Float Fixing Date Business Day Convention"
                      value={values.floatFixingDateBusinessDayConvention}
                      enabled={values.isEditable}
                      errors={errors}
                      onChange={(e) => setFieldValue("floatFixingDateBusinessDayConvention", e.id)}
                      data={InstrumentStaticData.BusinessDayConventionOptions}
                      textField="name"
                      valueField="id"
                    />
                  </Col>
                </Row>
              </>
            )}

            <hr />
            <Row>
              <Col lg={9} xs={9} md={9}>
                <h6>
                  <strong>Risk Settings</strong>
                </h6>
              </Col>

              {isEditing && (
                <Col lg={3} xs={3} md={3}>
                  <strong>
                    <CheckboxGroup
                      checked={
                        this.state.syncAdditionalInfoWithChildInstruments
                      }
                      onClick={(e) => {
                        const val = e.currentTarget.checked;
                        this.props.setSyncAdditionalInfo(val);
                        this.setState({
                          syncAdditionalInfoWithChildInstruments: val,
                        });
                      }}
                      label="Sync Child Instruments?"
                    />
                  </strong>
                </Col>
              )}
            </Row>

            <Row>
              <Col lg={3} xs={3} md={3}>
                <PriceLimit
                  lowPrice={values.lowPriceLimit}
                  highPrice={values.highPriceLimit}
                  setLow={values.setLowPriceLimit}
                  setHigh={values.setHighPriceLimit}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onSetLow={(checked) => { setFieldValue("setLowPriceLimit", checked); }}
                  onSetHigh={(checked) => { setFieldValue("setHighPriceLimit", checked); }}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <RelativePriceLimit
                  lowPrice={values.relativeLow}
                  highPrice={values.relativeHigh}
                  setLow={values.relativeLowSet}
                  setHigh={values.relativeHighSet}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onSetLow={(checked) => {
                    setFieldValue("relativeLowSet", checked);
                  }}
                  onSetHigh={(checked) => {
                    setFieldValue("relativeHighSet", checked);
                  }}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <OrderLimit
                  lowOrder={values.lowOrderLimit}
                  highOrder={values.highOrderLimit}
                  setLow={values.setLowOrderLimit}
                  setHigh={values.setHighOrderLimit}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onSetLow={(checked) => {
                    setFieldValue("setLowOrderLimit", checked);
                  }}
                  onSetHigh={(checked) => {
                    setFieldValue("setHighOrderLimit", checked);
                  }}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <TotalNotionalLimit
                    lowTotalNotional={values.lowTotalNotionalLimit}
                    highTotalNotional={values.highTotalNotionalLimit}
                    setLowTotalNotional={values.setLowTotalNotionalLimit}
                    setHighTotalNotional={values.setHighTotalNotionalLimit}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSetLowTotalNotional={(checked) => { setFieldValue("setLowTotalNotionalLimit", checked); }}
                    onSetHighTotalNotional={(checked) => { setFieldValue("setHighTotalNotionalLimit", checked); }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={6}>
                <CheckboxGroup
                  id={"chkSkipRiskAndCurrencyChecks"}
                  checked={values.skipRiskAndCurrencyChecks}
                  onClick={(e) => {
                    setFieldValue("skipRiskAndCurrencyChecks", e.target.checked);
                  }}
                  label={"Skip Risk Checks and Currency Balance Updates"}
                  tooltip={toolTips.SKIP_RISK_AND_CURRENCY_CHECKS}
                  tooltipPlace={'right'}
                />
              </Col>
            </Row>
            
            <hr />

            <ButtonMain
              type="button"
              text="SAVE"
              onClick={() =>
                submitHandler("saveOnly", onBeforeSubmit, submitForm)
              }
            />
            <ButtonSecondary
              type="button"
              text="SAVE AND CREATE AN INSTRUMENT"
              onClick={() =>
                submitHandler("saveAndCreate", onBeforeSubmit, submitForm)
              }
            />
            {isEditing ? (
              <ButtonBorderless
                type="button"
                text="Remove Product"
                onClick={() => onDelete(values.name)}
                icon="trash-alt"
                customClassName="orange-icon"
              />
            ) : null}
            <ButtonBorderless
              type="button"
              text="Cancel"
              icon="times-circle"
              customClassName="grey-icon"
              onClick={(e) => {
                e.preventDefault();
                this.context.router.history.push("/products");
              }}
            />
          </Container>
        </form>
        <TradingScheduleDialog
          title="Product Trading Hours"
          show={this.state.showTradingShedule}
          onCancel={
            this.state.isNew ? this.cancelAddSchedule : this.toggleSchedule
          }
          onSave={this.saveSchedule}
          schedule={this.state.selectedSchedule}
          scheduleIndex={this.state.selectedScheduleIndex}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isEditing: !state.products.isCreatingProduct,
    product: state.products.product,
    products: state.products.products,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setSyncAdditionalInfo: (shouldSync) => {
    dispatch(SetSyncAdditionalInfo(shouldSync));
  },
  setSyncTradingInfo: (shouldSync) => {
    dispatch(SetSyncTradingInfo(shouldSync));
  },
  setSyncTradeDayRollSchedule: (shouldSync) => {
    dispatch(SetSyncTradeDayRollSchedule(shouldSync));
  },
  setSyncCrossOrderInfo: (shouldSync) => {
    dispatch(SetSyncCrossOrderInfo(shouldSync));
  },
  setSyncSettlementPriceCalcInfo: (shouldSync) => {
    dispatch(SetSyncSettlementPriceCalcInfo(shouldSync));
  },

});

function makeDropdownOptions(options, isObjectList = true, showSelect = true) {
  if (isObjectList) {
    if (showSelect) {
      let arr = [{ text: SELECTED_DEFAULT, value: 0 }];
      arr.splice(1, 0, ...options);
      return arr;
    } else {
      return options;
    }
  } else {
    if (showSelect) return [SELECTED_DEFAULT, ...options];

    return options;
  }
}

const ProductForm = withFormik({
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: (product) => {
    let inst = { ...product.product };

    if (window.location.href.endsWith("/copy")) {
      inst.description = ""
      inst.name = ""
    }

    var priceScale = 1;
    if (inst.minimumPriceIncrement) {
      const scale = Math.pow(
          10,
          countDecimals(inst.minimumPriceIncrement)
      );
      if (scale !== 0) priceScale = scale;
    }

    var qtyScale = 1;
    if (inst.minimumTradeQuantity) {
      const scale = Math.pow(
          10,
          countDecimals(inst.minimumTradeQuantity)
      );
      if (scale !== 0) qtyScale = scale;
    }

    if (inst.highPriceLimit) {
      inst.highPriceLimit = parsePrice(inst.highPriceLimit, priceScale);
    }
    if (inst.lowPriceLimit) {
      inst.lowPriceLimit = parsePrice(inst.lowPriceLimit, priceScale);
    }
    if (inst.highOrderLimit) {
      inst.highOrderLimit = parsePrice(inst.highOrderLimit, qtyScale);
    }
    if (inst.lowOrderLimit) {
      inst.lowOrderLimit = parsePrice(inst.lowOrderLimit, qtyScale);
    }
    if (inst.highTotalNotionalLimit) {
      inst.highTotalNotionalLimit = parsePrice(inst.highTotalNotionalLimit, priceScale * qtyScale);
    }
    if (inst.lowTotalNotionalLimit) {
      inst.lowTotalNotionalLimit = parsePrice(inst.lowTotalNotionalLimit, priceScale * qtyScale);
    }

    inst.evtAttSubTypYesBuyPriceIncrement = !isNullOrUndefined(
      inst.evtAttSubTypYesBuyPriceIncrement
    )
      ? inst.evtAttSubTypYesBuyPriceIncrement
      : EVENT_ATTR_YES_BUY_PRICE_INCREMENT;
    inst.evtAttSubTypYesSellPriceIncrement = !isNullOrUndefined(
      inst.evtAttSubTypYesSellPriceIncrement
    )
      ? inst.evtAttSubTypYesSellPriceIncrement
      : EVENT_ATTR_YES_SELL_PRICE_INCREMENT;
    inst.evtAttSubTypYesBuyScale = !isNullOrUndefined(
      inst.evtAttSubTypYesBuyScale
    )
      ? inst.evtAttSubTypYesBuyScale
      : EVENT_ATTR_YES_BUY_SCALE;
    inst.evtAttSubTypYesSellScale = !isNullOrUndefined(
      inst.evtAttSubTypYesSellScale
    )
      ? inst.evtAttSubTypYesSellScale
      : EVENT_ATTR_YES_SELL_SCALE;
    inst.evtAttSubTypYesStrictLimit = !isNullOrUndefined(
      inst.evtAttSubTypYesStrictLimit
    )
      ? inst.evtAttSubTypYesStrictLimit
      : true;

    inst.evtAttSubTypNoBuyPriceIncrement = !isNullOrUndefined(
      inst.evtAttSubTypNoBuyPriceIncrement
    )
      ? inst.evtAttSubTypNoBuyPriceIncrement
      : EVENT_ATTR_NO_BUY_PRICE_INCREMENT;
    inst.evtAttSubTypNoSellPriceIncrement = !isNullOrUndefined(
      inst.evtAttSubTypNoSellPriceIncrement
    )
      ? inst.evtAttSubTypNoSellPriceIncrement
      : EVENT_ATTR_NO_SELL_PRICE_INCREMENT;
    inst.evtAttSubTypNoBuyScale = !isNullOrUndefined(
      inst.evtAttSubTypNoBuyScale
    )
      ? inst.evtAttSubTypNoBuyScale
      : EVENT_ATTR_NO_BUY_SCALE;
    inst.evtAttSubTypNoSellScale = !isNullOrUndefined(
      inst.evtAttSubTypNoSellScale
    )
      ? inst.evtAttSubTypNoSellScale
      : EVENT_ATTR_NO_SELL_SCALE;

    inst.evtAttSubTypNoOpposingSide = !isNullOrUndefined(
      inst.evtAttSubTypNoOpposingSide
    )
      ? inst.evtAttSubTypNoOpposingSide
      : true;

    inst.evtAttSubTypNoStrictLimit = !isNullOrUndefined(inst.evtAttSubTypNoStrictLimit) ? inst.evtAttSubTypNoStrictLimit : true;

    inst.tradeSettlementPeriod = !isNullOrUndefined(inst.tradeSettlementPeriod) ? inst.tradeSettlementPeriod : "";

    if (!!inst.crossOrderRestingDuration) {
      inst.crossOrderRestingDuration = ProtobufParser.fromDurationToMillis(inst.crossOrderRestingDuration);
    }

    if (inst.settlementPeriodStartOffsetFromCloseDuration) {
      inst.settlementPeriodStartOffsetFromCloseDuration = ProtobufParser.fromDurationToMillis(inst.settlementPeriodStartOffsetFromCloseDuration);
    }

    if (inst.settlementPeriodEndOffsetFromCloseDuration) {
      inst.settlementPeriodEndOffsetFromCloseDuration = ProtobufParser.fromDurationToMillis(inst.settlementPeriodEndOffsetFromCloseDuration);
    }

    if (inst.bufferFromEndOfSettlementPeriodDuration) {
      inst.bufferFromEndOfSettlementPeriodDuration = ProtobufParser.fromDurationToMillis(inst.bufferFromEndOfSettlementPeriodDuration);
    }

    inst.tradeDayRollSchedule = inst.tradeDayRollSchedule || {};

    return inst;
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().nullable().required("Product name is required."),
    description: Yup.string().nullable().required("Description is required."),
    basecurrency: Yup.string()
      .nullable()
      .required("Currency is required."),
    multiplier: Yup.number()
      .moreThan(0, "Multiplier must be greater than 0.")
      .nullable()
      .required("Multiplier is required."),
    minimumUnaffiliatedFirms: Yup.number("Must be a number")
      .typeError('Minimum Unaffiliated Firms must be a `number` type')
      .notRequired()
      .nullable()
      .min(0, "Minimum Unaffiliated Firms must be greater than 0 if provided."),
    ...SubTypesSchemaValidation(),
    minimumTradeQtyForVwapSettlementCalculation: Yup.number("Must be a number")
      .typeError('Minimum Trade Quantity for VWAP must be a `number` type')
      .notRequired()
      .nullable(),
    crossOrderRestingDuration: Yup.number("Must be a number").nullable()
      .typeError('Cross Order Resting Duration must be a `number` type')
      .min(0, "Cross Order Resting Duration must be greater than or equal to 0."),
    blockTradeMinimumQuantity: Yup.number("Must be a number").nullable()
      .typeError('Block Trade Minimum Quantity must be a `number` type')
      .moreThan(0, "Block Trade Minimum Quantity must be greater than 0."),
    blockTradeMinimumNotional: Yup.number("Must be a number").nullable()
      .typeError('Block Trade Minimum Notional must be a `number` type')
      .moreThan(0, "Block Trade Minimum Notional must be greater than 0."),
  }),
  validate: (values) => validateAttributes(values),
  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onSubmitForm(values, setFieldError);
  },
  displayName: "product-form",
  enableReinitialize: true,
})(BasicProductForm);

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);

export function validateAttributes(values) {
  let errors = {};
  let validateField = function (name, label, type, required = true, scale = null, opts = {}) {
    validate(values, errors, name, label, type, required, scale, opts);
  };

  !!values.setBlockTradeThreshold && validateField("blockTradeThreshold.minimumNotional", "Block Trade Minimum Notional", NUMBER_TYPE, !!values.setBlockTradeThreshold, null, { greaterThan: 0 });
  !!values.setBlockTradeThreshold && validateField("blockTradeThreshold.minimumQuantity", "Block Trade Minimum Quantity", NUMBER_TYPE, !!values.setBlockTradeThreshold, null, { greaterThan: 0 });

  // Interest Rate Swap Field Validations
  if (values.type === INTEREST_RATE_SWAP) {
    // Swap Information Fields
    validateField(
      "effectiveDateBusinessDayConvention",
      "Effective Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "effectiveDateOffset",
      "Swap Effective Date Offset",
      NUMBER_TYPE
    );
    validateField(
      "maturityDateBusinessDayConvention",
      "Maturity Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "rollAndPaymentDateHolidayCalendarsList",
      "Roll and Payment Date Holiday List",
      ARRAY_TYPE
    );

    // Fixed Leg Information Fields
    validateField("fixedRollConvention", "Fixed Roll Convention", STRING_TYPE);
    validateField(
      "fixedDayCountConvention",
      "Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "fixedBusinessDayConvention",
      "Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "fixedPaymentDateRelativeTo",
      "Fixed Payment Date Relative To",
      NUMBER_TYPE
    );

    // Floating Leg Information Fields
    validateField(
      "floatHolidayCalendarsList",
      "Float Holiday Calendar",
      ARRAY_TYPE
    );
    validateField(
      "floatDayCountConvention",
      "Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "floatResetDateBusinessDayConvention",
      "Reset Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "floatFixingDatesOffset",
      "Float Fixing Dates Offset",
      NUMBER_TYPE
    );

    validateField("floatRollConvention", "Float Roll Convention", STRING_TYPE);
    validateField(
      "floatFixingDateBusinessDayConvention",
      "Float Fixing Date Business Day Convention",
      DATE_TYPE
    );
    validateField(
      "floatPaymentDateRelativeTo",
      "Float Payment Date Relative To",
      NUMBER_TYPE
    );
    validateField(
      "floatFixingDatesDayType",
      "Float Fixing Dates Day Type",
      STRING_TYPE
    );
  }

  // Interest Rate Swap Field Validations
  if (values.type === FORWARD_STARTING_INTEREST_RATE_SWAP) {
    // Swap Information Fields
    validateField("sideConvention", "Side Convention", STRING_TYPE);
    validateField("tenor", "Tenor", NUMBER_TYPE);
    validateField(
      "forwardStartingPeriod",
      "Forward Starting Period",
      NUMBER_TYPE
    );
    validateField(
      "effectiveDateBusinessDayConvention",
      "Effective Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "effectiveDateOffset",
      "Swap Effective Date Offset",
      NUMBER_TYPE
    );
    validateField(
      "maturityDateBusinessDayConvention",
      "Maturity Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "rollAndPaymentDateHolidayCalendarsList",
      "Roll and Payment Date Holiday List",
      ARRAY_TYPE
    );

    // Fixed Leg Information Fields
    validateField(
      "fixedPaymentFrequency",
      "Fixed Payment Frequency",
      STRING_TYPE
    );
    validateField(
      "fixedCompoundingMethod",
      "Fixed Compounding Method",
      STRING_TYPE
    );
    validateField("fixedRollConvention", "Fixed Roll Convention", STRING_TYPE);
    validateField(
      "fixedDayCountConvention",
      "Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "fixedBusinessDayConvention",
      "Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "fixedPaymentDateRelativeTo",
      "Fixed Payment Date Relative To",
      NUMBER_TYPE
    );

    // Floating Leg Information Fields
    validateField(
      "floatPaymentFrequency",
      "Float Payment Frequency",
      STRING_TYPE
    );
    validateField(
      "floatCompoundingMethod",
      "Float Compounding Method",
      STRING_TYPE
    );
    validateField(
      "floatHolidayCalendarsList",
      "Float Holiday Calendar",
      ARRAY_TYPE
    );
    validateField(
      "floatDayCountConvention",
      "Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "floatResetDateBusinessDayConvention",
      "Reset Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "floatFixingDatesOffset",
      "Float Fixing Dates Offset",
      NUMBER_TYPE
    );
    validateField("floatingRateIndex", "Floating Rate Index", STRING_TYPE);
    validateField("floatRollConvention", "Float Roll Convention", STRING_TYPE);
    validateField(
      "floatPaymentDateRelativeTo",
      "Float Payment Date Relative To",
      NUMBER_TYPE
    );
    validateField(
      "floatFixingDatesDayType",
      "Float Fixing Dates Day Type",
      STRING_TYPE
    );
    validateField(
      "floatingRateIndexTenor",
      "Floating Rate Index Tenor",
      STRING_TYPE
    );
    validateField(
      "floatResetDateRelativeTo",
      "Float Reset Date Relative To",
      STRING_TYPE
    );
    validateField(
      "floatBusinessDayConvention",
      "Float Business Day Convention",
      STRING_TYPE
    );
    validateField(
      "floatFixingDateHolidayCalendarsList",
      "Float Fixing Date Holiday Calendars",
      ARRAY_TYPE
    );
  }

  // ZCIS Swap Field Validations
  if (values.type === ZERO_COUPON_INFLATION_SWAP) {
    // Swap Information Fields
    validateField("tenor", "Tenor", NUMBER_TYPE);
    validateField("sideConvention", "Side Convention", STRING_TYPE);
    validateField("swapEffectiveDate", "Swap Effective Date", DATE_TYPE);
    validateField(
      "fixedCompoundingMethod",
      "Fixed Compounding Method",
      NUMBER_TYPE
    );
    validateField(
      "floatCompoundingMethod",
      "Float Compounding Method",
      NUMBER_TYPE
    );
    validateField(
      "fixedPaymentDayConvention",
      "Fixed Payment Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "fixedCalculationPeriodDayConvention",
      "Fixed Calculation Period Day Convention",
      NUMBER_TYPE
    );

    validateField(
      "indexSource",
      "Index Source",
      STRING_TYPE
    );
    validateField(
      "interpolationMethod",
      "Interpolation Method",
      STRING_TYPE
    );
    // validateField(
    //   "fallbackBondApplicable",
    //   "Fallback Bond Applicable",
    //   STRING_TYPE
    // );
    validateField(
      "inflationInstrumentName",
      "Inflation Instrument Name",
      STRING_TYPE
    );


    validateField(
      "effectiveDateBusinessDayConvention",
      "Effective Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField("maturityDate", "Maturity Date", DATE_TYPE);
    validateField(
      "maturityDateBusinessDayConvention",
      "Maturity Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "rollAndPaymentDateHolidayCalendarsList",
      "Roll and Payment Date Holiday Calendar",
      ARRAY_TYPE
    );
    validateField(
      "fixedPaymentFrequency",
      "Fixed Payment Frequency",
      STRING_TYPE
    );
    validateField("fixedRollConvention", "Fixed Roll Convention", STRING_TYPE);
    validateField(
      "fixedDayCountConvention",
      "Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "floatDayCountConvention",
      "Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "floatHolidayCalendarsList",
      "Float Holiday Calendar",
      ARRAY_TYPE
    );
    validateField(
      "floatPaymentFrequency",
      "Float Payment Frequency",
      STRING_TYPE
    );
    validateField("floatingRateIndex", "Floating Rate Index", STRING_TYPE);
    validateField(
      "floatFirstUnadjustedPaymentDate",
      "Float First Unadjusted Payment Date",
      DATE_TYPE
    );
    validateField("floatRollConvention", "Float Roll Convention", STRING_TYPE);
    validateField(
      "fixedFirstUnadjustedPaymentDate",
      "Fixed First Unadjusted Payment Date",
      DATE_TYPE
    );
    validateField(
      "floatPaymentDateRelativeTo",
      "Float Payment Date Relative To",
      NUMBER_TYPE
    );
    validateField(
      "fixedPaymentDateRelativeTo",
      "Fixed Payment Date Relative To",
      NUMBER_TYPE
    );
    validateField(
      "unadjustedEffectiveDate",
      "Unadjusted Effective Date",
      DATE_TYPE
    );
    validateField(
      "unadjustedMaturityDate",
      "Unadjusted Maturity Date",
      DATE_TYPE
    );
    validateField(
      "effectiveDateOffset",
      "Effective Date Offset",
      NUMBER_TYPE
    );
    validateField(
      "floatPaymentDayConvention",
      "Float Payment Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "floatCalculationPeriodDayConvention",
      "Float Calculation Period Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "forwardStartingPeriod",
      "Forward Starting Period",
      NUMBER_TYPE
    );
    validateField("tenorTimePeriod", "Tenor Time Period", NUMBER_TYPE);
    validateField(
      "forwardStartingPeriodTimePeriod",
      "Forward Starting Period Time Period",
      NUMBER_TYPE
    );
    validateField("inflationLag", "inflationLag", STRING_TYPE);
  }

  // Basis Swap Field Validations
  if (values.type === BASIS_SWAP) {
    // Swap Information Fields
    validateField(
      "effectiveDateBusinessDayConvention",
      "Effective Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "effectiveDateOffset",
      "Swap Effective Date Offset",
      NUMBER_TYPE
    );
    validateField(
      "maturityDateBusinessDayConvention",
      "Maturity Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField("tenor", "Tenor", NUMBER_TYPE);
    validateField("sideConvention", "Side Convention", STRING_TYPE);

    // Fixed Leg Information Fields

    // Floating Leg Information Fields
    validateField(
      "leg1FloatingRateIndex",
      "Leg1 Floating Rate Index",
      STRING_TYPE
    );
    validateField(
      "leg2FloatingRateIndex",
      "Leg2 Floating Rate Index",
      STRING_TYPE
    );
    validateField(
      "leg1FloatDayCountConvention",
      "Leg1 Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "leg2FloatDayCountConvention",
      "Leg2 Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "leg1FloatBusinessDayConvention",
      "Leg1 Float Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "leg2FloatBusinessDayConvention",
      "Leg2 Float Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "leg1FloatResetDateBusinessDayConvention",
      "Leg1 Reset Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "leg2FloatResetDateBusinessDayConvention",
      "Leg2 Reset Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "leg1FirstFloatResetDate",
      "Leg1 First Float Reset Date",
      DATE_TYPE
    );
    validateField(
      "leg2FirstFloatResetDate",
      "Leg2 First Float Reset Date",
      DATE_TYPE
    );
    validateField(
      "leg1FirstFloatFixingDate",
      "Leg1 First Float Fixing Date",
      DATE_TYPE
    );
    validateField(
      "leg2FirstFloatFixingDate",
      "Leg2 First Float Fixing Date",
      DATE_TYPE
    );
    validateField(
      "leg1FloatRollConvention",
      "Leg1 Float Roll Convention",
      STRING_TYPE
    );
    validateField(
      "leg2FloatRollConvention",
      "Leg2 Float Roll Convention",
      STRING_TYPE
    );
    validateField(
      "leg1FloatingRateIndexTenor",
      "Leg1 Floating Rate Index Tenor",
      STRING_TYPE
    );
    validateField(
      "leg2FloatingRateIndexTenor",
      "Leg2 Floating Rate Index Tenor",
      STRING_TYPE
    );
    validateField(
      "leg1FloatFixingDateHolidayCalendarsList",
      "Leg1 Float Fixing Date Holiday Calendar",
      ARRAY_TYPE
    );
    validateField(
      "leg2FloatFixingDateHolidayCalendarsList",
      "Leg2 Float Fixing Date Holiday Calendar",
      ARRAY_TYPE
    );
    validateField(
      "leg1FloatFixingDatesDayType",
      "Leg1 Float Fixing Dates Day Type",
      STRING_TYPE
    );
    validateField(
      "leg2FloatFixingDatesDayType",
      "Leg2 Float Fixing Dates Day Type",
      STRING_TYPE
    );
    validateField(
      "leg1FloatPaymentFrequency",
      "Leg1 Float Payment Frequency",
      STRING_TYPE
    );
    validateField(
      "leg2FloatPaymentFrequency",
      "Leg2 Float Payment Frequency",
      STRING_TYPE
    );
    validateField(
      "leg1FloatFixingDateBusinessDayConvention",
      "Leg1 Float Fixing Date Business Day Convention",
      STRING_TYPE
    );
    validateField(
      "leg2FloatFixingDateBusinessDayConvention",
      "Leg2 Float Fixing Date Business Day Convention",
      STRING_TYPE
    );
    validateField(
      "leg1FloatResetDateRelativeTo",
      "Leg1 Float Reset Date Relative To",
      STRING_TYPE
    );
    validateField(
      "leg2FloatResetDateRelativeTo",
      "Leg2 Float Reset Date Relative To",
      STRING_TYPE
    );

    validateField(
      "leg1FloatFixingDatesOffset",
      "Leg1 Float Fixing Dates Offset",
      NUMBER_TYPE
    );
    validateField(
      "leg2FloatFixingDatesOffset",
      "Leg2 Float Fixing Dates Offset",
      NUMBER_TYPE
    );
    validateField(
      "leg1FloatPaymentDateRelativeTo",
      "Leg1 Float Payment Date Relative To",
      STRING_TYPE
    );
    validateField(
      "leg2FloatPaymentDateRelativeTo",
      "Leg2 Float Payment Date Relative To",
      STRING_TYPE
    );
    validateField(
      "leg1FloatRateResetFrequency",
      "Leg1 Float Rate Reset Frequency",
      STRING_TYPE
    );
    validateField(
      "leg2FloatRateResetFrequency",
      "Leg2 Float Rate Reset Frequency",
      STRING_TYPE
    );

    validateField(
      "leg1FloatFirstUnadjustedPaymentDate",
      "Leg1 Float First Unadjusted Payment Date",
      DATE_TYPE
    );
    validateField(
      "leg2FloatFirstUnadjustedPaymentDate",
      "Leg2 Float First Unadjusted Payment Date",
      DATE_TYPE
    );
    validateField(
      "floatFirstUnadjustedEffectiveDate",
      "Float First Unadjusted Effective Date",
      DATE_TYPE
    );
    validateField(
      "unadjustedMaturityDate",
      "Unadjusted Maturity Date",
      DATE_TYPE
    );
  }

  // Forward Rate Agreement Swap Field Validations
  if (values.type === FORWARD_RATE_AGREEMENT_SWAP) {
    // Swap Information Fields

    validateField("tenor", "Tenor", NUMBER_TYPE);
    validateField(
      "forwardStartingPeriod",
      "Forward Starting Period",
      NUMBER_TYPE
    );
    validateField("sideConvention", "Side Convention", STRING_TYPE);
    validateField(
      "effectiveDateBusinessDayConvention",
      "Effective Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "maturityDateBusinessDayConvention",
      "Maturity Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "calculationPeriodNumberOfDays",
      "Calculation Period Number of Days",
      NUMBER_TYPE
    );
    validateField(
      "rollAndPaymentDateHolidayCalendarsList",
      "Roll and Payment Date Holiday List",
      ARRAY_TYPE
    );

    // Fixed Leg Information Fields

    validateField(
      "fixedDayCountConvention",
      "Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "fixedBusinessDayConvention",
      "Business Day Convention",
      NUMBER_TYPE
    );

    // Floating Leg Information Fields
    validateField(
      "floatDayCountConvention",
      "Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "floatBusinessDayConvention",
      "Float Business Day Convention",
      STRING_TYPE
    );
    validateField(
      "floatFixingDateHolidayCalendarsList",
      "Float Fixing Date Holiday Calendars",
      ARRAY_TYPE
    );
    validateField(
      "floatFixingDateBusinessDayConvention",
      "Float Fixing Date Business Day Convention",
      DATE_TYPE
    );
    validateField("floatingRateIndex", "Floating Rate Index", STRING_TYPE);
    validateField(
      "floatFixingDatesDayType",
      "Float Fixing Dates Day Type",
      STRING_TYPE
    );
    validateField(
      "floatFixingDatesOffset",
      "Float Fixing Dates Offset",
      NUMBER_TYPE
    );
  }

  // Over night index Swap Field Validations
  if (values.type === OVER_NIGHT_INDEX_SWAP) {
    // Swap Information Fields

    validateField("tenor", "Tenor", NUMBER_TYPE);
    validateField("tenorTimePeriod", "Tenor Time Period", NUMBER_TYPE);
    validateField("forwardStartingPeriod", "Forward Starting Period", NUMBER_TYPE);
    validateField("forwardStartingPeriodTimePeriod", "Forward Starting Period Time Period", NUMBER_TYPE);
    validateField("sideConvention", "Side Convention", STRING_TYPE);
    validateField("effectiveDateBusinessDayConvention", "Effective Date Business Day Convention", NUMBER_TYPE);
    validateField("maturityDateBusinessDayConvention", "Maturity Date Business Day Convention", NUMBER_TYPE);
    validateField("effectiveDateOffset", "Swap Effective Date Offset", NUMBER_TYPE);

    // Fixed Leg Information Fields
    validateField("fixedDayCountConvention", "Day Count Convention", NUMBER_TYPE);
    validateField("fixedBusinessDayConvention", "Business Day Convention", NUMBER_TYPE);
    validateField("fixedPaymentDateOffset", "Fixed Payment Date Offset", NUMBER_TYPE);

    // Floating Leg Information Fields
    validateField("floatDayCountConvention", "Day Count Convention", NUMBER_TYPE);
    validateField("floatBusinessDayConvention", "Float Business Day Convention", STRING_TYPE);
    validateField("floatHolidayCalendarsList", "Float Fixing Date Holiday Calendars", ARRAY_TYPE);

    validateField("floatingRateIndex", "Floating Rate Index", STRING_TYPE);
    validateField("floatFixingDatesDayType", "Float Fixing Dates Day Type", STRING_TYPE);
    validateField("floatFixingDatesOffset", "Float Fixing Dates Offset", NUMBER_TYPE);
    validateField("floatPaymentDateOffset", "Float Payment Date Offset", NUMBER_TYPE);
  }

  // Single Period Agreement Swap Field Validations
  if (values.type === SINGLE_PERIOD_SWAP) {
    // Swap Information Fields
    validateField("tenor", "Tenor", NUMBER_TYPE);
    validateField("tenorTimePeriod", "Tenor Time Period", STRING_TYPE);
    validateField("sideConvention", "Side Convention", STRING_TYPE);
    validateField(
      "effectiveDateBusinessDayConvention",
      "Effective Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "maturityDateBusinessDayConvention",
      "Maturity Date Business Day Convention",
      NUMBER_TYPE
    );
    validateField(
      "rollAndPaymentDateHolidayCalendarsList",
      "Roll and Payment Date Holiday List",
      ARRAY_TYPE
    );

    // Fixed Leg Information Fields

    validateField(
      "fixedDayCountConvention",
      "Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "fixedBusinessDayConvention",
      "Business Day Convention",
      NUMBER_TYPE
    );

    // Floating Leg Information Fields
    validateField(
      "floatDayCountConvention",
      "Day Count Convention",
      NUMBER_TYPE
    );
    validateField(
      "floatBusinessDayConvention",
      "Float Business Day Convention",
      STRING_TYPE
    );
    validateField(
      "floatFixingDateHolidayCalendarsList",
      "Float Fixing Date Holiday Calendars",
      ARRAY_TYPE
    );
    validateField(
      "floatFixingDateBusinessDayConvention",
      "Float Fixing Date Business Day Convention",
      DATE_TYPE
    );
    validateField("floatingRateIndex", "Floating Rate Index", STRING_TYPE);
    validateField(
      "floatFixingDatesDayType",
      "Float Fixing Dates Day Type",
      STRING_TYPE
    );
    validateField(
      "floatFixingDatesOffset",
      "Float Fixing Dates Offset",
      NUMBER_TYPE
    );
  }
  // Non Deliverable Forwards Field Validations
  if (values.type === NON_DELIVERABLE_FORWARDS) {
    // Swap Information Fields
    validateField("tenor", "Tenor", NUMBER_TYPE);
    validateField("forwardStartingPeriod", "Forward Starting Period", NUMBER_TYPE);
    validateField(
      "forwardStartingPeriodTimePeriod",
      "Forward Starting Period Time Period",
      ARRAY_TYPE
    );
    validateField(
      "sideConvention",
      "Side Convention",
      ARRAY_TYPE
    );
    validateField(
      "currency1",
      "Currency 1",
      ARRAY_TYPE
    );
    validateField(
      "currency2",
      "Currency 2",
      ARRAY_TYPE
    );
    validateField(
      "exchangedCurrency1",
      "Exchanged Currency 1",
      ARRAY_TYPE
    );
    validateField(
      "exchangedCurrency2",
      "Exchanged Currency 2",
      ARRAY_TYPE
    );
    validateField("valuationDate", "Valuation Date", DATE_TYPE);
    validateField("fixingDate", "Fixing Date", DATE_TYPE);
    validateField(
      "exchangeRateCurrency1",
      "Exchange Rate Currency 1",
      ARRAY_TYPE
    );
    validateField(
      "exchangeRateCurrency2",
      "Exchange Rate Currency 2",
      ARRAY_TYPE
    );
    validateField(
      "exchangeRateQuoteBasis",
      "Exchange Rate Quote Basis",
      ARRAY_TYPE
    );
    validateField(
      "fixingQuoteBasis",
      "Fixing Quote Basis",
      ARRAY_TYPE
    );
    validateField(
      "settlementCurrency",
      "Settlement Currency",
      ARRAY_TYPE
    );
    validateField(
      "fixingCurrency1",
      "Fixing Currency 1",
      ARRAY_TYPE
    );
    validateField(
      "fixingCurrency2",
      "Fixing Currency 2",
      ARRAY_TYPE
    );
    validateField(
      "tenorTimePeriod",
      "Tenor Time Period",
      ARRAY_TYPE
    );
  }

  if (numberOrDefault(values.minimumTradeQuantity, 0) < 0) {
    errors.minimumTradeQuantity = "Minimum Trade Quantity must be a number and >= 0";
  }

  if (numberOrDefault(values.minimumPriceIncrement, -1) <= 0) {
    errors.minimumPriceIncrement = "Minimum Price Increment must be greater than 0.";
  } else {
    const fraction = Fraction.getFraction(values.minimumPriceIncrement);
    const fractionToMinimum = Fraction.reduce(fraction.numerator, fraction.denominator);
    if (fractionToMinimum.numerator !== 1) {
      errors.minimumPriceIncrement = "Minimum Price Increment must be of format 1/n where n is an integer when present.";
    }
  }

  if (values.type === FOREX) {
    validateField("fxBaseCurrency", "Base Currency", STRING_TYPE);
    validateField("fxQuoteCurrency", "Quote Currency", STRING_TYPE);
  }

  if (!isEmptyString(values.tradeSettlementPeriod)) {
    const tradeSettlementPeriod = Number.parseInt(values.tradeSettlementPeriod);
    if (Number.isNaN(tradeSettlementPeriod) || tradeSettlementPeriod < 0) {
      errors.tradeSettlementPeriod = "Trade Settlement Period must be >= 0";
    }
  }

  if (values.type === EVENT) {
    if (values.isContinuous) {
      if (values.evtAttPayoutUpperBound <= 0
          || Number.isNaN(Number.parseFloat(values.evtAttPayoutUpperBound))
          || !Number.isFinite(Number.parseFloat(values.evtAttPayoutUpperBound))) {
        errors.evtAttPayoutUpperBound = 'Payout Upper Bound must be greater than 0.';
      } else if (values.evtAttPayoutLowerBound > values.evtAttPayoutUpperBound
          || Number.isNaN(Number.parseFloat(values.evtAttPayoutLowerBound))
          || !Number.isFinite(Number.parseFloat(values.evtAttPayoutLowerBound))) {
        errors.evtAttPayoutLowerBound = 'Payout Lower Bound must be less than or equal to Payout Upper Bound.';
      }
    } else {
      if (values.evtAttPayoutValue <= 0
        || Number.isNaN(Number.parseFloat(values.evtAttPayoutValue))
        || !Number.isFinite(Number.parseFloat(values.evtAttPayoutValue))) {
        errors.evtAttPayoutValue = 'Payout Value must be greater than 0.';
      } else {
        const fraction = Fraction.getFraction(values.evtAttPayoutValue);
        const fractionToMinimum = Fraction.reduce(fraction.numerator, fraction.denominator);
        if (fractionToMinimum.numerator !== 1) {
          errors.evtAttPayoutValue = 'Payout Value must be of format 1/n where n is an integer when present.';
        }
      }
      
      if (!values.evtAttStrikeUnit) {
        errors.evtAttStrikeUnit = "Strike Unit is required";
      }
    }

    if ((!!values.evtAttPositionAccountability && values.evtAttPositionAccountability.length > 0)
      && (values.evtAttPositionAccountability <= 0
        || Number.isNaN(Number.parseFloat(values.evtAttPositionAccountability))
        || !Number.isFinite(Number.parseFloat(values.evtAttPositionAccountability)))) {
      errors.evtAttPositionAccountability = 'Position Accountability must be > 0.';
    }
    // Section errors present
    if (Object.getOwnPropertyNames(errors).length > 0) {
      return errors;
    }
  }

  if (
    values.expirationDate !== null &&
    values.startDate !== null &&
    values.startDate >= values.expirationDate
  )
    errors.expirationDate = "Start date must be before expiration date.";

  if (
    values.terminationDate !== null &&
    values.startDate !== null &&
    values.startDate >= values.terminationDate
  ) {
    errors.terminationDate = "Start date must be before termination date.";
  }

  if (values.setLowPriceLimit) {
    if (!isNumber(values.lowPriceLimit)) {
      errors.lowPriceLimit = "Low limit is required.";
    } else if (values.setHighPriceLimit && isNumber(values.highPriceLimit) && Number(values.lowPriceLimit) >= Number(values.highPriceLimit)) {
      errors.lowPriceLimit = "Low limit must be less than High Limit.";
    }
  }

  if (values.setHighPriceLimit) {
    if (!isNumber(values.highPriceLimit)) {
      errors.highPriceLimit = "High limit is required.";
    } else if (Number(values.highPriceLimit) <= 0) {
      errors.highPriceLimit = "High limit must be greater than 0.";
    } else if (values.setLowPriceLimit && isNumber(values.lowPriceLimit) && Number(values.lowPriceLimit) >= Number(values.highPriceLimit)) {
      errors.highPriceLimit = "High limit must be greater than Low Limit.";
    }
  }

  if (values.relativeLowSet) {
    if (isNullOrUndefined(values.relativeLow) || isNaN(values.relativeLow)) {
      errors.relativeLow = "Low limit is required.";
    } else if (Number(values.relativeLow) <= 0) {
      errors.relativeLow = "Low limit must be greater than 0.";
    } else if (
      values.relativeHighSet &&
      Number(values.relativeLow) > Number(values.relativeHigh)
    ) {
      errors.relativeLow = "Low limit must be less High Limit.";
    }
  }

  if (values.relativeHighSet) {
    if (isNullOrUndefined(values.relativeHigh) || isNaN(values.relativeHigh)) {
      errors.relativeHigh = "High limit is required.";
    } else if (Number(values.relativeHigh) <= 0) {
      errors.relativeHigh = "High limit must be greater than 0.";
    } else if (
      values.relativeLowSet &&
      Number(values.relativeLow) > Number(values.relativeHigh)
    ) {
      errors.relativeHigh = "High limit must be greater Low Limit.";
    }
  }

  if (values.setLowOrderLimit) {
    if (values.lowOrderLimit === null) {
      errors.lowOrderLimit = "Low limit is required.";
    } else if (Number(values.lowOrderLimit) <= 0) {
      errors.lowOrderLimit = "Low limit must be greater than 0.";
    } else if (
      values.setHighOrderLimit &&
      Number(values.lowOrderLimit) > Number(values.highOrderLimit)
    ) {
      errors.lowOrderLimit = "Low limit must be less High Limit.";
    }
  }

  if (values.setHighOrderLimit) {
    if (values.highOrderLimit === null) {
      errors.highOrderLimit = "High limit is required.";
    } else if (Number(values.highOrderLimit) <= 0) {
      errors.highOrderLimit = "High limit must be greater than 0.";
    } else if (
      values.setLowOrderLimit &&
      Number(values.lowOrderLimit) > Number(values.highOrderLimit)
    ) {
      errors.highOrderLimit = "High limit must be greater Low Limit.";
    }
  }

  return errors;
}
