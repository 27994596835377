import { TRADE_DAY_ROLL_SCHEDULE_TYPE_CONFIGURE_BY_DAY, TRADE_DAY_ROLL_SCHEDULE_TYPE_SAME_EVERY_DAY, TRADE_DAY_ROLL_SCHEDULE_TYPE_UNSET } from "../constants/tradeDayRollScheduleTypes";
const { DayOfWeek } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/type/v1beta1/type_pb");

export default class TradeDayRollSchedule {

  scheduleType = TRADE_DAY_ROLL_SCHEDULE_TYPE_UNSET;
  cutoffTime = null;
  monCutoffTime = null;
  tueCutoffTime = null;
  wedCutoffTime = null;
  thuCutoffTime = null;
  friCutoffTime = null;
  satCutoffTime = null;
  sunCutoffTime = null;

  static parseProto(tradeDayRollScheduleList) {
    const schedule = new TradeDayRollSchedule();

    tradeDayRollScheduleList.forEach((tradeDayRollSchedule) => {
      const daysOfWeekList = tradeDayRollSchedule.getDaysOfWeekList()
      let hours = tradeDayRollSchedule.getTimeOfDay().getHours()
      let minutes = tradeDayRollSchedule.getTimeOfDay().getMinutes()

      hours = String(hours).padStart(2, '0')
      minutes = String(minutes).padStart(2, '0')

      const time = `${hours}:${minutes}`;

      // Same Every Day
      if (daysOfWeekList.length === 7) {
        schedule.cutoffTime = time;
        schedule.scheduleType = TRADE_DAY_ROLL_SCHEDULE_TYPE_SAME_EVERY_DAY;

      } else {
        schedule.scheduleType = TRADE_DAY_ROLL_SCHEDULE_TYPE_CONFIGURE_BY_DAY;

        daysOfWeekList.forEach((dayOfWeek) => {
          if (dayOfWeek === DayOfWeek.DAY_OF_WEEK_MONDAY) schedule.monCutoffTime = time;

          if (dayOfWeek === DayOfWeek.DAY_OF_WEEK_TUESDAY) schedule.tueCutoffTime = time;

          if (dayOfWeek === DayOfWeek.DAY_OF_WEEK_WEDNESDAY) schedule.wedCutoffTime = time;

          if (dayOfWeek === DayOfWeek.DAY_OF_WEEK_THURSDAY) schedule.thuCutoffTime = time;

          if (dayOfWeek === DayOfWeek.DAY_OF_WEEK_FRIDAY) schedule.friCutoffTime = time;

          if (dayOfWeek === DayOfWeek.DAY_OF_WEEK_SATURDAY) schedule.satCutoffTime = time;

          if (dayOfWeek === DayOfWeek.DAY_OF_WEEK_SUNDAY) schedule.sunCutoffTime = time;

        });
      }
    });

    return schedule
  }
}

