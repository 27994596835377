import React, { Component } from "react";
import PropTypes from "prop-types";
import FirmForm from "./FirmForm";
import FirmEditForm from "./FirmFormViewEdit";
import FirmAssociations from "./FirmAssociations";
import FirmServiceForm from "./FirmServicesForm";
import FirmServicesEditForm from "./FirmServicesFormViewEdit";
import FirmServicesKeysForm from "./FirmServicesKeysForm";
import FirmServicesKeysEditForm from "./FirmServicesKeysFormViewEdit";
import { FormModes } from "../../constants/enumerations";
import FirmHeader from "../../components/firm/FirmHeader";
import { connect } from "react-redux";
import FirmUsersGrid from "./FirmUsersGrid";
import FirmAccountsGrid from "./FirmAccountsGrid";
import FirmServicesGrid from "./FirmServicesGrid";
import FirmServicesKeysGrid from "./FirmServicesKeysGrid";
import { hasWriteAccess } from "../../services/TokenService";
import PositionsDataGrid from "../account/PositionsDataGrid";
import LoaderIcon from "../core/loader/LoaderIcon";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import CheckboxGroup from "../core/form/CheckboxGroup";
import confirm from "../../modules/confirmDialog";
import Notification from "../../modules/notifications";
import AccountService from "../../services/AccountService";
import _ from 'lodash';
import ParticipantService from "../../services/ParticipantService";

function mapStateToProps(state) {
  return {
    firmServices: state.firms.firmServices,
    firm: state.firms.firm,
    participants: state.participants.participants,
    accounts: state.accounts.accounts,
    positions: state.accounts.positions,
    fetchingPositions: state.accounts.fetchingPositions,
  };
}

export class FirmDetails extends Component {
  autorefreshpositions = "autorefreshpositions"
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    this.props.enableAutoRefresh(window.localStorage.getItem(this.autorefreshpositions) === 'true');
  }

  suspendAccounts = (shouldSuspend) => {
      confirm(<div>{`Are you sure you wish to ${!!shouldSuspend ? "suspend" : "activate"} these accounts?`}</div>, {
          title: `Account ${!!shouldSuspend ? "Suspension" : "Activation"} Confirmation`,
          okButtonText: "Yes",
          cancelButtonText: "No",
      }).then(
          () => {
              const refresh = _.debounce(this.props.loadAccounts, 1000);
              this.props.accounts.forEach(acct => {
                  const cb = (err, response) => {
                      if (response) {
                          Notification.success(`${acct.id} updated`);
                          refresh();
                      }

                      if (err) {
                          Notification.error(`${acct.id} update failed: ${err.message}`);
                      }
                  };
                  AccountService.suspend({ id: acct.name, isSuspended: !!shouldSuspend }, cb);
              })
          },
          () => { }
      );
  }

  suspendUsers = (shouldSuspend) => {
      confirm(<div>{`Are you sure you wish to ${!!shouldSuspend ? "suspend" : "activate"} these users?`}</div>, {
          title: `User ${!!shouldSuspend ? "Suspension" : "Activation"} Confirmation`,
          okButtonText: "Yes",
          cancelButtonText: "No",
      }).then(
          () => {
              const refresh = _.debounce(this.props.loadParticipants, 1000);
              this.props.participants.filter(p => p.firm === this.props.firm.name).forEach(p => {
                  const cb = (err, response) => {
                      if (response) {
                          Notification.success(`${p.id} updated`);
                          refresh();
                      }

                      if (err) {
                          Notification.error(`${p.id} update failed: ${err.message}`);
                      }
                  };
                  ParticipantService.suspend({ name: p.name, isSuspended: !!shouldSuspend }, cb);
              })
          },
          () => { }
      );
  }

  selectTab = (selectedTab) => {
    let { id } = this.props.firm;

    switch (selectedTab) {
      case "profile":
        return this.context.router.history.push(`/firms/${id}`);
      case "users":
        return this.context.router.history.push(`/firms/${id}/users`);
      case "accounts":
        return this.context.router.history.push(`/firms/${id}/accounts`);
      case "association":
        return this.context.router.history.push(`/firms/${id}/association`);
      case "services":
        return this.context.router.history.push(`/firms/${id}/services`);
      default:
        console.error("No tab found!");
        break;
    }
  };

  onAutoRefresh = (value, cb) => {
    window.localStorage.setItem(this.autorefreshpositions, value)
    cb(value);
  }

  render() {
    let {
      onSubmitForm,
      onSubmitServicesForm,
      onSubmitServicesKeyForm,
      onEditForm,
      onEditServicesKeyForm,
      mode,
      decodedId,
      loadFirm,
      loadFirms,
      loadFirmServices,
      loadFirmServiceKeys,
      loadAffiliatedFirms,
      loadAssociatedFirms,
      loadAssociatedRFQFirms,
      loadAssociatedAgentFirms,
      loadAssociatedClearingHouses,
      updateAssociatedFirms,
      loadParticipants,
      loadAccounts,
      fetchingPositions,
      positions,
      accounts,
      firm,
      firmType,
      match,
      loadFirmAttributes,
      firmServices,
      enableAutoRefresh,
      reloadPositions,
    } = this.props;

    var form = null;
    const fs = firmServices.find((fs) => fs.id === match.params.firmServiceId);

    switch (mode) {
      case FormModes.view:
      case FormModes.edition:
        if (firm.name && firm.attributes === undefined)
          loadFirmAttributes(firm.name);
        form = (
          <div>
            <FirmHeader
              isAddButtonVisible={false}
              areTabsVisible={true}
              selectedTab="profile"
              handleOnClick={this.selectTab}
              firm={firm}
            />
            <FirmEditForm
              onEditForm={mode === FormModes.edition ? onEditForm : null}
            />
          </div>
        );
        break;
      case FormModes.creation:
        form = (
          <FirmForm
            title={"Create Firm"}
            isEditing={false}
            onSubmitForm={onSubmitForm}
            firmType={firmType}
          />
        );
        break;
      case FormModes.services:
        form = (
          <div>
            <FirmHeader
              buttonText="ADD NEW FIRM SERVICE"
              buttonClick={() => {
                return this.context.router.history.push(
                  `/firms/${decodedId}/services/newService`
                );
              }}
              isAddButtonVisible={hasWriteAccess()}
              areTabsVisible={true}
              selectedTab="services"
              handleOnClick={this.selectTab}
              firm={firm}
            />
            <FirmServicesGrid
              decodedId={decodedId}
              loadFirm={loadFirm}
              loadFirmServices={loadFirmServices}
            />
          </div>
        );
        break;
      case FormModes.serviceView:
      case FormModes.serviceEdit:
        form = (
          <div>
            <FirmHeader
              isAddButtonVisible={false}
              areTabsVisible={true}
              selectedTab="services"
              handleOnClick={this.selectTab}
              firm={firm}
            />
            <FirmServicesEditForm
              onEditForm={mode === FormModes.serviceEdit ? onEditForm : null}
            />
          </div>
        );
        break;
      case FormModes.serviceCreation:
        form = (
          <FirmServiceForm
            title={"Create Firm Service"}
            isEditing={false}
            onSubmitForm={onSubmitServicesForm}
          />
        );
        break;
      case FormModes.serviceKeys:
        form = (
          <div>
            <FirmHeader
              buttonText="Create NEW API KEY"
              buttonClick={() => {
                return this.context.router.history.push(
                  `/firms/${match.params.id}/services/${match.params.firmServiceId}/keys/new`
                );
              }}
              isAddButtonVisible={hasWriteAccess()}
              areTabsVisible={false}
            />
            <FirmServicesKeysGrid
              id={fs ? fs.id : ""}
              firmServiceId={fs ? fs.name : ""}
              loadFirmServiceKeys={loadFirmServiceKeys}
            />
          </div>
        );
        break;
      case FormModes.serviceKeysView:
      case FormModes.serviceKeysEdit:
        form = (
          <div>
            <FirmHeader isAddButtonVisible={false} areTabsVisible={false} />
            <FirmServicesKeysEditForm
              apiKeyId={match.params.apiKeyId}
              firmServiceId={fs ? fs.name : ""}
              loadFirmServiceKeys={loadFirmServiceKeys}
              onEditServicesKeyForm={
                mode === FormModes.serviceKeysEdit
                  ? onEditServicesKeyForm
                  : null
              }
            />
          </div>
        );
        break;

      case FormModes.serviceKeysCreation:
        form = (
          <FirmServicesKeysForm
            title={"Create API Keys"}
            isEditing={false}
            onSubmitForm={onSubmitServicesKeyForm}
            id={fs ? fs.id : "firm service not found"}
            firmServiceId={fs ? fs.name : ""}
            firm={firm}
          />
        );
        break;
      case FormModes.users:
        form = (
          <div>
            <FirmHeader
              buttonText="ADD NEW USER"
              buttonClick={() => {
                return this.context.router.history.push(
                  `/firms/${decodedId}/users/new`
                );
              }}
              isAddButtonVisible={hasWriteAccess()}
              areTabsVisible={true}
              selectedTab="users"
              handleOnClick={this.selectTab}
              handleOnSuspend={this.suspendUsers}
              firm={firm}
            />
            <FirmUsersGrid
              decodedId={decodedId}
              loadFirm={loadFirm}
              loadParticipants={loadParticipants}
            />
          </div>
        );
        break;
      case FormModes.accounts:
        form = (
          <div>
            <FirmHeader
              buttonText="ADD NEW ACCOUNT"
              buttonClick={() => {
                return this.context.router.history.push(
                  `/firms/${decodedId}/accounts/new`
                );
              }}
              isAddButtonVisible={hasWriteAccess()}
              areTabsVisible={true}
              selectedTab="accounts"
              handleOnClick={this.selectTab}
              handleOnSuspend={this.suspendAccounts}
              firm={firm}
            />
            <CheckboxGroup
              checked={window.localStorage.getItem(this.autorefreshpositions) === 'true'}
              onClick={(e) => {
                this.onAutoRefresh(e.target.checked, enableAutoRefresh);
              }}
              label={"Auto Reload Positions"}
            />
            <FirmAccountsGrid
              decodedId={decodedId}
              loadFirm={loadFirm}
              loadAccounts={loadAccounts}
              loadFirms={loadFirms}
            />
            <FieldGroupReadOnly id="positions" label="Account Positions" />
            <PositionsDataGrid data={positions} accounts={accounts} reload={reloadPositions} editable={false}/>
            <LoaderIcon show={fetchingPositions} />
          </div>
        );
        break;
      case FormModes.association:
        form = (
          <div>
            <FirmHeader
              isAddButtonVisible={false}
              areTabsVisible={true}
              selectedTab="association"
              handleOnClick={this.selectTab}
              firm={firm}
            />
            <FirmAssociations
              decodedId={decodedId}
              loadFirm={loadFirm}
              loadFirms={loadFirms}
              loadAffiliatedFirms={loadAffiliatedFirms}
              loadAssociatedFirms={loadAssociatedFirms}
              loadAssociatedRFQFirms={loadAssociatedRFQFirms}
              loadAssociatedAgentFirms={loadAssociatedAgentFirms}
              loadAssociatedClearingHouses={loadAssociatedClearingHouses}
              updateAssociatedFirms={updateAssociatedFirms}
            />
          </div>
        );
        break;

      default:
        console.error("Unsupported mode: ", mode);
        break;
    }
    return form;
  }
}

FirmDetails.propTypes = {
  mode: PropTypes.oneOf(Object.keys(FormModes)).isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  onEditForm: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(FirmDetails);
