import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import DataGridActions from '../core/data-grid/DataGridActions';
import {tsProtoObjToDate} from "../../actions/ptypes";
import {convertDateToString} from "../../modules/util";

const PodsDataGrid = ({
  data,
  onView,
}) => {
  let columns = [
    {
      Header: 'NAME ID',
      accessor: 'name',
      minWidth: 300,
      sortable: true,
      filterable: true,
    },
    {
      Header: 'PHASE',
      id: 'phase',
      accessor: d => d.phase + (!!d.reason ? " (" + d.reason + ")" : ""),
      sortable: true,
      filterable: true,
    },
    {
      Header: 'START TIME',
      id: 'startTime',
      accessor: d => !!d.startTime && !!d.startTime.seconds ? convertDateToString(tsProtoObjToDate(d.startTime), "YYYYMMDD-HH:mm:ss.SSS") : null,
      sortable: true,
      filterable: true,
    },
    {
      Header: '# RESTARTS',
      accessor: 'restartCount',
      sortable: false,
      filterable: false,
    },
  ];
  let containerStatuses = new Set();
  if(Array.isArray(data)) {
    data.forEach(elem => {
      if(Array.isArray(elem.conditionsMap)) {
        elem.conditionsMap.forEach(condition => {
          if (Array.isArray(condition) && condition.length > 0) {
            containerStatuses.add(condition[0]);
          }
        })
      }
    });
  }
  Array.from(containerStatuses).sort().forEach(status => columns.push({
    Header: status.toUpperCase(),
    id: status.toLowerCase(),
    accessor: d => {
      var sts = "";
      if (!!d && Array.isArray(d.conditionsMap)) {
        d.conditionsMap.forEach(condition => {
          if (Array.isArray(condition) && condition.length > 1 && condition[0] === status) {
            sts = condition[1];
          }
        })
      }
      return sts;
    },
    sortable: false,
    filterable: false,
  }))

  columns.push({
    Header: 'LOGS',
    maxWidth: 150,
    minWidth: 150,
    sortable: false,
    filterable: false,
    Cell: row => (
        <DataGridActions
            onView={() => { onView(row.original.name) }}
        >
        </DataGridActions>
    )
  });
  return (
      <div>
          <DataGrid
              data={data}
              columns={columns}
              filterable={true}
              defaultSortedField="name"
          >
          </DataGrid>
      </div>
  )
};

PodsDataGrid.propTypes = {
    data: PropTypes.array.isRequired,
    onView: PropTypes.func.isRequired,
};

export default PodsDataGrid;
