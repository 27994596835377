import {
  FETCH_FIRMS_START,
  FETCH_FIRMS_SUCCEED,
  FETCH_FIRMS_FAILED,
  FETCH_FIRM_START,
  FETCH_FIRM_SUCCEED,
  FETCH_FIRM_FAILED,
  FETCH_FIRM_SERVICES_START,
  FETCH_FIRM_SERVICES_SUCCEED,
  FETCH_FIRM_SERVICES_FAILED,
  FETCH_FIRM_SERVICE_KEYS_START,
  FETCH_FIRM_SERVICE_KEYS_SUCCEED,
  FETCH_FIRM_SERVICE_KEYS_FAILED,
  CLEAN_FIRM,
  SAVE_FIRM_START,
  SAVE_FIRM_SUCCEED,
  SAVE_FIRM_FAILED,
  CREATE_FIRM_START,
  UPDATE_FIRM_FAILED,
  UPDATE_FIRM_START,
  UPDATE_FIRM_SUCCEED,
  DELETE_FIRM_FAILED,
  DELETE_FIRM_SUCCEED,
  DELETE_FIRM_START,
  RESET_LOADING_FLAG,
  FETCH_ASSOCIATED_FIRMS_START,
  FETCH_ASSOCIATED_FIRMS_SUCCEED,
  FETCH_AFFILIATED_FIRMS_SUCCEED,
  FETCH_ASSOCIATED_RFQ_FIRMS_SUCCEED,
  FETCH_ASSOCIATED_AGENT_FIRMS_SUCCEED,
  FETCH_ASSOCIATED_CLEARING_HOUSES_SUCCEED,
  FETCH_ASSOCIATED_FIRMS_FAILED,
  UPDATE_ASSOCIATED_FIRMS_START,
  UPDATE_ASSOCIATED_FIRMS_SUCCEED,
  UPDATE_ASSOCIATED_FIRMS_FAILED,
  UPDATE_FIRM_ITEM,
  TOGGLE_SHOW_DELETED_FIRMS,
  RESET_FIRMS,
} from "../constants/firmTypes";
import { RESET } from "../constants/appTypes";
import Firm from "../entities/Firm";

const initialState = {
  fetchingFirms: false,
  fetchingFirm: false,
  fetchingFirmServices: false,
  fetchingFirmServiceKeys: false,
  savingFirm: false,
  isCreating: false,
  firms: [],
  firmServices: [],
  firmServiceKeys: [],
  firm: new Firm(),
  affiliatedFirms: [],
  associatedFirms: [],
  associatedRFQFirms: [],
  associatedAgentFirms: [],
  associatedClearingHouses: [],
  isUpdating: false,
  fetchingAssociatedFirms: false,
  updatingAssociatedFirms: false,
  showDeletedFirms: false,
};

const firms = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FIRMS_START:
      return {
        ...state,
        fetchingFirms: true,
      };
    case FETCH_FIRMS_SUCCEED:
      return {
        ...state,
        fetchingFirms: false,
        firms: action.payload,
      };
    case FETCH_FIRMS_FAILED:
      return {
        ...state,
        fetchingFirms: false,
        firms: [],
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    case RESET_FIRMS:
      return {
        ...state,
        fetchingFirms: false,
        firms: [],
      };
    case FETCH_FIRM_START:
      return {
        ...state,
        fetchingFirm: true,
      };
    case FETCH_FIRM_SUCCEED:
      return {
        ...state,
        fetchingFirm: false,
        firm: action.payload,
      };
    case FETCH_FIRM_FAILED:
      return {
        ...state,
        fetchingFirm: false,
      };
    case FETCH_FIRM_SERVICES_START:
      return {
        ...state,
        fetchingFirmServices: true,
      };
    case FETCH_FIRM_SERVICES_SUCCEED:
      return {
        ...state,
        fetchingFirmServices: false,
        firmServices: action.payload,
      };
    case FETCH_FIRM_SERVICES_FAILED:
      return {
        ...state,
        fetchingFirmServices: false,
      };
    case FETCH_FIRM_SERVICE_KEYS_START:
      return {
        ...state,
        fetchingFirmServiceKeys: true,
      };
    case FETCH_FIRM_SERVICE_KEYS_SUCCEED:
      return {
        ...state,
        fetchingFirmServiceKeys: false,
        firmServiceKeys: action.payload,
      };
    case FETCH_FIRM_SERVICE_KEYS_FAILED:
      return {
        ...state,
        fetchingFirmServiceKeys: false,
      };
    case CLEAN_FIRM:
      return {
        ...state,
        firm: new Firm(),
      };
    case SAVE_FIRM_START:
      return {
        ...state,
        isUpdating: true,
      };
    case SAVE_FIRM_SUCCEED:
      return {
        ...state,
        isUpdating: false,
      };
    case SAVE_FIRM_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case CREATE_FIRM_START:
      return {
        ...state,
        isCreating: action.value,
      };
    case UPDATE_FIRM_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case UPDATE_FIRM_START:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_FIRM_SUCCEED:
      return {
        ...state,
        isUpdating: false,
      };
    case DELETE_FIRM_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case DELETE_FIRM_START:
      return {
        ...state,
        isUpdating: true,
      };
    case DELETE_FIRM_SUCCEED:
      return {
        ...state,
        isUpdating: false,
      };
    case RESET_LOADING_FLAG:
      return {
        ...state,
        isUpdating: false,
      };
    case FETCH_ASSOCIATED_FIRMS_START:
      return {
        ...state,
        fetchingAssociatedFirms: true,
      };
    case FETCH_ASSOCIATED_FIRMS_SUCCEED:
      return {
        ...state,
        fetchingAssociatedFirms: false,
        associatedFirms: action.payload,
      };
    case FETCH_ASSOCIATED_RFQ_FIRMS_SUCCEED:
      return {
        ...state,
        fetchingAssociatedFirms: false,
        associatedRFQFirms: action.payload,
      };
    case FETCH_ASSOCIATED_AGENT_FIRMS_SUCCEED:
      return {
        ...state,
        fetchingAssociatedFirms: false,
        associatedAgentFirms: action.payload,
      };
    case FETCH_AFFILIATED_FIRMS_SUCCEED:
      return {
        ...state,
        fetchingAssociatedFirms: false,
        affiliatedFirms: action.payload,
      };
    case FETCH_ASSOCIATED_CLEARING_HOUSES_SUCCEED:
      return {
        ...state,
        fetchingAssociatedFirms: false,
        associatedClearingHouses: action.payload,
      };
    case FETCH_ASSOCIATED_FIRMS_FAILED:
      return {
        ...state,
        fetchingAssociatedFirms: false,
        firms: [],
      };
    case UPDATE_ASSOCIATED_FIRMS_START:
      return {
        ...state,
        updatingAssociatedFirms: true,
      };
    case UPDATE_ASSOCIATED_FIRMS_SUCCEED:
      return {
        ...state,
        updatingAssociatedFirms: false,
      };
    case UPDATE_ASSOCIATED_FIRMS_FAILED:
      return {
        ...state,
        updatingAssociatedFirms: false,
      };
    case UPDATE_FIRM_ITEM:
      return {
        ...state,
        firm: new Firm({
          ...state.firm,
          [action.key]: action.value,
        }),
      };
    case TOGGLE_SHOW_DELETED_FIRMS:
      return {
        ...state,
        showDeletedFirms: !state.showDeletedFirms,
      };
    default:
      return state;
  }
};

export default firms;
