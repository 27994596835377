import React from "react";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import GridActions from '../core/data-grid/GridActions';
import {GridActionEdit, GridActionView} from "../core/data-grid/GridAction";
import { useMemo } from "react";
import { GridActionMenu } from "../core/menu-items/menu-items";

const ParticipantDataGrid = ({ data, onView, onEdit, showGroups, firms }) => {
  const gridCtxMenu = useMemo(() => GridActionMenu, []);

  const columns = [
    {
      Header: "GROUPS",
      id: "groups",
      show: !!showGroups,
      accessor: (d) => d.groups.join(", "),
      maxWidth: 240,
      minWidth: 240,
    },
    {
      Header: "NAME",
      accessor: "displayName",
    },
    {
      Header: "ROLE",
      id: "role",
      accessor: (d) => (d.role ? d.role.name : null),
      maxWidth: 120,
      minWidth: 120,
    },
    {
      Header: "FIRM",
      id: "firm",
      accessor: (d) => d.firm ? firms.firms.find((firm) => firm.name === d.firm).displayName : null,
      maxWidth: 120,
      minWidth: 120,
    },
    {
      Header: "STATE",
      id: "state",
      accessor: (d) => (d.isSuspended ? "Suspended" : "Active"),
      maxWidth: 120,
      minWidth: 120,
    },
    {
      maxWidth: 120,
      minWidth: 120,
      sortable: false,
      Cell: (row) => (
        <GridActions>
          <GridActionView contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onView(row.original.name, actionContext); }} />
          <GridActionEdit contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onEdit(row.original.name, actionContext); }} />
        </GridActions>
      ),
    },
  ];
  return (
    <div>
      <DataGrid data={data} columns={columns} filterable={false} />
    </div>
  );
};

ParticipantDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  showGroups: PropTypes.bool,
};

export default ParticipantDataGrid;
