export default class ParticipantGroupFormItem {
  constructor(item) {
    if (item) {
      this.id = item.id;
      this.isSuspended = item.isSuspended;
      this.firm=item.firm;
      this.firmDisplayName = item.firmDisplayName;
      return;
    }
    this.id = null;
    this.isSuspended = null;
    this.firm=null;
    this.firmDisplayName = null;
  }
}