import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import FieldGroup from "../../../core/form/FieldGroup";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import {validateField, NUMBER_TYPE, DATE_TYPE, ARRAY_TYPE} from '../../instrumentFormUtils'
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import DateGroup from "../../../core/form/DateGroup";
import { FormModes } from "../../../../constants/enumerations";
export const NDFInfo = ({ values,
                              setFieldValue,
                              touched,
                              handleBlur,
                              errors,
                              mode,
                              onChange,
                            metadata }) => {
    const [open, setOpen] = useState(false);
    const isViewing = mode === FormModes.view;

    return (
        <>
            <div onClick={() => setOpen(!open)}>
                <Row className="accordian-style">
                    <Col lg={11} xs={11} md={11}>
                        <h6><strong>Non Deliverable Forward Information</strong></h6>
                    </Col>
                    <Col>
                        <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
                    </Col>
                </Row>
            </div>
            <Collapse in={open}>
                <div className="collapse-content">
                    <Row>
                        <Col lg={3} xs={3} md={3}>
                            <h6>Attributes</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="tenor"
                                name="tenor"
                                type="textarea"
                                label="Tenor"
                                setFieldValue={setFieldValue}
                                value={values.tenor}
                                errors={errors}
                                touched={touched}
                                onChange={onChange}
                                onBlur={handleBlur}
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <DropdownListGroup
                                id="sideConvention"
                                name="sideConvention"
                                label="Side Convention"
                                value={values.sideConvention}
                                enabled={values.isEditable}
                                errors={errors}
                                onChange={(e) => setFieldValue("sideConvention", e.id)}
                                data={InstrumentStaticData.SideConventionOptions}
                                textField="name"
                                valueField="id"
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="forwardStartingPeriod"
                                name="forwardStartingPeriod"
                                type="textarea"
                                label="Forward Starting Period"
                                setFieldValue={setFieldValue}
                                value={values.forwardStartingPeriod}
                                errors={errors}
                                touched={touched}
                                onChange={onChange}
                                onBlur={handleBlur}
                                plaintext={isViewing}
                            />
                        </Col>

                        <Col lg={3} xs={3} md={3}>
                            <DropdownListGroup
                                id="forwardStartingPeriodTimePeriod"
                                name="forwardStartingPeriodTimePeriod"
                                label="Forward Starting Period Time Period"
                                value={values.forwardStartingPeriodTimePeriod}
                                enabled={values.isEditable}
                                errors={errors}
                                onChange={(e) => setFieldValue("forwardStartingPeriodTimePeriod", e.id)}
                                data={InstrumentStaticData.TimePeriodOptions}
                                textField="name"
                                valueField="id"
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="currency1"
                                name="currency1"
                                as="select"
                                label="Currency 1"
                                value={values.currency1}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={onChange}
                                options={
                                    metadata && metadata.currencies
                                        ? Object.keys(metadata.currencies)
                                        : []
                                }
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="currency2"
                                name="currency2"
                                as="select"
                                label="Currency 2"
                                value={values.currency2}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={onChange}
                                options={
                                    metadata && metadata.currencies
                                        ? Object.keys(metadata.currencies)
                                        : []
                                }
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="exchangedCurrency1"
                                name="exchangedCurrency1"
                                as="select"
                                label="Exchanged Currency 1"
                                value={values.exchangedCurrency1}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={onChange}
                                options={
                                    metadata && metadata.currencies
                                        ? Object.keys(metadata.currencies)
                                        : []
                                }
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="exchangedCurrency2"
                                name="exchangedCurrency2"
                                as="select"
                                label="Exchanged Currency 2"
                                value={values.exchangedCurrency2}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={onChange}
                                options={
                                    metadata && metadata.currencies
                                        ? Object.keys(metadata.currencies)
                                        : []
                                }
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="exchangeRateCurrency1"
                                name="exchangeRateCurrency1"
                                as="select"
                                label="Exchange Rate Currency 1"
                                value={values.exchangeRateCurrency1}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={onChange}
                                options={
                                    metadata && metadata.currencies
                                        ? Object.keys(metadata.currencies)
                                        : []
                                }
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="exchangeRateCurrency2"
                                name="exchangeRateCurrency2"
                                as="select"
                                label="Exchange Rate Currency 2"
                                value={values.exchangeRateCurrency2}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={onChange}
                                options={
                                    metadata && metadata.currencies
                                        ? Object.keys(metadata.currencies)
                                        : []
                                }
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <DateGroup
                                id="valuationDate"
                                name="valuationDate"
                                label="Valuation Date"
                                value={values.valuationDate}
                                maxDate={null}
                                dropUp={true}
                                enabled={values.isEditable}
                                errors={errors}
                                touched={touched}
                                onChange={(e) => setFieldValue("valuationDate", e)}
                                onBlur={handleBlur}
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <DateGroup
                                id="fixingDate"
                                name="fixingDate"
                                label="Fixing Date"
                                value={values.fixingDate}
                                maxDate={null}
                                dropUp={true}
                                enabled={values.isEditable}
                                errors={errors}
                                touched={touched}
                                onChange={(e) => setFieldValue("fixingDate", e)}
                                onBlur={handleBlur}
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <DropdownListGroup
                                id="exchangeRateQuoteBasis"
                                name="exchangeRateQuoteBasis"
                                label="Exchange Rate Quote Basis"
                                value={values.exchangeRateQuoteBasis}
                                enabled={values.isEditable}
                                errors={errors}
                                onChange={(e) => setFieldValue("exchangeRateQuoteBasis", e.id)}
                                data={InstrumentStaticData.QuoteBasisOptions}
                                textField="name"
                                valueField="id"
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <DropdownListGroup
                                id="fixingQuoteBasis"
                                name="fixingQuoteBasis"
                                label="Fixing Quote Basis"
                                value={values.fixingQuoteBasis}
                                enabled={values.isEditable}
                                errors={errors}
                                onChange={(e) => setFieldValue("fixingQuoteBasis", e.id)}
                                data={InstrumentStaticData.QuoteBasisOptions}
                                textField="name"
                                valueField="id"
                                plaintext={isViewing}
                            />
                        </Col>

                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="settlementCurrency"
                                name="settlementCurrency"
                                as="select"
                                label="Settlement Currency"
                                value={values.settlementCurrency}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={onChange}
                                options={["USD"]}
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <DropdownListGroup
                                id="tenorTimePeriod"
                                name="tenorTimePeriod"
                                label="Tenor Time Period"
                                value={values.tenorTimePeriod}
                                enabled={values.isEditable}
                                errors={errors}
                                onChange={(e) => setFieldValue("tenorTimePeriod", e.id)}
                                data={InstrumentStaticData.TimePeriodOptions}
                                textField="name"
                                valueField="id"
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="fixingCurrency1"
                                name="fixingCurrency1"
                                as="select"
                                label="Fixing Currency 1"
                                value={values.fixingCurrency1}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={onChange}
                                options={
                                    metadata && metadata.currencies
                                        ? Object.keys(metadata.currencies)
                                        : []
                                }
                                plaintext={isViewing}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup
                                id="fixingCurrency2"
                                name="fixingCurrency2"
                                as="select"
                                label="Fixing Currency 2"
                                value={values.fixingCurrency2}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={onChange}
                                options={
                                    metadata && metadata.currencies
                                        ? Object.keys(metadata.currencies)
                                        : []
                                }
                                plaintext={isViewing}
                            />
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </>
    )
}

export const ValidateNDFInfo = (values, errors) => {
    errors = validateField(values, errors,"tenor", "Tenor", NUMBER_TYPE);
    errors = validateField(values, errors,"forwardStartingPeriod", "Forward Starting Period", NUMBER_TYPE);
    errors = validateField(values, errors,"forwardStartingPeriodTimePeriod", "Forward Starting Period Time Period", ARRAY_TYPE);
    errors = validateField(values, errors,"sideConvention", "Side Convention", ARRAY_TYPE);
    errors = validateField(values, errors,"currency1", "Currency 1", ARRAY_TYPE);
    errors = validateField(values, errors,"currency2", "Currency 2", ARRAY_TYPE);
    errors = validateField(values, errors,"exchangedCurrency1", "Exchanged Currency 1", ARRAY_TYPE);
    errors = validateField(values, errors,"exchangedCurrency2", "Exchanged Currency 2", ARRAY_TYPE);
    errors = validateField(values, errors,"valuationDate", "Valuation Date", DATE_TYPE);
    errors = validateField(values, errors,"fixingDate", "Fixing Date", DATE_TYPE);
    errors = validateField(values, errors,"exchangeRateCurrency1", "Exchange Rate Currency 1", ARRAY_TYPE);
    errors = validateField(values, errors,"exchangeRateCurrency2", "Exchange Rate Currency 2", ARRAY_TYPE);
    errors = validateField(values, errors,"exchangeRateQuoteBasis", "Exchange Rate Quote Basis", ARRAY_TYPE);
    errors = validateField(values, errors,"fixingQuoteBasis", "Fixing Quote Basis", ARRAY_TYPE);
    errors = validateField(values, errors,"settlementCurrency", "Settlement Currency", ARRAY_TYPE);
    errors = validateField(values, errors,"fixingCurrency1", "Fixing Currency 1", ARRAY_TYPE);
    errors = validateField(values, errors,"fixingCurrency2", "Fixing Currency 2", ARRAY_TYPE);
    errors = validateField(values, errors,"tenorTimePeriod", "Tenor Time Period", ARRAY_TYPE);
    return errors;
}