import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import confirm from "../modules/confirmDialog";
import Notification from "../modules/notifications";
import CommissionForm from "../components/account/CommissionForm";
import CommissionList from "../components/account/CommissionList";
import { CommissionMapper } from "../modules/mappers/commissionMapper"
import { CommissionService } from "../services/CommissionService"
import { ListCommissions } from '../actions/commissions'
import { FormModes } from "../constants/enumerations";
import ButtonMain from "../components/core/form/ButtonMain";
import { Row, Col } from "react-bootstrap";
import {LoadParticipants} from "../actions/participants";

function mapStateToProps(state) {
  return {
    fetchingAccount: state.accounts.fetchingAccount,
    firms: state.firms.firms,
    account: state.accounts.account,
    accounts: state.accounts.accounts,
    commissions: state.commissions,
  };
}

const mapDispatchToProps = (dispatch) => ({
  listCommissions: () => {
    dispatch(ListCommissions());
  },
  loadParticipants: () => {
    dispatch(LoadParticipants());
  },
});

class CommissionContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { mode: FormModes.listing };
  }

  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    this.props.loadParticipants();
    if (this.state.mode === FormModes.listing) {
      this.props.listCommissions();
    }
  }

  getFormMode = () => {
    let commId = this.context.router.route.match.params.commid;
    let { pathname } = this.props.location;
    if (commId && pathname.indexOf("/edit") > 0) return FormModes.whitelist.edition;
    if (pathname.indexOf("/new") > 0) return FormModes.creation;
    return FormModes.listing;
  }

  onDeleteCommission = (data) => {
    confirm("Are you sure you want to delete the commission?", {
      title: "Account Commission",
      okButtonText: "Delete",
      cancelButtonText: "No",
    }).then(
      () => {
        let rule = CommissionMapper.mapToCommissionRule(data);
        CommissionService.deleteCommission(rule, (err, resp) => {
          if (resp) {
            Notification.success("Commission deleted successfully.");
            this.props.listCommissions();
          }

          if (err) {
            Notification.error(`Error when deleting commission: \n ${err.message}`);
          }
        });
      },
      () => { }
    );
  };

  onNewCommission = () => {
    this.setState({ mode: FormModes.creation })
  }

  onEditCommission = (commission) => {
    this.setState({ mode: FormModes.edition, commission })
  }

  onViewCommission = (commission) => {
    this.setState({ mode: FormModes.view, commission })
  }

  setCommission = (data) => {
    let assignment = CommissionMapper.mapToCommission(data);
    CommissionService.setCommission(assignment, (err, resp) => {
      if (resp) {
        Notification.success("Commission applied successfully.");
        this.listAndRefresh();
      }

      if (err) {
        Notification.error(`Error while setting commission: \n ${err.message}`);
      }
    });
  }

  listAndRefresh = () => {
    this.props.listCommissions();
    this.setState({ mode: FormModes.listing });
  }

  onCancelFormAction = () => {
    this.setState({ mode: FormModes.listing });
  }

  render() {
    let mode = this.state.mode;

    return (
      <>
        {(mode === FormModes.listing) && (
          <>
            <div>
              <Row>
                <Col>
                  <div style={{ height: "75px" }}>
                    <ButtonMain
                      text="New Commission"
                      type="button"
                      onClick={this.onNewCommission}
                      customClassName="btn-main-header"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CommissionList onCancel={this.onCancelFormAction} onView={this.onViewCommission} onEdit={this.onEditCommission} onRemove={this.onDeleteCommission} {...this.props} />
                </Col>
              </Row>
            </div>
          </>
        )}

        {(mode === FormModes.creation || mode === FormModes.edition || mode === FormModes.view) &&
          <CommissionForm mode={mode} commission={this.state.commission} onSetCommission={this.setCommission} {...this.props} />
        }
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommissionContainer)
);
