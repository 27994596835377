
import {
  FETCH_PRODUCTSLIST_START,
  FETCH_PRODUCTSLIST_SUCCEED,
  FETCH_PRODUCTSLIST_FAILED
} from '../constants/productListTypes';

export const FetchProductsListStart = () => ({
  type: FETCH_PRODUCTSLIST_START
});

export const FetchProductsListSucceed = (products) => ({
  type: FETCH_PRODUCTSLIST_SUCCEED,
  payload: products
});

export const FetchProductsListFailed = (error) => ({
  type: FETCH_PRODUCTSLIST_FAILED,
  payload: error
});