import React from "react";
import PropTypes from "prop-types";
import ButtonMain from "../core/form/ButtonMain";
import { Row, Col } from "react-bootstrap";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { Link } from "react-router-dom";
import ButtonSecondary from "../core/form/ButtonSecondary";

const FirmHeader = ({
  buttonText,
  buttonClick,
  isAddButtonVisible,
  areTabsVisible,
  handleOnClick,
  selectedTab,
  firm,
  handleOnSuspend,
}) => {
  const linkClass = "link-with-router link-tab";
  const selectedLinkClass = `${linkClass} link-tab-selected`;
  const profileLinkClass =
    selectedTab === "profile" ? selectedLinkClass : linkClass;
  const usersLinkClass =
    selectedTab === "users" ? selectedLinkClass : linkClass;
  const accountsLinkClass =
    selectedTab === "accounts" ? selectedLinkClass : linkClass;
  const associationsLinkClass =
    selectedTab === "association" ? selectedLinkClass : linkClass;
  const servicesLinkClass =
    selectedTab === "services" ? selectedLinkClass : linkClass;

  let firmType = null;

  if (firm && firm.firmType) {
    firmType = {
      participantFirm: firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT,
      clearingMember: firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_MEMBER,
      clearingHouse: firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_HOUSE,
      supervisor: firm.firmType.id === FirmType.FIRM_TYPE_SUPERVISOR,
      agent: firm.firmType.id === FirmType.FIRM_TYPE_AGENT,
    };
  }

  return (
    <div>
      <Row className="page-header">
        {areTabsVisible ? (
          <Col lg={6} xs={6} md={6}>
            <h2
              className={profileLinkClass}
              onClick={() => handleOnClick("profile")}
            >
              <Link to={`/firms/${firm.id}`} className="tabLink">Firm Profile</Link>
            </h2>
            {firmType && (firmType.participantFirm || firmType.clearingMember || firmType.supervisor || firmType.agent) ? (
              <h2
                className={usersLinkClass}
                onClick={() => handleOnClick("users")}
              >
               <Link to={`/firms/${firm.id}/users`} className="tabLink">Users</Link>
              </h2>
            ) : null}
            {firmType && !firmType.supervisor && !firmType.agent ? (
              <h2
                className={accountsLinkClass}
                onClick={() => handleOnClick("accounts")}
              >
                <Link to={`/firms/${firm.id}/accounts`} className="tabLink">Accounts</Link>
              </h2>
            ) : null}
            {firmType && !firmType.supervisor ? (
              <h2
                className={associationsLinkClass}
                onClick={() => handleOnClick("association")}
              >
                <Link to={`/firms/${firm.id}/association`} className="tabLink">Firm Associations</Link>
              </h2>
            ) : null}

            {firmType && firmType.supervisor ? (
              <h2
                className={servicesLinkClass}
                onClick={() => handleOnClick("services")}
              >
                <Link to={`/firms/${firm.id}/services`} className="tabLink">Firm Services</Link>
              </h2>
            ) : null}
          </Col>
        ) : null}
        <Col lg={areTabsVisible ? 6 : 12} xs={areTabsVisible ? 6 : 12} md={areTabsVisible ? 6 : 12}>
          {isAddButtonVisible ? (<div style={{ float: "right" }}>
              {!!handleOnSuspend && <ButtonSecondary
                  text="Suspend All"
                  type="button"
                  onClick={() => handleOnSuspend(true)}
                  style={{"margin-right": "15px"}}
              />}
              {!!handleOnSuspend && <ButtonSecondary
                  text="Activate All"
                  type="button"
                  onClick={() => handleOnSuspend(false)}
                  style={{"margin-right": "30px"}}
              />}
              <ButtonMain
                text={buttonText}
                type="button"
                onClick={buttonClick}
              />
          </div>) : null}
        </Col>
      </Row>
    </div>
  );
};

FirmHeader.defaultProps = {
  isParticipantsSelected: false,
  isParticipantGroupsSelected: false,
  isFirmSelected: false,
  isAddButtonVisible: false,
  isAccountsSelected: false,
  selectedTab: "profile",
};

FirmHeader.propTypes = {
  isAddButtonVisible: PropTypes.bool,
  isParticipantsSelected: PropTypes.bool,
  isParticipantGroupsSelected: PropTypes.bool,
  isFirmSelected: PropTypes.bool,
  isAccountsSelected: PropTypes.bool,
};

export default FirmHeader;
