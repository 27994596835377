import React, { Component } from "react";
import Dialog from "../core/dialog/index";
import { Row, Container } from "react-bootstrap";
import ReactJson from 'react-json-view'
import {isNullOrUndefined} from "../../modules/util";
import { AdminRequestStatus } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";

class RequestDetails extends Component {
  render() {
    let { requestData, onDialogClose, onApprove, onReject } = this.props;
    const isPending = !isNullOrUndefined(requestData) && !isNullOrUndefined(requestData.status) && requestData.status.id === AdminRequestStatus.ADMIN_REQUEST_STATUS_PENDING;
    return (
      <div>
        <Dialog
          show={true}
          title={isPending ? "Approve or reject this request?" : "Admin Request Details"}
          onClose={() => onDialogClose()}
        >
          <div className="m-3">
            <Container>
              <Row>
                <label className="font-size-20 font-weight-bold">Requesting User</label>
              </Row>
              <Row>
                <span>{requestData.user}</span>
              </Row>
              <Row>
                <label className="font-size-20 mt-3 font-weight-bold">Request Name</label>
              </Row>
              <Row>
                <span>{requestData.name}</span>
              </Row>
              <Row className="mt-4">
                <label className="font-size-20 font-weight-bold">Payload</label>
              </Row>
              <Row>
                {requestData.message && (
                      <ReactJson src={JSON.parse(requestData.message)} theme="monokai" displayDataTypes={false}  />
                    )}
              </Row>
              {!isPending && <Row className="mt-4">
                <label className="font-size-20 font-weight-bold">Return Message</label>
              </Row>}
              {!isPending && <Row>
                {requestData.returnMessage && (
                      <ReactJson src={JSON.parse(requestData.returnMessage)} theme="monokai" displayDataTypes={false}  />
                    )}
              </Row>}
              {isPending && <Row className="detailFooter mt-4">
                <div>
                  <div>
                    <button
                      type="submit"
                      className={"Filter-Button"}
                      style={{textTransform: "uppercase", background: "#008000"}}
                      onClick={onApprove}>Approve
                    </button>
                    <span>&nbsp;</span>
                    <button
                      className={"Filter-Button"}
                      style={{textTransform: "uppercase", background: "#ff0000"}}
                      onClick={onReject}>Reject
                    </button>
                  </div>
                </div>
              </Row>}
            </Container>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default RequestDetails;
