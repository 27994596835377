import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import DropdownListGroup from "../core/form/DropdownListGroup";
import UsersStaticData from "../../modules/usersStaticData"
import FieldGroup from "../core/form/FieldGroup";
import Filter from "../core/filter/Filter";

function mapStateToProps(state) {
    return {
        firms: state.firms,
    };
}

const UsersFilter = (props) => {
    const defaultFilters = {
        firm: null,
        userName: null,
        userState: null,
        role: null
    }

    const [firms, setFirms] = useState([]);
    const [roles, setRoles] = useState([]);
    const [userStates, setUserStates] = useState([]);
    const [currentFilterItems, setCurrentFilterItems] = useState(defaultFilters);

    useEffect(() => {
        if (!!props.firms && !!props.firms.firms) {
            setFirms(props.firms.firms.map(firm => { return { id: firm.name, displayName: firm.displayName } }));
        }

        setRoles(UsersStaticData.ParticipantRoles([0]));

        setUserStates([{ id: "", name: "" }, ...UsersStaticData.ParticipantStates()]);

    }, [props.firms.firms]);

    const onReset = () => {
        setCurrentFilterItems(defaultFilters);
        props.onApplyFilter("", "", "", "");
    }

    const onApply = () => {
        if(props.onApplyFilter){
          props.onApplyFilter(currentFilterItems.userName, currentFilterItems.userState, currentFilterItems.role, currentFilterItems.firm);
        }
    }

    const onRemoveFilterItem = (filterItemId) => {
        let filterItems = { ...currentFilterItems };

        if (typeof filterItemId === 'object' && filterItemId.hasOwnProperty("id")) {
            delete filterItems[filterItemId.id];
        }
        else {
            delete filterItems[filterItemId];
        }

        setCurrentFilterItems(filterItems);
        props.onApplyFilter(filterItems.userName, filterItems.userState, filterItems.role, filterItems.firm);
    };

    const handleOnChange = (key, value) => {
        let updatedValue = {...currentFilterItems};
        updatedValue[key] = value;
        setCurrentFilterItems(currentFilterItems => ({
            ...currentFilterItems,
            ...updatedValue
          }));
    }

    return (
        <Filter
            className="instruments-filter"
            onApply={onApply}
            onReset={onReset}
            onRemoveFilterItem={onRemoveFilterItem}
            filterItems={props.filters}
            onFlip={() => {}}
        >
            <div className="with-callback">
                <Row>
                    <Col lg={4} xs={4} md={4}>
                        <FieldGroup
                            id="userName"
                            name="userName"
                            type="text"
                            label="User Name"
                            value={currentFilterItems.userName && currentFilterItems.userName.value}
                            setFieldValue={() => { }}
                            placeholder="Partial or complete User Name."
                            onChange={(e) => { handleOnChange('userName', {
                                id: "userName",
                                value: e.target.value,
                                text: "User Name",
                                name: e.target.value }); 
                            }}
                        />
                    </Col>
                    <Col lg={4} xs={4} md={4}>
                        <DropdownListGroup
                            id="userState"
                            name="userState"
                            label="User State"
                            value={currentFilterItems.userState && currentFilterItems.userState.userState}
                            onChange={(e) => { handleOnChange('userState', {
                                id: "userState",
                                value: e.name,
                                text: "User State",
                                name: e.name,
                                userState: e.id
                               });
                            }}
                            data={userStates}
                        ></DropdownListGroup>
                    </Col>
                    <Col lg={4} xs={4} md={4}>
                        <DropdownListGroup
                            id="userRole"
                            name="userRole"
                            label="User Role"
                            value={currentFilterItems.role && currentFilterItems.role.userRole}
                            onChange={(e) => { handleOnChange('role', {
                                id: "role",
                                value: e.name,
                                text: "User Role",
                                name: e.name,
                                userRole: e.id
                               }); 
                            }}
                            data={roles}
                            showFilter={true}
                        ></DropdownListGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} xs={4} md={4}>
                        <DropdownListGroup
                            id="firmId"
                            name="firmId"
                            label="Firm"
                            key="id"
                            textField="displayName"
                            valueField="id"
                            value={currentFilterItems.firm && currentFilterItems.firm.firm}
                            onChange={(e) => { handleOnChange('firm', {
                                id: "firm",
                                value: e.displayName,
                                text: "Firm",
                                name: e.displayName,
                                firm: e.id
                               });
                            }}
                            data={firms}
                            showFilter={true}
                        ></DropdownListGroup>
                    </Col>
                </Row>
            </div>
        </Filter>
    );
}

export default connect(mapStateToProps, null)(UsersFilter);
