import slugify from "slugify";

const {
  Participant,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb.js");

export default class ParticipantMapper {
  static mapToParticipant(data) {
    var participantToSave = new Participant();
    participantToSave.setId(
      data.userId ? data.userId : slugify(data.displayName)
    );
    participantToSave.setDisplayName(data.displayName);
    if (data.role) {
      participantToSave.setRole(data.role.id);
    }
    var groupList = [];
    if (data.groups) {
      for (var i = 0; data.groups.length > i; i++) {
        groupList.push(data.groups[i]);
      }
      participantToSave.setGroupIdsList(groupList);
    }
    if (data.firm) {
      participantToSave.setFirm(`${data.firm}`);
    }
    if (data.serviceUser) {
      participantToSave.setServiceUser(data.serviceUser);
    }

    if (
      !!data.aliases &&
      Array.isArray(data.aliases) &&
      data.aliases.length > 0
    ) {
      participantToSave.clearAliasesMap();

      data.aliases.forEach((alias) => {
        participantToSave.getAliasesMap().set(alias.option, alias.alias);
      });
    }
    if (data.name) {
      participantToSave.setName(data.name);
    }

    return participantToSave;
  }
}
