export const RAISE_ALERT_START = "RAISE_ALERT_START";
export const RAISE_ALERT_SUCCESS = "RAISE_ALERT_SUCCESS";
export const RAISE_ALERT_FAILED = "RAISE_ALERT_FAILED";

export const UPDATE_ALERT_START = "UPDATE_ALERT_START";
export const UPDATE_ALERT_SUCCESS = "UPDATE_ALERT_SUCCESS";
export const UPDATE_ALERT_FAILED = "UPDATE_ALERT_FAILED";

export const LIST_ALERTS_START = "LIST_ALERTS_START"
export const LIST_ALERTS_SUCCESS = "LIST_ALERTS_SUCCESS"
export const LIST_ALERTS_FAILED = "LIST_ALERTS_FAILED"

export const DOWNLOAD_ALERTS_START = "DOWNLOAD_ALERTS_START"
export const DOWNLOAD_ALERTS_SUCCESS = "DOWNLOAD_ALERTS_SUCCESS"
export const DOWNLOAD_ALERTS_FAILED = "DOWNLOAD_ALERTS_FAILED"

export const HAS_ALERTS_SUCCESS = "HAS_ALERTS_SUCCESS"
export const HAS_ALERTS_FAILED = "HAS_ALERTS_FAILED"