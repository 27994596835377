import {
  FETCH_TIME_SALES_START,
  FETCH_TIME_SALES_SUCCEED,
  FETCH_TIME_SALES_FAILED,
  REFRESH_TIME_SALES_ITEMS,
  RESET_TIME_SALES_TOKENS,
  FETCH_TIME_SALES_REPORT_FAILED,
  FETCH_TIME_SALES_REPORT_SUCCEED,
  FETCH_TIME_SALES_REPORT_START,
  TRADE_ITEM
} from '../constants/timeSalesTypes';
import {
  RESET,
} from '../constants/appTypes';

const initialState = {
  fetchingData: false,
  pageToken: null,
  nextPageToken: null,
  items: [],
  trade: null
}

const timeSales = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TIME_SALES_START:
      return {
        ...state,
        fetchingData: true,
        items: []
      };
    case FETCH_TIME_SALES_SUCCEED:
      return {
        ...state,
        fetchingData: false
      };
    case FETCH_TIME_SALES_FAILED:
      return {
        ...state,
        fetchingData: false,
        items: []
      };
    case FETCH_TIME_SALES_REPORT_START:
      return {
        ...state,
        fetchingData: true,
      };
    case FETCH_TIME_SALES_REPORT_SUCCEED:
      return {
        ...state,
        fetchingData: false
      };
    case FETCH_TIME_SALES_REPORT_FAILED:
      return {
        ...state,
        fetchingData: false,
      };
    case RESET_TIME_SALES_TOKENS:
      return {
        ...state,
        tokens: {},
        pageToken: null,
        nextPageToken: null,
      };
    case REFRESH_TIME_SALES_ITEMS:
      return {
        ...state,
        items: action.payload,
        pageToken: action.pageToken,
        nextPageToken: action.nextPageToken,
        tokens: { ...state.tokens, [action.pageToken]: action.nextPageToken },
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;

    case TRADE_ITEM:
      return {
        ...state,
        trade: action.payload
      }
    default:
      return state;
  }
}

export default timeSales;
