import { tsProtoToDate } from '../actions/ptypes';
import {capitalizeWord, convertDateToString, getEnumName} from '../modules/util';
import { parsePrice } from "../modules/util";
import State from "./dto/State";
import {SettlementPriceCalculationMethod} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";

export default class InstrumentStats {
    constructor(item, transactionTime, priceScale=1, qtyScale=1) {
        this.closePx = null;
        this.closeSetTime = null;
        this.highPx = null;
        this.highSetTime = null;
        this.indicativeOpenPx = null;
        this.indicativeOpenSetTime = null;
        this.lastTradePx = null;
        this.lastTradeQty = null;
        this.lastTradeSetTime = null;
        this.lowPx = null;
        this.lowSetTime = null;
        this.notionalTraded = null;
        this.notionalSetTime = null;
        this.openInterest = null;
        this.openInterestSetTime = null;
        this.openPx = null;
        this.openSetTime = null;
        this.settlementPx = null;
        this.settlementPreliminaryFlag = null;
        this.settlementPriceCalculationMethod = null;
        this.settlementSetTime = null;
        this.sharesTraded = null;
        this.transactionTime = null;

        if(item) {
            this.closePx = parsePrice(item.getClosePx(), priceScale);
            if (item.getCloseSetTime()) this.closeSetTime = convertDateToString(tsProtoToDate(item.getCloseSetTime()), "YYYYMMDD-HH:mm:ss.SSS");
            this.highPx = parsePrice(item.getHighPx(), priceScale);
            if (item.getHighSetTime()) this.highSetTime = convertDateToString(tsProtoToDate(item.getHighSetTime()), "YYYYMMDD-HH:mm:ss.SSS");
            this.indicativeOpenPx = parsePrice(item.getIndicativeOpenPx(), priceScale);
            if (item.getIndicativeOpenSetTime()) this.indicativeOpenSetTime = convertDateToString(tsProtoToDate(item.getIndicativeOpenSetTime()), "YYYYMMDD-HH:mm:ss.SSS");
            this.lastTradePx = parsePrice(item.getLastTradePx(), priceScale);
            this.lastTradeQty = parsePrice(item.getLastTradeQty(), qtyScale);
            if (item.getLastTradeSetTime()) this.lastTradeSetTime = convertDateToString(tsProtoToDate(item.getLastTradeSetTime()), "YYYYMMDD-HH:mm:ss.SSS");
            this.lowPx = parsePrice(item.getLowPx(), priceScale);
            if (item.getLowSetTime()) this.lowSetTime = convertDateToString(tsProtoToDate(item.getLowSetTime()), "YYYYMMDD-HH:mm:ss.SSS");
            this.notionalTraded = parsePrice(item.getNotionalTraded(), priceScale, qtyScale);
            if (item.getNotionalSetTime()) this.notionalSetTime = convertDateToString(tsProtoToDate(item.getNotionalSetTime()), "YYYYMMDD-HH:mm:ss.SSS");
            this.openInterest = parsePrice(item.getOpenInterest(), qtyScale);
            if (item.getOpenInterestSetTime()) this.openInterestSetTime = convertDateToString(tsProtoToDate(item.getOpenInterestSetTime()), "YYYYMMDD-HH:mm:ss.SSS");
            this.openPx = parsePrice(item.getOpenPx(), priceScale);
            if (item.getOpenSetTime()) this.openSetTime = convertDateToString(tsProtoToDate(item.getOpenSetTime()), "YYYYMMDD-HH:mm:ss.SSS");
            this.settlementPx = parsePrice(item.getSettlementPx(), priceScale);
            this.settlementPreliminaryFlag = item.getSettlementPreliminaryFlag();

            if (item.getSettlementSetTime()) this.settlementSetTime = convertDateToString(tsProtoToDate(item.getSettlementSetTime()), "YYYYMMDD-HH:mm:ss.SSS");
            this.sharesTraded = parsePrice(item.getSharesTraded(), qtyScale);
            if (transactionTime) this.transactionTime = convertDateToString(tsProtoToDate(transactionTime), "YYYYMMDD-HH:mm:ss.SSS");

            let settlementPriceCalculationMethod = new State();
            settlementPriceCalculationMethod.id = item.getSettlementPriceCalculationMethod();
            settlementPriceCalculationMethod.name = capitalizeWord(getEnumName(SettlementPriceCalculationMethod, settlementPriceCalculationMethod.id));
            this.settlementPriceCalculationMethod = settlementPriceCalculationMethod;
        }
    }
}