import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router'

import './LinkWithRouter.css';

const LinkWithRouter = withRouter(({ push, label, to, className }) => {
  return (
    <div
      className={className}
      onClick={e => {
        e.preventDefault();
        push(to);
      }}
      >
      {label}
    </div>);
});

LinkWithRouter.PropTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default connect(
  null,
  { push }
)(LinkWithRouter);