import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import AuthService from "../../services/AuthService"

import { login, checkPassword, resetLoginInfo, resetLoginError, confirmMFA } from '../../actions/auth';
import { parseJwt } from '../../modules/authUtil';
import { QRCodeSVG } from 'qrcode.react';

import './index.css';
import _ from 'lodash';
import { Env } from "../../constants/environment";
import { Col, Row } from 'react-bootstrap';
import { isNullOrUndefined } from '../../modules/util';
import { LOGIN_FAILURE, MFA_OTP_AUTH_URL } from '../../constants/authTypes';
import { Code } from 'grpc-web-client/dist/Code';


const getRegister = props => {
  if (!props || !props.history || !props.history.location || !props.history.location.search) {
    return false;
  }
  const getParams = _.chain(props.history.location.search)
    .replace('?', '')
    .split('&')
    .map(_.partial(_.split, _, '=', 2))
    .fromPairs()
    .value();
  return getParams.register ? getParams.register.toString().toLowerCase() === 'true' : false;
};

const LoginForm = props => {
  const register = getRegister(props);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    auth,
    validateForm,
    resetLoginInfo,
  } = props;

  const requireMfa = useMemo(() => window.REACT_APP_REQUIRE_MFA, [])

  return (
    <form onSubmit={handleSubmit} className="form">
      {register && <div className="form-group formGroup">
        <label htmlFor="token">Token</label>
        <input
          id="token"
          placeholder="Enter your token"
          type="text"
          value={values.token}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.token && touched.token ?
              "text-input error form-control formControl" :
              "text-input form-control formControl"
          }
        />
        {errors.token && touched.username && <label className="form-input-error username-error">{errors.token}</label>}
      </div>
      }

      {isNullOrUndefined(values.mfaOTPAuthUrl) && <>
        <div className="form-group formGroup">
          <label htmlFor="username">Username</label>
          <input
            id="username"
            placeholder="Enter your username"
            type="text"
            value={values.username}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={register}
            className={
              errors.username && touched.username ?
                `text-input error form-control formControl` :
                `text-input form-control formControl`
            }
          />
          {errors.username && touched.username &&
            <label className="form-input-error username-error">{errors.username}</label>}
        </div>

        <div className={`form-group formGroup`}>
          <label htmlFor="password">
            Password
          </label>
          <input
            id="password"
            placeholder="Enter your password"
            type="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.password && touched.password
                ? `text-input error form-control formControl`
                : `text-input form-control formControl`
            }
          />
          {errors.password && touched.password &&
            <label className="form-input-error password-error">{errors.password}</label>
          }
        </div>
      </>}

      {register && <div className={`form-group formGroup`}>
        <label htmlFor="matchpassword">
          Confirm Password
        </label>
        <input
          id="matchpassword"
          placeholder="Confirm your password"
          type="password"
          value={values.matchpassword}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.matchpassword && touched.matchpassword
              ? `text-input error form-control formControl`
              : `text-input form-control formControl`
          }
        />

        {errors.matchpassword && touched.matchpassword &&
          <label className="form-input-error password-error">{errors.matchpassword}</label>
        }
      </div>}

      {requireMfa && !register && (
        <>
          {!!values.mfaOTPAuthUrl &&
            <Row>
              <Col sm={5} lg={5} xl={5}>
                <QRCodeSVG value={values.mfaOTPAuthUrl} />
              </Col>
              <Col sm={7} lg={7} xl={7}>
                <label style={{ fontWeight: "normal", textAlign: "justify", whiteSpace: "normal", wordWrap: "break-word" }}>
                  Scan the QR code with your preferred authenticator (e.g the Google Authenticator app) to setup multi-factor authentication.
                  Enter the 6 digit One-Time Password generated by your authenticator below.
                </label>
              </Col>
            </Row>
          }

          <div className={`form-group formGroup`}>
            <label htmlFor="mfaOTP">
              One-Time Password
            </label>
            <input
              id="mfaOTP"
              placeholder="6 Digit One-Time Password"
              type="text"
              value={values.mfaOTP}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.mfaOTP && touched.mfaOTP
                  ? `text-input error form-control formControl`
                  : `text-input form-control formControl`
              }
            />

            {!!errors.mfaOTP && <label className="form-input-error password-error">{errors.mfaOTP}</label>}
          </div>
        </>
      )}

      {auth.error && <label className="form-input-error auth-error" style={{ paddingTop: "20px" }}>{auth.error}</label>}

      <div>
        <Row>
          {isNullOrUndefined(values.mfaOTPAuthUrl) && <Col >
            <button className={`${register ? "btn-secondary" : "btn-main"} btnSubmit`} style={{ width: "100%" }} type="submit" disabled={isSubmitting}>
              {register ? "Register" : "Login"}
            </button>
          </Col>}

          {!!values.mfaOTPAuthUrl && <>
            <Col >
              <button className={`btn-main btnSubmit`} style={{ width: "100%" }} disabled={isSubmitting} type='button' onClick={async () => {
                let valid = await validateForm();
                if (Object.keys(valid).length === 0) {
                  props.confirmMFA(values);
                }
                return false;
              }}>Enable MFA</button>
            </Col>
            <Col >
              <button className={`${register ? "btn-secondary" : "btn-main"} btnSubmit`} style={{ width: "100%" }} type="reset" disabled={isSubmitting} onClick={() => {
                resetLoginInfo();
              }}>Cancel</button>
            </Col>
          </>}

        </Row>
      </div>

      {!!Env.getEnv("REACT_APP_AUTH_URL") && <div className={`form-group formGroup`}>
        <button className={`btn-secondary btnSubmit`} onClick={() => {
          window.location.href = Env.getEnv("REACT_APP_AUTH_URL");
        }} disabled={isSubmitting}>
          Single Sign On
        </button>
      </div>}

      {!register && <a onClick={() => { resetLoginInfo() }} href="/?register=true">Need to register? Click here</a>}
    </form>
  );
};

export const EnhancedLoginForm = withFormik({
  mapPropsToValues: (props) => {
    return ({
      username: props.auth.username,
      password: props.auth.password,
      suggestion: '',
      mfaOTPAuthUrl: props.auth.mfaOTPAuthUrl,
      mfaRequired: true
    });
  },

  validationSchema: Yup.object().shape({
    password: Yup.string().required('Password is required!')
  }),

  validate: (values, props) => {
    let errors = {};

    if (getRegister(props)) {
      if (!values.token) {
        errors.token = 'Token is required!';
        values.username = "";
      } else {
        const parsed = parseJwt(values.token, true);
        if (parsed.sub) {
          const splitted = parsed.sub.split("/");
          values.username = splitted[splitted.length - 1];
        } else {
          values.username = "";
        }
      }
    }

    if (!getRegister(props) && !values.username) {
      errors.username = 'Username is required!';
    }

    if (getRegister(props) && values.password !== values.matchpassword) {
      errors.matchpassword = 'Password must match.';
      values.suggestion = "";
    } else if (!!values.suggestion) {
      errors.matchpassword = values.suggestion;
    }

    if (!!values?.mfaOTPAuthUrl && values?.mfaOTP?.length !== 6) {
      errors.mfaOTP = "6 digits OTP is required!";
    }

    if (getRegister(props) && _.isEmpty(errors)) {
      props.checkPassword(values.password, (err, res) => {
        if (res && !res.getAcceptable()) {
          values.suggestion = Number(res.getStrength() / res.getMinimumStrength()).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }) + " " + res.getSuggestion();
        }
      })
    }
    return errors;
  },

  handleSubmit: async (values, obj) => {
    const { props, setSubmitting, setFieldValue } = obj
    setSubmitting(false);
    props.login({ user: values, registering: getRegister(props), }, async (err, res, dispatch) => {
      if (!!err && err?.code === Code.FailedPrecondition) {
        try {
          const resp = await AuthService.enrollMFAAsync(values.username, values.password);
          dispatch({
            type: MFA_OTP_AUTH_URL, payload: {
              username: values.username,
              password: values.password,
              mfaOTPAuthUrl: resp
            }
          });

          setFieldValue('mfaOTPAuthUrl', resp);
        } catch (e) {
          dispatch({
            type: LOGIN_FAILURE,
            payload: { error: e.message },
          });
        }
      }
    });
  },
  displayName: 'LoginForm' // helps with React DevTools
})(LoginForm);

class Login extends Component {
  componentDidMount() {
    document.title = 'Exchange - Login';
  }

  render() {
    const { login, checkPassword, auth, resetLoginInfo, resetLoginError, confirmMFA } = this.props;
    const Form = withRouter(({ history }) => (
      <EnhancedLoginForm login={login} checkPassword={checkPassword} history={history} auth={auth} resetLoginInfo={resetLoginInfo} resetLoginError={resetLoginError} confirmMFA={confirmMFA} />
    ));
    return (
      <div >
        <div className="wrapper">
          <h1 className="welcomeBack">Welcome back</h1>
          <Form />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { login, checkPassword, resetLoginInfo, resetLoginError, confirmMFA }
)(Login);
