import * as React from "react";

export const DurationControlUnitInput = ({ value, type, characterLength, onChange, onUpArrowKeyPress, onDownArrowKeyPress, onFocus, onBlur, disabled }) => {
    const inputRef = React.useRef(null);
    const [focused, setFocused] = React.useState(false);
    const getValue = () => {
        if (value === null) {
            return "";
        }
        return focused ? value : String(value).padStart(characterLength, "0");
    };

    if (!focused || disabled) {
        return (<span className={`react-duration-control-unit-text-${type}`} onClick={() => {
            if (!disabled) {
                setFocused(true);
                onFocus();
            }
        }}>
            {getValue()}
        </span>);
    }
    return (<div className={`duration-control-unit-input-wrapper ${type}`}>
        <input ref={inputRef} type="text" autoFocus value={getValue()} onChange={(event) => {
            if (!event.target.value) {
                onChange(null);
                return;
            }
            if (!Number.isNaN(Number(event.target.value))) {
                onChange(parseInt(event.target.value, 10));
            }
        }} onBlur={(event) => {
            setFocused(false);
            onBlur();
            if (!event.target.value) {
                onChange(0);
            }
        }} onKeyDown={(event) => {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
            }
            if (event.key === "Enter") {
                setFocused(false);
                onBlur();
                if (!inputRef.current || !inputRef.current.value) {
                    onChange(0);
                }
            }
        }} onKeyUp={(event) => {
            if (event.key === "ArrowUp") {
                onUpArrowKeyPress();
            }
            else if (event.key === "ArrowDown") {
                onDownArrowKeyPress();
            }
        }} className={`duration-control-unit-input ${type}`} maxLength={characterLength} style={{ width: `${characterLength + 1 }ch` }}></input>
    </div>);
};