import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import {validateField, getOptionText, NUMBER_TYPE, ARRAY_TYPE } from '../../instrumentFormUtils'


export const FixedLegInfoFra = ({
  values,
  errors,
  setFieldValue,
  touched,
  handleBlur,
  mode
}) => {

  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Fixed Leg Information</strong></h6>
          </Col>
          <Col>
          <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
             <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Fixing Date Holiday Calendars List" value={ values.fixedFixingDateHolidayCalendarsList ? values.fixedFixingDateHolidayCalendarsList.join(", ") : "" } />
             </Col>
             <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Day Count Convention" value={getOptionText(InstrumentStaticData.DayCountConventionOptions, values.fixedDayCountConvention)} />
             </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.fixedBusinessDayConvention)} />
            </Col>
          </Row>
        </div>
      </Collapse>


    </React.Fragment>
  )

}

export const ValidateFixedLegInfoFra = (values, errors) => {
  errors = validateField(values, errors, "fixedDayCountConvention", "Day Count Convention", NUMBER_TYPE);
  errors = validateField(values, errors, "fixedBusinessDayConvention", "Business Day Convention", NUMBER_TYPE);
  return validateField(values, errors, "fixedFixingDateHolidayCalendarsList", "Fixed Fixing Date Holiday Calendars List", ARRAY_TYPE);
}
