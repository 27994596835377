import {
  FETCH_PARTICIPANT_GROUPS_START,
  FETCH_PARTICIPANT_GROUPS_SUCCEED,
  FETCH_PARTICIPANT_GROUPS_FAILED,
  FETCH_PARTICIPANT_GROUP_START,
  FETCH_PARTICIPANT_GROUP_SUCCEED,
  FETCH_PARTICIPANT_GROUP_FAILED,
  CLEAN_PARTICIPANT_GROUP,
  SAVE_PARTICIPANT_GROUP_START,
  SAVE_PARTICIPANT_GROUP_SUCCEED,
  SAVE_PARTICIPANT_GROUP_FAILED,
  CREATE_PARTICIPANT_GROUP_STATE,
  FETCH_PARTICIPANTS_LIST_START,
  FETCH_PARTICIPANTS_LIST_SUCCEED,
  FETCH_PARTICIPANTS_LIST_FAILED,
  CLEAN_PARTICIPANTS_LIST_GROUP
} from '../constants/participantGroupTypes';
import {
  RESET,
} from '../constants/appTypes';
import ParticipantGroupFormItem from '../entities/dto/ParticipantGroupFormItem';

const initialState = {
  fetchingParticipantGroups: false,
  fetchingParticipantGroup: false,
  fetchingParticipantsList: false,
  savingParticipantGroup: false,
  isCreating: false,
  participantGroups: [],
  participantsList: [],
  participantGroup: new ParticipantGroupFormItem(),
}

const participantGroups = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTICIPANT_GROUPS_START:
      return {
        ...state,
        fetchingParticipantGroups: true
      };
    case FETCH_PARTICIPANT_GROUPS_SUCCEED:
      return {
        ...state,
        fetchingParticipantGroups: false,
        participantGroups: action.payload
      };
    case FETCH_PARTICIPANT_GROUPS_FAILED:
      return {
        ...state,
        fetchingParticipantGroups: false,
        participantGroups: []
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    case FETCH_PARTICIPANT_GROUP_START:
      return {
        ...state,
        fetchingParticipantGroup: true
      };
    case FETCH_PARTICIPANT_GROUP_SUCCEED:
      return {
        ...state,
        fetchingParticipantGroup: false,
        participantGroup: action.payload
      };
    case FETCH_PARTICIPANT_GROUP_FAILED:
      return {
        ...state,
        fetchingParticipantGroup: false
      };
    case CLEAN_PARTICIPANT_GROUP:
      return {
        ...state,
        participantGroup: new ParticipantGroupFormItem()
      };
    case SAVE_PARTICIPANT_GROUP_START:
      return {
        ...state,
        savingParticipantGroup: true
      };
    case SAVE_PARTICIPANT_GROUP_SUCCEED:
      return {
        ...state,
        savingParticipantGroup: false
      };
    case SAVE_PARTICIPANT_GROUP_FAILED:
      return {
        ...state,
        savingParticipantGroup: false
      };
    case CREATE_PARTICIPANT_GROUP_STATE:
      return {
        ...state,
        isCreating: action.value
      };
    case FETCH_PARTICIPANTS_LIST_START:
      return {
        ...state,
        fetchingParticipantsList: true,
        participantsList: []
      };
    case FETCH_PARTICIPANTS_LIST_SUCCEED:
      return {
        ...state,
        fetchingParticipantsList: false,
        participantsList: action.payload
      };
    case FETCH_PARTICIPANTS_LIST_FAILED:
      return {
        ...state,
        fetchingParticipantsList: false,
        participantsList: []
      };
    case CLEAN_PARTICIPANTS_LIST_GROUP:
      return {
        ...state,
        participantsList: []
      };
    default:
      return state;
  }
}

export default participantGroups;
