import React from 'react';
import PropTypes from 'prop-types';
import './Buttons.css';

const ButtonSecondary = ({
  type,
  text,
  enabled,
  onClick,
  style,
}) => (
    <button
      type={type}
      className="btn btn-secondary"
      onClick={onClick}
      disabled={!enabled}
      style={style}
    >{text}</button>
  )

ButtonSecondary.defaultProps = {
  type: 'submit',
  text: '',
  enabled: true,
  icon: '',
  customClassName: '',
}

ButtonSecondary.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  icon: PropTypes.string,
  customClassName: PropTypes.string,
  onClick: PropTypes.func
}

export default ButtonSecondary;