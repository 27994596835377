const { Timestamp } = require('google-protobuf/google/protobuf/timestamp_pb.js');
const { Duration } = require('google-protobuf/google/protobuf/duration_pb.js');
const { DoubleValue } = require('google-protobuf/google/protobuf/wrappers_pb.js');

export default class ProtobufParser {
  //If you look at "google-protobuf/google/protobuf/timestamp_pb.js"
  // there is a method "fromDate()"". For some reason, that method doesn't work
  // and here is a custom method to replace it.
  static toTimestamp(date) {
    var newTimestamp = new Timestamp();
    newTimestamp.setSeconds(Math.floor(date.getTime() / 1000));
    newTimestamp.setNanos(date.getMilliseconds() * 1000000);

    return newTimestamp;
  }

  static toDouble(value) {
    return new DoubleValue(value);
  }

  static millisToDuration(value) {
    const millis = Number(value);
    const seconds = Math.round(millis / 1000);
    return this.toDuration({
      seconds: seconds,
      nanos: Math.round((millis - (seconds * 1000)) * 1000000),
    })
  }

  static toDuration(value) {
    var newDuration = new Duration();
    newDuration.setSeconds(value.seconds);
    newDuration.setNanos(value.nanos);

    return newDuration;
  }

  static fromDuration(value) {
    if (value && typeof value.toObject === 'function' && typeof value["getSeconds"] === 'function') {
      return {
        seconds: value.getSeconds(),
        nanos: value.getNanos()
      }
    }
    
    return {
      seconds: value && value.hasOwnProperty('seconds') ? value.seconds : 0,
      nanos: value && value.hasOwnProperty('nanos') ? value.nanos : 0,
    }
  }

  static fromDurationToMillis(value) {
    const dur = this.fromDuration(value);
    return Math.round((dur.seconds * 1000) + (dur.nanos / 1000000))
  }

  static formatDuration(value, postfix = "") {
    const obj = value && typeof value.toObject === 'function' ? value.toObject() : value;
    const str = String(obj && obj.seconds ? obj.seconds : 0).padStart(1, "0")
        + "."
        + String(obj && obj.nanos ? obj.nanos : 0).padStart(9, "0");
    return str.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1') + postfix;
  }
}