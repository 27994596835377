import React from 'react';
import PropTypes from 'prop-types';
import './Buttons.css';

const ButtonBorderless = ({
  type,
  text,
  onClick,
  icon,
  enabled,
  customClassName,
  size
}) => (
    <button
      type={type}
      className={`btn btn-text btn-borderless${customClassName ? ' ' + customClassName : ''}`}
      onClick={onClick}
      disabled={!enabled}
    >
      <i className={`fa fa-fw fa-${icon}${customClassName ? ' ' + customClassName : ''} fa-${size}`} />
      {text}
    </button>
  )

ButtonBorderless.defaultProps = {
  type: 'submit',
  text: '',
  enabled: true,
  icon: '',
  customClassName: '',
}

ButtonBorderless.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  icon: PropTypes.string,
  customClassName: PropTypes.string,
  onClick: PropTypes.func
}

export default ButtonBorderless;