import { replace } from 'connected-react-router';
import { StatusCode } from 'grpc-web';
import { store } from '../store';
import {
  RESET,
} from '../constants/appTypes';
import Notification from '../modules/notifications';

const processAPIResponse = (err, res, cb) => {
  if (err) {
    
    switch (err.code) {
      case 16:
        store.dispatch({ type: RESET });
        store.dispatch(replace('/'));
        Notification.error('Your credentials have expired. You have been logged out.');
        break;

      case StatusCode.UNAVAILABLE:
        Notification.error('Cannot perform this operation. Please try again in a few minutes.');
        break;
      default:
        break;
    }
  }

  if (cb) {
    cb(err, res);
  }
}

export default processAPIResponse;
