import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../data-grid/DataGrid';
import FirmsStaticData from "../../../modules/firmsStaticData";
import "./CheckListDataGrid.css"

const defaultCustomerOrderCapacityID = 5; // OTHER

const CheckListDataGrid = ({
  data,
  selectorId,
  columnText,
  columnAccesor,
  onClick,
  showCustomerOrderCapacity = true
}) => {
  const [customerOrderCapacity, setCustomerOrderCapacity] = useState()
  const [gridData, setGridData] = useState()

  useEffect(() => {
    const customerOrderCapacity = FirmsStaticData.CustomerOrderCapacity();
    setCustomerOrderCapacity(customerOrderCapacity)
    setGridData(data);
  }, [data]);

  const getCustomerOrderCapacity = () => {
    if (!!customerOrderCapacity)
      return customerOrderCapacity
    
    return [];
  }

  const getCustomerOrderCapacityDropDown = (record, onChange) => {
    const customerOrderCapacity = getCustomerOrderCapacity();
    if (!record.customerOrderCapacity) {
      for (const cap of customerOrderCapacity) {
        if (cap.id === defaultCustomerOrderCapacityID) {
          record.customerOrderCapacity = cap.id;
          break;
        }
      }
    }
    return (
      <select className='chk-form-control' value={record.customerOrderCapacity} onChange={onChange}>
        {customerOrderCapacity.map(orderCap => <option value={orderCap.id} key={orderCap.id}>{orderCap.name}</option>)}
      </select>
    );
  }

  const columns = [
    {
      accessor: 'selector',
      filterable: false,
      sortable: false,
      Cell: cellInfo => {
        return (
            <input
                key={selectorId}
                className='checkbox-grid'
                type="checkbox"
                checked={cellInfo.original.isSelected}
                onClick={e => {
                  const id = cellInfo.original.id;
                  const updatedRow = {id, isSelected: e.target.checked};
                  onClick(updatedRow);
                }}
            />
        );
    },
      maxWidth: 50
    },
    {
      Header: `${columnText}`,
      accessor: `${columnAccesor}`
    }];

  if (showCustomerOrderCapacity) {
    columns.push({
      Header: "CUSTOMER ORDER CAPACITY",
      Cell: cellInfo => {
        return getCustomerOrderCapacityDropDown(cellInfo.original, (e) => {
          cellInfo.original.customerOrderCapacity = e.target.value;
          setGridData([...gridData]);
        })
      },
    });
  }

  return (
    <div>
      <DataGrid
        data={gridData}
        columns={columns}
        filterable={false}
      />
    </div>
  )
};

CheckListDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  selectorId: PropTypes.string.isRequired,
  columnText: PropTypes.string.isRequired,
  columnAccesor: PropTypes.string.isRequired,
};

export default CheckListDataGrid;