import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import reducers from './reducers';

const composeEnhancers =
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

// configure persistance
export const accountsConfig = {
  key: "accounts",
  storage,
  whitelist: ["accounts"],
};

export const instrumentsConfig = {
  key: "instruments",
  storage,
  whitelist: ["instrument"],
};

export const firmConfig = {
  key: "firms",
  storage,
  whitelist: ["firms"],
};

// configure persistance
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'app']
};
export const history = createBrowserHistory();
const persistedReducer = persistReducer(persistConfig, reducers(history));

// configure store
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(ReduxThunk, routerMiddleware(history)))
);