import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { v4 } from "uuid";
import Tooltip from '../core/tooltip-html/tooltip-html';
import { isArrayWithValues } from "../../modules/util";

export default function AlternateLegalNames({ onChange, tooltip, readOnly, data }) {
  const [isOpen, setOpen] = useState(readOnly);
  const [legalNames, setLegalNames] = useState([]);
  const [validationErr, setValidationErr] = useState("");

  useEffect(() => {
      setLegalNames(prepareStateData(data));
  }, [data])

  useEffect(() => {
    setValidationErr(validateLegalNames(legalNames));
  }, [legalNames])

  const newLegalName = () => {
    setLegalNames(legalNames => [...legalNames, { id: v4(), name: "" }]);
  }

  const prepareStateData = (legalNames) => {
    let lst = [];
    if (isArrayWithValues(legalNames)) {
      legalNames.forEach(legalName => {
        lst.push({ id: v4(), name: legalName })
      })
    }

    return lst;
  }

  const updateName = (id, name) => {
    let element = legalNames.find((elm) => elm.id === id);
    if (!!element) {
      element.name = name;
    }

    setLegalNames([...legalNames]);
    notifyChange(legalNames);
  }

  const deleteName = (id) => {
    let idx = legalNames.findIndex((elm) => elm.id === id);
    if (idx >= 0) {
      legalNames.splice(idx, 1);
    }

    setLegalNames([...legalNames]);
    notifyChange(legalNames);
  }

  const notifyChange = () => {
    // Do not notify if there is any error.
    if (validateLegalNames(legalNames).length > 0) {
      return;
    }

    if (onChange) {
      onChange(legalNames.map(itm => itm.name));
    }
  }

  const validateLegalNames = (legalNames) => {
    for (const legalName of legalNames) {
      if (legalName.name.trim() === "") {
        return "Legal name cannot be left empty";
      }

      if (legalNames.filter((itm) => itm.name === legalName.name).length > 1) {
        return `Duplicate legal name '${legalName.name}'`;
      }
    }

    return "";
  }

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!isOpen)} className="alias-header" id='alias'>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6>
              <strong>Alternate Legal Names</strong>
              {!!tooltip && <Tooltip text={tooltip} id={`tip-alias`} />}
            </h6>
          </Col>
          <Col>
            <i
              className={`fa fa-fw ${isOpen ? "fa-minus" : "fa-plus"
                } custom-plus`}
            />
          </Col>
        </Row>
      </div>

      <div className="account-alias">
        <Collapse in={isOpen}>
          <div className="collapse-content">
            {!!legalNames && Array.isArray(legalNames) && legalNames.length > 0 && <Row key="header-row" className="form-group">
              <Col>
                <span className="header-alias">Name</span>
              </Col>
            </Row>
            }

            {legalNames.map((legalName, index) => {
              return (<Row key={legalName.id} className="form-group">
                <Col lg={3} xs={6} md={6} >
                  {!readOnly && <input type="text" className="form-control" value={legalName.name} onChange={(event) => { updateName(legalName.id, event.target.value) }} />}
                  {readOnly && <div class="form-readonly-label">{legalName.name}</div>}
                </Col>
                {!readOnly && <Col lg={1} xs={6} md={6}><Button onClick={(evt) => { deleteName(legalName.id); }}>Delete</Button></Col>}
              </Row>)
            })}

            {!!validationErr && <Row>
              <Col lg={6} xs={6} md={12}>
                <div class="form-error">{validationErr}</div>
              </Col>
            </Row>}

            {!readOnly && <Row>
              <Col lg={6} xs={6} md={12}>
                <button
                  type="button"
                  className="btn-add-alias"
                  onClick={() => { newLegalName() }}
                >
                  Add Name
                </button>
              </Col>
            </Row>}
          </div>
        </Collapse >
      </div>
    </React.Fragment >
  );
}
