import AccountService from "../../services/AccountService";
import * as FirmService from "../../services/FirmService";
import ParticipantService from "../../services/ParticipantService";
const { FirmType, ParticipantRole } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb.js");
const { ListAccountsRequest, ListParticipantsRequest } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");

class TreeNode {
	constructor(label, disabled, value) {
		this.label = label;
		this.value = value
		this.disabled = disabled;
		this.includeInTree = true;
		this.children = [];
	}

	addChild(child) {
		this.children.push(child);
	}
}

export const listFirmAccountUsers = async () => {
	const rootNode = new TreeNode();
	const firms = rootNode.children;

	const firmResp = await FirmService.listFirmsAsync(FirmType.FIRM_TYPE_PARTICIPANT);
	const firmList = firmResp.getFirmsList();

	if (!!firmList) {
		firmList.forEach((firm, index) => {
			const firmName = firm.getName();
			const firmDisplayName = firm.getDisplayName();

			const firmNode = new TreeNode(firmDisplayName, true, { type: "FIRM", name: firmName });
			rootNode.addChild(firmNode);
		});
	}

	const firmAccounts = [];
	rootNode.children.forEach((firmNode) => {
		const accountReq = new ListAccountsRequest();
		accountReq.setFirm(firmNode.value.name);
		firmAccounts.push(AccountService.listAccountsAsync(accountReq));
	})

	const accountsResp = await Promise.all(firmAccounts);
	accountsResp.forEach((response, idx) => {
		const firm = firms[idx]
		const accountsList = response.getAccountsList();

		accountsList.forEach((account, idx) => {
			const name = account.getName();
			const getDisplayName = account.getDisplayName();
			const accountNode = new TreeNode(getDisplayName, true, { type: "ACCOUNT", name: name, parent: firm.value.name });
			firm.addChild(accountNode);
		});
	});

	const accountUsers = [];
	firms.forEach((firmNode) => {

		firmNode.children.forEach((accountNode) => {

			const accountUsresPromise = new Promise((resolve, reject) => {
				const accountName = accountNode.value.name;

				AccountService.getParticipants(accountName, (err, response) => {
					if (err) {
						reject(err);
					}

					if (response) {
						const userList = response.getParticipantsList();

						userList.forEach((user, index) => {
							const userName = user.getName();
							const userDisplayName = user.getDisplayName();
							const userNode = new TreeNode(userDisplayName, false, { type: "USER", name: userName, parent: accountNode.value.name });
							accountNode.addChild(userNode);
						});
					}

					resolve();
				});
			});

			accountUsers.push(accountUsresPromise);
		});
	});

	await Promise.all(accountUsers);

	return filterEmptyFirmNodes(rootNode.children);
};

export const listBrokerUsers = async () => {
	const rootNode = new TreeNode();
	const firms = rootNode.children;

	const firmResp = await FirmService.listFirmsAsync(FirmType.FIRM_TYPE_AGENT);
	const firmList = firmResp.getFirmsList();

	if (!!firmList) {
		firmList.forEach((firm, index) => {
			const firmName = firm.getName();
			const firmDisplayName = firm.getDisplayName();

			const firmNode = new TreeNode(firmDisplayName, true, { type: "FIRM", name: firmName });
			rootNode.addChild(firmNode);
		});
	}

	const accountUsers = [];
	firms.forEach((firmNode) => {
		const accountUsresPromise = new Promise((resolve, reject) => {

			var request = new ListParticipantsRequest();
			request.setFirm(firmNode.value.name);
			request.setRole(ParticipantRole.PARTICIPANT_ROLE_BROKER);
			ParticipantService.listParticipantsAsync(request).then((resp) => {
				const userList = resp.getParticipantsList();

				userList.forEach((user, index) => {
					const userName = user.getName();
					const userDisplayName = user.getDisplayName();
					const userNode = new TreeNode(userDisplayName, false, { type: "USER", name: userName, parent: firmNode.value.name });
					firmNode.addChild(userNode);
				});

				resolve();
			}).catch(reject);

		});

		accountUsers.push(accountUsresPromise);
	});

	await Promise.all(accountUsers);

	return filterEmptyBrokerNodes(rootNode.children);
}

export const filterEmptyFirmNodes = (firmNodes) => {
	firmNodes.forEach((firmNode) => {
		firmNode.children.forEach((accountNode) => {
			accountNode.includeInTree = accountNode.children.length > 0
		});

		// Taking only those accounts which have users.
		firmNode.children = firmNode.children.filter((accountNode) => accountNode.includeInTree);

		firmNode.includeInTree = firmNode.children.some((accountNode) => accountNode.includeInTree);
	});

	const filteredNodes = firmNodes.filter((firmNode) => firmNode.includeInTree);
	return filteredNodes;
}

export const filterEmptyBrokerNodes = (firmNodes) => {
	firmNodes.forEach((firmNode) => {
		firmNode.includeInTree = firmNode.children.length > 0
	});

	const filteredNodes = firmNodes.filter((firmNode) => firmNode.includeInTree);
	return filteredNodes;
}
