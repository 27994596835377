import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TitleBreadcrumb } from "../core/title-breadcrumb/title-breadcrumb";

const OrdersSearchHeader = ({
  selectTab,
  selectedTab,
}) => {
  const linkClass = "link-with-router link-tab";
  const selectedLinkClass = `${linkClass} link-tab-selected`;
  const title = "Search";
  return (
    <div>
      <div>
        <TitleBreadcrumb titles={[{'title':title, link:`${window.location.origin}/order-search/orders`}]} />
      </div>

      <Row className="page-header">
        <Col lg={10} xs={10} md={10}>
            <div>
              <h2 className={selectedTab === "orders" ? selectedLinkClass : linkClass}
                onClick={() => selectTab("orders")}><Link to='/order-search/orders' className="tabLink">Orders</Link></h2>

              <h2 className={selectedTab === "executions" ? selectedLinkClass : linkClass}
                onClick={() => selectTab("executions")}><Link to='/order-search/executions' className="tabLink">Executions</Link></h2>
            </div>
        </Col>
      </Row>
    </div>
  );
};

OrdersSearchHeader.defaultProps = {

};

OrdersSearchHeader.propTypes = {

};

export default OrdersSearchHeader;
