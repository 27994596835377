import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  FetchAccountStart,
  FetchAccountSucceed,
  FetchAccountFailed,
  FetchPositionsStart,
  FetchPositionsSucceed,
  FetchPositionsFailed,
  DownloadPositionsStart,
  DownloadPositionsSucceed,
  DownloadPositionsFailed,
  FetchPendingSettlementsStart,
  FetchPendingSettlementsSucceed,
  FetchPendingSettlementsFailed,
  FetchBalancesStart,
  FetchBalancesSucceed,
  FetchBalancesFailed,
  FetchWithdrawalsStart,
  FetchWithdrawalsSucceed,
  FetchWithdrawalsFailed,
  FetchParticipantsListSucceed,
  CleanAccount,
  CleanParticipantsListGroup,
  FetchPendingCreditsStart,
  FetchPendingCreditsSucceed,
  FetchPendingCreditsFailed,
  fetchSecurityDefinitions
} from "../actions/accounts";
import {
  FetchFirmFailed,
  FetchFirmStart,
  FetchFirmSucceed,
} from "../actions/firms";
import { LoadFirms } from "../actions/firms";
import { SetCurrentTab } from "../actions/app";
import "./Content.css";
import Loader from "../components/core/loader/Loader";
import Participant from "../entities/Participant";
import Account from "../entities/Account";
import Notification from "../modules/notifications";
import AccountFormItem from "../entities/dto/AccountFormItem";
import AccountFormContainer from "../containers/AccountFormContainer";
import { AdminDocumentTitle } from "../constants/strings";
import AccountService from "../services/AccountService";
import Firm from "../entities/Firm";
import * as firmService from "../services/FirmService";
import AccountHeader from "../components/account/AccountHeader";
import { getFormMode } from "../modules/util";
import { FormModes } from "../constants/enumerations";
import { hasWriteAccess } from "../services/TokenService";
import querystring from 'query-string';
import { isNullOrUndefined } from '../modules/util';
import _ from "lodash"
import {StatusCode} from "grpc-web";
import {Env} from "../constants/environment";

const refreshFrequency = 5; //(seconds)

function mapStateToProps(state) {
  return {
    fetchingAccount: state.accounts.fetchingAccount,
    fetchingPositions: state.accounts.fetchingPositions,
    fetchingBalances: state.accounts.fetchingBalances,
    account: state.accounts.account,
    positions: state.accounts.positions,
    pendingSettlements: state.accounts.pendingSettlements,
    balances: state.accounts.balances,
    withdrawals: state.accounts.withdrawals,
    firm: state.firms.firm,
    currentTab: state.app.currentTab,
    firms: state.firms.firms,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAccountStart: (product) => {
    dispatch(FetchAccountStart(product));
  },
  fetchAccountSucceed: (product) => {
    dispatch(FetchAccountSucceed(product));
  },
  fetchAccountFailed: (product) => {
    dispatch(FetchAccountFailed(product));
  },
  fetchPositionsStart: (positions) => {
    dispatch(FetchPositionsStart(positions));
  },
  fetchPositionsSucceed: (positions) => {
    dispatch(FetchPositionsSucceed(positions));
  },
  fetchPositionsFailed: (err) => {
    dispatch(FetchPositionsFailed(err));
  },
  downloadPositionsStart: (positions) => {
    dispatch(DownloadPositionsStart(positions));
  },
  downloadPositionsSucceed: (positions) => {
    dispatch(DownloadPositionsSucceed(positions));
  },
  downloadPositionsFailed: (err) => {
    dispatch(DownloadPositionsFailed(err));
  },
  fetchPendingSettlementsStart: () => {
    dispatch(FetchPendingSettlementsStart());
  },
  fetchPendingSettlementsSucceed: (positions) => {
    dispatch(FetchPendingSettlementsSucceed(positions));
  },
  fetchPendingSettlementsFailed: (err) => {
    dispatch(FetchPendingSettlementsFailed(err));
  },
  fetchBalancesStart: (balances) => {
    dispatch(FetchBalancesStart(balances));
  },
  fetchBalancesSucceed: (balances) => {
    dispatch(FetchBalancesSucceed(balances));
  },
  fetchBalancesFailed: (err) => {
    dispatch(FetchBalancesFailed(err));
  },
  fetchWithdrawalsStart: (wds) => {
    dispatch(FetchWithdrawalsStart(wds));
  },
  fetchWithdrawalsSucceed: (wds) => {
    dispatch(FetchWithdrawalsSucceed(wds));
  },
  fetchWithdrawalsFailed: (err) => {
    dispatch(FetchWithdrawalsFailed(err));
  },
  fetchPendingCreditsStart: () => {
    dispatch(FetchPendingCreditsStart());
  },
  fetchPendingCreditsSucceed: (credits) => {
    dispatch(FetchPendingCreditsSucceed(credits));
  },
  fetchPendingCreditsFailed: (err) => {
    dispatch(FetchPendingCreditsFailed(err));
  },
  fetchFirmStart: (firm) => {
    dispatch(FetchFirmStart(firm));
  },
  fetchFirmSucceed: (firm) => {
    dispatch(FetchFirmSucceed(firm));
  },
  fetchFirmFailed: (firm) => {
    dispatch(FetchFirmFailed(firm));
  },
  fetchParticipantsSucceed: (participants) => {
    dispatch(FetchParticipantsListSucceed(participants));
  },
  clearGroup: () => {
    dispatch(CleanAccount());
  },
  clearParticipantsList: () => {
    dispatch(CleanParticipantsListGroup());
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
  setCurrentTab: (selectedTab) => {
    dispatch(SetCurrentTab(selectedTab));
  },
  fetchSecurityDefinitions: () => {
    dispatch(fetchSecurityDefinitions());
  }
});

class AccountFormPage extends Component {
  loadPositionsTimer = 0;
  loadBalancesTimer = 0;
  loadWithdrawalsTimer = 0;
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    document.title = AdminDocumentTitle;

    const id = this.props.match.params.id;
    const firmId = this.props.match.params.firmid;
    if (firmId) {
      this.loadFirm(firmId);
    }
    if (id && id !== "new") {
      const name = `firms/${firmId}/accounts/${id}`;
      this.getAccountInformation(name);
      this.loadPositionsTimer = setInterval(
          () => this.loadPositions(name),
          refreshFrequency * 1000
      );
      this.loadWithdrawalsTimer = setInterval(
          () => this.loadWithdrawals(name),
          refreshFrequency * 1000
      );
      this.loadBalancesTimer = setInterval(
          () => this.loadBalances(name),
          refreshFrequency * 1000
      );
    }

    this.props.loadFirms();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearInterval(this.loadPositionsTimer);
    clearInterval(this.loadBalancesTimer);
    clearInterval(this.loadWithdrawalsTimer);
  }

  getAccountInformation = (name) => {
    this.props.clearGroup();
    this.props.clearParticipantsList();
    this.props.fetchAccountStart();

    const cb = (err, response) => {
      if (response) {
        const account = new AccountFormItem(new Account(response.getAccount()));
        this.props.fetchAccountSucceed(account);
        this.loadParticipants(name);
        this.loadPositions(name);
        this.loadBalances(name);
        this.loadWithdrawals(name);
        this.loadPendingCredits(name);
      }

      if (err) {
        this.props.fetchAccountFailed();
        console.error("cannot get account info", err);
        Notification.error("Cannot get account information.");
      }
    };
    AccountService.get(name, cb);
  };

  loadFirm = (id) => {
    return new Promise((resolve, reject) => {
      this.props.fetchFirmStart();
      const cb = (err, response) => {
        if (response) {
          const firm = new Firm(response.getFirm());
          this.props.fetchFirmSucceed(firm);
          resolve(firm);
        }
        if (err) {
          this.props.fetchFirmFailed();
          Notification.error(`Cannot get firm information for id ${id}`);
          reject();
        }
      };

      firmService.get(id, cb);
    });
  };

  loadParticipants = (name) => {
    const cb = (err, response) => {
      if (response) {
        var participantsList = response
          .getParticipantsList()
          .map((participant) => {
            return new Participant(participant);
          });
        this.props.fetchParticipantsSucceed(participantsList);
      }

      if (err) {
        console.error(err);
      }
    };

    AccountService.listParticipantsInAccount(name, cb);
  };

  loadPositions = (name, filters) => {
    this.props.fetchPositionsStart();
    this.props.fetchPendingSettlementsStart();
    const cb = (err, response) => {
      if (response) {
        var positionsList = response.getPositionsList().map((position) => {
          return position.toObject();
        });
        this.props.fetchPositionsSucceed(positionsList);
      }

      if (err) {
        this.props.fetchPositionsFailed(err);
      }
    };

    const cb2 = (err, response) => {
      if (response) {
        var pendingSettlementsList = response.getTotalPendingSettlementsList().map((pendingSettlement) => {
          return pendingSettlement.toObject();
        });
        this.props.fetchPendingSettlementsSucceed(pendingSettlementsList);
      }

      if (err) {
        this.props.fetchPendingSettlementsFailed(err);
      }
    };

    if (!!filters) {
      let { firm, account } = this.props;
      this.context.router.history.push(`/firms/${firm.id}/accounts/${account.id}/positions?${querystring.stringify(filters)}`);
    }

    let urlContents = querystring.parseUrl(window.location.href)
    let urlAsOfTime = !isNullOrUndefined(urlContents.query.asOfTime) && !_.isEmpty(urlContents.query.asOfTime) ? new Date(urlContents.query.asOfTime) : null  

    if (!isNullOrUndefined(urlAsOfTime)) {
      AccountService.getHistoricalPositions(name, urlAsOfTime, cb);
      this.props.fetchPendingSettlementsSucceed([]);
    } else {
      AccountService.listPositions(name, cb);
      AccountService.listPendingSettlement(name, cb2)
    }

  };

  exportPositions = (filters) => {
    let onSuccess = (data) => {
      this.props.downloadPositionsSucceed()
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `positions.csv`);
      document.body.appendChild(link);
      link.click();
    };
    let onError = (error) => {
      console.error(error);
      this.props.downloadPositionsFailed(error);
      Notification.error("Could not download report.");
    };

    const id = this.props.match.params.id;
    const firmId = this.props.match.params.firmid;
    if (firmId && id && id !== "new") {
      const name = `firms/${firmId}/accounts/${id}`;
      //Grab asOfTime from query if not provided in the filter
      let urlContents = querystring.parseUrl(window.location.href)
      let urlAsOfTime = !isNullOrUndefined(urlContents.query.asOfTime) && !_.isEmpty(urlContents.query.asOfTime) ? new Date(urlContents.query.asOfTime) : null  

      this.props.downloadPositionsStart()
      AccountService.downloadPositions(name, !!filters ? filters.asOfTime : urlAsOfTime, onSuccess, onError);
    }
  }

  loadWithdrawals = (name) => {
    this.props.fetchWithdrawalsStart();
    const cb = (err, response) => {
      if (response) {
        var withdrawalsList = response.getWithdrawalsList().map((wd) => {
          return wd.toObject();
        });
        this.props.fetchWithdrawalsSucceed(withdrawalsList);
      }

      if (err) {
        this.props.fetchWithdrawalsFailed(err);
      }
    };

    AccountService.listPendingWithdrawals(name, cb);
  };

  loadBalances = (name) => {
    this.props.fetchBalancesStart();
    const cb = (err, response) => {
      if (response) {
        var balancesList = [];
        const balancesMap = response.getBalancesMap();

        if (!!balancesMap) {
          balancesMap.forEach((balanceResp, currency) => {
            const balanceObj = balanceResp.toObject();
            let securities = [];

            if (!!balanceObj.securitiesMap) {
              balanceObj.securitiesMap.forEach((security, idx) => {
                securities.push({
                  securityId: security[0],
                  currency: currency,
                  availableValue: security[1].availableValue,
                  balance: security[1].balance,
                  haircut: security[1].haircut,
                  marketValue: security[1].marketValue,
                  notionalValue: security[1].notionalValue,
                });
              });
            }

            balancesList.push({
              account: name,
              currency: currency,
              balance: balanceObj.balance,
              buyingPower: balanceObj.buyingPower,
              openOrders: balanceObj.openOrders,
              marginRequirement: balanceObj.marginRequirement,
              unsettledFunds: balanceObj.unsettledFunds,
              capitalRequirement: balanceObj.capitalRequirement,
              excessCapital: balanceObj.excessCapital,
              totalSecurityAvailableValue: balanceObj.totalSecurityAvailableValue,
              totalSecurityNotionalValue: balanceObj.totalSecurityNotionalValue,
              securities: securities,
              alternateCapitalRequirement: balanceObj.alternateCapitalRequirement,
              alternateCapitalRequirementCurrency: balanceObj.alternateCapitalRequirementCurrency,
            });
          });
        }

        this.props.fetchSecurityDefinitions();
        this.props.fetchBalancesSucceed(balancesList);
      }

      if (err) {
        let shouldHideBalances = Env.getEnv("REACT_APP_HIDE_BALANCES");
        if (!!shouldHideBalances && !isNullOrUndefined(shouldHideBalances)) {
          shouldHideBalances = shouldHideBalances.toString().toLowerCase() === "true" || shouldHideBalances === 1;
        } else {
          shouldHideBalances = false;
        }

        if (!shouldHideBalances || err.code !== StatusCode.UNIMPLEMENTED) {
          Notification.error(`Cannot list balances: ${err.message}`)
        }
        this.props.fetchBalancesFailed(err);
      }
    };

    AccountService.listBalances(name, cb);
  };

    loadPendingCredits = (name) => {
    this.props.fetchPendingCreditsStart()
    const cb = (err, response) => {
      if (response) {
        let creditsList = response.getCreditsList().map(pc => pc.toObject());
        this.props.fetchPendingCreditsSucceed(creditsList)
      }
    }
    AccountService.listPendingCredits(name, null, cb);
  }

  openCreateAccount = () => {
    this.context.router.history.push(`/accounts/new`);
  };

  selectTab = (selectedTab) => {
    let { firm, account } = this.props;

    switch (selectedTab) {
      case "profile":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}`
        );
      case "users":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}/users`
        );
      case "positions":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}/positions`
        );
      case "balances":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}/balances`
        );
      case "ledger":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}/ledger`
        );
      case "ledger/balance-ledger":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}/ledger/balance-ledger`
        );
      case "ledger/position-ledger":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}/ledger/position-ledger`
        );
      case "whitelist":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}/whitelist`
        );
      case "commissions":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}/commissions`
        );
      case "association":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}/association`
        );
      case "bankDetails":
        return this.context.router.history.push(
          `/firms/${firm.id}/accounts/${account.id}/bankDetails`
        );
      default:
        console.error("No tab found!");
        break;
    }
  };

  render() {
    const path = getFormMode(this.props.location.pathname, (tab) => {
      this.props.setCurrentTab(tab);
    });

    return (
      <div className="with-callback">
        <AccountHeader
          headerText="Account"
          areTabsVisible={path !== FormModes.creation}
          isAddButtonVisible={hasWriteAccess() && path !== FormModes.creation}
          selectTab={this.selectTab}
          selectedTab={this.props.currentTab}
          account={this.props.account}
          firms={this.props.firms}
          firm={this.props.firm}
        />
        <Loader show={this.props.fetchingAccount} />
        <AccountFormContainer
          item={this.props.account}
          firm={this.props.match.params.firmid ? this.props.firm : null}
          history={this.context.router.history}
          reloadPositions={(filters) => this.loadPositions(this.props.account.name, filters)}
          reloadBalances={() => {
            this.loadBalances(this.props.account.name);
            this.loadWithdrawals(this.props.account.name);
          }}
          reloadCredits={() => {
            this.loadPendingCredits(this.props.account.name);
          }}
          onPositionExport={this.exportPositions}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountFormPage)
);
