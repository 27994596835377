import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import confirm from '../modules/confirmDialog';
import {
  SaveParticipantGroupStart,
  SaveParticipantGroupSucceed,
  SaveParticipantGroupFailed,
  IsCreatingParticipantGroup,
} from '../actions/participantGroups';

import Notification from '../modules/notifications';
import { StatusCode } from 'grpc-web';
import ParticipantGroupMapper from '../modules/mappers/participantGroupMapper';
import ParticipantGroupDetails from '../components/participant-group/ParticipantGroupDetails';
import { FormModes } from '../constants/enumerations';
import ParticipantGroupService from '../services/ParticipantGroupService';

const SuspendGroupMessage = ({ suspend }) => {
  if (suspend) {
    return (
      <div>
        <span className="warning-message">WARNING.</span>
        This change will remove any working orders for the Group from all Markets. <br /><br />
        Are you sure you want to disable the Group.
        </div>
    )
  }
  return (
    <div>
      Are you sure you want to re-enable this Group?
      </div>
  )
};

function mapStateToProps(state) {
  return {
    fetchingParticipantGroup: state.participantGroups.fetchingParticipantGroup,
    isCreating: state.participantGroups.isCreating
  };
}

const mapDispatchToProps = (dispatch) => ({
  isCreating: (flag) => {
    dispatch(IsCreatingParticipantGroup(flag))
  },
  saveFormStart: () => {
    dispatch(SaveParticipantGroupStart())
  },
  saveFormSucceed: () => {
    dispatch(SaveParticipantGroupSucceed())
  },
  saveFormFailed: () => {
    dispatch(SaveParticipantGroupFailed())
  }
});

class ParticipantGroupFormContainer extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  saveParticipantGroup = (data, setFieldError) => {
    this.props.saveFormStart();
    const cb = (err, response) => {
      this.processSavedParticipantGroup(response, err, setFieldError);
    }

    const participantGroup = ParticipantGroupMapper.mapToParticipantGroup(data);
    ParticipantGroupService.post(participantGroup, cb);
  }

  setSuspendedGroup = (groupId, isSuspended) => {
    confirm(<SuspendGroupMessage suspend={isSuspended} />,
      {
        title: 'Group Confirmation',
        okButtonText: 'Yes',
        cancelButtonText: 'No'
      }
    ).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            this.context.router.history.push('/participant-groups');
            Notification.success('Participant group updated.');
          }

          if (err) {
            Notification.error(`Error when updating participant group: \n ${err.message}`);
          }
        };

        ParticipantGroupService.suspend({ id: groupId, isSuspended: isSuspended }, cb)
      },
      () => {
      });
  }

  remove = groupId => {
    confirm('Are you sure you want to remove the participant group?',
      {
        title: 'Participant Group Confirmation',
        okButtonText: 'Yes',
        cancelButtonText: 'No'
      }
    ).then(
      () => {

        const cb = (err, response) => {
          if (response) {
            this.context.router.history.push('/participant-groups');
            Notification.success('Participant group deleted.');
          }

          if (err) {
            Notification.error(`Error when deleting participant group: \n ${err.message}`);
          }
        };

        ParticipantGroupService.delete(groupId, cb)
      },
      () => {
      });
  }

  processSavedParticipantGroup = (response, err, setFieldError) => {
    if (response && response.getParticipantGroup()) {
      this.props.saveFormSucceed();
      this.context.router.history.push('/participant-groups');
      Notification.success('Participant Group saved');
    }

    if (err) {
      this.props.saveFormFailed();
      this.processFormError(err, setFieldError);
    }
  }

  processFormError = (error, setFieldError) => {
    switch (error.code) {
      case StatusCode.ALREADY_EXISTS:
        setFieldError('id', error.message, false);
        Notification.error(`Failed to save this participant group, ID has already been used.`);
        break;
      default:
        Notification.error(`Failed to save this participant group. ${error.message}`);
        break;
    }
  }

  getFormMode = () => {
    if (this.props.location.pathname.indexOf('/edit') > 0) {
      this.props.isCreating(false);
      return FormModes.edition;
    } else if (this.props.location.pathname.indexOf('/new') > 0) {
      this.props.isCreating(true);
      return FormModes.creation;
    } else {
      this.props.isCreating(false);
      return FormModes.view;
    }
  }

  render() {
    return (
      <ParticipantGroupDetails
        onSubmitForm={this.saveParticipantGroup}
        onDisableGroup={(id, isSuspended) => { this.setSuspendedGroup(id, isSuspended) }}
        onDelete={(id) => { this.remove(id) }}
        mode={this.getFormMode()}
      >
      </ParticipantGroupDetails>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ParticipantGroupFormContainer));
