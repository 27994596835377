import ApiCall from "../modules/apiCall";
import ProtobufParser from "../modules/protobufParser";
import { apiUrl } from "../modules/util";
import {getAccessToken} from "./TokenService";
import { isNullOrUndefined } from '../modules/util';

const {
  CancelOrderRequest,
  FindAndCancelOrdersRequest,
  DownloadOrdersRequest,
  DownloadExecutionsRequest,
  SearchOrdersRequest,
  SearchExecutionsRequest,
  DownloadTraceRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");
const {
  AdminAPIClient,
  AdminAPIPromiseClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");

const client = new AdminAPIClient(apiUrl(), null, null);
const promiseClient = new AdminAPIPromiseClient(apiUrl(), null, null);

export default class OrderService {
  static cancelOrder(id, cb) {
    var request = new CancelOrderRequest();
    request.setOrderId(id);

    ApiCall(client, "cancelOrder", request, cb);
  }

  static findAndCancelOrders(symbol, cb) {
    var request = new FindAndCancelOrdersRequest();
    request.setSymbol(symbol);

    ApiCall(client, "findAndCancelOrders", request, cb);
  }

  static searchOrders(dataRequest) {
    const { filters, pageToken, onRefresh, onError, pageSize } = dataRequest;
    const cbLoop = (err, response) => {
      if (response) {
        const orders = response.getOrdersList();
        const nextPageToken = response.getNextPageToken();
        onRefresh({ orders, nextPageToken, pageToken });
      }
      if (err) {
        onError(err);
      }
    };

    var request = this.buildSearchOrdersRequest(filters);
    request.setPageSize(pageSize ? pageSize : 10);
    if (pageToken) request.setPageToken(pageToken);

    ApiCall(client, "searchOrders", request, cbLoop);
  }

  static getLastExecution(id) {
    var request = new SearchExecutionsRequest();
    request.setOrderId(id);
    request.setPageSize(1);
    request.setNewestFirst(true);

    return promiseClient.searchExecutions(request,
        { authorization: getAccessToken() });
  }

  static searchExecutions(dataRequest) {
    const { filters, pageToken, onRefresh, onError, pageSize, onEnd} = dataRequest;
    const cbLoop = (err, response) => {
      if (response) {
        const executions = response.getExecutionsList();
        const nextPageToken = response.getNextPageToken();
        const eof = response.getEof();
        onRefresh({
          executions,
          pageToken,
          nextPageToken,
          eof,
        });
        onEnd();
      }
      if (err) {
        onError(err);
      }
    };

    var request = this.buildSearchOrdersRequest(filters, true);
    request.setPageSize(pageSize ? pageSize : 10);
    request.setNewestFirst(true);
    if (pageToken) request.setPageToken(pageToken);
    ApiCall(client, "searchExecutions", request, cbLoop);
  }

  static async downloadOrdersReport(
    filters,
    isExecutions,
    onSuccess,
    onError
  ) {
    let request = isExecutions ? new DownloadExecutionsRequest() : new DownloadOrdersRequest();
    if (isExecutions) {
      request.setSearchExecutionsRequest(this.buildSearchOrdersRequest(filters, isExecutions));
    } else {
      request.setSearchOrdersRequest(this.buildSearchOrdersRequest(filters, isExecutions));
    }

    var call = ApiCall(client, isExecutions ? "downloadExecutions" : "downloadOrders", request);
    var chunks = [];
    call.on("data", data => chunks.push(data.getFilechunk()));
    call.on("status", sts => {
      if (sts && sts.code === 0) onSuccess(chunks.join(""));
    });
    call.on("error", onError);
  }

  static downloadTrace(id, cb) {
    let request = new DownloadTraceRequest();
    request.setTraceId(id);

    ApiCall(client, "downloadTrace", request, cb);
  }

  static buildSearchOrdersRequest(filters, isExecutions = false) {
    var request = isExecutions ? new SearchExecutionsRequest() : new SearchOrdersRequest();
    request.setSymbol(filters.instrumentId);
    if (filters.fromDate)
      request.setStartTime(ProtobufParser.toTimestamp(filters.fromDate));
    if (filters.toDate)
      request.setEndTime(ProtobufParser.toTimestamp(filters.toDate));
    if (filters.participant) request.setParticipant(filters.participant);
    if (filters.clordid) request.setClordId(filters.clordid);
    if (filters.clientaccountid) request.setClientAccountId(filters.clientaccountid);
    if (filters.clientparticipantid) request.setClientParticipantId(filters.clientparticipantid);
    if (filters.orderid) request.setOrderId(filters.orderid);
    if (filters.parentorderid) request.setParentOrderId(filters.parentorderid);
    if (filters.symbolsubtype) request.setSymbolSubType(filters.symbolsubtype);
    if (filters.hostcrossid) request.setHostCrossId(filters.hostcrossid)

    if (isExecutions) {
      if (filters.accounts) request.setAccountsList(filters.accounts);
      if (!isNullOrUndefined(filters.executionType)) request.setExecTypesList([filters.executionType])
    } else {
      if (filters.account) request.setAccount(filters.account);
      if (filters.crossid) request.setCrossId(filters.crossid);
      if (filters.side) request.setSide(filters.side);
      if (filters.orderStateFilter) { request.setOrderStateFilter(filters.orderStateFilter); }
    }
    return request;
  }
}
