import React from "react";
import PropTypes from "prop-types";
import { FormLabel } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const PhoneNumber = ({ id, name, value, onChange, isRequired = false, label ="Phone Number" }) => (
  <>
    <FormLabel>{label}</FormLabel>
    {!!isRequired && <label className="form-label-required"> Required</label>}
    <PhoneInput
      id={id}
      name={name}
      defaultCountry="US"
      value={value}
      onChange={onChange}
    />
  </>
);

PhoneNumber.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default PhoneNumber;
