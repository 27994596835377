import React from "react";
import PropTypes from "prop-types";
import { FormGroup, FormLabel } from "react-bootstrap";
import DropdownList from "../DropdownList";
import { isNullOrUndefined } from "../../../modules/util";
import { getIn } from 'formik';
import Tooltip from '../tooltip-html/tooltip-html';

const DropdownListGroup = ({
  id,
  name,
  data,
  label,
  isRequired,
  value,
  enabled,
  itemComponent,
  dropUp,
  errors,
  touched,
  onChange,
  onBlur,
  textField,
  valueField,
  plaintext,
  showFilter,
  tooltip,
  tooltipPlace,
  selectIcon
}) => {
  const getDisplayText = () => {
    if (isNullOrUndefined(value)) return "";

    const valFld = valueField ? valueField : "id";

    const txtFld = textField ? textField : "name";

    let selectedItem = null;

    if (data) {
      selectedItem = data.find(obj => typeof obj === "object" ? obj[valFld] === value : obj === value);
    }

    if (textField && typeof selectedItem === "object") {
      return selectedItem[txtFld];
    }

    return selectedItem ? selectedItem : "";
  }

  const error = getIn(errors, name);

  return (
    <FormGroup id={id}>
      {label && <FormLabel>{label}</FormLabel>}

      {!isNullOrUndefined(tooltip) &&
        <Tooltip
          text={tooltip}
          id={`tip-${id}`}
          place={!!tooltipPlace ? tooltipPlace : 'top'}
        ></Tooltip>
      }

      {plaintext && <div className="form-readonly-label">{getDisplayText()}</div>}

      {!plaintext &&
        <>
          {isRequired && <label className="form-label-required"> Required</label>}
          <DropdownList
            id={id}
            name={name}
            data={data}
            value={value}
            dropUp={dropUp}
            enabled={enabled}
            onChange={onChange}
            onBlur={onBlur}
            itemComponent={itemComponent}
            textField={textField}
            valueField={valueField}
            errors={errors && errors[name] ? true : false}
            showFilter={showFilter}
            selectIcon={selectIcon}
          />
          {error ? (
          <div className="form-error">
            <i
              className="fa fa-exclamation-triangle orange-icon"
              aria-hidden="true"
            ></i>{" "}
            {error}
          </div>
          ) : errors && errors[name] && (
            <div className="form-error">
              <i
                className="fa fa-exclamation-triangle orange-icon"
                aria-hidden="true"
              ></i>{" "}
              {errors[name]}
            </div>
          )}
        </>
      }
    </FormGroup>
  )
};

DropdownListGroup.defaultProps = {
  enabled: true,
  dropUp: false,
  isRequired: false,
  value: null,
};

DropdownListGroup.propTypes = {
  enabled: PropTypes.bool,
  dropUp: PropTypes.bool,
  value: PropTypes.any,
  isRequired: PropTypes.bool,
  itemComponent: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  textField: PropTypes.string,
  valueField: PropTypes.string,
};

export default DropdownListGroup;
