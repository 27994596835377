import React, { Component } from 'react';
import { withFormik } from 'formik';
import DateGroup from '../../../core/form/DateGroup';
import ButtonSecondary from '../../../core/form/ButtonSecondary';
import {
  Col, Form
} from 'react-bootstrap';
import moment from 'moment';

const Filter_Action = "filter";
const Reset_Action = "reset";
class BasicMarketStatsFilterForm extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      handleBlur,
      setFieldValue,
    } = this.props;

    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} lg={2} xs={2} md={2}>
              <Form.Label>Transaction Time From</Form.Label>
              <DateGroup
                id="fromDate"
                name="fromDate"
                enableTime={true}
                step={15}
                value={values.fromDate ? new Date(values.fromDate) : null}
                onChange={e => setFieldValue('fromDate', e)}
                defaultCurrentDate={values.fromDate}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} lg={2} xs={2} md={2}>
              <Form.Label>Transaction Time To</Form.Label>
              <DateGroup
                id="toDate"
                name="toDate"
                enableTime={true}
                step={15}
                value={values.toDate ? new Date(values.toDate) : null}
                onChange={e => setFieldValue('toDate', e)}
                defaultCurrentDate={values.toDate}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Form.Group>
            <div className="form-group col-auto align-self-end" >
              <button
                className="Filter-Button"
                style={{ marginBottom: "18px", width: "100px" }}
                type="button"
                onClick={() => {
                  values.action = Filter_Action;
                  handleSubmit();
                }}>Filter</button>
            </div>
            <div className="form-group col-auto align-self-end">
              <ButtonSecondary
                style={{ marginBottom: "18px", width: "100px" }}
                text="Reset"
                type="button"
                onClick={() => {
                  values.action = Reset_Action;
                  handleSubmit();
                }}
              />
            </div>
          </Form.Row>
        </Form>
      </React.Fragment>
    );
  }
};

const MarketStatsFilterForm = withFormik({
  mapPropsToValues: (filters) => ({
    ...filters.filters
  }),
  validate: values => {
    let errors = {};
    if (values.fromDate !== null && values.toDate !== null
      && values.fromDate >= values.toDate)
      errors.fromDate = 'Start date must be before End Date.';
    return errors;
  },
  handleSubmit: (values, formikBag) => {
    const { resetForm, props, setFieldError } = formikBag;
    if (values.action === Reset_Action) {
      var start = moment().startOf("day").toDate()
      var end = moment().endOf("day").toDate()
     
      var resetValues = {
        fromDate: start,
        toDate: end,
      }

      resetForm(resetValues);
      props.onSubmitForm(resetValues, setFieldError)
    } else {
      props.onSubmitForm(values, setFieldError);
    }
  },
  displayName: 'order-history-filter-form',
  enableReinitialize: true
})(BasicMarketStatsFilterForm);

export default MarketStatsFilterForm;