import { convertEnumToDropdownList } from "./util";
import { AdminRequestStatus, AdminRequestReturnStatus } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import FormatString, { StringFormatOptions } from "./formatString"

export default class AdminRequestsStaticData {

  /**
   * 
   * @param {[ids:int]} excludeIds 
   * @returns {[{id:int, name:string}]} options list
   */

  static AdminRequestStatus(excludeIds) {
    return convertEnumToDropdownList(AdminRequestStatus, excludeIds, new StringFormatOptions(FormatString.stringFormats.titleCase, false));
  }

  static AdminRequestReturnStatus(excludeIds) {
    return convertEnumToDropdownList(AdminRequestReturnStatus, excludeIds, new StringFormatOptions(FormatString.stringFormats.titleCase, false));
  }
}
