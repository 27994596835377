import React, { Component } from 'react';
import { withFormik } from 'formik';
import DateGroup from '../core/form/DateGroup';
import ButtonSecondary from '../core/form/ButtonSecondary';
import {
  Col, FormControl, Form
} from 'react-bootstrap';
import LoaderIcon from '../core/loader/LoaderIcon';
import AutoComplete from '../core/auto-complete/AutoComplete';
import { Side, OrderStateFilter } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb';
import moment from 'moment';
import OrderHistoryAdditionalFiltersForm from './OrderHistoryAdditionalFiltersForm'

const Filter_Action = "filter";
const Additional_Filter_Action = "additionalfilter";
const Export_Action = "export";
const Export_Executions = "exportexecutions";
const Reset_Action = "reset";
class BasicOrderHistoryFilterForm extends Component {
  render() {
    const {
      loading,
      values,
      parent,
      errors,
      touched,
      handleSubmit,
      handleBlur,
      handleChange,
      setFieldValue,
      autoCompleteDataSource,
      onRefreshAutoComplete
    } = this.props;

    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} lg={2} xs={2} md={2}>
              <Form.Label>Symbol</Form.Label>
              <AutoComplete
                id="instrumentId"
                placeholder="Symbol"
                noItemsMessage="No instruments found"
                dataSource={autoCompleteDataSource}
                refreshDataSource={onRefreshAutoComplete}
                defaultValue={values.instrumentId ? values.instrumentId : null}
                onChange={(e) => {
                  if (e.target.value === '') {
                    setFieldValue("instrumentId", null);
                  }
                  handleChange(e);
                }}
                onEnter={(value) => {
                  setFieldValue("instrumentId", value);
                }}
                onSelect={(value) => {
                  setFieldValue("instrumentId", value);
                }}
                errors={errors}
                disable={true}
              />
            </Form.Group>
            <Form.Group as={Col} lg={2} xs={2} md={2}>
              {parent === "OrdersSearchPage" ? <Form.Label>Receive Time From</Form.Label> : <Form.Label>Transaction Time From</Form.Label>}
              <DateGroup
                id="fromDate"
                name="fromDate"
                enableTime={true}
                step={15}
                value={values.fromDate ? new Date(values.fromDate) : null}
                onChange={e => setFieldValue('fromDate', e)}
                defaultCurrentDate={values.fromDate}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} lg={2} xs={2} md={2}>
              {parent === "OrdersSearchPage" ? <Form.Label>Receive Time To</Form.Label> : <Form.Label>Transaction Time To</Form.Label>}
              <DateGroup
                id="toDate"
                name="toDate"
                enableTime={true}
                step={15}
                value={values.toDate ? new Date(values.toDate) : null}
                onChange={e => setFieldValue('toDate', e)}
                defaultCurrentDate={values.toDate}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} lg={2} xs={2} md={2}>
              <Form.Label>Order ID</Form.Label>
              <FormControl
                id="orderid"
                name="orderid"
                type="text"
                defaultValue={values.orderid}
                placeholder="Order ID"
                onChange={e => setFieldValue('orderid', e.target.value)}
                onBlur={e => setFieldValue('orderid', e.target.value)}
              />
            </Form.Group>
            <div className="form-group col-auto align-self-end" >
              <button
                className="Filter-Button"
                style={{ marginBottom: "18px", width: "100px" }}
                type="button"
                onClick={() => {
                  values.action = Filter_Action;
                  handleSubmit();
                }}>Filter</button>
            </div>
            <div className="form-group col-auto align-self-end">
              <ButtonSecondary
                style={{ marginBottom: "18px", width: "100px" }}
                text="Reset"
                type="button"
                onClick={() => {
                  values.action = Reset_Action;
                  handleSubmit();
                }}
              />
            </div>
            {parent === "OrdersSearchPage" && <>
              <div className="form-group col-auto align-self-end">
                <ButtonSecondary
                  text="Export Orders"
                  type="button"
                  style={{ marginBottom: "18px" }}
                  onClick={() => {
                    values.action = Export_Action;
                    handleSubmit();
                  }}
                />
              </div>
            </>}
            {parent === "ExecutionsSearchPage" && <>
              <div className="form-group col-auto align-self-end">
                <ButtonSecondary
                  text="Export Executions"
                  type="button"
                  style={{ marginBottom: "18px" }}
                  onClick={() => {
                    values.action = Export_Executions;
                    handleSubmit();
                  }}
                />
              </div>
            </>}
            <Form.Group as={Col} style={{ marginTop: "30px" }}>
              <div className="form-group col-auto align-self-end">
                <LoaderIcon show={loading}></LoaderIcon>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} style={{ marginBottom: "2px" }}>
              <OrderHistoryAdditionalFiltersForm
                values={values}
                setFieldValue={setFieldValue}
                setAction={() => {values.action = Additional_Filter_Action}}
                parent={parent}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </React.Fragment>
    );
  }
};

const OrderHistoryFilterForm = withFormik({
  mapPropsToValues: (filters) => ({
    ...filters.filters
  }),
  validate: values => {
    let errors = {};
    if (values.fromDate !== null && values.toDate !== null
      && values.fromDate >= values.toDate)
      errors.fromDate = 'Start date must be before End Date.';
    return errors;
  },
  handleSubmit: (values, formikBag) => {
    const { resetForm, props, setFieldError } = formikBag;
    if (values.action === Reset_Action) {
      var start = moment().startOf("day").toDate()
      var end = moment().endOf("day").toDate()
     
      var resetValues = {
        fromDate: start,
        toDate: end,
        instrumentId: null,
        participantDisplayName: "",
        participant: "",
        accountDisplayNames: "",
        accounts: "",
        accountDisplayName: "",
        account: "",
        clientaccountid: "",
        clientparticipantid: "",
        clordid: "",
        orderid: "",
        crossid: "",
        parentorderid: "",
        symbolsubtype: "",
        side: Side.SIDE_UNDEFINED,
        orderStateFilter: OrderStateFilter.ORDER_STATE_FILTER_UNDEFINED
      }

      resetForm(resetValues);
    } else if (values.action !== Additional_Filter_Action){
      props.onSubmitForm(values, setFieldError);
    }
  },
  displayName: 'order-history-filter-form',
  enableReinitialize: true
})(BasicOrderHistoryFilterForm);

export default OrderHistoryFilterForm;