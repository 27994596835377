import ProtobufParser from "../modules/protobufParser";

const { RaiseAlertRequest, ListAlertsRequest, UpdateAlertRequest, UpdateAlertNotesRequest } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/alert/v1beta1/alert_api_pb");

export class Alert {
  constructor(item) {
    this.id = null;
    this.status = null;
    this.tradeId = null;
    this.venue = null;
    this.alertName = null;
    this.description = null;
    this.alertTime = null;
    this.symbol = null;
    this.accounts = null;
    this.firm = null;
    this.notes = null;
    this.notesEditHistory = [];
    this.metadata = new Map();
    this.filterStartTime = null;
    this.filterEndTime = null;
    this.positionLedgerReferenceId = null;

    if (!item) return;

    this.id = item.getId();
    this.status = item.getStatus();
    this.tradeId = item.getTradeId();
    this.venue = item.getVenue();
    this.alertName = item.getAlertName();
    this.description = item.getDescription();
    this.notes = item.getNotes();
    this.notesEditHistory = item.getNotesEditHistoryList();
    item.getMetadataMap().forEach((k, v) => this.metadata.set(k, v));
    this.positionLedgerReferenceId = item.getPositionLedgerReferenceId();

    const alertTime = item.getAlertTime();
    if (!!alertTime) this.alertTime = alertTime.toDate();
    const filterStartTime = item.getFilterStartTime();
    if (!!filterStartTime) this.filterStartTime = filterStartTime.toDate();
    const filterEndTime = item.getFilterEndTime();
    if (!!filterEndTime) this.filterEndTime = filterEndTime.toDate();

    this.symbol = item.getSymbol();
    this.accounts = item.getAccountsList();
    this.firm = item.getFirm();
  }
}

export class RaiseAlertReq {
  constructor(item) {
    this.tradeId = null;
    this.venue = null;
    this.alertName = null;
    this.description = null;
    this.alertTime = null;
    this.symbol = null;
    this.accounts = null;
    this.firm = null;

    if (!item) return;

    this.tradeId = item.getTradeId();
    this.venue = item.getVenue();
    this.alertName = item.getAlertName();
    this.description = item.getDescription();
    this.alertTime = item.getAlertTime();
    this.symbol = item.getSymbol();
    this.accounts = item.getAccountsList();
    this.firm = item.getFirm();
  }

  toProto = () => {
    const request = new RaiseAlertRequest();

    if (!!this.tradeId) request.setTradeId(this.tradeId);
    if (!!this.venue) request.setVenue(this.venue);
    if (!!this.alertName) request.setAlertName(this.alertName);
    if (!!this.description) request.setDescription(this.description);

    if (!!this.alertTime) {
      const alertTime = ProtobufParser.toTimestamp(this.alertTime);
      request.setAlertTime(alertTime);
    }

    if (!!this.symbol) request.setSymbol(this.symbol);
    if (!!this.accounts) request.setAccountsList(this.accounts);
    if (!!this.firm) request.setFirm(this.firm);

    return request;
  }
}

export class ListAlertsReq {
  constructor() {
    this.pageSize = null;
    this.pageToken = null;
    this.id = null;
    this.statusesList = null;
    this.tradeId = null;
    this.venue = null;
    this.alertName = null;
    this.description = null;
    this.startTime = null;
    this.endTime = null;
    this.symbol = null;
    this.account = null;
    this.firm = null;
    this.newestFirst = true;
  }

  toProto = () => {
    const request = new ListAlertsRequest();

    if (!!this.pageSize) request.setPageSize(this.pageSize);
    if (!!this.pageToken) request.setPageToken(this.pageToken);
    if (!!this.id) request.setId(this.id);
    if (!!this.statusesList) request.setStatusesList(this.statusesList);
    if (!!this.tradeId) request.setTradeId(this.tradeId);
    if (!!this.venue) request.setVenue(this.venue);
    if (!!this.alertName) request.setAlertName(this.alertName);
    if (!!this.description) request.setDescription(this.description);

    if (!!this.startTime) {
      const startTime = ProtobufParser.toTimestamp(this.startTime);
      request.setStartTime(startTime);
    }

    if (!!this.endTime) {
      const endTime = ProtobufParser.toTimestamp(this.endTime);
      request.setEndTime(endTime);
    }

    if (!!this.symbol) request.setSymbol(this.symbol);
    if (!!this.account) request.setAccount(this.account);
    if (!!this.firm) request.setFirm(this.firm);
    if (!!this.newestFirst) request.setNewestFirst(!!this.newestFirst);

    return request;
  }
}

export class UpdateAlertReq {
  constructor() {
    this.id = null
    this.status = null;
  }

  toProto = () => {
    const request = new UpdateAlertRequest();
    
    if (!!this.id) request.setId(this.id)
    if (!!this.status) request.setStatus(this.status)

    return request;
  }
}

export class UpdateAlertNotesReq {
  constructor() {
    this.id = null
    this.notes = null;
  }

  toProto = () => {
    const request = new UpdateAlertNotesRequest();

    if (!!this.id) request.setId(this.id)
    if (!!this.notes) request.setNotes(this.notes)

    return request;
  }
}
