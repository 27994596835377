import {
    FETCH_SEARCH_INSTRUMENT_STATS_START,
    FETCH_SEARCH_INSTRUMENT_STATS_SUCCEED,
    FETCH_SEARCH_INSTRUMENT_STATS_FAILED
  } from '../constants/instrumentTypes';
      
  const initialState = {
    fetchingInstrumentStats: false,
    stats: [],
    pageToken: null,
    nextPageToken: null,
    tokens: {}
  };
  
  const instrumentStats = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SEARCH_INSTRUMENT_STATS_START:
        return {
          ...state,
          fetchingInstrumentStats: true,
        };
      case FETCH_SEARCH_INSTRUMENT_STATS_SUCCEED:
        const pageTokens = { ...state.tokens };
        pageTokens[action.payload.pageToken] = action.payload.nextPageToken;

        return {
            ...state,
            fetchingInstrumentStats: false,
            stats:[...action.payload.statsList],
            pageToken: action.payload.pageToken,
            nextPageToken: action.payload.nextPageToken,
            tokens: pageTokens,
        };
      case FETCH_SEARCH_INSTRUMENT_STATS_FAILED:
        return {
            ...state,
            fetchingInstrumentStats: false
        }
        
      default:
        return state;
    }
  };
  
  export default instrumentStats;
  