import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  FetchAccountsStart,
  FetchAccountsSucceed,
  FetchAccountsFailed,
  CleanAccount,
  ToggleShowDeletedAccounts,
} from "../actions/accounts";
import { LoadFirms } from "../actions/firms";
import Loader from "../components/core/loader/Loader";
import Account from "../entities/Account";
import "./Content.css";
import ParticipantHeader from "../components/participant/ParticipantHeader";
import AccountDataGrid from "../components/account/AccountDataGrid";
import { AdminDocumentTitle } from "../constants/strings";
import AccountService from "../services/AccountService";
import Notification from "../modules/notifications";
import { hasWriteAccess } from "../services/TokenService";
import { getHelmet, isArrayWithValues, isNumber } from "../modules/util";
import confirm from "../modules/confirmDialog";
import CheckboxGroup from "../components/core/form/CheckboxGroup";
import AccountsFilter from "../components/account/AccountsFilter";
import { Col, Row } from "react-bootstrap";
import AccountStaticData from "../modules/accountStaticData";
import { NavigationManager } from "../components/core/helpers/NavigationHelper";

const { ListAccountsRequest } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");

function mapStateToProps(state) {
  return {
    accounts: state.accounts,
    firms: state.firms,
    showDeletedAccounts: state.accounts.showDeletedAccounts,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchGroupsStart: () => {
    dispatch(FetchAccountsStart());
  },
  fetchGroupsSucceed: (accountList) => {
    dispatch(FetchAccountsSucceed(accountList));
  },
  fetchGroupsFailed: () => {
    dispatch(FetchAccountsFailed());
  },
  toggleShowDeletedAccounts: () => {
    dispatch(ToggleShowDeletedAccounts());
  },
  cleanAccount: () => {
    dispatch(CleanAccount());
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
});

class Accounts extends Component {

  constructor() {
    super();
    this.state = {
      accounts: null,
      oldFilter: { firm: '' },
      accountStates: AccountStaticData.AccountStates,
      collateralAccounts: [],
      filter: {
        "accountName": null,
        "firm": "",
        "accountState": null,
        "collateralAccount": null,
        "riskSystem": null
      }
    };
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.props.cleanAccount();
    this.loadAccounts(this.props.showDeletedAccounts);
    this.props.loadFirms();
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(props, state) {
    if (!!state) {
      if (!isArrayWithValues(state.accounts)) {
        return { accounts: Accounts.filterData(props.accounts.accounts, state.filter) };
      }
    }
    return null;
  }

  static contextTypes = {
    router: PropTypes.object,
  };

  openCreateAccount = () => {
    this.context.router.history.push(`/accounts/new`);
  };

  loadAccounts = (showDeletedAccounts, firm = "", properties = []) => {
    this.props.fetchGroupsStart();
    const cb = (err, response) => {
      if (response) {
        const accountsList = response.getAccountsList().map((acct) => {
          return new Account(acct);
        });
        this.props.fetchGroupsSucceed(accountsList);
      }

      if (err) {
        this.props.fetchGroupsFailed();
        Notification.error("Cannot get accounts list.");
      }
      this.setState({ accounts: null, oldFilter: { firm: firm } });
    };

    var request = new ListAccountsRequest();
    if (!!firm) {
      request.setFirm(firm);
    }

    if (showDeletedAccounts) {
      request.setShowDeleted(true);
    }

    if (isArrayWithValues(properties)) {
      request.setPropertiesList(properties);
    }

    AccountService.listAccounts(request, cb);
  };

  openCreateAccount = () => {
    this.context.router.history.push(`/accounts/new`);
  };

  openView = (accountName, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/${accountName}`);
    } else {
      this.context.router.history.push(`/${accountName}`);
    }
  };

  openEdit = (accountName, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/${accountName}/edit`);
    } else {
      this.context.router.history.push(`/${accountName}/edit`);
    }
  };


  onRemove = (name) => {
    confirm("Are you sure you want to delete the account?", {
      title: "Account Deletion",
      okButtonText: "Delete",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            this.loadAccounts(this.props.showDeletedAccounts);
            Notification.success("Account deleted.");
          }

          if (err) {
            Notification.error(
              `Error when deleting account: \n ${err.message}`
            );
          }
        };

        AccountService.delete(name, cb);
      },
      () => { }
    );
  };

  unDeleteAccount = async (acctName) => {
    confirm("Are you sure you want to restore this account?", {
      title: "Account Restore Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            this.loadAccounts(this.props.showDeletedAccounts);
            Notification.success(`Account restored.`);
          }

          if (err) {
            console.error("could not restore account", err);
            Notification.error("Could not restore account", acctName);
          }
        };

        AccountService.undelete(acctName, cb);
      },
      () => { }
    );
  };

  filterAccounts = (accountName, accountState, collateralAccountName, riskSystem, firm, properties) => {
    this.setState({
      filter: {
        "accountName": accountName,
        "firm": !!firm ? firm : "",
        "accountState": accountState,
        "collateralAccount": collateralAccountName,
        "riskSystem": riskSystem,
        "properties": properties,
      }
    }, () => {
      if (this.state.filter.firm.firm !== this.state.oldFilter.firm || this.state.filter.properties !== this.state.oldFilter.properties) {
        this.loadAccounts(this.props.showDeletedAccounts, firm ? firm.firm : "", !!properties ? properties.map(prop => prop.value) : []);
      } else {
        this.setState({ accounts: Accounts.filterData(this.props.accounts.accounts, this.state.filter) });
      }
    });
  }

  static filterData = (data, filter) => {
    if (!!filter) {
      const accountName = filter.accountName ? filter.accountName.value : null;
      let acReg = new RegExp(accountName, "i");

      const collateralAccount = filter.collateralAccount ? filter.collateralAccount.value : null;
      let acCollatReg = new RegExp(collateralAccount, "i");
      
      let filteredData = data.filter(
        (acc) => {
          let res = true;
          if (!!filter.accountName) {
            res = res && !!acc.displayName.match(acReg)
          }

          if (!!res && filter.accountState && isNumber(filter.accountState.state)) {
            res = res && acc.state.id === filter.accountState.state
          }

          if (!!res && !!filter.collateralAccount){
            res = res && !!acc.collateralAccount.match(acCollatReg)
          }

          if (!!res && !!filter.riskSystem){
            res = res && acc.riskSystem === filter.riskSystem.riskSystem
          }

          return res;
        }
      );
      return filteredData;
    }

    return data;
  }

  render() {
    let { accounts, firms } = this.props;

    return (
      <div className="with-callback">
        <ParticipantHeader
          headerText={getHelmet({})}
          buttonText="ADD NEW ACCOUNT"
          buttonClick={() => {
            this.openCreateAccount();
          }}
          isAccountsSelected={true}
          isAddButtonVisible={hasWriteAccess()}
        />
        <Row>
          <Col lg={10} xs={10} md={10}>
            <AccountsFilter onApplyFilter={(accountName, accountState, collateralAccountName, riskSystem, firm, properties) => {
              this.filterAccounts(accountName, accountState, collateralAccountName, riskSystem, firm, properties);
            }} filter={this.state.filter} />
          </Col>
          <Col lg={2} xs={2} md={2} style={{ marginTop: "40px" }}>
            <CheckboxGroup
              checked={this.props.showDeletedAccounts}
              onChange={(e) => {
                this.props.toggleShowDeletedAccounts();
                this.loadAccounts(!this.props.showDeletedAccounts);
              }}
              label={"Show Deleted Accounts"} />
          </Col>
        </Row>

        <Loader show={accounts.fetchingAccounts}></Loader>

        <AccountDataGrid
          data={(!!this.state && !!this.state.accounts) ? this.state.accounts : []}
          allAccounts={accounts.accounts}
          firms={firms.firms}
          onView={(accountName, actionContext) => {
            this.openView(accountName, actionContext);
          }}
          onEdit={(accountName, actionContext) => {
            this.openEdit(accountName, actionContext);
          }}
          onRemove={(accountName) => this.onRemove(accountName)}
          onUnDelete={(accountName) => {
            this.unDeleteAccount(accountName);
          }}
        ></AccountDataGrid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
