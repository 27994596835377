import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';

import Routes from './routes';
import {Env} from "./constants/environment";

class App extends Component {
  constructor() {
    super();

    if (!!Env.getEnv("REACT_APP_THEME_COLOR_BRAND")) {
      document.documentElement.style.setProperty('--APP_COLOR_BRAND', Env.getEnv("REACT_APP_THEME_COLOR_BRAND"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_LEFT_BAR_BG")) {
      document.documentElement.style.setProperty('--APP_COLOR_LEFT_BAR_BG', Env.getEnv("REACT_APP_THEME_COLOR_LEFT_BAR_BG"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_LEFT_BAR_CONTENT")) {
      document.documentElement.style.setProperty('--APP_COLOR_LEFT_BAR_CONTENT', Env.getEnv("REACT_APP_THEME_COLOR_LEFT_BAR_CONTENT"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_LEFT_BAR_ICON")) {
      document.documentElement.style.setProperty('--APP_COLOR_LEFT_BAR_ICON', Env.getEnv("REACT_APP_THEME_COLOR_LEFT_BAR_ICON"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_LEFT_BAR_ICON_ACTIVE")) {
      document.documentElement.style.setProperty('--APP_COLOR_LEFT_BAR_ICON_ACTIVE', Env.getEnv("REACT_APP_THEME_COLOR_LEFT_BAR_ICON_ACTIVE"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_PAGE_TITLE")) {
      document.documentElement.style.setProperty('--APP_COLOR_PAGE_TITLE', Env.getEnv("REACT_APP_THEME_COLOR_PAGE_TITLE"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_PAGE_SUBTITLE")) {
      document.documentElement.style.setProperty('--APP_COLOR_PAGE_SUBTITLE', Env.getEnv("REACT_APP_THEME_COLOR_PAGE_SUBTITLE"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_PAGE_MENU")) {
      document.documentElement.style.setProperty('--APP_COLOR_PAGE_MENU', Env.getEnv("REACT_APP_THEME_COLOR_PAGE_MENU"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_PAGE_MENU_ACTIVE")) {
      document.documentElement.style.setProperty('--APP_COLOR_PAGE_MENU_ACTIVE', Env.getEnv("REACT_APP_THEME_COLOR_PAGE_MENU_ACTIVE"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TABLE_ICON")) {
      document.documentElement.style.setProperty('--APP_COLOR_TABLE_ICON', Env.getEnv("REACT_APP_THEME_COLOR_TABLE_ICON"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_BUTTON_PRIMARY")) {
      document.documentElement.style.setProperty('--APP_COLOR_BUTTON_PRIMARY', Env.getEnv("REACT_APP_THEME_COLOR_BUTTON_PRIMARY"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_BUTTON_GRADIENT")) {
      document.documentElement.style.setProperty('--APP_COLOR_BUTTON_GRADIENT', Env.getEnv("REACT_APP_THEME_COLOR_BUTTON_GRADIENT"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_BUTTON_SECONDARY")) {
      document.documentElement.style.setProperty('--APP_COLOR_BUTTON_SECONDARY', Env.getEnv("REACT_APP_THEME_COLOR_BUTTON_SECONDARY"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_FORM_LABEL")) {
      document.documentElement.style.setProperty('--APP_COLOR_FORM_LABEL', Env.getEnv("REACT_APP_THEME_COLOR_FORM_LABEL"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_FORM_CONTENT")) {
      document.documentElement.style.setProperty('--APP_COLOR_FORM_CONTENT', Env.getEnv("REACT_APP_THEME_COLOR_FORM_CONTENT"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_FORM_HELP")) {
      document.documentElement.style.setProperty('--APP_COLOR_FORM_HELP', Env.getEnv("REACT_APP_THEME_COLOR_FORM_HELP"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_FORM_REQUIRED")) {
      document.documentElement.style.setProperty('--APP_COLOR_FORM_REQUIRED', Env.getEnv("REACT_APP_THEME_COLOR_FORM_REQUIRED"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_CONTENT")) {
      document.documentElement.style.setProperty('--APP_COLOR_CONTENT', Env.getEnv("REACT_APP_THEME_COLOR_CONTENT"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_ERROR")) {
      document.documentElement.style.setProperty('--APP_COLOR_ERROR', Env.getEnv("REACT_APP_THEME_COLOR_ERROR"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TABLE_ALTROW")) {
      document.documentElement.style.setProperty('--APP_COLOR_TABLE_ALTROW', Env.getEnv("REACT_APP_THEME_COLOR_TABLE_ALTROW"));
    }
  }

  render() {
    return (
      <ConnectedRouter history={this.props.history}><Routes /></ConnectedRouter>
    );
  }
}

App.propTypes = {
  history: PropTypes.object
};

export default App;