export default class ParticipantCheckListItem {
  constructor(item) {
    if (item) {
      this.id = item.id;
      this.isSelected = false;
      this.name = item.name;
      this.displayName = item.displayName;
      return;
    }
    this.id = null;
    this.isSelected = null;
    this.name = null;
    this.displayName = null;
  }
}