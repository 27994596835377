import { convertDateToString } from '../modules/util';

export default class License {
  constructor(item) {
    const obj = item.toObject();
    this.clientIdentifier = obj.clientIdentifier;
    this.expirationTime = obj.expirationTime ? convertDateToString(item.getExpirationTime().toDate(), "YYYYMMDD-HH:mm:ss.SSS") : "";
    this.versionRange = obj.versionRange;
  }
  clientIdentifier = "";
  expirationTime = "";
  versionRange = "";
}
