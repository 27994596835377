import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckListDataGrid from './CheckListDataGrid';
import Dialog from '../dialog';

class CheckListPopup extends Component {
  render() {
    return (
      <Dialog
        show={this.props.show}
        title={this.props.title}
        onOk={this.props.onOk}
        onClose={this.props.onCancel}
        okButtonText={this.props.okButtonText}
        dialogClassName={this.props.className}
        cancelButtonText={this.props.cancelButtonText} okButtonDisabled={this.props.data.length > 0 ? false : true} >
        {this.props.customText}
        <CheckListDataGrid
          data={this.props.data}
          selectorId={this.props.selectorId}
          columnText={this.props.columnText}
          columnAccesor={this.props.columnAccesor}
          onClick={this.props.onSelectItem}
          showCustomerOrderCapacity={this.props.showCustomerOrderCapacity}
        />
      </Dialog>
    );
  }
}

CheckListPopup.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
  onSelectItem: PropTypes.func,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  selectorId: PropTypes.string,
  columnAccesor: PropTypes.string,
  columnText: PropTypes.string,
  customText: PropTypes.object,
  data: PropTypes.array,
  showCustomerOrderCapacity: PropTypes.bool,
}

export default CheckListPopup;