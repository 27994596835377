import React, { useState } from "react";
import { connect } from "react-redux";
import Collapse from 'react-bootstrap/Collapse'
import { Row, Col } from "react-bootstrap";
import DateGroup from "../../../core/form/DateGroup";
import FieldGroup from "../../../core/form/FieldGroup";
import MultiSelectDropdownListGroup from "../../../core/form/MultiSelectDropdownListGroup"
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import ButtonMain from "../../../core/form/ButtonMain";
import { setFRAAtts, createFRAAtts } from "../../../../modules/instrumentUtils";
import DateAdjustmentService from '../../../../services/DateAdjustmentService';
import DateTimeParser from "../../../../modules/dateTimeParser";
import { FormModes } from "../../../../constants/enumerations"

const calculationFields = ["swapEffectiveDate", "maturityDate", "unadjustedEffectiveDate", "unadjustedMaturityDate", "calculationPeriodNumberOfDays", "floatFixingDate"];

const DateCalculatorFra = ({
  values,
  isEditing,
  touched,
  setFieldValue,
  setFieldError,
  handleBlur,
  handleChange,
  enableStartDate,
  calculateDates,
  errors,
  mode
}) => {
  const onDateCalculator = (values, setFieldValue, setFieldError, calculateDates) => {
    const successHandler = (attributes) => {
      let atts = {};

      setFRAAtts(attributes, atts);

      calculationFields.forEach((name) => {
        setFieldValue(name, atts[name]);
      });
    }

    const handleError = err => {
      setFieldError('dateCalculator', err);
    }

    if (values.startDate) {
      let atts = createFRAAtts(values);
      calculateDates(handleError, successHandler, atts, values.startDate);
    } else {
      setFieldError('dateCalculator', "Missing Trade Date");
    }
  }

  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Date Calculator</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>

      <Collapse in={open}>
        <div className="collapse-content">
          <h6>Swap Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="startDate" name="startDate" label="Trade date" isRequired={true}
                value={values.startDate ? new Date(values.startDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(values.isPartialEditable, isEditing, values.startDate)}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue('startDate', e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="tenor" name="tenor" type="textarea" label="Tenor" isRequired={true}
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.tenor}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup id="rollAndPaymentDateHolidayCalendarsList" name="rollAndPaymentDateHolidayCalendarsList" label="Roll and Payment Date Holiday Calendar"
                labelWrapperClass="label-wrapper"
                value={values.rollAndPaymentDateHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("rollAndPaymentDateHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"} valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="effectiveDateBusinessDayConvention" name="effectiveDateBusinessDayConvention" label="Effective Date Business Day Convention"
                value={values.effectiveDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("effectiveDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>

          </Row>

          <Row>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="maturityDateBusinessDayConvention" name="maturityDateBusinessDayConvention" label="Maturity Date Business Day Convention"
                value={values.maturityDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("maturityDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="calculationPeriodNumberOfDays" name="calculationPeriodNumberOfDays" type="textarea" label="Calculation Period Number of Days"
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.calculationPeriodNumberOfDays}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("calculationPeriodNumberOfDays", e.target.value)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="unadjustedEffectiveDate" name="unadjustedEffectiveDate" label="Unadjusted Effective Date" isRequired={true}
                value={values.unadjustedEffectiveDate ? new Date(values.unadjustedEffectiveDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(values.isPartialEditable, isEditing, values.unadjustedEffectiveDate)}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue('unadjustedEffectiveDate', e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="unadjustedMaturityDate" name="unadjustedMaturityDate" label="Unadjusted Maturity Date" isRequired={true}
                value={values.unadjustedMaturityDate ? new Date(values.unadjustedMaturityDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(values.isPartialEditable, isEditing, values.unadjustedMaturityDate)}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue('unadjustedMaturityDate', e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <hr />
          <h6>Fixed Leg Input</h6>
          <Row>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="fixedDayCountConvention" name="fixedDayCountConvention" label="Day Count Convention" isRequired={true}
                value={values.fixedDayCountConvention}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("fixedDayCountConvention", e.id)}
                data={InstrumentStaticData.DayCountConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="fixedBusinessDayConvention" name="fixedBusinessDayConvention" label="Fixed Business Day Convention"
                value={values.fixedBusinessDayConvention}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("fixedBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup id="fixedFixingDateHolidayCalendarsList" name="fixedFixingDateHolidayCalendarsList" label="Fixed Fixing Date Holiday Calendars"
                labelWrapperClass="label-wrapper"
                value={values.fixedFixingDateHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("fixedFixingDateHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"} valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <hr />
          <h6>Float Leg Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup id="floatFixingDateHolidayCalendarsList" name="floatFixingDateHolidayCalendarsList" label="Float Fixing Date Holiday Calendar"
                labelWrapperClass="label-wrapper"
                value={values.floatFixingDateHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatFixingDateHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"} valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatDayCountConvention" name="floatDayCountConvention" label="Day Count Convention" isRequired={true}
                errors={errors}
                value={values.floatDayCountConvention}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("floatDayCountConvention", e.id)}
                data={InstrumentStaticData.DayCountConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatFixingDatesDayType" name="floatFixingDatesDayType" label="Float Fixing Dates Day Type"
                value={values.floatFixingDatesDayType}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatFixingDatesDayType", e.id)}
                data={InstrumentStaticData.FixingDatesDayType}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatBusinessDayConvention" name="floatBusinessDayConvention" label="Float Business Day Convention"
                value={values.floatBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="floatFixingDatesOffset" name="floatFixingDatesOffset" type="textarea" label="Float Fixing Dates Offset" isRequired={true}
                disabled={!values.isEditable}
                value={values.floatFixingDatesOffset}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatFixingDateBusinessDayConvention" name="floatFixingDateBusinessDayConvention" label="Fixing Date Business Day Convention"
                value={values.floatFixingDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatFixingDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="floatFixingDate" name="floatFixingDate" label="Float Fixing Date" isRequired={true}
                value={values.floatFixingDate ? new Date(values.floatFixingDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(values.isPartialEditable, isEditing, values.floatFixingDate)}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue('floatFixingDate', e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={9} xs={9} md={3}></Col>
            <Col lg={3} xs={3} md={3}>
              <ButtonMain type="button" text="Calculate Dates" onClick={() => onDateCalculator(values, setFieldValue, setFieldError, calculateDates)}
              />
              {errors && errors.dateCalculator && (
                <div className="form-error mt-2">
                  <i className="fa fa-exclamation-triangle orange-icon" aria-hidden="true"></i>{" "}
                  {errors.dateCalculator}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) => ({
  calculateDates: (handleError, successHandler, atts, startDate) => {
    dispatch(DateAdjustmentService.adjustDates(handleError, successHandler, atts, startDate));
  }
});

export const ValidateDateCalculatorFra = (values, errors) => {
  calculationFields.forEach((field) => {
    if (!values[field]) {
      errors["dateCalculator"] = "You must calculate dates";
    }
  });
  return errors;
}

export default connect(null, mapDispatchToProps)(DateCalculatorFra);