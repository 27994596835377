import {
  FETCH_PODS_SUCCEED,
  FETCH_PODS_FAILED,
  FETCH_PODS_START,
  FETCH_JOBS_SUCCEED,
  FETCH_JOBS_FAILED,
  FETCH_JOBS_START,
  FETCH_CRONS_SUCCEED,
  FETCH_CRONS_FAILED,
  FETCH_CRONS_START,
  FETCH_VERSIONS_SUCCEED,
  FETCH_VERSIONS_FAILED,
  FETCH_VERSIONS_START,
  STREAM_LOGS_START,
  STREAM_LOGS_UPDATE,
  STREAM_LOGS_SUCCEED,
  STREAM_LOGS_FAILED,
  SET_CRON_DATE,
  SET_LOG_FROM_PREVIOUS_TERMINATION,
} from "../constants/JobTypes"

const initialState = {
  pods: [],
  jobs: [],
  crons: [],
  versions: [],
  cronDate: null,
  logs: "",
  fetchingPods: false,
  fetchingJobs: false,
  fetchingCrons: false,
  fetchingVersions: false,
  logLive: false,
  logSource: "",
  logFromPreviousTermination: false,
}

const jobs = (state = initialState, action) => {
  switch (action.type) {
    case SET_CRON_DATE:
      return {
        ...state,
        cronDate: action.payload,
      }
    case SET_LOG_FROM_PREVIOUS_TERMINATION:
      return {
        ...state,
        logFromPreviousTermination: action.payload,
      }
    case STREAM_LOGS_START:
      return {
        ...state,
        logSource: action.payload,
        logLive: true,
        logs: "",
      }
    case STREAM_LOGS_UPDATE:
      return {
        ...state,
        logs: state.logs.concat(action.payload),
      }
    case STREAM_LOGS_SUCCEED:
      return {
        ...state,
        logLive: false,
      }
    case STREAM_LOGS_FAILED:
      return {
        ...state,
        logLive: false,
      }
    case FETCH_PODS_START:
      return {
        ...state,
        fetchingPods: true,
      }
    case FETCH_PODS_SUCCEED:
      return {
        ...state,
        pods: [...action.payload.pods],
        fetchingPods: false
      }
    case FETCH_PODS_FAILED:
      return {
        ...state,
        pods: [],
        fetchingPods: false
      }
    case FETCH_JOBS_START:
      return {
        ...state,
        fetchingJobs: true,
      }
    case FETCH_JOBS_SUCCEED:
      return {
        ...state,
        jobs: [...action.payload.jobs],
        fetchingJobs: false
      }
    case FETCH_JOBS_FAILED:
      return {
        ...state,
        jobs: [],
        fetchingJobs: false
      }
    case FETCH_CRONS_START:
      return {
        ...state,
        fetchingCrons: true,
      }
    case FETCH_CRONS_SUCCEED:
      return {
        ...state,
        crons: [...action.payload.crons],
        fetchingCrons: false
      }
    case FETCH_CRONS_FAILED:
      return {
        ...state,
        crons: [],
        fetchingCrons: false
      }
    case FETCH_VERSIONS_START:
      return {
        ...state,
        fetchingVersions: true,
      }
    case FETCH_VERSIONS_SUCCEED:
      return {
        ...state,
        versions: [...action.payload.versions],
        fetchingVersions: false
      }
    case FETCH_VERSIONS_FAILED:
      return {
        ...state,
        versions: [],
        fetchingVersions: false
      }
    default:
      return state;
  }
}

export default jobs;
