import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { hasWriteAccess } from "../../../services/TokenService";
import * as Icons from '../../core/icons'

import "./DataGridActions.css";

const GridAction = ({ children, title, icon, onClick, disabled, contextMenu, className, style  }) => {

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 });

  const menuWidth = 174;
  const docClientWidth = document.body.clientWidth;
  const menuMaxLeft = docClientWidth - menuWidth;

  const isDisabled = ()=>{
    return typeof disabled === 'boolean' && disabled === true;
  }

  const handleContextMenu = (e, menuProps) => {
    if (!!menuProps && !!menuProps.show) e.preventDefault();

    setTimeout(() => {
      setContextMenuVisible(!!menuProps && !!menuProps.show && !isDisabled());
    }, 100);

    // Calculate the position of the context menu
    const x = e.clientX > menuMaxLeft ? menuMaxLeft : e.clientX;
    const y = e.clientY;
    setContextMenuPosition({ top: y - 5, left: x - 5 });
  };

  const hideContextMenu = (evt) => {
    setContextMenuVisible(false);
  };

  const handleContextMenuItemClick = (e, actionContext) => {
    onClick(e, actionContext);
  }

  const getMenuItems = () => {
    const menuItems = !!contextMenu && !!contextMenu.menuOptions && contextMenu.menuOptions.map(mItem => {
      return <li key={mItem.key} onClick={(e) => handleContextMenuItemClick(e,  mItem )}>{mItem.icon} <span>{mItem.label}</span></li>
    });

    return menuItems;
  }

  return (
    <>
      <Button variant="buttonActions" size="sm" className={className} style={style} onClick={onClick} title={title} disabled={disabled} onContextMenu={(e) => { handleContextMenu(e, contextMenu) }} >
        {icon}
        {children}
      </Button>

      {!!contextMenuVisible && (<div className="context-menu" style={{ left: contextMenuPosition.left, top: contextMenuPosition.top }} onMouseLeave={hideContextMenu} >
        <ul>
          {getMenuItems()}
        </ul>
      </div>)}
    </>
  );
};

export default GridAction;

export const GridActionView = ({ title, icon, onClick, disabled, contextMenu }) => {
  return (<GridAction title={title? title : "View"} icon={icon ? icon : Icons.eye} onClick={onClick} contextMenu={contextMenu} disabled={disabled} />);
}

export const GridActionEdit = ({ title, icon, onClick, disabled, contextMenu }) => {
  return (hasWriteAccess() && <GridAction title={title? title : "Edit"} icon={icon ? icon : Icons.edit} onClick={onClick} contextMenu={contextMenu} disabled={disabled} />);
}

export const GridActionCopy = ({ title, icon, onClick, disabled, contextMenu }) => {
  return (hasWriteAccess() && <GridAction title={title ? title : "Copy"} icon={icon ? icon : Icons.edit} onClick={onClick} contextMenu={contextMenu} disabled={disabled} />);
}

export const GridActionApprove = ({ title, icon, onClick, disabled, contextMenu }) => {
  return (hasWriteAccess() && <GridAction title={title? title : "Approve"} icon={icon ? icon : Icons.check} onClick={onClick} contextMenu={contextMenu} disabled={disabled} />);
}

export const GridActionDelete = ({ title, icon, onClick, disabled, contextMenu }) => {
  return (hasWriteAccess() && <GridAction title={title? title : "Delete"} icon={icon ? icon : Icons.trashAlt} onClick={onClick} contextMenu={contextMenu} disabled={disabled} />);
}

export const GridActionRestore = ({ title, icon, onClick, disabled, contextMenu }) => {
  return (hasWriteAccess() && <GridAction title={title? title : "Restore"} icon={icon ? icon : Icons.redoAlt} onClick={onClick} contextMenu={contextMenu} disabled={disabled} />);
}

export const GridActionRetry = ({ title, icon, onClick, disabled, contextMenu }) => {
  return (hasWriteAccess() && <GridAction title={title? title : "Retry"} icon={icon ? icon : Icons.redoAlt} onClick={onClick} contextMenu={contextMenu} disabled={disabled} />);
}

export const GridActionBust = ({ title, icon, onClick, disabled, contextMenu }) => {
  return (hasWriteAccess() && <GridAction title={title? title : "Bust"} icon={icon ? icon : Icons.timesCircle} onClick={onClick} contextMenu={contextMenu} disabled={disabled} />);
}

export const GridActionViewHistory = ({title, icon, onClick, disabled, contextMenu }) => {
  return (<GridAction title={title? title : "History"} icon={icon ? icon : Icons.clock} onClick={onClick} contextMenu={contextMenu} disabled={disabled} />);
}

export const GridActionCustom = ({ title, icon, onClick, disabled, contextMenu, className, style }) => {
  return (<GridAction title={title} icon={icon} className={className} style={style} onClick={onClick} contextMenu={contextMenu} disabled={disabled} />);
}