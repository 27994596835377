import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';

import './index.css';

class DefaultWrapper extends Component {
  render() {
    return (
      <div className="App">
        <ToastContainer
          hideProgressBar={true}
        />
        <div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default DefaultWrapper;
