import React from "react";
import PropTypes from "prop-types";
import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import Tooltip from '../tooltip-html/tooltip-html';
import { getObjectValue, isNullOrUndefined } from "../../../modules/util";

function returnOptions(options) {
  if (options === undefined || options.length === 0) return;
  var opts = [];
  opts.push(<option key="placeholder"></option>);
  options.forEach(option => {
    opts.push(<option key={option}>{option}</option>);
  });

  return opts;
}

const FieldGroup = ({
  id,
  type,
  componentType,
  isRequired,
  name,
  label,
  help,
  tooltip,
  tooltipPlace,
  value,
  placeholder,
  setFieldValue,
  errors,
  touched,
  onChange,
  options,
  cssName,
  readOnly,
  plaintext,
  ...props
}) => {
  const error = getObjectValue(errors, name);

  return (
    <FormGroup id={id}>
      {label && <FormLabel>{label}</FormLabel>}

      {!isNullOrUndefined(tooltip) &&
      <Tooltip
          text = {tooltip}
          id= {`tip-${id}`}
          place={!!tooltipPlace ? tooltipPlace : 'top' }
        ></Tooltip>
      }

      {plaintext && <div className="form-readonly-label">{value ? value : ""}</div>}

      {!plaintext &&
        <>
          {isRequired && <label className="form-label-required"> Required</label>}
          <FormControl
            id={id}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={e => {
              onChange(e);
            }}
            className={` ${cssName} form-control ${errors && errors[name] ? "form-input-has-error" : ""}`}
            {...props}
            readOnly={readOnly}
          >
            {options && returnOptions(options)}
          </FormControl>

          {help && <div className="help-block">{help}</div>}
          {error ? (
            <div className="form-error">
            <i
              className="fa fa-exclamation-triangle orange-icon"
              aria-hidden="true"
            ></i>{" "}
            {error}
          </div>
          ) : errors && errors[name] && (
            <div className="form-error">
              <i
                className="fa fa-exclamation-triangle orange-icon"
                aria-hidden="true"
              ></i>{" "}
              {errors[name]}
            </div>
          )}
        </>
      }
    </FormGroup>
  )
};

FieldGroup.defaultProps = {
  type: "text",
  isRequired: false,
  value: ""
};

FieldGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func
};

export default FieldGroup;
