import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Content.css';
import { AdminDocumentTitle } from '../constants/strings';
import {Col, Container, FormGroup, FormLabel, Row} from "react-bootstrap";
import {SetCurrentSelection, SetCurrentTab} from "../actions/app";
import {ListCrons, ListJobs, ListPods, StreamLogs, SetCronDate, SetLogFromPreviousTermination} from "../actions/jobs";
import JobsDataGrid from "../components/jobs/JobsDataGrid";
import CronJobsDataGrid from "../components/jobs/CronJobsDataGrid";
import PodsDataGrid from "../components/jobs/PodsDataGrid";
import ComplexToolbarLogViewer from "../components/jobs/ComplexToolbarLogViewer";
import Notification from "../modules/notifications";
import JobService from "../services/JobService";
import ConfirmDialog from "../components/core/confirm-dialog/ConfirmDialog";
import DateGroup from "../components/core/form/DateGroup";
import {dateToProtoDate} from "../actions/ptypes";
import {isNullOrUndefined} from "../modules/util";
import { TitleBreadcrumb } from '../components/core/title-breadcrumb/title-breadcrumb';

const {
  RunCronJobRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/jobs/v1beta1/jobs_api_pb.js");

function mapStateToProps(state) {
  return {
    currentTab: state.app.currentTab,
    currentSelection: state.app.currentSelection,
    logFromPreviousTermination: state.jobs.logFromPreviousTermination,
    logSource: state.jobs.logSource,
    logLive: state.jobs.logLive,
    pods: state.jobs.pods,
    crons: state.jobs.crons,
    jobs: state.jobs.jobs,
    logs: state.jobs.logs,
    cronDate: state.jobs.cronDate,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentTab: (selectedTab) => {
    dispatch(SetCurrentTab(selectedTab));
  },
  setCurrentSelection: (selection) => {
    dispatch(SetCurrentSelection(selection));
  },
  setCronDate: (cronDate) => {
    dispatch(SetCronDate(cronDate));
  },
  setLogFromPreviousTermination: (fromPreviousTermination) => {
    dispatch(SetLogFromPreviousTermination(fromPreviousTermination));
  },
  loadPods: () => {
    dispatch(ListPods());
  },
  loadJobs: () => {
    dispatch(ListJobs());
  },
  loadCrons: () => {
    dispatch(ListCrons());
  },
  streamLogs: (name, fromPreviousTermination) => {
    dispatch(StreamLogs(name, fromPreviousTermination));
  },
})

const pingFrequency = 5;
class Jobs extends Component {
  timer = 0;
  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.props.setCurrentTab("pods");
    this.props.streamLogs("", this.props.logFromPreviousTermination);
    this.props.setCronDate("");
    this.props.setLogFromPreviousTermination(false);
    this.timer = setInterval(() => {
      this.props.loadPods();
      this.props.loadJobs();
      this.props.loadCrons();
    }, pingFrequency * 1000);

    this.props.loadPods();
    this.props.loadJobs();
    this.props.loadCrons();

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  openViewLogs = (name, fromPreviousTermination = null) => {
    this.props.setCurrentTab("logs");
    const fromPrev = !isNullOrUndefined(fromPreviousTermination) ? !!fromPreviousTermination : this.props.logFromPreviousTermination;
    this.props.setLogFromPreviousTermination(fromPrev);
    this.props.streamLogs(name, fromPrev);
  };

  render() {
    const linkClass = 'link-with-router link-tab';
    const selectedLinkClass = `${linkClass} link-tab-selected`;
    const jobsSelected = this.props.currentTab.startsWith("jobs");
    const cronsSelected = this.props.currentTab.startsWith("crons");
    const podsSelected = this.props.currentTab.startsWith("pods");
    const logsSelected = this.props.currentTab.startsWith("logs");
    const cronsRunnerOpen = this.props.currentTab.startsWith("crons-runner");
    return (
        <div>
          <TitleBreadcrumb titles={[{ 'title': 'Jobs', link: `${window.location.origin}/jobs` }]} />
          <Row className="page-header">
            <Col lg={6} xs={6} md={6}>
              <div className={podsSelected ? selectedLinkClass : linkClass} onClick={() => this.props.setCurrentTab("pods")}>Pods</div>
              <div className={cronsSelected ? selectedLinkClass : linkClass} onClick={() => this.props.setCurrentTab("crons")}>Crons</div>
              <div className={jobsSelected ? selectedLinkClass : linkClass} onClick={() => this.props.setCurrentTab("jobs")}>Jobs</div>
              <div className={logsSelected ? selectedLinkClass : linkClass} onClick={() => this.props.setCurrentTab("logs")}>Logs</div>
            </Col>
          </Row>
          <div className="with-callback">
            {podsSelected && (<PodsDataGrid data={this.props.pods} onView={(name) => {
              this.props.setLogFromPreviousTermination(false);
              this.openViewLogs(name, false);
            }}/>)}
            {cronsSelected && (<CronJobsDataGrid data={this.props.crons} onRun={(name) => {
              this.props.setCronDate("");
              this.props.setCurrentSelection(name);
              this.props.setCurrentTab("crons-runner");
            }}/>)}
            {jobsSelected && (<JobsDataGrid data={this.props.jobs} onView={(name) => {
              this.props.setLogFromPreviousTermination(false);
              this.openViewLogs(name, false);
            }}/>)}
            {logsSelected && (<ComplexToolbarLogViewer
                pods={this.props.pods}
                jobs={this.props.jobs}
                logs={this.props.logs}
                logLive={this.props.logLive}
                selectedDataSource={this.props.logSource}
                setSelectedDataSource={this.openViewLogs}
                fromPreviousTermination={this.props.logFromPreviousTermination}
                setFromPreviousTermination={(fromPrev) => this.openViewLogs(this.props.logSource, fromPrev)}
            />)}
            {cronsRunnerOpen && (<ConfirmDialog
                show={!!cronsRunnerOpen}

                proceed={() => {
                  const cb = (err, response) => {
                    this.props.setCurrentTab("crons");
                    if (response) {
                      const jobName = response.getJobName();
                      Notification.success("Triggered job: " + jobName);
                      this.props.loadPods();
                      this.props.loadJobs();
                      this.props.setLogFromPreviousTermination(false);
                      this.openViewLogs(jobName, false);
                    }

                    if (err) {
                      Notification.error(
                          `Error when triggering job: \n ${err.message}`
                      );
                    }
                  };

                  var req = new RunCronJobRequest();
                  req.setName(this.props.currentSelection);
                  if(!!this.props.cronDate) {
                    req.setDate(dateToProtoDate(this.props.cronDate));
                  }
                  JobService.runCronJob(req, cb);
                }}

                cancel={() => {
                  this.props.setCurrentTab("crons");
                }}

                confirmation={
                  <Container>
                    <Row>
                      <Col>
                        <FormGroup>
                          <FormLabel className='jobTitle'>Are you sure you wish to submit this job?</FormLabel>
                          <DateGroup id="runDate" name="runDate" label="Run for Date (Optional)"
                                     value={!!this.props.cronDate ? this.props.cronDate : null}
                                     onChange={(e) => this.props.setCronDate(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Container >
                }

                options={{
                  title: this.props.currentSelection,
                  okButtonText: "Submit",
                  cancelButtonText: "Cancel",
                }}
            />)}
          </div>
        </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
