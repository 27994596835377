
import {
  getEnumName,
  capitalizeWord,
  convertDateToString
} from '../modules/util';
import State from './dto/State';
import Order from './Order';

import { ExecutionType, Side } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb';
import { protoDateToDate, tsProtoToDate } from "../actions/ptypes";

export default class Execution {
  constructor(item) {
    if (item) {
      const object = item.toObject();
      this.id = object.id;
      this.order = new Order(item.getOrder());
      this.lastShares = object.lastShares;
      this.commissionNotionalCollected = !!object.commissionNotionalCollected ? object.commissionNotionalCollected : 0;
      this.lastPx = object.lastPx;
      this.transactTime = tsProtoToDate(item.getTransactTime());
      this.transactTradeDate = !!object.transactTradeDate ? convertDateToString(protoDateToDate(item.getTransactTradeDate()), "YYYYMMDD") : "";
      this.legPricesList = object.legPricesList;

      this.legPricesList.forEach(legPrice => {
        legPrice.sideName = capitalizeWord(getEnumName(Side, legPrice.side));
      });

      let execType = new State();
      execType.id = object.type;
      execType.name = capitalizeWord(getEnumName(ExecutionType, object.type));
      this.execType = execType;
      return;
    }
    this.id = null;
    this.order = null;
    this.lastShares = null;
    this.lastPx = null;
    this.transactTime = null;
    this.execType = null;
    this.commissionNotionalCollected = null;
  }
}
