import React from "react";
import PropTypes from "prop-types";
import { DropdownList } from "react-widgets";
const errorBorder = {
  border: "solid 1px #DB614A",
};

const DropdownListControl = ({
  id,
  name,
  data,
  dropUp,
  value,
  enabled,
  itemComponent,
  onChange,
  onBlur,
  textField,
  valueField,
  errors,
  showFilter,
  selectIcon
}) => {
  const filterSearchItem = (data, value) => {
    let val = value.toLowerCase();

    if (typeof data === "string") {
      return (data.toLowerCase().indexOf(val) > -1);
    } else {
      if (!!textField) {
        let found = (data[textField].toLowerCase().indexOf(val) > -1);
        if (found) return true;
      }

      if (data && data.id && data.name) {
        let id =  String(data.id).toLowerCase();
        let name = data.name.toLowerCase();
        return name.indexOf(val) > -1 || id.indexOf(val) > -1;
      }
    }
    return;
  };

  const extraProps = showFilter ? { filter: filterSearchItem } : {};

  return (
    <DropdownList
      id={id}
      name={name}
      style={(errors ? errorBorder : null) }
      data={data}
      valueField={valueField}
      textField={textField}
      value={value}
      disabled={!enabled}
      itemComponent={itemComponent}
      dropUp={dropUp}
      onChange={onChange}
      onBlur={onBlur}
      selectIcon={(selectIcon ? selectIcon : undefined)}
      {...extraProps}
    />
  );
};

DropdownListControl.defaultProps = {
  data: [],
  dropUp: false,
  value: null,
  enabled: true,
  textField: "name",
  valueField: "id",
  errors: false,
};

DropdownListControl.propTypes = {
  data: PropTypes.any.isRequired,
  textField: PropTypes.string,
  valueField: PropTypes.string,
  dropUp: PropTypes.bool,
  value: PropTypes.any,
  enabled: PropTypes.bool,
  itemComponent: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

export default DropdownListControl;
