import ApiCall from "../modules/apiCall";
import apiCallPromise from "../modules/apiCallPromise";
import ProtobufParser from "../modules/protobufParser";
import { apiUrl } from "../modules/util";

const {
  GetParticipantRequest,
  ListParticipantsRequest,
  CreateParticipantRequest,
  SetParticipantSuspendStateRequest,
  SetParticipantRoleRequest,
  DeleteParticipantRequest,
  CreateParticipantAPITokenRequest,
  CreateParticipantAdminTokenRequest,
  UpdateUserAttributesRequest,
  GetUserAttributesRequest,
  RevokeTokensRequest,
  UndeleteParticipantRequest,
  UpdateParticipantRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");
const {
  AdminAPIClient,
  AdminAPIPromiseClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");

const client = new AdminAPIClient(apiUrl(), null, null);
const promiseClient = new AdminAPIPromiseClient(apiUrl(), null, null);

export default class ParticipantService {
  static get(name, cb) {
    var request = new GetParticipantRequest();
    request.setParticipantId(name);

    ApiCall(client, "getParticipant", request, cb);
  }

  static async get2(name) {
    var request = new GetParticipantRequest();
    request.setParticipantId(name);

    return await apiCallPromise(promiseClient, "getParticipant", request);
  }

  static getAll(cb, showDeleted = false) {
    var request = new ListParticipantsRequest();
    if (showDeleted) request.setShowDeleted(true);

    ApiCall(client, "listParticipants", request, cb);
  }

  static listParticipants(listParticipantsRequest, cb) {
    ApiCall(client, "listParticipants", listParticipantsRequest, cb);
  }

  static async listParticipantsAsync(listParticipantsRequest) {
    return await apiCallPromise(promiseClient, "listParticipants", listParticipantsRequest);
  }

  static getAttributes(name) {
    var request = new GetUserAttributesRequest();
    request.setParent(name);

    return apiCallPromise(promiseClient, "getUserAttributes", request);
  }

  static post(participant, cb) {
    var request = new CreateParticipantRequest();
    request.setParent(participant.getFirm());
    request.setParticipant(participant);

    ApiCall(client, "createParticipant", request, cb);
  }

  static postPromise(participant) {
    var request = new CreateParticipantRequest();
    request.setParent(participant.getFirm());
    request.setParticipant(participant);

    return apiCallPromise(promiseClient, "createParticipant", request);
  }

  static suspend(data, cb) {
    var request = new SetParticipantSuspendStateRequest();
    request.setParticipantId(data.name);
    request.setIsSuspended(data.isSuspended);

    ApiCall(client, "setParticipantSuspendState", request, cb);
  }

  static setRole(data, cb) {
    var request = new SetParticipantRoleRequest();

    request.setParticipantId(data.name);
    request.setRole(data.role.id);

    ApiCall(client, "setParticipantRole", request, cb);
  }

  static update(participant, userAttributes) {
    var request = new UpdateUserAttributesRequest();

    request.setParent(participant.name);
    request.setAttributes(userAttributes);

    return apiCallPromise(promiseClient, "updateUserAttributes", request);
  }

  static delete(name, cb) {
    var request = new DeleteParticipantRequest();
    request.setParticipantId(name);

    ApiCall(client, "deleteParticipant", request, cb);
  }

  static createAPIToken(id, svcKeyName, ttlSeconds, singleUse, cb) {
    var request = new CreateParticipantAPITokenRequest();
    request.setParticipantId(id);
    request.setTimeToLiveDuration(
      ProtobufParser.toDuration({ seconds: ttlSeconds, nanos: 0 })
    );
    request.setSingleUse(!!singleUse);
    if (!!svcKeyName) request.setServiceUserTokenName(svcKeyName);

    ApiCall(client, "createParticipantAPIToken", request, cb);
  }

  static createAdminToken(id, svcKeyName, ttlSeconds, singleUse, cb) {
    var request = new CreateParticipantAdminTokenRequest();
    request.setParticipantId(id);
    request.setTimeToLiveDuration(
      ProtobufParser.toDuration({ seconds: ttlSeconds, nanos: 0 })
    );
    request.setSingleUse(!!singleUse);
    if (!!svcKeyName) request.setServiceUserTokenName(svcKeyName);

    ApiCall(client, "createParticipantAdminToken", request, cb);
  }

  static revokeTokens(name, isFirm, cb) {
    var request = new RevokeTokensRequest();
    if (isFirm) {
      request.setFirm(name);
    } else {
      request.setUser(name);
    }
    ApiCall(client, "revokeTokens", request, cb);
  }

  static unDeleteParticipant(name) {
    var request = new UndeleteParticipantRequest();
    request.setName(name);

    return apiCallPromise(promiseClient, "undeleteParticipant", request);
  }

  static updateParticipant(participant) {
    let request = new UpdateParticipantRequest();
    request.setParticipant(participant);
    return apiCallPromise(promiseClient, "updateParticipant", request);
  }
}
