import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import { validateField, getOptionText, NUMBER_TYPE, DATE_TYPE, ARRAY_TYPE } from '../../instrumentFormUtils'
import DateGroup from "../../../core/form/DateGroup";
import FieldGroup from "../../../core/form/FieldGroup";
import DateTimeParser from "../../../../modules/dateTimeParser";
import { FormModes } from "../../../../constants/enumerations";

export const SwapInfoFra = ({
  values,
  errors,
  setFieldValue,
  touched,
  handleBlur,
  mode
}) => {
  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view ;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Swap Information</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Tenor" value={values.tenor} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="sideConvention" name="sideConvention" label="Side Convention" isRequired={true}
                value={values.sideConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("sideConvention", e.id)}
                data={InstrumentStaticData.SideConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Roll and Payment Date Holiday Calendar" value={values.rollAndPaymentDateHolidayCalendarsList ? values.rollAndPaymentDateHolidayCalendarsList.join(", ") : ""} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Effective Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.effectiveDateBusinessDayConvention)} />
            </Col>
          </Row>
          <Row>

            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Maturity Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.maturityDateBusinessDayConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Calculation Period Number Of Days" value={values.calculationPeriodNumberOfDays} />
            </Col>
            <Col lg={3} xs={3} md={3} >
              <DateGroup id="swapEffectiveDate" name="swapEffectiveDate" label="Swap Effective Date" isRequired={true}
                value={values.swapEffectiveDate ? new Date(values.swapEffectiveDate) : null}
                dropUp={true}
                enabled={values.isPartialEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("swapEffectiveDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Unadjusted Effective Date" value={values.unadjustedEffectiveDate ? DateTimeParser.toDisplay(values.unadjustedEffectiveDate) : null} />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Unadjusted Maturity Date" value={values.unadjustedMaturityDate ? DateTimeParser.toDisplay(values.unadjustedMaturityDate) : null} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="forwardStartingPeriod" name="forwardStartingPeriod" type="textarea" label="Forward Starting Period"
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.forwardStartingPeriod}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("forwardStartingPeriod", e.target.value)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="maturityDate" name="maturityDate" label="Maturity Date" isRequired={true}
                value={values.maturityDate ? new Date(values.maturityDate) : null}
                dropUp={true}
                enabled={values.isPartialEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("maturityDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
        </div>
      </Collapse>

    </React.Fragment>
  )
}

export const ValidateSwapInfoFra = (values, errors) => {
  errors = validateField(values, errors, "tenor", "Tenor", NUMBER_TYPE);
  errors = validateField(values, errors, "sideConvention", "Side Convention", NUMBER_TYPE, values);
  errors = validateField(values, errors, "maturityDateBusinessDayConvention", "Maturity Date Business Day Convention", NUMBER_TYPE);
  errors = validateField(values, errors, "effectiveDateBusinessDayConvention", "Effective Date Business Day Convention", NUMBER_TYPE);
  errors = validateField(values, errors, "rollAndPaymentDateHolidayCalendarsList", "Roll And Payment Date Holiday Calendars List", ARRAY_TYPE);
  errors = validateField(values, errors, "swapEffectiveDate", "Swap Effective Date", DATE_TYPE);
  errors = validateField(values, errors, "maturityDate", "Swap Maturity Date", DATE_TYPE);
  errors = validateField(values, errors, "forwardStartingPeriod", "Forward Starting Period", NUMBER_TYPE);
  return validateField(values, errors, "calculationPeriodNumberOfDays", "Calculation Period Number Of Days", NUMBER_TYPE);
}
