import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import slugify from "slugify";
import FieldGroup from "../core/form/FieldGroup";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import { Container, Row, Col } from "react-bootstrap";
import ButtonMain from "../core/form/ButtonMain";
import ButtonBorderless from "../core/form/ButtonBorderless";
import FirmStateListContainer from "../../containers/FirmStateListContainer";
import FirmTypeListContainer from "../../containers/FirmTypeListContainer";
import FirmParticipantTypeListContainer from "../../containers/FirmParticipantTypeListContainer";
import OrganizationTypeListContainer from "../../containers/OrganizationTypeListContainer";
import { FirmType, OrganizationType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { convertEnumToDropdownList } from "../../modules/util";
import PhoneNumber from "../core/form/PhoneNumber";
import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import Aliases from "../../components/shared/Aliases";
import { Env } from "../../constants/environment"
import CheckboxGroup from "../core/form/CheckboxGroup";
import Collapse from "react-bootstrap/Collapse";
import PersonnelForm from "./PersonnelForm";
import * as toolTips from '../../constants/firmToolTip';
import ErrorLabel from "../core/form/ErrorLabel";
import AlternateLegalNames from "../shared/AlternateLegalNames";

const firmTypeObj = convertEnumToDropdownList(FirmType);

export class BasicFirmForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  setPersonnelOpen(isPersonnelOpen) {
    this.setState({ isPersonnelOpen: isPersonnelOpen });
  }

  getPersonnelOpen = () => {
    return !!this.state && !!this.state.isPersonnelOpen;
  }

  getAlternateLegalNamesOpen = () => {
    return !!this.state && !!this.state.isAlternateLegalNamesOpen;
  }

  setAlternateLegalNamesOpen = () => {
    this.setState({ isAlternateLegalNamesOpen: !this.getAlternateLegalNamesOpen() });
  }

  render() {
    const {
      title,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      firmType,
    } = this.props;

    if (firmType)
      values.firmType = firmTypeObj
        .filter((firmTypeObj) => firmTypeObj.name === firmType)
        .shift();

    const aliasOnChange = (aliases) => {
      values.aliases = aliases;
    }

    const alternateLegalNamesOnChange = (alternateLegalNames) => {
      values.alternateLegalNames = alternateLegalNames;
    }

    return (
      <form onSubmit={handleSubmit} name="firmForm">
        <h1>{title}</h1>
        <Container>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FormGroup id="firstName">
                <FormLabel>Firm Name</FormLabel>
                <label className="form-label-required"> Required</label>
                <FormControl
                  id="firstName"
                  type="text"
                  name="firmName"
                  value={values.firmName}
                  placeholder=""
                  onChange={e => {
                    handleChange(e);
                    if (!touched.firmId) {
                      setFieldValue("firmId", slugify(e.target.value));
                    }
                  }}
                >
                </FormControl>
                {errors && errors["firmName"] && (
                  <div className="form-error">
                    <i
                      className="fa fa-exclamation-triangle orange-icon"
                      aria-hidden="true"
                    ></i>{" "}
                    {errors["firmName"]}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroup
                id="firmId"
                name="firmId"
                type="text"
                label="Firm ID"
                placeholder=""
                errors={errors}
                touched={touched}
                value={values.firmId}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
                help="Firm ID must be unique."
                isRequired={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              {firmType ? (
                <FieldGroupReadOnly
                  id="firmType"
                  name="firmType"
                  label="Firm Type"
                  value={firmType}
                />
              ) : (
                <FirmTypeListContainer
                  id="firmType"
                  name="firmType"
                  label="Firm Type"
                  isRequired={true}
                  value={values.firmType ? values.firmType.id : null}
                  enabled={true}
                  errors={errors}
                  onChange={(e) => {
                    setFieldValue("firmType", e);
                    setFieldValue("firmIsParticipant", e.id === FirmType.FIRM_TYPE_PARTICIPANT);
                    setFieldValue("participantType", 0);
                    setFieldValue("organizationType", 0);
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FirmStateListContainer
                id="state"
                name="state"
                label="Firm state"
                isRequired={true}
                value={values.state ? values.state.id : null}
                enabled={true}
                errors={errors}
                onChange={(e) => {
                  setFieldValue("state", e);
                }}
              />
            </Col>
          </Row>
          {values && values.firmType && values.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT && <Row>
            <Col lg={6} xs={6} md={6}>
              <CheckboxGroup
                  checked={values.applyCommissionsAsSpread}
                  disabled={false}
                  onClick={(e) => {
                    setFieldValue("applyCommissionsAsSpread", e.target.checked);
                  }}
                  label={"Apply Commissions as Spread"} />
            </Col>
          </Row>}
          {values && values.firmType && values.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT && <Row>
            <Col lg={6} xs={6} md={6}>
              <CheckboxGroup
                  checked={values.skipCommissionBalanceAdjustment}
                  disabled={false}
                  onClick={(e) => {
                    setFieldValue("skipCommissionBalanceAdjustment", e.target.checked);
                  }}
                  label={"Skip Commission Balance Adjustment"} />
            </Col>
          </Row>}
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FirmParticipantTypeListContainer
                id="participantType"
                name="participantType"
                label="Participant Type"
                isRequired={false}
                value={values.participantType ? values.participantType.id : 0}
                enabled={values.firmIsParticipant}
                errors={errors}
                onChange={(e) => setFieldValue("participantType", e)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <OrganizationTypeListContainer
                id="organizationType"
                name="organizationType"
                label="Organization Type"
                isRequired={false}
                value={values.organizationType ? values.organizationType.id : 0}
                enabled={true}
                errors={errors}
                onChange={(e) => setFieldValue("organizationType", e)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroup
                tooltip={toolTips.LEGAL_ENTITY_IDENTIFIER}
                id="legalEntityIdentifier"
                name="legalEntityIdentifier"
                type="text"
                label="Legal Entity Identifier"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.legalEntityIdentifier}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="legalName"
                name="legalName"
                type="text"
                label="Legal Name"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.legalName}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="businessContactName"
                name="businessContactName"
                type="text"
                label="Business Contact Name"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.businessContactName}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroup
                id="email"
                name="email"
                type="email"
                label="Email"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.email}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <PhoneNumber
                id="phoneNumber"
                name="phoneNumber"
                value={values.phoneNumber}
                onChange={(e) => setFieldValue("phoneNumber", e)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="usTaxpayerIdentificationNumber"
                name="usTaxpayerIdentificationNumber"
                type="text"
                label="US Taxpayer Identification Number"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.usTaxpayerIdentificationNumber}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="designatedSelfRegulatoryOrganization"
                name="designatedSelfRegulatoryOrganization"
                type="text"
                label="Designated Self Regulatory Organization"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.designatedSelfRegulatoryOrganization}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="designatedExaminingAuthority"
                name="designatedExaminingAuthority"
                type="text"
                label="Designated Examining Authority"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.designatedExaminingAuthority}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="mainAddress1"
                name="mainAddress1"
                type="text"
                label="Main Address 1"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.mainAddress1}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="mainAddress2"
                name="mainAddress2"
                type="text"
                label="Main Address 2"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.mainAddress2}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="mainAddressCity"
                name="mainAddressCity"
                type="text"
                label="Main Address City"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.mainAddressCity}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="mainAddressState"
                name="mainAddressState"
                type="text"
                label="Main Address State"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.mainAddressState}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="mainAddressPostalCode"
                name="mainAddressPostalCode"
                type="text"
                label="Main Address Postal Code"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.mainAddressPostalCode}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroup
                id="mainAddressCountryCode"
                name="mainAddressCountryCode"
                type="text"
                label="Main Address Country Code"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.mainAddressCountryCode}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row>
            <Col><AlternateLegalNames onChange={alternateLegalNamesOnChange} tooltip={null} /></Col>
          </Row>

          <Row>
            <Col>
              <React.Fragment>
                <div onClick={() => this.setPersonnelOpen(!this.getPersonnelOpen())}>
                  <Row className="accordian-style">
                    <Col lg={11} xs={11} md={11}>
                      <h6>
                        <strong>Key Personnel</strong>
                      </h6>
                    </Col>
                    <Col>
                      <i
                        className={`fa fa-fw ${this.getPersonnelOpen() ? "fa-minus" : "fa-plus"
                          } custom-plus`}
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <Collapse in={this.getPersonnelOpen()}>
                    <div className="collapse-content">
                      {!!values && Array.isArray(values.keyPersonnel) ?
                        values.keyPersonnel.map((keyPerson, idx) => (
                          <div>
                            <button
                              type="button"
                              className="btn-add-alias"
                              onClick={() => {
                                setFieldValue("keyPersonnel", values.keyPersonnel.filter((_, i) => i !== idx))
                              }}
                            >
                              Remove Entry {idx}
                            </button>
                            <PersonnelForm
                              setFieldValue={(field, val) => setFieldValue("keyPersonnel", values.keyPersonnel.map((entry, i) => {
                                let obj = entry;
                                if (i === idx) obj[field] = val;
                                return obj
                              }))}
                              handleBlur={handleBlur}
                              handleChange={c => setFieldValue("keyPersonnel", values.keyPersonnel.map((entry, i) => {
                                let obj = entry;
                                if (i === idx) obj[c.target.id] = c.target.value;
                                return obj
                              }))}
                              errors={errors}
                              touched={touched}
                              onEditForm={true}
                              values={keyPerson}
                            />
                          </div>
                        ))
                        : null}
                      <button
                        type="button"
                        className="btn-add-alias"
                        onClick={() => {
                          setFieldValue("keyPersonnel", (!!values && Array.isArray(values.keyPersonnel) ? values.keyPersonnel : []).concat({}))
                        }}
                      >
                        Add Entry
                      </button>
                      <Row><Col></Col> </Row>
                    </div>
                  </Collapse>
                </div>
              </React.Fragment>
            </Col>
          </Row>

          <Row>
            <Col><Aliases onChange={aliasOnChange} options={Env.getAliasOptions()} tooltip={toolTips.ALIAS} /></Col>
          </Row>

          <Row>
            <Col lg={1} xs={1} md={1}>
              <ButtonMain type="submit" text="SAVE" />
            </Col>
            <Col lg={2} xs={2} md={2}>
              <ButtonBorderless
                type="button"
                text="Cancel"
                icon="times-circle"
                customClassName="grey-icon"
                onClick={(e) => {
                  e.preventDefault();
                  this.context.router.history.goBack();
                }}
              />
            </Col>
            <Col lg={9} xs={9} md={9}>
              {Object.keys(errors).length > 0 && <div style={{ marginTop: "15px" }} ><ErrorLabel text="At least one required field is missing. Please review." /></div>}
            </Col>
          </Row>

        </Container>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    isEditing: !state.firms.isCreating,
    firm: state.firms.firm,
  };
}

const FirmForm = withFormik({
  mapPropsToValues: (item) => {

    return {
      ...item.item,
      firmIsParticipant: false,
      participantType: FirmType.FIRM_TYPE_UNDEFINED,
      organizationType: OrganizationType.ORGANIZATION_TYPE_UNDEFINED,
      alternateLegalNames: [],
    };
  },

  validationSchema: Yup.object().shape({
    firmName: Yup.string().nullable().required("Firm name is required."),
    email: Yup.string().email("Invalid email format"),
    firmId: Yup.string().required("Firm Id is required."),
  }),
  validate: (values) => {
    let errors = {};
    if (!values.state) {
      errors.state = "State must be defined.";
    }
    if (!values.firmType) {
      errors.firmType = "Firm Type must be defined.";
    }
    return errors;
  },
  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onSubmitForm(values, setFieldError);
  },
  displayName: "firm-form",
  enableReinitialize: true,
})(BasicFirmForm);

export default connect(mapStateToProps, null)(FirmForm);
