import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import DateGroup from "../core/form/DateGroup";
import moment from "moment";
import AutoComplete from "../core/auto-complete/AutoComplete";
import AlertStaticData from "../../modules/alertsStaticData";
import DropdownListGroup from "../core/form/DropdownListGroup";
import MultiSelectDropdownListGroup from "../core/form/MultiSelectDropdownListGroup";
import ButtonSecondary from '../core/form/ButtonSecondary';
import querystring from 'query-string';
import { AlertStatus } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/alert/v1beta1/alert_pb";

const Reset_Action = "reset";
const Export_Action = "export";

const defaultStatusesList = AlertStaticData.AlertStatuses([0]).filter(status => status.id !== AlertStatus.ALERT_STATUS_RESOLVED);

const BasicAlertsFilterForm = (props) => {
  const { values, touched, errors, handleChange, handleBlur,
    handleSubmit, setFieldValue,
    onRefreshInstruments, instrumentIdsList,
    firmsOptions, onExport } = props;

  const [alertStatuses, setAlertStatuses] = useState();

  useEffect(() => {
    const alertStatuses = AlertStaticData.AlertStatuses([0])
    setAlertStatuses(alertStatuses);
  }, []);

  const style = {
    marginRight: "0px",
    marginBottom: "1px",
    marginLeft: "0px"
  }

  return (
    <div style={style}>
      <form onSubmit={handleChange} style={style}>
        <Row style={style}>
          <Col lg={2} xs={2} md={2}>
            <FieldGroup
              id="venue"
              name="venue"
              type="text"
              label="Venue"
              value={values.venue}
              onChange={handleChange}
            />
          </Col>
          <Col lg={2} xs={2} md={2}>
            <FieldGroup
                id="alertName"
                name="alertName"
                type="text"
                label="Alert Name"
                value={values.alertName}
                onChange={handleChange}
            />
          </Col>
          <Col lg={3} xs={3} md={3}>
            <Form.Label>Insert Time From</Form.Label>
            <DateGroup
              id="startTime"
              name="startTime"
              enableTime={true}
              step={15}
              value={values.startTime ? new Date(values.startTime) : null}
              onChange={(e) => setFieldValue("startTime", e)}
              defaultCurrentDate={moment().startOf("day").toDate()}
              errors={errors}
              touched={touched}
              onBlur={handleBlur}
            />
          </Col>
          <Col lg={3} xs={3} md={3}>
            <Form.Label>Insert Time To</Form.Label>
            <DateGroup
              id="endTime"
              name="endTime"
              enableTime={true}
              step={15}
              value={values.endTime ? new Date(values.endTime) : null}
              onChange={(e) => setFieldValue("endTime", e)}
              defaultCurrentDate={moment().startOf("day").toDate()}
              errors={errors}
              touched={touched}
              onBlur={handleBlur}
            />
          </Col>
        </Row>
        <Row style={style}>
          <Col lg={2} xs={2} md={2}>
            <FieldGroup
              id="id"
              name="id"
              type="text"
              label="Alert ID"
              value={values.id}
              onChange={handleChange}
            />
          </Col>
          <Col lg={2} xs={2} md={2}>
            <FieldGroup
              id="tradeId"
              name="tradeId"
              type="text"
              label="Trade ID"
              value={values.tradeId}
              onChange={handleChange}
            />
          </Col>

          <Col lg={3} xs={3} md={3}>
            <FieldGroup
              id="account"
              name="account"
              type="text"
              label="Account ID"
              value={values.account}
              onChange={handleChange}
              tooltip={"The full resource name of the account, such as 'firms/FirmA/accounts/AccountA'"}
            />
          </Col>

          <Col lg={2} xs={2} md={2}>
            <DropdownListGroup
              id="firm"
              name="firm"
              type="text"
              label="Firm"
              value={values.firm}
              onChange={(e) => setFieldValue("firm", e.id)}
              data={firmsOptions}
              textField="name"
              valueField="id"
            />
          </Col>

          <Col lg={2} xs={2} md={2}>
            <Form.Label>Instrument ID</Form.Label>
            <AutoComplete
              id="symbol"
              name="symbol"
              placeholder="Instrument ID"
              noItemsMessage="No instruments found"
              dataSource={instrumentIdsList}
              refreshDataSource={onRefreshInstruments}
              defaultValue={values.symbol}
              onChange={(e) => {
                if (e.target.value === "") {
                  setFieldValue("symbol", null);
                }
                handleChange(e);
              }}
              onEnter={(value) => {
                setFieldValue("symbol", value);
              }}
              onSelect={(value) => {
                setFieldValue("symbol", value);
              }}
            />
          </Col>
        </Row>
        <Row style={style}>
          <Col lg={4} xs={4} md={4}>
            <MultiSelectDropdownListGroup
                id="status"
                name="status"
                label="Status"
                value={values.statusesList}
                onChange={(e) => setFieldValue("statusesList", e)}
                data={alertStatuses}
                textField="name"
                valueField="id"
            />
          </Col>
          <div className="form-group col-auto align-self-end" >
            <button
              className="Filter-Button"
              style={{ width: "100px" }}
              onClick={() => {
                handleSubmit();
              }}>Filter</button>
          </div>
          <div className="form-group col-auto align-self-end">
            <ButtonSecondary
              style={{ width: "100px" }}
              text="Reset"
              type="button"
              onClick={() => {
                values.action = Reset_Action;
                handleSubmit();
              }}
            />
          </div>
          <Col>
            <ButtonSecondary
              style={{ width: "100px", float: "right", marginTop: "33px"}}
              text="Export"
              type="button"
              onClick={() => {
                values.action = Export_Action;
                onExport(values)
              }}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
}

const AlertsFilterForm = withFormik({
  mapPropsToValues: (props) => {
    return { ...props.filter }
  },

  validate: (values) => {
    const errors = {};
    return errors;
  },

  handleSubmit: (values, formikBag) => {
    const { props, resetForm} = formikBag;
    if(values.action === Reset_Action) {
      var resetValues = {
        venue: "",
        alertName: "",
        startTime: "",
        endTime: "",
        id: "",
        tradeId: "",
        account: "",
        firm: "",
        symbol: "",
        statusesList: defaultStatusesList,
      }

      resetForm(resetValues);
      if (!!props.onFilter) props.onFilter(resetValues);
      props.context.router.history.push(`/alerts?${querystring.stringify(resetValues)}`);
    } else {
      if (!!props.onFilter) props.onFilter(values);

      let searchState = {
        id: values.id,
        statusesList: values.statusesList ? values.statusesList.map(status => status.id) : null,
        tradeId: values.tradeId,
        venue: values.venue,
        alertName: values.alertName,
        startTime: values.startTime,
        endTime: values.endTime,
        symbol: values.symbol,
        account: values.account,
        firm: values.firm,
      }

      props.context.router.history.push(`/alerts?${querystring.stringify(searchState)}`);
    }
  },

})(BasicAlertsFilterForm)

export default AlertsFilterForm;