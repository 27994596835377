import React from "react";

const tooltipStyle = { fontSize: "14px", fontWeight:"normal" };

export const PRODUCT_DESCRIPTION = <span>A short description of the product that is used to provide additional business context. <br />
    For example, a product with ID "GC" would benefit from the description "Gold Futures"</span>;

export const ROUTING_KEY = <span>An internal construct for the EP3 message broker that is used to classify which Kafka partition will map to the actions on <br />
    this book. It is set to the Product ID by default, and it cannot be changed once the instrument transitions out of a PENDING state.</span>

export const EXTERNAL_SYMBOL = <span>If this instrument uses a third-party integration for injecting market data or for some other back office purpose, <br />
    this field is used to indicate that particular third party's identifier for this instrument.</span>

export const MULTIPLIER = <span>The single share/contract multiplier, which is used in the calculation of the value of a contract and the P&L of a position. <br />
    For example in the context of options, a multiplier of 100 would indicate that an instrument controls 100 shares of the underlying for each contract <br />
    of the instrument traded, such that this option purchased at a price of 2.34 would cost the buyer 234.00</span>

export const TRADE_SETTLEMENT_PERIOD = <span>Represents the number of days to settle a trade. This field is meant to provide additional context <br />
    regarding the external clearing process and does not have any functional impact within EP3.</span>

export const MINIMUM_PRICE_INCREMENT = <span>The smallest difference in price allowed between levels in the order book. Also known as the Tick Size. <br />
    For example, a 0.01 Minimum Price Increment (assuming a Multiplier of 1) would mean that an order with <br />
    a price of 7.51 would be accepted, while an order with a price of 7.518 would be rejected.</span>

export const MINIMUM_TRADE_QUANTITY = <span>The minimum quantity allowed on an order. This field can be a decimal, indicating the ability to trade fractional shares of the instrument. <br />
    For example, this field can be 0.00000001 to make the smallest tradable quantity have 8 decimal places of precision.</span>

export const MINIMUM_UNAFFILIATED_FIRMS = <span>Relates to RFQ functionality. This is the minimum number of firms that an RFQ must be sent to <br />
    by the requestor to ensure a fair market. Typically used by Swap Execution Facilities.</span>

export const ABSOLUTE_PRICE_LIMITS = <span>The minimum price and the maximum price for acceptable orders. Orders that violate an Absolute Price Limit are rejected.</span>

export const ORDER_SIZE_LIMITS = <span>The minimum quantity and maximum quantity for acceptable orders. Orders that violate an Order Size Limit are rejected.</span>

export const TOTAL_NOTIONAL_LIMITS = <span>The minimum notional value and maximum notional value for acceptable orders, in terms of the instrument's "Currency". <br />
    E.g. for a GBP/USD Forex instrument, this should be specified in terms of USD. EP3 calculates notional value on an order <br />
    as the order's price multiplied by the order's quantity multiplied by the instrument's multiplier.</span>

export const START_DATE = <span>The first date that the Instrument is able to trade. The exact time that the <br />
    instrument is able to trade will depend on the instrument's trading hours.</span>

export const LAST_TRADE_DATE_TIME = <span>When the Instrument's state will be set to CLOSED. In a CLOSED state, no more orders can be entered, and any Day orders will be expired. <br />
    In addition, reaching the Last Trade Date/Time causes EP3 to cancel all Good Til Cancel and Good Til Date orders for this instrument.</span>

export const EXPIRATION_DATE_TIME = <span>When the Instrument's state will be set to EXPIRED. This could be a different date than Last Trade Date/Time for certain commodities.</span>

export const TERMINATION_DATE = <span>When the Instrument's state will be set to TERMINATED. At this time, the order book is removed from the matching engine. <br />
    All downstream orders, positions, etc. will be closed. Historical data will still remain in EP3 ledgers. Terminated instruments <br />
    by default don't show on the Instruments page in the Admin UI, but they are viewable by explicitly filtering for them.</span>

export const CFI_CODE = <span>Six-letter Classification of Financial Instruments code. Required only if EP3 will be creating the Part 16.02 Trade Capture Report for the U.S. CFTC</span>

export const CATEGORY_LEVELS = <span>Defines not only the lowest-level child category that a product belongs to, but also any parent <br />
    category(ies) that exist above the child category. For example, a 'Cerium' product might define <br />
    'Commodities &gt; Metals &gt; Rare Earth' as its categories to describe the product categorization at multiple levels of detail</span>

export const CROSS_ORDER_RESTING_DURATION = <span>Amount of time the order from the prioritized side of a cross should rest in the book <br />
    before the order from the non-prioritized side enters the book</span>;

export const SETTLEMENT_PERIOD_START_OFFSET_FROM_CLOSE_DURATION = <span>A trade must occur within the Settlement Period to be included in a VWAP settlement price calculation.<br />
    For example, if this is set to 30 minutes and the instrument closes at 3:30pm, the start of the Settlement Period would be 3:00pm.</span>

export const SETTLEMENT_PERIOD_END_OFFSET_FROM_CLOSE_DURATION = <span>A trade must occur within the Settlement Period to be included in a VWAP settlement price calculation.<br />
    For example, if this is set to 15 minutes and the instrument closes at 3:30pm, the end of the Settlement Period would be 3:15pm.</span>

export const MINIMUM_TRADE_QTY_FOR_VWAP_SETTLEMENT_CALCULATION = <span>The minimum number of trades needed in the Settlement Period in order to calculate the settlement price using VWAP.</span>

export const BUFFER_FROM_END_OF_SETTLEMENT_PERIOD_DURATION = <span>The minimum amount of time before market close that an order must be<br />
    open and unmodified to be eligible to be the best open bid or best open offer at market close.<br />
    For example, if this is set to 15 minutes and the instrument closes at 3:30pm, the order would need to be open and unmodified since 3:15pm or earlier.</span>

export const SETTLEMENT_PRICE_CALCULATION_ENDING_METHOD = <span>The ending tier or tier + rule in the settlement price calculation sequence.<br />
    This allows the exchange to exclude certain tiers or rules that should never be used.<br />
    If unset, all tiers and rules for the chosen Settlement Price Logic will be allowed. Currently, this functionality is applicable only to the VWAP logic.</span>

export const BLOCK_TRADE_CAN_EDIT = <span>If unchecked, the exchange-level thresholds will be used. If checked, the instrument-level thresholds will be used.</span>

export const BLOCK_TRADE_MINIMUM_QUANTITY = <span>The minimum quantity for an order cross that has been marked as a block trade. <br />
    Either this threshold or the minimum notional must be met for a block trade to be accepted.</span>

export const BLOCK_TRADE_MINIMUM_NOTIONAL = <span>The minimum notional value for an order cross that has been marked as a block trade. <br />
    Either this threshold or the minimum quantity must be met for a block trade to be accepted.</span>

export const SETTLEMENT_PRICE_CALCULATION_REQUIRES_APPROVAL = <span>If checked, the Settlement Preliminary flag of the calculated settlement price will be set to 'true'.</span>

export const EVENT_PAYOUT_TYPE = <span>For "Binary" contracts, the payout is either 0 or the Payout Value. <br />
    For "Continuous" contracts, the payout can be anything between the Payout Lower Bound and Payout Upper Bound.</span>

export const EVENT_POSITION_ACCOUNTABILITY_VALUE = <span>Used for Market Surveillance alerting only. <br />
    When the position for an account changes such that it exceeds the instrument’s Position Accountability Value, <br />
    EP3 immediately raises a “Position Accountability Value Exceeded” alert.</span>

export const EVENT_POSITION_LIMIT = <span>Used for Market Surveillance alerting only. <br />
    When the position for an account changes such that it exceeds the instrument’s Position Limit, <br />
    EP3 immediately raises a “Position Limit Exceeded” alert.</span>

export const EVENT_CALCULATION_METHOD = <span>For outcome values that are percentages, this is used to indicate how the source agency calculates the percentage. <br />
    For outcome values that aren't percentages, this should be set to "Value" to indicate that the source agency <br />
    simply provides the actual value, rather than calculating a percentage.</span>

export const EVENT_STRIKE_UNIT = <span>The data type of the outcome value from the source agency.</span>

export const EVENT_TIME_SPECIFIER = <span>An optional Year, Year + Month, or Year + Month + Day to indicate the time period of the underlying event. <br />
    For events whose time periods don't correspond to an exact year, month, or day, the exchange can establish a <br />
    convention to determine how this should be specified. For example, the exchange <br />
    could determine that the time specifier of a quarterly event is the Year + Month of the last month in the quarter.</span>

export const EVENT_QUESTION_TEMPLATE = <span>An optional template that can be used by external systems to programmatically generate the Question for instruments for this product. <br />
    For example, a Question Template of "Will the {'{{ .Instrument.GetEventAttributes.EventDisplayName }}'} be {'{{ .Instrument.GetEventAttributes.EvaluationType }}'} {'{{ .Instrument.GetEventAttributes.StrikeValue }}'} at the close of trading?" <br />
    could be used to generate the following Question for an instrument: "Will the December 31st, 2025 S&P 500 Index be {'>'} 6,000 at the close of trading?"</span>

export const EVENT_STRIKE_EVALUATION_LOWER_LIMIT = <span>Optional field to communicate to external systems that outcome values less than this are invalid, <br />
    and therefore the outcome of the instrument should not be determined.</span>

export const EVENT_STRIKE_EVALUATION_UPPER_LIMIT = <span>Optional field to communicate to external systems that outcome values greater than this are invalid, <br />
    and therefore the outcome of the instrument should not be determined.</span>

export const TRADE_DAY_ROLL_SCHEDULE = <span style={tooltipStyle}>The Trade Day Roll Schedule is optional and serves two purposes:
        <ul>
            <li>Allows EP3 or downstream components to include the trade date on an event (e.g. order, execution, <br />
                or trade). Useful for exchanges whose concept of "trade day" isn't the same as a calendar day.</li>
            <li>If the instrument is currently still open, EP3 will perform the EOD market actions (e.g. canceling DAY <br />
                orders) when the cutoff time is reached. Useful for exchanges that don't have a scheduled close period <br />
                each day.</li>
        </ul>
    </span>

export const SKIP_RISK_AND_CURRENCY_CHECKS = <span style={tooltipStyle}>Checking this will bypass Pre-Trade Risk (“PTR”) checks (for both sufficient Buying Power and sufficient <br />
    position) and post-trade currency balance updates at the instrument level. For example, if PTR is enabled, <br />
    you can now create a new instrument that bypasses EP3's PTR checks and post-trade currency balance updates.</span>
