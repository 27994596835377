import { convertEnumToDropdownList } from "../modules/util";
import {
  InterestRateSwapAttributesBusinessDayConvention,
  InterestRateSwapAttributesFrequency,
  InterestRateSwapAttributesDayCountConvention,
  InterestRateSwapAttributesDateRelativeTo,
  InterestRateSwapAttributesCompoundingMethod,
  InterestRateSwapAttributesSideConvention,
  InterestRateSwapAttributesDayType,
  InterestRateSwapAttributesTimePeriod,
  InterestRateSwapAttributesExchangeRateQuoteBasis,
} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/swaps_pb";

import {
  InstrumentState, InstrumentType, SchedulingFilterState, InstrumentProductType,
  PutOrCall, TradableFilter, SettlementPriceLogic, SettlementPriceCalculationMethod
} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import { Side } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import { SecurityType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/risk/v1beta1/risk_pb";
import { CalculationMethod, PayoutType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/event_pb.js"

import FormatString, { StringFormatOptions } from "../modules/formatString"

export default class InstrumentStaticData {
  static get HolidayCalendarList() {
    return [
      "New York",
      "London",
      "TARGET",
      "Canada",
      "Norway",
      "Swiss",
      "Japan",
      "Sweden",
      "Denmark",
    ];
  }

  static get IndexSourceList() {
    return [
      "UKRPI",
      "CPTFEMU",
      "FRCPXTOB"
    ];
  }

  static get FloatingRateIndexList() {
    return [
      "1M USD LIBOR",
      "3M USD LIBOR",
      "6M USD LIBOR",
      "3M EURIBOR",
      "6M EURIBOR",
      "3M GDP LIBOR",
      "6M GDP LIBOR",
      "3M CDOR",
      "6M NIBOR",
      "3M JPY LIBOR",
      "6M JPY LIBOR",
      "6M CNF LIBOR",
      "3M BBSW",
      "6M BBSW",
      "3M STIBOR",
      "6M CIBOR2",
    ];
  }

  static get FixedRollConventionList() {
    return [
      "EOM",
      "FRN",
      "IMM",
      "IMMCAD",
      "IMMAUD",
      "IMMNZD",
      "SFE",
      "NONE",
      "TBILL",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "MON",
      "TUE",
      "WED",
      "THU",
      "FRI",
      "SAT",
      "SUN",
    ];
  }

  static get FixingDatesDayType() {
    return convertEnumToDropdownList(
      InterestRateSwapAttributesDayType
    );
  }
  static get QuoteBasisOptions() {
    return convertEnumToDropdownList(
      InterestRateSwapAttributesExchangeRateQuoteBasis
    );
  }

  static get SideConventionOptions() {
    return convertEnumToDropdownList(InterestRateSwapAttributesSideConvention);
  }

  static get BusinessDayConventionOptions() {
    return convertEnumToDropdownList(
      InterestRateSwapAttributesBusinessDayConvention
    );
  }

  static get PaymentFrequencyOptions() {
    return convertEnumToDropdownList(InterestRateSwapAttributesFrequency);
  }

  static get FixedPaymentFrequency() {
    return [
      { id: 2, name: "Quarterly" },
      { id: 3, name: "Semiannually" },
      { id: 4, name: "Annually" },
    ];
  }

  static get FloatPaymentFrequency() {
    return [
      { id: 2, name: "Quarterly" },
      { id: 3, name: "Semiannually" },
    ];
  }

  static get FloatRateResetFrequency() {
    return [
      { id: 1, name: "Monthly" },
      { id: 2, name: "Quarterly" },
      { id: 3, name: "Semiannually" },
    ];
  }

  static get CompoundingOptions() {
    return convertEnumToDropdownList(
      InterestRateSwapAttributesCompoundingMethod
    );
  }

  static get DayCountConventionOptions() {
    return convertEnumToDropdownList(
      InterestRateSwapAttributesDayCountConvention
    );
  }

  static get DateRelativeToOptions() {
    return convertEnumToDropdownList(
      InterestRateSwapAttributesDateRelativeTo
    );
  }

  static get InstrumentStates() {
    return convertEnumToDropdownList(InstrumentState);
  }

  static get InstrumentTypes() {
    return convertEnumToDropdownList(InstrumentType).filter(x => x.id !== 0);
  }

  static get SchedulingFilterStates() {
    return convertEnumToDropdownList(SchedulingFilterState).filter(x => x.id !== 0);
  }

  static get PutOrCallOptions() {
    return convertEnumToDropdownList(PutOrCall).filter(x => x.id !== 0);
  }

  static get SideOptions() {
    return convertEnumToDropdownList(Side);
  }

  static get SecurityTypeOptions() {
    return convertEnumToDropdownList(SecurityType);
  }

  static EventCalculationMethod(excludeIds) {
    return convertEnumToDropdownList(CalculationMethod, excludeIds, new StringFormatOptions(FormatString.stringFormats.titleCase, true));
  }

  static get EventPayoutType() {
    return convertEnumToDropdownList(PayoutType);
  }

  static get TimePeriodOptions() {
    return convertEnumToDropdownList(
      InterestRateSwapAttributesTimePeriod
    );
  }

  static get TradableFilters() {
    return convertEnumToDropdownList(TradableFilter).filter(x => x.id !== 0);
  }

  static SettlementPriceLogics(excludeIds) {
    return convertEnumToDropdownList(SettlementPriceLogic, excludeIds, new StringFormatOptions(FormatString.stringFormats.capitalizeAllCase, false));
  }

  static SettlementPriceCalculationEndingMethods() {
    let excludeIds = [SettlementPriceCalculationMethod.SETTLEMENT_PRICE_CALCULATION_METHOD_EVENT_TIER_1, SettlementPriceCalculationMethod.SETTLEMENT_PRICE_CALCULATION_METHOD_EVENT_TIER_2, SettlementPriceCalculationMethod.SETTLEMENT_PRICE_CALCULATION_METHOD_EVENT_TIER_3, SettlementPriceCalculationMethod.SETTLEMENT_PRICE_CALCULATION_METHOD_EVENT_TIER_4, SettlementPriceCalculationMethod.SETTLEMENT_PRICE_CALCULATION_METHOD_EVENT_TIER_5, SettlementPriceCalculationMethod.SETTLEMENT_PRICE_CALCULATION_METHOD_OVERRIDE];
    return convertEnumToDropdownList(SettlementPriceCalculationMethod, excludeIds, new StringFormatOptions(FormatString.stringFormats.capitalizeAllCase, false));
  }

  static InstrumentProductTypes() {
    return convertEnumToDropdownList(InstrumentProductType);
  }
}
