import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import styles from "../../../order-history/styles.css";

class MarketStatsDetail extends Component {
  render() {
    let { marketStatsData } = this.props;
    let dataset = {
      "Stats Details": [
        ["Transaction Time", marketStatsData.transactionTime],
        ["Open Price", !!marketStatsData.openSetTime ? marketStatsData.openPx : ""],
        ["Open Set Time", marketStatsData.openSetTime],
        ["Indicative Open Price", !!marketStatsData.indicativeOpenSetTime ? marketStatsData.indicativeOpenPx : ""],
        ["Indicative Open Set Time", marketStatsData.indicativeOpenSetTime],
        ["High Price", !!marketStatsData.highSetTime ? marketStatsData.highPx : ""],
        ["High Set Time", marketStatsData.highSetTime],
        ["Low Price", !!marketStatsData.lowSetTime ? marketStatsData.lowPx : ""],
        ["Low Set Time", marketStatsData.lowSetTime],
        ["Close Price", !!marketStatsData.closeSetTime ? marketStatsData.closePx : ""],
        ["Close Set Time", marketStatsData.closeSetTime],
        ["Last Trade Price", !!marketStatsData.lastTradeSetTime ? marketStatsData.lastTradePx : ""],
        ["Last Trade Set Time", marketStatsData.lastTradeSetTime],
        ["Last Trade Quantity", marketStatsData.lastTradeQty],
        ["Notional Volume", !!marketStatsData.notionalSetTime ? marketStatsData.notionalTraded : ""],
        ["Notional Volume Set Time", marketStatsData.notionalSetTime],
        ["Shares Traded", marketStatsData.sharesTraded],
        ["Settlement Price", !!marketStatsData.settlementSetTime ? marketStatsData.settlementPx : ""],
        ["Settlement Set Time", marketStatsData.settlementSetTime],
        ["Settlement Preliminary Flag", String(marketStatsData.settlementPreliminaryFlag)],
        ["Settlement Price Calculation Method", !!marketStatsData.settlementPriceCalculationMethod ? marketStatsData.settlementPriceCalculationMethod.name : ''],
        ["Open Interest", !!marketStatsData.openInterestSetTime ? marketStatsData.openInterest : ""],
        ["Open Interest Set Time", marketStatsData.openInterestSetTime],
      ]
    };

    return (
      <div>
        {dataset["Stats Details"].map((data, index) => (
          <ListGroup className="order-details-list-group" key={index}>
            <ListGroup.Item
              key={0}
              className={`order-details-list-item order-details-key ${index % 2 === 0 ? styles.oddRow : ""}`}
            >
              {data[0]}
            </ListGroup.Item>
            <ListGroup.Item
              key={1}
              className={`order-details-list-item ${index % 2 === 0 ? styles.oddRow : ""}`}
            >
              {data[1]}
            </ListGroup.Item>
          </ListGroup>
        ))}
      </div>
    );
  }
}
export default MarketStatsDetail;
