import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import OrderDetails from '../order-history/OrderDetails'
import _ from "lodash";

const ExecutionSearchDataGrid = ({
  data,
  showExecutionDetails,
  executionData,
  updateExecutionViewState,
  onPageChange,
  paginationComponent,
  onPageSizeChange
}) => {

  const viewExecution = (e) => {
    return (<Button variant="buttonActions" size="sm" onClick={() => { orderPopup(e) }} title="View">
      <i className="fa fa-fw fa-eye orange-icon" />
    </Button>)
  }

  const orderPopup = (e) => {
    updateExecutionViewState(true, e.row._original);
  }

  const onDialogClose = () => {
    updateExecutionViewState(false);
  }


  const columns = [
    {
      Header: "LAST TRANSACT TIME",
      accessor: "lastTransactTime",
      minWidth: 180
    },
    {
      Header: 'TRADE DATE',
      id: 'transactTradeDate',
      accessor: 'transactTradeDate',
      minWidth: 100,
    },
    {
      Header: "ORD STATUS",
      id: "orderStatus",
      accessor: "orderStatus",
      minWidth: 100
    },
    {
      Header: "TEXT",
      accessor: "text",
      minWidth: 100
    },
    {
      Header: "SYMBOL",
      accessor: "symbol",
      minWidth: 80
    },
    {
      Header: 'SUB TYPE',
      accessor: 'symbolsubtype',
      minWidth: 80
    },
    {
      Header: "SIDE",
      accessor: "side",
      minWidth: 50
    },
    {
      Header: "EXEC TYPE",
      id: "type",
      accessor: d => `${d.type} ${d.orderRejectReasonText}`,
      minWidth: 100
    },
    {
      Header: "FILL QTY",
      accessor: "fillQty",
      minWidth: 80
    },
    {
      Header: "FILL PX",
      id: "fillPx",
      accessor: d => (!!d.symbol ? d.fillPx : ""),
      minWidth: 80
    },
    {
      Header: "TRADE ID",
      accessor: "tradeid",
      minWidth: 80
    },
    {
      Header: "EXEC ID",
      accessor: "execId",
      minWidth: 80
    },
    {
      Header: "OPEN QTY",
      accessor: "openQty",
      minWidth: 80
    },
    {
      Header: "CUM QTY",
      accessor: "cumQty",
      minWidth: 80
    },
    {
      Header: "AVG PX",
      accessor: "avgPx",
      minWidth: 80
    },
    {
      Header: "ORD QTY",
      accessor: "orderQty",
      minWidth: 80
    },
    {
      Header: 'CASH QTY',
      id: 'cashOrderQty',
      accessor: d => (d.cashOrderQty > 0 ? d.cashOrderQty : ""),
      minWidth: 100
    },
    {
      Header: "ORDER TYPE",
      accessor: "ordType",
      minWidth: 100
    },
    {
      Header: "TIF",
      accessor: "timeInForce",
      minWidth: 100
    },
    {
      Header: "LIMIT PX",
      id: "price",
      accessor: d =>
        d.ordType && _.includes(d.ordType.toLowerCase(), "limit")
          ? d.price
          : "",
      minWidth: 80
    },
    {
      Header: "STOP PX",
      id: "stopPrice",
      accessor: d =>
        d.ordType && _.includes(d.ordType.toLowerCase(), "stop")
          ? d.stopPrice
          : "",
      minWidth: 80
    },
    {
      Header: 'ORD CAPACITY',
      accessor: 'orderCapacity',
      minWidth: 100,
      sortable: false
    },
    {
      Header: "FIRM",
      accessor: "participant_firm",
      minWidth: 100
    },
    {
      Header: "ACCOUNT",
      accessor: "account",
      minWidth: 100
    },
    {
      Header: "USER",
      accessor: "participant",
      minWidth: 100
    },
    {
      Header: "ORDER ID",
      accessor: "orderid",
      minWidth: 100
    },
    {
      Header: "EXCH CROSS ID",
      accessor: "hostcrossid",
      minWidth: 110
    },
    {
      Header: '',
      accessor: 'execId',
      sortable: false,
      maxWidth: 45,
      minWidth: 45,
      Cell: e => { return viewExecution(e) },
    },
  ];
  return (
    <div>
      <DataGrid
        data={data}
        columns={columns}
        filterable={false}
        manual={true}
        onPageChange={onPageChange}
        PaginationComponent={paginationComponent}
        onPageSizeChange={onPageSizeChange}
      ></DataGrid>
      {showExecutionDetails &&
        <OrderDetails
          orderData={executionData}
          onDialogClose={onDialogClose}
          isExecution={true}
        />
      }
    </div>
  );
};

ExecutionSearchDataGrid.propTypes = {
  data: PropTypes.array.isRequired
};

export default ExecutionSearchDataGrid;
