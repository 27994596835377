import { dateToProtoDate } from "../../actions/ptypes";
const { UserAttributes, BeneficialOwnershipAttribute } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb");

export default class UserAttributesMapper {
  static mapToUserAttributes(data) {
    var userAttributes = new UserAttributes();

    if (data.legalName) userAttributes.setLegalName(data.legalName);
    if (data.title) userAttributes.setTitle(data.title);
    if (data.phoneNumber) userAttributes.setPhoneNumber(data.phoneNumber);
    if (data.email) userAttributes.setEmail(data.email);
    if (data.dateOfBirth) userAttributes.setDateOfBirth(dateToProtoDate(data.dateOfBirth));
    if (data.registrationNumber) userAttributes.setRegistrationNumber(data.registrationNumber);
    if (data.address) userAttributes.setAddress(data.address);
    if (data.description) userAttributes.setDescription(data.description);
    if (data.oauthId) userAttributes.setOauthId(data.oauthId);
    if (data.legalEntityIdentifier) userAttributes.setLegalEntityIdentifier(data.legalEntityIdentifier);

    let ownershipAttributes = []
    if (data.cftcForm102Owner) ownershipAttributes.push(BeneficialOwnershipAttribute.BENEFICIAL_OWNERSHIP_ATTRIBUTE_OWNER)
    if (data.cftcForm102Controller) ownershipAttributes.push(BeneficialOwnershipAttribute.BENEFICIAL_OWNERSHIP_ATTRIBUTE_CONTROLLER)
    userAttributes.setBeneficialOwnershipAttributesList([...ownershipAttributes])

    return userAttributes;
  }
}
