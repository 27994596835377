import { AdminAPIPromiseClient } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb";
import { } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb";
import { DeactivateMFARequest } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js";
import apiCallPromise from "../modules/apiCallPromise";

const { BasicAuthAPIPromiseClient } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_grpc_web_pb.js");

const {
  EnrollMFARequest,
  ConfirmMFARequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_pb.js");

const { apiUrl } = require("../modules/util");

const basicAuthAPIAsyncClient = new BasicAuthAPIPromiseClient(apiUrl(), null, null);
const adminAPIAsyncClient = new AdminAPIPromiseClient(apiUrl(), null, null);

export default class AuthService {
  static enrollMFAAsync = async (userName, password) => {
    const req = new EnrollMFARequest();

    req.setUsername(userName);
    req.setPassword(password);

    const resp = await apiCallPromise(basicAuthAPIAsyncClient, "enrollMFA", req);
    const enrollmentUrl = resp.getEnrollmentUrl()
    return enrollmentUrl;
  }


  static confirmMFAAsync = async (userName, password, otp) => {
    const req = new ConfirmMFARequest();
    req.setUsername(userName);
    req.setPassword(password);
    req.setOtp(otp);


    const resp = await apiCallPromise(basicAuthAPIAsyncClient, "confirmMFA", req);
    return resp
  }

  static DeactivateMFA = async (userName) => {
    const req = new DeactivateMFARequest();
    req.setUsername(userName);

    try {
      const resp = await apiCallPromise(adminAPIAsyncClient, "deactivateMFA", req);
      return { resp: resp, error: null };
    } catch (e) {
      return { resp: null, error: e };
    }
  }
}