import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import DataGridActions from "../core/data-grid/DataGridActions";
import { getValue, getOptionName, isArrayWithValues } from "../../modules/util";
import FirmsStaticData from "../../modules/firmsStaticData";

const UserAccountsDataGrid = ({ data, firms, onView, onEdit, onRemove, onUnDelete, allAccounts }) => {

  const [customerOrderCapacity, setCustomerOrderCapacity] = useState();
  const [accountTypes, setAccountTypes] = useState();
  const [accounts, setAccounts] = useState();

  useEffect(() => {
    const accountTypes = FirmsStaticData.AccountTypes([0]);
    setAccountTypes(accountTypes);
    const customerOrderCapacity = FirmsStaticData.CustomerOrderCapacity([0]);
    setCustomerOrderCapacity(customerOrderCapacity)
    prepareGridDate();
    setAccounts(data)
  }, [data])

  const prepareGridDate = () => {
    if (isArrayWithValues(data)) {
      data.forEach(element => {
        element.accountTypeName = getOptionName(accountTypes, element.accountType, "");
        element.customerOrderCapacityName = getOptionName(customerOrderCapacity, element.customerOrderCapacity, "");
      });
    }
  }

  const firmsList = !firms ? [] : firms;
  const firmsMap = new Map(firmsList.map(obj => [obj.name, obj.displayName ? obj.displayName : obj.name]));

  const columns = [
    {
      Header: "NAME",
      accessor: "displayName",
      maxWidth: 250,
      minWidth: 250,
    },
    {
      Header: "CUSTOMER ORDER CAPACITY",
      id: "customerOrderCapacity",
      accessor: (d) => d.customerOrderCapacity.name,
      maxWidth: 250,
      minWidth: 250,
    },
    {
      Header: "ACCOUNT TYPE",
      accessor: "accountTypeName",
      maxWidth: 250,
      minWidth: 250,
    },
    {
      Header: "ASSOCIATED FIRM",
      id: "associatedFirm",
      accessor: (d) => firmsMap.has(d.associatedFirm) ? firmsMap.get(d.associatedFirm) : d.associatedFirm,
    },
    {
      Header: "CLEARING FIRM",
      id: "parentFirm",
      accessor: (d) => firmsMap.has(d.parentFirm) ? firmsMap.get(d.parentFirm) : d.parentFirm,
    },
    {
      Header: "COLLATERAL ACCOUNT",
      id: "collateralAccount",
      accessor: (d) => {
        if (allAccounts)
          return getValue(allAccounts.find(x => x.name === d.collateralAccount), "displayName", "")
        else
          return getValue(data.find(x => x.name === d.collateralAccount), "displayName", "")
      },
    },
    {
      Header: "STATE",
      id: "state",
      accessor: (d) => (d.isSuspended ? "Suspended" : "Active"),
      maxWidth: 120,
      minWidth: 120,
    },
    {
      Header: "ACTIONS",
      maxWidth: 120,
      minWidth: 120,
      sortable: false,
      Cell: (row) => {
        return (
          row.original.deleted ? (
            <DataGridActions
              onView={() => {
                onView(row.original.name);
              }}
              onUnDelete={() => {
                onUnDelete(row.original.name);
              }}
              viewLink={`/${row.original.name}`}
            />) :
            (
              <DataGridActions
                onView={() => {
                  onView(row.original.name);
                }}
                onEdit={() => {
                  onEdit(row.original.name);
                }}
                onRemove={() => {
                  onRemove(row.original.name, row.original);
                }}
                deleteMsg='Remove'
                viewLink={`/${row.original.name}`}
                editLink={`/${row.original.name}/edit`}
              />)
        )
      },
    },
  ];
  return (
    <div>
      <DataGrid data={accounts} columns={columns} filterable={false} />
    </div>
  );
};

UserAccountsDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default UserAccountsDataGrid;
