import { push } from "connected-react-router";
import {
  FETCH_INSTRUMENTS_START,
  FETCH_INSTRUMENTS_SUCCEED,
  FETCH_INSTRUMENTS_FAILED,
  FETCH_METADATA_START,
  FETCH_METADATA_SUCCEED,
  FETCH_METADATA_FAILED,
  FETCH_HOLIDAY_CALENDARS_START,
  FETCH_HOLIDAY_CALENDARS_SUCCEED,
  FETCH_HOLIDAY_CALENDARS_FAILED,
  CREATE_INSTRUMENT_STATE,
  FETCH_INSTRUMENT_START,
  FETCH_INSTRUMENT_SUCCEED,
  FETCH_INSTRUMENT_FAILED,
  CLEAN_INSTRUMENT,
  UPDATE_INSTRUMENT_STATE_START,
  UPDATE_INSTRUMENT_STATE_SUCCEED,
  UPDATE_INSTRUMENT_STATE_FAILED,
  UPDATE_INSTRUMENT_ITEM,
  SET_STATE_WARNING_MESSAGE,
  SET_NOT_ALLOWED_STATES,
  CLEAN_INSTRUMENTS_LIST,
  SET_INSTRUMENT_IDS_LIST,
  REFRESH_INSTRUMENTS_SEARCH,
  REMOVE_INSTRUMENTS,
  SET_INSTRUMENT_BOOKS,
  FETCH_INSTRUMENTS_REPORT_START,
  FETCH_INSTRUMENTS_REPORT_SUCCEED,
  FETCH_INSTRUMENTS_REPORT_FAILED,
  FETCH_SEARCH_INSTRUMENT_STATS_START,
  FETCH_SEARCH_INSTRUMENT_STATS_SUCCEED,
  FETCH_SEARCH_INSTRUMENT_STATS_FAILED,
  FETCH_SYMBOLS_START,
  FETCH_SYMBOLS_SUCCEED,
  FETCH_SYMBOLS_FAILED,
} from "../constants/instrumentTypes";
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";

import Instrument from "../entities/Instrument";
import Product from "../entities/Product";
import InstrumentFormItem from "../entities/dto/InstrumentFormItem";

import InstrumentService from "../services/InstrumentService";
import ProductService from "../services/ProductService";

import InstrumentMapper from "../modules/mappers/instrumentMapper";
import Notification from "../modules/notifications";
import Holiday from "../entities/Holiday";
import ProtobufParser from "../modules/protobufParser";
import InstrumentStats from "../../src/entities/InstrumentStats";

export const CleanInstrument = () => ({
  type: CLEAN_INSTRUMENT,
});

export const cleanInstrumentsList = () => ({
  type: CLEAN_INSTRUMENTS_LIST,
});

export const IsCreatingInstrument = (isCreatingInstrument) => ({
  type: CREATE_INSTRUMENT_STATE,
  value: isCreatingInstrument,
});

/**
 * Gets a list of all existing instruments.
 * @return {array} Instruments list.
 */
export function fetchInstruments(productId) {
  return (dispatch) => {
    dispatch({ type: FETCH_INSTRUMENTS_START });
    dispatch({ type: REMOVE_INSTRUMENTS });

    const errorHandler = (err) => {
      if (err) {
        dispatch({ type: FETCH_INSTRUMENTS_FAILED });
        Notification.error(`Cannot get list of instruments: ${err.message}`);
      }
    };
    const sucessHandler = (instrurments) => {
      var instrumentsList = instrurments.map((instrument) => {
        return new Instrument(instrument);
      });
      dispatch({
        type: FETCH_INSTRUMENTS_SUCCEED,
        payload: [...instrumentsList],
      });
    };
    InstrumentService.getAll(errorHandler, sucessHandler, productId);
  };
}

export function fetchInstruments2(pageToken, pageSize, filterItems, cb) {
  return (dispatch, getState) => {
    let filteredInstruments = [];
    const instrumentIds = new Set();
    dispatch({ type: FETCH_INSTRUMENTS_START });
    // if it is a fresh search start - remove all existing instruments in state.
    if (!pageToken) dispatch({ type: REMOVE_INSTRUMENTS });

    const errorHandler = (err) => {
      if (err) {
        dispatch({ type: FETCH_INSTRUMENTS_FAILED });
        Notification.error(`Cannot get list of instruments: ${err.message}`);
      }
    };

    const sucessHandler = (_instrurments, nextPageToken, requestToken) => {
      let instruments = _instrurments.map((instrument) => {
        return new Instrument(instrument);
      });

      if (filterItems)
        instruments = filterInstruments(instruments, filterItems);

      if (Array.isArray(instruments)) {
        instruments.forEach(inst => {
          if (!instrumentIds.has(inst.id)) {
            filteredInstruments.push(inst);
            instrumentIds.add(inst.id);
          }
        });
      }

      // filter items of the first call because first call might contain duplicates from last search.
      if (pageToken && pageToken === requestToken) {
        // remove instruments from list already shown to user.. fetch new ones.
        let existingInstruments = getState().instruments.instruments;

        filteredInstruments = filteredInstruments.filter(
          (ins) => !existingInstruments.find((item) => item.id === ins.id)
        );
      }

      if (nextPageToken && filteredInstruments.length < pageSize) {
        InstrumentService.getInstruments(
          errorHandler,
          sucessHandler,
          nextPageToken,
          pageSize,
          filterItems
        );
      } else {
        // if no of records are greater than page size now - start next call again from last token, not from new token. otherwise data may lost
        if (filteredInstruments.length > pageSize) {
          filteredInstruments.splice(pageSize);
          nextPageToken = requestToken;
        }

        dispatch({
          type: REFRESH_INSTRUMENTS_SEARCH,
          payload: {
            instruments: filteredInstruments,
            nextPageToken,
            pageToken,
          },
        });

        if (cb) {
          cb(filteredInstruments);
        }
      }
    };

    InstrumentService.getInstruments(
      errorHandler,
      sucessHandler,
      pageToken,
      pageSize,
      filterItems
    );
  };
}

export function exportInstruments(filterItems) {
  return (dispatch) => {
    let onSuccess = (data) => {
      dispatch({ type: FETCH_INSTRUMENTS_REPORT_SUCCEED });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `instruments.csv`);
      document.body.appendChild(link);
      link.click();
    };
    let onError = (error) => {
      console.error(error);
      dispatch({ type: FETCH_INSTRUMENTS_REPORT_FAILED });
      Notification.error("Could not download report.");
    };

    dispatch({ type: FETCH_INSTRUMENTS_REPORT_START });
    InstrumentService.downloadInstruments(filterItems, onSuccess, onError);
  };
}

function filterInstruments(instruments, filterItems) {
  return instruments.filter((instrument) => {
    if (
      filterItems.basecurrency &&
      instrument.basecurrency !== filterItems.basecurrency.value
    )
      return false;

    // eslint-disable-next-line eqeqeq
    if (filterItems.tenor && instrument.tenor != filterItems.tenor.value)
      return false;

    if (
      filterItems.floatingRateIndex &&
      instrument.floatingRateIndex !== filterItems.floatingRateIndex.value
    )
      return false;

    if (
      filterItems.floatingRateIndexTenor &&
      // eslint-disable-next-line eqeqeq
      instrument.floatingRateIndexTenor !=
      filterItems.floatingRateIndexTenor.value
    )
      return false;

    if (
      filterItems.tradeDate &&
      (filterItems.tradeDate.value.getDate() !==
        instrument.startDate.getDate() ||
        filterItems.tradeDate.value.getFullYear() !==
        instrument.startDate.getFullYear() ||
        filterItems.tradeDate.value.getMonth() !==
        instrument.startDate.getMonth())
    )
      return false;

    if (
      filterItems.clearingHouse &&
      instrument.clearingHouse !== filterItems.clearingHouse.value
    )
      return false;

    return true;
  });
}

/**
 * Gets a map of admin metadata.
 * @return {map} Admin metadata.
 */
export function fetchMetadata() {
  return (dispatch) => {
    dispatch({ type: FETCH_METADATA_START });

    const cb = (err, response) => {
      if (response) {
        const currencies = JSON.parse(
          response.getMetadataMap().get("currencies")
        );
        const location = response.getMetadataMap().get("location");
        dispatch({
          type: FETCH_METADATA_SUCCEED,
          payload: { currencies: currencies, location: location },
        });
      }

      if (err) {
        dispatch({ type: FETCH_METADATA_FAILED });
        Notification.error(`Cannot get admin metadata: ${err.message}`);
      }
    };

    InstrumentService.getMetadata(cb);
  };
}

/**
 * Gets a list of holiday calendar events.
 * @return {function(*): void}
 */
export function listHolidayCalendars() {
  return (dispatch) => {
    dispatch({ type: FETCH_HOLIDAY_CALENDARS_START });

    const cb = (err, response) => {
      if (response) {
        let events = [];
        let calendars = {};
        response.getHolidayCalendarsList().forEach(cal => {
          calendars[cal.getId()] = {
            global: cal.getGlobal(),
            description: cal.getDescription(),
          }
          cal.getHolidaysList().forEach(holiday => {
            events.push(new Holiday(cal, holiday));
          })
        })
        dispatch({
          type: FETCH_HOLIDAY_CALENDARS_SUCCEED,
          payload: events,
          calendars: calendars,
        });
      }

      if (err) {
        dispatch({ type: FETCH_HOLIDAY_CALENDARS_FAILED });
        Notification.error(`Cannot get holiday calendars: ${err.message}`);
      }
    };

    InstrumentService.listHolidayCalendars(cb);
  };
}

/**
 * Updates a list of holiday calendar events.
 * @return {function(*): void}
 */
export function updateHolidayCalendar(baseEvent, newCalendar) {
  return (dispatch) => {
    const cb = (err, response) => {
      if (response) {
        listHolidayCalendars()(dispatch);
      }
      if (err) {
        Notification.error(`Cannot update holiday calendar: ${err.message}`);
      }
    };
    InstrumentService.updateHolidayCalendar(cb, baseEvent, newCalendar);
  }
}

/**
 * Deletes a holiday calendar.
 * @return {function(*): void}
 */
export function deleteHolidayCalendar(id) {
  return (dispatch) => {
    const cb = (err, response) => {
      if (response) {
        listHolidayCalendars()(dispatch);
      }
      if (err) {
        Notification.error(`Cannot delete holiday calendar: ${err.message}`);
      }
    };
    InstrumentService.deleteHolidayCalendar(cb, id);
  }
}

/**
 * Creates a holiday calendar.
 * @return {function(*): void}
 */
export function createHolidayCalendar(calendar) {
  return (dispatch) => {
    const cb = (err, response) => {
      if (response) {
        listHolidayCalendars()(dispatch);
      }
      if (err) {
        Notification.error(`Cannot create holiday calendar: ${err.message}`);
      }
    };
    InstrumentService.createHolidayCalendar(cb, calendar);
  }
}

/**
 * Gets a specific Instrument.
 * @param {string} id Instrument id.
 * @return {array} Instrument found.
 */
export function fetchInstrument(id) {
  return (dispatch) => {
    dispatch({ type: FETCH_INSTRUMENT_START });

    const cb = (err, response) => {
      if (response) {
        const instrument = new InstrumentFormItem(
          new Instrument(response.getInstrument())
        );
        dispatch({ type: FETCH_INSTRUMENT_SUCCEED, payload: instrument });
        dispatch({
          type: SET_NOT_ALLOWED_STATES,
          value: buildNotAllowedStateList(instrument),
        });
      }

      if (err) {
        dispatch({ type: FETCH_INSTRUMENT_FAILED });
        Notification.error(`Cannot get instrument information: ${err.message}`);
      }
    };

    InstrumentService.get(id, cb);
  };
}

/**
 * Updates the state of an Instrument.
 * @param {string} id Instrument id.
 * @param {string} stateId Instrument State Id.
 * @param {string} isScheduled Instrument's attribute.
 * @param {string} scheduleExecutionTime Future state execution time
 */
export function updateState(id, stateId, isScheduled, scheduleExecutionTime) {
  return (dispatch) => {
    dispatch({ type: UPDATE_INSTRUMENT_STATE_START });

    const cb = (err, response) => {
      if (response) {
        dispatch({ type: UPDATE_INSTRUMENT_STATE_SUCCEED });
        dispatch(push("/instruments"));
        Notification.success("Instrument changes saved.");
      }

      if (err) {
        dispatch({ type: UPDATE_INSTRUMENT_STATE_FAILED });
        Notification.error(`Cannot change Instrument State. ${err.message}`);
      }
    };

    InstrumentService.updateState(id, stateId, isScheduled, scheduleExecutionTime, cb);
  };
}

/**
 * Creates an Intrument object from an existing product.
 * @param {string} productId Product id.
 * @returns {Instrument} Mapped instrument.
 */
export function fetchFromProduct(productId) {
  return (dispatch) => {
    dispatch({ type: FETCH_INSTRUMENT_START });

    const cb = (err, response) => {
      if (response) {
        const product = new Product(response.getProduct());
        const mappedInstrument = InstrumentMapper.mapFromProduct(product);
        dispatch({ type: FETCH_INSTRUMENT_SUCCEED, payload: mappedInstrument });
      }

      if (err) {
        dispatch({ type: FETCH_INSTRUMENT_FAILED });
        Notification.error(`Cannot get product information: ${err.message}`);
      }
    };

    ProductService.get(productId, cb);
  };
}

/**
 * Gets a list of all existing instruments ids.
 * @return {array} Instruments list.
 */
export function fetchInstrumentIdsList() {
  return (dispatch) => {
    const errorHandler = (err) => {
      if (err) {
        Notification.error(`Cannot get list of instrument ids: ${err.message}`);
      }
    };
    const successHandler = (instruments) => {
      var instrumentsList = instruments.map((instrument) => {
        return new Instrument(instrument).id;
      });
      dispatch({
        type: SET_INSTRUMENT_IDS_LIST,
        list: instrumentsList.sort(),
      });
    };

    InstrumentService.getAll(errorHandler, successHandler);
  };
}

/**
 * Gets a existing instruments Hide Market Data.
 * @return {Boolean} Instrument's Hide Market Data.
 */
export function updateInstrumentHideMarketData(instrumentId, value) {
  return (dispatch) => {
    const errorHandler = (err) => {
      if (err) {
        Notification.error(
          `Cannot set Hide Market Data of instruments: ${err.message}`
        );
      }
    };
    const sucessHandler = (hideMarketData) => {
      Notification.success("Instrument changes saved.");
    };
    InstrumentService.updateHideMarketData(
      instrumentId,
      value,
      errorHandler,
      sucessHandler
    );
  };
}

/**
 * Set market stats to instrument.
 * @return {Object} Instrument.
 */

export function setInstrumentMarketStats(instrumentId, stats, cb) {
  return (dispatch) => {
    InstrumentService.updateInstrumentStats(instrumentId, stats, cb);
  };
}

export function setSettlementPrice(instrumentId, settlementPrice, transactTime, settlementPreliminary, cb) {
  return (dispatch) => {
    InstrumentService.updateSettlementPrice(instrumentId, settlementPrice, transactTime, settlementPreliminary,  cb);
  };
}

export function setOpenInterest(instrumentId, openInterest, transactTime, cb) {
  return (dispatch) => {
    InstrumentService.updateOpenInterest(instrumentId, openInterest, transactTime, cb);
  };
}

const buildNotAllowedStateList = (instrument) => {
  var stateList = [];
  if (instrument.state.id !== InstrumentState.INSTRUMENT_STATE_PENDING) {
    stateList.push(InstrumentState.INSTRUMENT_STATE_PENDING);
  }
  return stateList;
};

export const UpdateInstrumentStateSucceed = () => ({
  type: UPDATE_INSTRUMENT_STATE_SUCCEED,
});

export const UpdateInstrumentStateFailed = (error) => ({
  type: UPDATE_INSTRUMENT_STATE_FAILED,
  payload: error,
});

export const UpdateInstrumentItem = (key, value) => ({
  type: UPDATE_INSTRUMENT_ITEM,
  key: key,
  value: value,
});

export const SetStateWarningMessage = (message) => ({
  type: SET_STATE_WARNING_MESSAGE,
  value: message,
});

export const setInstrumentBooks = (books) => ({
  type: SET_INSTRUMENT_BOOKS,
  payload: { books: books },
});

export const searchInstrumentStats = (symbol, startDate, endDate, pageToken, pageSize) => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_SEARCH_INSTRUMENT_STATS_START });

    const cb = (err, response) => {
      if (response) {
        const state = getState();
        const { priceScale, fractionalQtyScale } = state.instruments.instrument;
        var transactionTimeList = response.getTransactTimeList();
        var statsList = response.getStatsList().map((stats, index) => {
            return new InstrumentStats(stats, transactionTimeList[index], priceScale, fractionalQtyScale);
        });

        const isEOF = response.getEof();
        const nextPageToken = !isEOF ? response.getNextPageToken() : null;

        dispatch({ type: FETCH_SEARCH_INSTRUMENT_STATS_SUCCEED, payload: { statsList, pageToken: pageToken, nextPageToken: nextPageToken, }});
      }

      if (err) {
        dispatch({ type: FETCH_SEARCH_INSTRUMENT_STATS_FAILED });
        Notification.error(`Cannot get instrument stats: ${err.message}`);
      }
    };

    InstrumentService.searchInstrumentStats(
      symbol,
      startDate ? ProtobufParser.toTimestamp(startDate) : null,
      endDate ? ProtobufParser.toTimestamp(endDate) : null,
      pageSize,
      pageToken,
      cb
    );
  };
};

export const fetchSymbols = () =>  {
  return (dispatch) => {
    dispatch({ type: FETCH_SYMBOLS_START });

    const cb = (err, response) => {
      if (response) {
        const symbols = response.getSymbolsList();
        dispatch({ type: FETCH_SYMBOLS_SUCCEED, payload: symbols });
      }
      if (err) {
        dispatch({ type: FETCH_SYMBOLS_FAILED });
        Notification.error(`Cannot get symbol list: ${err.message}`);
      }
    }

    InstrumentService.getSymbolList(cb);
  };
}
