import React from "react";
import { ButtonToolbar, ButtonGroup } from "react-bootstrap";

import "./DataGridActions.css";

const GridActions = (props) => {

  return (
    <>
      <ButtonToolbar>
        <ButtonGroup>
          {props.children}
        </ButtonGroup>
      </ButtonToolbar>
    </>
  );
};

export default GridActions;
