import { ADD_INSTRUMENTS_SCALES } from "../constants/orderTypes";
import InstrumentService from "../services/InstrumentService";
import Instrument from "../entities/Instrument";
import Notification from "../modules/notifications";

export function ensureAndApplyInstrumentScales(
  items,
  existingPriceScales,
  existingQuantityScales,
  getSymbolFunc,
  dispatch = null,
  applyScaleFunc = null
) {
  let newScales = {};
  let newQtyScales = {};
  let missingScales = [];
  let errorOccured = false;

  const defer = new Promise((resolve, reject) => {
    items.forEach((item) => {
      const symbol = getSymbolFunc(item);

      if (!existingPriceScales[symbol]) {
        missingScales.push(symbol);
      }
    });

    let promises = [];
    if (missingScales.length > 0) {
      const instProm = new Promise((instResolve, instReject) => {
        const errorHandler = (err) => {
          if (err) {
            if (!!err.code && err.code === 5) {
              Notification.error(err.message);
            }
            console.error(`Could not fetch scale for symbols: ${missingScales}. Error: ${err}`, missingScales, err);
            errorOccured = true
          }
        };
    
        const sucessHandler = (_instruments) => {
          let instruments = _instruments.map((instrument) => {
            return new Instrument(instrument);
          });
          instruments.forEach((instrument)=> {
            let symbol = instrument.id
            newScales[symbol] = instrument.priceScale;
            newQtyScales[symbol] = instrument.fractionalQtyScale;
          })
          instResolve()
        };

        InstrumentService.getInstruments2(errorHandler, sucessHandler, missingScales)
      });
      promises.push(instProm);
    }

    Promise.all(promises).then(() => {
      if (errorOccured) {
        Notification.error("Some prices could not be scaled. Please check the console logs for more detail.");
      }
      if (dispatch) {
        dispatch({ type: ADD_INSTRUMENTS_SCALES, payload: newScales, qtyPayload: newQtyScales });
      }
  
      if (applyScaleFunc) {
        items.forEach((item) => {
          const symbol = getSymbolFunc(item);
          const priceScale = existingPriceScales[symbol] || newScales[symbol];
          const quantityScale = existingQuantityScales[symbol] || newQtyScales[symbol];
          applyScaleFunc(item, priceScale, quantityScale);
        });
      }

      resolve(items, newScales);
    });
  });

  return defer;
}
