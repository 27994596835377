import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  FetchParticipantStart,
  FetchParticipantSucceed,
  FetchParticipantFailed,
  SaveParticipantStart,
  SaveParticipantSucceed,
  SaveParticipantFailed,
  LoadParticipantAttributes,
} from "../actions/participants";
import {
  FetchParticipantGroupsStart,
  FetchParticipantGroupsSucceed,
  FetchParticipantGroupsFailed,
} from "../actions/participantGroups";
import {
  FetchFirmsStart,
  FetchFirmsSucceed,
  FetchFirmsFailed,
  FetchFirmStart,
  FetchFirmSucceed,
  FetchFirmFailed,
  CleanFirm,
} from "../actions/firms";
import { SetCurrentTab } from "../actions/app";
import "./Content.css";
import Loader from "../components/core/loader/Loader";
import UsersHeader from "../components/users/UsersHeader";
import Notification from "../modules/notifications";
import UsersForm from "../components/users/UsersForm";
import UsersFormViewEdit from "../components/users/UsersFormViewEdit";
import { AdminDocumentTitle } from "../constants/strings";
import ParticipantFormItem from "../entities/dto/ParticipantFormItem";
import ParticipantService from "../services/ParticipantService";
import Participant from "../entities/Participant";
import ParticipantMapper from "../modules/mappers/participantMapper";
import { StatusCode } from "grpc-web";
import Firm from "../entities/Firm";
import * as firmService from "../services/FirmService";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import ParticipantGroupService from "../services/ParticipantGroupService";
import ParticipantGroup from "../entities/ParticipantGroup";
import { hasWriteAccess, onGenerate, onRevoke } from "../services/TokenService";
import UserAttributesMapper from "../modules/mappers/userAttributesMapper";
import { getFormMode } from "../modules/util";
import { FormModes } from "../constants/enumerations";
import AccountsInParticipantContainer from "../containers/AccountsInParticipantContainer";
import AuthService from "../services/AuthService";
import { AuthUtil } from "../modules/authUtil";

function mapStateToProps(state) {
  return {
    fetchingParticipant: state.participants.fetchingParticipant,
    fetchingParticipantGroups: state.fetchingParticipantGroups,
    fetchingFirms: state.firms.fetchingFirms,
    fetchingFirm: state.firms.fetchingFirm,
    participant: state.participants.participant,
    participants: state.participants.participants,
    participantGroups: state.participantGroups.participantGroups,
    firms: state.firms.firms,
    firm: state.firms.firm,
    currentTab: state.app.currentTab,
    accessToken: state.auth.accessToken,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchParticipantStart: (product) => {
    dispatch(FetchParticipantStart(product));
  },
  fetchParticipantSucceed: (product) => {
    dispatch(FetchParticipantSucceed(product));
  },
  fetchParticipantFailed: (product) => {
    dispatch(FetchParticipantFailed(product));
  },
  fetchFirmsStart: () => {
    dispatch(FetchFirmsStart());
  },
  fetchFirmsSucceed: (firms) => {
    dispatch(FetchFirmsSucceed(firms));
  },
  fetchFirmsFailed: () => {
    dispatch(FetchFirmsFailed());
  },
  saveFormStart: () => {
    dispatch(SaveParticipantStart());
  },
  saveFormSucceed: () => {
    dispatch(SaveParticipantSucceed());
  },
  saveFormFailed: () => {
    dispatch(SaveParticipantFailed());
  },
  fetchGroupsStart: () => {
    dispatch(FetchParticipantGroupsStart());
  },
  fetchGroupsSucceed: (participantGroupList) => {
    dispatch(FetchParticipantGroupsSucceed(participantGroupList));
  },
  fetchGroupsFailed: () => {
    dispatch(FetchParticipantGroupsFailed());
  },
  fetchFirmStart: (firm) => {
    dispatch(FetchFirmStart(firm));
  },
  fetchFirmSucceed: (firm) => {
    dispatch(FetchFirmSucceed(firm));
  },
  fetchFirmFailed: (firm) => {
    dispatch(FetchFirmFailed(firm));
  },
  cleanFirm: () => {
    dispatch(CleanFirm());
  },
  loadParticipantAttributes: (resourceName) => {
    dispatch(LoadParticipantAttributes(resourceName));
  },
  setCurrentTab: (selectedTab) => {
    dispatch(SetCurrentTab(selectedTab));
  },
});

class UsersFormPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  static tryGetJsonString(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;

    this.props.cleanFirm();
    this.loadFirms();
    this.loadGroups();

    const id = this.props.match.params.id;
    const firmid = this.props.match.params.firmid;

    if (id !== "new" && id !== "newService" && id !== undefined) {
      var name = id;
      if (firmid) {
        name = `firms/${firmid}/users/${id}`;
      }
      const decodedId = decodeURIComponent(name);
      this.loadParticipant(decodedId);
      this.setState({ decodedId: decodedId });
    } else if (firmid) {
      var firm = `firms/${firmid}`;
      this.loadFirm(firm);
    }
    window.scrollTo(0, 0);
  }

  getTitle = (mode) => {
    switch (mode) {
      case FormModes.edition:
        return "Edit User"
      case FormModes.creation:
        return "Create User"
      default:
        return "View User"
    }
  }

  getIsNewService = () => {
    return this.props.location.pathname.endsWith("/newService") > 0;
  };

  getIsFirmService = () => {
    return this.props.location.pathname.indexOf("/services") > 0;
  };

  loadFirm = (id) => {
    this.props.fetchFirmStart();
    const cb = (err, response) => {
      if (response) {
        const firm = new Firm(response.getFirm());
        this.props.fetchFirmSucceed(firm);
      }

      if (err) {
        this.props.fetchFirmFailed();
        Notification.error(`Cannot get firm information for name ${id}`);
      }
    };

    firmService.getByName(id, cb);
  };

  loadFirms = () => {
    this.props.fetchFirmsStart();

    const cb = (err, response) => {
      if (response) {
        var firmsList = response.getFirmsList().map((firm) => {
          return new Firm(firm);
        });
        this.props.fetchFirmsSucceed(firmsList);
      }

      if (err) {
        this.props.fetchFirmsFailed();
        Notification.error("Cannot get list of firms.");
      }
    };

    firmService.getAll(cb);
  };

  loadParticipant = (name) => {
    this.props.fetchParticipantStart();
    const cb = (err, response) => {
      if (response) {
        const participant = new ParticipantFormItem(
          new Participant(response.getParticipant())
        );
        this.loadFirm(participant.firm);
        this.props.loadParticipantAttributes(participant.name);
        this.props.fetchParticipantSucceed(participant);
      }

      if (err) {
        this.props.fetchParticipantFailed();
        Notification.error("Cannot get user information.");
      }
    };

    ParticipantService.get(name, cb);
  };

  loadGroups = () => {
    this.props.fetchGroupsStart();
    const cb = (err, response) => {
      if (response) {
        let groupsList = response.getParticipantGroupsList().map((group) => {
          return new ParticipantGroup(group);
        });
        this.props.fetchGroupsSucceed(groupsList);
      }

      if (err) {
        this.props.fetchGroupsFailed();
        Notification.error(err && err.message ? err.message : 'Cannot get groups list.');
      }
    };

    ParticipantGroupService.getAll(cb);
  };

  handleEditSubmit = (data, participant, setFieldError) => {
    const roleCallback = (err, response) => {
      if (response) {
        this.context.router.history.push("/users");
        Notification.success("User role updated.");
      }

      if (err) {
        this.processFormError(err, setFieldError);
      }
    };

    const stateCallback = (err, response) => {
      if (response) {
        this.context.router.history.push("/users");
        Notification.success("User state updated.");
      }

      if (err) {
        this.processFormError(err, setFieldError);
      }
    };

    if (data.state.id === 0) data.isSuspended = false;
    else data.isSuspended = true;
    if (participant) {
      if (participant.role.name !== data.role.name)
        ParticipantService.setRole(data, roleCallback);
      if (participant.isSuspended !== data.isSuspended)
        ParticipantService.suspend(data, stateCallback);

      const updateUserAttributes = () => {
        let userAttributes = UserAttributesMapper.mapToUserAttributes(data);
        ParticipantService.update(participant, userAttributes)
          .catch((err) => {
            this.processFormError(err, setFieldError);
          })
          .then((response) => {
            if (response) {
              this.props.saveFormSucceed();

              if(participant.serviceUser) {
                this.context.router.history.push(`/firms/${this.props.match.params.firmid}/services`);
                Notification.success("Firm service updated.");
              } else {
                this.context.router.history.push("/users");
                Notification.success("User updated.");
              }
            }
          });
      };

      let newParticipant = ParticipantMapper.mapToParticipant(
        data,
        participant
      );
      ParticipantService.updateParticipant(newParticipant)
        .catch((error) => {
          this.context.router.history.push("/users");
          Notification.error(
            `Error while updating user ${error.message ? error.message : ""}`
          );
        })
        .then((response) => {
          if (response) updateUserAttributes();
        });
    }
  };

  handleNewSubmit = async (data, setFieldError) => {
    this.props.saveFormStart();

    const participant = ParticipantMapper.mapToParticipant(data);

    try {
      const response = await ParticipantService.postPromise(participant);
      if (response) {
        let participant = new Participant(response.getParticipant());
        let userAttributes = UserAttributesMapper.mapToUserAttributes(data);
        const updateResponse = await ParticipantService.update(
          participant,
          userAttributes
        );
        if (updateResponse) {
          this.props.saveFormSucceed();

          if(data.serviceUser) {
              this.context.router.history.push(`/firms/${this.props.match.params.firmid}/services`);
              Notification.success("Firm service saved.");
          } else {
            this.context.router.history.push("/users");
            Notification.success("New user saved.");
          }
        }
      }
    } catch (error) {
      console.log("Create Participant", error);
      this.props.saveFormFailed();
      this.processFormError(error, setFieldError);
      Notification.error(error && error.message ? error.message : 'Error while adding the user')
      return;
    }
  };

  processFormError = (error, setFieldError) => {
    switch (error.code) {
      case StatusCode.ALREADY_EXISTS:
        setFieldError("id", error.message, false);
        Notification.error(
          `Failed to save this user, ID has already been used.`
        );
        break;
      default:
        Notification.error(`Failed to save this user. ${error.message}`);
        break;
    }
  };

  isAdminFirm = (firmType) => {
    return firmType === FirmType.FIRM_TYPE_SUPERVISOR || firmType === FirmType.FIRM_TYPE_CLEARING_MEMBER
  }

  onButtonClick = () => {
    const { participant } = this.props;
    const ttlSeconds = 60 * 60 * 24; //1 day
    onGenerate(
      this.state.decodedId,
      participant.displayName,
      this.isAdminFirm(this.props.firm.firmType.id),
      ttlSeconds,
      true
    );
  };

  onTestTokenClick = () => {
    const ttlSeconds = 60 * 10; //10 minutes
    const { participant } = this.props;
    onGenerate(
      this.state.decodedId,
      participant.displayName,
      this.isAdminFirm(this.props.firm.firmType.id),
      ttlSeconds,
      false
    );
  };

  onLogoffClick = () => {
    onRevoke(this.state.decodedId, false);
  };

  onRemoveMFAClick = async () => {
    const { error } = await AuthService.DeactivateMFA(this.state.decodedId);
    if (error) {
      Notification.error("Error: code " + error.code + ": " + error.message);
    } else {
      Notification.success("Successfully deactivated MFA!");
    }
  }

  selectTab = (selectedTab) => {
    let { firm, participant } = this.props;
    switch (selectedTab) {
      case "profile":
      case "users":
        return this.context.router.history.push(
          `/firms/${firm.id}/users/${participant.id}`
        );
      case "accounts":
        return this.context.router.history.push(
          `/firms/${firm.id}/users/${participant.id}/accounts`
        );
      default:
        console.error("No tab found!");
        break;
    }
  };

  render() {
    let {
      participants,
      participantGroups,
      firms,
      firm,
      fetchingFirm,
      fetchingFirms,
      fetchingParticipantGroups,
      fetchingParticipant,
    } = this.props;
    const path = getFormMode(this.props.location.pathname, (tab) => {
      this.props.setCurrentTab(tab);
    });
    return (
      <div className="with-callback">
        <UsersHeader
          headerText={this.getIsFirmService() ? "Firm Service" : "Users"}
          headerLink={`${window.location.origin}/users`}
          areTabsVisible={
            path !== FormModes.creation && !this.getIsFirmService()
          }
          isAddButtonVisible={
            hasWriteAccess() &&
            path !== FormModes.creation &&
            path !== FormModes.accounts &&
            !this.getIsFirmService()
          }
          onButtonClick={this.onButtonClick}
          onTestTokenClick={this.onTestTokenClick}
          onLogoffClick={this.onLogoffClick}
          onRemoveMFAClick={this.onRemoveMFAClick}
          selectTab={this.selectTab}
          selectedTab={this.props.currentTab}
          id={this.props.match.params.id}
          participant={this.props.participant}
          firm={this.props.firm}
          formMode={path}
          accessToken={this.props.accessToken}
        />
        {(() => {
          switch (path) {
            case FormModes.creation:
              return (
                <div>
                  <Loader
                    show={fetchingFirms || fetchingParticipantGroups}
                  ></Loader>
                  <UsersForm
                    onSubmitForm={this.handleNewSubmit}
                    participantGroups={
                      firm.id
                        ? participantGroups.filter(
                          (group) => group.firm === firm.id
                        )
                        : participantGroups
                    }
                    firms={firms.filter(
                      (firm) =>
                        firm.firmType.id !== FirmType.FIRM_TYPE_CLEARING_HOUSE
                    )}
                    preloadFirm={firm.id ? firm.id : null}
                    isNewService={this.getIsNewService()}
                    title={this.getTitle(path)}
                  />
                </div>
              );
            case FormModes.view:
            case FormModes.edition:
              return (
                <div>
                  <Loader show={fetchingParticipant || fetchingFirm}></Loader>
                  <UsersFormViewEdit
                    participant={participants ? participants.participant : null}
                    firm={firm}
                    onEditForm={path === FormModes.edition ? true : false}
                    handleEditSubmit={this.handleEditSubmit}
                    isFirmService={this.getIsFirmService()}
                    title={this.getTitle(path)}
                  />
                </div>
              );
            case FormModes.accounts:
              return <AccountsInParticipantContainer />;

            default:
              return;
          }
        })()}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersFormPage)
);
