import { convertEnumToDropdownList } from "./util";
import { ParticipantRole, ParticipantState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";

import FormatString, { StringFormatOptions } from "./formatString"

export default class UsersStaticData {
  static ParticipantRoles(excludeIds) {
    return convertEnumToDropdownList(ParticipantRole, excludeIds, new StringFormatOptions(FormatString.stringFormats.titleCase, false));
  }

  static ParticipantStates(excludeIds) {
    return convertEnumToDropdownList(ParticipantState, excludeIds, new StringFormatOptions(FormatString.stringFormats.titleCase, false));
  }
}
