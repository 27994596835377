import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import querystring from 'query-string';
import _ from "lodash"
import moment from "moment";

import "./Content.css";
import SearchNoItemsResult from "../components/core/search-no-items/SearchNoItemsResult";
import RFQSearchDataGrid from "../components/rfq-history/RFQSearchDataGrid";
import PaginationWithPageSize, {
  withPageToken,
} from "../components/core/data-grid/PaginationWithPageSize";
import {
  searchRequestForQuotes,
  cleanSearch,
} from "../actions/requestForQuotes";

import {
  FetchAccountsStart,
  FetchAccountsSucceed,
  FetchAccountsFailed,
  CleanAccount,
  LoadAccounts,
} from "../actions/accounts";
import {
  FetchParticipantsStart,
  FetchParticipantsSucceed,
  FetchParticipantsFailed,
  LoadParticipants,
} from "../actions/participants";
import { LoadFirms } from "../actions/firms";

import RFQFilter from "../components/core/filter/RFQFilter";
import ButtonBorderless from "../components/core/form/ButtonBorderless";
import { isNullOrUndefined, isEmptyString } from "../modules/util";
import { TitleBreadcrumb } from "../components/core/title-breadcrumb/title-breadcrumb";
import { NavigationManager } from "../components/core/helpers/NavigationHelper";


const STORAGE_PAGE_SIZE_KEY = "dataGridPageSize-RFQSearchPage";
const DEFAULT_PAGE_SIZE = 10;

function mapStateToProps(state) {
  return {
    fetchingRequestForQuotes: state.requestForQuotes.fetchingRequestForQuotes,
    requestForQuotes: state.requestForQuotes.requestForQuotes,
    noItemsFound: state.requestForQuotes.noItemsFound,
    tokens: state.requestForQuotes.tokens,
    pageToken: state.requestForQuotes.pageToken,
    accounts: state.accounts.accounts,
    participants: state.participants.participants,
    firms: state.firms.firms,
  };
}

const mapDispatchToProps = (dispatch) => ({
  searchRequestForQuotes: (filters, pageToken, pageSize) => {
    dispatch(searchRequestForQuotes(filters, pageToken, pageSize));
  },
  cleanSearch: () => {
    dispatch(cleanSearch());
  },
  fetchGroupsStart: () => {
    dispatch(FetchAccountsStart());
  },
  fetchGroupsSucceed: (accountList) => {
    dispatch(FetchAccountsSucceed(accountList));
  },
  fetchGroupsFailed: () => {
    dispatch(FetchAccountsFailed());
  },
  cleanAccount: () => {
    dispatch(CleanAccount());
  },
  fetchParticipantsStart: () => {
    dispatch(FetchParticipantsStart());
  },
  fetchParticipantsSucceed: (participanttList) => {
    dispatch(FetchParticipantsSucceed(participanttList));
  },
  fetchParticipantsFailed: () => {
    dispatch(FetchParticipantsFailed());
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
  loadAccounts: () => {
    dispatch(LoadAccounts());
  },
  loadParticipants: () => {
    dispatch(LoadParticipants());
  },
});

class RFQSearchPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  static emptyFilterObj = {
    fromDate: null,
    toDate: null,
    instrumentId: null,
    participant: null,
    account: null,
    clientId: null,
    rfqid: null,
    side: 0,
    rfqStateFilter: 0,
    firm: null,
  };

  constructor() {
    super();
    
    const queryParams = querystring.parseUrl(window.location.href);
    const hasParams = !_.isEmpty(queryParams.query);
    if (hasParams) {
      const searchState = JSON.parse(queryParams.query.filter);
      this.state = { ...RFQSearchPage.emptyFilterObj, ...searchState };
    } else {
      let _fromDate = moment().startOf("day").toDate();
      let _toDate = moment().endOf("day").toDate();

      const searchParams = {
        fromDate: {
          id: "fromDate",
          value: _fromDate,
          text: "From Date",
          name: _fromDate.toString(),
        },
        toDate: {
          id: "toDate",
          value: _toDate,
          text: "To Date",
          name: _toDate.toString(),
        }
      };
      let initialState = { ...RFQSearchPage.emptyFilterObj, ...searchParams };
      this.state = initialState;
    }

    this.timer = null;
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.getPageSizeFromStorage = this.getPageSizeFromStorage.bind(this);
  }

  componentDidMount() {
    this.props.cleanSearch();
    this.props.cleanAccount();
    this.props.loadAccounts();
    this.props.loadParticipants();
    this.props.loadFirms();

    if (!this.props.fetchingRequestForQuotes) {
      let reqObj = this.prepareReqObj(this.state)
      this.props.searchRequestForQuotes(reqObj, "", this.state.pageSize);
    }

    window.scrollTo(0, 0);
  }

  prepareReqObj = (filterItems) => {
    let values = {};
    Object.keys(filterItems).forEach((key) => {
      const _filterItem = filterItems[key];
      if (_filterItem && _filterItem.value) {

        if (key === "rfqid") {
          values[key] = `requestForQuotes/${_filterItem.value}`;
        } else {
          values[key] = _filterItem.value;
        }
      }
    });

    return values;
  }

  search = (values) => {
    let filteredValues = {};
    Object.keys(values).forEach((key) => {
      const _filterItem = values[key];
      if (!isNullOrUndefined(_filterItem) && !isEmptyString(_filterItem)) {
        filteredValues[key] = _filterItem;
      }
    });

    let stateObject = { ...RFQSearchPage.emptyFilterObj, ...filteredValues };

    let filterData = { filter: JSON.stringify(stateObject) };
    const parsedString = querystring.stringify(filterData);
    const routeUrl = this.context.router.route.location.pathname;
    this.context.router.history.push(`${routeUrl}?${parsedString}`);
  };

  onPageChange = (pageToken) => {
    let reqObj = this.prepareReqObj(this.state)
    this.props.searchRequestForQuotes(reqObj, pageToken, this.state.pageSize);
  };

  setPageSizeInStorage = (pageSize) => {
    window.localStorage.setItem(STORAGE_PAGE_SIZE_KEY, pageSize)
  }

  getPageSizeFromStorage = () => {
    const pageSize = window.localStorage.getItem(STORAGE_PAGE_SIZE_KEY)

    if (pageSize)
      return pageSize;

    return DEFAULT_PAGE_SIZE;
  }

  onPageSizeChange = (pageSize) => {
    this.setPageSizeInStorage(pageSize);
    this.setState({ pageSize: pageSize });
    let reqObj = this.prepareReqObj(this.state)
    this.props.searchRequestForQuotes(reqObj, "", pageSize);
  }

  getPaginationComponent = () => {
    const pageToken = this.props.pageToken;
    const tokens = this.props.tokens;
    const pageSize = Number(this.getPageSizeFromStorage());
    return withPageToken(PaginationWithPageSize, { pageToken, tokens }, pageSize);
  };

  parseDisplayNames = () => {
    let { requestForQuotes, accounts, participants, firms } = this.props;

    if (requestForQuotes.length > 0) {
      requestForQuotes.forEach((rfq) => {
        const matchedAccount = accounts.find(
          (account) => account.name === rfq.account
        );
        if (matchedAccount) rfq.account = matchedAccount.displayName;

        const matchedParticipant = participants.find(
          (participant) => participant.name === rfq.user
        );
        if (matchedParticipant) rfq.user = matchedParticipant.displayName;

        const matchedAgent = participants.find(
          (participant) => participant.name === rfq.submittingUser
        );
        if (matchedAgent) {
          rfq.submittingUser = matchedAgent.displayName;
          rfq.submittingFirm = matchedAgent.firm;
          const matchedAgentFirm = firms.find((firm) => firm.name === rfq.submittingFirm);
          if (matchedAgentFirm) rfq.submittingFirm = matchedAgentFirm.displayName;
        } else {
          rfq.submittingFirm = null;
        }

        rfq.name = rfq.name.replace("requestForQuotes/", "");

        const matchedFirm = firms.find((firm) => firm.name === rfq.firm);
        if (matchedFirm) rfq.firm = matchedFirm.displayName;
      });
    }
  };

  applyFilter = (filterItems) => {
    this.search(filterItems);
  };

  showQuoteHistory = (id, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/requestForQuotes/quoteHistory/${id}`);
    } else {
      this.context.router.history.push(`/requestForQuotes/quoteHistory/${id}`);
    }
  };

  showQuote = (id, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/requestForQuotes/quotes/${id}`);
    } else {
      this.context.router.history.push(`/requestForQuotes/quotes/${id}`);
    }
  };

  render() {
    let {
      fetchingRequestForQuotes,
      noItemsFound,
      requestForQuotes,
      pageToken,
      firms,
      accounts,
      participants,
    } = this.props;
    this.parseDisplayNames();
    return (
      <div className="with-callback">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TitleBreadcrumb titles={[{'title':'Requests for Quotes', link:`${window.location.origin}/requestForQuotes/search`}]} />

          <div style={{ display: "flex" }}>
            <ButtonBorderless
              customClassName="btn-quote-search"
              text="Quote Search"
              onClick={() => {
                this.context.router.history.push("/quotes/search");
              }}
            >
              Quote Search
            </ButtonBorderless>
          </div>
        </div>

        <RFQFilter
          onApply={this.applyFilter}
          loading={fetchingRequestForQuotes}
          filters={this.state}
          onSubmitForm={this.search}
          firms={firms}
          allAccounts={accounts}
          allParticipants={participants}
        ></RFQFilter>

        {noItemsFound && pageToken === undefined ? (
          <SearchNoItemsResult message="Sorry, but we didn't find any matching Request for Quotes." />
        ) : (
          <RFQSearchDataGrid
            data={requestForQuotes}
            linkBack={true}
            paginationComponent={this.getPaginationComponent()}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            onView={(id, actionContext) => {
              this.showQuote(id, actionContext);
            }}
            onQuoteHistory={(id, actionContext) => {
              this.showQuoteHistory(id, actionContext);
            }}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RFQSearchPage);
