import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import FirmsStaticData from "../../modules/firmsStaticData";
import { getOptionName } from "../../modules/util";
import GridActions from '../core/data-grid/GridActions';
import { GridActionDelete, GridActionEdit, GridActionView } from "../core/data-grid/GridAction";
import { useMemo } from "react";
import { GridActionMenu } from "../core/menu-items/menu-items";

const ParticipantsInGroupDataGrid = ({ data, onView, onEdit, onDelete, resource, showCustomerOrderCapacity = true }) => {

  const [customerOrderCapacity, setCustomerOrderCapacity] = useState()

  useEffect(() => {
    const customerOrderCapacity = FirmsStaticData.CustomerOrderCapacity([0]);
    setCustomerOrderCapacity(customerOrderCapacity)
  }, [data])

  const getCustomerOrderCapacity = (record) => {
    const capacityId = record.accounts.get(resource);
    const capacityName = getOptionName(customerOrderCapacity, capacityId, "");
    return capacityName;
  }

  const gridCtxMenu = useMemo(() => GridActionMenu, []);

  const columns = [{
    Header: "NAME",
    accessor: "displayName",
  }];

  if (showCustomerOrderCapacity) {
    columns.push({
      Header: "CUSTOMER ORDER CAPACITY",
      Cell: (d) => getCustomerOrderCapacity(d.original),
    });
  }

  columns.push({
    Header: "ACTIONS",
    maxWidth: 280,
    sortable: false,
    Cell: (row) => (<>
      <GridActions>
        <GridActionView contextMenu={gridCtxMenu}
          onClick={(e, actionContext) => { onView(row.original.name, actionContext); }} />
        <GridActionEdit contextMenu={gridCtxMenu}
          onClick={(e, actionContext) => { onEdit(row.original.name, actionContext); }} />
        <GridActionDelete title='Remove' onClick={(e, actionContext) => { onDelete(row.original.name, actionContext) }} />
      </GridActions>
    </>
    ),
  });

  return (
    <div>
      <DataGrid data={data} columns={columns} filterable={false} />
    </div>
  );
};

ParticipantsInGroupDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ParticipantsInGroupDataGrid;
