import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import DataGrid from "../core/data-grid/DataGrid";
import { isArrayWithValues, isNullOrUndefined } from "../../modules/util";
import { fetchMetadata } from "../../actions/instruments";
import { connect } from "react-redux";
import DateGroup from "../core/form/DateGroup";
import FieldGroup from "../core/form/FieldGroup";
import "./AccountLedger.css"
import { FetchPositionLedgerStart, FetchPositionLedgerSucceed, FetchPositionLedgerFailed, fetchPositionLedger, downloadPositionLedger } from "../../actions/accounts";
import ProtobufParser from "../../modules/protobufParser";
import PaginationWithPageSize, { withPageToken } from "../../components/core/data-grid/PaginationWithPageSize";
import ButtonSecondary from "../core/form/ButtonSecondary";
import querystring from 'query-string';
import _ from "lodash"
import GridHeaderLabel from "../core/form/GridHeaderLabel";
import * as toolTips from "../../constants/positionLedgerToolTip"

const { GetPositionLedgerRequest } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");


const STORAGE_PAGE_SIZE_KEY = "positionLedgerGridPageSize";
const DEFAULT_PAGE_SIZE = 10;

function PositionLedger(props) {
  const queryParams = querystring.parseUrl(window.location.href);
  const hasParams = !_.isEmpty(queryParams.query);
  let refId = ""
  if (hasParams) {
    refId = queryParams.query.referenceId
  }
  const [positionLedgerData, setPositionLedgerData] = useState([]);
  const [transactionTimeFrom, setTransactionTimeFrom] = useState("");
  const [transactionTimeTo, setTransactionTimeTo] = useState("");
  const [symbol, setSymbol] = useState("");
  const [tradeId, setTradeId] = useState("");
  const [referenceId, setReferenceId] = useState(refId);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});

  const { account } = props;

  const defaultFromDate = new Date();
  defaultFromDate.setHours(0, 0, 0);

  const defaultToDate = new Date();
  defaultToDate.setHours(23, 59, 59);

  useEffect(() => {
    props.fetchMetadata();

    resetFilterValues();

    setErrors({});

    loadPositionLedger("", transactionTimeFrom, transactionTimeTo, tradeId, referenceId, description, (cbData) => {
      setPositionLedgerData(cbData);
    });
  }, []);

  useEffect(() => {
    loadPositionLedger("", transactionTimeFrom, transactionTimeTo, tradeId, referenceId, description, (cbData) => {
      setPositionLedgerData(cbData);
    });
  }, [props.account])

  useEffect(() => {
    setPositionLedgerData([...props.positionLedger.ledgerList])
  }, [props.positionLedger])

  useEffect(() => {
    // Trigger only if all is set to the initial state.
    if (transactionTimeFrom.toString() === defaultFromDate.toString()
      && transactionTimeTo.toString() === defaultToDate.toString()
      && symbol === ""
      && tradeId === ""
      && referenceId === refId
      && description === "") {
      filterDate();
    }
  }, [transactionTimeFrom, transactionTimeTo, symbol, tradeId, referenceId, description,]);

  const filterDate = () => {
    const error = validateFilter();
    if (!!error.transactionTimeFrom || !!error.transactionTimeTo) {
      return;
    }

    loadPositionLedger("", transactionTimeFrom, transactionTimeTo, tradeId, referenceId, description, (cbData) => {
      setPositionLedgerData(cbData);
    });
  }

  const setPageSizeInStorage = (pageSize) => {
    window.localStorage.setItem(STORAGE_PAGE_SIZE_KEY, pageSize)
  }

  const getPageSizeFromStorage = () => {
    const pageSize = window.localStorage.getItem(STORAGE_PAGE_SIZE_KEY)

    if (pageSize)
      return Number(pageSize);

    return Number(DEFAULT_PAGE_SIZE);
  }

  const onPageChange = (pageToken) => {
    loadPositionLedger(pageToken, transactionTimeFrom, transactionTimeTo, tradeId, referenceId, description, () => { });
  }

  const onPageSizeChange = (pageSize) => {
    setPageSizeInStorage(pageSize);
    const pageToken = "";

    loadPositionLedger(pageToken, transactionTimeFrom, transactionTimeTo, tradeId, referenceId, description, () => { });
  }

  const getPaginationComponent = () => {
    const pageToken = props.pageToken;
    const tokens = props.pageTokens;
    const pageSize = getPageSizeFromStorage();
    return withPageToken(PaginationWithPageSize, { pageToken, tokens }, pageSize);
  }

  const loadPositionLedger = (pageToken, timeFrom, timeTo, tradeId, referenceId, description, cb) => {
    if (isNullOrUndefined(account) || isNullOrUndefined(account.name) || _.isEmpty(account.name)) return;
    let req = prepareGetPositionLedgerReq(pageToken, timeFrom, timeTo, tradeId, referenceId, description);
    props.fetchPositionLedger(req, cb)
  }

  const downloadPositionLedger = () => {
    let req = prepareGetPositionLedgerReq("", transactionTimeFrom, transactionTimeTo, tradeId, referenceId, description);
    req.setPageSize(0)
    props.downloadPositionLedger(req)
  }

  const prepareGetPositionLedgerReq = (pageToken, timeFrom, timeTo, tradeId, referenceId, description) => {
    const timeFromProto = timeFrom ? ProtobufParser.toTimestamp(timeFrom) : null;
    const timeToProto = timeTo ? ProtobufParser.toTimestamp(timeTo) : null;

    let req = new GetPositionLedgerRequest();
    req.setName(account.name)
    req.setPageSize(getPageSizeFromStorage())
    req.setStartTime(timeFromProto)
    req.setEndTime(timeToProto)

    if (!!symbol) req.setSymbol(symbol);
    if (!!pageToken) req.setPageToken(pageToken)

    if (!!tradeId) req.setTradeId(tradeId)
    if (!!referenceId) req.setReferenceId(referenceId)
    if (!!description) req.setDescription("/"+description+"/i");

    return req;
  }

  const validateFilter = () => {
    const errors = { transactionTimeFrom: "", transactionTimeTo: "" };
    if (transactionTimeFrom > transactionTimeTo) {
      errors.transactionTimeTo = "Transaction Time To should be greater than Transaction Time From";
    }

    setErrors(errors);
    return errors;
  };

  const resetFilterValues = () => {
    if (!hasParams) {
      setTransactionTimeFrom(defaultFromDate);
      setTransactionTimeTo(defaultToDate);
    }

    setSymbol("");
    setTradeId("");
    setReferenceId(refId);
    setDescription("");
  }

  const columns = [
    {
      Header: "TRANSACTION TIME",
      accessor: "updateTime",
      width: 175,
      sortable: false
    },
    {
      id: "colDesc",
      Header: "DESCRIPTION",
      accessor: (d) => (<span title={d.description}>{d.description}</span>),
      width: 500,
      sortable: false
    },
    {
      id: "symbol",
      Header: "SYMBOL",
      accessor: "afterPosition.symbol",
      sortable: false
    },
    {
      id: "subType",
      Header: "SUB TYPE",
      accessor: "afterPosition.symbolSubType",
      width: 100,
      sortable: false
    },
    {
      id: "entryType",
      Header: "ENTRY TYPE",
      accessor: "entryTypeName",
      sortable: false
    },
    {
      Header: "POSITION",
      columns: [{
        Header: "BEFORE",
        accessor: "beforePosition.netPosition",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterPosition.netPosition",
        width: 100,
        sortable: false
      }]
    },
    {
      id: "tradeId",
      Header: "TRADE ID",
      accessor: "tradeId",
      width: 200,
      sortable: false
    },
    {
      id: "referenceId",
      Header: "REFERENCE ID",
      accessor: "referenceId",
      width: 200,
      sortable: false
    },
    {
      id: "allocationGroupId",
      Header: <GridHeaderLabel id={"lblAllocationGroupId"} label={"ALLOCATION GROUP ID"} tooltip={toolTips.ALLOCATION_GROUP_ID} />,
      accessor: "allocationGroupId",
      width: 200,
      sortable: false
    },
    {
      id: "transferReferenceTradeIdsList",
      Header: <GridHeaderLabel id={"lblTransferReferenceTradeIdsList"} label={"TRANSFER REFERENCE TRADE IDS"} tooltip={toolTips.REFERENCE_TRADE_IDS} />,
      accessor: (d) => isArrayWithValues(d.transferReferenceTradeIdsList) ? d.transferReferenceTradeIdsList.join(", ") : "",
      width: 500,
      sortable: false,
    }
  ]

  return (
    <>
      <Form.Row>
        <Col lg={2} xs={2} md={2}>
          <DateGroup id="transactionTimeFrom" name="transactionTimeFrom" label="Transaction Time From"
            value={transactionTimeFrom ? new Date(transactionTimeFrom) : null}
            onChange={(e) => setTransactionTimeFrom(e)}
            enableTime={true}
            errors={errors}
          />
        </Col>
        <Col lg={2} xs={2} md={2}>
          <DateGroup id="transactionTimeTo" name="transactionTimeTo" label="Transaction Time To"
            value={transactionTimeTo ? new Date(transactionTimeTo) : null}
            onChange={(e) => setTransactionTimeTo(e)}
            enableTime={true}
            errors={errors}
          />
        </Col>
        <Col lg={2} xs={2} md={2}>
          <FieldGroup id="symbol" name="symbol" type="textarea" label="Symbol"
            value={symbol ? symbol : ""}
            onChange={(e) => { setSymbol(e.target.value) }}
            onBlur={(e) => { setSymbol(e.target.value) }}
          />
        </Col>
        <Col lg={2} xs={2} md={2}>
          <FieldGroup id="tradeId" name="tradeId" type="textarea" label="Trade ID"
            value={tradeId ? tradeId : ""}
            onChange={(e) => { setTradeId(e.target.value) }}
            onBlur={(e) => { setTradeId(e.target.value) }}
          />
        </Col>
        <Col lg={2} xs={2} md={2}>
          <FieldGroup id="referenceId" name="referenceId" type="textarea" label="Reference ID"
            value={referenceId ? referenceId : ""}
            onChange={(e) => { setReferenceId(e.target.value) }}
            onBlur={(e) => { setReferenceId(e.target.value) }}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col lg={6} xs={6} md={6}>
          <FieldGroup id="description" name="description" type="textarea" label="Description"
                      value={description ? description : ""}
                      onChange={(e) => { setDescription(e.target.value) }}
                      onBlur={(e) => { setDescription(e.target.value) }}
          />
        </Col>
        <Col lg={3} xs={3} md={3} style={{ paddingTop: "33px" }}>
          <div>
            <button className="Filter-Button" onClick={filterDate}>Filter</button>
            <ButtonSecondary style={{ width: "100px" }}
              text="Reset"
              onClick={() => {
                resetFilterValues();
              }} />

            <ButtonSecondary text="Export Data" type="button" style={{ float: "right" }} onClick={() => { downloadPositionLedger() }} />
          </div>
        </Col>
      </Form.Row>
      <Row>
        <Col>
          <div>
            <DataGrid
              data={positionLedgerData}
              columns={columns}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              PaginationComponent={getPaginationComponent()}
              manual={true}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps(state) {
  return {
    account: state.accounts.account,
    metadata: state.instruments.metadata,
    positionLedger: state.accounts.positionLedger,
    pageToken: state.accounts.positionLedger.pageToken,
    nextPageToken: state.accounts.positionLedger.nextPageToken,
    pageTokens: state.accounts.positionLedger.pageTokens,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchMetadata: () => {
    dispatch(fetchMetadata());
  },
  fetchPositionLedgerStart: () => {
    dispatch(FetchPositionLedgerStart());
  },
  fetchPositionLedgerSucceed: (positionLedger) => {
    dispatch(FetchPositionLedgerSucceed(positionLedger));
  },
  fetchPositionLedgerFailed: (err) => {
    dispatch(FetchPositionLedgerFailed(err));
  },
  fetchPositionLedger: (retPositionLedgerRequest, cb) => {
    dispatch(fetchPositionLedger(retPositionLedgerRequest, cb))
  },
  downloadPositionLedger: (retPositionLedgerRequest) => {
    dispatch(downloadPositionLedger(retPositionLedgerRequest))
  },
  
});

export default connect(mapStateToProps, mapDispatchToProps)(PositionLedger);
