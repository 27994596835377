import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import app from "./app";
import auth from "./auth";
import products from "./products";
import productsList from "./productsList";
import instruments from "./instruments";
import participants from "./participants";
import participantGroups from "./participantGroups";
import orders from "./orders";
import books from "./books";
import users from "./users";
import timeSales from "./timeSales";
import trades from "./trades";
import executions from "./executions";
import instrumentsScales from "./instrumentsScales";
import firms from "./firms";
import accounts from "./accounts";
import requestForQuotes from "./requestForQuotes";
import quotes from "./quotes";
import commissions from "./commissions";
import alerts from "./alerts";
import jobs from "./jobs";
import adminRequests from "./adminRequests";
import { accountsConfig, instrumentsConfig, firmConfig } from "../store";
import { persistReducer } from "redux-persist";
import instrumentStats from "./instrumentStats";
import blockTrades from "./blockTrades";


const rootReducer = (history) =>
  combineReducers({
    app,
    auth,
    products,
    productsList,
    instruments: persistReducer(instrumentsConfig, instruments),
    instrumentsScales,
    participants,
    participantGroups,
    orders,
    books,
    users,
    timeSales,
    trades,
    router: connectRouter(history),
    executions,
    firms: persistReducer(firmConfig, firms),
    accounts: persistReducer(accountsConfig, accounts),
    requestForQuotes,
    quotes,
    commissions,
    alerts,
    jobs,
    adminRequests,
    instrumentStats,
    blockTrades,
  });

export default rootReducer;
