import React from "react";
import moment from "moment";
import _ from "lodash";
import { FormModes } from "../constants/enumerations";
import FormatString from "../modules/formatString";
import { TitleBreadcrumb } from "../components/core/title-breadcrumb/title-breadcrumb";
import {Env} from "../constants/environment";

export const isValidHttpUrl = (urlStr) => {
  let url;

  try {
    url = new URL(urlStr);
  } catch (_) {
    return false;
  }

  return url.protocol.startsWith("http");
}

export const apiUrl = () => {
  const envUrl = Env.getEnv("REACT_APP_API_URL");
  return isValidHttpUrl(envUrl) ? envUrl : window.location.origin.replace(/\/$/, '') + envUrl;
}

export const isOnline = () => {
  return navigator.onLine;
};

export const boolDisplayChar = (val) => {
  return !!val ? "\u2705" : "\u274c";
};

export const showError = (error) => {
  alert(`WHOOPS! ${error}`);
};

const enumCommonPrefixIndex = new Map();

export const getEnumName = (enumeration, value) => {
  if (!enumCommonPrefixIndex.has(enumeration)) {
    const keys = _.clone(Object.keys(enumeration)).sort();
    const a1 = keys[0],
      a2 = keys[keys.length - 1],
      L = a1.length;
    let i = 0;
    while (i < L && a1.charAt(i) === a2.charAt(i)) i++;
    enumCommonPrefixIndex.set(enumeration, i);
  }
  const enumString = Object.keys(enumeration).find(
    (k) => enumeration[k] === value
  );
  if (!enumString) {
    return value.toString();
  }
  const idxStart = enumCommonPrefixIndex.get(enumeration);
  return enumString.substring(idxStart).replace(/_/g, " ");
};

/**
 *
 * @param {[{id:int, name:string}]} options array.
 * @param {int} id to get value of
 * @param {string=} defaultValue value to return if found nothing
 */

export const getOptionName = (options, id, defaultValue = "") => {
  if (!isArrayWithValues(options)) return defaultValue;

  const option = options.find(option => option.id === id);
  if (!isNullOrUndefined(option)) {
    return option.name;
  }

  return defaultValue;
}

export const convertEnumToDropdownList = (enumeration, excludeIds, stringFormatOptions) => {
  const idSet = new Set(valueOrDefault(excludeIds, []));
  const enumList = [];

  Object.keys(enumeration || {}).forEach((key) => {
    const enumId = enumeration[key];
    if (!idSet.has(enumId)) {
      var name = getEnumName(enumeration, enumeration[key]);

      if (stringFormatOptions) {
        switch (stringFormatOptions.stringFormat) {
          case FormatString.stringFormats.noFormat:
            break
          case FormatString.stringFormats.titleCase:
            name = FormatString.toTitleCase(name.toLowerCase());
            break
          case FormatString.stringFormats.capitalizeCase:
            name = FormatString.toCapitalize(name.toLowerCase());
            break
          case FormatString.stringFormats.capitalizeAllCase:
            name = FormatString.capitalizeAll(name);
            break
          default:
            name = capitalizeWord(name);
        }

        if (stringFormatOptions.hyphenate) {
          name = FormatString.hyphenate(name);
        }
      } else {
        name = capitalizeWord(name.toLowerCase());
      }

      enumList.push({ id: enumId, name: name });
    }
  });

  return enumList;
};

export const convertToDropdownCollection = (
  data,
  idFieldName,
  descriptionFieldName
) => {
  const collection = data.map((item) => {
    return { id: item[idFieldName], name: item[descriptionFieldName] };
  });
  return collection;
};

export const capitalizeWord = (word) => {
  word = word.toLowerCase();
  return word.replace(/^\w/, (c) => c.toUpperCase());
};

export const convertDateToString = (date, format) => {
  return moment(date).format(format);
};

export const parsePrice = (number, ...scales) => {
  let scale = 1;
  scales.forEach(elem => {
    if (elem !== 0 && !isNullOrUndefined(elem) && !isNaN(elem)) {
      scale *= elem;
    }
  });
  let decimalPlaces = 0;
  let temp = scale;
  for (decimalPlaces = 0; temp % 10 === 0; temp /= 10) {
    decimalPlaces++;
  }
  return (parseFloat(number) / parseFloat(scale)).toFixed(decimalPlaces);
};

export const parseFormValue = (value) => {
  return !value ? "" : value;
};

export const validScaledPrice = (value, scale) => {
  const decimals = countDecimals(value);
  return Math.pow(10, decimals) <= scale;
};

export const convertScaledPriceToInt = (value, scale) => {
  let scaled = parseFloat(value) * parseFloat(scale > 1 ? scale : 1);
  return Math.round(scaled);
};

export function convertExponentialToDecimal(exponentialNumber) {
  if (isNullOrUndefined(exponentialNumber)) {
    return;
  }

  let str = exponentialNumber.toString();
  if (str.indexOf("e") !== -1) {
    let exponent = parseInt(str.split("-")[1], 10);
    let result = Number(exponentialNumber);
    return result.toFixed(exponent);
  } else {
    return exponentialNumber;
  }
}

export const countDecimals = (value) => {
  if (isNullOrUndefined(value)) {
    return 0;
  }

  value = convertExponentialToDecimal(value);

  // eslint-disable-next-line eqeqeq
  if (Math.floor(value) == value) return 0;
  return value.toString().split(".")[1].length || 0;
};

export const getHelmet = ({ id, firmServiceId }) => {
  let currentPage = window.location.pathname.split("/")[1];
  let pageTitle = FormatString.toTitleCase(currentPage).replace('-', ' ');

  if (!pageTitle) return;
  pageTitle = pageTitle[0].toUpperCase() + pageTitle.slice(1);
  if (id) {
    if (firmServiceId)
      return (
        <TitleBreadcrumb titles={[
          { title: pageTitle, link: `${window.location.origin}/${currentPage}` },
          { title: id, link: `${window.location.origin}/${currentPage}/${id}/services` },
          { title: firmServiceId }
        ]} />
      );
    else
      return (
        <TitleBreadcrumb titles={[
          { title: pageTitle, link: `${window.location.origin}/${currentPage}` },
          { title: id }
        ]} />
      );
  } else
    return (
      <TitleBreadcrumb titles={[
        { title: pageTitle, link: `${window.location.origin}/${currentPage}` }
      ]} />
    );
};

export const getFormMode = (pathname, setCurrentTab) => {
  if (pathname.indexOf("/new") > 0) {
    return FormModes.creation;
  } else if (pathname.indexOf("/edit") > 0) {
    setCurrentTab("profile");
    return FormModes.edition;
  } else if (pathname.endsWith("/accounts") > 0) {
    setCurrentTab("accounts");
    return FormModes.accounts;
  } else if (pathname.endsWith("/users") > 0) {
    setCurrentTab("users");
    return FormModes.users;
  } else if (pathname.indexOf("/positions") > 0) {
    setCurrentTab("positions");
    return FormModes.position;
  } else if (pathname.indexOf("/balances") > 0) {
    setCurrentTab("balances");
    return FormModes.balance;
  } else if (pathname.indexOf("/ledger/balance-ledger") > 0) {
    setCurrentTab("ledger/balance-ledger");
    return FormModes.ledger;
  } else if (pathname.indexOf("/ledger/position-ledger") > 0) {
    setCurrentTab("ledger/position-ledger");
    return FormModes.positionLedger;
  } else if (pathname.indexOf("/whitelist") > 0) {
    setCurrentTab("whitelist");
    return FormModes.whitelist;
  } else if (pathname.indexOf("/commissions") > 0) {
    setCurrentTab("commissions");
    return FormModes.commissions;
  } else if (pathname.indexOf("/association") > 0) {
    setCurrentTab("association");
    return FormModes.association;
  } else if (pathname.indexOf("/bankDetails") > 0) {
    setCurrentTab("bankDetails");
    return FormModes.bankDetails;
  } else if (pathname.indexOf("/orders") > 0) {
    setCurrentTab("orders");
    return FormModes.orders;
  } else if (pathname.indexOf("/executions") > 0) {
    setCurrentTab("executions");
    return FormModes.executions;
  } else {
    setCurrentTab("profile");
    return FormModes.view;
  }
};

export const isNullOrUndefined = (value) => {
  return value === null || value === undefined;
};

export const isArrayWithValues = (array) => {
  if (isNullOrUndefined(array)) return false;
  if (!Array.isArray(array)) return false;
  if (array.length === 0) return false;

  return true;
};

export const valueOrDefault = (value, defaultValue) => {
  if (isNullOrUndefined(value)) {
    return defaultValue;
  } else {
    return value;
  }
}

export const getValue = (obj, prop, defaultValue) => {
  if (!!obj && obj.hasOwnProperty(prop)) {
    const val = obj[prop];
    return valueOrDefault(val, defaultValue);
  }

  return defaultValue;
}

/**
 *
 * @param {any} val to be converted to string
 * @param {bool} trimmed set to true if returned string to be trimmed.
 * @returns {string} converted.
 */
export const toString = (val, trimmed = true) => {
  let str = String(val);

  if (trimmed) {
    return str.trim();
  }

  return str;
}

export const isEmptyString = (val, useTrim = false) => {
  return toString(val, !!useTrim).length === 0;
}

/**
 *
 * @param {number} numVal
 * @returns {bool}
 */
export const isNumber = (numVal) => {
  if (isNullOrUndefined(numVal))
    return false;

  if (isEmptyString(numVal))
    return false;

  return Number.isFinite(Number(numVal));
}

/**
 *
 * @param {number} numVal
 * @param {number} defaultValue will be returned if numVal is not a number.
 * @returns {number}
 */
export const numberOrDefault = (numVal, defaultValue = 0) => {
  if (isNumber(numVal)) {
    return Number(numVal);
  }

  return defaultValue;
}

/**
 *
 * @returns {boolean}
 */
export const inTest = () => {
  return !isNullOrUndefined(process.env.JEST_WORKER_ID);
}

// Get the value of a nested property using a string key

/**
 * 
 * @param {object} obj
 * @param {string} key - dot '.' denoted nested key.
 * @returns 
 */

export const getObjectValue = (obj, key, defaultReturn = '') => {
  
  if (obj === undefined || obj === null) return defaultReturn;

  const keys = key.split('.'); 
  let value = obj;

  for (const prop of keys) {
    value = value[prop]; 
    if (value === undefined || value === null) {
      return value; 
    }
  }

  return value;
}

// filterZeroValue removes the first enum option '0' typically mapped to UNDEFINED
export const filterZeroValue = (options) => {
  let returned = [];
  for (const opt of options) {
    if (opt.id !== 0) {
      returned.push(opt);
    }
  }
  return returned;
}
