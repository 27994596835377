import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import RelativePriceLimitReadOnly from "../../components/relative-price-limit/RelativePriceLimitReadOnly";
import OrderLimitReadOnly from "../../components/order-limit/OrderLimitReadOnly";
import PriceLimitReadOnly from "../../components/price-limit/PriceLimitReadOnly";
import ProductFormItem from "../../entities/dto/ProductFormItem";
import { Container, Row, Col } from "react-bootstrap";
import TradingHours from "../core/TradingHours";
import ButtonMain from "../core/form/ButtonMain";
import ButtonBorderless from "../core/form/ButtonBorderless";
import MultiItem from "../core/form/MultiItem";
import {
  INTEREST_RATE_SWAP,
  DEFAULT,
  FUTURE,
  OPTION,
  FORWARD_STARTING_INTEREST_RATE_SWAP,
  BASIS_SWAP,
  FORWARD_RATE_AGREEMENT_SWAP,
  EVENT,
  OVER_NIGHT_INDEX_SWAP,
  SINGLE_PERIOD_SWAP,
  NON_DELIVERABLE_FORWARDS,
  FOREX,
  ZERO_COUPON_INFLATION_SWAP
} from "../../constants/strings";
import Collapse from "react-bootstrap/Collapse";
import InstrumentStaticData from "../../modules/instrumentStaticData";
import {
  capitalizeWord,
  countDecimals,
  getEnumName,
  isNullOrUndefined,
  parsePrice,
} from "../../modules/util";
import DateTimeParser from "../../modules/dateTimeParser";
import { PutOrCall } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import TotalNotionalLimitReadOnly from "../total-notional-limit/TotalNotionalLimitReadOnly";
import * as toolTips from '../../constants/productToolTip'
import CheckboxGroup from "../core/form/CheckboxGroup";
import TradeDayRollSchedule from "../core/TradeDayRollSchedule/TradeDayRollSchedule";
import { FormModes } from "../../constants/enumerations";

function mapStateToProps(state) {
  return {
    product: state.products.product,
    holidays: state.instruments.holidayCalendarObjects,
  };
}

function getOptionText(options, value) {
  if (!isNullOrUndefined(value)) {
    let found = options.find((item) => {
      return item.id === value;
    });

    if (found) {
      return found.name;
    }
  }

  return "";
}

function getOpposingSideName(side) {
  return side ? 'Opposing' : 'As Defined';
}

class ProductFormReadOnly extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();

    this.state = {
      tradingInformation: false,
      swapInformation: false,
      fixedLegInformation: false,
      floatingLegInformation: false,
      additionalDetails: false,
      calculationMethods: [],
      settlementPriceCalculationInformation: false
    };
  }

  componentDidMount() {
    this.setState({ calculationMethods: InstrumentStaticData.EventCalculationMethod([0]) })
  }

  render() {
    const { metadata, holidays } = this.props;

    const product = { ...this.props.product };

    const showIRS = product.type === INTEREST_RATE_SWAP;
    const showDerivativeInfo =
      product.type === FUTURE || product.type === OPTION;
    const showFsIRS = product.type === FORWARD_STARTING_INTEREST_RATE_SWAP;
    const showBasisSwap = product.type === BASIS_SWAP;
    const showFRAS = product.type === FORWARD_RATE_AGREEMENT_SWAP;
    const showEvent = product.type === EVENT;
    const showOIS = product.type === OVER_NIGHT_INDEX_SWAP;
    const showSPS = product.type === SINGLE_PERIOD_SWAP;
    const showNDF = product.type === NON_DELIVERABLE_FORWARDS;
    const showForexInfo = product.type === FOREX;
    const showZCIS = product.type === ZERO_COUPON_INFLATION_SWAP;

    var priceScale = 1;
    if (product.minimumPriceIncrement) {
      const scale = Math.pow(10, countDecimals(product.minimumPriceIncrement));
      if (scale !== 0) priceScale = scale;
    }

    var qtyScale = 1;
    if (product.minimumTradeQuantity) {
      const scale = Math.pow(10, countDecimals(product.minimumTradeQuantity));
      if (scale !== 0) qtyScale = scale;
    }

    if (product.highPriceLimit) {
      product.highPriceLimit = parsePrice(product.highPriceLimit, priceScale);
    }
    if (product.lowPriceLimit) {
      product.lowPriceLimit = parsePrice(product.lowPriceLimit, priceScale);
    }
    if (product.highOrderLimit) {
      product.highOrderLimit = parsePrice(product.highOrderLimit, qtyScale);
    }
    if (product.lowOrderLimit) {
      product.lowOrderLimit = parsePrice(product.lowOrderLimit, qtyScale);
    }
    if (product.highTotalNotionalLimit) {
      product.highTotalNotionalLimit = parsePrice(product.highTotalNotionalLimit, priceScale * qtyScale);
    }
    if (product.lowTotalNotionalLimit) {
      product.lowTotalNotionalLimit = parsePrice(product.lowTotalNotionalLimit, priceScale * qtyScale);
    }

    return (
      <form id="product-form">
        <h1>{showIRS ? "View Interest Rate Swap Product" : "View Product"}</h1>
        <Container>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Product Type"
                value={product.type || DEFAULT}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                id="productname"
                label="Product Name (ID)"
                value={product.name}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                id="description"
                label="Description"
                value={product.description}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                id="cfiCode"
                label="CFI Code"
                value={product.cfiCode}
                tooltip="Six-letter Classification of Financial Instruments code. Required only if EP3 will be creating the Part 16.02 Trade Capture Report for the U.S. CFTC"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <MultiItem
                id="categoryLevels"
                name="categoryLevels"
                label="Category Levels"
                labelWrapperClass="label-wrapper"
                data={product.categoryLevels}
                plaintext={true}
                toolTip={<span>Defines not only the lowest-level child category that a product belongs to, but also any parent <br/> category(ies) that exist above the child category. For example, a 'Cerium' product might define <br/> 'Commodities &gt; Metals &gt; Rare Earth' as its categories to describe the product categorization at multiple levels of detail</span>}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                  id="externalSymbol"
                  label="External Symbol"
                  value={product.externalSymbol}
              />
            </Col>

          </Row>

          <div
            onClick={() =>
              this.setState({
                tradingInformation: !this.state.tradingInformation,
              })
            }
          >
            <Row className="accordian-style">
              <Col lg={11} xs={11} md={11}>
                <h6>
                  <strong>Trading Information</strong>
                </h6>
              </Col>
              <Col>
                <i
                  className={`fa fa-fw ${this.state.tradingInformation ? "fa-minus" : "fa-plus"
                    } custom-plus`}
                />
              </Col>
            </Row>
          </div>

          <Collapse in={this.state.tradingInformation}>
            <div className="collapse-content">
              <Row>
                <Col lg={12} xs={12} md={12}>
                  <TradingHours
                    scheduleList={
                      product.tradingScheduleList
                        ? product.tradingScheduleList
                        : []
                    }
                    enableEdit={false}
                    metadata={metadata}
                    holidayCalendars={product.holidayCalendars}
                    holidays={holidays}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={12} xs={12} md={12}>
                  <TradeDayRollSchedule
                    values={product}
                    mode={FormModes.view}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroupReadOnly
                    label="Currency"
                    value={product.basecurrency}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroupReadOnly
                    label="ClearingHouse"
                    value={
                      product.firm
                        ? product.firm.displayName
                        : product.clearingHouse
                    }
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroupReadOnly
                    label="Multiplier"
                    value={product.multiplier === 0 ? "" : product.multiplier}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroupReadOnly
                    label="Trade Settlement Period"
                    value={product.tradeSettlementPeriod}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroupReadOnly
                      id="instrumentProductType"
                      label="Underlying Type"
                      value={getOptionText(
                          InstrumentStaticData.InstrumentProductTypes(),
                          product.instrumentProductType
                      )}
                      tooltip="Optionally indicates the type of underlying product the security is associated with. Maps to FIX Tag 460."
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroupReadOnly
                    label="Minimum Price Increment"
                    value={
                      product.minimumPriceIncrement === 0
                        ? ""
                        : product.minimumPriceIncrement
                    }
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroupReadOnly
                    label="Minimum Trade Quantity"
                    value={
                      product.minimumTradeQuantity === 0
                        ? ""
                        : product.minimumTradeQuantity
                    }
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroupReadOnly
                    label="Minimum Unaffiliated Firms"
                    value={product.minimumUnaffiliatedFirms}
                  />
                </Col>
              </Row>
            </div>
          </Collapse>

          <div
            onClick={() =>
              this.setState({
                crossOrderInformation: !this.state.crossOrderInformation,
              })
            }
          >
            <Row className="accordian-style">
              <Col lg={11} xs={11} md={11}>
                <h6>
                  <strong>Cross Order Information</strong>
                </h6>
              </Col>
              <Col>
                <i
                  className={`fa fa-fw ${this.state.crossOrderInformation ? "fa-minus" : "fa-plus"
                    } custom-plus`}
                />
              </Col>
            </Row>
          </div>

          <Collapse in={this.state.crossOrderInformation}>
            <div className="collapse-content">
              <Row>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroupReadOnly
                    label="Cross Order Resting Duration"
                    tooltip={toolTips.CROSS_ORDER_RESTING_DURATION}
                    value={!!product.crossOrderRestingDuration ? `${product.crossOrderRestingDuration.nanos} milliseconds` : '' }
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <hr />
                  <h6><strong>Instrument-Level Block Trade Thresholds</strong></h6>
                </Col>
              </Row>

              <Row>
                <Col lg={3} xs={3} md={3}>
                  <div style={{ height: "100%" }}>
                    <div style={{ top: "30%", position: "relative" }}>
                      <CheckboxGroup
                        label="Enabled"
                        checked={product.setBlockTradeThreshold}
                        disabled={true}
                        tooltip={toolTips.BLOCK_TRADE_CAN_EDIT}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={4} xs={4} md={4}>
                  <FieldGroupReadOnly
                    id="blockTradeThreshold.minimumQuantity"
                    name="blockTradeThreshold.minimumQuantity"
                    label="Block Trade Minimum Quantity"
                    tooltip={toolTips.BLOCK_TRADE_MINIMUM_QUANTITY}
                    value={!!product.blockTradeThreshold ? product.blockTradeThreshold.minimumQuantity : ""}
                  />
                </Col>
                <Col lg={4} xs={4} md={4}>
                  <FieldGroupReadOnly
                    id="blockTradeThreshold.minimumNotional"
                    name="blockTradeThreshold.minimumNotional"
                    label="Block Trade Minimum Notional"
                    tooltip={toolTips.BLOCK_TRADE_MINIMUM_NOTIONAL}
                    value={!!product.blockTradeThreshold ? product.blockTradeThreshold.minimumNotional : ""}
                  />
                </Col>
              </Row>
            </div>
          </Collapse>

          <div
            onClick={() =>
              this.setState({
                settlementPriceCalculationInformation: !this.state.settlementPriceCalculationInformation,
              })
            }
          >
            <Row className="accordian-style">
              <Col lg={11} xs={11} md={11}>
                <h6>
                  <strong>Settlement Price Calculation Information</strong>
                </h6>
              </Col>
              <Col>
                <i
                  className={`fa fa-fw ${this.state.settlementPriceCalculationInformation ? "fa-minus" : "fa-plus"
                    } custom-plus`}
                />
              </Col>
            </Row>
          </div>

          <Collapse in={this.state.settlementPriceCalculationInformation}>
            <div className="collapse-content">

              <Row>
                <Col lg={5} xs={5} md={5}>
                  <FieldGroupReadOnly
                    id="settlementPeriodStartOffsetFromCloseDuration"
                    name="settlementPeriodStartOffsetFromCloseDuration"
                    label="Settlement Start Period Offset from Market Close"
                    value={product.settlementPeriodStartOffsetFromCloseDuration ? `${product.settlementPeriodStartOffsetFromCloseDuration.nanos} milliseconds` : ''}
                    tooltip={toolTips.SETTLEMENT_PERIOD_START_OFFSET_FROM_CLOSE_DURATION}
                  />
                </Col>

                <Col lg={5} xs={5} md={5}>
                  <FieldGroupReadOnly
                    id="settlementPeriodEndOffsetFromCloseDuration"
                    name="settlementPeriodEndOffsetFromCloseDuration"
                    label="Settlement End Period Offset from Market Close"
                    value={product.settlementPeriodEndOffsetFromCloseDuration ? `${product.settlementPeriodEndOffsetFromCloseDuration.nanos} milliseconds` : ''}
                    tooltip={toolTips.SETTLEMENT_PERIOD_END_OFFSET_FROM_CLOSE_DURATION}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={2} xs={2} md={2}>
                  <FieldGroupReadOnly
                    id="settlementPriceLogic"
                    name="settlementPriceLogic"
                    label="Settlement Price Logic"
                    value={getOptionText(
                      InstrumentStaticData.SettlementPriceLogics(),
                      product.settlementPriceLogic
                    )}
                  />
                </Col>
                <Col lg={4} xs={4} md={4}>
                  <FieldGroupReadOnly
                    id="settlementPriceCalculationEndingMethod"
                    name="settlementPriceCalculationEndingMethod"
                    label="Settlement Price Calculation Ending Method"
                    value={getOptionText(
                      InstrumentStaticData.SettlementPriceCalculationEndingMethods(),
                      product.settlementPriceCalculationEndingMethod
                    )}
                    tooltip={toolTips.SETTLEMENT_PRICE_CALCULATION_ENDING_METHOD}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={5} xs={5} md={5}>
                  <FieldGroupReadOnly
                    id="minimumTradeQtyForVwapSettlementCalculation"
                    name="minimumTradeQtyForVwapSettlementCalculation"
                    label="Minimum Trade Quantity for VWAP"
                    value={product.minimumTradeQtyForVwapSettlementCalculation}
                    tooltip={toolTips.MINIMUM_TRADE_QTY_FOR_VWAP_SETTLEMENT_CALCULATION}
                  />
                </Col>
                <Col lg={5} xs={5} md={5}>
                  <FieldGroupReadOnly
                    id="bufferFromEndOfSettlementPeriodDuration"
                    name="bufferFromEndOfSettlementPeriodDuration"
                    label="Minimum Order Time in Market"
                    value={product.bufferFromEndOfSettlementPeriodDuration ? `${product.bufferFromEndOfSettlementPeriodDuration.nanos} milliseconds` : ''}
                    tooltip={toolTips.BUFFER_FROM_END_OF_SETTLEMENT_PERIOD_DURATION}
                  />
                </Col>
                <Col lg={2} xs={2} md={2} style={{ marginTop: "25px", padding: "0px" }}>
                  <CheckboxGroup
                    id="settlementPriceCalculationRequiresApproval"
                    checked={product.settlementPriceCalculationRequiresApproval}
                    label="Requires Approval"
                    labelPlacementAbove={true}
                    tickStyle={{ marginLeft: "5px" }}
                    tooltip={toolTips.SETTLEMENT_PRICE_CALCULATION_REQUIRES_APPROVAL}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </Collapse>

          {showDerivativeInfo ? (
            <React.Fragment>
              <div
                onClick={() =>
                  this.setState({
                    swapInformation: !this.state.swapInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Derivative Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.swapInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>
              <Collapse in={this.state.swapInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date"
                        value={
                          product.maturityDate
                            ? DateTimeParser.toDisplay(product.maturityDate)
                            : null
                        }
                      />
                    </Col>
                    {product.type === OPTION && (
                      <Col lg={3} xs={3} md={3}>
                        <FieldGroupReadOnly
                          label="Put or Call"
                          value={capitalizeWord(
                            getEnumName(PutOrCall, product.putOrCall)
                          )}
                        />
                      </Col>
                    )}
                    {product.type === OPTION && (
                      <Col lg={3} xs={3} md={3}>
                        <FieldGroupReadOnly
                          label="Strike Price"
                          value={product.strikePrice}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              </Collapse>
            </React.Fragment>
          ) : null}

          {showForexInfo ? (
            <React.Fragment>
              <div
                onClick={() =>
                  this.setState({
                    swapInformation: !this.state.swapInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Forex Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.swapInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>
              <Collapse in={this.state.swapInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                          label="Base Currency"
                          value={product.fxBaseCurrency}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                          label="Quote Currency"
                          value={product.fxQuoteCurrency}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </React.Fragment>
          ) : null}

          {showIRS ? (
            <React.Fragment>
              <div
                onClick={() =>
                  this.setState({
                    swapInformation: !this.state.swapInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Swap Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.swapInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.swapInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Effective Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.effectiveDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.maturityDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Swap Effective Date Offset"
                        value={
                          product.effectiveDateOffset === 0
                            ? ""
                            : product.effectiveDateOffset
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Roll and Payment Date Holiday Calendar"
                        value={
                          product.rollAndPaymentDateHolidayCalendarsList
                            ? product.rollAndPaymentDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row></Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    fixedLegInformation: !this.state.fixedLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Fixed Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.fixedLegInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.fixedLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.fixedPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Compunding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.fixedCompoundingMethod
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Roll Convention"
                        value={product.fixedRollConvention}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.fixedDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.fixedBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.fixedPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    floatingLegInformation: !this.state.floatingLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Floating Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.floatingLegInformation
                        ? "fa-minus"
                        : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.floatingLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index"
                        value={product.floatingRateIndex}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Holiday Calendar List"
                        value={
                          product.floatHolidayCalendarsList
                            ? product.floatHolidayCalendarsList.join(", ")
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.floatDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Compunding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.floatCompoundingMethod
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.floatPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Rate Reset Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.floatRateResetFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Reset Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatResetDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Roll Convention"
                        value={product.floatRollConvention}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Dates Offset"
                        value={product.floatFixingDatesOffset}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixing Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatFixingDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.floatPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Dates Day Type"
                        value={getOptionText(
                          InstrumentStaticData.FixingDatesDayType,
                          product.floatFixingDatesDayType
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Date Holiday Calendar List"
                        value={
                          product.floatFixingDateHolidayCalendarsList
                            ? product.floatFixingDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index Tenor"
                        value={product.floatingRateIndexTenor}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </React.Fragment>
          ) : null}

          {showBasisSwap ? (
            <React.Fragment>
              <div
                onClick={() =>
                  this.setState({
                    swapInformation: !this.state.swapInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Swap Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.swapInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.swapInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Tenor" value={product.tenor} />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Side Convention"
                        value={getOptionText(
                          InstrumentStaticData.SideConventionOptions,
                          product.sideConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Swap Effective Date Offset"
                        value={
                          product.effectiveDateOffset === 0
                            ? ""
                            : product.effectiveDateOffset
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Effective Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.effectiveDateBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.maturityDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Swap Effective Date"
                        value={
                          product.swapEffectiveDate
                            ? DateTimeParser.toDisplay(
                              product.swapEffectiveDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date"
                        value={
                          product.maturityDate
                            ? DateTimeParser.toDisplay(product.maturityDate)
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Roll and Payment Date Holiday Calendar List"
                        value={
                          product.rollAndPaymentDateHolidayCalendarsList
                            ? product.rollAndPaymentDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    floatingLegInformation: !this.state.floatingLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Floating Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.floatingLegInformation
                        ? "fa-minus"
                        : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.floatingLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Floating Rate Index"
                        value={product.leg1FloatingRateIndex}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Floating Rate Index"
                        value={product.leg2FloatingRateIndex}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.leg1FloatDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.leg2FloatDayCountConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Compunding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.leg1FloatCompoundingMethod
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Compunding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.leg2FloatCompoundingMethod
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Rate Reset Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.leg1FloatRateResetFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Rate Reset Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.leg2FloatRateResetFrequency
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.FixedPaymentFrequency,
                          product.leg1FloatPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.FixedPaymentFrequency,
                          product.leg2FloatPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.leg1FloatBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.leg2FloatBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Float Reset Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.leg1FloatResetDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Float Reset Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.leg2FloatResetDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Reset Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.leg1FloatResetDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Reset Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.leg2FloatResetDateBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Float Fixing Date Holiday Calendars"
                        value={
                          product.leg1FloatFixingDateHolidayCalendarsList
                            ? product.leg1FloatFixingDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Float Fixing Date Holiday Calendars"
                        value={
                          product.leg2FloatFixingDateHolidayCalendarsList
                            ? product.leg2FloatFixingDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Fixing Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.leg1FloatFixingDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Fixing Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.leg2FloatFixingDateBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 First Float Reset Date"
                        value={
                          product.leg1FirstFloatResetDate
                            ? DateTimeParser.toDisplay(
                              product.leg1FirstFloatResetDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 First Float Reset Date"
                        value={
                          product.leg2FirstFloatResetDate
                            ? DateTimeParser.toDisplay(
                              product.leg2FirstFloatResetDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 First Float Fixing Date"
                        value={
                          product.leg1FirstFloatFixingDate
                            ? DateTimeParser.toDisplay(
                              product.leg1FirstFloatFixingDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 First Float Fixing Date"
                        value={
                          product.leg2FirstFloatFixingDate
                            ? DateTimeParser.toDisplay(
                              product.leg2FirstFloatFixingDate
                            )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Float Roll Convention"
                        value={product.leg1FloatRollConvention}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Float Roll Convention"
                        value={product.leg2FloatRollConvention}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Float Fixing Dates Offset"
                        value={product.leg1FloatFixingDatesOffset}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Float Fixing Dates Offset"
                        value={product.leg2FloatFixingDatesOffset}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Float Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.leg1FloatPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Float Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.leg2FloatPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Float Fixing Dates Day Type"
                        value={getOptionText(
                          InstrumentStaticData.FixingDatesDayType,
                          product.leg1FloatFixingDatesDayType
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Float Fixing Dates Day Type"
                        value={getOptionText(
                          InstrumentStaticData.FixingDatesDayType,
                          product.leg2FloatFixingDatesDayType
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Floating Rate Index Tenor"
                        value={product.leg1FloatingRateIndexTenor}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Floating Rate Index Tenor"
                        value={product.leg2FloatingRateIndexTenor}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg1 Float First Unadjusted Payment Date"
                        value={
                          product.leg1FloatFirstUnadjustedPaymentDate
                            ? DateTimeParser.toDisplay(
                              product.leg1FloatFirstUnadjustedPaymentDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Leg2 Float First Unadjusted Payment Date"
                        value={
                          product.leg2FloatFirstUnadjustedPaymentDate
                            ? DateTimeParser.toDisplay(
                              product.leg2FloatFirstUnadjustedPaymentDate
                            )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float First Unadjusted Effective Date"
                        value={
                          product.floatFirstUnadjustedEffectiveDate
                            ? DateTimeParser.toDisplay(
                              product.floatFirstUnadjustedEffectiveDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Unadjusted Maturity Date"
                        value={
                          product.unadjustedMaturityDate
                            ? DateTimeParser.toDisplay(
                              product.unadjustedMaturityDate
                            )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </React.Fragment>
          ) : null}

          {showZCIS ? (
            <React.Fragment>
              <div
                onClick={() =>
                  this.setState({
                    swapInformation: !this.state.swapInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Swap Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.swapInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>
              <Collapse in={this.state.swapInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Tenor" value={product.tenor} />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Side Convention"
                        value={getOptionText(
                          InstrumentStaticData.SideConventionOptions,
                          product.sideConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Swap Effective Date"
                        value={
                          product.swapEffectiveDate
                            ? DateTimeParser.toDisplay(
                              product.swapEffectiveDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Forward Starting Period Time Period"
                        value={getOptionText(InstrumentStaticData.TimePeriodOptions, product.forwardStartingPeriodTimePeriod)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Tenor Time Period"
                        value={getOptionText(InstrumentStaticData.TimePeriodOptions, product.tenorTimePeriod)}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Index Source"
                        value={product.indexSource}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Forward Starting Period"
                        value={product.forwardStartingPeriod}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Roll and Payment Date Holiday Calendars"
                        value={
                          product.rollAndPaymentDateHolidayCalendarsList
                            ? product.rollAndPaymentDateHolidayCalendarsList.join(
                                ", "
                              )
                            : ""
                        }
                      />
                    </Col>
                    {/* New */}
                    
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Inflation Lag"
                        value={product.inflationLag}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Unadjusted Effective Date"
                        value={
                          product.unadjustedEffectiveDate
                            ? DateTimeParser.toDisplay(
                              product.unadjustedEffectiveDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Effective Date Offset"
                        value={
                          product.effectiveDateOffset === 0
                            ? ""
                            : product.effectiveDateOffset
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Effective Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.effectiveDateBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Interpolation Method"
                        value={product.interpolationMethod}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date"
                        value={
                          product.maturityDate
                            ? DateTimeParser.toDisplay(product.maturityDate)
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                        <FieldGroupReadOnly
                          label="Unadjusted Maturity Date"
                          value={
                            product.unadjustedMaturityDate
                              ? DateTimeParser.toDisplay(
                                product.unadjustedMaturityDate
                              )
                              : null
                          }
                        />
                      </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.maturityDateBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Inflation Instrument Name"
                        value={product.inflationInstrumentName}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Fallback Bond Applicable" value={product.fallbackBondApplicable + ""} />
                    </Col>
                  </Row>
                </div>
              </Collapse>
              <div
                onClick={() =>
                  this.setState({
                    fixedLegInformation: !this.state.fixedLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Fixed Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.fixedLegInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.fixedLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.fixedPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Roll Convention"
                        value={product.fixedRollConvention}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.fixedPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed First Unadjusted Payment Date"
                        value={
                          product.fixedFirstUnadjustedPaymentDate
                            ? DateTimeParser.toDisplay(
                              product.fixedFirstUnadjustedPaymentDate
                            )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.fixedDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Compounding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.fixedCompoundingMethod
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.fixedBusinessDayConvention
                        )}
                        />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    floatingLegInformation: !this.state.floatingLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Floating Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.floatingLegInformation
                        ? "fa-minus"
                        : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>
              <Collapse in={this.state.floatingLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Holiday Calendars"
                        value={
                          product.floatHolidayCalendarsList
                            ? product.floatHolidayCalendarsList.join(", ")
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.floatPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index"
                        value={product.floatingRateIndex}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float First Unadjusted Payment Date"
                        value={
                          product.floatFirstUnadjustedPaymentDate
                            ? DateTimeParser.toDisplay(
                              product.floatFirstUnadjustedPaymentDate
                            )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Roll Convention"
                        value={product.floatRollConvention}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.floatPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatBusinessDayConvention
                        )}
                        />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.floatDayCountConvention
                        )}
                        />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Compounding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.floatCompoundingMethod
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </React.Fragment>
          ) : null}

          {showFsIRS ? (
            <React.Fragment>
              <div
                onClick={() =>
                  this.setState({
                    swapInformation: !this.state.swapInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Swap Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.swapInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.swapInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Tenor" value={product.tenor} />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Side Convention"
                        value={getOptionText(
                          InstrumentStaticData.SideConventionOptions,
                          product.sideConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Swap Effective Date"
                        value={
                          product.swapEffectiveDate
                            ? DateTimeParser.toDisplay(
                              product.swapEffectiveDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Effective Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.effectiveDateBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date"
                        value={
                          product.maturityDate
                            ? DateTimeParser.toDisplay(product.maturityDate)
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.maturityDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Unadjusted Effective Date"
                        value={
                          product.unadjustedEffectiveDate
                            ? DateTimeParser.toDisplay(
                              product.unadjustedEffectiveDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Roll and Payment Date Holiday Calendar List"
                        value={
                          product.rollAndPaymentDateHolidayCalendarsList
                            ? product.rollAndPaymentDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Unadjusted Maturity Date"
                        value={
                          product.unadjustedMaturityDate
                            ? DateTimeParser.toDisplay(
                              product.unadjustedMaturityDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Swap Effective Date Offset"
                        value={
                          product.effectiveDateOffset === 0
                            ? ""
                            : product.effectiveDateOffset
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Forward Starting Period"
                        value={product.forwardStartingPeriod}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    fixedLegInformation: !this.state.fixedLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Fixed Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.fixedLegInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.fixedLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.fixedPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Compounding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.fixedCompoundingMethod
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.fixedDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.fixedBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Roll Convention"
                        value={product.fixedRollConvention}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed First Unadjusted Payment Date"
                        value={
                          product.fixedFirstUnadjustedPaymentDate
                            ? DateTimeParser.toDisplay(
                              product.fixedFirstUnadjustedPaymentDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.fixedPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    floatingLegInformation: !this.state.floatingLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Floating Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.floatingLegInformation
                        ? "fa-minus"
                        : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.floatingLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Compunding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.floatCompoundingMethod
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.floatDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Holiday Calendar List"
                        value={
                          product.floatHolidayCalendarsList
                            ? product.floatHolidayCalendarsList.join(", ")
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.floatPaymentFrequency
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="First Float Reset Date"
                        value={
                          product.firstFloatResetDate
                            ? DateTimeParser.toDisplay(
                              product.firstFloatResetDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="First Float Fixing Date"
                        value={
                          product.firstFloatFixingDate
                            ? DateTimeParser.toDisplay(
                              product.firstFloatFixingDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index"
                        value={product.floatingRateIndex}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Reset Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatResetDateBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Roll Convention"
                        value={product.floatRollConvention}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float First Unadjusted Payment Date"
                        value={
                          product.floatFirstUnadjustedPaymentDate
                            ? DateTimeParser.toDisplay(
                              product.floatFirstUnadjustedPaymentDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.floatPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Dates Day Type"
                        value={getOptionText(
                          InstrumentStaticData.FixingDatesDayType,
                          product.floatFixingDatesDayType
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Dates Offset"
                        value={product.floatFixingDatesOffset}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index Tenor"
                        value={product.floatingRateIndexTenor}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Reset Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.floatResetDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixing Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatFixingDateBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Date Holiday Calendar List"
                        value={
                          product.floatFixingDateHolidayCalendarsList
                            ? product.floatFixingDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Rate Reset Frequency"
                        value={getOptionText(
                          InstrumentStaticData.FloatRateResetFrequency,
                          product.floatRateResetFrequency
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </React.Fragment>
          ) : null}

          {showFRAS ? (
            <React.Fragment>
              <div
                onClick={() =>
                  this.setState({
                    swapInformation: !this.state.swapInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Forward Rate Agreement Swap Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.swapInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.swapInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Tenor" value={product.tenor} />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Side Convention"
                        value={getOptionText(
                          InstrumentStaticData.SideConventionOptions,
                          product.sideConvention
                        )}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Forward Starting Period"
                        value={product.forwardStartingPeriod}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Swap Effective Date"
                        value={
                          product.swapEffectiveDate
                            ? DateTimeParser.toDisplay(
                              product.swapEffectiveDate
                            )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Effective Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.effectiveDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date"
                        value={
                          product.maturityDate
                            ? DateTimeParser.toDisplay(product.maturityDate)
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.maturityDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Calculation Period Number of Days"
                        value={product.calculationPeriodNumberOfDays}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Unadjusted Effective Date"
                        value={
                          product.unadjustedEffectiveDate
                            ? DateTimeParser.toDisplay(
                              product.unadjustedEffectiveDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Unadjusted Maturity Date"
                        value={
                          product.unadjustedMaturityDate
                            ? DateTimeParser.toDisplay(
                              product.unadjustedMaturityDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Roll and Payment Date Holiday Calendar List"
                        value={
                          product.rollAndPaymentDateHolidayCalendarsList
                            ? product.rollAndPaymentDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    fixedLegInformation: !this.state.fixedLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Fixed Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.fixedLegInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.fixedLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.fixedDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.fixedBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Fixing Holiday Date Calendars"
                        value={
                          product.fixedFixingDateHolidayCalendarsList
                            ? product.fixedFixingDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    floatingLegInformation: !this.state.floatingLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Floating Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.floatingLegInformation
                        ? "fa-minus"
                        : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.floatingLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.floatDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Holiday Calendar List"
                        value={
                          product.floatFixingDateHolidayCalendarsList
                            ? product.floatFixingDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixing Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatFixingDateBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index"
                        value={product.floatingRateIndex}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Dates Day Type"
                        value={getOptionText(
                          InstrumentStaticData.FixingDatesDayType,
                          product.floatFixingDatesDayType
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Dates Offset"
                        value={product.floatFixingDatesOffset}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Date"
                        value={
                          product.floatFixingDate
                            ? DateTimeParser.toDisplay(product.floatFixingDate)
                            : null
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index Tenor"
                        value={product.floatingRateIndexTenor}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </React.Fragment>
          ) : null}

          {showOIS ? (
            <React.Fragment>
              <div
                onClick={() =>
                  this.setState({
                    swapInformation: !this.state.swapInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Overnight Index Swap Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.swapInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.swapInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Tenor" value={product.tenor} />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Tenor Time Period"
                        value={getOptionText(InstrumentStaticData.TimePeriodOptions, product.tenorTimePeriod)}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Side Convention"
                        value={getOptionText(InstrumentStaticData.SideConventionOptions, product.sideConvention)}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Swap Effective Date"
                        value={
                          product.swapEffectiveDate
                            ? DateTimeParser.toDisplay(
                              product.swapEffectiveDate
                            )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Effective Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.effectiveDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.maturityDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Unadjusted Effective Date"
                        value={
                          product.unadjustedEffectiveDate
                            ? DateTimeParser.toDisplay(
                              product.unadjustedEffectiveDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Unadjusted Maturity Date"
                        value={
                          product.unadjustedMaturityDate
                            ? DateTimeParser.toDisplay(
                              product.unadjustedMaturityDate
                            )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date"
                        value={
                          product.maturityDate
                            ? DateTimeParser.toDisplay(product.maturityDate)
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Swap Effective Date Offset"
                        value={
                          product.effectiveDateOffset === 0
                            ? ""
                            : product.effectiveDateOffset
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Forward Starting Period"
                        value={product.forwardStartingPeriod}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Forward Starting Period Time Period"
                        value={getOptionText(InstrumentStaticData.TimePeriodOptions, product.forwardStartingPeriodTimePeriod)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Payment Date Day Type"
                        value={getOptionText(
                          InstrumentStaticData.FixingDatesDayType,
                          product.paymentDateDayType
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    fixedLegInformation: !this.state.fixedLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Fixed Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.fixedLegInformation ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.fixedLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.fixedPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Compunding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.fixedCompoundingMethod
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.fixedDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.fixedBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Holiday Calendars"
                        value={
                          product.fixedHolidayCalendarsList
                            ? product.fixedHolidayCalendarsList.join(", ")
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Roll Convention"
                        value={product.fixedRollConvention}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Date Offset"
                        value={product.fixedPaymentDateOffset}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed First Unadjusted Payment Date"
                        value={
                          product.fixedFirstUnadjustedPaymentDate
                            ? DateTimeParser.toDisplay(
                              product.fixedFirstUnadjustedPaymentDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.fixedPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    floatingLegInformation: !this.state.floatingLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Floating Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.state.floatingLegInformation
                        ? "fa-minus"
                        : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.floatingLegInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Compounding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.floatCompoundingMethod
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.floatDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Holiday Calendars"
                        value={
                          product.floatHolidayCalendarsList
                            ? product.floatHolidayCalendarsList.join(", ")
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.FloatPaymentFrequency,
                          product.floatPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Rate Reset Frequency"
                        value={getOptionText(
                          InstrumentStaticData.FloatRateResetFrequency,
                          product.floatRateResetFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Reset Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatResetDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="First Float Reset Date"
                        value={
                          product.firstFloatResetDate
                            ? DateTimeParser.toDisplay(
                              product.firstFloatResetDate
                            )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="First Float Fixing Date"
                        value={
                          product.firstFloatFixingDate
                            ? DateTimeParser.toDisplay(product.firstFloatFixingDate)
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Dates Day Type"
                        value={getOptionText(
                          InstrumentStaticData.FixingDatesDayType,
                          product.floatFixingDatesDayType
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index"
                        value={product.floatingRateIndex}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Roll Convention"
                        value={product.floatRollConvention}
                      />
                    </Col>
                  </Row>
                  <Row>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float First Unadjusted Payment Date"
                        value={
                          product.floatFirstUnadjustedPaymentDate
                            ? DateTimeParser.toDisplay(
                              product.floatFirstUnadjustedPaymentDate
                            )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.floatPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Reset Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.floatResetDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Dates Offset"
                        value={product.floatFixingDatesOffset}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixing Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatFixingDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Date Holiday Calendars"
                        value={
                          product.floatFixingDateHolidayCalendarsList
                            ? product.floatFixingDateHolidayCalendarsList.join(
                              ", "
                            )
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Payment Date Offset"
                        value={product.floatPaymentDateOffset}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index Tenor"
                        value={product.floatingRateIndexTenor}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </React.Fragment>
          ) : null}

          {showEvent && <>
            <div onClick={() => this.setState({ swapInformation: !this.state.swapInformation })}>
              <Row className="accordian-style">
                <Col lg={11} xs={11} md={11}>
                  <h6><strong>Event Information</strong></h6>
                </Col>
                <Col>
                  <i className={`fa fa-fw ${this.state.swapInformation ? 'fa-minus' : 'fa-plus'} custom-plus`} />
                </Col>
              </Row>
            </div>

            <Collapse in={this.state.swapInformation}>
              <div className="collapse-content">
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <h6>Attributes</h6>
                  </Col>
                </Row>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Payout Type" value={getOptionText(InstrumentStaticData.EventPayoutType, product.evtAttPayoutType)} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Position Accountability Value" value={product.evtAttPositionAccountability} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Position Limit" value={product.evtAttPositionLimit} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Source Agency" value={product.evtAttSourceAgency} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Source Agency URL" value={product.evtAttSourceAgencyUrl} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Payout Value" value={product.evtAttPayoutValue} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Payout Lower Bound" value={product.evtAttPayoutLowerBound} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Payout Upper Bound" value={product.evtAttPayoutUpperBound} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Calculation Method" value={getOptionText(this.state.calculationMethods, product.evtAttCalculationMethod)} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Strike Unit" value={product.evtAttStrikeUnit} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Strike Evaluation Lower Limit" value={product.evtAttStrikeEvaluationLowerLimit} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Strike Evaluation Upper Limit" value={product.evtAttStrikeEvaluationUpperLimit} />
                  </Col>
                </Row>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Frequency Type" value={product.evtAttFrequencyType} />
                  </Col>
                  <Col lg={9} xs={9} md={9}>
                    <FieldGroupReadOnly label="Question Template" value={product.evtAttQuestion} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FieldGroupReadOnly label="Underlying Event Specification" value={product.evtAttUnderlyingEventSpecification} />
                  </Col>
                </Row>

                {!!product.subTypesList && product.subTypesList.length > 0 && <Row>
                  <Col lg={3} xs={3} md={3}>
                    <h6>Sub Types</h6>
                  </Col>
                </Row>}
                {product.subTypesList.map( type => (
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Sub Type Name" value={type.symbol} />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Buy Price Increment" value={type.buyPriceIncrement} />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Sell Price Increment" value={type.sellPriceIncrement} />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Buy Scale" value={type.buyScale} />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Sell Scale" value={type.sellScale} />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Opposing Side" value={getOpposingSideName(type.opposingSide)} />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Strict Limit" value={type.strictLimit + ""} />
                    </Col>
                  </Row>
                ))}
              </div>
            </Collapse>
          </>}

          {showNDF && <>
            <div onClick={() => this.setState({ swapInformation: !this.state.swapInformation })}>
              <Row className="accordian-style">
                <Col lg={11} xs={11} md={11}>
                  <h6><strong>Non Deliverable Forward Information</strong></h6>
                </Col>
                <Col>
                  <i className={`fa fa-fw ${this.state.swapInformation ? 'fa-minus' : 'fa-plus'} custom-plus`} />
                </Col>
              </Row>
            </div>

            <Collapse in={this.state.swapInformation}>
              <div className="collapse-content">
                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <h6>Attributes</h6>
                  </Col>
                </Row>

                <Row>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Tenor" value={product.tenor} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Side Convention"
                        value={getOptionText(InstrumentStaticData.SideConventionOptions, product.sideConvention)}
                      />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Forward Starting Period" value={product.forwardStartingPeriod} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Forward Starting Period Time Period"
                      value={getOptionText(InstrumentStaticData.TimePeriodOptions, product.forwardStartingPeriodTimePeriod)}
                      />
                   </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Currency 1" value={product.currency1} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Currency 2" value={product.currency2} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Exchanged Currency 1" value={product.exchangedCurrency1} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Exchanged Currency 2" value={product.exchangedCurrency2} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Exchange Rate Currency 1" value={product.exchangeRateCurrency1} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Exchange Rate Currency 2" value={product.exchangeRateCurrency2} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Valuation Date"
                        value={
                          product.valuationDate
                            ? DateTimeParser.toDisplay(
                                product.valuationDate
                              )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixing Date"
                        value={
                          product.fixingDate
                            ? DateTimeParser.toDisplay(
                                product.fixingDate
                              )
                            : null
                        }
                      />
                    </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly
                      label="Exchange Rate Quote Basis"
                      value={getOptionText(InstrumentStaticData.QuoteBasisOptions, product.exchangeRateQuoteBasis)}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly
                      label="Fixing Quote Basis"
                      value={getOptionText(InstrumentStaticData.QuoteBasisOptions, product.fixingQuoteBasis)}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Settlement Currency" value={product.settlementCurrency} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly
                      label="Tenor Time Period"
                      value={getOptionText(InstrumentStaticData.TimePeriodOptions, product.tenorTimePeriod)}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Fixing Currency 1" value={product.fixingCurrency1} />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly label="Fixing Currency 2" value={product.fixingCurrency2} />
                  </Col>
                </Row>
              </div>
            </Collapse>
          </>}

          {showSPS ? (
            <React.Fragment>
              <div
                onClick={() =>
                  this.setState({
                    swapInformation: !this.state.swapInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Single Period Swap Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${
                        this.state.swapInformation ? "fa-minus" : "fa-plus"
                      } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.swapInformation}>
                <div className="collapse-content">
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly label="Tenor" value={product.tenor} />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Side Convention"
                        value={getOptionText(
                          InstrumentStaticData.SideConventionOptions,
                          product.sideConvention
                        )}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Tenor Time Period"
                        value={getOptionText(InstrumentStaticData.TimePeriodOptions, product.tenorTimePeriod)}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Swap Effective Date"
                        value={
                          product.swapEffectiveDate
                            ? DateTimeParser.toDisplay(
                                product.swapEffectiveDate
                              )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Effective Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.effectiveDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.maturityDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Roll and Payment Date Holiday Calendars"
                        value={
                          product.rollAndPaymentDateHolidayCalendarsList
                            ? product.rollAndPaymentDateHolidayCalendarsList.join(
                                ", "
                              )
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Unadjusted Effective Date"
                        value={
                          product.unadjustedEffectiveDate
                            ? DateTimeParser.toDisplay(
                                product.unadjustedEffectiveDate
                              )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Unadjusted Maturity Date"
                        value={
                          product.unadjustedMaturityDate
                            ? DateTimeParser.toDisplay(
                                product.unadjustedMaturityDate
                              )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Maturity Date"
                        value={
                          product.maturityDate
                            ? DateTimeParser.toDisplay(product.maturityDate)
                            : null
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    fixedLegInformation: !this.state.fixedLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Fixed Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${
                        this.state.fixedLegInformation ? "fa-minus" : "fa-plus"
                      } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.fixedLegInformation}>
                <div className="collapse-content">
                  <Row>
                  <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.fixedPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Compounding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.fixedCompoundingMethod
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.fixedDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.fixedBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Roll Convention"
                        value={product.fixedRollConvention}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed First Unadjusted Payment Date"
                        value={
                          product.fixedFirstUnadjustedPaymentDate
                            ? DateTimeParser.toDisplay(
                                product.fixedFirstUnadjustedPaymentDate
                              )
                            : null
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Fixed Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.fixedPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                onClick={() =>
                  this.setState({
                    floatingLegInformation: !this.state.floatingLegInformation,
                  })
                }
              >
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Floating Leg Information</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${
                        this.state.floatingLegInformation
                          ? "fa-minus"
                          : "fa-plus"
                      } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>

              <Collapse in={this.state.floatingLegInformation}>
                <div className="collapse-content">
                  <Row>
                  <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Compunding Method"
                        value={getOptionText(
                          InstrumentStaticData.CompoundingOptions,
                          product.floatCompoundingMethod
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Day Count Convention"
                        value={getOptionText(
                          InstrumentStaticData.DayCountConventionOptions,
                          product.floatDayCountConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Holiday Calendars"
                        value={
                          product.floatHolidayCalendarsList
                            ? product.floatHolidayCalendarsList.join(", ")
                            : ""
                        }
                      />
                    </Col>

                  </Row>
                  <Row>
                  <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Payment Frequency"
                        value={getOptionText(
                          InstrumentStaticData.PaymentFrequencyOptions,
                          product.floatPaymentFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Rate Reset Frequency"
                        value={getOptionText(
                          InstrumentStaticData.FloatRateResetFrequency,
                          product.floatRateResetFrequency
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Reset Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatResetDateBusinessDayConvention
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="First Float Reset Date"
                        value={
                          product.firstFloatResetDate
                            ? DateTimeParser.toDisplay(
                                product.firstFloatResetDate
                              )
                            : null
                        }
                      />
                    </Col>

                  </Row>

                  <Row>
                  <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="First Float Fixing Date"
                        value={
                          product.firstFloatFixingDate
                            ? DateTimeParser.toDisplay(product.firstFloatFixingDate)
                            : null
                        }
                      />
                    </Col>
                  <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index"
                        value={product.floatingRateIndex}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Roll Convention"
                        value={product.floatRollConvention}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float First Unadjusted Payment Date"
                        value={
                          product.floatFirstUnadjustedPaymentDate
                            ? DateTimeParser.toDisplay(
                                product.floatFirstUnadjustedPaymentDate
                              )
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                  <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Payment Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.floatPaymentDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Dates Day Type"
                        value={getOptionText(
                          InstrumentStaticData.FixingDatesDayType,
                          product.floatFixingDatesDayType
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Dates Offset"
                        value={product.floatFixingDatesOffset}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Floating Rate Index Tenor"
                        value={product.floatingRateIndexTenor}
                      />
                    </Col>
                  </Row>
                  <Row>
                  <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Reset Date Relative To"
                        value={getOptionText(
                          InstrumentStaticData.DateRelativeToOptions,
                          product.floatResetDateRelativeTo
                        )}
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Date Holiday Calendars"
                        value={
                          product.floatFixingDateHolidayCalendarsList
                            ? product.floatFixingDateHolidayCalendarsList.join(
                                ", "
                              )
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        label="Float Fixing Date Business Day Convention"
                        value={getOptionText(
                          InstrumentStaticData.BusinessDayConventionOptions,
                          product.floatFixingDateBusinessDayConvention
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </React.Fragment>
          ) : null}


          <div onClick={() => this.setState({ additionalDetails: !this.state.additionalDetails })}>
            <Row className="accordian-style">
              <Col lg={11} xs={11} md={11}>
                <h6>
                  <strong>Risk Settings</strong>
                </h6>
              </Col>
              <Col>
                <i className={`fa fa-fw ${this.state.additionalDetails ? "fa-minus" : "fa-plus"} custom-plus`} />
              </Col>
            </Row>
          </div>

          <Collapse in={this.state.additionalDetails}>
            <div className="collapse-content">
              <Row>
                <Col lg={3} xs={3} md={3}>
                  <PriceLimitReadOnly
                    lowLimitValue={product.lowPriceLimit}
                    highLimitValue={product.highPriceLimit}
                    setLowLimit={product.setLowPriceLimit}
                    setHighLimit={product.setHighPriceLimit}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <RelativePriceLimitReadOnly
                    lowLimitValue={product.relativeLow}
                    highLimitValue={product.relativeHigh}
                    setLowLimit={product.relativeLowSet}
                    setHighLimit={product.relativeHighSet}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <OrderLimitReadOnly
                    lowLimitValue={product.lowOrderLimit}
                    highLimitValue={product.highOrderLimit}
                    setLowLimit={product.setLowOrderLimit}
                    setHighLimit={product.setHighOrderLimit}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <TotalNotionalLimitReadOnly
                    lowLimitValue={product.lowTotalNotionalLimit}
                    highLimitValue={product.highTotalNotionalLimit}
                    setLowLimit={product.setLowTotalNotionalLimit}
                    setHighLimit={product.setHighTotalNotionalLimit}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={6} md={6}>
                  <CheckboxGroup
                    id={"chkSkipRiskAndCurrencyChecks"}
                    checked={product.skipRiskAndCurrencyChecks}
                    label={"Skip Risk Checks and Currency Balance Updates"}
                    tooltip={toolTips.SKIP_RISK_AND_CURRENCY_CHECKS}
                    tooltipPlace={'right'}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </Collapse>
          <br />
          <ButtonMain
            type="button"
            onClick={(e) => {
              e.preventDefault();
              var url = `/instruments/new?productId=${product.name}`;
              this.context.router.history.push(url);
            }}
            text="CREATE AN INSTRUMENT"
          />
          <ButtonBorderless
            type="button"
            text="Cancel"
            icon="times-circle"
            customClassName="grey-icon"
            onClick={(e) => {
              e.preventDefault();
              this.context.router.history.push("/products");
            }}
          />
        </Container>
      </form>
    );
  }
}

ProductFormReadOnly.propTypes = {
  item: PropTypes.instanceOf(ProductFormItem),
};

export default connect(mapStateToProps, null)(ProductFormReadOnly);
