import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import DateGroup from "../../../core/form/DateGroup";
import { FormModes } from "../../../../constants/enumerations";
import FieldGroup from "../../../core/form/FieldGroup";
import DropdownListGroup from "../../../core/form/DropdownListGroup";

import {
  validateField,
  getOptionText,
  NUMBER_TYPE,
  DATE_TYPE,
} from "../../instrumentFormUtils";
import DateTimeParser from "../../../../modules/dateTimeParser";

export const OisInfoBasis = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  errors,
  mode,
  handleChange,
}) => {
  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6>
              <strong>Over Night Index Swap Information</strong>
            </h6>
          </Col>
          <Col>
            <i
              className={`fa fa-fw ${
                open ? "fa-minus" : "fa-plus"
              } custom-plus`}
            />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Tenor" value={values.tenor} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Tenor Time Period" value={getOptionText(InstrumentStaticData.TimePeriodOptions, values.tenorTimePeriod)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Side Convention"
                value={getOptionText(InstrumentStaticData.SideConventionOptions, values.sideConvention)}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Swap Effective Date"
                value={
                  values.swapEffectiveDate
                    ? DateTimeParser.toDisplay(values.swapEffectiveDate)
                    : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Effective Date Business Day Convention"
                value={getOptionText(
                  InstrumentStaticData.BusinessDayConventionOptions,
                  values.effectiveDateBusinessDayConvention
                )}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Maturity Date Business Day Convention"
                value={getOptionText(
                  InstrumentStaticData.BusinessDayConventionOptions,
                  values.maturityDateBusinessDayConvention
                )}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="unadjustedEffectiveDate"
                name="unadjustedEffectiveDate"
                label="Unadjusted Effective Date"
                value={values.unadjustedEffectiveDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("unadjustedEffectiveDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="unadjustedMaturityDate"
                name="unadjustedMaturityDate"
                label="Unadjusted Maturity Date"
                value={values.unadjustedMaturityDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("unadjustedMaturityDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="maturityDate"
                name="maturityDate"
                label="Maturity Date"
                value={values.maturityDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("maturityDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Swap Effective Date Offset"
                value={values.effectiveDateOffset}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup
                id="forwardStartingPeriod"
                name="forwardStartingPeriod"
                type="textarea"
                label="Forward Starting Period"
                value={values.forwardStartingPeriod}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="forwardStartingPeriodTimePeriod"
                name="forwardStartingPeriodTimePeriod"
                label="Forward Starting Period Time Period"
                value={values.forwardStartingPeriodTimePeriod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("forwardStartingPeriodTimePeriod", e.id)}
                data={InstrumentStaticData.TimePeriodOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Payment Date Day Type"
                value={getOptionText(
                  InstrumentStaticData.FixingDatesDayType,
                  values.paymentDateDayType
                )}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  );
};

export const ValidateOisInfoBasis = (values, errors) => {
  errors = validateField(values, errors, "tenor", "Tenor", NUMBER_TYPE);
  errors = validateField(values, errors, "sideConvention", "Side Convention", NUMBER_TYPE, values);
  errors = validateField(values, errors, "maturityDateBusinessDayConvention", "Maturity Date Business Day Convention", NUMBER_TYPE);
  errors = validateField(values, errors, "effectiveDateBusinessDayConvention","Effective Date Business Day Convention", NUMBER_TYPE);
  errors = validateField(values, errors, "swapEffectiveDate", "Swap Effective Date", DATE_TYPE);
  errors = validateField(values, errors, "maturityDate", "Swap Maturity Date", DATE_TYPE);
  return validateField(values, errors, "effectiveDateOffset", "Swap Effective Date Offset", NUMBER_TYPE);
};
