import React, { Component } from "react";

import { ParticipantRole } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import FieldGroup from "../components/core/form/FieldGroup";
import { getEnumName, capitalizeWord } from "../modules/util";
import DropdownListGroup from "../components/core/form/DropdownListGroup";

class UserRoleListContainer extends Component {
  formatRole = role => {
    return capitalizeWord(getEnumName(ParticipantRole, role));
  };

  getRoles = (includeUndefined) => {
    let { firm } = this.props;
    let retVal = includeUndefined ? [this.formatRole(ParticipantRole.PARTICIPANT_ROLE_UNDEFINED)] : [];
    if (firm && firm.firmType) {
      switch (firm.firmType.id) {
        case FirmType.FIRM_TYPE_PARTICIPANT:
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_TRADING));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_TRADING_READ_ONLY));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_MARKET_DATA));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_DROP_COPY));
          break;
        case FirmType.FIRM_TYPE_AGENT:
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_TRADING));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_TRADING_READ_ONLY));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_MARKET_DATA));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_DROP_COPY));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_BROKER));
          break;
        case FirmType.FIRM_TYPE_SUPERVISOR:
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_SUPERVISOR_READ_ONLY_ADMIN));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_SUPERVISOR_LIMITED_MEMBER_ADMIN));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_SUPERVISOR_ONBOARDING_ADMIN));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_SUPERVISOR_EXCHANGE_OPERATION_ADMIN));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_SUPERVISOR_ADMIN));
          break;
        case FirmType.FIRM_TYPE_CLEARING_MEMBER:
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_SUPERVISOR_READ_ONLY_ADMIN));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_SUPERVISOR_LIMITED_MEMBER_ADMIN));
          retVal.push(this.formatRole(ParticipantRole.PARTICIPANT_ROLE_SUPERVISOR_ONBOARDING_ADMIN));
          break;
        case FirmType.FIRM_TYPE_UNDEFINED:
        case FirmType.FIRM_TYPE_CLEARING_HOUSE:
        default:
          break;
      }
    }

    return retVal;
  };

  render() {
    const {
      isRequired,
      includeUndefined,
      errors,
      touched,
      value,
      setFieldValue,
      handleChange,
      handleBlur,
      onEdit
    } = this.props;
    return onEdit ? (
      <DropdownListGroup
        id="role"
        name="role"
        label="Role"
        value={this.props.value}
        onChange={event => {
          this.props.onChange(event);
        }}
        data={this.getRoles(!!includeUndefined)}
      />
    ) : (
      <FieldGroup
        id="role"
        name="role"
        label="Role"
        as="select"
        placeholder=""
        isRequired={isRequired}
        errors={errors}
        touched={touched}
        value={value}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        onBlur={handleBlur}
        options={this.getRoles(!!includeUndefined)}
      />
    );
  }
}

export default UserRoleListContainer;
