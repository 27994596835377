import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Menu from '../Menu';

import './index.css';
import { NavigationHelper } from '../../core/helpers/NavigationHelper';
import UserLicenseBanner from '../../users/UserLicenseBanner'

class AdminWrapper extends Component {
  render() {
    return (
      <React.Fragment>
        <Menu/>
        <div className="pg-wrapper">
          <UserLicenseBanner />
          <ToastContainer
            hideProgressBar={true}
          />
          <div className="page">
            {this.props.children}
          </div>
        </div>
        <NavigationHelper />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  null,
)(AdminWrapper);
