import React from "react";
const FilterItem = (props) => {
  const onCloseHandler = () => {
    if (props.onClose) {
      props.onClose(!!props.associatedObj ? props.associatedObj : props.id);
    }
  };
  return (
    <div className="Filter-Item">
      <span className="filter-item-label">{props.text}:</span>&nbsp;
      <span className="filter-item-value">{props.name}</span>
      <button className="Filter-Item-Cross" onClick={onCloseHandler}>
        <i className="fas fa-times"></i>
      </button>
    </div>
  );
};

export default FilterItem;
