import React, { useEffect, useState } from "react"
import AlertStaticData from "../../modules/alertsStaticData";
import {convertDateToString, getOptionName, isArrayWithValues, isNullOrUndefined} from "../../modules/util";
import DataGrid from "../core/data-grid/DataGrid";
import DataGridActions from "../core/data-grid/DataGridActions";
import AlertsDetail from "./AlertsDetail";
import Notification from "../../modules/notifications";
import _ from 'lodash';
import usePreserveGridColsLayout from "../hooks/usePreserveGridColsLayout";

const AlertsDataGrid = ({ data, onPageChange, onPageSizeChange, paginationComponent, firmsOptions, onUpdateAlert, onUpdateAlertNotes, onRefreshData }) => {

  const [alertStatuses, setAlertStatuses] = useState();
  const [gridData, setGridData] = useState([]);
  const [detailView, setDetailView] = useState();
  const [ref, setRef] = useState();
  const { columnsLayout, saveColumnsLayout } = usePreserveGridColsLayout("AlertsDGColumnsLayout");

  useEffect(() => {
    const alertStatuses = AlertStaticData.AlertStatuses([0])
    setAlertStatuses(alertStatuses);
    setGridData(data);
  }, [data, firmsOptions]);

  const updateStatus = (recordId, status) => {
    if (!!onUpdateAlert) {
      onUpdateAlert(recordId, status, (err, resp) => {
        if (!!resp) {
          const alertId = resp.getAlert().getId();
          const alerts = gridData.filter((itm) => itm.id === alertId);
          if (isArrayWithValues(alerts)) {
            alerts[0].status = status;
            setGridData([...gridData]);
          }
        }
      });
    }
  }

  const onColumnResize = (newResized, event) => {
    debugger
    if (isArrayWithValues(newResized)) {
      newResized.map((column) => {
        columnsLayout[column.id] = column.value;
      });
    }

    saveColumnsLayout(columnsLayout);
  }

  const getColumnWidth = (id) => {
    return columnsLayout[id] ? columnsLayout[id] : undefined;
  }

  const StatusDropDown = ({ record }) => {
    return (
      <select className='chk-form-control' value={record.status} onChange={(e) => { updateStatus(record.id, e.target.value) }}>
        {!!alertStatuses && alertStatuses.map(status => <option key={status.id} value={status.id}>{status.name}</option>)}
      </select>
    );
  }

  const StatusMultiDropDown = ({ recordSet }) => {
    return (
        <select className='chk-form-control' onChange={(e) => { updateStatus(recordSet, e.target.value) }}>
          {!!alertStatuses && alertStatuses.map(status => <option key={status.id} value={status.id}>{status.name}</option>)}
        </select>
    );
  }

  const buildAccountList = (accList) => {
    let idList = []
    accList.forEach(acc => {
      const parts = acc.split('/');
      let accName = parts[parts.length - 1];

      if (_.isEmpty(accName)) { accName = acc }

      idList.push(accName)
    });

    return idList.join(', ')
  }

  const columns = [
    {
      Header: () => {
        const hasSelection =
            ref &&
            ref.state &&
            ref.state.selection &&
            ref.state.selection.size > 0;
        return (
            <div>
              STATUS
              {hasSelection && <div><StatusMultiDropDown recordSet={ref.state.selection} /></div>}
            </div>
        )
      },
      id: "status",
      Cell: (cellInfo) => <StatusDropDown record={cellInfo.original} />,
      width: 150
    },
    {
      Header: "VENUE",
      accessor: "venue",
      width: getColumnWidth("venue"),
    },
    {
      Header: "ALERT NAME",
      accessor: "alertName",
      width: getColumnWidth("alertName"),
    },
    {
      Header: "DESCRIPTION",
      accessor: "description",
      width: getColumnWidth("description"),
    },
    {
      Header: "TIME GENERATED",
      id: "startTime",
      accessor: (d) => {
        return !!d.alertTime ? convertDateToString(d.alertTime, "YYYYMMDD-HH:mm:ss.SSS") : ""
      },
      width: getColumnWidth("startTime"),
    },
    {
      Header: "ALERT ID",
      accessor: "id",
      width: getColumnWidth("id"),
    },
    {
      Header: "TRADE ID",
      accessor: "tradeId",
      width: getColumnWidth("tradeId"),
    },
    {
      Header: "ACCOUNT IDS",
      id: "accounts",
      accessor: (d) => {
        return !!d.accounts ? buildAccountList(d.accounts) : ""
      },
      width: getColumnWidth("accounts"),
    },
    {
      Header: "FIRM",
      id: "firm",
      accessor: (d) => {
        return !!d.firm ? getOptionName(firmsOptions, d.firm, "") : ""
      },
      width: getColumnWidth("firm"),
    },
    {
      Header: "INSTRUMENT",
      accessor: "symbol",
      width: getColumnWidth("symbol"),
    },
    {
      Header: "ACTIONS",
      maxWidth: 60,
      minWidth: 60,
      sortable: false,
      Cell: (row) => {
        return (
            <DataGridActions
                onView={() => setDetailView(row.original)}
            />
        );
      },
    }
  ];

  return (
    <div>
      {!isNullOrUndefined(detailView) && <AlertsDetail
          detailView={detailView}
          onCancel={() => setDetailView(null)}
          onSaveNotes={(notes) => {
            onUpdateAlertNotes(detailView.id, notes, (err, resp) => {
              if (!err && !!resp) {
                Notification.success("Updated notes successfully!");
                onRefreshData();
              }
            });
            setDetailView(null);
          }}
      />}
      <DataGrid
        ref={(r) => {if (!!r && !ref) setRef(r)}}
        data={gridData}
        columns={columns}
        filterable={false}
        selectable={true}
        manual={true}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onResizedChange={onColumnResize}
        PaginationComponent={paginationComponent}
      />
    </div>
  )

}

export default AlertsDataGrid;