import React, { useState } from "react"
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import { Col, Container, FormLabel, Row } from "react-bootstrap";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import FieldGroup from "../core/form/FieldGroup";
import { isNullOrUndefined } from "../../modules/util";
import DropdownListGroup from "../core/form/DropdownListGroup";
import DataGrid from "../core/data-grid/DataGrid";
import _ from "lodash"
import querystring from 'query-string';
import './AlertsDetail.css'

const AlertsDetail = ({ detailView, onCancel, onSaveNotes }) => {
    const [notes, setNotes] = useState();

    if (isNullOrUndefined(notes) && notes !== "") setNotes(!!detailView.notes ? detailView.notes : "");

    let arrAvailable = (val) => {
        return !isNullOrUndefined(val) && !_.isEmpty(val)
    }

    const alertLink = (linkText, additionalText, shouldShow, linkRef) => {
        return (
            <>
                {shouldShow && <div>
                    <a href={linkRef}> <i><ins>{linkText}</ins></i></a>
                    <span style={{fontSize:"small"}}> <i>{"(" + additionalText + ")"}</i></span>
                </div>}
            </>
        );
    }

    const listAccount = (accounts) => {
        let accRows = []
        accounts.forEach(acc => {
            accRows.push(
                <li><a href={"/" + acc}><i><ins>{acc}</ins></i></a></li>
            )
        });
        return <ul className="no-bullets" >{accRows}</ul>;
    }

    const listLinks = (detailView) => {
        if (!detailView) {
            return <div></div>
        }

        let showExecutionsLink = arrAvailable(detailView.accounts) || arrAvailable(detailView.symbol) || !isNullOrUndefined(detailView.filterStartTime) || !isNullOrUndefined(detailView.filterEndTime)
        let showPositionsLink1 = arrAvailable(detailView.accounts) && !isNullOrUndefined(detailView.filterStartTime)
        let showPositionsLink2 = arrAvailable(detailView.accounts) && !isNullOrUndefined(detailView.filterEndTime)
        let showTimeAndSalesLink = arrAvailable(detailView.accounts) || arrAvailable(detailView.symbol) || !isNullOrUndefined(detailView.filterStartTime) || !isNullOrUndefined(detailView.filterEndTime)
        let showPositionLedgerLink = arrAvailable(detailView.accounts) && arrAvailable(detailView.positionLedgerReferenceId)
        let showLinks = showPositionsLink1 || showPositionsLink2 || showExecutionsLink || showTimeAndSalesLink || showPositionLedgerLink

        return (
            <div>
                {showLinks && <Row>
                    <Col>
                        <FormLabel>Links (Applicable Filters)</FormLabel>
                        <ul className="no-bullets">
                            <li>{alertLink("Executions", "Symbol, Accounts, Filter Start & End Date/Time", showExecutionsLink, executionsLink(detailView.accounts, detailView.symbol, detailView.filterStartTime, detailView.filterEndTime))}</li>
                            {buildPositionLinks("Start Positions", "Filter Start Date/Time", showPositionsLink1, detailView.accounts, detailView.filterStartTime).map(item => <li>{item}</li>)}
                            {buildPositionLinks("End Positions", "Filter End Date/Time", showPositionsLink2, detailView.accounts, detailView.filterEndTime).map(item => <li>{item}</li>)}
                            <li>{alertLink("Time and Sales", "Symbol, Accounts, Filter Start & End Date/Time", showTimeAndSalesLink, timeAndSalesLink(detailView.accounts, detailView.symbol, detailView.filterStartTime, detailView.filterEndTime))}</li>
                            {buildPositionLedgerLinks("Position Ledger", "Reference ID", showPositionLedgerLink, detailView.accounts, detailView.positionLedgerReferenceId).map(item => <li>{item}</li>)}
                        </ul>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <FieldGroupReadOnly id={"filterStartTime"} label={"Filter Start Date/Time"} value={!!detailView.filterStartTime ? detailView.filterStartTime.toLocaleString() : null} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FieldGroupReadOnly id={"filterEndTime"} label={"Filter End Date/Time"} value={!!detailView.filterEndTime ? detailView.filterEndTime.toLocaleString() : null} />
                            </Col>
                        </Row>
                    </Col>
                </Row>}
            </div>
        )
    }

    const executionsLink = (accounts, symbol, fromDate, toDate) => {
        let baseLink = "/order-search/executions?"
        let queries = {
            "accounts": accounts,
            "instrumentId": symbol,
            "fromDate": fromDate,
            "toDate": toDate,
        }
        return baseLink + querystring.stringify(queries)
    }

    const buildPositionLinks = (linkText, additionalText, showLink, accounts, asOfTime) => {
        let links = []
        accounts.forEach((acc) => {
            const parts = acc.split('/');
            let displayName = parts[parts.length - 1];
            if (_.isEmpty(displayName)) { displayName = acc }
            links.push(alertLink(linkText + " for " + displayName, additionalText, showLink, positionsLink(acc, asOfTime)))
        })

        return links
    }

    const positionsLink = (account, asOfTime) => {
        let baseLink = "/" + account + "/positions?"
        let queries = {
            "asOfTime": asOfTime,
        }
        return baseLink + querystring.stringify(queries)
    }

    const timeAndSalesLink = (accounts, symbol, fromDate, toDate) => {
        let baseLink = "/timeandsales?"
        let queries = {
            "accounts": accounts,
            "instrumentId": symbol,
            "fromDate": fromDate,
            "toDate": toDate,
        }
        return baseLink + querystring.stringify(queries)
    }

    const buildPositionLedgerLinks = (linkText, additionalText, showLink, accounts, refId) => {
        let links = []
        accounts.forEach((acc) => {
            const parts = acc.split('/');
            let displayName = parts[parts.length - 1];
            if (_.isEmpty(displayName)) { displayName = acc }
            links.push(alertLink(linkText + " for " + displayName, additionalText, showLink, positionLedgerLink(acc, refId)))
        })
        
        return links
    }

    const positionLedgerLink = (account, refId) => {
        let baseLink = "/" + account + "/ledger/position-ledger?"
        let queries = {
            "referenceId": refId,
        }
        return baseLink + querystring.stringify(queries)
    }

    const columns = [
        {
            Header: "Key",
            accessor: "key",
            sortable: false
        },
        {
            Header: "Value",
            accessor: "value",
            sortable: false
        },
    ];

    return (
        <ConfirmDialog size='lg' className='alert-details-dialog'
            show={!isNullOrUndefined(detailView)}
            cancel={onCancel}
            proceed={() => onSaveNotes(notes)}
            confirmation={
                <Container>
                    <Row>
                        <Col lg={6} xs={6} md={6}>
                            <FieldGroupReadOnly id={"venue"} label={"Venue"} value={detailView.venue} />
                        </Col>
                        <Col lg={6} xs={6} md={6}>
                            <FieldGroupReadOnly id={"alertName"} label={"Alert Name"} value={detailView.alertName} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FieldGroupReadOnly id={"description"} label={"Description"} value={detailView.description} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FieldGroupReadOnly id={"symbol"} label={"Symbol"} value={
                                <a href={`/instruments/${encodeURIComponent(detailView.symbol)}`}>
                                    <i><ins>{detailView.symbol}</ins></i>
                                </a>
                            } />
                        </Col>

                        <Col>
                            <FieldGroupReadOnly id={"tradeId"} label={"Trade ID"} value={
                                <a href={`/timeandsales/${encodeURIComponent(detailView.tradeId)}/details`}>
                                    <i><ins>{detailView.tradeId}</ins></i>
                                </a>
                            } />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: "10px" }}>
                        <Col>
                            <FieldGroupReadOnly id={"firm"} label={"Firm"} value={
                                <a href={"/" + detailView.firm}>
                                    <i><ins>{detailView.firm}</ins></i>
                                </a>
                            } />
                        </Col>

                        <Col>
                            <div>
                                <FormLabel>Accounts</FormLabel>
                                <div>{!!detailView.accounts && listAccount(detailView.accounts)}</div>
                            </div>
                        </Col>
                    </Row>
                    {listLinks(detailView)}
                    <Row>
                        <Col>
                            <FormLabel>Metadata</FormLabel>
                            <DataGrid
                                filterable={false}
                                sortable={false}
                                showPagination={false}
                                data={Array.from(detailView.metadata, ([value, key]) => ({ key, value }))}
                                columns={columns}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DropdownListGroup id="notesEditHistory" name="notesEditHistory" label="Notes + Edit History"
                                onChange={(e) => setNotes(e.content)}
                                data={detailView.notesEditHistory.map((notes, idx) => {
                                    const maxLen = 50;
                                    const idxStr = (idx + 1).toString();
                                    return {
                                        name: idxStr + ") " + (notes.length > maxLen ? notes.substring(0, maxLen - 3) + "..." : notes),
                                        content: notes,
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FieldGroup id="notes" name="notes" as="textarea"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                onBlur={(e) => setNotes(e.target.value)}
                                help={"This is a free-form field for compliance notes regarding the alert. Historical edits can be selected above."}
                            />
                        </Col>
                    </Row>
                </Container>
            }
            options={{
                title: "View Alert " + detailView.id,
                okButtonText: notes !== detailView.notes ? "Save Notes" : null,
            }}
        />
    )
}

export default AlertsDetail;