import JobService from "../services/JobService";
import Notification from "../modules/notifications";
import {
    FETCH_CRONS_FAILED,
    FETCH_CRONS_START,
    FETCH_CRONS_SUCCEED,
    FETCH_JOBS_FAILED,
    FETCH_JOBS_START,
    FETCH_JOBS_SUCCEED,
    FETCH_PODS_FAILED,
    FETCH_PODS_START,
    FETCH_PODS_SUCCEED,
    FETCH_VERSIONS_FAILED,
    FETCH_VERSIONS_START,
    FETCH_VERSIONS_SUCCEED,
    STREAM_LOGS_START,
    STREAM_LOGS_UPDATE,
    STREAM_LOGS_SUCCEED,
    STREAM_LOGS_FAILED,
    SET_CRON_DATE,
    SET_LOG_FROM_PREVIOUS_TERMINATION,
} from "../constants/JobTypes";

const {
    ListPodsRequest,
    ListJobsRequest,
    ListCronJobsRequest,
    StreamLogsRequest,
    ListEp3VersionsRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/jobs/v1beta1/jobs_api_pb.js");

export const ListCronJobsStart = () => ({
    type: FETCH_CRONS_START,
});

export const ListCronJobsSucceed = (crons) => ({
    type: FETCH_CRONS_SUCCEED,
    payload: {crons: crons},
});

export const ListCronJobsFailed = (error) => ({
    type: FETCH_CRONS_FAILED,
    payload: error,
});

export const ListJobsStart = () => ({
    type: FETCH_JOBS_START,
});

export const ListJobsSucceed = (jobs) => ({
    type: FETCH_JOBS_SUCCEED,
    payload: {jobs: jobs},
});

export const ListJobsFailed = (error) => ({
    type: FETCH_JOBS_FAILED,
    payload: error,
});

export const ListPodsStart = () => ({
    type: FETCH_PODS_START,
});

export const ListPodsSucceed = (pods) => ({
    type: FETCH_PODS_SUCCEED,
    payload: {pods: pods},
});

export const ListPodsFailed = (error) => ({
    type: FETCH_PODS_FAILED,
    payload: error,
});

export const ListEp3VersionsStart = () => ({
    type: FETCH_VERSIONS_START,
});

export const ListEp3VersionsSucceed = (versions) => ({
    type: FETCH_VERSIONS_SUCCEED,
    payload: {versions: versions},
});

export const ListEp3VersionsFailed = (error) => ({
    type: FETCH_VERSIONS_FAILED,
    payload: error,
});

export const StreamLogsStart = (name) => ({
    type: STREAM_LOGS_START,
    payload: name,
});

export const StreamLogsUpdate = (log) => ({
    type: STREAM_LOGS_UPDATE,
    payload: log,
});

export const StreamLogsSucceed = () => ({
    type: STREAM_LOGS_SUCCEED,
});

export const StreamLogsFailed = (error) => ({
    type: STREAM_LOGS_FAILED,
    payload: error,
});

export const ListPods = () => {
    return (dispatch) => {
        dispatch(ListPodsStart());

        const cb = (err, resp) => {
            if (resp) {
                let podsList = resp.getPodsList().map((item) => {
                    return item.toObject();
                });

                dispatch(ListPodsSucceed(podsList))
            }

            if (err) {
                dispatch(ListPodsFailed(err));
            }

        };

        JobService.listPods(new ListPodsRequest(), cb);
    }
}

export const ListJobs = () => {
    return (dispatch) => {
        dispatch(ListJobsStart());

        const cb = (err, resp) => {
            if (resp) {
                let jobsList = resp.getJobsList().map((item) => {
                    return item.toObject();
                });

                dispatch(ListJobsSucceed(jobsList))
            }

            if (err) {
                dispatch(ListJobsFailed(err));
            }

        };

        JobService.listJobs(new ListJobsRequest(), cb);
    }
}

export const ListCrons = () => {
    return (dispatch) => {
        dispatch(ListCronJobsStart());

        const cb = (err, resp) => {
            if (resp) {
                let cronsList = resp.getCronJobsList().map((item) => {
                    return item.toObject();
                });

                dispatch(ListCronJobsSucceed(cronsList))
            }

            if (err) {
                dispatch(ListCronJobsFailed(err));
            }

        };

        JobService.listCronJobs(new ListCronJobsRequest(), cb);
    }
}

export const ListEp3Versions = () => {
    return (dispatch) => {
        dispatch(ListEp3VersionsStart());

        const cb = (err, resp) => {
            if (resp) {
                const imagesList = resp.getImagesList().map((img) => String(img));

                dispatch(ListEp3VersionsSucceed(imagesList))
            }

            if (err) {
                dispatch(ListEp3VersionsFailed(err));
            }

        };

        JobService.listEp3Versions(new ListEp3VersionsRequest(), cb);
    }
}

export const StreamLogs = (name, fromPreviousTermination) => {
    return (dispatch) => {
        dispatch(StreamLogsStart(name));
        if (name === "") {
            dispatch(StreamLogsSucceed());
            return
        }
        const onData = (data) => dispatch(StreamLogsUpdate(data.getFilechunk()));
        const onStatus = (sts) => {
            if (sts.code !== 0) {
                Notification.error("Log stream error: " + sts.details);
            }
        };
        const onEnd = () => dispatch(StreamLogsSucceed());
        const onErr = (err) => dispatch(StreamLogsFailed(err));
        let req = new StreamLogsRequest();
        req.setName(name);
        req.setStayOpen(true);
        req.setFromPreviousTermination(!!fromPreviousTermination);
        JobService.streamLogs(req, onData, onStatus, onEnd, onErr);
    }
}

export const SetCronDate = (SetCronDate) => ({
    payload: SetCronDate,
    type: SET_CRON_DATE,
});

export const SetLogFromPreviousTermination = (SetLogFromPreviousTermination) => ({
    payload: SetLogFromPreviousTermination,
    type: SET_LOG_FROM_PREVIOUS_TERMINATION,
});