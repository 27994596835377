import ApiCall from "../modules/apiCall";
import { apiUrl } from "../modules/util";

const {
  SetCommissionRequest,
  DeleteCommissionRequest,
  ListCommissionsRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");

const {
  AdminAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");

const client = new AdminAPIClient(apiUrl(), null, null);

export class CommissionService {
  static setCommission(data, cb) {
    var req = new SetCommissionRequest();
    req.setCommission(data);
    ApiCall(client, "setCommission", req, cb);
  }

  static deleteCommission(rule, cb) {
    var req = new DeleteCommissionRequest();
    req.setRule(rule);
    ApiCall(client, "deleteCommission", req, cb);
  }

  static listCommissions(cb) {
    var req = new ListCommissionsRequest();
    ApiCall(client, "listCommissions", req, cb);
  }
}