import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import { validateField, STRING_TYPE } from '../../instrumentFormUtils';
import FieldGroup from "../../../core/form/FieldGroup";
import { FormModes } from "../../../../constants/enumerations"
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";

export const ForexInfo = ({
    values,
    setFieldValue,
    errors,
    mode,
    metadata,
}) => {
    const [open, setOpen] = useState(false);
    const isViewing = mode === FormModes.view;

    return (
        <React.Fragment>
            <div onClick={() => setOpen(!open)}>
                <Row className="accordian-style">
                    <Col lg={11} xs={11} md={11}>
                        <h6><strong>Forex Information</strong></h6>
                    </Col>
                    <Col>
                        <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
                    </Col>
                </Row>
            </div>
            <Collapse in={open}>
                <div className="collapse-content">
                    <Row>
                        <Col lg={3} xs={3} md={3}>
                            {!isViewing && <FieldGroup
                                id="fxBaseCurrency"
                                name="fxBaseCurrency"
                                as="select"
                                label="Base Currency"
                                isRequired={true}
                                value={values.fxBaseCurrency}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={(e) => setFieldValue("fxBaseCurrency", e.target.value)}
                                options={
                                    metadata && metadata.currencies
                                        ? Object.keys(metadata.currencies)
                                        : []
                                }
                            />}
                            {!!isViewing && <FieldGroupReadOnly
                                id="fxBaseCurrency"
                                label="Base Currency"
                                value={values.fxBaseCurrency}
                            />}
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            {!isViewing && <FieldGroup
                                id="fxQuoteCurrency"
                                name="fxQuoteCurrency"
                                as="select"
                                label="Quote Currency"
                                isRequired={true}
                                value={values.fxQuoteCurrency}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                onChange={(e) => setFieldValue("fxQuoteCurrency", e.target.value)}
                                options={
                                    values && !!values.basecurrency
                                        ? [values.basecurrency]
                                        : []
                                }
                            />}
                            {!!isViewing && <FieldGroupReadOnly
                                id="fxQuoteCurrency"
                                label="Quote Currency"
                                value={values.fxQuoteCurrency}
                            />}
                        </Col>
                    </Row>
                </div>
            </Collapse>

        </React.Fragment>
    )
}

export const ValidateForexInfo = (values, errors) => {
    errors = validateField(values, errors, "fxBaseCurrency", "Base Currency", STRING_TYPE);
    errors = validateField(values, errors, "fxQuoteCurrency", "Quote Currency", STRING_TYPE);
    return errors;
}