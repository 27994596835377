import {
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCEED,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCT_START,
  FETCH_PRODUCT_SUCCEED,
  FETCH_PRODUCT_FAILED,
  CLEAN_PRODUCT,
  EDIT_PRODUCT_CHANGED,
  CREATE_PRODUCT_STATE,
  SET_PRODUCT_PROCESS_NAME,
  SYNC_TRADING_INFO,
  SYNC_ADDITIONAL_INFO,
  SYNC_CROSS_ORDER_INFO,
  SYNC_SETTLEMENT_PRICE_CALC_INFO,
  SYNC_TRADE_DAY_ROLL_SCHEDULE,
} from "../constants/productTypes";

import Product from "../entities/Product";
import ProductFormItem from "../entities/dto/ProductFormItem";
import Notification from "../modules/notifications";
import ProductService from "../services/ProductService";
import { getFirm } from "../services/FirmService";
import {inTest} from "../modules/util";

export function fetchProducts() {
  return (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS_START });

    const cb = (err, response) => {
      if (response) {
        var productList = response.getProductsList().map((product) => {
          return new Product(product);
        });
        dispatch({ type: FETCH_PRODUCTS_SUCCEED, payload: productList });
      }

      if (err) {
        dispatch({ type: FETCH_PRODUCTS_FAILED });
        Notification.error("Cannot get list of products.");
      }
    };

    ProductService.getAll(cb);
  };
}

export function fetchProduct(id) {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_PRODUCT_START });

    try {
      let response = await ProductService.get2(id);
      if (response) {
        const product = new ProductFormItem(new Product(response.getProduct()));
        let state = getState();
        product.firm = await getFirm(state, product.clearingHouse);
        dispatch({ type: FETCH_PRODUCT_SUCCEED, payload: product });
      }
    } catch (err) {
      if (!inTest()) console.error(err);
      dispatch({ type: FETCH_PRODUCT_FAILED });
      Notification.error("Cannot get product information");
    }
  };
}

export const CleanProduct = () => ({
  type: CLEAN_PRODUCT,
});

export const EditProductChanged = (key, value) => ({
  type: EDIT_PRODUCT_CHANGED,
  key: key,
  value: value,
});

export const IsCreatingProduct = (isCreatingProduct) => ({
  type: CREATE_PRODUCT_STATE,
  value: isCreatingProduct,
});

export const SetProductProcessName = (processName) => ({
  type: SET_PRODUCT_PROCESS_NAME,
  value: processName,
});

export const SetSyncAdditionalInfo = (shouldSync) => ({
  type: SYNC_ADDITIONAL_INFO,
  value: shouldSync,
});

export const SetSyncTradingInfo = (shouldSync) => ({
  type: SYNC_TRADING_INFO,
  value: shouldSync,
});

export const SetSyncTradeDayRollSchedule = (shouldSync) => ({
  type: SYNC_TRADE_DAY_ROLL_SCHEDULE,
  value: shouldSync,
});

export const SetSyncCrossOrderInfo = (shouldSync) => ({
  type: SYNC_CROSS_ORDER_INFO,
  value: shouldSync,
});

export const SetSyncSettlementPriceCalcInfo = (shouldSync) => ({
  type: SYNC_SETTLEMENT_PRICE_CALC_INFO,
  value: shouldSync,
});

