import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import _ from 'lodash';
import '../core/data-grid/Pagination.css'
import { Button } from "react-bootstrap";
import OrderDetails from '../order-history/OrderDetails'
import {isArrayWithValues, isNullOrUndefined} from "../../modules/util";
import { GridActionViewHistory } from '../core/data-grid/GridAction';
import { useMemo } from 'react';
import { GridActionMenu } from '../core/menu-items/menu-items';
import usePreserveGridColsLayout from '../hooks/usePreserveGridColsLayout';

const OrderSearchDataGrid = ({
  data,
  onPageChange,
  paginationComponent,
  cancelOrder,
  updateOrderViewState,
  viewOrderHistory,
  showOrderDetails,
  orderData,
  onPageSizeChange
}) => {
  const [ref, setRef] = useState();

  const gridCtxMenu = useMemo(() => GridActionMenu, []);
  const { columnsLayout, saveColumnsLayout } = usePreserveGridColsLayout("OrderSearchDGColumnsLayout");

  const onColumnResize = (newResized, event) => {
    if (isArrayWithValues(newResized)) {
      newResized.map((column) => {
        columnsLayout[column.id] = column.value;
      });
    }

    saveColumnsLayout(columnsLayout);
  }

  const getColumnWidth = (id) => {
    return columnsLayout[id] ? columnsLayout[id] : undefined;
  }

  const cancelButtonForIds = (ids) => {
    return <Button variant="buttonActions" size="sm" onClick={() => cancelOrder(ids)} title="Cancel">
      <i className="fa fa-fw fa-times-circle orange-icon" />
    </Button>
  }

  const cancelButton = (e) => {
    if (e.row.openQty.toString() !== "0") {
      return cancelButtonForIds([e.value]);
    }
    return "";
  }

  const viewOrder = (e) => {
    return (<Button variant="buttonActions" size="sm" onClick={() => { orderPopup(e) }} title="View">
      <i className="fa fa-fw fa-eye orange-icon" />
    </Button>)
  }

  const orderPopup = (e) => {
    updateOrderViewState(true, e.row._original);
  }

  const onDialogClose = () => {
    updateOrderViewState(false);
  }

  const columns = [
    {
      Header: 'RECEIVE TIME',
      id: 'createTime',
      accessor: 'createTime',
      minWidth: 180,
      sortable: false,
      width: getColumnWidth("createTime"),
    },
    {
      Header: 'TRADE DATE',
      id: 'transactTradeDate',
      accessor: 'transactTradeDate',
      minWidth: 100,
      sortable: false,
      width: getColumnWidth("transactTradeDate"),
    },
    {
      Header: 'ORD STATUS',
      id: 'orderStatus',
      accessor: d => d.orderStatus + (!!d.text ? " (" + d.text + ")": ""),
      minWidth: 120,
      sortable: false,
      width: getColumnWidth("orderStatus"),
    },
    {
      Header: 'SYMBOL',
      accessor: 'symbol',
      minWidth: 100,
      sortable: false,
      width: getColumnWidth("symbol"),
    },
    {
      Header: 'SUB TYPE',
      accessor: 'symbolsubtype',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("symbolsubtype"),
    },
    {
      Header: 'SIDE',
      accessor: 'side',
      minWidth: 50,
      sortable: false,
      width: getColumnWidth("side"),
    },
    {
      Header: 'ORD QTY',
      accessor: 'orderQty',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("orderQty"),
    },
    {
      Header: 'CASH QTY',
      accessor: 'cashOrderQty',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("cashOrderQty"),
    },
    {
      Header: 'ORD TYPE',
      id: 'ordType',
      accessor: d => {
        let content = d.ordType;
        let execInsts = [];
        if (!!d.allOrNone) execInsts.push("AllOrNone");
        if (!!d.strictLimit) execInsts.push("StrictLimit");
        if (!!d.bestLimit) execInsts.push("BestLimit");
        if (!!d.immediatelyExecutableLimit) execInsts.push("ImmediatelyExecutableLimit");
        if (!!d.participateDontInitiate) execInsts.push("ParticipateDontInitiate");

        if (execInsts.length > 0) {
          content += " (" + execInsts.join(", ") + ")"
        }
        return content;
      },
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("ordType"),
    },
    {
      Header: "TIF",
      accessor: "timeInForce",
      minWidth: 70,
      sortable: false,
      width: getColumnWidth("timeInForce"),
    },
    {
      Header: 'LIMIT PX',
      id: 'price',
      accessor: d => d.ordType && _.includes(d.ordType.toLowerCase(), 'limit') ? d.price : '',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("price"),
    },
    {
      Header: 'STOP PX',
      id: 'stopPrice',
      accessor: d => d.ordType && _.includes(d.ordType.toLowerCase(), 'stop') ? d.stopPrice : '',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("stopPrice"),
    },
    {
      Header: 'ORD CAPACITY',
      accessor: 'orderCapacity',
      minWidth: 100,
      sortable: false,
      width: getColumnWidth("orderCapacity"),
    },
    {
      Header: 'FIRM',
      accessor: 'participant_firm',
      minWidth: 100,
      sortable: false,
      width: getColumnWidth("participant_firm"),
    },
    {
      Header: 'ACCOUNT',
      accessor: 'account',
      minWidth: 100,
      sortable: false,
      width: getColumnWidth("account"),
    },
    {
      Header: 'USER',
      accessor: 'participant',
      minWidth: 100,
      sortable: false,
      width: getColumnWidth("participant"),
    },
    {
      Header: 'OPEN QTY',
      accessor: 'openQty',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("openQty"),
    },
    {
      Header: 'CUM QTY',
      accessor: 'cumQty',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("cumQty"),
    },
    {
      Header: 'AVG PX',
      accessor: 'avgPx',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("avgPx"),
    },
    {
      Header: 'ORDER ID',
      accessor: 'orderid',
      minWidth: 100,
      sortable: false,
      width: getColumnWidth("orderid"),
    },
    {
      Header: 'EXCH CROSS ID',
      accessor: 'hostcrossid',
      minWidth: 110,
      sortable: false,
      width: getColumnWidth("hostcrossid"),
    },
    {
      Header: () => {
        const selections =
            ref &&
            ref.state &&
            ref.state.selection &&
            ref.state.selection.size > 0 ? Array.from(ref.state.selection).filter(item => !isNullOrUndefined(item)) : [];
        if (!selections || !Array.isArray(selections) || selections.length <= 0) return '';
        return cancelButtonForIds(selections);
      },
      accessor: 'orderid',
      sortable: false,
      maxWidth: 45,
      minWidth: 45,
      width: getColumnWidth("orderid"),
      Cell: e => { return cancelButton(e) },
    },
    {
      Header: '',
      accessor: 'orderid',
      sortable: false,
      maxWidth: 45,
      minWidth: 45,
      Cell: e => { return viewOrder(e) },
    },
    {
      Header: '',
      accessor: 'orderid',
      sortable: false,
      maxWidth: 45,
      minWidth: 45,
      Cell: field => {
        return <GridActionViewHistory contextMenu={gridCtxMenu} onClick={(e, actionContext) => viewOrderHistory(field.value, actionContext)} />
      },
    }
  ];
  return (
    <div>
      <DataGrid
        ref={(r) => {if (!!r && !ref) setRef(r)}}
        data={data}
        columns={columns}
        filterable={false}
        selectable={true}
        manual={true}
        autoFillHeight={true}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onResizedChange={onColumnResize}
        PaginationComponent={paginationComponent}
      >
      </DataGrid>
      {showOrderDetails &&
        <OrderDetails
          orderData={orderData}
          onDialogClose={onDialogClose}
        />
      }
    </div>
  )
};

OrderSearchDataGrid.propTypes = {
  data: PropTypes.array.isRequired
};

export default OrderSearchDataGrid;
