import React from 'react';
import PropTypes from 'prop-types';
import './Loader.css';

const LoaderIcon = ({ show }) => {
  if (!show) return null;
  return (
    <React.Fragment>
      <div className="loader-icon"></div>
    </React.Fragment>
  )
};

LoaderIcon.defaultProps = {
  show: false
}

LoaderIcon.propTypes = {
  show: PropTypes.bool.isRequired
};

export default LoaderIcon;