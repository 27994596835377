
import React, { useMemo } from 'react';
import { Row, Col } from "react-bootstrap";
import DropdownListGroup from '../form/DropdownListGroup';
import { FormModes } from '../../../constants/enumerations';
import { useTimeByInterval } from '../../hooks/useTimeByInterval';
import { TRADE_DAY_ROLL_SCHEDULE_TYPE_CONFIGURE_BY_DAY, TRADE_DAY_ROLL_SCHEDULE_TYPE_SAME_EVERY_DAY, TRADE_DAY_ROLL_SCHEDULE_TYPE_UNSET } from '../../../constants/tradeDayRollScheduleTypes';

const TradeDayRollSchedule = ({
  values,
  setFieldValue,
  errors,
  mode,
}) => {
  const isViewing = mode === FormModes.view;
  const scheduleType = useMemo(() => [
    TRADE_DAY_ROLL_SCHEDULE_TYPE_UNSET,
    TRADE_DAY_ROLL_SCHEDULE_TYPE_SAME_EVERY_DAY,
    TRADE_DAY_ROLL_SCHEDULE_TYPE_CONFIGURE_BY_DAY,
  ], []);

  const [timeData] = useTimeByInterval("00:00", "23:59", 1);

  values.tradeDayRollSchedule = values.tradeDayRollSchedule || {};

  return (
    <>
      <Row>
        <Col lg={3} xs={3} md={3}>
          <DropdownListGroup
            id="scheduleType"
            name="scheduleType"
            label="Schedule Type"
            value={values.tradeDayRollSchedule.scheduleType ? values.tradeDayRollSchedule.scheduleType : TRADE_DAY_ROLL_SCHEDULE_TYPE_UNSET}
            errors={errors}
            onChange={(e) => setFieldValue("tradeDayRollSchedule.scheduleType", e)}
            data={scheduleType}
            plaintext={isViewing}
          />
        </Col>

        {values.tradeDayRollSchedule.scheduleType && values.tradeDayRollSchedule.scheduleType === TRADE_DAY_ROLL_SCHEDULE_TYPE_SAME_EVERY_DAY &&
          <Col lg={2} xs={2} md={2}>
            <DropdownListGroup id="cutoffTime" name="cutoffTime" label="Cutoff Time"
              onChange={(e) => { setFieldValue("tradeDayRollSchedule.cutoffTime", e.value) }}
              selectIcon={<span className="fas fa-clock" />}
              data={timeData}
              value={values.tradeDayRollSchedule.cutoffTime}
              valueField={"value"}
              textField={"label"}
              plaintext={isViewing}
            />
          </Col>}

        {values.tradeDayRollSchedule.scheduleType && values.tradeDayRollSchedule.scheduleType === TRADE_DAY_ROLL_SCHEDULE_TYPE_CONFIGURE_BY_DAY && <>
          <Col lg={2} xs={2} md={2}>
            <DropdownListGroup id="monCutoffTime" name="monCutoffTime" label="MON Cutoff Time"
              onChange={(e) => { setFieldValue("tradeDayRollSchedule.monCutoffTime", e.value) }}
              selectIcon={<span className="fas fa-clock" />}
              data={timeData}
              value={values.tradeDayRollSchedule.monCutoffTime}
              valueField={"value"}
              textField={"label"}
              plaintext={isViewing}
            />
          </Col>

          <Col lg={2} xs={2} md={2}>
            <DropdownListGroup id="tueCutoffTime" name="tueCutoffTime" label="TUE Cutoff Time"
              onChange={(e) => { setFieldValue("tradeDayRollSchedule.tueCutoffTime", e.value) }}
              selectIcon={<span className="fas fa-clock" />}
              data={timeData}
              value={values.tradeDayRollSchedule.tueCutoffTime}
              valueField={"value"}
              textField={"label"}
              plaintext={isViewing}
            />
          </Col>

          <Col lg={2} xs={2} md={2}>
            <DropdownListGroup id="wedCutoffTime" name="wedCutoffTime" label="WED Cutoff Time"
              onChange={(e) => { setFieldValue("tradeDayRollSchedule.wedCutoffTime", e.value) }}
              selectIcon={<span className="fas fa-clock" />}
              data={timeData}
              value={values.tradeDayRollSchedule.wedCutoffTime}
              valueField={"value"}
              textField={"label"}
              plaintext={isViewing}
            />
          </Col>

          <Col lg={2} xs={2} md={2}>
            <DropdownListGroup id="thuCutoffTime" name="thuCutoffTime" label="THU Cutoff Time"
              onChange={(e) => { setFieldValue("tradeDayRollSchedule.thuCutoffTime", e.value) }}
              selectIcon={<span className="fas fa-clock" />}
              data={timeData}
              value={values.tradeDayRollSchedule.thuCutoffTime}
              valueField={"value"}
              textField={"label"}
              plaintext={isViewing}
            />
          </Col>

          <Col lg={2} xs={2} md={2}>
            <DropdownListGroup id="friCutoffTime" name="friCutoffTime" label="FRI Cutoff Time"
              onChange={(e) => { setFieldValue("tradeDayRollSchedule.friCutoffTime", e.value) }}
              selectIcon={<span className="fas fa-clock" />}
              data={timeData}
              value={values.tradeDayRollSchedule.friCutoffTime}
              valueField={"value"}
              textField={"label"}
              plaintext={isViewing}
            />
          </Col>

          <Col lg={2} xs={2} md={2}>
            <DropdownListGroup id="satCutoffTime" name="satCutoffTime" label="SAT Cutoff Time"
              onChange={(e) => { setFieldValue("tradeDayRollSchedule.satCutoffTime", e.value) }}
              selectIcon={<span className="fas fa-clock" />}
              data={timeData}
              value={values.tradeDayRollSchedule.satCutoffTime}
              valueField={"value"}
              textField={"label"}
              plaintext={isViewing}
            />
          </Col>

          <Col lg={2} xs={2} md={2}>
            <DropdownListGroup id="sunCutoffTime" name="sunCutoffTime" label="SUN Cutoff Time"
              onChange={(e) => { setFieldValue("tradeDayRollSchedule.sunCutoffTime", e.value) }}
              selectIcon={<span className="fas fa-clock" />}
              data={timeData}
              value={values.tradeDayRollSchedule.sunCutoffTime}
              valueField={"value"}
              textField={"label"}
              plaintext={isViewing}
            />
          </Col>
        </>}
      </Row>
    </>
  );
}

export default TradeDayRollSchedule;