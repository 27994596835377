
import {
  FETCH_PARTICIPANT_GROUPS_START,
  FETCH_PARTICIPANT_GROUPS_SUCCEED,
  FETCH_PARTICIPANT_GROUPS_FAILED,
  FETCH_PARTICIPANT_GROUP_START,
  FETCH_PARTICIPANT_GROUP_SUCCEED,
  FETCH_PARTICIPANT_GROUP_FAILED,
  CLEAN_PARTICIPANT_GROUP,
  SAVE_PARTICIPANT_GROUP_START,
  SAVE_PARTICIPANT_GROUP_SUCCEED,
  SAVE_PARTICIPANT_GROUP_FAILED,
  CREATE_PARTICIPANT_GROUP_STATE,
  FETCH_PARTICIPANTS_LIST_START,
  FETCH_PARTICIPANTS_LIST_SUCCEED,
  FETCH_PARTICIPANTS_LIST_FAILED,
  CLEAN_PARTICIPANTS_LIST_GROUP
} from '../constants/participantGroupTypes';

export const FetchParticipantGroupsStart = () => ({
  type: FETCH_PARTICIPANT_GROUPS_START
});

export const FetchParticipantGroupsSucceed = (participantGroups) => ({
  type: FETCH_PARTICIPANT_GROUPS_SUCCEED,
  payload: participantGroups
});

export const FetchParticipantGroupsFailed = (error) => ({
  type: FETCH_PARTICIPANT_GROUPS_FAILED,
  payload: error
});

export const FetchParticipantGroupStart = () => ({
  type: FETCH_PARTICIPANT_GROUP_START
});

export const FetchParticipantGroupSucceed = (product) => ({
  type: FETCH_PARTICIPANT_GROUP_SUCCEED,
  payload: product
});

export const FetchParticipantGroupFailed = (error) => ({
  type: FETCH_PARTICIPANT_GROUP_FAILED,
  payload: error
});

export const CleanParticipantGroup = () => ({
  type: CLEAN_PARTICIPANT_GROUP
});

export const SaveParticipantGroupStart = () => ({
  type: SAVE_PARTICIPANT_GROUP_START
});

export const SaveParticipantGroupSucceed = () => ({
  type: SAVE_PARTICIPANT_GROUP_SUCCEED
});

export const SaveParticipantGroupFailed = (error) => ({
  type: SAVE_PARTICIPANT_GROUP_FAILED,
  payload: error
});

export const IsCreatingParticipantGroup = (isCreating) => ({
  type: CREATE_PARTICIPANT_GROUP_STATE,
  value: isCreating
});

export const FetchParticipantsListStart = () => ({
  type: FETCH_PARTICIPANTS_LIST_START,
});

export const FetchParticipantsListSucceed = (participants) => ({
  type: FETCH_PARTICIPANTS_LIST_SUCCEED,
  payload: participants
});

export const FetchParticipantsListFailed = () => ({
  type: FETCH_PARTICIPANTS_LIST_FAILED
});

export const CleanParticipantsListGroup = () => ({
  type: CLEAN_PARTICIPANTS_LIST_GROUP
});