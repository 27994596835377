export default class Fraction {
  static getFraction = (decimal) => {
    for(var denominator = 1; (decimal * denominator) % 1 !== 0; denominator++);
    return {numerator: decimal * denominator, denominator: denominator};
  }

  static reduce(numerator,denominator){
    var gcd = function gcd(a,b){
      return b ? gcd(b, a%b) : a;
    };
    gcd = gcd(numerator,denominator);
    return {numerator: numerator/gcd, denominator: denominator/gcd}
  }
}