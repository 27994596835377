import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProductForm from './ProductForm';
import ProductFormReadOnly from './ProductFormReadOnly';
import ProductFormItem from '../../entities/dto/ProductFormItem';

class ProductDetails extends Component {
  render() {
    const {
      onSubmitForm, 
      onBeforeSubmit,
      onDelete,
      mode,
      metadata,
      holidays,
    } = this.props;

    var form = null;

    switch (mode) {
      case formModes.view:
        form = <ProductFormReadOnly metadata={metadata}/>
        break;
      case formModes.edition:
      case formModes.creation:
      const isEditing = mode === formModes.edition;
        form = (
          <ProductForm
            title={isEditing ? 'Edit Product' : 'Create Product'}
            isEditing={isEditing}
            onSubmitForm={onSubmitForm}
            onBeforeSubmit={onBeforeSubmit}
            onDelete={onDelete}
            metadata={metadata}
            holidays={holidays}
          />
        );
        break;
      default:
        break;
    }
    return form;
  }
}

const formModes = {
  view: 'view',
  edition: 'edition',
  creation: 'creation'
}

ProductDetails.defaultProps = {
  item: new ProductFormItem()
}

ProductDetails.formModes = formModes;

ProductDetails.propTypes = {
  item: PropTypes.instanceOf(ProductFormItem),
  mode: PropTypes.oneOf(Object.keys(formModes)).isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default connect((state) => ({
  //isCreatingProduct: state.products.isCreatingProduct
}), null)(ProductDetails);