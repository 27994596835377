import React, { Component } from 'react';
import DropdownListGroup from '../components/core/form/DropdownListGroup';
import {
  convertEnumToDropdownList
} from '../modules/util';
import { InstrumentState } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb';

class InstrumentStateListContainer extends Component {
  state = {
    data: [],
    originalState: { id: 0, name: 'Pending' },
    newState: null,
    enabled: true
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedState !== this.props.selectedState) {
      if (this.state.data.length > 0) {
        const currentState = this.state.data.find(s => s.id === nextProps.selectedState);
        this.setState({ originalState: currentState });
      }
    }

    this.setState({ enabled: nextProps.enabled });

    if (nextProps.notAllowedInstrumentStates !== this.props.notAllowedInstrumentStates) {
      this.removeStates(nextProps.notAllowedInstrumentStates);
    }
  }

  componentDidMount() {
    this.loadStates();
  }

  loadStates = () => {
    const allData = convertEnumToDropdownList(InstrumentState);
    let data = [];
    if (Array.isArray(allData)) {
      allData.forEach(elem => {
        if (!Array.isArray(this.props.notAllowedInstrumentStates) || !this.props.notAllowedInstrumentStates.includes(elem.id)) {
          data.push(elem);
        }
      });
    }
    this.setState({ data: data, originalState: this.props.selectedState, newState: this.props.selectedState });
  }

  removeStates = (stateIds) => {
    if (this.state.data.length > 0) {
      for (var i = 0; i < stateIds.length; i++) {
        const currentState = this.state.data.find(s => s.id === stateIds[i]); //eslint-disable-line
        if (currentState) {
          const index = this.state.data.indexOf(currentState);
          if (index > -1) {
            var newList = [...this.state.data];
            newList.splice(index, 1);
            this.setState({ data: newList });
          }
        }
      }
    }
  }

  handlerChange = (event) => {
    const selectedState = event;
    if (this.state.originalState === null)
      this.setState({ ...this.state, originalState: selectedState });

    this.setState({ ...this.state, newState: selectedState });
    this.props.onChange(this.state.originalState, selectedState);
  }

  render() {
    return (
      <DropdownListGroup
        id="state"
        name="state"
        label="State"
        value={this.state.originalState ? this.state.originalState : null}
        onChange={(event) => this.handlerChange(event)}
        data={this.state.data}
        enabled={this.state.enabled}
      />
    )
  }
}

export default InstrumentStateListContainer;
