import {
    getExecutionTypeName, getOrdRejectReasonName,
} from '../../modules/mappers/enumsMapper';
import { parsePrice, convertDateToString } from '../../modules/util';
import OrderSearchGridItem from "./OrderSearchGridItem";

export default class ExecutionGridItem {
    constructor(execution, instrument) {
        if (execution) {
            const order = execution.getOrder();
            const priceScale = instrument.priceScale;
            const qtyScale = instrument.fractionalQtyScale;
            const ordItem = new OrderSearchGridItem(order, priceScale, qtyScale, execution)
            ordItem.execId = execution.getId();
            ordItem.lastTransactTime = convertDateToString(execution.getTransactTime().toDate(), "YYYYMMDD-HH:mm:ss.SSS");
            ordItem.fillQty = execution.getLastShares();
            ordItem.commissionNotionalCollected = parsePrice(execution.getCommissionNotionalCollected(), priceScale, qtyScale);
            ordItem.type = getExecutionTypeName(execution.getType());
            ordItem.aggressor = execution.getAggressor();
            ordItem.fillPx = parsePrice(execution.getLastPx(), priceScale);
            ordItem.orderRejectReason = execution.getOrderRejectReason();
            ordItem.orderRejectReasonText = order.getState() === 5 ? `(${getOrdRejectReasonName(ordItem.orderRejectReason)})` : '';
            ordItem.text = execution.getText();
            ordItem.tradeid = execution.getTradeId();
            ordItem.traceid = execution.getTraceId();
            return ordItem;
        }

        this.execId = null;
        this.aggressor = null;
        this.lastTransactTime = null;
        this.participant = null;
        this.account = null;
        this.side = null;
        this.symbol = null;
        this.timeInForce = null;
        this.orderCapacity = null;
        this.ordType = null;
        this.orderStatus = null;
        this.orderQty = null;
        this.minQty = null;
        this.openQty = null;
        this.cumQty = null;
        this.price = null;
        this.stopPrice = null;
        this.fillPx = null;
        this.clordid = null;
        this.orderid = null;
        this.tradeid = null;
        this.orderRejectReason = null;
        this.orderRejectReasonText = null;
        this.text = null;
        this.clientaccountid = null;
        this.clientparticipantid = null;
        this.symbolsubtype = null;
        this.cashOrderQty = null;
        this.collateralMemo = null;
        this.hostcrossid = null;
        this.goodTillTime = null;
        this.transactionBookedTime = null;
        this.lastTransactTime = null;
    }
}