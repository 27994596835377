import { CommissionService } from "../services/CommissionService"
import Commission from "../entities/Commission";
import {
  LIST_COMMISSION_START,
  LIST_COMMISSION_SUCCEED,
  LIST_COMMISSION_FAILED,
} from "../constants/commissionTypes";

export const ListCommissionsStart = () => ({
  type: LIST_COMMISSION_START,
});

export const ListCommissionSucceed = (commissions) => ({
  type: LIST_COMMISSION_SUCCEED,
  payload: commissions,
});

export const ListCommissionFailed = (error) => ({
  type: LIST_COMMISSION_FAILED,
  payload: error,
});


export const ListCommissions = () => {
  return (dispatch) => {
    dispatch(ListCommissionsStart());

    const cb = (err, resp) => {
      if (resp) {
        let commissionsList = resp.getCommissionsList().map((item) => {
          return new Commission(item);
        });

        dispatch(ListCommissionSucceed(commissionsList))
      }

      if (err) {
        dispatch(ListCommissionFailed());
      }

    };

    CommissionService.listCommissions(cb);
  }
}