import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../../../core/data-grid/DataGrid';
import '../../../core/data-grid/Pagination.css'
import { Button } from "react-bootstrap";
import MarketStatsDetail from "./MarketStatsDetail";
import Dialog from '../../../core/dialog';

const MarketStatsDataGrid = ({
  data,
  onPageChange,
  paginationComponent,
  onPageSizeChange,
  updateStatsViewState,
  showStatsDetail,
  marketStatsData
}) => {
  const [ref, setRef] = useState();

  const viewOrder = (e) => {
    return (<Button variant="buttonActions" size="sm" onClick={() => { orderPopup(e) }} title="View">
      <i className="fa fa-fw fa-eye orange-icon" />
    </Button>)
  }

  const orderPopup = (e) => {
    updateStatsViewState(true, e.row._original);
  }

  const onDialogClose = () => {
    updateStatsViewState(false);
  }

  const columns = [
    {
      Header: 'TRANSACTION TIME',
      id: 'transactionTime',
      accessor: 'transactionTime',
      minWidth: 180,
      sortable: false
    },
    {
      Header: 'OPEN PRICE',
      id: 'openPx',
      accessor: 'openPx',
      minWidth: 100,
      sortable: false
    },
    {
      Header: 'INDICATIVE OPEN PRICE',
      id: 'indicativeOpenPx',
      accessor: 'indicativeOpenPx',
      minWidth: 150,
      sortable: false
    },
    {
      Header: 'HIGH PRICE',
      id: 'highPx',
      accessor: 'highPx',
      minWidth: 100,
      sortable: false
    },
    {
      Header: 'LOW PRICE',
      id: 'lowPx',
      accessor: 'lowPx',
      minWidth: 100,
      sortable: false
    },
    {
      Header: 'CLOSE PRICE',
      id: 'closePx',
      accessor: 'closePx',
      minWidth: 100,
      sortable: false
    },
    {
      Header: 'LAST TRADE PRICE',
      id: 'lastTradePx',
      accessor: 'lastTradePx',
      minWidth: 100,
      sortable: false
    },
    {
      Header: 'LAST TRADE QTY',
      id: 'lastTradeQty',
      accessor: 'lastTradeQty',
      minWidth: 100,
      sortable: false
    },
    {
      Header: 'NOTIONAL VOLUME',
      id: 'notionalTraded',
      accessor: 'notionalTraded',
      minWidth: 100,
      sortable: false
    },
    {
      Header: 'SHARES TRADED',
      id: 'sharesTraded',
      accessor: 'sharesTraded',
      minWidth: 100,
      sortable: false
    },
    {
      Header: 'SETTLEMENT PRICE',
      id: 'settlementPx',
      accessor: 'settlementPx',
      minWidth: 100,
      sortable: false
    },
    {
      Header: 'SETTLEMENT CALC METHOD',
      id: 'settlementPriceCalculationMethod',
      accessor: d => !!d.settlementPriceCalculationMethod ? d.settlementPriceCalculationMethod.name : '',
      minWidth: 100,
      sortable: false
    },
    {
      Header: "OPEN INTEREST",
      id: 'openInterest',
      accessor: "openInterest",
      minWidth: 100,
      sortable: false
    },
    {
      Header: '',
      sortable: false,
      maxWidth: 45,
      minWidth: 45,
      Cell: e => { return viewOrder(e) },
    },
  ];
  return (
    <div>
      <DataGrid
        ref={(r) => { if (!!r && !ref) setRef(r) }}
        data={data}
        columns={columns}
        filterable={false}
        manual={true}
        autoFillHeight={true}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        PaginationComponent={paginationComponent}
      >
      </DataGrid>
      {showStatsDetail &&
        <Dialog
          show={true}
          title={"Market Stats Details"}
          onOk={() => onDialogClose()}
          onClose={() => onDialogClose()}
          okButtonText={"Ok"}
        >
          <MarketStatsDetail
            marketStatsData={marketStatsData}
          />
        </Dialog>
      }
    </div >
  )
};

MarketStatsDataGrid.propTypes = {
  data: PropTypes.array.isRequired
};

export default MarketStatsDataGrid;
