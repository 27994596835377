import ApiCall from "../modules/apiCall";
import { apiUrl } from "../modules/util";
const { NettingAPIClient } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/netting/v1beta1/netting_api_grpc_web_pb")
const { PerformResolutionRequest, UndoResolutionRequest } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/netting/v1beta1/netting_api_pb")

const apiClient = new NettingAPIClient(apiUrl(), null, null);

export default class NettingService {
    static performResolution(symbol, subType, cb) {
        let req = new PerformResolutionRequest()
        req.setSymbol(symbol)
        req.setSymbolSubType(subType)
        ApiCall(apiClient, "performResolution", req, cb)
    }

    static undoResolution(symbol, cb) {
        let req = new UndoResolutionRequest()
        req.setSymbol(symbol)
        ApiCall(apiClient, "undoResolution", req, cb)
    }
}