export const FETCH_INSTRUMENTS_START = 'FETCH_INSTRUMENTS_START';
export const FETCH_INSTRUMENTS_SUCCEED = 'FETCH_INSTRUMENTS_SUCCEED';
export const FETCH_INSTRUMENTS_FAILED = 'FETCH_INSTRUMENTS_FAILED';
export const FETCH_METADATA_START = 'FETCH_METADATA_START';
export const FETCH_METADATA_SUCCEED = 'FETCH_METADATA_SUCCEED';
export const FETCH_METADATA_FAILED = 'FETCH_METADATA_FAILED';
export const FETCH_HOLIDAY_CALENDARS_START = 'FETCH_HOLIDAY_CALENDARS_START';
export const FETCH_HOLIDAY_CALENDARS_SUCCEED = 'FETCH_HOLIDAY_CALENDARS_SUCCEED';
export const FETCH_HOLIDAY_CALENDARS_FAILED = 'FETCH_HOLIDAY_CALENDARS_FAILED';
export const CREATE_INSTRUMENT_STATE = 'CREATE_INSTRUMENT_STATE';
export const FETCH_INSTRUMENT_START = 'FETCH_INSTRUMENT_START';
export const FETCH_INSTRUMENT_SUCCEED = 'FETCH_INSTRUMENT_SUCCEED';
export const FETCH_INSTRUMENT_FAILED = 'FETCH_INSTRUMENT_FAILED';
export const CLEAN_INSTRUMENT = 'CLEAN_INSTRUMENT';
export const UPDATE_INSTRUMENT_STATE_START = 'UPDATE_INSTRUMENT_STATE_START';
export const UPDATE_INSTRUMENT_STATE_SUCCEED = 'UPDATE_INSTRUMENT_STATE_SUCCEED';
export const UPDATE_INSTRUMENT_STATE_FAILED = 'UPDATE_INSTRUMENT_STATE_FAILED';
export const UPDATE_INSTRUMENT_ITEM = 'UPDATE_INSTRUMENT_ITEM';
export const SET_STATE_WARNING_MESSAGE = 'SET_STATE_WARNING_MESSAGE';
export const SET_NOT_ALLOWED_STATES = 'SET_NOT_ALLOWED_STATES';
export const CLEAN_INSTRUMENTS_LIST = 'CLEAN_INSTRUMENTS_LIST';
export const CLEAN_INSTRUMENT_IDS_LIST = 'CLEAN_INSTRUMENT_IDS_LIST';
export const SET_INSTRUMENT_IDS_LIST = 'SET_INSTRUMENT_IDS_LIST';
export const REFRESH_INSTRUMENTS_SEARCH='REFRESH_INSTRUMENTS_SEARCH';
export const REMOVE_INSTRUMENTS = "REMOVE_INSTRUMENTS";
export const SET_INSTRUMENT_BOOKS = "SET_INSTRUMENT_BOOKS";
export const FETCH_INSTRUMENTS_REPORT_START = 'FETCH_INSTRUMENTS_REPORT_START';
export const FETCH_INSTRUMENTS_REPORT_SUCCEED = 'FETCH_INSTRUMENTS_REPORT_SUCCEED';
export const FETCH_INSTRUMENTS_REPORT_FAILED = 'FETCH_INSTRUMENTS_REPORT_FAILED';
export const FETCH_SEARCH_INSTRUMENT_STATS_START = 'FETCH_SEARCH_INSTRUMENT_STATS_START';
export const FETCH_SEARCH_INSTRUMENT_STATS_SUCCEED = 'FETCH_SEARCH_INSTRUMENT_STATS_SUCCEED';
export const FETCH_SEARCH_INSTRUMENT_STATS_FAILED = 'FETCH_SEARCH_INSTRUMENT_STATS_FAILED';
export const FETCH_SYMBOLS_START = 'FETCH_SYMBOLS_START';
export const FETCH_SYMBOLS_SUCCEED = 'FETCH_SYMBOLS_SUCCEED';
export const FETCH_SYMBOLS_FAILED = 'FETCH_SYMBOLS_FAILED';
