import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import * as toolTips from "../../../constants/instrumentToolTip";
import { FormModes } from "../../../constants/enumerations";
import FieldGroup from "../../core/form/FieldGroup";
import { validateField, NUMBER_TYPE } from '../instrumentFormUtils'
import * as Yup from "yup";
import CheckboxGroup from "../../core/form/CheckboxGroup";
import TimeDuration from  "../../../components/core/TimeDuration";
import ErrorLabel from "../../core/form/ErrorLabel";

export const CrossOrderInfo = ({
    values,
    setFieldValue,
    touched,
    handleBlur,
    handleChange,
    updateState,
    errors,
    mode
}) => {
    const [open, setOpen] = useState(false);
    const isViewing = mode === FormModes.view;

    const showErrorIfAnyRequiredFieldMissing = () => {
        const errMinimumQuantity = errors['blockTradeThreshold.minimumQuantity'];
        const errMinimumNotional = errors['blockTradeThreshold.minimumNotional'];

        let isSetOpen = !!errMinimumQuantity || !!errMinimumNotional;
        if (isSetOpen && !open) {
            setOpen(isSetOpen);
        }

        return isSetOpen;
    }

    return (
        <>
            <div onClick={() => setOpen(!open)}>
                <Row className="accordian-style">
                    <Col lg={8} xs={8} md={8}>
                        <h6>
                            <strong>Cross Order Information</strong>
                        </h6>
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                        {showErrorIfAnyRequiredFieldMissing() && <ErrorLabel text={"Required field(s) missing"} />}
                    </Col>
                    <Col>
                        <i className={`fa fa-fw ${open ? "fa-minus" : "fa-plus"} custom-plus`} />
                    </Col>
                </Row>
            </div>

            <Collapse in={open}>
                <div className="collapse-content">
                    <Row>
                        <Col lg={5} xs={5} md={5}>
                            <TimeDuration id="crossOrderRestingDuration" name="crossOrderRestingDuration" type="textarea" label="Cross Order Resting Duration"
                                tooltip={toolTips.CROSS_ORDER_RESTING_DURATION}
                                value={values.crossOrderRestingDuration}
                                errors={errors}
                                onChange={(value) => setFieldValue("crossOrderRestingDuration", value)}
                                onBlur={handleBlur}
                                plaintext={isViewing}
                                readonlyValue={!!values.crossOrderRestingDuration ? `${values.crossOrderRestingDuration} milliseconds`: ''}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                            <h6><strong>Instrument-Level Block Trade Thresholds</strong></h6>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={3} xs={3} md={3}>
                            <div style={{height:"100%"}}>
                                <div style={{top:"30%", position:"relative"}}>
                                <CheckboxGroup
                                    label="Enabled"
                                    checked={values.setBlockTradeThreshold}
                                    onClick={(e) => setFieldValue("setBlockTradeThreshold", e.target.checked)}
                                    disabled={isViewing}
                                    tooltip={toolTips.BLOCK_TRADE_CAN_EDIT}
                                />
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup id="blockTradeThreshold.minimumQuantity" name="blockTradeThreshold.minimumQuantity" type="textarea" label="Minimum Quantity"
                                tooltip={toolTips.BLOCK_TRADE_MINIMUM_QUANTITY}
                                value={!!values.blockTradeThreshold ? values.blockTradeThreshold.minimumQuantity : ""}
                                errors={errors}
                                touched={touched}
                                onChange={(e) => {
                                    setFieldValue("blockTradeThreshold.minimumQuantity", e.target.value)
                                }}
                                onBlur={handleBlur}
                                plaintext={isViewing}
                                disabled={!values.setBlockTradeThreshold}
                            />
                        </Col>
                        <Col lg={3} xs={3} md={3}>
                            <FieldGroup id="blockTradeThreshold.minimumNotional" name="blockTradeThreshold.minimumNotional" type="textarea" label="Minimum Notional"
                                tooltip={toolTips.BLOCK_TRADE_MINIMUM_NOTIONAL}
                                value={!!values.blockTradeThreshold ? values.blockTradeThreshold.minimumNotional : ""}
                                errors={errors}
                                touched={touched}
                                onChange={(e) => {
                                    setFieldValue("blockTradeThreshold.minimumNotional", e.target.value)
                                }}
                                onBlur={handleBlur}
                                plaintext={isViewing}
                                disabled={!values.setBlockTradeThreshold}
                            />
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </>
    );
};

export const ValidateCrossOrderInfo = (values, errors) => {
    errors = validateField(values, errors, "crossOrderRestingDuration", "Cross Order Resting Duration", NUMBER_TYPE, false, null, { greaterThanOrEqualTo: 0 });

    if (!!values.setBlockTradeThreshold)
        errors = validateField(values, errors, "blockTradeThreshold.minimumQuantity", "Block Trade Minimum Quantity", NUMBER_TYPE, !!values.setBlockTradeThreshold, null, { greaterThan: 0 });

    if (!!values.setBlockTradeThreshold)
        errors = validateField(values, errors, "blockTradeThreshold.minimumNotional", "Block Trade Minimum Notional", NUMBER_TYPE, !!values.setBlockTradeThreshold, null, { greaterThan: 0 });

    return errors;
}

export const CrossOrderInfoSchemaValidation = () => {
    return {
        nonTradable: Yup.boolean(),
        crossOrderRestingDuration: Yup.number().nullable()
            .typeError('Cross Order Resting Duration must be a `number` type')
            .min(0, "Cross Order Resting Duration must be greater than or equal to 0."),
    }
}
