import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import { validateField, getOptionText, NUMBER_TYPE, DATE_TYPE } from '../../instrumentFormUtils'
import DateGroup from "../../../core/form/DateGroup";
import { FormModes } from "../../../../constants/enumerations"
import FieldGroup from "../../../core/form/FieldGroup";


export const SwapInfoFsIrs = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  handleChange,
  errors,
  mode
}) => {
  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view ;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Swap Information</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>

      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Tenor" value={values.tenor} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="sideConvention" name="sideConvention" label="Side Convention" isRequired={true}
                value={values.sideConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("sideConvention", e.id)}
                data={InstrumentStaticData.SideConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="swapEffectiveDate" name="swapEffectiveDate" label="Swap Effective Date" isRequired={true}
                  value={values.swapEffectiveDate ? new Date(values.swapEffectiveDate) : null}
                  dropUp={true}
                  enabled={values.isPartialEditable}
                  errors={errors}
                  touched={touched}
                  onChange={(e) => setFieldValue("swapEffectiveDate", e)}
                  onBlur={handleBlur}
                  plaintext={isViewing}
                />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Effective Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.effectiveDateBusinessDayConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="maturityDate" name="maturityDate" label="Maturity Date"
                value={values.maturityDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("maturityDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Maturity Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.maturityDateBusinessDayConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="unadjustedEffectiveDate" name="unadjustedEffectiveDate" label="Unadjusted Effective Date"
                value={values.unadjustedEffectiveDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("unadjustedEffectiveDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly id="id" label="Roll and Payment Date Holiday Calendar List" value={values.rollAndPaymentDateHolidayCalendarsList ? values.rollAndPaymentDateHolidayCalendarsList.join(", ") : ""} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="unadjustedMaturityDate" name="unadjustedMaturityDate" label="Unadjusted Maturity Date"
                value={values.unadjustedMaturityDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("unadjustedMaturityDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Swap Effective Date Offset" value={values.effectiveDateOffset} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="forwardStartingPeriod" name="forwardStartingPeriod" type="textarea" label="Forward Starting Period"
                setFieldValue={setFieldValue}
                value={values.forwardStartingPeriod}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
        </div>
      </Collapse>

    </React.Fragment>
  )
}

export const ValidateSwapInfoFsIrs = (values, errors) => {
  errors = validateField(values, errors, "tenor", "Tenor", NUMBER_TYPE);
  errors = validateField(values, errors,  "sideConvention", "Side Convention", NUMBER_TYPE, values);
  errors = validateField(values, errors,  "swapEffectiveDate", "Swap Effective Date", DATE_TYPE);
  return validateField(values, errors,  "maturityDate", "Swap Maturity Date", DATE_TYPE);;
}