import { ADD_INSTRUMENTS_SCALES } from '../constants/orderTypes';
const initialState = {
    scales: {},
    qtyScales: {},
}

const instrumentsScales = (state = initialState, action) => {
    switch (action.type) {
        case ADD_INSTRUMENTS_SCALES:
            return {
                ...state,
                scales: { ...state.scales, ...action.payload },
                qtyScales: { ...state.qtyScales, ...action.qtyPayload },
            }

        default:
            return state;
    }
}

export default instrumentsScales;