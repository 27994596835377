import { v4 } from "uuid";
import ApiCall from "../modules/apiCall";
import ProtobufParser from "../modules/protobufParser";
import { apiUrl } from "../modules/util";
import { AdminAPIClient } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb";
import { TwoSidedBlockTrade, BlockTrade, Party } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { InsertTwoSidedBlockTradeRequest } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js';

const client = new AdminAPIClient(apiUrl(), null, null);

export default class BlockTradeService {

  static async insertTwoSidedBlockTrade({ symbol, qty, price, buyer, seller, broker, transactionBookedTime }, cb) {
    const request = new InsertTwoSidedBlockTradeRequest();
    const twoSidedBlockTrade = new TwoSidedBlockTrade();

    const blockTrade = new BlockTrade();
    blockTrade.setSymbol(symbol.name);
    blockTrade.setPrice(price);
    blockTrade.setQuantity(qty);

    if (!!transactionBookedTime) {
      const bookDate = ProtobufParser.toTimestamp(transactionBookedTime);
      blockTrade.setTransactionBookedTime(bookDate);
    }

    const buyerParty = new Party();
    buyerParty.setUserId(buyer.user);
    buyerParty.setAccountId(buyer.account);

    const sellerParty = new Party();
    sellerParty.setUserId(seller.user);
    sellerParty.setAccountId(seller.account);

    !!broker?.user && twoSidedBlockTrade.setBrokerUserId(broker.user)

    twoSidedBlockTrade.setBlockTrade(blockTrade);
    twoSidedBlockTrade.setBuyer(buyerParty);
    twoSidedBlockTrade.setSeller(sellerParty);
    twoSidedBlockTrade.setBuyerClordId(v4());
    twoSidedBlockTrade.setSellerClordId(v4());
    twoSidedBlockTrade.setCrossId(v4());

    request.setBlockTrade(twoSidedBlockTrade);
    ApiCall(client, 'insertTwoSidedBlockTrade', request, cb)
  }
}
