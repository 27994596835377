export default class ProductListItem {
  constructor(item) {
    if (item) {
      this.name = item.name;
      this.description = item.description;
      return;
    }
    this.name = null;
    this.description = null;
  }
}