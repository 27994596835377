import State from "./dto/State";
import { capitalizeWord, getEnumName } from "../modules/util";
import { AccountState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { AccountProperty } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb";
import UserAttributes from "./UserAttributes";

export default class Account {
  constructor(item) {
    if (item) {
      this.id = item.getId();
      this.name = item.getName();
      this.displayName = item.getDisplayName();
      this.accountType = item.getAccountType();
      var state = new State();
      state.id = item.getState();
      state.name = capitalizeWord(getEnumName(AccountState, state.id));
      this.state = state;
      this.isSuspended = state.id === AccountState.ACCOUNT_STATE_SUSPENDED;
      this.parentFirm = item.getParentFirm();
      this.associatedFirm = item.getAssociatedFirm();
      this.riskSystem = item.getRiskSystem();
      this.collateralAccount = item.getCollateralAccount();
      this.priorityWeight = item.getPriorityWeight();

      this.beneficialOwnership = [];
      const beneficialOwnershipList = item.getBeneficialOwnershipList();
      if (Array.isArray(beneficialOwnershipList)) {
        beneficialOwnershipList.forEach(entry => this.beneficialOwnership.push(new UserAttributes(entry)));
      }

      this.propertiesList = [];
      const propertiesList = item.getPropertiesList();
      if (Array.isArray(propertiesList)) {
        propertiesList.forEach(entry => {
          var prop = new State();
          prop.id = entry;
          prop.name = capitalizeWord(getEnumName(AccountProperty, prop.id));
          this.propertiesList.push(prop);
        });
      }

      this.aliases = [];
      item.getAliasesMap().forEach((alias, option) => {
        this.aliases.push({ option, alias });
      });

      this.deleted = item.getDeleted();

      if (item.hasWhitelist()) {
        const whitelistObj = item.getWhitelist();
        const productsMap = whitelistObj.getProductsMap();
        const symbolsMap = whitelistObj.getSymbolsMap();
        this.whitelist = {
          enabled: whitelistObj.getEnabled(),
          productsList: [],
          symbolsList: [],
        }

        productsMap.forEach((obj, product) => {
          this.whitelist.productsList.push({
            side: obj.getSide(),
            product: product,
          });
        });

        symbolsMap.forEach((obj, symbol) => {
          this.whitelist.symbolsList.push({
            side: obj.getSide(),
            symbol: symbol,
          });
        });
      }

      if (item.hasOrderSizeLimit()) {
        const orderSizeLimit = item.getOrderSizeLimit();
        this.orderSizeLimit = {
          low: orderSizeLimit.getLow(),
          lowSet: orderSizeLimit.getLowSet(),
          high: orderSizeLimit.getHigh(),
          highSet: orderSizeLimit.getHighSet(),
        }
      }

      this.bankDetails = []
      let bankDetailsList = item.getBankDetailsList();
      bankDetailsList.forEach((det, idx) => {
        this.bankDetails[idx] = det.toObject()
      })

      return;
    }
    this.id = null;
    this.name = null;
    this.displayName = null;
    this.state = null;
    this.isSuspended = null;
    this.parentFirm = null;
    this.associatedFirm = null;
    this.riskSystem = null;
    this.orderSizeLimit = { low: null, lowSet: null, high: null, highSet: null };
    this.aliases = [];
    this.deleted = null;
    this.priorityWeight = null;
    this.beneficialOwnership = [];
    this.bankDetails = [];
  }
}
