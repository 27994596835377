import React, { Component } from "react";
import DropdownListGroup from "../components/core/form/DropdownListGroup";
import { convertEnumToDropdownList } from "../modules/util";
import { ParticipantState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";

class UserStateListContainer extends Component {
  state = {
    data: []
  };

  componentDidMount() {
    const data = convertEnumToDropdownList(ParticipantState);
    this.setState({ data });
  }

  render() {
    return (
      <DropdownListGroup
        id="state"
        name="state"
        label="User State"
        value={this.props.value}
        onChange={event => {
          this.props.onChange(event);
        }}
        data={this.state.data}
      />
    );
  }
}

export default UserStateListContainer;
