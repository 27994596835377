import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import { connect } from "react-redux";
import { isNullOrUndefined, parsePrice } from "../../modules/util";
import { fetchInstruments } from "../../actions/instruments";
import { hasWriteAccess } from "../../services/TokenService";
import DataGridActions from "../core/data-grid/DataGridActions";
import AdjustPositionForm from "./AdjustPositionForm";
import TransferPositionForm from "./TransferPositionForm"
import { parseJwt } from "../../modules/authUtil";

const GRID_ACTION_TRANSFER_POSITION = "GRID_ACTION_TRANSFER_POSITION"
const GRID_ACTION_EDIT = "GRID_ACTION_EDIT"
const GRID_ACTION_CREATE = "GRID_ACTION_CREATE"

function mapStateToProps(state) {
    return {
        account: state.accounts.account,
        instruments: state.instruments.instruments,
        firms: state.firms.firms,
        accessToken: state.auth.accessToken,
    };
}

const mapDispatchToProps = (dispatch) => ({
    loadInstruments: () => {
        dispatch(fetchInstruments(""))
    },
});

export class PositionsDataGrid extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);
        const accessToken = this.props.accessToken;
        const token = parseJwt(accessToken);
        this.state = { symbol: null, account: null, action: "", userRole: token.role };
    }

    componentDidMount() {
        let { loadInstruments, instruments } = this.props;
        if (!Array.isArray(instruments) || instruments.length === 0) {
            loadInstruments();
        }
    }

    canTransferPositions() {
        if (this.state.userRole === "USER_ROLE_SUPERVISOR_ADMIN") {
            return true;
        }

        return false
    }

    canAdjustPositions() {
        if (this.state.userRole === "USER_ROLE_SUPERVISOR_ADMIN") {
            return true;
        }

        return false
    }

    render() {
        const { data, accounts, pendingSettlements, reload, instruments, editable, account } = this.props;
        const scales = Array.isArray(instruments) ? instruments.reduce(function (map, inst) {
            map[inst.id] = inst.fractionalQtyScale;
            return map;
        }, {}) : {};
        const settlements = Array.isArray(pendingSettlements) ? pendingSettlements.reduce(function (map, settlement) {
            map[settlement.symbol + "-" + settlement.symbolSubType] = settlement.position;
            return map;
        }, {}) : {};

        let columns = [
            {
                Header: 'ACCOUNT',
                id: 'account',
                maxWidth: 150,
                accessor: d => {
                    const account = accounts.find(elem => elem.name === d.account);
                    return !!account ? account.displayName : d.account;
                }
            },
            {
                Header: 'SYMBOL',
                accessor: 'symbol'
            },
            {
                Header: 'SUB TYPE',
                accessor: 'symbolSubType',
                maxWidth: 150,
            },
            {
                Header: 'BOD POSITION',
                id: 'bodPosition',
                accessor: d => parsePrice(d.bodPosition, scales[d.symbol]),
                maxWidth: 120,
                minWidth: 120
            },
            {
                Header: 'OPEN POSITIONS',
                id: 'netPosition',
                accessor: d => parsePrice(d.netPosition, scales[d.symbol]),
                maxWidth: 120,
                minWidth: 120
            },
            {
                Header: 'QTY BOUGHT',
                id: 'qtyBought',
                accessor: d => parsePrice(d.qtyBought, scales[d.symbol]),
                maxWidth: 120,
                minWidth: 120
            },
            {
                Header: 'QTY SOLD',
                id: 'qtySold',
                accessor: d => parsePrice(d.qtySold, scales[d.symbol]),
                maxWidth: 120,
                minWidth: 120
            },
            {
                Header: 'UNSETTLED',
                id: 'pendingSettlement',
                accessor: d => !!settlements[d.symbol+"-"+d.symbolSubType] ? parsePrice(settlements[d.symbol+"-"+d.symbolSubType], scales[d.symbol]) : "",
                maxWidth: 120,
                minWidth: 120
            },
        ];
        if (!!editable && hasWriteAccess()) {
            columns.push({
                Header: () => {
                    return (
                        <DataGridActions
                            onEdit={() => this.setState({ symbol: "", account: account.name, symbolSubType: "", action: GRID_ACTION_EDIT })}
                        />
                    );
                },
                maxWidth: 100,
                minWidth: 100,
                sortable: false,
                Cell: (row) => {
                    return (
                        <DataGridActions
                            onEdit={this.canAdjustPositions() ? () => this.setState({ symbol: row.original.symbol, account: row.original.account, symbolSubType: row.original.symbolSubType, action: GRID_ACTION_EDIT }) : null}
                            onTransfer={this.canTransferPositions() ? () => { this.setState({ symbol: row.original.symbol, account: row.original.account, symbolSubType: row.original.symbolSubType, action: GRID_ACTION_TRANSFER_POSITION }) } : null}
                        />
                    );
                },
            })
        }
        return (
            <div>
                <AdjustPositionForm
                    show={!isNullOrUndefined(this.state.account) && (this.state.action === GRID_ACTION_EDIT || this.state.action === GRID_ACTION_CREATE)}
                    instruments={instruments ? instruments : []}
                    account={this.state.account}
                    symbol={this.state.symbol}
                    symbolSubType={this.state.symbolSubType}
                    scales={scales}
                    reload={reload}
                    defaultValue={0}
                    onComplete={() => this.setState({ symbol: null, account: null, action: "" })}
                />
                <TransferPositionForm
                    show={this.state.action === GRID_ACTION_TRANSFER_POSITION}
                    account={this.state.account}
                    firms={this.props.firms}
                    symbol={this.state.symbol}
                    symbolSubType={this.state.symbolSubType}
                    accounts={this.props.accounts}
                    onComplete={() => this.setState({ symbol: null, account: null, action: "" })}
                    reload={reload}
                    instruments={this.props.instruments}
                />
                <DataGrid
                    data={data}
                    columns={columns}
                    filterable={false}
                />
            </div>
        );
    }
}

PositionsDataGrid.propTypes = {
    data: PropTypes.array.isRequired,
    accounts: PropTypes.array,
    pendingSettlements: PropTypes.array,
    reload: PropTypes.func.isRequired,
    editable: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionsDataGrid);
