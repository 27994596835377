import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import { convertDateToString, getEnumName, isNullOrUndefined } from "../../modules/util";
import { hasWriteAccess } from "../../services/TokenService";
import DataGridActions from "../core/data-grid/DataGridActions";
import AdjustmentForm from "./AdjustmentForm";
import CreateWithdrawalForm from "./CreateWithdrawalForm";
import CapitalReqForm from "./CapitalReqForm";
import { connect } from "react-redux";
import { fetchMetadata } from "../../actions/instruments";
import { fetchSecurityDefinitions } from "../../actions/accounts";
import { getValue } from "../../modules/util";
import ButtonMain from "../core/form/ButtonMain";
import { Row, Col } from "react-bootstrap";
import SecurityForm from './securityForm';
import AccountService from "../../services/AccountService";
import Notification from "../../modules/notifications";
import confirm from "../../modules/confirmDialog";
import { SecurityType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/risk/v1beta1/risk_pb";
import { WithdrawalType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/risk/v1beta1/risk_pb";
import PendingCreditsForm from './CreatePendingCreditsForm';
import { parseJwt } from "../../modules/authUtil";
import { tsProtoObjToDate } from "../../actions/ptypes";
import {Env} from "../../constants/environment";

function mapStateToProps(state) {
    return {
        account: state.accounts.account,
        securities: state.accounts.securities,
        metadata: state.instruments.metadata,
        pendingCredits: state.accounts.pendingCredits,
        accessToken: state.auth.accessToken,
    };
}

const mapDispatchToProps = (dispatch) => ({
    fetchMetadata: () => {
        dispatch(fetchMetadata());
    },
    fetchSecurityDefinitions: () => {
        dispatch(fetchSecurityDefinitions());
    },
});

export class BalancesDataGrid extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);
        const accessToken = this.props.accessToken;
        const token = parseJwt(accessToken);
        this.state = { currBalance: null, currency: null, account: null, description: "", userRole: token.role };
    }

    componentDidMount() {
        let { fetchMetadata, metadata, fetchSecurityDefinitions, securityDefinitions } = this.props;
        if (isNullOrUndefined(metadata)) {
            fetchMetadata();
        }
        if (isNullOrUndefined(securityDefinitions)) {
            fetchSecurityDefinitions();
        }
    }

    canEdit() {
        if (this.state.userRole === "USER_ROLE_SUPERVISOR_ADMIN") {
            return true;
        }

        return false
    }

    shouldHideSecurityGrid() {
        let shouldHide = Env.getEnv("REACT_APP_HIDE_SECURITY_GRID");
        if (!shouldHide) return false;
        return shouldHide.toString().toLowerCase() === "true" || shouldHide === 1;
    }

    getCollateralAccount(collateralAccount) {
        if (!!collateralAccount) {
            const cAcc = getValue(collateralAccount, "displayName", "");
            return cAcc;
        }

        return "";
    }

    switchToCollateralAccount(collateralAccount) {
        return () => {
            const parentFirm = collateralAccount.parentFirm.split("/").at(-1);
            const navURL = `/firms/${parentFirm}/accounts/${collateralAccount.id}/balances`;
            this.context.router.history.push(navURL);
        }
    }

    removeSecurity(security) {
        const { reload, account } = this.props;

        confirm(`Are you sure you want to delete the ${security.securityId} security?`, {
            title: "Delete Confirmation",
            okButtonText: "Yes",
            cancelButtonText: "No",
        }).then(() => {
            AccountService.setAccountSecurityMarketValue(account.name, security.currency, security.securityId, "0", "0", "Security Deletion", (err, response) => {
                if (err) {
                    Notification.error(err.message);
                }

                AccountService.setAccountSecurityBalance(account.name, security.currency, security.securityId, "0", "Security Deletion", (err, response) => {
                    if (response) {
                        Notification.success("Security was deleted successfully!");
                        reload();
                    }
                    if (err) {
                        Notification.error(err.message);
                    }
                });
            });
        }, () => { });
    }

    getWithdrawalColumns(wdType) {
        let withdrawalColumns = [
            {
                Header: 'CREATE TIME',
                id: 'creationTime',
                accessor: d => !!d.creationTime ? convertDateToString(tsProtoObjToDate(d.creationTime), "YYYYMMDD-HH:mm:ss.SSS") : "",
                maxWidth: 180,
                minWidth: 180,
            },
            {
                Header: 'CURRENCY',
                accessor: 'currency',
                width: 80
            },
        ]
        if (wdType === WithdrawalType.WITHDRAWAL_TYPE_SECURITY) {
            withdrawalColumns.push({
                Header: 'SECURITY IDENTIFIER',
                accessor: 'securityId',
                width: 140
            })
        }
        withdrawalColumns.push(
            ...[
            {
                Header: 'BALANCE',
                accessor: 'balance',
                width: 120
            },
            {
                Header: 'DESCRIPTION',
                accessor: 'description'
            },
            {
                Header: 'ACKNOWLEDGED',
                id: 'acknowledged',
                accessor: d => !!d.acknowledged ? "Y" : "N",
                width: 150
            },
            {
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                filterable: false,
                Header: 'ACTIONS',
                Cell: (row) => {
                    wdType = (isNullOrUndefined(row.original.securityId) || row.original.securityId.length === 0) ? WithdrawalType.WITHDRAWAL_TYPE_CURRENCY  : WithdrawalType.WITHDRAWAL_TYPE_SECURITY
                    return (
                        <DataGridActions
                            onApprove={this.canEdit()
                                ? () => this.setState({ currWithdrawal: row.original,  withdrawalType: wdType, showApproveWithdrawal: true, showDeleteWithdrawal: null, showViewWithdrawal: null })
                                : null}
                            onRemove={this.canEdit()
                                ? () => this.setState({ currWithdrawal: row.original, withdrawalType: wdType, showApproveWithdrawal: null, showDeleteWithdrawal: true, showViewWithdrawal: null })
                                : null
                            }
                            onView={() => this.setState({ currWithdrawal: row.original, withdrawalType: wdType, showApproveWithdrawal: null, showDeleteWithdrawal: null, showViewWithdrawal: true })}
                        />
                    );
                }
            }]);
        return withdrawalColumns
    }

    render() {
        const { data, accounts, withdrawals, reload, metadata, securities, account, editable, pendingCredits, reloadCredits } = this.props;
        const collateralAccount = accounts.find(act => act.name === account.collateralAccount);
        const accountNames = accounts.map(account => account.name);
        const filteredData = data.filter((position) => accountNames.includes(position.account) || (!isNullOrUndefined(account) && account.name === position.account));

        let securityCurrencyMap = new Map();
        let securityIds = [];
        let securitiesData = [];
        filteredData.forEach((position) => {
            if (!!position.securities && position.securities.length > 0) {
                for (const idx in position.securities) {
                    const sec = position.securities[idx];
                    securityCurrencyMap.set(sec.securityId, sec.currency)
                    securityIds.push(sec.securityId)
                    securitiesData.push(
                        {
                            ...sec,
                            ...securities[sec.securityId]
                        }
                    )
                }
            }
        });

        let currencyWithdrawals = [];
        let securityWithdrawals = [];
        for (var i = 0; i < withdrawals.length; i++) {
            if (isNullOrUndefined(withdrawals[i].securityId) || withdrawals[i].securityId.length === 0) {
              currencyWithdrawals.push(withdrawals[i])
            } else  {
              securityWithdrawals.push(withdrawals[i])
            }
        }

        let columns = [
            {
                Header: 'CURRENCY',
                accessor: 'currency',
                width: 80,
            },
            {
                Header: 'FIAT BALANCE',
                accessor: 'balance'
            },
        ];
        if (!this.shouldHideSecurityGrid()) {
            columns.push(
                {
                    Header: 'TOTAL SECURITY NOTIONAL VALUE',
                    accessor: 'totalSecurityNotionalValue'
                },
                {
                    Header: 'TOTAL SECURITY AVAILABLE VALUE',
                    accessor: 'totalSecurityAvailableValue'
                },
            );
        }
        columns.push(
            {
                Header: 'CAPITAL REQUIREMENT',
                id: "capitalRequirement",
                accessor: d => d.capitalRequirement + (!!d.alternateCapitalRequirementCurrency ? " (" + d.alternateCapitalRequirement + " " + d.alternateCapitalRequirementCurrency + ")":""),
            },
            {
                Header: 'EXCESS CAPITAL',
                accessor: 'excessCapital'
            },
            {
                Header: 'BUYING POWER',
                accessor: 'buyingPower'
            },
            {
                Header: 'OPEN ORDERS',
                accessor: 'openOrders'
            },
            {
                Header: 'MARGIN REQUIREMENT',
                accessor: 'marginRequirement'
            },
            {
                Header: 'UNSETTLED FUNDS',
                accessor: 'unsettledFunds'
            },
        );

        if (!!editable && hasWriteAccess()) {
            columns.push({
                maxWidth: 100,
                minWidth: 100,
                sortable: false,
                Header: 'BALANCE',
                Cell: (row) => {
                    return (
                        <DataGridActions
                            onEdit={() => this.setState({ currBalance: row.original.balance, currency: row.original.currency, account: row.original.account, description: "", showBalance: true })}
                        />
                    );
                }
            });

            columns.push({
                maxWidth: 100,
                minWidth: 100,
                sortable: false,
                Header: 'CAPITAL',
                Cell: (row) => {
                    return (
                        <DataGridActions
                            onEdit={() => this.setState({ 
                                currCapital: (!!row.original.alternateCapitalRequirementCurrency ? row.original.alternateCapitalRequirement:row.original.capitalRequirement),
                                alternateCurrency: (!!row.original.alternateCapitalRequirementCurrency ? row.original.alternateCapitalRequirementCurrency:""),
                                currency: row.original.currency, account: row.original.account, description: "", showCapital: true })}
                        />
                    );
                }
            })
        }

        let securitiesColumns = [
            {
                Header: 'CURRENCY',
                accessor: 'currency',
                width: 80,
            },
            {
                Header: 'SECURITY IDENTIFIER',
                accessor: 'securityId',
            },
            {
                Header: 'DESCRIPTION',
                id: 'description',
                accessor: (d) => !isNullOrUndefined(d.description) ? d.description : '',
            },
            {
                Header: 'TYPE',
                id: 'securityType',
                accessor: (d) => !isNullOrUndefined(d.securityType) ? getEnumName(SecurityType, d.securityType) : '',
            },
            {
                Header: 'BALANCE',
                accessor: 'balance'
            },
            {
                Header: 'MARKET VALUE',
                accessor: 'marketValue'
            },
            {
                Header: 'HAIRCUT (IN BPS)',
                accessor: 'haircut'
            },
            {
                Header: 'NOTIONAL VALUE',
                accessor: 'notionalValue'
            },
            {
                Header: 'AVAILABLE VALUE',
                accessor: 'availableValue'
            },
        ];

        if (!!editable && hasWriteAccess()) {
            securitiesColumns.push({
                maxWidth: 100,
                minWidth: 100,
                sortable: false,
                filterable: false,
                Header: 'SECURITY',
                Cell: (row) => {
                    return (
                        <DataGridActions
                            onEdit={() => this.setState({ security: row.original, account: account.name, showSecurity: true })}
                        />
                    );
                }
            });

            securitiesColumns.push({
                maxWidth: 100,
                minWidth: 100,
                sortable: false,
                filterable: false,
                Header: '',
                Cell: (row) => {
                    return (
                        <DataGridActions
                            onRemove={() => this.removeSecurity(row.original)}
                        />
                    );
                }
            });
        }

        let currencyWithdrawalsColumns = this.getWithdrawalColumns(WithdrawalType.WITHDRAWAL_TYPE_CURRENCY);
        let securityWithdrawalsColumns = this.getWithdrawalColumns(WithdrawalType.WITHDRAWAL_TYPE_SECURITY);

        let pendingCreditsColumns = [
            {
                Header: 'CREATE TIME',
                id: 'creationTime',
                accessor: d => !!d.creationTime ? convertDateToString(tsProtoObjToDate(d.creationTime), "YYYYMMDD-HH:mm:ss.SSS") : "",
                maxWidth: 180,
                minWidth: 180,
            },
            {
                Header: 'CURRENCY',
                accessor: 'currency',
                width: 80
            },
            {
                Header: 'AMOUNT',
                accessor: 'balance',
                width: 120
            },
            {
                Header: 'DESCRIPTION',
                accessor: 'description'
            },
            {
                Header: 'CORRELATION ID',
                accessor: 'correlationId',
                width: 200
            },
            {
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                filterable: false,
                Header: 'ACTIONS',
                Cell: (row) => {
                    return (
                        <DataGridActions
                            onApprove={this.canEdit()
                                ? () => this.setState({ currPendingCredit: row.original, showApprovePendingCredit: true, showViewPendingCredit: null, showDeletePendingCredit: null })
                                : null
                            }
                            onRemove={this.canEdit()
                                ? () => this.setState({ currPendingCredit: row.original, showApprovePendingCredit: null, showDeletePendingCredit: true, showViewPendingCredit: null })
                                : null
                            }
                            onView={() => this.setState({ currPendingCredit: row.original, showApprovePendingCredit: null, showViewPendingCredit: true, showDeletePendingCredit: null })}
                        />
                    );
                }
            }
        ];

        const titleStyle = {
            marginTop: '35px',
            marginBottom: "10px",
            alignSelf: "end"
        };

        const h3Style = {
            marginBottom: "0px",
        };

        const resetDialogState = () => {
            this.setState({
                currBalance: null,
                currWithdrawal: null,
                currency: null,
                account: null,
                alternateCurrency: null,
                showBalance: null,
                showCapital: null,
                showSecurity: null,
                showCreateCurrencyWithdrawal: null,
                showCreateSecurityWithdrawal: null,
                showApproveWithdrawal: null,
                showDeleteWithdrawal: null,
                showViewWithdrawal: null,
                showApprovePendingCredit: null,
                showViewPendingCredit: null,
                showDeletePendingCredit: null,
                description: "",
                security: {}
            });
        }

        return (
            <>
                <Row>
                    <Col style={titleStyle}>
                        <h3 style={h3Style}>Balance Details by Currency</h3>
                    </Col>
                    <Col style={titleStyle}>
                        <ButtonMain
                            text="SET BALANCE"
                            type="button"
                            style={{ float: "right" }}
                            customClassName="btn-main-header"
                            onClick={() => this.setState({ currBalance: "0.0", currency: "", account: account.name, showBalance: true })}
                        />

                        <ButtonMain
                            text="SET CAPITAL"
                            type="button"
                            style={{ float: "right" }}
                            customClassName="btn-main-header"
                            onClick={() => this.setState({ currCapital: "0.0", currency: "", account: account.name, alternateCurrency: "", showCapital: true })}
                        />

                        {collateralAccount && <ButtonMain
                            text={"Switch To: " + this.getCollateralAccount(collateralAccount)}
                            type="button"
                            style={{ float: "right" }}
                            customClassName="btn-main-header"
                            onClick={this.switchToCollateralAccount(collateralAccount)} />}

                    </Col>
                </Row>

                <Row>
                    <Col>
                        <AdjustmentForm
                            show={!isNullOrUndefined(this.state.showBalance)}
                            dataSourceOptions={!isNullOrUndefined(metadata) && !isNullOrUndefined(metadata.currencies) ? Object.keys(metadata.currencies) : []}
                            account={this.state.account}
                            currency={this.state.currency}
                            defaultValue={this.state.currBalance}
                            description={this.state.description}
                            reload={reload}
                            onComplete={() => resetDialogState()}
                        />

                        <CapitalReqForm
                            show={!isNullOrUndefined(this.state.showCapital)}
                            dataSourceOptions={!isNullOrUndefined(metadata) && !isNullOrUndefined(metadata.currencies) ? Object.keys(metadata.currencies) : []}
                            account={this.state.account}
                            currency={this.state.currency}
                            defaultValue={this.state.currCapital}
                            description={this.state.description}
                            alternateCurrency={this.state.alternateCurrency}
                            reload={reload}
                            onComplete={() => resetDialogState()}
                        />

                        <SecurityForm
                            show={!isNullOrUndefined(this.state.showSecurity)}
                            currencies={!isNullOrUndefined(metadata) && !isNullOrUndefined(metadata.currencies) ? Object.keys(metadata.currencies) : []}
                            securities={securities}
                            account={this.state.account}
                            data={this.state.security}
                            reload={reload}
                            onComplete={() => resetDialogState()}
                        />

                        <DataGrid
                            data={filteredData}
                            columns={columns}
                            filterable={false}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col style={titleStyle}>
                        <h3 style={h3Style}>Pending Currency Withdrawals</h3>
                    </Col>

                    <Col style={titleStyle}>
                        <ButtonMain
                            text="CREATE CURRENCY WITHDRAWAL REQUEST"
                            type="button"
                            style={{ float: "right" }}
                            customClassName="btn-main-header"
                            onClick={() => this.setState({ currBalance: "0.0", currency: "", account: account.name, withdrawalType: WithdrawalType.WITHDRAWAL_TYPE_CURRENCY, showCreateCurrencyWithdrawal: true })}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CreateWithdrawalForm
                            show={(this.state.withdrawalType === WithdrawalType.WITHDRAWAL_TYPE_CURRENCY)
                                && (!isNullOrUndefined(this.state.showCreateCurrencyWithdrawal)
                                    || !isNullOrUndefined(this.state.showApproveWithdrawal)
                                    || !isNullOrUndefined(this.state.showDeleteWithdrawal)
                                    || !isNullOrUndefined(this.state.showViewWithdrawal))}
                            dataSourceOptions={!isNullOrUndefined(metadata) && !isNullOrUndefined(metadata.currencies) ? Object.keys(metadata.currencies) : []}
                            account={this.state.account}
                            currency={this.state.currency}
                            defaultValue={this.state.currBalance}
                            description={this.state.description}
                            withdrawalType={this.state.withdrawalType}
                            currWithdrawal={!isNullOrUndefined(this.state.showCreateCurrencyWithdrawal) ? null : this.state.currWithdrawal}
                            isApproving={!isNullOrUndefined(this.state.showApproveWithdrawal)}
                            isDeleting={!isNullOrUndefined(this.state.showDeleteWithdrawal)}
                            isViewing={!isNullOrUndefined(this.state.showViewWithdrawal)}
                            reload={reload}
                            onComplete={() => resetDialogState()}
                        />
                        <DataGrid
                            data={currencyWithdrawals}
                            columns={currencyWithdrawalsColumns}
                            filterable={false}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col style={titleStyle}>
                        <h3 style={h3Style}>Pending Credits</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PendingCreditsForm
                            show={!isNullOrUndefined(this.state.showApprovePendingCredit) || !isNullOrUndefined(this.state.showViewPendingCredit) || !isNullOrUndefined(this.state.showDeletePendingCredit)}
                            values={this.state.currPendingCredit || {}}
                            isApproving={!isNullOrUndefined(this.state.showApprovePendingCredit)}
                            isViewing={!isNullOrUndefined(this.state.showViewPendingCredit)}
                            isDeleting={!isNullOrUndefined(this.state.showDeletePendingCredit)}
                            onComplete={() => resetDialogState()}
                            reloadCredits={reloadCredits}
                        />
                        <DataGrid
                            data={pendingCredits}
                            columns={pendingCreditsColumns}
                            filterable={false}
                        />
                    </Col>
                </Row>

                {!this.shouldHideSecurityGrid() && <Row>
                    <Col style={titleStyle}>
                        <h3 style={h3Style}>Securities</h3>
                    </Col>
                    <Col style={titleStyle}>
                        <ButtonMain
                            text="ADD SECURITY"
                            type="button"
                            style={{ float: "right" }}
                            customClassName="btn-main-header"
                            onClick={() => this.setState({ account: account.name, showSecurity: true })}
                        />
                    </Col>
                </Row>}

                {!this.shouldHideSecurityGrid() && <Row>
                    <Col>
                        <DataGrid
                            data={securitiesData}
                            columns={securitiesColumns}
                            filterable={true}
                        />
                    </Col>
                </Row>}

                {!this.shouldHideSecurityGrid() && <Row>
                    <Col style={titleStyle}>
                        <h3 style={h3Style}>Pending Security Withdrawals</h3>
                    </Col>

                    <Col style={titleStyle}>
                        <ButtonMain
                            text="CREATE SECURITY WITHDRAWAL REQUEST"
                            type="button"
                            style={{ float: "right" }}
                            customClassName="btn-main-header"
                            onClick={() => this.setState({ currBalance: "0.0", currency: "", account: account.name, withdrawalType: WithdrawalType.WITHDRAWAL_TYPE_SECURITY, showCreateSecurityWithdrawal: true })}
                        />
                    </Col>
                </Row>}
                {!this.shouldHideSecurityGrid() && <Row>
                    <Col>
                        <CreateWithdrawalForm
                            show={(this.state.withdrawalType === WithdrawalType.WITHDRAWAL_TYPE_SECURITY)
                                && (!isNullOrUndefined(this.state.showCreateSecurityWithdrawal)
                                    || !isNullOrUndefined(this.state.showApproveWithdrawal)
                                    || !isNullOrUndefined(this.state.showDeleteWithdrawal)
                                    || !isNullOrUndefined(this.state.showViewWithdrawal))}
                            dataSourceOptions={!isNullOrUndefined(metadata) && !isNullOrUndefined(metadata.currencies) ? Object.keys(metadata.currencies) : []}
                            securityKeys={securityIds}
                            securityCurrencies={securityCurrencyMap}
                            account={this.state.account}
                            currency={this.state.currency}
                            defaultValue={this.state.currBalance}
                            description={this.state.description}
                            withdrawalType={this.state.withdrawalType}
                            securityId={this.state.securityId}
                            currWithdrawal={!isNullOrUndefined(this.state.showCreateSecurityWithdrawal) ? null : this.state.currWithdrawal}
                            isApproving={!isNullOrUndefined(this.state.showApproveWithdrawal)}
                            isDeleting={!isNullOrUndefined(this.state.showDeleteWithdrawal)}
                            isViewing={!isNullOrUndefined(this.state.showViewWithdrawal)}
                            reload={reload}
                            onComplete={() => resetDialogState()}
                        />
                        <DataGrid
                            data={securityWithdrawals}
                            columns={securityWithdrawalsColumns}
                            filterable={false}
                        />
                    </Col>
                </Row>
                }
            </>
        )
    }
}

BalancesDataGrid.propTypes = {
    data: PropTypes.array.isRequired,
    accounts: PropTypes.array,
    withdrawals: PropTypes.array,
    reload: PropTypes.func.isRequired,
    editable: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(BalancesDataGrid);