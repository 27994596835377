import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import { getValue, getOptionName, isArrayWithValues } from "../../modules/util";
import FirmsStaticData from "../../modules/firmsStaticData";
import GridActions from '../core/data-grid/GridActions';
import { GridActionDelete, GridActionEdit, GridActionRestore, GridActionView } from "../core/data-grid/GridAction";
import { useMemo } from "react";
import { GridActionMenu } from "../core/menu-items/menu-items";

const AccountDataGrid = ({ data, firms, onView, onEdit, onRemove, onUnDelete, allAccounts }) => {

  const [customerOrderCapacity, setCustomerOrderCapacity] = useState()
  const [accountTypes, setAccountTypes] = useState();
  const [accounts, setAccounts] = useState();

  const gridCtxMenu = useMemo(() => GridActionMenu, []);

  useEffect(() => {
    const accountTypes = FirmsStaticData.AccountTypes([0]);
    setAccountTypes(accountTypes);
    const customerOrderCapacity = FirmsStaticData.CustomerOrderCapacity([0]);
    setCustomerOrderCapacity(customerOrderCapacity)
    prepareGridDate();
    setAccounts(data)
  }, [data])

  const prepareGridDate = () => {
    if (isArrayWithValues(data)) {
      data.forEach(element => {
        element.accountTypeName = getOptionName(accountTypes, element.accountType, "");
        element.customerOrderCapacityName = getOptionName(customerOrderCapacity, element.customerOrderCapacity, "");
      });
    }
  }

  const firmsList = !firms ? [] : firms;
  const firmsMap = new Map(firmsList.map(obj => [obj.name, obj.displayName ? obj.displayName : obj.name]));

  const columns = [
    {
      Header: "NAME",
      accessor: "displayName",
      maxWidth: 240,
      minWidth: 240,
    },
    {
      Header: "ACCOUNT TYPE",
      accessor: "accountTypeName",
      maxWidth: 240,
      minWidth: 240,
    },
    {
      Header: "ASSOCIATED FIRM",
      id: "associatedFirm",
      accessor: (d) => firmsMap.has(d.associatedFirm) ? firmsMap.get(d.associatedFirm) : d.associatedFirm,
    },
    {
      Header: "CLEARING FIRM",
      id: "parentFirm",
      accessor: (d) => firmsMap.has(d.parentFirm) ? firmsMap.get(d.parentFirm) : d.parentFirm,
    },
    {
      Header: "RISK SYSTEM",
      id: "riskSystem",
      accessor: "riskSystem",
      maxWidth: 120,
      minWidth: 120,
    },
    {
      Header: "COLLATERAL ACCOUNT",
      id: "collateralAccount",
      accessor: (d) => {
        if (allAccounts)
          return getValue(allAccounts.find(x => x.name === d.collateralAccount), "displayName", "")
        else
          return getValue(data.find(x => x.name === d.collateralAccount), "displayName", "")
      },
      maxWidth: 120,
      minWidth: 120,
    },
    {
      Header: "STATE",
      id: "state",
      accessor: (d) => (d.isSuspended ? "Suspended" : "Active"),
      maxWidth: 120,
      minWidth: 120,
    },
    {
      Header: "ORDER SIZE LOWER LIMIT",
      id: "orderSizeLowerLimit",
      accessor: (d) => (d.orderSizeLimit ? d.orderSizeLimit.low : ""),
      maxWidth: 120,
      minWidth: 40,
    },
    {
      Header: "ORDER SIZE LOWER LIMIT SET",
      id: "orderSizeLowerLimitSet",
      accessor: (d) => (d.orderSizeLimit ? (d.orderSizeLimit.lowSet ? "ON" : "OFF") : ""),
      maxWidth: 120,
      minWidth: 40,
    },
    {
      Header: "ORDER SIZE HIGHER LIMIT",
      id: "orderSizeHigherLimit",
      accessor: (d) => (d.orderSizeLimit ? d.orderSizeLimit.high : ""),
      maxWidth: 120,
      minWidth: 40,
    },
    {
      Header: "ORDER SIZE HIGHER LIMIT SET",
      id: "orderSizeHigherLimitSet",
      accessor: (d) => (d.orderSizeLimit ? (d.orderSizeLimit.highSet ? "ON" : "OFF") : ""),
      maxWidth: 120,
      minWidth: 40,
    },
    {
      Header: "ACTIONS",
      maxWidth: 120,
      minWidth: 120,
      sortable: false,
      Cell: (row) => {
        return (
          <GridActions>
            <GridActionView contextMenu={gridCtxMenu}
              onClick={(e, actionContext) => { onView(row.original.name, actionContext); }} />

            {!row.original.deleted && <GridActionEdit contextMenu={gridCtxMenu}
              onClick={(e, actionContext) => { onEdit(row.original.name, actionContext) }} />}

            {!row.original.deleted && <GridActionDelete onClick={(e, actionContext) => { onRemove(row.original.name, actionContext) }} />}
            {!!row.original.deleted && <GridActionRestore onClick={(e, actionContext) => { onUnDelete(row.original.name, actionContext) }} />}
          </GridActions>
        )
      },
    },
  ];
  return (
    <div>
      <DataGrid data={accounts} columns={columns} filterable={false} />
    </div>
  );
};

AccountDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default AccountDataGrid;
