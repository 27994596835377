import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'react-bootstrap';
import PriceLimitField from './PriceLimitField';
import './PriceLimit.css';
import * as toolTips from '../../constants/instrumentToolTip'
import Tooltip from '../core/tooltip-html/tooltip-html';

class PriceLimit extends Component {
  render() {
    return (
      <div className="price-limit-wrapper">
        <label>Absolute Price Limits</label>
        <Tooltip
          text = {toolTips.ABSOLUTE_PRICE_LIMITS}
          id="tipAbsolutePriceLimits"
        ></Tooltip>
        <Row>
          <Col lg={6} xs={6} md={6}>
            <PriceLimitField
              label="Low Limit"
              priceName="lowPriceLimit"
              priceValue={this.props.lowPrice}
              setPrice={this.props.setLow}
              isRequired={false}
              errors={this.props.errors}
              touched={this.props.touched}
              setFieldValue={this.props.setFieldValue}
              onChange={this.props.onChange}
              onBlur={this.props.handleBlur}
              onSetClick={(checked) => {
                this.props.onSetLow(checked);
              }}
            />
          </Col>
          <Col lg={6} xs={6} md={6}>
            <PriceLimitField
              label="High Limit"
              priceName="highPriceLimit"
              priceValue={this.props.highPrice}
              setPrice={this.props.setHigh}
              isRequired={false}
              errors={this.props.errors}
              touched={this.props.touched}
              setFieldValue={this.props.setFieldValue}
              onChange={this.props.onChange}
              onBlur={this.props.handleBlur}
              onSetClick={(checked) => {
                this.props.onSetHigh(checked);
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

PriceLimit.propTypes = {
  lowPrice: PropTypes.any,
  highPrice: PropTypes.any,
  setLow: PropTypes.bool,
  setHigh: PropTypes.bool,
  errors: PropTypes.any,
  touched: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSetLow: PropTypes.func.isRequired,
  onSetHigh: PropTypes.func.isRequired
};

export default PriceLimit;