import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import FieldGroup from '../core/form/FieldGroup';
import CheckboxGroup from '../core/form/CheckboxGroup';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import FieldGroupReadOnly from '../core/form/FieldGroupReadOnly';
import ParticipantsInGroupContainer from '../../containers/ParticipantsInGroupContainer';
import ButtonBorderless from '../core/form/ButtonBorderless';
import ButtonMain from '../core/form/ButtonMain';
import FirmsListContainer from '../../containers/FirmsListContainer';

class BasicParticipantGroupForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  render() {
    const {
      title,
      group,
      touched,
      errors,
      isEditing,
      handleChange,
      handleBlur,
      handleSubmit,
      onDisableGroup,
      setFieldValue,
      onDelete,
      values
    } = this.props;
    return (
      <>
        <form onSubmit={handleSubmit}>
          <h1>{title}</h1>
          <Container>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {isEditing ?
                  <FieldGroupReadOnly
                    id="id"
                    label="Participant Group Name"
                    value={values.id}
                  />
                  :
                  <FieldGroup
                    id="id"
                    name="id"
                    type="text"
                    label="Participant Group Name"
                    placeholder=""
                    isRequired={true}
                    disabled={isEditing}
                    errors={errors}
                    touched={touched}
                    value={values.id}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
              </Col>
            </Row>

            <Row>
              <Col lg={6} xs={6} md={12}>
                {isEditing ?
                  <FieldGroupReadOnly
                    id="id"
                    label="Participant Firm Name"
                    value={values.firmDisplayName}
                  />
                  :
                  <FirmsListContainer
                    id="firm"
                    name="firm"
                    label="Firm"
                    value={values.firm}
                    isRequired={true}
                    errors={errors}
                    enabled={!isEditing}
                    onChange={(e) => setFieldValue('firm', e)}
                  />
                }

              </Col>
            </Row>

            {isEditing  && <CheckboxGroup
              checked={group.isSuspended}
              onClick={(e) => { onDisableGroup(group.id, e.target.checked); }}
              label={'Suspend Group'}
            />}

            {isEditing ? <Row>
              <Col lg={12} xs={12} md={12}>
                <ParticipantsInGroupContainer
                  groupId={group.id} group={group}
                />
              </Col>
            </Row> : null}

            {
              isEditing ?
                <ButtonBorderless
                  type="button"
                  onClick={() => onDelete(group.id)}
                  icon="trash-alt"
                  customClassName="orange-icon"
                  text="Remove group"
                />
                : <ButtonMain
                  type="submit"
                  text="SAVE"
                />
            }
            <ButtonBorderless
              type="button"
              text="Cancel"
              icon="times-circle"
              customClassName="grey-icon"
              onClick={(e) => {
                e.preventDefault();
                this.context.router.history.push('/participant-groups');
              }} />

          </Container>
        </form>
      </>
    );
  }
};

function mapStateToProps(state) {
  return {
    isEditing: !state.participantGroups.isCreating,
    group: state.participantGroups.participantGroup,
  };
}



const ParticipantGroupForm = withFormik({
  mapPropsToValues: (group) => ({
    ...group.group
  }),
  validationSchema: Yup.object().shape({
    id: Yup.string()
      .nullable()
      .required('Participant Group name is required.'),
    firm: Yup.object()  // Firm object expected.
      .required('Firm is required.'),
  }),
  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onSubmitForm(values, setFieldError);
  },
  displayName: 'participant-group-form',
  enableReinitialize: true
})(BasicParticipantGroupForm);

export default connect(mapStateToProps, null)(ParticipantGroupForm);