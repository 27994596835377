import { convertDateToString } from "../../modules/util";

export default class TimeSalesGridItem {
  constructor(item) {
    //this.tradeCaptureId = null;
    this.transactTime = null;
    this.tradeId = null;
    this.aggressor = null;
    this.passive = null;
    this.aggressorId = null;
    this.passiveId = null;
    this.aggressorAccount = null;
    this.passiveAccount = null;
    this.aggressorSide = null;
    this.passiveSide = null;
    this.tradeQty = null;
    this.tradePrice = null;
    this.status = null;
    this.symbol = null;
    this.blockTradeIndicator = false;
    this.text = null;

    if (item) {
      this.tradeId = item.id;
      if (item.state && item.state.name) {
        this.status = item.state.name;
      }

      if (!!item.aggressor && !!item.aggressor.order) {
        this.aggressor = item.aggressor.order.participant;
        this.aggressorAccount = item.aggressor.order.account;
        this.aggressorSide = item.aggressor.order.side.name;
        this.aggressorId = item.aggressor.order.id;
        this.aggressorClientAccountId = item.aggressor.order.clientaccountid;
        this.aggressorClientParticipantId = item.aggressor.order.clientparticipantid;
        this.aggressorParentOrderId = item.aggressor.order.parentorderid;
        this.aggressorSymbolSubType = item.aggressor.order.symbolsubtype;
        this.aggressorLastPrice = item.aggressor.lastPx;
        this.aggressorCommissionsBasisPoints = item.aggressor.order.commissionsBasisPoints;
        this.aggressorCommissionNotionalCollected = item.aggressor.commissionNotionalCollected;
        this.blockTradeIndicator = item.aggressor.order.blockTradeIndicator;
        this.aggressorTransactTradeDate = item.aggressor.transactTradeDate;
      }

      if (!!item.passive && !!item.passive.order) {
        this.passive = item.passive.order.participant;
        this.passiveAccount = item.passive.order.account;
        this.passiveSide = item.passive.order.side.name;
        this.passiveId = item.passive.order.id;
        this.passiveClientAccountId = item.passive.order.clientaccountid;
        this.passiveClientParticipantId = item.passive.order.clientparticipantid;
        this.passiveParentOrderId = item.passive.order.parentorderid;
        this.passiveSymbolSubType = item.passive.order.symbolsubtype;
        this.passiveLastPrice = item.passive.lastPx;
        this.passiveCommissionsBasisPoints = item.passive.order.commissionsBasisPoints;
        this.passiveCommissionNotionalCollected = item.passive.commissionNotionalCollected;
        this.blockTradeIndicator = item.passive.order.blockTradeIndicator;
        this.passiveTransactTradeDate = item.passive.transactTradeDate;
      }

      // Common fields.
      const commonFieldOrder = !!item.aggressor && !!item.aggressor.order ? item.aggressor : item.passive;
      this.symbol = commonFieldOrder.order.symbol;
      this.tradePrice = commonFieldOrder.lastPx;
      this.tradeQty = commonFieldOrder.lastShares;
      this.transactTime = convertDateToString(commonFieldOrder.transactTime, "YYYYMMDD-HH:mm:ss.SSS");
      this.blockTradeIndicator = commonFieldOrder.order.blockTradeIndicator.toString();
      this.text = item.text;
      this.aggressorData = item.aggressor;
      this.passiveData = item.passive;

      // TODO:
      //
      // These fields are missing in the updated api response--
      //
      // this.tradeCaptureId = item.id;
      return;
    }
  }
}
