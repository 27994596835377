import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import { Container, Row, Col } from "react-bootstrap";
import ButtonMain from "../core/form/ButtonMain";
import ButtonBorderless from "../core/form/ButtonBorderless";
import UserRoleListContainer from "../../containers/UserRoleListContainer";
import UserStateListContainer from "../../containers/UserStateListContainer";
import { convertEnumToDropdownList } from "../../modules/util";
import { ParticipantRole } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import FieldGroup from "../core/form/FieldGroup";
import Aliases from "../../components/shared/Aliases";
import { Env } from "../../constants/environment";
import UserAttributesForm from "./UserAttributesForm";

class BasicUserForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      participant,
      title,
      handleSubmit,
      setFieldValue,
      onEditForm,
      values,
      errors,
      touched,
      handleBlur,
      firm,
      handleChange,
      isFirmService,
    } = this.props;
    if (!participant) return null;

    const aliasOnChange = (aliases) => {
      values.aliases = aliases;
    };

    return (
      <form onSubmit={handleSubmit}>
        <h1>{title}</h1>
        <Container>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroupReadOnly
                id="userName"
                label={
                  participant.serviceUser ? "Firm Service Name" : "User Name"
                }
                value={participant.displayName}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroupReadOnly
                id="userId"
                label={participant.serviceUser ? "Firm Service ID" : "User ID"}
                value={participant ? participant.id : ""}
              />
            </Col>
          </Row>
          {isFirmService ? (
            <Row>
              <Col lg={6} xs={6} md={12}>
                {onEditForm ? (
                  <FieldGroup
                    id="description"
                    name="description"
                    type="text"
                    label={"Description"}
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.description}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="description"
                    label={"Description"}
                    value={
                      participant.attributes
                        ? participant.attributes.description
                        : null
                    }
                  />
                )}
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroupReadOnly
                id="firm"
                label="Firm"
                value={firm ? firm.displayName : ""}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              {onEditForm ? (
                <UserRoleListContainer
                  id="role"
                  name="role"
                  label="Role"
                  isRequired={false}
                  errors={errors}
                  touched={touched}
                  value={values.role}
                  setFieldValue={setFieldValue}
                  onChange={(e) => {
                    setFieldValue("role", e);
                  }}
                  onBlur={handleBlur}
                  firm={firm}
                  onEdit={true}
                />
              ) : (
                <FieldGroupReadOnly
                  id="userRole"
                  label="Role"
                  value={participant.role ? participant.role.name : ""}
                />
              )}
            </Col>
          </Row>
          <UserAttributesForm
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              isNewService={isFirmService}
              isEditable={onEditForm}
              values={values}
          />

          {!isFirmService ? (
            <Row>
              <Col lg={6} xs={6} md={12}>
                <FieldGroupReadOnly
                  id="group"
                  label="Group"
                  value={participant.groups ? participant.groups : ""}
                />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col lg={6} xs={6} md={12}>
              {onEditForm ? (
                <UserStateListContainer
                  id="state"
                  name="state"
                  label={isFirmService ? "Firm Service State" : "User State"}
                  value={values.state ? values.state.id : null}
                  enabled={true}
                  onChange={(e) => {
                    setFieldValue("state", e);
                  }}
                />
              ) : (
                <FieldGroupReadOnly
                  id="userState"
                  label={isFirmService ? "Firm Service State" : "User State"}
                  value={participant.state ? participant.state.name : null}
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <Aliases onChange={aliasOnChange} aliases={!!values && !!values.aliases ? values.aliases : []} readOnly={!onEditForm} options={Env.getAliasOptions()} />
            </Col>
          </Row>

          {onEditForm ? (
            <div>
              <ButtonMain type="submit" text="SAVE" />
              <ButtonBorderless
                type="button"
                text="Cancel"
                icon="times-circle"
                customClassName="grey-icon"
                onClick={(e) => {
                  e.preventDefault();
                  this.context.router.history.goBack();
                }}
              />
            </div>
          ) : null}
        </Container>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    participant: state.participants.participant,
  };
}

const roleEnum = convertEnumToDropdownList(ParticipantRole);
roleEnum.splice(0, 1);

const formatValues = (values) => {
  return {
    ...values,
    role:
      roleEnum.filter((role) => role.name === values.role).shift() ||
      values.role,
  };
};

const UserForm = withFormik({
  mapPropsToValues: (participant) => ({
    ...participant.participant,
    legalName: participant.participant.attributes
      ? participant.participant.attributes.legalName
      : null,
    title: participant.participant.attributes
      ? participant.participant.attributes.title
      : null,
    email: participant.participant.attributes
      ? participant.participant.attributes.email
      : null,
    phoneNumber: participant.participant.attributes
      ? participant.participant.attributes.phoneNumber
      : null,
    dateOfBirth: participant.participant.attributes
      ? participant.participant.attributes.dateOfBirth
      : null,
    registrationNumber: participant.participant.attributes
      ? participant.participant.attributes.registrationNumber
      : null,
    oauthId: participant.participant.attributes
      ? participant.participant.attributes.oauthId
      : null,
    address: participant.participant.attributes
      ? participant.participant.attributes.address
      : null,
    legalEntityIdentifier: participant.participant.attributes
        ? participant.participant.attributes.legalEntityIdentifier
        : null,
    description: participant.participant.attributes
      ? participant.participant.attributes.description
      : null,
  }),
  validationSchema: Yup.object().shape({
    id: Yup.string().nullable().required("Participant name is required."),
    email: Yup.string().email("Invalid email format"),
  }),
  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.handleEditSubmit(
      formatValues(values),
      props.participant,
      setFieldError
    );
  },
  displayName: "user-form",
  enableReinitialize: true,
})(BasicUserForm);

export default connect(mapStateToProps, null)(UserForm);
