
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import querystring from 'query-string';
import _ from "lodash"

import {
  cleanSearch,
  getFiltered,
  exportData
} from '../actions/timeSales';

import {
  fetchInstrumentIdsList,
  cleanInstrumentsList
} from '../actions/instruments';
import './Content.css';
import { AdminDocumentTitle } from '../constants/strings';
import TimeSalesHeader from '../components/time-and-sales/TimeSalesHeader';
import TimeSalesDataGrid from '../components/time-and-sales/TimeSalesDataGrid';
import PaginationWithPageSize, { withPageToken } from "../components/core/data-grid/PaginationWithPageSize";
import { LoadAccounts } from "../actions/accounts";
import { LoadFirms } from "../actions/firms";
import confirm from "../modules/confirmDialog";
import Notification from "../modules/notifications";
import TradeService from "../services/TradeService";
import { isNullOrUndefined, isNumber } from "../modules/util";
import { NavigationManager } from '../components/core/helpers/NavigationHelper';
import BlockTradeOrderTicket from "../components/block-trade/BlockTradeOrderTicket";
import TimeSalesFilter from '../components/time-and-sales/TimeSalesFilter';
import { Col, Row } from 'react-bootstrap';
import ButtonSecondary from '../components/core/form/ButtonSecondary';
import { hasWriteAccess } from '../services/TokenService';
import { Env } from '../constants/environment';

const {
  TradeState,
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/trades/v1beta1/trades_pb');

const STORAGE_PAGE_SIZE_KEY = "dataGridPageSize-TimeAndSale";
const DEFAULT_PAGE_SIZE = 10;

function mapStateToProps(state) {
  let tnsItems = state.timeSales.items.map((ts) => {
    let _ts = { ...ts }
    let aggressor = null;
    let passive = null;

    if (!!ts && !!ts.aggressor) {
      let index = ts.aggressor.indexOf("/users")
      let ts_aggressor = ts.aggressor.substring(0, index)
      aggressor = state.firms.firms.find((f) =>
        f.name === ts_aggressor
      )
    }

    if (!!ts && !!ts.passive) {
      let index = ts.passive.indexOf("/users")
      let ts_passive = ts.passive.substring(0, index)
      passive = state.firms.firms.find((f) =>
        f.name === ts_passive
      )
    }

    let agg_account = state.accounts.accounts.find((a) =>
      a.name === ts.aggressorAccount
    )

    let pas_account = state.accounts.accounts.find((a) =>
      a.name === ts.passiveAccount
    )

    if (aggressor)
      _ts.aggressor = aggressor.displayName;

    if (passive)
      _ts.passive = passive.displayName;

    if (agg_account)
      _ts.aggressorAccount = agg_account.displayName;

    if (pas_account)
      _ts.passiveAccount = pas_account.displayName;

    _ts.instrumentId = ts.symbol;

    return _ts;
  });

  return {
    fetchingData: state.timeSales.fetchingData,
    items: tnsItems,
    pageToken: state.timeSales.pageToken,
    tokens: state.timeSales.tokens,
    instruments: state.instruments.instrumentIdsList,
    accounts: state.accounts.accounts,
    firms: state.firms.firms
  };
}

const mapDispatchToProps = (dispatch) => ({
  filterData: (startDate, endDate, instrumentId, participant, accounts, orderid, execid, tradeid, clientaccountid, clientparticipantid, parentorderid, symbolsubtype, tradeStates, pageToken, pageSize) => {
    dispatch(getFiltered(startDate, endDate, instrumentId, participant, accounts, orderid, execid, tradeid, clientaccountid, clientparticipantid, parentorderid, symbolsubtype, tradeStates, pageToken, pageSize))
  },
  cleanSearch: () => {
    dispatch(cleanSearch())
  },
  fetchInstrumentIdsList: () => {
    dispatch(fetchInstrumentIdsList())
  },
  cleanInstrumentsList: () => {
    dispatch(cleanInstrumentsList())
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
  loadAccounts: () => {
    dispatch(LoadAccounts());
  },
  exportData: (startDate, endDate, instrumentId, participant, accounts, orderid, execid, tradeid, clientaccountid, clientparticipantid, parentorderid, symbolsubtype, tradeStates) => {
    dispatch(exportData(startDate, endDate, instrumentId, participant, accounts, orderid, execid, tradeid, clientaccountid, clientparticipantid, parentorderid, symbolsubtype, tradeStates))
  }
})

class TimeSalesPage extends Component {
  constructor(props) {
    super(props)

    const queryParams = querystring.parseUrl(window.location.href);
    const hasParams = !_.isEmpty(queryParams.query);
    if (hasParams) {

      const fromDate = isNumber(queryParams.query.fromDate) ? new Date(parseInt(queryParams.query.fromDate)) : null;
      const toDate = isNumber(queryParams.query.toDate) ? new Date(parseInt(queryParams.query.toDate)) : null;
      this.state = {
        ...this.state, ...queryParams.query,
        fromDate: fromDate,
        toDate: toDate
      };
    } else {
      this.state = {
        ...this.state,
        fromDate: new Date(new Date().setHours(0, 0, 0, 0)),
        toDate: new Date(new Date().setHours(23, 59, 59, 999))
      };
    }

    if (!Array.isArray(this.state.tradeStates)) {
      this.state.tradeStates = [this.state.tradeStates];
    }

    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.getPageSizeFromStorage = this.getPageSizeFromStorage.bind(this);
  }

  state = {
    fromDate: null,
    toDate: null,
    instrumentId: null,
    participant: "",
    orderid: "",
    execid: "",
    tradeid: "",
    accounts: null,
    tradeStates: [],
    clientaccountid: "",
    clientparticipantid: "",
    showBlockTrades: false,
    pageSize: DEFAULT_PAGE_SIZE
  }

  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.props.cleanSearch();
    this.props.cleanInstrumentsList();
    this.props.loadFirms();
    this.props.loadAccounts();
    this.getFilteredData(this.state);
    window.scrollTo(0, 0);
  }

  getPaginationComponent = () => {
    const pageToken = this.props.pageToken;
    const tokens = this.props.tokens;
    const pageSize = Number(this.getPageSizeFromStorage());
    return withPageToken(PaginationWithPageSize, { pageToken, tokens }, pageSize);
  }

  showBlockTradesDialog = () => {
    this.setState({ showBlockTrades: true });
  };

  hideBlockTradesDialog = () => {
    this.setState({ showBlockTrades: false });
  };

  applyFilter = (values) => {
    const routeUrl = this.context.router.route.location.pathname;
    const parsedString = querystring.stringify(values, { skipEmptyString: true, skipNull: true });
    this.context.router.history.push(`${routeUrl}?${parsedString}`);
  }

  resetFilter = () => {
    const routeUrl = this.context.router.route.location.pathname;
    this.context.router.history.push(`${routeUrl}`);
  }

  getFilteredData = (values) => {
    const pageSize = Number(this.getPageSizeFromStorage());
    this.props.filterData(values.fromDate, values.toDate, values.instrumentId, values.participant, values.accounts, values.orderid, values.execid, values.tradeid, values.clientaccountid, values.clientparticipantid, values.parentorderid, values.symbolsubtype, values.tradeStates, "", pageSize);
  }

  applyState(values) {
    this.setState({
      fromDate: values.fromDate,
      toDate: values.toDate,
      instrumentId: values.instrumentId,
      participant: values.participant,
      accounts: values.accounts,
      orderid: values.orderid,
      execid: values.execid,
      tradeid: values.tradeid,
      tradeStates: values.tradeStates,
      clientaccountid: values.clientaccountid,
      clientparticipantid: values.clientparticipantid,
      pageToken: "",
      pageSize: Number(this.getPageSizeFromStorage())
    });
  }

  exportData = (values) => {
    this.applyState(values);
    this.props.exportData(values.fromDate, values.toDate, values.instrumentId, values.participant, values.accounts, values.orderid, values.execid, values.tradeid, values.clientaccountid, values.clientparticipantid, values.parentorderid, values.symbolsubtype, values.tradeStates);
  }

  onPageChange = (pageToken) => {
    const pageSize = Number(this.getPageSizeFromStorage());
    const values = this.state;
    this.props.filterData(values.fromDate, values.toDate, values.instrumentId, values.participant, values.accounts, values.orderid, values.execid, values.tradeid, values.clientaccountid, values.clientparticipantid, values.parentorderid, values.symbolsubtype, values.tradeStates, pageToken, pageSize);
  }

  setPageSizeInStorage = (pageSize) => {
    window.localStorage.setItem(STORAGE_PAGE_SIZE_KEY, pageSize)
  }

  getPageSizeFromStorage = () => {
    const pageSize = window.localStorage.getItem(STORAGE_PAGE_SIZE_KEY)

    if (pageSize)
      return pageSize;

    return DEFAULT_PAGE_SIZE;
  }

  onPageSizeChange = (pageSize) => {
    this.setPageSizeInStorage(pageSize);
    this.setState({ pageSize });
    const values = this.state;
    const pageToken = "";
    this.props.filterData(values.fromDate, values.toDate, values.instrumentId, values.participant, values.accounts, values.orderid, values.execid, values.tradeid, values.clientaccountid, values.clientparticipantid, values.parentorderid, values.symbolsubtype, values.tradeStates, pageToken, pageSize);
  }

  bust = (tradeId, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/timeandsales/${tradeId}/bust`);
    } else {
      this.context.router.history.push(`/timeandsales/${tradeId}/bust`);
    }
  }

  view = (tradeId, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/timeandsales/${tradeId}/details`);
    } else {
      this.context.router.history.push(`/timeandsales/${tradeId}/details`);
    }
  }

  retryRequest = (tradeId) => {
    confirm(`Are you sure you want to retry clearing on ${tradeId}?`, {
      title: "Retry Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(() => {
      TradeService.updateTrade(tradeId, TradeState.TRADE_STATE_RETRY_REQUEST, "", (err, response) => {
        if (response) {
          Notification.success("Trade retry requested.");
          if (!isNullOrUndefined(this.props.pageToken)) this.onPageChange(this.props.pageToken);
        }
        if (err) {
          Notification.error(err.message);
        }
      });
    }, () => { });
  }

  render() {
    let shouldShowBlockTrades = Env.getEnv("REACT_APP_SHOW_BLOCK_TRADES");
    if (!!shouldShowBlockTrades && !isNullOrUndefined(shouldShowBlockTrades)) {
      shouldShowBlockTrades = shouldShowBlockTrades.toString().toLowerCase() === "true" || shouldShowBlockTrades === 1;
    } else {
      shouldShowBlockTrades = false;
    }

    return (
      <div className="with-callback">
        <TimeSalesHeader />
        <Row style={{ marginBottom: "10px" }}>
          <Col lg={9} xs={9} md={9}>
            <TimeSalesFilter
              filters={this.state}
              onApplyFilter={this.applyFilter}
              onResetFilter={this.resetFilter}
              loading={this.fetchingData}
              autoCompleteDataSource={this.props.instruments}
              onRefreshAutoComplete={() => { this.props.fetchInstrumentIdsList() }}
            />
          </Col>

          <Col lg={3} xs={3} md={3}>
            <div style={{ float:"right" }}>
              <ButtonSecondary
                text="Export Data"
                type="button"
                onClick={() => {
                  this.exportData(this.state)
                }}
              />

              {!!shouldShowBlockTrades && hasWriteAccess() &&
                <ButtonSecondary
                  text="Block Trade"
                  type="button"
                  onClick={() => {
                    this.showBlockTradesDialog();
                  }}
                />}
            </div>
          </Col>
        </Row>

        <TimeSalesDataGrid
          data={this.props.items}
          onBust={(tradeId, actionContext) => { this.bust(tradeId, actionContext) }}
          onPageChange={this.onPageChange}
          onPageSizeChange={this.onPageSizeChange}
          paginationComponent={this.getPaginationComponent()}
          onView={(tradeId, actionContext) => { this.view(tradeId, actionContext) }}
          onRetry={(tradeId, actionContext) => { this.retryRequest(tradeId, actionContext) }}
        />

        {(this.state.showBlockTrades === true) && <div><BlockTradeOrderTicket onClose={() => { this.hideBlockTradesDialog() }} /></div>}
      </div >
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeSalesPage);
