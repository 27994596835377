import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import ButtonBorderless from "../core/form/ButtonBorderless";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import Loader from "../core/loader/Loader";
import { hasWriteAccess } from "../../services/TokenService";
import { convertEnumToDropdownList, isNullOrUndefined } from "../../modules/util";
import ButtonMain from "../core/form/ButtonMain";
import Tooltip from '../core/tooltip-html/tooltip-html';

function mapStateToProps(state) {
  return {
    firm: state.firms.firm,
    firms: state.firms.firms,
    affiliatedFirms: state.firms.affiliatedFirms,
    associatedFirms: state.firms.associatedFirms,
    associatedRFQFirms: state.firms.associatedRFQFirms,
    associatedAgentFirms: state.firms.associatedAgentFirms,
    associatedClearingHouses: state.firms.associatedClearingHouses,
    fetchingFirms: state.firms.fetchingFirms,
    fetchingAssociatedFirms: state.firms.fetchingAssociatedFirms,
    updatingAssociatedFirms: state.firms.updatingAssociatedFirms,
  };
}

const listGroupStyle = {
  maxHeight: "500px",
  overflow: "auto",
  display: 'block',
};

const spacingStyle = {
  paddingTop: "20px",
  paddingBottom: "20px",
};

export const filterType = {
  affiliatedFirms: "affiliated",
  associatedFirms: "associated",
  associatedClearing: "associatedClearing",
  associatedRFQ: "associatedRFQ",
  associatedAgent: "associatedAgent",
}

const firmTypeObj = convertEnumToDropdownList(FirmType).reduce(
  (obj, item) => Object.assign(obj, { [item["id"]]: item["name"] }),
  {}
);

export class FirmAssociations extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  state = {
    available: {},
    associated: {},
  };

  componentDidMount() {
    let {
      decodedId,
      firm,
      firms,
      loadFirm,
      loadFirms,
      loadAffiliatedFirms,
      loadAssociatedRFQFirms,
      loadAssociatedAgentFirms,
      loadAssociatedFirms,
      loadAssociatedClearingHouses,
    } = this.props;
    if (!firm || !firm.id) {
      loadFirm(decodedId);
    }

    //on refresh
    if (firms.length === 0) {
      loadFirm(decodedId).then((firm) => {
        if (
          firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT
        ) {
          loadFirms(() => loadAffiliatedFirms(this.loadAvailableAffiliates));
          loadFirms(() => loadAssociatedRFQFirms(this.loadAvailableRFQFirms));
        }

        if (
          firm.firmType.id === FirmType.FIRM_TYPE_AGENT ||
          firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT
        )
          loadFirms(() => loadAssociatedAgentFirms(this.loadAvailableAgentFirms));

        if (
          firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_MEMBER ||
          firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT
        )
          loadFirms(() => loadAssociatedFirms(this.loadAvailableFirms));

        if (
          firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_MEMBER ||
          firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_HOUSE
        )
          loadFirms(() =>
            loadAssociatedClearingHouses(this.loadAvailableClearingHouses)
          );
      });
    }

    //on page navigation
    if (firm.id) {
      if (
        firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT
      ) {
        loadAffiliatedFirms(this.loadAvailableAffiliates);
        loadAssociatedRFQFirms(this.loadAvailableRFQFirms);
      }

      if (
        firm.firmType.id === FirmType.FIRM_TYPE_AGENT ||
        firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT
      )
        loadAssociatedAgentFirms(this.loadAvailableAgentFirms);

      if (
        firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_MEMBER ||
        firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT
      )
        loadAssociatedFirms(this.loadAvailableFirms);

      if (
        firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_MEMBER ||
        firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_HOUSE
      )
        loadAssociatedClearingHouses(this.loadAvailableClearingHouses);
    }
  }

  loadAvailableAffiliates = () => {
    let { firm, firms, affiliatedFirms } = this.props;
    if (!firm || !firm.id) return;

    let available = {
      ...this.state.available,
      [filterType.affiliatedFirms + FirmType.FIRM_TYPE_PARTICIPANT]: {},
    };

    let associated = {
      ...this.state.associated,
      [filterType.affiliatedFirms + FirmType.FIRM_TYPE_PARTICIPANT]: {},
    };

    const myId = firm.id;
    firms.forEach((firm) => {
      if (firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT && firm.id !== myId) {
        if (affiliatedFirms.includes(firm.name)) {
          associated[filterType.affiliatedFirms + FirmType.FIRM_TYPE_PARTICIPANT][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
        } else
          available[filterType.affiliatedFirms + FirmType.FIRM_TYPE_PARTICIPANT][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
      }
    });

    this.setState({
      available: available,
      associated: associated,
    });
  };

  loadAvailableRFQFirms = () => {
    let { firm, firms, associatedRFQFirms } = this.props;
    if (!firm || !firm.id) return;

    let available = {
      ...this.state.available,
      [filterType.associatedRFQ + FirmType.FIRM_TYPE_PARTICIPANT]: {},
    };

    let associated = {
      ...this.state.associated,
      [filterType.associatedRFQ + FirmType.FIRM_TYPE_PARTICIPANT]: {},
    };

    const myId = firm.id;
    firms.forEach((firm) => {
      if (firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT && firm.id !== myId) {
        if (associatedRFQFirms.includes(firm.name)) {
          associated[filterType.associatedRFQ + FirmType.FIRM_TYPE_PARTICIPANT][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
        } else
          available[filterType.associatedRFQ + FirmType.FIRM_TYPE_PARTICIPANT][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
      }
    });

    this.setState({
      available: available,
      associated: associated,
    });
  };

  loadAvailableAgentFirms = () => {
    let { firm, firms, associatedAgentFirms } = this.props;
    if (!firm || !firm.id) return;

    let available = {
      ...this.state.available,
      [filterType.associatedAgent + FirmType.FIRM_TYPE_AGENT]: {},
      [filterType.associatedAgent + FirmType.FIRM_TYPE_PARTICIPANT]: {},
    };

    let associated = {
      ...this.state.associated,
      [filterType.associatedAgent + FirmType.FIRM_TYPE_AGENT]: {},
      [filterType.associatedAgent + FirmType.FIRM_TYPE_PARTICIPANT]: {},
    };

    firms.forEach((firm) => {
      if (firm.firmType.id === FirmType.FIRM_TYPE_AGENT) {
        if (associatedAgentFirms.includes(firm.name)) {
          associated[filterType.associatedAgent + FirmType.FIRM_TYPE_AGENT][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
        } else
          available[filterType.associatedAgent + FirmType.FIRM_TYPE_AGENT][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
      }

      if (firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT) {
        if (associatedAgentFirms.includes(firm.name)) {
          associated[filterType.associatedAgent + FirmType.FIRM_TYPE_PARTICIPANT][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
        } else
          available[filterType.associatedAgent + FirmType.FIRM_TYPE_PARTICIPANT][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
      }
    });

    this.setState({
      available: available,
      associated: associated,
    });
  };

  loadAvailableFirms = () => {
    let { firm, firms, associatedFirms } = this.props;
    if (!firm || !firm.id) return;

    let available = {
      ...this.state.available,
      [filterType.associatedFirms + FirmType.FIRM_TYPE_CLEARING_MEMBER]: {},
      [filterType.associatedFirms + FirmType.FIRM_TYPE_PARTICIPANT]: {},
    };

    let associated = {
      ...this.state.associated,
      [filterType.associatedFirms + FirmType.FIRM_TYPE_CLEARING_MEMBER]: {},
      [filterType.associatedFirms + FirmType.FIRM_TYPE_PARTICIPANT]: {},
    };

    firms.forEach((firm) => {
      if (firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_MEMBER) {
        if (associatedFirms.includes(firm.name)) {
          associated[filterType.associatedFirms + FirmType.FIRM_TYPE_CLEARING_MEMBER][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
        } else
          available[filterType.associatedFirms + FirmType.FIRM_TYPE_CLEARING_MEMBER][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
      }

      if (firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT) {
        if (associatedFirms.includes(firm.name)) {
          associated[filterType.associatedFirms + FirmType.FIRM_TYPE_PARTICIPANT][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
        } else
          available[filterType.associatedFirms + FirmType.FIRM_TYPE_PARTICIPANT][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
      }
    });

    this.setState({
      available: available,
      associated: associated,
    });
  };

  loadAvailableClearingHouses = () => {
    let { firm, firms, associatedClearingHouses } = this.props;
    if (!firm || !firm.id) return;

    let available = {
      ...this.state.available,
      [filterType.associatedClearing + FirmType.FIRM_TYPE_CLEARING_MEMBER]: {},
      [filterType.associatedClearing + FirmType.FIRM_TYPE_CLEARING_HOUSE]: {},
    };

    let associated = {
      ...this.state.associated,
      [filterType.associatedClearing + FirmType.FIRM_TYPE_CLEARING_MEMBER]: {},
      [filterType.associatedClearing + FirmType.FIRM_TYPE_CLEARING_HOUSE]: {},
    };

    firms.forEach((firm) => {
      if (firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_MEMBER) {
        if (associatedClearingHouses.includes(firm.name)) {
          associated[filterType.associatedClearing + FirmType.FIRM_TYPE_CLEARING_MEMBER][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
        } else
          available[filterType.associatedClearing + FirmType.FIRM_TYPE_CLEARING_MEMBER][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
      }

      if (firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_HOUSE) {
        if (associatedClearingHouses.includes(firm.name)) {
          associated[filterType.associatedClearing + FirmType.FIRM_TYPE_CLEARING_HOUSE][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
        } else
          available[filterType.associatedClearing + FirmType.FIRM_TYPE_CLEARING_HOUSE][firm.id] = {
            displayName: firm.displayName,
            active: false,
          };
      }
    });

    this.setState({
      available: available,
      associated: associated,
    });
  };

  getFirms = (firms, type, filterType, firmType) => {
    const listItems = [];
    if (!firms) return;

    for (const [key, value] of Object.entries(firms)) {
      if (value.active) {
        listItems.push(
          <ListGroup.Item
            key={key}
            action
            active
            onClick={() => this.selectFirm(key, type, filterType, firmType)}
          >
            {value.displayName}
          </ListGroup.Item>
        );
      } else {
        listItems.push(
          <ListGroup.Item
            key={key}
            action
            onClick={() => this.selectFirm(key, type, filterType, firmType)}
          >
            {value.displayName}
          </ListGroup.Item>
        );
      }
    }

    return (
      <ListGroup style={listGroupStyle} className="justify-content-md-center">
        {listItems.map((item) => item)}
      </ListGroup>
    );
  };

  selectFirm = (selectedFirm, type, filterType, firmType) => {
    if (!hasWriteAccess()) {
      return;
    }

    let { available, associated } = this.state;

    if (type === "available") {
      available[filterType + firmType][selectedFirm]["active"] = !available[filterType + firmType][
        selectedFirm
      ]["active"];
      this.setState({ available: available });
    } else {
      associated[filterType + firmType][selectedFirm]["active"] = !associated[filterType + firmType][
        selectedFirm
      ]["active"];
      this.setState({ associated: associated });
    }
  };

  moveFirms = (fromFirm, toFirm, type, filterType, firmType) => {
    let { available, associated } = this.state;

    for (const [id, value] of Object.entries(fromFirm)) {
      if (value.active) {
        toFirm[id] = { displayName: value.displayName, active: false };
        delete fromFirm[id];
      }
    }

    if (type === "available")
      this.setState({
        available: { ...available, [filterType + firmType]: fromFirm },
        associated: { ...associated, [filterType + firmType]: toFirm },
      });
    else
      this.setState({
        available: { ...available, [filterType + firmType]: toFirm },
        associated: { ...associated, [filterType + firmType]: fromFirm },
      });
  };

  cancel = () => {
    return this.context.router.history.push(
      `/firms/${this.props.decodedId}/association`
    );
  };

  pageHeader = (header, margin) => {
    return (
      <div>
        <p style={{marginLeft: margin}}><h5>{header}</h5></p>
        <br></br>
      </div>)
  }

  render() {
    let { firm } = this.props;
    if (firm && firm.firmType) {
      switch (firm.firmType.id) {
        case FirmType.FIRM_TYPE_PARTICIPANT:
          return (
            <div>
              {this.pageHeader("Participant Firm Associations", '355px')}
              {this.renderAssociationsBox(filterType.affiliatedFirms, FirmType.FIRM_TYPE_PARTICIPANT, "Available Participant Firms", "Affilliated Participant Firms", "Participant Firms Affiliated with " + firm.displayName, "Associate any Participant Firms that are affiliated with this participant firm. These could be subsidiaries of the same parent company.")}
              <br />
              {this.renderAssociationsBox(filterType.associatedRFQ, FirmType.FIRM_TYPE_PARTICIPANT, "Available Participant Firms", "Associated RFQ Participant Firms", "RFQ Participant Firms for " + firm.displayName, "RFQ Participant Firms are firms that " + firm.displayName + " will accept RFQs from.")}
              <br />
              {this.renderAssociationsBox(filterType.associatedAgent, FirmType.FIRM_TYPE_AGENT, "Available Agents / Brokers", "Associated Agents / Brokers", "Agents for " + firm.displayName, "Agents are Participant Firms, typically brokers, that can enter orders on behalf of the participant firm.")}
              <br />
              {this.renderAssociationsBox(filterType.associatedFirms, FirmType.FIRM_TYPE_CLEARING_MEMBER, null, null, "Clearing Members associated with " + firm.displayName, "Clearing Members where the participant firm has accounts.")}
            </div>
          );
        case FirmType.FIRM_TYPE_CLEARING_MEMBER:
          return (
            <div>
              {this.pageHeader("Clearing Member Firm Associations", '270px')}
              {this.renderAssociationsBox(filterType.associatedFirms, FirmType.FIRM_TYPE_PARTICIPANT, null, null, "Participant Firms Associated with " + firm.displayName, "Associating a Participant Firm to a Clearing Member indicates that the Participant Firm submits trades for clearance and settlement with this Clearing Member.")}
              <br />
              {this.renderAssociationsBox(filterType.associatedClearing, FirmType.FIRM_TYPE_CLEARING_HOUSE, null, null, "Clearing Houses Associated with " + firm.displayName, "Associate the Clearing Houses that this Clearing Member belongs to.")}
            </div>
          );
        case FirmType.FIRM_TYPE_CLEARING_HOUSE:
          return (
            <div>
              {this.pageHeader("Clearing House Firm Associations", '270px')}
              {this.renderAssociationsBox(filterType.associatedClearing, FirmType.FIRM_TYPE_CLEARING_MEMBER, null, null, "Clearing Member Firms Associated with " + firm.displayName, "Associate the Clearing Members that have clearing privileges with this clearing house.")}
            </div>
          );
        case FirmType.FIRM_TYPE_AGENT:
          return (
            <div>
              {this.pageHeader("Agent Firm Associations", '230px')}
              {this.renderAssociationsBox(filterType.associatedAgent, FirmType.FIRM_TYPE_PARTICIPANT, "Available Participant Firms", "Associated Participant Firms", "Participant Firms Associated with " + firm.displayName, "Associate the Participant Firms that the Agent Firm trades on behalf of.")}
            </div>
          );
        case FirmType.FIRM_TYPE_UNDEFINED:
        case FirmType.FIRM_TYPE_SUPERVISOR:
          return <div>N/A</div>;
        default:
          return <div>N/A</div>;
      }
    }
    return <div></div>;
  }

  renderAssociationsBox = (filterType, firmType, availableHeading, associatedHeading, title, tooltip) => {
    let firmLabel = "Associated"
    let { available, associated } = this.state;

    let getAvailableHeading = () => {
      if (isNullOrUndefined(availableHeading)) {
        return (<b>Available {firmTypeObj[firmType]}s</b>)
      }
      return (
        <b>
          {availableHeading}
        </b>
      );
    }

    let getAssociatedHeading = () => {
      if (isNullOrUndefined(associatedHeading)) {
        return (<b>{firmLabel} {firmTypeObj[firmType]}s</b>)
      }
      return (<b>{associatedHeading}</b>)
    }

    let formatTitle = (title, tooltip) => {
      return (
      <>
      <b><span className="firm-associations-headers">{title}</span></b>
      <Tooltip
          text = {tooltip}
          id = {tooltip.split(" ").join("")}
        ></Tooltip>
      </>)
    }

    return (
      <Container>
        <Col
          style={spacingStyle}
          lg={10}
          xs={10}
          md={10}
          className="border border-dark"
        >
          <Row className="justify-content-md-center">
            <Col lg={12} xs={12} md={12}>
              <h6>
                <center><b>{formatTitle(title, tooltip)}</b></center><br></br>
              </h6>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col lg={4} xs={4} md={4}>
              <h6>
                {getAvailableHeading()}
              </h6>
            </Col>
            <Col lg={2} xs={2} md={2}></Col>
            <Col lg={4} xs={4} md={4}>
              <h6>
                {getAssociatedHeading()}
              </h6>
            </Col>
          </Row>
          <Row style={spacingStyle} className="justify-content-md-center">
            <Col lg={4} xs={4} md={4}>
              <Loader show={this.props.fetchingFirms}></Loader>
              {this.getFirms(available[filterType + firmType], "available", filterType, firmType)}
            </Col>
            <Col lg={2} xs={2} md={2}>
              <Row className="justify-content-md-center">
                <ButtonBorderless
                  type="button"
                  icon="arrow-alt-circle-right"
                  size="2x"
                  enabled={hasWriteAccess()}
                  onClick={() =>
                    this.moveFirms(
                      available[filterType + firmType],
                      associated[filterType + firmType],
                      "available",
                      filterType,
                      firmType
                    )
                  }
                />
              </Row>
              <Row className="justify-content-md-center">
                <ButtonBorderless
                  type="button"
                  icon="arrow-alt-circle-left"
                  size="2x"
                  enabled={hasWriteAccess()}
                  onClick={() =>
                    this.moveFirms(
                      associated[filterType + firmType],
                      available[filterType + firmType],
                      "associated",
                      filterType,
                      firmType
                    )
                  }
                />
              </Row>
            </Col>
            <Col lg={4} xs={4} md={4}>
              <Loader show={this.props.fetchingAssociatedFirms}></Loader>
              {this.getFirms(associated[filterType + firmType], "associated", filterType, firmType)}
            </Col>
          </Row>
          <Row style={spacingStyle}>
            <Col
              lg={{ span: 2.5, offset: 1 }}
              xs={{ span: 2.5, offset: 1 }}
              md={{ span: 2.5, offset: 1 }}
            >
              <ButtonMain
                text={`Add New ${firmTypeObj[firmType]}`}
                type="button"
                onClick={() =>
                  this.context.router.history.push(
                    `/firms/new/${firmTypeObj[firmType]}`
                  )
                }
                customClassName="btn-main-header"
                enabled={hasWriteAccess()}
              />
            </Col>
            <Col
              lg={{ span: 2.5, offset: 8 }}
              xs={{ span: 2.5, offset: 8 }}
              md={{ span: 2.5, offset: 8 }}
            >
              {this.props.updatingAssociatedFirms ? (
                <Loader show={this.props.updatingAssociatedFirms}></Loader>
              ) : (
                <ButtonBorderless
                  type="button"
                  icon="sync-alt"
                  size="2x"
                  text="Update"
                  enabled={hasWriteAccess()}
                  onClick={() =>
                    this.props.updateAssociatedFirms({
                      firmType: this.props.firm.firmType.id,
                      dataType: firmType,
                      filterType: filterType,
                      list: associated[filterType + firmType],
                    })
                  }
                />
              )}

              <ButtonBorderless
                type="button"
                icon="times"
                size="2x"
                text="Cancel"
                enabled={hasWriteAccess()}
                onClick={this.cancel}
              />
            </Col>
          </Row>
        </Col>
      </Container>
    );
  };
}

export default connect(mapStateToProps, null)(FirmAssociations);
