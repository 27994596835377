import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import FieldGroup from "../../core/form/FieldGroup";
import FieldGroupReadOnly from "../../core/form/FieldGroupReadOnly";
import ProductListContainer from "../../../containers/ProductListContainer";
import CheckboxGroup from "../../core/form/CheckboxGroup";
import { validateField, STRING_TYPE } from '../instrumentFormUtils'
import * as Yup from "yup";
import { BASIS_SWAP, EVENT, MULTILEG, ZERO_COUPON_INFLATION_SWAP } from "../../../constants/strings";
import { FormModes } from "../../../constants/enumerations";
import AutoComplete from "../../core/auto-complete/AutoComplete";
import * as toolTips from '../../../constants/instrumentToolTip';

let selectedInstrumentType = null;

export const Header = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  handleChange,
  isEditing,
  onProductListChange,
  onEventIdChange,
  instrumentType,
  state,
  updateState,
  errors,
  mode
}) => {
  const isViewing = mode === FormModes.view;

  selectedInstrumentType = instrumentType;

  let getInstrumentType = () => {
    return state.type || values.type
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg={3} xs={3} md={3}>
          <ProductListContainer id="productId" name="productId" label="Product"
            errors={errors}
            value={!state.selectedProductId ? values.productId : state.selectedProductId}
            enabled={values.isEditable}
            onChange={(e) => { updateState({ selectedProductId: e.id }); onProductListChange(e); }}
            plaintext={isEditing || isViewing}
          />
        </Col>
        <Col lg={3} xs={3} md={3}>
          <FieldGroup id="id" name="id" type="text" label="Instrument ID (Symbol)" help="Symbol must be unique."
            isRequired={true}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            value={!state.instrumentId ? values.id : state.instrumentId}
            onChange={(e) => { handleChange(e); updateState({ instrumentId: e.target.value }); }}
            onBlur={handleBlur}
            plaintext={isEditing || isViewing}
          />

        </Col>
        <Col lg={4} xs={4} md={4}>
          <FieldGroup id="description" name="description" type="textarea" label="Instrument Description"
            tooltip={toolTips.INSTRUMENT_DESCRIPTION}
            disabled={!values.isPartialEditable}
            setFieldValue={setFieldValue}
            value={values.description}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            onBlur={handleBlur}
            plaintext={isViewing}
          />
        </Col>
        <Col lg={2} xs={2} md={2}>
          <FieldGroup
            id="cfiCode" name="cfiCode" type="textarea" label="CFI Code"
            tooltip="Six-letter Classification of Financial Instruments code. Required only if EP3 will be creating the Part 16.02 Trade Capture Report for the U.S. CFTC"
            disabled={!values.isPartialEditable}
            isRequired={false}
            setFieldValue={setFieldValue}
            value={values.cfiCode}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            onBlur={handleBlur}
            plaintext={isViewing}
          />
        </Col>
      </Row>

      {instrumentType === EVENT && <Row>
        <Col lg={4} xs={4} md={4}>
          <Form.Label>Event ID</Form.Label>
          <label className="form-label-required"> Required</label>
          <AutoComplete
            id="evtAttEventId"
            placeholder="Event ID"
            dataSource={state.eventIds}
            defaultValue={values.evtAttEventId ? values.evtAttEventId : null}
            onChange={(e) => {
              handleChange(e);
              updateState({ evtAttEventId: e.target.value });
              onEventIdChange(e.target.value);
              setFieldValue("evtAttEventId", e.target.value);
            }}
            onEnter={(value) => {
              updateState({ evtAttEventId: value });
              onEventIdChange(value);
              setFieldValue("evtAttEventId", value);
            }}
            onSelect={(value) => {
              updateState({ evtAttEventId: value });
              onEventIdChange(value);
              setFieldValue("evtAttEventId", value);
            }}
            errors={errors}
            disabled={isEditing || isViewing}
          />
        </Col>
        <Col lg={4} xs={4} md={4}>
          <FieldGroup id="evtAttEventDisplayName" name="evtAttEventDisplayName" type="text" label="Event Description"
            isRequired={true}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            value={!state.evtAttEventDisplayName ? values.evtAttEventDisplayName : state.evtAttEventDisplayName}
            onChange={(e) => { handleChange(e); updateState({ evtAttEventDisplayName: e.target.value }); }}
            onBlur={handleBlur}
            plaintext={isEditing || isViewing}
          />
        </Col>
      </Row>}

      <Row>
        <Col lg={3} xs={3} md={3}>
          <FieldGroupReadOnly label="Instrument Type" value={getInstrumentType()} />
        </Col>
        <Col lg={3} xs={3} md={3}>
          <FieldGroup id="routingkey" name="routingkey" type="textarea" label="Routing Key"
            disabled={!values.isEditable || instrumentType === MULTILEG || instrumentType === BASIS_SWAP || instrumentType === ZERO_COUPON_INFLATION_SWAP}
            tooltip={toolTips.ROUTING_KEY}
            setFieldValue={setFieldValue}
            value={values.routingkey}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            onBlur={handleBlur}
            plaintext={isViewing}
          />
        </Col>
        <Col lg={3} xs={3} md={3}>
          <FieldGroup id="externalSymbol" name="externalSymbol" type="textarea" label="External Symbol"
          tooltip={toolTips.EXTERNAL_SYMBOL}
            disabled={!values.isEditable}
            setFieldValue={setFieldValue}
            value={values.externalSymbol}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            onBlur={handleBlur}
            plaintext={isViewing}
          />
        </Col>
        <Col lg={3} xs={3} md={3}>
          <div className="checkbox form-control" style={{ height: "80px", paddingTop: "10px", }}>
            <Row style={{ marginBottom: "-6px" }} >
              <Col lg={6} xs={6} md={6}>
                <CheckboxGroup
                  checked={values.nonTradable}
                  disabled={isViewing}
                  onClick={(e) => {
                    if (values.isEditable) {
                      setFieldValue("nonTradable", e.target.checked);
                      if (!e.target.checked) { setFieldValue("hideMarketData", false); }
                    }
                  }}
                  label={"Non-Tradable Instrument"} />
              </Col>
            </Row>

            <Row style={{ marginBottom: "0px" }}>
              <Col lg={6} xs={6} md={6}>
                <CheckboxGroup
                  checked={values.hideMarketData}
                  disabled={isViewing || !values.nonTradable}
                  onClick={(e) => { setFieldValue("hideMarketData", e.target.checked); }}
                  label={"Hide Market Data"} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const ValidateHeaders = (values, errors) => {
  errors = validateField(values, errors, "productId", "Product", STRING_TYPE, values);

  if (selectedInstrumentType === EVENT) {
    errors = validateField(values, errors, "evtAttEventId", "Event ID", STRING_TYPE, values);
    errors = validateField(values, errors, "evtAttEventDisplayName", "Event Description", STRING_TYPE, values);
  }

  return errors;
}

export const HeaderSchemaValidation = () => {
  return {
    id: Yup.string().nullable().required("Instrument ID is required."),
  }
}
