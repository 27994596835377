

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Loader from '../components/core/loader/Loader';
import PropTypes from 'prop-types';
import confirm from '../modules/confirmDialog';
import CheckListPopup from '../components/core/checklist-popup';
import {
  FetchParticipantsListStart,
  FetchParticipantsListSucceed,
  FetchParticipantsListFailed
} from '../actions/participantGroups';
import {
  Row,
  Col
} from 'react-bootstrap';
import '../pages/Content.css';
import Participant from '../entities/Participant';
import ParticipantsInGroupDataGrid from '../components/participants-in-group/ParticipantsInGroupDataGrid';
import ParticipantCheckListItem from '../entities/dto/ParticipantCheckListItem';
import Notification from '../modules/notifications';
import ParticipantGroupService from '../services/ParticipantGroupService';
import ParticipantService from '../services/ParticipantService';
import ButtonMain from '../components/core/form/ButtonMain';
import { NavigationManager } from '../components/core/helpers/NavigationHelper';

const ModalMessage = ({
  groupName
}) => {
  return (
    <div>
      Select Participants to add to Group <strong>"{groupName}"</strong>
    </div>
  )
};

const RemoveParticipantMessage = ({
  groupName
}) => {
  return (
    <div>
      Are you sure you want to remove this Participant from Group <strong>"{groupName}"</strong>?
    </div>
  )
};

function mapStateToProps(state) {
  return {
    fetchingParticipantsList: state.participantGroups.fetchingParticipantsList,
    participants: state.participantGroups.participantsList,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchParticipantsStart: () => {
    dispatch(FetchParticipantsListStart())
  },
  fetchParticipantsSucceed: (participants) => {
    dispatch(FetchParticipantsListSucceed(participants))
  },
  fetchParticipantsFailed: () => {
    dispatch(FetchParticipantsListFailed())
  },
});

class ParticipantsInGroupContainer extends Component {
  state = {
    showParticipantPopup: false,
    selectedParticipantsToAdd: [],
    participantsToSelect: [],
    groupName: ''
  }

  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    const instrumentId = this.props.match.params.id;
    if (instrumentId) {
      this.setState({ groupName: instrumentId })
      this.loadParticipantGroups(instrumentId);
    }
  }

  loadParticipantGroups = (id) => {
    this.props.fetchParticipantsStart();
    const cb = (err, response) => {
      if (response) {
        var participantsList = response.getParticipantsList().map((participant) => {
          return new Participant(participant)
        });
        this.props.fetchParticipantsSucceed(participantsList);
        this.loadParticipantsNotInGroup();
      }

      if (err) {
        this.props.fetchParticipantsFailed();
        Notification.error('Cannot get participants list.');
      }
    };

    ParticipantGroupService.getParticipants(id, cb);
  }

  loadParticipantsNotInGroup = () => {
    const cb = (err, response) => {
      if (response) {
        let participants = response.getParticipantsList();
        if (this.props.group && this.props.group.firm) {
          participants = participants.filter(item => item.getFirm() === this.props.group.firm);
        }
        var participantsList = participants.map((participant) => {
          return new ParticipantCheckListItem(new Participant(participant))
        });

        if (this.props.participants) {
          for (let index = 0; index < this.props.participants.length; index++) {
            const participantId = this.props.participants[index].id;
            const element = participantsList.find(p => p.id === participantId);
            if (element) {
              var removeIndex = participantsList.map(function (item) { return item.id; })
                .indexOf(participantId);
              participantsList.splice(removeIndex, 1);
            }
          }
        }
        this.setState({ participantsToSelect: participantsList });
      }

      if (err) {
        Notification.error('Cannot check participants list.');
      }
    };

    ParticipantService.getAll(cb);
  }

  saveParticipants = () => {
    const participantIds = this.state.selectedParticipantsToAdd.map(function (item) {
      return item['name'];
    });

    const cb = (err, response) => {
      if (response) {
        Notification.success('Participant/s successfully added.');
        this.closeParticipantsPoup();
        this.loadParticipantGroups(this.state.groupName);
      }

      if (err) {
        Notification.error(`An error occurred while adding participants: ${err.message}`);
      }
    };

    ParticipantGroupService.addParticipants(
      this.state.groupName,
      participantIds,
      cb
    );
  }

  closeParticipantsPoup = () => {
    this.setState({
      showParticipantPopup: false,
      selectedParticipantsToAdd: []
    });
  }

  openViewParticipant = (name, actionContext) => {
    let viewUrl = `/users/${name}`; //support legacy users

    if (name.startsWith("firms/")) {
      viewUrl = `/${name}`;
    }

    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(viewUrl);
    }
    else {
      this.context.router.history.push(viewUrl);
    }
  };

  openEditParticipant = (name, actionContext) => {
    let viewUrl = `/users/${name}/edit`; //support legacy users

    if (name.startsWith("firms/")) {
      viewUrl = `/${name}/edit`;
    }

    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(viewUrl);
    }
    else {
      this.context.router.history.push(viewUrl);
    }
  };

  removeParticipant = participantId => {
    confirm(<RemoveParticipantMessage groupName={this.state.groupName}></RemoveParticipantMessage>,
      {
        title: 'Participant Confirmation',
        okButtonText: 'Yes',
        cancelButtonText: 'No'
      }
    ).then(
      () => {

        const cb = (err, response) => {
          if (response) {
            Notification.success('Participant successfully removed.');
            this.loadParticipantGroups(this.state.groupName);
          }

          if (err) {
            Notification.error(`An error occurred while removing the participant: ${err.message}`);
          }
        };

        let participantIdArray = [];
        participantIdArray.push(participantId);

        ParticipantGroupService.removeParticipant(
          this.state.groupName,
          participantIdArray,
          cb
        );
      },
      () => {
      });
  }

  onSelectParticipant = (participant) => {
    let existingList = _.clone(this.state.selectedParticipantsToAdd);
    const checkItem = this.state.participantsToSelect.find(item => item.id === participant.id);
    let pushItem = _.clone(checkItem);
    pushItem.isSelected = participant.isSelected;
    if (pushItem.isSelected) {
      existingList.push(pushItem);
    } else {
      const existingItem = existingList.find(x => x.id === participant.id);
      if (existingItem) {
        const removeIndex = existingList.map(function (item) { return item.id; })
            .indexOf(participant.id);
        existingList.splice(removeIndex, 1);
      }
    }

    this.setState({ selectedParticipantsToAdd: existingList });


    const copy = _.clone(this.state.participantsToSelect);
    const currentItem = copy.find(x => x.id === participant.id);
    const itemIndex = copy.indexOf(currentItem);
    copy[itemIndex] = pushItem;
    this.setState({ participantsToSelect: copy });
  }

  render() {
    return (
      <div>
        <Row>
          <Col lg={9} xs={9} md={9}>
            <div className="page-sub-title">Participants</div>
          </Col>
          <Col lg={3} xs={3} md={3}>
            <ButtonMain
              text="Add Participants"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showParticipantPopup: true })
              }}
              customClassName="btn-main-header"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} xs={12} md={12}>
            <Loader show={this.props.fetchingParticipantsList}></Loader>
            <div className="with-callback">
              <ParticipantsInGroupDataGrid
                data={this.props.participants}
                onView={(participantName, actionContext) => this.openViewParticipant(participantName, actionContext)}
                onEdit={(participantName, actionContext) => this.openEditParticipant(participantName, actionContext)}
                onDelete={(participantName, actionContext) => this.removeParticipant(participantName, actionContext)}
                showCustomerOrderCapacity={false}
              />
            </div>
          </Col>
        </Row>
        <CheckListPopup
          show={this.state.showParticipantPopup}
          title="Add Participants"
          onOk={() => { this.saveParticipants() }}
          onCancel={() => { this.closeParticipantsPoup() }}
          okButtonText="Add PArticipants"
          cancelButtonText="Cancel"
          onSelectItem={(participant) => { this.onSelectParticipant(participant); }}
          columnText="PARTICIPANT NAME"
          columnAccesor="displayName"
          selectorId="displayName"
          customText={<ModalMessage groupName={this.state.groupName}></ModalMessage>}
          data={this.state.participantsToSelect}
          showCustomerOrderCapacity={false}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ParticipantsInGroupContainer));