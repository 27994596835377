import _ from 'lodash';
import TimeOfDay from "./TimeOfDay";
import ProtobufParser from '../modules/protobufParser';
import { TradingHourPeriodicity } from '../constants/enumerations';
import State from "./dto/State";
import {capitalizeWord, getEnumName} from "../modules/util";
import {InstrumentState} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";

const allDays = [0, 1, 2, 3, 4, 5, 6];
const weekDays = [1, 2, 3, 4, 5];

export default class TradingHour {
  constructor(item) {
    if (item) {
      let daysOfWeek = [];
      const savedDaysOfWeek = item.getDaysOfWeekList()
      for (let index = 0; index < savedDaysOfWeek.length; index++) {
        let dayOfWeek = savedDaysOfWeek[index] === 7 ? 0 : savedDaysOfWeek[index];
        daysOfWeek.push(dayOfWeek);
      }

      this.daysOfWeekList = daysOfWeek;
       
      if (_.isEqual(daysOfWeek, allDays) || daysOfWeek.length === 0) {
        this.periodicity = TradingHourPeriodicity.EVERYDAY;
      } else if (_.isEqual(daysOfWeek, weekDays)) {
        this.periodicity = TradingHourPeriodicity.EVERYWEEKDAY;
      } else { 
        this.periodicity = TradingHourPeriodicity.CUSTOM;
      }

      this.timeOfDay = new TimeOfDay(item.getTimeOfDay());
      this.duration = ProtobufParser.fromDuration(item.getDuration());
      let state = new State();
      state.id = item.getState();
      state.name = capitalizeWord(getEnumName(InstrumentState, state.id));
      this.state = state;
      return;
    }
    this.daysOfWeekList = null;
    this.periodicity = null;
    this.timeOfDay = null;
    this.duration = null;
    this.state = null;
  }
}