import ApiCall from "../modules/apiCall";
import { getAccessToken } from './TokenService';
import InstrumentMapper from '../modules/mappers/instrumentMapper';
import ProtobufParser from "../modules/protobufParser";
import { apiUrl, isEmptyString } from "../modules/util";

const {
  GetMetadataRequest,
  ListInstrumentsRequest,
  GetInstrumentRequest,
  CreateInstrumentRequest,
  DeleteInstrumentRequest,
  UpdatePendingInstrumentRequest,
  UpdateActiveInstrumentRequest,
  UpdateInstrumentStateRequest,
  UpdateInstrumentStatsRequest,
  UpdateSettlementPriceRequest,
  UpdateOpenInterestRequest,
  ListHolidayCalendarsRequest,
  UpdateHolidayCalendarRequest,
  DeleteHolidayCalendarRequest,
  CreateHolidayCalendarRequest,
  ListSymbolsRequest,
  PerformEndOfDayMarketActionsRequest,
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_api_pb.js');

const {
  DownloadInstrumentsRequest,
  SearchInstrumentStatsRequest
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js');

const { InstrumentState } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb");
const { InstrumentsAPIClient, InstrumentsAPIPromiseClient } = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_api_grpc_web_pb.js');

const client = new InstrumentsAPIClient(apiUrl(), null, null);
const promiseClient = new InstrumentsAPIPromiseClient(apiUrl(), null, null);

const {
  AdminAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");

const adminClient = new AdminAPIClient(apiUrl(), null, null);

export default class InstrumentService {
  static getMetadata(cb) {
    var request = new GetMetadataRequest();

    ApiCall(client, "getMetadata", request, cb);
  }

  static getAll(handleError, whenComplete, productId) {
    const request = new ListInstrumentsRequest();
    request.setPageSize(1000);
    if (productId)
      request.setProductId(productId);
    const instruments = [];
    const cbLoop = (err, response) => {
      if (response) {
        response.getInstrumentsList().forEach(instrument => {
          instruments.push(instrument);
        });
        if (response.getNextPageToken()) {
          request.setPageToken(response.getNextPageToken());

          ApiCall(client, "listInstruments", request, cbLoop);

        } else {
          whenComplete(instruments);
        }
      }
      if (err) {
        handleError(err);
      }
    };

    ApiCall(client, "listInstruments", request, cbLoop);
  }
  static getInstruments(handleError, sucessHandler, pageToken, pageSize, filterItems = {}) {
    const request = new ListInstrumentsRequest();
    request.setPageSize(10);
    request.setNewestFirst(true);
    if (pageSize)
      request.setPageSize(pageSize);
    if (pageToken)
      request.setPageToken(pageToken);
    else
      pageToken = '';

    this.applyInstrumentFilterItems(request, filterItems)

    const instruments = [];
    const cbLoop = (err, response) => {
      if (response) {
        response.getInstrumentsList().forEach(instrument => {
          instruments.push(instrument);
        });
        const nextPageToken = response.getNextPageToken();
        let isEof = response.getEof ? response.getEof() : false;

        sucessHandler(instruments, isEof ? "" : nextPageToken, pageToken);
      }
      if (err) {
        handleError(err);
      }
    };

    ApiCall(client, "listInstruments", request, cbLoop);
  }

  static getInstruments2(handleError, sucessHandler, symbols) {
    const request = new ListInstrumentsRequest();
    request.setNewestFirst(true);
    request.setSymbolsList(symbols);

    const cb = (err, response) => {
      if (response) {
        sucessHandler(response.getInstrumentsList());
      }
      if (err) {
        handleError(err);
      }
    };

    ApiCall(client, "listInstruments", request, cb);
  }

  static applyInstrumentFilterItems(request, filterItems = {}) {
    if (filterItems.id) {
      request.setSymbolsList([filterItems.id.value]);
    }
    if (filterItems.state) {
      request.setStatesList([filterItems.state.value]);
    }
    if (filterItems.type) {
      request.setType(filterItems.type.value);
    }
    if (filterItems.isScheduled) {
      request.setFilterState(filterItems.isScheduled.value);
    }
    if (filterItems.isTradable) {
      request.setTradableFilter(filterItems.isTradable.value);
    }
    request.setShowDeleted(filterItems.state && filterItems.state.value === InstrumentState.INSTRUMENT_STATE_TERMINATED);
    request.setProductId(filterItems.productName ? filterItems.productName.value : "");
  }

  static get(id, cb) {

    var request = new GetInstrumentRequest();
    request.setInstrumentId(id);

    ApiCall(client, "getInstrument", request, cb);
  }

  static get2(id) {

    var request = new GetInstrumentRequest();
    request.setInstrumentId(id);

    return promiseClient.getInstrument(request,
      { authorization: getAccessToken() });
  }

  static post(instrument, cb) {

    var request = new CreateInstrumentRequest();
    request.setInstrument(instrument);

    ApiCall(client, "createInstrument", request, cb);
  }

  static delete(id) {
    var request = new DeleteInstrumentRequest();
    request.setInstrumentId(id);
    return promiseClient.deleteInstrument(request, { authorization: getAccessToken() })
  }

  static updatePending(instrument, cb) {
    var request = new UpdatePendingInstrumentRequest();
    request.setInstrument(instrument);

    ApiCall(client, "updatePendingInstrument", request, cb);
  }

  static updateActive(id, instrument, cb) {

    var request = new UpdateActiveInstrumentRequest();
    request.setInstrumentId(id);

    var requestPriceLimit = InstrumentMapper.mapToUpdatePriceLimitRequest(instrument);
    request.setUpdatePriceLimit(requestPriceLimit);

    var requestOrderLimit = InstrumentMapper.mapToUpdateOrderLimitRequest(instrument);
    request.setUpdateOrderSizeLimit(requestOrderLimit);

    var requestDates = InstrumentMapper.mapToUpdateDatesRequest(instrument);
    request.setUpdateDates(requestDates);

    var requestHours = InstrumentMapper.mapToUpdateHoursRequest(instrument);
    request.setUpdateHours(requestHours);

    var requestHideMarket = InstrumentMapper.mapToUpdateHideMarketRequest(instrument);
    request.setUpdateHideMarketData(requestHideMarket);

    var requestHolidayCalendars = InstrumentMapper.mapToUpdateHolidayCalendarsRequest(instrument);
    request.setUpdateHolidayCalendars(requestHolidayCalendars);

    if (instrument.hasEventAttributes()) {
      const typeAttributesRequest = new UpdateActiveInstrumentRequest.UpdateTypeAttributesRequest();
      typeAttributesRequest.setEventAttributes(instrument.getEventAttributes());
      request.setUpdateTypeAttributes(typeAttributesRequest)
    }

    var requestTypeAttributes = InstrumentMapper.mapToUpdateTypeAttributesRequest(instrument);
    if (requestTypeAttributes) {
      request.setUpdateTypeAttributes(requestTypeAttributes);
    }

    var requestCfiCode = InstrumentMapper.mapToUpdateCfiCodeRequest(instrument);
    request.setUpdateCfiCode(requestCfiCode);

    var requestInstrumentProductType = InstrumentMapper.mapToUpdateInstrumentProductTypeRequest(instrument);
    request.setUpdateInstrumentProductType(requestInstrumentProductType);

    var requestDescription = InstrumentMapper.mapToUpdateDescriptionRequest(instrument);
    request.setUpdateDescription(requestDescription);

    let crossOrderRestingDuration = instrument.getAttributes().getCrossOrderRestingDuration();
    let crossOrderRestingDurationReq = new UpdateActiveInstrumentRequest.UpdateCrossOrderRestingDurationRequest();
    crossOrderRestingDurationReq.setCrossOrderRestingDuration(crossOrderRestingDuration);
    request.setUpdateCrossOrderRestingDuration(crossOrderRestingDurationReq);

    const updateSettlementCalculationDetailsReq = InstrumentMapper.mapToUpdateSettlementCalculationDetailsRequest(instrument);
    request.setUpdateSettlementCalculationDetails(updateSettlementCalculationDetailsReq);


     const updateBlockTradeThresholdReq = new UpdateActiveInstrumentRequest.UpdateBlockTradeThresholdRequest();
     updateBlockTradeThresholdReq.setBlockTradeThreshold(instrument.getAttributes().getBlockTradeThreshold());

     request.setUpdateBlockTradeThreshold(updateBlockTradeThresholdReq);

     const updateMetadataReq = new UpdateActiveInstrumentRequest.UpdateMetadataRequest();

     instrument.getAttributes().getMetadataMap().forEach((value, key) => {
       updateMetadataReq.getMetadataMap().set(key, value)
     });

     request.setUpdateMetadata(updateMetadataReq);

    if (instrument.getAttributes().getTradeDayRollScheduleList()) {
      let tradeDayRollScheduleReq = new UpdateActiveInstrumentRequest.UpdateTradeDayRollScheduleRequest();
      tradeDayRollScheduleReq.setTradeDayRollScheduleList(instrument.getAttributes().getTradeDayRollScheduleList())
      request.setUpdateTradeDayRollSchedule(tradeDayRollScheduleReq)
    }

    const updateStateReq = new UpdateActiveInstrumentRequest.UpdateStateRequest();
    updateStateReq.setIsScheduled(instrument.getIsScheduled())
    updateStateReq.setState(instrument.getState());
    request.setUpdateStateRequest(updateStateReq);

    const updateSkipRiskAndCurrencyChecks = new UpdateActiveInstrumentRequest.UpdateSkipRiskAndCurrencyChecks();
    updateSkipRiskAndCurrencyChecks.setSkipRiskAndCurrencyChecks(instrument.getAttributes().getSkipRiskAndCurrencyChecks());
    request.setUpdateSkipRiskAndCurrencyChecks(updateSkipRiskAndCurrencyChecks);

    ApiCall(client, "updateActiveInstrument", request, cb);
  }

  static updateState(id, stateId, isScheduled, scheduleExecutionTime, cb) {

    var request = new UpdateInstrumentStateRequest();
    request.setInstrumentId(id);
    request.setIsScheduled(isScheduled);
    request.setState(stateId);
    if (scheduleExecutionTime) {
      const ts = ProtobufParser.toTimestamp(scheduleExecutionTime);
      request.setScheduleExecutionTime(ts);
    }

    ApiCall(client, "updateInstrumentState", request, cb);
  }

  static updateInstrumentStats(id, data, cb) {
    let request = new UpdateInstrumentStatsRequest();
    request.setInstrumentId(id);
    if (!!data.transactTime) request.setTransactTime(ProtobufParser.toTimestamp(data.transactTime))
    let stats = InstrumentMapper.createInstrumentStats(data);
    request.setStats(stats);
    ApiCall(client, "updateInstrumentStats", request, cb);
  }

  static updateSettlementPrice(id, settlementPrice, transactTime, settlementPreliminary, cb) {
    let request = new UpdateSettlementPriceRequest();

    request.setSymbol(id);

    if (isEmptyString(settlementPrice)) {
      request.setClear(true);
    } else {
      request.setSettlementPrice(settlementPrice);
    }

    request.setSettlementPreliminary(!!settlementPreliminary);

    if (transactTime) {
      const pDate = ProtobufParser.toTimestamp(transactTime);
      request.setTransactTime(pDate);
    }

    ApiCall(client, "updateSettlementPrice", request, cb);
  }

  static updateOpenInterest(id, openInterest, transactTime, cb) {
    let request = new UpdateOpenInterestRequest();
    request.setSymbol(id);

    if (isEmptyString(openInterest)) {
      request.setClear(true);
    } else {
      request.setOpenInterest(openInterest);
    }
    
    if (transactTime) {
      const pDate = ProtobufParser.toTimestamp(transactTime);
      request.setTransactTime(pDate);
    }

    ApiCall(client, "updateOpenInterest", request, cb);
  }

  static listHolidayCalendars(cb) {
    const request = new ListHolidayCalendarsRequest();
    ApiCall(client, "listHolidayCalendars", request, cb);
  }

  static updateHolidayCalendar(cb, baseEvent, newCalendar) {
    let request = new UpdateHolidayCalendarRequest();
    const calendar = InstrumentMapper.createHolidayCalendar(baseEvent, newCalendar);
    request.setHolidayCalendar(calendar);
    ApiCall(client, "updateHolidayCalendar", request, cb);
  }

  static deleteHolidayCalendar(cb, id) {
    let request = new DeleteHolidayCalendarRequest();
    request.setId(id);
    ApiCall(client, "deleteHolidayCalendar", request, cb);
  }

  static createHolidayCalendar(cb, calendar) {
    let request = new CreateHolidayCalendarRequest();
    const newCalendar = InstrumentMapper.createHolidayCalendar(calendar, []);
    request.setHolidayCalendar(newCalendar);
    ApiCall(client, "createHolidayCalendar", request, cb);
  }

  static performEodActions(ids, cb) {
    var request = new PerformEndOfDayMarketActionsRequest();
    request.setInstrumentIdsList(ids);
    ApiCall(client, "performEndOfDayMarketActions", request, cb);
  }

  static async downloadInstruments(filterItems = {}, onSuccess, onError) {
    let request = new DownloadInstrumentsRequest();

    let instrumentsRequest = new ListInstrumentsRequest();
    this.applyInstrumentFilterItems(instrumentsRequest, filterItems)
    request.setInstrumentsRequest(instrumentsRequest);

    var call = ApiCall(adminClient, "downloadInstruments", request);
    var chunks = [];
    call.on("data", data => chunks.push(data.getFilechunk()));
    call.on("status", sts => {
      if (sts && sts.code === 0) onSuccess(chunks.join(""));
    });
    call.on("error", onError);
  }

  static async searchInstrumentStats(symbol, startDate, endDate, pageSize, pageToken, cb) {
    let request = new SearchInstrumentStatsRequest();

    request.setSymbol(symbol);
    request.setStartTime(startDate);
    request.setEndTime(endDate);
    request.setPageSize(pageSize);
    request.setPageToken(pageToken);
    request.setNewestFirst(true);

    ApiCall(adminClient, "searchInstrumentStats", request, cb);
  }

  static getSymbolList(cb) {
    let request = new ListSymbolsRequest();

    ApiCall(client, "listSymbols", request, cb);
  }
}
