import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import { validateField, makeDropdownOptions, getOptionText, SELECTED_DEFAULT, NUMBER_TYPE, STRING_TYPE, ARRAY_TYPE, DATE_TYPE } from '../../instrumentFormUtils'
import DateGroup from "../../../core/form/DateGroup";
import { FormModes } from "../../../../constants/enumerations";

export const FloatingLegInfoZCIS = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  handleChange,
  errors,
  mode
}) => {

  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Floating Leg Information</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>            
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Float Holiday Calendars" value={values.floatHolidayCalendarsList ? values.floatHolidayCalendarsList.join(", ") : ""} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Float Payment Frequency" value={getOptionText(InstrumentStaticData.FloatPaymentFrequency, values.floatPaymentFrequency)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatingRateIndex" name="floatingRateIndex" label="Floating Rate Index" isRequired={true}
                value={values.floatingRateIndex}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatingRateIndex", e)}
                data={InstrumentStaticData.FloatingRateIndexList}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="floatFirstUnadjustedPaymentDate" name="floatFirstUnadjustedPaymentDate" label="Float First Unadjusted Payment Date" isRequired={true}
                value={values.floatFirstUnadjustedPaymentDate ? new Date(values.floatFirstUnadjustedPaymentDate) : null}
                dropUp={true}
                enabled={values.isPartialEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("floatFirstUnadjustedPaymentDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatRollConvention" name="floatRollConvention" label="Float Roll Convention" isRequired={true}
                value={values.floatRollConvention || SELECTED_DEFAULT}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("floatRollConvention", e)}
                data={makeDropdownOptions(InstrumentStaticData.FixedRollConventionList, false)}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Float Payment Date Relative To" value={getOptionText(InstrumentStaticData.DateRelativeToOptions, values.floatPaymentDateRelativeTo)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Float Payment Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.floatPaymentDayConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Float Calculation Period Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.floatCalculationPeriodDayConvention)} />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatDayCountConvention" name="floatDayCountConvention" label="Float Day Count Convention" isRequired={true}
                value={values.floatDayCountConvention}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("floatDayCountConvention", e.id)}
                data={InstrumentStaticData.DayCountConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Float Compounding Method" value={getOptionText(InstrumentStaticData.CompoundingOptions, values.floatCompoundingMethod)} />
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

export const ValidateFloatingLegZCIS = (values, errors) => {
  errors = validateField(values, errors,  "floatDayCountConvention", "Float Day Count Convention", NUMBER_TYPE);
  errors = validateField(values, errors,  "floatHolidayCalendarsList", "Float Holiday Calendars", ARRAY_TYPE);
  errors = validateField(values, errors,  "floatPaymentFrequency", "Float Payment Frequency", STRING_TYPE);
  errors = validateField(values, errors,  "floatingRateIndex", "Floating Rate Index", STRING_TYPE);
  errors = validateField(values, errors,  "floatFirstUnadjustedPaymentDate", "Float First Unadjusted Payment Date", DATE_TYPE);
  errors = validateField(values, errors,  "floatRollConvention", "Float Roll Convention", STRING_TYPE);
  errors = validateField(values, errors,  "floatPaymentDateRelativeTo", "Float Payment Date Relative To", NUMBER_TYPE);
  errors = validateField(values, errors,  "floatCompoundingMethod", "Float Compounding Method", NUMBER_TYPE);
  return validateField(values, errors,  "floatPaymentDayConvention", "Float Payment Day Convention",  NUMBER_TYPE);
 }