import React, { Component } from "react";
import Dialog from "../core/dialog/index";
import { ListGroup, Card } from "react-bootstrap";
import "./styles.css";
import _ from "lodash";
import { exportTrace } from '../../actions/orders';
import { getEnumName } from "../../modules/util";
import { ConditionTriggerMethod } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Env} from "../../constants/environment";

function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = (dispatch) => ({
  downloadTrace: (traceId) => {
    dispatch(exportTrace(traceId))
  }
});

class OrderDetails extends Component {
  render() {
    const envHasTracerQuery = Env.getEnv("REACT_APP_HAS_TRACER_QUERY");
    const hasTracerQueryService = !!envHasTracerQuery && envHasTracerQuery.toString().toLowerCase() === "true";
    let { orderData, onDialogClose, isExecution, downloadTrace } = this.props;
    let execDataSet = {
      "Execution Details": [
        ["Aggressor Indicator", orderData.aggressor ? 'Y' : 'N'],
        ["Notional Value of Collected Commissions for this Fill", orderData.commissionNotionalCollected],
        ["Trace ID", !!orderData.traceid ? hasTracerQueryService ? (<Link onClick={(e) => {
          e.preventDefault();
          downloadTrace(orderData.traceid);
        }} to={"./"} onContextMenu={e => e.preventDefault()}>{orderData.traceid}</Link>) : orderData.traceid : ''],
      ]
    };
    let dataset = {
      "Order Details": [
        ["Risk Check Time", orderData.riskCheckTime],
        ["Book Insert Time", orderData.insertTime],
        ["Last Transact Time", orderData.lastTransactTime],
        ["Trade Date", orderData.transactTradeDate],
        ["Client Order ID", orderData.clordid],
        ["Client Cross ID", orderData.crossid],
        ["Transaction Booked Time", orderData.transactionBookedTime],
        ["Parent Order ID", orderData.parentorderid],
        ["Good Till Time", orderData.goodTillTime],
        ["Stop Trigger Method", !!orderData.stopTriggerMethod ? getEnumName(ConditionTriggerMethod, orderData.stopTriggerMethod) : ""],
        ["Minimum Quantity", orderData.minQty !== "0" ? orderData.minQty : ""],
        ["All-or-None", orderData.allOrNone ? 'Y' : 'N'],
        ["Strict Limit", orderData.strictLimit ? 'Y' : 'N'],
        ["Best Limit", orderData.bestLimit ? 'Y' : 'N'],
        ["Immediately Executable Limit", orderData.immediatelyExecutableLimit ? 'Y' : 'N'],
        ["Participate Don't Initiate", orderData.participateDontInitiate ? 'Y' : 'N'],
        ["Block Trade Indicator", orderData.blockTradeIndicator ? 'Y' : 'N'],
        ["Order Capacity", orderData.orderCapacity],
        ["Client Account ID", orderData.clientaccountid],
        ["Client User ID", orderData.clientparticipantid],
        ["Agent Firm", orderData.submittingParticipant_firm ? orderData.submittingParticipant_firm : ''],
        ["Agent User", orderData.submittingParticipant],
        ["Risk Status", orderData.collateralMemo],
        ["Priority Weight", orderData.priorityWeight],
        ["Total Notional Value of Collected Commissions", orderData.commissionNotionalTotalCollected],
        ["Total Commission Basis Points Associated with this Order", orderData.commissionsBasisPoints],
      ]
    };
    let price = (orderData.price > 0) ? orderData.price.toString() : ""
    price = `${price} ${(orderData.stopPrice > 0) ? orderData.stopPrice.toString() : ""}`

    let title = (!!orderData.cashOrderQty && orderData.cashOrderQty !== "0") ?
      `${orderData.side} ${orderData.cashOrderQty} (Cash Order Quantity) of ${orderData.symbol} ${orderData.ordType} ${price.trim()}` :
      `${orderData.side} ${orderData.orderQty} ${orderData.symbol} ${orderData.ordType} ${price.trim()}`

    let subTitle = [
      (!!orderData.participant_firm && !_.isEmpty(orderData.participant_firm) ? `Firm: ${orderData.participant_firm}` : null),
      (!!orderData.account && !_.isEmpty(orderData.account) ? `Account: ${orderData.account}` : null),
      (!!orderData.participant && !_.isEmpty(orderData.participant) ? `User: ${orderData.participant}` : null),
      (!!orderData.clientparticipantid && !_.isEmpty(orderData.clientparticipantid) ? `Client User ID: ${orderData.clientparticipantid}` : null),
      (!!orderData.clientaccountid && !_.isEmpty(orderData.clientaccountid) ? `Client Account ID: ${orderData.clientaccountid}` : null),
    ].filter(n => n).join(", ")

    return (
      <div>
        <Dialog
          show={true}
          title={isExecution ? "Execution Details" : "Order Details"}
          onOk={() => onDialogClose()}
          onClose={() => onDialogClose()}
          okButtonText={"Ok"}
        >
          {isExecution &&<>
            <Card>
              <Card.Body>
                <h4 style={{ color: 'darkblue' }}>Additional Execution Fields</h4>
              </Card.Body>
            </Card>
            <div style={{"paddingBottom": "30px"}}>
              {execDataSet["Execution Details"].map((data, index) => (
                <ListGroup className="order-details-list-group" key={index}>
                  <ListGroup.Item
                    key={0}
                    className={`order-details-list-item order-details-key ${index % 2 === 0 ? "oddRow" : ""}`}
                  >
                    {data[0]}
                  </ListGroup.Item>
                  <ListGroup.Item
                    key={1}
                    className={`order-details-list-item ${index % 2 === 0 ? "oddRow" : ""}`}
                  >
                    {data[1]}
                  </ListGroup.Item>
                </ListGroup>
              ))}
            </div>
          </>}
          <Card>
            <Card.Body>
              <h4 style={{ color: 'darkblue' }}>Additional Order Fields</h4>
              <h5>{title.trim()}</h5>
              <h6>{subTitle.trim()}</h6>
            </Card.Body>
          </Card>
          <div>
            {dataset["Order Details"].map((data, index) => (
              <ListGroup className="order-details-list-group" key={index}>
                <ListGroup.Item
                  key={0}
                  className={`order-details-list-item order-details-key ${index % 2 === 0 ? "oddRow" : ""}`}
                >
                  {data[0]}
                </ListGroup.Item>
                <ListGroup.Item
                  key={1}
                  className={`order-details-list-item ${index % 2 === 0 ? "oddRow" : ""}`}
                >
                  {data[1]}
                </ListGroup.Item>
              </ListGroup>
            ))}

          </div>
        </Dialog>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
