import State from "./dto/State";
import {capitalizeWord, getEnumName} from "../modules/util";
import {BookStatus as AdminBookStatus} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { tsProtoObjToDate } from "../actions/ptypes";


export default class BookStatus {
  constructor(item) {
    if (item) {
      const object = item.toObject();
      const stateValue = item.getState();
      let state = new State();
      state.id = stateValue;
      state.name = capitalizeWord(getEnumName(AdminBookStatus.State, stateValue));
      this.state = state;
      this.stats = object.stats;
      this.transactTime = tsProtoObjToDate(object.transactTime)
      return;
    }
    this.state = null;
    this.stats = null;
  }
}