import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FetchProductsListStart,
  FetchProductsListSucceed,
  FetchProductsListFailed
} from '../actions/productsList';

import Notification from '../modules/notifications';
import DropdownListGroup from '../components/core/form/DropdownListGroup';
import ProductListItem from '../entities/dto/ProductListItem';
import {convertToDropdownCollection, inTest} from '../modules/util';
import Product from '../entities/Product';
import ProductListItemComponent from '../components/product/ProductListItemComponent';
import ProductService from '../services/ProductService';

function mapStateToProps(state) {
  return {
    fetchingProductsList: state.fetchingProductsList,
    productsList: state.productsList
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchProductsListStart: () => {
    dispatch(FetchProductsListStart())
  },
  fetchProductsListSucceed: (productList) => {
    dispatch(FetchProductsListSucceed(productList))
  },
  fetchProductsListFailed: () => {
    dispatch(FetchProductsListFailed())
  },
});

class ProductListContainer extends Component {
  componentDidMount() {
    this.loadProducts();
  }

  loadProducts = () => {
    this.props.fetchProductsListStart();
    const cb = (err, response) => {
      if (response) {
        var productListDb = response.getProductsList().map((product) => {
          return new ProductListItem(new Product(product))
        });
        var productList = convertToDropdownCollection(productListDb, 'name', 'description');
        this.props.fetchProductsListSucceed(productList);
      }

      if (err) {
        this.props.fetchProductsListFailed();
        Notification.error('Cannot get product list items.');
        if (!inTest()) console.log(err);
      }
    };

    ProductService.getAll(cb)
  }

  render() {
    const plaintext = this.props.plaintext;
    return (
      <DropdownListGroup
        id={this.props.id}
        name={this.props.name}
        label={this.props.label}
        value={this.props.value}
        enabled={this.props.enabled}
        onChange={this.props.onChange}
        data={[{ id: null, name: 'Select' }, ...this.props.productsList.productsList]}
        itemComponent={ProductListItemComponent}
        errors={this.props.errors}
        textField="name"
        valueField="id"
        plaintext={plaintext}
        showFilter={true}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListContainer);