import React, { useState } from "react";
import moment from 'moment';
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import { validateField, DATE_TYPE } from '../instrumentFormUtils'
import DateGroup from "../../core/form/DateGroup";
import DateTimeParser from "../../../modules/dateTimeParser";
import FieldGroupReadOnly from "../../core/form/FieldGroupReadOnly";
import * as Yup from "yup";
import { DEFAULT, FUTURE, OPTION, FOREX, MULTILEG, INTEREST_RATE_SWAP, EVENT, NON_DELIVERABLE_FORWARDS, ZERO_COUPON_INFLATION_SWAP } from "../../../constants/strings";
import { FormModes } from "../../../constants/enumerations";
import * as toolTips from '../../../constants/instrumentToolTip';
import ErrorLabel from "../../core/form/ErrorLabel";
import CheckboxGroup from "../../core/form/CheckboxGroup";

function instrumentCanSetStartDate(instrumentType) {
  return instrumentType === DEFAULT || instrumentType === FUTURE || instrumentType === OPTION || instrumentType === MULTILEG || instrumentType === EVENT || instrumentType === NON_DELIVERABLE_FORWARDS || instrumentType === FOREX || instrumentType === ZERO_COUPON_INFLATION_SWAP;
}

export const TradingSchedule = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  handleChange,
  instrumentType,
  isEditing,
  enableStartDate,
  errors,
  mode
}) => {

  const [open, setOpen] = useState(true);
  const isViewing = mode === FormModes.view;

  const showErrorIfAnyRequiredFieldMissing = () => {
    const errPresent = !!errors["startDate"];

    if (errPresent && !open) {
      setOpen(errPresent);
    }

    return errPresent;
  }

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={8} xs={8} md={8}>
            <h6><strong>Trading Schedule</strong></h6>
          </Col>
          <Col lg={3} xs={3} md={3}>
            {showErrorIfAnyRequiredFieldMissing() && <ErrorLabel text={"Required field(s) missing"} />}
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>

      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            {instrumentType && !instrumentCanSetStartDate(instrumentType) ? (
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly label={instrumentCanSetStartDate(instrumentType) ? "Start Date" : "Trade Date"} value={values.startDate ? DateTimeParser.toDisplay(values.startDate) : null} />
              </Col>
            ) : (
              <Col lg={3} xs={3} md={3}>
                <DateGroup id="startDate" name="startDate" label={instrumentCanSetStartDate(instrumentType) ? "Start Date" : "Trade Date"} isRequired={true}
                  tooltip={toolTips.START_DATE}
                  value={values.startDate ? new Date(values.startDate) : null}
                  dropUp={true}
                  enabled={enableStartDate(values.isPartialEditable, isEditing, values.startDate)}
                  errors={errors}
                  touched={touched}
                  onChange={(e) => {
                    if (!e) return;
                    const startDate = new Date(e.getFullYear(), e.getMonth(), e.getDate());
                    setFieldValue("startDate", startDate);
                  }}
                  onBlur={handleBlur}
                  defaultCurrentDate={moment().startOf("day").toDate()}
                  plaintext={isViewing}
                /> </Col>)}

            <Col lg={3} xs={3} md={3}>
              <DateGroup id="lastTradeDate" name="lastTradeDate" label="Last Trade Date/Time"
                tooltip={toolTips.LAST_TRADE_DATE_TIME}
                value={values.lastTradeDate ? new Date(values.lastTradeDate) : null}
                errors={errors}
                dropUp={true}
                enabled={values.isPartialEditable}
                touched={touched}
                onChange={(e) => setFieldValue("lastTradeDate", e)}
                onBlur={handleBlur}
                enableTime={true}
                defaultCurrentDate={values.startDate ? DateTimeParser.addDays(values.startDate, 1) : moment().startOf("day").toDate()}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="expirationDate" name="expirationDate" label="Expiration Date/Time"
                tooltip={toolTips.EXPIRATION_DATE_TIME}
                value={values.expirationDate ? new Date(values.expirationDate) : null}
                errors={errors}
                dropUp={true}
                enabled={values.isPartialEditable}
                touched={touched}
                onChange={(e) => setFieldValue("expirationDate", e)}
                onBlur={handleBlur}
                enableTime={true}
                defaultCurrentDate={values.startDate ? DateTimeParser.addDays(values.startDate, 1) : moment().startOf("day").toDate()}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="terminationDate" name="terminationDate" label="Termination Date"
                tooltip={toolTips.TERMINATION_DATE}
                value={values.terminationDate ? new Date(values.terminationDate) : null}
                errors={errors}
                dropUp={true}
                enabled={values.isPartialEditable}
                touched={touched}
                onChange={(e) => {
                  if (!e) { setFieldValue("terminationDate", null); return; }
                  const terminationDate = new Date(e.getFullYear(), e.getMonth(), e.getDate());
                  setFieldValue("terminationDate", terminationDate);
                }}
                onBlur={handleBlur}
                defaultCurrentDate={
                  values.expirationDate ? DateTimeParser.addDays(values.expirationDate, 1) :
                    values.startDate ? DateTimeParser.addDays(values.startDate, 1) :
                      moment().startOf("day").toDate()
                }
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <CheckboxGroup
                checked={values.isScheduled}
                disabled={isViewing}
                onClick={(e) => {
                  setFieldValue("isScheduled", e.target.checked);
                }}
                label={"Follow Trading Hours"} />
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

export const ValidateTradingSchedule = (values, errors, instrumentType) => {
  debugger
  if (!values.startDate) {
    errors.startDate = "Start Date is required.";
  }

  if (instrumentCanSetStartDate(instrumentType)) {
    let isIrs = instrumentType === INTEREST_RATE_SWAP;
    return validateField(values, errors, "startDate", isIrs ? "Trade Date" : "Start Date", DATE_TYPE);
  }

  if (!!values.lastTradeDate) {
    if (values.lastTradeDate < values.startDate) {
      errors.lastTradeDate = "Last Trader Date must be greater than start date."
    }
  }

  if (!!values.expirationDate) {
    if (values.expirationDate < values.startDate) {
      errors.expirationDate = "Expiration Date must be greater than Start Date."
    }

    if (!!values.lastTradeDate && values.expirationDate < values.lastTradeDate) {
      errors.expirationDate = "Expiration Date must be greater than Last Trader Date."
    }
  }

  if (!!values.terminationDate) {
    if (values.terminationDate < values.startDate) {
      errors.terminationDate = "Termination Date must be greater than Start Date."
    }

    if (!!values.lastTradeDate && values.terminationDate < values.lastTradeDate) {
      errors.terminationDate = "Termination Date must be greater than Last Trader Date."
    }

    if (!!values.expirationDate && values.terminationDate < values.expirationDate) {
      errors.terminationDate = "Termination Date must be greater than Expiration Date."
    }
  }

  return errors;
}

export const TradingScheduleSchemaValidation = () => {
  return {
    state: Yup.object().nullable().required("State is required"),
  }
}
