import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import confirm from "../modules/confirmDialog";
import { LoadAccounts } from "../actions/accounts";
import { LoadFirms } from "../actions/firms";
import { Row, Col } from "react-bootstrap";
import "../pages/Content.css";
import Participant from "../entities/Participant";
import Notification from "../modules/notifications";
import AccountService from "../services/AccountService";
import UserAccountsDataGrid from "../components/account/UserAccountsDataGrid";
import ButtonMain from "../components/core/form/ButtonMain";
import CheckListPopup from "../components/core/checklist-popup";
import { isArrayWithValues } from "../modules/util";
import FirmsStaticData from "../modules/firmsStaticData";

function mapStateToProps(state) {
  return {
    participant: state.participants.participant,
    accounts: state.accounts.accounts,
    firms: state.firms.firms,
  };
}

const mapDispatchToProps = (dispatch) => ({
  loadAccounts: () => {
    return dispatch(LoadAccounts());
  },
  loadFirms: () => {
    return dispatch(LoadFirms());
  }
});

class AccountsInParticipantContainer extends Component {
  state = {
    allAssociatedAccounts: [],
    linkableAccounts: [],
    loadedParticipantsByFirm: false
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  prepareParticipantData = () => {
    this.setState({ loadedParticipantsByFirm: true });

    AccountService.listParticipants({ firm: this.props.participant.firm }, (err, response) => {
      if (response) {
        var participantsList = response.getParticipantsList().map((participant) => {
          return new Participant(participant);
        });

        const customerOrderCapacity = FirmsStaticData.CustomerOrderCapacity();

        const participant = participantsList.find(participant => participant.name === this.props.participant.name);

        if (participant) {
          let accountIds = []

          // Participant contains associated accounts list.
          participant.accounts.forEach((key, value) => accountIds.push(value));

          const firmAccounts = this.props.accounts.filter(acc => acc.associatedFirm === this.props.participant.firm);

          const linkedAccounts = [];
          participant.accounts.forEach((key, value) => {
            const account = firmAccounts.find(acc => acc.name === value)
            if(account) {
              account["customerOrderCapacity"] = customerOrderCapacity.find(coc => coc.id === key);
              linkedAccounts.push(account);
            }
          });

          const linkableAccounts = firmAccounts.filter(fa => accountIds.indexOf(fa.name) === -1)

          this.setState({
            allAssociatedAccounts: linkedAccounts,
            linkableAccounts: linkableAccounts
          });

        }
      }

      if (err) {
        console.error("Cannot get participants for firm: ", this.props.participant.firm);
      }
    });
  }

  componentDidMount() {
    this.props.loadFirms();

    this.props.loadAccounts();
  }

  componentDidUpdate(prevProps) {
    if (!!prevProps.participant && !!prevProps.participant.firm && !!this.props.participant && !!this.props.participant.firm) {
      if (!this.state.loadedParticipantsByFirm) {
        this.prepareParticipantData();
      }
    }
  }

  onView = (name) => {
    this.context.router.history.push(`/${name}`);
  };

  onEdit = (name) => {
    this.context.router.history.push(`/${name}/edit`);
  };

  onRemove = (name, infoObj) => {
    confirm(`Are you sure you want to remove Account "${name}" from this User?`, {
      title: "Confirm Associated Account Removal",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            this.prepareParticipantData();
            Notification.success("Participant removed from account.");
          }

          if (err) {
            Notification.error(
              `Error when removing participant from account: \n ${err.message}`
            );
          }
        };

        AccountService.removeParticipant(name, [this.props.participant.name], cb);
      },
      () => { }
    );
  };

  unDeleteAccount = async (acctName) => {
    confirm("Are you sure you want to restore this account?", {
      title: "Account Restore Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            this.props.loadFirms();
            this.props.loadAccounts().then(this.mapAccounts);
            Notification.success(`Account ${acctName} restored.`);
          }

          if (err) {
            console.error("could not restore account", err);
            Notification.error("Could not restore account", acctName);
          }
        };

        AccountService.undelete(acctName, cb);
      },
      () => { }
    );
  };

  openAccountsPopUp = () => {
    this.setState({ showAccountsPopUp: true });
  };

  closeAccountsPopUp = () => {
    this.setState({ showAccountsPopUp: false });
  };

  saveAssociation = () => {
    const linkingAccounts = this.state.linkableAccounts.filter(function (item) {
      return item['isSelected'];
    });

    if (!isArrayWithValues(linkingAccounts)) {
      this.closeAccountsPopUp();
      return;
    }

    const accountIds = linkingAccounts.map((item) => {
      return item['name']
    })

    const customerOrderCapacities = linkingAccounts.map((item) => {
      return item['customerOrderCapacity'] ? item['customerOrderCapacity'] : 0;
    })

    
    const participantName = this.props.participant.name;
    let refreshTimeoutId = null;

    accountIds.forEach((accountId, idx) => {

      const cb = (err, response) => {
        if (response) {
          Notification.success(`Account '${accountId}' added successfully.`);

          if (!!refreshTimeoutId) clearInterval(refreshTimeoutId);
          
          refreshTimeoutId = setTimeout(() => {
            this.prepareParticipantData();
            this.closeAccountsPopUp();
          }, 500);
        }

        if (err) {
          Notification.error(`An error occurred while adding account: ${accountId}`);
        }
      };

      AccountService.addParticipants(accountId, [participantName], [customerOrderCapacities[idx]], cb);
    });

  };

  onSelectAssociation = (account) => {
    const linkableAccounts = this.state.linkableAccounts;

    if (isArrayWithValues(linkableAccounts)) {
      const selectedIdx = linkableAccounts.findIndex(acc => acc.id === account.id);
      linkableAccounts[selectedIdx].isSelected = account.isSelected;
      this.setState({ linkableAccounts: [...linkableAccounts] })
    }
  }


  render() {
    return (
      <div>
        <Row>
          <Col lg={9} xs={9} md={9}>
            <div className="page-sub-title">Associated Accounts</div>
          </Col>
          <Col lg={3} xs={3} md={3}>
            <ButtonMain
              text="Add Associated Account"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.openAccountsPopUp();
              }}
              customClassName="btn-main-header"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} xs={12} md={12}>
            <div className="with-callback">
              <UserAccountsDataGrid
                data={this.state.allAssociatedAccounts}
                firms={this.props.firms}
                onView={this.onView}
                onEdit={this.onEdit}
                onRemove={this.onRemove}
                onUnDelete={(acctName) => {
                  this.unDeleteAccount(acctName);
                }}
                allAccounts={this.props.accounts}
              />
            </div>
          </Col>
        </Row>
        <CheckListPopup
          show={this.state.showAccountsPopUp}
          title="Add Account"
          className="modal-dialog-xl"
          onOk={() => {
            this.saveAssociation();
          }}
          onCancel={() => {
            this.closeAccountsPopUp();
          }}
          okButtonText="Confirm"
          cancelButtonText="Cancel"
          onSelectItem={(participant) => {
            this.onSelectAssociation(participant);
          }}
          columnText="ACCOUNT NAME"
          columnAccesor="displayName"
          selectorId="displayName"
          customText={<div>Select account to associate with user <strong>{this.props.participant.displayName}</strong>.</div>}
          data={this.state.linkableAccounts}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountsInParticipantContainer)
);
