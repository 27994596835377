import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'react-bootstrap';
import OrderLimitField from './OrderLimitField';
import './OrderLimit.css';
import * as toolTips from '../../constants/instrumentToolTip'
import Tooltip from '../core/tooltip-html/tooltip-html';

class OrderLimit extends Component {
  render() {
    return (
      <div className="order-limit-wrapper">
        <label>Order Size Limits</label>
        <Tooltip
          text = {toolTips.ORDER_SIZE_LIMITS}
          id="tipOrderSizeLimits"
        ></Tooltip>
        <Row>
          <Col lg={6} xs={6} md={6}>
            <OrderLimitField
              label="Low Limit"
              orderName="lowOrderLimit"
              orderValue={this.props.lowOrder}
              setOrder={this.props.setLow}
              isRequired={false}
              errors={this.props.errors}
              touched={this.props.touched}
              setFieldValue={this.props.setFieldValue}
              onChange={this.props.onChange}
              onBlur={this.props.handleBlur}
              onSetClick={(checked) => {
                this.props.onSetLow(checked);
              }}
            />
          </Col>
          <Col lg={6} xs={6} md={6}>
            <OrderLimitField
              label="High Limit"
              orderName="highOrderLimit"
              orderValue={this.props.highOrder}
              setOrder={this.props.setHigh}
              isRequired={false}
              errors={this.props.errors}
              touched={this.props.touched}
              setFieldValue={this.props.setFieldValue}
              onChange={this.props.onChange}
              onBlur={this.props.handleBlur}
              onSetClick={(checked) => {
                this.props.onSetHigh(checked);
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

OrderLimit.propTypes = {
  lowOrder: PropTypes.any,
  highOrder: PropTypes.any,
  setLow: PropTypes.bool,
  setHigh: PropTypes.bool,
  errors: PropTypes.any,
  touched: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSetLow: PropTypes.func.isRequired,
  onSetHigh: PropTypes.func.isRequired
};

export default OrderLimit;