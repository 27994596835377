import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNullOrUndefined, validScaledPrice } from "../../modules/util";
import { Col, Row, } from "react-bootstrap";
import Notification from "../../modules/notifications";
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import AccountService from "../../services/AccountService";
import AutoComplete from "../core/auto-complete/AutoComplete";
import { FormGroup, FormLabel, FormControl, Container } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import AccountBankDetails from "./AccountBankDetails"
import { FormModes } from "../../constants/enumerations";
import { WithdrawalType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/risk/v1beta1/risk_pb";


export class CreateWithdrawalForm extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = { value: null, override: null, description: "", deleteDescription: "", securityId: "", bankRecipient: {} };
    }

    render() {
        const { show, reload, currency, account, withdrawalType, onComplete, defaultValue, dataSourceOptions, securityKeys, securityCurrencies, isApproving, isDeleting, currWithdrawal, isViewing } = this.props;
        const selectedOption = !isNullOrUndefined(this.state.override) && this.state.override !== "" ? this.state.override : !isNullOrUndefined(currency) ? currency : null;
        const valid = (Array.isArray(dataSourceOptions)
            && dataSourceOptions.includes(selectedOption)
            && this.state.description.trim().length > 0);

        const hasWithdrawal = !isNullOrUndefined(currWithdrawal) && (!!isApproving || !!isDeleting || !!isViewing);

        if (!!currWithdrawal && !!currWithdrawal.bankRecipient && currWithdrawal.bankRecipient !== this.state.bankRecipient) {
            this.setState({ bankRecipient: currWithdrawal.bankRecipient })
        }

        let mode = !!hasWithdrawal ? FormModes.view : FormModes.creation;

        let setBankDetail = (field) => {
            let targetField = field.target;
            this.setState((prevState) => {
                let newState = {};
                newState = Object.assign(newState, prevState);
                newState.bankRecipient[targetField.id] = targetField.value
                return newState
            })
        }

        let setBankDetailFieldValue = (fieldName, value) => {
            this.setState((prevState) => {
                let newState = {};
                newState = Object.assign(newState, prevState);
                newState.bankRecipient[fieldName] = value
                return newState
            })
        }

        return (
            <ConfirmDialog
                show={!!show}

                proceed={() => {
                    if (!!this.props.isApproving) {
                        let responseHandled = false;
                        AccountService.approvePendingWithdrawal(this.props.currWithdrawal.id, (err, response) => {
                            if (responseHandled) return;

                            if (response) {
                                Notification.success("Withdrawal request approved!");
                                reload();
                            }
                            if (err) {
                                Notification.error(err.message);
                            }
                            responseHandled = true
                        });
                    } else if (!!this.props.isDeleting) {
                        AccountService.deletePendingWithdrawal(this.props.currWithdrawal.id, this.state.deleteDescription,(err, response) => {
                            if (response) {
                                Notification.success("Withdrawal request deleted!");
                                reload();
                            }
                            if (err) {
                                Notification.error(err.message);
                            }
                        });
                    } else if (!isNullOrUndefined(currency)) {
                        if (!validScaledPrice(this.state.value, 100000000)) {
                            Notification.error(this.state.value + " is an invalid quantity for " + selectedOption);
                            return
                        }

                        AccountService.createPendingWithdrawal(account, selectedOption, this.state.value, this.state.description, this.state.securityId, this.state.bankRecipient, (err, response) => {
                            if (response) {
                                Notification.success("Withdrawal request created!");
                                reload();
                            }
                            if (err) {
                                Notification.error(err.message);
                            }
                        });
                    }

                    this.setState({ value: null, override: null, description: "", deleteDescription: "", securityId: "", bankRecipient: {} });
                    onComplete();
                }}

                cancel={() => {
                    this.setState({ value: null, override: null, description: "", deleteDescription: "", securityId: "", bankRecipient: {} });
                    onComplete();
                }}

                confirmation={
                    <Container>
                        <Row>
                            <Col>
                                {!hasWithdrawal && withdrawalType === WithdrawalType.WITHDRAWAL_TYPE_CURRENCY && <FormGroup>
                                    <FormLabel>Currency</FormLabel>
                                    <AutoComplete
                                        id="symbol"
                                        placeholder="Enter Currency"
                                        noItemsMessage="No results found"
                                        dataSource={dataSourceOptions}
                                        defaultValue={currency}
                                        onChange={(e) => this.setState({ override: e.target.value })}
                                        onEnter={(value) => this.setState({ override: value })}
                                        onSelect={(value) => this.setState({ override: value })}
                                        disable={false}
                                    />
                                </FormGroup>}
                                {!hasWithdrawal && withdrawalType === WithdrawalType.WITHDRAWAL_TYPE_SECURITY && !!this.state.override && <FieldGroupReadOnly
                                    id="symbol"
                                    name="symbol"
                                    label="Currency"
                                    value={this.state.override}
                                />}
                                {!!hasWithdrawal && <FieldGroupReadOnly
                                    id="symbol"
                                    name="symbol"
                                    label="Currency"
                                    value={currWithdrawal.currency}
                                />}
                            </Col>
                        </Row>

                        {withdrawalType === WithdrawalType.WITHDRAWAL_TYPE_SECURITY && <Row>
                            <Col>
                                 {!hasWithdrawal && <FormGroup>
                                    <FormLabel>Security ID</FormLabel>
                                    <AutoComplete
                                        id="securityId"
                                        placeholder="Enter Security ID"
                                        noItemsMessage="No results found"
                                        dataSource={securityKeys}
                                        defaultValue={""}
                                        onChange={(e) => this.setState({ securityId: e.target.value, override: ""})}
                                        onEnter={(value) => this.setState({ securityId: value, override: !isNullOrUndefined(securityCurrencies.get(value)) ? securityCurrencies.get(value) : ""})}
                                        onSelect={(value) => this.setState({ securityId: value, override: !isNullOrUndefined(securityCurrencies.get(value)) ? securityCurrencies.get(value) : ""})}
                                        disable={false}
                                    />
                                </FormGroup>}
                                {!!hasWithdrawal && <FieldGroupReadOnly
                                    id="securityId"
                                    name="securityId"
                                    label="Security ID"
                                    value={currWithdrawal.securityId}
                                />}
                            </Col>
                        </Row>}

                        <Row>
                            <Col>
                                {!hasWithdrawal && <FormGroup>
                                    <FormLabel>Balance Amount</FormLabel>
                                    <FormControl
                                        readOnly={false}
                                        type="text"
                                        value={isNullOrUndefined(this.state.value) ? defaultValue : this.state.value}
                                        onChange={(e) => this.setState({ value: e.target.value })}
                                    />
                                </FormGroup>}
                                {!!hasWithdrawal && <FieldGroupReadOnly
                                    id="balance"
                                    name="balance"
                                    label="Balance Amount"
                                    value={currWithdrawal.balance}
                                />}
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {!hasWithdrawal && <FieldGroup id="description" name="description" type="textarea" label="Description of Change"
                                    value={this.state.description}
                                    onChange={(e) => this.setState({ description: e.target.value })}
                                    onBlur={(e) => this.setState({ description: e.target.value })}
                                    help={"This description will show in the Ledger entry for this change."}
                                />}
                                {!!hasWithdrawal && <FieldGroupReadOnly
                                    id="description"
                                    name="description"
                                    label="Description of Change"
                                    value={currWithdrawal.description}
                                />}
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {withdrawalType === WithdrawalType.WITHDRAWAL_TYPE_CURRENCY && <AccountBankDetails
                                    values={this.state.bankRecipient || {}}
                                    onChange={setBankDetail}
                                    setFieldValue={setBankDetailFieldValue}
                                    mode={mode}
                                    parent="CreateWithdrawalForm"

                                />}
                                {withdrawalType === WithdrawalType.WITHDRAWAL_TYPE_SECURITY && <AccountBankDetails
                                    values={this.state.bankRecipient || {}}
                                    onChange={setBankDetail}
                                    setFieldValue={setBankDetailFieldValue}
                                    mode={mode}
                                    parent="CreateSecurityWithdrawalForm"

                                />}
                            </Col>
                        </Row>
                        {isDeleting && <FieldGroup id="deleteDescription" name="deleteDescription" type="textarea" label="Description of Deletion"
                            value={this.state.deleteDescription}
                            onChange={(e) => this.setState({ deleteDescription: e.target.value })}
                            onBlur={(e) => this.setState({ deleteDescription: e.target.value })}
                            help={"This description will show in the Ledger entry for this deletion."}
                        />}
                    </Container>
                }

                options={{
                    title: `${isApproving ? "Approve" : isDeleting ? "Delete" : isViewing ? "View" : "Create"} Withdrawal Request`,
                    okButtonText: hasWithdrawal ? (isApproving ? "Approve" : isViewing ? null : "Delete") : valid ? "Submit" : null,
                    cancelButtonText: "Cancel",
                }}
            />
        );
    }
}

CreateWithdrawalForm.propTypes = {
    show: PropTypes.bool.isRequired,
    dataSourceOptions: PropTypes.array.isRequired,
    account: PropTypes.string,
    symbol: PropTypes.string,
    currency: PropTypes.string,
    defaultValue: PropTypes.string,
    currWithdrawal: PropTypes.object,
    isApproving: PropTypes.bool,
    isDeleting: PropTypes.bool,
    reload: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
}

export default CreateWithdrawalForm;