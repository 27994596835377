import {
  FETCH_RFQ_START,
  FETCH_RFQ_SUCCEED,
  FETCH_RFQ_FAILED,
  FETCH_RFQ_HISTORY_FAILED,
  FETCH_RFQ_HISTORY_START,
  FETCH_RFQ_HISTORY_SUCCEED,
  CLEAN_RFQ_SEARCH,
  RESET_TOKEN,
} from "../constants/requestForQuoteTypes";
import RequestForQuoteService from "../services/RequestForQuoteService";
import RequestForQuote from "../entities/RequestForQuote";
import Notification from "../modules/notifications";
import { ensureAndApplyInstrumentScales } from "../modules/scaleUtils";
import { parsePrice } from "../modules/util";

export function searchRequestForQuotes(filters, pageToken, pageSize) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_RFQ_START });
    RequestForQuoteService.search({ filters, pageToken, pageSize })
      .catch((error) => {
        dispatch({ type: FETCH_RFQ_FAILED });
        Notification.error("Search Error. Please try again!");
        return null;
      })
      .then((response) => {
        if (!response) return;

        let rfqList = response.getRequestForQuotesList().map((rfq) => {
          return new RequestForQuote(rfq);
        });

        const { scales, qtyScales } = getState().instrumentsScales;
        const isEof = response.getEof();
        ensureAndApplyInstrumentScales(
          rfqList,
          scales,
          qtyScales,
          (item) => item.symbol,
          dispatch,
          (item, scale, qtyScale) => {
            if (qtyScale) item.quantity = parsePrice(item.quantity, qtyScale);
          }
        ).then(
          (scaledRfqList, newScales) => {
            dispatch({
              type: FETCH_RFQ_SUCCEED,
              payload: scaledRfqList,
              nextPageToken: isEof ? "" : response.getNextPageToken(),
              pageToken: pageToken,
            });
          },
          (err) => {
            dispatch({ type: FETCH_RFQ_FAILED });
            Notification.error(err);
          }
        )
      });
  };
}

export function loadRfqHistory(filters, pageToken) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_RFQ_HISTORY_START });
    RequestForQuoteService.searchRfqHistory({ filters, pageToken })
      .catch((error) => {
        dispatch({ type: FETCH_RFQ_HISTORY_FAILED });
        Notification.error("Search Error. Please try again!");
        return null;
      })
      .then((response) => {
        if (!response) return;

        let rfqList = response.getRequestForQuotesList().map((rfq) => {
          return new RequestForQuote(rfq);
        });

        const { scales, qtyScales } = getState().instrumentsScales;

        ensureAndApplyInstrumentScales(
          rfqList,
          scales,
          qtyScales,
          (item) => item.symbol,
          dispatch,
          (item, scale, qtyScale) => {
            if (qtyScale) item.quantity = parsePrice(item.quantity, qtyScale);
          }
        ).then(
          (scaledRfqList, newScales) => {
            dispatch({
              type: FETCH_RFQ_HISTORY_SUCCEED,
              payload: scaledRfqList,
              nextPageToken: response.getNextPageToken(),
              pageToken: pageToken,
            });
          },
          (err) => {
            dispatch({ type: FETCH_RFQ_HISTORY_FAILED });
            Notification.error(err);
          }
        )
      });
  };
}

export function cleanSearch() {
  return (dispatch) => {
    dispatch({ type: CLEAN_RFQ_SEARCH });
    dispatch({ type: RESET_TOKEN });
  };
}
