import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import ButtonMain from "../core/form/ButtonMain";
import ButtonSecondary from "../core/form/ButtonSecondary";
import "./users.css"
import { Link } from "react-router-dom";
import { TitleBreadcrumb } from "../core/title-breadcrumb/title-breadcrumb";
import { FormModes } from "../../constants/enumerations";
import { AuthUtil } from "../../modules/authUtil";

const UsersHeader = ({
  isAddButtonVisible,
  onButtonClick,
  onTestTokenClick,
  onLogoffClick,
  onRemoveMFAClick,
  areTabsVisible,
  id,
  selectTab,
  selectedTab,
  headerText,
  headerLink,
  participant,
  firm,
  formMode,
  accessToken,
}) => {
  const linkClass = "link-with-router link-tab";
  const selectedLinkClass = `${linkClass} link-tab-selected`;
  const accountsTabVisible = !!participant && !!participant.role && !!participant.role.name && !participant.role.name.toLowerCase().includes("admin");

  const showRemoveMFAButton = useMemo(() => {
    const userRole = AuthUtil.getRole(accessToken);
    const showButton = userRole?.toLowerCase().includes("admin")
    return showButton;
  }, [accessToken])

  return (
    <div>
      <div className="page-header">
        <TitleBreadcrumb titles={[{ 'title': headerText, link: headerLink }, !!participant && formMode !== FormModes.creation ? { 'title': participant.id } : null]} />
        <Row className="page-header">
          <Col lg={5} xs={5} md={5}>
            {areTabsVisible ? (
              <div>
                <h2
                  className={
                    selectedTab === "users" || selectedTab === "profile"
                      ? selectedLinkClass
                      : linkClass
                  }
                  onClick={() => selectTab("users")}>
                  <Link to={`/firms/${firm.id}/users/${participant.id}`} className="tabLink">User Profile</Link>
                </h2>

                {!!accountsTabVisible && <h2 className={selectedTab === "accounts" ? selectedLinkClass : linkClass}
                  onClick={() => selectTab("accounts")}><Link to={`/firms/${firm.id}/users/${participant.id}/accounts`} className="tabLink">Accounts</Link></h2>}
              </div>
            ) : null}
          </Col>

          <Col lg={7} xs={7} md={7}>
            <div style={{ float: "right", marginRight: "30px" }} >
              {isAddButtonVisible ? (
                <ButtonMain
                  text={!id ? "ADD NEW USER" : "GENERATE REGISTRATION TOKEN"}
                  type="button"
                  onClick={onButtonClick}
                />
              ) : null}
              {isAddButtonVisible && !!id ? (
                <ButtonSecondary
                  text="Generate Test Token"
                  type="button"
                  onClick={onTestTokenClick}
                />
              ) : null}
              {isAddButtonVisible && !!id ? (
                <ButtonSecondary
                  text="Force Logoff"
                  type="button"
                  onClick={onLogoffClick}
                />
              ) : null}

              {!!id && !!showRemoveMFAButton ? (<ButtonSecondary
                text="Remove Multi-factor Authentication"
                type="button"
                onClick={onRemoveMFAClick}
              />) : null}

            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

UsersHeader.defaultProps = {
  isAPISelected: false,
  isAdminSelected: false,
};

UsersHeader.propTypes = {
  isAPISelected: PropTypes.bool,
  isAdminSelected: PropTypes.bool,
  isAddButtonVisible: PropTypes.bool,
};

export default UsersHeader;
