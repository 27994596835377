import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import ButtonBorderless from "../core/form/ButtonBorderless";
import FirmStateListContainer from "../../containers/FirmStateListContainer";
import ButtonMain from "../core/form/ButtonMain";
import { withFormik } from "formik";
import * as Yup from "yup";

function mapStateToProps(state) {
  return {
    firm: state.firms.firm
  };
}

export class BasicFirmServicesEditForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const {
      firm,
      handleSubmit,
      setFieldValue,
      onEditForm,
      values
    } = this.props;

    if (!firm) return null;
    return (
      <div>
        <form onSubmit={handleSubmit} name="firmServicesEditForm">
          <Container>
            <Row>
              <Col lg={4} xs={4} md={4}>
                <FieldGroupReadOnly
                  id="firmServiceName"
                  label="Firm Service Name"
                  value={firm.id}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} xs={4} md={4}>
                {onEditForm ? (
                  <FirmStateListContainer
                    id="state"
                    name="state"
                    label="Firm State"
                    value={values.state ? values.state.id : null}
                    enabled={true}
                    onChange={e => {
                      setFieldValue("state", e);
                    }}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="firmState"
                    label="Firm State"
                    value={firm.state ? firm.state.name : null}
                  />
                )}
              </Col>
            </Row>
            {onEditForm ? (
              <div>
                <ButtonMain type="submit" text="SAVE" />
                <ButtonBorderless
                  type="button"
                  text="Cancel"
                  icon="times-circle"
                  customClassName="grey-icon"
                  onClick={e => {
                    e.preventDefault();
                    this.context.router.history.goBack();
                  }}
                />
              </div>
            ) : null}
          </Container>
        </form>
      </div>
    );
  }
}

const FirmServicesEditForm = withFormik({
  mapPropsToValues: firm => ({
    ...firm.firm,
    state: firm.firm.state
  }),
  validationSchema: Yup.object().shape({
    id: Yup.string()
      .nullable()
      .required("Firm name is required.")
  }),
  validate: values => {
    let errors = {};
    if (!values.state) {
      errors.state = "State must be defined.";
    }
    return errors;
  },

  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onEditForm(values, setFieldError);
  },
  displayName: "firm-edit-form",
  enableReinitialize: true
})(BasicFirmServicesEditForm);

export default connect(mapStateToProps, null)(FirmServicesEditForm);
