import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import { FormModes } from "../../../constants/enumerations";
import FieldGroup from "../../core/form/FieldGroup";
import TimeDuration from "../../core/TimeDuration";
import FieldGroupReadOnly from "../../core/form/FieldGroupReadOnly";
import * as toolTips from '../../../constants/instrumentToolTip';
import * as Yup from "yup";
import DropdownListGroup from "../../core/form/DropdownListGroup";
import InstrumentStaticData from "../../../modules/instrumentStaticData";
import CheckboxGroup from "../../core/form/CheckboxGroup";

export const SettlementPriceCalculationInfo = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  handleChange,
  isEditing,
  errors,
  mode
}) => {

  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Settlement Price Calculation Information</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>

      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={5} xs={5} md={5}>
              <TimeDuration
                name="settlementPeriodStartOffsetFromCloseDuration"
                label="Settlement Start Period Offset from Market Close"
                onChange={(value) => {
                  setFieldValue("settlementPeriodStartOffsetFromCloseDuration", value)
                }}
                disabled={isViewing}
                value={values.settlementPeriodStartOffsetFromCloseDuration}
                tooltip={toolTips.SETTLEMENT_PERIOD_START_OFFSET_FROM_CLOSE_DURATION}
              />
            </Col>

            <Col lg={5} xs={5} md={5}>
              <TimeDuration
                id="settlementPeriodEndOffsetFromCloseDuration"
                name="settlementPeriodEndOffsetFromCloseDuration"
                label="Settlement End Period Offset from Market Close"
                onChange={(value) => {
                  setFieldValue("settlementPeriodEndOffsetFromCloseDuration", value)
                }}
                disabled={isViewing}
                value={values.settlementPeriodEndOffsetFromCloseDuration}
                tooltip={toolTips.SETTLEMENT_PERIOD_END_OFFSET_FROM_CLOSE_DURATION}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={2} xs={2} md={2}>
              <DropdownListGroup
                id="settlementPriceLogic"
                name="settlementPriceLogic"
                label="Settlement Price Logic"
                value={values.settlementPriceLogic}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("settlementPriceLogic", e.id)
                }
                data={InstrumentStaticData.SettlementPriceLogics()}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={4} xs={4} md={4}>
              <DropdownListGroup
                id="settlementPriceCalculationEndingMethod"
                name="settlementPriceCalculationEndingMethod"
                label="Settlement Price Calculation Ending Method"
                value={values.settlementPriceCalculationEndingMethod}
                errors={errors}
                onChange={(e) =>
                  setFieldValue("settlementPriceCalculationEndingMethod", e.id)
                }
                data={InstrumentStaticData.SettlementPriceCalculationEndingMethods()}
                textField="name"
                valueField="id"
                plaintext={isViewing}
                tooltip={toolTips.SETTLEMENT_PRICE_CALCULATION_ENDING_METHOD}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={5} xs={5} md={5}>
              {isViewing ? <FieldGroupReadOnly
                id="minimumTradeQtyForVwapSettlementCalculation"
                name="minimumTradeQtyForVwapSettlementCalculation"
                label="Minimum Trade Quantity for VWAP"
                value={values.minimumTradeQtyForVwapSettlementCalculation}
                tooltip={toolTips.MINIMUM_TRADE_QTY_FOR_VWAP_SETTLEMENT_CALCULATION}
              /> : (<FieldGroup
                id="minimumTradeQtyForVwapSettlementCalculation"
                name="minimumTradeQtyForVwapSettlementCalculation"
                type="textarea"
                label="Minimum Trade Quantity for VWAP"
                tooltip={toolTips.MINIMUM_TRADE_QTY_FOR_VWAP_SETTLEMENT_CALCULATION}
                value={values.minimumTradeQtyForVwapSettlementCalculation}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                min={1}
                max={10}
                step={1}
              />)}
            </Col>
            <Col lg={5} xs={5} md={5}>
              <TimeDuration
                id="bufferFromEndOfSettlementPeriodDuration"
                name="bufferFromEndOfSettlementPeriodDuration"
                label="Minimum Order Time in Market"
                onChange={(value) => {
                  setFieldValue("bufferFromEndOfSettlementPeriodDuration", value)
                }}
                disabled={isViewing}
                value={values.bufferFromEndOfSettlementPeriodDuration}
                tooltip={toolTips.BUFFER_FROM_END_OF_SETTLEMENT_PERIOD_DURATION}
              />
            </Col>
            <Col lg={2} xs={2} md={2} style={{ marginTop: "35px", padding:"0px" }}>
              <CheckboxGroup
                id="settlementPriceCalculationRequiresApproval"
                checked={values.settlementPriceCalculationRequiresApproval}
                onChange={(e) => { setFieldValue('settlementPriceCalculationRequiresApproval', e.target.checked) }}
                label="Requires Approval"
                labelPlacementAbove={true}
                tickStyle={{ marginLeft: "5px" }}
                tooltip={toolTips.SETTLEMENT_PRICE_CALCULATION_REQUIRES_APPROVAL}
                disabled={isViewing}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

export const SettlementPriceCalculationValidation = () => {
  return {
    minimumTradeQtyForVwapSettlementCalculation: Yup.number("Must be a number")
      .typeError('Minimum Trade Quantity for VWAP must be a `number` type')
      .notRequired()
      .nullable()
  }
}

