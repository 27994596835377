import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import confirm from "../modules/confirmDialog";
import {
  bustTrade,
  updateReportingCounterparty,
} from "../actions/tradesActions";

import TradeBustForm from "../components/trade/TradeBustForm";
import TradeDetailsForm from "../components/trade/TradeDetailsForm";
import { TitleBreadcrumb } from "../components/core/title-breadcrumb/title-breadcrumb";

const mapDispatchToProps = (dispatch) => ({
  bustTrade: (tradeId, bustReason) => {
    dispatch(bustTrade(tradeId, bustReason));
  },
  updateReportingCounterParty: (tradeCaptureId, reportingCounterParty) => {
    dispatch(
      updateReportingCounterparty(tradeCaptureId, reportingCounterParty)
    );
  },
});

class TradeFormContainer extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  bustTrade = (values) => {
    confirm("Please confirm busting this trade.", {
      title: "Bust Trade",
      okButtonText: "Yes, I confirm this update.",
      cancelButtonText: "Cancel",
    }).then(
      () => {
        this.props.bustTrade(values.tradeId, values.bustReason);
      },
      () => { }
    );
  };

  render() {
    const bust = this.props.location.pathname.indexOf("/bust") > 0;
    const viewDetails = this.props.location.pathname.indexOf("/details") > 0;
    return (
      <Fragment>
        <TitleBreadcrumb titles={[{'title':"Time + Sales", link:`${window.location.origin}/timeandsales`}, {title:this.props.match.params.tradecaptureid}]}></TitleBreadcrumb>
        {bust && <TradeBustForm onSubmitForm={this.bustTrade} />}
        {viewDetails && (
          <TradeDetailsForm
            onSubmitForm={this.props.updateReportingCounterParty}
            tradeId={this.props.match.params.tradecaptureid}
          ></TradeDetailsForm>
        )}
      </Fragment>
    );
  }
}

export default withRouter(
  connect(null, mapDispatchToProps)(TradeFormContainer)
);
