export const FETCH_FIRMS_START = "FETCH_FIRMS_START";
export const FETCH_FIRMS_SUCCEED = "FETCH_FIRMS_SUCCEED";
export const FETCH_FIRMS_FAILED = "FETCH_FIRMS_FAILED";
export const FETCH_FIRM_START = "FETCH_FIRM_START";
export const FETCH_FIRM_SUCCEED = "FETCH_FIRM_SUCCEED";
export const FETCH_FIRM_FAILED = "FETCH_FIRM_FAILED";
export const FETCH_FIRM_SERVICES_START = "FETCH_FIRM_SERVICES_START";
export const FETCH_FIRM_SERVICES_SUCCEED = "FETCH_FIRM_SERVICES_SUCCEED";
export const FETCH_FIRM_SERVICES_FAILED = "FETCH_FIRM_SERVICES_FAILED";
export const FETCH_FIRM_SERVICE_KEYS_START = "FETCH_FIRM_SERVICE_KEYS_START";
export const FETCH_FIRM_SERVICE_KEYS_SUCCEED = "FETCH_FIRM_SERVICE_KEYS_SUCCEED";
export const FETCH_FIRM_SERVICE_KEYS_FAILED = "FETCH_FIRM_SERVICE_KEYS_FAILED";
export const CLEAN_FIRM = "CLEAN_FIRM";
export const SAVE_FIRM_START = "SAVE_FIRM_START";
export const SAVE_FIRM_SUCCEED = "SAVE_FIRM_SUCCEED";
export const SAVE_FIRM_FAILED = "SAVE_FIRM_FAILED";
export const CREATE_FIRM_START = "CREATE_FIRM_START";
export const UPDATE_FIRM_ITEM = "UPDATE_FIRM_ITEM";
export const UPDATE_FIRM_START = "UPDATE_FIRM_START";
export const UPDATE_FIRM_SUCCEED = "UPDATE_FIRM_SUCCEED";
export const UPDATE_FIRM_FAILED = "UPDATE_FIRM_FAILED";
export const DELETE_FIRM_START = "DELETE_FIRM_START";
export const DELETE_FIRM_SUCCEED = "DELETE_FIRM_SUCCEED";
export const DELETE_FIRM_FAILED = "DELETE_FIRM_FAILED";
export const RESET_LOADING_FLAG = "RESET_LOADING_FLAG";
export const FETCH_ASSOCIATED_FIRMS_START = "FETCH_ASSOCIATED_FIRMS_START";
export const FETCH_ASSOCIATED_FIRMS_SUCCEED = "FETCH_ASSOCIATED_FIRMS_SUCCEED";
export const FETCH_AFFILIATED_FIRMS_SUCCEED = "FETCH_AFFILIATED_FIRMS_SUCCEED";
export const FETCH_ASSOCIATED_RFQ_FIRMS_SUCCEED = "FETCH_ASSOCIATED_RFQ_FIRMS_SUCCEED";
export const FETCH_ASSOCIATED_AGENT_FIRMS_SUCCEED = "FETCH_ASSOCIATED_AGENT_FIRMS_SUCCEED";
export const FETCH_ASSOCIATED_CLEARING_HOUSES_SUCCEED =
  "FETCH_ASSOCIATED_CLEARING_HOUSES_SUCCEED";
export const FETCH_ASSOCIATED_FIRMS_FAILED = "FETCH_ASSOCIATED_FIRMS_FAILED";
export const UPDATE_ASSOCIATED_FIRMS_START = "UPDATE_ASSOCIATED_FIRMS_START";
export const UPDATE_ASSOCIATED_FIRMS_SUCCEED =
  "UPDATE_ASSOCIATED_FIRMS_SUCCEED";
export const UPDATE_ASSOCIATED_FIRMS_FAILED = "UPDATE_ASSOCIATED_FIRMS_FAILED";
export const TOGGLE_SHOW_DELETED_FIRMS = "TOGGLE_SHOW_DELETED_FIRMS";
export const RESET_FIRMS = "RESET_FIRMS";