import React, { Component } from "react";
import DateGroup from "../core/form/DateGroup";
import MultiSelectDropdownListGroup from "../core/form/MultiSelectDropdownListGroup";
import moment from "moment";
import { Col, Form, FormControl } from "react-bootstrap";
import LoaderIcon from "../core/loader/LoaderIcon";
import AutoComplete from "../core/auto-complete/AutoComplete";
import StaticDataUtils from '../../modules/staticDataUtils';
import Tooltip from "../core/tooltip-html/tooltip-html";
import { valueOrDefault } from "../../modules/util";

const getSelectedTradeStates = (selected) => {
  if (!selected) { return []; }

  if (!Array.isArray(selected)) {
    selected = [selected];
  }

  const selectedIds = selected.map((v) => parseInt(v));
  return StaticDataUtils.TradeStateOptions.filter((s) => selectedIds.indexOf(s.id) !== -1);
}

class TimeSalesFilterForm extends Component {
  render() {
    const {
      loading,
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      setFieldValue,
      autoCompleteDataSource,
      onRefreshAutoComplete,
    } = this.props;

    return (
      <React.Fragment>
        <Form>
          <Form.Row>
            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>Instrument ID</Form.Label>
              <AutoComplete
                id="instrumentId"
                placeholder="Instrument ID"
                noItemsMessage="No instruments found"
                dataSource={autoCompleteDataSource}
                refreshDataSource={onRefreshAutoComplete}
                defaultValue={values.instrumentId ? values.instrumentId : null}
                value={values.instrumentId}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setFieldValue("instrumentId", null);
                  }
                  handleChange(e);
                }}
                onEnter={(value) => {
                  setFieldValue("instrumentId", value);
                }}
                onSelect={(value) => {
                  setFieldValue("instrumentId", value);
                }}
                errors={errors}
              />
            </Form.Group>

            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>Insert Time From</Form.Label>
              <DateGroup
                id="fromDate"
                name="fromDate"
                enableTime={true}
                step={15}
                value={values.fromDate ? new Date(values.fromDate) : null}
                onChange={(e) => setFieldValue("fromDate", e)}
                defaultCurrentDate={moment().startOf("day").toDate()}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Form.Group>

            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>Insert Time To</Form.Label>
              <DateGroup
                id="toDate"
                name="toDate"
                enableTime={true}
                step={15}
                value={values.toDate ? new Date(values.toDate) : null}
                onChange={(e) => setFieldValue("toDate", e)}
                defaultCurrentDate={moment().endOf("day").toDate()}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Form.Group>

            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>Participant</Form.Label>
              <Tooltip
                text="Searches for any case-insensitive partial matches on the participant field"
                id="fieldGroupTooltipParticipant"
              ></Tooltip>
              <FormControl
                id="participant"
                name="participant"
                type="text"
                value={values.participant}
                placeholder="Participant"
                onChange={(e) => setFieldValue("participant", e.target.value)}
              />
            </Form.Group>

          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>Accounts</Form.Label>
              <Tooltip
                text="Searches for any case-insensitive partial matches on the account's full resource name when searching for a single account. Multiple accounts can be searched by entering the exact full resource names of the accounts in a comma separated list such as 'firms/FirmA/accounts/AccountA, firms/FirmA/accounts/AccountB'"
                id="fieldGroupTooltipAccount"
              ></Tooltip>
              <FormControl
                id="accounts"
                name="accounts"
                type="text"
                value={valueOrDefault(values.accounts, "")}
                placeholder="Accounts"
                onChange={(e) => setFieldValue("accounts", e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>TradeID</Form.Label>
              <FormControl
                id="tradeid"
                name="tradeid"
                type="text"
                value={values.tradeid}
                placeholder="TradeID"
                onChange={(e) => setFieldValue("tradeid", e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>OrderID</Form.Label>
              <FormControl
                id="orderid"
                name="orderid"
                type="text"
                value={values.orderid}
                placeholder="OrderID"
                onChange={(e) => setFieldValue("orderid", e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>ExecID</Form.Label>
              <FormControl
                id="execid"
                name="execid"
                type="text"
                value={values.execid}
                placeholder="ExecID"
                onChange={(e) => setFieldValue("execid", e.target.value)}
              />
            </Form.Group>

          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>Client Participant ID</Form.Label>
              <FormControl
                id="clientparticipantid"
                name="clientparticipantid"
                type="text"
                value={values.clientparticipantid}
                placeholder="Client User ID"
                onChange={(e) => setFieldValue("clientparticipantid", e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>Client Account ID</Form.Label>
              <FormControl
                id="clientaccountid"
                name="clientaccountid"
                type="text"
                value={values.clientaccountid}
                placeholder="Client Account ID"
                onChange={(e) => setFieldValue("clientaccountid", e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>Parent Order Id</Form.Label>
              <FormControl
                id="parentorderid"
                name="parentorderid"
                type="text"
                value={values.parentorderid}
                placeholder="Parent Order ID"
                onChange={(e) => setFieldValue("parentorderid", e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <Form.Label>Sub Type</Form.Label>
              <FormControl
                id="symbolsubtype"
                name="symbolsubtype"
                type="text"
                value={values.symbolsubtype}
                placeholder="Symbol Sub Type"
                onChange={(e) => setFieldValue("symbolsubtype", e.target.value)}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup
                label="Trade State"
                labelWrapperClass="label-wrapper"
                value={getSelectedTradeStates(values.tradeStates)}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => {
                  const vals = e.map((v) => v.id)
                  setFieldValue("tradeStates", vals)
                }}
                data={StaticDataUtils.GetTradeStateOptions([0])}
                textField={"name"}
                valueField={"id"}
              />
            </Form.Group>

            <div className="form-group col-auto align-self-end">
              <LoaderIcon show={loading}></LoaderIcon>
            </div>

          </Form.Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default TimeSalesFilterForm;
