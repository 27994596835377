import React, { Component } from "react";
import DropdownListGroup from "../components/core/form/DropdownListGroup";
import { convertEnumToDropdownList } from "../modules/util";
import { FirmState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";

class FirmStateListContainer extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    const data = convertEnumToDropdownList(FirmState);
    this.setState({ data });
  }

  render() {
    return (
      <DropdownListGroup
        id="state"
        name="state"
        label="Firm State"
        isRequired={this.props.isRequired}
        value={this.props.value}
        errors={this.props.errors}
        onChange={(event) => {
          this.props.onChange(event);
        }}
        data={['', ...this.state.data]}
      />
    );
  }
}

export default FirmStateListContainer;
