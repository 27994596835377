import {
  getEnumName,
  capitalizeWord 
} from '../modules/util';
import { ParticipantRole } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb';
import State from './dto/State';

export default class ServiceKey {
  constructor(item) {
    if (item) {
      this.name = item.getName();
      this.userId = item.getUserId();
      this.jti = item.getJti();

      var role = new State();
      role.id = item.getRole();
      role.name = capitalizeWord(getEnumName(ParticipantRole, role.id));
      this.role = role;
      this.description = item.getDescription()

      return;
    }
    this.name = null;
    this.userId = null;
    this.jti = null;
    this.role = null;
    this.description = null;
  }
}
