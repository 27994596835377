import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import FieldGroupReadOnly from '../core/form/FieldGroupReadOnly';
import CheckboxGroup from '../core/form/CheckboxGroup';
import Tooltip from '../core/tooltip/Tooltip';

import { Container, Row, Col } from 'react-bootstrap';
import InstrumentStateListContainer from '../../containers/InstrumentStateListContainer';
import ButtonMain from '../core/form/ButtonMain';
import ButtonBorderless from '../core/form/ButtonBorderless';
import DateGroup from "../core/form/DateGroup";
import DropdownListGroup from "../core/form/DropdownListGroup";
import {isArrayWithValues, isNullOrUndefined} from '../../modules/util';
import confirm from "../../modules/confirmDialog";
import { EVENT } from '../../constants/strings';
import { Env } from "../../constants/environment";
import ButtonSecondary from "../core/form/ButtonSecondary";

function mapStateToProps(state) {
  return {
    notAllowedStates: state.instruments.notAllowedInstrumentStates,
    instruments: state.instruments
  };
}

const isNettingEnabled = Env.isNettingEnabled();

class BasicInstrumentFormMultiState extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  performResolution = () => {
    if (!this.state.symbolSubType) {
      this.setState({ errors: { symbolSubType: "Please select Winning Symbol Sub Type." } });
      return
    }

    confirm(`Are you sure you want to perform resolution for all instruments?`, {
      title: "Perform Resolution Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        if (isArrayWithValues(this.props.selection)) {
          this.props.selection.forEach((symbol, idx) => {
            this.props.onPerformResolution(symbol, this.state.symbolSubType, idx === (this.props.selection.length - 1));
          });
        } else if (!!this.props.selection && !!this.props.selection.id) {
          this.props.onPerformResolution(this.props.selection.id, this.state.symbolSubType, true);
        }
      },
      () => { }
    );
  }

  undoResolution = () => {
    confirm(`Are you sure you want to undo resolution for all instruments?`, {
      title: "Undo Resolution Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        if (isArrayWithValues(this.props.selection)) {
          this.props.selection.forEach((symbol, idx) => {
            this.props.onUndoResolution(symbol, idx === (this.props.selection.length - 1));
          });
        } else if (!!this.props.selection && !!this.props.selection.id) {
          this.props.onUndoResolution(this.props.selection.id, true);
        }
      },
      () => { }
    );
  }

  isSelectedEventInstrument = () => {
    if (isArrayWithValues(this.props.selection)) {
      return this.props.selection.length === this.props.selection.filter(symbol => {
        const inst = this.props.instruments.instruments.find(inst => inst.id === symbol);
        return !isNullOrUndefined(inst) && inst.type === EVENT;
      }).length;
    } else if (!!this.props.selection && !!this.props.selection.id) {
      return this.props.selection.type === EVENT;
    }
    return false
  }

  getSubTypes = () => {
    if (isArrayWithValues(this.props.selection)) {
      let inst = this.props.instruments.instruments.find(inst => inst.id === this.props.selection[0])
      if (inst.type === EVENT) {
        return inst.subTypesList.map(type => type.symbol)
      }
    } else if (!!this.props.selection && !!this.props.selection.id) {
      if (this.props.selection.type === EVENT) {
        return this.props.selection.subTypesList.map(type => type.symbol)
      }
    }

    return []
  }

  render() {
    const { title, selection, stateWarningMessage, notAllowedStates, } = this.props;
    const errors = !!this.state ? this.state.errors : {};
    if (!this.state) {
      if (Array.isArray(selection) || selection.id) {
        let defaultState = { selState: 0, isScheduled: false, scheduleExecutionTime: null, errors: {} };
        if (!Array.isArray(selection)) {
          defaultState.selState = selection.state ? selection.state.id : 0;
          defaultState.isScheduled = selection.isScheduled;
        }
        this.setState(defaultState);
      }
      return <h1>{title}</h1>;
    }
    const instIds = Array.isArray(selection) ? selection : [selection.id];
    return (
      <form>
        <h1>{title}</h1>
        <Container>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <FieldGroupReadOnly
                id="id"
                label="Instrument IDs (Symbol)"
                value={instIds.join(", ")} />
            </Col>
          </Row>
          <Row>
            <Col lg={4} xs={4} md={4}>
              <Tooltip
                message={stateWarningMessage}
                show={stateWarningMessage !== null}
                position={Tooltip.bubblePosition.right}
              />
              <InstrumentStateListContainer
                id="state"
                name="state"
                label="State"
                selectedState={this.state.selState}
                enabled={!this.state.isScheduled}
                onChange={(_, e) => { this.setState({ selState: e.id, isScheduled: this.state.isScheduled }); }}
                notAllowedInstrumentStates={notAllowedStates}
              />
            </Col>
            <Col lg={8} xs={8} md={8}>
              <CheckboxGroup
                checked={this.state.isScheduled}
                onClick={(e) => { this.setState({ selState: this.state.selState, isScheduled: e.target.checked }); }}
                label={'Follow Instrument Trading Hours'}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4} xs={4} md={4}>
              <DateGroup
                id="scheduleExecutionTime"
                name="scheduleExecutionTime"
                label="Schedule Execution Time"
                enableTime={true}
                step={1}
                value={this.state.scheduleExecutionTime ? new Date(this.state.scheduleExecutionTime) : null}
                onChange={e => this.setState({ scheduleExecutionTime: e })}
                notEditable={true}
                timeFormat=' hh:mm A'
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonMain
                type="button"
                text="Save Instrument State"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.onSubmitForm(this.state);
                }}
              />
              <ButtonSecondary
                  type="button"
                  text="Perform EOD Actions"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.onTradeDayRollForm(this.state);
                  }}
              />
              <ButtonBorderless
                type="button"
                text="Cancel"
                icon="times-circle"
                customClassName="grey-icon"
                onClick={(e) => {
                  e.preventDefault();
                  const history = this.context.router.history;
                  if (history.length > 1) {
                    history.goBack();
                  } else {
                    history.push("/instruments");
                  }
                }} />
            </Col>
          </Row>
        </Container>

        {!!isNettingEnabled && this.isSelectedEventInstrument() &&
          <Container>
            <Row>
              <Col><h1>Resolution</h1></Col>
            </Row>

            <Row>
              <Col lg={3} xs={3} md={3}><DropdownListGroup
                id="symbolSubType"
                name="symbolSubType"
                label="Winning Symbol Sub Type"
                errors={errors}
                value={this.state.symbolSubType}
                onChange={(e) => {
                  this.setState({ "symbolSubType": e });
                }}
                data={this.getSubTypes()}
              ></DropdownListGroup></Col>
              <Col>
                <div style={{ marginTop: "30px" }}>
                  <ButtonMain
                    type="button"
                    text="Perform Resolution"
                    onClick={(e) => {
                      e.preventDefault();
                      this.performResolution();
                    }}
                  /></div></Col>
            </Row>
            <Row>
              <Col>
                <ButtonMain
                  type="button"
                  text="Undo Resolution"
                  onClick={(e) => {
                    e.preventDefault();
                    this.undoResolution()
                  }}
                />
              </Col>
            </Row>
          </Container>
        }
      </form>
    );
  }
};

const InstrumentFormMultiState = withFormik({
  mapPropsToValues: (instrument) => ({
    ...instrument.instrument
  }),
  validationSchema: Yup.object().shape({
    state: Yup.object().shape({
      id: Yup.string(),
      name: Yup.string()
    }),
    isScheduled: Yup.bool()
      .nullable()
  }),
  validate: values => {
    let errors = {};
    return errors;
  },
  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onSubmitForm(values, setFieldError);
  },
  displayName: 'instrument-state-form',
  enableReinitialize: true
})(BasicInstrumentFormMultiState);

export default connect(mapStateToProps, null)(InstrumentFormMultiState);
