import {
  FETCH_RFQ_START,
  FETCH_RFQ_SUCCEED,
  FETCH_RFQ_FAILED,
  CLEAN_RFQ_SEARCH,
  RESET_TOKEN,
  FETCH_RFQ_HISTORY_START,
  FETCH_RFQ_HISTORY_FAILED,
  FETCH_RFQ_HISTORY_SUCCEED,
} from "../constants/requestForQuoteTypes";

const initialState = {
  fetchingRequestForQuotes: false,
  requestForQuotes: [],
  noItemsFound: false,
  tokens: {},
};

const requestForQuotes = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RFQ_START:
    case FETCH_RFQ_HISTORY_START:
      return {
        ...state,
        fetchingRequestForQuotes: true,
        requestForQuotes: [],
      };
    case FETCH_RFQ_SUCCEED:
    case FETCH_RFQ_HISTORY_SUCCEED:
      return {
        ...state,
        fetchingRequestForQuotes: false,
        requestForQuotes: action.payload,
        noItemsFound: action.payload.length === 0 ? true : false,
        pageToken: action.pageToken,
        tokens: { ...state.tokens, [action.pageToken]: action.nextPageToken },
      };
    case FETCH_RFQ_FAILED:
    case FETCH_RFQ_HISTORY_FAILED:
      return {
        ...state,
        fetchingRequestForQuotes: false,
        requestForQuotes: [],
      };
    case CLEAN_RFQ_SEARCH:
      return {
        ...state,
        fetchingRequestForQuotes: false,
        noItemsFound: false,
        requestForQuotes: [],
      };
    case RESET_TOKEN:
      return {
        ...state,
        tokens: {},
      };
    default:
      return state;
  }
};

export default requestForQuotes;
