import React, { Component } from 'react';
import ReactTable from 'react-table';
import { connect } from "react-redux";
import { convertEnumToDropdownList } from '../../modules/util';
import { Side } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb';

const ID_FIELD = "name";
function mapStateToProps(state) {
    return {
        fetchingProducts: state.fetchingProducts,
    };
}

export class WhitelistProducts extends Component {
    constructor(props, context) {
        super(props, context);
        this.selectedSymbols = this.props.selectedItems || [];
        this.gridData = this.prepareGridData(this.props.data, this.selectedSymbols);
        this.gridColumns = this.prepareGridColumns();
        this.state = {
            gridData: this.gridData,
            gridColumns: this.gridColumns,
            selectAll: false,
            sideEnum: [{ id: Side.SIDE_UNDEFINED, name: "" }, ...convertEnumToDropdownList(Side, [Side.SIDE_UNDEFINED])]
        }
        this.tableRef = React.createRef();
        this.onRowSelect = this.props.onRowSelect;
    }

    componentDidMount() {
        this.updateSelectAllState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.length !== this.gridData.length) {
            this.gridData = this.prepareGridData(this.props.data, this.selectedSymbols);
            this.setState({ gridData: this.gridData, selectAll: false });
        }

        if (prevProps.selectedItems !== this.props.selectedItems) {
            this.selectedSymbols = this.props.selectedItems || [];
            this.gridData = this.prepareGridData(this.props.data, this.selectedSymbols);
            this.setState({ gridData: this.gridData });
        }
    }

    prepareGridData = (elements, selectedSymbols) => {
        let gridData = [];
        let idx = 0;
        for (const symbol in elements) {
            const gridRow = elements[symbol];
            const selectedSymbol = selectedSymbols.find((symbol) => symbol.product == gridRow[ID_FIELD]);

            gridData.push({
                index: idx++,
                selected: !!selectedSymbol,
                side: selectedSymbol?.side,
                gridRow,
            });
        }

        return gridData;
    }

    getCheckedStatus = (element) => {
        return element.selected;
    }

    getTableCurrentViewData = () => {
        if (this.tableRef.current) {
            return this.tableRef.current.getResolvedState().sortedData;
        }

        return null;
    }

    isAllSelected = (onlyInView = false) => {
        if (onlyInView) {
            let viewData = this.getTableCurrentViewData();
            if (viewData) {
                let inst = viewData.find((element) => element._original.selected === false)
                return !inst;
            }

            return false;
        }

        let searchData = this.state.gridData.find((element) => element.selected === false)
        return !searchData;
    }

    setRowSelection = (index, symbol, selected) => {
        let inst = this.state.gridData[index]

        if (inst.index !== index) {
            throw new Error("Table and data index mismatch")
        }

        inst.selected = selected;

        this.setState({ gridData: this.state.gridData, selectAll: this.isAllSelected() }, () => {
            this.onSelectNotify();
        });
    }

    setRestrictToSide = (index, symbol, value) => {
        let inst = this.state.gridData[index]

        if (inst.index !== index) {
            throw new Error("Table and data index mismatch")
        }

        inst.side = value;

        this.setState({ gridData: this.state.gridData, selectAll: this.isAllSelected() }, () => {
            this.onSelectNotify();
        });
    }

    selectAll = (selected) => {
        let viewData = this.getTableCurrentViewData();
        if (!viewData) return;

        let gridData = this.state.gridData;

        viewData.forEach(element => {
            let idx = element._original.index
            gridData[idx].selected = selected;
        });

        this.setState({ gridData: gridData, selectAll: selected }, () => {
            this.onSelectNotify();
        });
    }

    getSelectedSymbols = () => {
        let selectedSymbols = [];
        this.state.gridData.forEach(element => {
            if (element.selected) {
                selectedSymbols.push({
                    product: element.gridRow[ID_FIELD],
                    side: element.side,
                });
            }
        });

        return selectedSymbols;
    }

    onSelectNotify = () => {
        if (this.onRowSelect) {
            const symbols = this.getSelectedSymbols();
            this.onRowSelect(symbols)
        }
    }

    filterData = (filter, row, column) => {
        const rowData = row[filter.id];

        if (rowData && filter.value) {
            return rowData.toLowerCase().includes(filter.value.toLowerCase());
        }

        return false;
    }

    updateSelectAllState = () => {
        this.setState({ selectAll: this.isAllSelected(true) });
    }

    restrictToSideOptions = (row) => {
        return this.state.sideEnum.map((item) => {
            return <option defaultValue={row?.side == item.id} key={item.id} value={item.id}>{item.name}</option>
        });
    }

    prepareGridColumns = () => {
        let gridColumns = [
            {
                id: "selected",
                filterable: false,
                sortable: false,
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            checked={this.getCheckedStatus(original)}
                            onChange={(e) => {
                                this.setRowSelection(original.index, original.gridRow[ID_FIELD], e.target.checked);
                            }}
                        />
                    );
                },
                Header: x => {
                    return (
                        <input
                            type="checkbox"
                            checked={this.state.selectAll}
                            onChange={(e) => this.selectAll(e.target.checked)}
                        />
                    );
                },

                width: 50
            },
            {
                Header: "Product",
                id: "name",
                accessor: (d) => d.gridRow.name,
            },
            {
                Header: "Description",
                id: "description",
                accessor: (d) => d.gridRow.description,
            },
            {
                id: "Restrict to Side",
                filterable: false,
                sortable: false,
                Cell: ({ original }) => {
                    return (
                        <select value={original?.side} className='rw-widget-input rw-widget-container'
                            style={{ height: '37px', width: '100%' }}
                            onChange={(e) => {
                                this.setRestrictToSide(original.index, original.gridRow[ID_FIELD], e.target.value);
                            }}
                        >{this.restrictToSideOptions(original)}</select>
                    );
                },
                Header: "Restrict to Side",

                width: 130
            },
            {
                Header: "Clearing House",
                id: "clearingHouse",
                accessor: (d) => d.gridRow.clearingHouse.displayName
            },
        ]

        return gridColumns;
    }

    render() {
        return (
            <>
                <div id="InstrumentSelector">
                    <ReactTable
                        ref={this.tableRef}
                        data={this.state.gridData}
                        parent={this}
                        filterable={true}
                        sortable={true}
                        minRows={0}
                        columns={this.gridColumns}
                        showPagination={true}
                        showPageSizeOptions={true}
                        showPageJump={false}
                        defaultPageSize={10}
                        onFilteredChange={() => {
                            this.updateSelectAllState();
                        }}
                        defaultFilterMethod={(filter, row, column) => {
                            return this.filterData(filter, row, column)
                        }}
                    >
                    </ReactTable>
                </div >
            </>
        )
    }
}

export default connect(mapStateToProps, null)(WhitelistProducts);