import React from "react";
import PropTypes from "prop-types";
import '../data-grid/Pagination.css'

const defaultButton = props => <button {...props}>{props.children}</button>;
export default class Pagination extends React.Component {
  constructor(props) {
    super();
    this.changePage = this.changePage.bind(this);
  }

  static propTypes = {
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  };

  changePage(pageToken) {
    if (pageToken === 'undefined')
      pageToken = undefined;
    this.props.onPageChange(pageToken);
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const { tokens, pageToken } = this.props.pageTokenData;

    let previousPageToken = pageToken;
    if (previousPageToken === 'undefined')
      previousPageToken = undefined;

    let nextPageToken = undefined;
    if (tokens) {
      nextPageToken = tokens[previousPageToken];
      if (nextPageToken === 'undefined')
        nextPageToken = undefined;
    }
    return (
      <div className="Table__pagination">
        <div className="Table__prevPageWrapper">
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              let token = Object.keys(tokens).find(key => tokens[key] === previousPageToken)
              this.changePage(token);
            }}
            disabled={!previousPageToken}
          >
            {this.props.previousText}
          </PageButtonComponent>
        </div>
        <div className="Table__nextPageWrapper">
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              this.changePage(nextPageToken);
            }}
            disabled={!nextPageToken}
          >
            {this.props.nextText}
          </PageButtonComponent>
        </div>
      </div>
    );
  }
}
export function withPageToken(PaginationComponent, pageTokenData) {
  return class extends React.Component {
    render() {
      return <PaginationComponent pageTokenData={pageTokenData} {...this.props} />;
    }
  };
}