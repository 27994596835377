import React from 'react';
import PropTypes from 'prop-types';
import ParticipantGroupForm from './ParticipantGroupForm';
import ParticipantGroupFormReadOnly from './ParticipantGroupFormReadOnly';
import { FormModes } from '../../constants/enumerations';

const ParticipantGroupDetails = ({
  onSubmitForm,
  onDisableGroup,
  onDelete,
  mode }) => {
  var form = null;
  switch (mode) {
    case FormModes.view:
      form = <ParticipantGroupFormReadOnly />
      break;
    case FormModes.edition:
    case FormModes.creation:
      const isEditing = mode === FormModes.edition;
      form = (<ParticipantGroupForm
        title={isEditing ? 'Edit Participant Group' : 'Create Participant Group'}
        isEditing={isEditing}
        onSubmitForm={onSubmitForm}
        onDisableGroup={onDisableGroup}
        onDelete={onDelete}
      />
      );
      break;
    default:
      break;
  }
  return form;
}

ParticipantGroupDetails.propTypes = {
  mode: PropTypes.oneOf(Object.keys(FormModes)).isRequired,
  onSubmitForm: PropTypes.func.isRequired
}

export default ParticipantGroupDetails;