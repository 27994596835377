import ApiCall from "../modules/apiCall";
import apiCallPromise from "../modules/apiCallPromise";
import {apiUrl} from "../modules/util";

const {
  GetProductRequest,
  CreateProductRequest,
  UpdateProductRequest,
  DeleteProductRequest,
  ListProductsRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_api_pb.js");
const {
  InstrumentsAPIClient,
  InstrumentsAPIPromiseClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_api_grpc_web_pb.js");

const client = new InstrumentsAPIClient(
  apiUrl(),
  null,
  null
);

const promiseClient = new InstrumentsAPIPromiseClient(
  apiUrl(),
  null,
  null
);

export default class ProductService {
  static get(id, cb) {
    var request = new GetProductRequest();
    request.setProductId(id);

    ApiCall(client, "getProduct", request, cb);
  }

  static async get2(id) {
    var request = new GetProductRequest();
    request.setProductId(id);

    return await apiCallPromise(promiseClient, "getProduct", request);
  }

  static getAll(cb) {
    var request = new ListProductsRequest();

    ApiCall(client, "listProducts", request, cb);
  }

  static create(product, cb) {
    var request = new CreateProductRequest();
    request.setProduct(product);

    ApiCall(client, "createProduct", request, cb);
  }

  static update(product, cb) {
    var request = new UpdateProductRequest();
    request.setProduct(product);

    ApiCall(client, "updateProduct", request, cb);
  }

  static delete(id, cb) {
    var request = new DeleteProductRequest();
    request.setProductId(id);

    ApiCall(client, "deleteProduct", request, cb);
  }
}
