import React from "react";
import PropTypes from "prop-types";
import { Multiselect } from "react-widgets";
const errorBorder = {
  border: "solid 1px #DB614A",
};

const MultiSelectControl = ({
  data,
  dropUp,
  value,
  enabled,
  itemComponent,
  onChange,
  onBlur,
  textField,
  valueField,
  errors,
  defaultValue,
  readOnly
}) => (
  <Multiselect
    style={errors ? errorBorder : null}
    data={data}
    valueField={valueField}
    textField={textField}
    value={value}
    disabled={!enabled}
    itemComponent={itemComponent}
    dropUp={dropUp}
    onChange={onChange}
    onBlur={onBlur}
    defaultValue={defaultValue}
    readOnly={readOnly}
  />
);

MultiSelectControl.defaultProps = {
  data: [],
  dropUp: false,
  value: null,
  enabled: true,
  textField: "name",
  valueField: "id",
  errors: false,
};

MultiSelectControl.propTypes = {
  data: PropTypes.any.isRequired,
  textField: PropTypes.string,
  valueField: PropTypes.string,
  dropUp: PropTypes.bool,
  value: PropTypes.any,
  enabled: PropTypes.bool,
  itemComponent: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

export default MultiSelectControl; 
