import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  FetchParticipantsStart,
  FetchParticipantsSucceed,
  FetchParticipantsFailed,
  UnDeleteParticipant,
  ToggleShowDeletedParticipants,
} from "../actions/participants";
import { LoadFirms } from "../actions/firms";
import Loader from "../components/core/loader/Loader";
import "./Content.css";
import UsersHeader from "../components/users/UsersHeader";
import { AdminDocumentTitle } from "../constants/strings";
import Notification from "../modules/notifications";
import UsersDataGrid from "../components/users/UsersDataGrid";
import confirm from "../modules/confirmDialog";
import ParticipantService from "../services/ParticipantService";
import Participant from "../entities/Participant";
import { hasWriteAccess } from "../services/TokenService";
import CheckboxGroup from "../components/core/form/CheckboxGroup";
import UsersFilter from "../components/users/UsersFilter"
import { Col, Row } from "react-bootstrap";
import { isArrayWithValues, isNumber } from "../modules/util";
import { NavigationManager } from "../components/core/helpers/NavigationHelper";

const { ListParticipantsRequest } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");

function mapStateToProps(state) {
  return {
    fetchingParticipants: state.participants.fetchingParticipants,
    participants: state.participants.participants.map((p) => {
      var firm = state.firms.firms.find((f) => f.name === p.firm);
      p.firm = firm ? firm : p.firm;
      return p;
    }),
    showDeletedParticipants: state.participants.showDeletedParticipants,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchParticipantsStart: () => {
    dispatch(FetchParticipantsStart());
  },
  fetchParticipantsSucceed: (participanttList) => {
    dispatch(FetchParticipantsSucceed(participanttList));
  },
  fetchParticipantsFailed: () => {
    dispatch(FetchParticipantsFailed());
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
  toggleShowDeletedParticipants: () => {
    dispatch(ToggleShowDeletedParticipants());
  },
  unDeleteParticipant: (id) => {
    return UnDeleteParticipant(id);
  },
});

class Users extends Component {

  constructor() {
    super();
    this.state = {
      users: null,
      oldFilter: {
        "role": "",
        "firm": "",
      },
      filter: {
        firm: null,
        userName: null,
        state: null,
        role: null
    }
    };
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.loadParticipants(this.props.showDeletedParticipants);
    this.props.loadFirms();
    window.scrollTo(0, 0);
  }

  static contextTypes = {
    router: PropTypes.object,
  };

  static getDerivedStateFromProps(props, state) {
    if (!!state) {
      if (!isArrayWithValues(state.users)) {
        return { users: Users.filterData(props.participants, state.filter) };
      }
    }
    return null;
  }

  loadParticipants = (showDeletedParticipants = false, firm = "", role = "") => {
    this.props.fetchParticipantsStart();

    const cb = (err, response) => {
      if (response) {
        var participantsList = response
          .getParticipantsList()
          .map((participant) => {
            return new Participant(participant);
          });
        this.props.fetchParticipantsSucceed(participantsList);
      }

      if (err) {
        this.props.fetchParticipantsFailed();
        Notification.error("Cannot get list of participants.");
      }

      this.setState({ users: null, oldFilter: { firm: firm, role: role } });
    };

    var request = new ListParticipantsRequest();
    if (showDeletedParticipants) request.setShowDeleted(true);
    if (!!firm) request.setFirm(firm);
    if (!!role) request.setRole(role)

    ParticipantService.listParticipants(request, cb);
  };

  unDeleteParticipant = async (name, displayName) => {
    await confirm("Are you sure you want to restore this User?", {
      title: "User Restore Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    });

    try {
      await this.props.unDeleteParticipant(name);
      this.context.router.history.push("/users");
      Notification.success(`User ${displayName} restored.`);
    } catch (e) {
      console.error("could not restore user", e);
      Notification.error("Could not restore user", displayName);
    }
  };

  onView = (name, actionContext) => {
     //support legacy users
    let viewUrl = `/users/${name}`
    
    if (name.startsWith("firms/")) {
      viewUrl = name
    }

    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(viewUrl);
    } else {
      this.context.router.history.push(viewUrl);
    }
  };

  onEdit = (name, actionContext) => {
    //support legacy users
    let viewUrl = `/users/${name}/edit`;

    if (name.startsWith("firms/")) {
      viewUrl = `${name}/edit`;
    } 

    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(viewUrl);
    } else {
      this.context.router.history.push(viewUrl);
    }
  };

  onRemove = (username) => {
    confirm(<div>Are you sure you want to remove this user?</div>, {
      title: "User Remove Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            Notification.success("User removed.");
            this.loadParticipants(this.props.showDeletedParticipants);
          }

          if (err) {
            Notification.error("Cannot remove user.");
          }
        };
        ParticipantService.delete(username, cb);
      },
      () => { }
    );
  };

  onButtonClick = () => {
    return this.context.router.history.push(`/users/new`);
  };

  filterUsers = (userName, userState, role, firm) => {
    this.setState({
      filter: {
        "userName": userName,
        "userState": userState,
        "role": !!role ? role : "",
        "firm": !!firm ? firm : "",
      }
    }, () => {
      if (this.state.filter.firm.firm !== this.state.oldFilter.firm || this.state.filter.role.userRole !== this.state.oldFilter.role) {
        this.loadParticipants(this.props.showDeletedAccounts, firm ? firm.firm : null, role ? role.userRole : null);
      } else {
        this.setState({ users: Users.filterData(this.props.participants, this.state.filter) });
      }
    });
  }

  static filterData = (data, filter) => {
    if (!!filter) {
      let acReg = new RegExp(filter.userName ? filter.userName.value : null, "i");

      let filteredData = data.filter(
        (acc) => {
          let res = true;
          if (!!filter.userName) {
            res = res && !!acc.displayName.match(acReg)
          }

          if (!!res && filter.userState && isNumber(filter.userState.userState)) {
            res = res && acc.state.id === filter.userState.userState
          }

          return res;
        }
      );
      return filteredData;
    }

    return data;
  }

  render() {
    return (
      <div className="with-callback">
        <UsersHeader
          isAddButtonVisible={hasWriteAccess()}
          onButtonClick={this.onButtonClick}
          headerText="Users"
          headerLink={`${window.location.origin}/users`}
        />
        <Row>
          <Col lg={10} xs={10} md={10}>
            <UsersFilter onApplyFilter={(userName, userState, role, firm) => {
              this.filterUsers(userName, userState, role, firm);
            }}  filters={this.state.filter} />
          </Col>
          <Col lg={2} xs={2} md={2} style={{ marginTop: "40px" }}>
            <CheckboxGroup
              checked={this.props.showDeletedParticipants}
              onChange={(e) => {
                this.props.toggleShowDeletedParticipants();
                this.loadParticipants(!this.props.showDeletedParticipants);
              }}
              label={"Show Deleted Users"}
            />
          </Col>
        </Row>

        <Loader show={this.props.fetchingParticipants} />
        <UsersDataGrid
          onView={(username, actionContext) => this.onView(username, actionContext)}
          onEdit={(username, actionContext) => this.onEdit(username, actionContext)}
          onRemove={(username) => {
            this.onRemove(username);
          }}
          data={(!!this.state && !!this.state.users) ? this.state.users : []}
          onUnDelete={(name, displayName) => {
            this.unDeleteParticipant(name, displayName);
          }}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
