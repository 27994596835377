import { getEnumName, convertDateToString } from '../modules/util';
import State from "./dto/State";
import { Side } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb';

export default class Commission {
  constructor(item) {
    let comm = item.getCommission().toObject();
    this.account = comm.account;
    this.basisPoints = comm.basisPoints;
    this.minimumNotional = comm.minimumNotional;
    this.maximumRateBasisPoints = comm.maximumRateBasisPoints;
    this.maximumNotional = comm.maximumNotional;
    this.applyAsSpread = !!comm.applyAsSpread;

    let protoRule = item.getRule();
    let rule = protoRule.toObject()

    if (protoRule.getExpirationTime()) {
      this.expirationTime = protoRule.getExpirationTime().toDate();
      this.expirationTimeString = rule.expirationTime ? convertDateToString(this.expirationTime, "YYYYMMDD-HH:mm:ss.SSS") : "";
    }

    this.firm = rule.firm;

    if (rule.sideOverride) {
      let state = new State();
      state.id = rule.sideOverride;
      state.name = getEnumName(Side, rule.sideOverride)
      this.sideOverride = state;
    }

    this.symbolOverride = rule.symbolOverride;
    this.userOverride = rule.userOverride;
    this.accountOverride = rule.accountOverride;
  }

  account = null;
  basisPoints = null;
  minimumNotional = null;
  maximumRateBasisPoints = null;
  maximumNotional = null;
  expirationTime = null;
  expirationTimeString = "";
  firm = null;
  sideOverride = null;
  symbolOverride = null;
  userOverride = null;
}