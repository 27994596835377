import {
  FETCH_INSTRUMENTS_START,
  FETCH_INSTRUMENTS_SUCCEED,
  FETCH_INSTRUMENTS_FAILED,
  FETCH_METADATA_START,
  FETCH_METADATA_SUCCEED,
  FETCH_METADATA_FAILED,
  FETCH_HOLIDAY_CALENDARS_START,
  FETCH_HOLIDAY_CALENDARS_SUCCEED,
  FETCH_HOLIDAY_CALENDARS_FAILED,
  FETCH_INSTRUMENT_START,
  FETCH_INSTRUMENT_SUCCEED,
  FETCH_INSTRUMENT_FAILED,
  CREATE_INSTRUMENT_STATE,
  CLEAN_INSTRUMENT,
  UPDATE_INSTRUMENT_STATE_START,
  UPDATE_INSTRUMENT_STATE_SUCCEED,
  UPDATE_INSTRUMENT_STATE_FAILED,
  UPDATE_INSTRUMENT_ITEM,
  SET_STATE_WARNING_MESSAGE,
  SET_NOT_ALLOWED_STATES,
  CLEAN_INSTRUMENTS_LIST,
  CLEAN_INSTRUMENT_IDS_LIST,
  SET_INSTRUMENT_IDS_LIST,
  REFRESH_INSTRUMENTS_SEARCH,
  REMOVE_INSTRUMENTS,
  SET_INSTRUMENT_BOOKS,
  FETCH_INSTRUMENTS_REPORT_START,
  FETCH_INSTRUMENTS_REPORT_SUCCEED,
  FETCH_INSTRUMENTS_REPORT_FAILED,
  FETCH_SYMBOLS_START,
  FETCH_SYMBOLS_SUCCEED,
  FETCH_SYMBOLS_FAILED,
} from '../constants/instrumentTypes';

import {
  ADJUST_DATES_START,
  ADJUST_DATES_SUCCESS,
  ADJUST_DATES_FAILED,
} from "../constants/dateTypes";

import { RESET } from "../constants/appTypes";

import InstrumentFormItem from "../entities/dto/InstrumentFormItem";

const initialState = {
  isCalculatingDates: false,
  isUpdatingState: false,
  isCreatingInstrument: false,
  fetchingMetadata: false,
  metadata: null,
  fetchingHolidayCalendars: false,
  holidayCalendars: [],
  holidayCalendarObjects: {},
  fetchingInstruments: false,
  fetchingInstrument: false,
  fetchingInstrumentReport: false,
  instrument: new InstrumentFormItem(),
  instruments: [],
  stateWarningMessage: null,
  notAllowedInstrumentStates: [],
  matchedInstrumentsAutoComplete: [],
  instrumentIdsList: [],
  tokens: {},
  pageToken: null,
  type: null,
  instrumentBooks: {},
  fetchingSymbols: false,
  symbols: []
};

const instruments = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSTRUMENTS_START:
      return {
        ...state,
        fetchingInstruments: true,
      };
    case REMOVE_INSTRUMENTS:
      return {
        ...state,
        instruments: [],
      };
    case FETCH_INSTRUMENTS_SUCCEED:
      return {
        ...state,
        instruments: [...action.payload],
        fetchingInstruments: false,
      };
    case SET_INSTRUMENT_BOOKS:
      action.payload.books.forEach((book, sym) => {
        state.instrumentBooks[sym] = book;
      })
      return { ...state };
    case FETCH_INSTRUMENTS_FAILED:
      return {
        ...state,
        fetchingInstruments: false,
        instruments: [],
      };
    case FETCH_METADATA_START:
      return {
        ...state,
        fetchingMetadata: true,
      };
    case FETCH_METADATA_SUCCEED:
      return {
        ...state,
        fetchingMetadata: false,
        metadata: action.payload,
      };
    case FETCH_METADATA_FAILED:
      return {
        ...state,
        fetchingMetadata: false,
        metadata: null,
      };
    case FETCH_HOLIDAY_CALENDARS_START:
      return {
        ...state,
        fetchingHolidayCalendars: true,
      };
    case FETCH_HOLIDAY_CALENDARS_SUCCEED:
      return {
        ...state,
        fetchingHolidayCalendars: false,
        holidayCalendars: action.payload,
        holidayCalendarObjects: action.calendars,
      };
    case FETCH_HOLIDAY_CALENDARS_FAILED:
      return {
        ...state,
        fetchingHolidayCalendars: false,
        holidayCalendars: [],
        holidayCalendarObjects: {},
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    case CREATE_INSTRUMENT_STATE:
      return {
        ...state,
        isCreatingInstrument: action.value,
      };
    case FETCH_INSTRUMENT_START:
      return {
        ...state,
        fetchingInstrument: true,
      };
    case FETCH_INSTRUMENT_SUCCEED:
      return {
        ...state,
        fetchingInstrument: false,
        instrument: action.payload,
      };
    case FETCH_INSTRUMENT_FAILED:
      return {
        ...state,
        fetchingInstrument: false,
      };
    case CLEAN_INSTRUMENT:
      return {
        ...state,
        instrument: new InstrumentFormItem(),
      };
    case UPDATE_INSTRUMENT_STATE_START:
      return {
        ...state,
        isUpdatingState: true,
      };
    case UPDATE_INSTRUMENT_STATE_SUCCEED:
      return {
        ...state,
        isUpdatingState: false,
      };
    case UPDATE_INSTRUMENT_STATE_FAILED:
      return {
        ...state,
        isUpdatingState: false,
      };
    case ADJUST_DATES_START:
      return {
        ...state,
        isCalculatingDates: true,
      };
    case ADJUST_DATES_SUCCESS:
      return {
        ...state,
        isCalculatingDates: false,
      };
    case ADJUST_DATES_FAILED:
      return {
        ...state,
        isCalculatingDates: false,
      };
    case UPDATE_INSTRUMENT_ITEM:
      return {
        ...state,
        instrument: new InstrumentFormItem({
          ...state.instrument,
          [action.key]: action.value,
        }),
      };
    case SET_STATE_WARNING_MESSAGE:
      return {
        ...state,
        stateWarningMessage: action.value,
      };
    case SET_NOT_ALLOWED_STATES:
      return {
        ...state,
        notAllowedInstrumentStates: action.value,
      };
    case CLEAN_INSTRUMENTS_LIST:
      return {
        ...state,
        instruments: [],
      };
    case CLEAN_INSTRUMENT_IDS_LIST:
      return {
        ...state,
        instrumentIdsList: [],
      };
    case SET_INSTRUMENT_IDS_LIST:
      return {
        ...state,
        instrumentIdsList: action.list,
      };
    case REFRESH_INSTRUMENTS_SEARCH:
      let newState = {
        ...state,
        instruments: action.payload.instruments,
        pageToken: action.payload.pageToken,
        tokens: {
          ...state.tokens,
          [action.payload.pageToken]: action.payload.nextPageToken,
        },
        fetchingInstruments: false,
      };
      return newState;
    case FETCH_INSTRUMENTS_REPORT_START:
      return {
        ...state,
        fetchingInstrumentReport: true,
      };
    case FETCH_INSTRUMENTS_REPORT_FAILED:
      return {
        ...state,
        fetchingInstrumentReport: false,
      };
    case FETCH_INSTRUMENTS_REPORT_SUCCEED:
      return {
        ...state,
        fetchingInstrumentReport: false,
      };
    case FETCH_SYMBOLS_START:
      return {
        ...state,
        fetchingSymbols: true,
      };
    case FETCH_SYMBOLS_FAILED:
      return {
        ...state,
        fetchingSymbols: false,
      };
    case FETCH_SYMBOLS_SUCCEED:
      return {
        ...state,
        fetchingSymbols: false,
        symbols: action.payload,
      };
    default:
      return state;
  }
};

export default instruments;
