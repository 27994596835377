import TradingHour from "./TradingHour";
import { protoDateToDate } from "../actions/ptypes";
import {
  setFRAAtts,
  setIRSAtts,
  setFsIRSAtts,
  setFutureAtts,
  setOptionAtts,
  setDefaultAtts,
  setMultilegAtts, setBasisAtts, setEventAtts, setOISAtts, setSpsAtts, setNDFAtts, setForexAtts, setZeroCouponInflationSwapAtts
} from "../modules/instrumentUtils";
import {parsePrice, countDecimals} from "../modules/util";
import TradeDayRollSchedule from "./TradeDayRollSchedule";

export default class Product {
  constructor(item) {
    if (item) {
      this.name = item.getId();
      this.description = item.getDescription();
      this.externalSymbol = item.getExternalSymbol();

      const minimumPriceIncrement = item.getAttributes().getTickSize();
      this.minimumPriceIncrement = minimumPriceIncrement === 0 ? null : minimumPriceIncrement;

      const priceScale = Math.pow(10, countDecimals(this.minimumPriceIncrement));

      this.fractionalQtyScale = item.getAttributes().getFractionalQtyScale();
      let minTradeQtyInt = item.getAttributes().getMinimumTradeQty();
      this.minimumTradeQuantity = parseFloat(parsePrice(minTradeQtyInt, this.fractionalQtyScale));

      this.minimumTradeQuantity = this.minimumTradeQuantity === 0 ? null : this.minimumTradeQuantity;

      this.minimumUnaffiliatedFirms = item.getAttributes().getMinimumUnaffiliatedFirms();
      this.tradeSettlementPeriod = item.getAttributes().getTradeSettlementPeriod();
      this.holidayCalendars = item.getAttributes().getHolidayCalendarsList();

      this.crossOrderRestingDuration = !!item.getAttributes().getCrossOrderRestingDuration() ? item.getAttributes().getCrossOrderRestingDuration().toObject() : null;

      this.blockTradeThreshold = !!item.getAttributes().getBlockTradeThreshold() ? item.getAttributes().getBlockTradeThreshold().toObject() : null;

      if (!!this.blockTradeThreshold) {
        let blockMinQty = this.blockTradeThreshold.minimumQuantity;
        this.blockTradeThreshold.minimumQuantity = parseFloat(parsePrice(blockMinQty, this.fractionalQtyScale));

        const notionalScale = priceScale * this.fractionalQtyScale;
        const minimumNotionalPrice = this.blockTradeThreshold.minimumNotional;
        this.blockTradeThreshold.minimumNotional = parseFloat(parsePrice(minimumNotionalPrice, notionalScale));
      }

      this.multiplier = item.getAttributes().getMultiplier();
      this.basecurrency = item.getAttributes().getBaseCurrency();
      this.clearingHouse = item.getAttributes().getClearingHouse();

      const startDate = item.getAttributes().getStartDate();
      this.startDate = startDate ? protoDateToDate(startDate) : null;

      const expirationDate = item.getAttributes().getExpirationDate();
      const expirationTime = item.getAttributes().getExpirationTime();
      this.expirationDate = expirationDate ? protoDateToDate(expirationDate, expirationTime) : null;

      const terminationDate = item.getAttributes().getTerminationDate();
      this.terminationDate = terminationDate ? protoDateToDate(terminationDate) : null;

      const priceLimit = item.getAttributes().getPriceLimit();
      this.setLowPriceLimit = priceLimit ? priceLimit.getLowSet() : null;
      this.setHighPriceLimit = priceLimit ? priceLimit.getHighSet() : null;

      if (this.setLowPriceLimit) this.lowPriceLimit = priceLimit.getLow();
      if (this.setHighPriceLimit) this.highPriceLimit = priceLimit.getHigh();

      this.relativeLowSet = priceLimit ? priceLimit.getRelativeLowSet() : null;
      this.relativeHighSet = priceLimit ? priceLimit.getRelativeHighSet() : null;

      if (this.relativeLowSet) this.relativeLow = priceLimit ? priceLimit.getRelativeLow() : null;
      if (this.relativeHighSet) this.relativeHigh = priceLimit ? priceLimit.getRelativeHigh() : null;

      const orderLimits = item.getAttributes().getOrderSizeLimit();
      this.lowOrderLimit = orderLimits ? orderLimits.getLow() : null;
      this.highOrderLimit = orderLimits ? orderLimits.getHigh() : null;
      this.setLowOrderLimit = orderLimits ? orderLimits.getLowSet() : null;
      this.setHighOrderLimit = orderLimits ? orderLimits.getHighSet() : null;
      this.lowTotalNotionalLimit = orderLimits ? orderLimits.getTotalNotionalLow() : null;
      this.highTotalNotionalLimit = orderLimits ? orderLimits.getTotalNotionalHigh() : null;
      this.setLowTotalNotionalLimit = orderLimits ? orderLimits.getTotalNotionalLowSet() : null;
      this.setHighTotalNotionalLimit = orderLimits ? orderLimits.getTotalNotionalHighSet() : null;
      this.skipRiskAndCurrencyChecks = item.getAttributes().getSkipRiskAndCurrencyChecks();

      const tradingSchedule = item.getAttributes().getTradingScheduleList();
      var tradingHours = [];
      if (tradingSchedule) {
        for (var i = 0; tradingSchedule.length > i; i++) {
          tradingHours.push(new TradingHour(tradingSchedule[i]));
        }
      }
      this.tradingSchedule = tradingHours;

      const tradeDayRollScheduleList = item.getAttributes().getTradeDayRollScheduleList();
      if (tradeDayRollScheduleList){
        this.tradeDayRollSchedule = TradeDayRollSchedule.parseProto(tradeDayRollScheduleList);
      }

      this.categoryLevels = [];
      const categoryLevels = item.getAttributes().getCategoryLevelsList();
      if (categoryLevels.length > 0) {
        this.categoryLevels = categoryLevels;
      }

      this.cfiCode = item.getAttributes().getCfiCode();
      this.instrumentProductType = item.getAttributes().getInstrumentProductType();

      const settlementPeriodStartOffsetFromCloseDuration = item.getAttributes().getSettlementPeriodStartOffsetFromCloseDuration();
      this.settlementPeriodStartOffsetFromCloseDuration = settlementPeriodStartOffsetFromCloseDuration ? settlementPeriodStartOffsetFromCloseDuration.toObject() : null;

      const settlementPeriodEndOffsetFromCloseDuration = item.getAttributes().getSettlementPeriodEndOffsetFromCloseDuration();
      this.settlementPeriodEndOffsetFromCloseDuration = settlementPeriodEndOffsetFromCloseDuration ? settlementPeriodEndOffsetFromCloseDuration.toObject() : null;

      this.minimumTradeQtyForVwapSettlementCalculation = item.getAttributes().getMinimumTradeQtyForVwapSettlementCalculation();

      const bufferFromEndOfSettlementPeriodDuration = item.getAttributes().getBufferFromEndOfSettlementPeriodDuration();
      this.bufferFromEndOfSettlementPeriodDuration = bufferFromEndOfSettlementPeriodDuration ? bufferFromEndOfSettlementPeriodDuration.toObject() : null;

      this.settlementPriceLogic = item.getAttributes().getSettlementPriceLogic();
      this.settlementPriceCalculationRequiresApproval = item.getAttributes().getSettlementPriceCalculationRequiresApproval();
      this.settlementPriceCalculationEndingMethod = item.getAttributes().getSettlementPriceCalculationEndingMethod();

      if (item.hasInterestRateSwapAttributes()) {
        let irsAtts = item.getInterestRateSwapAttributes().toObject();
        setIRSAtts(irsAtts, this);
      } else if (item.hasForwardRateAgreementSwapAttributes()) {
        let fraAtts = item.getForwardRateAgreementSwapAttributes().toObject();
        setFRAAtts(fraAtts, this);
      } else if (item.hasForwardStartingInterestRateSwapAttributes()) {
        let fsIRSAtts = item.getForwardStartingInterestRateSwapAttributes().toObject();
        setFsIRSAtts(fsIRSAtts, this);
      } else if (item.hasFutureAttributes()) {
        let atts = item.getFutureAttributes().toObject();
        setFutureAtts(atts, this);
      } else if (item.hasOptionAttributes()) {
        let atts = item.getOptionAttributes().toObject();
        setOptionAtts(atts, this);
      } else if (item.hasForexAttributes()) {
        let atts = item.getForexAttributes().toObject();
        setForexAtts(atts, this);
      } else if (item.hasMultilegAttributes()) {
        let atts = item.getMultilegAttributes().toObject();
        setMultilegAtts(atts, this);
      } else if (item.hasBasisSwapAttributes()) {
        let atts = item.getBasisSwapAttributes().toObject();
        setBasisAtts(atts, this);
      } else if (item.hasEventAttributes()) {
        let atts = item.getEventAttributes().toObject();
        setEventAtts(atts, this, priceScale);
      } else if (item.hasNonDeliverableForwardAttributes()) {
        let atts = item.getNonDeliverableForwardAttributes().toObject();
        setNDFAtts(atts, this);
      } else if (item.hasOvernightIndexSwapAttributes()) {
        let atts = item.getOvernightIndexSwapAttributes().toObject();
        setOISAtts(atts, this);
      } else if (item.hasSinglePeriodSwapAttributes()) {
        let atts = item.getSinglePeriodSwapAttributes().toObject();
        setSpsAtts(atts, this);
      } else if (item.hasZeroCouponInflationSwapAttributes()) {
        let atts = item.getZeroCouponInflationSwapAttributes().toObject();
        setZeroCouponInflationSwapAtts(atts, this);
      } else {
        setDefaultAtts(this);
      }
      return;
    }

    this.name = null;
    this.description = null;
    this.externalSymbol = null;
    this.cfiCode = null;
    this.instrumentProductType = null;
    this.minimumPriceIncrement = null;
    this.minimumTradeQuantity = null;
    this.minimumUnaffiliatedFirms = null;
    this.crossOrderRestingDuration = null;
    this.blockTradeThreshold = {};
    this.multiplier = null;
    this.tradeSettlementPeriod = null;
    this.holidayCalendars = null;
    this.basecurrency = null;
    this.startDate = null;
    this.expirationDate = null;
    this.terminationDate = null;
    this.tradingScheduleList = null;
    this.lowPriceLimit = null;
    this.highPriceLimit = null;
    this.setLowPriceLimit = null;
    this.setHighPriceLimit = null;
    this.lowOrderLimit = null;
    this.highOrderLimit = null;
    this.setLowOrderLimit = null;
    this.setHighOrderLimit = null;
    this.lowTotalNotionalLimit = null;
    this.highTotalNotionalLimit = null;
    this.setLowTotalNotionalLimit = null;
    this.setHighTotalNotionalLimit = null;
    this.skipRiskAndCurrencyChecks = null;
    this.clearingHouse = null;
    this.fractionalQtyScale = null;
    this.settlementPeriodStartOffsetFromCloseDuration = null;
    this.settlementPeriodEndOffsetFromCloseDuration = null;
    this.minimumTradeQtyForVwapSettlementCalculation = null;
    this.bufferFromEndOfSettlementPeriodDuration = null;
    this.settlementPriceLogic = null;
    this.settlementPriceCalculationRequiresApproval = false;
    this.settlementPriceCalculationEndingMethod = null;
  }
}
