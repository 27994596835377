import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { SetCurrentTab } from "../actions/app";
import "./Content.css";
import OrdersSearchHeader from "../components/order-history/OrdersSearchHeader";
import OrdersSearchPageSelector from "../components/order-history/OrdersSearchPageSelector";
import { getFormMode } from "../modules/util";
import { NavigationManager } from "../components/core/helpers/NavigationHelper";

function mapStateToProps(state) {
  return {
    currentTab: state.app.currentTab,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentTab: (selectedTab) => {
    dispatch(SetCurrentTab(selectedTab));
  },
});

class OrderSearchFormPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  selectTab = (selectedTab) => {
    switch (selectedTab) {
      case "orders":
        return this.context.router.history.push(`/order-search/orders`);
      case "executions":
        return this.context.router.history.push(`/order-search/executions`);
      default:
        console.error("No tab found!");
        break;
    }
  };

  viewOrderHistory = (orderId, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/order-search/executions?orderid=${orderId}`);
    } else {
      this.context.router.history.push(`/order-search/executions?orderid=${orderId}`);
    }
  }

  render() {
    const path = getFormMode(this.props.location.pathname, (tab) => {
      this.props.setCurrentTab(tab);
    });

    return (
      <div className="with-callback">
        <OrdersSearchHeader
          headerText="Order Search"
          selectTab={this.selectTab}
          selectedTab={this.props.currentTab}
        />
        <OrdersSearchPageSelector
          mode={path}
          viewOrderHistory={this.viewOrderHistory}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderSearchFormPage)
);
