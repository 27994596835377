import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import GridActions from '../core/data-grid/GridActions';
import { GridActionEdit, GridActionView } from "../core/data-grid/GridAction";
import { useMemo } from "react";
import { GridActionMenu } from '../core/menu-items/menu-items';

const ParticipantGroupDataGrid = ({
  data,
  onView,
  onEdit
}) => {

  const gridCtxMenu = useMemo(() => GridActionMenu, []);

  const columns = [
    {
      Header: 'NAME',
      accessor: 'id',
      maxWidth: 240,
      minWidth: 240,
    },
    {
      Header: 'FIRM',
      id: 'firm',
      accessor: d => d.firmDisplayName,
    },
    {
      Header: 'STATE',
      id: 'state',
      accessor: d => d.isSuspended ? 'Suspended' : 'Active',
      maxWidth: 120,
      minWidth: 120
    },
    {
      Header: 'ACTIONS',
      maxWidth: 120,
      minWidth: 120,
      sortable: false,
      Cell: row => (
        <GridActions>
          <GridActionView contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onView(row.original.id, actionContext); }} />
          <GridActionEdit contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onEdit(row.original.id, actionContext); }} />
        </GridActions>
      )
    }
  ];
  return (
    <div>
      <DataGrid
        data={data}
        columns={columns}
        filterable={false}
      />
    </div>
  )
};

ParticipantGroupDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default ParticipantGroupDataGrid;