import { APP_ERROR, SET_CURRENT_TAB, SET_CURRENT_SELECTION, RESET, ADD_BROWSER_HISTORY } from "../constants/appTypes";

const initialState = {
  error: null,
  loading: false,
  theme: "dark",
  currentTab: "profile",
  currentSelection: "",
};

const appReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case APP_ERROR:
      state = Object.assign({}, state, {
        error: payload,
        loading: false,
      });
      return state;

    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };

    case SET_CURRENT_SELECTION:
      return {
        ...state,
        currentSelection: action.payload,
      };

    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    case ADD_BROWSER_HISTORY:
      let history = state.browserHistory ? state.browserHistory : [];

      // Keep only last 10 items.
      if (history.length > 10) {
        history = history.slice(history.length - 10, history.length);
      }

      return {
        ...state,
        browserHistory: [...history, action.payload]
      };

    default:
      return state;
  }
};

export default appReducer;
