import { Date as ApiDate, TimeOfDay as ApiTimeOfDay } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/type/v1beta1/type_pb"

/**
 * Returns a JavaScript 'Date' object corresponding to this Date.
 * @param {!api.Date} date The api.Date proto to convert
 * @param {!api.TimeOfDay} timeOfDay The api.TimeOfDay proto to convert
 * @return {!Date}
 */
export function protoDateToDate(date, timeOfDay) {
  if (timeOfDay == null) {
    return new Date(date.getYear(), date.getMonth()-1, date.getDay())
  } else {
    return new Date(date.getYear(), date.getMonth()-1, date.getDay(), timeOfDay.getHours(), timeOfDay.getMinutes(), timeOfDay.getSeconds())
  }
};

/**
 * Returns a JavaScript 'Date' object corresponding to this Timestamp.
 * @param {!proto.api.Timestamp} value The timestamp to convert
 * @return {!Date}
 */
export function tsProtoToDate(value) {
  if (!value) {
    return
  }
  var seconds = value.getSeconds();
  var nanos = value.getNanos();

  return new Date((seconds * 1000) + (nanos / 1000000));
};

/**
 * Returns a JavaScript 'Date' object corresponding to this Timestamp.
 * @param {!proto.api.Timestamp} value The timestamp to convert
 * @return {!Date}
 */
 export function tsProtoObjToDate(value) {
  var seconds = value.seconds;
  var nanos = value.nanos;

  return new Date((seconds * 1000) + (nanos / 1000000));
};

/**
 * Returns a protobuf 'Date' object corresponding to this Date.
 * @param {!Date} value The date to convert
 * @return {!api.Date}
 */
export function dateToProtoDate(d) {
  let pDate = new ApiDate();
  pDate.setYear(d.getFullYear());
  pDate.setMonth(d.getMonth()+1);
  pDate.setDay(d.getDate());
  return pDate
}

/**
 * Returns a protobuf 'TimeOfDay' object corresponding to this Date.
 * @param {!Date} value The date to convert
 * @return {!api.TimeOfDay}
 */
export function dateToProtoTimeOfDay(d) {
  let pTimeOfDay = new ApiTimeOfDay();
  pTimeOfDay.setHours(d.getHours());
  pTimeOfDay.setMinutes(d.getMinutes());
  pTimeOfDay.setSeconds(d.getSeconds());
  return pTimeOfDay
}
