import {
  FETCH_QUOTES_START,
  FETCH_QUOTES_SUCCEED,
  FETCH_QUOTES_FAILED,
  CLEAN_QUOTES_SEARCH,
  FETCH_QUOTE_HISTORY_FAILED,
  FETCH_QUOTE_HISTORY_SUCCEED,
  FETCH_QUOTE_HISTORY_START,
  RESET_TOKEN,
} from "../constants/quoteTypes";
import QuoteService from "../services/QuoteService";
import Quote from "../entities/Quote";
import Notification from "../modules/notifications";
import QuoteMessageType from "../entities/dto/QuoteMessageType";
import { parsePrice } from "../modules/util";
import { ensureAndApplyInstrumentScales } from "../modules/scaleUtils";

export function searchQuotes(filters, pageToken) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_QUOTES_START });

    if (
      filters.messageType === QuoteMessageType.All ||
      filters.messageType === QuoteMessageType.Quote
    ) {
      QuoteService.listQuotes({ filters, pageToken })
        .catch((error) => {
          dispatch({ type: FETCH_QUOTES_FAILED });
          Notification.error("Search Error. Please try again!");
          return null;
        })
        .then((response) => {
          if (!response) return;

          let quoteList = response.getQuotesList().map((q) => {
            return new Quote(q);
          });

          const { scales, qtyScales } = getState().instrumentsScales;

          ensureAndApplyInstrumentScales(
            quoteList,
            scales,
            qtyScales,
            (item) => item.symbol,
            dispatch,
            (item, scale, qtyScale) => {
              if (scale) item.price = parsePrice(item.price, scale);
              if (qtyScale) item.quantity = parsePrice(item.quantity, qtyScale);
            }
          ).then(
            (quoteList, newScales) => {
              dispatch({
                type: FETCH_QUOTES_SUCCEED,
                payload: quoteList,
                nextPageToken: response.getNextPageToken(),
                pageToken: pageToken,
                messageType: QuoteMessageType.Quote,
              });
            },
            (err) => {
              dispatch({ type: FETCH_QUOTES_FAILED });
              Notification.error(err);
            }
          );
        });
    }
  };
}

export function loadQuoteHistory(filters, pageToken) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_QUOTE_HISTORY_START });
    QuoteService.searchQuoteHistory({ filters, pageToken })
      .catch((error) => {
        dispatch({ type: FETCH_QUOTE_HISTORY_FAILED });
        Notification.error("Search Error. Please try again!");
        return null;
      })
      .then((response) => {
        if (!response) return;

        let quoteList = response.getQuotesList().map((quote) => {
           return new Quote(quote);
        });

        const { scales, qtyScales } = getState().instrumentsScales;

        ensureAndApplyInstrumentScales(
          quoteList,
          scales,
          qtyScales,
          (item) => item.symbol,
          dispatch,
          (item, scale, qtyScale) => {
            if (scale) item.price = parsePrice(item.price, scale);
            if (qtyScale) item.quantity = parsePrice(item.quantity, qtyScale);
          }
        ).then(
          (quoteList, newScales) => {
            dispatch({
              type: FETCH_QUOTE_HISTORY_SUCCEED,
              payload: quoteList,
              nextPageToken: response.getNextPageToken(),
              pageToken: pageToken,
            });
          },
          (err) => {
            dispatch({ type: FETCH_QUOTES_FAILED });
            Notification.error(err);
          }
        );
      });
  };
}

export function cleanSearch() {
  return (dispatch) => {
    dispatch({ type: CLEAN_QUOTES_SEARCH });
    dispatch({ type: RESET_TOKEN });
  };
}
