import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  ACCESS_TKN_REFRESH_SUCCESS,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_END,
  MFA_OTP_AUTH_URL,
  LOGIN_RESET,
  LOGIN_ERROR_RESET,
} from '../constants/authTypes';
import {
  RESET,
} from '../constants/appTypes';

const initialState = {
  id: null,
  accessToken: null,
  refreshToken: null,
  hasWriteAccess: false,
  expires: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      state = Object.assign({}, state, {
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        expires: payload.expires,
        hasWriteAccess: payload.hasWriteAccess,
        error: null,
      });
      return state;

    case LOGIN_FAILURE:
      state = Object.assign({}, state, {
        error: payload.error,
      });
      return state;

    case LOGIN_RESET:
      state = Object.assign({}, state, {
        username: null,
        password: null,
        mfaOTPAuthUrl: null,
        error: null,
      });
      return state;

    case LOGIN_ERROR_RESET:
      state = Object.assign({}, state, {
        error: null,
      });
      return state;

    case ACCESS_TKN_REFRESH_SUCCESS:
      state = Object.assign({}, state, {
        accessToken: payload.accessToken,
      });
      return state;

    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    case CHANGE_PASSWORD_START:
      state = Object.assign({}, state, {
        updatingPassword: true,
      });
      return state;
    case CHANGE_PASSWORD_END:
      state = Object.assign({}, state, {
        updatingPassword: false,
      });
      return state;

    case MFA_OTP_AUTH_URL:
      state = Object.assign({}, state, {
        username: payload.username,
        password: payload.password,
        mfaOTPAuthUrl: payload.mfaOTPAuthUrl,
      });
      return state;

    default:
      return state;
  }
};

export default authReducer;
