import React from "react";
import PropTypes from "prop-types";
import { FormModes } from "../../constants/enumerations";
import OrdersSearchPage from "../../pages/OrdersSearchPage";
import ExecutionsSearchPage from "../../pages/ExecutionsSearchPage";

const OrdersSearchPageSelector = ({
  mode,
  viewOrderHistory,
}) => {
  var form = null;
  switch (mode) {
    case FormModes.orders:
      form = <OrdersSearchPage
        viewOrderHistory={viewOrderHistory}
      />;
      break;
    case FormModes.executions:
      form = <ExecutionsSearchPage/>;
      break;
    default:
      break;
  }
  return form;
};

OrdersSearchPageSelector.propTypes = {
  mode: PropTypes.oneOf(Object.keys(FormModes)).isRequired,
  firm: PropTypes.object,
};

export default OrdersSearchPageSelector;
