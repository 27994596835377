import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import GridActions from '../core/data-grid/GridActions';
import { GridActionBust, GridActionRetry, GridActionView } from "../core/data-grid/GridAction";
import { useMemo } from "react";
import { GridActionMenu } from '../core/menu-items/menu-items';
import { isArrayWithValues } from '../../modules/util';
import usePreserveGridColsLayout from '../hooks/usePreserveGridColsLayout';

const TimeSalesDataGrid = ({
  data,
  onBust,
  onPageChange,
  onPageSizeChange,
  paginationComponent,
  onView,
  onRetry,
}) => {
  const gridCtxMenu = useMemo(() => GridActionMenu, []);

  const { columnsLayout, saveColumnsLayout } = usePreserveGridColsLayout("TimeAndSalesDGColumnsLayout");

  const onColumnResize = (newResized, event) => {
    debugger
    if (isArrayWithValues(newResized)) {
      newResized.map((column) => {
        columnsLayout[column.id] = column.value;
      });
    }

    saveColumnsLayout(columnsLayout);
  }

  const getColumnWidth = (id) => {
    return columnsLayout[id] ? columnsLayout[id] : undefined
  }

  const columns = [
    {
      Header: 'TRANSACTION TIME',
      accessor: 'transactTime',
      minWidth: 180,
      sortable: false,
      width: getColumnWidth("transactTime"),
    },
        {
      Header: 'TRADE DATE',
      accessor: 'aggressorTransactTradeDate',
      minWidth: 100,
      sortable: false,
      width: getColumnWidth("aggressorTransactTradeDate"),
    },
    {
      Header: 'TRADE ID',
      accessor: 'tradeId',
      minWidth: 250,
      sortable: false,
      width: getColumnWidth("tradeId"),
    },
    {
      Header: 'INSTRUMENT ID',
      accessor: 'instrumentId',
      minWidth: 120,
      sortable: false,
      width: getColumnWidth("instrumentId"),
    },
    {
      Header: 'AGGRESSOR',
      accessor: 'aggressor',
      minWidth: 100,
      sortable: false,
      width: getColumnWidth("aggressor"),
    },
    {
      Header: 'AGGR. ACCOUNT',
      accessor: 'aggressorAccount',
      minWidth: 150,
      sortable: false,
      width: getColumnWidth("aggressorAccount"),
    },
    {
      Header: 'AGGR. CLIENT USER',
      accessor: 'aggressorClientParticipantId',
      minWidth: 150,
      sortable: false,
      width: getColumnWidth("aggressorClientParticipantId"),
    },
    {
      Header: 'AGGR. CLIENT ACCT',
      accessor: 'aggressorClientAccountId',
      minWidth: 150,
      sortable: false,
      width: getColumnWidth("aggressorClientAccountId"),
    },
    {
      Header: 'AGGR. PARENT ORDER ID',
      accessor: 'aggressorParentOrderId',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("aggressorParentOrderId"),
    },
    {
      Header: 'AGGR. SUB TYPE',
      accessor: 'aggressorSymbolSubType',
      minWidth: 80,
      width: getColumnWidth("aggressorSymbolSubType"),
    },
    {
      Header: 'AGGR. SIDE',
      accessor: 'aggressorSide',
      minWidth: 150,
      sortable: false,
      width: getColumnWidth("aggressorSide"),
    },
    {
      Header: 'PASSIVE',
      accessor: 'passive',
      minWidth: 120,
      sortable: false,
      width: getColumnWidth("passive"),
    },
    {
      Header: 'PASS. ACCOUNT',
      accessor: 'passiveAccount',
      minWidth: 150,
      sortable: false,
      width: getColumnWidth("passiveAccount"),
    },
    {
      Header: 'PASS. CLIENT USER',
      accessor: 'passiveClientParticipantId',
      minWidth: 150,
      sortable: false,
      width: getColumnWidth("passiveClientParticipantId"),
    },
    {
      Header: 'PASS. CLIENT ACCT',
      accessor: 'passiveClientAccountId',
      minWidth: 150,
      sortable: false,
      width: getColumnWidth("passiveClientAccountId"),
    },
    {
      Header: 'PASS. PARENT ORDER ID',
      accessor: 'passiveParentOrderId',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("passiveParentOrderId"),
    },
    {
      Header: 'PASS. SUB TYPE',
      accessor: 'passiveSymbolSubType',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("passiveSymbolSubType"),
    },
    {
      Header: 'PASS. SIDE',
      accessor: 'passiveSide',
      minWidth: 150,
      sortable: false,
      width: getColumnWidth("passiveSide"),
    },
    {
      Header: 'TRADE QTY',
      accessor: 'tradeQty',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("tradeQty"),
    },
    {
      Header: 'AGGR. PRICE',
      accessor: 'aggressorLastPrice',
      minWidth: 90,
      sortable: false,
      width: getColumnWidth("aggressorLastPrice"),
    },
    {
      Header: 'PASS. PRICE',
      accessor: 'passiveLastPrice',
      minWidth: 90,
      sortable: false,
      width: getColumnWidth("passiveLastPrice"),
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("status"),
    },
    {
      Header: 'BLOCK TRADE INDICATOR',
      accessor: 'blockTradeIndicator',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("blockTradeIndicator"),
    },
    {
      Header: 'TEXT',
      accessor: 'text',
      minWidth: 80,
      sortable: false,
      width: getColumnWidth("text"),
    },
    {
      Header: '',
      maxWidth: 120,
      minWidth: 120,
      sortable: false,
      Cell: row => (
        <GridActions>
          <GridActionView contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onView(row.original.tradeId, actionContext); }} />
          <GridActionBust contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onBust(row.original.tradeId, actionContext); }} />
          <GridActionRetry onClick={(e, actionContext) => { onRetry(row.original.tradeId); }} />
        </GridActions>
      )
    }
  ];
  return (
    <div>
      <DataGrid
        data={data}
        columns={columns}
        filterable={false}
        manual={true}
        autoFillHeight={true}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onResizedChange={onColumnResize}
        PaginationComponent={paginationComponent}
      />
    </div>
  )
};

TimeSalesDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onBust: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default TimeSalesDataGrid;
