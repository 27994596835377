import { toast } from 'react-toastify';
import {inTest, numberOrDefault} from './util';

const toastDuration = 5000;

export default class Notification {
  static success(message, delay) {
    if (!inTest() && numberOrDefault(delay, 0) > 0) {
      setTimeout(() => { NotificationService.success(message); }, delay);
    } else {
      NotificationService.success(message);
    }
  }

  static error(message, delay) {
    if (!inTest() && numberOrDefault(delay, 0) > 0) {
      setTimeout(() => { NotificationService.error(message); }, delay);
    } else {
      NotificationService.error(message);
    }
  }

  static warning(message, delay) {
    if (!inTest() && numberOrDefault(delay, 0) > 0) {
      setTimeout(() => { NotificationService.warning(message); }, delay);
    } else {
      NotificationService.warning(message);
    }
  }

  static info(message, delay) {
    if (!inTest() && numberOrDefault(delay, 0) > 0) {
      setTimeout(() => { NotificationService.info(message); }, delay);
    } else {
      NotificationService.info(message);
    }
  }
}

class NotificationService {
  static success(message) {
    toast.success(`${message}`, {
      autoClose: toastDuration,
      className: 'custom-toast custom-toast-success'
    });
  }

  static error(message) {
    if (message.indexOf("The request is being stored for approval") >= 0) {
      Notification.info(message);
      return
    }

    const toastId = 'error-toast';
    if (!toast.isActive(toastId)) {
      toast.error(`${message}`, {
        toastId: toastId,
        autoClose: 2000000,
        className: 'custom-toast custom-toast-error'
      });
    }
  }

  static warning(message) {
    toast.warn(message, {
      autoClose: toastDuration,
      className: 'custom-toast custom-toast-warning'
    });
  }

  static info(message) {
    toast.info(message, {
      autoClose: toastDuration,
      className: 'custom-toast custom-toast-info'
    });
  }
}