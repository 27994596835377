import React from "react";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import GridActions from '../core/data-grid/GridActions';
import { GridActionDelete, GridActionEdit, GridActionRestore, GridActionView } from "../core/data-grid/GridAction";
import { useMemo } from "react";
import { GridActionMenu } from "../core/menu-items/menu-items";

const FirmDataGrid = ({ data, onView, onEdit, onRemove, onUnDelete }) => {
  const gridCtxMenu = useMemo(() => GridActionMenu, []);

  const columns = [
    {
      Header: "NAME",
      accessor: "displayName"
    },
    {
      Header: "TYPE",
      id: "firmType",
      accessor: d => (d.firmType ? d.firmType.name : null),
      maxWidth: 140,
      minWidth: 140
    },
    {
      Header: "STATE",
      id: "state",
      accessor: d => (d.isSuspended ? "Suspended" : "Active"),
      maxWidth: 120,
      minWidth: 120
    },
    {
      Header: () => {
        return <div>ACTIONS</div>;
      },
      maxWidth: 160,
      minWidth: 120,
      sortable: false,
      Cell: row => (
        <GridActions>
          <GridActionView contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onView(row.original.id, actionContext); }} />

          {!row.original.deleted && <GridActionEdit contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onEdit(row.original.id, actionContext) }} />}

          {!row.original.deleted && <GridActionDelete onClick={(e, actionContext) => { onRemove(row.original.id, false) }} />}
          {!!row.original.deleted && <GridActionRestore onClick={(e, actionContext) => { onUnDelete(row.original.id, actionContext) }} />}
        </GridActions>
      )
    }
  ];
  return (
    <div>
      <DataGrid
        data={data}
        columns={columns}
        filterable={false}
        selectable={false}
      />
    </div>
  );
};

FirmDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  showGroups: PropTypes.bool
};

export default FirmDataGrid;
