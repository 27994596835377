import React from "react";
import PropTypes from "prop-types";
import ButtonMain from "../core/form/ButtonMain";
import { Row, Col } from "react-bootstrap";
const ParticipantHeader = ({
  headerText,
  buttonText,
  buttonClick,
  isAddButtonVisible
}) => {
  return (
    <div>
      {headerText}
      <Row className="page-header">
        <Col lg={12} xs={12} md={12}>
          {isAddButtonVisible ? (
            <ButtonMain
              text={buttonText}
              type="button"
              onClick={buttonClick}
              style={{ float: "right" }}
              customClassName="btn-main-header"
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

ParticipantHeader.defaultProps = {
  isParticipantsSelected: false,
  isParticipantGroupsSelected: false,
  isFirmSelected: false,
  isAddButtonVisible: false,
  isAccountsSelected: false
};

ParticipantHeader.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonClick: PropTypes.func.isRequired,
  isAddButtonVisible: PropTypes.bool,
  isParticipantsSelected: PropTypes.bool,
  isParticipantGroupsSelected: PropTypes.bool,
  isFirmSelected: PropTypes.bool,
  isAccountsSelected: PropTypes.bool
};

export default ParticipantHeader;
