import {
  SEARCH_EXECUTIONS_START,
  REFRESH_SEARCH_EXECUTIONS,
  SEARCH_EXECUTIONS_SUCCEED,
  SEARCH_EXECUTIONS_FAILED,
  SEARCH_EXECUTIONS_NO_ITEM,
  ADD_EXECUTIONS,
  RESET_SEARCH_EXECUTIONS_FETCHING_STATUS,
  RESET_EXECUTIONS_TOKENS
} from '../constants/executionsTypes';
import {
  RESET,
} from '../constants/appTypes';

const initialState = {
  fetchingHistory: false,
  executions: [],
  noItemsFound: false,
  pageToken: null,
  tokens: {}
}

const executions = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_EXECUTIONS_START:
      return {
        ...state,
        fetchingHistory: true,
        executions: [],
        noItemsFound: false
      };
    case SEARCH_EXECUTIONS_SUCCEED:
      return {
        ...state,
        fetchingHistory: false
      };
    case SEARCH_EXECUTIONS_FAILED:
      return {
        ...state,
        fetchingHistory: false,
        executions: [],
        noItemsFound: false
      };
    case REFRESH_SEARCH_EXECUTIONS:
      return {
        ...state,
        executions: action.payload,
        pageToken: action.pageToken,
        tokens: { ...state.tokens, [action.pageToken]: action.nextPageToken }
      };
    case RESET_SEARCH_EXECUTIONS_FETCHING_STATUS:
      return {
        ...state,
        fetchingHistory: false
      };
    case ADD_EXECUTIONS:
      return {
        ...state,
        executions: [...state.executions, ...action.payload]
      };
    case SEARCH_EXECUTIONS_NO_ITEM:
      return {
        ...state,
        noItemsFound: true
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    case RESET_EXECUTIONS_TOKENS:
      return {
        ...state,
        tokens: {},
        pageToken: null
      }

    default:
      return state;
  }
}

export default executions;
