import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import DropdownListGroup from "../core/form/DropdownListGroup";
import FieldGroup from "../core/form/FieldGroup";
import AccountStaticData from "../../modules/accountStaticData";
import Filter from "../core/filter/Filter";

import MultiSelectDropdownListGroup from "../core/form/MultiSelectDropdownListGroup";
import { convertEnumToDropdownList, isArrayWithValues } from "../../modules/util";
import { AccountProperty } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb";
import FormatString, {StringFormatOptions} from "../../modules/formatString";
import {Env} from "../../constants/environment";

function mapStateToProps(state) {
    return {
        firms: state.firms,
    };
}

const AccountsFilter = (props) => {
    const defaultFilters = {
        accountName: null,
        collateralAccount: null,
        accountState: null,
        riskSystem: null,
        firm: null,
        accountProperties:null,
    }

    const [firms, setFirms] = useState([]);
    const [accountStates, setAccountStates] = useState([]);
    const [currentFilterItems, setCurrentFilterItems] = useState(defaultFilters);

    useEffect(() => {
        if (!!props.firms && !!props.firms.firms) {
            setFirms(props.firms.firms.map(firm => { return { id: firm.name, displayName: firm.displayName } }));
        }

        setAccountStates([{ id: "", name: "" }, ...AccountStaticData.AccountStates()])
    }, [props.firms.firms]);

    const getRiskSystems = () => {
        let riskSystems = [{ id: "", name: "" }];
        let riskSysStr = Env.getEnv("REACT_APP_RISK_SYSTEMS");

        if (riskSysStr && riskSysStr.length > 0) {
            const splitList = riskSysStr.split(',');
            splitList.forEach(riskSys => {
                riskSystems.push({ id: riskSys.trim(), name: riskSys.trim() });
            });
        }

        return riskSystems;
    }

    const onApply = () => {
        if(props.onApplyFilter){
            props.onApplyFilter(currentFilterItems.accountName, currentFilterItems.accountState, currentFilterItems.collateralAccount, currentFilterItems.riskSystem, currentFilterItems.firm, currentFilterItems.accountProperties);
        }
    }

    const onReset = () => {
        setCurrentFilterItems(defaultFilters);
        props.onApplyFilter("", "", "", "", "", "");
    }

    const handleOnChange = (key, value) => {
        let updatedValue = {...currentFilterItems};
        updatedValue[key] = value;
        setCurrentFilterItems(currentFilterItems => ({
            ...currentFilterItems,
            ...updatedValue
          }));
    }

    const onRemoveFilterItem = (filterItemId) => {
        let filterItems = {...currentFilterItems};

        if (Array.isArray( filterItems[filterItemId.id] )){
            let idx =  filterItems[filterItemId.id].findIndex(elm => elm.value === filterItemId.value);
            filterItems[filterItemId.id].splice(idx, 1);
        }else{
            delete filterItems[filterItemId.id];
        }

        setCurrentFilterItems(filterItems);
        props.onApplyFilter(filterItems.accountName, filterItems.accountState, filterItems.collateralAccount, filterItems.riskSystem, filterItems.firm, filterItems.accountProperties);
    };

    return (
        <Filter
            className="instruments-filter"
            onApply={onApply}
            onReset={onReset}
            onRemoveFilterItem={onRemoveFilterItem}
            filterItems={props.filter}
            onFlip={() => { }}
        >
            <div className="with-callback">
                <Row>
                    <Col lg={4} xs={4} md={4}>
                        <FieldGroup
                            id="accountName"
                            name="accountName"
                            type="text"
                            label="Account Name"
                            value={currentFilterItems.accountName && currentFilterItems.accountName.value }
                            setFieldValue={() => { }}
                            placeholder="Partial or complete Account Name."
                            onChange={(e) => { handleOnChange('accountName', {
                                id: "accountName",
                                value: e.target.value,
                                text: "Account Name",
                                name: e.target.value 
                               }); 
                            }}
                        />
                    </Col>
                    <Col lg={4} xs={4} md={4}>
                        <FieldGroup
                            id="collateralAccount"
                            name="collateralAccount"
                            type="text"
                            label="Collateral Account Name"
                            value={currentFilterItems.collateralAccount && currentFilterItems.collateralAccount.value}
                            setFieldValue={() => { }}
                            placeholder="Partial or complete Collateral Account Name."
                            onChange={(e) => { handleOnChange('collateralAccount', {
                                id: "collateralAccount",
                                value: e.target.value,
                                text: "Collateral Account Name",
                                name: e.target.value 
                               })
                           }}
                        />
                    </Col>
                    <Col lg={4} xs={4} md={4}>
                        <DropdownListGroup
                            id="accountState"
                            name="accountState"
                            label="Account State"
                            value={currentFilterItems.accountState && currentFilterItems.accountState.state}
                            onChange={(e) => { handleOnChange('accountState', {
                                id: "accountState",
                                value: e.name,
                                text: "Account State",
                                name: e.name,
                                state: e.id
                               });
                            }}
                            data={accountStates}
                        ></DropdownListGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} xs={4} md={4}>
                        <DropdownListGroup id="riskSystem" name="riskSystem" label="Risk System"
                            value={currentFilterItems.riskSystem && currentFilterItems.riskSystem.riskSystem}
                            onChange={(e) => handleOnChange('riskSystem', {
                                id: "riskSystem",
                                value: e.name,
                                text: "Risk System",
                                name: e.name,
                                riskSystem: e.id
                               })
                            }
                            data={getRiskSystems()}
                        ></DropdownListGroup>
                    </Col>
                    <Col lg={4} xs={4} md={4}>
                        <DropdownListGroup
                            id="firmId"
                            name="firmId"
                            label="Firm"
                            key="id"
                            textField="displayName"
                            value={currentFilterItems.firm && currentFilterItems.firm.firm}
                            onChange={(e) => { handleOnChange('firm', {
                                id: "firm",
                                value: e.displayName,
                                text: "Firm",
                                name: e.displayName,
                                firm: e.id
                                });
                            }}
                            data={firms}
                            showFilter={true}
                        ></DropdownListGroup>
                    </Col>
                    <Col lg={4} xs={4} md={4}>
                        <MultiSelectDropdownListGroup
                            label="Properties"
                            labelWrapperClass="label-wrapper"
                            value={isArrayWithValues(currentFilterItems.accountProperties) ? currentFilterItems.accountProperties.map(prop => prop.value) : null}
                            onChange={(e) => {
                                handleOnChange('accountProperties', e.map(prop => {
                                    return {
                                    id: "accountProperties",
                                    value: prop.id,
                                    text: "Properties",
                                    name: prop.name 
                                   };
                                }));
                            }}
                            data={convertEnumToDropdownList(AccountProperty, [0], new StringFormatOptions(FormatString.stringFormats.titleCase, false))}
                            textField={"name"}
                            valueField={"id"}
                        />
                    </Col>
                </Row>
            </div>
        </Filter>
    );
}

export default connect(mapStateToProps, null)(AccountsFilter);
