import ProtobufParser from "../protobufParser";
import InstrumentFormItem from "../../entities/dto/InstrumentFormItem";
import TradingHourMapper from "./tradingHourMapper";
import { convertScaledPriceToInt, countDecimals, isNullOrUndefined, isNumber, valueOrDefault } from "../util";
import { dateToProtoDate, dateToProtoTimeOfDay } from "../../actions/ptypes";
import {
  INTEREST_RATE_SWAP,
  FORWARD_RATE_AGREEMENT_SWAP,
  FUTURE,
  OPTION,
  MULTILEG,
  FORWARD_STARTING_INTEREST_RATE_SWAP,
  BASIS_SWAP,
  EVENT,
  OVER_NIGHT_INDEX_SWAP,
  SINGLE_PERIOD_SWAP, NON_DELIVERABLE_FORWARDS,
  FOREX,
  ZERO_COUPON_INFLATION_SWAP
} from "../../constants/strings";
import {
  setIRSAtts,
  createIRSAtts,
  createFRAAtts,
  setFRAAtts,
  createFutureAtts,
  setFutureAtts,
  createOptionAtts,
  setOptionAtts,
  createForexAtts,
  setForexAtts,
  setDefaultAtts,
  createMultilegAtts,
  setMultilegAtts,
  setBasisAtts,
  createBasisAtts,
  createFSIRSAtts,
  setFsIRSAtts,
  setUIEventAtts,
  createEventAtts,
  setOISAtts,
  createOISAtts,
  setSpsAtts,
  createSpsAtts,
  setNDFAtts,
  createNDFAtts,
  setZeroCouponInflationSwapAtts,
  createZeroCouponInflationSwapAtts,
} from "../instrumentUtils";
import TradeDayRollMapper from "./tradeDayRollMapper.js";

const {
  InstrumentStats,
  HolidayCalendar,
  Holiday,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb.js");

const {
  UpdateActiveInstrumentRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_api_pb.js");

const {
  PriceLimit,
  OrderSizeLimit,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb");

const {
  InstrumentAttributes,
  Instrument,
  BlockTradeThreshold,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb.js");
export default class InstrumentMapper {
  static mapToInstrument(data) {
    var instrumentToSave = new Instrument();
    var priceScale = 1;
    if (data.minimumPriceIncrement !== null) {
      const scale = Math.pow(10, countDecimals(data.minimumPriceIncrement));
      if (scale !== 0) priceScale = scale;
    }

    instrumentToSave.setPriceScale(priceScale);
    if (!!data.isScheduled) {
      instrumentToSave.setIsScheduled(data.isScheduled);
    }

    if (data.type === INTEREST_RATE_SWAP) {
      let irsAtts = createIRSAtts(data);
      instrumentToSave.setInterestRateSwapAttributes(irsAtts);
    } else if (data.type === FORWARD_RATE_AGREEMENT_SWAP) {
      let atts = createFRAAtts(data);
      instrumentToSave.setForwardRateAgreementSwapAttributes(atts);
    } else if (data.type === FORWARD_STARTING_INTEREST_RATE_SWAP) {
      let atts = createFSIRSAtts(data);
      instrumentToSave.setForwardStartingInterestRateSwapAttributes(atts);
    } else if (data.type === FUTURE) {
      let atts = createFutureAtts(data);
      instrumentToSave.setFutureAttributes(atts);
    } else if (data.type === OPTION) {
      let atts = createOptionAtts(data);
      instrumentToSave.setOptionAttributes(atts);
    } else if (data.type === MULTILEG) {
      let atts = createMultilegAtts(data);
      instrumentToSave.setMultilegAttributes(atts);
    } else if (data.type === FOREX) {
      let atts = createForexAtts(data);
      instrumentToSave.setForexAttributes(atts);
    } else if (data.type === BASIS_SWAP) {
      let atts = createBasisAtts(data);
      instrumentToSave.setBasisSwapAttributes(atts);
    } else if (data.type === EVENT) {
      let atts = createEventAtts(data, priceScale);
      instrumentToSave.setEventAttributes(atts);
    } else if (data.type === OVER_NIGHT_INDEX_SWAP) {
      let attrs = createOISAtts(data);
      instrumentToSave.setOvernightIndexSwapAttributes(attrs);
    } else if (data.type === SINGLE_PERIOD_SWAP) {
      let attrs = createSpsAtts(data);
      instrumentToSave.setSinglePeriodSwapAttributes(attrs);
    } else if (data.type === NON_DELIVERABLE_FORWARDS) {
      let attrs = createNDFAtts(data);
      instrumentToSave.setNonDeliverableForwardAttributes(attrs);
    } else if (data.type === ZERO_COUPON_INFLATION_SWAP) {
      let atts = createZeroCouponInflationSwapAtts(data);
      instrumentToSave.setZeroCouponInflationSwapAttributes(atts);
    }

    /* Tradable Instrument Attributes - Starts */
    var tradable = new InstrumentAttributes();
    if (!isNullOrUndefined(data.cfiCode)) {
      tradable.setCfiCode(data.cfiCode);
    }

    if (!isNullOrUndefined(data.instrumentProductType)) {
      tradable.setInstrumentProductType(data.instrumentProductType.id ? data.instrumentProductType.id : data.instrumentProductType);
    }

    if (data.minimumPriceIncrement !== null) {
      tradable.setTickSize(ProtobufParser.toDouble(data.minimumPriceIncrement));
    }

    var qtyScale = 1;
    if (data.minimumTradeQuantity !== null) {
      const scale = Math.pow(10, countDecimals(data.minimumTradeQuantity));
      if (scale !== 0) qtyScale = scale;
      let minimumTradeQuantityInt = parseInt(
        convertScaledPriceToInt(data.minimumTradeQuantity, scale)
      );
      tradable.setMinimumTradeQty(minimumTradeQuantityInt);
      tradable.setFractionalQtyScale(scale);
    }

    if (data.minimumUnaffiliatedFirms !== null) {
      tradable.setMinimumUnaffiliatedFirms(data.minimumUnaffiliatedFirms);
    }

    if (data.crossOrderRestingDuration !== null) {
      const restingDuration = ProtobufParser.millisToDuration(data.crossOrderRestingDuration);
      tradable.setCrossOrderRestingDuration(restingDuration);
    }

    if (Object.keys(valueOrDefault(data.blockTradeThreshold, {})).length > 0) {

      let blockTradeThreshold = null;

      if (!!data.setBlockTradeThreshold) {

        blockTradeThreshold = new BlockTradeThreshold();

        if (!!data.blockTradeThreshold.minimumQuantity) {
          let blockTradeMinimumQuantityScaled = convertScaledPriceToInt(data.blockTradeThreshold.minimumQuantity, qtyScale)
          blockTradeThreshold.setMinimumQuantity(blockTradeMinimumQuantityScaled);
        }

        if (!!data.blockTradeThreshold.minimumNotional) {
          let notionalScale = qtyScale * priceScale;
          let blockTradeMinimumNotionalScaled = convertScaledPriceToInt(data.blockTradeThreshold.minimumNotional, notionalScale)
          blockTradeThreshold.setMinimumNotional(blockTradeMinimumNotionalScaled);
        }
        
      }

      tradable.setBlockTradeThreshold(blockTradeThreshold);
    }

    if (data.basecurrency !== null) {
      tradable.setBaseCurrency(data.basecurrency);
    }

    if (data.multiplier !== null) {
      tradable.setMultiplier(data.multiplier);
    }

    if (data.tradeSettlementPeriod !== null) {
      tradable.setTradeSettlementPeriod(data.tradeSettlementPeriod);
    }

    if (
      Array.isArray(data.holidayCalendars) &&
      data.holidayCalendars.length > 0
    ) {
      tradable.setHolidayCalendarsList(data.holidayCalendars);
    }

    tradable.setClearingHouse(data.clearingHouse);

    if (data.startDate !== null) {
      const startDate = dateToProtoDate(data.startDate);
      tradable.setStartDate(startDate);
    }

    if (!!data.lastTradeDate) {
      const lastTradeDate = dateToProtoDate(data.lastTradeDate);
      tradable.setLastTradeDate(lastTradeDate);

      const lastTradeTime = dateToProtoTimeOfDay(data.lastTradeDate);
      tradable.setLastTradeTime(lastTradeTime);
    }

    if (data.expirationDate !== null) {
      const expirationDate = dateToProtoDate(data.expirationDate);
      tradable.setExpirationDate(expirationDate);

      const expirationTime = dateToProtoTimeOfDay(data.expirationDate);
      tradable.setExpirationTime(expirationTime);
    }

    if (data.terminationDate !== null) {
      const terminationDate = dateToProtoDate(data.terminationDate);
      tradable.setTerminationDate(terminationDate);
    }

    if (
      data.lowPriceLimit !== null ||
      data.highPriceLimit !== null ||
      data.relativeLow !== null ||
      data.relativeHigh !== null
    ) {
      var priceLimit = new PriceLimit();

      if (isNumber(data.lowPriceLimit)) {
        priceLimit.setLow(convertScaledPriceToInt(data.lowPriceLimit, priceScale));
      }

      if (isNumber(data.highPriceLimit)) {
        priceLimit.setHigh(convertScaledPriceToInt(data.highPriceLimit, priceScale));
      }

      if (isNumber(data.relativeLow)) {
        priceLimit.setRelativeLow(data.relativeLow / 100);
      }

      if (isNumber(data.relativeHigh)) {
        priceLimit.setRelativeHigh(data.relativeHigh / 100);
      }

      priceLimit.setRelativeLowSet(data.relativeLowSet);
      priceLimit.setRelativeHighSet(data.relativeHighSet);
      priceLimit.setLowSet(data.setLowPriceLimit);
      priceLimit.setHighSet(data.setHighPriceLimit);

      tradable.setPriceLimit(priceLimit);
    }

    if (data.lowOrderLimit !== null || data.highOrderLimit !== null || data.lowTotalNotionalLimit !== null || data.highTotalNotionalLimit !== null) {
      var orderLimit = new OrderSizeLimit();

      if (isNumber(data.lowOrderLimit)) {
        orderLimit.setLow(convertScaledPriceToInt(data.lowOrderLimit, qtyScale));
      }

      if (isNumber(data.highOrderLimit)) {
        orderLimit.setHigh(convertScaledPriceToInt(data.highOrderLimit, qtyScale));
      }

      if (isNumber(data.lowTotalNotionalLimit)) {
        orderLimit.setTotalNotionalLow(convertScaledPriceToInt(data.lowTotalNotionalLimit, priceScale * qtyScale));
      }

      if (isNumber(data.highTotalNotionalLimit)) {
        orderLimit.setTotalNotionalHigh(convertScaledPriceToInt(data.highTotalNotionalLimit, priceScale * qtyScale));
      }

      orderLimit.setLowSet(!!data.setLowOrderLimit);
      orderLimit.setHighSet(!!data.setHighOrderLimit);
      orderLimit.setTotalNotionalLowSet(!!data.setLowTotalNotionalLimit);
      orderLimit.setTotalNotionalHighSet(!!data.setHighTotalNotionalLimit);

      tradable.setOrderSizeLimit(orderLimit);
    }

    tradable.setSkipRiskAndCurrencyChecks(data.skipRiskAndCurrencyChecks);

    var tradingHours = [];

    if (data.tradingScheduleList) {
      for (var i = 0; data.tradingScheduleList.length > i; i++) {
        tradingHours.push(
          TradingHourMapper.mapToTradingHour(data.tradingScheduleList[i])
        );
      }
    }

    tradable.setTradingScheduleList(tradingHours);

    if (data.tradeDayRollSchedule) {
      let mappedData = TradeDayRollMapper.mapTradeDayRollSchedule(data.tradeDayRollSchedule);
      tradable.setTradeDayRollScheduleList(mappedData);
    }

    if(data.settlementPeriodStartOffsetFromCloseDuration) {
      tradable.setSettlementPeriodStartOffsetFromCloseDuration(ProtobufParser.millisToDuration(data.settlementPeriodStartOffsetFromCloseDuration))
    }

    if(data.settlementPeriodEndOffsetFromCloseDuration) {
      tradable.setSettlementPeriodEndOffsetFromCloseDuration(ProtobufParser.millisToDuration(data.settlementPeriodEndOffsetFromCloseDuration))
    }

    if(data.minimumTradeQtyForVwapSettlementCalculation) {
      tradable.setMinimumTradeQtyForVwapSettlementCalculation(data.minimumTradeQtyForVwapSettlementCalculation)
    }

    if(data.bufferFromEndOfSettlementPeriodDuration) {
      tradable.setBufferFromEndOfSettlementPeriodDuration(ProtobufParser.millisToDuration(data.bufferFromEndOfSettlementPeriodDuration))
    }

    tradable.setSettlementPriceLogic(data.settlementPriceLogic);
    tradable.setSettlementPriceCalculationRequiresApproval(data.settlementPriceCalculationRequiresApproval);
    tradable.setSettlementPriceCalculationEndingMethod(data.settlementPriceCalculationEndingMethod);

    data.metadata && data.metadata.forEach((data) => {
      tradable.getMetadataMap().set(data.key, data.value);
    });

    instrumentToSave.setAttributes(tradable);
    /* Tradable Instrument Attributes - Ends */

    instrumentToSave.setId(data.id);
    instrumentToSave.setDescription(data.description);
    instrumentToSave.setRoutingKey(data.routingkey);
    instrumentToSave.setExternalSymbol(data.externalSymbol);
    instrumentToSave.setNonTradable(data.nonTradable);
    instrumentToSave.setHideMarketData(data.hideMarketData);

    if (data.productId !== null) {
      instrumentToSave.setProductId(data.productId);
    }

    if (!isNullOrUndefined(data?.state?.id)) {
      instrumentToSave.setState(data?.state?.id);
    }

    return instrumentToSave;
  }

  static mapFromProduct(product) {
    var instrument = new InstrumentFormItem();

    instrument.productId = product.name;
    instrument.routingkey = product.name;
    instrument.cfiCode = product.cfiCode;
    instrument.externalSymbol = product.externalSymbol;
    instrument.instrumentProductType = product.instrumentProductType;
    instrument.minimumPriceIncrement = product.minimumPriceIncrement;
    instrument.minimumTradeQuantity = product.minimumTradeQuantity;
    instrument.crossOrderRestingDuration = product.crossOrderRestingDuration;
    instrument.blockTradeThreshold = product.blockTradeThreshold;
    instrument.setBlockTradeThreshold = !!product.blockTradeThreshold;
    instrument.multiplier = product.multiplier;
    instrument.basecurrency = product.basecurrency;
    instrument.clearingHouse = product.clearingHouse;
    instrument.startDate = product.startDate;
    instrument.expirationDate = product.expirationDate;
    instrument.terminationDate = product.terminationDate;
    instrument.relativeLowSet = product.relativeLowSet;
    instrument.relativeLow = product.relativeLow * 100;
    instrument.relativeHighSet = product.relativeHighSet;
    instrument.relativeHigh = product.relativeHigh * 100;
    instrument.lowPriceLimit = product.lowPriceLimit;
    instrument.highPriceLimit = product.highPriceLimit;
    instrument.setLowPriceLimit = product.setLowPriceLimit;
    instrument.setHighPriceLimit = product.setHighPriceLimit;
    instrument.lowOrderLimit = product.lowOrderLimit;
    instrument.highOrderLimit = product.highOrderLimit;
    instrument.setLowOrderLimit = product.setLowOrderLimit;
    instrument.setHighOrderLimit = product.setHighOrderLimit;
    instrument.lowTotalNotionalLimit = product.lowTotalNotionalLimit;
    instrument.highTotalNotionalLimit = product.highTotalNotionalLimit;
    instrument.setLowTotalNotionalLimit = product.setLowTotalNotionalLimit;
    instrument.setHighTotalNotionalLimit = product.setHighTotalNotionalLimit;
    instrument.holidayCalendars = product.holidayCalendars;
    instrument.settlementPeriodStartOffsetFromCloseDuration = product.settlementPeriodStartOffsetFromCloseDuration;
    instrument.settlementPeriodEndOffsetFromCloseDuration = product.settlementPeriodEndOffsetFromCloseDuration;
    instrument.bufferFromEndOfSettlementPeriodDuration = product.bufferFromEndOfSettlementPeriodDuration;
    instrument.settlementPriceLogic = product.settlementPriceLogic;
    instrument.settlementPriceCalculationRequiresApproval = product.settlementPriceCalculationRequiresApproval;
    instrument.minimumTradeQtyForVwapSettlementCalculation = product.minimumTradeQtyForVwapSettlementCalculation;
    instrument.settlementPriceCalculationEndingMethod = product.settlementPriceCalculationEndingMethod;

    //SUNDAY IN BACKEND IS 7. IN UI IS 0.
    if (product.tradingSchedule) {
      for (let index = 0; index < product.tradingSchedule.length; index++) {
        let position = product.tradingSchedule[index].daysOfWeekList.indexOf(7);
        if (position > -1) {
          product.tradingSchedule[index].daysOfWeekList[position] = 0;
        }
      }
    }

    instrument.tradingScheduleList = product.tradingSchedule;
    instrument.tradeDayRollSchedule = product.tradeDayRollSchedule

    if (product.type === INTEREST_RATE_SWAP) {
      setIRSAtts(product, instrument);
    } else if (product.type === FORWARD_RATE_AGREEMENT_SWAP) {
      setFRAAtts(product, instrument);
    } else if (product.type === FORWARD_STARTING_INTEREST_RATE_SWAP) {
      setFsIRSAtts(product, instrument);
    } else if (product.type === FUTURE) {
      setFutureAtts(product, instrument);
    } else if (product.type === OPTION) {
      setOptionAtts(product, instrument);
    } else if (product.type === FOREX) {
      setForexAtts(product, instrument);
    } else if (product.type === MULTILEG) {
      setMultilegAtts(product, instrument);
    } else if (product.type === BASIS_SWAP) {
      setBasisAtts(product, instrument);
    } else if (product.type === EVENT) {
      setUIEventAtts(product, instrument);
      // Setting question empty as instrument page will always fill its own question.
      instrument.evtAttQuestion = "";
    } else if (product.type === OVER_NIGHT_INDEX_SWAP) {
      setOISAtts(product, instrument);
    } else if (product.type === SINGLE_PERIOD_SWAP) {
      setSpsAtts(product, instrument);
    } else if (product.type === NON_DELIVERABLE_FORWARDS) {
      setNDFAtts(product, instrument);
    } else if (product.type === ZERO_COUPON_INFLATION_SWAP) {
      setZeroCouponInflationSwapAtts(product, instrument);
    } else {
      setDefaultAtts(instrument);
    }

    return instrument;
  }

  static mapToUpdatePriceLimitRequest(data) {
    let requestPriceLimit =
      new UpdateActiveInstrumentRequest.UpdatePriceLimitRequest();
    var priceLimit = data.getAttributes().getPriceLimit();

    if (priceLimit) {
      requestPriceLimit.setPriceLimit(priceLimit);
    }

    return requestPriceLimit;
  }

  static mapToUpdateSettlementCalculationDetailsRequest(data) {

    const instAttr = data.getAttributes();

    let updateSettlementCalculationDetailsReq = new UpdateActiveInstrumentRequest.UpdateSettlementCalculationDetailsRequest();
    if (!!instAttr.getSettlementPeriodStartOffsetFromCloseDuration()) updateSettlementCalculationDetailsReq.setSettlementPeriodStartOffsetFromCloseDuration(instAttr.getSettlementPeriodStartOffsetFromCloseDuration())
    if (!!instAttr.getSettlementPeriodEndOffsetFromCloseDuration()) updateSettlementCalculationDetailsReq.setSettlementPeriodEndOffsetFromCloseDuration(instAttr.getSettlementPeriodEndOffsetFromCloseDuration())
    if (!!instAttr.getBufferFromEndOfSettlementPeriodDuration()) updateSettlementCalculationDetailsReq.setBufferFromEndOfSettlementPeriodDuration(instAttr.getBufferFromEndOfSettlementPeriodDuration());
    if (!!instAttr.getMinimumTradeQtyForVwapSettlementCalculation()) updateSettlementCalculationDetailsReq.setMinimumTradeQtyForVwapSettlementCalculation(instAttr.getMinimumTradeQtyForVwapSettlementCalculation());

    updateSettlementCalculationDetailsReq.setSettlementPriceLogic(instAttr.getSettlementPriceLogic());
    updateSettlementCalculationDetailsReq.setSettlementPriceCalculationRequiresApproval(instAttr.getSettlementPriceCalculationRequiresApproval());
    updateSettlementCalculationDetailsReq.setSettlementPriceCalculationEndingMethod(instAttr.getSettlementPriceCalculationEndingMethod());

    return updateSettlementCalculationDetailsReq;
  }

  static mapToUpdateOrderLimitRequest(data) {
    let requestOrderSizeLimit =
      new UpdateActiveInstrumentRequest.UpdateOrderSizeLimitRequest();
    var orderSizeLimit = data.getAttributes().getOrderSizeLimit();

    if (orderSizeLimit) {
      requestOrderSizeLimit.setOrderSizeLimit(orderSizeLimit);
    }

    return requestOrderSizeLimit;
  }

  static mapToUpdateDatesRequest(data) {
    let requestDates = new UpdateActiveInstrumentRequest.UpdateDatesRequest();
    const startDate = data.getAttributes().getStartDate();
    const lastTradeDate = data.getAttributes().getLastTradeDate();
    const lastTradeTime = data.getAttributes().getLastTradeTime();
    const expirationDate = data.getAttributes().getExpirationDate();
    const expirationTime = data.getAttributes().getExpirationTime();
    const terminationDate = data.getAttributes().getTerminationDate();

    if (startDate && startDate > new Date()) {
      requestDates.setStartDate(startDate);
      requestDates.setStartDateUpdate(true);
    }

    requestDates.setLastTradeDate(!isNullOrUndefined(lastTradeDate) ? lastTradeDate : null);
    requestDates.setLastTradeDateUpdate(true);
    requestDates.setLastTradeTime(!isNullOrUndefined(lastTradeDate) && !isNullOrUndefined(lastTradeTime) ? lastTradeTime : null);
    requestDates.setLastTradeTimeUpdate(true);

    requestDates.setExpirationDate(!isNullOrUndefined(expirationDate) ? expirationDate : null);
    requestDates.setExpirationDateUpdate(true);
    requestDates.setExpirationTime(!isNullOrUndefined(expirationDate) && !isNullOrUndefined(expirationTime) ? expirationTime : null);
    requestDates.setExpirationTimeUpdate(true);

    requestDates.setTerminationDate(!isNullOrUndefined(terminationDate) ? terminationDate : null);
    requestDates.setTerminationDateUpdate(true);

    return requestDates;
  }

  static mapToUpdateHoursRequest(data) {
    let requestHours = new UpdateActiveInstrumentRequest.UpdateHoursRequest();
    const tradingSchedule = data.getAttributes().getTradingScheduleList();
    var tradingHours = [];

    if (tradingSchedule) {
      for (var i = 0; tradingSchedule.length > i; i++) {
        tradingHours.push(
          TradingHourMapper.mapToTradingHour(tradingSchedule[i].toObject())
        );
      }
    }

    requestHours.setTradingScheduleList(tradingHours);
    return requestHours;
  }

  static mapToUpdateHideMarketRequest(data) {
    let requestHideMarket =
      new UpdateActiveInstrumentRequest.UpdateHideMarketDataRequest();
    requestHideMarket.setHideMarketData(data.getHideMarketData());
    return requestHideMarket;
  }

  static mapToUpdateHolidayCalendarsRequest(data) {
    let requestHolidayCalendars =
      new UpdateActiveInstrumentRequest.UpdateHolidayCalendarsRequest();
    requestHolidayCalendars.setHolidayCalendarsList(
      data.getAttributes().getHolidayCalendarsList()
    );
    return requestHolidayCalendars;
  }

  static mapToUpdateCfiCodeRequest(data) {
    let requestCfiCode = new UpdateActiveInstrumentRequest.UpdateCfiCodeRequest();
    var cfiCode = data.getAttributes().getCfiCode();

    if (!isNullOrUndefined(cfiCode)) {
      requestCfiCode.setCfiCode(cfiCode);
    }

    return requestCfiCode;
  }

  static mapToUpdateInstrumentProductTypeRequest(data) {
    let requestInstProdType = new UpdateActiveInstrumentRequest.UpdateInstrumentProductTypeRequest();
    var instProdType = data.getAttributes().getInstrumentProductType();

    if (!isNullOrUndefined(instProdType)) {
      requestInstProdType.setInstrumentProductType(instProdType);
    }

    return requestInstProdType;
  }

  static mapToUpdateDescriptionRequest(data) {
    let requestDescription = new UpdateActiveInstrumentRequest.UpdateDescriptionRequest();
    requestDescription.setDescription(data.getDescription());
    return requestDescription;
  }

  static mapToUpdateTypeAttributesRequest(data) {
    let request = new UpdateActiveInstrumentRequest.UpdateTypeAttributesRequest();

    if (data.hasInterestRateSwapAttributes()) {
      request.setInterestRateSwapAttributes(data.getSinglePeriodSwapAttributes());
    } else if (data.hasCustomAttributes()) {
      request.setCustomAttributes(data.getCustomAttributes());
    } else if (data.hasForwardRateAgreementSwapAttributes()) {
      request.setForwardRateAgreementSwapAttributes(data.getForwardRateAgreementSwapAttributes());
    } else if (data.hasJsonAttributes()) {
      request.setJsonAttributes(data.getJsonAttributes());
    } else if (data.hasFutureAttributes()) {
      request.setFutureAttributes(data.getFutureAttributes());
    } else if (data.hasOptionAttributes()) {
      request.setOptionAttributes(data.getOptionAttributes());
    } else if (data.hasForexAttributes()) {
      request.setForexAttributes(data.getForexAttributes());
    } else if (data.hasMultilegAttributes()) {
      request.setMultilegAttributes(data.getMultilegAttributes());
    } else if (data.hasForwardStartingInterestRateSwapAttributes()) {
      request.setForwardStartingInterestRateSwapAttributes(data.getForwardStartingInterestRateSwapAttributes());
    } else if (data.hasBasisSwapAttributes()) {
      request.setBasisSwapAttributes(data.getBasisSwapAttributes());
    } else if (data.hasEventAttributes()) {
      request.setEventAttributes(data.getEventAttributes());
    } else if (data.hasOvernightIndexSwapAttributes()) {
      request.setOvernightIndexSwapAttributes(data.getOvernightIndexSwapAttributes());
    } else if (data.hasSinglePeriodSwapAttributes()) {
      request.setSinglePeriodSwapAttributes(data.getSinglePeriodSwapAttributes());
    } else if (data.hasZeroCouponInflationSwapAttributes()) {
      request.setZeroCouponInflationSwapAttributes(data.getZeroCouponInflationSwapAttributes());
    } else {
      return null;
    }

    return request;
  }

  static createInstrumentStats(data) {
    let requestStats = new InstrumentStats();

    if (data.low) {
      requestStats.setLowPx(data.low);
    }

    if (data.high) {
      requestStats.setHighPx(data.high);
    }

    if (data.open) {
      requestStats.setOpenPx(data.open);
    }

    if (data.indicativeOpen) {
      requestStats.setIndicativeOpenPx(data.indicativeOpen);
    }

    if (data.close) {
      requestStats.setClosePx(data.close);
    }

    if (data.lastPrice) {
      requestStats.setLastTradePx(data.lastPrice);
    }

    if (data.lastQuantity) {
      requestStats.setLastTradeQty(data.lastQuantity);
    }

    if (data.sharesTraded) {
      requestStats.setSharesTraded(data.sharesTraded);
    }

    if (data.notionalTraded) {
      requestStats.setNotionalTraded(data.notionalTraded);
    }

    if (data.settlementPrice) {
      requestStats.setSettlementPx(data.settlementPrice);
    }

    if (data.openInterest) {
      requestStats.setOpenInterest(data.openInterest);
    }

    return requestStats;
  }

  static createHoliday(event) {
    let holiday = new Holiday();
    holiday.setDescription(event.title);
    holiday.setDate(dateToProtoDate(event.start));
    holiday.setTimeOfDay(dateToProtoTimeOfDay(event.start));
    const millisDuration = event.end.getTime() - event.start.getTime();
    const duration = {
      seconds: Math.floor(millisDuration / 1000),
      nanos: (millisDuration % 1000) * 1000000,
    };
    holiday.setDuration(ProtobufParser.toDuration(duration));
    return holiday;
  }

  static createHolidayCalendar(baseEvent, data) {
    let calendar = new HolidayCalendar();
    calendar.setId(baseEvent.calendar);
    calendar.setDescription(baseEvent.description);
    calendar.setGlobal(baseEvent.global);
    if (Array.isArray(data)) {
      let holidayList = [];
      data.forEach((event) => holidayList.push(this.createHoliday(event)));
      calendar.setHolidaysList(holidayList);
    }
    return calendar;
  }
}
