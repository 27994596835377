import React from "react";
import PropTypes from "prop-types";
import { FormGroup, FormLabel } from "react-bootstrap";
import MultiSelectControl from "../MultiSelectControl/MultiSelectControl";

const MultiSelectDropdownListGroup = ({
  id,
  name,
  data,
  label,
  isRequired,
  value,
  enabled,
  itemComponent,
  dropUp,
  errors,
  touched,
  onChange,
  onBlur,
  textField,
  valueField,
  defaultValue,
  readOnly,
  plaintext
}) => (
  <FormGroup id={id}>
    {label && <FormLabel>{label}</FormLabel>}

    {plaintext && <div className="multiselect">{value ? value.join(", ") : ""}</div>}

    {!plaintext &&
      <>
        {isRequired && <label className="form-label-required"> Required</label>}
        <div className="multiselect">
          <MultiSelectControl
            data={data}
            value={value}
            dropUp={dropUp}
            enabled={enabled}
            onChange={onChange}
            onBlur={onBlur}
            itemComponent={itemComponent}
            textField={textField}
            valueField={valueField}
            errors={errors && errors[name] ? true : false}
            defaultValue={defaultValue}
            readOnly={readOnly}
          />
        </div>
        {errors && errors[name] && (
          <div className="form-error">
            <i
              className="fa fa-exclamation-triangle orange-icon"
              aria-hidden="true"
            ></i>{" "}
            {errors[name]}
          </div>
        )}
      </>
    }
  </FormGroup>
);

MultiSelectDropdownListGroup.defaultProps = {
  enabled: true,
  dropUp: false,
  isRequired: false,
  value: null,
};

MultiSelectDropdownListGroup.propTypes = {
  enabled: PropTypes.bool,
  dropUp: PropTypes.bool,
  value: PropTypes.any,
  isRequired: PropTypes.bool,
  itemComponent: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  textField: PropTypes.string,
  valueField: PropTypes.string,
};

export default MultiSelectDropdownListGroup;
