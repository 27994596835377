import { useEffect, useState } from "react";

export default function usePreserveGridColsLayout(key) {
  const [columnsLayout, setColumnsLayout] = useState({});

  useEffect(() => {
    const layoutJson = localStorage.getItem(key);

    if (layoutJson && layoutJson !== 'null') {
      try {
        const layoutObj = JSON.parse(layoutJson);
        if (layoutObj) setColumnsLayout(layoutObj);
      } catch (e) {
        console.error(`Error loading columns layout. Key:${key}`, e);
      }
    }
  }, [key]);

  const saveColumnsLayout = (layout) => {
    try {
      const layoutJson = JSON.stringify(layout);
      localStorage.setItem(key, layoutJson);
      setColumnsLayout(layout);
    } catch (e) {
      console.error(`Error saving columns layout. Key:${key}`, e);
    }
  }

  return { columnsLayout, saveColumnsLayout };
}
