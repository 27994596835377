import React, { useMemo } from "react";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import DataGridActions from "../core/data-grid/DataGridActions";
import { PendingInstrumentDeleteMsg, PENDING_INSTRUMENT_BULK_DELETE_MSG } from "../../constants/strings";
import _ from "lodash"
import { isArrayWithValues, parsePrice } from "../../modules/util";
import GridActions from "../core/data-grid/GridActions";
import GridAction from "../core/data-grid/GridAction";
import * as Icons from '../core/icons'
import { hasWriteAccess } from "../../services/TokenService";
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import { GridActionMenu } from "../core/menu-items/menu-items";
import usePreserveGridColsLayout from "../hooks/usePreserveGridColsLayout";

const InstrumentDataGrid = ({
  data,
  onView,
  onState,
  onEdit,
  onRemove,
  onCopy,
  onBook,
  onMarketStats,
  product,
  onPageChange,
  paginationComponent,
  onPageSizeChange
}) => {
  var ref = null;

  const gridCtxMenu = useMemo(() => GridActionMenu, []);
  const { columnsLayout, saveColumnsLayout } = usePreserveGridColsLayout("InstrumentDGColumnsLayout");

  const onColumnResize = (newResized, event) => {
    if (isArrayWithValues(newResized)) {
      newResized.map((column) => {
        columnsLayout[column.id] = column.value;
      });
    }

    saveColumnsLayout(columnsLayout);
  }

  const getColumnWidth = (id) => {
    return columnsLayout[id] ? columnsLayout[id] : undefined;
  }

  const columns = [
    {
      Header: "PRODUCT NAME",
      accessor: "productId",
      show: !product,
      sortable: false,
      width: getColumnWidth("productId"),
    },
    {
      Header: "ID (SYMBOL)",
      id: "symbol",
      accessor: (d) => { return !!d.id ? d.id : ""; },
      sortable: false,
      width: getColumnWidth("symbol"),
    },
    {
      Header: "DESCRIPTION",
      accessor: "description",
      sortable: false,
      width: getColumnWidth("description"),
    },
    {
      Header: "TRADABLE",
      id: "tradable",
      accessor: (d) => { return !!d.nonTradable ? "N" : "Y"; },
      sortable: false,
      maxWidth: 80,
      width: getColumnWidth("tradable"),
    },
    {
      Header: "CLEARING HOUSE",
      id: "clearingHouse",
      sortable: false,
      accessor: (d) => {
        if (!d.clearingHouse) {
          return "";
        }
        return d.clearingHouse.displayName
          ? d.clearingHouse.displayName
          : d.clearingHouse;
      },
      width: getColumnWidth("clearingHouse"),
    },
    {
      Header: "SCHEDULED",
      id: "scheduled",
      accessor: (d) => { return !!d.isScheduled ? "Scheduled" : <span className="color-error" >Manual</span> },
      sortable: false,
      maxWidth: 80,
      width: getColumnWidth("scheduled"),
    },
    {
      Header: () => {
        const hasSelection =
          ref &&
          ref.state &&
          ref.state.selection &&
          ref.state.selection.size > 0;
        return (
          <div>
            STATE
            {hasSelection && (
              <DataGridActions
                onState={() => {
                  onState(Array.from(ref.state.selection.values()));
                }}
              />
            )}
          </div>
        );
      },
      accessor: "state.name",
      sortable: false,
      width: getColumnWidth("state.name"),
    },
    {
      Header: "OPEN",
      id: "openPx",
      accessor: (d) => !d.books || !d.books.stats || !d.books.stats.openSetTime ? "" : parsePrice(d.books.stats.openPx, d.priceScale),
      sortable: false,
      maxWidth: 80,
      width: getColumnWidth("openPx"),
    },
    {
      Header: "HIGH",
      id: "highPx",
      accessor: (d) => !d.books || !d.books.stats || !d.books.stats.highSetTime ? "" : parsePrice(d.books.stats.highPx, d.priceScale),
      sortable: false,
      maxWidth: 80,
      width: getColumnWidth("highPx"),
    },
    {
      Header: "LOW",
      id: "lowPx",
      accessor: (d) => !d.books || !d.books.stats || !d.books.stats.lowSetTime ? "" : parsePrice(d.books.stats.lowPx, d.priceScale),
      sortable: false,
      maxWidth: 80,
      width: getColumnWidth("lowPx"),
    },
    {
      Header: "CLOSE",
      id: "closePx",
      accessor: (d) => !d.books || !d.books.stats || !d.books.stats.closeSetTime ? "" : parsePrice(d.books.stats.closePx, d.priceScale),
      sortable: false,
      maxWidth: 80,
      width: getColumnWidth("closePx"),
    },
    {
      Header: "LAST-PX",
      id: "lastTradePx",
      accessor: (d) => !d.books || !d.books.stats || !d.books.stats.lastTradeSetTime ? "" : parsePrice(d.books.stats.lastTradePx, d.priceScale),
      sortable: false,
      maxWidth: 80,
      width: getColumnWidth("lastTradePx"),
    },
    {
      Header: "SETTL-PX",
      id: "settlementPx",
      accessor: (d) => !d.books || !d.books.stats || !d.books.stats.settlementSetTime ? "" : parsePrice(d.books.stats.settlementPx, d.priceScale),
      sortable: false,
      maxWidth: 80,
      width: getColumnWidth("settlementPx"),
    },
    {
      Header: (row) => {
        const hasSelection =
          ref &&
          ref.state &&
          ref.state.selection &&
          ref.state.selection.size > 0;

        const prepareInstrumentsToDelete = () => {
          /*  
            NOTE - 
            This is required as we need to fetch only current page selected values
            as with the pagination for instruments, we are not getting values which 
            are selected in the previous page.
          */
          let selectedInstruments = Array.from(ref.state.selection.values())
          let currentPageSelectedInstruments = []
          const success = _.every(row.data, ins => {
            let isInstrumentDeletable = ins._original.isDeletable
            let instrumentId = ins._original.id
            if (selectedInstruments.indexOf(instrumentId) > -1) {
              if (!isInstrumentDeletable) {
                return false
              }
              currentPageSelectedInstruments.push(ins._original.id)
            }
            return true;
          })
          if (!success) {
            return [];
          }
          return currentPageSelectedInstruments;
        }

        return (
          <div>
            ACTIONS
            {hasSelection && (
              <DataGridActions
                isDeletable={prepareInstrumentsToDelete().length > 0}
                deleteMsg={prepareInstrumentsToDelete().length <= 0 ? PENDING_INSTRUMENT_BULK_DELETE_MSG : 'Delete Selected'}
                onRemove={() => {
                  onRemove(prepareInstrumentsToDelete(), true);
                }}
              />
            )}
          </div>
        );
      },
      maxWidth: 300,
      minWidth: 300,
      sortable: false,
      Cell: (row) => {
        return (
          <GridActions>
            <GridAction title="View" icon={Icons.eye}
              onClick={(e, actionContext) => { onView(row.original.id, actionContext); }}
              contextMenu={gridCtxMenu} />

            {hasWriteAccess() && <GridAction title="State" icon={Icons.exclamationTriangle}
              onClick={(e, actionContext) => { onState(row.original.id, actionContext); }}
              contextMenu={gridCtxMenu} />}

            <GridAction title="Edit" icon={Icons.edit}
              onClick={(e, actionContext) => { onEdit(row.original.id, actionContext) }}
              contextMenu={gridCtxMenu} />

            {hasWriteAccess() && <GridAction title="Copy" icon={Icons.copy}
              onClick={(e, actionContext) => { onCopy(row.original.id, actionContext) }}
              contextMenu={gridCtxMenu} />}

            {hasWriteAccess() && <GridAction
              title={row.original.isDeletable ? 'Delete' : PendingInstrumentDeleteMsg}
              icon={Icons.trashAlt} disabled={!row.original.isDeletable}
              onClick={(e, actionContext) => { onRemove(row.original.id, false) }} />}

            <GridAction title="Book" icon={Icons.book}
              onClick={(e, actionContext) => { onBook(row.original.id, actionContext) }}
              contextMenu={gridCtxMenu} />

            <GridAction title="Review Market Stats" icon={Icons.cog}
              disabled={row.original.state.id === InstrumentState.INSTRUMENT_STATE_PENDING || row.original.state.id === InstrumentState.INSTRUMENT_STATE_TERMINATED}
              onClick={(e, actionContext) => { onMarketStats(row.original.id, actionContext) }}
              contextMenu={gridCtxMenu} />

          </GridActions>
        );
      },
    },
  ];
  return (
    <div>
      <DataGrid
        ref={(r) => (ref = r)}
        data={product ? data.filter((row) => row.productId === product) : data}
        columns={columns}
        filterable={false}
        selectable={true}
        manual={true}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onResizedChange={onColumnResize}
        PaginationComponent={paginationComponent}
      />
    </div>
  );
};

InstrumentDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  onState: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  product: PropTypes.string,
};

export default InstrumentDataGrid;
