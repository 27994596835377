import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import DropdownListGroup from "../../core/form/DropdownListGroup";
import FieldGroup from "../../core/form/FieldGroup";
import ConfirmDialog from "../../core/confirm-dialog/ConfirmDialog";
import {createHolidayCalendar, deleteHolidayCalendar} from "../../../actions/instruments";
import CheckboxGroup from "../../core/form/CheckboxGroup";
import confirm from "../../../modules/confirmDialog";

function mapStateToProps(state) {
    return {
        fetchingHolidayCalendars: state.instruments.fetchingHolidayCalendars,
        holidayCalendars: state.instruments.holidayCalendars,
        holidayCalendarObjects: state.instruments.holidayCalendarObjects,
    };
}

const mapDispatchToProps = (dispatch) => ({
    createHolidayCalendar: (calendar) => {
        dispatch(createHolidayCalendar(calendar));
    },
    deleteHolidayCalendar: (id) => {
        dispatch(deleteHolidayCalendar(id));
    },
})

class HolidayManager extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
    }

    getDefaultState() {
        return {
            calendar: "",
            description: "",
            global: false,
        }
    }

    render() {
        return <ConfirmDialog
            show={this.props.show}
            proceed={() => {
                const isDelete = this.props.holidayCalendarObjects.hasOwnProperty(this.state.calendar);
                const action = isDelete ? "DELETE" : "CREATE";
                confirm("Are you sure you want to " + action + " "+this.state.calendar+"?", {
                    title: action + " " + this.state.calendar,
                    okButtonText: "Yes",
                    cancelButtonText: "No",
                }).then(
                    () => {
                        if (isDelete) {
                            this.props.deleteHolidayCalendar(this.state.calendar);
                        } else {
                            this.props.createHolidayCalendar(this.state);
                        }
                        this.props.setShow(false)
                    },
                    () => {},
                )
            }}
            cancel={() => this.props.setShow(false)}
            options={{
                title: "Manage Holiday Calendars",
                okButtonText: !this.state.calendar ? "" : this.props.holidayCalendarObjects.hasOwnProperty(this.state.calendar) ? "Delete" : "Create",
                cancelButtonText: "Cancel",
            }}
            confirmation={<div>
                <DropdownListGroup
                    id="calendar"
                    name="calendar"
                    label="Holiday Calendar"
                    value={this.props.holidayCalendarObjects.hasOwnProperty(this.state.calendar) ? this.state.calendar : ""}
                    onChange={(e) => this.setState({
                        calendar: e,
                        global: this.props.holidayCalendarObjects[e].global,
                        description: this.props.holidayCalendarObjects[e].description,
                    })}
                    data={Object.keys(this.props.holidayCalendarObjects)}
                />
                <FieldGroup
                    id="calendarText"
                    name="calendarText"
                    type="text"
                    label="Calendar Title"
                    value={this.state.calendar}
                    onChange={(e) => this.setState({
                        calendar: e.target.value,
                        global: this.props.holidayCalendarObjects.hasOwnProperty(e.target.value) ? this.props.holidayCalendarObjects[e.target.value].global : this.state.global,
                        description: this.props.holidayCalendarObjects.hasOwnProperty(e.target.value) ? this.props.holidayCalendarObjects[e.target.value].description : this.state.description,
                    })}
                    errors={[]}
                    setFieldValue={() => {}}
                />
                <FieldGroup
                    id="description"
                    name="description"
                    type="text"
                    label="Description"
                    value={this.state.description}
                    onChange={(e) => this.setState({ description: e.target.value })}
                    errors={[]}
                    setFieldValue={() => {}}
                    disabled={this.props.holidayCalendarObjects.hasOwnProperty(this.state.calendar)}
                />
                <CheckboxGroup
                    checked={this.state.global}
                    onClick={(e) => { this.setState({ global: e.target.checked}); } }
                    label="Global (apply to all instruments)"
                    disabled={this.props.holidayCalendarObjects.hasOwnProperty(this.state.calendar)}
                />
            </div>
            }
        />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayManager);
