import ApiCall from "../modules/apiCall";
import { apiUrl } from "../modules/util";
const { JobsAPIClient } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/jobs/v1beta1/jobs_api_grpc_web_pb.js")

const jobClient = new JobsAPIClient(apiUrl(), null, null);
const activeLogCalls = new Set();

export default class JobService {
    static listPods(listPodsRequest, cb) {
        ApiCall(jobClient, "listPods", listPodsRequest, cb)
    }

    static listCronJobs(listCronJobsRequest, cb) {
        ApiCall(jobClient, "listCronJobs", listCronJobsRequest, cb)
    }

    static listJobs(listJobsRequest, cb) {
        ApiCall(jobClient, "listJobs", listJobsRequest, cb)
    }

    static listEp3Versions(listEp3VersionsRequest, cb) {
        ApiCall(jobClient, "listEp3Versions", listEp3VersionsRequest, cb)
    }

    static runCronJob(runCronJobRequest, cb) {
        ApiCall(jobClient, "runCronJob", runCronJobRequest, cb)
    }

    static streamLogs(streamLogsRequest, onData, onStatus, onEnd, onErr) {
        const call = ApiCall(jobClient, "streamLogs", streamLogsRequest, null, {data: onData, status: onStatus, end: onEnd, error: onErr});

        for (let entry of activeLogCalls.values()){
            entry.cancel();
        }
        activeLogCalls.clear();
        if (call) {
            activeLogCalls.add(call);
        }
        return call;
    }
}
