import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { withFormik } from "formik";
import { FormModes } from "../../constants/enumerations";
import ButtonBorderless from "../core/form/ButtonBorderless";
import DateGroup from "../core/form/DateGroup";
import ButtonMain from "../core/form/ButtonMain";
import * as Yup from "yup";
import DropdownListGroup from "../core/form/DropdownListGroup";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import { FirmType, ParticipantRole } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import {isEmptyString, isNullOrUndefined} from '../../modules/util';
import Tooltip from "../core/tooltip-html/tooltip-html";
import CheckboxGroup from "../core/form/CheckboxGroup";
import InstrumentStaticData from "../../modules/instrumentStaticData";

function firmTypeValid(typ) {
    return typ === FirmType.FIRM_TYPE_PARTICIPANT || typ === FirmType.FIRM_TYPE_CLEARING_MEMBER;
}

function userRoleValid(typ) {
    return typ === ParticipantRole.PARTICIPANT_ROLE_TRADING;
}

function mapStateToProps(state) {
    return {
        account: state.accounts.account,
        accounts: state.accounts.accounts.map((acct) => ({"id": acct.name, "name": acct.displayName + " (" + acct.name + ")"})),
        associatedFirm: state.firms.firm,
        users: state.participants.participants.filter((user) => !!user && !!user.role && userRoleValid(user.role.id)).map((user) => ({ "id": user.name, "name": user.displayName + " (" + user.name + ")" })),
        associatedFirms: state.firms.firms.filter((firm) => !!firm && !!firm.firmType && firmTypeValid(firm.firmType.id)).map((firm) => ({ id: firm.name, name: firm.displayName + " (" + firm.name + ")" }))
    };
}

const mapDispatchToProps = (dispatch) => ({

});

const findResourceAndGetDisplayName = (resources, id) => {
    let rsc = resources.find((usr) => usr.id === id);
    return !isNullOrUndefined(rsc) && !isNullOrUndefined(rsc.name) && !isEmptyString(rsc.name) ? rsc.name : id;
}

const basisPointsToolTip = "The commission rate expressed in basis points (100bps = 1%)";

const maximumNotionalTooltip = (<div>
    The optional maximum commission notional that can be collected per order
</div>);

const minimumNotionalTooltip = (<div>
    The optional minimum commission notional that must be collected<br/>
    if an order has any fills, overriding the standard rate<br/>
    until the minimum is satisfied
</div>);

const maximumRateBasisPointsTooltip = (<div>
    The optional maximum rate that must be collected<br/>
    if an order has any fills, applying only up until<br/>
    the minimum notional is satisfied and expressed in<br/>
    basis points (100bps = 1%). Only relevant if the<br/>
    minimum notional field is set.
</div>);

const applyAsSpreadTooltip = (<div>
    If set to true, EP3 will apply the commission as a spread<br/>
    (rather than identifying it in the commission field on execution reports)<br/>
    to any Participant firms that have the apply_commissions_as_spread flag enabled.
</div>);

class BasicCommissionsForm extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    componentDidMount() {

    }

    render() {
        const {
            touched,
            errors,
            isEditing,
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            history,
            location
        } = this.props;
        return (
            <>
                <form onSubmit={handleSubmit}>
                    <h1>Commission Form</h1>
                    <Container>
                        <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="Account"
                                    label="Account"
                                    value={values.displayName}
                                    tooltip="The destination account for exchange commissions to be collected into"
                                />
                            </Col>
                        </Row>

                        {(values.isReadOnly && !values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="basisPoints"
                                    label="Basis Points"
                                    value={values.basisPoints}
                                    tooltip={basisPointsToolTip}
                                />
                            </Col>
                        </Row>
                        }

                        {(values.isReadOnly && !values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="applyAsSpread"
                                    label="Apply as Spread"
                                    value={!!values.applyAsSpread ? "Y" : "N"}
                                    tooltip={applyAsSpreadTooltip}
                                />
                            </Col>
                        </Row>
                        }

                        {(values.isReadOnly && !values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="minimumNotional"
                                    label="Minimum Notional"
                                    value={values.minimumNotional}
                                    tooltip={minimumNotionalTooltip}
                                />
                            </Col>
                        </Row>
                        }

                        {(values.isReadOnly && !values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="maximumRateBasisPoints"
                                    label="Maximum Rate Basis Points"
                                    value={values.maximumRateBasisPoints}
                                    tooltip={maximumRateBasisPointsTooltip}
                                />
                            </Col>
                        </Row>
                        }

                        {(values.isReadOnly && !values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="maximumNotional"
                                    label="Maximum Notional"
                                    value={values.maximumNotional}
                                    tooltip={maximumNotionalTooltip}
                                />
                            </Col>
                        </Row>
                        }

                        {(!values.isReadOnly || values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FormGroup id="basisPoints">
                                    <FormLabel>Basis Points</FormLabel>
                                    <Tooltip
                                        text={basisPointsToolTip}
                                        id="basisPointsTooltip"
                                    />
                                    <label className="form-label-required"> Required</label>
                                    <FormControl
                                        id="basisPoints"
                                        type="text"
                                        name="basisPoints"
                                        value={values.basisPoints}
                                        placeholder=""
                                        onChange={(e) => {
                                            handleChange(e);
                                            if (!isEditing) {
                                                if (!touched.basisPoints) {
                                                    setFieldValue("basisPoints", e.target.value);
                                                }
                                            }
                                        }}
                                    ></FormControl>
                                    {errors && errors["basisPoints"] && (
                                        <div className="form-error">
                                            <i
                                                className="fa fa-exclamation-triangle orange-icon"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            {errors["basisPoints"]}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>}

                        {(!values.isReadOnly || values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FormGroup id="applyAsSpread">
                                    <CheckboxGroup
                                        checked={!!values.applyAsSpread}
                                        onClick={(e) => {
                                            setFieldValue("applyAsSpread", !!e.target.checked);
                                        }}
                                        disabled={(!isNullOrUndefined(values.minimumNotional) && !isEmptyString(values.minimumNotional)) || (!isNullOrUndefined(values.maximumNotional) && !isEmptyString(values.maximumNotional))}
                                        label="Apply as Commission Spread"
                                    />
                                    <Tooltip
                                        text={applyAsSpreadTooltip}
                                        id="applyAsSpreadTooltip"
                                    />
                                    {errors && errors["applyAsSpread"] && (
                                        <div className="form-error">
                                            <i
                                                className="fa fa-exclamation-triangle orange-icon"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            {errors["applyAsSpread"]}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>}

                        {(!values.isReadOnly || values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FormGroup id="minimumNotional">
                                    <FormLabel>Minimum Notional</FormLabel>
                                    <Tooltip
                                        text={minimumNotionalTooltip}
                                        id="minimumNotionalTooltip"
                                    />
                                    <FormControl
                                        id="minimumNotional"
                                        type="text"
                                        name="minimumNotional"
                                        disabled={!!values.applyAsSpread}
                                        value={values.minimumNotional}
                                        placeholder=""
                                        onChange={(e) => {
                                            handleChange(e);
                                            if (!isEditing) {
                                                if (!touched.minimumNotional) {
                                                    setFieldValue("minimumNotional", e.target.value);
                                                }
                                            }
                                        }}
                                    ></FormControl>
                                    {errors && errors["minimumNotional"] && (
                                        <div className="form-error">
                                            <i
                                                className="fa fa-exclamation-triangle orange-icon"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            {errors["minimumNotional"]}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>}

                        {(!values.isReadOnly || values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FormGroup id="maximumRateBasisPoints">
                                    <FormLabel>Maximum Rate Basis Points</FormLabel>
                                    <Tooltip
                                        text={maximumRateBasisPointsTooltip}
                                        id="maximumRateBasisPointsTooltip"
                                    />
                                    <FormControl
                                        id="maximumRateBasisPoints"
                                        type="text"
                                        name="maximumRateBasisPoints"
                                        disabled={isNullOrUndefined(values.minimumNotional) || isEmptyString(values.minimumNotional)}
                                        value={values.maximumRateBasisPoints}
                                        placeholder=""
                                        onChange={(e) => {
                                            handleChange(e);
                                            if (!isEditing) {
                                                if (!touched.maximumRateBasisPoints) {
                                                    setFieldValue("maximumRateBasisPoints", e.target.value);
                                                }
                                            }
                                        }}
                                    ></FormControl>
                                    {errors && errors["maximumRateBasisPoints"] && (
                                        <div className="form-error">
                                            <i
                                                className="fa fa-exclamation-triangle orange-icon"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            {errors["maximumRateBasisPoints"]}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>}

                        {(!values.isReadOnly || values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FormGroup id="maximumNotional">
                                    <FormLabel>Maximum Notional</FormLabel>
                                    <Tooltip
                                        text={maximumNotionalTooltip}
                                        id="maximumNotionalTooltip"
                                    />
                                    <FormControl
                                        id="maximumNotional"
                                        type="text"
                                        name="maximumNotional"
                                        disabled={!!values.applyAsSpread}
                                        value={values.maximumNotional}
                                        placeholder=""
                                        onChange={(e) => {
                                            handleChange(e);
                                            if (!isEditing) {
                                                if (!touched.maximumNotional) {
                                                    setFieldValue("maximumNotional", e.target.value);
                                                }
                                            }
                                        }}
                                    ></FormControl>
                                    {errors && errors["maximumNotional"] && (
                                        <div className="form-error">
                                            <i
                                                className="fa fa-exclamation-triangle orange-icon"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            {errors["maximumNotional"]}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>}

                        {values.isReadOnly && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="firm"
                                    label="Firm"
                                    value={values.firmDisplayName}
                                />
                            </Col>
                        </Row>
                        }


                        {!values.isReadOnly && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <DropdownListGroup id="firm" name="firm" label="Firm"
                                    value={values.firm}
                                    errors={errors}
                                    onChange={(e) => setFieldValue("firm", e.id)}
                                    showFilter={true}
                                    data={values.firms}
                                ></DropdownListGroup>
                            </Col>
                        </Row>}

                        {values.isReadOnly && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="userOverride"
                                    label="User Override"
                                    value={values.userOverrideDisplayName}
                                />
                            </Col>
                        </Row>
                        }

                        {!values.isReadOnly && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <DropdownListGroup id="userOverride" name="userOverride" label="User Override"
                                    value={values.userOverride}
                                    errors={errors}
                                    onChange={(e) => setFieldValue("userOverride", e.id)}
                                    showFilter={true}
                                    data={values.users}
                                ></DropdownListGroup>
                            </Col>
                        </Row>
                        }

                        {values.isReadOnly && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="accountOverride"
                                    label="Account Override"
                                    value={values.accountOverrideDisplayName}
                                />
                            </Col>
                        </Row>
                        }

                        {!values.isReadOnly && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <DropdownListGroup id="accountOverride" name="accountOverride" label="Account Override"
                                    value={values.accountOverride}
                                    errors={errors}
                                    onChange={(e) => setFieldValue("accountOverride", e.id)}
                                    showFilter={true}
                                    data={values.accounts}
                                ></DropdownListGroup>
                            </Col>
                        </Row>
                        }

                        {values.isReadOnly && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="sideOverride"
                                    label="Side Override"
                                    value={values.sideOverrideString}
                                />
                            </Col>
                        </Row>
                        }

                        {!values.isReadOnly && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <DropdownListGroup id="sideOverride" name="sideOverride" label="Side Override"
                                    value={values.sideOverride}
                                    errors={errors}
                                    onChange={(e) => setFieldValue("sideOverride", e)}
                                    data={values.sideConventionOptions}
                                ></DropdownListGroup>
                            </Col>
                        </Row>}

                        {values.isReadOnly && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="symbolOverride"
                                    label="Symbol Override"
                                    value={values.symbolOverride}
                                />
                            </Col>
                        </Row>
                        }

                        {!values.isReadOnly && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FormGroup id="symbolOverride">
                                    <FormLabel>Symbol Override</FormLabel>
                                    <FormControl
                                        id="symbolOverride"
                                        type="text"
                                        name="symbolOverride"
                                        value={values.symbolOverride}
                                        placeholder=""
                                        onChange={(e) => {
                                            handleChange(e);
                                            if (!isEditing) {
                                                if (!touched.symbolOverride) {
                                                    setFieldValue("symbolOverride", e.target.value);
                                                }
                                            }
                                        }}
                                        readOnly={values.isReadOnly}
                                    ></FormControl>
                                </FormGroup>
                            </Col>
                        </Row>}

                        {(values.isReadOnly && !values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <FieldGroupReadOnly
                                    id="expirationTime"
                                    label="Expiration Date/Time"
                                    value={values.expirationTimeString}
                                />
                            </Col>
                        </Row>
                        }

                        {(!values.isReadOnly || values.isEditing) && <Row>
                            <Col lg={6} xs={6} md={12}>
                                <DateGroup id="expirationTime" name="expirationTime" label="Expiration Date/Time"
                                    value={values.expirationTime ? new Date(values.expirationTime) : null}
                                    errors={errors}
                                    dropUp={true}
                                    touched={touched}
                                    onChange={(e) => setFieldValue("expirationTime", e)}
                                    enableTime={true}
                                    defaultCurrentDate={Date.now()}
                                />

                                {errors && errors["expirationTime"] && (
                                    <div className="form-error">
                                        <i
                                            className="fa fa-exclamation-triangle orange-icon"
                                            aria-hidden="true"
                                        ></i>{" "}
                                        {errors["expirationTime"]}
                                    </div>
                                )}
                            </Col>
                        </Row>}

                        <div style={{ marginTop: "40px" }}>
                            {(!values.isReadOnly || values.isEditing) && (
                                <ButtonMain
                                    type="submit"
                                    text={isEditing ? "UPDATE" : "SAVE"}
                                />
                            )}

                            <ButtonBorderless
                                type="button"
                                text="Cancel"
                                icon="times-circle"
                                customClassName="grey-icon"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push(location.pathname);
                                }}
                            />
                        </div>
                    </Container>
                </form>
            </>
        )
    }
}

const CommissionsForm = withFormik({
    mapPropsToValues: (props) => {
        let commission = props.commission;
        let values = {
            accountId: commission ? commission.account : props.account.name,
            displayName: props.account.displayName,
            basisPoints: commission ? commission.basisPoints : null,
            applyAsSpread: !!commission && !!commission.applyAsSpread,
            maximumNotional: commission ? commission.maximumNotional : "",
            minimumNotional: commission ? commission.minimumNotional : "",
            maximumRateBasisPoints: commission ? commission.maximumRateBasisPoints : null,
            firm: commission ? commission.firm : "",
            firmDisplayName: commission ? findResourceAndGetDisplayName(props.associatedFirms, commission.firm) : "",
            userOverride: commission ? commission.userOverride : null,
            userOverrideDisplayName: commission ? findResourceAndGetDisplayName(props.users, commission.userOverride) : "",
            accountOverride: commission ? commission.accountOverride : null,
            accountOverrideDisplayName: commission ? findResourceAndGetDisplayName(props.accounts, commission.accountOverride) : "",
            sideOverride: commission && commission.sideOverride ? commission.sideOverride.id : null,
            sideOverrideString: commission && commission.sideOverride ? commission.sideOverride.name : null,
            symbolOverride: commission ? commission.symbolOverride : null,
            expirationTime: commission ? commission.expirationTime : null,
            expirationTimeString: commission ? commission.expirationTimeString : null,
            isReadOnly: props.mode === FormModes.view || props.mode === FormModes.edition,
            isEditing: props.mode === FormModes.edition,
            firms: [{ id: "", name: "" }, ...props.associatedFirms],
            sideConventionOptions: InstrumentStaticData.SideOptions,
            users: [{ id: "", name: "" }, ...props.users],
            accounts: [{ id: "", name: "" }, ...props.accounts],
        };

        return values;
    },

    validationSchema: Yup.object().shape({
        basisPoints: Yup.number().required("Please enter basis points."),
        minimumNotional: Yup.number(),
        maximumNotional: Yup.number()
    }),

    validate: (values, props) => {
        let errors = {};
        if (!isNullOrUndefined(values.expirationTime)
            && (isNullOrUndefined(values.sideOverride) || values.sideOverride.id === 0)
            && (isNullOrUndefined(values.symbolOverride) || values.symbolOverride.trim().length === 0)
            && (isNullOrUndefined(values.accountOverride) || values.accountOverride.trim().length === 0)
            && (isNullOrUndefined(values.userOverride) || values.userOverride.length === 0)) {
            errors.expirationTime = "Provided an expiration without providing an override.";
        }

        return errors;
    },
    handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
        props.onSetCommission(values)
    },
})(BasicCommissionsForm);

export default connect(mapStateToProps, mapDispatchToProps)(CommissionsForm);