import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import FieldGroup from "../../../core/form/FieldGroup";
import { FormModes } from "../../../../constants/enumerations";
import CheckboxGroup from '../../../core/form/CheckboxGroup';
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import { FieldArray } from "formik";
import * as Yup from "yup";
import ButtonSecondary from "../../../core/form/ButtonSecondary";
import { isArrayWithValues } from "../../../../modules/util";
import { Env } from "../../../../constants/environment";

export const SubTypes = ({ values,
  setFieldValue,
  touched,
  handleBlur,
  onChange,
  errors,
  mode }) => {

  const isViewing = mode === FormModes.view;
  const isCreating = mode === FormModes.creation;
  const isNettingEnabled = Env.isNettingEnabled();

  const initialType = {symbol: "", buyPriceIncrement: null, sellPriceIncrement: null, buyScale: null, sellScale: null, opposingSide: null, sellQuantityRatio: null, buyQuantityRatio: null };

  useEffect(() => {
    if(isCreating) {
      if (!!isNettingEnabled){
        if (!isArrayWithValues(values.subTypesList)){
          values.subTypesList = [initialType]
        }
      } else {
        values.subTypesList = []
      }
    }
  }, [isCreating]);

  const OPPOSING_SIDE = [{ id: false, name: 'As Defined' }, { id: true, name: 'Opposing' }];

  return (
    <FieldArray
      name="subTypesList"
      render={arrayHelpers => (
        <div>
          {values.subTypesList && values.subTypesList.length > 0 && (
            values.subTypesList.map((subType, index) => (
              <Row key={index}>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="symbol" name={`subTypesList.${index}.symbol`} type="textarea" label="Sub Type Name"
                    disabled={!isCreating}
                    value={values.subTypesList[index].symbol}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={onChange}
                    onBlur={handleBlur}
                    plaintext={isViewing}
                    isRequired={true}
                  />
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="buyPriceIncrement" name={`subTypesList.${index}.buyPriceIncrement`} type="textarea" label="Buy Price Increment"
                    disabled={!isCreating}
                    value={values.subTypesList[index].buyPriceIncrement}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={onChange}
                    onBlur={handleBlur}
                    plaintext={isViewing}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="sellPriceIncrement" name={`subTypesList.${index}.sellPriceIncrement`} type="textarea" label="Sell Price Increment"
                    disabled={!isCreating}
                    value={values.subTypesList[index].sellPriceIncrement}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={onChange}
                    onBlur={handleBlur}
                    plaintext={isViewing}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="buyScale" name={`subTypesList.${index}.buyScale`} type="textarea" label="Buy Scale"
                    disabled={!isCreating}
                    value={values.subTypesList[index].buyScale}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={onChange}
                    onBlur={handleBlur}
                    plaintext={isViewing}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="sellScale" name={`subTypesList.${index}.sellScale`} type="textarea" label="Sell Scale"
                    disabled={!isCreating}
                    value={values.subTypesList[index].sellScale}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={onChange}
                    onBlur={handleBlur}
                    plaintext={isViewing}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <DropdownListGroup id="opposingSide" name={`subTypesList.${index}.opposingSide`} label="Opposing Side"
                    value={values.subTypesList[index].opposingSide}
                    enabled={isCreating}
                    errors={errors}
                    onChange={(e) => {
                      setFieldValue(`subTypesList.${index}.opposingSide`, e.id);
                    }}
                    data={OPPOSING_SIDE}
                    textField="name"
                    valueField="id"
                    plaintext={isViewing}
                    isRequired={true}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="buyQuantityRatio" name={`subTypesList.${index}.buyQuantityRatio`} type="textarea" label="Buy Quantity Ratio"
                    disabled={!isCreating}
                    value={values.subTypesList[index].buyQuantityRatio}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={onChange}
                    onBlur={handleBlur}
                    plaintext={isViewing}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <FieldGroup id="sellQuantityRatio" name={`subTypesList.${index}.sellQuantityRatio`} type="textarea" label="Sell Quantity Ratio"
                    disabled={!isCreating}
                    value={values.subTypesList[index].sellQuantityRatio}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={onChange}
                    onBlur={handleBlur}
                    plaintext={isViewing}
                  />
                </Col>

                <Col lg={3} xs={3} md={3}>
                  <CheckboxGroup
                    checked={values.subTypesList[index].strictLimit}
                    onChange={(e) => {
                      setFieldValue(`subTypesList.${index}.strictLimit`, e.target.checked)
                    }}
                    label="Strict Limit"
                    disabled={isViewing}
                  />
                </Col>
                {(values.subTypesList.length > 1 || !isNettingEnabled) && isCreating && <ButtonSecondary type="button" onClick={() => arrayHelpers.remove(index)} text="Remove sub type" />}
              </Row>
            ))
          )}
          {isCreating &&
          <button type="button" className="btn-add-alias mb-2" onClick={() => arrayHelpers.push(initialType)}>
            Add Sub Type
          </button>
          }
        </div>
      )}
    />
  )
}

export const SubTypesSchemaValidation = () => {
  return {
    subTypesList: Yup.array()
      .of(
        Yup.object().shape({
          symbol: Yup.string().required('Sub Type Name is required.'),
          buyPriceIncrement: Yup.number()
            .typeError('Buy Price Increment must be a number')
            .transform(emptyStringToNull)
            .nullable(),
          sellPriceIncrement: Yup.number()
            .typeError('Sell Price Increment must be a number')
            .transform(emptyStringToNull)
            .nullable(),
          buyScale: Yup.number()
            .typeError('Buy Scale must be a number')
            .transform(emptyStringToNull)
            .nullable(),
          sellScale: Yup.number()
            .typeError('Sell Scale must be a number')
            .transform(emptyStringToNull)
            .nullable(),
          opposingSide: Yup.boolean().required()
            .typeError('Opposing Side is required.'),
          buyQuantityRatio: Yup.number()
            .typeError('Buy Quantity Ratio must be a number')
            .transform(emptyStringToNull)
            .nullable(),
          sellQuantityRatio: Yup.number()
            .typeError('Sell Quantity Ratio must be a number')
            .transform(emptyStringToNull)
            .nullable(),
        })
      )
  }
}

const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}