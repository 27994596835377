import React, { useState } from "react";
import PropTypes from "prop-types";
import { timeToInt, timeFromInt } from "time-number";
import moment from "moment";

function TimeSelect({
  onChange,
  id,
  name,
  end = "23:59",
  format = 12,
  initialValue = "00:00",
  start = "00:00",
  step = 30,
  value = null,
  ...rest
}) {
  const [showTimeOptions, setShowTimeOptions] = useState(false);
  const [currentTimeValue, setCurrentTimeValue] = useState(
    generateFormattedTime(value || initialValue)
  );
  const [lastValidTime, setLastValidTime] = useState(currentTimeValue);

  function generateFormattedTime(time) {
    const ret = timeFromInt(time, false);

    if (format === 24) {
      return ret;
    }

    const found = ret.match(/^(\d+):/);
    const hour = parseInt(found[1], 10);

    if (hour === 0) {
      return `${ret.replace(/^\d+/, "12")} AM`;
    } else if (hour < 12) {
      return `${ret} AM`;
    } else if (hour === 12) {
      return `${ret} PM`;
    }

    const newHour = hour < 22 ? `0${hour - 12}` : (hour - 12).toString();

    return `${ret.replace(/^\d+/, newHour)} PM`;
  }

  function generateTimeRange() {
    const times = [];
    const iend = timeToInt(end, false);

    for (let i = timeToInt(start, false); i <= iend; i += step * 60) {
      times.push(i);
    }

    return times;
  }

  function listTimeOptions() {
    return generateTimeRange().map((unformattedTime) => {
      const formattedTime = generateFormattedTime(unformattedTime);

      return {
        key: unformattedTime,
        val: formattedTime,
      };
    });
  }

  function getClassNames() {
    let className = "timeList";
    if (!validateTime(currentTimeValue)) {
      return className + " invalidTime";
    }
    return className;
  }

  function getTimeFormat(time) {
    if (!time) return "h";

    let splits = time.split(":");
    let timeFormat = "h";
    let hours = splits[0];
    let minutes = splits[1];
    
    if (minutes.toUpperCase().indexOf("AM") || minutes.toUpperCase().indexOf("PM")) {
      let ampmIndex = minutes.toUpperCase().indexOf("AM");
      if (ampmIndex === -1) ampmIndex = minutes.toUpperCase().indexOf("PM");

      // Getting full length of minutes to fetch whole minutes value
      if (ampmIndex === -1) ampmIndex = minutes.length;
      minutes = minutes.substring(0, ampmIndex).trim();
    }

    const hh = hours.length > 2 ? 2 : hours.length;
    const mm = minutes.length > 2 ? 2 : minutes.length;

    timeFormat = `${"h".repeat(hh)}:${"m".repeat(mm)}`;

    return timeFormat
  }

  function validateTime(time) {
    if (!time) return false;
    let splits = time.split(":");
    let timeFormat = "h";
    switch (splits.length) {
      case 2:
        timeFormat = getTimeFormat(time);
        break;
      case 3:
        timeFormat = "h:mm:ss";
        break;
      default:
    }
    if (format === 12) {
      timeFormat = timeFormat + " A";
    }

    let isValid = moment(time, timeFormat, true).isValid();
    return isValid;
  }

  const timeOptions = listTimeOptions();
  const optionWidgets = timeOptions.map(({ key, val }) => (
    <div
      className="timeList-option"
      onClick={(e) => {
        e.preventDefault();
        setShowTimeOptions(false);
        setCurrentTimeValue(val);
        onChange(key);
      }}
      key={key}
      value={key}
    >
      {val}
    </div>
  ));

  function scrollToCurrentValue() {
    let curValue = timeToInt(currentTimeValue);
    let options = [...document.querySelectorAll(".timeList-option")];
    let previous = null;
    let selectedOption = null;
    for (let counter = 0; counter < options.length; counter++) {
      let option = options[counter];
      let optionValue = parseInt(option.getAttribute("value"));
      if (optionValue >= curValue) {
        selectedOption = previous || option;
        break;
      }
      previous = option;
    }
    if (selectedOption) {
      selectedOption.scrollIntoView();
    }
  }

  function updateTimeValue() {
    setShowTimeOptions(false);
    if (validateTime(currentTimeValue)) {
      let time = currentTimeValue;
      if (
        currentTimeValue.toUpperCase().endsWith("A") ||
        currentTimeValue.toUpperCase().endsWith("P")
      ) {
        time = (currentTimeValue + "m").toUpperCase();
        setCurrentTimeValue(time);
      }
      onChange(timeToInt(time));
      setLastValidTime(time);
    } else {
      setCurrentTimeValue(lastValidTime);
    }
  }

  return (
    <>
      <div
        onBlur={(event) => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            updateTimeValue();
          }
        }}
      >
        <input
          id={id}
          list="timeValues"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.target.blur();
            }
          }}
          onChange={(e) => {
            setShowTimeOptions(false);
            setCurrentTimeValue(e.target.value);
          }}
          name={name}
          className={getClassNames()}
          value={currentTimeValue}
          onFocus={() => {
            setShowTimeOptions(true);
            setTimeout(scrollToCurrentValue, 100);
          }}
          {...rest}
        />
        {showTimeOptions && (
          <div
            tabindex="0"
            name="timeList-options-div"
            id="timeList-options-div"
            className="timeList-options-div"
          >
            {optionWidgets}
          </div>
        )}
      </div>
    </>
  );
}

TimeSelect.propTypes = {
  end: PropTypes.string,
  format: PropTypes.number,
  initialValue: PropTypes.any,
  onChange: PropTypes.func,
  start: PropTypes.string,
  step: PropTypes.number,
  value: PropTypes.any,
};

export default TimeSelect;
