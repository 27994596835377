import React, { useEffect, useState } from 'react';
import { Col, Row, } from "react-bootstrap";
import ConfirmDialog from "../../core/confirm-dialog/ConfirmDialog";
import AutoComplete from "../../core/auto-complete/AutoComplete";
import { FormGroup, FormLabel, Container } from "react-bootstrap";

export default function CreateProductCategoryLevel(props) {
    const { show, onComplete, categoryLevelsList, onCancel, workingIndex } = props;
    const [category, setCategory] = useState("");
    const [categories, setCategories] = useState([]);

    const valid = (category.trim().length > 0);

    useEffect(() => {
        let categories = new Set();
        if (!!categoryLevelsList && categoryLevelsList.length > 0) {
            categoryLevelsList.forEach(levels => {
                if (levels.length > workingIndex) {
                    const level = levels[workingIndex];
                    categories.add(level);
                }
            });
        }

        setCategories([...categories]);
    }, [categoryLevelsList])

    const resetState = () => {
        setCategory("");
        setCategories([]);
    }

    return (
        <ConfirmDialog
            show={!!show}

            proceed={() => {
                onComplete(category);
                onCancel();
                resetState();
            }}

            cancel={() => {
                onCancel();
            }}

            confirmation={
                <Container>
                    <Row>
                        <Col>
                            <FormGroup>
                                <FormLabel>Add Category</FormLabel>
                                <AutoComplete
                                    id="categoryLevel"
                                    placeholder="Enter Category"
                                    noItemsMessage="No results found"
                                    dataSource={categories}
                                    defaultValue={category}
                                    disable={false}
                                    onChange={(e) => setCategory(e.target.value)}
                                    onEnter={(value) => setCategory(value)}
                                    onSelect={(value) => setCategory(value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container >
            }

            options={{
                title: "Add Category",
                okButtonText: valid ? "Submit" : null,
                cancelButtonText: "Cancel",
            }}
        />
    );
}
