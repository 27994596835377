import React, { forwardRef } from "react";

export class NavigationManager {
    static navRef = React.createRef();

    static openInNewTab = (link) => {
        this.navRef.current.target = "_blank"
        this.navRef.current.href = link;
        this.navRef.current.click()
    }
}

export const NavigationHelper = forwardRef((props, ref) => {
    return (
        <div style={{ display: "none", visibility: "collapse" }} ><a ref={NavigationManager.navRef} href='/' target='_blank'>a</a></div>
    );
});
