import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'react-bootstrap';
import TotalNotionalLimitField from './TotalNotionalLimitField';
import './TotalNotionalLimit.css';
import * as toolTips from '../../constants/instrumentToolTip'
import Tooltip from '../core/tooltip-html/tooltip-html';

class TotalNotionalLimit extends Component {
  render() {
    return (
      <div className="total-notional-limit-wrapper">
        <label>Total Notional Limits</label>
        <Tooltip
          text = {toolTips.TOTAL_NOTIONAL_LIMITS}
          id="tipTotalNotionalLimits"
        ></Tooltip>
        <Row>
          <Col lg={6} xs={6} md={6}>
            <TotalNotionalLimitField
                label="Low Limit"
                totalNotionalName="lowTotalNotionalLimit"
                totalNotionalValue={this.props.lowTotalNotional}
                setTotalNotional={this.props.setLowTotalNotional}
                isRequired={false}
                errors={this.props.errors}
                touched={this.props.touched}
                setFieldValue={this.props.setFieldValue}
                onChange={this.props.onChange}
                onBlur={this.props.handleBlur}
                onSetClick={(checked) => {
                  this.props.onSetLowTotalNotional(checked);
                }}
            />
          </Col>
          <Col lg={6} xs={6} md={6}>
            <TotalNotionalLimitField
                label="High Limit"
                totalNotionalName="highTotalNotionalLimit"
                totalNotionalValue={this.props.highTotalNotional}
                setTotalNotional={this.props.setHighTotalNotional}
                isRequired={false}
                errors={this.props.errors}
                touched={this.props.touched}
                setFieldValue={this.props.setFieldValue}
                onChange={this.props.onChange}
                onBlur={this.props.handleBlur}
                onSetClick={(checked) => {
                  this.props.onSetHighTotalNotional(checked);
                }}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

TotalNotionalLimit.propTypes = {
  lowTotalNotional: PropTypes.any,
  highTotalNotional: PropTypes.any,
  setLowTotalNotional: PropTypes.bool,
  setHighTotalNotional: PropTypes.bool,
  errors: PropTypes.any,
  touched: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSetLowTotalNotional: PropTypes.func.isRequired,
  onSetHighTotalNotional: PropTypes.func.isRequired
};

export default TotalNotionalLimit;