import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import {validateField, makeDropdownOptions, getOptionText, SELECTED_DEFAULT, NUMBER_TYPE, STRING_TYPE} from '../../instrumentFormUtils'
import DateGroup from "../../../core/form/DateGroup";
import { FormModes } from "../../../../constants/enumerations"

export const FixedLegInfoSps = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  errors,
  mode
}) => {

  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Fixed Leg Information</strong></h6>
          </Col>
          <Col>
          <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Fixed Payment Frequency" value={getOptionText(InstrumentStaticData.FixedPaymentFrequency, values.fixedPaymentFrequency)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
            <FieldGroupReadOnly label="Fixed Compounding Method" value={getOptionText(InstrumentStaticData.CompoundingOptions, values.fixedCompoundingMethod)} />

            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Fixed Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.fixedBusinessDayConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="fixedDayCountConvention" name="fixedDayCountConvention" label="Fixed Day Count Convention"
                value={values.fixedDayCountConvention}
                errors={errors}
                enabled={values.isPartialEditable}
                onChange={(e) => setFieldValue("fixedDayCountConvention", e.id)}
                data={InstrumentStaticData.DayCountConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="fixedRollConvention" name="fixedRollConvention" label="Fixed Roll Convention"
                value={values.fixedRollConvention || SELECTED_DEFAULT}
                errors={errors}
                enabled={values.isPartialEditable}
                onChange={(e) => setFieldValue("fixedRollConvention", e)}
                data={makeDropdownOptions(InstrumentStaticData.FixedRollConventionList, false)}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="fixedFirstUnadjustedPaymentDate" name="fixedFirstUnadjustedPaymentDate" label="Fixed First Unadjusted Payment Date"
                value={values.fixedFirstUnadjustedPaymentDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isPartialEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("fixedFirstUnadjustedPaymentDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Fixed Payment Date Relative To" value={getOptionText(InstrumentStaticData.DateRelativeToOptions, values.fixedPaymentDateRelativeTo)} />
            </Col>

          </Row>
        </div>
      </Collapse>


    </React.Fragment>
  )

}

export const ValidateFixedLegInfoSps = (values, errors) => {
  errors = validateField(values, errors, "fixedPaymentFrequency", "Fixed Payment Frequency", NUMBER_TYPE);
  errors = validateField(values, errors,  "fixedCompoundingMethod", "Fixed Method Compounding", NUMBER_TYPE);
  errors = validateField(values, errors,  "fixedRollConvention", "Fixed Roll Convention", STRING_TYPE);
  errors = validateField(values, errors,  "fixedDayCountConvention", "Day Count Convention", NUMBER_TYPE);
  errors = validateField(values, errors,  "fixedBusinessDayConvention", "Business Day Convention", NUMBER_TYPE);
  return validateField(values, errors,  "fixedPaymentDateRelativeTo", "Fixed Payment Date Relative To", NUMBER_TYPE);
}
