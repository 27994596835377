import { store } from '../store';
import confirm from "../modules/confirmDialog";
import { FormControl } from "react-bootstrap";
import Notification from "../modules/notifications";
import ParticipantService from "./ParticipantService";
import React from "react";

export const getAccessToken = () => {
  return store.getState().auth.accessToken;
}

export const getRefreshToken = () => {
  return store.getState().auth.refreshToken;
}

export const hasWriteAccess = () => {
  return store.getState().auth.hasWriteAccess;
}

export const onRevoke = (resourceName, isFirm) => {
  confirm(<FormControl readOnly={true} type="text" defaultValue={resourceName} placeholder="Token" />, {
    title: "Revoke Active Tokens",
    okButtonText: "Yes",
    cancelButtonText: "No"
  }).then(
    () => {
      const cb = (err, response) => {
        if (response) {
          Notification.success("Tokens Revoked");
        } else if (err) {
          Notification.error(`Cannot revoke tokens for id: ${resourceName}`);
        }
      }
      ParticipantService.revokeTokens(resourceName, isFirm, cb)
    },
    () => { }
  )
}

const emailToken = (userName, token) => {
  return `mailto:?subject=${userName} Registration Token&body=Welcome ${userName}! Please find your registration token below: \n ${token}`;
}

const linkStyle = {
  outline: "none",
  "text-decoration": "none",
  display: "inline-block",
  color: "white"
}

const iconStyle = {
  "color": "white",
  "font-size": "15px",
  "margin-left": "5px"
}

export const onGenerate = (userId, userName, isAdmin, ttlSeconds, singleUse, serviceKeyName, cbSecondary = null) => {
  const cb = (err, response) => {
    if (cbSecondary) cbSecondary(err, response);
    if (response) {
      const token = response.getToken();
      const tokenType = (singleUse ? "Single Use" : "Test") + " " + (isAdmin ? "Admin Token" : "API Token");
      confirm(<FormControl readOnly={true} type="text" defaultValue={token} placeholder="Token" />, {
        title: tokenType,
        okButtonText: document.queryCommandSupported('copy') ? <> Copy to Clipboard <i style={iconStyle} class="far fa-copy"></i></> : null,
        cancelButtonText: "Ok",
        additionalBtnText: <> <a style={linkStyle} href={emailToken(userName, token)}>Email <i style={iconStyle} class="far fa-envelope"></i></a></>,
      }).then(
        () => {
          const textField = document.createElement('textarea');
          textField.innerText = token;
          document.body.appendChild(textField);
          textField.select();
          document.execCommand('copy');
          textField.remove();
          Notification.success(tokenType + " copied to clipboard");
        },
        () => { }
      );
    }

    if (err) {
      Notification.error(`Cannot generate token for id: ${userId}`);
    }
  };
  return isAdmin ?
    ParticipantService.createAdminToken(userId, serviceKeyName, ttlSeconds, singleUse, cb) :
    ParticipantService.createAPIToken(userId, serviceKeyName, ttlSeconds, singleUse, cb);
};
