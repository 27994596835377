import {
  setDefaultAtts,
  setFRAAtts,
  setIRSAtts,
  setFutureAtts,
  setOptionAtts,
  setMultilegAtts,
  setBasisAtts,
  setFsIRSAtts,
  setUIEventAtts,
  setOISAtts,
  setSpsAtts,
  setNDFAtts,
  setForexAtts,
  setZeroCouponInflationSwapAtts
} from "../../modules/instrumentUtils";
import {
  FORWARD_RATE_AGREEMENT_SWAP, INTEREST_RATE_SWAP, FOREX,
  FUTURE, OPTION, MULTILEG, FORWARD_STARTING_INTEREST_RATE_SWAP,
  BASIS_SWAP, EVENT,ZERO_COUPON_INFLATION_SWAP, OVER_NIGHT_INDEX_SWAP, SINGLE_PERIOD_SWAP, NON_DELIVERABLE_FORWARDS
} from "../../constants/strings";

export default class ProductFormItem {
  constructor(item) {
    if (item) {
      this.name = item.name;
      this.description = item.description;
      this.externalSymbol = item.externalSymbol;
      this.cfiCode = item.cfiCode;
      this.instrumentProductType = item.instrumentProductType;
      this.clearingHouse = item.clearingHouse;
      this.minimumPriceIncrement = item.minimumPriceIncrement;
      this.minimumTradeQuantity = item.minimumTradeQuantity;
      this.fractionalQtyScale = item.fractionalQtyScale;
      this.minimumUnaffiliatedFirms = item.minimumUnaffiliatedFirms;
      this.crossOrderRestingDuration = item.crossOrderRestingDuration;
      this.blockTradeThreshold = item.blockTradeThreshold;
      this.setBlockTradeThreshold = !!item.blockTradeThreshold ? Object.keys(item.blockTradeThreshold).length > 0 : false;
      this.multiplier = item.multiplier;
      this.tradeSettlementPeriod = item.tradeSettlementPeriod;
      this.holidayCalendars = item.holidayCalendars;
      this.basecurrency = item.basecurrency;
      this.startDate = item.startDate;
      this.expirationDate = item.expirationDate;
      this.terminationDate = item.terminationDate;

      //SUNDAY IN BACKEND IS 7. IN UI IS 0.
      if (item.tradingSchedule) {
        for (let index = 0; index < item.tradingSchedule.length; index++) {
          let position = item.tradingSchedule[index].daysOfWeekList.indexOf(7);
          if (position > -1) {
            item.tradingSchedule[index].daysOfWeekList[position] = 0;
          }
        }
      }

      this.tradingScheduleList = item.tradingSchedule;
      this.tradeDayRollSchedule = item.tradeDayRollSchedule;
      this.categoryLevels = item.categoryLevels;

      this.setLowPriceLimit = item.setLowPriceLimit;
      this.setHighPriceLimit = item.setHighPriceLimit;
      this.lowPriceLimit = item.lowPriceLimit;
      this.highPriceLimit = item.highPriceLimit;

      this.lowOrderLimit = item.lowOrderLimit !== 0 ? item.lowOrderLimit : null;
      this.highOrderLimit = item.highOrderLimit !== 0 ? item.highOrderLimit : null;
      this.setLowOrderLimit = item.setLowOrderLimit;
      this.setHighOrderLimit = item.setHighOrderLimit;

      this.lowTotalNotionalLimit = item.lowTotalNotionalLimit !== 0 ? item.lowTotalNotionalLimit : null;
      this.highTotalNotionalLimit = item.highTotalNotionalLimit !== 0 ? item.highTotalNotionalLimit : null;
      this.setLowTotalNotionalLimit = item.setLowTotalNotionalLimit;
      this.setHighTotalNotionalLimit = item.setHighTotalNotionalLimit;
      this.skipRiskAndCurrencyChecks = item.skipRiskAndCurrencyChecks;

      this.relativeLow = item.relativeLow !== 0 ? item.relativeLow * 100 : null;
      this.relativeHigh = item.relativeHigh !== 0 ? item.relativeHigh * 100 : null;
      this.relativeLowSet = item.relativeLowSet;
      this.relativeHighSet = item.relativeHighSet;
      this.settlementPeriodStartOffsetFromCloseDuration = item.settlementPeriodStartOffsetFromCloseDuration;
      this.settlementPeriodEndOffsetFromCloseDuration   = item.settlementPeriodEndOffsetFromCloseDuration;
      this.minimumTradeQtyForVwapSettlementCalculation = item.minimumTradeQtyForVwapSettlementCalculation;
      this.bufferFromEndOfSettlementPeriodDuration = item.bufferFromEndOfSettlementPeriodDuration;
      this.settlementPriceLogic = item.settlementPriceLogic;
      this.settlementPriceCalculationRequiresApproval = item.settlementPriceCalculationRequiresApproval;
      this.settlementPriceCalculationEndingMethod = item.settlementPriceCalculationEndingMethod;

      if (item.type === INTEREST_RATE_SWAP) {
        setIRSAtts(item, this);
      } else if (item.type === FORWARD_RATE_AGREEMENT_SWAP) {
        setFRAAtts(item, this);
      } else if (item.type === FORWARD_STARTING_INTEREST_RATE_SWAP) {
        setFsIRSAtts(item, this);
      } else if (item.type === BASIS_SWAP) {
        setBasisAtts(item, this);
      } else if (item.type === ZERO_COUPON_INFLATION_SWAP) {
        setZeroCouponInflationSwapAtts(item, this);
      } else if (item.type === FUTURE) {
        setFutureAtts(item, this);
      } else if (item.type === OPTION) {
        setOptionAtts(item, this);
      } else if (item.type === FOREX) {
        setForexAtts(item, this);
      } else if (item.type === MULTILEG) {
        setMultilegAtts(item, this);
      } else if (item.type === EVENT) {
        setUIEventAtts(item, this);
      } else if (item.type === OVER_NIGHT_INDEX_SWAP) {
        setOISAtts(item, this);
      } else if (item.type === SINGLE_PERIOD_SWAP) {
        setSpsAtts(item, this);
      } else if (item.type === NON_DELIVERABLE_FORWARDS) {
        setNDFAtts(item, this);
      } else {
        setDefaultAtts(this);
      }

      return;
    }

    this.name = null;
    this.description = null;
    this.cfiCode = null;
    this.instrumentProductType = null;
    this.minimumPriceIncrement = null;
    this.minimumTradeQuantity = null;
    this.crossOrderRestingDuration = null;
    this.blockTradeThreshold = {};
    this.setBlockTradeThreshold = false;
    this.minimumUnaffiliatedFirms = null;
    this.multiplier = null;
    this.tradeSettlementPeriod = null;
    this.holidayCalendars = null;
    this.basecurrency = null;
    this.startDate = null;
    this.expirationDate = null;
    this.terminationDate = null;
    this.tradingScheduleList = null;
    this.relativeLow = null;
    this.relativeHigh = null;
    this.relativeLowSet = null;
    this.relativeHighSet = null;
    this.lowOrderLimit = null;
    this.highOrderLimit = null;
    this.setLowOrderLimit = null;
    this.setHighOrderLimit = null;
    this.lowTotalNotionalLimit = null;
    this.highTotalNotionalLimit = null;
    this.setLowTotalNotionalLimit = null;
    this.setHighTotalNotionalLimit = null;
    this.clearingHouse = null;
    this.settlementPeriodStartOffsetFromCloseDuration = null;
    this.settlementPeriodEndOffsetFromCloseDuration   = null;
    this.minimumTradeQtyForVwapSettlementCalculation = null;
    this.bufferFromEndOfSettlementPeriodDuration = null;
    this.settlementPriceLogic = null;
    this.settlementPriceCalculationRequiresApproval = false;
    this.settlementPriceCalculationEndingMethod = null;
  }
}
