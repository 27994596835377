import React, { useState } from "react";
import { connect } from "react-redux";
import Collapse from 'react-bootstrap/Collapse'
import { Row, Col } from "react-bootstrap";
import DateGroup from "../../../core/form/DateGroup";
import DateTimeParser from "../../../../modules/dateTimeParser";
import FieldGroup from "../../../core/form/FieldGroup";
import MultiSelectDropdownListGroup from "../../../core/form/MultiSelectDropdownListGroup"
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import ButtonMain from "../../../core/form/ButtonMain";
import { createIRSAtts, setIRSAtts } from "../../../../modules/instrumentUtils";
import { makeDropdownOptions, SELECTED_DEFAULT } from "../../instrumentFormUtils";
import DateAdjustmentService from '../../../../services/DateAdjustmentService';
import { FormModes } from "../../../../constants/enumerations"
import _ from 'lodash';

const DateCalculatorIrs = ({
  values,
  isEditing,
  touched,
  setFieldValue,
  setFieldError,
  handleBlur,
  handleChange,
  enableStartDate,
  calculateDates,
  errors,
  mode
}) => {
  const onDateCalculator = (values, setFieldValue, setFieldError, calculateDates) => {

    let dateCalculatorValues = {
      startDate: values.startDate,
      effectiveDateOffset: values.effectiveDateOffset,
      rollAndPaymentDateHolidayCalendarsList: values.rollAndPaymentDateHolidayCalendarsList,
      tenor: values.tenor,
      effectiveDateBusinessDayConvention: values.effectiveDateBusinessDayConvention,
      maturityDateBusinessDayConvention: values.maturityDateBusinessDayConvention,

      fixedPaymentFrequency: values.fixedPaymentFrequency,
      fixedBusinessDayConvention: values.fixedBusinessDayConvention,
      fixedPaymentDateRelativeTo: values.fixedPaymentDateRelativeTo,

      floatPaymentFrequency: values.floatPaymentFrequency,
      floatRateResetFrequency: values.floatRateResetFrequency,
      floatResetDateBusinessDayConvention: values.floatResetDateBusinessDayConvention,
      floatBusinessDayConvention: values.floatBusinessDayConvention,
      floatCompoundingMethod: values.floatCompoundingMethod,
      floatHolidayCalendarsList: values.floatHolidayCalendarsList,
      floatFixingDatesOffset: values.floatFixingDatesOffset,
      floatResetDateRelativeTo: values.floatResetDateRelativeTo,
      floatFixingDateBusinessDayConvention: values.floatFixingDateBusinessDayConvention,
      floatFixingDatesDayType: values.floatFixingDatesDayType,
      floatPaymentDateRelativeTo: values.floatPaymentDateRelativeTo,
      floatFixingDateHolidayCalendarsList: values.floatFixingDateHolidayCalendarsList,
    }

    const successHandler = (attributes) => {
      let atts = {};

      setIRSAtts(attributes, atts);

      _.map(atts, (val, key) => {
        setFieldValue(key, val);
      });
    }

    const handleError = err => {
      setFieldError('dateCalculator', err);
    }

    if (dateCalculatorValues.startDate) {
      let atts = createIRSAtts(dateCalculatorValues);
      calculateDates(handleError, successHandler, atts, dateCalculatorValues.startDate);
    } else {
      setFieldError('dateCalculator', "Trade Date missing");
    }
  }

  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;


  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Date Calculator</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>

      <Collapse in={open}>
        <div className="collapse-content">
          <h6>Swap Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="startDate" name="startDate" label="Trade date" isRequired={true}
                value={values.startDate ? new Date(values.startDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(values.isPartialEditable, isEditing, values.startDate)}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue('startDate', e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="effectiveDateOffset" name="effectiveDateOffset" type="textarea" label="Swap Effective Date Offset" isRequired={true}
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.effectiveDateOffset}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup id="rollAndPaymentDateHolidayCalendarsList" name="rollAndPaymentDateHolidayCalendarsList" label="Roll and Payment Date Holiday Calendar List"
                labelWrapperClass="label-wrapper"
                value={values.rollAndPaymentDateHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("rollAndPaymentDateHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"} valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="tenor" name="tenor" type="textarea" label="Tenor" isRequired={true}
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.tenor}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="effectiveDateBusinessDayConvention" name="effectiveDateBusinessDayConvention" label="Effective Date Business Day Convention"
                value={values.effectiveDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("effectiveDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="maturityDateBusinessDayConvention" name="maturityDateBusinessDayConvention" label="Maturity Date Business Day Convention"
                value={values.maturityDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("maturityDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <hr />
          <h6>Fixed Leg Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="fixedPaymentFrequency" name="fixedPaymentFrequency" label="Payment Frequency" isRequired={true}
                value={values.fixedPaymentFrequency || SELECTED_DEFAULT}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("fixedPaymentFrequency", e.id)}
                data={makeDropdownOptions(InstrumentStaticData.FixedPaymentFrequency, false, true)}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="fixedBusinessDayConvention" name="fixedBusinessDayConvention" label="Business Day Convention" isRequired={true}
                value={values.fixedBusinessDayConvention}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("fixedBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="fixedPaymentDateRelativeTo" name="fixedPaymentDateRelativeTo" label="Fixed Payment Date Relative To"
                value={values.fixedPaymentDateRelativeTo}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("fixedPaymentDateRelativeTo", e.id)}
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>
          <hr />
          <h6>Float Leg Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatPaymentFrequency" name="floatPaymentFrequency" label="Payment Frequency" isRequired={true}
                value={values.floatPaymentFrequency || SELECTED_DEFAULT}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatPaymentFrequency", e.id)}
                data={makeDropdownOptions(InstrumentStaticData.FloatPaymentFrequency, false, true)}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatRateResetFrequency" name="floatRateResetFrequency" label="Rate Reset Frequency" isRequired={true}
                value={values.floatRateResetFrequency || SELECTED_DEFAULT}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatRateResetFrequency", e.id)}
                data={makeDropdownOptions(InstrumentStaticData.FloatRateResetFrequency, false, true)}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatResetDateBusinessDayConvention" name="floatResetDateBusinessDayConvention" label="Reset Date Business Day Convention"
                value={values.floatResetDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatResetDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatBusinessDayConvention" name="floatBusinessDayConvention" label="Float Business Day Convention"
                value={values.floatBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatCompoundingMethod" name="floatCompoundingMethod" label="Compounding Method" isRequired={true}
                value={values.floatCompoundingMethod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatCompoundingMethod", e.id)}
                data={InstrumentStaticData.CompoundingOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup id="floatHolidayCalendarsList" name="floatHolidayCalendarsList" label="Float Holiday Calendar List"
                labelWrapperClass="label-wrapper"
                value={values.floatHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"} valueField={"value"}
                plaintext={isViewing}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="floatFixingDatesOffset" name="floatFixingDatesOffset" type="textarea" label="Float Fixing Dates Offset" isRequired={true}
                disabled={!values.isEditable}
                value={values.floatFixingDatesOffset}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatResetDateRelativeTo" name="floatResetDateRelativeTo" label="Float Reset Date Relative To"
                value={values.floatResetDateRelativeTo}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatResetDateRelativeTo", e.id)}
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatFixingDateBusinessDayConvention" name="floatFixingDateBusinessDayConvention" label="Fixing Date Business Day Convention"
                value={values.floatFixingDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatFixingDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatFixingDatesDayType" name="floatFixingDatesDayType" label="Float Fixing Dates Day Type"
                value={values.floatFixingDatesDayType}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatFixingDatesDayType", e.id)}
                data={InstrumentStaticData.FixingDatesDayType}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatPaymentDateRelativeTo" name="floatPaymentDateRelativeTo" label="Float Payment Date Relative To"
                value={values.floatPaymentDateRelativeTo}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("floatPaymentDateRelativeTo", e.id)}
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup id="floatFixingDateHolidayCalendarsList" name="floatFixingDateHolidayCalendarsList" label="Float Fixing Date Holiday Calendar List"
                labelWrapperClass="label-wrapper"
                value={values.floatFixingDateHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatFixingDateHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"} valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          {!isViewing &&
            <Row>
              <Col lg={9} xs={9} md={3}></Col>
              <Col lg={3} xs={3} md={3}>
                <ButtonMain type="button" text="Calculate Dates" onClick={() => onDateCalculator(values, setFieldValue, setFieldError, calculateDates)}
                />
                {errors && errors.dateCalculator && (
                  <div className="form-error mt-2">
                    <i className="fa fa-exclamation-triangle orange-icon" aria-hidden="true"></i>{" "}
                    {errors.dateCalculator}
                  </div>
                )}
              </Col>
            </Row>
          }
        </div>
      </Collapse>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) => ({
  calculateDates: (handleError, successHandler, atts, startDate) => {
    dispatch(DateAdjustmentService.adjustDates(handleError, successHandler, atts, startDate));
  }
});

export default connect(null, mapDispatchToProps)(DateCalculatorIrs);

export const ValidateDateCalculatorIrs = (values, errors) => {
  if (!values.unadjustedEffectiveDate || !values.unadjustedMaturityDate || !values.fixedFirstUnadjustedPaymentDate || !values.floatFirstUnadjustedPaymentDate) {
    errors["dateCalculator"] = "You must calculate dates";
  }
  return errors;
}