import React, { Component } from "react";
import { connect } from "react-redux";
import UsersDataGrid from "../users/UsersDataGrid";
import confirm from "../../modules/confirmDialog";
import ParticipantService from "../../services/ParticipantService";
import PropTypes from "prop-types";
import Notification from "../../modules/notifications";

function mapStateToProps(state) {
  return {
    fetchingParticipant: state.participants.fetchingParticipant,
    participants: state.participants.participants,
    firm: state.firms.firm,
  };
}

export class FirmUsersGrid extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    let { decodedId, loadFirm, loadParticipants } = this.props;
    loadFirm(decodedId);
    loadParticipants();
  }

  onView = (name) => {
    this.context.router.history.push(`/${name}`);
  };

  onEdit = (name) => {
    this.context.router.history.push(`/${name}/edit`);
  };

  onRemove = (username) => {
    confirm(<div>Are you sure you wish to remove this user?</div>, {
      title: "User Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            Notification.success("User removed.");
            this.props.loadParticipants();
          }

          if (err) {
            Notification.error("Cannot remove user.");
          }
        };
        ParticipantService.delete(username, cb);
      },
      () => {}
    );
  };

  render() {
    let { participants, firm, decodedId } = this.props;
    return (
      <UsersDataGrid
        onView={(name) => this.onView(name)}
        onEdit={(name) => this.onEdit(name)}
        onRemove={(name) => {
          this.onRemove(name);
        }}
        firm={decodedId}
        data={participants
          .filter((participant) => participant.firm === firm.name)
          .map((participant) => Object.assign({}, participant, { firm: firm }))}
      />
    );
  }
}

export default connect(mapStateToProps, null)(FirmUsersGrid);
