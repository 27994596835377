

import React, { Component } from 'react';
import ProductDataGrid from '../components/product/ProductDataGrid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchProducts,
  CleanProduct
} from '../actions/products';
import './Content.css';
import InstrumentHeader from '../components/instrument/InstrumentHeader';
import Loader from '../components/core/loader/Loader';
import { AdminDocumentTitle } from '../constants/strings';
import { fetchMetadata } from "../actions/instruments";
import { hasWriteAccess } from "../services/TokenService";
import { LoadFirms } from '../actions/firms';
import { NavigationManager } from '../components/core/helpers/NavigationHelper';

function mapStateToProps(state) {
  return {
    fetchingProducts: state.fetchingProducts,
    products: state.products.products.map(i => {
      var firm = state.firms.firms.find(f => f.name === i.clearingHouse)
      i.clearingHouse = firm ? firm : i.clearingHouse
      return i
    }),
  };
}

const mapDispatchToProps = (dispatch) => ({
  cleanProduct: () => {
    dispatch(CleanProduct())
  },
  fetchProducts: () => {
    dispatch(fetchProducts())
  },
  fetchMetadata: () => {
    dispatch(fetchMetadata())
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
})

class Products extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.props.fetchProducts();
    this.props.fetchMetadata();
    this.props.loadFirms();
    window.scrollTo(0, 0);
  }

  openViewProduct = (productId, actionContext) => {
    this.props.cleanProduct();

    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/products/${encodeURIComponent(productId)}`);
    }
    else {
      this.context.router.history.push(`/products/${encodeURIComponent(productId)}`);
    }
  }

  openEditProduct = (productId, actionContext) => {
    this.props.cleanProduct();

    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/products/${encodeURIComponent(productId)}/edit`);
    }
    else {
      this.context.router.history.push(`/products/${encodeURIComponent(productId)}/edit`);
    }
  }

  openCopyProduct = (productId, actionContext) => {
    this.props.cleanProduct();

    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/products/${encodeURIComponent(productId)}/copy`);
    }
    else {
      this.context.router.history.push(`/products/${encodeURIComponent(productId)}/copy`);
    }
  }

  openCreateProduct = () => {
    this.props.cleanProduct();
    this.context.router.history.push(`/products/new`);
  }

  render() {
    return (
      <div className="with-callback">
        <InstrumentHeader
          buttonText="ADD NEW PRODUCT"
          buttonClick={() => { this.openCreateProduct() }}
          isProductsSelected={true}
          isAddButtonVisible={hasWriteAccess()}
        />
        <Loader show={this.props.fetchingProducts}></Loader>
        <ProductDataGrid data={this.props.products}
          onView={(productId, actionContext) => { this.openViewProduct(productId, actionContext) }}
          onEdit={(productId, actionContext) => { this.openEditProduct(productId, actionContext) }}
          onCopy={(productId, actionContext) => { this.openCopyProduct(productId, actionContext) }}>
        </ProductDataGrid>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);