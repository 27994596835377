export const FETCH_PARTICIPANT_GROUPS_START = 'FETCH_PARTICIPANT_GROUPS_START';
export const FETCH_PARTICIPANT_GROUPS_SUCCEED = 'FETCH_PARTICIPANT_GROUPS_SUCCEED';
export const FETCH_PARTICIPANT_GROUPS_FAILED = 'FETCH_PARTICIPANT_GROUPS_FAILED';
export const FETCH_PARTICIPANT_GROUP_START = 'FETCH_PARTICIPANT_GROUP_START';
export const FETCH_PARTICIPANT_GROUP_SUCCEED = 'FETCH_PARTICIPANT_GROUP_SUCCEED';
export const FETCH_PARTICIPANT_GROUP_FAILED = 'FETCH_PARTICIPANT_GROUP_FAILED';
export const CLEAN_PARTICIPANT_GROUP = 'CLEAN_PARTICIPANT_GROUP';
export const SAVE_PARTICIPANT_GROUP_START = 'SAVE_PARTICIPANT_GROUP_START';
export const SAVE_PARTICIPANT_GROUP_SUCCEED = 'SAVE_PARTICIPANT_GROUP_SUCCEED';
export const SAVE_PARTICIPANT_GROUP_FAILED = 'SAVE_PARTICIPANT_GROUP_FAILED';
export const CREATE_PARTICIPANT_GROUP_STATE = 'CREATE_PARTICIPANT_GROUP_STATE';
export const FETCH_PARTICIPANTS_LIST_START = 'FETCH_PARTICIPANTS_LIST_START';
export const FETCH_PARTICIPANTS_LIST_SUCCEED = 'FETCH_PARTICIPANTS_LIST_SUCCEED';
export const FETCH_PARTICIPANTS_LIST_FAILED = 'FETCH_PARTICIPANTS_LIST_FAILED';
export const CLEAN_PARTICIPANTS_LIST_GROUP = 'CLEAN_PARTICIPANTS_LIST_GROUP';