import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { DropdownList } from "react-widgets";
import { v4 } from "uuid";
import Tooltip from '../core/tooltip-html/tooltip-html';

export default function Aliases(props) {
  const onChange = props.onChange;
  const options = props.options;
  const isReadonly = props.readOnly;

  const [isOpen, setOpen] = useState(isReadonly);
  const [aliases, setAlias] = useState([]);
  const [validationErr, setValidationErr] = useState("");

  useEffect(() => {
    setAlias(prepareAliasState(props.aliases));
  }, [props.aliases])

  useEffect(() => {
    setValidationErr(validateAliases(aliases));
  }, [aliases])

  const newAlias = () => {
    setAlias(aliases => [...aliases, { id: v4(), option: "", alias: "" }]);
  }

  const prepareAliasState = (propsAliases) => {
    let lst = [];
    if (!!propsAliases && Array.isArray(propsAliases)) {
      propsAliases.forEach(arrItem => {
        lst.push({ id: v4(), option: arrItem.option, alias: arrItem.alias })
      })
    }

    return lst;
  }

  const updateAlias = (id, option, alias) => {
    let element = aliases.find((elm) => elm.id === id);
    if (!!element) {
      element.option = option;
      element.alias = alias;
    }

    setAlias([...aliases]);
    notifyChangeAlias(aliases);
  }

  const deleteAlias = (id) => {
    let idx = aliases.findIndex((elm) => elm.id === id);
    if (idx >= 0) {
      aliases.splice(idx, 1);
    }

    setAlias([...aliases]);
    notifyChangeAlias(aliases);
  }

  const notifyChangeAlias = () => {
    // Do not notify if there is any error.
    if (validateAliases(aliases).length > 0) {
      return;
    }

    if (onChange) {
      onChange(aliases);
    }
  }

  const validateAliases = (aliases) => {
    for (const alias of aliases) {
      if (alias.option === "" || alias.alias.trim() === "") {
        return "Option or Alias cannot be left empty";
      }

      if (aliases.filter((itm) => itm.option === alias.option).length > 1) {
        return `Duplicate Option ${alias.option}`;
      }
    }

    return "";
  }

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!isOpen)} className="alias-header" id='alias'>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6>
              <strong>Aliases</strong>
              {!!props.tooltip && <Tooltip text={props.tooltip} id={`tip-alias`} />}
            </h6>
          </Col>
          <Col>
            <i
              className={`fa fa-fw ${isOpen ? "fa-minus" : "fa-plus"
                } custom-plus`}
            />
          </Col>
        </Row>
      </div>

      <div className="account-alias">
        <Collapse in={isOpen}>
          <div className="collapse-content">
            {!!aliases && Array.isArray(aliases) && aliases.length > 0 && <Row key="header-row" className="form-group">
              <Col lg={3} xs={3} md={3}>
                <span className="header-alias">Option</span>
              </Col>
              <Col>
                <span className="header-alias">Alias</span>
              </Col>
            </Row>
            }

            {aliases.map((alias, index) => {
              return (<Row key={alias.id} className="form-group">
                <Col lg={3} xs={6} md={6} >
                  {!isReadonly && <DropdownList name="option" data={options} value={alias.option} onChange={(option) => { updateAlias(alias.id, option, alias.alias) }} />}
                  {isReadonly && <div class="form-readonly-label">{alias.option}</div>}
                </Col>
                <Col lg={3} xs={6} md={6} >
                  {!isReadonly && <input type="text" className="form-control" value={alias.alias} onChange={(event) => { updateAlias(alias.id, alias.option, event.target.value) }} />}
                  {isReadonly && <div class="form-readonly-label">{alias.alias}</div>}
                </Col>
                {!isReadonly && <Col lg={1} xs={6} md={6}><Button onClick={(evt) => { deleteAlias(alias.id); }}>Delete</Button></Col>}
              </Row>)
            })}

            {!!validationErr && <Row>
              <Col lg={6} xs={6} md={12}>
                <div class="form-error">{validationErr}</div>
              </Col>
            </Row>}

            {!isReadonly && <Row>
              <Col lg={6} xs={6} md={12}>
                <button
                  type="button"
                  className="btn-add-alias"
                  onClick={() => { newAlias() }}
                >
                  Add Alias
                </button>
              </Col>
            </Row>}
          </div>
        </Collapse >
      </div>
    </React.Fragment >
  );
}
