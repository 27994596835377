import {
  convertScaledPriceToInt,
  countDecimals,
  isNullOrUndefined,
  parsePrice,
  numberOrDefault
} from "../modules/util";
import {
  INTEREST_RATE_SWAP,
  FORWARD_RATE_AGREEMENT_SWAP,
  FUTURE,
  OPTION,
  DEFAULT,
  MULTILEG,
  FORWARD_STARTING_INTEREST_RATE_SWAP,
  BASIS_SWAP,
  EVENT,
  OVER_NIGHT_INDEX_SWAP,
  SINGLE_PERIOD_SWAP,
  NON_DELIVERABLE_FORWARDS,
  FOREX,
  ZERO_COUPON_INFLATION_SWAP
} from "../constants/strings";
import { dateToProtoDate, dateToProtoTimeOfDay } from "../actions/ptypes";
import { isArray } from "lodash";

const {
  InterestRateSwapAttributes,
  ForwardRateAgreementSwapAttributes,
  ForwardStartingInterestRateSwapAttributes,
  BasisSwapAttributes,
  OvernightIndexSwapAttributes,
  SinglePeriodSwapAttributes,
  NonDeliverableForwardAttributes,
  ZeroCouponInflationSwapAttributes
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/swaps_pb.js");
const {
  FutureAttributes,
  OptionAttributes,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/derivatives_pb.js");
const {
  ForexAttributes,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/forex_pb.js");
const {
  MultilegAttributes,
  LegAttributes,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/multileg_pb.js");
const {
  EventAttributes,
  PayoutType
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/event_pb.js");
const {
  HedgeMarket,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/hedger/v1beta1/hedger_pb.js");
const { Date: ProtoDate } = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/type/v1beta1/type_pb.js');

export const createIRSAtts = (data) => {
  let irsAtts = new InterestRateSwapAttributes();

  /* Swap Information Attributes */
  if (!isNullOrUndefined(data.tenor)) {
    irsAtts.setTenor(data.tenor);
  }

  if (!isNullOrUndefined(data.sideConvention)) {
    irsAtts.setSideConvention(data.sideConvention);
  }

  if (!isNullOrUndefined(data.rollAndPaymentDateHolidayCalendarsList)) {
    irsAtts.setRollAndPaymentDateHolidayCalendarsList(
      Array.isArray(data.rollAndPaymentDateHolidayCalendarsList)
        ? data.rollAndPaymentDateHolidayCalendarsList
        : [data.rollAndPaymentDateHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.effectiveDateBusinessDayConvention)) {
    irsAtts.setEffectiveDateBusinessDayConvention(
      data.effectiveDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.maturityDateBusinessDayConvention)) {
    irsAtts.setMaturityDateBusinessDayConvention(
      data.maturityDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.swapEffectiveDate)) {
    irsAtts.setSwapEffectiveDate(dateToProtoDate(data.swapEffectiveDate));
  }
  if (!isNullOrUndefined(data.maturityDate)) {
    irsAtts.setMaturityDate(dateToProtoDate(data.maturityDate));
  }
  if (!isNullOrUndefined(data.effectiveDateOffset)) {
    irsAtts.setEffectiveDateOffset(data.effectiveDateOffset);
  }
  if (!isNullOrUndefined(data.unadjustedEffectiveDate)) {
    irsAtts.setUnadjustedEffectiveDate(
      dateToProtoDate(data.unadjustedEffectiveDate)
    );
  }
  if (!isNullOrUndefined(data.unadjustedMaturityDate)) {
    irsAtts.setUnadjustedMaturityDate(
      dateToProtoDate(data.unadjustedMaturityDate)
    );
  }

  /* Fixed Leg Attributes */
  if (!isNullOrUndefined(data.fixedPaymentFrequency)) {
    irsAtts.setFixedPaymentFrequency(data.fixedPaymentFrequency);
  }
  if (!isNullOrUndefined(data.fixedCompoundingMethod)) {
    irsAtts.setFixedCompoundingMethod(data.fixedCompoundingMethod);
  }
  if (!isNullOrUndefined(data.fixedRollConvention)) {
    irsAtts.setFixedRollConvention(data.fixedRollConvention);
  }
  if (!isNullOrUndefined(data.fixedDayCountConvention)) {
    irsAtts.setFixedDayCountConvention(data.fixedDayCountConvention);
  }
  if (!isNullOrUndefined(data.fixedBusinessDayConvention)) {
    irsAtts.setFixedBusinessDayConvention(data.fixedBusinessDayConvention);
  }
  if (!isNullOrUndefined(data.fixedPaymentDateRelativeTo)) {
    irsAtts.setFixedPaymentDateRelativeTo(data.fixedPaymentDateRelativeTo);
  }
  if (!isNullOrUndefined(data.fixedFirstUnadjustedPaymentDate)) {
    irsAtts.setFixedFirstUnadjustedPaymentDate(
      dateToProtoDate(data.fixedFirstUnadjustedPaymentDate)
    );
  }

  /* Floating Leg Attributes */
  if (!isNullOrUndefined(data.floatingRateIndex)) {
    irsAtts.setFloatingRateIndex(data.floatingRateIndex);
  }
  if (!isNullOrUndefined(data.floatHolidayCalendarsList)) {
    irsAtts.setFloatHolidayCalendarsList(
      Array.isArray(data.floatHolidayCalendarsList)
        ? data.floatHolidayCalendarsList
        : [data.floatHolidayCalendarsList]
    );
  }
  if (!isNullOrUndefined(data.floatDayCountConvention)) {
    irsAtts.setFloatDayCountConvention(data.floatDayCountConvention);
  }
  if (!isNullOrUndefined(data.firstFloatFixingDate)) {
    irsAtts.setFirstFloatFixingDate(dateToProtoDate(data.firstFloatFixingDate));
  }

  if (!isNullOrUndefined(data.floatPaymentFrequency)) {
    irsAtts.setFloatPaymentFrequency(data.floatPaymentFrequency);
  }
  if (!isNullOrUndefined(data.floatRateResetFrequency)) {
    irsAtts.setFloatRateResetFrequency(data.floatRateResetFrequency);
  }
  if (!isNullOrUndefined(data.floatResetDateBusinessDayConvention)) {
    irsAtts.setFloatResetDateBusinessDayConvention(
      data.floatResetDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.floatBusinessDayConvention)) {
    irsAtts.setFloatBusinessDayConvention(data.floatBusinessDayConvention);
  }

  if (!isNullOrUndefined(data.floatCompoundingMethod)) {
    irsAtts.setFloatCompoundingMethod(data.floatCompoundingMethod);
  }
  if (!isNullOrUndefined(data.firstFloatResetDate)) {
    irsAtts.setFirstFloatResetDate(dateToProtoDate(data.firstFloatResetDate));
  }
  if (!isNullOrUndefined(data.floatFixingDatesOffset)) {
    irsAtts.setFloatFixingDatesOffset(data.floatFixingDatesOffset);
  }
  if (!isNullOrUndefined(data.floatResetDateRelativeTo)) {
    irsAtts.setFloatResetDateRelativeTo(data.floatResetDateRelativeTo);
  }

  if (!isNullOrUndefined(data.floatRollConvention)) {
    irsAtts.setFloatRollConvention(data.floatRollConvention);
  }
  if (!isNullOrUndefined(data.floatFirstUnadjustedPaymentDate)) {
    irsAtts.setFloatFirstUnadjustedPaymentDate(
      dateToProtoDate(data.floatFirstUnadjustedPaymentDate)
    );
  }
  if (!isNullOrUndefined(data.floatFixingDateBusinessDayConvention)) {
    irsAtts.setFloatFixingDateBusinessDayConvention(
      data.floatFixingDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.floatPaymentDateRelativeTo)) {
    irsAtts.setFloatPaymentDateRelativeTo(data.floatPaymentDateRelativeTo);
  }
  if (!isNullOrUndefined(data.floatFixingDatesDayType)) {
    irsAtts.setFloatFixingDatesDayType(data.floatFixingDatesDayType);
  }
  if (!isNullOrUndefined(data.floatingRateIndexTenor)) {
    irsAtts.setFloatingRateIndexTenor(data.floatingRateIndexTenor);
  }
  if (!isNullOrUndefined(data.floatFixingDateHolidayCalendarsList)) {
    irsAtts.setFloatFixingDateHolidayCalendarsList(
      Array.isArray(data.floatFixingDateHolidayCalendarsList)
        ? data.floatFixingDateHolidayCalendarsList
        : [data.floatFixingDateHolidayCalendarsList]
    );
  }
  return irsAtts;
};

export const setIRSAtts = (source, destination) => {
  destination.type = INTEREST_RATE_SWAP;

  if (!isNullOrUndefined(source.tenor)) {
    destination.tenor = source.tenor;
  }

  if (!isNullOrUndefined(source.maturityDateBusinessDayConvention)) {
    destination.maturityDateBusinessDayConvention =
      source.maturityDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.rollAndPaymentDateHolidayCalendarsList)) {
    destination.rollAndPaymentDateHolidayCalendarsList =
      source.rollAndPaymentDateHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.effectiveDateBusinessDayConvention)) {
    destination.effectiveDateBusinessDayConvention =
      source.effectiveDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.sideConvention)) {
    destination.sideConvention = source.sideConvention;
  }

  if (!isNullOrUndefined(source.swapEffectiveDate)) {
    destination.swapEffectiveDate = getDate(source.swapEffectiveDate);
  }

  if (!isNullOrUndefined(source.maturityDate)) {
    destination.maturityDate = getDate(source.maturityDate);
  }

  if (!isNullOrUndefined(source.effectiveDateOffset)) {
    destination.effectiveDateOffset = source.effectiveDateOffset;
  }

  if (!isNullOrUndefined(source.unadjustedEffectiveDate)) {
    destination.unadjustedEffectiveDate = getDate(
      source.unadjustedEffectiveDate
    );
  }

  if (!isNullOrUndefined(source.unadjustedMaturityDate)) {
    destination.unadjustedMaturityDate = getDate(source.unadjustedMaturityDate);
  }

  //
  // Fixed Leg Attributes
  //
  if (!isNullOrUndefined(source.fixedPaymentFrequency)) {
    destination.fixedPaymentFrequency = source.fixedPaymentFrequency;
  }

  if (!isNullOrUndefined(source.fixedCompoundingMethod)) {
    destination.fixedCompoundingMethod = source.fixedCompoundingMethod;
  }

  if (!isNullOrUndefined(source.fixedRollConvention)) {
    destination.fixedRollConvention = source.fixedRollConvention;
  }

  if (!isNullOrUndefined(source.fixedDayCountConvention)) {
    destination.fixedDayCountConvention = source.fixedDayCountConvention;
  }

  if (!isNullOrUndefined(source.fixedBusinessDayConvention)) {
    destination.fixedBusinessDayConvention = source.fixedBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.fixedPaymentDateRelativeTo)) {
    destination.fixedPaymentDateRelativeTo = source.fixedPaymentDateRelativeTo;
  }

  if (!isNullOrUndefined(source.fixedFirstUnadjustedPaymentDate)) {
    destination.fixedFirstUnadjustedPaymentDate = getDate(
      source.fixedFirstUnadjustedPaymentDate
    );
  }

  //
  // Floating Leg Attributes
  //
  if (!isNullOrUndefined(source.floatingRateIndex)) {
    destination.floatingRateIndex = source.floatingRateIndex;
  }

  if (!isNullOrUndefined(source.floatHolidayCalendarsList)) {
    destination.floatHolidayCalendarsList = source.floatHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.floatDayCountConvention)) {
    destination.floatDayCountConvention = source.floatDayCountConvention;
  }

  if (!isNullOrUndefined(source.firstFloatFixingDate)) {
    destination.firstFloatFixingDate = getDate(source.firstFloatFixingDate);
  }

  if (!isNullOrUndefined(source.floatPaymentFrequency)) {
    destination.floatPaymentFrequency = source.floatPaymentFrequency;
  }

  if (!isNullOrUndefined(source.floatRateResetFrequency)) {
    destination.floatRateResetFrequency = source.floatRateResetFrequency;
  }

  if (!isNullOrUndefined(source.floatResetDateBusinessDayConvention)) {
    destination.floatResetDateBusinessDayConvention =
      source.floatResetDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatBusinessDayConvention)) {
    destination.floatBusinessDayConvention = source.floatBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatCompoundingMethod)) {
    destination.floatCompoundingMethod = source.floatCompoundingMethod;
  }

  if (!isNullOrUndefined(source.firstFloatResetDate)) {
    destination.firstFloatResetDate = getDate(source.firstFloatResetDate);
  }

  if (!isNullOrUndefined(source.floatFixingDatesOffset)) {
    destination.floatFixingDatesOffset = source.floatFixingDatesOffset;
  }

  if (!isNullOrUndefined(source.floatResetDateRelativeTo)) {
    destination.floatResetDateRelativeTo = source.floatResetDateRelativeTo;
  }

  if (!isNullOrUndefined(source.floatRollConvention)) {
    destination.floatRollConvention = source.floatRollConvention;
  }

  if (!isNullOrUndefined(source.floatFirstUnadjustedPaymentDate)) {
    destination.floatFirstUnadjustedPaymentDate = getDate(
      source.floatFirstUnadjustedPaymentDate
    );
  }

  if (!isNullOrUndefined(source.floatFixingDateBusinessDayConvention)) {
    destination.floatFixingDateBusinessDayConvention =
      source.floatFixingDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatPaymentDateRelativeTo)) {
    destination.floatPaymentDateRelativeTo = source.floatPaymentDateRelativeTo;
  }

  if (!isNullOrUndefined(source.floatFixingDatesDayType)) {
    destination.floatFixingDatesDayType = source.floatFixingDatesDayType;
  }

  if (!isNullOrUndefined(source.floatingRateIndexTenor)) {
    destination.floatingRateIndexTenor = source.floatingRateIndexTenor;
  }

  if (!isNullOrUndefined(source.floatFixingDateHolidayCalendarsList)) {
    destination.floatFixingDateHolidayCalendarsList =
      source.floatFixingDateHolidayCalendarsList;
  }
};

export const createFRAAtts = (data) => {
  let atts = new ForwardRateAgreementSwapAttributes();

  /* Swap Information Attributes */
  if (!isNullOrUndefined(data.tenor)) {
    atts.setTenor(data.tenor);
  }

  if (!isNullOrUndefined(data.sideConvention)) {
    atts.setSideConvention(data.sideConvention);
  }

  if (!isNullOrUndefined(data.rollAndPaymentDateHolidayCalendarsList)) {
    atts.setRollAndPaymentDateHolidayCalendarsList(
      Array.isArray(data.rollAndPaymentDateHolidayCalendarsList)
        ? data.rollAndPaymentDateHolidayCalendarsList
        : [data.rollAndPaymentDateHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.effectiveDateBusinessDayConvention)) {
    atts.setEffectiveDateBusinessDayConvention(
      data.effectiveDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.maturityDateBusinessDayConvention)) {
    atts.setMaturityDateBusinessDayConvention(
      data.maturityDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.swapEffectiveDate)) {
    atts.setSwapEffectiveDate(dateToProtoDate(data.swapEffectiveDate));
  }

  if (!isNullOrUndefined(data.maturityDate)) {
    atts.setMaturityDate(dateToProtoDate(data.maturityDate));
  }

  if (!isNullOrUndefined(data.unadjustedEffectiveDate)) {
    atts.setUnadjustedEffectiveDate(
      dateToProtoDate(data.unadjustedEffectiveDate)
    );
  }

  if (!isNullOrUndefined(data.unadjustedMaturityDate)) {
    atts.setUnadjustedMaturityDate(
      dateToProtoDate(data.unadjustedMaturityDate)
    );
  }

  if (!isNullOrUndefined(data.calculationPeriodNumberOfDays)) {
    atts.setCalculationPeriodNumberOfDays(data.calculationPeriodNumberOfDays);
  }

  if (!isNullOrUndefined(data.forwardStartingPeriod)) {
    atts.setForwardStartingPeriod(data.forwardStartingPeriod);
  }

  /* Fixed Leg Attributes */

  if (!isNullOrUndefined(data.fixedDayCountConvention)) {
    atts.setFixedDayCountConvention(data.fixedDayCountConvention);
  }

  if (!isNullOrUndefined(data.fixedBusinessDayConvention)) {
    atts.setFixedBusinessDayConvention(data.fixedBusinessDayConvention);
  }

  if (!isNullOrUndefined(data.fixedFixingDateHolidayCalendarsList)) {
    atts.setFixedFixingDateHolidayCalendarsList(
      Array.isArray(data.fixedFixingDateHolidayCalendarsList)
        ? data.fixedFixingDateHolidayCalendarsList
        : [data.fixedFixingDateHolidayCalendarsList]
    );
  }

  /* Floating Leg Attributes */
  if (!isNullOrUndefined(data.floatDayCountConvention)) {
    atts.setFloatDayCountConvention(data.floatDayCountConvention);
  }

  if (!isNullOrUndefined(data.floatBusinessDayConvention)) {
    atts.setFloatBusinessDayConvention(data.floatBusinessDayConvention);
  }

  if (!isNullOrUndefined(data.floatFixingDateBusinessDayConvention)) {
    atts.setFloatFixingDateBusinessDayConvention(
      data.floatFixingDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.floatingRateIndex)) {
    atts.setFloatingRateIndex(data.floatingRateIndex);
  }

  if (!isNullOrUndefined(data.floatFixingDateHolidayCalendarsList)) {
    atts.setFloatFixingDateHolidayCalendarsList(
      Array.isArray(data.floatFixingDateHolidayCalendarsList)
        ? data.floatFixingDateHolidayCalendarsList
        : [data.floatFixingDateHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.floatFixingDatesDayType)) {
    atts.setFloatFixingDatesDayType(data.floatFixingDatesDayType);
  }

  if (!isNullOrUndefined(data.floatFixingDatesOffset)) {
    atts.setFloatFixingDatesOffset(data.floatFixingDatesOffset);
  }

  if (!isNullOrUndefined(data.floatFixingDate)) {
    atts.setFloatFixingDate(dateToProtoDate(data.floatFixingDate));
  }

  if (!isNullOrUndefined(data.floatingRateIndexTenor)) {
    atts.setFloatingRateIndexTenor(data.floatingRateIndexTenor);
  }

  return atts;
};

export const createEventAtts = (data, priceScale) => {
  let atts = new EventAttributes();
  atts.setPositionAccountabilityValue(data.evtAttPositionAccountability)
  atts.setPositionLimit(data.evtAttPositionLimit);
  atts.setUnderlyingEventSpecification(data.evtAttUnderlyingEventSpecification)
  atts.setQuestion(data.evtAttQuestion)
  atts.setSourceAgency(data.evtAttSourceAgency)
  atts.setSourceAgencyUrl(data.evtAttSourceAgencyUrl);

  atts.setStrikeEvaluationLowerLimit(data.evtAttStrikeEvaluationLowerLimit);
  atts.setStrikeEvaluationUpperLimit(data.evtAttStrikeEvaluationUpperLimit);

  if (!isNullOrUndefined(data.evtExpectedPayoutDateTime)) {
    const expectedPayoutDate = dateToProtoDate(data.evtExpectedPayoutDateTime);
    atts.setExpectedPayoutDate(expectedPayoutDate);

    const expectedPayoutTime = dateToProtoTimeOfDay(data.evtExpectedPayoutDateTime);
    atts.setExpectedPayoutTime(expectedPayoutTime);
  }

  if (!isNullOrUndefined(data.evtAttPayoutValue)) {
    const payoutValue = convertScaledPriceToInt(Number(data.evtAttPayoutValue), priceScale)
    atts.setPayoutValue(payoutValue);
  }

  atts.setEvaluationType(data.evtAttEvaluationType);

  if (data.evtAttTimeSpecifier && data.evtAttTimeSpecifier.year > 0) {
    let timeSpecifier = new ProtoDate();
    timeSpecifier.setYear(data.evtAttTimeSpecifier.year);
    timeSpecifier.setMonth(data.evtAttTimeSpecifier.month);
    timeSpecifier.setDay(data.evtAttTimeSpecifier.day);
    atts.setTimeSpecifier(timeSpecifier);
  }

  atts.setStrikeValue(data.evtAttStrikeValue);

  atts.setEventId(data.evtAttEventId);
  atts.setEventDisplayName(data.evtAttEventDisplayName);

  atts.setStrikeUnit(data.evtAttStrikeUnit);
  atts.setCalculationMethod(data.evtAttCalculationMethod);

  atts.setPayoutType(data.evtAttPayoutType);
  if (!isNullOrUndefined(data.evtAttPayoutLowerBound)) {
    atts.setPayoutLowerBound(convertScaledPriceToInt(Number(data.evtAttPayoutLowerBound), priceScale));
  }
  if (!isNullOrUndefined(data.evtAttPayoutUpperBound)) {
    atts.setPayoutUpperBound(convertScaledPriceToInt(Number(data.evtAttPayoutUpperBound), priceScale));
  }

  let hedgeMarkets = data.subTypesList.map( market => {
    let hm = new HedgeMarket();

    if (!isNullOrUndefined(market.buyPriceIncrement)) {
      const buyPriceIncrement = convertScaledPriceToInt(Number(market.buyPriceIncrement), priceScale)
      hm.setBuyPriceIncrement(buyPriceIncrement);
    }

    if (!isNullOrUndefined(market.sellPriceIncrement)) {
      const sellPriceIncrement = convertScaledPriceToInt(Number(market.sellPriceIncrement), priceScale)
      hm.setSellPriceIncrement(sellPriceIncrement);
    }
  
    hm.setSymbol(market.symbol)
    hm.setBuyScale(Number(market.buyScale));
    hm.setSellScale(Number(market.sellScale));
    hm.setOpposingSide(market.opposingSide);
    hm.setStrictLimit(market.strictLimit);
    hm.setBuyQuantityRatio(Number(market.buyQuantityRatio))
    hm.setSellQuantityRatio(Number(market.sellQuantityRatio))

    return hm;
  })
  atts.setSubTypesList(hedgeMarkets);
  atts.setFrequencyType(data.evtAttFrequencyType);

  return atts;
}

export const createNDFAtts = (data) => {
  let atts = new NonDeliverableForwardAttributes();

  if (!isNullOrUndefined(data.tenor)) {
    atts.setTenor(data.tenor);
  }

  if (!isNullOrUndefined(data.forwardStartingPeriod)) {
    atts.setForwardStartingPeriod(data.forwardStartingPeriod);
  }

  if (!isNullOrUndefined(data.forwardStartingPeriodTimePeriod)) {
    atts.setForwardStartingPeriodTimePeriod(data.forwardStartingPeriodTimePeriod);
  }

  if (!isNullOrUndefined(data.sideConvention)) {
    atts.setSideConvention(data.sideConvention);
  }

  if (!isNullOrUndefined(data.currency1)) {
    atts.setCurrency1(data.currency1);
  }

  if (!isNullOrUndefined(data.currency2)) {
    atts.setCurrency2(data.currency2);
  }

  if (!isNullOrUndefined(data.exchangedCurrency1)) {
    atts.setExchangedCurrency1(data.exchangedCurrency1);
  }

  if (!isNullOrUndefined(data.exchangedCurrency2)) {
    atts.setExchangedCurrency2(data.exchangedCurrency2);
  }

  if (!isNullOrUndefined(data.unadjustedValuationDate)) {
    atts.setUnadjustedValuationDate(
        dateToProtoDate(data.unadjustedValuationDate)
    );
  }

  if (!isNullOrUndefined(data.unadjustedFixingDate)) {
    atts.setUnadjustedFixingDate(
        dateToProtoDate(data.unadjustedFixingDate)
    );
  }

  if (!isNullOrUndefined(data.valuationDate)) {
    atts.setValuationDate(dateToProtoDate(data.valuationDate));
  }

  if (!isNullOrUndefined(data.fixingDate)) {
    atts.setFixingDate(dateToProtoDate(data.fixingDate));
  }

  if (!isNullOrUndefined(data.exchangeRateCurrency1)) {
    atts.setExchangeRateCurrency1(data.exchangeRateCurrency1);
  }

  if (!isNullOrUndefined(data.exchangeRateCurrency2)) {
    atts.setExchangeRateCurrency2(data.exchangeRateCurrency2);
  }

  if (!isNullOrUndefined(data.exchangeRateQuoteBasis)) {
    atts.setExchangeRateQuoteBasis(data.exchangeRateQuoteBasis);
  }

  if (!isNullOrUndefined(data.fixingQuoteBasis)) {
    atts.setFixingQuoteBasis(data.fixingQuoteBasis);
  }

  if (!isNullOrUndefined(data.settlementCurrency)) {
    atts.setSettlementCurrency(data.settlementCurrency);
  }

  if (!isNullOrUndefined(data.fixingCurrency1)) {
    atts.setFixingCurrency1(data.fixingCurrency1);
  }

  if (!isNullOrUndefined(data.fixingCurrency2)) {
    atts.setFixingCurrency2(data.fixingCurrency2);
  }

  if (!isNullOrUndefined(data.tenorTimePeriod)) {
    atts.setTenorTimePeriod(data.tenorTimePeriod);
  }

  if (!isNullOrUndefined(data.fixingDateOffset)) {
    atts.setFixingDateOffset(data.fixingDateOffset);
  }

  if (!isNullOrUndefined(data.fixingHolidayCalendarsList)) {
    atts.setFixingHolidayCalendarsList(
        Array.isArray(data.fixingHolidayCalendarsList)
            ? data.fixingHolidayCalendarsList
            : [data.fixingHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.valuationHolidayCalendarsList)) {
    atts.setValuationHolidayCalendarsList(
        Array.isArray(data.valuationHolidayCalendarsList)
            ? data.valuationHolidayCalendarsList
            : [data.valuationHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.fixingDateBusinessDayConvention)) {
    atts.setFixingDateBusinessDayConvention(data.fixingDateBusinessDayConvention);
  }

  if (!isNullOrUndefined(data.valuationDateBusinessDayConvention)) {
    atts.setValuationDateBusinessDayConvention(data.valuationDateBusinessDayConvention);
  }

  return atts;
}

export const setEventAtts = (source, destination, priceScale) => {
  destination.type = EVENT;

  destination.evtAttPositionAccountability = source.positionAccountabilityValue;
  destination.evtAttPositionLimit = source.positionLimit;
  destination.evtAttUnderlyingEventSpecification = source.underlyingEventSpecification;
  destination.evtAttQuestion = source.question;
  destination.evtAttSourceAgency = source.sourceAgency;
  destination.evtAttSourceAgencyUrl = source.sourceAgencyUrl;

  if (!isNullOrUndefined(source.payoutValue)) {
    destination.evtAttPayoutValue = parsePrice(source.payoutValue, priceScale);
  }

  destination.evtAttStrikeValue = source.strikeValue;
  destination.evtAttTimeSpecifier = source.timeSpecifier;
  destination.evtAttEvaluationType = source.evaluationType;

  destination.evtAttStrikeEvaluationLowerLimit = source.strikeEvaluationLowerLimit;
  destination.evtAttStrikeEvaluationUpperLimit = source.strikeEvaluationUpperLimit;

  if (!isNullOrUndefined(source.expectedPayoutDate) && !isNullOrUndefined(source.expectedPayoutTime)) {
    let pDt = source.expectedPayoutDate
    let pTm = source.expectedPayoutTime
    let payoutDate = new Date(pDt.year, pDt.month - 1, pDt.day, pTm.hours, pTm.minutes, pTm.seconds)
    destination.evtExpectedPayoutDateTime = payoutDate
  }

  destination.evtAttEventId = source.eventId;
  destination.evtAttEventDisplayName = source.eventDisplayName;

  destination.evtAttStrikeUnit = source.strikeUnit;
  destination.evtAttCalculationMethod = source.calculationMethod;
  
  destination.evtAttPayoutType = source.payoutType;
  if (source.payoutType === PayoutType.PAYOUT_TYPE_CONTINUOUS) {
    destination.isContinuous = true;
  } else {
    destination.isContinuous = false;
  }


  if (!isNullOrUndefined(source.payoutLowerBound)) {
    destination.evtAttPayoutLowerBound = parsePrice(source.payoutLowerBound, priceScale);
  }
  if (!isNullOrUndefined(source.payoutUpperBound)) {
    destination.evtAttPayoutUpperBound = parsePrice(source.payoutUpperBound, priceScale);
  }

  destination.subTypesList = source.subTypesList;
  destination.evtAttFrequencyType = source.frequencyType;
}

export const setNDFAtts = (source, destination) => {
  destination.type = NON_DELIVERABLE_FORWARDS;

  if (!isNullOrUndefined(source.tenor)) {
    destination.tenor = source.tenor;
  }

  if (!isNullOrUndefined(source.forwardStartingPeriod)) {
    destination.forwardStartingPeriod = source.forwardStartingPeriod;
  }

  if (!isNullOrUndefined(source.forwardStartingPeriodTimePeriod)) {
    destination.forwardStartingPeriodTimePeriod = source.forwardStartingPeriodTimePeriod;
  }

  if (!isNullOrUndefined(source.sideConvention)) {
    destination.sideConvention = source.sideConvention;
  }

  if (!isNullOrUndefined(source.currency1)) {
    destination.currency1 = source.currency1;
  }

  if (!isNullOrUndefined(source.currency2)) {
    destination.currency2 = source.currency2;
  }

  if (!isNullOrUndefined(source.exchangedCurrency1)) {
    destination.exchangedCurrency1 = source.exchangedCurrency1;
  }

  if (!isNullOrUndefined(source.exchangedCurrency2)) {
    destination.exchangedCurrency2 = source.exchangedCurrency2;
  }

  if (!isNullOrUndefined(source.valuationDate)) {
    destination.valuationDate = getDate(source.valuationDate);
  }

  if (!isNullOrUndefined(source.fixingDate)) {
    destination.fixingDate = getDate(source.fixingDate);
  }

  if (!isNullOrUndefined(source.exchangeRateCurrency1)) {
    destination.exchangeRateCurrency1 = source.exchangeRateCurrency1;
  }

  if (!isNullOrUndefined(source.exchangeRateCurrency2)) {
    destination.exchangeRateCurrency2 = source.exchangeRateCurrency2;
  }

  if (!isNullOrUndefined(source.exchangeRateQuoteBasis)) {
    destination.exchangeRateQuoteBasis = source.exchangeRateQuoteBasis;
  }

  if (!isNullOrUndefined(source.fixingQuoteBasis)) {
    destination.fixingQuoteBasis = source.fixingQuoteBasis;
  }

  if (!isNullOrUndefined(source.settlementCurrency)) {
    destination.settlementCurrency = source.settlementCurrency;
  }

  if (!isNullOrUndefined(source.fixingCurrency1)) {
    destination.fixingCurrency1 = source.fixingCurrency1;
  }

  if (!isNullOrUndefined(source.fixingCurrency2)) {
    destination.fixingCurrency2 = source.fixingCurrency2;
  }

  if (!isNullOrUndefined(source.tenorTimePeriod)) {
    destination.tenorTimePeriod = source.tenorTimePeriod;
  }

  if (!isNullOrUndefined(source.valuationDateBusinessDayConvention)) {
    destination.valuationDateBusinessDayConvention = source.valuationDateBusinessDayConvention
  }

  if (!isNullOrUndefined(source.fixingDateBusinessDayConvention)) {
    destination.fixingDateBusinessDayConvention = source.fixingDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.valuationHolidayCalendarsList)) {
    destination.valuationHolidayCalendarsList = source.valuationHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.fixingHolidayCalendarsList)) {
    destination.fixingHolidayCalendarsList = source.fixingHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.unadjustedValuationDate)) {
    destination.unadjustedValuationDate = getDate(source.unadjustedValuationDate);
  }

  if (!isNullOrUndefined(source.unadjustedFixingDate)) {
    destination.unadjustedFixingDate = getDate(source.unadjustedFixingDate);
  }

  if (!isNullOrUndefined(source.fixingDateOffset)) {
    destination.fixingDateOffset = source.fixingDateOffset;
  }
}

export const setUIEventAtts = (source, destination) => {
  destination.type = EVENT;
  destination.evtAttPositionAccountability = source.evtAttPositionAccountability;
  destination.evtAttPositionLimit = source.evtAttPositionLimit;
  destination.evtAttUnderlyingEventSpecification = source.evtAttUnderlyingEventSpecification;
  destination.evtAttQuestion = source.evtAttQuestion;
  destination.evtAttSourceAgency = source.evtAttSourceAgency;
  destination.evtAttSourceAgencyUrl = source.evtAttSourceAgencyUrl;
  destination.evtAttPayoutValue = source.evtAttPayoutValue;
  destination.evtAttStrikeValue = source.evtAttStrikeValue;
  destination.evtAttTimeSpecifier = source.evtAttTimeSpecifier;
  destination.evtAttEvaluationType = source.evtAttEvaluationType;

  destination.evtAttEventId = source.evtAttEventId;
  destination.evtAttEventDisplayName = source.evtAttEventDisplayName;

  destination.evtAttStrikeUnit = source.evtAttStrikeUnit;
  destination.evtAttCalculationMethod = source.evtAttCalculationMethod;

  destination.evtAttStrikeEvaluationLowerLimit = source.evtAttStrikeEvaluationLowerLimit;
  destination.evtAttStrikeEvaluationUpperLimit = source.evtAttStrikeEvaluationUpperLimit;

  destination.evtExpectedPayoutDateTime = source.evtExpectedPayoutDateTime;

  destination.evtAttPayoutType = source.evtAttPayoutType;
  if (destination.evtAttPayoutType === PayoutType.PAYOUT_TYPE_CONTINUOUS) {
    destination.isContinuous = true;
  } else {
    destination.isContinuous = false;
  }
  destination.evtAttPayoutLowerBound = source.evtAttPayoutLowerBound;
  destination.evtAttPayoutUpperBound = source.evtAttPayoutUpperBound;

  const priceScale = !isNullOrUndefined(source.priceScale) ? source.priceScale : Math.pow(
      10,
      countDecimals(source.minimumPriceIncrement)
  );

  destination.subTypesList = Array.isArray(source.subTypesList) ? source.subTypesList.map(elem => {
    return {
      ...elem,
      buyPriceIncrement: parsePrice(elem.buyPriceIncrement, priceScale),
      sellPriceIncrement: parsePrice(elem.sellPriceIncrement, priceScale),
    };
  }) : [];

  destination.evtAttFrequencyType =  source.evtAttFrequencyType;
}

export const setFRAAtts = (source, destination) => {
  destination.type = FORWARD_RATE_AGREEMENT_SWAP;

  if (!isNullOrUndefined(source.tenor)) {
    destination.tenor = source.tenor;
  }

  if (!isNullOrUndefined(source.maturityDateBusinessDayConvention)) {
    destination.maturityDateBusinessDayConvention =
      source.maturityDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.effectiveDateBusinessDayConvention)) {
    destination.effectiveDateBusinessDayConvention =
      source.effectiveDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.sideConvention)) {
    destination.sideConvention = source.sideConvention;
  }

  if (!isNullOrUndefined(source.swapEffectiveDate)) {
    destination.swapEffectiveDate = getDate(source.swapEffectiveDate);
  }

  if (!isNullOrUndefined(source.maturityDate)) {
    destination.maturityDate = getDate(source.maturityDate);
  }

  if (!isNullOrUndefined(source.unadjustedEffectiveDate)) {
    destination.unadjustedEffectiveDate = getDate(
      source.unadjustedEffectiveDate
    );
  }

  if (!isNullOrUndefined(source.unadjustedMaturityDate)) {
    destination.unadjustedMaturityDate = getDate(source.unadjustedMaturityDate);
  }

  if (!isNullOrUndefined(source.rollAndPaymentDateHolidayCalendarsList)) {
    destination.rollAndPaymentDateHolidayCalendarsList =
      source.rollAndPaymentDateHolidayCalendarsList;
  }

  destination.calculationPeriodNumberOfDays =
    source.calculationPeriodNumberOfDays;
  destination.forwardStartingPeriod = source.forwardStartingPeriod;

  //
  // Fixed Leg Attributes
  //
  if (!isNullOrUndefined(source.fixedDayCountConvention)) {
    destination.fixedDayCountConvention = source.fixedDayCountConvention;
  }

  if (!isNullOrUndefined(source.fixedBusinessDayConvention)) {
    destination.fixedBusinessDayConvention = source.fixedBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.fixedFirstUnadjustedPaymentDate)) {
    destination.fixedFirstUnadjustedPaymentDate = getDate(
      source.fixedFirstUnadjustedPaymentDate
    );
  }

  if (!isNullOrUndefined(source.fixedFixingDateHolidayCalendarsList)) {
    destination.fixedFixingDateHolidayCalendarsList =
      source.fixedFixingDateHolidayCalendarsList;
  }

  //
  // Floating Leg Attributes
  //
  if (!isNullOrUndefined(source.floatingRateIndex)) {
    destination.floatingRateIndex = source.floatingRateIndex;
  }

  if (!isNullOrUndefined(source.floatHolidayCalendarsList)) {
    destination.floatHolidayCalendarsList = source.floatHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.floatDayCountConvention)) {
    destination.floatDayCountConvention = source.floatDayCountConvention;
  }

  if (!isNullOrUndefined(source.firstFloatFixingDate)) {
    destination.firstFloatFixingDate = getDate(source.firstFloatFixingDate);
  }

  if (!isNullOrUndefined(source.floatResetDateBusinessDayConvention)) {
    destination.floatResetDateBusinessDayConvention =
      source.floatResetDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatBusinessDayConvention)) {
    destination.floatBusinessDayConvention = source.floatBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatFixingDatesOffset)) {
    destination.floatFixingDatesOffset = source.floatFixingDatesOffset;
  }

  if (!isNullOrUndefined(source.floatFixingDateBusinessDayConvention)) {
    destination.floatFixingDateBusinessDayConvention =
      source.floatFixingDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatFixingDatesDayType)) {
    destination.floatFixingDatesDayType = source.floatFixingDatesDayType;
  }

  if (!isNullOrUndefined(source.floatingRateIndexTenor)) {
    destination.floatingRateIndexTenor = source.floatingRateIndexTenor;
  }

  if (!isNullOrUndefined(source.floatFixingDateHolidayCalendarsList)) {
    destination.floatFixingDateHolidayCalendarsList =
      source.floatFixingDateHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.floatFixingDate)) {
    destination.floatFixingDate = getDate(source.floatFixingDate);
  }
};

export const createBasisAtts = (data) => {
  let basisAtts = new BasisSwapAttributes();

  /* Swap Information Attributes */
  if (!isNullOrUndefined(data.tenor)) {
    basisAtts.setTenor(data.tenor);
  }
  if (!isNullOrUndefined(data.sideConvention)) {
    basisAtts.setSideConvention(data.sideConvention);
  }
  if (!isNullOrUndefined(data.effectiveDateBusinessDayConvention)) {
    basisAtts.setEffectiveDateBusinessDayConvention(
      data.effectiveDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.maturityDateBusinessDayConvention)) {
    basisAtts.setMaturityDateBusinessDayConvention(
      data.maturityDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.maturityDate)) {
    basisAtts.setMaturityDate(dateToProtoDate(data.maturityDate));
  }
  if (!isNullOrUndefined(data.effectiveDateOffset)) {
    basisAtts.setEffectiveDateOffset(data.effectiveDateOffset);
  }
  if (!isNullOrUndefined(data.swapEffectiveDate)) {
    basisAtts.setSwapEffectiveDate(dateToProtoDate(data.swapEffectiveDate));
  }
  if (!isNullOrUndefined(data.rollAndPaymentDateHolidayCalendarsList)) {
    basisAtts.setRollAndPaymentDateHolidayCalendarsList(
      Array.isArray(data.rollAndPaymentDateHolidayCalendarsList)
        ? data.rollAndPaymentDateHolidayCalendarsList
        : [data.rollAndPaymentDateHolidayCalendarsList]
    );
  }

  /* Floating Leg Attributes */

  if (!isNullOrUndefined(data.leg1FloatingRateIndex)) {
    basisAtts.setLeg1FloatingRateIndex(data.leg1FloatingRateIndex);
  }
  if (!isNullOrUndefined(data.leg2FloatingRateIndex)) {
    basisAtts.setLeg2FloatingRateIndex(data.leg2FloatingRateIndex);
  }
  if (!isNullOrUndefined(data.leg1FloatDayCountConvention)) {
    basisAtts.setLeg1FloatDayCountConvention(data.leg1FloatDayCountConvention);
  }
  if (!isNullOrUndefined(data.leg2FloatDayCountConvention)) {
    basisAtts.setLeg2FloatDayCountConvention(data.leg2FloatDayCountConvention);
  }
  if (!isNullOrUndefined(data.leg1FloatCompoundingMethod)) {
    basisAtts.setLeg1FloatCompoundingMethod(data.leg1FloatCompoundingMethod);
  }
  if (!isNullOrUndefined(data.leg2FloatCompoundingMethod)) {
    basisAtts.setLeg2FloatCompoundingMethod(data.leg2FloatCompoundingMethod);
  }
  if (!isNullOrUndefined(data.leg1FloatRateResetFrequency)) {
    basisAtts.setLeg1FloatRateResetFrequency(data.leg1FloatRateResetFrequency);
  }
  if (!isNullOrUndefined(data.leg2FloatRateResetFrequency)) {
    basisAtts.setLeg2FloatRateResetFrequency(data.leg2FloatRateResetFrequency);
  }
  if (!isNullOrUndefined(data.leg1FloatPaymentFrequency)) {
    basisAtts.setLeg1FloatPaymentFrequency(data.leg1FloatPaymentFrequency);
  }
  if (!isNullOrUndefined(data.leg2FloatPaymentFrequency)) {
    basisAtts.setLeg2FloatPaymentFrequency(data.leg2FloatPaymentFrequency);
  }
  if (!isNullOrUndefined(data.leg1FloatBusinessDayConvention)) {
    basisAtts.setLeg1FloatBusinessDayConvention(
      data.leg1FloatBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.leg2FloatBusinessDayConvention)) {
    basisAtts.setLeg2FloatBusinessDayConvention(
      data.leg2FloatBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.leg1FloatFixingDatesDayType)) {
    basisAtts.setLeg1FloatFixingDatesDayType(data.leg1FloatFixingDatesDayType);
  }
  if (!isNullOrUndefined(data.leg2FloatFixingDatesDayType)) {
    basisAtts.setLeg2FloatFixingDatesDayType(data.leg2FloatFixingDatesDayType);
  }
  if (!isNullOrUndefined(data.leg1FloatResetDateBusinessDayConvention)) {
    basisAtts.setLeg1FloatResetDateBusinessDayConvention(
      data.leg1FloatResetDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.leg2FloatResetDateBusinessDayConvention)) {
    basisAtts.setLeg2FloatResetDateBusinessDayConvention(
      data.leg2FloatResetDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.leg1FloatResetDateRelativeTo)) {
    basisAtts.setLeg1FloatResetDateRelativeTo(
      data.leg1FloatResetDateRelativeTo
    );
  }
  if (!isNullOrUndefined(data.leg2FloatResetDateRelativeTo)) {
    basisAtts.setLeg2FloatResetDateRelativeTo(
      data.leg2FloatResetDateRelativeTo
    );
  }
  if (!isNullOrUndefined(data.leg1FloatFixingDateBusinessDayConvention)) {
    basisAtts.setLeg1FloatFixingDateBusinessDayConvention(
      data.leg1FloatFixingDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.leg2FloatFixingDateBusinessDayConvention)) {
    basisAtts.setLeg2FloatFixingDateBusinessDayConvention(
      data.leg2FloatFixingDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.leg1FloatFixingDateHolidayCalendarsList)) {
    basisAtts.setLeg1FloatFixingDateHolidayCalendarsList(
      Array.isArray(data.leg1FloatFixingDateHolidayCalendarsList)
        ? data.leg1FloatFixingDateHolidayCalendarsList
        : [data.leg1FloatFixingDateHolidayCalendarsList]
    );
  }
  if (!isNullOrUndefined(data.leg2FloatFixingDateHolidayCalendarsList)) {
    basisAtts.setLeg2FloatFixingDateHolidayCalendarsList(
      Array.isArray(data.leg2FloatFixingDateHolidayCalendarsList)
        ? data.leg2FloatFixingDateHolidayCalendarsList
        : [data.leg2FloatFixingDateHolidayCalendarsList]
    );
  }
  if (!isNullOrUndefined(data.leg1FirstFloatResetDate)) {
    basisAtts.setLeg1FirstFloatResetDate(
      dateToProtoDate(data.leg1FirstFloatResetDate)
    );
  }
  if (!isNullOrUndefined(data.leg2FirstFloatResetDate)) {
    basisAtts.setLeg2FirstFloatResetDate(
      dateToProtoDate(data.leg2FirstFloatResetDate)
    );
  }
  if (!isNullOrUndefined(data.leg1FirstFloatFixingDate)) {
    basisAtts.setLeg1FirstFloatFixingDate(
      dateToProtoDate(data.leg1FirstFloatFixingDate)
    );
  }
  if (!isNullOrUndefined(data.leg2FirstFloatFixingDate)) {
    basisAtts.setLeg2FirstFloatFixingDate(
      dateToProtoDate(data.leg2FirstFloatFixingDate)
    );
  }
  if (!isNullOrUndefined(data.leg1FloatRollConvention)) {
    basisAtts.setLeg1FloatRollConvention(data.leg1FloatRollConvention);
  }
  if (!isNullOrUndefined(data.leg2FloatRollConvention)) {
    basisAtts.setLeg2FloatRollConvention(data.leg2FloatRollConvention);
  }
  if (!isNullOrUndefined(data.leg1FloatFixingDatesOffset)) {
    basisAtts.setLeg1FloatFixingDatesOffset(data.leg1FloatFixingDatesOffset);
  }
  if (!isNullOrUndefined(data.leg2FloatFixingDatesOffset)) {
    basisAtts.setLeg2FloatFixingDatesOffset(data.leg2FloatFixingDatesOffset);
  }
  if (!isNullOrUndefined(data.leg1FloatPaymentDateRelativeTo)) {
    basisAtts.setLeg1FloatPaymentDateRelativeTo(
      data.leg1FloatPaymentDateRelativeTo
    );
  }
  if (!isNullOrUndefined(data.leg2FloatPaymentDateRelativeTo)) {
    basisAtts.setLeg2FloatPaymentDateRelativeTo(
      data.leg2FloatPaymentDateRelativeTo
    );
  }
  if (!isNullOrUndefined(data.leg1FloatFixingDatesDayType)) {
    basisAtts.setLeg1FloatFixingDatesDayType(data.leg1FloatFixingDatesDayType);
  }
  if (!isNullOrUndefined(data.leg1FloatFixingDatesDayType)) {
    basisAtts.setLeg1FloatFixingDatesDayType(data.leg1FloatFixingDatesDayType);
  }
  if (!isNullOrUndefined(data.leg1FloatingRateIndexTenor)) {
    basisAtts.setLeg1FloatingRateIndexTenor(data.leg1FloatingRateIndexTenor);
  }
  if (!isNullOrUndefined(data.leg2FloatingRateIndexTenor)) {
    basisAtts.setLeg2FloatingRateIndexTenor(data.leg2FloatingRateIndexTenor);
  }
  if (!isNullOrUndefined(data.leg1FloatFirstUnadjustedPaymentDate)) {
    basisAtts.setLeg1FloatFirstUnadjustedPaymentDate(
      dateToProtoDate(data.leg1FloatFirstUnadjustedPaymentDate)
    );
  }
  if (!isNullOrUndefined(data.leg2FloatFirstUnadjustedPaymentDate)) {
    basisAtts.setLeg2FloatFirstUnadjustedPaymentDate(
      dateToProtoDate(data.leg2FloatFirstUnadjustedPaymentDate)
    );
  }
  if (!isNullOrUndefined(data.floatFirstUnadjustedEffectiveDate)) {
    basisAtts.setFloatFirstUnadjustedEffectiveDate(
      dateToProtoDate(data.floatFirstUnadjustedEffectiveDate)
    );
  }
  if (!isNullOrUndefined(data.unadjustedMaturityDate)) {
    basisAtts.setUnadjustedMaturityDate(
      dateToProtoDate(data.unadjustedMaturityDate)
    );
  }
  return basisAtts;
};

export const setBasisAtts = (source, destination) => {
  destination.type = BASIS_SWAP;

  /* Swap Information Attributes */
  if (!isNullOrUndefined(source.tenor)) {
    destination.tenor = source.tenor;
  }
  if (!isNullOrUndefined(source.sideConvention)) {
    destination.sideConvention = source.sideConvention;
  }
  if (!isNullOrUndefined(source.effectiveDateBusinessDayConvention)) {
    destination.effectiveDateBusinessDayConvention =
      source.effectiveDateBusinessDayConvention;
  }
  if (!isNullOrUndefined(source.maturityDateBusinessDayConvention)) {
    destination.maturityDateBusinessDayConvention =
      source.maturityDateBusinessDayConvention;
  }
  if (!isNullOrUndefined(source.maturityDate)) {
    destination.maturityDate = getDate(source.maturityDate);
  }
  if (!isNullOrUndefined(source.effectiveDateOffset)) {
    destination.effectiveDateOffset = source.effectiveDateOffset;
  }
  if (!isNullOrUndefined(source.swapEffectiveDate)) {
    destination.swapEffectiveDate = getDate(source.swapEffectiveDate);
  }
  if (!isNullOrUndefined(source.rollAndPaymentDateHolidayCalendarsList)) {
    destination.rollAndPaymentDateHolidayCalendarsList =
      source.rollAndPaymentDateHolidayCalendarsList;
  }

  /* Floating Leg Attributes */

  if (!isNullOrUndefined(source.leg1FloatingRateIndex)) {
    destination.leg1FloatingRateIndex = source.leg1FloatingRateIndex;
  }
  if (!isNullOrUndefined(source.leg2FloatingRateIndex)) {
    destination.leg2FloatingRateIndex = source.leg2FloatingRateIndex;
  }
  if (!isNullOrUndefined(source.leg1FloatDayCountConvention)) {
    destination.leg1FloatDayCountConvention =
      source.leg1FloatDayCountConvention;
  }
  if (!isNullOrUndefined(source.leg2FloatDayCountConvention)) {
    destination.leg2FloatDayCountConvention =
      source.leg2FloatDayCountConvention;
  }
  if (!isNullOrUndefined(source.leg1FloatCompoundingMethod)) {
    destination.leg1FloatCompoundingMethod = source.leg1FloatCompoundingMethod;
  }
  if (!isNullOrUndefined(source.leg2FloatCompoundingMethod)) {
    destination.leg2FloatCompoundingMethod = source.leg2FloatCompoundingMethod;
  }
  if (!isNullOrUndefined(source.leg1FloatRateResetFrequency)) {
    destination.leg1FloatRateResetFrequency =
      source.leg1FloatRateResetFrequency;
  }
  if (!isNullOrUndefined(source.leg2FloatRateResetFrequency)) {
    destination.leg2FloatRateResetFrequency =
      source.leg2FloatRateResetFrequency;
  }
  if (!isNullOrUndefined(source.leg1FloatPaymentFrequency)) {
    destination.leg1FloatPaymentFrequency = source.leg1FloatPaymentFrequency;
  }
  if (!isNullOrUndefined(source.leg2FloatPaymentFrequency)) {
    destination.leg2FloatPaymentFrequency = source.leg2FloatPaymentFrequency;
  }
  if (!isNullOrUndefined(source.leg1FloatBusinessDayConvention)) {
    destination.leg1FloatBusinessDayConvention =
      source.leg1FloatBusinessDayConvention;
  }
  if (!isNullOrUndefined(source.leg2FloatBusinessDayConvention)) {
    destination.leg2FloatBusinessDayConvention =
      source.leg2FloatBusinessDayConvention;
  }
  if (!isNullOrUndefined(source.leg1FloatResetDateBusinessDayConvention)) {
    destination.leg1FloatResetDateBusinessDayConvention =
      source.leg1FloatResetDateBusinessDayConvention;
  }
  if (!isNullOrUndefined(source.leg2FloatResetDateBusinessDayConvention)) {
    destination.leg2FloatResetDateBusinessDayConvention =
      source.leg2FloatResetDateBusinessDayConvention;
  }
  if (!isNullOrUndefined(source.leg1FloatResetDateRelativeTo)) {
    destination.leg1FloatResetDateRelativeTo =
      source.leg1FloatResetDateRelativeTo;
  }
  if (!isNullOrUndefined(source.leg2FloatResetDateRelativeTo)) {
    destination.leg2FloatResetDateRelativeTo =
      source.leg2FloatResetDateRelativeTo;
  }
  if (!isNullOrUndefined(source.leg1FloatFixingDateBusinessDayConvention)) {
    destination.leg1FloatFixingDateBusinessDayConvention =
      source.leg1FloatFixingDateBusinessDayConvention;
  }
  if (!isNullOrUndefined(source.leg2FloatFixingDateBusinessDayConvention)) {
    destination.leg2FloatFixingDateBusinessDayConvention =
      source.leg2FloatFixingDateBusinessDayConvention;
  }
  if (!isNullOrUndefined(source.leg1FloatFixingDateHolidayCalendarsList)) {
    destination.leg1FloatFixingDateHolidayCalendarsList =
      source.leg1FloatFixingDateHolidayCalendarsList;
  }
  if (!isNullOrUndefined(source.leg2FloatFixingDateHolidayCalendarsList)) {
    destination.leg2FloatFixingDateHolidayCalendarsList =
      source.leg2FloatFixingDateHolidayCalendarsList;
  }
  if (!isNullOrUndefined(source.leg1FirstFloatResetDate)) {
    destination.leg1FirstFloatResetDate = getDate(
      source.leg1FirstFloatResetDate
    );
  }
  if (!isNullOrUndefined(source.leg2FirstFloatResetDate)) {
    destination.leg2FirstFloatResetDate = getDate(
      source.leg2FirstFloatResetDate
    );
  }
  if (!isNullOrUndefined(source.leg1FirstFloatFixingDate)) {
    destination.leg1FirstFloatFixingDate = getDate(
      source.leg1FirstFloatFixingDate
    );
  }
  if (!isNullOrUndefined(source.leg2FirstFloatFixingDate)) {
    destination.leg2FirstFloatFixingDate = getDate(
      source.leg2FirstFloatFixingDate
    );
  }
  if (!isNullOrUndefined(source.leg1FloatRollConvention)) {
    destination.leg1FloatRollConvention = source.leg1FloatRollConvention;
  }
  if (!isNullOrUndefined(source.leg2FloatRollConvention)) {
    destination.leg2FloatRollConvention = source.leg2FloatRollConvention;
  }
  if (!isNullOrUndefined(source.leg1FloatFixingDatesOffset)) {
    destination.leg1FloatFixingDatesOffset = source.leg1FloatFixingDatesOffset;
  }
  if (!isNullOrUndefined(source.leg2FloatFixingDatesOffset)) {
    destination.leg2FloatFixingDatesOffset = source.leg2FloatFixingDatesOffset;
  }
  if (!isNullOrUndefined(source.leg1FloatPaymentDateRelativeTo)) {
    destination.leg1FloatPaymentDateRelativeTo =
      source.leg1FloatPaymentDateRelativeTo;
  }
  if (!isNullOrUndefined(source.leg2FloatPaymentDateRelativeTo)) {
    destination.leg2FloatPaymentDateRelativeTo =
      source.leg2FloatPaymentDateRelativeTo;
  }
  if (!isNullOrUndefined(source.leg1FloatFixingDatesDayType)) {
    destination.leg1FloatFixingDatesDayType =
      source.leg1FloatFixingDatesDayType;
  }
  if (!isNullOrUndefined(source.leg2FloatFixingDatesDayType)) {
    destination.leg2FloatFixingDatesDayType =
      source.leg2FloatFixingDatesDayType;
  }
  if (!isNullOrUndefined(source.leg1FloatingRateIndexTenor)) {
    destination.leg1FloatingRateIndexTenor = source.leg1FloatingRateIndexTenor;
  }
  if (!isNullOrUndefined(source.leg2FloatingRateIndexTenor)) {
    destination.leg2FloatingRateIndexTenor = source.leg2FloatingRateIndexTenor;
  }
  if (!isNullOrUndefined(source.leg1FloatFirstUnadjustedPaymentDate)) {
    destination.leg1FloatFirstUnadjustedPaymentDate = getDate(
      source.leg1FloatFirstUnadjustedPaymentDate
    );
  }
  if (!isNullOrUndefined(source.leg2FloatFirstUnadjustedPaymentDate)) {
    destination.leg2FloatFirstUnadjustedPaymentDate = getDate(
      source.leg2FloatFirstUnadjustedPaymentDate
    );
  }
  if (!isNullOrUndefined(source.floatFirstUnadjustedEffectiveDate)) {
    destination.floatFirstUnadjustedEffectiveDate = getDate(
      source.floatFirstUnadjustedEffectiveDate
    );
  }
  if (!isNullOrUndefined(source.unadjustedMaturityDate)) {
    destination.unadjustedMaturityDate = getDate(source.unadjustedMaturityDate);
  }
};

export const createZeroCouponInflationSwapAtts = (data) => {
  let basisAtts = new ZeroCouponInflationSwapAttributes();

  /* Swap Information Attributes */
  if (!isNullOrUndefined(data.tenor)) {
    basisAtts.setTenor(data.tenor);
  }
  if (!isNullOrUndefined(data.sideConvention)) {
    basisAtts.setSideConvention(data.sideConvention);
  }
  if (!isNullOrUndefined(data.swapEffectiveDate)) {
    basisAtts.setSwapEffectiveDate(dateToProtoDate(data.swapEffectiveDate));
  }
  if (!isNullOrUndefined(data.effectiveDateBusinessDayConvention)) {
    basisAtts.setEffectiveDateBusinessDayConvention(
      data.effectiveDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.maturityDate)) {
    basisAtts.setMaturityDate(dateToProtoDate(data.maturityDate));
  }
  if (!isNullOrUndefined(data.maturityDateBusinessDayConvention)) {
    basisAtts.setMaturityDateBusinessDayConvention(
      data.maturityDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.rollAndPaymentDateHolidayCalendarsList)) {
    basisAtts.setRollAndPaymentDateHolidayCalendarsList(
      data.rollAndPaymentDateHolidayCalendarsList
    );
  }
  /* Fixed Leg Attributes */

  if (!isNullOrUndefined(data.fixedPaymentFrequency)) {
    basisAtts.setFixedPaymentFrequency(data.fixedPaymentFrequency);
  }
  if (!isNullOrUndefined(data.fixedCompoundingMethod)) {
    basisAtts.setFixedCompoundingMethod(data.fixedCompoundingMethod);
  }
  if (!isNullOrUndefined(data.floatCompoundingMethod)) {
    basisAtts.setFloatCompoundingMethod(data.floatCompoundingMethod);
  }
  if (!isNullOrUndefined(data.fixedDayCountConvention)) {
    basisAtts.setFixedDayCountConvention(data.fixedDayCountConvention);
  }
  if (!isNullOrUndefined(data.floatDayCountConvention)) {
    basisAtts.setFloatDayCountConvention(data.floatDayCountConvention);
  }
  if (!isNullOrUndefined(data.floatHolidayCalendarsList)) {
    basisAtts.setFloatHolidayCalendarsList(data.floatHolidayCalendarsList);
  }
  if (!isNullOrUndefined(data.fixedPaymentDayConvention)) {
    basisAtts.setFixedPaymentDayConvention(data.fixedPaymentDayConvention);
  }
  if (!isNullOrUndefined(data.fixedCalculationPeriodDayConvention)) {
    basisAtts.setFixedCalculationPeriodDayConvention(data.fixedCalculationPeriodDayConvention);
  }
  if (!isNullOrUndefined(data.floatPaymentFrequency)) {
    basisAtts.setFloatPaymentFrequency(data.floatPaymentFrequency);
  }
  if (!isNullOrUndefined(data.floatingRateIndex)) {
    basisAtts.setFloatingRateIndex(data.floatingRateIndex);
  }

  if (!isNullOrUndefined(data.floatRollConvention)) {
    basisAtts.setFloatRollConvention(data.floatRollConvention);
  }

  if (!isNullOrUndefined(data.fixedRollConvention)) {
    basisAtts.setFixedRollConvention(data.fixedRollConvention);
  }

  if (!isNullOrUndefined(data.floatFirstUnadjustedPaymentDate)) {
    basisAtts.setFloatFirstUnadjustedPaymentDate(
      dateToProtoDate(data.floatFirstUnadjustedPaymentDate)
    );
  }

  if (!isNullOrUndefined(data.fixedFirstUnadjustedPaymentDate)) {
    basisAtts.setFixedFirstUnadjustedPaymentDate(
      dateToProtoDate(data.fixedFirstUnadjustedPaymentDate)
    );
  }

  if (!isNullOrUndefined(data.floatPaymentDateRelativeTo)) {
    basisAtts.setFloatPaymentDateRelativeTo(data.floatPaymentDateRelativeTo);
  }

  if (!isNullOrUndefined(data.fixedPaymentDateRelativeTo)) {
    basisAtts.setFixedPaymentDateRelativeTo(data.fixedPaymentDateRelativeTo);
  }
  if (!isNullOrUndefined(data.unadjustedEffectiveDate)) {
    basisAtts.setUnadjustedEffectiveDate(
      dateToProtoDate(data.unadjustedEffectiveDate)
    );
  }

  if (!isNullOrUndefined(data.unadjustedMaturityDate)) {
    basisAtts.setUnadjustedMaturityDate(
      dateToProtoDate(data.unadjustedMaturityDate)
    );
  }

  if (!isNullOrUndefined(data.effectiveDateOffset)) {
    basisAtts.setEffectiveDateOffset(data.effectiveDateOffset);
  }

  if (!isNullOrUndefined(data.floatPaymentDayConvention)) {
    basisAtts.setFloatPaymentDayConvention(data.floatPaymentDayConvention);
  }

  if (!isNullOrUndefined(data.floatCalculationPeriodDayConvention)) {
    basisAtts.setFloatCalculationPeriodDayConvention(data.floatCalculationPeriodDayConvention);
  }

  if (!isNullOrUndefined(data.forwardStartingPeriod)) {
    basisAtts.setForwardStartingPeriod(data.forwardStartingPeriod);
  }
  if (!isNullOrUndefined(data.tenorTimePeriod)) {
    basisAtts.setTenorTimePeriod(data.tenorTimePeriod);
  }
  if (!isNullOrUndefined(data.forwardStartingPeriodTimePeriod)) {
    basisAtts.setForwardStartingPeriodTimePeriod(data.forwardStartingPeriodTimePeriod);
  }
  if (!isNullOrUndefined(data.inflationLag)) {
    basisAtts.setInflationLag(data.inflationLag);
  }
  if (!isNullOrUndefined(data.indexSource)) {
    basisAtts.setIndexSource(data.indexSource);
  }
  if (!isNullOrUndefined(data.interpolationMethod)) {
    basisAtts.setInterpolationMethod(data.interpolationMethod);
  }
  if (!isNullOrUndefined(data.fallbackBondApplicable)) {
    basisAtts.setFallbackBondApplicable(data.fallbackBondApplicable);
  }
  if (!isNullOrUndefined(data.inflationInstrumentName)) {
    basisAtts.setInflationInstrumentName(data.inflationInstrumentName);
  }

  
  return basisAtts;
};

export const setZeroCouponInflationSwapAtts = (source, destination) => {
  destination.type = ZERO_COUPON_INFLATION_SWAP;

  /* Swap Information Attributes */
  if (!isNullOrUndefined(source.tenor)) {
    destination.tenor = source.tenor;
  }
  if (!isNullOrUndefined(source.sideConvention)) {
    destination.sideConvention = source.sideConvention;
  }
  if (!isNullOrUndefined(source.swapEffectiveDate)) {
    destination.swapEffectiveDate = getDate(source.swapEffectiveDate);
  }
  if (!isNullOrUndefined(source.effectiveDateBusinessDayConvention)) {
    destination.effectiveDateBusinessDayConvention =
      source.effectiveDateBusinessDayConvention;
  }
  if (!isNullOrUndefined(source.maturityDate)) {
    destination.maturityDate = getDate(source.maturityDate);
  }
  if (!isNullOrUndefined(source.maturityDateBusinessDayConvention)) {
    destination.maturityDateBusinessDayConvention =
      source.maturityDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.rollAndPaymentDateHolidayCalendarsList)) {
    destination.rollAndPaymentDateHolidayCalendarsList =
      source.rollAndPaymentDateHolidayCalendarsList;
  }
   //
  // Fixed Leg Attributes
  //
  if (!isNullOrUndefined(source.fixedPaymentFrequency)) {
    destination.fixedPaymentFrequency = source.fixedPaymentFrequency;
  }
  if (!isNullOrUndefined(source.fixedCompoundingMethod)) {
    destination.fixedCompoundingMethod = source.fixedCompoundingMethod;
  }
  if (!isNullOrUndefined(source.floatCompoundingMethod)) {
    destination.floatCompoundingMethod = source.floatCompoundingMethod;
  }
  if (!isNullOrUndefined(source.fixedPaymentDayConvention)) {
    destination.fixedPaymentDayConvention = source.fixedPaymentDayConvention;
  }
  if (!isNullOrUndefined(source.fixedCalculationPeriodDayConvention)) {
    destination.fixedCalculationPeriodDayConvention = source.fixedCalculationPeriodDayConvention;
  }
  if (!isNullOrUndefined(source.fixedDayCountConvention)) {
    destination.fixedDayCountConvention = source.fixedDayCountConvention;
  }
  if (!isNullOrUndefined(source.floatDayCountConvention)) {
    destination.floatDayCountConvention = source.floatDayCountConvention;
  }
  if (!isNullOrUndefined(source.floatHolidayCalendarsList)) {
    destination.floatHolidayCalendarsList = source.floatHolidayCalendarsList;
  }
  if (!isNullOrUndefined(source.floatPaymentFrequency)) {
    destination.floatPaymentFrequency = source.floatPaymentFrequency;
  }
  if (!isNullOrUndefined(source.floatingRateIndex)) {
    destination.floatingRateIndex = source.floatingRateIndex;
  }
  if (!isNullOrUndefined(source.floatRollConvention)) {
    destination.floatRollConvention = source.floatRollConvention;
  }
  if (!isNullOrUndefined(source.fixedRollConvention)) {
    destination.fixedRollConvention = source.fixedRollConvention;
  }
  if (!isNullOrUndefined(source.floatFirstUnadjustedPaymentDate)) {
    destination.floatFirstUnadjustedPaymentDate = getDate(
      source.floatFirstUnadjustedPaymentDate
    );
  }
  if (!isNullOrUndefined(source.fixedFirstUnadjustedPaymentDate)) {
    destination.fixedFirstUnadjustedPaymentDate = getDate(
      source.fixedFirstUnadjustedPaymentDate
    );
  }
  if (!isNullOrUndefined(source.floatPaymentDateRelativeTo)) {
    destination.floatPaymentDateRelativeTo = source.floatPaymentDateRelativeTo;
  }
  if (!isNullOrUndefined(source.fixedPaymentDateRelativeTo)) {
    destination.fixedPaymentDateRelativeTo = source.fixedPaymentDateRelativeTo;
  }
  if (!isNullOrUndefined(source.unadjustedEffectiveDate)) {
    destination.unadjustedEffectiveDate = getDate(
      source.unadjustedEffectiveDate
    );
  }
  if (!isNullOrUndefined(source.unadjustedMaturityDate)) {
    destination.unadjustedMaturityDate = getDate(source.unadjustedMaturityDate);
  }
  if (!isNullOrUndefined(source.effectiveDateOffset)) {
    destination.effectiveDateOffset = source.effectiveDateOffset;
  }
  if (!isNullOrUndefined(source.floatPaymentDayConvention)) {
    destination.floatPaymentDayConvention = source.floatPaymentDayConvention;
  }
  if (!isNullOrUndefined(source.floatCalculationPeriodDayConvention)) {
    destination.floatCalculationPeriodDayConvention = source.floatCalculationPeriodDayConvention;
  }
  if (!isNullOrUndefined(source.forwardStartingPeriod)) {
    destination.forwardStartingPeriod = source.forwardStartingPeriod;
  }
  if (!isNullOrUndefined(source.tenorTimePeriod)) {
    destination.tenorTimePeriod = source.tenorTimePeriod;
  }
  if (!isNullOrUndefined(source.forwardStartingPeriodTimePeriod)) {
    destination.forwardStartingPeriodTimePeriod = source.forwardStartingPeriodTimePeriod;
  }
  if (!isNullOrUndefined(source.inflationLag)) {
    destination.inflationLag = source.inflationLag;
  }
  if (!isNullOrUndefined(source.indexSource)) {
    destination.indexSource = source.indexSource;
  }
  if (!isNullOrUndefined(source.interpolationMethod)) {
    destination.interpolationMethod = source.interpolationMethod;
  }
  if (!isNullOrUndefined(source.fallbackBondApplicable)) {
    destination.fallbackBondApplicable = source.fallbackBondApplicable;
  }
  if (!isNullOrUndefined(source.inflationInstrumentName)) {
    destination.inflationInstrumentName = source.inflationInstrumentName;
  }
};

export const createFutureAtts = (data) => {
  let atts = new FutureAttributes();

  if (!isNullOrUndefined(data.maturityDate) && Number(data.maturityDate.year) > 0) {
    let maturityDate = new ProtoDate();
    maturityDate.setYear(numberOrDefault(data.maturityDate.year));
    maturityDate.setMonth(numberOrDefault(data.maturityDate.month));
    maturityDate.setDay(numberOrDefault(data.maturityDate.day));
    atts.setMaturityDate(maturityDate);
  }

  return atts;
};

export const setFutureAtts = (source, destination) => {
  destination.type = FUTURE;

  if (!isNullOrUndefined(source.maturityDate)) {
    destination.maturityDate = source.maturityDate;
  }
};

export const createOptionAtts = (data) => {
  let atts = new OptionAttributes();

  if (!isNullOrUndefined(data.maturityDate) && Number(data.maturityDate.year) > 0) {
    let maturityDate = new ProtoDate();
    maturityDate.setYear(numberOrDefault(data.maturityDate.year));
    maturityDate.setMonth(numberOrDefault(data.maturityDate.month));
    maturityDate.setDay(numberOrDefault(data.maturityDate.day));
    atts.setMaturityDate(maturityDate);
  }

  if (!isNullOrUndefined(data.putOrCall)) {
    atts.setPutOrCall(data.putOrCall);
  }

  if (!isNullOrUndefined(data.strikePrice)) {
    const priceScale = Math.pow(10, countDecimals(data.minimumPriceIncrement));
    atts.setStrikePrice(convertScaledPriceToInt(data.strikePrice, priceScale));
  }

  return atts;
};

export const setOptionAtts = (source, destination) => {
  destination.type = OPTION;

  if (!isNullOrUndefined(source.maturityDate)) {
    destination.maturityDate = source.maturityDate;
  }

  if (!isNullOrUndefined(source.putOrCall)) {
    destination.putOrCall = source.putOrCall;
  }

  if (!isNullOrUndefined(source.strikePrice)) {
    const priceScale = Math.pow(
      10,
      countDecimals(source.minimumPriceIncrement)
    );
    destination.strikePrice = parsePrice(source.strikePrice, priceScale);
  }
};

export const createForexAtts = (data) => {
  let atts = new ForexAttributes();

  if (!isNullOrUndefined(data.fxBaseCurrency)) {
    atts.setBaseCurrency(data.fxBaseCurrency);
  }

  if (!isNullOrUndefined(data.fxQuoteCurrency)) {
    atts.setQuoteCurrency(data.fxQuoteCurrency);
  }

  return atts;
};

export const setForexAtts = (source, destination) => {
  destination.type = FOREX;

  if (!isNullOrUndefined(source.baseCurrency)) {
    destination.fxBaseCurrency = source.baseCurrency;
  } else if (!isNullOrUndefined(source.fxBaseCurrency)) {
    destination.fxBaseCurrency = source.fxBaseCurrency;
  }

  if (!isNullOrUndefined(source.quoteCurrency)) {
    destination.fxQuoteCurrency = source.quoteCurrency;
  } else if (!isNullOrUndefined(source.fxQuoteCurrency)) {
    destination.fxQuoteCurrency = source.fxQuoteCurrency;
  }
};

export const createMultilegAtts = (data) => {
  let atts = new MultilegAttributes();

  if (!isNullOrUndefined(data.legsList) && Array.isArray(data.legsList)) {
    const mappedLegs = data.legsList.map((leg) => {
      let legAtts = new LegAttributes();
      legAtts.setSymbol(leg.symbol);
      legAtts.setRatio(convertScaledPriceToInt(leg.ratio, 1));
      legAtts.setOpposingSide(!!leg.opposingSide);
      return legAtts;
    });
    atts.setLegsList(mappedLegs);
  }

  return atts;
};

export const setMultilegAtts = (source, destination) => {
  destination.type = MULTILEG;
  destination.legsList = [];

  if (!isNullOrUndefined(source.legsList) && Array.isArray(source.legsList)) {
    source.legsList.forEach((leg) =>
      destination.legsList.push(JSON.parse(JSON.stringify(leg)))
    );
  }
};

export const setDefaultAtts = (destination) => {
  destination.type = DEFAULT;
};

function getDate(date) {
  if (date instanceof Date) {
    return date;
  }

  return new Date(date.year, date.month - 1, date.day);
}

export const WithEmptyOption = (data) => {
  if (isArray(data)) {
    data.splice(0, 0, { id: null, name: "" });
  }
  return data;
};

export const createFSIRSAtts = (data) => {
  let fsIRS = new ForwardStartingInterestRateSwapAttributes();

  if (!isNullOrUndefined(data.tenor)) {
    fsIRS.setTenor(data.tenor);
  }

  if (!isNullOrUndefined(data.sideConvention)) {
    fsIRS.setSideConvention(data.sideConvention);
  }

  if (!isNullOrUndefined(data.swapEffectiveDate)) {
    fsIRS.setSwapEffectiveDate(dateToProtoDate(data.swapEffectiveDate));
  }

  if (!isNullOrUndefined(data.effectiveDateBusinessDayConvention)) {
    fsIRS.setEffectiveDateBusinessDayConvention(
      data.effectiveDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.maturityDate)) {
    fsIRS.setMaturityDate(dateToProtoDate(data.maturityDate));
  }

  if (!isNullOrUndefined(data.maturityDateBusinessDayConvention)) {
    fsIRS.setMaturityDateBusinessDayConvention(
      data.maturityDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.rollAndPaymentDateHolidayCalendarsList)) {
    fsIRS.setRollAndPaymentDateHolidayCalendarsList(
        Array.isArray(data.rollAndPaymentDateHolidayCalendarsList)
            ? data.rollAndPaymentDateHolidayCalendarsList
            : [data.rollAndPaymentDateHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.fixedPaymentFrequency)) {
    fsIRS.setFixedPaymentFrequency(data.fixedPaymentFrequency);
  }

  if (!isNullOrUndefined(data.fixedCompoundingMethod)) {
    fsIRS.setFixedCompoundingMethod(data.fixedCompoundingMethod);
  }

  if (!isNullOrUndefined(data.fixedDayCountConvention)) {
    fsIRS.setFixedDayCountConvention(data.fixedDayCountConvention);
  }

  if (!isNullOrUndefined(data.fixedBusinessDayConvention)) {
    fsIRS.setFixedBusinessDayConvention(data.fixedBusinessDayConvention);
  }

  if (!isNullOrUndefined(data.floatCompoundingMethod)) {
    fsIRS.setFloatCompoundingMethod(data.floatCompoundingMethod);
  }

  if (!isNullOrUndefined(data.floatDayCountConvention)) {
    fsIRS.setFloatDayCountConvention(data.floatDayCountConvention);
  }

  if (!isNullOrUndefined(data.floatHolidayCalendarsList)) {
    fsIRS.setFloatHolidayCalendarsList(data.floatHolidayCalendarsList);
  }

  if (!isNullOrUndefined(data.floatPaymentFrequency)) {
    fsIRS.setFloatPaymentFrequency(data.floatPaymentFrequency);
  }

  if (!isNullOrUndefined(data.floatRateResetFrequency)) {
    fsIRS.setFloatRateResetFrequency(data.floatRateResetFrequency);
  }

  if (!isNullOrUndefined(data.floatResetDateBusinessDayConvention)) {
    fsIRS.setFloatResetDateBusinessDayConvention(
      data.floatResetDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.firstFloatResetDate)) {
    fsIRS.setFirstFloatResetDate(dateToProtoDate(data.firstFloatResetDate));
  }

  if (!isNullOrUndefined(data.firstFloatFixingDate)) {
    fsIRS.setFirstFloatFixingDate(dateToProtoDate(data.firstFloatFixingDate));
  }

  if (!isNullOrUndefined(data.floatingRateIndex)) {
    fsIRS.setFloatingRateIndex(data.floatingRateIndex);
  }

  if (!isNullOrUndefined(data.floatRollConvention)) {
    fsIRS.setFloatRollConvention(data.floatRollConvention);
  }

  if (!isNullOrUndefined(data.fixedRollConvention)) {
    fsIRS.setFixedRollConvention(data.fixedRollConvention);
  }

  if (!isNullOrUndefined(data.floatFirstUnadjustedPaymentDate)) {
    fsIRS.setFloatFirstUnadjustedPaymentDate(
      dateToProtoDate(data.floatFirstUnadjustedPaymentDate)
    );
  }

  if (!isNullOrUndefined(data.fixedFirstUnadjustedPaymentDate)) {
    fsIRS.setFixedFirstUnadjustedPaymentDate(
      dateToProtoDate(data.fixedFirstUnadjustedPaymentDate)
    );
  }

  if (!isNullOrUndefined(data.floatPaymentDateRelativeTo)) {
    fsIRS.setFloatPaymentDateRelativeTo(data.floatPaymentDateRelativeTo);
  }

  if (!isNullOrUndefined(data.unadjustedEffectiveDate)) {
    fsIRS.setUnadjustedEffectiveDate(
      dateToProtoDate(data.unadjustedEffectiveDate)
    );
  }

  if (!isNullOrUndefined(data.fixedPaymentDateRelativeTo)) {
    fsIRS.setFixedPaymentDateRelativeTo(data.fixedPaymentDateRelativeTo);
  }

  if (!isNullOrUndefined(data.floatFixingDatesDayType)) {
    fsIRS.setFloatFixingDatesDayType(data.floatFixingDatesDayType);
  }

  if (!isNullOrUndefined(data.floatFixingDatesOffset)) {
    fsIRS.setFloatFixingDatesOffset(data.floatFixingDatesOffset);
  }

  if (!isNullOrUndefined(data.floatingRateIndexTenor)) {
    fsIRS.setFloatingRateIndexTenor(data.floatingRateIndexTenor);
  }

  if (!isNullOrUndefined(data.unadjustedMaturityDate)) {
    fsIRS.setUnadjustedMaturityDate(
      dateToProtoDate(data.unadjustedMaturityDate)
    );
  }

  if (!isNullOrUndefined(data.effectiveDateOffset)) {
    fsIRS.setEffectiveDateOffset(data.effectiveDateOffset);
  }

  if (!isNullOrUndefined(data.floatResetDateRelativeTo)) {
    fsIRS.setFloatResetDateRelativeTo(data.floatResetDateRelativeTo);
  }

  if (!isNullOrUndefined(data.floatFixingDateBusinessDayConvention)) {
    fsIRS.setFloatFixingDateBusinessDayConvention(
      data.floatFixingDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.floatBusinessDayConvention)) {
    fsIRS.setFloatBusinessDayConvention(data.floatBusinessDayConvention);
  }

  if (!isNullOrUndefined(data.floatFixingDateHolidayCalendarsList)) {
    fsIRS.setFloatFixingDateHolidayCalendarsList(
        Array.isArray(data.floatFixingDateHolidayCalendarsList)
            ? data.floatFixingDateHolidayCalendarsList
            : [data.floatFixingDateHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.forwardStartingPeriod)) {
    fsIRS.setForwardStartingPeriod(data.forwardStartingPeriod);
  }

  return fsIRS;
};

export const setFsIRSAtts = (source, destination) => {
  destination.type = FORWARD_STARTING_INTEREST_RATE_SWAP;

  if (!isNullOrUndefined(source.tenor)) {
    destination.tenor = source.tenor;
  }

  if (!isNullOrUndefined(source.sideConvention)) {
    destination.sideConvention = source.sideConvention;
  }

  if (!isNullOrUndefined(source.swapEffectiveDate)) {
    destination.swapEffectiveDate = getDate(source.swapEffectiveDate);
  }

  if (!isNullOrUndefined(source.effectiveDateBusinessDayConvention)) {
    destination.effectiveDateBusinessDayConvention =
      source.effectiveDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.maturityDate)) {
    destination.maturityDate = getDate(source.maturityDate);
  }

  if (!isNullOrUndefined(source.maturityDateBusinessDayConvention)) {
    destination.maturityDateBusinessDayConvention =
      source.maturityDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.rollAndPaymentDateHolidayCalendarsList)) {
    destination.rollAndPaymentDateHolidayCalendarsList =
      source.rollAndPaymentDateHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.fixedPaymentFrequency)) {
    destination.fixedPaymentFrequency = source.fixedPaymentFrequency;
  }

  if (!isNullOrUndefined(source.fixedDayCountConvention)) {
    destination.fixedDayCountConvention = source.fixedDayCountConvention;
  }

  if (!isNullOrUndefined(source.fixedCompoundingMethod)) {
    destination.fixedCompoundingMethod = source.fixedCompoundingMethod;
  }

  if (!isNullOrUndefined(source.fixedBusinessDayConvention)) {
    destination.fixedBusinessDayConvention = source.fixedBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatCompoundingMethod)) {
    destination.floatCompoundingMethod = source.floatCompoundingMethod;
  }

  if (!isNullOrUndefined(source.floatDayCountConvention)) {
    destination.floatDayCountConvention = source.floatDayCountConvention;
  }

  if (!isNullOrUndefined(source.floatHolidayCalendarsList)) {
    destination.floatHolidayCalendarsList = source.floatHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.floatPaymentFrequency)) {
    destination.floatPaymentFrequency = source.floatPaymentFrequency;
  }

  if (!isNullOrUndefined(source.floatRateResetFrequency)) {
    destination.floatRateResetFrequency = source.floatRateResetFrequency;
  }

  if (!isNullOrUndefined(source.floatResetDateBusinessDayConvention)) {
    destination.floatResetDateBusinessDayConvention =
      source.floatResetDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.firstFloatResetDate)) {
    destination.firstFloatResetDate = getDate(source.firstFloatResetDate);
  }

  if (!isNullOrUndefined(source.firstFloatFixingDate)) {
    destination.firstFloatFixingDate = getDate(source.firstFloatFixingDate);
  }

  if (!isNullOrUndefined(source.floatingRateIndex)) {
    destination.floatingRateIndex = source.floatingRateIndex;
  }

  if (!isNullOrUndefined(source.floatRollConvention)) {
    destination.floatRollConvention = source.floatRollConvention;
  }

  if (!isNullOrUndefined(source.fixedRollConvention)) {
    destination.fixedRollConvention = source.fixedRollConvention;
  }

  if (!isNullOrUndefined(source.floatFirstUnadjustedPaymentDate)) {
    destination.floatFirstUnadjustedPaymentDate = getDate(
      source.floatFirstUnadjustedPaymentDate
    );
  }

  if (!isNullOrUndefined(source.fixedFirstUnadjustedPaymentDate)) {
    destination.fixedFirstUnadjustedPaymentDate = getDate(
      source.fixedFirstUnadjustedPaymentDate
    );
  }

  if (!isNullOrUndefined(source.floatPaymentDateRelativeTo)) {
    destination.floatPaymentDateRelativeTo = source.floatPaymentDateRelativeTo;
  }

  if (!isNullOrUndefined(source.unadjustedEffectiveDate)) {
    destination.unadjustedEffectiveDate = getDate(
      source.unadjustedEffectiveDate
    );
  }

  if (!isNullOrUndefined(source.fixedPaymentDateRelativeTo)) {
    destination.fixedPaymentDateRelativeTo = source.fixedPaymentDateRelativeTo;
  }

  if (!isNullOrUndefined(source.floatFixingDatesDayType)) {
    destination.floatFixingDatesDayType = source.floatFixingDatesDayType;
  }

  if (!isNullOrUndefined(source.floatFixingDatesOffset)) {
    destination.floatFixingDatesOffset = source.floatFixingDatesOffset;
  }

  if (!isNullOrUndefined(source.floatingRateIndexTenor)) {
    destination.floatingRateIndexTenor = source.floatingRateIndexTenor;
  }

  if (!isNullOrUndefined(source.unadjustedMaturityDate)) {
    destination.unadjustedMaturityDate = getDate(source.unadjustedMaturityDate);
  }

  if (!isNullOrUndefined(source.effectiveDateOffset)) {
    destination.effectiveDateOffset = source.effectiveDateOffset;
  }

  if (!isNullOrUndefined(source.floatResetDateRelativeTo)) {
    destination.floatResetDateRelativeTo = source.floatResetDateRelativeTo;
  }

  if (!isNullOrUndefined(source.floatFixingDateBusinessDayConvention)) {
    destination.floatFixingDateBusinessDayConvention =
      source.floatFixingDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatBusinessDayConvention)) {
    destination.floatBusinessDayConvention = source.floatBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatFixingDateHolidayCalendarsList)) {
    destination.floatFixingDateHolidayCalendarsList =
      source.floatFixingDateHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.forwardStartingPeriod)) {
    destination.forwardStartingPeriod = source.forwardStartingPeriod;
  }
};

export const createOISAtts = (data) => {
  let atts = new OvernightIndexSwapAttributes();

  /* Swap Information Attributes */
  if (!isNullOrUndefined(data.tenor)) {
    atts.setTenor(data.tenor);
  }

  if (!isNullOrUndefined(data.tenorTimePeriod)) {
    atts.setTenorTimePeriod(data.tenorTimePeriod);
  }

  if (!isNullOrUndefined(data.sideConvention)) {
    atts.setSideConvention(data.sideConvention);
  }

  if (!isNullOrUndefined(data.swapEffectiveDate)) {
    atts.setSwapEffectiveDate(dateToProtoDate(data.swapEffectiveDate));
  }

  if (!isNullOrUndefined(data.effectiveDateBusinessDayConvention)) {
    atts.setEffectiveDateBusinessDayConvention(
      data.effectiveDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.maturityDate)) {
    atts.setMaturityDate(dateToProtoDate(data.maturityDate));
  }

  if (!isNullOrUndefined(data.maturityDateBusinessDayConvention)) {
    atts.setMaturityDateBusinessDayConvention(
      data.maturityDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.forwardStartingPeriod)) {
    atts.setForwardStartingPeriod(data.forwardStartingPeriod);
  }

  if (!isNullOrUndefined(data.forwardStartingPeriodTimePeriod)) {
    atts.setForwardStartingPeriodTimePeriod(data.forwardStartingPeriodTimePeriod);
  }

  if (!isNullOrUndefined(data.fixedHolidayCalendarsList)) {
    atts.setFixedHolidayCalendarsList(
      Array.isArray(data.fixedHolidayCalendarsList)
        ? data.fixedHolidayCalendarsList
        : [data.fixedHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.fixedPaymentFrequency)) {
    atts.setFixedPaymentFrequency(data.fixedPaymentFrequency);
  }
  if (!isNullOrUndefined(data.fixedCompoundingMethod)) {
    atts.setFixedCompoundingMethod(data.fixedCompoundingMethod);
  }

  if (!isNullOrUndefined(data.fixedDayCountConvention)) {
    atts.setFixedDayCountConvention(data.fixedDayCountConvention);
  }
  if (!isNullOrUndefined(data.fixedBusinessDayConvention)) {
    atts.setFixedBusinessDayConvention(data.fixedBusinessDayConvention);
  }

  if (!isNullOrUndefined(data.floatDayCountConvention)) {
    atts.setFloatDayCountConvention(data.floatDayCountConvention);
  }
  if (!isNullOrUndefined(data.floatHolidayCalendarsList)) {
    atts.setFloatHolidayCalendarsList(
      Array.isArray(data.floatHolidayCalendarsList)
        ? data.floatHolidayCalendarsList
        : [data.floatHolidayCalendarsList]
    );
  }
  if (!isNullOrUndefined(data.floatPaymentFrequency)) {
    atts.setFloatPaymentFrequency(data.floatPaymentFrequency);
  }
  if (!isNullOrUndefined(data.floatRateResetFrequency)) {
    atts.setFloatRateResetFrequency(data.floatRateResetFrequency);
  }
  if (!isNullOrUndefined(data.floatResetDateBusinessDayConvention)) {
    atts.setFloatResetDateBusinessDayConvention(
      data.floatResetDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.firstFloatResetDate)) {
    atts.setFirstFloatResetDate(dateToProtoDate(data.firstFloatResetDate));
  }
  if (!isNullOrUndefined(data.firstFloatFixingDate)) {
    atts.setFirstFloatFixingDate(dateToProtoDate(data.firstFloatFixingDate));
  }
  if (!isNullOrUndefined(data.floatingRateIndex)) {
    atts.setFloatingRateIndex(data.floatingRateIndex);
  }
  if (!isNullOrUndefined(data.floatRollConvention)) {
    atts.setFloatRollConvention(data.floatRollConvention);
  }
  if (!isNullOrUndefined(data.fixedRollConvention)) {
    atts.setFixedRollConvention(data.fixedRollConvention);
  }
  if (!isNullOrUndefined(data.floatFirstUnadjustedPaymentDate)) {
    atts.setFloatFirstUnadjustedPaymentDate(
      dateToProtoDate(data.floatFirstUnadjustedPaymentDate)
    );
  }
  if (!isNullOrUndefined(data.fixedFirstUnadjustedPaymentDate)) {
    atts.setFixedFirstUnadjustedPaymentDate(
      dateToProtoDate(data.fixedFirstUnadjustedPaymentDate)
    );
  }
  if (!isNullOrUndefined(data.floatPaymentDateRelativeTo)) {
    atts.setFloatPaymentDateRelativeTo(data.floatPaymentDateRelativeTo);
  }
  if (!isNullOrUndefined(data.fixedPaymentDateRelativeTo)) {
    atts.setFixedPaymentDateRelativeTo(data.fixedPaymentDateRelativeTo);
  }
  if (!isNullOrUndefined(data.floatFixingDatesDayType)) {
    atts.setFloatFixingDatesDayType(data.floatFixingDatesDayType);
  }
  if (!isNullOrUndefined(data.floatFixingDatesOffset)) {
    atts.setFloatFixingDatesOffset(data.floatFixingDatesOffset);
  }
  if (!isNullOrUndefined(data.floatingRateIndexTenor)) {
    atts.setFloatingRateIndexTenor(data.floatingRateIndexTenor);
  }
  if (!isNullOrUndefined(data.unadjustedEffectiveDate)) {
    atts.setUnadjustedEffectiveDate(
      dateToProtoDate(data.unadjustedEffectiveDate)
    );
  }
  if (!isNullOrUndefined(data.unadjustedMaturityDate)) {
    atts.setUnadjustedMaturityDate(
      dateToProtoDate(data.unadjustedMaturityDate)
    );
  }
  if (!isNullOrUndefined(data.effectiveDateOffset)) {
    atts.setEffectiveDateOffset(data.effectiveDateOffset);
  }
  if (!isNullOrUndefined(data.floatResetDateRelativeTo)) {
    atts.setFloatResetDateRelativeTo(data.floatResetDateRelativeTo);
  }
  if (!isNullOrUndefined(data.floatFixingDateBusinessDayConvention)) {
    atts.setFloatFixingDateBusinessDayConvention(
      data.floatFixingDateBusinessDayConvention
    );
  }
  if (!isNullOrUndefined(data.floatBusinessDayConvention)) {
    atts.setFloatBusinessDayConvention(data.floatBusinessDayConvention);
  }
  if (!isNullOrUndefined(data.floatFixingDateHolidayCalendarsList)) {
    atts.setFloatFixingDateHolidayCalendarsList(
      Array.isArray(data.floatFixingDateHolidayCalendarsList)
        ? data.floatFixingDateHolidayCalendarsList
        : [data.floatFixingDateHolidayCalendarsList]
    );
  }
  if (!isNullOrUndefined(data.fixedPaymentDateOffset)) {
    atts.setFixedPaymentDateOffset(data.fixedPaymentDateOffset);
  }
  if (!isNullOrUndefined(data.floatPaymentDateOffset)) {
    atts.setFloatPaymentDateOffset(data.floatPaymentDateOffset);
  }
  if (!isNullOrUndefined(data.paymentDateDayType)) {
    atts.setPaymentDateDayType(data.paymentDateDayType);
  }
  if (!isNullOrUndefined(data.floatCompoundingMethod)) {
    atts.setFloatCompoundingMethod(data.floatCompoundingMethod);
  }

  return atts;
};

export const setOISAtts = (source, destination) => {
  destination.type = OVER_NIGHT_INDEX_SWAP;

  if (!isNullOrUndefined(source.tenor)) {
    destination.tenor = source.tenor;
  }

  if (!isNullOrUndefined(source.tenorTimePeriod)) {
    destination.tenorTimePeriod = source.tenorTimePeriod;
  }

  if (!isNullOrUndefined(source.sideConvention)) {
    destination.sideConvention = source.sideConvention;
  }

  if (!isNullOrUndefined(source.swapEffectiveDate)) {
    destination.swapEffectiveDate = getDate(source.swapEffectiveDate);
  }

  if (!isNullOrUndefined(source.effectiveDateBusinessDayConvention)) {
    destination.effectiveDateBusinessDayConvention =
      source.effectiveDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.maturityDate)) {
    destination.maturityDate = getDate(source.maturityDate);
  }

  if (!isNullOrUndefined(source.maturityDateBusinessDayConvention)) {
    destination.maturityDateBusinessDayConvention =
      source.maturityDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.fixedHolidayCalendarsList)) {
    destination.fixedHolidayCalendarsList = source.fixedHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.fixedPaymentFrequency)) {
    destination.fixedPaymentFrequency = source.fixedPaymentFrequency;
  }

  if (!isNullOrUndefined(source.fixedCompoundingMethod)) {
    destination.fixedCompoundingMethod = source.fixedCompoundingMethod;
  }

  if (!isNullOrUndefined(source.fixedDayCountConvention)) {
    destination.fixedDayCountConvention = source.fixedDayCountConvention;
  }

  if (!isNullOrUndefined(source.forwardStartingPeriod)) {
    destination.forwardStartingPeriod = source.forwardStartingPeriod;
  }

  if (!isNullOrUndefined(source.forwardStartingPeriodTimePeriod)) {
    destination.forwardStartingPeriodTimePeriod = source.forwardStartingPeriodTimePeriod;
  }

  //
  // Fixed Leg Attributes
  //
  if (!isNullOrUndefined(source.fixedBusinessDayConvention)) {
    destination.fixedBusinessDayConvention = source.fixedBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatCompoundingMethod)) {
    destination.floatCompoundingMethod = source.floatCompoundingMethod;
  }

  if (!isNullOrUndefined(source.floatDayCountConvention)) {
    destination.floatDayCountConvention = source.floatDayCountConvention;
  }

  if (!isNullOrUndefined(source.floatHolidayCalendarsList)) {
    destination.floatHolidayCalendarsList = source.floatHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.floatPaymentFrequency)) {
    destination.floatPaymentFrequency = source.floatPaymentFrequency;
  }

  if (!isNullOrUndefined(source.floatRateResetFrequency)) {
    destination.floatRateResetFrequency = source.floatRateResetFrequency;
  }

  if (!isNullOrUndefined(source.floatResetDateBusinessDayConvention)) {
    destination.floatResetDateBusinessDayConvention =
      source.floatResetDateBusinessDayConvention;
  }

  //
  // Floating Leg Attributes
  //
  if (!isNullOrUndefined(source.firstFloatResetDate)) {
    destination.firstFloatResetDate = getDate(source.firstFloatResetDate);
  }

  if (!isNullOrUndefined(source.firstFloatFixingDate)) {
    destination.firstFloatFixingDate = getDate(source.firstFloatFixingDate);
  }

  if (!isNullOrUndefined(source.floatingRateIndex)) {
    destination.floatingRateIndex = source.floatingRateIndex;
  }

  if (!isNullOrUndefined(source.floatRollConvention)) {
    destination.floatRollConvention = source.floatRollConvention;
  }

  if (!isNullOrUndefined(source.fixedRollConvention)) {
    destination.fixedRollConvention = source.fixedRollConvention;
  }

  if (!isNullOrUndefined(source.floatFirstUnadjustedPaymentDate)) {
    destination.floatFirstUnadjustedPaymentDate = getDate(
      source.floatFirstUnadjustedPaymentDate
    );
  }

  if (!isNullOrUndefined(source.fixedFirstUnadjustedPaymentDate)) {
    destination.fixedFirstUnadjustedPaymentDate = getDate(
      source.fixedFirstUnadjustedPaymentDate
    );
  }

  if (!isNullOrUndefined(source.floatPaymentDateRelativeTo)) {
    destination.floatPaymentDateRelativeTo = source.floatPaymentDateRelativeTo;
  }

  if (!isNullOrUndefined(source.fixedPaymentDateRelativeTo)) {
    destination.fixedPaymentDateRelativeTo = source.fixedPaymentDateRelativeTo;
  }

  if (!isNullOrUndefined(source.floatFixingDatesDayType)) {
    destination.floatFixingDatesDayType = source.floatFixingDatesDayType;
  }

  if (!isNullOrUndefined(source.floatFixingDatesOffset)) {
    destination.floatFixingDatesOffset = source.floatFixingDatesOffset;
  }

  if (!isNullOrUndefined(source.floatingRateIndexTenor)) {
    destination.floatingRateIndexTenor = source.floatingRateIndexTenor;
  }

  if (!isNullOrUndefined(source.unadjustedEffectiveDate)) {
    destination.unadjustedEffectiveDate = getDate(
      source.unadjustedEffectiveDate
    );
  }
  if (!isNullOrUndefined(source.unadjustedMaturityDate)) {
    destination.unadjustedMaturityDate = getDate(source.unadjustedMaturityDate);
  }

  if (!isNullOrUndefined(source.effectiveDateOffset)) {
    destination.effectiveDateOffset = source.effectiveDateOffset;
  }

  if (!isNullOrUndefined(source.floatResetDateRelativeTo)) {
    destination.floatResetDateRelativeTo = source.floatResetDateRelativeTo;
  }

  if (!isNullOrUndefined(source.floatFixingDateBusinessDayConvention)) {
    destination.floatFixingDateBusinessDayConvention =
      source.floatFixingDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatBusinessDayConvention)) {
    destination.floatBusinessDayConvention = source.floatBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatFixingDateHolidayCalendarsList)) {
    destination.floatFixingDateHolidayCalendarsList =
      source.floatFixingDateHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.fixedPaymentDateOffset)) {
    destination.fixedPaymentDateOffset = source.fixedPaymentDateOffset;
  }

  if (!isNullOrUndefined(source.floatPaymentDateOffset)) {
    destination.floatPaymentDateOffset = source.floatPaymentDateOffset;
  }

  if (!isNullOrUndefined(source.paymentDateDayType)) {
    destination.paymentDateDayType = source.paymentDateDayType;
  }
};

export const createSpsAtts = (data) => {
  let sps = new SinglePeriodSwapAttributes();

  if (!isNullOrUndefined(data.tenor)) {
    sps.setTenor(data.tenor);
  }

  if (!isNullOrUndefined(data.sideConvention)) {
    sps.setSideConvention(data.sideConvention);
  }

  if (!isNullOrUndefined(data.swapEffectiveDate)) {
    sps.setSwapEffectiveDate(dateToProtoDate(data.swapEffectiveDate));
  }

  if (!isNullOrUndefined(data.effectiveDateBusinessDayConvention)) {
    sps.setEffectiveDateBusinessDayConvention(
      data.effectiveDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.maturityDate)) {
    sps.setMaturityDate(dateToProtoDate(data.maturityDate));
  }

  if (!isNullOrUndefined(data.maturityDateBusinessDayConvention)) {
    sps.setMaturityDateBusinessDayConvention(
      data.maturityDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.rollAndPaymentDateHolidayCalendarsList)) {
    sps.setRollAndPaymentDateHolidayCalendarsList(
        Array.isArray(data.rollAndPaymentDateHolidayCalendarsList)
            ? data.rollAndPaymentDateHolidayCalendarsList
            : [data.rollAndPaymentDateHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.fixedPaymentFrequency)) {
    sps.setFixedPaymentFrequency(data.fixedPaymentFrequency);
  }

  if (!isNullOrUndefined(data.fixedCompoundingMethod)) {
    sps.setFixedCompoundingMethod(data.fixedCompoundingMethod);
  }

  if (!isNullOrUndefined(data.fixedDayCountConvention)) {
    sps.setFixedDayCountConvention(data.fixedDayCountConvention);
  }

  if (!isNullOrUndefined(data.fixedBusinessDayConvention)) {
    sps.setFixedBusinessDayConvention(data.fixedBusinessDayConvention);
  }

  if (!isNullOrUndefined(data.floatCompoundingMethod)) {
    sps.setFloatCompoundingMethod(data.floatCompoundingMethod);
  }

  if (!isNullOrUndefined(data.floatDayCountConvention)) {
    sps.setFloatDayCountConvention(data.floatDayCountConvention);
  }

  if (!isNullOrUndefined(data.floatHolidayCalendarsList)) {
    sps.setFloatHolidayCalendarsList(
        Array.isArray(data.floatHolidayCalendarsList)
            ? data.floatHolidayCalendarsList
            : [data.floatHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.floatPaymentFrequency)) {
    sps.setFloatPaymentFrequency(data.floatPaymentFrequency);
  }

  if (!isNullOrUndefined(data.floatRateResetFrequency)) {
    sps.setFloatRateResetFrequency(data.floatRateResetFrequency);
  }

  if (!isNullOrUndefined(data.floatResetDateBusinessDayConvention)) {
    sps.setFloatResetDateBusinessDayConvention(
      data.floatResetDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.firstFloatResetDate)) {
    sps.setFirstFloatResetDate(dateToProtoDate(data.firstFloatResetDate));
  }

  if (!isNullOrUndefined(data.firstFloatFixingDate)) {
    sps.setFirstFloatFixingDate(dateToProtoDate(data.firstFloatFixingDate));
  }

  if (!isNullOrUndefined(data.floatingRateIndex)) {
    sps.setFloatingRateIndex(data.floatingRateIndex);
  }

  if (!isNullOrUndefined(data.floatRollConvention)) {
    sps.setFloatRollConvention(data.floatRollConvention);
  }

  if (!isNullOrUndefined(data.fixedRollConvention)) {
    sps.setFixedRollConvention(data.fixedRollConvention);
  }

  if (!isNullOrUndefined(data.floatFirstUnadjustedPaymentDate)) {
    sps.setFloatFirstUnadjustedPaymentDate(
      dateToProtoDate(data.floatFirstUnadjustedPaymentDate)
    );
  }

  if (!isNullOrUndefined(data.fixedFirstUnadjustedPaymentDate)) {
    sps.setFixedFirstUnadjustedPaymentDate(
      dateToProtoDate(data.fixedFirstUnadjustedPaymentDate)
    );
  }

  if (!isNullOrUndefined(data.floatPaymentDateRelativeTo)) {
    sps.setFloatPaymentDateRelativeTo(data.floatPaymentDateRelativeTo);
  }

  if (!isNullOrUndefined(data.unadjustedEffectiveDate)) {
    sps.setUnadjustedEffectiveDate(
      dateToProtoDate(data.unadjustedEffectiveDate)
    );
  }

  if (!isNullOrUndefined(data.fixedPaymentDateRelativeTo)) {
    sps.setFixedPaymentDateRelativeTo(data.fixedPaymentDateRelativeTo);
  }

  if (!isNullOrUndefined(data.floatFixingDatesDayType)) {
    sps.setFloatFixingDatesDayType(data.floatFixingDatesDayType);
  }

  if (!isNullOrUndefined(data.floatFixingDatesOffset)) {
    sps.setFloatFixingDatesOffset(data.floatFixingDatesOffset);
  }

  if (!isNullOrUndefined(data.floatingRateIndexTenor)) {
    sps.setFloatingRateIndexTenor(data.floatingRateIndexTenor);
  }

  if (!isNullOrUndefined(data.unadjustedMaturityDate)) {
    sps.setUnadjustedMaturityDate(
      dateToProtoDate(data.unadjustedMaturityDate)
    );
  }

  if (!isNullOrUndefined(data.floatResetDateRelativeTo)) {
    sps.setFloatResetDateRelativeTo(data.floatResetDateRelativeTo);
  }

  if (!isNullOrUndefined(data.floatFixingDateBusinessDayConvention)) {
    sps.setFloatFixingDateBusinessDayConvention(
      data.floatFixingDateBusinessDayConvention
    );
  }

  if (!isNullOrUndefined(data.floatBusinessDayConvention)) {
    sps.setFloatBusinessDayConvention(data.floatBusinessDayConvention);
  }

  if (!isNullOrUndefined(data.floatFixingDateHolidayCalendarsList)) {
    sps.setFloatFixingDateHolidayCalendarsList(
        Array.isArray(data.floatFixingDateHolidayCalendarsList)
            ? data.floatFixingDateHolidayCalendarsList
            : [data.floatFixingDateHolidayCalendarsList]
    );
  }

  if (!isNullOrUndefined(data.tenorTimePeriod)) {
    sps.setTenorTimePeriod(data.tenorTimePeriod);
  }

  return sps;
};

export const setSpsAtts = (source, destination) => {
  destination.type = SINGLE_PERIOD_SWAP;

  if (!isNullOrUndefined(source.tenor)) {
    destination.tenor = source.tenor;
  }

  if (!isNullOrUndefined(source.sideConvention)) {
    destination.sideConvention = source.sideConvention;
  }

  if (!isNullOrUndefined(source.swapEffectiveDate)) {
    destination.swapEffectiveDate = getDate(source.swapEffectiveDate);
  }

  if (!isNullOrUndefined(source.effectiveDateBusinessDayConvention)) {
    destination.effectiveDateBusinessDayConvention =
      source.effectiveDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.maturityDate)) {
    destination.maturityDate = getDate(source.maturityDate);
  }

  if (!isNullOrUndefined(source.maturityDateBusinessDayConvention)) {
    destination.maturityDateBusinessDayConvention =
      source.maturityDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.rollAndPaymentDateHolidayCalendarsList)) {
    destination.rollAndPaymentDateHolidayCalendarsList =
      source.rollAndPaymentDateHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.fixedPaymentFrequency)) {
    destination.fixedPaymentFrequency = source.fixedPaymentFrequency;
  }

  if (!isNullOrUndefined(source.fixedCompoundingMethod)) {
    destination.fixedCompoundingMethod = source.fixedCompoundingMethod;
  }

  if (!isNullOrUndefined(source.fixedDayCountConvention)) {
    destination.fixedDayCountConvention = source.fixedDayCountConvention;
  }

  if (!isNullOrUndefined(source.fixedBusinessDayConvention)) {
    destination.fixedBusinessDayConvention = source.fixedBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatCompoundingMethod)) {
    destination.floatCompoundingMethod = source.floatCompoundingMethod;
  }

  if (!isNullOrUndefined(source.floatDayCountConvention)) {
    destination.floatDayCountConvention = source.floatDayCountConvention;
  }

  if (!isNullOrUndefined(source.floatHolidayCalendarsList)) {
    destination.floatHolidayCalendarsList = source.floatHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.floatPaymentFrequency)) {
    destination.floatPaymentFrequency = source.floatPaymentFrequency;
  }

  if (!isNullOrUndefined(source.floatRateResetFrequency)) {
    destination.floatRateResetFrequency = source.floatRateResetFrequency;
  }

  if (!isNullOrUndefined(source.floatResetDateBusinessDayConvention)) {
    destination.floatResetDateBusinessDayConvention =
      source.floatResetDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.firstFloatResetDate)) {
    destination.firstFloatResetDate = getDate(source.firstFloatResetDate);
  }

  if (!isNullOrUndefined(source.firstFloatFixingDate)) {
    destination.firstFloatFixingDate = getDate(source.firstFloatFixingDate);
  }

  if (!isNullOrUndefined(source.floatingRateIndex)) {
    destination.floatingRateIndex = source.floatingRateIndex;
  }

  if (!isNullOrUndefined(source.floatRollConvention)) {
    destination.floatRollConvention = source.floatRollConvention;
  }

  if (!isNullOrUndefined(source.fixedRollConvention)) {
    destination.fixedRollConvention = source.fixedRollConvention;
  }

  if (!isNullOrUndefined(source.floatFirstUnadjustedPaymentDate)) {
    destination.floatFirstUnadjustedPaymentDate = getDate(
      source.floatFirstUnadjustedPaymentDate
    );
  }

  if (!isNullOrUndefined(source.fixedFirstUnadjustedPaymentDate)) {
    destination.fixedFirstUnadjustedPaymentDate = getDate(
      source.fixedFirstUnadjustedPaymentDate
    );
  }

  if (!isNullOrUndefined(source.floatPaymentDateRelativeTo)) {
    destination.floatPaymentDateRelativeTo = source.floatPaymentDateRelativeTo;
  }

  if (!isNullOrUndefined(source.fixedPaymentDateRelativeTo)) {
    destination.fixedPaymentDateRelativeTo = source.fixedPaymentDateRelativeTo;
  }


  if (!isNullOrUndefined(source.floatFixingDatesDayType)) {
    destination.floatFixingDatesDayType = source.floatFixingDatesDayType;
  }


  if (!isNullOrUndefined(source.floatFixingDatesOffset)) {
    destination.floatFixingDatesOffset = source.floatFixingDatesOffset;
  }

  if (!isNullOrUndefined(source.floatingRateIndexTenor)) {
    destination.floatingRateIndexTenor = source.floatingRateIndexTenor;
  }

  if (!isNullOrUndefined(source.unadjustedEffectiveDate)) {
    destination.unadjustedEffectiveDate = getDate(
      source.unadjustedEffectiveDate
    );
  }

  if (!isNullOrUndefined(source.unadjustedMaturityDate)) {
    destination.unadjustedMaturityDate = getDate(source.unadjustedMaturityDate);
  }

  if (!isNullOrUndefined(source.floatResetDateRelativeTo)) {
    destination.floatResetDateRelativeTo = source.floatResetDateRelativeTo;
  }

  if (!isNullOrUndefined(source.floatFixingDateBusinessDayConvention)) {
    destination.floatFixingDateBusinessDayConvention =
      source.floatFixingDateBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatBusinessDayConvention)) {
    destination.floatBusinessDayConvention = source.floatBusinessDayConvention;
  }

  if (!isNullOrUndefined(source.floatFixingDateHolidayCalendarsList)) {
    destination.floatFixingDateHolidayCalendarsList =
      source.floatFixingDateHolidayCalendarsList;
  }

  if (!isNullOrUndefined(source.tenorTimePeriod)) {
    destination.tenorTimePeriod = source.tenorTimePeriod;
  }
};


export const copyProps = (source, destination) => {
  Object.keys(source).forEach((key) => {
    destination[key] = source[key];
  });
};
