import React, { Component } from "react";
import { connect } from "react-redux";
import { GetUserLicense } from "../../actions/accounts";
import "./UserLicenseBanner.css";

class UserLicenseBanner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showBanner: this.showLicenseBanner()
    };
  }

  componentDidMount() {
    this.props.getUserLicense();
  }

  hideBanner = () => {
    this.setState({ showBanner: false });
    localStorage.setItem("showLicenseBanner", false);
  }

  showLicenseBanner = () => {
    let showBanner = localStorage.getItem("showLicenseBanner");

    if (showBanner === 'false') return false;

    return true;
  }

  render() {
    const userLicenseErr = this.props.userLicenseErr ? this.props.userLicenseErr : "";
    const show = !!this.state.showBanner && !!this.props.userLicenseErr;
    return (<>
      {show && <div>
        <div className="banner">
          <span class="bannerCloseBtn" onClick={() => this.hideBanner()}>&times;</span>
          {userLicenseErr?.indexOf("license has expired") >= 0 ? "EP3 License has Expired." : userLicenseErr}
        </div>
      </div>}
    </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLicense: state.accounts.userLicense,
    userLicenseErr: state.accounts.userLicenseErr
  };
}

const mapDispatchToProps = (dispatch) => ({
  getUserLicense: () => {
    dispatch(GetUserLicense());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLicenseBanner)