import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormLabel
} from 'react-bootstrap';

import './OrderLimit.css';

const OrderLimitReadOnly = ({
  lowLimitValue,
  highLimitValue,
  setLowLimit,
  setHighLimit,
}) => {
  return (
    <FormGroup id="">
      <FormLabel>Order Limits</FormLabel>
      <div>
        <div className="form-readonly-label order-limit-readonly-field">{lowLimitValue || 0}</div>
        <div className="form-readonly-label order-limit-readonly-field">{highLimitValue || 0}</div>
      </div>
      <div>
        <div className="order-limit-readonly-field-text">Low Limit {setLowLimit ? '' : 'Not'} Set</div>
        <div className="order-limit-readonly-field-text">High Limit {setHighLimit ? '' : 'Not'} Set</div>
      </div>
    </FormGroup>
  )
}

OrderLimitReadOnly.propTypes = {
  lowLimitValue: PropTypes.number,
  highLimitValue: PropTypes.number,
  setLowLimit: PropTypes.bool,
  setHighLimit: PropTypes.bool
}

export default OrderLimitReadOnly;