import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import DataGridActions from '../core/data-grid/DataGridActions';
import {hasWriteAccess} from "../../services/TokenService";

const CronJobsDataGrid = ({
  data,
  onRun,
}) => {
  let columns = [
    {
      Header: 'NAME ID',
      accessor: 'name',
      minWidth: 300,
      sortable: true,
      filterable: true,
    }
  ];
  if(hasWriteAccess()) {
      columns.push({
          Header: 'RUN CRON',
          maxWidth: 150,
          minWidth: 150,
          sortable: false,
          filterable: false,
          Cell: row => (
              <DataGridActions
                  onState={() => { onRun(row.original.name) }}
              >
              </DataGridActions>
          )
      });
  }
    return (
        <div>
            <DataGrid
                data={data}
                columns={columns}
                filterable={true}
                defaultSortedField="name"
            >
            </DataGrid>
        </div>
    )
};

CronJobsDataGrid.propTypes = {
    data: PropTypes.array.isRequired,
    onRun: PropTypes.func.isRequired,
};

export default CronJobsDataGrid;
