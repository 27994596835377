import {
  FETCH_BOOKS_START,
  FETCH_BOOKS_SUCCEED,
  FETCH_BOOKS_FAILED,
  SEARCH_BOOKS_NO_ITEMS,
  RESET_BOOKS,
} from "../constants/bookTypes";
import { StatusCode } from "grpc-web";
import _ from "lodash";
import {parsePrice, convertDateToString, capitalizeWord, getEnumName, isNullOrUndefined} from "../modules/util";

import BookService from "../services/BookService";
import InstrumentService from "../services/InstrumentService";
import Notification from "../modules/notifications";
import Book from "../entities/Book";
import BookStatus from "../entities/BookStatus"
import Instrument from "../entities/Instrument";
import UnaggregatedBookGridItem from "../entities/dto/UnaggregatedBookGridItem";
import { tsProtoObjToDate } from "../actions/ptypes";
import { OrderType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import {SettlementPriceCalculationMethod} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";

const getItems = (instrument, snapBids, snapOffers) => {
  let items = [];
  const bids = _.clone(snapBids);
  const offers = _.clone(snapOffers);
  const bidsAmount = bids.length;
  const offersAmount = offers.length;

  const iterations = Math.max(bidsAmount, offersAmount);

  for (let i = 0; iterations > i; i++) {
    let buyer = "";
    let qtyToBuy = "";
    let priceToBuy = "";
    let stopBuyPrice = "";
    let priceToSell = "";
    let stopSellPrice = "";
    let qtyToSell = "";
    let seller = "";
    let sellorderid = "";
    let buyorderid = "";
    let sellOrdType = OrderType.ORDER_TYPE_UNDEFINED;
    let buyOrdType = OrderType.ORDER_TYPE_UNDEFINED;
    let buyAccount = "";
    let sellAccount = "";

    if (bids[i]) {
      buyorderid = bids[i].id;
      buyer = bids[i].participant;
      qtyToBuy = parsePrice(bids[i].status.leavesQty, instrument.fractionalQtyScale);
      priceToBuy = parsePrice(bids[i].price, instrument.priceScale);
      stopBuyPrice = parsePrice(bids[i].stopPrice, instrument.priceScale);
      buyOrdType = bids[i].type.id;
      buyAccount = bids[i].account;
    }

    if (offers[i]) {
      sellorderid = offers[i].id;
      seller = offers[i].participant;
      qtyToSell = parsePrice(offers[i].status.leavesQty, instrument.fractionalQtyScale);
      priceToSell = parsePrice(offers[i].price, instrument.priceScale);
      stopSellPrice = parsePrice(offers[i].stopPrice, instrument.priceScale);
      sellOrdType = offers[i].type.id;
      sellAccount = offers[i].account;
    }

    items.push(
      new UnaggregatedBookGridItem({
        buyorderid,
        buyOrdType,
        sellorderid,
        sellOrdType,
        buyer,
        qtyToBuy,
        priceToBuy,
        stopBuyPrice,
        priceToSell,
        stopSellPrice,
        qtyToSell,
        seller,
        buyAccount,
        sellAccount,
      })
    );
  }
  return items;
};

const convertDtToStr = (value) => {
  return convertDateToString(value, "YYYYMMDD-HH:mm:ss.SSS");
}

const parseStats = (stats, instrument) => {
  const pxScale = instrument.priceScale !== 0 ? instrument.priceScale : 1;
  const qtyScale = instrument.fractionalQtyScale !== 0 ? instrument.fractionalQtyScale : 1;
  return {
    openPx: parsePrice(stats.openPx, pxScale),
    indicativeOpenPx: parsePrice(stats.indicativeOpenPx, pxScale),
    closePx: parsePrice(stats.closePx, pxScale),
    lowPx: parsePrice(stats.lowPx, pxScale),
    lastTradePx: parsePrice(stats.lastTradePx, pxScale),
    lastTradeQty: parsePrice(stats.lastTradeQty, qtyScale),
    highPx: parsePrice(stats.highPx, pxScale),
    settlementPx: parsePrice(stats.settlementPx, pxScale),
    sharesTraded: parsePrice(stats.sharesTraded, qtyScale),
    notionalTraded: parsePrice(stats.notionalTraded, pxScale * qtyScale),
    openInterest: parsePrice(stats.openInterest, qtyScale),
    openSetTime: stats.openSetTime ? convertDtToStr(tsProtoObjToDate(stats.openSetTime)) : "",
    closeSetTime: stats.closeSetTime ? convertDtToStr(tsProtoObjToDate(stats.closeSetTime)) : "",
    highSetTime: stats.highSetTime ? convertDtToStr(tsProtoObjToDate(stats.highSetTime)) : "",
    lowSetTime: stats.lowSetTime ? convertDtToStr(tsProtoObjToDate(stats.lowSetTime)) : "",
    lastTradeSetTime: stats.lastTradeSetTime ? convertDtToStr(tsProtoObjToDate(stats.lastTradeSetTime)) : "",
    notionalSetTime: stats.notionalSetTime ? convertDtToStr(tsProtoObjToDate(stats.notionalSetTime)) : "",
    indicativeOpenSetTime: stats.indicativeOpenSetTime ? convertDtToStr(tsProtoObjToDate(stats.indicativeOpenSetTime)) : "",
    settlementSetTime: stats.settlementSetTime ? convertDtToStr(tsProtoObjToDate(stats.settlementSetTime)) : "",
    openInterestSetTime: stats.openInterestSetTime ? convertDtToStr(tsProtoObjToDate(stats.openInterestSetTime)) : "",
    settlementPreliminary: stats.settlementPreliminaryFlag,
    settlementPriceCalculationMethod: !isNullOrUndefined(stats.settlementPriceCalculationMethod) ? capitalizeWord(getEnumName(SettlementPriceCalculationMethod, stats.settlementPriceCalculationMethod)) : "",
  };
};

export function getUnaggregatedBooks(symbol) {
  return (dispatch) => {
    dispatch({ type: FETCH_BOOKS_START });
    const cb1 = (err, response) => {
      if (response) {
        const instrument = new Instrument(response.getInstrument());

        const cb2 = (err, response) => {
          if (response) {
            const unaggregatedBook = new Book(response);
            dispatch({
              type: FETCH_BOOKS_SUCCEED,
              payload: {
                limit: getItems(instrument,
                  unaggregatedBook.snapshot.bids,
                  unaggregatedBook.snapshot.offers,
                ),
                stop: getItems(instrument,
                  unaggregatedBook.snapshot.stopBids,
                  unaggregatedBook.snapshot.stopOffers,
                ),
                stats: parseStats(unaggregatedBook.snapshot.status.stats, instrument),
                status: {
                  state: unaggregatedBook.snapshot.status.state,
                  transactTime: (!!unaggregatedBook.snapshot.status && !!unaggregatedBook.snapshot.status.transactTime) ? "" : unaggregatedBook.snapshot.status.transactTime,
                },
              },
            });
          }

          if (err) {
            dispatch({ type: FETCH_BOOKS_FAILED });
            if (err.code !== StatusCode.UNAVAILABLE) {
              switch (err.code) {
                case StatusCode.NOT_FOUND:
                  dispatch({ type: SEARCH_BOOKS_NO_ITEMS });
                  break;
                default:
                  Notification.error("Cannot get list of books.");
                  break;
              }
            }
          }
        };

        BookService.getUnaggregatedBooks(symbol, false, cb2);
      }

      if (err) {
        dispatch({ type: FETCH_BOOKS_FAILED });
        if (err.code !== StatusCode.UNAVAILABLE) {
          switch (err.code) {
            case StatusCode.NOT_FOUND:
              dispatch({ type: SEARCH_BOOKS_NO_ITEMS });
              break;
            default:
              Notification.error("Cannot get instrument information.");
              break;
          }
        }
      }
    };

    InstrumentService.get(symbol, cb1);
  };
}

export function listCurrentInstrumentStatuses(symbols, cb) {
  const bookCB = (err, response) => {
    let parsedBooks = new Map()
    if (!!response) {
      let statusMap = response.getBookStatusesMap()
      let parsedBook = null
      statusMap.forEach((sts, sym) => {
        const bookStatus = new BookStatus(sts)
        parsedBook = {
          stats: parseStats(bookStatus.stats, sym)
        }
        parsedBooks.set(sym, parsedBook)
      })
    }

    if (cb != null) {
      cb(err, parsedBooks)
    }
  };

  BookService.listCurrentInstrumentStatuses(symbols, bookCB);
}

export function resetBooks() {
  return { type: RESET_BOOKS };
}
