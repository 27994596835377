import React, { Component } from "react";
import Filter from "./Filter";
import DateGroup from "../../core/form/DateGroup";
import moment from "moment";
import { Col, FormControl, Form, Row } from "react-bootstrap";
import LoaderIcon from "../../core/loader/LoaderIcon";
import AutoComplete from "../../core/auto-complete/AutoComplete";
import { Side } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import { QuoteStatus } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/quotes/v1beta1/quotes_pb";
import _ from "lodash";
import DropdownListGroup from "../../core/form/DropdownListGroup";

class RFQFilter extends Component {
  constructor() {
    super();
    this.state = { ...this.getDefaultState() };
    this.onApply = this.onApply.bind(this);
    this.onRemoveFilterItem = this.onRemoveFilterItem.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onFlip = this.onFlip.bind(this);
    this.validate = this.validate.bind(this);
    this.oldState = {};
    this.currentFilterItems = {};
    this.participants = [];
    this.accounts = [];
    this.sides = [{id: '', name: "All"}, {id: Side.SIDE_UNDEFINED, name: "Two-Sided"}, {id: Side.SIDE_BUY, name: "Buy"}, {id: Side.SIDE_SELL, name: "Sell"}];
    this.quoteStatuses = [{id: QuoteStatus.QUOTE_STATUS_UNDEFINED, name: "All"},{id: QuoteStatus.QUOTE_STATUS_PENDING, name: "Pending"}, {id: QuoteStatus.QUOTE_STATUS_ACCEPTED, name: "Accepted"},
                          {id: QuoteStatus.QUOTE_STATUS_DELETED, name: "Deleted"}, {id: QuoteStatus.QUOTE_STATUS_EXPIRED, name: "Expired"}]
  }

  getDefaultState() {
    let stateObj = {
      instrumentId: "",
      fromDate: null,
      state: "",
      toDate: null,
      rfqid: "",
      participant: "",
      firm: "",
      account: "",
      clientId: "",
      side: "",
      rfqStateFilter: "",
      errors: null,
    };

    return stateObj;
  }

  componentDidMount() {
    this.setState(this.props.filters);

    let currentFilters = {};
    Object.keys(this.props.filters).forEach((key) => {
      if (!_.isEmpty(this.props.filters[key])) {
        currentFilters[key] = this.props.filters[key];
      }
    });

    this.currentFilterItems = { ...currentFilters };
  }

  filteredList = (allParticipants, allAccounts) => {
    this.participants = allParticipants;
    this.accounts = allAccounts;

    if (this.state.firm) {
      this.participants = allParticipants.filter(
        (participant) => participant.firm === this.state.firm.value
      );
      this.accounts = allAccounts.filter(
        (account) => account.associatedFirm === this.state.firm.value
      );
    } else if (this.state.participant) {
      let participant = allParticipants.find(
        (participant) => participant.name === this.state.participant.value
      );
      this.accounts = allAccounts.filter(
        (account) => account.associatedFirm === participant.firm
      );
    }
  };

  onFlip = (state) => {
    if (state.visible) {
      this.oldState = Object.assign({}, { ...this.state });
    } else {
      if (state.isDirty) {
        this.setState({ ...this.oldState });
      }
    }
  };

  updateFilter = (filter) => {
    let filterItems = this.state;
    if (
      filter.value === null ||
      filter.value === undefined ||
      filter.value.toString().trim() === ""
    ) {
      delete filterItems[filter.id];
    } else {
      filterItems[filter.id] = filter;
    }

    this.setState(filterItems);
  };

  onReset = () => {
    let _fromDate = moment().startOf("day").toDate();
    let _toDate = moment().endOf("day").toDate();
    let emptyState = this.getDefaultState();

    emptyState.fromDate = {
      id: "fromDate",
      value: _fromDate,
      text: "From Date",
      name: _fromDate.toString(),
    }

    emptyState.toDate = {
      id: "toDate",
      value: _toDate,
      text: "To Date",
      name: _toDate.toString(),
    };

    this.props.onApply(emptyState);
  };

  onApply = () => {
    this.initiateSearch();
  };

  onRemoveFilterItem = (filterItemId) => {
    if (typeof filterItemId === 'object' && filterItemId.hasOwnProperty("id")) {
      delete this.state[filterItemId.id];
    }
    else {
      delete this.state[filterItemId];
    }

    this.setState(this.state, () => {
      this.initiateSearch();
    });
  };

  initiateSearch() {
    this.props.onApply({ ...this.state });
  }

  validate() {
    if (
      this.state.fromDate &&
      this.state.toDate &&
      this.state.fromDate > this.state.toDate
    ) {
      this.setState({
        errors: {
          fromDate: "Start date must be before End Date.",
        },
      });
      return false;
    } else {
      this.setState({
        errors: null,
      });
      return true;
    }
  }

  render() {
    this.filteredList(this.props.allParticipants, this.props.allAccounts);
    return (
      <Filter
        className="instruments-filter"
        onApply={this.onApply}
        onReset={this.onReset}
        onRemoveFilterItem={this.onRemoveFilterItem}
        filterItems={this.currentFilterItems}
        onFlip={this.onFlip}
        validate={this.validate}
      >
        <div style={{ marginBottom: "20px" }}>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <Form.Label>Instrument ID</Form.Label>
              <AutoComplete
                id="instrumentId"
                placeholder="Instrument ID"
                noItemsMessage="No instruments found"
                dataSource={this.props.autoCompleteDataSource}
                refreshDataSource={this.props.onRefreshAutoComplete}
                value={this.state.instrumentId && this.state.instrumentId.value}
                defaultValue={this.state.instrumentId && this.state.instrumentId.value}
                onChange={(e) => {
                  let value = e.target.value;
                  this.updateFilter({
                    id: "instrumentId",
                    value: value,
                    text: "Instrument ID",
                    name: value,
                  });
                }}
                onEnter={(value) => {
                  this.setState({ instrumentId: value });
                }}
                onSelect={(value) => {
                  this.setState({ instrumentId: value });
                }}
                disable={true}
              />
            </Col>
            <Col lg={4} xs={4} md={4}>
              <Form.Label>Insert Time From</Form.Label>
              <DateGroup
                id="fromDate"
                name="fromDate"
                enableTime={true}
                step={15}
                value={this.state.fromDate && new Date(this.state.fromDate.value)}
                errors={this.state && this.state.errors}
                touched={this.state && this.state.touches}
                onChange={(e) => {
                  this.updateFilter({
                    id: "fromDate",
                    value: e,
                    text: "From Date",
                    name: e ? e.toString() : "",
                  });
                }}
                defaultCurrentDate={moment().startOf("day").toDate()}
              />
            </Col>
            <Col as={Col} lg={4} xs={4} md={4}>
              <Form.Label>Insert Time To</Form.Label>
              <DateGroup
                id="toDate"
                name="toDate"
                enableTime={true}
                step={15}
                value={this.state.toDate && new Date(this.state.toDate.value)}
                onChange={(e) => {
                  this.updateFilter({
                    id: "toDate",
                    value: e,
                    text: "To Date",
                    name: e ? e.toString() : "",
                  });
                }}
                defaultCurrentDate={moment().endOf("day").toDate()}
              />
            </Col>
          </Row>
          <Row>
            <Col as={Col} lg={5} xs={5} md={5}>
              <DropdownListGroup
                id="firm"
                name="firm"
                label="Firm"
                value={this.state.firm ? this.state.firm.value : ''}
                onChange={(e) => {
                  this.updateFilter({
                    id: "firm",
                    value: e.name,
                    text: "Firm",
                    name: e.displayName,
                  });
                }}
                data={[{name: '', displayName: 'All'}, ...this.props.firms]}
                textField="displayName"
                valueField="name"
              />
            </Col>
            <Col as={Col} lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="participant"
                name="participant"
                label="Participant"
                value={this.state.participant ? this.state.participant.value : ''}
                onChange={(e) => {
                  this.updateFilter({
                    id: "participant",
                    value: e.name,
                    text: "participant",
                    name: e.displayName,
                  });
                }}
                data={[{name: '', displayName: 'All'}, ...this.participants]}
                textField="displayName"
                valueField="name"
              />
            </Col>
            <Col as={Col} lg={4} xs={4} md={4}>
              <DropdownListGroup
                id="account"
                name="account"
                label="Account"
                value={this.state.account ? this.state.account.value : ''}
                onChange={(e) => {
                  this.updateFilter({
                    id: "account",
                    value: e.name,
                    text: "account",
                    name: e.displayName,
                  });
                }}
                data={[{name: '', displayName: 'All'}, ...this.accounts]}
                textField="displayName"
                valueField="name"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '15px' }}>
            <Col as={Col} lg={4} xs={4} md={4}>
              <Form.Label>Quote Request ID</Form.Label>
              <FormControl
                id="rfqid"
                name="rfqid"
                type="text"
                defaultValue={this.state.rfqid && this.state.rfqid.value}
                placeholder="Quote Request ID"
                onChange={(e) => {
                  this.updateFilter({
                    id: "rfqid",
                    value: e.target.value,
                    text: "RFQ ID",
                    name: e.target.value,
                  });
                }}
                value={this.state.rfqid && this.state.rfqid.value}
              />
            </Col>
            <Col as={Col} lg={4} xs={4} md={4}>
              <Form.Label>Client ID</Form.Label>
              <FormControl
                id="clientId"
                name="clientId"
                type="text"
                defaultValue={this.state.clientId && this.state.clientId.value}
                placeholder="Client ID"
                onChange={(e) => {
                  this.updateFilter({
                    id: "clientId",
                    value: e.target.value,
                    text: "Client ID",
                    name: e.target.value,
                  });
                }}
                value={this.state.clientId && this.state.clientId.value}
              />
            </Col>
            <Col controlId="side" as={Col} lg={2} xs={2} md={2}>
              <DropdownListGroup
                id="side"
                name="side"
                label="Side"
                value={this.state.side ? this.state.side.value : ''}
                onChange={(e) => {
                  this.updateFilter({
                    id: "side",
                    value: e.id,
                    text: "Side",
                    name: e.name,
                  });
                }}
                data={this.sides}
              />
            </Col>

            <Col controlId="rfqStateFilter" as={Col} lg={2} xs={2} md={2}>
              <DropdownListGroup
                id="rfqStateFilter"
                name="rfqStateFilter"
                label="Request Status"
                value={this.state.rfqStateFilter && this.state.rfqStateFilter.value}
                onChange={(e) => {
                  this.updateFilter({
                    id: "rfqStateFilter",
                    value: e.id,
                    text: "Status",
                    name: e.name,
                  });
                }}
                data={this.quoteStatuses}
              />
            </Col>

            <div className="form-group col-auto align-self-end">
              <LoaderIcon show={this.props.loading}></LoaderIcon>
            </div>
          </Row>
        </div>
      </Filter>
    );
  }
}

export default RFQFilter;
