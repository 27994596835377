import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import querystring from 'query-string';
import _ from "lodash"
import {
  CleanInstrument,
  fetchInstruments2,
  fetchMetadata,
  setInstrumentBooks,
  exportInstruments,
} from "../actions/instruments";
import InstrumentHeader from "../components/instrument/InstrumentHeader";
import Loader from "../components/core/loader/Loader";
import "./Content.css";
import InstrumentDataGrid from "../components/instrument/InstrumentDataGrid";
import { AdminDocumentTitle } from "../constants/strings";
import PaginationWithPageSize, {
  withPageToken,
} from "../components/core/data-grid/PaginationWithPageSize";
import { hasWriteAccess } from "../services/TokenService";
import { LoadFirms } from "../actions/firms";
import Notification from "../modules/notifications";
import InstrumentService from "../services/InstrumentService";
import confirm from "../modules/confirmDialog";
import InstrumentsFilter from "../components/core/filter/InstrumentsFilter";
import { listCurrentInstrumentStatuses } from "../actions/books";
import { isNullOrUndefined } from "../modules/util";
import ButtonSecondary from "../components/core/form/ButtonSecondary";
import { Row, Col } from "react-bootstrap";
import { NavigationManager } from "../components/core/helpers/NavigationHelper";

const STORAGE_PAGE_SIZE_KEY = "dataGridPageSize-Instruments";
const DEFAULT_PAGE_SIZE = 10;

function mapStateToProps(state) {
  return {
    fetchingInstruments: state.fetchingInstruments,
    instruments: state.instruments.instruments.map((i) => {
      var firm = state.firms.firms.find((f) => f.name === i.clearingHouse);
      i.clearingHouse = firm ? firm : i.clearingHouse;
      i.books = state.instruments.instrumentBooks[i.id];
      return i;
    }),
    tokens: state.instruments.tokens,
    pageToken: state.instruments.pageToken,
  };
}

const mapDispatchToProps = (dispatch) => ({
  cleanInstrumentForm: () => {
    dispatch(CleanInstrument());
  },
  fetchInstruments: (pageToken, pageSize, filterItems, cb) => {
    dispatch(fetchInstruments2(pageToken, pageSize, filterItems, cb));
  },
  setInstrumentBooks: (books) => {
    dispatch(setInstrumentBooks(books));
  },
  fetchMetadata: () => {
    dispatch(fetchMetadata());
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
  exportInstruments: (filterItems) => {
    dispatch(exportInstruments(filterItems));
  },
});

class Instruments extends Component {

  static defaultFilter = {
    type: "",
    id: null,
    state: null,
    tradeDate: null,
    clearingHouse: null,
    basecurrency: null,
    tenor: null,
    floatingRateIndex: null,
    floatingRateIndexTenor: null,
    productName: null
  };

  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.getPageSizeFromStorage = this.getPageSizeFromStorage.bind(this);

    const queryParams = querystring.parseUrl(window.location.href);
    const hasParams = !_.isEmpty(queryParams.query) && queryParams.query.filter;
    if (hasParams) {
      let qFilter = JSON.parse(queryParams.query.filter);
      qFilter = Instruments.filterDateParser(qFilter);
      this.state = { filterItems: { ...Instruments.defaultFilter, ...qFilter }, pageSize: Number(this.getPageSizeFromStorage()) };
    } else {
      this.state = { filterItems: Instruments.defaultFilter, pageSize: Number(this.getPageSizeFromStorage()) };
    }
  }

  static filterDateParser = function (filter) {
    let filterData = { ...filter }
    if (filterData.tradeDate) {
      filterData.tradeDate.value = new Date(filterData.tradeDate.value);
    }

    return filterData;
  };

  prepareReqObj = (filterItems) => {
    let values = {};
    Object.keys(filterItems).forEach((key) => {
      if (filterItems[key]) {
        values[key] = filterItems[key];
      }
    });

    return values;
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.props.cleanInstrumentForm();
    this.fetchInstruments("");
    this.props.fetchMetadata();
    this.props.loadFirms();
    this.applyFilter = this.applyFilter.bind(this);
    window.scrollTo(0, 0);
  }

  static contextTypes = {
    router: PropTypes.object,
  };

  openViewInstrument = (instrumentId, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/instruments/${encodeURIComponent(instrumentId)}`);
    } else {
      this.context.router.history.push(`/instruments/${encodeURIComponent(instrumentId)}`);
    }
  };

  openChangeStateInstrument = (instrumentId, actionContext) => {
    var getVal = "";
    var dest = "";
    if (Array.isArray(instrumentId)) {
      const instsJSON = JSON.stringify(instrumentId);
      getVal = encodeURIComponent(instsJSON);
      dest = "multiState";
    } else {
      getVal = encodeURIComponent(instrumentId);
      dest = "changeState";
    }

    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/instruments/${getVal}/${dest}`);
    } else {
      this.context.router.history.push(`/instruments/${getVal}/${dest}`);
    }
  };

  openEditInstrument = (instrumentId, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/instruments/${encodeURIComponent(instrumentId)}/edit`);
    } else {
      this.context.router.history.push(`/instruments/${encodeURIComponent(instrumentId)}/edit`);
    }
  };

  openCopyInstrument = (instrumentId, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/instruments/${encodeURIComponent(instrumentId)}/copy`);
    } else {
      this.context.router.history.push(`/instruments/${encodeURIComponent(instrumentId)}/copy`);
    }
  };

  openBookInstrument = (instrumentId, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/books?instrumentId=${encodeURIComponent(instrumentId)}`);
    } else {
      this.context.router.history.push(`/books?instrumentId=${encodeURIComponent(instrumentId)}`);
    }
  };

  openCreateInstrument = () => {
    this.context.router.history.push(`/instruments/new`);
  };

  onPageChange = (pageToken) => {
    this.fetchInstruments(pageToken);
  };

  fetchInstruments = (pageToken) => {
    const pageSize = this.state.pageSize;
    const reqObj = this.prepareReqObj(this.state.filterItems)
    this.props.fetchInstruments(pageToken, pageSize, reqObj, this.fetchMarketData);
  };

  fetchMarketData = (insts) => {
    let symbols = []
    let symbolClearMap = new Map()
    insts.forEach((inst) => {
      symbols.push(inst.id)
      symbolClearMap.set(inst.id, {})
    })
    this.props.setInstrumentBooks(symbolClearMap)

    listCurrentInstrumentStatuses(symbols, (err, books) => {
      if (isNullOrUndefined(err)) {
        this.props.setInstrumentBooks(books)
      }
    })
  }

  getPaginationComponent = () => {
    const pageToken = this.props.pageToken;
    const tokens = this.props.tokens;
    const pageSize = Number(this.getPageSizeFromStorage());
    return withPageToken(PaginationWithPageSize, { pageToken, tokens }, pageSize);
  };

  removeInstruments = (instrumentIds, hasActiveSelection) => {
    instrumentIds = !Array.isArray(instrumentIds) ? [instrumentIds] : instrumentIds

    if (instrumentIds && instrumentIds.length > 0) {

      const instrumentTag = instrumentIds.length > 1 ? 'instruments' : 'instrument'

      const confirmationMessage = hasActiveSelection ?
        `Are you sure you want to delete the selected ${instrumentTag}?`
        : `Are you sure you want to delete the ${instrumentTag}?`

      confirm(confirmationMessage, {
        title: "Instrument Confirmation",
        okButtonText: "Yes",
        cancelButtonText: "No",
      }).then(
        () => {

          const deleteInstrument = (id) => {
            return new Promise((resolve, reject) => {
              try {
                InstrumentService.delete(id)
                  .then(response => {
                    resolve(response)
                  })
                  .catch(error => {
                    reject(error)
                  })
              }
              catch (error) {
                reject(error)
              }
            })

          }
          let promises = []

          instrumentIds && Array.isArray(instrumentIds) && instrumentIds.forEach(async id => {
            promises.push(deleteInstrument(id))
          })

          Promise.all(promises).then(res => {
            if (res) {
              this.context.router.history.push("/instruments");
              Notification.success(`${instrumentIds.length > 1 ? 'Instruments' : 'Instrument'} deleted.`);
            }

          })
            .catch(error => {
              if (error) {
                Notification.error(
                  `Error when deleting ${instrumentTag}: \n ${error.message}`
                );
              }
            })
        },
        () => { }
      );
    }
  };

  showMarketInstrument = (instrumentId, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/instruments/${encodeURIComponent(instrumentId)}/marketStats`);
    } else {
      this.context.router.history.push(`/instruments/${encodeURIComponent(instrumentId)}/marketStats`);
    }
  };

  applyFilter = (values) => {
    if (values) {
      const reqObj = this.prepareReqObj(values)
      this.filterInstruments(reqObj);
    }
  };

  filterInstruments = (filter) => {
    const routeUrl = this.context.router.route.location.pathname;

    if (_.isEmpty(filter)) {
      this.context.router.history.push(`${routeUrl}`);
    } else {
      let filterData = { filter: JSON.stringify(filter) };
      const parsedString = querystring.stringify(filterData);
      this.context.router.history.push(`${routeUrl}?${parsedString}`);
    }
  }

  onExport = (values) => {
    if (values) {
      let reqObj = this.prepareReqObj(values)
      this.props.exportInstruments(reqObj);
    }
  }

  setPageSizeInStorage = (pageSize) => {
    window.localStorage.setItem(STORAGE_PAGE_SIZE_KEY, pageSize)
  }

  getPageSizeFromStorage = () => {
    const pageSize = window.localStorage.getItem(STORAGE_PAGE_SIZE_KEY)

    if (pageSize)
      return pageSize;

    return DEFAULT_PAGE_SIZE;
  }

  onPageSizeChange = (pageSize) => {
    this.setPageSizeInStorage(pageSize);
    this.setState({ pageSize: pageSize }, () => {
      this.fetchInstruments("")
    });
  }

  render() {
    return (
      <div className="with-callback">
        <InstrumentHeader
          buttonText="ADD INSTRUMENT"
          buttonClick={() => {
            this.openCreateInstrument();
          }}
          secondaryButtonText="REFRESH MKT DATA"
          secondaryButtonClick={() => {
            if(!isNullOrUndefined(this.props) && Array.isArray(this.props.instruments)) {
              this.fetchMarketData(this.props.instruments);
            }
          }}
          isInstrumentsSelected={true}
          isAddButtonVisible={hasWriteAccess()}
          isSecondaryButtonVisible={true}
        />
        <Row>
          <Col>
            <InstrumentsFilter filters={this.state.filterItems} onApply={this.applyFilter}></InstrumentsFilter>
          </Col>
          <div style={{ float: "right" }}>
            <Col>
              <ButtonSecondary text="Export Data" type="button" onClick={() => {
                  this.onExport(this.state.filterItems)
                }}
              />
            </Col>
          </div>
        </Row>
        <Loader show={this.props.instruments.fetchingInstruments}></Loader>
        <InstrumentDataGrid
          data={this.props.instruments}
          onView={(instrumentId, actionContext) => { this.openViewInstrument(instrumentId, actionContext); }}
          onState={(instrumentId, actionContext) => { this.openChangeStateInstrument(instrumentId, actionContext); }}
          onEdit={(instrumentId, actionContext) => { this.openEditInstrument(instrumentId, actionContext); }}
          onCopy={(instrumentId, actionContext) => { this.openCopyInstrument(instrumentId, actionContext); }}
          onBook={(instrumentId, actionContext) => { this.openBookInstrument(instrumentId, actionContext); }}
          onRemove={(instrumentIds, hasActiveSelection) => { this.removeInstruments(instrumentIds, hasActiveSelection); }}
          onMarketStats={(instrumentId, actionContext) => { this.showMarketInstrument(instrumentId, actionContext); }}
          onPageSizeChange={this.onPageSizeChange}
          paginationComponent={this.getPaginationComponent()}
          onPageChange={this.onPageChange}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Instruments);