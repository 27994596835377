import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ProductDetails from '../components/product/ProductDetails';
import confirm from '../modules/confirmDialog';
import {
  IsCreatingProduct,
  SetProductProcessName,
  SetSyncAdditionalInfo,
  SetSyncTradingInfo,
  SetSyncCrossOrderInfo,
  SetSyncSettlementPriceCalcInfo,
} from '../actions/products';

import Notification from '../modules/notifications';
import ProductMapper from '../modules/mappers/productMapper';
import { StatusCode } from 'grpc-web';
import ProductService from '../services/ProductService';
import { fetchMetadata } from "../actions/instruments";
import InstrumentService from "../services/InstrumentService";
import Product from "../entities/Product";
import { isNullOrUndefined } from "../modules/util";
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";

function mapStateToProps(state) {
  return {
    metadata: state.instruments.metadata,
    holidays: state.instruments.holidayCalendarObjects,
    isAddingProduct: state.products.isCreatingProduct,
    processName: state.products.processNameForSaving,
    syncAdditionalInfo: state.products.syncAdditionalInfo,
    syncTradingInfo: state.products.syncTradingInfo,
    syncTradeDayRollSchedule: state.products.syncTradeDayRollSchedule,
    syncCrossOrderInfo: state.products.syncCrossOrderInfo,
    syncSettlementPriceCalcInfo: state.products.syncSettlementPriceCalcInfo,
  };
}

const mapDispatchToProps = (dispatch) => ({
  isCreatingProduct: (status) => {
    dispatch(IsCreatingProduct(status))
  },
  setProductProcessName: (processName) => {
    dispatch(SetProductProcessName(processName))
  },
  fetchMetadata: () => {
    dispatch(fetchMetadata())
  },
  setSyncAdditionalInfo: (shouldSync) => {
    dispatch(SetSyncAdditionalInfo(shouldSync))
  },
  setSyncTradingInfo: (shouldSync) => {
    dispatch(SetSyncTradingInfo(shouldSync))
  },
  setSyncCrossOrderInfo: (shouldSync) => {
    dispatch(SetSyncCrossOrderInfo(shouldSync));
  },
  setSyncSettlementPriceCalcInfo: (shouldSync) => {
    dispatch(SetSyncSettlementPriceCalcInfo(shouldSync));
  },
});

class ProductFormContainer extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    this.props.fetchMetadata();
  }

  onBeforeSubmitForm = processName => {
    this.props.setProductProcessName(processName);
  }

  removeProduct = productId => {
    confirm('Are you sure you want to remove the product?',
      {
        title: 'Product Confirmation',
        okButtonText: 'Yes',
        cancelButtonText: 'No'
      }
    ).then(() => {
      const cb = (err, response) => {
        if (response) {
          this.context.router.history.push('/products');
          Notification.success('Product deleted.');
        }

        if (err) {
          Notification.error(`Error when deleting product: \n ${err.message}`);
        }
      };

      ProductService.delete(productId, cb);
    },
      () => { });
  }

  updateChildInstruments = (product, setFieldError) => {
    const successHandler = (instruments) => {
      instruments.forEach((instrument) => {
        const productAttrs = product.getAttributes();
        const instAttrs = instrument.getAttributes();

        if (!isNullOrUndefined(productAttrs)) {
          if (!!this.props.syncTradingInfo) {
            instrument.getAttributes().setInstrumentProductType(productAttrs.getInstrumentProductType());
            instrument.getAttributes().setTradingScheduleList(productAttrs.getTradingScheduleList());
            instrument.getAttributes().setHolidayCalendarsList(productAttrs.getHolidayCalendarsList());
          }

          if (!!this.props.syncTradeDayRollSchedule) {
            instrument.getAttributes().setTradeDayRollScheduleList(productAttrs.getTradeDayRollScheduleList());
          }

          if (!!this.props.syncCrossOrderInfo) {
            instAttrs.setCrossOrderRestingDuration(productAttrs.getCrossOrderRestingDuration());
            instAttrs.setBlockTradeThreshold(productAttrs.getBlockTradeThreshold());
          }

          if (!!this.props.syncSettlementPriceCalcInfo) {
            instAttrs.setSettlementPeriodStartOffsetFromCloseDuration(productAttrs.getSettlementPeriodStartOffsetFromCloseDuration());
            instAttrs.setSettlementPeriodEndOffsetFromCloseDuration(productAttrs.getSettlementPeriodEndOffsetFromCloseDuration());
            instAttrs.setMinimumTradeQtyForVwapSettlementCalculation(productAttrs.getMinimumTradeQtyForVwapSettlementCalculation());
            instAttrs.setBufferFromEndOfSettlementPeriodDuration(productAttrs.getBufferFromEndOfSettlementPeriodDuration());
            instAttrs.setSettlementPriceLogic(productAttrs.getSettlementPriceLogic());
            instAttrs.setSettlementPriceCalculationRequiresApproval(productAttrs.getSettlementPriceCalculationRequiresApproval());
            instAttrs.setSettlementPriceCalculationEndingMethod(productAttrs.getSettlementPriceCalculationEndingMethod())
          }

          if (!!this.props.syncAdditionalInfo) {
            instrument.getAttributes().setPriceLimit(productAttrs.getPriceLimit());
            instrument.getAttributes().setOrderSizeLimit(productAttrs.getOrderSizeLimit());
            instrument.getAttributes().setSkipRiskAndCurrencyChecks(productAttrs.getSkipRiskAndCurrencyChecks());
          }
        }

        const instId = instrument.getId();
        const cb = (err, response) => {
          if (err) {
            Notification.error(`Failed to save instrument ${instId}: ${err.message}`);
          }
          if (response) {
            Notification.success(`Successfully updated ${instId}`);
          }
        }

        if (instrument.getState() === InstrumentState.INSTRUMENT_STATE_PENDING) {
          InstrumentService.updatePending(instrument, cb);
        } else if (instrument.getState() !== InstrumentState.INSTRUMENT_STATE_TERMINATED) {
          InstrumentService.updateActive(instId, instrument, cb);
        }

        this.props.setSyncAdditionalInfo(false);
        this.props.setSyncTradingInfo(false);
        this.props.setSyncCrossOrderInfo(false);
        this.props.setSyncSettlementPriceCalcInfo(false);
      });
    };
    InstrumentService.getAll((err) => this.processSaveProduct(null, err, setFieldError), successHandler, product.getId());
  }

  saveProduct = (productData, setFieldError) => {
    let performSave = () => {
      const product = ProductMapper.mapToProduct(productData);

      const cb = (err, response) => {
        if (this.props.syncTradingInfo || this.props.syncTradeDayRollSchedule || this.props.syncAdditionalInfo || this.props.syncCrossOrderInfo || this.props.syncSettlementPriceCalcInfo) {
          this.updateChildInstruments(product, setFieldError);
        }

        this.processSaveProduct(response, err, setFieldError);
      };

      if (this.props.isAddingProduct) {
        ProductService.create(product, cb);
      }
      else {
        ProductService.update(product, cb);
      }
    }

    const confirmationMessage = this.props.isAddingProduct ? `Are you sure you want to add the product?` :
      this.props.processName === 'saveAndApply' ? 'Are you sure you want to apply to all instruments?' : null;

    if (confirmationMessage) {
      confirm(confirmationMessage,
        {
          title: 'Product Confirmation',
          okButtonText: 'Yes',
          cancelButtonText: 'No'
        }
      ).then(
        () => { performSave(); },
        () => { });
    }
    else {
      performSave();
    }
  }

  processSaveProduct = (response, err, setFieldError) => {
    if (response) {
      let redirectUrl = '/products';
      const product = new Product(response.getProduct());

      if (this.props.processName === 'saveAndCreate') {
        redirectUrl = `/instruments/new?productId=${product.name}`;
      }

      this.context.router.history.push(redirectUrl);
      Notification.success('Product successfully saved.');
    }

    if (err) {
      this.processFormError(err, setFieldError);
    }
  }

  processForm = (productData, setFieldError) => {
    switch (this.props.processName) {
      case 'saveOnly':
      case 'saveAndCreate':
      case 'saveAndApply':
        this.saveProduct(productData, setFieldError);
        break;
      case 'remove':
        this.removeProduct(productData, setFieldError);
        break;
      default:
        break;
    }
  }

  processFormError = (error, setFieldError) => {
    switch (error.code) {
      case StatusCode.ALREADY_EXISTS:
        setFieldError('name', error.message, false);
        Notification.error(`Failed to save this product, ID has already been used.`);
        break;
      default:
        Notification.error(`Failed to save this product. ${error.message}`);
        break;
    }
  }

  getFormMode = () => {
    if (this.props.location.pathname.indexOf('/edit') > 0) {
      this.props.isCreatingProduct(false);
      return ProductDetails.formModes.edition;
    } else if (this.props.location.pathname.endsWith('/copy')) {
      this.props.isCreatingProduct(true);
      return ProductDetails.formModes.creation;
    } else if (this.props.location.pathname.indexOf('/new') > 0) {
      this.props.isCreatingProduct(true);
      return ProductDetails.formModes.creation;
    } else {
      this.props.isCreatingProduct(false);
      return ProductDetails.formModes.view;
    }
  }

  render() {
    return (
      <React.Fragment>
        <ProductDetails
          onSubmitForm={this.processForm}
          onBeforeSubmit={this.onBeforeSubmitForm}
          onDelete={(productId) => { this.removeProduct(productId) }}
          mode={this.getFormMode()}
          metadata={this.props.metadata}
          holidays={this.props.holidays}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductFormContainer));
