import { push, replace } from "connected-react-router";
import { apiUrl, isOnline, showError } from "../modules/util";

import {
  LOGIN_START,
  LOGIN_END,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_END,
  LOGIN_RESET,
  LOGIN_ERROR_RESET,
} from "../constants/authTypes";
import { RESET } from "../constants/appTypes";
import Notification from "../modules/notifications";
import { parseJwt } from "../modules/authUtil";
import { Env } from "../constants/environment";
import AuthService from "../services/AuthService";
import { Code } from 'grpc-web-client/dist/Code';

const {
  LoginRequest,
  LogoutRequest,
  RegisterRequest,
  ChangePasswordRequest,
  CheckPasswordRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_pb.js");
const {
  BasicAuthAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_grpc_web_pb.js");

// Login
export function login(data, callback) {
  return (dispatch) => {
    if (isOnline()) {
      dispatch({ type: LOGIN_START });

      const auth_client = new BasicAuthAPIClient(
        apiUrl(),
        null,
        null
      );

      if (data.registering) {
        const register = new RegisterRequest();
        register.setToken(data.user.token);
        register.setPassword(data.user.password);

        auth_client.register(register, {}, (err, res) => {
          dispatch({ type: LOGIN_END });
          if (err) {
            dispatch({
              type: LOGIN_FAILURE,
              payload: { error: "Unable to register user." },
            });
            Notification.error(
              "Error code " + err.code + ": " + err.message
            );
          } else {
            const accessToken = "";
            const user = "";
            const expires = new Date();
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { accessToken, user, expires },
            });
            Notification.success(
              "Successfully submitted registration for new user, you may now log in"
            );
            dispatch(push("/"));
          }
        });
        return;
      }

      const login = new LoginRequest();
      login.setUsername(data.user.username);
      login.setPassword(data.user.password);
      if (!!data?.user?.mfaOTP) login.setOtp(data?.user?.mfaOTP);

      auth_client.login(login, {}, (err, res) => {
        if (err) {
          dispatch({ type: LOGIN_END });
          dispatch({
            type: LOGIN_FAILURE,
            payload: {
              error: (err?.code === Code.FailedPrecondition ? "" : "Username and password combination don't match."),
            },
          });
          Notification.error("Error code " + err.code + ": " + err.message)
        } else {
          assignTokens(res.getRefreshToken(), res.getAccessToken())(dispatch);
        }

        if (callback) {
          callback(err, res, dispatch);
        }
      });
    } else {
      dispatch({ type: LOGIN_END });
      showError("No internet connection.");
    }
  };
}

// Assign Tokens
export function assignTokens(refreshToken, accessToken) {
  return (dispatch) => {
    setTokenAndRedirectAfterLogin(refreshToken, accessToken, dispatch);
  };
}

export function setTokenAndRedirectAfterLogin(refreshToken, accessToken, dispatch) {
  setAuthTokens(refreshToken, accessToken)(dispatch);
  dispatch(push("/firms"));
}

export function setAuthTokens(refreshToken, accessToken) {
  return (dispatch) => {
    const decodedRefresh = parseJwt(refreshToken);
    const expires = new Date(decodedRefresh.exp * 1000); // when the session is set to expire
    let hasWriteAccess = false;
    try {
      const decoded = parseJwt(accessToken);
      const role = decoded.role;
      hasWriteAccess =
        role.trimEnd().toUpperCase().endsWith("ROLE_SUPERVISOR_ONBOARDING_ADMIN") ||
        role.trimEnd().toUpperCase().endsWith("ROLE_SUPERVISOR_EXCHANGE_OPERATION_ADMIN") ||
        role.trimEnd().toUpperCase().endsWith("ROLE_SUPERVISOR_ADMIN");
    } catch (err) {
      console.log(err);
    }
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { refreshToken, accessToken, expires, hasWriteAccess },
    });
  }
}

// Reset Password
export function resetPassword(data, cb) {
  return (dispatch) => {
    if (isOnline()) {
      dispatch({ type: LOGIN_START });

      // TODO: add api call
    } else {
      dispatch({ type: LOGIN_END });
      showError("No internet connection.");
    }
  };
}

// Update Password
export function changePassword(user, oldPassword, newPassword, cb) {
  return (dispatch) => {
    if (isOnline()) {
      dispatch({ type: CHANGE_PASSWORD_START });

      const auth_client = new BasicAuthAPIClient(
        apiUrl(),
        null,
        null
      );

      const req = new ChangePasswordRequest();
      req.setUsername(user);
      req.setOldPassword(oldPassword)
      req.setNewPassword(newPassword)

      auth_client.changePassword(req, {}, (err, res) => {
        if (cb) {
          cb(err, res)
        }

        if (err) {
          Notification.error("Error code " + err.code + ": " + err.message);
        } else {
          Notification.success("Password changed successfully!");
        }
        dispatch({ type: CHANGE_PASSWORD_END });
      });
    } else {
      dispatch({ type: CHANGE_PASSWORD_END });
      showError("No internet connection.");
    }
  };
}

// Check Password
export function checkPassword(password, cb) {
  return (dispatch) => {
    if (isOnline()) {
      const auth_client = new BasicAuthAPIClient(
        apiUrl(),
        null,
        null
      );

      const req = new CheckPasswordRequest();
      req.setPassword(password);

      auth_client.checkPassword(req, {}, (err, res) => {
        if (cb) {
          cb(err, res)
        }

        if (err) {
          Notification.error("Error code " + err.code + ": " + err.message);
        }
      });
    } else {
      showError("No internet connection.");
    }
  };
}

export function logout(accessToken, refreshToken) {
  return (dispatch) => {
    dispatch(reset()); // clears session
    if (accessToken && refreshToken) {
      const auth_client = new BasicAuthAPIClient(
        apiUrl(),
        null,
        null
      );
      const request = new LogoutRequest();
      request.setAccessToken(accessToken);
      request.setRefreshToken(refreshToken);
      auth_client.logout(
        request,
        {},
        (err, res) => { }
      );
    }
    const logoutUrl = Env.getEnv("REACT_APP_LOGOUT_URL");
    if (!!logoutUrl) {
      window.location.href = logoutUrl;
    } else {
      dispatch(replace("/"));
    }
  };
}

export function resetLoginInfo() {
  return (dispatch) => {
    dispatch({ type: LOGIN_RESET });
  };
}

export function resetLoginError() {
  return (dispatch) => {
    dispatch({ type: LOGIN_ERROR_RESET });
  };
}

// reset auth redux state
function reset() {
  return { type: RESET };
}

export function confirmMFA(data, cb) {
  return async (dispatch) => {
    let resp = null
    try {
      resp = await AuthService.confirmMFAAsync(data.username, data.password, data.mfaOTP);
    } catch (e) {
      let errorMsg = e.message;
      if (e.message === 'Validation against the provided TOTP failed') {
        errorMsg = "Incorrect One-Time Password. Make sure the OTP entered was generated from the QR code above.";
      }
      dispatch({
        type: LOGIN_FAILURE,
        payload: { error: errorMsg },
      });
      Notification.error("Error code " + e.code + ": " + e.message);
      return
    }

    Notification.success("Successfully confirmed MFA!");
    dispatch({ type: LOGIN_RESET });

    if (cb) {
      cb(resp.resp, dispatch);
    }
  }
}
