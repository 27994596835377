import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import { range } from "lodash";
import FieldGroup from "../../../core/form/FieldGroup";
import { FormModes } from "../../../../constants/enumerations";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import { validateField, STRING_TYPE } from '../../instrumentFormUtils'
import Fraction from "../../../../modules/fraction";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import DateGroupYMD from "../../../core/form/DateGroupYMD";
import {countDecimals, validScaledPrice, valueOrDefault} from "../../../../modules/util";
import { Env } from "../../../../constants/environment";
import DateGroup from "../../../core/form/DateGroup";
import { SubTypes } from './SubTypes';
import ErrorLabel from "../../../core/form/ErrorLabel";
import * as toolTips from '../../../../constants/instrumentToolTip'

export const EventInfo = ({ values,
  setFieldValue,
  touched,
  handleBlur,
  onChange,
  errors,
  mode }) => {
  const [open, setOpen] = useState(true);
  const isViewing = mode === FormModes.view;

  const dtYear = (new Date()).getFullYear();
  const years = ["", ...range(dtYear, dtYear + 11)];
  const months = ["", ...range(1, 13)];
  const days = ["", ...range(1, 32)];
  const evaluationTypes = Env.getEnv("REACT_APP_EVENT_ATTR_EVALUATION_TYPES");
  const EVALUATION_TYPES = valueOrDefault(evaluationTypes === '' ? null : evaluationTypes, '>=,<=,==,!=,<,>').split(",");
  const EVENT_ATTR_STRIKE_UNIT = Env.getEventAttrStrikeUnits();
  const payoutTypes = InstrumentStaticData.EventPayoutType;
  const calculationMethods = InstrumentStaticData.EventCalculationMethod([0]);

  const showErrorIfAnyRequiredFieldMissing = () => {
    const errPresent = !!errors["evtAttQuestion"]
      || !!errors["evtAttSourceAgencyUrl"]
      || !!errors["evtAttStrikeValue"]
      || !!errors["evtAttEvaluationType"]
      || !!errors["evtAttPayoutLowerBound"]
      || !!errors["evtAttPayoutUpperBound"]
      || Array.isArray(errors.subTypesList);

    if (errPresent && !open) {
      setOpen(errPresent);
    }

    return errPresent;
  }

  const setIsContinuous = (type) => {
    if (type === payoutTypes[1].id) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={8} xs={8} md={8}>
            <h6><strong>Event Information</strong></h6>
          </Col>
          <Col lg={3} xs={3} md={3}>
            {showErrorIfAnyRequiredFieldMissing() && <ErrorLabel text={"Required field(s) missing"} />}
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={3} xs={3} md={3}>
              <h6>Attributes</h6>
            </Col>
          </Row>
          <Row>
            <Col lg={2} xs={2} md={2}>
              <DropdownListGroup
                id="evtAttPayoutType"
                name="evtAttPayoutType"
                label="Payout Type"
                enabled={!values.isDisabledOnEdit}
                value={values.evtAttPayoutType || payoutTypes[0].id}
                errors={errors}
                onChange={(e) => {
                  setFieldValue("evtAttPayoutType", e.id);
                  setFieldValue("isContinuous", setIsContinuous(e.id));
                }}
                data={payoutTypes}
                textField="name"
                valueField="id"
                plaintext={isViewing}
                tooltip={toolTips.EVENT_PAYOUT_TYPE}
              />
            </Col>
        
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="evtAttPositionAccountability" name="evtAttPositionAccountability" type="textarea" label="Position Accountability Value"
                value={values.evtAttPositionAccountability}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
                tooltip={toolTips.EVENT_POSITION_ACCOUNTABILITY_VALUE}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="evtAttPositionLimit" name="evtAttPositionLimit" type="textarea" label="Position Limit"
                value={values.evtAttPositionLimit}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
                tooltip={toolTips.EVENT_POSITION_LIMIT}
              />
            </Col>

            <Col lg={6} xs={6} md={6}>
              <FieldGroup id="evtAttQuestion" name="evtAttQuestion" type="textarea" label="Question"
                value={values.evtAttQuestion}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
                tooltip={toolTips.EVENT_QUESTION}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="evtAttPayoutValue" name="evtAttPayoutValue" type="textarea" label="Payout Value"
                disabled={values.isContinuous || values.isDisabledOnEdit}
                value={!values.isContinuous ? values.evtAttPayoutValue : null}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="evtAttPayoutLowerBound" name="evtAttPayoutLowerBound" type="textarea" label="Payout Lower Bound" isRequired={values.isContinuous}
                disabled={!values.isContinuous || values.isDisabledOnEdit}
                value={values.isContinuous ? values.evtAttPayoutLowerBound : null}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="evtAttPayoutUpperBound" name="evtAttPayoutUpperBound" type="textarea" label="Payout Upper Bound" isRequired={values.isContinuous}
                disabled={!values.isContinuous || values.isDisabledOnEdit}
                value={values.isContinuous ? values.evtAttPayoutUpperBound : null}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="evtAttSourceAgency" name="evtAttSourceAgency" type="textarea" label="Source Agency"
                value={values.evtAttSourceAgency}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="evtAttSourceAgencyUrl" name="evtAttSourceAgencyUrl" type="textarea" label="Source Agency URL"
                value={values.evtAttSourceAgencyUrl}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>

            <Col lg={4} xs={4} md={4}>
              <DropdownListGroup
                id="evtAttCalculationMethod"
                name="evtAttCalculationMethod"
                label="Outcome Value Calculation Method"
                value={values.evtAttCalculationMethod || null}
                errors={errors}
                onChange={(e) => setFieldValue("evtAttCalculationMethod", e.id)}
                data={calculationMethods}
                textField="name"
                valueField="id"
                plaintext={isViewing}
                tooltip={toolTips.EVENT_CALCULATION_METHOD}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <FieldGroup id="evtAttUnderlyingEventSpecification" name="evtAttUnderlyingEventSpecification" type="textarea" label="Underlying Event Specification"
                value={values.evtAttUnderlyingEventSpecification}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
                as="textarea"
                style={{ "height": "90px" }}
                help={"The legal language describing how the market outcome and expiration is determined."}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={2} xs={2} md={2}>
              <DropdownListGroup
                id="evtAttStrikeUnit"
                name="evtAttStrikeUnit"
                label="Strike Unit"
                enabled={!values.isContinuous}
                value={!values.isContinuous ? values.evtAttStrikeUnit : null }
                errors={errors}
                onChange={(e) => setFieldValue("evtAttStrikeUnit", e)}
                data={EVENT_ATTR_STRIKE_UNIT}
                plaintext={isViewing}
                tooltip={toolTips.EVENT_STRIKE_UNIT}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="evtAttStrikeValue" name="evtAttStrikeValue" type="textarea" label="Strike Value" isRequired={!values.isContinuous}
                disabled={values.isContinuous}
                value={values.evtAttStrikeValue}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="evtAttEvaluationType" name="evtAttEvaluationType" label="Evaluation Type" isRequired={!values.isContinuous}
                value={!values.isContinuous ? values.evtAttEvaluationType : null}
                enabled={!isViewing && !values.isContinuous}
                errors={errors}
                onChange={(e) => setFieldValue("evtAttEvaluationType", e)}
                data={EVALUATION_TYPES}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroupYMD id="evtAttTimeSpecifier" name="evtAttTimeSpecifier" type="textarea" label="Event Time Specifier"
                disabled={!values.isEditable}
                value={values.evtAttTimeSpecifier}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={(e) => {
                  setFieldValue(`evtAttTimeSpecifier.${e.option}`, e.value)
                }}
                yearData={years}
                monthData={months}
                dayData={days}
                onBlur={handleBlur}
                plaintext={isViewing}
                tooltip={toolTips.EVENT_TIME_SPECIFIER}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="evtAttStrikeEvaluationLowerLimit" name="evtAttStrikeEvaluationLowerLimit" type="textarea" label="Strike Evaluation Lower Limit"
                disabled={values.isContinuous}
                value={values.evtAttStrikeEvaluationLowerLimit}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
                tooltip={toolTips.EVENT_STRIKE_EVALUATION_LOWER_LIMIT}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="evtAttStrikeEvaluationUpperLimit" name="evtAttStrikeEvaluationUpperLimit" type="textarea" label="Strike Evaluation Upper Limit"
                disabled={values.isContinuous}
                value={values.evtAttStrikeEvaluationUpperLimit}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={handleBlur}
                plaintext={isViewing}
                tooltip={toolTips.EVENT_STRIKE_EVALUATION_UPPER_LIMIT}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
               <DateGroup id="evtExpectedPayoutDateTime" name="evtExpectedPayoutDateTime" label="Expected Payout Date/Time"
                value={values.evtExpectedPayoutDateTime ? new Date(values.evtExpectedPayoutDateTime) : null}
                 errors={errors}
                 onChange={(e) => setFieldValue("evtExpectedPayoutDateTime", e)}
                 onBlur={handleBlur}
                 enableTime={true}
                 plaintext={isViewing}
              />
            </Col>
          </Row>

          {!!values.subTypesList && values.subTypesList.length > 0 && <Row>
            <Col lg={3} xs={3} md={3}>
              <h6>Sub Types</h6>
            </Col>
          </Row>}

          <SubTypes 
             values={values}
             setFieldValue={setFieldValue}
             touched={touched}
             handleBlur={handleBlur}
             onChange={onChange}
             errors={errors} 
             mode={mode} />
        </div>
      </Collapse>
    </>
  )
}

export const ValidateEventInfo = (values, errors) => {
  if (values.isContinuous) {
    if (values.evtAttPayoutUpperBound <= 0
      || Number.isNaN(Number.parseFloat(values.evtAttPayoutUpperBound))
      || !Number.isFinite(Number.parseFloat(values.evtAttPayoutUpperBound))) {
      errors.evtAttPayoutUpperBound = 'Payout Upper Bound must be greater than 0.';
      return errors
    } else if (values.evtAttPayoutLowerBound > values.evtAttPayoutUpperBound
        || Number.isNaN(Number.parseFloat(values.evtAttPayoutLowerBound))
        || !Number.isFinite(Number.parseFloat(values.evtAttPayoutLowerBound))) {
      errors.evtAttPayoutLowerBound = 'Payout Lower Bound must be less than or equal to Payout Upper Bound.';
      return errors
    }
  }
  else {
    errors = validateField(values, errors, "evtAttStrikeValue", "Strike Value", STRING_TYPE, true);
    if (values.evtAttPayoutValue <= 0
      || Number.isNaN(Number.parseFloat(values.evtAttPayoutValue))
      || !Number.isFinite(Number.parseFloat(values.evtAttPayoutValue))) {
      errors.evtAttPayoutValue = 'Instrument Payout Value must be greater than 0.';
      return errors
    } else {
      const fraction = Fraction.getFraction(values.evtAttPayoutValue);
      const fractionToMinimum = Fraction.reduce(fraction.numerator, fraction.denominator);
      if (fractionToMinimum.numerator !== 1) {
        errors.evtAttPayoutValue = 'Instrument Payout Value must be of format 1/n where n is an integer when present.';
      }
    }
    if (!values.evtAttEvaluationType) {
      errors.evtAttEvaluationType = "Evaluation Type is required."
    }
  }

  if (!!values.evtAttTimeSpecifier) {
    if (values.evtAttTimeSpecifier.day > 0 && values.evtAttTimeSpecifier.month === 0) {
      errors.evtAttTimeSpecifier = "Month value must be greater than 0."
    } else if (values.evtAttTimeSpecifier.month > 0 && values.evtAttTimeSpecifier.year === 0) {
      errors.evtAttTimeSpecifier = "Year value must be greater than 0."
    }
  }

  if (Array.isArray(values.subTypesList)) {
    var priceScale = 1;
    if (values.minimumPriceIncrement !== null) {
      const scale = Math.pow(10, countDecimals(values.minimumPriceIncrement));
      if (scale !== 0) priceScale = scale;
    }
    values.subTypesList.forEach((subType, idx) => {
      if (!validScaledPrice(subType.buyPriceIncrement, priceScale)) {
        errors[`subTypesList.${idx}.buyPriceIncrement`] = "Invalid buy price increment"
      }
      if (!validScaledPrice(subType.sellPriceIncrement, priceScale)) {
        errors[`subTypesList.${idx}.sellPriceIncrement`] = "Invalid sell price increment"
      }
    });
  }

  return errors;
}