import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  CleanInstrument,
  fetchInstrument,
  fetchFromProduct,
  fetchMetadata,
  listHolidayCalendars
} from "../actions/instruments";
import { LoadFirms } from "../actions/firms";
import "./Content.css";
import Loader from "../components/core/loader/Loader";
import InstrumentHeader from "../components/instrument/InstrumentHeader";
import InstrumentFormContainer from "../containers/InstrumentFormContainer";
import { AdminDocumentTitle } from "../constants/strings";

const queryString = require("query-string");

function mapStateToProps(state) {
  return {
    fetchingInstrument: state.instruments.fetchingInstrument,
    isUpdatingState: state.instruments.isUpdatingState,
    isCalculatingDates: state.instruments.isCalculatingDates,
    isUpdatingSchedule: state.instruments.isUpdatingSchedule,
    instrument: state.instruments.instrument,
    firms: state.firms.firms,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchInstrument: (id) => {
    dispatch(fetchInstrument(id));
  },
  fetchMetadata: () => {
    dispatch(fetchMetadata());
  },
  listHolidayCalendars: () => {
    dispatch(listHolidayCalendars())
  },
  cleanInstrument: () => {
    dispatch(CleanInstrument());
  },
  fetchFromProduct: (productId) => {
    dispatch(fetchFromProduct(productId));
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
});

class InstrumentFormPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  static tryGetJsonString(str) {
    if (Number.isFinite(Number(str))) {
      // Incase of number JSON.parse return valid
      return null
    }

    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.props.cleanInstrument();
    this.props.fetchMetadata();
    this.props.listHolidayCalendars();
    const instrumentId = this.props.match.params.id;

    if (instrumentId) {
      const decodedInstId = decodeURIComponent(instrumentId);
      if (InstrumentFormPage.tryGetJsonString(decodedInstId) === null) {
        this.props.fetchInstrument(decodedInstId);
      }
    }

    const params = queryString.parse(this.props.location.search);
    if (params.productId) {
      this.onProductListChange({ id: params.productId });
    }

    this.props.loadFirms();
    window.scrollTo(0, 0);
  }

  onProductListChange = (selectedProduct) => {
    if(selectedProduct.id) {
      this.props.fetchFromProduct(selectedProduct.id);
    } else {
      this.props.cleanInstrument();
    }
  };

  openCreateInstrument = () => {
    this.context.router.history.push(`/instruments/new`);
  };

  render() {
    var selection = null;
    const instrumentId = this.props.match.params.id;
    if (instrumentId) {
      selection = InstrumentFormPage.tryGetJsonString(
        decodeURIComponent(instrumentId)
      );
    }

    return (
      <div className="with-callback">
        <InstrumentHeader
          buttonText="Add New Instrument"
          buttonClick={() => {
            this.openCreateInstrument();
          }}
          isInstrumentsSelected={true}
          instrumentId={instrumentId}
        />
        <Loader
          show={this.props.fetchingInstrument || this.props.isUpdatingState || this.props.isCalculatingDates}
        ></Loader>
        <InstrumentFormContainer
          onProductListChange={this.onProductListChange}
          selection={selection}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InstrumentFormPage)
);
