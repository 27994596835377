export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCEED = 'FETCH_PRODUCTS_SUCCEED';
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED';
export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START';
export const FETCH_PRODUCT_SUCCEED = 'FETCH_PRODUCT_SUCCEED';
export const FETCH_PRODUCT_FAILED = 'FETCH_PRODUCT_FAILED';
export const CLEAN_PRODUCT = 'CLEAN_PRODUCT';
export const EDIT_PRODUCT_CHANGED = 'EDIT_PRODUCT_CHANGED';
export const CREATE_PRODUCT_STATE = 'CREATE_PRODUCT_STATE';
export const SET_PRODUCT_PROCESS_NAME = 'SET_PRODUCT_PROCESS_NAME';
export const SYNC_TRADING_INFO = "SYNC_TRADING_INFO";
export const SYNC_TRADE_DAY_ROLL_SCHEDULE = "SYNC_TRADE_DAY_ROLL_SCHEDULE";
export const SYNC_ADDITIONAL_INFO = "SYNC_ADDITIONAL_INFO";
export const SYNC_CROSS_ORDER_INFO = "SYNC_CROSS_ORDER_INFO";
export const SYNC_SETTLEMENT_PRICE_CALC_INFO = "SYNC_SETTLEMENT_PRICE_CALC_INFO";
