import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import DataGridActions from '../core/data-grid/DataGridActions';
import AdminRequestsDetail from '../admin-requests/AdminRequestsDetail';
import usePreserveGridColsLayout from '../hooks/usePreserveGridColsLayout';
import { isArrayWithValues } from '../../modules/util';

const AdminRequestsDataGrid = ({
  data,
  onUpdate,
  onPageChange,
  onPageSizeChange,
  paginationComponent,
  onRefreshData,
}) => {

  const [showDetail, setShowDetail] = useState(false);
  const [request, setRequest] = useState({});
  const { columnsLayout, saveColumnsLayout } = usePreserveGridColsLayout("AdminRequestsDGColumnsLayout");

  const onView = (rowId) => {
    setShowDetail(true);
    const request = data.find((req) => req.id === rowId)

    if(request) {
      setRequest(request);
    }
  }

  const onClose = () => {
    setShowDetail(false);
  }

  const onApprove = () => {
    onUpdate(request.id, true, (err, resp)=>{
      if (!err){ 
        setShowDetail(false);
        onRefreshData();
      }
    });
  }

  const onReject = () => {
    onUpdate(request.id, false, (err, resp)=>{
      if (!err){ 
        setShowDetail(false);
        onRefreshData();
      }
    });
  }

  const onColumnResize = (newResized, event) => {
    if (isArrayWithValues(newResized)) {
      newResized.map((column) => {
        columnsLayout[column.id] = column.value;
      });
    }

    saveColumnsLayout(columnsLayout);
  }

  const getColumnWidth = (id) => {
    return columnsLayout[id] ? columnsLayout[id] : undefined;
  }

  const columns = [
    {
      Header: 'REQUEST TIME',
      accessor: 'creationTime',
      maxWidth: 180,
      minWidth: 180,
      width: getColumnWidth("creationTime"),
    },
    {
      Header: 'REQUEST NAME',
      accessor: 'name',
      maxWidth: 150,
      minWidth: 150,
      width: getColumnWidth("name"),
    },
    {
      Header: 'REQUESTING USER',
      accessor: 'user',
      maxWidth: 350,
      minWidth: 350,
      width: getColumnWidth("user"),
    },
    {
      Header: 'APPROVAL COUNT',
      accessor: 'approvalCount',
      maxWidth: 140,
      minWidth: 140,
      width: getColumnWidth("approvalCount"),
    },
    {
      Header: 'REQUIRED APPROVALS',
      accessor: 'requiredApprovals',
      maxWidth: 150,
      minWidth: 150,
      width: getColumnWidth("requiredApprovals"),
    },
    {
      Header: 'STATUS',
      accessor: 'status.name',
      maxWidth: 120,
      minWidth: 120,
      width: getColumnWidth("status.name"),
    },
    {
      Header: 'ORIGINAL API CALL STATUS',
      accessor: 'returnStatus.name',
      maxWidth: 200,
      minWidth: 200,
      width: getColumnWidth("returnStatus.name"),
    },
    {
      Header: 'APPROVING USERS',
      id: "approvalUsers",
      accessor: (d) => d.approvalUsers.join(', '),
      maxWidth: 350,
      minWidth: 350,
      width: getColumnWidth("approvalUsers"),
    },
    {
      Header: 'ACTIONS',
      maxWidth: 100,
      minWidth: 100,
      sortable: false,
      Cell: row => (
        <DataGridActions
          onView={() => { onView(row.original.id) }}
        />
      )
    }
  ];
  return (
    <div>
      <DataGrid
        data={data}
        columns={columns}
        filterable={false}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onResizedChange={onColumnResize}
        PaginationComponent={paginationComponent}
        manual={true}
      />
      {showDetail && (
        <AdminRequestsDetail requestData={request} onDialogClose={onClose} onApprove={onApprove} onReject={onReject} />
      )}
    </div>
  )
};

AdminRequestsDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default AdminRequestsDataGrid;