import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { convertScaledPriceToInt, isNullOrUndefined, validScaledPrice } from "../../modules/util";
import { Col, Row, } from "react-bootstrap";
import Notification from "../../modules/notifications";
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import AccountService from "../../services/AccountService";
import AutoComplete from "../core/auto-complete/AutoComplete";
import { FormGroup, FormLabel, FormControl, Container } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import DropdownListGroup from "../core/form/DropdownListGroup";

export class AdjustmentForm extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = { value: null, override: null, isAdjusting: null, description: "" };
    }

    getActionTypes = () => {
        return [
            {id: null, name: "Undefined"},
            {id: true, name: "Adjust By"},
            {id: false, name: "Set To"}
        ];
    }

    render() {
        const { show, reload, scales, symbol, currency, account, onComplete, defaultValue, dataSourceOptions } = this.props;
        const selectedOption = !isNullOrUndefined(this.state.override) && this.state.override !== "" ? this.state.override : !isNullOrUndefined(symbol) ? symbol : !isNullOrUndefined(currency) ? currency : null;
        let valid = (Array.isArray(dataSourceOptions)
            && dataSourceOptions.includes(selectedOption)
            && this.state.description.trim().length > 0);
        if (isNullOrUndefined(symbol)) {
            valid = valid && !isNullOrUndefined(this.state.isAdjusting);
        }

        return (
            <ConfirmDialog
                show={!!show}

                proceed={() => {
                    if (!isNullOrUndefined(symbol)) {
                        const scale = !isNullOrUndefined(scales[selectedOption]) && !!scales[selectedOption] ? scales[selectedOption] : 1;
                        if (!validScaledPrice(this.state.value, scale)) {
                            Notification.error(this.state.value + " is an invalid quantity for " + selectedOption);
                            return
                        }

                        const scaledAdjustment = convertScaledPriceToInt(this.state.value, scale);
                        AccountService.adjustPosition(account, selectedOption, scaledAdjustment, (err, response) => {
                            if (response) {
                                Notification.success("Position adjusted!");
                                reload();
                            }
                            if (err) {
                                Notification.error(err.message);
                            }
                        });
                    } else if (!isNullOrUndefined(currency)) {
                        if (!validScaledPrice(this.state.value, 100000000)) {
                            Notification.error(this.state.value + " is an invalid quantity for " + selectedOption);
                            return
                        }

                        if (this.state.isAdjusting) {
                            AccountService.adjustBalance(account, selectedOption, this.state.value, this.state.description, (err, response) => {
                                if (response) {
                                    Notification.success("Balance adjusted!");
                                    reload();
                                }
                                if (err) {
                                    Notification.error(err.message);
                                }
                            });
                        } else {
                            AccountService.setBalance(account, selectedOption, this.state.value, this.state.description, (err, response) => {
                                if (response) {
                                    Notification.success("Balance set!");
                                    reload();
                                }
                                if (err) {
                                    Notification.error(err.message);
                                }
                            });
                        }
                    }

                    this.setState({ value: null, override: null, isAdjusting: null, description: "" });
                    onComplete();
                }}

                cancel={() => {
                    this.setState({ value: null, override: null, isAdjusting: null, description: "" });
                    onComplete();
                }}

                confirmation={
                    <Container>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <FormLabel>Currency</FormLabel>
                                    <AutoComplete
                                        id="symbol"
                                        placeholder="Enter Currency"
                                        noItemsMessage="No results found"
                                        dataSource={dataSourceOptions}
                                        defaultValue={currency}
                                        onChange={(e) => this.setState({ override: e.target.value })}
                                        onEnter={(value) => this.setState({ override: value })}
                                        onSelect={(value) => this.setState({ override: value })}
                                        disable={false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        {isNullOrUndefined(symbol) && <Row>
                            <Col>
                                <DropdownListGroup id="actionType" name="actionType" label="Adjust/Set"
                                                   value={this.state.isAdjusting}
                                                   onChange={(e) => this.setState({ isAdjusting: e.id })}
                                                   data={this.getActionTypes()}
                                />
                            </Col>
                        </Row>}

                        <Row>
                            <Col>
                                <FormGroup>
                                    <FormLabel>Balance Amount</FormLabel>
                                    <FormControl
                                        readOnly={false}
                                        type="text"
                                        value={isNullOrUndefined(this.state.value) ? defaultValue : this.state.value}
                                        onChange={(e) => this.setState({ value: e.target.value })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FieldGroup id="description" name="description" type="textarea" label="Description of Change"
                                    value={this.state.description}
                                    onChange={(e) => this.setState({ description: e.target.value })}
                                    onBlur={(e) => this.setState({ description: e.target.value })}
                                    help={"This description will show in the Ledger entry for this change."}
                                />
                            </Col>
                        </Row>
                    </Container>
                }

                options={{
                    title: isNullOrUndefined(symbol) ? "Set Balance" : "Adjust Position",
                    okButtonText: valid ? "Submit" : null,
                    cancelButtonText: "Cancel",
                }}
            />
        );
    }
}

AdjustmentForm.propTypes = {
    show: PropTypes.bool.isRequired,
    dataSourceOptions: PropTypes.array.isRequired,
    account: PropTypes.string,
    symbol: PropTypes.string,
    currency: PropTypes.string,
    defaultValue: PropTypes.string,
    reload: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
}

export default AdjustmentForm;