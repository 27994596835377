import { TRADE_DAY_ROLL_SCHEDULE_TYPE_CONFIGURE_BY_DAY, TRADE_DAY_ROLL_SCHEDULE_TYPE_SAME_EVERY_DAY } from '../../constants/tradeDayRollScheduleTypes.js';
import { isNullOrUndefined } from '../util.js';

const { TradeDayRoll } = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb.js');
const { TimeOfDay, DayOfWeek } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/type/v1beta1/type_pb");

export default class TradeDayRollMapper {
  static mapTradeDayRollSchedule(tradeDayRollSchedule) {
    const schedule = [];
    const scheduleType = tradeDayRollSchedule.scheduleType;

    if (scheduleType == TRADE_DAY_ROLL_SCHEDULE_TYPE_SAME_EVERY_DAY) {
      let tradeDayRoll = new TradeDayRoll();
      schedule.push(tradeDayRoll);

      tradeDayRoll.setDaysOfWeekList([
        DayOfWeek.DAY_OF_WEEK_MONDAY,
        DayOfWeek.DAY_OF_WEEK_TUESDAY,
        DayOfWeek.DAY_OF_WEEK_WEDNESDAY,
        DayOfWeek.DAY_OF_WEEK_THURSDAY,
        DayOfWeek.DAY_OF_WEEK_FRIDAY,
        DayOfWeek.DAY_OF_WEEK_SATURDAY,
        DayOfWeek.DAY_OF_WEEK_SUNDAY,
      ]);

      tradeDayRoll.setTimeOfDay(this.timeToTimeOfDay(tradeDayRollSchedule.cutoffTime))
    }

    if (scheduleType == TRADE_DAY_ROLL_SCHEDULE_TYPE_CONFIGURE_BY_DAY) {

      if (!isNullOrUndefined(tradeDayRollSchedule.monCutoffTime)) schedule.push(this.tradeDayRollByDay(DayOfWeek.DAY_OF_WEEK_MONDAY, tradeDayRollSchedule.monCutoffTime));

      if (!isNullOrUndefined(tradeDayRollSchedule.tueCutoffTime)) schedule.push(this.tradeDayRollByDay(DayOfWeek.DAY_OF_WEEK_TUESDAY, tradeDayRollSchedule.tueCutoffTime));

      if (!isNullOrUndefined(tradeDayRollSchedule.wedCutoffTime)) schedule.push(this.tradeDayRollByDay(DayOfWeek.DAY_OF_WEEK_WEDNESDAY, tradeDayRollSchedule.wedCutoffTime));

      if (!isNullOrUndefined(tradeDayRollSchedule.thuCutoffTime)) schedule.push(this.tradeDayRollByDay(DayOfWeek.DAY_OF_WEEK_THURSDAY, tradeDayRollSchedule.thuCutoffTime));

      if (!isNullOrUndefined(tradeDayRollSchedule.friCutoffTime)) schedule.push(this.tradeDayRollByDay(DayOfWeek.DAY_OF_WEEK_FRIDAY, tradeDayRollSchedule.friCutoffTime));

      if (!isNullOrUndefined(tradeDayRollSchedule.satCutoffTime)) schedule.push(this.tradeDayRollByDay(DayOfWeek.DAY_OF_WEEK_SATURDAY, tradeDayRollSchedule.satCutoffTime));

      if (!isNullOrUndefined(tradeDayRollSchedule.sunCutoffTime)) schedule.push(this.tradeDayRollByDay(DayOfWeek.DAY_OF_WEEK_SUNDAY, tradeDayRollSchedule.sunCutoffTime));
    }

    return schedule;
  }

  /**
   * Converts a given time string to a TimeOfDay object.
   * 
   * @param time: 13:25 - The time to be converted.
   * @returns {TimeOfDay} - The converted TimeOfDay object.
   */
  static timeToTimeOfDay(timeStr) {
    const timeSplit = timeStr.split(':');

    let timeOfDay = new TimeOfDay()
    timeOfDay.setHours(timeSplit[0]);
    timeOfDay.setMinutes(timeSplit[1]);
    timeOfDay.setSeconds(0);

    return timeOfDay;
  }

  static tradeDayRollByDay(dayOfWeek, cutoffTime) {
    let tradeDayRoll = new TradeDayRoll();
    tradeDayRoll.setDaysOfWeekList([dayOfWeek]);
    tradeDayRoll.setTimeOfDay(this.timeToTimeOfDay(cutoffTime))
    return tradeDayRoll;
  }
}
