import {
  getEnumName,
  capitalizeWord
} from '../modules/util';
import { ParticipantState, ParticipantRole } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb';
import State from './dto/State';
import UserAttributes from './UserAttributes'

export default class Participant {
  constructor(item) {
    if (item) {
      this.id = item.getId();
      this.name = item.getName();
      this.displayName = item.getDisplayName();
      this.groups = item.getGroupIdsList();

      var state = new State();
      state.id = item.getState();
      state.name = capitalizeWord(getEnumName(ParticipantState, state.id));
      this.state = state;
      this.isSuspended = state.id === ParticipantState.PARTICIPANT_STATE_SUSPENDED;

      var role = new State();
      role.id = item.getRole();
      role.name = capitalizeWord(getEnumName(ParticipantRole, role.id));
      this.role = role;
      this.firm = item.getFirm();
      this.serviceUser = item.getServiceUser();
      this.deleted = item.getDeleted();
      this.attributes = new UserAttributes();
      this.aliases = [];
      item.getAliasesMap().forEach((alias, option) => {
        this.aliases.push({ option, alias });
      });

      this.accounts = new Map();
      item.getAccountsMap().forEach((capacity, account) => { 
        this.accounts.set(account, capacity)
      })

      return;
    }
    this.id = null;
    this.displayName = null;
    this.name = null;
    this.groups = null;
    this.state = null;
    this.isSuspended = null;
    this.role = null;
    this.firm = null;
    this.serviceUser = null;
    this.deleted = null;
    this.aliases = [];
  }
}
