import React from "react";
import { Col, Row } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import DropdownListGroup from "../core/form/DropdownListGroup";
import CheckboxGroup from '../core/form/CheckboxGroup';
import AccountStaticData from "../../modules/accountStaticData"
import { FormModes } from "../../constants/enumerations";

const { BankIdType, BankDetailsType } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb.js");

const AccountBankDetails = (props) => {
  const { values, errors, onChange, setFieldValue, mode, parent } = props;
  const bankIdTypes = AccountStaticData.getBankIdTypes([BankIdType.BANK_ID_TYPE_UNDEFINED])
  const bankAccountTypes = AccountStaticData.getBankAccountTypes([BankDetailsType.BANK_DETAILS_TYPE_UNDEFINED])
  const isViewing = mode === FormModes.view;

  const style = {
    marginLeft: "1px"
  }

  return (
    <div className="account-bank-details" >
      {parent === "CreateBankDetailsForm" && <>
        <Row>
          <Col>
            <DropdownListGroup
              id="bankAccountType" name="bankAccountType" errors={errors} label="Bank Account Type"
              value={values.bankAccountType}
              onChange={(e) => setFieldValue("bankAccountType", e.id)}
              data={bankAccountTypes}
              textField="name"
              valueField="id"
              plaintext={isViewing}
              isRequired={true}
            />
          </Col>
        </Row>
        <Row style={style}>
          <CheckboxGroup
              checked={values.pb_default}
              setFieldValue={setFieldValue}
              onClick={(e) => { setFieldValue("pb_default", e.target.checked) }}
              label="Default"
              disabled={isViewing}
              tooltip="Exactly one Bank Account account per Bank Account Type must be set as the Default account"
          />
        </Row>
      </>}
      <Row>
        <Col>
          <FieldGroup id="bankId" name="bankId" errors={errors} onChange={onChange}
            value={values.bankId} label="Bank Id" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <DropdownListGroup
            id="bankIdType" name="bankIdType" errors={errors} label="Bank Id Type"
            value={values.bankIdType}
            onChange={(e) => setFieldValue("bankIdType", e.id)}
            data={bankIdTypes}
            textField="name"
            valueField="id"
            plaintext={isViewing}
          />
        </Col>
      </Row>


      {parent === "CreateWithdrawalForm" &&
        <Row>
          <Col>
            <FieldGroup id="cashAccountNumber" name="cashAccountNumber" errors={errors} onChange={onChange}
              value={values.cashAccountNumber || ""} label="Account Number" plaintext={isViewing} />
          </Col>
        </Row>
      }

      {parent === "CreateSecurityWithdrawalForm" &&
        <Row>
          <Col>
            <FieldGroup id="securitiesAccountNumber" name="securitiesAccountNumber" errors={errors} onChange={onChange}
              value={values.securitiesAccountNumber || ""} label="Securities Account Number" plaintext={isViewing} />
          </Col>
        </Row>
      }

      {(parent === "AccountForm" || parent === "CreateBankDetailsForm") && <>
        <Row>
          <Col>
            <FieldGroup id="cashAccountNumber" name="cashAccountNumber" errors={errors} onChange={onChange}
              value={values.cashAccountNumber || ""} label="Cash Account Number" plaintext={isViewing} />
          </Col>
        </Row>

        <Row>
          <Col>
            <FieldGroup id="securitiesAccountNumber" name="securitiesAccountNumber" errors={errors} onChange={onChange}
              value={values.securitiesAccountNumber || ""} label="Securities Account Number" plaintext={isViewing} />
          </Col>
        </Row>
      </>}

      <Row>
        <Col>
          <FieldGroup id="accountTitle" name="accountTitle" errors={errors} onChange={onChange}
            value={values.accountTitle || ""} label="Account Title" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="beneficiaryName" name="beneficiaryName" errors={errors} onChange={onChange}
            value={values.beneficiaryName || ""} label="Beneficiary Name" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="beneficiaryAddress1" name="beneficiaryAddress1" errors={errors} onChange={onChange}
            value={values.beneficiaryAddress1 || ""} label="Beneficiary Address 1" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="beneficiaryAddress2" name="beneficiaryAddress2" errors={errors} onChange={onChange}
            value={values.beneficiaryAddress2 || ""} label="Beneficiary Address 2" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="beneficiaryCity" name="beneficiaryCity" errors={errors} onChange={onChange}
            value={values.beneficiaryCity || ""} label="Beneficiary City" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="beneficiaryState" name="beneficiaryState" errors={errors} onChange={onChange}
            value={values.beneficiaryState || ""} label="Beneficiary State" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="beneficiaryPostalCode" name="beneficiaryPostalCode" errors={errors} onChange={onChange}
            value={values.beneficiaryPostalCode || ""} label="Beneficiary Postal Code" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="beneficiaryCountryCode" name="beneficiaryCountryCode" errors={errors} onChange={onChange}
            value={values.beneficiaryCountryCode || ""} label="Beneficiary Country Code" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="bankName" name="bankName" errors={errors} onChange={onChange}
            value={values.bankName || ""} label="Bank Name" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="bankAddress1" name="bankAddress1" errors={errors} onChange={onChange}
            value={values.bankAddress1 || ""} label="Bank Address 1" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="bankAddress2" name="bankAddress2" errors={errors} onChange={onChange}
            value={values.bankAddress2 || ""} label="Bank Address 2" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="bankCity" name="bankCity" errors={errors} onChange={onChange}
            value={values.bankCity || ""} label="Bank City" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="bankState" name="bankState" errors={errors} onChange={onChange}
            value={values.bankState || ""} label="Bank State" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="bankPostalCode" name="bankPostalCode" errors={errors} onChange={onChange}
            value={values.bankPostalCode || ""} label="Bank Postal Code" plaintext={isViewing} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldGroup id="bankCountryCode" name="bankCountryCode" errors={errors} onChange={onChange}
            value={values.bankCountryCode || ""} label="Bank Country Code" plaintext={isViewing} />
        </Col>
      </Row>
    </div>
  );
}

export default AccountBankDetails;