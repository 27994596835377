import {isEmptyString, isNullOrUndefined, valueOrDefault} from '../util';
import ProtobufParser from "../../modules/protobufParser";

const {
  CommissionAssignment,
  CommissionRule
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb.js");

const {
  Commission
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb.js");

export class CommissionMapper {
  static mapToCommission(data) {

    let commission = new Commission();
    commission.setAccount(data.accountId);
    commission.setBasisPoints(data.basisPoints);
    commission.setApplyAsSpread(!data.minimumNotional && !!data.applyAsSpread);
    
    const minimumNotional = !!data.minimumNotional && !isEmptyString(data.minimumNotional) ? data.minimumNotional : "";
    commission.setMinimumNotional(minimumNotional);
    if (!isEmptyString(minimumNotional) && !isNullOrUndefined(data.maximumRateBasisPoints)) commission.setMaximumRateBasisPoints(data.maximumRateBasisPoints);
 
    const maximumNotional = !!data.maximumNotional && !isEmptyString(data.maximumNotional) ? data.maximumNotional : "";
    commission.setMaximumNotional(maximumNotional);
  
    let rule = CommissionMapper.mapToCommissionRule(data);

    let cAssignment = new CommissionAssignment();
    cAssignment.setCommission(commission);
    cAssignment.setRule(rule);

    return cAssignment;
  }

  static mapToCommissionRule(data) {
    let rule = new CommissionRule();
    if (!isNullOrUndefined(data.firm)) rule.setFirm(valueOrDefault(data.firm, null));
    if (!isNullOrUndefined(data.userOverride)) rule.setUserOverride(data.userOverride);
    if (!isNullOrUndefined(data.accountOverride)) rule.setAccountOverride(data.accountOverride);
    if (!isNullOrUndefined(data.symbolOverride)) rule.setSymbolOverride(data.symbolOverride);
    if (!isNullOrUndefined(data.sideOverride)) rule.setSideOverride(data.sideOverride.id);

    if (!isNullOrUndefined(data.expirationTime)) {
      const expirationTime = ProtobufParser.toTimestamp(data.expirationTime);
      rule.setExpirationTime(expirationTime);
    }

    return rule;
  }
}
