import {
  getEnumName,
  capitalizeWord
} from '../modules/util';

import Order from "./Order";
import Trade from "./Trade";
import State from './dto/State';

import { TradeCaptureReportState } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb';
import { tsProtoToDate } from "../actions/ptypes";

export default class TradeCapture {
  constructor(item) {
    if (item) {
      const object = item.toObject();
      this.id = object.id;
      this.trade = new Trade(item.getTrade());
      this.tradeTime = tsProtoToDate(item.getTradeTime());
      const clearedTime = item.getClearedTime()
      this.clearedTime =  clearedTime ? tsProtoToDate(clearedTime) : null;
      const orders = item.getOrdersList();
      let ordersList = [];
      for(var i=0; orders.length > i;i++)
      {
        ordersList.push(new Order(orders[i]));
      }
      this.orders = ordersList;

      const stateValue = item.getState();
      let state = new State();
      state.id = stateValue;
      state.name = capitalizeWord(getEnumName(TradeCaptureReportState, stateValue));
      this.state = state;
      this.reportingCounterparty = this.trade.reportingCounterparty;
      this.text = this.trade.text;
      return;
    }
    this.id = null;
    this.trade = null;
    this.tradeTime = null;
    this.clearedTime = null;    
    this.state = null;
    this.orders = null;
    this.reportingCounterparty = null;
    this.text = null;
  }
}
