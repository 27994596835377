import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl
} from 'react-bootstrap';

import './AutoComplete.css';

const keyCodeEnter = 13;
const keyCodeArrowUp = 38;
const keyCodeArrowDown = 40;

class AutoComplete extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    noItemsMessage: PropTypes.string.isRequired,
    itemsPerPage: PropTypes.number,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    dataSource: PropTypes.instanceOf(Array),
    // Indicates how many chars before updates dataSource.
    charsToStartSearch: PropTypes.number,
    // Indicates whether the component must update or not its dataSource.
    keepRefreshing: PropTypes.bool,
    onEnter: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    dataSource: [],
    charsToStartSearch: 2,
    keepRefreshing: false,
    itemsPerPage: 100,
    disable: false
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered or passed as defaultValue
      userInput: props.defaultValue || '',
      itemsDisplayed: this.props.itemsPerPage
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({ userInput: nextProps.defaultValue });
    }
  }

  onInputChange = e => {
    const userInput = e.currentTarget.value;
    if (userInput.length >= this.props.charsToStartSearch && !this.props.disable) {
      if (this.props.keepRefreshing || this.props.dataSource.length === 0) {
        if (!!this.props.refreshDataSource) this.props.refreshDataSource(userInput);
      } else {
        this.findSuggestions(this.props.dataSource, userInput);
      }
    }
    this.setState({
      userInput: e.currentTarget.value
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      displayedSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });

    this.props.onSelect(e.currentTarget.innerText);
  };

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === keyCodeEnter) {
      if (filteredSuggestions[activeSuggestion]) {
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: filteredSuggestions[activeSuggestion]
        });

        this.props.onEnter(filteredSuggestions[activeSuggestion]);
      }
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === keyCodeArrowUp) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === keyCodeArrowDown) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  findSuggestions = (dataSource, value) => {
    const filteredSuggestions = dataSource.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true
    });
  }

  loadMoreItems = () => {
    this.setState({ itemsDisplayed: this.state.itemsDisplayed + this.props.itemsPerPage });
  }

  render() {
    const {
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions) {
      if (filteredSuggestions.length) {
        const items = filteredSuggestions.slice(0, this.state.itemsDisplayed);
        suggestionsListComponent = (
          <div className="auto-complete-container">
            <ul className="suggestions">
              {items.map((suggestion, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  className = "suggestion-active";
                }

                return (
                  <li
                    className={className}
                    key={suggestion}
                    onClick={onClick}
                  >
                    {suggestion}
                  </li>
                );
              })}
              {filteredSuggestions.length > this.props.itemsPerPage ? <li
                className="load-more"
                key="load-more"
                onClick={() => { this.loadMoreItems() }}
              >
                Load more
              </li> : null}
            </ul>
          </div>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>{this.props.noItemsMessage}</em>
          </div>
        );
      }
    }

    const {
      id,
      placeholder,
      help,
      errors
    } = this.props;

    const hideSuggestions = () => {
      this.setState({ showSuggestions: false });
    }

    return (
      <div className="auto-complete-wrapper">
        <div className="auto-complete-icon">
          <i className="fas fa-search fa-2x"></i>
        </div>
        <FormControl
          id={id}
          autoComplete="off"
          type="text"
          name={id}
          placeholder={placeholder}
          value={userInput}
          onChange={(e) => {
            this.props.onChange(e);
            this.onInputChange(e);
          }}
          onBlur={(e) => { setTimeout(hideSuggestions, 200) }}
          onKeyDown={onKeyDown}
          className="form-control"
          disabled={this.props.disabled}
        />
        {errors && errors[id] && <div className="form-error">
          <i className="fa fa-exclamation-triangle orange-icon" aria-hidden="true"></i> {errors[id]}</div>}
        {suggestionsListComponent}
        {help && <div className="help-block">{help}</div>}
      </div>
    );
  }
}

export default AutoComplete;