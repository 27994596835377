import React, { Component } from "react";
import { connect } from "react-redux";
import AccountDataGrid from "../account/AccountDataGrid";
import PropTypes from "prop-types";
import confirm from "../../modules/confirmDialog";
import AccountService from "../../services/AccountService";
import Notification from "../../modules/notifications";
import { NavigationManager } from "../core/helpers/NavigationHelper";

function mapStateToProps(state) {
  return {
    fetchingAccounts: state.accounts.fetchingAccounts,
    accounts: state.accounts.accounts,
    firm: state.firms.firm,
    firms: state.firms.firms,
  };
}

export class FirmAccountsGrid extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    let { decodedId, loadFirm, loadAccounts, loadFirms } = this.props;
    loadFirm(decodedId);
    loadFirms();
    loadAccounts();
  }

  onView = (accountName, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/${accountName}`)
    } else {
      this.context.router.history.push(`/${accountName}`);
    }
  };

  onEdit = (accountName, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/${accountName}/edit`);
    } else {
      this.context.router.history.push(`/${accountName}/edit`);
    }
  };

  onRemove = (name) => {
    confirm("Are you sure you want to delete the account?", {
      title: "Account Deletion",
      okButtonText: "Delete",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            this.props.loadAccounts();
            Notification.success("Account deleted.");
          }

          if (err) {
            Notification.error(
              `Error when deleting account: \n ${err.message}`
            );
          }
        };

        AccountService.delete(name, cb);
      },
      () => {}
    );
  };

  unDeleteAccount = async (acctName) => {
    confirm("Are you sure you want to restore this account?", {
      title: "Account Restore Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            this.props.loadAccounts();
            Notification.success(`Account ${acctName} restored.`);
          }

          if (err) {
            console.error("could not restore account", err);
            Notification.error("Could not restore account", acctName);
          }
        };

        AccountService.undelete(acctName, cb);
      },
      () => {}
    );
  };

  render() {
    let { accounts, firms } = this.props;

    return (
      <AccountDataGrid
        onView={(accountName, actionContext) => this.onView(accountName, actionContext)}
        onEdit={(accountName, actionContext) => this.onEdit(accountName, actionContext)}
        onRemove={(acct) => this.onRemove(acct)}
        data={accounts}
        firms={firms}
        onUnDelete={(acctName) => {
          this.unDeleteAccount(acctName);
        }}
      />
    );
  }
}

export default connect(mapStateToProps, null)(FirmAccountsGrid);
