import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {isArrayWithValues, numberOrDefault, convertScaledPriceToInt, isEmptyString} from "../../modules/util";
import { Col, Row, } from "react-bootstrap";
import Notification from "../../modules/notifications";
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import AccountService from "../../services/AccountService";
import { Container } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import DropdownListGroup from '../../components/core/form/DropdownListGroup';
import FirmParticipantType from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb"
import { useMemo } from 'react';
import * as toolTips from "../../constants/accountToolTip"
const { LedgerEntryType } = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/type/v1beta1/type_pb.js');

export const TransferPositionForm = (props) => {
    const [quantity, setQuantity] = useState();
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [toAccount, setToAccount] = useState();
    const [toFirm, setToFirm] = useState();
    const [accountsByFirm, setAccountsByFirm] = useState();
    const [participantFirms, setParticipantFirms] = useState([]);
    const [errors, setErrors] = useState({});
    const [transferReason, setTransferReason] = useState(LedgerEntryType.LEDGER_ENTRY_TYPE_MANUAL_TRANSFER);
    const [allocationGroupId, setAllocationGroupId] = useState("");
    const [referenceTradeIds, setReferenceTradeIds] = useState("");

    useEffect(() => {
        resetForm();
    }, []);

    useEffect(() => {
        setAccountsByFirm(filterAccountsByFirm(toFirm))
        setToAccount("");
    }, [toFirm]);

    useEffect(() => {
        setParticipantFirms(filterParticipantFirms());
    }, [props.firms])

    const transferReasons = useMemo(() => ([
        { id: LedgerEntryType.LEDGER_ENTRY_TYPE_MANUAL_TRANSFER, name: 'Position Transfer' },
        { id: LedgerEntryType.LEDGER_ENTRY_TYPE_AVERAGE_PRICE_TRANSFER, name: 'Average Price Transfer' },
        { id: LedgerEntryType.LEDGER_ENTRY_TYPE_GIVE_UP, name: 'Give Up' },
    ]), []);

    const isInputsValid = () => {
        let errors = {
            quantity: "",
            price: "",
            description: "",
            toAccount: "",
            allocationGroupId: "",
            referenceTradeIds: ""
        }

        if (numberOrDefault(quantity, 0) === 0) {
            errors.quantity = "Required."
        }

        if (numberOrDefault(price, 0) === 0) {
            errors.price = "Required."
        }

        if (!toAccount) {
            errors.toAccount = "Required."
        }

        if (isEmptyString(description)) {
            errors.description = "Required."
        }

        if ((transferReason === LedgerEntryType.LEDGER_ENTRY_TYPE_AVERAGE_PRICE_TRANSFER || transferReason === LedgerEntryType.LEDGER_ENTRY_TYPE_GIVE_UP) && isEmptyString(allocationGroupId, true)) {
            errors.allocationGroupId = "Required.";
        }

        if ((transferReason === LedgerEntryType.LEDGER_ENTRY_TYPE_AVERAGE_PRICE_TRANSFER || transferReason === LedgerEntryType.LEDGER_ENTRY_TYPE_GIVE_UP) && isEmptyString(referenceTradeIds, true)) {
            errors.referenceTradeIds = "Required.";
        }

        setErrors(errors)

        return errors.quantity === "" && errors.price === "" && errors.toAccount === "" && errors.description === "" && errors.allocationGroupId === "" && errors.referenceTradeIds === "";
    }

    const { show, reload, account, symbol, symbolSubType, instruments, onComplete } = props;

    const resetForm = () => {
        setQuantity("0");
        setPrice("0");
        setToAccount("");
        setToFirm("");
        setDescription("");
        setTransferReason(LedgerEntryType.LEDGER_ENTRY_TYPE_MANUAL_TRANSFER);
        setAllocationGroupId("");
        setReferenceTradeIds("");
        setErrors({});
    }

    const filterAccountsByFirm = (firm) => {
        return props.accounts.filter(acc => acc.parentFirm === firm || acc.associatedFirm === firm);
    }

    const filterParticipantFirms = () => {
        return props.firms.filter(firm => firm.firmType.id === FirmParticipantType.FirmType.FIRM_TYPE_PARTICIPANT)
    }

    return (
        <ConfirmDialog
            show={!!show}
            proceed={(evt) => {
                let submitButton = evt.target;
                if (isInputsValid()) {
                    let currentInst = instruments.find(inst => inst.id === symbol);
                    let priceScale = !!currentInst ? currentInst.priceScale : 1;
                    const costPerUnit = convertScaledPriceToInt(Number(price), priceScale)
                    submitButton.disabled = true;

                    const referenceTradeIdsList = referenceTradeIds.split(',').map(item => item.trim());

                    AccountService.transferPosition({ entryType: transferReason, allocationGroupId, referenceTradeIds: referenceTradeIdsList, sourceAccountName: account, destinationAccount: toAccount, symbol, subType: symbolSubType, description, quantity, costPerUnit }, (err, response) => {
                        if (!!response) {
                            Notification.success("Position adjusted!");
                            reload();

                            resetForm();
                            onComplete();
                        }

                        if (!!err) {
                            Notification.error(err.message);
                        }

                        if (!!submitButton) submitButton.disabled = false;
                    });
                }
            }}

            cancel={() => {
                resetForm();
                onComplete();
            }}

            confirmation={
                <Container>
                    <Row>
                        <Col>
                            <DropdownListGroup
                                id="transferReason"
                                name="transferReason"
                                label="Transfer Reason"
                                value={transferReason}
                                onChange={(e) => { setTransferReason(e.id); }}
                                data={transferReasons}
                                errors={errors}
                                plaintext={false}
                                showFilter={false}
                                tooltip={toolTips.TRANSFER_REASON}
                            />
                        </Col>
                    </Row>

                    {(transferReason === LedgerEntryType.LEDGER_ENTRY_TYPE_AVERAGE_PRICE_TRANSFER
                        || transferReason === LedgerEntryType.LEDGER_ENTRY_TYPE_GIVE_UP) && <>
                            <Row>
                                <Col>
                                    <FieldGroup
                                        id="allocationGroupId"
                                        name="allocationGroupId"
                                        label="Allocation Group ID"
                                        type="text"
                                        value={allocationGroupId}
                                        errors={errors}
                                        onChange={(e) => setAllocationGroupId(e.target.value)}
                                        tooltip={toolTips.ALLOCATION_GROUP_ID}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FieldGroup
                                        id="referenceTradeIds"
                                        name="referenceTradeIds"
                                        label="Reference Trade IDs"
                                        type="textarea"
                                        as="textarea"
                                        style={{ "height": "60px" }}
                                        value={referenceTradeIds}
                                        errors={errors}
                                        onChange={(e) => setReferenceTradeIds(e.target.value)}
                                        tooltip={toolTips.REFERENCE_TRADE_IDS}
                                    />
                                </Col>
                            </Row>
                        </>}

                    <Row>
                        <Col>
                            <FieldGroup
                                id="quantity"
                                name="quantity"
                                label="Quantity"
                                type="text"
                                value={quantity}
                                errors={errors}
                                onChange={(e) => setQuantity(e.target.value)}
                                tooltip={toolTips.TRANSFER_QTY}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FieldGroup
                                id="price"
                                name="price"
                                label="Price"
                                type="text"
                                value={price}
                                errors={errors}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DropdownListGroup
                                id="toFirm"
                                name="toFirm"
                                label="Destination Firm"
                                value={toFirm}
                                onChange={(e) => { setToFirm(e.name); }}
                                data={participantFirms}
                                errors={errors}
                                textField="displayName"
                                valueField="name"
                                plaintext={false}
                                showFilter={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DropdownListGroup
                                id="toAccount"
                                name="toAccount"
                                label="Destination Account"
                                value={toAccount}
                                onChange={(e) => { setToAccount(e.name); }}
                                data={accountsByFirm}
                                errors={errors}
                                textField="displayName"
                                valueField="name"
                                plaintext={false}
                                showFilter={true}
                                enabled={isArrayWithValues(accountsByFirm)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FieldGroup id="description" name="description" type="textarea" label="Description of Change"
                                        value={description}
                                        errors={errors}
                                        onChange={(e) => setDescription(e.target.value)}
                                        onBlur={(e) => setDescription(e.target.value)}
                                        help={"This description will show in the Ledger entry for this change."}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {!symbolSubType && <span>{`${quantity} ${symbol} will be transferred.`}</span>}
                            {!!symbolSubType && <span>{`${quantity} ${symbol} (SubType: ${symbolSubType}) will be transferred.`}</span>}
                        </Col>
                    </Row>
                </Container>
            }

            options={{
                title: "Transfer Position",
                okButtonText: "Submit",
                cancelButtonText: "Cancel",
            }}
        />
    );

}

TransferPositionForm.propTypes = {
    show: PropTypes.bool.isRequired,
    account: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    symbolSubType: PropTypes.string.isRequired,
    reload: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
}

export default TransferPositionForm;