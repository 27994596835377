import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import slugify from "slugify";
import FieldGroup from "../core/form/FieldGroup";
import CheckboxGroup from "../core/form/CheckboxGroup";
import { Container, Row, Col } from "react-bootstrap";
import ButtonBorderless from "../core/form/ButtonBorderless";
import ButtonMain from "../core/form/ButtonMain";
import FirmsListContainer from "../../containers/FirmsListContainer";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { AccountProperty } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import {isNullOrUndefined, getOptionName, convertEnumToDropdownList} from "../../modules/util";
import { FormModes } from "../../constants/enumerations";
import DropdownListGroup from "../core/form/DropdownListGroup";
import OrderLimit from "../order-limit/OrderLimit";
import OrderLimitReadOnly from "../order-limit/OrderLimitReadOnly";
import Aliases from "../../components/shared/Aliases";
import { Env } from "../../constants/environment"
import FirmsStaticData from "../../modules/firmsStaticData";
import Collapse from "react-bootstrap/Collapse";
import UserAttributesForm from "../users/UserAttributesForm";
import * as toolTips from '../../constants/accountToolTip';
import MultiSelectDropdownListGroup from "../core/form/MultiSelectDropdownListGroup";
import FormatString, {StringFormatOptions} from "../../modules/formatString";
import ErrorLabel from "../core/form/ErrorLabel";

class BasicAccountForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    const accountTypes = FirmsStaticData.AccountTypes();
    this.setState({ accountTypes: accountTypes, isOpen: true, isBankDetailsOpen: false });
  }

  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  getOpen = () => {
    return !!this.state ? !!this.state.isOpen : false;
  }


  getAccountTypes = () => {
    if (!!this.state && !!this.state.accountTypes)
      return this.state.accountTypes

    return [];
  }

  render() {
    const {
      title,
      touched,
      errors,
      isEditing,
      handleChange,
      handleBlur,
      handleSubmit,
      onDisableAccount,
      setFieldValue,
      firm,
      values,
      account,
      firms,
      mode
    } = this.props;

    if (!isEditing && firm && firm.firmType) {
      if (
        firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT &&
        !values.participantFirm
      ) {
        setFieldValue("participantFirm", firm.displayName);
      } else if (
        firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_MEMBER &&
        !values.clearingMember
      ) {
        setFieldValue("clearingMember", firm.displayName);
      } else if (
        firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_HOUSE &&
        !values.clearingHouse
      ) {
        setFieldValue("clearingHouse", firm.displayName);
      }
    }

    const getAccountType = () => {
      if (mode === FormModes.creation || mode === FormModes.edition) {
        return (
          <Row>
            <Col lg={6} xs={6} md={12}>
              <DropdownListGroup id="accountType" name="accountType" label="Account Type"
                value={values.accountType}
                errors={errors}
                onChange={(e) => setFieldValue("accountType", e.id)}
                data={this.getAccountTypes()}
              ></DropdownListGroup>
            </Col>
          </Row>
        );
      } else {
        return (<FieldGroupReadOnly
          id="accountType"
          label="Account Type"
          value={(!!this.state && !!this.state.accountTypes) && getOptionName(this.getAccountTypes(), values.accountType, "")}
        />);
      }
    }

    const thisIsFirmPage = () => {
      return window.location.pathname.startsWith("/firms")
    }

    const enableClearingMemberFirm = () => {
      if (thisIsFirmPage()) {
        return firm.firmType && firm.firmType.id !== FirmType.FIRM_TYPE_CLEARING_MEMBER
      }
      return true;
    }

    const getClearingHouseBlock = () => {
      if (firm && firm.firmType && firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT) {
        return null
      }
      else if (!isNullOrUndefined(values["participantFirm"])) {
        return null
      }
      else if (firm && firm.firmType && firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_HOUSE) {
        return <Row>
          <Col lg={6} xs={6} md={12}>
            <FieldGroupReadOnly
              id="clearingHouse"
              label="Clearing House"
              value={values.clearingHouse}
            />
          </Col></Row>
      }
      return <Row>
        <Col lg={6} xs={6} md={12}>
          <FirmsListContainer
            id="clearingHouse"
            name="clearingHouse"
            label="Clearing House"
            value={values.clearingHouse || ''}
            isRequired={false}
            firmType={FirmType.FIRM_TYPE_CLEARING_HOUSE}
            onChange={(e) => setFieldValue("clearingHouse", e.name)}
            errors={errors}
          /></Col></Row>

    }

    const getParticipantBlock = () => {
      if (firm && firm.firmType && firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_HOUSE) {
        return null
      }
      else if (!isNullOrUndefined(values["clearingHouse"])) {
        return null
      }
      else if (firm && firm.firmType && firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT) {
        return <Row>
          <Col lg={6} xs={6} md={12}><FieldGroupReadOnly
            id="participantFirm"
            label="Participant Firm"
            value={values.participantFirm}
          /></Col></Row>
      }

      return <Row>
        <Col lg={6} xs={6} md={12}><FirmsListContainer
          id="participantFirm"
          name="participantFirm"
          label="Participant Firm"
          value={values.participantFirm || ''}
          isRequired={false}
          firmType={FirmType.FIRM_TYPE_PARTICIPANT}
          onChange={(e) => setFieldValue("participantFirm", e.name)}
          errors={errors}
        ></FirmsListContainer></Col></Row>
    }

    const getClearingMemberFirmName = (associatedFirm, clearingFirm) => {
      if (associatedFirm && clearingFirm) {
        if (clearingFirm.firmType.id === FirmType.FIRM_TYPE_CLEARING_HOUSE) {
          return associatedFirm.displayName
        }
        else {
          return clearingFirm.displayName;
        }
      }

      return "";
    }

    const getRiskSystems = () => {
      let riskSystems = [{ id: "", name: "Default" }];
      let riskSysStr = Env.getEnv("REACT_APP_RISK_SYSTEMS");

      if (riskSysStr && riskSysStr.length > 0) {
        const splitList = riskSysStr.split(',');
        splitList.forEach(riskSys => {
          riskSystems.push({ id: riskSys.trim(), name: riskSys.trim() });
        });
      }

      return riskSystems;
    }

    const getRiskSystemBlock = () => {
      if (mode === FormModes.creation || mode === FormModes.edition) {
        return (
          <Row>
            <Col lg={6} xs={6} md={12}>
              <DropdownListGroup id="riskSystem" name="riskSystem" label="Risk System"
              tooltip={toolTips.RISK_SYSTEM}
                value={values.riskSystem}
                errors={errors}
                onChange={(e) => setFieldValue("riskSystem", e.id)}
                data={getRiskSystems()}
              ></DropdownListGroup>
            </Col>
          </Row>
        );
      } else {
        return (<FieldGroupReadOnly
          id="riskSystem"
          label="Risk System"
          value={values.riskSystem}
        />);
      }
    }

    const getCollateralAccounts = () => {
      let data = null;
      if (this.props.accounts.accounts) {
        data = this.props.accounts.accounts.map(x => { return { "id": x.name, "name": x.displayName } });
      }
      data = [{ "id": "", "name": "" }, ...data];
      return data;
    }

    const getCollateralAccountDisplayName = (name) => {
      if (this.props.accounts.accounts) {
        const account = this.props.accounts.accounts.find(x => x.name === name);
        if (!!account) {
          return account.displayName;
        }
      }

      return "";
    }

    const getCollateralAccountBlock = () => {
      if (mode === FormModes.creation || mode === FormModes.edition) {
        return (
          <Row>
            <Col lg={6} xs={6} md={12}>
              <DropdownListGroup id="collateralAccount" name="collateralAccount" label="Collateral Account"
                value={values.collateralAccount}
                errors={errors}
                onChange={(e) => setFieldValue("collateralAccount", e.id)}
                data={getCollateralAccounts()}
              ></DropdownListGroup>
            </Col>
          </Row>
        );
      } else {
        return (<FieldGroupReadOnly
          id="collateralAccount"
          label="Collateral Account"
          value={getCollateralAccountDisplayName(values.collateralAccount)}
        />);
      }
    }

    const associatedFirm = firms.firms.find((firm) => firm.name === account.associatedFirm);
    const clearingFirm = firms.firms.find((firm) => firm.name === account.parentFirm);

    const aliasOnChange = (aliases) => {
      values.aliases = aliases;
    }

    return (
      <>
        <form onSubmit={handleSubmit}>
          <h1>{title}</h1>
          <Container>
            <Row>
              <Col lg={6} xs={6} md={12}>
                {mode === FormModes.view && (
                  <FieldGroupReadOnly
                    id="accountName"
                    label="Account Name"
                    value={account.displayName}
                  />
                )}

                {(mode === FormModes.creation || mode === FormModes.edition) && (
                  <FormGroup id="displayName">
                    <FormLabel>Account Name</FormLabel>
                    <label className="form-label-required"> Required</label>
                    <FormControl
                      id="displayName"
                      type="text"
                      name="displayName"
                      value={values.displayName}
                      placeholder=""
                      onChange={(e) => {
                        handleChange(e);
                        if (!isEditing) {
                          if (!touched.accountId) {
                            setFieldValue("accountId", slugify(e.target.value));
                          }
                        }
                      }}
                    ></FormControl>
                    {errors && errors["displayName"] && (
                      <div className="form-error">
                        <i
                          className="fa fa-exclamation-triangle orange-icon"
                          aria-hidden="true"
                        ></i>{" "}
                        {errors["displayName"]}
                      </div>
                    )}
                  </FormGroup>
                )}
              </Col>
            </Row>

            <Row>
              <Col lg={6} xs={6} md={12}>
                {(mode === FormModes.view || mode === FormModes.edition) && (
                  <FieldGroupReadOnly
                    id="accountId"
                    label="Account ID"
                    value={values.id}
                  />)}

                {mode === FormModes.creation && (
                  <FieldGroup
                    id="accountId"
                    name="accountId"
                    type="text"
                    label="Account ID"
                    placeholder=""
                    errors={errors}
                    touched={touched}
                    isRequired={true}
                    value={values.accountId || ''}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    help="Account ID must be unique."
                  />)}

              </Col>
            </Row>

            {getAccountType()}

            <Row>
              <Col lg={6} xs={6} md={12}>
            {mode === FormModes.view && (
                <FieldGroupReadOnly
                    id="priorityWeight"
                    label="Account Priority Weight"
                    value={values.priorityWeight}
                />
            )}
            {(mode === FormModes.creation || mode === FormModes.edition || isEditing) && (
                <FieldGroup
                    id="priorityWeight"
                    name="priorityWeight"
                    type="text"
                    label="Account Priority Weight"
                    placeholder=""
                    errors={errors}
                    touched={touched}
                    isRequired={false}
                    value={values.priorityWeight || ''}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    help="Represents the relative weighting of orders within a price level on the central limit order book. May be positive or negative, higher values take priority over other orders within the same price level regardless of time. If not provided, defaults to 0."
                />)}
              </Col>
            </Row>

            {(mode === FormModes.view || mode === FormModes.edition)
              && associatedFirm && associatedFirm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT && (
                <Row>
                  <Col lg={6} xs={6} md={12}>
                    <FieldGroupReadOnly
                      id="participantFirm"
                      label="Participant Firm"
                      value={associatedFirm ? associatedFirm.displayName : null}
                    />{" "}
                  </Col>
                </Row>
              )}

            {mode === FormModes.creation && getParticipantBlock()}

            {(mode === FormModes.view || mode === FormModes.edition) &&
              (clearingFirm && clearingFirm.firmType.id === FirmType.FIRM_TYPE_CLEARING_HOUSE)
              && (
                <Row>
                  <Col lg={6} xs={6} md={12}>
                    <FieldGroupReadOnly
                      id="clearingHouse"
                      label="Clearing House"
                      value={clearingFirm.displayName}
                    />{" "}
                  </Col>
                </Row>
              )}

            {mode === FormModes.creation && getClearingHouseBlock()}

            {(mode === FormModes.view || mode === FormModes.edition) && (
              <Row>
                <Col lg={6} xs={6} md={12}>
                  <FieldGroupReadOnly
                    id="clearingMember"
                    label="Clearing Member Firm"
                    value={getClearingMemberFirmName(associatedFirm, clearingFirm)}
                  />
                </Col>
              </Row>)}

            {mode === FormModes.creation && (
              <Row>
                <Col lg={6} xs={6} md={12}>
                  {enableClearingMemberFirm() ? (
                    <FirmsListContainer
                      id="clearingMember"
                      name="clearingMember"
                      label="Clearing Member Firm"
                      value={values.clearingMember || ''}
                      isRequired={true}
                      firmType={FirmType.FIRM_TYPE_CLEARING_MEMBER}
                      firmParent={values.participantFirm || values.clearingHouse}
                      onChange={(e) => setFieldValue("clearingMember", e.name)}
                      errors={errors}
                    />) : (
                    <FieldGroupReadOnly
                      id="clearingMember"
                      label="Clearing Member Firm"
                      value={values.clearingMember}
                    />
                  )}
                </Col>
              </Row>
            )}

            {getCollateralAccountBlock()}

            {getRiskSystemBlock()}

            <Row>
              <Col lg={6} xs={6} md={12}>
                <MultiSelectDropdownListGroup
                    id="properties"
                    name="properties"
                    label="Properties"
                    value={values.propertiesList}
                    onChange={(e) => setFieldValue("propertiesList", e)}
                    data={convertEnumToDropdownList(AccountProperty, [0], new StringFormatOptions(FormatString.stringFormats.titleCase, false))}
                    textField="name"
                    valueField="id"
                    readOnly={mode === FormModes.view}
                />
              </Col>
            </Row>

            {isEditing && (
              <CheckboxGroup
                checked={values.isSuspended}
                onClick={(e) => {
                  onDisableAccount(values.name, e.target.checked);
                }}
                label={"Suspend Account"}
              />
            )}

            <React.Fragment>
              <div onClick={() => this.setOpen(!this.getOpen())}>
                <Row className="accordian-style">
                  <Col lg={11} xs={11} md={11}>
                    <h6>
                      <strong>Beneficial Ownership</strong>
                    </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fa fa-fw ${this.getOpen() ? "fa-minus" : "fa-plus"
                        } custom-plus`}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Collapse in={this.getOpen()}>
                  <div className="collapse-content">
                    {!!values && Array.isArray(values.beneficialOwnership) ?
                        values.beneficialOwnership.map((beneficialOwner, idx) => (
                          <div>
                            {(mode === FormModes.creation || mode === FormModes.edition || isEditing) ? <button
                                type="button"
                                className="btn-add-alias"
                                onClick={() => {
                                  setFieldValue("beneficialOwnership", values.beneficialOwnership.filter((_, i) => i !== idx))
                                }}
                            >
                              Remove Entry {idx}
                            </button> : null}
                            <UserAttributesForm
                                setFieldValue={(field, val) => setFieldValue("beneficialOwnership", values.beneficialOwnership.map((entry, i) => {
                                  let obj = entry;
                                  if (i === idx) obj[field] = val;
                                  return obj
                                }))}
                                handleBlur={handleBlur}
                                handleChange={c => setFieldValue("beneficialOwnership", values.beneficialOwnership.map((entry, i) => {
                                  let obj = entry;
                                  if (i === idx) obj[c.target.id] = c.target.value;
                                  return obj
                                }))}
                                errors={errors}
                                touched={touched}
                                isNewService={false}
                                isEditable={mode === FormModes.creation || mode === FormModes.edition || isEditing}
                                values={beneficialOwner}
                                parent={"ACCOUNT_FORM"}
                            />
                          </div>
                        ))
                        : null}
                    {(mode === FormModes.creation || mode === FormModes.edition || isEditing) ? <button
                        type="button"
                        className="btn-add-alias"
                        onClick={() => {
                          setFieldValue("beneficialOwnership", (!!values && Array.isArray(values.beneficialOwnership) ? values.beneficialOwnership : []).concat({}))
                        }}
                    >
                      Add Entry
                    </button> : null}
                    <Row><Col></Col> </Row>
                  </div>
                </Collapse>
              </div>
            </React.Fragment>

            <Row>
              <Col><Aliases onChange={aliasOnChange} aliases={values.aliases} readOnly={mode === FormModes.view} options={Env.getAliasOptions()} tooltip={toolTips.ALIAS} /></Col>
            </Row>

            {(mode === FormModes.creation || mode === FormModes.edition || isEditing) && (
              <OrderLimit
                lowOrder={values.lowOrderLimit}
                highOrder={values.highOrderLimit}
                setLow={values.setLowOrderLimit}
                setHigh={values.setHighOrderLimit}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
                onSetLow={(checked) => { setFieldValue("setLowOrderLimit", checked); }}
                onSetHigh={(checked) => { setFieldValue("setHighOrderLimit", checked); }}
              />
            )}

            {(mode === FormModes.view) && (
              <OrderLimitReadOnly
                lowLimitValue={values.lowOrderLimit}
                highLimitValue={values.highOrderLimit}
                setLowLimit={values.setLowOrderLimit}
                setHighLimit={values.setHighOrderLimit}
              />
            )}

            <Row>
              <Col lg={1} xs={1} md={1}>
                {(isEditing || mode === FormModes.creation) &&
                  <ButtonMain
                    type="submit"
                    text={isEditing ? "UPDATE" : "SAVE"}
                  />
                }
              </Col>
              <Col lg={2} xs={2} md={2}>
                <ButtonBorderless
                  type="button"
                  text="Cancel"
                  icon="times-circle"
                  customClassName="grey-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    this.context.router.history.goBack();
                  }}
                />
              </Col>
              <Col lg={9} xs={9} md={9}>
                {Object.keys(errors).length > 0 && <div style={{ marginTop: "10px" }} ><ErrorLabel text="At least one required field is missing. Please review." /></div>}
              </Col>
            </Row>

          </Container>
        </form>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.accounts.account,
    accounts: state.accounts,
    firms: state.firms,
  };
}

const formatValues = (values, props) => {
  let parentFirm, associatedFirm;

  if (values.participantFirm) {
    parentFirm = values.clearingMember;
    associatedFirm = values.participantFirm;
  } else if (values.clearingHouse) {
    parentFirm = values.clearingHouse;
    associatedFirm = values.clearingMember;
  }

  return {
    ...values,
    associatedFirm:
      props.firm &&
        (props.firm.displayName === associatedFirm ||
          props.firm.name === associatedFirm ||
          props.firm.id === associatedFirm)
        ? props.firm.name
        : associatedFirm,

    parentFirm:
      props.firm &&
        (props.firm.displayName === parentFirm ||
          props.firm.name === parentFirm ||
          props.firm.id === parentFirm)
        ? props.firm.name
        : parentFirm,
  };
};

const AccountForm = withFormik({
  mapPropsToValues: (props) => {
    if (props.mode === FormModes.creation) return {
      aliases: [],
      beneficialOwnership: [],
      propertiesList: [],
    };

    let participantFirm, clearingMember, clearingHouse;
    const { firm, firms } = props.firms;
    const { associatedFirm, parentFirm } = props.account;

    const associatedFirmFound = firms.find(
      (firm) => firm.name === associatedFirm
    );

    const clearingFirmFound = firms.find((firm) => firm.name === parentFirm);

    if (
      firm.firmType &&
      (firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT ||
        firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_MEMBER)
    ) {
      participantFirm = associatedFirmFound
        ? associatedFirmFound.displayName
        : null;
      clearingMember = clearingFirmFound ? clearingFirmFound.displayName : null;
    } else if (
      firm.firmType &&
      firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_HOUSE
    ) {
      clearingHouse = clearingFirmFound ? clearingFirmFound.displayName : null;
      clearingMember = associatedFirmFound
        ? associatedFirmFound.displayName
        : null;
    }

    return {
      ...props.account,
      participantFirm: participantFirm,
      clearingMember: clearingMember,
      clearingHouse: clearingHouse,
      accountId: props.account.id
    };
  },
  validationSchema: Yup.object().shape({
    displayName: Yup.string().nullable().required("Account name is required."),
    clearingMember: Yup.string().required("Clearing Member Firm is required."),
    priorityWeight: Yup.number().nullable().integer(),
    accountId: Yup.string().required("Account Id is required."),    
  }),

  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    values.account = true;
    if (props.isEditing) {
      props.onUpdate(
        {
          name: values.name,
          aliases: values.aliases,
          accountType: values.accountType,
          beneficialOwnership: values.beneficialOwnership,
          displayName: values.displayName,
          riskSystem: values.riskSystem,
          bankDetails: values.bankDetails,
          orderSizeLimit: {
            setLow: values.setLowOrderLimit,
            low: Number(values.lowOrderLimit),
            setHigh: values.setHighOrderLimit,
            high: Number(values.highOrderLimit)
          },
          collateralAccount: values.collateralAccount,
          priorityWeight: values.priorityWeight,
          propertiesList: values.propertiesList,
        },
        setFieldError
      );
    } else {
      props.onSubmitForm(formatValues(values, props), setFieldError);
    }
  },
  displayName: "account-form",
  enableReinitialize: true,
})(BasicAccountForm);

export default connect(mapStateToProps, null)(AccountForm);
