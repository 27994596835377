import React from "react";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import "../core/data-grid/Pagination.css";
import LoaderIcon from "../core/loader/LoaderIcon";

const QuotesDataGrid = ({
  data,
  loading,
  onPageChange,
  paginationComponent,
}) => {
  const mainCol = [
    {
      id: "createdOn",
      Header: "TIMESTAMP",
      accessor: "createdOn",
      minWidth: 130,
    },
    {
      Header: "MESSAGE TYPE",
      accessor: "messageType",
      minWidth: 80,
    },
    {
      Header: "EXPIRE TIME",
      accessor: "expiredOn",
      minWidth: 130,
    },
    {
      Header: "PARTICIPANT",
      accessor: "user",
      minWidth: 60,
    },
    {
      Header: "FIRM",
      accessor: "firm",
      minWidth: 80,
    },
    {
      Header: "ACCOUNT",
      accessor: "account",
      minWidth: 60,
    },
    {
      Header: "AGENT",
      accessor: "submittingUser",
      minWidth: 60,
    },
    {
      Header: "FIRM",
      accessor: "submittingFirm",
      minWidth: 80,
    },
    {
      Header: "SIDE",
      accessor: "side",
      minWidth: 30,
    },
    {
      Header: "QUOTE ID",
      accessor: "name",
      minWidth: 100,
    },
    {
      Header: "CLIENT ID",
      accessor: "clientId",
      minWidth: 80,
    },
    {
      Header: "INSTRUMENTID",
      accessor: "symbol",
      minWidth: 80,
    },
    {
      Header: "CLORDID",
      accessor: "clordId",
      minWidth: 80,
    },
    {
      Header: "STATE",
      accessor: "status",
      minWidth: 50,
    },
    {
      Header: "ORD QTY",
      accessor: "quantity",
      minWidth: 80,
    },
    {
      Header: "PRICE",
      accessor: "price",
      minWidth: 80,
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="form-group col-auto align-self-end">
          <LoaderIcon show={loading}></LoaderIcon>
        </div>
      ) : (
        <DataGrid
          filterable={false}
          data={data.map((q) => {
            return {
              createdOn: q.createdOn ? q.createdOn.toString() : null,
              firm: q.firm ? q.firm : null,
              user: q.user ? q.user : null,
              submittingUser: q.submittingUser ? q.submittingUser : null,
              submittingFirm: q.submittingFirm ? q.submittingFirm : null,
              account: q.account || null,
              side: q.side ? q.side.name : null,
              symbol: q.symbol ? q.symbol : null,
              expiredOn: q.expiredOn ? q.expiredOn.toString() : null,
              status: q.status ? q.status.name : null,
              name: q.name ? q.name : null,
              qid: q.qid ? q.qid : null,
              clientId: q.clientId ? q.clientId : null,
              quantity: q.quantity ? q.quantity : null,
              clordId: q.clordId ? q.clordId : null,
              price: q.price ? q.price : null,
              responseType: q.responseType ? q.responseType.name : null,
              messageType: q.messageType,
            };
          })}
          columns={mainCol}
          onPageChange={onPageChange}
          PaginationComponent={paginationComponent}
          defaultPageSize={5}
          manual={true}
        ></DataGrid>
      )}
    </div>
  );
};

QuotesDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
};

export default QuotesDataGrid;
