import ApiCall from "../modules/apiCall";
import {apiUrl} from "../modules/util";

const {
  GetParticipantGroupRequest,
  CreateParticipantGroupRequest,
  SetParticipantGroupSuspendStateRequest,
  DeleteParticipantGroupRequest,
  AddParticipantToGroupRequest,
  RemoveParticipantFromGroupRequest,
  ListParticipantsRequest,
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js');
const { AdminAPIClient } = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js');

const client = new AdminAPIClient(apiUrl(), null, null);

export default class ParticipantGroupService {
  static get(id, cb) {

    var request = new GetParticipantGroupRequest();
    request.setParticipantGroupId(id);

    ApiCall(client, "getParticipantGroup", request, cb);
  }

  static getAll(cb) {

    var request = new GetParticipantGroupRequest();

    ApiCall(client, "listParticipantGroups", request, cb);
  }

  static post(group, cb) {

    var request = new CreateParticipantGroupRequest();
    request.setParticipantGroup(group);

    ApiCall(client, "createParticipantGroup", request, cb);
  }

  static suspend(data, cb) {
    var request = new SetParticipantGroupSuspendStateRequest();
    request.setParticipantGroupId(data.id);
    request.setIsSuspended(data.isSuspended);

    ApiCall(client, "setParticipantGroupSuspendState", request, cb);
  }

  static delete(id, cb) {

    var request = new DeleteParticipantGroupRequest();
    request.setParticipantGroupId(id);

    ApiCall(client, "deleteParticipantGroup", request, cb);
  }
  
  static listParticipantsInGroup(groupId, cb) {

    var request = new ListParticipantsRequest();
    request.setParticipantGroupId(groupId);

    ApiCall(client, "listParticipants", request, cb);
  }

  static getParticipants(id, cb) {

    var request = new ListParticipantsRequest();
    request.setParticipantGroupId(id);

    ApiCall(client, "listParticipants", request, cb);
  }

  static addParticipants(id, participantIds, cb) {

    var request = new AddParticipantToGroupRequest();
    request.setParticipantIdsList(participantIds);
    request.setGroupId(id);

    ApiCall(client, "addParticipantToGroup", request, cb);
  }

  static removeParticipant(id, participantIds, cb) {

    var request = new RemoveParticipantFromGroupRequest();
    request.setParticipantIdsList(participantIds);
    request.setGroupId(id);

    ApiCall(client, "removeParticipantFromGroup", request, cb);
  }
}
