import apiCallPromise from "../modules/apiCallPromise";
import ProtobufParser from "../modules/protobufParser";
import { apiUrl } from "../modules/util";

const {
  ListRequestForQuotesRequest, ListRfqHistoryRequest
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");
const {
  AdminAPIPromiseClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");

const client = new AdminAPIPromiseClient(
  apiUrl(),
  null,
  null
);

export default class RequestForQuoteService {
  static search(dataRequest) {
    return new Promise((resolve, reject) => {
      let { filters, pageToken, pageSize } = dataRequest;
      var request = new ListRequestForQuotesRequest();
      if (pageSize && pageSize > 0) request.setPageSize(pageSize);
      if (filters.fromDate)
        request.setStartTime(
          ProtobufParser.toTimestamp(new Date(filters.fromDate))
        );
      if (filters.toDate)
        request.setEndTime(
          ProtobufParser.toTimestamp(new Date(filters.toDate))
        );
      if (filters.participant) request.setUser(filters.participant);
      if (filters.account) request.setAccount(filters.account);
      if (filters.instrumentId) request.setSymbol(filters.instrumentId);
      if (filters.rfqid) request.setQuoteRequestId(filters.rfqid);
      if (filters.clientId) request.setClientId(filters.clientId);
      if (filters.side) request.setSide(filters.side);
      if (filters.rfqStateFilter) request.setStatusesList([filters.rfqStateFilter]);
      let firmsList = [];
      if (filters.firm) firmsList.push(filters.firm);
      if (Array.isArray(filters.selectedDealers)) filters.selectedDealers.forEach(dealer => firmsList.push(dealer.value));
      if (firmsList.length > 0) request.setFirmsList(firmsList)
      if (pageToken) request.setPageToken(pageToken);
      if (filters.side === '0') request.setOnlyTwoSided(true);

      apiCallPromise(client, "listRequestForQuotes", request)
        .catch((error) => {
          reject(error);
        })
        .then((response) => {
          resolve(response);
        });
    });
  }

  static searchRfqHistory(dataRequest) {
    return new Promise((resolve, reject) => {
      let { filters, pageToken } = dataRequest;
      var request = new ListRfqHistoryRequest();
      request.setPageSize(10);
      if (filters.rfqId) request.setRfqId(filters.rfqId);
      if (filters.rfqName) request.setRfqName(filters.rfqName);
      if (pageToken) request.setPageToken(pageToken);

      apiCallPromise(client, "listRfqHistory", request)
        .catch((error) => {
          reject(error);
        })
        .then((response) => {
          resolve(response);
        });
    });
  }
}
