import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";

export default function Metadata(props) {
  const onChange = props.onChange;
  const isReadonly = props.readOnly;

  const [isOpen, setOpen] = useState(isReadonly);
  const [metadata, setMetadata] = useState([]);
  const [validationErr, setValidationErr] = useState("");

  useEffect(() => {
    setMetadata(prepareMetadataState(props.metadata));
  }, [props.metadata])

  useEffect(() => {
    setValidationErr(validateMetadata(metadata));
  }, [metadata])


  const newMetadata = () => {
    setMetadata(metadata => [...metadata, { key: "", value: "" }]);
  }

  const prepareMetadataState = (metadata) => {
    let list = [];
    if (!!metadata && Array.isArray(metadata)) {
      metadata.forEach(arrItem => {
        list.push({ key: arrItem.key, value: arrItem.value })
      })
    }

    return list;
  }

  const updateMetadata = (id, key, value) => {
    let element = metadata.find((elm) => elm.key === id);
    if (!!element) {
      if (key === "key") {
        element.key = value;
      } else {
        element.value = value;
      }
    }

    setMetadata([...metadata]);
    notifyChangeMetadata(metadata);
  }

  const deleteMetadata = (key) => {
    let idx = metadata.findIndex((elm) => elm.key === key);
    if (idx >= 0) {
      metadata.splice(idx, 1);
    }

    setMetadata([...metadata]);
    notifyChangeMetadata(metadata);
  }

  const notifyChangeMetadata = () => {
    // Do not notify if there is any error.
    if (validateMetadata(metadata).length > 0) {
      return;
    }

    if (onChange) {
      onChange(metadata);
    }
  }

  const validateMetadata = (metadata) => {
    for (const data of metadata) {
      if (data.key === "" || data.value === "") {
        return "Key or Value cannot be left empty";
      }

      if (metadata.filter((item) => item.key === data.key).length > 1) {
        return `Duplicate Key ${data.key}`;
      }
    }

    return "";
  }

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!isOpen)} className="metadata-header" id='metadata'>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6>
              <strong>Metadata</strong>
            </h6>
          </Col>
          <Col>
            <i
              className={`fa fa-fw ${isOpen ? "fa-minus" : "fa-plus"
                } custom-plus`}
            />
          </Col>
        </Row>
      </div>

      <div className="instrument-metadata">
        <Collapse in={isOpen}>
          <div className="collapse-content">
            {!!metadata && Array.isArray(metadata) && metadata.length > 0 && <Row key="header-row" className="form-group">
              <Col lg={3} xs={3} md={3}>
                <span className="header-metadata">Key</span>
              </Col>
              <Col>
                <span className="header-metadata">Value</span>
              </Col>
            </Row>
            }

            {metadata.map((data, index) => {
              return (<Row key={index} className="form-group">
                <Col lg={3} xs={6} md={6} >
                  {!isReadonly && <input type="text" className="form-control" value={data.key} onChange={(event) => { updateMetadata(data.key, "key", event.target.value) }} />}
                  {isReadonly && <div class="form-readonly-label">{data.key}</div>}
                </Col>
                <Col lg={3} xs={6} md={6} >
                  {!isReadonly && <input type="text" className="form-control" value={data.value} onChange={(event) => { updateMetadata(data.key, "value", event.target.value) }} />}
                  {isReadonly && <div class="form-readonly-label">{data.value}</div>}
                </Col>
                {!isReadonly && <Col lg={1} xs={6} md={6}><Button onClick={(evt) => { deleteMetadata(data.key); }}>Delete</Button></Col>}
              </Row>)
            })}

            {!!validationErr && <Row>
              <Col lg={6} xs={6} md={12}>
                <div class="form-error">{validationErr}</div>
              </Col>
            </Row>}

            {!isReadonly && <Row>
              <Col lg={6} xs={6} md={12}>
                <button
                  type="button"
                  className="btn-add-metadata"
                  onClick={() => { newMetadata() }}
                >
                  Add
                </button>
              </Col>
            </Row>}
          </div>
        </Collapse >
      </div>
    </React.Fragment >
  );
}
