import { convertEnumToDropdownList, filterZeroValue } from "./util";
import { AccountType, CustomerOrderCapacity } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb";
import { FirmType, FirmState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import FormatString, { StringFormatOptions } from "./formatString"
import {Env} from "../constants/environment";

export default class FirmsStaticData {

  /**
   * 
   * @param {[ids:int]} excludeIds 
   * @returns {[{id:int, name:string}]} options list
   */
  static AccountTypes(excludeIds) {
    let accountOptions = new Set()
    let accountOptionsStr = Env.getEnv("REACT_APP_ACCOUNT_OPTIONS");

    if (accountOptionsStr && accountOptionsStr.length > 0) {
      accountOptionsStr.split(',').map(str => str.trim()).forEach(elem => {
        accountOptions.add(elem);
        accountOptions.add("ACCOUNT_TYPE_" + elem);
      });
    }

    let excluded = Array.isArray(excludeIds) && excludeIds.length > 0 ? [].concat(excludeIds) : [];
    if (accountOptions.size > 0) {
      for (const enumName in AccountType) {
        if (!accountOptions.has(enumName)) {
          excluded.push(AccountType[enumName]);
        }
      }
    }

    return convertEnumToDropdownList(AccountType, excluded, new StringFormatOptions(FormatString.stringFormats.titleCase, false));
  }

  /**
   * 
   * @param {[ids:int]} excludeIds 
   * @returns {[{id:int, name:string}]} options list
   */
  static CustomerOrderCapacity(excludeIds) {
    return filterZeroValue(convertEnumToDropdownList(CustomerOrderCapacity, excludeIds, new StringFormatOptions(FormatString.stringFormats.titleCase, false)));
  }

  static FirmTypes(excludeIds) {
    return convertEnumToDropdownList(FirmType, excludeIds, new StringFormatOptions(FormatString.stringFormats.titleCase, false));
  }

  static FirmStates(excludeIds) {
    return convertEnumToDropdownList(FirmState, excludeIds, new StringFormatOptions(FormatString.stringFormats.titleCase, false));
  }
}
