import {
    FETCH_ADMIN_REQUESTS_START,
    FETCH_ADMIN_REQUESTS_SUCCEED,
    FETCH_ADMIN_REQUESTS_FAILED,
    UPDATE_ADMIN_REQUESTS_START,
    UPDATE_ADMIN_REQUESTS_SUCCEED,
    UPDATE_ADMIN_REQUESTS_FAILED
} from '../constants/adminRequestsTypes';

const initialState = {
    fetchingAdminRequest: false,
    requests: [],
    noItemsFound: false,
    isUpdatingRequest: false,
    pageTokens: {},
    nextPageToken: "",
    pageToken: ""
}

const adminRequests = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_REQUESTS_START:
            return {
                ...state,
                fetchingAdminRequest: true,
                noItemsFound: false
            };
        case FETCH_ADMIN_REQUESTS_SUCCEED:
            const pageTokens = { ...state.pageTokens };
            pageTokens[action.payload.pageToken] = action.payload.nextPageToken;
      
            return {
                ...state,
                fetchingAdminRequest: false,
                requests: [...action.payload.requestList],
                nextPageToken: action.payload.nextPageToken,
                pageTokens: pageTokens,
                pageToken: action.payload.pageToken,
            };
        case FETCH_ADMIN_REQUESTS_FAILED:
            return {
                ...state,
                fetchingAdminRequest: false,
                requests: [],
                noItemsFound: false
            };
        case UPDATE_ADMIN_REQUESTS_START:
            return {
                ...state,
                isUpdatingRequest: true
            };
        case UPDATE_ADMIN_REQUESTS_SUCCEED:
            return {
                ...state,
                isUpdatingRequest: false
            };
        case UPDATE_ADMIN_REQUESTS_FAILED:
            return {
                ...state,
                isUpdatingRequest: false
            };

        default:
            return state;
    }
}

export default adminRequests;