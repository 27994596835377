import querystring from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  cleanSearch,
  searchExecutions,
  exportOrders,
  resetFetchingStatus
} from '../actions/orders';
import './Content.css';
import { AdminDocumentTitle } from '../constants/strings';
import OrderHistoryFilterForm from "../components/order-history/OrderHistoryFilterForm";
import SearchNoItemsResult from '../components/core/search-no-items/SearchNoItemsResult';
import { cleanInstrumentsList, fetchInstrumentIdsList } from "../actions/instruments";
import ExecutionSearchDataGrid from '../components/order-history/ExecutionSearchDataGrid';
import PaginationWithPageSize, { withPageToken } from '../components/core/data-grid/PaginationWithPageSize';
import { LoadParticipants } from "../actions/participants";
import { LoadAccounts } from "../actions/accounts";
import moment from 'moment';
import { isNullOrUndefined } from '../modules/util';
import _ from 'lodash';

const STORAGE_PAGE_SIZE_KEY = "dataGridPageSize-ExecutionSearchPage";
const DEFAULT_PAGE_SIZE = 10;

function mapStateToProps(state) {
  let history = state.executions.executions.map((h) => {
    let _h = { ...h }
    let account = state.accounts.accounts.find((a) => a.name === _h.account)
    let participant = state.participants.participants.find((p) => p.name === h.participant)

    if (account)
      _h.account = account.displayName

    if (participant) {
      _h.participant = participant.displayName;

      let firmDisplayName = "";
      if (participant.firm) {
        if (typeof (participant.firm) === "string") {
          let firm = state.firms.firms.find((f) =>
            f.name === participant.firm
          )

          if (firm) {
            firmDisplayName = firm.displayName;
          } else {
            firmDisplayName = participant.firm.substring(participant.firm.indexOf("/") + 1, participant.firm.length)
          }
        } else if (participant.firm.displayName) {
          firmDisplayName = participant.firm.displayName;
        }
      }

      _h.participant_firm = firmDisplayName;
    }

    return _h;
  });

  return {
    fetchingHistory: state.executions.fetchingHistory,
    historyItems: history,
    noItemsFound: state.executions.noItemsFound,
    tokens: state.executions.tokens,
    pageToken: state.executions.pageToken,
    participants: state.participants.participants,
    accounts: state.accounts.accounts
  };
}

const mapDispatchToProps = (dispatch) => ({
  cleanSearch: () => {
    dispatch(cleanSearch())
  },
  searchExecutions: (filters, pageToken, pageSize) => {
    dispatch(searchExecutions(filters, pageToken, pageSize))
  },
  exportOrderHistory: (filters, executions) => {
    dispatch(exportOrders(filters, executions))
  },
  fetchInstrumentIdsList: () => {
    dispatch(fetchInstrumentIdsList())
  },
  cleanInstrumentsList: () => {
    dispatch(cleanInstrumentsList())
  },
  resetFetchingStatus: () => {
    dispatch(resetFetchingStatus())
  },
  loadParticipants: () => {
    dispatch(LoadParticipants());
  },
  loadAccounts: () => {
    dispatch(LoadAccounts());
  }
})

class ExecusionsSearchPage extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor() {
    super();
    var start = moment().startOf("day").toDate()
    var end = moment().endOf("day").toDate()
    this.state = {
      fromDate: start,
      toDate: end,
      instrumentId: null,
      participantDisplayName: '',
      participant: null,
      accountDisplayNames: '',
      accounts: null,
      clientaccountid: null,
      clientparticipantid: null,
      clordid: null,
      orderid: null,
      tradeid: null,
      crossid: null,
      parentorderid: null,
      symbolsubtype:null,
      tokens: {},
      side: 0,
      orderStateFilter: 0,
      executionType: null,
      pageSize: Number(this.getPageSizeFromStorage())
    }
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.getPageSizeFromStorage = this.getPageSizeFromStorage.bind(this);
    this.search = this.search.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.props.cleanSearch();
    this.props.cleanInstrumentsList();
    const state = querystring.parseUrl(window.location.href);
    let accountsArr = Array.isArray(state.query.accounts) ? state.query.accounts : [state.query.accounts]
    this.setState({
      instrumentId: state.query.instrumentId,
      participantDisplayName: state.query.participantDisplayName,
      participant: state.query.participant,
      accountDisplayNames: !!state.query.accountDisplayNames ? state.query.accountDisplayNames : this.displayNamesFromAccounts(accountsArr),
      accounts: accountsArr,
      clientaccountid: state.query.clientaccountid,
      clientparticipantid: state.query.clientparticipantid,
      clordid: state.query.clordid,
      orderid: state.query.orderid,
      tradeid: state.query.tradeid,
      crossid: state.query.crossid,
      side: !!state.query.side ? parseInt(state.query.side) : null,
      orderStateFilter: !!state.query.orderStateFilter ? parseInt(state.query.orderStateFilter) : null,
      viewingExecutionDetails: false,
      fromDate: isNullOrUndefined(state.query.fromDate) ? (_.size(state.query) === 0 ? this.state.fromDate : null ) : new Date(state.query.fromDate),
      toDate: isNullOrUndefined(state.query.toDate) ? (_.size(state.query) === 0 ? this.state.toDate : null ) : new Date(state.query.toDate),
      parentorderid: state.query.parentorderid,
      symbolsubtype: state.query.symbolsubtype,
      executionType: !!state.query.executionType ? parseInt(state.query.executionType) : null,
    }, () => {
      if (!this.props.fetchingHistory) {
        this.props.searchExecutions(this.state, "", this.state.pageSize);
      }
    });

    if (this.props.participants.length === 0)
      this.props.loadParticipants();

    if (this.props.accounts.length === 0)
      this.props.loadAccounts();

    window.scrollTo(0, 0);
  }

  displayNamesFromAccounts = (accounts) => {
    if (!Array.isArray(accounts) || _.isEmpty(accounts)) {
      return null
    }

    let displayNames = []
    accounts.forEach((acc => {
      let foundAcc = this.props.accounts.find((a) => a.name === acc)
      if (foundAcc) {
        displayNames.push(foundAcc.displayName)
      }
    }))

    return displayNames
  }

  search = (values) => {
    this.props.cleanSearch();
    
    let searchState = {
      instrumentId: values.instrumentId,
      fromDate: values.fromDate,
      toDate: values.toDate,
      participantDisplayName: values.participantDisplayName,
      participant: this.getByPrettyName(values.participantDisplayName, this.props.participants),
      accountDisplayNames: values.accountDisplayNames,
      accounts: this.getByPrettyNameList(values.accountDisplayNames, this.props.accounts),
      clientaccountid: values.clientaccountid,
      clientparticipantid: values.clientparticipantid,
      clordid: values.clordid,
      orderid: values.orderid,
      tradeid: values.tradeid,
      crossid: values.crossid,
      side: values.side,
      orderStateFilter: values.orderStateFilter,
      parentorderid: values.parentorderid,
      symbolsubtype: values.symbolsubtype,
      executionType: values.executionType,
    };

    this.setState(searchState);
    this.props.searchExecutions(this.state, "", this.state.pageSize);
    this.context.router.history.push(`/order-search/executions?${querystring.stringify(searchState)}`);

    if (values.action.startsWith("export")) {
      this.props.exportOrderHistory(this.state, values.action.endsWith("executions"));
    }
  }

  getByPrettyNameList = (field, propsObjs) => {
    if (!field || _.isEmpty(field)) {
      return null
    }
    if (!Array.isArray(field)) {
      field = field.split(",")
    } else if (Array.isArray(field) && field.length === 1) {
      field = field[0].split(",")
    }

    let accs = []
    field.forEach((acc) => {
      accs.push(this.getByPrettyName(acc.trim(), propsObjs))
    })
    
    return accs
  }

  getByPrettyName = (field, propsObjs) => {
    if (Array.isArray(propsObjs) && !!field && !propsObjs.find(itm => itm.name === field)) {
      const fieldById = propsObjs.find(itm => itm.id === field);
      const fieldByDisplayName = propsObjs.find(itm => itm.displayName === field);
      if (!!fieldById) {
        return fieldById.name;
      } else if (!!fieldByDisplayName) {
        return fieldByDisplayName.name;
      }
    }
    return field;
  }

  updateExecutionViewState = (showExecution, executionData) => {
    this.setState({
      viewingExecutionDetails: showExecution,
      executionData: executionData
    });
  }

  setPageSizeInStorage = (pageSize) => {
    window.localStorage.setItem(STORAGE_PAGE_SIZE_KEY, pageSize)
  }

  getPageSizeFromStorage = () => {
    const pageSize = window.localStorage.getItem(STORAGE_PAGE_SIZE_KEY)

    if (pageSize)
      return pageSize;

    return DEFAULT_PAGE_SIZE;
  }

  onPageChange = (pageToken) => {
    this.props.searchExecutions(this.state, pageToken, this.state.pageSize);
  }

  onPageSizeChange = (pageSize) => {
    this.setPageSizeInStorage(pageSize);
    this.setState({ pageSize: pageSize });
    this.props.searchExecutions(this.state, "", pageSize);
  }

  getPaginationComponent = () => {
    const pageToken = this.props.pageToken;
    const tokens = this.props.tokens;
    const pageSize = Number(this.getPageSizeFromStorage());
    return withPageToken(PaginationWithPageSize, { pageToken, tokens }, pageSize);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  render() {
    return (
      <div className="with-callback">
        <OrderHistoryFilterForm
          filters={this.state}
          parent="ExecutionsSearchPage"
          onSubmitForm={this.search}
          loading={this.props.fetchingHistory}
          autoCompleteDataSource={this.props.instruments}
          onRefreshAutoComplete={() => { this.props.fetchInstrumentIdsList() }}
        />
        {this.props.noItemsFound ?
          <SearchNoItemsResult message="Sorry, but we didn't find any Executions." />
          :
          <ExecutionSearchDataGrid
            data={this.props.historyItems}
            showExecutionDetails={this.state.viewingExecutionDetails}
            executionData={this.state.executionData}
            updateExecutionViewState={this.updateExecutionViewState}
            paginationComponent={this.getPaginationComponent()}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
          />
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExecusionsSearchPage);
