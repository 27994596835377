export const PendingInstrumentDeleteMsg = "You can only delete an instrument in 'Pending' or 'Terminated' State";
export const AdminDocumentTitle = "Exchange - Admin";
export const INTEREST_RATE_SWAP = "Interest Rate Swap";
export const DEFAULT = "Default";
export const FUTURE = "Future";
export const OPTION = "Option";
export const FORWARD_RATE_AGREEMENT_SWAP = "Forward Rate Agreement Swap"
export const MULTILEG = "Multi Leg";
export const FORWARD_STARTING_INTEREST_RATE_SWAP = "Forward Starting Interest Rate Swap";
export const BASIS_SWAP = "Basis Swap";
export const EVENT = "Event";
export const PENDING_INSTRUMENT_BULK_DELETE_MSG = "You can only delete the selected instruments in 'Pending' or 'Terminated' State";
export const OVER_NIGHT_INDEX_SWAP = "Over Night Index Swap";
export const SINGLE_PERIOD_SWAP = "Single Period Swap";
export const NON_DELIVERABLE_FORWARDS = "Non Deliverable Forwards";
export const FOREX = "Foreign Exchange (Forex)";
export const ZERO_COUPON_INFLATION_SWAP = "Zero Coupon Inflation Swap"
