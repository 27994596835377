import { getEnumName, capitalizeWord } from "../modules/util";
import State from "./dto/State";
import QuoteMessageType from "./dto/QuoteMessageType";
import { Side } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import { QuoteStatus } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/quotes/v1beta1/quotes_pb";
import { tsProtoToDate } from "../actions/ptypes";

export default class Quote {
  constructor(item) {
    if (item) {
      const object = item.toObject();
      this.name = object.name;
      this.account = object.account;
      this.clientId = object.clientId;
      this.clordId = object.clordId;
      this.expiredOn = tsProtoToDate(item.getExpirationTime());
      this.id = object.id;
      this.createdOn = tsProtoToDate(item.getInsertTime());
      this.quantity = object.orderQty;
      this.price = object.price;
      this.quoteRequestId = object.quoteRequestId;

      let side = new State();
      side.id = object.side;
      side.name = capitalizeWord(getEnumName(Side, side.id));
      this.side = side;

      let status = new State();
      status.id = object.status;
      status.name = capitalizeWord(getEnumName(QuoteStatus, status.id));
      this.status = status;

      this.symbol = object.symbol;
      this.user = object.user;
      this.firm = object.firm;
      this.submittingUser = object.submittingUser;
      this.messageType = QuoteMessageType.Quote;

      return;
    }
    this.name = null;
    this.account = null;
    this.clientId = null;
    this.clOrdId = null;
    this.expiredOn = null;
    this.id = null;
    this.createdOn = null;
    this.quantity = null;
    this.price = null;
    this.quoteRequestId = null;
    this.side = null;
    this.status = null;
    this.symbol = null;
    this.user = null;
    this.firm = null;
    this.submittingUser = null;
    this.messageType = null;
  }
}
