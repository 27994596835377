import {
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCEED,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCT_START,
  FETCH_PRODUCT_SUCCEED,
  FETCH_PRODUCT_FAILED,
  CLEAN_PRODUCT,
  EDIT_PRODUCT_CHANGED,
  CREATE_PRODUCT_STATE,
  SET_PRODUCT_PROCESS_NAME,
  SYNC_TRADING_INFO,
  SYNC_ADDITIONAL_INFO,
  SYNC_CROSS_ORDER_INFO,
  SYNC_SETTLEMENT_PRICE_CALC_INFO,
  SYNC_TRADE_DAY_ROLL_SCHEDULE,
} from '../constants/productTypes';
import {
  RESET,
} from '../constants/appTypes';
import ProductFormItem from '../entities/dto/ProductFormItem';

const initialState = {
  isCreatingProduct: false,
  fetchingProduct: false,
  product: new ProductFormItem(),
  fetchingProducts: false,
  processNameForSaving: '',
  products: [],
  syncAdditionalInfo: false,
  syncTradingInfo: false
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_START:
      return {
        ...state,
        fetchingProducts: true
      };
    case FETCH_PRODUCTS_SUCCEED:
      return {
        ...state,
        fetchingProducts: false,
        products: action.payload
      };
    case FETCH_PRODUCTS_FAILED:
      return {
        ...state,
        fetchingProducts: false,
        products: []
      };
    case FETCH_PRODUCT_START:
      return {
        ...state,
        fetchingProduct: true
      };
    case FETCH_PRODUCT_SUCCEED:
      return {
        ...state,
        fetchingProduct: false,
        product: action.payload
      };
    case FETCH_PRODUCT_FAILED:
      return {
        ...state,
        fetchingProduct: false
      };
    case CLEAN_PRODUCT:
      return {
        ...state,
        product: new ProductFormItem()
      };
    case EDIT_PRODUCT_CHANGED:
      var editedProduct = new ProductFormItem({
        ...state.product,
        [action.key]: action.value
      });
      return {
        ...state,
        product: editedProduct
      };
    case CREATE_PRODUCT_STATE:
      return {
        ...state,
        isCreatingProduct: action.value
      };
    case SET_PRODUCT_PROCESS_NAME:
      return {
        ...state,
        processNameForSaving: action.value
      };
    case SYNC_ADDITIONAL_INFO:
      return {
        ...state,
        syncAdditionalInfo: action.value
      };
    case SYNC_TRADING_INFO:
      return {
        ...state,
        syncTradingInfo: action.value
      };
    case SYNC_TRADE_DAY_ROLL_SCHEDULE:
      return {
        ...state,
        syncTradeDayRollSchedule: action.value
      };
    case SYNC_CROSS_ORDER_INFO:
      return {
        ...state,
        syncCrossOrderInfo: action.value
      };
    case SYNC_SETTLEMENT_PRICE_CALC_INFO:
      return {
        ...state,
        syncSettlementPriceCalcInfo: action.value
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    default:
      return state;
  }
}

export default products
