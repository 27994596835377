import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-big-calendar/lib/sass/styles.scss';
import InstrumentHeader from '../components/instrument/InstrumentHeader';
import Loader from '../components/core/loader/Loader';
import { AdminDocumentTitle } from '../constants/strings';
import {listHolidayCalendars, updateHolidayCalendar} from "../actions/instruments";
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import confirm from "../modules/confirmDialog";
import {FormControl} from "react-bootstrap";
import {hasWriteAccess} from "../services/TokenService";
import HolidayCreator from "../components/instrument/components/HolidayCreator";
import HolidayManager from "../components/instrument/components/HolidayManager";

const localizer = momentLocalizer(moment);

function mapStateToProps(state) {
    return {
        fetchingHolidayCalendars: state.instruments.fetchingHolidayCalendars,
        holidayCalendars: state.instruments.holidayCalendars,
    };
}

const mapDispatchToProps = (dispatch) => ({
    listHolidayCalendars: () => {
        dispatch(listHolidayCalendars());
    },
    updateHolidayCalendar: (baseEvent, newCalendar) => {
        dispatch(updateHolidayCalendar(baseEvent, newCalendar));
    },
})

class Calendars extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
    }

    getDefaultState() {
        return {
            showAddEvent: false,
            showManageCalendars: false,
        }
    }

    componentDidMount() {
        document.title = AdminDocumentTitle;
        this.props.listHolidayCalendars();
    }

    getCalendarName(event) {
        let calendarName = event.calendar;
        if (event.global) {
            calendarName += " (Global)"
        }
        return calendarName;
    }

    getTitle(event) {
        return this.getCalendarName(event) + " - " + event.title
    }

    showPopup(event) {
        confirm(<FormControl readOnly={true} type="text" defaultValue={event.title}/>, {
            title: this.getCalendarName(event),
            okButtonText: hasWriteAccess() ? "Delete" : null,
            cancelButtonText: "Dismiss"
        }).then(
            () => {
                confirm("Are you sure you want to delete "+event.title+"?", {
                    title: this.getCalendarName(event),
                    okButtonText: "Delete",
                    cancelButtonText: "No",
                }).then(
                    () => {
                        let newHolidayCalendar = [];
                        this.props.holidayCalendars.forEach(prevEvent => {
                           if (event.calendar === prevEvent.calendar && event !== prevEvent) {
                               newHolidayCalendar.push(prevEvent);
                           }
                        });
                        this.props.updateHolidayCalendar(event, newHolidayCalendar);
                    },
                    () => {},
                )
            },
            () => {},
        )
    }

    render() {
        return (
            <div className="with-callback">
                <InstrumentHeader
                    isCalendarsSelected={true}
                    isAddButtonVisible={hasWriteAccess()}
                    isSecondaryButtonVisible={hasWriteAccess()}
                    buttonText="Add New Event"
                    buttonClick={() => {
                        let newState = this.getDefaultState();
                        newState.showAddEvent = true;
                        this.setState(newState);
                    }}
                    secondaryButtonText="Manage Calendars"
                    secondaryButtonClick={() => {
                        let newState = this.getDefaultState();
                        newState.showManageCalendars = true;
                        this.setState(newState);
                    }}
                />
                <Loader show={this.props.fetchingHolidayCalendars}/>
                <HolidayCreator
                    show={this.state.showAddEvent}
                    setShow={(show) => this.setState({showAddEvent: show})}
                />
                <HolidayManager
                    show={this.state.showManageCalendars}
                    setShow={(show) => this.setState({showManageCalendars: show})}
                />
                <Calendar
                    localizer={localizer}
                    events={this.props.holidayCalendars}
                    views={[Views.WEEK, Views.AGENDA]}
                    length={365}
                    defaultView={Views.AGENDA}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    titleAccessor={event => (
                        //eslint-disable-next-line
                        <a href="javascript:void(0);" onClick={() => this.showPopup(event)}>
                            {this.getTitle(event)}
                        </a>
                    )}
                    onSelectEvent={event => this.showPopup(event)}
                    showMultiDayTimes
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendars);
