import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import GridActions from '../core/data-grid/GridActions';
import GridAction from '../core/data-grid/GridAction';
import * as Icons from '../core/icons'
import { hasWriteAccess } from '../../services/TokenService';
import { GridActionMenu } from '../core/menu-items/menu-items';

const ProductDataGrid = ({
  data,
  onView,
  onEdit,
  onCopy,
}) => {

  const gridCtxMenu = useMemo(() => GridActionMenu, []);

  const columns = useMemo(()=>[
    {
      Header: 'NAME ID',
      accessor: 'name',
      maxWidth: 200
    },
    {
      Header: 'PRODUCT DESCRIPTION',
      accessor: 'description'
    },
    {
      Header: "CLEARING HOUSE",
      id: "clearingHouse",
      accessor: d => {
        if(!d.clearingHouse) {
          return ""
        }
        return d.clearingHouse.displayName ? d.clearingHouse.displayName : d.clearingHouse
      },
    },
    {
      Header: '',
      maxWidth: 150,
      minWidth: 150,
      sortable: false,
      Cell: row => (
        <GridActions>
          <GridAction title="View" icon={Icons.eye}
            onClick={(e, actionContext) => { onView(row.original.name, actionContext) }}
            contextMenu={gridCtxMenu} />

          {hasWriteAccess() && <GridAction title="Edit" icon={Icons.edit}
            onClick={(e, actionContext) => { onEdit(row.original.name, actionContext) }}
            contextMenu={gridCtxMenu} />}

          <GridAction title="Copy" icon={Icons.copy}
            onClick={(e, actionContext) => { onCopy(row.original.name, actionContext) }}
            contextMenu={gridCtxMenu} />

        </GridActions>
      )
    }
  ], []);
    return (
        <div>
            <DataGrid
                data={data}
                columns={columns}
                filterable={false}
                defaultSortedField="name"
            >
            </DataGrid>
        </div>
    )
};

ProductDataGrid.propTypes = {
    data: PropTypes.array.isRequired,
    onView: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired
};

export default ProductDataGrid;