import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import confirm from "../modules/confirmDialog";
import {
  SaveAccountStart,
  SaveAccountSucceed,
  SaveAccountFailed,
} from "../actions/accounts";
import Notification from "../modules/notifications";
import { StatusCode } from "grpc-web";
import AccountMapper from "../modules/mappers/accountMapper";
import AccountDetails from "../components/account/AccountDetails";
import AccountService from "../services/AccountService";
import { getFormMode } from "../modules/util";

const SuspendAccountMessage = ({ suspend }) => {
  if (suspend) {
    return (
      <div>
        <span className="warning-message">WARNING.</span>
        This change will remove any working orders for the Account from all
        Markets. <br />
        <br />
        Are you sure you want to disable the Account.
      </div>
    );
  }
  return <div>Are you sure you want to re-enable this Account?</div>;
};

function mapStateToProps(state) {
  return {
    fetchingAccount: state.accounts.fetchingAccount,
    participants: state.accounts.participantsList,
    firms: state.firms.firms,
    positions: state.accounts.positions,
    pendingSettlements: state.accounts.pendingSettlements,
    balances: state.accounts.balances,
    withdrawals: state.accounts.withdrawals,
    account: state.accounts.account,
    accounts: state.accounts.accounts
  };
}

const mapDispatchToProps = (dispatch) => ({
  saveFormStart: () => {
    dispatch(SaveAccountStart());
  },
  saveFormSucceed: () => {
    dispatch(SaveAccountSucceed());
  },
  saveFormFailed: () => {
    dispatch(SaveAccountFailed());
  },
});

class AccountFormContainer extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  saveAccount = (data, setFieldError) => {
    this.props.saveFormStart();
    const cb = (err, response) => {
      this.processSavedAccount(response, err, setFieldError);
    };

    const account = AccountMapper.mapToAccount(data);
    AccountService.post(account, cb);
  };

  updateAccount = (data, prevPage = true, successMessage = "Account updated") => {
    const account = AccountMapper.mapToAccountUpdate(data);
    const cb = (err, response) => {
      this.processUpdatedAccount(response, err, prevPage, successMessage);
    };
    AccountService.update(account, cb);
  };

  updateAccountWhitelist = (data) => {
    data = {
      enableWhitelist: data.enableWhitelist,
      name: data.account,
      whiteListInstruments: data.instruments,
      whiteListProducts: data.products
    }
    const account = AccountMapper.mapToAccountUpdateWhiteList(data);
    const cb = (err, response) => {
      this.processUpdatedAccount(response, err);
    };
    AccountService.update(account, cb);
  };

  setSuspendedAccount = (accountId, isSuspended) => {
    confirm(<SuspendAccountMessage suspend={isSuspended} />, {
      title: "Account Suspension",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            Notification.success("Account updated.", 100);
            this.context.router.history.goBack();
          }

          if (err) {
            Notification.error(
              `Error when suspending account: \n ${err.message}`
            );
          }
        };

        AccountService.suspend({ id: accountId, isSuspended: isSuspended }, cb);
      },
      () => { }
    );
  };

  remove = (accountId) => {
    confirm("Are you sure you want to delete the account?", {
      title: "Account Deletion",
      okButtonText: "Delete",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            Notification.success("Account deleted.", 100);
            this.context.router.history.goBack();
          }

          if (err) {
            Notification.error(`Error when deleting account: \n ${err.message}`);
          }
        };

        AccountService.delete(accountId, cb);
      },
      () => { }
    );
  };

  processSavedAccount = (response, err, setFieldError) => {
    if (response && response.getAccount()) {
      this.props.saveFormSucceed();
      Notification.success("Account saved", 100);
      this.context.router.history.goBack();
    }

    if (err) {
      this.props.saveFormFailed();
      this.processFormError(err, setFieldError);
    }
  };

  processUpdatedAccount = (response, err, prevPage = true, successMessage = "Account updated") => {
    if (response && response.getAccount()) {
      Notification.success(successMessage, 100);

      if (!!prevPage) {
        this.context.router.history.goBack();
      } else {
        this.context.router.history.push();
      }
    }

    if (err) {
      Notification.error(`Failed to update account. ${err.message}`);
    }
  };

  processFormError = (error, setFieldError) => {
    switch (error.code) {
      case StatusCode.ALREADY_EXISTS:
        setFieldError("id", error.message, false);
        Notification.error(
          `Failed to save this account, ID has already been used.`
        );
        break;
      default:
        Notification.error(`Failed to save this account. ${error.message}`);
        break;
    }
  };

  openViewParticipant = (name) => {
    if (name.startsWith("firms/")) {
      this.context.router.history.push(`/${name}`);
    } else {
      //support legacy users
      this.context.router.history.push(`/users/${name}`);
    }
  };

  openEditParticipant = (name) => {
    if (name.startsWith("firms/")) {
      this.context.router.history.push(`/${name}/edit`);
    } else {
      //support legacy users
      this.context.router.history.push(`/users/${name}/edit`);
    }
  };

  render() {
    return (
      <AccountDetails
        onSubmitForm={this.saveAccount}
        onUpdate={this.updateAccount}
        onUpdateAccountWhitelist={this.updateAccountWhitelist}
        onDisableAccount={(id, isSuspended) => {
          this.setSuspendedAccount(id, isSuspended);
        }}
        onDelete={(id) => {
          this.remove(id);
        }}
        firm={this.props.firm}
        mode={getFormMode(this.props.location.pathname, () => { })}
        participants={this.props.participants}
        firms={this.props.firms}
        openViewParticipant={this.openViewParticipant}
        openEditParticipant={this.openEditParticipant}
        positions={this.props.positions}
        pendingSettlements={this.props.pendingSettlements}
        reloadPositions={this.props.reloadPositions}
        balances={this.props.balances}
        reloadBalances={this.props.reloadBalances}
        reloadCredits={this.props.reloadCredits}
        withdrawals={this.props.withdrawals}
        account={this.props.account}
        accounts={this.props.accounts}
        history={this.context.router.history}
        onPositionExport={this.props.onPositionExport}
      />
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountFormContainer)
);
