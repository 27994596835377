import ApiCall from "../modules/apiCall";
import { dateToProtoDate } from "../actions/ptypes";
import {
  ADJUST_DATES_START,
  ADJUST_DATES_SUCCESS,
  ADJUST_DATES_FAILED,
} from "../constants/dateTypes";
import { apiUrl } from "../modules/util";

const {
  AdjustDatesRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/date/v1beta1/date_adjustments_api_pb.js");

const {
  DateAdjustmentsAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/date/v1beta1/date_adjustments_api_grpc_web_pb");

const dateClient = new DateAdjustmentsAPIClient(apiUrl(), null, null);

const { InterestRateSwapAttributes, ForwardRateAgreementSwapAttributes, BasisSwapAttributes,OvernightIndexSwapAttributes, ZeroCouponInflationSwapAttributes } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/swaps_pb.js")

export default class DateAdjustmentService {
  static adjustDates(handleError, successHandler, swapAttributes, startDate) {
    return (dispatch) => {
      dispatch({ type: ADJUST_DATES_START });

      var request = new AdjustDatesRequest();
      request.setStartDate(dateToProtoDate(startDate));

      if (swapAttributes.constructor === InterestRateSwapAttributes) {
        request.setInterestRateSwapAttributes(swapAttributes);
      } else if (
        swapAttributes.constructor === ForwardRateAgreementSwapAttributes
      ) {
        request.setForwardRateAgreementSwapAttributes(swapAttributes);
      } else if (swapAttributes.constructor === BasisSwapAttributes) {
        request.setBasisSwapAttributes(swapAttributes);
      } else if (swapAttributes.constructor === ZeroCouponInflationSwapAttributes) {
        request.setZeroCouponInflationSwapAttributes(swapAttributes);
      } else if (swapAttributes.constructor === OvernightIndexSwapAttributes) {
        request.setOvernightIndexSwapAttributes(swapAttributes);
      } else {
        throw new Error(
          `Unsupported SwapAttribute ${swapAttributes.constructor.toString()}`
        );
      }

      const cb = (err, response) => {
        dispatch({ type: ADJUST_DATES_SUCCESS });

        if (response) {
          if (response.hasInterestRateSwapAttributes()) {
            successHandler(response.getInterestRateSwapAttributes().toObject());
          } else if (response.hasForwardRateAgreementSwapAttributes()) {
            successHandler(
              response.getForwardRateAgreementSwapAttributes().toObject()
            );
          } else if (response.hasBasisSwapAttributes()) {
            successHandler(response.getBasisSwapAttributes().toObject());
          } else if (response.hasZeroCouponInflationSwapAttributes()) {
            successHandler(response.getZeroCouponInflationSwapAttributes().toObject());
          } else if (response.hasOvernightIndexSwapAttributes()) {
            successHandler(
              response.getOvernightIndexSwapAttributes().toObject()
            );
          }
        }

        if (err) {
          dispatch({ type: ADJUST_DATES_FAILED });
          handleError(err.message);
        }
      };

      ApiCall(dateClient, "adjustDates", request, cb);
    };
  }
}
