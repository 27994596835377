import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import ButtonBorderless from "../core/form/ButtonBorderless";
import UserRoleListContainer from "../../containers/UserRoleListContainer";
import ButtonMain from "../core/form/ButtonMain";
import { withFormik } from "formik";
import * as Yup from "yup";
import { convertEnumToDropdownList } from "../../modules/util";
import {
  FirmType,
  ParticipantRole,
} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { hasWriteAccess, onGenerate } from "../../services/TokenService";
import ButtonSecondary from "../core/form/ButtonSecondary";
import confirm from "../../modules/confirmDialog";
import * as firmService from "../../services/FirmService";

function mapStateToProps(state) {
  return {
    firm: state.firms.firm,
    firmServiceKeys: state.firms.firmServiceKeys,
  };
}

export class BasicFirmServicesKeysEditForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    let { firmServiceId, loadFirmServiceKeys } = this.props;
    if (firmServiceId) loadFirmServiceKeys(firmServiceId);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps !== null &&
      prevProps.firmServiceId !== this.props.firmServiceId
    ) {
      this.props.loadFirmServiceKeys(this.props.firmServiceId);
    }
  }

  onGenerate = (serviceKey) => {
    confirm("Are you sure you wish to generate a new token?", {
      title: "Generate New Token",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const ttlSeconds = 60 * 60 * 24 * 365 * 5; //5 years
        const cb = (err, response) => {
          if (response) {
            this.componentDidMount();
          }
        };
        onGenerate(
          serviceKey.userId,
          serviceKey.name,
          this.props.firm.firmType.id === FirmType.FIRM_TYPE_SUPERVISOR,
          ttlSeconds,
          false,
          serviceKey.name,
          cb
        );
      },
      () => {}
    );
  };

  onRevoke = (serviceKey) => {
    confirm("Are you sure you wish to revoke this token?", {
      title: "Revoke " + serviceKey.jti,
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            this.componentDidMount();
          }
        };
        firmService.revokeServiceKey(serviceKey.userId, serviceKey.name, cb);
      },
      () => {}
    );
  };

  render() {
    const {
      apiKeyId,
      firm,
      firmServiceKeys,
      handleSubmit,
      setFieldValue,
      onEditServicesKeyForm,
      values,
      errors,
      touched,
      handleBlur,
    } = this.props;

    if (!firm || !firmServiceKeys) return null;
    const serviceKey = firmServiceKeys
      .filter((key) => key.name === apiKeyId)
      .shift();
    if (!serviceKey) return null;

    return (
      <div>
        <form onSubmit={handleSubmit} name="firmServicesKeysEditForm">
          <Container>
            <Row>
              <Col lg={4} xs={4} md={4}>
                <FieldGroupReadOnly
                  id="apiKeyId"
                  label="API Key Name"
                  value={serviceKey.name}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6} md={12}>
                <FieldGroupReadOnly
                  id="description"
                  name="description"
                  label="API Key Description"
                  value={values.description}
                />
              </Col>
            </Row> 
            <Row>
              <Col lg={4} xs={4} md={4}>
                <FieldGroupReadOnly
                  id="jti"
                  label="Token ID"
                  value={
                    serviceKey.jti ? serviceKey.jti : "<no generated token>"
                  }
                />
                <ButtonSecondary
                  type="button"
                  onClick={() => this.onGenerate(serviceKey)}
                  enabled={hasWriteAccess()}
                  text="Generate"
                />
                <ButtonSecondary
                  type="button"
                  onClick={() => this.onRevoke(serviceKey)}
                  enabled={hasWriteAccess() && !!serviceKey.jti}
                  text="Revoke"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} xs={4} md={4}>
                {onEditServicesKeyForm ? (
                  <UserRoleListContainer
                    id="role"
                    name="role"
                    label="Role"
                    isRequired={false}
                    errors={errors}
                    touched={touched}
                    value={values.role}
                    setFieldValue={setFieldValue}
                    onChange={(e) => {
                      let _role = roleEnum.find((role) => role.name === e);
                      setFieldValue("role", _role);
                    }}
                    onBlur={handleBlur}
                    firm={firm}
                    onEdit={true}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="role"
                    label="Role"
                    value={serviceKey.role ? serviceKey.role.name : null}
                  />
                )}
              </Col>
            </Row>
            <div>
              {onEditServicesKeyForm ? (
                <ButtonMain type="submit" text="SAVE" />
              ) : null}
              <ButtonBorderless
                type="button"
                text={onEditServicesKeyForm ? "Cancel" : "Back"}
                icon="times-circle"
                customClassName={
                  onEditServicesKeyForm ? "grey-icon" : "grey-icon-withNoMargin"
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.context.router.history.goBack();
                }}
              />
            </div>
          </Container>
        </form>
      </div>
    );
  }
}

const roleEnum = convertEnumToDropdownList(ParticipantRole);
const formatValues = (values) => {
  return {
    ...values,
    role: roleEnum.filter((role) => role.name === values.role.name).shift(),
  };
};

const FirmServicesKeysEditForm = withFormik({
  mapPropsToValues: (props) => {
    const serviceKey = props.firmServiceKeys
      .filter((key) => key.name === props.apiKeyId)
      .shift();
    return { ...serviceKey };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().nullable().required("Firm name is required."),
  }),
  validate: (values) => {
    let errors = {};
    if (!values.role) {
      errors.role = "Role must be defined.";
    }
    return errors;
  },

  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    let formattedValues = formatValues(values);
    props.onEditServicesKeyForm(formattedValues, setFieldError);
  },
  displayName: "firm-edit-form",
  enableReinitialize: true,
})(BasicFirmServicesKeysEditForm);

export default connect(mapStateToProps, null)(FirmServicesKeysEditForm);
