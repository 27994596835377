import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import DataGridActions from '../core/data-grid/DataGridActions';
import {convertDateToString} from "../../modules/util";
import {tsProtoObjToDate} from "../../actions/ptypes";

const JobsDataGrid = ({
  data,
  onView,
}) => {
  const columns = [
    {
      Header: 'NAME ID',
      accessor: 'name',
      minWidth: 300,
      sortable: true,
      filterable: true,
    },
    {
      Header: 'START TIME',
      id: 'startTime',
      accessor: d => !!d.startTime && !!d.startTime.seconds ? convertDateToString(tsProtoObjToDate(d.startTime), "YYYYMMDD-HH:mm:ss.SSS") : null,
      sortable: true,
      filterable: true,
    },
    {
      Header: 'END TIME',
      id: 'endTime',
      accessor: d => !!d.endTime && !!d.endTime.seconds ? convertDateToString(tsProtoObjToDate(d.endTime), "YYYYMMDD-HH:mm:ss.SSS") : null,
      sortable: true,
      filterable: true,
    },
    {
      Header: 'READY',
      id: 'ready',
      accessor: d => d.ready ? <i className="fa fa-fw fa-spinner orange-icon" /> : null,
      sortable: false,
      filterable: false,
    },
    {
      Header: 'ACTIVE',
      id: 'active',
      accessor: d => d.active ? <i className="fa fa-fw fa-play-circle orange-icon" /> : null,
      sortable: false,
      filterable: false,
    },
    {
      Header: 'SUCCEEDED',
      id: 'succeeded',
      accessor: d => d.succeeded ? <i className="fa fa-fw fa-check-circle orange-icon" /> : null,
      sortable: false,
      filterable: false,
    },
    {
      Header: 'FAILED',
      id: 'failed',
      accessor: d => d.failed ? <i className="fa fa-fw fa-times-circle orange-icon" /> : null,
      sortable: false,
      filterable: false,
    },
    {
      Header: 'LOGS',
      maxWidth: 150,
      minWidth: 150,
      sortable: false,
      filterable: false,
      Cell: row => (
        <DataGridActions
          onView={() => { onView(row.original.name) }}
        >
        </DataGridActions>
      )
    }
  ];
    return (
        <div>
            <DataGrid
                data={data}
                columns={columns}
                filterable={true}
                defaultSortedField="name"
            >
            </DataGrid>
        </div>
    )
};

JobsDataGrid.propTypes = {
    data: PropTypes.array.isRequired,
    onView: PropTypes.func.isRequired,
};

export default JobsDataGrid;
