import React from 'react';
import Moment from 'react-moment';

export default class DateTimeParser {
  static toDisplay(date, withTime = false) {
    const dateToFormat = new Date(date);
    return <Moment date={dateToFormat} format={withTime ? "MMMM Do YYYY h:mm A" : "MMMM Do YYYY"}/>
  }

  static addDays(date, days) {
    if (date) {
      var newDate = new Date(date);
      newDate.setDate(newDate.getDate() + days);
      return newDate;
    }
  }
}