import React from 'react';
import PropTypes from 'prop-types';
import './CheckboxGroup.css';
import Tooltip from '../tooltip-html/tooltip-html';
import { isNullOrUndefined } from "../../../modules/util";
import { FormLabel } from "react-bootstrap";

const CheckboxGroup = ({
  id,
  checked,
  label,
  onClick,
  disabled,
  onChange,
  tooltip,
  tooltipPlace,
  labelPlacementAbove,
  tickStyle
}) => (
  <>
    {labelPlacementAbove && <FormLabel>{label}</FormLabel>}

    {labelPlacementAbove && !isNullOrUndefined(tooltip) &&
      <Tooltip
        text={tooltip}
        id={`tip-${id}`}
        place={!!tooltipPlace ? tooltipPlace : 'top'}
      ></Tooltip>
    }

    <label className="checkbox-wrapper">
      <input type="checkbox"
        id={id}
        checked={checked}
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
      />
      <span style={tickStyle} className="checkmark"></span>
      {!labelPlacementAbove && label}

      {!labelPlacementAbove && !isNullOrUndefined(tooltip) &&
        <Tooltip
          text={tooltip}
          id={`tip-${id}`}
          place={!!tooltipPlace ? tooltipPlace : 'top'}
        ></Tooltip>
      }

    </label>
  </>
)

CheckboxGroup.defaultProps = {
  checked: false,
  label: '',
  disabled: false
}

CheckboxGroup.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default CheckboxGroup;
