import {
  FETCH_PARTICIPANTS_START,
  FETCH_PARTICIPANTS_SUCCEED,
  FETCH_PARTICIPANTS_FAILED,
  FETCH_PARTICIPANT_START,
  FETCH_PARTICIPANT_SUCCEED,
  FETCH_PARTICIPANT_FAILED,
  CLEAN_PARTICIPANT,
  SAVE_PARTICIPANT_START,
  SAVE_PARTICIPANT_SUCCEED,
  SAVE_PARTICIPANT_FAILED,
  CREATE_PARTICIPANT_STATE,
  UPDATE_PARTICIPANT_ITEM,
  TOGGLE_SHOW_DELETED_PARTICIPANTS,
} from "../constants/participantTypes";

import ParticipantService from "../services/ParticipantService";
import Participant from "../entities/Participant";
import UserAttributes from "../entities/UserAttributes";
import Notification from "../modules/notifications";
import {inTest} from "../modules/util";

export const FetchParticipantsStart = () => ({
  type: FETCH_PARTICIPANTS_START,
});

export const FetchParticipantsSucceed = (participants) => ({
  type: FETCH_PARTICIPANTS_SUCCEED,
  payload: participants,
});

export const FetchParticipantsFailed = (error) => ({
  type: FETCH_PARTICIPANTS_FAILED,
  payload: error,
});

export const FetchParticipantStart = () => ({
  type: FETCH_PARTICIPANT_START,
});

export const FetchParticipantSucceed = (participant) => ({
  type: FETCH_PARTICIPANT_SUCCEED,
  payload: participant,
});

export const FetchParticipantFailed = (error) => ({
  type: FETCH_PARTICIPANT_FAILED,
  payload: error,
});

export const CleanParticipant = () => ({
  type: CLEAN_PARTICIPANT,
});

export const SaveParticipantStart = () => ({
  type: SAVE_PARTICIPANT_START,
});

export const SaveParticipantSucceed = () => ({
  type: SAVE_PARTICIPANT_SUCCEED,
});

export const SaveParticipantFailed = (error) => ({
  type: SAVE_PARTICIPANT_FAILED,
  payload: error,
});

export const IsCreatingParticipant = (isCreating) => ({
  type: CREATE_PARTICIPANT_STATE,
  value: isCreating,
});

export const UpdateParticipantItem = (key, value) => ({
  type: UPDATE_PARTICIPANT_ITEM,
  key: key,
  value: value,
});

export const LoadParticipants = (showDeleted = false) => {
  return (dispatch) => {
    dispatch({ type: FETCH_PARTICIPANTS_START });

    const cb = (err, response) => {
      if (response) {
        var participantsList = response
          .getParticipantsList()
          .map((participant) => {
            return new Participant(participant);
          });

        dispatch({
          payload: participantsList,
          type: FETCH_PARTICIPANTS_SUCCEED,
        });
      }

      if (err) {
        dispatch({ type: FETCH_PARTICIPANTS_FAILED });
        Notification.error("Cannot get list of participants.");
      }
    };

    ParticipantService.getAll(cb, showDeleted);
  };
};

export const LoadParticipantAttributes = (resourceName) => {
  return (dispatch) => {
    ParticipantService.getAttributes(resourceName)
      .catch((err) => {
        if (!inTest()) console.error("couldn't get participant attributes", err);
      })
      .then((response) => {
        if (!response) return;
        dispatch({
          type: UPDATE_PARTICIPANT_ITEM,
          key: "attributes",
          value: new UserAttributes(response.getAttributes()),
        });
      });
  };
};

export const UnDeleteParticipant = async (resourceName) => {
  return await ParticipantService.unDeleteParticipant(resourceName);
};

export const ToggleShowDeletedParticipants = () => {
  return {
    type: TOGGLE_SHOW_DELETED_PARTICIPANTS,
  };
};
