import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import { validateField, NUMBER_TYPE, STRING_TYPE } from '../../instrumentFormUtils';
import { OPTION } from "../../../../constants/strings";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import FieldGroup from "../../../core/form/FieldGroup";
import { countDecimals } from "../../../../modules/util";
import { FormModes } from "../../../../constants/enumerations"
import DateGroupYMD from "../../../core/form/DateGroupYMD";
import { range } from "lodash";

export const DerivativeInfo = ({
    values,
    instrumentType,
    setFieldValue,
    touched,
    handleBlur,
    errors,
    mode
}) => {
    const [open, setOpen] = useState(true);
    const isViewing = mode === FormModes.view;
    const dtYear = (new Date()).getFullYear();
    const years = ["", ...range(dtYear, dtYear + 11)];
    const months = ["", ...range(1, 13)];
    const days = ["", ...range(1, 32)];

    return (
        <React.Fragment>
            <div onClick={() => setOpen(!open)}>
                <Row className="accordian-style">
                    <Col lg={11} xs={11} md={11}>
                        <h6><strong>Derivative Information</strong></h6>
                    </Col>
                    <Col>
                        <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
                    </Col>
                </Row>
            </div>
            <Collapse in={open}>
                <div className="collapse-content">
                    <Row>
                        <Col lg={4} xs={4} md={4}>
                            <DateGroupYMD id="maturityDate" name="maturityDate" type="textarea" label="Contract Month / Expiration Date"
                                disabled={!values.isEditable}
                                value={values.maturityDate}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                onChange={(e) => {
                                setFieldValue(`maturityDate.${e.option}`, e.value)
                                }}
                                yearData={years}
                                monthData={months}
                                dayData={days}
                                onBlur={handleBlur}
                                plaintext={isViewing}
                                isRequired={true}
                                tooltip={<span>If there is just one expiration per month, indicate the Contract Month (i.e. the year and month).<br/> If there are multiple expirations per month, indicate the Expiration Date. Used for reporting purposes,<br/> including reports sent to the U.S. CFTC as well as certain clearing houses.</span>}
                            />
                        </Col>
                        {instrumentType === OPTION &&
                            <Col lg={3} xs={3} md={3}>
                                <DropdownListGroup id="putOrCall" name="putOrCall" label="Put or Call"
                                    value={values.putOrCall}
                                    enabled={values.isEditable}
                                    errors={errors}
                                    onChange={(e) => setFieldValue("putOrCall", e.id)}
                                    data={InstrumentStaticData.PutOrCallOptions}
                                    textField="name"
                                    valueField="id"
                                    plaintext={isViewing}
                                />
                            </Col>}
                        {instrumentType === OPTION &&
                            <Col lg={3} xs={3} md={3}>
                                <FieldGroup id="strikePrice" name="strikePrice" type="textarea" label="Strike Price"
                                    value={values.strikePrice}
                                    disabled={!values.isEditable}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                    onChange={(e) => setFieldValue("strikePrice", e.target.value)}
                                    onBlur={handleBlur}
                                    min={1}
                                    max={10}
                                    step={1}
                                    plaintext={isViewing}
                                />
                            </Col>}
                    </Row>
                </div>
            </Collapse>

        </React.Fragment>
    )
}

export const ValidateDerivativeInfo = (values, errors) => {
    if (!!values.maturityDate) {
        if (values.maturityDate.month === "") {
          errors.maturityDate = "Month must be selected."
        } else if (values.maturityDate.year === "") {
          errors.maturityDate = "Year must be selected."
        }
    }

    if (values.type === OPTION) {
        const priceScale = Math.pow(10, countDecimals(values.minimumPriceIncrement));
        errors = validateField(values, errors, "strikePrice", "Strike Price", NUMBER_TYPE, true, priceScale);
        errors = validateField(values, errors, "putOrCall", "Put or Call", STRING_TYPE);
    }
    return errors;
}