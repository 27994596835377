export const FETCH_JOBS_START = 'FETCH_JOBS_START';
export const FETCH_JOBS_SUCCEED = 'FETCH_JOBS_SUCCEED';
export const FETCH_JOBS_FAILED = 'FETCH_JOBS_FAILED';
export const FETCH_PODS_START = 'FETCH_PODS_START';
export const FETCH_PODS_SUCCEED = 'FETCH_PODS_SUCCEED';
export const FETCH_PODS_FAILED = 'FETCH_PODS_FAILED';
export const FETCH_CRONS_START = 'FETCH_CRONS_START';
export const FETCH_CRONS_SUCCEED = 'FETCH_CRONS_SUCCEED';
export const FETCH_CRONS_FAILED = 'FETCH_CRONS_FAILED';
export const FETCH_VERSIONS_START = 'FETCH_VERSIONS_START';
export const FETCH_VERSIONS_SUCCEED = 'FETCH_VERSIONS_SUCCEED';
export const FETCH_VERSIONS_FAILED = 'FETCH_VERSIONS_FAILED';
export const STREAM_LOGS_START = 'STREAM_LOGS_START';
export const STREAM_LOGS_UPDATE = 'STREAM_LOGS_UPDATE';
export const STREAM_LOGS_SUCCEED = 'STREAM_LOGS_SUCCEED';
export const STREAM_LOGS_FAILED = 'STREAM_LOGS_FAILED';
export const SET_CRON_DATE = 'SET_CRON_DATE';
export const SET_LOG_FROM_PREVIOUS_TERMINATION = 'SET_LOG_FROM_PREVIOUS_TERMINATION';
