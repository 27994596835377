import React, { Component } from 'react';
import { connect } from "react-redux";
import DataGrid from "../core/data-grid/DataGrid";
import DataGridActions from "../core/data-grid/DataGridActions";

function mapStateToProps(state, props) {
    return {
        commissions: state.commissions.commissions.filter((comm) => comm.account === props.account.name).map((comm) => ({
            ...comm,
            userInfo: state.participantGroups.participantsList.find((usr) => usr.name === comm.userOverride),
            accountInfo: state.accounts.accounts.find((acct) => acct.name === comm.accountOverride),
            firmInfo: state.firms.firms.find((frm) => frm.name === comm.firm),
            accountDisplayName: props.account.displayName
        })),
    };
}

const mapDispatchToProps = (dispatch) => ({

});

class CommissionList extends Component {
    render() {
        const columns = [
            {
                Header: "ACCOUNT",
                accessor: "accountDisplayName",
            }, {
                Header: "BASIS POINTS",
                accessor: "basisPoints",
                width: 120
            }, {
                Header: "MINIMUM NOTIONAL",
                accessor: "minimumNotional",
                width: 120
            }, {
                Header: "MAXIMUM RATE BPS",
                id: "maximumRateBasisPoints",
                accessor: d => !!d.maximumRateBasisPoints && d.maximumRateBasisPoints !== "0" ? d.maximumRateBasisPoints : "",
                width: 120
            }, {
                Header: "MAXIMUM NOTIONAL",
                accessor: "maximumNotional",
                width: 120
            }, {
                Header: "APPLY AS SPREAD",
                id: "applyAsSpread",
                accessor: d => !!d.applyAsSpread ? "Y" : "N",
                width: 50
            }, {
                Header: "FIRM",
                accessor: "firmInfo.displayName",
            }, {
                Header: "USER OVERRIDE",
                accessor: "userInfo.displayName",
            }, {
                Header: "ACCOUNT OVERRIDE",
                accessor: "accountInfo.displayName",
            }, {
                Header: "SIDE OVERRIDE",
                accessor: "sideOverride.name",
                width: 120
            }, {
                Header: "SYMBOL OVERRIDE",
                accessor: "symbolOverride",
                width: 130
            }, {
                Header: "EXPIRATION TIME",
                accessor: "expirationTimeString",
                width: 150
            }, {
                Header: () => {
                    return <div>ACTIONS</div>;
                },
                maxWidth: 160,
                minWidth: 120,
                sortable: false,
                Cell: row => (
                    <DataGridActions
                        onView={() => {
                            this.props.onView(row.original);
                        }}
                        onEdit={() => {
                            this.props.onEdit(row.original);
                        }}
                        onRemove={() => {
                            this.props.onRemove(row.original);
                        }}
                    />
                )
            }
        ];

        return (
            <>
                {(this.props.commissions) && <DataGrid
                    data={this.props.commissions}
                    columns={columns}
                    filterable={false}
                    selectable={false}
                />}
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionList);