import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  LoadFirms,
  DeleteFirmFailed,
  DeleteFirmStart,
  DeleteFirmSucceed,
  ToggleShowDeletedFirms,
  UnDeleteFirm,
} from "../actions/firms";
import Loader from "../components/core/loader/Loader";
import "./Content.css";
import FirmDataGrid from "../components/firm/FirmDataGrid";
import { AdminDocumentTitle } from "../constants/strings";
import * as firmService from "../services/FirmService";
import Notification from "../modules/notifications";
import confirm from "../modules/confirmDialog";
import { hasWriteAccess } from "../services/TokenService";
import ParticipantHeader from "../components/participant/ParticipantHeader";
import { getHelmet, isArrayWithValues, isNumber } from "../modules/util";
import CheckboxGroup from "../components/core/form/CheckboxGroup";
import { FirmsFilter } from "../components/firm/FirmsFilter";
import { Col, Row } from "react-bootstrap";
import { NavigationManager } from "../components/core/helpers/NavigationHelper";

function mapStateToProps(state) {
  return {
    fetchingFirms: state.fetchingFirms,
    firms: state.firms,
    showDeletedFirms: state.firms.showDeletedFirms,
  };
}

const mapDispatchToProps = (dispatch) => ({
  deleteFirmStarted: () => {
    dispatch(DeleteFirmStart());
  },
  deleteFirmSucceed: () => {
    dispatch(DeleteFirmSucceed());
  },
  deleteFirmFailed: () => {
    dispatch(DeleteFirmFailed());
  },
  loadFirms: (showDeletedFirms, firmType = null, cb = null) => {
    dispatch(LoadFirms(firmType, showDeletedFirms, cb));
  },
  toggleShowDeletedFirms: () => {
    dispatch(ToggleShowDeletedFirms());
  },
  unDeleteFirm: (firmName) => {
    return UnDeleteFirm(firmName);
  },
});

class Firms extends Component {

  constructor() {
    super();
    
    this.state = {
      firms: null,
      oldFilter: { firmType: "" },
    };
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.loadFirms(this.props.showDeletedFirms, null, null);

    window.scrollTo(0, 0)
  }

  static getDerivedStateFromProps(props, state) {
    if (!!state) {
      if (!isArrayWithValues(state.firms)) {
        return { firms: Firms.filterData(props.firms.firms, state.filter) };
      }
    }
    return null;
  }

  static contextTypes = {
    router: PropTypes.object,
  };

  openViewFirm = (firmId, actionContext) => {
    const firmIdEncoded = encodeURIComponent(firmId);
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/firms/${firmIdEncoded}`);
    } else {
      this.context.router.history.push(`/firms/${firmIdEncoded}`);
    }
  };

  openEditFirm = (firmId, actionContext) => {
    const firmIdEncoded = encodeURI(firmId);
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/firms/${firmIdEncoded}/edit`);
    } else {
      this.context.router.history.push(`/firms/${firmIdEncoded}/edit`);
    }
  };

  openDeleteFirm = (firmId) => {
    confirm("Are you sure you want to remove this Firm?", {
      title: "Firm Remove Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(() => {
      const cb = (err, response) => {
        if (response) {
          this.props.deleteFirmSucceed();
          this.context.router.history.push("/firms");
          Notification.success("Firm deleted.");
        }

        if (err) {
          this.props.deleteFirmFailed();
          Notification.error(`Error when deleting firm: \n ${err.message}`);
        }
      };

      this.props.deleteFirmStarted();
      firmService.remove(firmId, cb);
    });
  };

  unDeleteFirm = async (firmId) => {
    await confirm("Are you sure you want to restore this Firm?", {
      title: "Firm Restore Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    });

    try {
      await this.props.unDeleteFirm(firmId);
      this.context.router.history.push("/firms");
      Notification.success(`Firm ${firmId} restored.`);
    } catch (e) {
      console.error("could not restore firm", e);
      Notification.error("Could not restore firm", firmId);
    }
  };

  openCreateFirm = () => {
    this.context.router.history.push(`/firms/new`);
  };

  loadFirms = (showDeletedFirms, firmType = null) => {
    this.props.loadFirms(showDeletedFirms, firmType, () => {
      this.setState({ firms: null, oldFilter: { "firmType": firmType } });
    });
  }

  filterFirms = (firmType, firmName, firmState) => {
    this.setState({
      filter: {
        "firmType": isNumber(firmType) ? firmType : "",
        "firmName": !!firmName ? firmName : "",
        "firmState": isNumber(firmState) ? firmState : "",
      }
    }, () => {
      if (this.state.filter.firmType !== this.state.oldFilter.firmType) {
        this.loadFirms(this.props.showDeletedFirms, firmType);
      } else {
        this.setState({ firms: Firms.filterData(this.props.firms.firms, this.state.filter) });
      }
    });
  }

  static filterData = (data, filter) => {
    if (!!filter) {
      let acReg = new RegExp(filter.firmName, "i");

      let filteredData = data.filter(
        (acc) => {
          let res = true;
          if (!!filter.firmName) {
            res = res && !!acc.displayName.match(acReg)
          }

          if (!!res && isNumber(filter.firmState)) {
            res = res && acc.state.id === filter.firmState
          }

          return res;
        }
      );
      return filteredData;
    }

    return data;
  }

  render() {
    return (
      <div className="with-callback">
        <ParticipantHeader
          headerText={getHelmet({})}
          buttonText="ADD NEW FIRM"
          buttonClick={() => {
            this.openCreateFirm();
          }}
          isFirmSelected={true}
          isAddButtonVisible={hasWriteAccess()}
        />

        <Row>
          <Col lg={10} xs={10} md={10}>
            <FirmsFilter onApplyFilter={(firmType, firmName, firmState) => {
              this.filterFirms(firmType, firmName, firmState);
            }}
            />
          </Col>
          <Col lg={2} xs={2} md={2} style={{ marginTop: "40px" }}>
            <CheckboxGroup
              checked={this.props.showDeletedFirms}
              onChange={(e) => {
                this.props.toggleShowDeletedFirms();
                this.loadFirms(!!e.target.checked, this.state.filter ? this.state.filter.firmType : null);
              }}
              label={"Show Deleted Firms"}
            />
          </Col>
        </Row>

        <Loader show={this.props.firms.fetchingFirms}></Loader>
        <FirmDataGrid
          data={this.state.firms}
          onView={(firmId, actionContext) => {
            this.openViewFirm(firmId, actionContext);
          }}
          onEdit={(firmId, actionContext) => {
            this.openEditFirm(firmId, actionContext);
          }}
          onRemove={(firmId) => {
            this.openDeleteFirm(firmId);
          }}
          showGroups={true}
          onUnDelete={(firmName) => {
            this.unDeleteFirm(firmName);
          }}
        ></FirmDataGrid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Firms);
