import { convertDateToString } from '../../modules/util';

export default class TimeSalesTradeCaptureFormItem {
  constructor(item) {
    this.tradeCaptureId = null;
    this.transactionTime = null;
    this.transactionBookedTime = null;
    this.tradeId = null;
    this.aggressor = null;
    this.passive = null;
    this.aggressorId = null;
    this.passiveId = null;
    this.aggressorAccount = null;
    this.passiveAccount = null;
    this.aggressorSide = null;
    this.passiveSide = null;
    this.tradeQty = null;
    this.tradePrice = null;
    this.status = null;
    this.priceScale = null;
    this.reportingCounterparty = null;
    this.tradeStatus = null;
    this.blockTradeIndicator = false;
    this.text = null;

    if (item) {
      this.tradeCaptureId = item.id;
      this.transactionTime = convertDateToString(item.tradeTime, "YYYYMMDD-HH:mm:ss.SSS");
      this.tradeId = item.trade.id;
      this.trade = item.trade;
      
      const aggressorOrder = item.orders.filter(order => {
        return !!item.trade.aggressor && !!item.trade.aggressor.order && order.id === item.trade.aggressor.order.id;
      })[0];

      const passiveOrder = item.orders.filter(order => {
        return !!item.trade.passive && !!item.trade.passive.order && order.id === item.trade.passive.order.id;
      })[0];

      if (!!aggressorOrder) {
        this.aggressor = aggressorOrder.participant;
        this.aggressorAccount = aggressorOrder.account;
        this.aggressorSide = aggressorOrder.side.name;
        this.aggressorId = aggressorOrder.id;
        this.aggressorSubmittingParticipant = aggressorOrder.submittingParticipant;
        this.aggressorClientAccountId = aggressorOrder.clientaccountid;
        this.aggressorClientParticipantId = aggressorOrder.clientparticipantid;
        this.aggressorSymbolSubType = aggressorOrder.symbolsubtype;
        this.aggressorCommissionsBasisPoints = aggressorOrder.commissionsBasisPoints;
        this.aggressorCommissionNotionalCollected = item.trade.aggressor.commissionNotionalCollected;
        this.transactionBookedTime = !!aggressorOrder.transactionBookedTime ? convertDateToString(aggressorOrder.transactionBookedTime, "YYYYMMDD-HH:mm:ss.SSS") : null;
        this.blockTradeIndicator = aggressorOrder.blockTradeIndicator;
      }

      if (!!passiveOrder) {
        this.passive = passiveOrder.participant;
        this.passiveAccount = passiveOrder.account;
        this.passiveSide = passiveOrder.side.name;
        this.passiveId = passiveOrder.id;
        this.passiveSubmittingParticipant = passiveOrder.submittingParticipant;
        this.passiveClientAccountId = passiveOrder.clientaccountid;
        this.passiveClientParticipantId = passiveOrder.clientparticipantid;
        this.passiveSymbolSubType = passiveOrder.symbolsubtype;
        this.passiveCommissionsBasisPoints = passiveOrder.commissionsBasisPoints;
        this.passiveCommissionNotionalCollected = item.trade.passive.commissionNotionalCollected;
        this.transactionBookedTime = !!passiveOrder.transactionBookedTime ? convertDateToString(passiveOrder.transactionBookedTime, "YYYYMMDD-HH:mm:ss.SSS") : null;
        this.blockTradeIndicator = passiveOrder.blockTradeIndicator;
      }

      this.tradeQty = item.trade.aggressor.lastShares;
      this.tradePrice = item.trade.aggressor.lastPx;

      this.status = item.state.name;
      this.reportingCounterparty = item.reportingCounterparty;
      this.tradeStatus = item.trade.state.name;
      this.blockTradeIndicator = this.blockTradeIndicator.toString();
      this.text = item.trade.text;
      return;
    }
  }
}