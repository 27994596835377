import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import confirm from "../modules/confirmDialog";
import {
  IsCreatingInstrument,
  updateState,
  UpdateInstrumentItem,
  SetStateWarningMessage,
  fetchMetadata,
  setInstrumentMarketStats,
  setSettlementPrice,
  setOpenInterest,
  updateInstrumentHideMarketData
} from "../actions/instruments";

import Notification from "../modules/notifications";
import InstrumentMapper from "../modules/mappers/instrumentMapper";
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import { StatusCode } from "grpc-web";
import InstrumentDetails from "../components/instrument/InstrumentDetails";
import InstrumentService from "../services/InstrumentService";
import NettingService from "../services/NettingService"
import { convertScaledPriceToInt, isNullOrUndefined, isNumber } from "../modules/util";
import _ from 'lodash';

function mapStateToProps(state) {
  return {
    isAddingInstrument: state.instruments.isCreatingInstrument,
    stateWarningMessage: state.instruments.stateWarningMessage,
    instrument: state.instruments.instrument,
    metadata: state.instruments.metadata,
    holidays: state.instruments.holidayCalendarObjects,
  };
}

const mapDispatchToProps = (dispatch) => ({
  isCreatingInstrument: (status) => {
    dispatch(IsCreatingInstrument(status));
  },
  updateState: (id, stateId, isScheduled, scheduleExecutionTime) => {
    dispatch(updateState(id, stateId, isScheduled, scheduleExecutionTime));
  },
  updateInstrumentItem(key, value) {
    dispatch(UpdateInstrumentItem(key, value));
  },
  setStateWarningMessage(message) {
    dispatch(SetStateWarningMessage(message));
  },
  fetchMetadata: () => {
    dispatch(fetchMetadata());
  },
  updateInstrumentHideMarketData: (id, hideMarketData) => {
    dispatch(updateInstrumentHideMarketData(id, hideMarketData));
  },
  setInstrumentMarketStats: (id, stats, cb) => {
    dispatch(setInstrumentMarketStats(id, stats, cb));
  },
  setSettlementPrice: (id, settlementPrice, transactTime, settlementPreliminary, cb) => {
    dispatch(setSettlementPrice(id, settlementPrice, transactTime, settlementPreliminary, cb));
  },
  setOpenInterest: (id, openInterest, transactTime, cb) => {
    dispatch(setOpenInterest(id, openInterest, transactTime, cb));
  },
});

class InstrumentFormContainer extends Component {


  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.props.setStateWarningMessage(null);
    this.props.fetchMetadata();
  }

  saveInstrumentMultiState = (newState) => {
    confirm(`Are you sure you want to update all states?`, {
      title: "Instrument Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const sel = Array.isArray(this.props.selection) ? this.props.selection : [this.props.instrument.id];
        sel.forEach((id) => {
          this.props.updateState(id, newState.selState, newState.isScheduled, newState.scheduleExecutionTime);
        });
      },
      () => { }
    );
  };

  saveTradeDayRoll = (newState) => {
    confirm(`Are you sure you want perform EOD actions?`, {
      title: "Trade Day Roll Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
        () => {
          const sel = Array.isArray(this.props.selection) ? this.props.selection : [this.props.instrument.id];
          const cb = (err, response) => this.processSavedInstrument(response, err);
          InstrumentService.performEodActions(sel, cb);
          this.context.router.history.push('/instruments');
        },
        () => { }
    );
  };

  saveInstrument = (instrumentData, setFieldError) => {
    confirm(`Are you sure you want to ${this.props.isAddingInstrument ? "add" : "edit"} the instrument?`,
      {
        title: "Instrument Confirmation",
        okButtonText: "Yes",
        cancelButtonText: "No",
      }
    ).then(
      () => {
        if (this.props.isAddingInstrument) { // Add Instrument

          if (!!instrumentData?.isScheduled) {
            this.handleSaveInstrument(instrumentData, setFieldError);

          } else if ((!!instrumentData?.startDate || !!instrumentData?.lastTradeDate || !!instrumentData?.expirationDate || !!instrumentData?.terminationDate)
            && !instrumentData?.isScheduled) {

            confirm(`Instrument has a Trading Schedule but is not currently following it. Set to follow?`,
              {
                title: "Instrument Confirmation",
                okButtonText: "Yes",
                cancelButtonText: "No",
              }
            ).then(() => { // Set to follow
              instrumentData.isScheduled = true;
            }, () => { }).finally(() => {
              this.handleSaveInstrument(instrumentData, setFieldError);
            });
          }

        } else { // Edit Instrument
          this.handleSaveInstrument(instrumentData, setFieldError);
        }
      },
      () => { }
    );
  };

  handleSaveInstrument = (instrumentData, setFieldError) => {
    const instrumentApi = InstrumentMapper.mapToInstrument(instrumentData);

    if (this.props.isAddingInstrument) {
      this.createInstrument(instrumentApi, instrumentData, setFieldError);
    }
    else {
      this.updateInstrument(instrumentApi, instrumentData, setFieldError);
    }
  }

  createInstrument = (data, formData, setFieldError) => {
    const cb = (err, response) => {

      if (!!formData.isScheduled) {
        this.props.updateState(formData.id, formData.state.id, formData.isScheduled, null);
      }

      this.processSavedInstrument(response, err, setFieldError);
    }

    InstrumentService.post(data, cb);
  };

  updateInstrument = (mappedInstrument, formData, setFieldError) => {
    const cb = (err, response) => {
      this.processSavedInstrument(response, err, setFieldError, formData.hideMarketData);
    }

    if (formData.state.id === InstrumentState.INSTRUMENT_STATE_PENDING) {
      InstrumentService.updatePending(mappedInstrument, cb);
    } else if (
      formData.state.id !== InstrumentState.INSTRUMENT_STATE_TERMINATED
    ) {
      InstrumentService.updateActive(formData.id, mappedInstrument, cb);
    }
  };

  processSavedInstrument = (response, err, setFieldError, hideMarketData) => {
    if (response) {

      this.context.router.history.push('/instruments');
      Notification.success('Instrument saved');
    }

    if (err) {
      this.processFormError(err, setFieldError);
    }
  };

  processFormError = (error, setFieldError) => {
    switch (error.code) {
      case StatusCode.ALREADY_EXISTS:
        if (!isNullOrUndefined(setFieldError)) {
          setFieldError("name", error.message, false);
        }
        Notification.error(
          `Instrument already exists. If the instrument has been recently terminated, please filter on that state and delete permanently to continue creation.`
        );
        break;
      default:
        Notification.error(`Failed to save this instrument. ${error.message}`);
        break;
    }
  };

  getFormMode = () => {
    if (this.props.location.pathname.indexOf("/edit") > 0) {
      this.props.isCreatingInstrument(false);
      return InstrumentDetails.formModes.edition;
    } else if (this.props.location.pathname.endsWith("/copy")) {
      this.props.isCreatingInstrument(true);
      return InstrumentDetails.formModes.creation;
    } else if (this.props.location.pathname.indexOf("/new") > 0) {
      this.props.isCreatingInstrument(true);
      return InstrumentDetails.formModes.creation;
    } else if (this.props.location.pathname.indexOf("/changeState") > 0) {
      this.props.isCreatingInstrument(false);
      return InstrumentDetails.formModes.changeState;
    } else if (this.props.location.pathname.indexOf("/multiState") > 0) {
      this.props.isCreatingInstrument(false);
      return InstrumentDetails.formModes.multiState;
    } else if (this.props.location.pathname.indexOf("/marketStats") > 0) {
      return InstrumentDetails.formModes.marketStats;
    } else {
      return InstrumentDetails.formModes.view;
    }
  };

  onSetInstrumentStats = (id, data, setFieldError) => {
    confirm("Are you sure you want to save the market stats for the instrument?", {
      title: "Instrument Stats Save",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const reqData = { ...data };
        var { instrument } = this.props;
        const pxScale = instrument.priceScale !== 0 ? instrument.priceScale : 1;
        const qtyScale = instrument.fractionalQtyScale !== 0 ? instrument.fractionalQtyScale : 1;

        const nonPriceAttributes = ["transactTime", "settlementPreliminary"];

        _.map(data, (value, key) => {
          if (!nonPriceAttributes.includes(key)) {
            const scale = key === "notionalTraded" ? pxScale * qtyScale : key === "sharesTraded" || key === "lastTradeQty" || key === "openInterest" ? qtyScale : pxScale;
            data[key] = convertScaledPriceToInt(value, scale);
          }
        });

        const cb = (err, response) => {
          if (response) {
            this.context.router.history.push('/instruments');
            Notification.success("Instrument Stats were saved");
          }

          if (err) {
            Notification.error(`Cannot save Instrument Stats: ${err.message}`);
          }
        };

        if (instrument.nonTradable) {
          // if nontradable, we can update stats all at once
          this.props.setInstrumentMarketStats(id, data, cb);
        } else {
          let settlementPrice = isNumber(reqData.settlementPrice) ? data.settlementPrice : '';
          this.props.setSettlementPrice(id, settlementPrice, data.transactTime, data.settlementPreliminary, cb);

          let openInterest = isNumber(reqData.openInterest) ? data.openInterest : '';
          this.props.setOpenInterest(id, openInterest, data.transactTime, cb);
        }
      },
      () => { }
    );
  }

  onPerformResolution = (symbol, subType, isLastIteration) => {
    NettingService.performResolution(symbol, subType, (err, resp) => {
      if (!isNullOrUndefined(err)) {
        Notification.error(`Failed to perform resolution: ${err.message}`);
      } else {
        if (isLastIteration) {
          this.context.router.history.push('/instruments');
        }
        Notification.success("Perform resolution completed successfully.");
      }
    });
  }

  onUndoResolution = (symbol, isLastIteration) => {
    NettingService.undoResolution(symbol, (err, resp) => {
      if (!isNullOrUndefined(err)) {
        Notification.error(`Failed to undo resolution: ${err.message}`);
      } else {
        if (isLastIteration) {
          this.context.router.history.push('/instruments');
        }
        Notification.success("Undo resolution completed successfully.");
      }
    });
  }

  render() {
    return (
      <InstrumentDetails
        stateWarningMessage={this.props.stateWarningMessage}
        onSubmitForm={this.saveInstrument}
        onSubmitMultiStateForm={this.saveInstrumentMultiState}
        onSubmitTradeDayRollForm={this.saveTradeDayRoll}
        onProductListChange={this.props.onProductListChange}
        selection={this.props.selection}
        instrument={this.props.instrument}
        metadata={this.props.metadata}
        holidays={this.props.holidays}
        mode={this.getFormMode()}
        onSetInstrumentStats={this.onSetInstrumentStats}
        updateHideMarketData={this.updateHideMarketData}
        onPerformResolution={this.onPerformResolution}
        onUndoResolution={this.onUndoResolution}
      />
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InstrumentFormContainer)
);
