import React from "react";
import PropTypes from "prop-types";
import { FormGroup, FormLabel, } from "react-bootstrap";
import { DropdownList } from "react-widgets";
import Tooltip from '../tooltip-html/tooltip-html';
import { isNullOrUndefined } from '../../../modules/util';

const DateGroupYMD = ({
  id,
  type,
  componentType,
  isRequired,
  name,
  label,
  help,
  value,
  placeholder,
  setFieldValue,
  errors,
  touched,
  onChange,
  options,
  cssName,
  readOnly,
  plaintext,
  dropUp,
  enabled,
  yearData,
  monthData,
  dayData,
  textField,
  valueField,
  tooltip,
  ...props
}) => {
  
  const year = value.year ? value.year : "";
  const month = value.month ? value.month : "";
  const day = value.day ? value.day : "";

  return (
    <FormGroup id={id} name={name}>

      {label && <FormLabel>{label}</FormLabel>}

      {plaintext && <div className="form-readonly-label">{year + (!!month ? "/" + month : "") + (!!day ? "/" + day : "")}</div>}

      {!plaintext &&
        <>
          {isRequired && <label className="form-label-required"> Required</label>}
          {!isNullOrUndefined(tooltip) &&
          <Tooltip
              text={ tooltip }
              id="dataGroupTooltip"
            ></Tooltip>
        }
          <div style={{ display: "flex", "flex-wrap": "nowrap" }}>
            <DropdownList
              style={{ "flex-grow": "1" }}
              data={yearData}
              value={year}
              enabled={enabled}
              onChange={(e) => {
                onChange({ "option": "year", "value": e, });
              }}
              valueField={valueField}
              textField={textField}
            />

            <DropdownList
              style={{ "flex-grow": "1" }}
              data={monthData}
              value={month}
              enabled={enabled}
              onChange={(e) => {
                onChange({ "option": "month", "value": e });
              }}
              valueField={valueField}
              textField={textField}
            />

            <DropdownList
              style={{ "flex-grow": "1" }}
              data={dayData}
              value={day}
              enabled={enabled}
              onChange={(e) => {
                onChange({ "option": "day", "value": e });
              }}
              valueField={valueField}
              textField={textField}
            />

          </div>
          {help && <div className="help-block">{help}</div>}
          {errors && errors[name] && (
            <div className="form-error">
              <i
                className="fa fa-exclamation-triangle orange-icon"
                aria-hidden="true"
              ></i>{" "}
              {errors[name]}
            </div>
          )}
        </>
      }

    </FormGroup>
  )
};

DateGroupYMD.defaultProps = {
  type: "text",
  isRequired: false,
  value: ""
};

DateGroupYMD.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default DateGroupYMD;
