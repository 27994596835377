import {
  getEnumName,
  capitalizeWord
} from '../modules/util';
import State from './dto/State';
import { TimeInForce, Side, OrderType, OrderCapacity } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb';
import OrderStatus from './OrderStatus';
import {tsProtoToDate} from "../actions/ptypes";

export default class Order {
  constructor(item) {
    if (item) {
      const object = item.toObject();
      this.id = object.id;
      this.participant = object.participant;
      this.createdOn = tsProtoToDate(item.getInsertTime());
      //Attributes
      this.clordId = object.clordId;
      this.account = object.account;
      this.quantity = object.orderQty;
      this.minQty = object.minQty;
      this.price = object.price;
      this.stopPrice = object.stopPrice;
      this.blockTradeIndicator = object.blockTradeIndicator;

      let side = new State();
      side.id = object.side;
      side.name = capitalizeWord(getEnumName(Side, side.id));
      this.side = side;

      this.symbol = object.symbol;

      let tif = new State();
      tif.id = object.timeInForce;
      tif.name = capitalizeWord(getEnumName(TimeInForce, tif.id));
      this.timeInForce = tif;

      let typ = new State();
      typ.id = object.type;
      typ.name = capitalizeWord(getEnumName(OrderType, typ.id));
      this.type = typ;

      let orderCapacity = new State();
      orderCapacity.id = object.orderCapacity;
      orderCapacity.name = capitalizeWord(getEnumName(OrderCapacity, orderCapacity.id));
      this.orderCapacity = typ;

      this.status = new OrderStatus(item);

      this.submittingParticipant = object.submittingParticipant;
      this.clientparticipantid = object.clientParticipantId;
      this.clientaccountid = object.clientAccountId;
      this.parentorderid = object.parentOrderId;
      this.symbolsubtype = object.symbolSubType;
      this.commissionsBasisPoints = object.commissionsBasisPoints;
      const lastTxTime = item.getLastTransactTime()
      if (!!lastTxTime) {
        this.lastTransactTime = tsProtoToDate(lastTxTime);
      }
      const txBookedTime = item.getTransactionBookedTime()
      if (!!txBookedTime) {
        this.transactionBookedTime = tsProtoToDate(txBookedTime);
      }
      return;
    }
    this.id = null;
    this.participant = null;
    this.status = null;
    this.createdOn = null;
    //Attributes
    this.clordId = null;
    this.account = null;
    this.quantity = null;
    this.minQty = null;
    this.price = null;
    this.side = null;
    this.symbol = null;
    this.timeInForce = null;
    this.type = null;
    this.orderCapacity = null;
    this.clientparticipantid = null;
    this.clientaccountid = null;
    this.parentorderid = null;
    this.symbolsubtype = null;
    this.commissionsBasisPoints = null;
    this.blockTradeIndicator = false;
  }
}
