import {
  getEnumName,
  capitalizeWord
} from '../modules/util';
import State from './dto/State';
import { OrderState } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb';

export default class OrderStatus {
  constructor(item) {
    if (item) {
      const object = item.toObject();
      this.cumQty = object.cumQty;
      this.avgPx = object.avgPx;
      this.leavesQty = object.leavesQty;

      let state = new State();
      state.id = object.state;
      state.name = capitalizeWord(getEnumName(OrderState, object.state));
      this.state = state;
      return;
    }
    this.hours = null;
    this.minutes = null;
    this.seconds = null;
    this.state = null;
  }
}
