import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import FieldGroup from "../core/form/FieldGroup";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import { Container, Row, Col } from "react-bootstrap";
import ButtonMain from "../core/form/ButtonMain";
import ButtonBorderless from "../core/form/ButtonBorderless";
import UserRoleListContainer from "../../containers/UserRoleListContainer";
import { convertEnumToDropdownList } from "../../modules/util";
import { ParticipantRole } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";

export class BasicFirmServiceKeysForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      title,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      firm,
      id,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} name="firmServiceKeysForm">
        <h1>{title}</h1>
        <Container>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroup
                id="name"
                name="name"
                type="text"
                label="API Key Name"
                placeholder=""
                isRequired={true}
                errors={errors}
                touched={touched}
                value={values.name}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroup
                id="description"
                name="description"
                type="text"
                label="API Key Description"
                placeholder=""
                isRequired={false}
                errors={errors}
                touched={touched}
                value={values.description}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroupReadOnly
                id="firmService"
                name="firmService"
                label="Firm Service"
                value={id}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <UserRoleListContainer
                id="role"
                name="role"
                label="Role"
                isRequired={true}
                errors={errors}
                touched={touched}
                value={values.role}
                setFieldValue={setFieldValue}
                onChange={(e) => {
                  setFieldValue("role", e);
                }}
                onBlur={handleBlur}
                firm={firm}
                onEdit={true}
              />
              {errors && errors["role"] && (
                <div className="form-error">
                  <i
                    className="fa fa-exclamation-triangle orange-icon"
                    aria-hidden="true"
                  ></i>{" "}
                  {errors["role"]}
                </div>
              )}
            </Col>
          </Row>
          <ButtonMain type="submit" text="SAVE" />
          <ButtonBorderless
            type="button"
            text="Cancel"
            icon="times-circle"
            customClassName="grey-icon"
            onClick={(e) => {
              e.preventDefault();
              this.context.router.history.goBack();
            }}
          />
        </Container>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    isEditing: !state.firms.isCreating,
  };
}

const roleEnum = convertEnumToDropdownList(ParticipantRole);
const formatValues = (values, props) => {
  return {
    ...values,
    firmServiceId: props.firmServiceId,
    role: roleEnum.filter((role) => role.name === values.role).shift(),
  };
};

const FirmServiceKeysForm = withFormik({
  mapPropsToValues: (item) => ({
    ...item.item,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().nullable().required("Firm name is required."),
  }),
  validate: (values) => {
    let errors = {};
    if (!values.role) {
      errors.role = "Role must be defined.";
    }
    return errors;
  },
  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onSubmitForm(formatValues(values, props), setFieldError);
  },
  displayName: "firm-form",
  enableReinitialize: true,
})(BasicFirmServiceKeysForm);

export default connect(mapStateToProps, null)(FirmServiceKeysForm);
