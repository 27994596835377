import State from "./dto/State";
import {capitalizeWord, getEnumName} from "../modules/util";
import {ParticipantGroupState} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";

export default class ParticipantGroup {
  constructor(item, firms) {
    if (item) {
      this.id = item.getId();
      var state = new State();
      state.id = item.getState();
      state.name = capitalizeWord(getEnumName(ParticipantGroupState, state.id));
      this.state = state;
      this.isSuspended = state.id === ParticipantGroupState.PARTICIPANT_GROUP_STATE_SUSPENDED;
      this.firm= item.getFirm();
      var firm = firms ? firms.find((firm) => firm.name === this.firm) : null;
      this.firmDisplayName = firm ? firm.displayName : this.firm; 
      return;
    }
    this.id = null;
    this.state = null;
    this.isSuspended = null;
    this.firm=null;
    this.firmDisplayName = null;
  }
}