import React, { Component } from "react";
import { connect } from "react-redux";
import confirm from "../../modules/confirmDialog";
import * as firmService from "../../services/FirmService";
import PropTypes from "prop-types";
import Notification from "../../modules/notifications";
import DataGrid from "../core/data-grid/DataGrid";
import DataGridActions from "../core/data-grid/DataGridActions";

function mapStateToProps(state) {
  return {
    firm: state.firms.firm,
    firmServiceKeys: state.firms.firmServiceKeys,
  };
}

export class FirmServicesKeysGrid extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    let { firmServiceId, loadFirmServiceKeys } = this.props;
    if (firmServiceId) loadFirmServiceKeys(firmServiceId);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps !== null &&
      prevProps.firmServiceId !== this.props.firmServiceId
    ) {
      this.props.loadFirmServiceKeys(this.props.firmServiceId);
    }
  }

  onView = (apiKeyId) => {
    this.context.router.history.push(`keys/${apiKeyId}`);
  };

  onEdit = (apiKeyId) => {
    this.context.router.history.push(`keys/${apiKeyId}/edit`);
  };

  onRemove = (keyName) => {
    confirm(<div>Are you sure you wish to remove this Firm Service Key?</div>, {
      title: "Firm Service API Key Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            Notification.success("Firm Service Key removed.");
            this.componentDidMount();
          }

          if (err) {
            Notification.error("Cannot remove Firm Service Key.");
          }
        };
        firmService.removeServiceKey(this.props.firmServiceId, keyName, cb);
      },
      () => {}
    );
  };

  render() {
    let { firmServiceKeys } = this.props;

    const columns = [
      {
        Header: "NAME",
        accessor: "name",
      },
      {
        Header: "TOKEN ID",
        accessor: "jti",
        maxWidth: 230,
        minWidth: 230,
      },
      {
        Header: "ROLE",
        id: "role",
        accessor: (d) => (d.role ? d.role.name : null),
        maxWidth: 200,
        minWidth: 200,
      },
      {
        Header: "ACTIONS",
        maxWidth: 150,
        minWidth: 150,
        sortable: false,
        Cell: (row) => (
          <DataGridActions
            onView={() => {
              this.onView(row.original.name);
            }}
            onEdit={() => {
              this.onEdit(row.original.name);
            }}
            onRemove={() => {
              this.onRemove(row.original.name);
            }}
          />
        ),
      },
    ];

    return (
      <DataGrid data={firmServiceKeys} columns={columns} filterable={false} />
    );
  }
}

export default connect(mapStateToProps, null)(FirmServicesKeysGrid);
