import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import {
  validateField,
  makeDropdownOptions,
  getOptionText,
  SELECTED_DEFAULT,
  NUMBER_TYPE,
  STRING_TYPE,
  ARRAY_TYPE,
  DATE_TYPE,
} from "../../instrumentFormUtils";
import DateGroup from "../../../core/form/DateGroup";
import FieldGroup from "../../../core/form/FieldGroup";
import { FormModes } from "../../../../constants/enumerations";

export const FloatingLegInfoOis = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  handleChange,
  errors,
  mode,
}) => {
  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6>
              <strong>Floating Leg Information</strong>
            </h6>
          </Col>
          <Col>
            <i
              className={`fa fa-fw ${
                open ? "fa-minus" : "fa-plus"
              } custom-plus`}
            />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Float Compounding Method"
                value={getOptionText(
                  InstrumentStaticData.CompoundingOptions,
                  values.floatCompoundingMethod
                )}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatDayCountConvention"
                name="floatDayCountConvention"
                label="Float Day Count Convention"
                value={values.floatDayCountConvention}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("floatDayCountConvention", e.id)}
                data={InstrumentStaticData.DayCountConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Float Business Day Convention"
                value={getOptionText(
                  InstrumentStaticData.BusinessDayConventionOptions,
                  values.floatBusinessDayConvention
                )}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Float Holiday Calendars"
                value={
                  values.floatHolidayCalendarsList
                    ? values.floatHolidayCalendarsList.join(", ")
                    : ""
                }
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Float Payment Frequency"
                value={getOptionText(
                  InstrumentStaticData.FloatPaymentFrequency,
                  values.floatPaymentFrequency
                )}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Float Rate Reset Frequency"
                value={getOptionText(
                  InstrumentStaticData.FloatRateResetFrequency,
                  values.floatRateResetFrequency
                )}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Reset Date Business Day Convention"
                value={getOptionText(
                  InstrumentStaticData.BusinessDayConventionOptions,
                  values.floatResetDateBusinessDayConvention
                )}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="firstFloatResetDate"
                name="firstFloatResetDate"
                label="First Float Reset Date"
                value={values.firstFloatResetDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("firstFloatResetDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="firstFloatFixingDate"
                name="firstFloatFixingDate"
                label="First Float Fixing Date"
                value={values.firstFloatFixingDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue("firstFloatFixingDate", e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col>
              <FieldGroupReadOnly
                label="Float Fixing Dates Day Type"
                value={getOptionText(
                  InstrumentStaticData.FixingDatesDayType,
                  values.floatFixingDatesDayType
                )}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatingRateIndex"
                name="floatingRateIndex"
                label="Floating Rate Index"
                value={values.floatingRateIndex}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatingRateIndex", e)}
                data={InstrumentStaticData.FloatingRateIndexList}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup
                id="floatRollConvention"
                name="floatRollConvention"
                label="Float Roll Convention"
                isRequired={true}
                value={values.floatRollConvention || SELECTED_DEFAULT}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("floatRollConvention", e)}
                data={makeDropdownOptions(
                  InstrumentStaticData.FixedRollConventionList,
                  false
                )}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>

            <Col lg={3} xs={3} md={3}>
              <DateGroup
                id="floatFirstUnadjustedPaymentDate"
                name="floatFirstUnadjustedPaymentDate"
                label="Float First Unadjusted Payment Date"
                value={values.floatFirstUnadjustedPaymentDate}
                maxDate={null}
                dropUp={true}
                enabled={values.isEditable}
                errors={errors}
                touched={touched}
                onChange={(e) =>
                  setFieldValue("floatFirstUnadjustedPaymentDate", e)
                }
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>

            <Col>
              <FieldGroupReadOnly
                label="Float Payment Date Relative To"
                value={getOptionText(
                  InstrumentStaticData.DateRelativeToOptions,
                  values.floatPaymentDateRelativeTo
                )}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Float Reset Date Relative To"
                value={getOptionText(
                  InstrumentStaticData.DateRelativeToOptions,
                  values.floatResetDateRelativeTo
                )}
              />
            </Col>

            <Col>
              <FieldGroupReadOnly
                label="Float Fixing Dates Offset"
                value={values.floatFixingDatesOffset}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Fixing Date Business Day Convention"
                value={getOptionText(
                  InstrumentStaticData.BusinessDayConventionOptions,
                  values.floatFixingDateBusinessDayConvention
                )}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly
                label="Float Fixing Date Holiday Calendars"
                value={
                  values.floatFixingDateHolidayCalendarsList
                    ? values.floatFixingDateHolidayCalendarsList.join(", ")
                    : ""
                }
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <FieldGroup
                id="floatPaymentDateOffset"
                name="floatPaymentDateOffset"
                type="textarea"
                label="Float Payment Date Offset"
                value={values.floatPaymentDateOffset}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={(e) =>
                  setFieldValue("floatPaymentDateOffset", e.target.value)
                }
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>

            <Col lg={3} xs={3} md={3}>
              <FieldGroup
                id="floatingRateIndexTenor"
                name="floatingRateIndexTenor"
                type="textarea"
                label="Floating Rate Index Tenor"
                value={values.floatingRateIndexTenor}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  );
};

export const ValidateFloatingLegOis = (values, errors) => {
  errors = validateField(
    values,
    errors,
    "floatingRateIndex",
    "Floating Rate Index",
    STRING_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatHolidayCalendarsList",
    "Float Holiday Calendar",
    ARRAY_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatDayCountConvention",
    "Day Count Convention",
    NUMBER_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatPaymentFrequency",
    "Payment Frequency",
    NUMBER_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatRateResetFrequency",
    "Rate Reset Frequency",
    NUMBER_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatResetDateBusinessDayConvention",
    "Reset Date Business Day Convention",
    NUMBER_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatBusinessDayConvention",
    "Float Business Day Convention",
    NUMBER_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatCompoundingMethod",
    "Compounding Method",
    NUMBER_TYPE
  );
  errors = validateField(
    values,
    errors,
    "firstFloatResetDate",
    "First Float Reset Date",
    DATE_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatFixingDatesOffset",
    "Float Fixing Dates Offset",
    NUMBER_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatResetDateRelativeTo",
    "Float Reset Date Relative To",
    NUMBER_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatFixingDateBusinessDayConvention",
    "Float Fixing Date Business Day Convention",
    ARRAY_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatFixingDatesDayType",
    "Float Fixing Dates Day Type",
    ARRAY_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatingRateIndexTenor",
    "Floating Rate Index Tenor",
    STRING_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatRollConvention",
    "Float Roll Convention",
    STRING_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatPaymentDateRelativeTo",
    "Float Payment Date Relative To",
    ARRAY_TYPE
  );
  errors = validateField(
    values,
    errors,
    "floatPaymentDateOffset",
    "Float Payment Date Offset",
    NUMBER_TYPE
  );
  return validateField(
    values,
    errors,
    "floatFixingDateHolidayCalendarsList",
    "Float Fixing Date Holiday Calendar",
    ARRAY_TYPE
  );
};
