import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'react-bootstrap';
import RelativePriceLimitField from './RelativePriceLimitField';
import './RelativePriceLimit.css';
import Tooltip from '../core/tooltip-html/tooltip-html';


class RelativePriceLimit extends Component {
  render() {
    return (
      <div className="relative-price-limit-wrapper">
        <label>Relative Price Limits</label> 
        <Tooltip
          text = "High and low price limits calculated as a percentage from the settlement price."
          id="relativePriceTooltip"
        ></Tooltip>
        <Row>
          <Col lg={6} xs={6} md={6}>
            <RelativePriceLimitField
              label="Low Limit (%)"
              priceName="relativeLow"
              priceValue={this.props.lowPrice}
              setPrice={this.props.setLow}
              isRequired={false}
              errors={this.props.errors}
              touched={this.props.touched}
              setFieldValue={this.props.setFieldValue}
              onChange={this.props.onChange}
              onBlur={this.props.handleBlur}
              onSetClick={(checked) => {
                this.props.onSetLow(checked);
              }}
            />
          </Col>
          <Col lg={6} xs={6} md={6}>
            <RelativePriceLimitField
              label="High Limit (%)"
              priceName="relativeHigh"
              priceValue={this.props.highPrice}
              setPrice={this.props.setHigh}
              isRequired={false}
              errors={this.props.errors}
              touched={this.props.touched}
              setFieldValue={this.props.setFieldValue}
              onChange={this.props.onChange}
              onBlur={this.props.handleBlur}
              onSetClick={(checked) => {
                this.props.onSetHigh(checked);
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

RelativePriceLimit.propTypes = {
  lowPrice: PropTypes.any,
  highPrice: PropTypes.any,
  setLow: PropTypes.bool,
  setHigh: PropTypes.bool,
  errors: PropTypes.any,
  touched: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSetLow: PropTypes.func.isRequired,
  onSetHigh: PropTypes.func.isRequired
};

export default RelativePriceLimit;