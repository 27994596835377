import React from "react";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import "../core/data-grid/Pagination.css";
import LoaderIcon from "../core/loader/LoaderIcon";
import GridActions from '../core/data-grid/GridActions';
import GridAction, { GridActionViewHistory } from "../core/data-grid/GridAction";
import { useMemo } from "react";
import { GridActionMenu } from "../core/menu-items/menu-items";
import usePreserveGridColsLayout from "../hooks/usePreserveGridColsLayout";
import { isArrayWithValues } from "../../modules/util";

const RFQSearchDataGrid = ({
  data,
  loading,
  onView,
  onQuoteHistory,
  onPageChange,
  onPageSizeChange,
  paginationComponent,
}) => {

  const gridCtxMenu = useMemo(() => GridActionMenu, []);
  const { columnsLayout, saveColumnsLayout } = usePreserveGridColsLayout("RFQSearchDGColumnsLayout");

  const onColumnResize = (newResized, event) => {
    if (isArrayWithValues(newResized)) {
      newResized.map((column) => {
        columnsLayout[column.id] = column.value;
      });
    }

    saveColumnsLayout(columnsLayout);
  }

  const getColumnWidth = (id) => {
    return columnsLayout[id] ? columnsLayout[id] : undefined;
  }

  const columns = [
    {
      Header: "TIMESTAMP",
      accessor: "createdOn",
      minWidth: 100,
      width: getColumnWidth("createdOn"),
    },
    {
      Header: "PARTICIPANT",
      accessor: "user",
      minWidth: 60,
      width: getColumnWidth("user"),
    },
    {
      Header: "FIRM",
      accessor: "firm",
      minWidth: 80,
      width: getColumnWidth("firm"),
    },
    {
      Header: "ACCOUNT",
      accessor: "account",
      minWidth: 60,
      width: getColumnWidth("account"),
    },
    {
      Header: "AGENT",
      accessor: "submittingUser",
      minWidth: 60,
      width: getColumnWidth("submittingUser"),
    },
    {
      Header: "FIRM",
      accessor: "submittingFirm",
      minWidth: 80,
      width: getColumnWidth("submittingFirm"),
    },
    {
      Header: "SIDE",
      accessor: "side",
      minWidth: 30,
      width: getColumnWidth("side"),
    },
    {
      Header: "INSTRUMENT ID",
      accessor: "symbol",
      minWidth: 80,
      width: getColumnWidth("symbol"),
    },
    {
      Header: "EXPIRE TIME",
      accessor: "expiredOn",
      minWidth: 100,
      width: getColumnWidth("expiredOn"),
    },
    {
      Header: "STATE",
      accessor: "status",
      minWidth: 50,
      width: getColumnWidth("status"),
    },
    {
      Header: "QUOTE REQ ID",
      accessor: "rfqid",
      minWidth: 100,
      width: getColumnWidth("rfqid"),
    },
    {
      Header: "Client ID",
      accessor: "clientId",
      minWidth: 100,
      width: getColumnWidth("clientId"),
    },
    {
      Header: "ORD QTY",
      accessor: "quantity",
      minWidth: 60,
      width: getColumnWidth("quantity"),
    },
    {
      Header: "ACTIONS",
      maxWidth: 150,
      minWidth: 150,
      sortable: false,
      Cell: (row) => (
        <GridActions>
          <GridActionViewHistory contextMenu={gridCtxMenu}
            onClick={(e, actionContext) => { onQuoteHistory(row.original.rfqid, actionContext); }} />

          <GridAction contextMenu={gridCtxMenu} onClick={(e, actionContext) => onView(row.original.rfqid, actionContext)}>
            <span className="quotes-linkbtn btn-borderless">Quotes</span>
          </GridAction>
        </GridActions>
      ),
    },
  ];
  return (
    <div>
      {loading ? (
        <div className="form-group col-auto align-self-end">
          <LoaderIcon show={loading}></LoaderIcon>
        </div>
      ) : (
        <DataGrid
          data={data.map((rfq) => {
            return {
              createdOn: rfq.createdOn.toString(),
              firm: rfq.firm,
              user: rfq.user,
              submittingUser: rfq.submittingUser,
              submittingFirm: rfq.submittingFirm,
              account: rfq.account,
              side: rfq.side.name === "Undefined" ? "Two-Sided" : rfq.side.name,
              symbol: rfq.symbol,
              expiredOn: rfq.expiredOn.toString(),
              status: rfq.status.name,
              rfqid: rfq.name,
              clientId: rfq.clientId,
              quantity: rfq.quantity,
            };
          })}
          columns={columns}
          filterable={false}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onResizedChange={onColumnResize}
          PaginationComponent={paginationComponent}
          manual={true}
        ></DataGrid>
      )}
    </div>
  );
};

RFQSearchDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onQuoteHistory: PropTypes.func,
};

export default RFQSearchDataGrid;
