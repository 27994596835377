import React, { Component } from "react";
import { connect } from "react-redux";
import { GetUserLicense } from "../../actions/accounts";
import { Container } from "react-bootstrap";
class UserLicense extends Component {
  componentDidMount() {
    this.props.getUserLicense();
  }

  render() {
    const clientIdentifier = this.props.userLicense ? this.props.userLicense.clientIdentifier : "";
    const expirationTime = this.props.userLicense ? this.props.userLicense.expirationTime : "";
    const versionRange = this.props.userLicense ? this.props.userLicense.versionRange : "";
    const userLicenseErr = this.props.userLicenseErr ? this.props.userLicenseErr : "";
    const versions = !!this.props.versions && Array.isArray(this.props.versions) ? this.props.versions : [];

    return (
      <div>
        <Container>
          <div style={{ fontWeight: "normal", lineHeight: "20px" }}>
            {!userLicenseErr && <>
              <p>{clientIdentifier}</p>
              <p>Version: {versionRange}</p>
              <p>Expires On: {expirationTime}</p>
              {versions.map(elem => {
                return (<p>{elem}</p>)
              })}
            </>}

            {userLicenseErr && <>
              <p>License Error:</p>
              <p style={{ whiteSpace: "break-spaces", display: "block" }}>{userLicenseErr?.indexOf("license has expired") >= 0 ? "EP3 License has Expired." : userLicenseErr }</p>
            </>}
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLicense: state.accounts.userLicense,
    userLicenseErr: state.accounts.userLicenseErr,
    versions: state.jobs.versions,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getUserLicense: () => {
    dispatch(GetUserLicense());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLicense)