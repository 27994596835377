import React, { useEffect, useState } from "react";
import { Col, FormGroup, FormLabel, Row, Form } from "react-bootstrap";
import DataGrid from "../core/data-grid/DataGrid";
import { tsProtoObjToDate } from "../../actions/ptypes";
import { convertDateToString, isArrayWithValues, isNullOrUndefined } from "../../modules/util";
import AutoComplete from "../core/auto-complete/AutoComplete";
import { fetchMetadata } from "../../actions/instruments";
import { connect } from "react-redux";
import DateGroup from "../core/form/DateGroup";
import FieldGroup from "../core/form/FieldGroup";
import "./AccountLedger.css"
import ProtobufParser from "../../modules/protobufParser";
import Notification from "../../modules/notifications";
import PaginationWithPageSize, { withPageToken } from "../../components/core/data-grid/PaginationWithPageSize";
import { fetchBalanceLedger, downloadBalanceLedger } from "../../actions/accounts";
import ButtonSecondary from "../core/form/ButtonSecondary";
import GridHeaderLabel from "../core/form/GridHeaderLabel";

const { GetAccountBalanceLedgerRequest } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb");

const STORAGE_PAGE_SIZE_KEY = "positionLedgerGridPageSize";
const DEFAULT_PAGE_SIZE = 10;

function AccountLedger(props) {

  const [ledgerData, setLedgerData] = useState();
  const [currency, setCurrency] = useState("");
  const [transactionTimeFrom, setTransactionTimeFrom] = useState();
  const [transactionTimeTo, setTransactionTimeTo] = useState();
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [pageToken, setPageToken] = useState("");
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { metadata, account } = props;

  useEffect(() => {
    setPageSize(getPageSizeFromStorage() || DEFAULT_PAGE_SIZE);
    props.fetchMetadata();

    let fromDt = new Date();
    fromDt.setHours(0, 0, 0);
    setTransactionTimeFrom(fromDt);

    let toDt = new Date();
    toDt.setHours(23, 59, 59);
    setTransactionTimeTo(toDt);

    setErrors({});
  }, []);

  useEffect(() => {
    if (!!currency && currency.trim().length === 3) {
      getAccountBalanceLedger((ledgerData) => { });
    }
  }, [pageToken])

  useEffect(() => {
    let ledgerData = [];
    if (!!props.balanceLedger && isArrayWithValues(props.balanceLedger.ledgerList)) {
      props.balanceLedger.ledgerList.forEach(rec => {
        let ledgerRec = prepareLedgerRecord(rec);
        ledgerData.push(ledgerRec);
      });
    }
    setLedgerData(ledgerData)
  }, [props.balanceLedger])

  const filterDate = () => {
    const error = validateFilter();
    if (!!error.currency || !!error.transactionTimeFrom || !!error.transactionTimeTo) {
      return;
    }

    if (!!currency && currency.trim().length === 3) {
      getAccountBalanceLedger((ledgerData) => { });
    }
  }

  const convertDtToStr = (value) => {
    return convertDateToString(value, "YYYYMMDD-HH:mm:ss.SSS");
  }

  const prepareAccountBalanceLedgerRequest = () => {
    const timeFrom = transactionTimeFrom ? ProtobufParser.toTimestamp(transactionTimeFrom) : null;
    const timeTo = transactionTimeTo ? ProtobufParser.toTimestamp(transactionTimeTo) : null;

    let req = new GetAccountBalanceLedgerRequest();
    req.setName(account.name);
    req.setNewestFirst(true);

    if (!!currency) req.setCurrency(currency);
    if (!!description) req.setDescription("/"+description+"/i");
    if (!!timeFrom) req.setStartTime(timeFrom);
    if (!!timeTo) req.setEndTime(timeTo);

    if (!!pageToken) req.setPageToken(pageToken);
    if (!!pageSize) req.setPageSize(pageSize);

    return req;
  }

  const getAccountBalanceLedger = (cb) => {
    let req = prepareAccountBalanceLedgerRequest();
    props.fetchBalanceLedger(req, (err, resp) => {
      if (!!err) {
        Notification.error(err.message);
        return;
      }
    });
  }

  const downloadBalanceLedger = () => {
    let req = prepareAccountBalanceLedgerRequest();
    req.setPageSize(0)
    props.downloadBalanceLedger(req)
  }

  const prepareLedgerRecord = (recordData) => {
    let ledgerRecord = {};

    if (!!recordData.beforeBalance) {
      ledgerRecord.beforeBalance = {
        balance: recordData.beforeBalance.balance,
        capitalRequirement: recordData.beforeBalance.capitalRequirement,
        alternateCapitalRequirement: recordData.beforeBalance.alternateCapitalRequirement,
        alternateCapitalRequirementCurrency: recordData.beforeBalance.alternateCapitalRequirementCurrency,
        excessCapital: recordData.beforeBalance.excessCapital,
        buyingPower: recordData.beforeBalance.buyingPower,
        totalSecurityNotionalValue: recordData.beforeBalance.totalSecurityNotionalValue,
        totalSecurityAvailableValue: recordData.beforeBalance.totalSecurityAvailableValue,
        totalPendingCreditValue: recordData.beforeBalance.totalPendingCreditValue,
        security: prepareSecurityRecord(recordData.beforeBalance, recordData.modifiedSecurityId),
      };
    }

    if (!!recordData.afterBalance) {
      ledgerRecord.afterBalance = {
        balance: recordData.afterBalance.balance,
        capitalRequirement: recordData.afterBalance.capitalRequirement,
        alternateCapitalRequirement: recordData.afterBalance.alternateCapitalRequirement,
        alternateCapitalRequirementCurrency: recordData.afterBalance.alternateCapitalRequirementCurrency,
        excessCapital: recordData.afterBalance.excessCapital,
        buyingPower: recordData.afterBalance.buyingPower,
        totalSecurityNotionalValue: recordData.afterBalance.totalSecurityNotionalValue,
        totalSecurityAvailableValue: recordData.afterBalance.totalSecurityAvailableValue,
        totalPendingCreditValue: recordData.afterBalance.totalPendingCreditValue,
        security: prepareSecurityRecord(recordData.afterBalance, recordData.modifiedSecurityId),
      };
    }

    ledgerRecord.description = recordData.description;
    ledgerRecord.updateTime = !!recordData.updateTime ? convertDtToStr(tsProtoObjToDate(recordData.updateTime)) : "";
    ledgerRecord.modifiedSecurityId = recordData.modifiedSecurityId;

    return ledgerRecord;
  }

  const prepareSecurityRecord = (recordData, modifiedSecurityId) => {
    let securityData = { availableValue: "-", balance: "-", haircut: "-", marketValue: "-", notionalValue: "-" };

    if (!!recordData.securitiesMap) {
      const secData = recordData.securitiesMap.find(sec => sec[0] === modifiedSecurityId);

      if (!!secData) {
        securityData = {
          availableValue: secData[1].availableValue,
          balance: secData[1].balance,
          haircut: secData[1].haircut,
          marketValue: secData[1].marketValue,
          notionalValue: secData[1].notionalValue,
        }
      }
    }

    return securityData;
  }

  const validateFilter = () => {
    const errors = { currency: "", transactionTimeFrom: "", transactionTimeTo: "" };
    if (currency.trim().length !== 3) {
      errors.currency = "Required";
    }

    if (transactionTimeFrom > transactionTimeTo) {
      errors.transactionTimeTo = "Transaction Time To should be greater than Transaction Time From";
    }

    setErrors(errors);

    return errors;
  };

  const setPageSizeInStorage = (pageSize) => {
    window.localStorage.setItem(STORAGE_PAGE_SIZE_KEY, pageSize)
    setPageSize(pageSize);
  }

  const getPageSizeFromStorage = () => {
    const pageSize = window.localStorage.getItem(STORAGE_PAGE_SIZE_KEY)

    if (pageSize)
      return Number(pageSize);

    return Number(DEFAULT_PAGE_SIZE);
  }

  const onPageChange = (pageToken) => {
    setPageToken(pageToken);
  }

  const onPageSizeChange = (pageSize) => {
    setPageSizeInStorage(pageSize);
    setPageToken("");
  }

  const getPaginationComponent = () => {
    const pageToken = props.pageToken;
    const tokens = props.pageTokens;
    const pageSize = getPageSizeFromStorage();
    return withPageToken(PaginationWithPageSize, { pageToken, tokens }, pageSize);
  }


  const columns = [
    {
      Header: "TRANSACTION TIME",
      accessor: "updateTime",
      width: 160,
      sortable: false
    },
    {
      id: "colDesc",
      Header: "DESCRIPTION",
      accessor: (d) => (<span title={d.description}>{d.description}</span>),
      width: 500,
      sortable: false
    },
    {
      Header: "FIAT BALANCE",
      columns: [{
        Header: "BEFORE",
        accessor: "beforeBalance.balance",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterBalance.balance",
        width: 100,
        sortable: false
      }]
    },
    {
      Header: <span style={{ "color": "inherit" }} title="TOTAL SECURITY NOTIONAL VALUE">TOTAL SECURITY NOTIONAL VALUE</span>,
      columns: [{
        Header: "BEFORE",
        accessor: "beforeBalance.totalSecurityNotionalValue",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterBalance.totalSecurityNotionalValue",
        width: 100,
        sortable: false
      }]
    },
    {
      Header: <span style={{ "color": "inherit" }} title="TOTAL SECURITY AVAILABLE VALUE">TOTAL SECURITY AVAILABLE VALUE</span>,
      columns: [{
        Header: "BEFORE",
        accessor: "beforeBalance.totalSecurityAvailableValue",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterBalance.totalSecurityAvailableValue",
        width: 100,
        sortable: false
      }]
    },
    {
      Header: <span style={{ "color": "inherit" }} title="TOTAL PENDING CREDIT VALUE">TOTAL PENDING CREDIT VALUE</span>,
      columns: [{
        Header: "BEFORE",
        accessor: "beforeBalance.totalPendingCreditValue",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterBalance.totalPendingCreditValue",
        width: 100,
        sortable: false
      }]
    },
    {
      Header: "CAPITAL REQUIREMENT",
      columns: [{
        Header: "BEFORE",
        id: 'beforeCapitalRequirement',
        accessor: d => (!!d.beforeBalance ? d.beforeBalance.capitalRequirement: "") + (!!d.beforeBalance && !!d.beforeBalance.alternateCapitalRequirementCurrency ? " (" + d.beforeBalance.alternateCapitalRequirement + " " + d.beforeBalance.alternateCapitalRequirementCurrency + ")":""),
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        id: 'afterCapitalRequirement',
        accessor: d => (!!d.afterBalance ? d.afterBalance.capitalRequirement: "") + (!!d.afterBalance && !!d.afterBalance.alternateCapitalRequirementCurrency ? " (" + d.afterBalance.alternateCapitalRequirement + " " + d.afterBalance.alternateCapitalRequirementCurrency + ")":""),
        width: 100,
        sortable: false
      }]
    },
    {
      Header: "EXCESS CAPITAL",
      columns: [{
        Header: "BEFORE",
        accessor: "beforeBalance.excessCapital",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterBalance.excessCapital",
        width: 100,
        sortable: false
      }]
    },
    {
      Header: <GridHeaderLabel id="lblModifiedSecurityId"
        tooltip={"If this ledger entry was created due to the modification of a security, this is the identifier of that security. Otherwise, this will be blank."}
        label={"SECURITY IDENTIFIER"} />,
      accessor: "modifiedSecurityId",
      width: 200,
      sortable: false
    }, {
      Header: "SECURITY BALANCE",
      columns: [{
        Header: "BEFORE",
        accessor: "beforeBalance.security.balance",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterBalance.security.balance",
        width: 100,
        sortable: false
      }]
    }, {
      Header: "SECURITY MARKET VALUE",
      columns: [{
        Header: "BEFORE",
        accessor: "beforeBalance.security.marketValue",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterBalance.security.marketValue",
        width: 100,
        sortable: false
      }]
    }, {
      Header: "SECURITY HAIRCUT",
      columns: [{
        Header: "BEFORE",
        accessor: "beforeBalance.security.haircut",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterBalance.security.haircut",
        width: 100,
        sortable: false
      }]
    }, {
      Header: "SECURITY NOTIONAL VALUE",
      columns: [{
        Header: "BEFORE",
        accessor: "beforeBalance.security.notionalValue",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterBalance.security.notionalValue",
        width: 100,
        sortable: false
      }]
    }, {
      Header: "SECURITY AVAILABLE VALUE",
      columns: [{
        Header: "BEFORE",
        accessor: "beforeBalance.security.availableValue",
        width: 100,
        sortable: false
      },
      {
        Header: "AFTER",
        accessor: "afterBalance.security.availableValue",
        width: 100,
        sortable: false
      }]
    },
  ]

  return (
    <>
      <Form.Row>
        <Col lg={2} xs={2} md={2}>
          <FormGroup>
            <FormLabel>Currency</FormLabel>
            <label className="form-label-required"> Required</label>
            <AutoComplete
              id="currency"
              placeholder="Enter Currency"
              noItemsMessage="No results found"
              dataSource={!isNullOrUndefined(metadata) && !isNullOrUndefined(metadata.currencies) ? Object.keys(metadata.currencies) : []}
              defaultValue={currency}
              disable={false}
              onChange={(e) => setCurrency(e.target.value)}
              onEnter={(value) => setCurrency(value)}
              onSelect={(value) => setCurrency(value)}
              errors={errors}
            />
          </FormGroup>
        </Col>
        <Col lg={2} xs={2} md={2}>
          <DateGroup id="transactionTimeFrom" name="transactionTimeFrom" label="Transaction Time From"
            value={transactionTimeFrom ? new Date(transactionTimeFrom) : null}
            onChange={(e) => setTransactionTimeFrom(e)}
            enableTime={true}
            errors={errors}
          />
        </Col>
        <Col lg={2} xs={2} md={2}>
          <DateGroup id="transactionTimeTo" name="transactionTimeTo" label="Transaction Time To"
            value={transactionTimeTo ? new Date(transactionTimeTo) : null}
            onChange={(e) => setTransactionTimeTo(e)}
            enableTime={true}
            errors={errors}
          />
        </Col>
        <Col lg={4} xs={4} md={4}>
          <FieldGroup id="description" name="description" type="textarea" label="Description"
            value={description ? description : ""}
            onChange={(e) => { setDescription(e.target.value) }}
            onBlur={(e) => { setDescription(e.target.value) }}
          />
        </Col>
        <Col lg={2} xs={2} md={2} style={{ paddingTop: "33px" }}>
          <div>
            <button className="Filter-Button" onClick={filterDate}>Filter</button>
            <ButtonSecondary text="Export Data" type="button" style={{ float: "right" }} onClick={() => {downloadBalanceLedger()}}/>
          </div>
        </Col>
      </Form.Row>
      <Row>
        <Col>
          <div id="accountLedgerGrid">
            <DataGrid
              data={ledgerData}
              columns={columns}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              PaginationComponent={getPaginationComponent()}
              manual={true}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps(state) {
  return {
    account: state.accounts.account,
    metadata: state.instruments.metadata,
    pageToken: state.accounts.balanceLedger.pageToken,
    nextPageToken: state.accounts.balanceLedger.nextPageToken,
    pageTokens: state.accounts.balanceLedger.pageTokens,
    balanceLedger: state.accounts.balanceLedger,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchMetadata: () => {
    dispatch(fetchMetadata());
  },
  fetchBalanceLedger: (req, cb) => {
    dispatch(fetchBalanceLedger(req, cb));
  },
  downloadBalanceLedger: (req) => {
    dispatch(downloadBalanceLedger(req));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountLedger);
