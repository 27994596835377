import { OrderType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";

export default class UnaggregatedBookGridItem {
  constructor(item) {
    if (item) {
      this.buyorderid = item.buyorderid;
      this.buyOrdType = item.buyOrdType;
      this.sellorderid = item.sellorderid;
      this.sellOrdType = item.sellOrdType;
      this.buyer = item.buyer;
      this.qtyToBuy = item.qtyToBuy;
      this.priceToBuy = item.priceToBuy;
      this.stopBuyPrice = item.stopBuyPrice;
      this.priceToSell = item.priceToSell;
      this.stopSellPrice = item.stopSellPrice;
      this.qtyToSell = item.qtyToSell;
      this.seller = item.seller;
      this.buyAccount = item.buyAccount.split("/").pop();
      this.sellAccount = item.sellAccount.split("/").pop();
      this.buyUser = item.buyer.split("/").pop();
      this.sellUser = item.seller.split("/").pop();
      return;
    }
    this.buyorderid = null;
    this.buyOrdType = OrderType.ORDER_TYPE_UNDEFINED;
    this.sellorderid = null;
    this.sellOrdType = OrderType.ORDER_TYPE_UNDEFINED;
    this.buyer = null;
    this.qtyToBuy = null;
    this.priceToBuy = null;
    this.stopBuyPrice = null;
    this.priceToSell = null;
    this.stopSellPrice = null;
    this.qtyToSell = null;
    this.seller = null;
    this.buyAccount = null;
    this.sellAccount = null;
    this.buyUser = null;
    this.sellUser = null;
  }
}