import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";

import 'react-table/react-table.css';
import './DataGrid.css';
import { hasWriteAccess } from "../../../services/TokenService";
import { isNullOrUndefined } from '../../../modules/util';

/*
 * This method tries to expand the DataGrid to fill the available free height of the page.
 * Its not very accurate but works. Uses old school HTML element manipulation.
 */
const autoFillGridHeight = () => {
  // The Width of the DataGrid seems to have been calculated by the library dynamically.
  // If DataGrid height is specified that may cause a vertical scrollbar, the internal
  // width mechanism doesnt account for it and displays a horizontal scrollbar to
  // accomodate the 15 odd pixels that scrollbar took. So if height has to be specified,
  // make room for vertical scrollbar by adding few pixels to the internally calculated width
  const element = document.querySelector(".ReactTable .rt-table .rt-tbody");
  if (element) {
    const minWidth = parseInt(element.style['min-width']);
    element.style['min-width'] = (minWidth + 20) + "px";
  }

};

class DataGrid extends Component {
  getTrProps = (state, rowInfo) => {
    const viewIdx = rowInfo ? rowInfo.viewIndex : 0;
    return {
      style: {
        background: viewIdx % 2 ? '#FFFFFF' : 'var(--APP_COLOR_TABLE_ALTROW)'
      },
      className: "tableRow"
    };
  };

  getTdProps = (state, rowInfo) => {
    return {
      style: {}
    };
  };

  componentDidMount() {
    if (this.props.autoFillHeight) {
      autoFillGridHeight();
    }
  }

  toggleSelection = (key, shift, row) => {
    let selection = this.state && this.state.selection ? this.state.selection : new Set();
    const selectAll = false;
    const val = row[this.props.keyField];

    if (selection.has(val)) {
      selection.delete(val)
    } else {
      selection.add(val);
    }
    // update the state
    this.setState({ selection, selectAll });
  }

  toggleAll = () => {
    const selectAll = this.state ? !this.state.selectAll : true;
    const selection = new Set();

    if (selectAll) {
      // Get all current records and select each one by ID
      const currentRecords = this.table.getWrappedInstance().getResolvedState().sortedData;
      currentRecords.forEach(item => selection.add(item._original[this.props.keyField]));
    }
    this.setState({ selection, selectAll });
  }

  isSelected = (key) => {
    return this.state && this.state.selection ? this.state.selection.has(key) : false;
  }

  getStoragePageSizeKey = () => {
    return "datagridPageSizes_" + window.location.href;
  }

  onPageSizeChanged = (pageSize) => {
    window.localStorage.setItem(this.getStoragePageSizeKey(), pageSize)

    if (this.props.onPageSizeChange)
      this.props.onPageSizeChange(pageSize)
  }

  getPageSize = () => {
    let pageSize = window.localStorage.getItem(this.getStoragePageSizeKey())
    if (!isNullOrUndefined(pageSize)) {
      return Number(pageSize);
    }
    return DataGrid.defaultProps.defaultPageSize;
  }

  filterData = (filter, row, column) => {
    const rowData = row[filter.id];

    if (rowData && filter.value) {
      return rowData.toLowerCase().includes(filter.value.toLowerCase());
    }

    return false;
  }

  render() {
    const TheTable = this.props.selectable && hasWriteAccess() ? selectTableHOC(ReactTable) : ReactTable;
    return (
      <TheTable
        ref={r => (this.table = r)}
        data={this.props.data}
        filterable={this.props.filterable}
        minRows={0}
        columns={this.props.columns}
        showPagination={this.props.showPagination}
        keyField={this.props.keyField}
        toggleSelection={this.toggleSelection}
        selectAll={this.state ? this.state.selectAll : false}
        toggleAll={this.toggleAll}
        selectType={"checkbox"}
        isSelected={this.isSelected}
        defaultFilterMethod={(filter, row, column) => {
          return this.filterData(filter, row, column)
        }}
        defaultPageSize={this.getPageSize()}
        defaultSorted={[
          {
            id: `${this.props.defaultSortedField}`,
            desc: this.props.defaultSortedDesc
          }
        ]}
        getTrProps={this.props.getTrProps ? this.props.getTrProps : this.getTrProps}
        getTdProps={this.props.getTdProps ? this.props.getTdProps : this.getTdProps}
        manual={this.props.manual}
        showPageJump={this.props.showPageJump}
        onPageChange={this.props.onPageChange}
        onPageSizeChange={this.onPageSizeChanged}
        onResizedChange={this.props.onResizedChange}
        PaginationComponent={this.props.PaginationComponent}
      />
    )
  }
}

DataGrid.defaultProps = {
  selectable: false,
  showPagination: true,
  defaultSortedDesc: false,
  defaultSortedField: 'id',
  defaultPageSize: 10,
  keyField: 'id',
  manual: false,
  showPageSizeOptions: true,
  showPageJump: true,
  autoFillHeight: false
}

DataGrid.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  filterable: PropTypes.bool.isRequired,
  selectable: PropTypes.bool.isRequired,
  showPagination: PropTypes.bool.isRequired,
  defaultSortedDesc: PropTypes.bool,
  defaultSortedField: PropTypes.string,
  keyField: PropTypes.string,
  defaultPageSize: PropTypes.number,
  onRowClick: PropTypes.func,
  getTrProps: PropTypes.func,
  getTdProps: PropTypes.func,
  manual: PropTypes.bool,
  onFetchData: PropTypes.func,
  autoFillHeight: PropTypes.bool
}

export default DataGrid;
