import * as React from "react";
import "./DurationControl.css";
import { DurationControlUnitInput } from "./DurationControlUnitInput";
import { Spinner } from "./Spinner";

export default class DurationControl extends React.Component {
 
    constructor(props) {
        super(props);
        const { pattern, value } = this.props;
        const elements = DurationControl.createElements(props.pattern);
 
        DurationControl.spreadMillisAcrossUnitElements(value, elements);

        const lastFocusedInputUnitType = 'millisecond';

        this.state = {
            pattern,
            elements,
            milliseconds: value,
            lastFocusedInputUnitType,
            focused: false
        };
    }

    get controlClassName() {
        const classes = ["react-duration-control"];
        if (this.props.className) {
            classes.push(this.props.className);
        }
        if (this.props.disabled) {
            classes.push("disabled");
        }
        if (this.state.focused) {
            classes.push("focused");
        }
        return classes.join(" ");
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value === prevState.milliseconds &&
            nextProps.pattern === prevState.pattern) {
            return null;
        }

        const elements = DurationControl.createElements(nextProps.pattern);

        DurationControl.spreadMillisAcrossUnitElements(nextProps.value, elements);

        return {
            pattern: nextProps.pattern,
            elements,
            milliseconds: nextProps.value,
            lastFocusedInputUnitType: 'millisecond'
        };
    }

    render() {
        return (<div className={this.controlClassName}>
            <fieldset className="react-duration-control-fieldset">
                {this.props.label && <legend className="react-duration-control-label">{this.props.label}</legend>}
                <div className="react-duration-control-elements-container">
                    {this.state.elements.map((element, index) => 
                         typeof element === "string" ? (<span className="react-duration-control-inline-text">{element}</span>) : (
                        <DurationControlUnitInput
                          key={element.type}
                          type={element.type}
                          characterLength={element.characters}
                          value={element.value}
                          onChange={(value) => this.updateUnitValue(element.type, value)}
                          onUpArrowKeyPress={() => this.incrementOrDecrementUnitValue(element.type, true)}
                          onDownArrowKeyPress={() => this.incrementOrDecrementUnitValue(element.type, false)}
                          onFocus={() => this.onUnitInputFocus(element.type)}
                          onBlur={() => this.onUnitInputBlur()} disabled={this.props.disabled} />
                        ))}
                </div>
                {this.props.hideSpinner ? (<></>) : (<Spinner onUpButtonPress={() => this.incrementOrDecrementUnitValue(this.state.lastFocusedInputUnitType, true)} onDownButtonPress={() => this.incrementOrDecrementUnitValue(this.state.lastFocusedInputUnitType, false)} disabled={this.props.disabled} />)}
            </fieldset>
        </div>);
    }

    updateUnitValue = (unitType, value) => {
        const elements = this.state.elements.slice();

        const unitElement = elements.find((element) => typeof element !== "string" && element.type === unitType);

        const clampedValue = this.validateValue(value, 0, unitElement.max);
        if (unitElement.value === clampedValue) {
            return;
        }

        const previousUnitValueMillis = unitElement.value === null
            ? 0
            : unitElement.value *
                DurationControl.UNIT_MILLISECOND_MULTIPLIERS[unitType];
        const newUnitValueMillis = clampedValue === null
            ? 0
            : clampedValue *
                DurationControl.UNIT_MILLISECOND_MULTIPLIERS[unitType];

        const unitValueMillisDifference = newUnitValueMillis - previousUnitValueMillis;
        const updatedMilliseconds = this.state.milliseconds + unitValueMillisDifference;

        unitElement.value = clampedValue;
        this.setState({ elements, milliseconds: updatedMilliseconds });

        if (unitValueMillisDifference) {
            this.props.onChange(updatedMilliseconds);
        }
    }

    onUnitInputFocus = (type) => {
        this.setState({
            lastFocusedInputUnitType: type,
            focused: true
        });
    }

    onUnitInputBlur = () => {
        this.setState({ focused: false });
        this.props.onBlur();
    }

    validateValue = (value, min, max) => {
        if (value === null) {
            return null;
        }
        else if (value > max) {
            return max;
        }
        else if (value < min) {
            return min;
        }
        else {
            return value;
        }
    }

   static createElements(pattern) {
        const patternRegex = /(\{d+\}|\{h+\}|\{m+\}|\{s+\}|\{f+\})/g;
        const dayUnitRegex = /^{(d+)}$/g;
        const hourUnitRegex = /^{(h+)}$/g;
        const minuteUnitRegex = /^{(m+)}$/g;
        const secondUnitRegex = /^{(s+)}$/g;
        const millisecondUnitRegex = /^{(f+)}$/g;

        const elements = pattern
            .split(patternRegex)
            .reduce((previous, current) => {
            if (!current) {
                return previous;
            }

            const characters = current.length - 2;
            if (current.match(dayUnitRegex)) {
                return [
                    ...previous,
                    {
                        type: "day",
                        characters,
                        step: 1,
                        max: 99,
                        value: 0
                    }
                ];
            }
            else if (current.match(hourUnitRegex)) {
                return [
                    ...previous,
                    {
                        type: "hour",
                        characters,
                        step: 1,
                        max: 99,
                        value: 0
                    }
                ];
            }
            else if (current.match(minuteUnitRegex)) {
                return [
                    ...previous,
                    {
                        type: "minute",
                        characters,
                        step: 1,
                        max: 99,
                        value: 0
                    }
                ];
            }
            else if (current.match(secondUnitRegex)) {
                return [
                    ...previous,
                    {
                        type: "second",
                        characters,
                        step: 1,
                        max: 99,
                        value: 0
                    }
                ];
            }
            else if (current.match(millisecondUnitRegex)) {
                return [
                    ...previous,
                    {
                        type: "millisecond",
                        characters,
                        step: 1,
                        max: 999,
                        value: 0
                    }
                ];
            }
            return [...previous, current];
        }, []);

        return elements;
    }

    incrementOrDecrementUnitValue = (unitType, isIncrement) => {
        if (!unitType) {
            return;
        }

        const unitElement = this.state.elements.find((element) => typeof element !== "string" && element.type === unitType);
        let updatedUnitValue;
        if (isIncrement) {
            updatedUnitValue = (unitElement.value || 0) + unitElement.step;
        }
        else {
            updatedUnitValue = (unitElement.value || 0) - unitElement.step;
        }
        this.updateUnitValue(unitType, updatedUnitValue);
    }

    static spreadMillisAcrossUnitElements(millis, elements) {
        const getUnitElement = (type) => elements.find((element) => typeof element !== "string" && element.type === type);
        const unitTypeArray = [
            "day",
            "hour",
            "minute",
            "second",
            "millisecond"
        ];
        let remainingMillis = millis;
        unitTypeArray.forEach((unitType) => {
            const unitElement = getUnitElement(unitType);
            if (!unitElement) {
                return;
            }
            const unitValue = remainingMillis /
                DurationControl.UNIT_MILLISECOND_MULTIPLIERS[unitType];
            if (unitValue >= 1) {
                let truncatedUnitValue = Math.trunc(unitValue);
                truncatedUnitValue = Math.min(truncatedUnitValue, unitElement.max);
                unitElement.value = truncatedUnitValue;
                remainingMillis -=
                    truncatedUnitValue *
                        DurationControl.UNIT_MILLISECOND_MULTIPLIERS[unitType];
            }
        });
    }
}

DurationControl.UNIT_MILLISECOND_MULTIPLIERS = {
    day: 86400000,
    hour: 3600000,
    minute: 60000,
    second: 1000,
    millisecond: 1
};
