import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { isNullOrUndefined } from "../../modules/util";
import { Link } from "react-router-dom";
import { TitleBreadcrumb } from "../core/title-breadcrumb/title-breadcrumb";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb";
import {Env} from "../../constants/environment";

const AccountHeader = ({
  areTabsVisible,
  selectTab,
  selectedTab,
  account,
  firms,
  firm
}) => {
  const linkClass = "link-with-router link-tab";
  const linkClassL2 = "link-with-router link-tab-l2";
  const selectedLinkClass = `${linkClass} link-tab-selected`;
  const title = "Accounts";
  const subTitle = !!account ? account.displayName : "";
  const shouldHideNonTradable = !firm || !firm.firmType || !(firm.firmType.id === FirmType.FIRM_TYPE_CLEARING_MEMBER || firm.firmType.id === FirmType.FIRM_TYPE_PARTICIPANT);

  let shouldHideBalances = Env.getEnv("REACT_APP_HIDE_BALANCES");
  if (!!shouldHideBalances && !isNullOrUndefined(shouldHideBalances)) {
    shouldHideBalances = shouldHideBalances.toString().toLowerCase() === "true" || shouldHideBalances === 1;
  } else {
    shouldHideBalances = false;
  }

  let shouldHidePositions = Env.getEnv("REACT_APP_HIDE_POSITIONS");
  if (!!shouldHidePositions && !isNullOrUndefined(shouldHidePositions)) {
    shouldHidePositions = shouldHidePositions.toString().toLowerCase() === "true" || shouldHidePositions === 1;
  } else {
    shouldHidePositions = false;
  }

  const showLedger = !shouldHidePositions || !shouldHideBalances;

  return (
    <div>
      <div>
        <TitleBreadcrumb titles={[{'title':title, link:`${window.location.origin}/accounts`}, !!subTitle ? {'title':subTitle}: null]} />
      </div>

      <Row className="page-header">
        <Col lg={10} xs={10} md={10}>
          {areTabsVisible ? (
            <div>
              <h2 className={selectedTab === "profile" ? selectedLinkClass : linkClass}
                onClick={() => selectTab("profile")}><Link to={`/firms/${firm.id}/accounts/${account.id}`} className="tabLink">Account Profile</Link></h2>

              {!shouldHideNonTradable && <h2 className={selectedTab === "users" ? selectedLinkClass : linkClass}
                onClick={() => selectTab("users")}><Link to={`/firms/${firm.id}/accounts/${account.id}/users`} className="tabLink">Users</Link></h2>}

              {!shouldHidePositions && <h2 className={selectedTab === "positions" ? selectedLinkClass : linkClass}
                onClick={() => selectTab("positions")}><Link to={`/firms/${firm.id}/accounts/${account.id}/positions`} className="tabLink">Positions</Link></h2>}

              {!shouldHideBalances && <h2 className={selectedTab === "balances" ? selectedLinkClass : linkClass}
                onClick={() => selectTab("balances")}><Link to={`/firms/${firm.id}/accounts/${account.id}/balances`} className="tabLink">Balances</Link></h2>}

              {!!showLedger && <h2 className={selectedTab === "ledger/balance-ledger" || selectedTab === "ledger/position-ledger" ? selectedLinkClass : linkClass}
                onClick={() => selectTab(selectedTab === "ledger/position-ledger" || shouldHideBalances ? "ledger/position-ledger" : "ledger/balance-ledger")}><Link to={`/firms/${firm.id}/accounts/${account.id}/${selectedTab === "ledger/position-ledger"|| shouldHideBalances ? "ledger/position-ledger" : "ledger/balance-ledger"}`} className="tabLink">Ledger</Link></h2>}

              {!shouldHideNonTradable && <h2 className={selectedTab === "whitelist" ? selectedLinkClass : linkClass}
                onClick={() => selectTab("whitelist")}><Link to={`/firms/${firm.id}/accounts/${account.id}/whitelist`} className="tabLink">Whitelist</Link></h2>}

              <h2 className={selectedTab === "commissions" ? selectedLinkClass : linkClass}
                onClick={() => selectTab("commissions")}><Link to={`/firms/${firm.id}/accounts/${account.id}/commissions`} className="tabLink">Commissions</Link></h2>

              <h2 className={selectedTab === "association" ? selectedLinkClass : linkClass}
                onClick={() => selectTab("association")}><Link to={`/firms/${firm.id}/accounts/${account.id}/association`} className="tabLink">Account Associations</Link></h2>

              <h2 className={selectedTab === "bankDetails" ? selectedLinkClass : linkClass}
                onClick={() => selectTab("bankDetails")}><Link to={`/firms/${firm.id}/accounts/${account.id}/bankDetails`} className="tabLink">Bank Accounts</Link></h2>
            </div>
          ) : null}
        </Col>
      </Row>
      {(selectedTab === "ledger/balance-ledger" || selectedTab === "ledger/position-ledger") &&
        <Row className="page-header">
          <Col lg={9} xs={9} md={9}>
            {areTabsVisible ? (
              <div>
                {!shouldHideBalances && <h2 className={selectedTab === "ledger/balance-ledger" ? selectedLinkClass : linkClassL2}
                  onClick={() => selectTab("ledger/balance-ledger")} style={{ fontSize: "17px" }}><Link to={`/firms/${firm.id}/accounts/${account.id}/ledger/balance-ledger`} className="tabLink">Balance Ledger</Link></h2>}

                {!shouldHidePositions && <h2 className={selectedTab === "ledger/position-ledger" ? selectedLinkClass : linkClassL2}
                  onClick={() => selectTab("ledger/position-ledger")} style={{ fontSize: "17px" }}><Link to={`/firms/${firm.id}/accounts/${account.id}/ledger/position-ledger`} className="tabLink">Position Ledger</Link></h2>}
              </div>
            ) : null}
          </Col>
        </Row>
      }
    </div>
  );
};

AccountHeader.defaultProps = {
  isAPISelected: false,
  isAdminSelected: false,
};

AccountHeader.propTypes = {
  isAPISelected: PropTypes.bool,
  isAdminSelected: PropTypes.bool,
  isAddButtonVisible: PropTypes.isRequired,
};

export default AccountHeader;
