export default class ParticipantFormItem {
  constructor(item) {
    if (item) {
      this.id = item.id;
      this.groups = item.groups;
      this.state = item.state;
      this.isSuspended = item.isSuspended;
      this.role = item.role;
      this.firm = item.firm;
      this.serviceUser = item.serviceUser;
      this.displayName = item.displayName;
      this.name = item.name;
      this.attributes = item.attributes;
      this.aliases = item.aliases
      return;
    }
    this.id = null;
    this.groups = null;
    this.state = null;
    this.isSuspended = null;
    this.role = null;
    this.firm = null;
    this.serviceUser = null;
    this.displayName = null;
    this.name = null;
    this.attributes = null;
    this.aliases = []
  }
}
