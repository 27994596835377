import ApiCall from "../modules/apiCall";
import apiCallPromise from "../modules/apiCallPromise";
import slugify from "slugify";
import { filterType } from "../components/firm/FirmAssociations";
import FirmMapper from "../modules/mappers/firmMapper";
import FirmEntity from "../../src/entities/Firm";
import { apiUrl } from "../modules/util";

const {
  GetFirmRequest,
  ListFirmsRequest,
  ListParticipantsRequest,
  ListUserKeysRequest,
  CreateFirmRequest,
  CreateUserKeyRequest,
  SetUserKeyRoleRequest,
  SetFirmSuspendStateRequest,
  DeleteFirmRequest,
  DeleteUserKeyRequest,
  RevokeUserKeyJtiRequest,
  GetAffiliatedFirmsRequest,
  SetAffiliatedFirmsRequest,
  GetAssociatedRFQFirmsRequest,
  SetAssociatedRFQFirmsRequest,
  GetAssociatedAgentFirmsRequest,
  SetAssociatedAgentFirmsRequest,
  GetAssociatedClearingMemberFirmsRequest,
  SetAssociatedClearingMemberFirmsRequest,
  GetAssociatedClearingHouseFirmsRequest,
  SetAssociatedClearingHouseFirmsRequest,
  UpdateFirmAttributesRequest,
  GetFirmAttributesRequest,
  UndeleteFirmRequest,
  UpdateFirmRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");
const {
  AdminAPIClient,
  AdminAPIPromiseClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");
const {
  Firm,
  UserKey,
  FirmState,
  FirmType,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb.js");
const client = new AdminAPIClient(apiUrl(), null, null);
const promiseClient = new AdminAPIPromiseClient(
  apiUrl(),
  null,
  null
);


export function get(id, cb) {
  getByName(`firms/${id}`, cb);
}

export function getByName(name, cb) {
  var request = new GetFirmRequest();
  request.setName(name);

  ApiCall(client, "getFirm", request, cb);
}

export async function getByName2(name) {
  var request = new GetFirmRequest();
  request.setName(name);

  return await apiCallPromise(promiseClient, "getFirm", request);
}

export function getAll(cb, firmType = null, showDeleted = false) {
  var request = new ListFirmsRequest();
  if (firmType) {
    request.setType(firmType);
  }

  if (showDeleted) {
    request.setShowDeleted(true);
  }
  ApiCall(client, "listFirms", request, cb);
}

export async function listFirmsAsync(firmType = null, showDeleted = false) {
  var request = new ListFirmsRequest();
  if (firmType) {
    request.setType(firmType);
  }

  if (showDeleted) {
    request.setShowDeleted(true);
  }

  return await apiCallPromise(promiseClient, "listFirms", request);
}

export function getServices(id, cb) {
  var request = new ListParticipantsRequest();
  request.setFirm(`firms/${id}`);
  request.setServiceUser(true);

  ApiCall(client, "listParticipants", request, cb);
}

export function getServiceKeys(id, cb) {
  var request = new ListUserKeysRequest();
  request.setUser(id);

  ApiCall(client, "listUserKeys", request, cb);
}

export function postServiceKey(data, cb) {
  var request = new CreateUserKeyRequest();
  var userKey = new UserKey();
  userKey.setName(data.name);
  userKey.setUserId(data.firmServiceId);
  userKey.setRole(data.role.id);
  userKey.setDescription(data.description)
  request.setKey(userKey);

  ApiCall(client, "createUserKey", request, cb);
}

export function updateServiceKey(data, cb) {
  var request = new SetUserKeyRoleRequest();
  request.setUser(data.userId);
  request.setRole(data.role.id);
  request.setName(data.name);
  request.setDescription(data.description);

  ApiCall(client, "setUserKeyRole", request, cb);
}

export function removeServiceKey(userId, name, cb) {
  var request = new DeleteUserKeyRequest();
  request.setUser(userId);
  request.setName(name);

  ApiCall(client, "deleteUserKey", request, cb);
}

export function revokeServiceKey(userId, name, cb) {
  var request = new RevokeUserKeyJtiRequest();
  request.setUser(userId);
  request.setName(name);

  ApiCall(client, "revokeUserKeyJti", request, cb);
}

export function post(data, cb) {
  var request = new CreateFirmRequest();
  var firm = new Firm();
  firm.setId(slugify(data.id));
  firm.setDisplayName(data.id);
  firm.setState(data.state.id);
  firm.setType(data.firmType.id);
  firm.setApplyCommissionsAsSpread(!!data.applyCommissionsAsSpread);
  firm.setSkipCommissionBalanceAdjustment(!!data.skipCommissionBalanceAdjustment);
  request.setFirm(firm);

  ApiCall(client, "createFirm", request, cb);
}

export function postPromise(data) {
  var request = new CreateFirmRequest();
  var firm = new Firm();
  firm.setId(data.firmId ? data.firmId : slugify(data.firmName));
  firm.setDisplayName(data.firmName);
  firm.setState(data.state.id);
  firm.setType(data.firmType.id);
  firm.setApplyCommissionsAsSpread(!!data.applyCommissionsAsSpread);
  firm.setSkipCommissionBalanceAdjustment(!!data.skipCommissionBalanceAdjustment);

  if (!!data.aliases && Array.isArray(data.aliases) && data.aliases.length > 0) {
    firm.clearAliasesMap();

    data.aliases.forEach(alias => {
      firm.getAliasesMap().set(alias.option, alias.alias);
    })
  }

  request.setFirm(firm);

  return apiCallPromise(promiseClient, "createFirm", request);
}

export function getAttributes(name) {
  var request = new GetFirmAttributesRequest();
  request.setParent(name);

  return apiCallPromise(promiseClient, "getFirmAttributes", request);
}

export function updateAttributes(firm, firmAttributes) {
  var request = new UpdateFirmAttributesRequest();

  request.setParent(firm.name);
  request.setAttributes(firmAttributes);

  return apiCallPromise(promiseClient, "updateFirmAttributes", request);
}

export function suspend(data) {
  var request = new SetFirmSuspendStateRequest();
  request.setName(data.name);
  request.setIsSuspended(data.state.id === FirmState.FIRM_STATE_SUSPENDED);

  return apiCallPromise(promiseClient, "setFirmSuspendState", request);
}

export function updateFirm(firm) {
  let _firm = FirmMapper.mapToFirmUpdate(firm);
  let request = new UpdateFirmRequest();
  request.setFirm(_firm);
  return apiCallPromise(promiseClient, "updateFirm", request);
}

export function remove(id, cb) {
  var request = new DeleteFirmRequest();
  request.setName(`firms/${id}`);

  ApiCall(client, "deleteFirm", request, cb);
}

export function getAffiliatedRequest(name, cb) {
  var request = new GetAffiliatedFirmsRequest();
  request.setFirm(`firms/${name}`);

  ApiCall(client, "getAffiliatedFirms", request, cb);
}

export function getAssociatedRFQRequest(name, cb) {
  var request = new GetAssociatedRFQFirmsRequest();
  request.setFirm(`firms/${name}`);

  ApiCall(client, "getAssociatedRFQFirms", request, cb);
}

export function getAssociatedAgentRequest(name, cb) {
  var request = new GetAssociatedAgentFirmsRequest();
  request.setFirm(`firms/${name}`);

  ApiCall(client, "getAssociatedAgentFirms", request, cb);
}

export function getClearingMemberRequest(name, cb) {
  var request = new GetAssociatedClearingMemberFirmsRequest();
  request.setFirm(`firms/${name}`);

  ApiCall(client, "getAssociatedClearingMemberFirms", request, cb);
}

export function getClearingHouseRequest(id, cb) {
  var request = new GetAssociatedClearingHouseFirmsRequest();
  request.setFirm(`firms/${id}`);

  ApiCall(client, "getAssociatedClearingHouseFirms", request, cb);
}

export function setAssociatedFirms(payload, cb) {
  switch (payload.firmType) {
    case FirmType.FIRM_TYPE_CLEARING_MEMBER:
      if (payload.dataType === FirmType.FIRM_TYPE_CLEARING_HOUSE) {
        setClearingHouseRequest(payload, cb);
      } else if (payload.dataType === FirmType.FIRM_TYPE_PARTICIPANT) {
        setClearingMemberRequest(payload, cb);
      }
      break;
    case FirmType.FIRM_TYPE_PARTICIPANT:
      if (payload.filterType === filterType.affiliatedFirms) {
        setAffiliatedRequest(payload, cb);
      } else if (payload.filterType === filterType.associatedRFQ) {
        setAssociatedRFQRequest(payload, cb);
      } else if (payload.filterType === filterType.associatedAgent) {
        setAssociatedAgentRequest(payload, cb);
      } else {
        setClearingMemberRequest(payload, cb);
      }
      break;
    case FirmType.FIRM_TYPE_AGENT:
      setAssociatedAgentRequest(payload, cb);
      break;
    case FirmType.FIRM_TYPE_CLEARING_HOUSE:
      setClearingHouseRequest(payload, cb);
      break;
    default:
      console.error("firmType not supported", payload.firmType);
      break;
  }
}

const setAffiliatedRequest = (payload, cb) => {
  var request = new SetAffiliatedFirmsRequest();

  request.setFirm(`firms/${payload.firmId}`);
  request.setAffiliatedFirmsList(
    payload.associatedFirmsList.map((id) => `firms/${id}`)
  );

  ApiCall(client, "setAffiliatedFirms", request, cb);
};

const setAssociatedRFQRequest = (payload, cb) => {
  var request = new SetAssociatedRFQFirmsRequest();

  request.setFirm(`firms/${payload.firmId}`);
  request.setAssociatedFirmsList(
    payload.associatedFirmsList.map((id) => `firms/${id}`)
  );

  ApiCall(client, "setAssociatedRFQFirms", request, cb);
};

const setAssociatedAgentRequest = (payload, cb) => {
  var request = new SetAssociatedAgentFirmsRequest();

  request.setFirm(`firms/${payload.firmId}`);
  request.setAssociatedFirmsList(
    payload.associatedFirmsList.map((id) => `firms/${id}`)
  );

  ApiCall(client, "setAssociatedAgentFirms", request, cb);
};

const setClearingMemberRequest = (payload, cb) => {
  var request = new SetAssociatedClearingMemberFirmsRequest();

  request.setFirm(`firms/${payload.firmId}`);
  request.setAssociatedFirmsList(
    payload.associatedFirmsList.map((id) => `firms/${id}`)
  );

  ApiCall(client, "setAssociatedClearingMemberFirms", request, cb);
};

const setClearingHouseRequest = (payload, cb) => {
  var request = new SetAssociatedClearingHouseFirmsRequest();

  request.setFirm(`firms/${payload.firmId}`);
  request.setAssociatedFirmsList(
    payload.associatedFirmsList.map((id) => `firms/${id}`)
  );

  ApiCall(client, "setAssociatedClearingHouseFirms", request, cb);
};

export function unDeleteFirm(name) {
  var request = new UndeleteFirmRequest();
  request.setName(`firms/${name}`);

  return apiCallPromise(promiseClient, "undeleteFirm", request);
}

export async function getFirm(state, name) {
  let firm = null;
  let firmName = name;

  if (typeof name === 'object' && typeof name.name === 'string' && !!name.name) {
    firmName = name.name;
  }

  try {
    let firms = state.firms.firms || [];
    firm = firms.find((_firm) => _firm.name === firmName);

    if (!firm) {
      firm = await getByName2(firmName);
      firm = new FirmEntity(firm.getFirm());
    }

  } catch (error) {
    console.error('Unable load details for firm:', { 'Payload': name, 'Error': error });
  }

  return firm;
}
