import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import moment from "moment";
import DropdownListGroup from "../../core/form/DropdownListGroup";
import FieldGroup from "../../core/form/FieldGroup";
import DateGroup from "../../core/form/DateGroup";
import ConfirmDialog from "../../core/confirm-dialog/ConfirmDialog";
import {updateHolidayCalendar} from "../../../actions/instruments";

function mapStateToProps(state) {
    return {
        fetchingHolidayCalendars: state.instruments.fetchingHolidayCalendars,
        holidayCalendars: state.instruments.holidayCalendars,
        holidayCalendarObjects: state.instruments.holidayCalendarObjects,
    };
}

const mapDispatchToProps = (dispatch) => ({
    updateHolidayCalendar: (baseEvent, newCalendar) => {
        dispatch(updateHolidayCalendar(baseEvent, newCalendar));
    },
})

class HolidayCreator extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
    }

    getDefaultState() {
        return {
            title: "",
            start: moment().startOf("day").toDate(),
            end: moment().endOf("day").toDate(),
            calendar: "",
            description: "",
            global: false,
        }
    }

    createEvent(state) {
        let newHolidayCalendar = [];
        this.props.holidayCalendars.forEach(prevEvent => {
            if (state.calendar === prevEvent.calendar) {
                newHolidayCalendar.push(prevEvent);
            }
        });
        newHolidayCalendar.push(state);
        this.props.updateHolidayCalendar(state, newHolidayCalendar);
        this.props.setShow(false);
    }

    render() {
        return <ConfirmDialog
            show={this.props.show}
            proceed={() => this.createEvent(this.state)}
            cancel={() => this.props.setShow(false)}
            options={{
                title: "Create Holiday Event",
                okButtonText: !this.state.calendar || !this.state.title ? "" : "Create",
                cancelButtonText: "Cancel",
            }}
            confirmation={<div>
                <DropdownListGroup
                    id="calendar"
                    name="calendar"
                    label="Holiday Calendar"
                    value={this.state.calendar}
                    onChange={(e) => this.setState({
                        calendar: e,
                        global: this.props.holidayCalendarObjects[e].global,
                        description: this.props.holidayCalendarObjects[e].description,
                    })}
                    data={Object.keys(this.props.holidayCalendarObjects)}
                />
                <FieldGroup
                    id="title"
                    name="title"
                    type="text"
                    label="Holiday Title"
                    value={this.state.title}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    errors={[]}
                    setFieldValue={() => {}}
                />
                <DateGroup id="start" name="start" label="Start Date/Time"
                           value={ this.state.start ? new Date(this.state.start) : null }
                           enabled={true}
                           onChange={(e) => this.setState({
                               start: e,
                               end: moment(e).endOf("day").toDate(),
                           })}
                           enableTime={true}
                />
                <DateGroup id="end" name="end" label="End Date/Time"
                           value={ this.state.end ? new Date(this.state.end) : null }
                           minDate={this.state.start}
                           enabled={true}
                           onChange={(e) => this.setState({end: e})}
                           enableTime={true}
                />
            </div>
            }
        />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayCreator);
