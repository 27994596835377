import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormLabel
} from 'react-bootstrap';

import './PriceLimit.css';
import {countDecimals, parsePrice, isNullOrUndefined, convertExponentialToDecimal} from "../../modules/util";

const PriceLimitReadOnly = ({
  lowLimitValue,
  highLimitValue,
  setLowLimit,
  setHighLimit,
  priceIncrement,
}) => {
  var lowLimit = lowLimitValue || 0;
  var highLimit = highLimitValue || 0;
  if (!isNullOrUndefined(priceIncrement)) {
      let increment = convertExponentialToDecimal(priceIncrement);
      const priceScale = Math.pow(10, countDecimals(increment));
      lowLimit = parsePrice(lowLimit, priceScale);
      highLimit = parsePrice(highLimit, priceScale);
  }
  return (
    <FormGroup id="">
      <FormLabel>Absolute Price Limits</FormLabel>
      <div>
        <div className="form-readonly-label price-limit-readonly-field">{lowLimit}</div>
        <div className="form-readonly-label price-limit-readonly-field">{highLimit}</div>
      </div>
      <div>
        <div className="price-limit-readonly-field-text">Low Limit {setLowLimit ? '' : 'Not'} Set</div>
        <div className="price-limit-readonly-field-text">High Limit {setHighLimit ? '' : 'Not'} Set</div>
      </div>
    </FormGroup>
  )
}

PriceLimitReadOnly.propTypes = {
  lowLimitValue: PropTypes.number,
  highLimitValue: PropTypes.number,
  setLowLimit: PropTypes.bool,
  setHighLimit: PropTypes.bool,
  priceIncrement: PropTypes.number,
}

export default PriceLimitReadOnly;