import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DataGrid from "../components/core/data-grid/DataGrid";
import DropdownListGroup from "../components/core/form/DropdownListGroup";

import { LoadAccounts } from "../actions/accounts";
import { LoadFirms } from "../actions/firms";
import { LoadParticipants } from "../actions/participants";
import { loadQuoteHistory } from "../actions/quotes";
import { loadRfqHistory } from "../actions/requestForQuotes";

import { isNullOrUndefined } from "../modules/util";
import { TitleBreadcrumb } from "../components/core/title-breadcrumb/title-breadcrumb";


class QuoteHistory extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }
  constructor() {
    super();
    this.state = {
      filter: "All",
      filterData: [],
      gridData: []
    }
  }

  componentDidMount() {
    this.props.loadAccounts();
    this.props.loadParticipants();
    this.props.loadFirms();
    this.props.loadRfqHistory();
    this.props.loadQuoteHistory();
    window.scrollTo(0, 0);
  }


  prepareGridData(filter) {
    let data = [];

    this.props.rfqs.forEach((rfq) => {
      let item = this.prepareGridItem(rfq, "RFQ");
      if (!isNullOrUndefined(item))
        data.push(item);
    }
    );

    this.props.quotes.forEach((qte) => {
      let item = this.prepareGridItem(qte, "Quote");
      if (!isNullOrUndefined(item))
        data.push(item);
    });

    return data;
  }

  prepareGridItem(obj, type) {
    if (isNullOrUndefined(obj))
      return;

    if (this.state.filter !== "All" && obj.status.name !== this.state.filter)
      return;

    let createdOn = this.formatDate(obj.createdOn);
    let expiredOn = this.formatDate(obj.expiredOn);

    let item = {
      created_date: obj.createdOn,
      created_on: createdOn,
      message_type: type,
      status: obj.status.name,
      expire_time: expiredOn,
      participant: this.getParticipantName(obj.user),
      submittingParticipant: this.getParticipantName(obj.submittingUser),
      submittingFirm: this.getParticipantFirmName(obj.submittingUser),
      account: this.getAccountName(obj.account),
      firm: this.getFirmName(obj.firm),
      side: this.getSide(obj.side.name),
      price: obj.price || "",
      quantity: obj.quantity,
      id: obj.id,
      order_qty: obj.quantity,
    }
    return item;
  }

  formatDate(d) {
    let datestring = + this.pad((d.getMonth() + 1), 2) + "-" + this.pad(d.getDate(), 2) + "-" + d.getFullYear() + " " +
      this.pad(d.getHours(), 2) + ":" + this.pad(d.getMinutes(), 2) + ":" + this.pad(d.getSeconds(), 2) + "." + this.pad(d.getMilliseconds(), 3);
    return datestring;

  }

  pad(data, desiredLength) {
    let neededZeros = desiredLength - data.toString().length;
    if (neededZeros) {
      let zeros = "0".repeat(desiredLength);
      return (zeros + data).slice(desiredLength * -1)
    }
    return data;
  }

  getParticipantName(name) {
    if (!isNullOrUndefined(this.props.participants)) {
      let p = this.props.participants.find(e => e.name === name);
      if (p)
        return p.displayName;
    }
    return name;
  }

  getParticipantFirmName(name) {
    if (!isNullOrUndefined(this.props.participants)) {
      let p = this.props.participants.find(e => e.name === name);
      if (p)
        return this.getFirmName(p.firm);
    }
    return "";
  }

  getAccountName(name) {
    if (!isNullOrUndefined(this.props.accounts)) {
      let p = this.props.accounts.find(e => e.name === name);
      if (p)
        return p.displayName;
    }
    return name;
  }

  getFirmName(name) {
    if (!isNullOrUndefined(this.props.firms)) {
      let p = this.props.firms.find(e => e.name === name);
      if (p)
        return p.displayName;
    }
    return name;
  }

  getSide(side) {
    if (side.toLowerCase() === "undefined")
      return "Two-Sided"
    return side;
  }

  getFilterOptions() {
    let filter = []
    this.props.rfqs.forEach((rfq) => {
      filter.push(rfq.status.name);
    });

    this.props.quotes.forEach((qte) => {
      filter.push(qte.status.name);
    });
    filter = [...new Set(filter)];
    filter.sort();
    filter.unshift("All")
    return filter;
  }
  changeFilter(e) {
    this.setState({
      filter: e
    });
  }

  render() {
    let data = this.prepareGridData();

    let filterOptions = this.getFilterOptions();

    const columns = [
      {
        Header: "CREATED AT",
        id: "created_on",
        accessor: (d) => d.created_on,
        minWidth: 130,
      },
      {
        Header: "MESSAGE TYPE",
        id: "message_type",
        accessor: (d) => d.message_type,
        minWidth: 80,
      },
      {
        Header: "EXPIRE TIME",
        id: "expire_time",
        accessor: (d) => d.expire_time,
        minWidth: 130,
      },
      {
        Header: "STATUS",
        id: "status",
        accessor: (d) => d.status,
        minWidth: 60,
      },
      {
        Header: "SIDE",
        id: "side",
        accessor: (d) => d.side,
        minWidth: 60,
      },
      {
        Header: "QUANTITY",
        id: "quantity",
        accessor: (d) => d.quantity,
        minWidth: 60,

      },
      {
        Header: "PRICE",
        id: "price",
        accessor: (d) => d.price,
        minWidth: 40,
      },
      {
        Header: "PARTICIPANT",
        id: "participant",
        accessor: (d) => d.participant,
        minWidth: 80,
      },
      {
        Header: "FIRM",
        id: "firm",
        accessor: (d) => d.firm,
        minWidth: 80,
      },
      {
        Header: "ACCOUNT",
        id: "account",
        accessor: (d) => d.account,
        minWidth: 80,
      },
      {
        Header: "AGENT",
        id: "submittingParticipant",
        accessor: (d) => d.submittingParticipant,
        minWidth: 80,
      },
      {
        Header: "FIRM",
        id: "submittingFirm",
        accessor: (d) => d.submittingFirm,
        minWidth: 80,
      },
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        minWidth: 200,
      }
    ];

    return (
      <React.Fragment>
        <TitleBreadcrumb titles={[{'title':'RFQ Message History'}]} />

        <div className="history-filter">
          <DropdownListGroup id="filter" name="filter" label="Filter"
            value={this.state.filter}
            onChange={(e) => this.changeFilter(e)}
            data={filterOptions}
          ></DropdownListGroup>
        </div>
        <DataGrid
          data={data}
          columns={columns}
          filterable={false}
          defaultSortedField="created_on"
          defaultSortedDesc={false}
        />
      </React.Fragment >
    );
  }
}

function mapStateToProps(state) {
  return {
    accounts: state.accounts.accounts,
    firms: state.firms.firms,
    participants: state.participants.participants,
    rfqs: state.requestForQuotes.requestForQuotes,
    quotes: state.quotes.quotes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadFirms: () => {
      dispatch(LoadFirms());
    },
    loadAccounts: () => {
      dispatch(LoadAccounts());
    },
    loadParticipants: () => {
      dispatch(LoadParticipants());
    },
    loadRfqHistory: () => {
      let id = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1)
      let filter = { rfqName: getRfqName(id) }
      dispatch(loadRfqHistory(filter, null))
    },
    loadQuoteHistory: () => {
      let id = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1)
      let filter = { rfqName: getRfqName(id) }
      dispatch(loadQuoteHistory(filter, null))
    },
  };

  function getRfqName(id) {
    return "requestForQuotes/" + id
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteHistory);
