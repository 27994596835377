import ProtobufParser from '../protobufParser';
import TimeOfDayMapper from './timeOfDayMapper';

const { TradingHours } = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb.js');

export default class TradingHourMapper {
  static mapToTradingHour(data) {
    var tradingHour = new TradingHours();

    let daysOfWeek = [];
    for (let index = 0; index < data.daysOfWeekList.length; index++) {
      let dayOfWeek = data.daysOfWeekList[index] === 0 ? 7 : data.daysOfWeekList[index];
      daysOfWeek.push(dayOfWeek);
    }

    tradingHour.setDaysOfWeekList(daysOfWeek);
    const timeOfDays = TimeOfDayMapper.mapToTimeOfDay(data.timeOfDay);
    tradingHour.setTimeOfDay(timeOfDays);
    
    const duration = ProtobufParser.toDuration(data.duration);
    tradingHour.setDuration(duration);
    if (data.state) {
      tradingHour.setState(data.state.id ? data.state.id : data.state);
    }

    return tradingHour;
  }
}
