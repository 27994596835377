import {isNullOrUndefined} from '../modules/util';

export const Env = {
  hasAliasOptions: () => {
    let aliasOptionsStr = Env.getEnv("REACT_APP_ALIAS_OPTIONS");

    return (!!aliasOptionsStr && aliasOptionsStr.length > 0);
  },

  getAliasOptions: () => {
    let aliasOptions = []
    let aliasOptionsStr = Env.getEnv("REACT_APP_ALIAS_OPTIONS");

    if (aliasOptionsStr && aliasOptionsStr.length > 0) {
      aliasOptions = aliasOptionsStr.split(',').map(str => str.trim());
    }

    return aliasOptions;
  },

  hasEventAttrStrikeUnits: () => {
    const strikeUnits = Env.getEnv("REACT_APP_EVENT_ATTR_STRIKE_UNIT");

    return (!!strikeUnits && strikeUnits.length > 0);
  },

  getEventAttrStrikeUnits: () => {
    const strikeUnits = Env.getEnv("REACT_APP_EVENT_ATTR_STRIKE_UNIT");

    if (!!strikeUnits && strikeUnits.length > 0) {
      return strikeUnits.split(";");
    }

    return ["String", "Integer", "Decimal", "Percentage"];
  },

  isNettingEnabled: () => {
    const nettingEnabled = Env.getEnv("REACT_APP_NETTING_ENABLED");
    if (!!nettingEnabled && !isNullOrUndefined(nettingEnabled)) {
      return (nettingEnabled.toString().toLowerCase() === "true" || nettingEnabled === 1);
    } else {
      return false;
    }
  },

  getEnv: (variableName) => {
    const fromEnv = process.env[variableName];
    if (!!fromEnv && !isNullOrUndefined(fromEnv)) {
      return fromEnv.toString().trim();
    }
    const fromWindow = window[variableName];
    const fromWindowVal = !!fromWindow && !isNullOrUndefined(fromWindow) ? fromWindow.toString().trim() : "";
    return fromWindowVal === "__" + variableName + "__" ? "" : fromWindowVal;
  },
}
