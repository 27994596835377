import React from "react";

const ErrorLabel = ({
  id,
  text,
  className
}) => {
  return (
    <>
      {text && (
        <div id={id} className={`form-error ${className}`}>
          <i className="fa fa-exclamation-triangle orange-icon"
            aria-hidden="true"></i>{" "}{text}
        </div>
      )}
    </>
  )
};

export default ErrorLabel;

