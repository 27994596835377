import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import DateGroup from "../core/form/DateGroup";
import PositionsDataGrid from "./PositionsDataGrid";
import ButtonSecondary from "../core/form/ButtonSecondary";
import querystring from 'query-string';
import { isNullOrUndefined } from '../../modules/util';
import _ from "lodash"

const AccountsPositionsDataGrid = ({ data, pendingSettlements, accounts, reload, editable, onExport}) => {

  const [asOfTime, setAsOfTime] = useState("");

  const onDate = (date) => {
    setAsOfTime(date)
    let filters = {
      "asOfTime": date,
    }
    reload(filters)
  }

  useEffect(() => {
    
  }, []);

  let urlContents = querystring.parseUrl(window.location.href)
  let urlAsOfTime = !isNullOrUndefined(urlContents.query.asOfTime) && !_.isEmpty(urlContents.query.asOfTime) ? new Date(urlContents.query.asOfTime) : null
  return (
        <>
          <Row>
            <Col lg={4} xs={4} md={4} style={{maxWidth:"325px"}}>
              <DateGroup id="asOfTime" name="asOfTime" label="As Of Date/Time"
                value={!!urlAsOfTime ? urlAsOfTime : asOfTime ? new Date(asOfTime) : null}
                onChange={(e) => onDate(e)}
                enableTime={true}
              />
            </Col>
            <Col>
                <ButtonSecondary text="Clear" type="button" style={{ marginTop: "33px" }} onClick={()=>{onDate(null)}} />
            </Col>
            <Col>
              <ButtonSecondary text="Export Data" type="button" style={{ float: "right", marginTop: "33px"}} onClick={() => {onExport()}}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <PositionsDataGrid data={data} pendingSettlements={pendingSettlements} accounts={accounts} reload={reload} editable={editable}/>;
              </div>
            </Col>
          </Row>
        </>
  );
};

AccountsPositionsDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  accounts: PropTypes.array,
  reload: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  saveAsOfTime: PropTypes.func.isRequired
};

export default AccountsPositionsDataGrid;
