import { getEnumName, capitalizeWord, parsePrice } from "../modules/util";
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import State from "./dto/State";
import TradingHour from "./TradingHour";
import TradeDayRollSchedule from "./TradeDayRollSchedule";
import { protoDateToDate } from "../actions/ptypes";
import {
  setIRSAtts,
  setFRAAtts,
  setFutureAtts,
  setOptionAtts,
  setDefaultAtts,
  setMultilegAtts,
  setFsIRSAtts,
  setBasisAtts,
  setEventAtts,
  setOISAtts,
  setSpsAtts,
  setNDFAtts,
  setForexAtts,
  setZeroCouponInflationSwapAtts
} from "../modules/instrumentUtils";

export default class Instrument {
  constructor(item) {
    this.type = null;
    this.id = null;
    this.productId = null;
    this.state = null;
    this.description = null;
    this.cfiCode = null;
    this.instrumentProductType = null;
    this.routingkey = null;
    this.externalSymbol = null;
    this.priceScale = null;
    this.isScheduled = null;
    this.minimumPriceIncrement = null;
    this.minimumTradeQuantity = null;
    this.minimumUnaffiliatedFirms = null;
    this.multiplier = null;
    this.tradeSettlementPeriod = null;
    this.holidayCalendars = null;
    this.basecurrency = null;
    this.startDate = null;
    this.expirationDate = null;
    this.terminationDate = null;
    this.lowPriceLimit = null;
    this.highPriceLimit = null;
    this.setLowPriceLimit = null;
    this.setHighPriceLimit = null;
    this.tradingSchedule = null;
    this.lowOrderLimit = null;
    this.highOrderLimit = null;
    this.setLowOrderLimit = null;
    this.setHighOrderLimit = null;
    this.lowTotalNotionalLimit = null;
    this.highTotalNotionalLimit = null;
    this.setLowTotalNotionalLimit = null;
    this.setHighTotalNotionalLimit = null;
    this.skipRiskAndCurrencyChecks = null;
    this.nonTradable = false;
    this.hideMarketData = false;
    this.crossOrderRestingDuration = null;
    this.blockTradeThreshold = {};
    this.settlementPeriodStartOffsetFromCloseDuration = null;
    this.settlementPeriodEndOffsetFromCloseDuration = null;
    this.minimumTradeQtyForVwapSettlementCalculation = null;
    this.bufferFromEndOfSettlementPeriodDuration = null;
    this.settlementPriceLogic = null;
    this.settlementPriceCalculationRequiresApproval = false;
    this.settlementPriceCalculationEndingMethod = null;
    this.metadata = [];

    if (item) {
      this.id = item.getId();

      const productId = item.getProductId();

      if (productId !== "") {
        this.productId = productId;
      }

      const stateValue = item.getState();
      let state = new State();

      state.id = stateValue;
      state.name = capitalizeWord(getEnumName(InstrumentState, stateValue));
      this.state = state;

      this.description = item.getDescription();
      this.nonTradable = item.getNonTradable();
      this.hideMarketData = item.getHideMarketData();
      this.routingkey = item.getRoutingKey();
      this.externalSymbol = item.getExternalSymbol();
      this.priceScale = item.getPriceScale();
      this.isScheduled = item.getIsScheduled();

      this.isDeletable = [
        InstrumentState.INSTRUMENT_STATE_PENDING,
        InstrumentState.INSTRUMENT_STATE_TERMINATED,
      ].some((value) => value === state.id);

      this.isEditable = state.id === InstrumentState.INSTRUMENT_STATE_PENDING;
      this.isPartialEditable = state.id !== InstrumentState.INSTRUMENT_STATE_TERMINATED;
      this.isStateEditable = state.id !== InstrumentState.INSTRUMENT_STATE_TERMINATED;

      //Attributes
      this.cfiCode = item.getAttributes().getCfiCode();
      this.instrumentProductType = item.getAttributes().getInstrumentProductType();

      this.minimumPriceIncrement = item.getAttributes().getTickSize();

      this.fractionalQtyScale = item.getAttributes().getFractionalQtyScale();
      let minTradeQtyInt = item.getAttributes().getMinimumTradeQty();
      this.minimumTradeQuantity = parseFloat(
        parsePrice(minTradeQtyInt, this.fractionalQtyScale)
      );

      this.minimumUnaffiliatedFirms = item
        .getAttributes()
        .getMinimumUnaffiliatedFirms();
      this.multiplier = item.getAttributes().getMultiplier();
      this.basecurrency = item.getAttributes().getBaseCurrency();
      this.clearingHouse = item.getAttributes().getClearingHouse();
      this.tradeSettlementPeriod = item
        .getAttributes()
        .getTradeSettlementPeriod();
      this.holidayCalendars = item.getAttributes().getHolidayCalendarsList();
      this.crossOrderRestingDuration = item.getAttributes().getCrossOrderRestingDuration() ? item.getAttributes().getCrossOrderRestingDuration().toObject() : null;

      this.blockTradeThreshold = !!item.getAttributes().getBlockTradeThreshold() ? item.getAttributes().getBlockTradeThreshold().toObject() : null;

      if (!!this.blockTradeThreshold) {
        const blockMinQty = this.blockTradeThreshold.minimumQuantity;
        this.blockTradeThreshold.minimumQuantity = parseFloat(parsePrice(blockMinQty, this.fractionalQtyScale));

        const notionalScale = this.priceScale * this.fractionalQtyScale;
        const minimumNotionalPrice = this.blockTradeThreshold.minimumNotional;
        this.blockTradeThreshold.minimumNotional = parseFloat(parsePrice(minimumNotionalPrice, notionalScale));
      }

      const priceLimit = item.getAttributes().getPriceLimit();
      this.lowPriceLimit = priceLimit ? priceLimit.getLow() : null;
      this.highPriceLimit = priceLimit ? priceLimit.getHigh() : null;
      this.setLowPriceLimit = priceLimit ? priceLimit.getLowSet() : null;
      this.setHighPriceLimit = priceLimit ? priceLimit.getHighSet() : null;
      this.relativeLowSet = priceLimit ? priceLimit.getRelativeLowSet() : null;
      this.relativeHighSet = priceLimit
        ? priceLimit.getRelativeHighSet()
        : null;
      this.relativeLow = priceLimit ? priceLimit.getRelativeLow() : null;
      this.relativeHigh = priceLimit ? priceLimit.getRelativeHigh() : null;

      const orderLimits = item.getAttributes().getOrderSizeLimit();
      this.lowOrderLimit = orderLimits ? orderLimits.getLow() : null;
      this.highOrderLimit = orderLimits ? orderLimits.getHigh() : null;
      this.setLowOrderLimit = orderLimits ? orderLimits.getLowSet() : null;
      this.setHighOrderLimit = orderLimits ? orderLimits.getHighSet() : null;
      this.lowTotalNotionalLimit = orderLimits ? orderLimits.getTotalNotionalLow() : null;
      this.highTotalNotionalLimit = orderLimits ? orderLimits.getTotalNotionalHigh() : null;
      this.setLowTotalNotionalLimit = orderLimits ? orderLimits.getTotalNotionalLowSet() : null;
      this.setHighTotalNotionalLimit = orderLimits ? orderLimits.getTotalNotionalHighSet() : null;
      this.skipRiskAndCurrencyChecks = item.getAttributes().getSkipRiskAndCurrencyChecks();

      const startDate = item.getAttributes().getStartDate();
      this.startDate = startDate ? protoDateToDate(startDate) : null;

      const lastTradeDate = item.getAttributes().getLastTradeDate();
      const lastTradeTime = item.getAttributes().getLastTradeTime();
      this.lastTradeDate = lastTradeDate ? protoDateToDate(lastTradeDate, lastTradeTime) : null;

      const expirationDate = item.getAttributes().getExpirationDate();
      const expirationTime = item.getAttributes().getExpirationTime();
      this.expirationDate = expirationDate
        ? protoDateToDate(expirationDate, expirationTime)
        : null;

      const terminationDate = item.getAttributes().getTerminationDate();
      this.terminationDate = terminationDate
        ? protoDateToDate(terminationDate)
        : null;

      const tradingSchedule = item.getAttributes().getTradingScheduleList();

      if (tradingSchedule) {
        let tradingHours = [];

        for (var i = 0; tradingSchedule.length > i; i++) {
          tradingHours.push(new TradingHour(tradingSchedule[i]));
        }

        this.tradingSchedule = tradingHours;
      }

      const tradeDayRollScheduleList = item.getAttributes().getTradeDayRollScheduleList();
      if (tradeDayRollScheduleList){
        this.tradeDayRollSchedule = TradeDayRollSchedule.parseProto(tradeDayRollScheduleList);
      }

      const settlementPeriodStartOffsetFromCloseDuration = item.getAttributes().getSettlementPeriodStartOffsetFromCloseDuration();
      this.settlementPeriodStartOffsetFromCloseDuration = settlementPeriodStartOffsetFromCloseDuration ? settlementPeriodStartOffsetFromCloseDuration.toObject() : null;

      const settlementPeriodEndOffsetFromCloseDuration = item.getAttributes().getSettlementPeriodEndOffsetFromCloseDuration();
      this.settlementPeriodEndOffsetFromCloseDuration   = settlementPeriodEndOffsetFromCloseDuration ? settlementPeriodEndOffsetFromCloseDuration.toObject() : null;

      this.minimumTradeQtyForVwapSettlementCalculation = item.getAttributes().getMinimumTradeQtyForVwapSettlementCalculation();

      const bufferFromEndOfSettlementPeriodDuration = item.getAttributes().getBufferFromEndOfSettlementPeriodDuration();
      this.bufferFromEndOfSettlementPeriodDuration = bufferFromEndOfSettlementPeriodDuration ? bufferFromEndOfSettlementPeriodDuration.toObject() : null;
      this.settlementPriceLogic = item.getAttributes().getSettlementPriceLogic();
      this.settlementPriceCalculationRequiresApproval = item.getAttributes().getSettlementPriceCalculationRequiresApproval();
      this.settlementPriceCalculationEndingMethod = item.getAttributes().getSettlementPriceCalculationEndingMethod();

      item.getAttributes().getMetadataMap().forEach((value, key) => {
        this.metadata.push({key, value})
      });

      if (item.hasInterestRateSwapAttributes()) {
        let atts = item.getInterestRateSwapAttributes().toObject();
        setIRSAtts(atts, this);
      } else if (item.hasForwardRateAgreementSwapAttributes()) {
        let atts = item.getForwardRateAgreementSwapAttributes().toObject();
        setFRAAtts(atts, this);
      } else if (item.hasForwardStartingInterestRateSwapAttributes()) {
        let fsIRSAtts = item
          .getForwardStartingInterestRateSwapAttributes()
          .toObject();
        setFsIRSAtts(fsIRSAtts, this);
      } else if (item.hasFutureAttributes()) {
        let atts = item.getFutureAttributes().toObject();
        setFutureAtts(atts, this);
      } else if (item.hasOptionAttributes()) {
        let atts = item.getOptionAttributes().toObject();
        setOptionAtts(atts, this);
      } else if (item.hasForexAttributes()) {
        let atts = item.getForexAttributes().toObject();
        setForexAtts(atts, this);
      } else if (item.hasMultilegAttributes()) {
        let atts = item.getMultilegAttributes().toObject();
        setMultilegAtts(atts, this);
      } else if (item.hasBasisSwapAttributes()) {
        let atts = item.getBasisSwapAttributes().toObject();
        setBasisAtts(atts, this);
      } else if (item.hasEventAttributes()) {
        let atts = item.getEventAttributes().toObject();
        setEventAtts(atts, this, this.priceScale);
      } else if (item.hasNonDeliverableForwardAttributes()) {
        let atts = item.getNonDeliverableForwardAttributes().toObject();
        setNDFAtts(atts, this);
      } else if (item.hasOvernightIndexSwapAttributes()) {
        let atts = item.getOvernightIndexSwapAttributes().toObject();
        setOISAtts(atts, this);
      } else if (item.hasSinglePeriodSwapAttributes()) {
        let atts = item.getSinglePeriodSwapAttributes().toObject();
        setSpsAtts(atts, this);
      } else if (item.hasZeroCouponInflationSwapAttributes()) {
        let atts = item.getZeroCouponInflationSwapAttributes().toObject();
        setZeroCouponInflationSwapAtts(atts, this);
      } else {
        setDefaultAtts(this);
      }
    }
  }
}
