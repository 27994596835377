import slugify from "slugify";
import { isArrayWithValues, isNullOrUndefined } from "../util";
import UserAttributesMapper from "./userAttributesMapper";

const {
  Account,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb.js");

const {
  AccountInstrumentRestriction,
  BankDetails,
  RestrictionConfiguration,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb.js");

const {
  OrderSizeLimit
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb.js")

export default class AccountMapper {
  static mapToAccount(data) {
    var account = new Account();

    account.setId(data.accountId ? data.accountId : data.displayName ? slugify(data.displayName) : '');
    account.setDisplayName(data.displayName);
    account.setAccountType(data.accountType);
    account.setState(data.state);

    account.setParentFirm(data.parentFirm);
    account.setAssociatedFirm(data.associatedFirm);
    account.setRiskSystem(data.riskSystem);
    account.setCollateralAccount(data.collateralAccount);
    if (!isNullOrUndefined(data.priorityWeight)) {
      account.setPriorityWeight(parseInt(String(data.priorityWeight)));
    }

    account.clearAliasesMap();
    data.aliases && data.aliases.forEach((alias) => {
      account.getAliasesMap().set(alias.option, alias.alias);
    });

    account.clearPropertiesList();
    data.propertiesList && data.propertiesList.forEach((prop) => {
      account.getPropertiesList().push(prop.id);
    })

    account.clearBeneficialOwnershipList();
    if (isArrayWithValues(data.beneficialOwnership)) {
      let userAttributes = []
      data.beneficialOwnership.forEach((entry) => {
        userAttributes.push(UserAttributesMapper.mapToUserAttributes(entry))
      });

      if (userAttributes.length > 0) {
        account.setBeneficialOwnershipList(Array.from(userAttributes));
      }
    }

    if (isArrayWithValues(data.bankDetails)) {
      let bankDetailsList = []
      data.bankDetails.forEach((entry) => {
        let bankDetails = new BankDetails();
        if (!isNullOrUndefined(entry)) {
          bankDetails.setBankId(entry.bankId);
          bankDetails.setBankIdType(entry.bankIdType);
          bankDetails.setCashAccountNumber(entry.cashAccountNumber);
          bankDetails.setSecuritiesAccountNumber(entry.securitiesAccountNumber);
          bankDetails.setBeneficiaryName(entry.beneficiaryName);
          bankDetails.setBeneficiaryAddress1(entry.beneficiaryAddress1);
          bankDetails.setBeneficiaryAddress2(entry.beneficiaryAddress2);
          bankDetails.setBeneficiaryCity(entry.beneficiaryCity);
          bankDetails.setBeneficiaryState(entry.beneficiaryState);
          bankDetails.setBeneficiaryPostalCode(entry.beneficiaryPostalCode);
          bankDetails.setBeneficiaryCountryCode(entry.beneficiaryCountryCode);
          bankDetails.setBankName(entry.bankName);
          bankDetails.setBankAddress1(entry.bankAddress1);
          bankDetails.setBankAddress2(entry.bankAddress2);
          bankDetails.setBankCity(entry.bankCity);
          bankDetails.setBankState(entry.bankState);
          bankDetails.setBankPostalCode(entry.bankPostalCode);
          bankDetails.setBankCountryCode(entry.bankCountryCode);
          bankDetails.setDefault(entry.pb_default);
          bankDetails.setBankAccountType(entry.bankAccountType);
          bankDetails.setAccountTitle(entry.accountTitle);

          bankDetailsList.push(bankDetails);
        }
        account.setBankDetailsList(bankDetailsList)
      });

    }

    var orderSizeLimit = new OrderSizeLimit();
    orderSizeLimit.setLow(data.lowOrderLimit ? Number(data.lowOrderLimit) : 0);
    orderSizeLimit.setHigh(data.highOrderLimit ? Number(data.highOrderLimit) : 0);
    orderSizeLimit.setLowSet(data.setLowOrderLimit ? data.setLowOrderLimit : false);
    orderSizeLimit.setHighSet(data.setHighOrderLimit ? data.setHighOrderLimit : false);
    account.setOrderSizeLimit(orderSizeLimit);

    return account;
  }

  static mapToAccountUpdate(data) {
    var account = this.mapToAccount(data);
    account.setName(data.name);
    account.setDisplayName(data.displayName);
    account.setRiskSystem(data.riskSystem);
    var orderSizeLimit = new OrderSizeLimit();
    orderSizeLimit.setLow(data.orderSizeLimit ? data.orderSizeLimit.low || 0 : 0);
    orderSizeLimit.setHigh(data.orderSizeLimit ? data.orderSizeLimit.high || 0 : 0);
    orderSizeLimit.setLowSet(data.orderSizeLimit ? data.orderSizeLimit.setLow : false);
    orderSizeLimit.setHighSet(data.orderSizeLimit ? data.orderSizeLimit.setHigh : false);
    account.setOrderSizeLimit(orderSizeLimit)

    account.clearAliasesMap();
    data.aliases && data.aliases.forEach((alias) => {
      account.getAliasesMap().set(alias.option, alias.alias);
    });

    account.clearPropertiesList();
    data.propertiesList && data.propertiesList.forEach((prop) => {
      account.getPropertiesList().push(prop.id);
    })

    account.clearBeneficialOwnershipList();
    if (isArrayWithValues(data.beneficialOwnership)) {
      let userAttributes = []
      data.beneficialOwnership.forEach((entry) => {
        userAttributes.push(UserAttributesMapper.mapToUserAttributes(entry))
      });

      if (userAttributes.length > 0) {
        account.setBeneficialOwnershipList(Array.from(userAttributes));
      }
    }

    account.setCollateralAccount(data.collateralAccount);
    if (!isNullOrUndefined(data.priorityWeight)) account.setPriorityWeight(parseInt(String(data.priorityWeight)));

    return account;
  }

  static mapToAccountUpdateWhiteList(data) {
    var account = this.mapToAccountUpdate(data);
    account.setName(data.name);

    var accountWhitelist = new AccountInstrumentRestriction();
    accountWhitelist.setEnabled(data.enableWhitelist);
    accountWhitelist.clearSymbolsMap();
    data.whiteListInstruments && data.whiteListInstruments.forEach((whitelistInstrument) => {
      const restrictionConfig = new RestrictionConfiguration();
      restrictionConfig.setSide(whitelistInstrument.side);
      accountWhitelist.getSymbolsMap().set(whitelistInstrument.symbol, restrictionConfig);
    })
    accountWhitelist.clearProductsMap();
    data.whiteListProducts && data.whiteListProducts.forEach((whiteListProduct) => {
      const restrictionConfig = new RestrictionConfiguration();
      restrictionConfig.setSide(whiteListProduct.side);
      accountWhitelist.getProductsMap().set(whiteListProduct.product, restrictionConfig);
    })
    account.setWhitelist(accountWhitelist);

    account.clearAliasesMap();
    data.aliases && data.aliases.forEach((alias) => {
      account.getAliasesMap().set(alias.option, alias.alias);
    });

    account.clearPropertiesList();
    data.propertiesList && data.propertiesList.forEach((prop) => {
      account.getPropertiesList().push(prop.id);
    })

    account.clearBeneficialOwnershipList();
    Array.isArray(data.beneficialOwnership) && data.beneficialOwnership.forEach((entry) => {
      account.getBeneficialOwnershipList().push(UserAttributesMapper.mapToUserAttributes(entry));
    })
    return account;
  }
}