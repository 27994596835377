import React, { useState } from "react";
import { connect } from "react-redux";
import Collapse from 'react-bootstrap/Collapse'
import { Row, Col } from "react-bootstrap";
import DateGroup from "../../../core/form/DateGroup";
import DateTimeParser from "../../../../modules/dateTimeParser";
import FieldGroup from "../../../core/form/FieldGroup";
import MultiSelectDropdownListGroup from "../../../core/form/MultiSelectDropdownListGroup"
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import ButtonMain from "../../../core/form/ButtonMain";
import { createBasisAtts,setBasisAtts } from "../../../../modules/instrumentUtils";
import { makeDropdownOptions, SELECTED_DEFAULT } from "../../instrumentFormUtils";
import DateAdjustmentService from '../../../../services/DateAdjustmentService';
import { FormModes } from "../../../../constants/enumerations"
import _ from 'lodash';

const DateCalculatorBasis = ({
  values,
  isEditing,
  touched,
  setFieldValue,
  setFieldError,
  handleBlur,
  handleChange,
  enableStartDate,
  calculateDates,
  errors,
  mode
}) => {
  const onDateCalculator = (values, setFieldValue, setFieldError, calculateDates) => {

    const successHandler = (attributes) => {
      let atts = {};

      setBasisAtts(attributes, atts);

      _.map(atts, (val, key) => {
        setFieldValue(key, val);
      });
    }

    const handleError = err => {
      setFieldError('dateCalculator', err);
    }

    if (values.startDate) {
      let atts = createBasisAtts(values);
      calculateDates(handleError, successHandler, atts, values.startDate);
    } else {
      setFieldError('dateCalculator', "Trade Date missing");
    }
  }

  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Date Calculator</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>

      <Collapse in={open}>
        <div className="collapse-content">
          <h6>Swap Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="startDate" name="startDate" label="Trade date" isRequired={true}
                value={values.startDate ? new Date(values.startDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(values.isPartialEditable, isEditing, values.startDate)}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue('startDate', e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="tenor" name="tenor" type="textarea" label="Tenor" isRequired={true}
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.tenor}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="sideConvention" name="sideConvention" label="Side Convention"
                value={values.sideConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("sideConvention", e.id)}
                data={InstrumentStaticData.SideConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="effectiveDateOffset" name="effectiveDateOffset" type="textarea" label="Swap Effective Date Offset" isRequired={true}
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.effectiveDateOffset}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="effectiveDateBusinessDayConvention" name="effectiveDateBusinessDayConvention" label="Effective Date Business Day Convention"
                value={values.effectiveDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("effectiveDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="maturityDateBusinessDayConvention" name="maturityDateBusinessDayConvention" label="Maturity Date Business Day Convention"
                value={values.maturityDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("maturityDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="swapEffectiveDate" name="swapEffectiveDate" label="Swap Effective Date" isRequired={true}
                value={values.swapEffectiveDate ? new Date(values.swapEffectiveDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(values.isPartialEditable, isEditing, values.startDate)}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue('swapEffectiveDate', e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DateGroup id="maturityDate" name="maturityDate" label="Maturity Date" isRequired={true}
                value={values.maturityDate ? new Date(values.maturityDate) : null}
                maxDate={
                  !values.expirationDate
                    ? values.terminationDate ? DateTimeParser.addDays(values.terminationDate, -2) : null
                    : DateTimeParser.addDays(values.expirationDate, -1)
                }
                dropUp={true}
                enabled={enableStartDate(values.isPartialEditable, isEditing, values.startDate)}
                errors={errors}
                touched={touched}
                onChange={(e) => setFieldValue('maturityDate', e)}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup id="rollAndPaymentDateHolidayCalendarsList" name="rollAndPaymentDateHolidayCalendarsList" label="Roll and Payment Date Holiday Calendar List"
                labelWrapperClass="label-wrapper"
                value={values.rollAndPaymentDateHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("rollAndPaymentDateHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"} valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
          </Row>
          <hr />
          <h6>Float Leg Input</h6>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatPaymentFrequency" name="leg1FloatPaymentFrequency" label="Leg1 Float Payment Frequency"
                value={values.leg1FloatPaymentFrequency || SELECTED_DEFAULT}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg1FloatPaymentFrequency", e.id)}
                data={makeDropdownOptions(InstrumentStaticData.FixedPaymentFrequency, false, true)}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatRateResetFrequency" name="leg1FloatRateResetFrequency" label="Leg1 Float Rate Reset Frequency"
                value={values.leg1FloatRateResetFrequency || SELECTED_DEFAULT}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg1FloatRateResetFrequency", e.id)}
                data={makeDropdownOptions(InstrumentStaticData.FloatRateResetFrequency, false, true)}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatBusinessDayConvention" name="leg1FloatBusinessDayConvention" label="Leg1 Float Business Day Convention"
                value={values.leg1FloatBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg1FloatBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatResetDateBusinessDayConvention" name="leg1FloatResetDateBusinessDayConvention" label="Leg1 Float Reset Date Business Day Convention"
                value={values.leg1FloatResetDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg1FloatResetDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatCompoundingMethod" name="leg1FloatCompoundingMethod" label="Leg1 Float Compounding Method"
                value={values.leg1FloatCompoundingMethod}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg1FloatCompoundingMethod", e.id)}
                data={InstrumentStaticData.CompoundingOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="leg1FloatFixingDatesOffset" name="leg1FloatFixingDatesOffset" type="textarea" label="Leg1 Float Fixing Dates Offset"
                disabled={!values.isEditable}
                value={values.leg1FloatFixingDatesOffset}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatResetDateRelativeTo" name="leg1FloatResetDateRelativeTo" label="Leg1 Float Reset Date Relative To"
                value={values.leg1FloatResetDateRelativeTo}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg1FloatResetDateRelativeTo", e.id)}
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatFixingDateBusinessDayConvention" name="leg1FloatFixingDateBusinessDayConvention" label="Leg1 Float Fixing Date Business Day Convention"
                value={values.leg1FloatFixingDateBusinessDayConvention}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg1FloatFixingDateBusinessDayConvention", e.id)}
                data={InstrumentStaticData.BusinessDayConventionOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatFixingDatesDayType" name="leg1FloatFixingDatesDayType" label="Leg1 Float Fixing Dates Day Type"
                value={values.leg1FloatFixingDatesDayType}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg1FloatFixingDatesDayType", e.id)}
                data={InstrumentStaticData.FixingDatesDayType}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg1FloatPaymentDateRelativeTo" name="leg1FloatPaymentDateRelativeTo" label="Leg1 Float Payment Date Relative To"
                value={values.leg1FloatPaymentDateRelativeTo}
                errors={errors}
                enabled={values.isEditable}
                onChange={(e) => setFieldValue("leg1FloatPaymentDateRelativeTo", e.id)}
                data={InstrumentStaticData.DateRelativeToOptions}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
            <DropdownListGroup id="leg2FloatRateResetFrequency" name="leg2FloatRateResetFrequency" label="Leg2 Float Rate Reset Frequency"
                value={values.leg2FloatRateResetFrequency || SELECTED_DEFAULT}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg2FloatRateResetFrequency", e.id)}
                data={makeDropdownOptions(InstrumentStaticData.FloatRateResetFrequency, false, true)}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="leg2FloatPaymentFrequency" name="leg2FloatPaymentFrequency" label="Leg2 Float Payment Frequency"
                value={values.leg2FloatPaymentFrequency || SELECTED_DEFAULT}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg2FloatPaymentFrequency", e.id)}
                data={makeDropdownOptions(InstrumentStaticData.FixedPaymentFrequency, false, true)}
                textField="name"
                valueField="id"
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <MultiSelectDropdownListGroup id="leg1FloatFixingDateHolidayCalendars" name="leg1FloatFixingDateHolidayCalendars" label="Leg1 Float Fixing Date Holiday Calendars"
                labelWrapperClass="label-wrapper"
                value={values.leg1FloatFixingDateHolidayCalendarsList}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("leg1FloatFixingDateHolidayCalendarsList", e)}
                data={InstrumentStaticData.HolidayCalendarList}
                textField={"text"} valueField={"value"}
                plaintext={isViewing}
              />
            </Col>
            {!isViewing &&
              <> 
                <Col lg={3} xs={3} md={3}></Col>             
                <Col lg={3} xs={3} md={3}></Col>
                <Col lg={3} xs={3} md={3}>
                  <ButtonMain type="button" text="Calculate Dates" onClick={() => onDateCalculator(values, setFieldValue, setFieldError, calculateDates)}
                  />
                  {errors && errors.dateCalculator && (
                    <div className="form-error mt-2">
                      <i className="fa fa-exclamation-triangle orange-icon" aria-hidden="true"></i>{" "}
                      {errors.dateCalculator}
                    </div>
                  )}
                </Col>
              </>
            }
          </Row>
          
        </div>
      </Collapse>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) => ({
  calculateDates: (handleError, successHandler, atts, startDate) => {
    dispatch(DateAdjustmentService.adjustDates(handleError, successHandler, atts, startDate));
  }
});

export default connect(null, mapDispatchToProps)(DateCalculatorBasis);

export const ValidateDateCalculatorBasis = (values, errors) => {
  if (!values.floatFirstUnadjustedEffectiveDate || !values.unadjustedMaturityDate || !values.leg1FloatFirstUnadjustedPaymentDate || !values.leg2FloatFirstUnadjustedPaymentDate) {
    errors["dateCalculator"] = "You must calculate dates";
  }
  return errors;
}