import React from "react";
import PropTypes from "prop-types";
import DataGrid from "../core/data-grid/DataGrid";
import { OrderType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";

import _ from "lodash";
import DataGridActions from "../core/data-grid/DataGridActions";

const BookDataGrid = ({
  data,
  onCancel,
  isStop,
  participants,
}) => {
  const getTdProps = (state, rowInfo, column) => {
    const lightRed = rowInfo && rowInfo.viewIndex % 2 ? "#FFDDDD" : "#FFCCCC";
    const lightBlue = rowInfo && rowInfo.viewIndex % 2 ? "#DDEEEE" : "#CCEEEE";
    var color = "#FFFFFF";
    if (column) {
      color = _.includes(column.className, "buy") ? lightBlue : lightRed;
    }
    return {
      style: {
        background: color,
      },
    };
  };
  const getTrProps = () => {
    return {
      style: {
        background: "#FFFFFF",
      },
    };
  };

  let columns = [
    {
      Header: "BUY ORDER ID",
      className: "buy",
      accessor: "buyorderid",
      Cell: (e) => <a href={"order-search/orders?orderid=" + e.value}>{e.value}</a>,
      minWidth: 140,
      maxWidth: 140,
    },
    {
      Header: "",
      className: "buy",
      maxWidth: 50,
      minWidth: 50,
      sortable: false,
      Cell: (row) => {
        if (row.original.buyorderid) {
          return <DataGridActions
            onBust={() => {
              onCancel(row.original.buyorderid);
            }}
          />;
        }
        return <DataGridActions />;
      },
    },
    {
      id: "buyerFirm",
      Header: "FIRM",
      className: "buy",
      accessor: (d) => {
        if (participants) {
          const [participant] = participants.filter((participant) =>
            participant.name === d.buyer
          );
          return participant ? participant.firm.displayName : "N/A";
        }
        return "N/A";
      },
      maxWidth: 150,
    },
    {
      Header: "ACCOUNT",
      className: "buy",
      accessor: "buyAccount",
      maxWidth: 100,
    },
    {
      Header: "USER",
      className: "buy",
      accessor: "buyUser",
      maxWidth: 100,
    },
    {
      Header: "QTY TO BUY",
      className: "buy",
      accessor: "qtyToBuy",
      maxWidth: 100,
    },
    {
      Header: "LIMIT BUY PX",
      className: "buy",
      id: "priceToBuy",
      accessor: d => !isStop || d.buyOrdType === OrderType.ORDER_TYPE_STOP_LIMIT ? d.priceToBuy : null,
      maxWidth: 120,
    },
  ];
  if (!!isStop) {
    columns.push(
        {
          Header: "STOP BUY PX",
          className: "buy",
          accessor: "stopBuyPrice",
          maxWidth: 120,
        },
        {
          Header: "STOP SELL PX",
          className: "sell",
          accessor: "stopSellPrice",
          maxWidth: 120,
        },
    )
  }
  columns.push(
    {
      Header: "LIMIT SELL PX",
      className: "sell",
      id: "priceToSell",
      accessor: d => !isStop || d.sellOrdType === OrderType.ORDER_TYPE_STOP_LIMIT ? d.priceToSell : null,
      maxWidth: 120,
    },
    {
      Header: "QTY TO SELL",
      className: "sell",
      accessor: "qtyToSell",
      maxWidth: 100,
    },
    {
      id: "sellerFirm",
      Header: "FIRM",
      className: "sell",
      accessor: (d) => {
        if (participants) {
          const [participant] = participants.filter((participant) =>
            participant.name === d.seller
          );
          return participant ? participant.firm.displayName : "";
        }
        return "";
      },
      maxWidth: 150,
    },
    {
      Header: "ACCOUNT",
      className: "sell",
      accessor: "sellAccount",
      maxWidth: 100,
    },
    {
      Header: "USER",
      className: "sell",
      accessor: "sellUser",
      maxWidth: 100,
    },
    {
      Header: "",
      className: "sell",
      maxWidth: 50,
      minWidth: 50,
      sortable: false,
      Cell: (row) => {
        if (row.original.sellorderid) {
          return <DataGridActions
            onBust={() => {
              onCancel(row.original.sellorderid);
            }}
          />;
        }
        return <DataGridActions />;
      },
    },
    {
      Header: "SELL ORDER ID",
      className: "sell",
      accessor: "sellorderid",
      Cell: (e) => <a href={"order-search/orders?orderid=" + e.value}>{e.value}</a>,
      minWidth: 140,
      maxWidth: 250,
    },
  );
  return (
    <div>
      <DataGrid
        data={data}
        columns={columns}
        filterable={false}
        defaultPageSize={100}
        getTdProps={getTdProps}
        getTrProps={getTrProps}
      >
      </DataGrid>
    </div>
  );
};

BookDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  isStop: PropTypes.bool.isRequired,
};

export default BookDataGrid;
