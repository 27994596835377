import { LIST_ALERTS_FAILED, LIST_ALERTS_START, LIST_ALERTS_SUCCESS, DOWNLOAD_ALERTS_FAILED, DOWNLOAD_ALERTS_START, DOWNLOAD_ALERTS_SUCCESS } from "../constants/AlertTypes"
import { Alert } from "../entities/Alert";
import Notification from "../modules/notifications";
import AlertService from "../services/AlertService";
const { DownloadAlertsRequest } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");

export function listAlerts(listAlertsRequest, cb) {
  return (dispatch, getState) => {
    dispatch({ type: LIST_ALERTS_START });

    const reqCb = (err, resp) => {
      if (!!err) {
        dispatch({ type: LIST_ALERTS_FAILED })
        Notification.error(`Cannot get list of alerts: ${err.message}`);
      }

      if (!!resp) {
        const alerts = resp.getAlertsList().map((elm) => {
          const alert = new Alert(elm);
          return alert;
        });

        const isEOF = resp.getEof();
        const nextPageToken = !isEOF ? resp.getNextPageToken() : null;
        const pageToken = listAlertsRequest.getPageToken();

        dispatch({ type: LIST_ALERTS_SUCCESS, payload: { alerts, pageToken, nextPageToken: nextPageToken, } })
      }

      if (!!cb) {
        cb(err, resp)
      }
    }

    AlertService.listAlerts(listAlertsRequest, reqCb);
  }
}

export function updateAlert(updateAlertRequest, cb) {
  return (dispatch, getState) => {

    const reqCb = (err, resp) => {
      if (!!err) {
        Notification.error(`Update alert was not successful: ${err.message}`);
      }

      if (!!resp) { }

      if (!!cb) { cb(err, resp); }
    }

    AlertService.updateAlert(updateAlertRequest, reqCb);
  }
}

export function updateAlertNotes(updateAlertNotesRequest, cb) {
  return (dispatch, getState) => {

    const reqCb = (err, resp) => {
      if (!!err) {
        Notification.error(`Update alert notes was not successful: ${err.message}`);
      }

      if (!!resp) { }

      if (!!cb) { cb(err, resp); }
    }

    AlertService.updateAlertNotes(updateAlertNotesRequest, reqCb);
  }
}

export function exportAlerts(listAlertsRequest) {
  return (dispatch, getState) => {
    let onSuccess = (data) => {
      dispatch({ type: DOWNLOAD_ALERTS_SUCCESS })
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `alerts.csv`);
      document.body.appendChild(link);
      link.click();
    };
    let onError = (error) => {
      console.error(error);
      dispatch({ type: DOWNLOAD_ALERTS_FAILED })
      Notification.error("Could not download alerts.");
    };
    const downloadAlertsRequest = new DownloadAlertsRequest();
    downloadAlertsRequest.setAlertsRequest(listAlertsRequest)

    dispatch({ type: DOWNLOAD_ALERTS_START })
    AlertService.downloadAlerts(downloadAlertsRequest, onSuccess, onError);
  }
}

