import React from "react";
import { withFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import DateGroup from "../core/form/DateGroup";
import moment from "moment";
import MultiSelectDropdownListGroup from "../core/form/MultiSelectDropdownListGroup";
import ButtonSecondary from '../core/form/ButtonSecondary';
import querystring from 'query-string';

import "./AdminRequestsFilter.css"

const Reset_Action = "reset";

const BasicAdminRequestsFilter = (props) => {
    const { values, touched, errors, handleChange, handleBlur,
        handleSubmit, setFieldValue, statusOptions
    } = props;

    const style = {
        marginRight: "0px",
        marginBottom: "1px",
        marginLeft: "0px"
    }

    return (
        <div style={style}>
            <form onSubmit={handleChange} style={style}>
                <Row style={style}>
                   <Col lg={4} xs={4} md={4}>
                        <MultiSelectDropdownListGroup
                            id="status"
                            name="status"
                            label="Status"
                            value={values.statusesList}
                            onChange={(e) => setFieldValue("statusesList", e)}
                            data={statusOptions}
                            textField="name"
                            valueField="id"
                        />
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                        <FieldGroup
                            id="requestName"
                            name="requestName"
                            type="text"
                            label="Request Name"
                            value={values.requestName}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                        <FieldGroup
                            id="requestUser"
                            name="requestUser"
                            type="text"
                            label="Requesting User"
                            value={values.requestUser}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row style={style}>
                    <Col lg={3} xs={3} md={3}>
                        <Form.Label>Request Time From</Form.Label>
                        <DateGroup
                            id="startTime"
                            name="startTime"
                            enableTime={true}
                            step={15}
                            value={values.startTime ? new Date(values.startTime) : null}
                            onChange={(e) => setFieldValue("startTime", e)}
                            defaultCurrentDate={moment().startOf("day").toDate()}
                            errors={errors}
                            touched={touched}
                            onBlur={handleBlur}
                        />
                    </Col>
                    <Col lg={3} xs={3} md={3}>
                        <Form.Label>Request Time To</Form.Label>
                        <DateGroup
                            id="endTime"
                            name="endTime"
                            enableTime={true}
                            step={15}
                            value={values.endTime ? new Date(values.endTime) : null}
                            onChange={(e) => setFieldValue("endTime", e)}
                            defaultCurrentDate={moment().startOf("day").toDate()}
                            errors={errors}
                            touched={touched}
                            onBlur={handleBlur}
                        />
                    </Col>
                    <div className="form-group col-auto align-self-end" >
                        <button
                            className="Filter-Button"
                            style={{ width: "100px" }}
                            onClick={() => {
                                handleSubmit();
                            }}>Filter</button>
                    </div>
                    <div className="form-group col-auto align-self-end">
                        <ButtonSecondary
                            style={{ width: "100px" }}
                            text="Reset"
                            type="button"
                            onClick={() => {
                                values.action = Reset_Action;
                                handleSubmit();
                            }}
                        />
                    </div>

                </Row>
            </form>
        </div>
    );
}

const AdminRequestsFilter = withFormik({
    mapPropsToValues: (props) => {
        return { ...props.filter }
    },

    validate: (values) => {
        const errors = {};
        if (values.startTime !== null && values.endTime !== null
            && values.startTime >= values.endTime)
            errors.startTime = 'Start date must be before End Date.';
        return errors;
    },

    handleSubmit: (values, formikBag) => {
        const { props } = formikBag;

        if (values.action === Reset_Action) {
            values.requestName = null;
            values.requestUser = null;
            values.startTime = null;
            values.endTime = null;
            values.statusesList = null;
        }

        let searchState = {}
        if (!!values.requestName) searchState["requestName"] = values.requestName
        if (!!values.requestUser) searchState["requestUser"] = values.requestUser
        if (!!values.startTime) searchState["startTime"] = values.startTime
        if (!!values.endTime) searchState["endTime"] = values.endTime
        if (!!values.statusesList) searchState["statusesList"] = values.statusesList ? values.statusesList.map(status => status.id) : null

        props.router.history.push(`/admin-requests?${querystring.stringify(searchState)}`);
    },

})(BasicAdminRequestsFilter)

export default AdminRequestsFilter;