import React, { Component } from "react";
import { withFormik } from "formik";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ButtonMain from "../core/form/ButtonMain";
import ButtonBorderless from "../core/form/ButtonBorderless";
import { Container, Row, Col } from "react-bootstrap";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import { parseFormValue } from "../../modules/util";
import {
  DEFAULT,
  OVER_NIGHT_INDEX_SWAP,
  FORWARD_RATE_AGREEMENT_SWAP,
  FORWARD_STARTING_INTEREST_RATE_SWAP,
  BASIS_SWAP,
  INTEREST_RATE_SWAP,
} from "../../constants/strings";
import DateTimeParser from "../../modules/dateTimeParser";
import "../../App.css";
import { hasWriteAccess } from "../../services/TokenService";
import * as Yup from "yup";
import InstrumentStaticData from "../../modules/instrumentStaticData";
import querystring from 'query-string';
import { getTrade } from '../../actions/timeSales';
import { Env } from "../../constants/environment";

const mapDispatchToProps = (dispatch) => ({
  getTrade: (tradeid) => {
    dispatch(getTrade(tradeid))
  },
})

class BasicTradeDetailsForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props)

    const queryParams = querystring.parseUrl(window.location.href);
    const paths = queryParams.url.split('/');
    const tradeId = paths[paths.length - 2];
    this.state = {
      ...this.state,
      tradeId: tradeId,
    }
  }

  componentDidMount() {
    this.props.getTrade(this.state.tradeId)
  }

  shouldHideSwapDetails() {
    let shouldHide = Env.getEnv("REACT_APP_HIDE_SWAP_INFO_ON_TRADE_DETAILS");
    if (!shouldHide) return false;

    if (shouldHide.toString().toLowerCase() === "true" || shouldHide === 1)
      return true;

    return false;
  }

  isInstrumentSwapType() {
    const instrumentType = this.props.values.instrument.type;
    const swapInstruments = [
      OVER_NIGHT_INDEX_SWAP,
      FORWARD_STARTING_INTEREST_RATE_SWAP,
      BASIS_SWAP,
      FORWARD_RATE_AGREEMENT_SWAP,
      INTEREST_RATE_SWAP,
    ];
    return swapInstruments.includes(instrumentType);
  }

  getStartDate(instrument) {
    if (!instrument) return "";
    return instrument.startDate
      ? DateTimeParser.toDisplay(instrument.startDate)
      : null;
  }

  getFirstFloatFixingDate(instrument) {
    if (!instrument) return "";
    return instrument.firstFloatFixingDate
      ? DateTimeParser.toDisplay(instrument.firstFloatFixingDate)
      : null;
  }

  getSwapEffectiveDate(instrument) {
    if (!instrument) return "";
    return instrument.swapEffectiveDate
      ? DateTimeParser.toDisplay(instrument.swapEffectiveDate)
      : null;
  }

  getMaturityDate(instrument) {
    if (!instrument) return "";
    return instrument.maturityDate
      ? DateTimeParser.toDisplay(instrument.maturityDate)
      : null;
  }

  hasEditAccess() {
    if (this.isInstrumentSwapType() && hasWriteAccess()) return true;
    return false;
  }

  render() {
    const { values, handleSubmit } = this.props;

    if (!values.tradeId) return null;

    const reportingCounterparty = InstrumentStaticData.SideOptions.filter(
      (item) => item.id === values.reportingCounterparty
    );
    const reportingCounterPartyName =
      Array.isArray(reportingCounterparty) && reportingCounterparty.length > 0
        ? reportingCounterparty[0].name
        : "";

    let reportingCounterPartyText = ''
    if (parseFormValue(values.aggressorSide) === reportingCounterPartyName) {
      reportingCounterPartyText = `${reportingCounterPartyName} (${values.aggressorFirm ? values.aggressorFirm.displayName : ""})`
    }
    if (parseFormValue(values.passiveSide) === reportingCounterPartyName) {
      reportingCounterPartyText = `${reportingCounterPartyName} (${values.passiveFirm ? values.passiveFirm.displayName : ""})`
    }
    return (
      <React.Fragment>
        <form name="tradeDetailsForm" onSubmit={handleSubmit}>
          <h1>Trade Details</h1>
          <Container name="tradeDetailsContainer">
            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="instrumentID"
                  label="Instrument ID"
                  value={parseFormValue(values.instrument.id)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="instrumentType"
                  label="Instrument Type"
                  value={parseFormValue(
                    values.instrument.type ? values.instrument.type : DEFAULT
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="instrumentStartDate"
                  label="Instrument Start Date"
                  value={parseFormValue(this.getStartDate(values.instrument))}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="instrumentCurrency"
                  label="Instrument Currency"
                  value={parseFormValue(values.instrument.basecurrency)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="instrumentDescription"
                  label="Instrument Descriptions"
                  value={parseFormValue(values.instrument.description)}
                />
              </Col>

              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="lastTransTime"
                  label="Last Transaction Time"
                  value={parseFormValue(values.transactTime)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorTransactTradeDate"
                  label="Trade Date"
                  value={values.aggressorTransactTradeDate}
                />
              </Col>
            </Row>

            <hr />

            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="tradeId"
                  label="System Trade ID"
                  value={parseFormValue(values.tradeId)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="tradeQty"
                  label="Trade Notional"
                  value={parseFormValue(values.tradeQty)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="tradeStatus"
                  label="Trade Status"
                  value={parseFormValue(values.status)}
                />
              </Col>

              {!this.isInstrumentSwapType() ? null : this.shouldHideSwapDetails() ? null : (
                <Col lg={3} xs={3} md={3} className="non-printable">
                  <FieldGroupReadOnly
                    id="reportingCounterparty"
                    label="Reporting Counterparty"
                    value={reportingCounterPartyText}
                  />
                </Col>
              )}

            </Row>

            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="blockTradeIndicator"
                  label="Block Trade Indicator"
                  value={parseFormValue(values.blockTradeIndicator)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="transactionBookedTime"
                  label="Transaction Booked Time"
                  value={parseFormValue(values.transactionBookedTime)}
                />
              </Col>
              <Col lg={6} xs={6} md={6}>
                <FieldGroupReadOnly
                  id="tradeText"
                  label="Text Details (Optional)"
                  value={parseFormValue(values.text)}
                />
              </Col>
            </Row>


            {!this.isInstrumentSwapType() ? null : this.shouldHideSwapDetails() ? null : (<>
              <hr />
              <Row>
                <React.Fragment>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly
                      id="swapEffectiveDate"
                      label="Swap Effective Date"
                      value={parseFormValue(
                        this.getSwapEffectiveDate(values.instrument)
                      )}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly
                      id="swapMaturityDate"
                      label="Swap Maturity Date"
                      value={parseFormValue(
                        this.getMaturityDate(values.instrument)
                      )}
                    />
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <FieldGroupReadOnly
                      id="floatFirstFixingDate"
                      label="Float First Fixing Date"
                      value={parseFormValue(
                        this.getFirstFloatFixingDate(values.instrument)
                      )}
                    />
                  </Col>
                </React.Fragment>
              </Row>
            </>
            )}

            <hr />

            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressor"
                  label="Aggressor User"
                  value={parseFormValue(
                    values.aggressorParticipantObj
                      ? values.aggressorParticipantObj.displayName
                      : ""
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorAccnt"
                  label="Aggressor Account"
                  value={parseFormValue(
                    values.aggressorAccountObj
                      ? values.aggressorAccountObj.displayName
                      : ""
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorFirm"
                  label="Aggressor Firm"
                  value={parseFormValue(
                    values.aggressorFirm ? values.aggressorFirm.displayName : ""
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorFirmLEI"
                  label="Aggressor Firm LEI"
                  value={parseFormValue(
                    values.aggressorFirm && values.aggressorFirm.attributes
                      ? values.aggressorFirm.attributes.legalEntityIdentifier
                      : ""
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorClientUserId"
                  label="Aggressor Client User ID"
                  value={parseFormValue(values.aggressorClientParticipantId)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorClientAccountId"
                  label="Aggressor Client Account ID"
                  value={parseFormValue(values.aggressorClientAccountId)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorOrderID"
                  label="Aggressor OrderID"
                  value={parseFormValue(values.aggressorId)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorParentOrderID"
                  label="Aggressor Parent OrderID"
                  value={parseFormValue(values.aggressorParentOrderId)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorSide"
                  label="Aggressor Side"
                  value={parseFormValue(values.aggressorSide)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorPrice"
                  label="Aggressor Price"
                  value={parseFormValue(values.aggressorLastPrice)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorAgentUser"
                  label="Aggressor Agent User"
                  value={parseFormValue(
                    values.aggressorSubmittingParticipantObj
                      ? values.aggressorSubmittingParticipantObj.displayName
                      : values.aggressorSubmittingParticipant
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorAgentFirm"
                  label="Aggressor Agent Firm"
                  value={parseFormValue(
                    values.aggressorSubmittingParticipantObj
                      ? values.aggressorSubmittingParticipantObj.firm.displayName
                      : ""
                  )}
                />
              </Col>

              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorSymbolSubType"
                  label="Aggressor Sub Type"
                  value={parseFormValue(values.aggressorSymbolSubType)}
                />
              </Col>

              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorCommissionNotionalCollected"
                  label="Aggressor Commission Notional Collected"
                  value={parseFormValue(values.aggressorCommissionNotionalCollected)}
                />
              </Col>
            </Row>

            {(!!values.aggressorAccountObj && !!values.aggressorAccountObj?.legPricesList?.length > 0) && (
              <>
                <hr />
                {values.aggressorAccountObj.legPricesList.map((legPrice, idx) => (
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        id="leg-symbol"
                        label={"Aggressor Symbol " + (idx + 1)}
                        value={legPrice.symbol}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        id="aggressor-side"
                        label="Aggressor Side"
                        value={legPrice.sideName}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        id="aggressor-qty"
                        label="Aggressor Quantity"
                        value={legPrice.qty}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        id="aggressor-price"
                        label="Aggressor Price"
                        value={parseFormValue(legPrice.tradePrice)}
                      />
                    </Col>
                  </Row>
                ))}
              </>
            )}

            <hr />

            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passive"
                  label="Passive User"
                  value={parseFormValue(
                    values.passiveParticipantObj
                      ? values.passiveParticipantObj.displayName
                      : ""
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveaccnt"
                  label="Passive Account"
                  value={parseFormValue(
                    values.passiveAccountObj
                      ? values.passiveAccountObj.displayName
                      : ""
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveFirm"
                  label="Passive Firm"
                  value={parseFormValue(
                    values.passiveFirm ? values.passiveFirm.displayName : ""
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveFirmLEI"
                  label="Passive Firm LEI"
                  value={parseFormValue(
                    values.passiveFirm && values.passiveFirm.attributes
                      ? values.passiveFirm.attributes.legalEntityIdentifier
                      : ""
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveClientUserId"
                  label="Passive Client User ID"
                  value={parseFormValue(values.passiveClientParticipantId)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveClientAccountId"
                  label="Passive Client Account ID"
                  value={parseFormValue(values.passiveClientAccountId)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveOrderID"
                  label="Passive OrderID"
                  value={parseFormValue(values.passiveId)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveParentOrderID"
                  label="Passive Parent OrderID"
                  value={parseFormValue(values.passiveParentOrderId)}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveside"
                  label="Passive Side"
                  value={parseFormValue(values.passiveSide)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passivePrice"
                  label="Passive Price"
                  value={parseFormValue(values.passiveLastPrice)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveAgentUser"
                  label="Passive Agent User"
                  value={parseFormValue(
                    values.passiveSubmittingParticipantObj
                      ? values.passiveSubmittingParticipantObj.displayName
                      : values.passiveSubmittingParticipant
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveAgentFirm"
                  label="Passive Agent Firm"
                  value={parseFormValue(
                    values.passiveSubmittingParticipantObj
                      ? values.passiveSubmittingParticipantObj.firm.displayName
                      : ""
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveSymbolSubType"
                  label="Passive Sub Type"
                  value={parseFormValue(values.passiveSymbolSubType)}
                />
              </Col>

              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveCommissionNotionalCollected"
                  label="Passive Commission Notional Collected"
                  value={parseFormValue(values.passiveCommissionNotionalCollected)}
                />
              </Col>
            </Row>

            {(!!values.passiveAccountObj && !!values.passiveAccountObj?.legPricesList?.length > 0) &&
              <>
                <hr />
                {values.passiveAccountObj.legPricesList.map((legPrice, idx) => (
                  <Row>
                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        id="passive-symbol"
                        label={"Passive Symbol " + (idx + 1)}
                        value={legPrice.symbol}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        id="passive-side"
                        label="Passive Side"
                        value={legPrice.sideName}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        id="passive-qty"
                        label="Passive Quantity"
                        value={legPrice.qty}
                      />
                    </Col>

                    <Col lg={3} xs={3} md={3}>
                      <FieldGroupReadOnly
                        id="passive-price"
                        label="Passive Price"
                        value={parseFormValue(legPrice.tradePrice)}
                      />
                    </Col>
                  </ Row>
                ))}
              </>
            }

            <hr />

            <Row className="non-printable" >
              <Col>
                {this.hasEditAccess() ? (
                  <ButtonMain text="SAVE" type="Submit" />
                ) : null}
                <ButtonBorderless
                  type="button"
                  text="Cancel"
                  icon="times-circle"
                  customClassName="grey-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    this.context.router.history.push("/timeandsales");
                  }}
                />
              </Col>
              <Col lg={3} xs={3} md={3} className="text-right">
                <ButtonMain
                  text="PRINT"
                  onClick={(e) => {
                    e.preventDefault();
                    window.print();
                  }}
                />
              </Col>
            </Row>
          </Container>
        </form>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    trade: state.timeSales.trade
  };
}

const TradeDetailsForm = withFormik({
  validationSchema: Yup.object().shape({
    reportingCounterparty: Yup.string()
      .nullable()
      .required("Reporting Counter Party is required."),
  }),
  mapPropsToValues: (trade) => ({
    ...trade.trade,
  }),
  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onSubmitForm(values.tradeId, values.reportingCounterparty);
  },
  displayName: "trade-details-form",
  enableReinitialize: true,
})(BasicTradeDetailsForm);

export default connect(mapStateToProps, mapDispatchToProps)(TradeDetailsForm);
