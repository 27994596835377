import apiCallPromise from "../modules/apiCallPromise";
import ProtobufParser from "../modules/protobufParser";
import QuoteMessageType from "../entities/dto/QuoteMessageType";
import {apiUrl} from "../modules/util";

const {
  ListQuotesRequest, ListQuoteHistoryRequest
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");
const {
  AdminAPIPromiseClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_grpc_web_pb.js");

const client = new AdminAPIPromiseClient(
  apiUrl(),
  null,
  null
);

export default class QuoteService {
  static listQuotes(dataRequest) {
    return new Promise((resolve, reject) => {
      let { filters, pageToken } = dataRequest;
      var request = new ListQuotesRequest();
      filters.messageType === QuoteMessageType.All
        ? request.setPageSize(5)
        : request.setPageSize(10);
      if (filters.fromDate)
        request.setStartTime(
          ProtobufParser.toTimestamp(new Date(filters.fromDate))
        );
      if (filters.toDate)
        request.setEndTime(
          ProtobufParser.toTimestamp(new Date(filters.toDate))
        );
      if (filters.participant) request.setUser(filters.participant);
      if (filters.account) request.setAccount(filters.account);
      if (filters.instrumentId) request.setSymbol(filters.instrumentId);
      if (filters.qid) request.setQuoteId(filters.qid);
      if (filters.rfqid) request.setQuoteRequestId(filters.rfqid);
      if (filters.clientId) request.setClientId(filters.clientId);
      if (filters.side) request.setSide(filters.side);
      if (filters.qStateFilter) request.setStatusesList([filters.qStateFilter]);
      if (filters.firm) request.setFirm(filters.firm);
      if (pageToken) request.setPageToken(pageToken);

      apiCallPromise(client, "listQuotes", request)
        .catch((error) => {
          reject(error);
        })
        .then((response) => {
          resolve(response);
        });
    });
  }

  static searchQuoteHistory(dataRequest) {
    return new Promise((resolve, reject) => {
      let { filters, pageToken } = dataRequest;
      var request = new ListQuoteHistoryRequest();
      request.setPageSize(10);
      if (filters.rfqId) request.setRfqId(filters.rfqId);
      if (filters.rfqName) request.setRfqName(filters.rfqName);
      if (pageToken) request.setPageToken(pageToken);

      apiCallPromise(client, "listQuoteHistory", request)
        .catch((error) => {
          reject(error);
        })
        .then((response) => {
          resolve(response);
        });
    });
  }

}
