import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import FieldGroup from "../core/form/FieldGroup";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import { Container, Row, Col } from "react-bootstrap";
import ButtonMain from "../core/form/ButtonMain";
import ButtonBorderless from "../core/form/ButtonBorderless";
import FirmStateListContainer from "../../containers/FirmStateListContainer";

export class BasicFirmServiceForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const {
      title,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue
    } = this.props;

    return (
      <form onSubmit={handleSubmit} name="firmServiceForm">
        <h1>{title}</h1>
        <Container>
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FieldGroup
                id="id"
                name="id"
                type="text"
                label="Firm Service Name"
                placeholder=""
                isRequired={true}
                errors={errors}
                touched={touched}
                value={values.id}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <FieldGroupReadOnly
            id="firmType"
            name="firmType"
            label="Firm Type"
            value="Supervisor"
          />
          <Row>
            <Col lg={6} xs={6} md={12}>
              <FirmStateListContainer
                id="state"
                name="state"
                label="Firm state"
                value={values.state ? values.state.id : null}
                enabled={true}
                onChange={e => {
                  setFieldValue("state", e);
                }}
              />
              {errors && errors["state"] && (
                <div className="form-error">
                  <i
                    className="fa fa-exclamation-triangle orange-icon"
                    aria-hidden="true"
                  ></i>{" "}
                  {errors["state"]}
                </div>
              )}
            </Col>
          </Row>
          <ButtonMain type="submit" text="SAVE" />
          <ButtonBorderless
            type="button"
            text="Cancel"
            icon="times-circle"
            customClassName="grey-icon"
            onClick={e => {
              e.preventDefault();
              this.context.router.history.goBack();
            }}
          />
        </Container>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    isEditing: !state.firms.isCreating
  };
}

const FirmServiceForm = withFormik({
  mapPropsToValues: item => ({
    ...item.item
  }),
  validationSchema: Yup.object().shape({
    id: Yup.string()
      .nullable()
      .required("Firm name is required.")
  }),
  validate: values => {
    let errors = {};
    if (!values.state) {
      errors.state = "State must be defined.";
    }
    return errors;
  },
  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onSubmitForm(values, setFieldError);
  },
  displayName: "firm-form",
  enableReinitialize: true
})(BasicFirmServiceForm);

export default connect(mapStateToProps, null)(FirmServiceForm);
