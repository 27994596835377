export const RESET_TIME_SALES_TOKENS = 'RESET_TIME_SALES_TOKENS';
export const FETCH_TIME_SALES_START = 'FETCH_TIME_SALES_START';
export const FETCH_TIME_SALES_SUCCEED = 'FETCH_TIME_SALES_SUCCEED';
export const FETCH_TIME_SALES_FAILED = 'FETCH_TIME_SALES_FAILED';
export const REFRESH_TIME_SALES_ITEMS = 'REFRESH_TIME_SALES_ITEMS';
export const FETCH_TIME_SALES_REPORT_START = 'FETCH_TIME_SALES_REPORT_START';
export const FETCH_TIME_SALES_REPORT_SUCCEED = 'FETCH_TIME_SALES_REPORT_SUCCEED';
export const FETCH_TIME_SALES_REPORT_FAILED = 'FETCH_TIME_SALES_REPORT_FAILED';
export const FETCH_TRADE_FAILED = 'FETCH_TRADE_FAILED';
export const FETCH_TRADE_SUCCEED = 'FETCH_TRADE_SUCCEED';
export const FETCH_TRADE_START = 'FETCH_TRADE_START';
export const TRADE_ITEM = 'TRADE_ITEM';