import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { convertScaledPriceToInt, isEmptyString, isNullOrUndefined, validScaledPrice } from "../../modules/util";
import { Col, Row, } from "react-bootstrap";
import Notification from "../../modules/notifications";
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import AccountService from "../../services/AccountService";
import AutoComplete from "../core/auto-complete/AutoComplete";
import { FormGroup, FormLabel, Container } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import { EVENT } from '../../constants/strings';
import DropdownListGroup from '../core/form/DropdownListGroup';

export const AdjustPositionForm = (props) => {

    const [symbol, setSymbol] = useState();
    const [value, setValue] = useState("");
    const [instrumentIds, setInstrumentIds] = useState([])
    const [subType, setSubType] = useState();
    const [instrument, setInstrument] = useState("");
    const [description, setDescription] = useState("");
    const [subTypes, setSubTypes] = useState([]);

    useEffect(() => {
        setSymbol(props.symbol);
        setValue(props.defaultValue);
        setInstrumentIds(getInstrumentIds(props.instruments));
        setSubType(props.symbolSubType);
        let inst = getInstrument(props.symbol)
        setInstrument(inst);
        setDescription("");
        setSubTypesArray(inst);
    }, [props.symbol, props.defaultValue, props.instruments]);

    const getInstrumentIds = (instruments) => {
        const instrumentIds = instruments.map(inst => inst.id)
        return instrumentIds;
    }

    const { show, reload, scales, account, onComplete, instruments } = props;
    const valid = (Array.isArray(instruments) && instrumentIds.includes(symbol));

    const getInstrument = (symbol) => {
        const instruments = props.instruments;
        if (Array.isArray(instruments)) {
            const inst = instruments.find(inst => {
                return inst.id === symbol
            });

            return inst;
        }
    }

    const setSubTypesArray = (inst) => {
        if (!!inst && !!inst.subTypesList && inst.subTypesList.length > 0) {
            var subtypes = [];
            for (let i = 0; i < inst.subTypesList.length; i++) {
                subtypes.push(inst.subTypesList[i].symbol);
            }
            setSubTypes(subtypes);
        } else {
            setSubTypes([]);
        }
    }

    const setSelectedSymbol = (symbol) => {
        const inst = getInstrument(symbol);

        if (!!inst) {
            setInstrument(inst)
            setSubType("");
            setSubTypesArray(inst);
        }

        setSymbol(symbol);
    }

    const resetForm = () => {
        setSymbol("");
        setValue("");
        setSubType("");
        setDescription("");
        setInstrument(null);
    }

    return (
        <ConfirmDialog
            show={!!show}

            proceed={(evt) => {
                if (!isNullOrUndefined(symbol)) {
                    const scale = !isNullOrUndefined(scales[symbol]) && !!scales[symbol] ? scales[symbol] : 1;
                    if (!validScaledPrice(value, scale)) {
                        Notification.error(value + " is an invalid quantity for " + symbol);
                        return
                    }

                    const scaledAdjustment = convertScaledPriceToInt(value, scale);

                    if (scaledAdjustment === 0){
                        Notification.error("Please provide valid open positions.");
                        return;
                    }

                    if (isEmptyString(description)) {
                        Notification.error("Please provide a description.");
                        return;
                    }

                    evt.target.disabled = true;

                    AccountService.adjustPosition(account, symbol, scaledAdjustment, subType, description, (err, response) => {
                        if (response) {
                            Notification.success("Position adjusted!");
                            reload();
                        }
                        if (err) {
                            Notification.error(err.message);
                        }

                        resetForm();
                        onComplete();
                    });
                }
            }}

            cancel={() => {
                resetForm();
                onComplete();
            }}

            confirmation={
                <Container>
                    <Row>
                        <Col>
                            <FormGroup>
                                <FormLabel>Symbol</FormLabel>
                                <AutoComplete
                                    id="symbol"
                                    placeholder="Enter Symbol"
                                    noItemsMessage="No results found"
                                    dataSource={instrumentIds}
                                    defaultValue={symbol}
                                    onChange={(e) => setSelectedSymbol(e.target.value)}
                                    onEnter={(symbol) => setSelectedSymbol(symbol)}
                                    onSelect={(symbol) => setSelectedSymbol(symbol)}
                                    disable={false}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FieldGroup
                                label="Change to Open Positions"
                                type="text"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                help='The increase or decrease to apply to the current Open Positions value for this Symbol. 
                                        For example entering "-10" will decrease the current Open Positions value by 10.'
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FieldGroup id="description" name="description" type="textarea" label="Description of Change"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        onBlur={(e) => setDescription(e.target.value)}
                                        help={"This description will show in the Ledger entry for this change."}
                            />
                        </Col>
                    </Row>

                    {(!!instrument && !!subTypes && subTypes.length > 0 && (instrument.type === EVENT || (!!subType && !isEmptyString(subType)))) &&
                        <Row>
                            <Col>
                                <DropdownListGroup
                                    id="subType"
                                    name="subType"
                                    label="Sub Type"
                                    value={subType}
                                    onChange={(e) => setSubType(e)}
                                    data={subTypes}
                                />
                            </Col>
                        </Row>
                    }
                </Container>
            }

            options={{
                title: "Adjust Position",
                okButtonText: valid ? "Submit" : null,
                cancelButtonText: "Cancel",
            }}
        />
    );

}

AdjustPositionForm.propTypes = {
    show: PropTypes.bool.isRequired,
    instruments: PropTypes.array.isRequired,
    account: PropTypes.string,
    symbol: PropTypes.string,
    defaultValue: PropTypes.string,
    reload: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
}

export default AdjustPositionForm;