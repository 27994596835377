import Execution from "./Execution";
import State from "./dto/State";
import { capitalizeWord, getEnumName } from "../modules/util";
import { TradeState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/trades/v1beta1/trades_pb";

export default class Trade {
  constructor(item) {
    if (item) {
      this.id = item.getId();
      this.text = item.getText();
      this.aggressor = new Execution(item.getAggressor());
      this.passive = new Execution(item.getPassive());

      const stateValue = item.getState();
      let state = new State();
      state.id = stateValue;
      state.name = capitalizeWord(getEnumName(TradeState, stateValue));
      this.state = state;
      this.reportingCounterparty = item.toObject().reportingCounterparty;
      return;
    }
    this.id = null;
    this.aggressor = null;
    this.passive = null;
    this.state = null;
    this.reportingCounterparty = null;
    this.text = null;
  }
}
