import React, { useEffect, useState } from 'react';
import { Col, Row, } from "react-bootstrap";
import Notification from "../../modules/notifications";
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import AccountService from "../../services/AccountService";
import AutoComplete from "../core/auto-complete/AutoComplete";
import { FormGroup, FormLabel, FormControl, Container } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import { isNumber } from "../../modules/util";

export default function CapitalReqForm(props) {
    const { show, reload, account, onComplete, dataSourceOptions } = props;
    const [currency, setCurrency] = useState(props.currency);
    const [capital, setCapital] = useState(props.defaultValue);
    const [description, setDescription] = useState("");
    const [alternateCurrency, setAlternateCurrency] = useState(props.alternateCurrency);

    useEffect(() => {
        setCapital(props.defaultValue);
        setCurrency(props.currency);
        setAlternateCurrency(props.alternateCurrency);
    }, [props.currency, props.defaultValue, props.alternateCurrency]);

    const valid = (isNumber(capital)
        && Array.isArray(dataSourceOptions)
        && dataSourceOptions.includes(currency)
        && (dataSourceOptions.includes(alternateCurrency) || alternateCurrency === null || alternateCurrency.length === 0)
        && description.trim().length > 0);
 
    return (
        <ConfirmDialog
            show={!!show}

            proceed={() => {
                AccountService.setCapitalRequirement(account, currency, capital, description, alternateCurrency, (err, response) => {
                    if (response) {
                        Notification.success("Capital set!");
                        reload();
                    }
                    if (err) {
                        Notification.error(err.message);
                    }
                });

                setDescription("");
                onComplete();
            }}

            cancel={() => {
                setDescription("");
                onComplete();
            }}

            confirmation={
                < Container >
                    <Row>
                        <Col>
                            <FormGroup>
                                <FormLabel>Currency</FormLabel>
                                <AutoComplete
                                    id="symbol"
                                    placeholder="Enter Currency"
                                    noItemsMessage="No results found"
                                    dataSource={dataSourceOptions}
                                    defaultValue={currency}
                                    disable={false}
                                    onChange={(e) => setCurrency(e.target.value)}
                                    onEnter={(value) => setCurrency(value)}
                                    onSelect={(value) => setCurrency(value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <FormLabel>Currency of Capital Requirement (optional)</FormLabel>
                                <AutoComplete
                                    id="altCurrency"
                                    placeholder="Enter Currency"
                                    noItemsMessage="No results found"
                                    dataSource={dataSourceOptions}
                                    defaultValue={alternateCurrency}
                                    disable={false}
                                    onChange={(e) => setAlternateCurrency(e.target.value)}
                                    onEnter={(value) => setAlternateCurrency(value)}
                                    onSelect={(value) => setAlternateCurrency(value)}
                                    help={"This will set the capital requirement in an alternate currency and use the exchange rate from an existing EP3 Forex instrument"}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <FormLabel>Capital Requirement{!!alternateCurrency ? " (in " + alternateCurrency + ")":""}</FormLabel>
                                <FormControl
                                    readOnly={false}
                                    type="text"
                                    value={capital}
                                    onChange={(e) => setCapital(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FieldGroup id="description" name="description" type="textarea" label="Description of Change"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                onBlur={(e) => setDescription(e.target.value)}
                                help={"This description will show in the Ledger entry for this change."}
                            />
                        </Col>
                    </Row>

                </Container >
            }

            options={{
                title: "Set Capital Requirement",
                okButtonText: valid ? "Submit" : null,
                cancelButtonText: "Cancel",
            }}
        />
    );
}
