import React from 'react';
import PropTypes from 'prop-types';
import './SearchNoItemsResult.css';

const SearchNoItemsResult = ({
  message
}) => (
    <div className="search-wrapper">
      <i className="fa circle-background fa-search fa-7x orange-icon"></i>
      <div className="search-text">
        {message}
      </div>
    </div>
  );

SearchNoItemsResult.propTypes = {
  message: PropTypes.string.isRequired
}

export default SearchNoItemsResult;