import { protoDateToDate } from "../actions/ptypes";

export default class UserAttributes {
  constructor(item) {
    if (item) {
      this.legalName = item.getLegalName();
      this.title = item.getTitle();
      this.phoneNumber = item.getPhoneNumber();
      this.email = item.getEmail();
      const dob = item.getDateOfBirth();
      this.dateOfBirth = dob ? protoDateToDate(dob) : null;
      this.registrationNumber = item.getRegistrationNumber();
      this.address = item.getAddress();
      this.description = item.getDescription();
      this.oauthId = item.getOauthId();
      this.legalEntityIdentifier = item.getLegalEntityIdentifier();
      this.beneficialOwnershipAttribute = item.getBeneficialOwnershipAttributesList();

      return;
    }
    this.legalName = null;
    this.title = null;
    this.phoneNumber = null;
    this.email = null;
    this.dateOfBirth = null;
    this.registrationNumber = null;
    this.address = null;
    this.description = null;
    this.legalEntityIdentifier = null;
  }
}
