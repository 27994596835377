export const FETCH_PARTICIPANTS_START = "FETCH_PARTICIPANTS_START";
export const FETCH_PARTICIPANTS_SUCCEED = "FETCH_PARTICIPANTS_SUCCEED";
export const FETCH_PARTICIPANTS_FAILED = "FETCH_PARTICIPANTS_FAILED";
export const FETCH_PARTICIPANT_START = "FETCH_PARTICIPANT_START";
export const FETCH_PARTICIPANT_SUCCEED = "FETCH_PARTICIPANT_SUCCEED";
export const FETCH_PARTICIPANT_FAILED = "FETCH_PARTICIPANT_FAILED";
export const CLEAN_PARTICIPANT = "CLEAN_PARTICIPANT";
export const SAVE_PARTICIPANT_START = "SAVE_PARTICIPANT_START";
export const SAVE_PARTICIPANT_SUCCEED = "SAVE_PARTICIPANT_SUCCEED";
export const SAVE_PARTICIPANT_FAILED = "SAVE_PARTICIPANT_FAILED";
export const CREATE_PARTICIPANT_STATE = "CREATE_PARTICIPANT_STATE";
export const UPDATE_PARTICIPANT_ITEM = "UPDATE_PARTICIPANT_ITEM";
export const TOGGLE_SHOW_DELETED_PARTICIPANTS =
  "TOGGLE_SHOW_DELETED_PARTICIPANTS";
