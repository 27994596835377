import {
  FETCH_ORDER_HISTORY_START,
  REFRESH_ORDER_HISTORY,
  FETCH_ORDER_HISTORY_SUCCEED,
  FETCH_ORDER_HISTORY_FAILED,
  SEARCH_ORDER_HISTORY_NO_ITEMS,
  ADD_ORDER_HISTORY,
  RESET_FETCHING_STATUS,
  RESET_TOKENS

} from '../constants/orderTypes';
import {
  RESET,
} from '../constants/appTypes';

const initialState = {
  fetchingHistory: false,
  orderHistory: [],
  noItemsFound: false,
  tokens: {},
  pageToken: null
}

const orders = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_HISTORY_START:
      return {
        ...state,
        fetchingHistory: true,
        orderHistory: [],
        noItemsFound: false
      };
    case FETCH_ORDER_HISTORY_SUCCEED:
      return {
        ...state,
        fetchingHistory: false
      };
    case FETCH_ORDER_HISTORY_FAILED:
      return {
        ...state,
        fetchingHistory: false,
        orderHistory: [],
        noItemsFound: false
      };
    case RESET_TOKENS:
      return {
        ...state,
        tokens: {},
        pageToken: null,
        nextPageToken: null
      };

    case REFRESH_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.payload,
        pageToken: action.pageToken,
        tokens: { ...state.tokens, [action.pageToken]: action.nextPageToken }
      };
    case RESET_FETCHING_STATUS:
      return {
        ...state,
        fetchingHistory: false
      };
    case ADD_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: [...state.orderHistory, ...action.payload]
      };
    case SEARCH_ORDER_HISTORY_NO_ITEMS:
      return {
        ...state,
        noItemsFound: true
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    default:
      return state;
  }
}

export default orders;
