import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchInstruments2 } from "../../actions/instruments";
import { fetchProducts } from '../../actions/products';
import { WhitelistInstruments } from "./WhitelistInstruments";
import { WhitelistProducts } from "./WhitelistProducts";
import ButtonMain from "../core/form/ButtonMain";
import CheckboxGroup from "../core/form/CheckboxGroup";

function mapStateToProps(state) {
    return {
        account: state.accounts.account,
        whitelist: state.accounts.account.whitelist,
        instruments: state.instruments.instruments.map((i) => {
            var firm = state.firms.firms.find((f) => f.name === i.clearingHouse);
            i.clearingHouse = firm ? firm : i.clearingHouse;
            return i;
        }),
        fetchingProducts: state.fetchingProducts,
        products: state.products.products.map(i => {
            var firm = state.firms.firms.find(f => f.name === i.clearingHouse)
            i.clearingHouse = firm ? firm : i.clearingHouse
            return i
        }),
    };
}

const mapDispatchToProps = (dispatch) => ({
    fetchInstruments: (pageToken, pageSize, filterItems) => {
        dispatch(fetchInstruments2(pageToken, pageSize, filterItems));
    },
    fetchProducts: () => {
        dispatch(fetchProducts())
    },
});

export class WhitelistForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.selectedInstruments = this.props.account.whitelist ? this.props.account.whitelist.symbolsList : [];
        this.selectedProducts = this.props.account.whitelist ? this.props.account.whitelist.productsList : [];

        this.setState({
            enableWhitelist: !!this.props.whitelist?.enabled,
            selectedProducts: this.props.whitelist?.productsList || [],
            selectedInstruments: this.props.whitelist?.symbolsList || [],
        });
    }

    fetchInstruments = (pageToken) => {
        this.props.fetchInstruments("", 100, {});
    };

    componentDidMount() {
        this.fetchInstruments("");
        this.props.fetchProducts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.whitelist !== this.props.whitelist) {
            this.enableWhitelist = !!this.props.whitelist?.enabled;
            this.selectedProducts = this.props.whitelist?.productsList || [];
            this.selectedInstruments = this.props.whitelist?.symbolsList || [];

            this.setState({
                enableWhitelist: this.enableWhitelist,
                selectedProducts: this.selectedProducts,
                selectedInstruments: this.selectedInstruments,
            });
        }
    }


    updateAccountWhitelist() {
        if (this.props.onUpdateAccountWhitelist) {
            let data = {
                enableWhitelist: this.state.enableWhitelist,
                account: this.props.account.name,
                instruments: this.selectedInstruments,
                products: this.selectedProducts
            }
            this.props.onUpdateAccountWhitelist(data);
        }
    }

    onInstrumentSelect(symbols) {
        this.selectedInstruments = [...symbols];
    }

    onProductSelect(symbols) {
        this.selectedProducts = [...symbols];
    }

    titleStyle = {
        marginTop: '35px'
    }

    render() {
        return (
            <>
                <div style={this.titleStyle}>
                    <CheckboxGroup checked={this.state?.enableWhitelist} onClick={(e) => {
                        this.setState({ enableWhitelist: e.target.checked });
                    }}
                        label={"Enable Whitelisting"}
                    />
                </div>

                <div style={this.titleStyle}><h3>Products</h3></div>
                <div id="ProductsSelector">
                    <WhitelistProducts data={this.props.products} selectedItems={this.state?.selectedProducts} onRowSelect={(symbols) => { this.onProductSelect(symbols) }} />
                </div >

                <div style={this.titleStyle}><h3>Instruments</h3></div>
                <div id="InstrumentSelector">
                    <WhitelistInstruments data={this.props.instruments} selectedItems={this.state?.selectedInstruments} onRowSelect={(symbols) => { this.onInstrumentSelect(symbols) }} />
                </div >


                <div style={this.titleStyle}>
                    <ButtonMain onClick={(e) => { this.updateAccountWhitelist() }} text={"Whitelist Selected"} />
                </div>

            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WhitelistForm);