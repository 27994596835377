import React, { Component } from "react";
import { withFormik } from "formik";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ButtonMain from "../core/form/ButtonMain";
import ButtonBorderless from "../core/form/ButtonBorderless";
import { Container, Row, Col } from "react-bootstrap";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import { parseFormValue } from "../../modules/util";
import FieldGroup from "../core/form/FieldGroup";
import * as Yup from "yup";

class BasicTradeBustForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      values,
      touched,
      errors,
      handleSubmit,
      setFieldValue,
      handleChange,
      handleBlur
    } = this.props;

    if (!values.tradeCaptureId) return null;

    return (
      <React.Fragment>
        <form onSubmit={handleSubmit}>
          <h1>Bust Trade</h1>
          <Container>
            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="lastTransTime"
                  label="Last transaction Time"
                  value={parseFormValue(values.transactionTime)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="tradeId"
                  label="Trade Id"
                  value={parseFormValue(values.tradeId)}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="status"
                  label="Status"
                  value={parseFormValue(values.tradeStatus)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="tradeQty"
                  label="Trade Qty"
                  value={parseFormValue(values.tradeQty)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="tradePrice"
                  label="Trade Price"
                  value={parseFormValue(values.tradePrice)}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressor"
                  label="Aggressor"
                  value={parseFormValue(
                    values.aggressorParticipantObj
                      ? values.aggressorParticipantObj.displayName
                      : ""
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorSide"
                  label="Aggressor Side"
                  value={parseFormValue(values.aggressorSide)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="aggressorAccnt"
                  label="Aggressor Account"
                  value={parseFormValue(
                    values.aggressorAccountObj
                      ? values.aggressorAccountObj.displayName
                      : ""
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passive"
                  label="Passive"
                  value={parseFormValue(
                    values.passiveParticipantObj
                      ? values.passiveParticipantObj.displayName
                      : ""
                  )}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveside"
                  label="Passive Side"
                  value={parseFormValue(values.passiveSide)}
                />
              </Col>
              <Col lg={3} xs={3} md={3}>
                <FieldGroupReadOnly
                  id="passiveaccnt"
                  label="Passive Account"
                  value={parseFormValue(
                    values.passiveAccountObj
                      ? values.passiveAccountObj.displayName
                      : ""
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FieldGroup
                    id="bustReason"
                    name="bustReason"
                    type="text"
                    label="Bust Reason"
                    placeholder=""
                    errors={errors}
                    touched={touched}
                    isRequired={true}
                    value={values.bustReason || ''}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    help="Represents the reason for busting the trade"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonMain type="submit" text="BUST" />
                <ButtonBorderless
                  type="button"
                  text="Cancel"
                  icon="times-circle"
                  customClassName="grey-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    this.context.router.history.push("/timeandsales");
                  }}
                />
              </Col>
            </Row>
          </Container>
        </form>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    tradeCapture: state.trades.tradeCapture,
  };
}

const TradeBustForm = withFormik({
  mapPropsToValues: (tradeCapture) => ({
    ...tradeCapture.tradeCapture,
  }),
  validationSchema: Yup.object().shape({
    bustReason: Yup.string().required("Bust reason is required."),
  }),
  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onSubmitForm(values, setFieldError);
  },
  displayName: "trade-bust-form",
  enableReinitialize: true,
})(BasicTradeBustForm);

export default connect(mapStateToProps, null)(TradeBustForm);
