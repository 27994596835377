import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { logout } from '../../actions/auth';

import DefaultWrapper from './DefaultWrapper';
import NoAuth from './NoAuth';

class PrivateRoute extends Component {
  UNSAFE_componentWillMount() {
    const { auth, logout, history } = this.props;
    const { accessToken, refreshToken, expires } = auth;
    const isAuthenticated = refreshToken !== null && expires !== null && moment().isBefore(expires); // the user is logged in and token is not expired
    
    // session is no longer valid, force logout
    if(!isAuthenticated) {
      logout(accessToken, refreshToken, history);
    }
  }

  render() {
    const { component: Component, roles, only, ...rest } = this.props;

    let isAuthorized = true; // user has access to this route
    if(only && roles) { // if a list of roles were given
      // check to see if one of the users roles is in the list of given roles
      const authorized = (role) => {
        return roles[role] === true;
      }
      isAuthorized = only.some(authorized); // user has at least one matching role
    }

    return <Route exact {...rest} render={(props) => (
      isAuthorized
        ? <Component {...this.props} />
        : <DefaultWrapper {...this.props}><NoAuth/></DefaultWrapper>
    )} />
  }
};

const mapStateToProps = ({ auth, history }) => {
  return ({
    auth,
    history,
  });
};

export default connect(
  mapStateToProps,
  { logout },
)(PrivateRoute);