import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FieldGroup from '../core/form/FieldGroup';
import CheckboxGroup from '../core/form/CheckboxGroup';

class OrderLimitField extends Component {
  state = {
    isSet: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.setOrder !== this.props.setOrder) {
      this.setState({ isSet: nextProps.setOrder });
    }
  }

  onSetClick = (e) => {
    const isSet = e.target.checked;
    this.setState({ isSet });
    this.props.onSetClick(isSet);
  }

  render() {
    return (
      <div>
        <FieldGroup
          id={this.props.orderName}
          name={this.props.orderName}
          label={this.props.label}
          disabled={!this.state.isSet}
          value={!!this.state.isSet ? this.props.orderValue : ""}
          errors={this.props.errors}
          touched={this.props.touched}
          setFieldValue={this.props.setFieldValue}
          onChange={this.props.onChange}
          onBlur={this.props.handleBlur}
        />
        <CheckboxGroup
          checked={this.state.isSet}
          onClick={(e) => { this.onSetClick(e) }}
          label={this.state.isSet ? 'Set' : 'Not Set'}
        />
      </div>
    )
  }
}

OrderLimitField.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  orderName: PropTypes.string.isRequired,
  orderValue: PropTypes.number,
  setOrder: PropTypes.bool,
  errors: PropTypes.any,
  touched: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSetClick: PropTypes.func.isRequired
};

export default OrderLimitField;