import React from "react";
import Tooltip from '../tooltip-html/tooltip-html';
import { isNullOrUndefined } from "../../../modules/util";

const GridHeaderLabel = ({
  id,
  label,
  tooltip,
  tooltipPlace
}) => {
  return (
    <>
      <span>{label}</span>
      {!isNullOrUndefined(tooltip) &&
        <Tooltip text={tooltip} id={`tip-${id}`} place={!!tooltipPlace ? tooltipPlace : 'top'} />
      }
    </>
  );
};

export default GridHeaderLabel;
