import React from "react";
import PropTypes from "prop-types";
import AccountForm from "./AccountForm";
import AccountsPositionsDataGrid from "./AccountsPositionsDataGrid";
import BalancesDataGrid from "./BalancesDataGrid";
import WhitelistForm from "./WhitelistForm";
import CommissionContainer from "../../containers/CommissionContainer"
import { FormModes } from "../../constants/enumerations";
import ParticipantsInAccountContainer from "../../containers/ParticipantsInAccountContainer";
import AccountLedger from "./AccountLedger";
import PositionLedger from "./PositionLedger"
import AssociatedAccounts from "./AssociatedAccounts"
import BankDetailsDataGrid from "./BankDetailsDataGrid"

const AccountDetails = ({
  onSubmitForm,
  onDisableAccount,
  onDelete,
  firm,
  mode,
  positions,
  pendingSettlements,
  reloadPositions,
  balances,
  reloadBalances,
  reloadCredits,
  withdrawals,
  onUpdate,
  accounts,
  account,
  history,
  onUpdateAccountWhitelist,
  onPositionExport,
}) => {

  let getTitle = (mode) => {
    let title;
    switch (mode) {
      case FormModes.edition:
        title = "Edit Account"
        break;
      case FormModes.creation:
        title = "Create Account"
        break;
      default:
        title = "View Account"
        break;
    }

    return title;
  }

  var form = null;
  switch (mode) {
    case FormModes.view:
    case FormModes.edition:
    case FormModes.creation:
      const isEditing = mode === FormModes.edition;
      form = (
        <AccountForm
          title={getTitle(mode)}
          isEditing={isEditing}
          mode={mode}
          onSubmitForm={onSubmitForm}
          onDisableAccount={onDisableAccount}
          onDelete={onDelete}
          firm={firm}
          onUpdate={onUpdate}
        />
      );
      break;
    case FormModes.users:
      form = <ParticipantsInAccountContainer />;
      break;
    case FormModes.position:
      form = <AccountsPositionsDataGrid data={positions} accounts={accounts} pendingSettlements={pendingSettlements} reload={reloadPositions} editable={true} onExport={onPositionExport}/>;
      break;
    case FormModes.balance:
      form = <BalancesDataGrid data={balances} withdrawals={withdrawals} accounts={accounts} reload={reloadBalances} reloadCredits={reloadCredits} editable={true} />;
      break;
    case FormModes.ledger:
      form = <AccountLedger data={balances} accounts={accounts} reload={reloadPositions} editable={true} />;
      break;
    case FormModes.positionLedger:
      form = <PositionLedger data={balances} accounts={accounts} reload={reloadPositions} editable={true} />;
      break;
    case FormModes.whitelist:
      form = <WhitelistForm onUpdateAccountWhitelist={onUpdateAccountWhitelist} account={account} editable={true} />;
      break;
    case FormModes.commissions:
      form = <CommissionContainer account={account} editable={true} />;
      break;
    case FormModes.association:
      form = <AssociatedAccounts account={account} editable={true} />;
      break;
    case FormModes.bankDetails:
      form = <BankDetailsDataGrid account={account} editable={true} onUpdate={onUpdate} />;
      break;
    default:
      break;
  }
  return form;
};

AccountDetails.propTypes = {
  mode: PropTypes.oneOf(Object.keys(FormModes)).isRequired,
  firm: PropTypes.object,
  onSubmitForm: PropTypes.func.isRequired,
};

export default AccountDetails;
