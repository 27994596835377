import {
    FETCH_ADMIN_REQUESTS_START,
    FETCH_ADMIN_REQUESTS_SUCCEED,
    FETCH_ADMIN_REQUESTS_FAILED,
    UPDATE_ADMIN_REQUESTS_START,
    UPDATE_ADMIN_REQUESTS_SUCCEED,
    UPDATE_ADMIN_REQUESTS_FAILED
} from "../constants/adminRequestsTypes";
import AdminRequestService from "../services/AdminRequestsService";
import AdminRequest from "../entities/AdminRequest";
import Notification from '../modules/notifications';

export const FetchAdminRequestsFailed = (error) => ({
    type: FETCH_ADMIN_REQUESTS_FAILED,
    payload: error,
});

export const FetchAdminRequestsStart = () => ({
    type: FETCH_ADMIN_REQUESTS_START,
});

export const FetchAdminRequestsSucceed = (payload) => ({
    type: FETCH_ADMIN_REQUESTS_SUCCEED,
    payload: payload,
});

export const UpdateAdminRequestStart = () => ({
    type: UPDATE_ADMIN_REQUESTS_START
})

export const UpdateAdminRequestSucceed = () => ({
    type: UPDATE_ADMIN_REQUESTS_SUCCEED
})

export const UpdateAdminRequestFailed = () => ({
    type: UPDATE_ADMIN_REQUESTS_FAILED
})

export const loadAdminRequests = (listAdminRequestsRequest, callback) => {
    return (dispatch) => {
        dispatch(FetchAdminRequestsStart());

        const cb = (err, response) => {
            if (!!response) {
                var requestList = response.getAdminRequestsList().map((adminRequest) => {
                    return new AdminRequest(adminRequest);
                });

                const isEOF = response.getEof();
                const nextPageToken = !isEOF ? response.getNextPageToken() : null;
                const pageToken = listAdminRequestsRequest.getPageToken();

                const payload = { requestList, pageToken, nextPageToken: nextPageToken}

                dispatch(FetchAdminRequestsSucceed(payload));
            }

            if (!!err) {
                dispatch(FetchAdminRequestsFailed(err));
            }

            if(!!callback) {
                callback(err, response);
            }
        };

        AdminRequestService.getAll(listAdminRequestsRequest, cb);
    };
}

export const updateAdminRequest = (id, approve, cb) => {
    return (dispatch) => {
        dispatch(UpdateAdminRequestStart);

        const serviceCB = (err, response) => {
            if (response) {
                dispatch(UpdateAdminRequestSucceed());
                Notification.success("Admin request changes saved.");
            }

            if (err) {
                dispatch(UpdateAdminRequestFailed())
                Notification.error(`Cannot update admin request. ${err.message}`);
            }
        
            if (!!cb){
                cb(err, response);
            }
        }

        AdminRequestService.updateAdminRequest(id, approve, serviceCB);
    }
}