import {
  BLOCK_TRADE_USERS_UPDATE,
  BLOCK_TRADE_BROKER_USERS_UPDATE,
} from '../constants/blockTradeTypes';

const initialState = {
  usersToAccounts: new Map(),
  accounts: new Map(),
  users: new Map(),
  firms: new Map(),
  firmsToUsers: new Map(),
  fetchAccountsUsers: false,
  brokerUsersToAccounts: new Map(),
  brokerAccounts: new Map(),
  brokerUsers: new Map(),
  brokerFirms: new Map(),
  brokerFirmsToUsers: new Map(),
};

const blockTrades = (state = initialState, action) => {

  switch (action.type) {

    case BLOCK_TRADE_USERS_UPDATE:
      return {
        ...state,
        users: action.payload,
      };

    case BLOCK_TRADE_BROKER_USERS_UPDATE:
      return {
        ...state,
        brokerUsers: action.payload,
      };

    default:
      return state;
  }
};

export default blockTrades;
