import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import Filter from "./Filter";
import DropdownListGroup from "../../core/form/DropdownListGroup";
import FieldGroup from "../../core/form/FieldGroup";
import { InstrumentType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import InstrumentStaticData from "../../../modules/instrumentStaticData";
import DateGroup from "../../core/form/DateGroup";
import { fetchMetadata } from "../../../actions/instruments";
import FirmsListContainer from "../../../containers/FirmsListContainer";
import { FirmType } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { WithEmptyOption } from "../../../modules/instrumentUtils";
import _ from "lodash";

class InstrumentsFilter extends Component {
  constructor() {
    super();
    this.state = { ...this.getDefaultState() };
    this.onApply = this.onApply.bind(this);
    this.onRemoveFilterItem = this.onRemoveFilterItem.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onFlip = this.onFlip.bind(this);
    this.oldState = {};
    this.fetchMetadata = false;
    this.currentFilterItems = {};
  }

  getDefaultState() {
    return {
      type: null,
      id: null,
      state: null,
      tradeDate: null,
      clearingHouse: null,
      basecurrency: null,
      tenor: null,
      floatingRateIndex: null,
      floatingRateIndexTenor: null,
      productName: null
    };
  }

  componentDidMount() {
    this.setState(this.props.filters);
    let currentFilters = {};

    if (this.props.filters) {
      Object.keys(this.props.filters).forEach((key) => {
        if (!_.isEmpty(this.props.filters[key])) {
          currentFilters[key] = this.props.filters[key];
        }
      });

      this.currentFilterItems = { ...currentFilters };
    }

    if (
      (!this.props.metadata || !this.props.metadata.currencies) &&
      !this.fetchingMetaData
    ) {
      this.fetchingMetaData = true;
      this.props.fetchMetadata();
    }
  }

  onFlip = (state) => {
    if (state.visible) {
      this.oldState = Object.assign({}, { ...this.state });
      this.dirtyFilterItems = { ...this.state.filterItems };
    } else {
      if (state.isDirty) {
        this.setState({ ...this.oldState });
      }
    }
  };

  updateFilter = (filter) => {
    let filterItems = this.state;
    if (
      filter.value === null ||
      filter.value === undefined ||
      filter.value.toString().trim() === ""
    ) {
      delete filterItems[filter.id];
    } else {
      filterItems[filter.id] = filter;
    }

    this.setState(filterItems);
  };

  onReset = () => {
    this.setState(this.getDefaultState());
    this.props.onApply(this.getDefaultState());
  };

  onApply = () => {
    if (this.props.onApply) {
      this.props.onApply(this.state);
    }
  };

  onRemoveFilterItem = (filterItemId) => {
    if (typeof filterItemId === 'object' && filterItemId.hasOwnProperty("id")) {
      delete this.state[filterItemId.id];
    } else {
      delete this.state[filterItemId];
    }

    this.setState(this.state, () => {
      this.props.onApply(this.state);
    });
  };

  render() {
    return (
      <Filter
        className="instruments-filter"
        onApply={this.onApply}
        onReset={this.onReset}
        onRemoveFilterItem={this.onRemoveFilterItem}
        filterItems={this.currentFilterItems}
        onFlip={this.onFlip}
      >
        <React.Fragment>
          <Container className="instruments-filter-body">
            <Row>
              <Col lg={4} xs={4} md={4}>
                <FieldGroup
                  id="productName"
                  name="productName"
                  type="text"
                  label="Product Name (ID)"
                  value={this.state.productName && this.state.productName.value}
                  errors={[]}
                  setFieldValue={() => { }}
                  onChange={(e) => {
                    this.updateFilter({
                      id: "productName",
                      value: e.target.value,
                      text: "Product Name",
                      name: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col lg={4} xs={4} md={4}>
                <FieldGroup
                  id="id"
                  name="id"
                  type="text"
                  label="Instrument ID (Symbol)"
                  value={this.state.id && this.state.id.value}
                  errors={[]}
                  setFieldValue={() => { }}
                  onChange={(e) => {
                    this.updateFilter({
                      id: "id",
                      value: e.target.value,
                      text: "Instrument ID (Symbol)",
                      name: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col lg={4} xs={4} md={4}>
                <DropdownListGroup
                  id="state"
                  name="state"
                  label="Instrument State"
                  value={this.state.state && this.state.state.value}
                  onChange={(e) => {
                    this.updateFilter({
                      id: "state",
                      value: e.id,
                      text: "Instrument State",
                      name: e.name,
                    });
                  }}
                  data={WithEmptyOption(InstrumentStaticData.InstrumentStates)}
                ></DropdownListGroup>
              </Col>
              <Col lg={4} xs={4} md={4}>
                <DropdownListGroup
                    id="type"
                    name="type"
                    label="Instrument Type"
                    value={this.state.type && this.state.type.value}
                    onChange={(e) => {
                      this.setState({ type: e.id });
                      this.updateFilter({
                        id: "type",
                        value: e.id,
                        text: "Instrument Type",
                        name: e.name,
                      });
                    }}
                    data={WithEmptyOption(InstrumentStaticData.InstrumentTypes)}
                ></DropdownListGroup>
              </Col>
              <Col lg={4} xs={4} md={4}>
                <DropdownListGroup
                  id="isScheduled"
                  name="isScheduled"
                  label="Schedule State"
                  value={this.state.isScheduled && this.state.isScheduled.value}
                  onChange={(e) => {
                    this.updateFilter({
                      id: "isScheduled",
                      value: e.id,
                      text: "Schedule State",
                      name: e.name,
                    });
                  }}
                  data={WithEmptyOption(InstrumentStaticData.SchedulingFilterStates)}
                ></DropdownListGroup>
              </Col>
              <Col lg={4} xs={4} md={4}>
                <DropdownListGroup
                  id="isTradable"
                  name="isTradable"
                  label="Tradable"
                  value={this.state.isTradable && this.state.isTradable.value}
                  onChange={(e) => {
                    this.updateFilter({
                      id: "isTradable",
                      value: e.id,
                      text: "Tradable",
                      name: e.name,
                    });
                  }}
                  data={WithEmptyOption(InstrumentStaticData.TradableFilters)}
                ></DropdownListGroup>
              </Col>
              {this.state.type && this.state.type.value === InstrumentType.INSTRUMENT_TYPE_IRS ? (
                <>
                  {" "}
                  <Col lg={4} xs={4} md={4}>
                    <DateGroup
                      id="tradeDate"
                      name="tradeDate"
                      label="Trade Date"
                      value={this.state.tradeDate && this.state.tradeDate.value}
                      dropUp={true}
                      onChange={(e) => {
                        this.updateFilter({
                          id: "tradeDate",
                          value: e,
                          text: "Trade Date",
                          name: e.toDateString(),
                        });
                      }}
                    />
                  </Col>
                  <Col lg={4} xs={4} md={4}>
                    <FirmsListContainer
                      id="clearingHouse"
                      name="clearingHouse"
                      label="Clearing House"
                      value={this.state.clearingHouse && this.state.clearingHouse.value}
                      firmType={FirmType.FIRM_TYPE_CLEARING_HOUSE}
                      WithEmptyOption={true}
                      onChange={(e) => {
                        this.updateFilter({
                          id: "clearingHouse",
                          value: e.name,
                          text: "Clearing House",
                          name: e.displayName,
                        });
                      }}
                    ></FirmsListContainer>
                  </Col>
                  <Col lg={4} xs={4} md={4}>
                    <DropdownListGroup
                      id="basecurrency"
                      name="basecurrency"
                      label="Currency"
                      value={this.state.basecurrency && this.state.basecurrency.value}
                      data={
                        this.props.metadata && this.props.metadata.currencies
                          ? ["", ...Object.keys(this.props.metadata.currencies)]
                          : []
                      }
                      onChange={(e) => {
                        this.updateFilter({
                          id: "basecurrency",
                          value: e,
                          text: "Currency",
                          name: e,
                        });
                      }}
                    />
                  </Col>
                  <Col lg={4} xs={4} md={4}>
                    <FieldGroup
                      id="tenor"
                      name="tenor"
                      type="text"
                      label="Tenor"
                      value={this.state.tenor && this.state.tenor.value}
                      errors={[]}
                      onChange={(e) => {
                        this.updateFilter({
                          id: "tenor",
                          value: e.target.value,
                          text: "Tenor",
                          name: e.target.value,
                        });
                      }}
                      setFieldValue={() => { }}
                    />
                  </Col>
                  <Col lg={4} xs={4} md={4}>
                    <DropdownListGroup
                      id="floatingRateIndex"
                      name="floatingRateIndex"
                      label="Floating Rate Index"
                      value={this.state.floatingRateIndex && this.state.floatingRateIndex.value}
                      onChange={(e) => {
                        this.updateFilter({
                          id: "floatingRateIndex",
                          value: e,
                          text: "Floating Rate Index",
                          name: e,
                        });
                      }}
                      data={InstrumentStaticData.FloatingRateIndexList}
                    />
                  </Col>
                  <Col lg={4} xs={4} md={4}>
                    <FieldGroup
                      id="floatingRateIndexTenor"
                      name="floatingRateIndexTenor"
                      label="Floating Rate Index Tenor"
                      value={this.state.floatingRateIndexTenor && this.state.floatingRateIndexTenor.value}
                      errors={[]}
                      onChange={(e) => {
                        this.updateFilter({
                          id: "floatingRateIndexTenor",
                          value: e.target.value,
                          text: "Floating Rate Index Tenor",
                          name: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </>
              ) : null}
            </Row>
          </Container>
        </React.Fragment>
      </Filter>
    );
  }
}

function mapStateToProps(state) {
  return {
    metadata: state.instruments.metadata,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMetadata: () => {
      dispatch(fetchMetadata());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentsFilter);