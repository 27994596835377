import React, { useEffect, useState } from "react";
import { Col, Row, } from "react-bootstrap";
import { fetchMetadata } from "../../actions/instruments";
import { connect } from "react-redux";
import ButtonMain from "../core/form/ButtonMain";
import { setAssociatedAccounts, listAssociatedAccounts, listAccounts } from "../../actions/accounts";
import { LoadFirms } from "../../actions/firms"
import ReactTable from 'react-table';
import Notification from "../../modules/notifications";
import CheckboxGroup from "../core/form/CheckboxGroup";
import { parseJwt } from "../../modules/authUtil";
import { isArrayWithValues } from "../../modules/util";

const { ListAccountsRequest, SetAssociatedAccountsRequest, GetAssociatedAccountsRequest } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js");
const { AccountAssociationType } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb")

function AssociatedAccounts(props) {

  const [accountsGridData, setAccountsGridData] = useState([]);
  const [manageMode, setManageMode] = useState(false);
  const [canAssociateAccounts, setCanAssociateAccounts] = useState();
  const { account } = props;
  const tableRef = React.createRef();

  useEffect(() => {
    loadAssociatedAccounts((cbData) => { });
    if (!props.accounts || props.accounts.length === 0) {
      listAccounts((accounts) => {
        prepareAccountsGridData(accounts)
      })
    } else {
      prepareAccountsGridData(props.accounts)
    }

    if (!props.firms) {
      props.loadFirms()
    }
    const accessToken = props.accessToken;
    const token = parseJwt(accessToken);
    setCanAssociateAccounts(token.role === "USER_ROLE_SUPERVISOR_ADMIN" || token.role === "USER_ROLE_SUPERVISOR_ONBOARDING_ADMIN")
  }, []);

  useEffect(() => {
    loadAssociatedAccounts((cbData) => { });
  }, [props.account]);

  useEffect(() => {
    if (!!props.accounts) {
      prepareAccountsGridData(props.accounts)
    }
  }, [props.firms, props.accounts, props.associatedAccounts])

  useEffect(() => {
    if (!!props.accounts) {
      prepareAccountsGridData(props.accounts)
    }
  }, [manageMode])

  const loadAssociatedAccounts = (cb) => {
    let req = prepareGetAssociatedAccountsReq();
    props.listAssociatedAccounts(req, cb)
  }

  const prepareGetAssociatedAccountsReq = () => {
    let req = new GetAssociatedAccountsRequest();
    req.setAccount(props.account.name);

    return req;
  }

  const prepareSetAssociatedAccountsReq = (account, accountAssociationType, associatedAccounts) => {
    let req = new SetAssociatedAccountsRequest();
    req.setAccount(account.name);
    req.setType(accountAssociationType);
    req.setAssociatedAccountsList(associatedAccounts);

    return req;
  }

  const prepareListAccountsRequest = () => {
    var request = new ListAccountsRequest();
    return request;
  }

  const listAccounts = (cb) => {
    let req = prepareListAccountsRequest()
    props.listAccounts(req, cb)
  }

  const setAssociatedAccounts = (cb) => {
    let accts = accountsGridData.filter((acc) => acc.selected === true)
    let selAccs = accts.map(acc => acc.account.name)
    let req = prepareSetAssociatedAccountsReq(account, AccountAssociationType.ACCOUNT_ASSOCIATION_TYPE_SHARED_BENEFICIAL_OWNERSHIP, selAccs);
    props.setAssociatedAccounts(req, cb)
  }

  const associateAccount = () => {
    setAssociatedAccounts((err, resp) => {
      if (!!err) {
        Notification.error(`Associations update failed: ${err.message}`);
      }

      if (!!resp) {
        Notification.success("Associations updated successfully.");
      }

      loadAssociatedAccounts((cbData) => { });
    });
  }

  // ~~~~~~~~~~~~~~~ Accounts List Start ~~~~~~~~~~~~~~~~~~~
  const accountsGridColumns = [
    {
      id: "selected",
      filterable: false,
      sortable: false,
      Cell: ({ original }) => {
        return (
          <input
            type="checkbox"
            checked={original.selected}
            onChange={(e) => { setRowSelection(original, e.target.checked); }}
            style={{ marginTop: "4px" }}
            disabled={!canAssociateAccounts}
          />
        );
      },
      Header: x => {
        return (
          <input
            type="checkbox"
            checked={isAllSelected(true)}
            onChange={(e) => selectAll(e.target.checked)}
            disabled={!canAssociateAccounts}
          />
        );
      },

      width: 45 // Width < 45px will overlap checkbox with the resizer and will make it unclickable.
    },
    {
      Header: "PARENT FIRM",
      id: "parentFirm",
      accessor: "firm.displayName",
    },
    {
      Header: "ACCOUNT",
      id: "Account",
      accessor: "account.displayName",
    },
  ]

  const filterData = (filter, row, column) => {
    const rowData = row[filter.id];

    if (rowData && filter.value) {
      return rowData.toLowerCase().includes(filter.value.toLowerCase());
    }

    return false;
  }

  const prepareAccountsGridData = (accounts) => {
    let gridData = accounts.map((acc, idx) => {
      let firm = getFirm(acc.parentFirm);
      return { index: idx, selected: isAccountAssociated(acc.name), account: acc, firm: firm }
    });

    if (!manageMode) {
      gridData = gridData.filter(gd => gd.selected)
    }

    setAccountsGridData(gridData)
  }

  const getFirm = (firmName) => {
    if (!!props.firms) {
      return props.firms.find(firm => firm.name === firmName)
    }

    return ""
  }

  const isAccountAssociated = (name) => {
    if (isArrayWithValues(props.associatedAccounts)) {
      let acc = props.associatedAccounts.find(acc => acc === name)
      return !!acc
    }

    return false;
  }

  const setRowSelection = (row, selected) => {
    row.selected = selected
    setAccountsGridData([...accountsGridData])
  }

  const getTableCurrentViewData = () => {
    if (tableRef.current) {
      return tableRef.current.getResolvedState().sortedData;
    }

    return null;
  }

  const selectAll = (selected) => {
    let viewData = getTableCurrentViewData();
    if (!viewData) return;

    viewData.forEach(element => {
      let acc = accountsGridData.find(ac => ac.account.name === element._original.account.name)
      if (!!acc) acc.selected = selected;
    });

    setAccountsGridData([...accountsGridData])
  }

  const isAllSelected = (onlyInView = false) => {
    if (onlyInView) {
      let viewData = getTableCurrentViewData();
      if (viewData) {
        let inst = viewData.find((element) => element._original.selected === false)
        return !inst;
      }

      return false;
    }

    let searchData = accountsGridData.find((element) => element.selected === false)
    return !searchData;
  }

  // ~~~~~~~~~~~~~~~ Accounts List Ends ~~~~~~~~~~~~~~~~~~~  

  return (
    <form>
      <>
        <Row>
          {canAssociateAccounts && <Col><h1><CheckboxGroup
            checked={manageMode}
            onClick={(e) => { setManageMode(e.target.checked) }}
            label={"Include Unassociated Accounts"}
          /></h1></Col>}
        </Row>
        <Row>
          <Col lg={6} xs={6} md={6} >
            <Row>
              <Col>
                <ReactTable
                  ref={tableRef}
                  data={accountsGridData}
                  parent={this}
                  filterable={true}
                  sortable={true}
                  minRows={0}
                  columns={accountsGridColumns}
                  showPagination={true}
                  showPageSizeOptions={true}
                  showPageJump={false}
                  defaultPageSize={10}
                  onFilteredChange={() => { }}
                  defaultFilterMethod={(filter, row, column) => {
                    return filterData(filter, row, column)
                  }}
                >
                </ReactTable>
              </Col>
            </Row>
            {canAssociateAccounts && <Row>
              <Col style={{ marginTop: "10px" }} ><ButtonMain text="Update Associations" type="button" onClick={associateAccount} /></Col>
            </Row>}
          </Col>
        </Row>
      </>
    </form>
  );
}

function mapStateToProps(state) {
  return {
    account: state.accounts.account,
    accounts: state.accounts.accounts,
    firms: state.firms.firms,
    associatedAccounts: state.accounts.associatedAccounts.associatedAccounts,
    pageToken: state.accounts.positionLedger.pageToken,
    nextPageToken: state.accounts.positionLedger.nextPageToken,
    pageTokens: state.accounts.positionLedger.pageTokens,
    accessToken: state.auth.accessToken,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchMetadata: () => {
    dispatch(fetchMetadata());
  },
  listAssociatedAccounts: (getAssociatedAccountsReq, cb) => {
    dispatch(listAssociatedAccounts(getAssociatedAccountsReq, cb))
  },
  setAssociatedAccounts: (setAssociatedAccountsReq, cb) => {
    dispatch(setAssociatedAccounts(setAssociatedAccountsReq, cb))
  },
  listAccounts: (listAccountsReq, cb) => {
    dispatch(listAccounts(listAccountsReq, cb))
  },
  loadFirms: () => {
    dispatch(LoadFirms())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedAccounts);
