import { StatusCode } from 'grpc-web';
import {
  FETCH_ORDER_HISTORY_START,
  REFRESH_ORDER_HISTORY,
  FETCH_ORDER_HISTORY_SUCCEED,
  FETCH_ORDER_HISTORY_FAILED,
  SEARCH_ORDER_HISTORY_NO_ITEMS,
  ADD_ORDER_HISTORY,
  RESET_FETCHING_STATUS,
  RESET_TOKENS,
  ADD_INSTRUMENTS_SCALES
} from '../constants/orderTypes';

import {
  SEARCH_EXECUTIONS_START,
  SEARCH_EXECUTIONS_FAILED,
  SEARCH_EXECUTIONS_NO_ITEM,
  SEARCH_EXECUTIONS_SUCCEED,
  RESET_SEARCH_EXECUTIONS_FETCHING_STATUS,
  REFRESH_SEARCH_EXECUTIONS,
  RESET_EXECUTIONS_TOKENS
} from '../constants/executionsTypes'

import { OrderState } from '@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb';
import Notification from '../modules/notifications';
import OrderService from '../services/OrderService';
import InstrumentService from '../services/InstrumentService';
import Instrument from '../entities/Instrument';
import OrderSearchGridItem from '../entities/dto/OrderSearchGridItem';
import ExecutionGridItem from '../entities/dto/ExecutionGridItem';

export function cleanSearch() {
  return dispatch => {
    dispatch({ type: REFRESH_ORDER_HISTORY, payload: [], pageToken: null, nextPageToken: null });
    dispatch({ type: RESET_TOKENS });
  }
}
export function cleanExecutionsSearch() {
  return dispatch => {
    dispatch({ type: REFRESH_SEARCH_EXECUTIONS, payload: [] });
    dispatch({ type: RESET_EXECUTIONS_TOKENS });
  }
}

export function resetFetchingStatus() {
  return dispatch => {
    dispatch({ type: RESET_FETCHING_STATUS });
  }
}

export function resetExecutionsFetchingStatus() {
  return dispatch => {
    dispatch({ type: RESET_SEARCH_EXECUTIONS_FETCHING_STATUS });
  }
}

export function searchOrders(filters, pageToken, pageSize) {
  return (dispatch, getState) => {
    let state = getState();
    const { scales, qtyScales } = state.instrumentsScales;
    const onError = function (error) {
      if (error && error.message !== "Http response at 400 or 500 level") {
        switch (error.code) {
          case StatusCode.NOT_FOUND:
            dispatch({ type: FETCH_ORDER_HISTORY_FAILED });
            dispatch({ type: SEARCH_ORDER_HISTORY_NO_ITEMS });
            break;
          default:
            dispatch({ type: FETCH_ORDER_HISTORY_FAILED });
            break;
        }
      } else {
        dispatch({ type: FETCH_ORDER_HISTORY_SUCCEED });
      }
    }

    const onRefresh = function (response) {
      if (!response.pageToken) {
        dispatch({ type: RESET_TOKENS });
      }
      if (response.orders.length === 0) {
        dispatch({ type: SEARCH_ORDER_HISTORY_NO_ITEMS });
        dispatch({ type: FETCH_ORDER_HISTORY_SUCCEED });
        return;
      }

      let promises = [];
      let gridItems = [];
      let newScales = {};
      let newQtyScales = {};
      let missingScales = {};
      let ordersMissingExecution = {};
      let ordersExecutionMap = new Map();

      response.orders.forEach((item) => {
        const symbol = item.getSymbol();
        const orderState = item.getState();
        const id = item.getId();
        if (!scales[symbol]) {
          missingScales[symbol] = symbol;
        }
        if (orderState === OrderState.ORDER_STATE_REJECTED) {
          ordersMissingExecution[id] = id;
        }
      });

      for (const symbol in missingScales) {
        const promise = InstrumentService.get2(missingScales[symbol]).then(
          (response) => {
            if (response) {
              let instrument = new Instrument(response.getInstrument());
              const instrumentScale = instrument.priceScale;
              newScales[symbol] = instrumentScale;
              const qtyScale = instrument.fractionalQtyScale;
              newQtyScales[symbol] = qtyScale;
            }
          }
        ).catch(
          (err) => {
            if (err) {
              instrumentFetchErrorHandler(err, dispatch, FETCH_ORDER_HISTORY_FAILED, symbol);
            }
          }
        );
        promises.push(promise);
      }

      for (const id in ordersMissingExecution) {
        const promise = OrderService.getLastExecution(id).then(
          (response) => {
            if (response) {
              const executionsList = response.getExecutionsList();
              if (Array.isArray(executionsList) && executionsList.length > 0) {
                ordersExecutionMap.set(id, executionsList[0]);
              }
            }
          }
        ).catch(
          (err) => {
            if (err) {
              instrumentFetchErrorHandler(err, dispatch, FETCH_ORDER_HISTORY_FAILED, id);
            }
          }
        );
        promises.push(promise);
      }

      Promise.all(promises).then(() => {
        response.orders.forEach((item) => {
          const symbol = item.getSymbol();
          const id = item.getId();
          const scale = scales[symbol] ? scales[symbol] : newScales[symbol];
          const qtyScale = qtyScales[symbol] ? qtyScales[symbol] : newQtyScales[symbol];
          const orderExecution = ordersExecutionMap.get(id);
          const gridItem = new OrderSearchGridItem(item, scale, qtyScale, orderExecution);
          gridItems.push(gridItem);
        });

        dispatch({ type: ADD_INSTRUMENTS_SCALES, payload: newScales, qtyPayload: newQtyScales });
        dispatch({ type: REFRESH_ORDER_HISTORY, payload: gridItems, pageToken: response.pageToken, nextPageToken: response.nextPageToken });
        dispatch({ type: FETCH_ORDER_HISTORY_SUCCEED });
      });
    }
    const onAdd = function (response) {
      dispatch({ type: ADD_ORDER_HISTORY, payload: toOrdersGridItems(response.orders) });
      dispatch({ type: FETCH_ORDER_HISTORY_SUCCEED });
    }
    const onEnd = function () {
      dispatch({ type: FETCH_ORDER_HISTORY_SUCCEED });
    }

    dispatch({ type: FETCH_ORDER_HISTORY_START });
    OrderService.searchOrders({ filters, onRefresh, onAdd, onError, onEnd, pageToken, pageSize });

  }
}

export function exportOrders(filters, isExecutions) {
  return (dispatch) => {
    let onSuccess = (data) => {
      dispatch({ type: FETCH_ORDER_HISTORY_SUCCEED });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", isExecutions ? `executions.csv` : `orders.csv`);
      document.body.appendChild(link);
      link.click();
    };
    let onError = (error) => {
      console.error(error);
      dispatch({ type: FETCH_ORDER_HISTORY_FAILED });
      Notification.error("Could not download report.");
    };

    dispatch({ type: FETCH_ORDER_HISTORY_START });
    OrderService.downloadOrdersReport(filters, isExecutions, onSuccess, onError)
  }
}

const toOrdersGridItems = function (orders, instrument) {
  const gridItems = orders.map((item) => new OrderSearchGridItem(item, instrument));
  return gridItems;
}

const instrumentFetchErrorHandler = function (err, dispatch, action) {
  if (err) {
    dispatch({ type: action });
    if (err.code !== StatusCode.UNAVAILABLE) {
      switch (err.code) {
        case StatusCode.NOT_FOUND:
          dispatch({ type: action });
          Notification.error('Cannot find Order´s Instrument.');
          break;
        default:
          Notification.error('Cannot get instrument information.');
          break;
      }
    }
  }
}

export function searchExecutions(filters, pageToken, pageSize) {
  return (dispatch) => {
    const onError = function (error) {
      if (error && error.message !== "Http response at 400 or 500 level") {
        switch (error.code) {
          case StatusCode.NOT_FOUND:
            dispatch({ type: SEARCH_EXECUTIONS_FAILED });
            dispatch({ type: SEARCH_EXECUTIONS_NO_ITEM });
            break;
          default:
            console.log(error.message);
            dispatch({ type: SEARCH_EXECUTIONS_FAILED });
            break;
        }
      } else {
        dispatch({ type: SEARCH_EXECUTIONS_SUCCEED });
      }
    }
    const onRefresh = function (response) {
      const actionType = REFRESH_SEARCH_EXECUTIONS;
      if (!response.pageToken) {
        dispatch({ type: RESET_EXECUTIONS_TOKENS });
      }

      dispatchCallAfterInstrumentInfo(dispatch, response.executions, actionType, response.pageToken, response.nextPageToken, response.eof);
      if (response.executions.length === 0) {
        dispatch({ type: SEARCH_EXECUTIONS_NO_ITEM });
      }
    }
    const onEnd = function () {
      dispatch({ type: SEARCH_EXECUTIONS_SUCCEED });
    }

    dispatch({ type: SEARCH_EXECUTIONS_START });
    OrderService.searchExecutions({ filters, onRefresh, onError, onEnd, pageToken, pageSize });
  }
}

const dispatchCallAfterInstrumentInfo = function (dispatch, executions, actionType, pageToken, nextPageToken, eof) {
  if (executions.length > 0) {
    const symbol = executions[0].getOrder().getSymbol();
    InstrumentService.get(symbol,
      (err, response) => {
        if (err) {
          instrumentFetchErrorHandler(err, dispatch, SEARCH_EXECUTIONS_FAILED);
        }
        if (response) {
          const instrument = new Instrument(response.getInstrument());
          dispatch({ type: actionType, payload: toSearchExecutionGridItems(executions, instrument), pageToken: pageToken, nextPageToken: eof ? undefined : nextPageToken });
        }
      });
  }
}

const toSearchExecutionGridItems = function (executions, instrument) {
  const gridItems = executions.map((item) => new ExecutionGridItem(item, instrument));
  return gridItems;
}

export function exportTrace(traceId) {
  return (dispatch) => {
    const cb = (err, response) => {
      if (err) {
        console.error(err);
        dispatch({ type: FETCH_ORDER_HISTORY_FAILED });
        Notification.error("Could not download trace.");
      } else if (response) {
        dispatch({ type: FETCH_ORDER_HISTORY_SUCCEED });
        const url = window.URL.createObjectURL(new Blob([response.getRaw()]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${traceId}.json`);
        document.body.appendChild(link);
        link.click();
      }
    }

    dispatch({ type: FETCH_ORDER_HISTORY_START });
    OrderService.downloadTrace(traceId, cb);
  }
}
