import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import PhoneNumber from "../core/form/PhoneNumber";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import PersonnelRoleListContainer from "../../containers/PersonnelRoleListContainer";

const PersonnelForm = ({ onEditForm, errors, touched, values, setFieldValue, handleChange, handleBlur }) => {

    return (
        <div>
            <Row>
                <Col lg={3} xs={3} md={3}>
                    {onEditForm ? (<FieldGroup
                        id="name"
                        name="name"
                        type="text"
                        label="Name"
                        placeholder=""
                        isRequired={true}
                        errors={errors}
                        touched={touched}
                        value={values.name}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="name"
                        label="Name"
                        value={values.name}
                    />)}
                </Col>
                <Col lg={3} xs={3} md={3}>
                    {onEditForm ? (<FieldGroup
                        id="email"
                        name="email"
                        type="email"
                        label="Email"
                        placeholder=""
                        isRequired={true}
                        errors={errors}
                        touched={touched}
                        value={values.email}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="email"
                        label="Email"
                        value={values.email}
                    />)}
                </Col>
                <Col lg={3} xs={3} md={3}>
                    {onEditForm ? (<PhoneNumber
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        isRequired={true}
                        value={values.phoneNumber}
                        onChange={(e) => setFieldValue("phoneNumber", e)}
                    />) : (
                    <FieldGroupReadOnly
                        id="phoneNumber"
                        label="Phone Number"
                        value={values.phoneNumber}
                    />)}
                </Col>
                <Col lg={3} xs={3} md={3}>
                {onEditForm ? (
                  <PersonnelRoleListContainer
                    id="role"
                    name="role"
                    label="Role"
                    value={values.role ? values.role.id : 0}
                    enabled={true}
                    errors={errors}
                    onChange={(e) => {
                      setFieldValue("role", e);
                    }}
                  />
                ) : (
                  <FieldGroupReadOnly
                    id="role"
                    label="Role"
                    value={values ? values.role.name : null}
                  />
                )}
              </Col>
            </Row>
            <Row>
                <Col lg={6} xs={6} md={6}>
                    {onEditForm ? (<FieldGroup
                        id="address1"
                        name="address1"
                        type="text"
                        label="Address 1"
                        placeholder=""
                        isRequired={false}
                        errors={errors}
                        touched={touched}
                        value={values.address1}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="address1"
                        label="Address 1"
                        value={values.address1}
                    />)}
                </Col>
                <Col lg={6} xs={6} md={6}>
                    {onEditForm ? (<FieldGroup
                        id="address2"
                        name="address2"
                        type="text"
                        label="Address 2"
                        placeholder=""
                        isRequired={false}
                        errors={errors}
                        touched={touched}
                        value={values.address2}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="address2"
                        label="Address 2"
                        value={values.address2}
                    />)}
                </Col>
            </Row>
            <Row>
                <Col lg={3} xs={3} md={3}>
                    {onEditForm ? (<FieldGroup
                        id="addressCity"
                        name="addressCity"
                        type="text"
                        label="Address City"
                        placeholder=""
                        isRequired={false}
                        errors={errors}
                        touched={touched}
                        value={values.addressCity}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="addressCity"
                        label="Address City"
                        value={values.addressCity}
                    />)}
                </Col>
                <Col lg={3} xs={3} md={3}>
                    {onEditForm ? (<FieldGroup
                        id="addressState"
                        name="addressState"
                        type="text"
                        label="Address State"
                        placeholder=""
                        isRequired={false}
                        errors={errors}
                        touched={touched}
                        value={values.addressState}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="addressState"
                        label="Address State"
                        value={values.addressState}
                    />)}
                </Col>
                <Col lg={3} xs={3} md={3}>
                    {onEditForm ? (<FieldGroup
                        id="addressPostalCode"
                        name="addressPostalCode"
                        type="text"
                        label="Address Postal Code"
                        placeholder=""
                        isRequired={false}
                        errors={errors}
                        touched={touched}
                        value={values.addressPostalCode}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="addressPostalCode"
                        label="Address Postal Code"
                        value={values.addressPostalCode}
                    />)}
                </Col>
                <Col lg={3} xs={3} md={3}>
                    {onEditForm ? (<FieldGroup
                        id="addressCountryCode"
                        name="addressCountryCode"
                        type="text"
                        label="Address Country Code"
                        placeholder=""
                        isRequired={false}
                        errors={errors}
                        touched={touched}
                        value={values.addressCountryCode}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="addressCountryCode"
                        label="Address Country Code"
                        value={values.addressCountryCode}
                    />)}
                </Col>
            </Row>
            <Row></Row>
        </div>
    );
};

PersonnelForm.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    onEditForm: PropTypes.func,
};

export default PersonnelForm;
