import querystring from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  cleanSearch,
  searchOrders,
  exportOrders,
  resetFetchingStatus
} from '../actions/orders';
import './Content.css';
import { AdminDocumentTitle } from '../constants/strings';
import OrderHistoryFilterForm from "../components/order-history/OrderHistoryFilterForm";
import SearchNoItemsResult from '../components/core/search-no-items/SearchNoItemsResult';
import { cleanInstrumentsList, fetchInstrumentIdsList } from "../actions/instruments";
import OrderSearchDataGrid from '../components/order-history/OrderSearchDataGrid';
import PaginationWithPageSize, { withPageToken } from '../components/core/data-grid/PaginationWithPageSize';
import { LoadParticipants } from "../actions/participants";
import { LoadFirms } from "../actions/firms";
import { LoadAccounts } from "../actions/accounts";
import confirm from "../modules/confirmDialog";
import OrderService from "../services/OrderService";
import Notification from "../modules/notifications";
import moment from 'moment';
import { isNullOrUndefined } from '../modules/util';

const STORAGE_PAGE_SIZE_KEY = "dataGridPageSize-OrderSearchPage";
const DEFAULT_PAGE_SIZE = 10;

function mapStateToProps(state) {

  let orderHistory = state.orders.orderHistory.map((h) => {
    let _h = { ...h }

    let participant = state.participants.participants.find((p) =>
      p.name === h.participant
    )

    let account = state.accounts.accounts.find((a) =>
      a.name === h.account
    )

    let submittingParticipant = state.participants.participants.find((p) =>
      p.name === h.submittingParticipant
    )

    if (participant) {
      _h.participant = participant.displayName;

      let firmDisplayName = "";

      if (participant.firm) {
        if (typeof (participant.firm) === "string") {
          let firm = state.firms.firms.find((f) =>
            f.name === participant.firm
          )

          if (firm) {
            firmDisplayName = firm.displayName;
          } else {
            firmDisplayName = participant.firm.substring(participant.firm.indexOf("/") + 1, participant.firm.length)
          }
        } else if (participant.firm.displayName) {
          firmDisplayName = participant.firm.displayName;
        }
      }

      _h.participant_firm = firmDisplayName;
    }

    if (submittingParticipant) {
      _h.submittingParticipant = submittingParticipant.displayName;

      let firmDisplayName = "";

      if (submittingParticipant.firm) {
        if (typeof (submittingParticipant.firm) === "string") {
          let firm = state.firms.firms.find((f) =>
            f.name === submittingParticipant.firm
          )

          if (firm) {
            firmDisplayName = firm.displayName;
          } else {
            firmDisplayName = submittingParticipant.firm.substring(submittingParticipant.firm.indexOf("/") + 1, submittingParticipant.firm.length)
          }
        } else if (submittingParticipant.firm.displayName) {
          firmDisplayName = submittingParticipant.firm.displayName;
        }
      }

      _h.submittingParticipant_firm = firmDisplayName;
    }

    if (account) {
      _h.account = account.displayName;
    }

    return _h;
  });

  return {
    fetchingHistory: state.orders.fetchingHistory,
    historyItems: orderHistory,
    noItemsFound: state.orders.noItemsFound,
    instruments: state.instruments.instrumentIdsList,
    tokens: state.orders.tokens,
    pageToken: state.orders.pageToken,
    participants: state.participants.participants,
    firms: state.firms.firms,
    accounts: state.accounts.accounts
  };
}

const mapDispatchToProps = (dispatch) => ({
  cleanSearch: () => {
    dispatch(cleanSearch())
  },
  searchOrderHistory: (filters, pageToken, pageSize) => {
    dispatch(searchOrders(filters, pageToken, pageSize))
  },
  exportOrderHistory: (filters, executions) => {
    dispatch(exportOrders(filters, executions))
  },
  fetchInstrumentIdsList: () => {
    dispatch(fetchInstrumentIdsList())
  },
  cleanInstrumentsList: () => {
    dispatch(cleanInstrumentsList())
  },
  resetFetchingStatus: () => {
    dispatch(resetFetchingStatus())
  },
  loadParticipants: () => {
    dispatch(LoadParticipants());
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
  loadAccounts: () => {
    dispatch(LoadAccounts());
  }
})

class OrdersSearchPage extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor() {
    super();
    var start = moment().startOf("day").toDate()
    var end = moment().endOf("day").toDate()
    this.state = {
      fromDate: start,
      toDate: end,
      instrumentId: null,
      participantDisplayName: '',
      participant: null,
      accountDisplayName: '',
      account: null,
      clientaccountid: null,
      clientparticipantid: null,
      clordid: null,
      orderid: null,
      crossid: null,
      parentorderid: null,
      symbolsubtype: null,
      hostcrossid: null,
      tokens: {},
      side: 0,
      orderStateFilter: 0,
      pageSize: Number(this.getPageSizeFromStorage())
    }
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.getPageSizeFromStorage = this.getPageSizeFromStorage.bind(this);
    this.search = this.search.bind(this);
    this.timer = null;
    this.cancelOrder = this.cancelOrder.bind(this)
  }

  componentDidMount() {
    document.title = AdminDocumentTitle;
    this.props.cleanSearch();
    this.props.cleanInstrumentsList();
    const state = querystring.parseUrl(window.location.href);
    this.setState({
      instrumentId: state.query.instrumentId,
      participantDisplayName: state.query.participantDisplayName,
      participant: state.query.participant,
      accountDisplayName: state.query.accountDisplayName,
      account: state.query.account,
      clientaccountid: state.query.clientaccountid,
      clientparticipantid: state.query.clientparticipantid,
      clordid: state.query.clordid,
      orderid: state.query.orderid,
      crossid: state.query.crossid,
      side: !!state.query.side ? parseInt(state.query.side) : null,
      orderStateFilter: !!state.query.orderStateFilter ? parseInt(state.query.orderStateFilter) : null,
      viewingOrderDetails: false,
      fromDate: isNullOrUndefined(state.query.fromDate) ? this.state.fromDate : new Date(state.query.fromDate),
      toDate: isNullOrUndefined(state.query.toDate) ? this.state.toDate : new Date(state.query.toDate),
      parentorderid: state.query.parentorderid,
      symbolsubtype: state.query.symbolsubtype,
      hostcrossid: state.query.hostcrossid,
    }, () => {
      if (!this.props.fetchingHistory) {
        this.props.searchOrderHistory(this.state, "", this.state.pageSize);
      }
    });

    if (this.props.participants.length === 0)
      this.props.loadParticipants();

    if (this.props.firms.length === 0)
      this.props.loadFirms();

    if (this.props.accounts.length === 0)
      this.props.loadAccounts();

    window.scrollTo(0, 0);
  }

  search = (values) => {
    this.props.cleanSearch();
    
    let searchState = {
      instrumentId: values.instrumentId,
      fromDate: values.fromDate,
      toDate: values.toDate,
      participantDisplayName: values.participantDisplayName,
      participant: this.getByPrettyName(values.participantDisplayName, this.props.participants),
      accountDisplayName: values.accountDisplayName,
      account: this.getByPrettyName(values.accountDisplayName, this.props.accounts),
      clientaccountid: values.clientaccountid,
      clientparticipantid: values.clientparticipantid,
      clordid: values.clordid,
      orderid: values.orderid,
      crossid: values.crossid,
      side: values.side,
      orderStateFilter: values.orderStateFilter,
      parentorderid: values.parentorderid,
      symbolsubtype: values.symbolsubtype,
      hostcrossid: values.hostcrossid,
    };

    this.setState(searchState);
    this.props.searchOrderHistory(this.state, "", this.state.pageSize);
    this.context.router.history.push(`/order-search/orders?${querystring.stringify(searchState)}`);
    
    if (!!values.action && values.action.startsWith("export")) {
      this.props.exportOrderHistory(this.state, values.action.endsWith("executions"));
    }
  }

  getByPrettyName = (field, propsObjs) => {
    if (Array.isArray(propsObjs) && !!field && !propsObjs.find(itm => itm.name === field)) {
      const fieldById = propsObjs.find(itm => itm.id === field);
      const fieldByDisplayName = propsObjs.find(itm => itm.displayName === field);
      if (!!fieldById) {
        return fieldById.name;
      } else if (!!fieldByDisplayName) {
        return fieldByDisplayName.name;
      }
    }
    return field;
  }

  setPageSizeInStorage = (pageSize) => {
    window.localStorage.setItem(STORAGE_PAGE_SIZE_KEY, pageSize)
  }

  getPageSizeFromStorage = () => {
    const pageSize = window.localStorage.getItem(STORAGE_PAGE_SIZE_KEY)

    if (pageSize)
      return pageSize;

    return DEFAULT_PAGE_SIZE;
  }

  onPageChange = (pageToken) => {
    this.props.searchOrderHistory(this.state, pageToken, this.state.pageSize);
  }

  onPageSizeChange = (pageSize) => {
    this.setPageSizeInStorage(pageSize);
    this.setState({ pageSize: pageSize });
    this.props.searchOrderHistory(this.state, "", pageSize);
  }

  getPaginationComponent = () => {
    const pageToken = this.props.pageToken;
    const tokens = this.props.tokens;
    const pageSize = Number(this.getPageSizeFromStorage());
    return withPageToken(PaginationWithPageSize, { pageToken, tokens }, pageSize);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  updateOrderViewState = (showOrder, orderData) => {
    this.setState({
      viewingOrderDetails: showOrder,
      orderData: orderData
    });
  }

  cancelOrder(orderIds) {
    let self = this;
    confirm("Are you sure you want to cancel?", {
      title: Array.isArray(orderIds) && orderIds.length === 1 ? orderIds[0] : "Multiple Order Cancellation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            Notification.success(`Submitted cancellation`);
            self.props.searchOrderHistory(self.state, "", this.state.pageSize);
          }

          if (err) {
            Notification.error(`Cancellation failed: ${err.message}`);
          }
        };
        orderIds.forEach(orderId => OrderService.cancelOrder(orderId, cb));
      },
      () => {
      },
    );
  }

  render() {
    const { viewOrderHistory } = this.props;
    return (
      <div className="with-callback">
        <OrderHistoryFilterForm
          filters={this.state}
          parent="OrdersSearchPage"
          onSubmitForm={this.search}
          loading={this.props.fetchingHistory}
          autoCompleteDataSource={this.props.instruments}
          onRefreshAutoComplete={() => { this.props.fetchInstrumentIdsList() }}
        />
        {this.props.noItemsFound ?
          <SearchNoItemsResult message="Sorry, but we didn't find any Orders." />
          :
          <OrderSearchDataGrid
            data={this.props.historyItems}
            paginationComponent={this.getPaginationComponent()}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            cancelOrder={this.cancelOrder}
            showOrderDetails={this.state.viewingOrderDetails}
            orderData={this.state.orderData}
            updateOrderViewState={this.updateOrderViewState}
            viewOrderHistory={viewOrderHistory}
          />
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersSearchPage);
