// auth actions
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_END = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGIN_ERROR_RESET = 'LOGIN_ERROR_RESET';
export const ACCESS_TKN_REFRESH_SUCCESS = 'ACCESS_TKN_REFRESH_SUCCESS';
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_END = "CHANGE_PASSWORD_END";
export const MFA_OTP_AUTH_URL='MFA_OTP_AUTH_URL';
