import {
  FETCH_ACCOUNTS_START,
  FETCH_ACCOUNTS_SUCCEED,
  FETCH_ACCOUNTS_FAILED,
  FETCH_POSITIONS_START,
  FETCH_POSITIONS_SUCCEED,
  FETCH_POSITIONS_FAILED,
  DOWNLOAD_POSITIONS_START,
  DOWNLOAD_POSITIONS_SUCCEED,
  DOWNLOAD_POSITIONS_FAILED,
  FETCH_PENDING_SETTLEMENTS_START,
  FETCH_PENDING_SETTLEMENTS_SUCCEED,
  FETCH_PENDING_SETTLEMENTS_FAILED,
  FETCH_BALANCES_START,
  FETCH_BALANCES_SUCCEED,
  FETCH_BALANCES_FAILED,
  FETCH_WITHDRAWALS_START,
  FETCH_WITHDRAWALS_SUCCEED,
  FETCH_WITHDRAWALS_FAILED,
  FETCH_ACCOUNT_START,
  FETCH_ACCOUNT_SUCCEED,
  FETCH_ACCOUNT_FAILED,
  CLEAN_ACCOUNT,
  SAVE_ACCOUNT_START,
  SAVE_ACCOUNT_SUCCEED,
  SAVE_ACCOUNT_FAILED,
  CREATE_ACCOUNT_STATE,
  FETCH_PARTICIPANTS_LIST_START,
  FETCH_PARTICIPANTS_LIST_SUCCEED,
  FETCH_PARTICIPANTS_LIST_FAILED,
  CLEAN_PARTICIPANTS_LIST_GROUP,
  TOGGLE_SHOW_DELETED_ACCOUNTS,
  GET_USER_LICENSE_START,
  GET_USER_LICENSE_SUCCEED,
  GET_USER_LICENSE_FAILED,
  FETCH_SECURITIES_START,
  FETCH_SECURITIES_SUCCEED,
  FETCH_SECURITIES_FAILED,
  FETCH_PENDING_CREDITS_START,
  FETCH_PENDING_CREDITS_SUCCEED,
  FETCH_PENDING_CREDITS_FAILED,
  FETCH_POSITION_LEDGER_START,
  FETCH_POSITION_LEDGER_SUCCEED,
  FETCH_POSITION_LEDGER_FAILED,
  DOWNLOAD_POSITION_LEDGER_START,
  DOWNLOAD_POSITION_LEDGER_SUCCEED,
  DOWNLOAD_POSITION_LEDGER_FAILED,
  FETCH_ASSOCIATED_ACCOUNTS_START,
  FETCH_ASSOCIATED_ACCOUNTS_SUCCEED,
  FETCH_ASSOCIATED_ACCOUNTS_FAILED,
  SET_ASSOCIATED_ACCOUNTS_START,
  SET_ASSOCIATED_ACCOUNTS_SUCCEED,
  SET_ASSOCIATED_ACCOUNTS_FAILED,
  FETCH_BALANCE_LEDGER_START,
  FETCH_BALANCE_LEDGER_SUCCEED,
  FETCH_BALANCE_LEDGER_FAILED,
  DOWNLOAD_BALANCE_LEDGER_START,
  DOWNLOAD_BALANCE_LEDGER_SUCCEED,
  DOWNLOAD_BALANCE_LEDGER_FAILED,
} from "../constants/accountTypes";
import Account from "../entities/Account";
import AccountService from "../services/AccountService";
import License from "../entities/License"
import Notification from "../modules/notifications";
import { isNullOrUndefined, convertDateToString, getEnumName } from "../modules/util";
import { tsProtoObjToDate } from "../actions/ptypes"
var type_pb = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/type/v1beta1/type_pb');

export const FetchAccountsStart = () => ({
  type: FETCH_ACCOUNTS_START,
});

export const FetchAccountsSucceed = (accounts) => ({
  type: FETCH_ACCOUNTS_SUCCEED,
  payload: accounts,
});

export const FetchAccountsFailed = (error) => ({
  type: FETCH_ACCOUNTS_FAILED,
  payload: error,
});

export const FetchPositionsStart = () => ({
  type: FETCH_POSITIONS_START,
});

export const FetchPositionsSucceed = (positions) => ({
  type: FETCH_POSITIONS_SUCCEED,
  payload: positions,
});

export const FetchPositionsFailed = (error) => ({
  type: FETCH_POSITIONS_FAILED,
  payload: error,
});

export const DownloadPositionsStart = () => ({
  type: DOWNLOAD_POSITIONS_START,
});

export const DownloadPositionsSucceed = (positions) => ({
  type: DOWNLOAD_POSITIONS_SUCCEED,
  payload: positions,
});

export const DownloadPositionsFailed = (error) => ({
  type: DOWNLOAD_POSITIONS_FAILED,
  payload: error,
});

export const FetchPendingSettlementsStart = () => ({
  type: FETCH_PENDING_SETTLEMENTS_START,
});

export const FetchPendingSettlementsSucceed = (positions) => ({
  type: FETCH_PENDING_SETTLEMENTS_SUCCEED,
  payload: positions,
});

export const FetchPendingSettlementsFailed = (error) => ({
  type: FETCH_PENDING_SETTLEMENTS_FAILED,
  payload: error,
});

export const FetchBalancesStart = () => ({
  type: FETCH_BALANCES_START,
});

export const FetchBalancesSucceed = (balances) => ({
  type: FETCH_BALANCES_SUCCEED,
  payload: balances,
});

export const FetchBalancesFailed = (error) => ({
  type: FETCH_BALANCES_FAILED,
  payload: error,
});

export const FetchPositionLedgerStart = () => ({
  type: FETCH_POSITION_LEDGER_START,
});

export const FetchPositionLedgerSucceed = (payload) => ({
  type: FETCH_POSITION_LEDGER_SUCCEED,
  payload: payload,
});

export const FetchPositionLedgerFailed = (error) => ({
  type: FETCH_POSITION_LEDGER_FAILED,
  payload: error,
});

export const DownloadPositionLedgerStart = () => ({
  type: DOWNLOAD_POSITION_LEDGER_START,
});

export const DownloadPositionLedgerSucceed = (payload) => ({
  type: DOWNLOAD_POSITION_LEDGER_SUCCEED,
  payload: payload,
});

export const DownloadPositionLedgerFailed = (error) => ({
  type: DOWNLOAD_POSITION_LEDGER_FAILED,
  payload: error,
});

export const FetchBalanceLedgerStart = () => ({
  type: FETCH_BALANCE_LEDGER_START,
});

export const FetchBalanceLedgerSucceed = (payload) => ({
  type: FETCH_BALANCE_LEDGER_SUCCEED,
  payload: payload,
});

export const FetchBalanceLedgerFailed = (error) => ({
  type: FETCH_BALANCE_LEDGER_FAILED,
  payload: error,
});

export const DownloadBalanceLedgerStart = () => ({
  type: DOWNLOAD_BALANCE_LEDGER_START,
});

export const DownloadBalanceLedgerSucceed = (payload) => ({
  type: DOWNLOAD_BALANCE_LEDGER_SUCCEED,
  payload: payload,
});

export const DownloadBalanceLedgerFailed = (error) => ({
  type: DOWNLOAD_BALANCE_LEDGER_FAILED,
  payload: error,
});

export const FetchWithdrawalsStart = () => ({
  type: FETCH_WITHDRAWALS_START,
});

export const FetchWithdrawalsSucceed = (wds) => ({
  type: FETCH_WITHDRAWALS_SUCCEED,
  payload: wds,
});

export const FetchWithdrawalsFailed = (error) => ({
  type: FETCH_WITHDRAWALS_FAILED,
  payload: error,
});

export const FetchPendingCreditsStart = () => ({
  type: FETCH_PENDING_CREDITS_START,
});

export const FetchPendingCreditsSucceed = (credits) => ({
  type: FETCH_PENDING_CREDITS_SUCCEED,
  payload: credits,
});

export const FetchPendingCreditsFailed = (error) => ({
  type: FETCH_PENDING_CREDITS_FAILED,
  payload: error,
});

export const FetchAccountStart = () => ({
  type: FETCH_ACCOUNT_START,
});

export const FetchAccountSucceed = (product) => ({
  type: FETCH_ACCOUNT_SUCCEED,
  payload: product,
});

export const FetchAccountFailed = (error) => ({
  type: FETCH_ACCOUNT_FAILED,
  payload: error,
});

export const CleanAccount = () => ({
  type: CLEAN_ACCOUNT,
});

export const SaveAccountStart = () => ({
  type: SAVE_ACCOUNT_START,
});

export const SaveAccountSucceed = () => ({
  type: SAVE_ACCOUNT_SUCCEED,
});

export const SaveAccountFailed = (error) => ({
  type: SAVE_ACCOUNT_FAILED,
  payload: error,
});

export const IsCreatingAccount = (isCreating) => ({
  type: CREATE_ACCOUNT_STATE,
  value: isCreating,
});

export const FetchParticipantsListStart = () => ({
  type: FETCH_PARTICIPANTS_LIST_START,
});

export const FetchParticipantsListSucceed = (participants) => ({
  type: FETCH_PARTICIPANTS_LIST_SUCCEED,
  payload: participants,
});

export const FetchParticipantsListFailed = () => ({
  type: FETCH_PARTICIPANTS_LIST_FAILED,
});

export const CleanParticipantsListGroup = () => ({
  type: CLEAN_PARTICIPANTS_LIST_GROUP,
});

export const ToggleShowDeletedAccounts = () => {
  return {
    type: TOGGLE_SHOW_DELETED_ACCOUNTS,
  };
};

export const FetchUserLicenseStart = () => {
  return {
    type: GET_USER_LICENSE_START,
  };
};

export const FetchUserLicenseSuccess = (license) => {
  return {
    type: GET_USER_LICENSE_SUCCEED,
    payload: license,
  };
};

export const FetchUserLicenseFailed = (error) => {
  return {
    type: GET_USER_LICENSE_FAILED,
    payload: error,
  };
};


export const FetchAssociatedAccountsStart = () => ({
  type: FETCH_ASSOCIATED_ACCOUNTS_START,
});

export const FetchAssociatedAccountsSucceed = (payload) => ({
  type: FETCH_ASSOCIATED_ACCOUNTS_SUCCEED,
  payload: payload,
});

export const FetchAssociatedAccountsFailed = (error) => ({
  type: FETCH_ASSOCIATED_ACCOUNTS_FAILED,
  payload: error,
});

export const SetAssociatedAccountsStart = () => ({
  type: SET_ASSOCIATED_ACCOUNTS_START,
});

export const SetAssociatedAccountsSucceed = (payload) => ({
  type: SET_ASSOCIATED_ACCOUNTS_SUCCEED,
  payload: payload,
});

export const SetAssociatedAccountsFailed = (error) => ({
  type: SET_ASSOCIATED_ACCOUNTS_FAILED,
  payload: error,
});

export const LoadAccounts = (showDeleted = false) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(FetchAccountsStart());

    const cb = (err, response) => {
      if (response) {
        var accountsList = response.getAccountsList().map((account) => {
          return new Account(account);
        });
        dispatch(FetchAccountsSucceed(accountsList));
        resolve(accountsList);
      }

      if (err) {
        dispatch(FetchAccountsFailed());
        reject(err);
      }
    };

    AccountService.getAll(cb, null, showDeleted);
  });
};

export const listAccounts = (listAccountsRequest, rootCb) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(FetchAccountsStart());

    const cb = (err, response) => {
      if (response) {
        var accountsList = response.getAccountsList().map((account) => {
          return new Account(account);
        });
        dispatch(FetchAccountsSucceed(accountsList));
        resolve(accountsList);

        if (!!rootCb) {
          rootCb(accountsList)
        }
      }

      if (err) {
        dispatch(FetchAccountsFailed());
        reject(err);
      }
    };

    AccountService.listAccounts(listAccountsRequest, cb);
  });
};

export const GetUserLicense = () => {
  return (dispatch) => {
    dispatch(FetchUserLicenseStart());

    const cb = (err, resp) => {
      if (resp) {
        let license = new License(resp);
        dispatch(FetchUserLicenseSuccess(license))
      }

      if (err) {
        const errMsg = "Error code " + err.code + ": " + err.message;
        dispatch(FetchUserLicenseFailed(errMsg));
      }
    };

    AccountService.getLicense(cb);
  }
}

/**
 * Gets a map of securities.
 * @return {map} Securities.
 */
export function fetchSecurityDefinitions() {
  return (dispatch) => {
    dispatch({ type: FETCH_SECURITIES_START });

    const cb = (err, response) => {
      if (response) {
        const respObj = !isNullOrUndefined(response) ? response.toObject() : {};
        const definitionsMap = respObj.hasOwnProperty('definitionsMap') ? respObj.definitionsMap : [];
        const payload = Array.isArray(definitionsMap) && definitionsMap.length > 0 ? Object.fromEntries(definitionsMap) : {};
        dispatch({
          type: FETCH_SECURITIES_SUCCEED,
          payload: payload,
        });
      }

      if (err) {
        dispatch({ type: FETCH_SECURITIES_FAILED });
        Notification.error(`Cannot get securities: ${err.message}`);
      }
    };

    AccountService.listSecurityDefinitions(cb);
  };
}

export function fetchPositionLedger(req, cb) {
  return (dispatch, getState) => {
    dispatch(FetchPositionLedgerStart());

    const reqCb = (err, resp) => {
      if (err) {
        dispatch(FetchPositionLedgerFailed(err));
        return
      }

      if (!!resp) {
        let ledgerList = resp.toObject()
        let ledgerListData = ledgerList.ledgerList;

        ledgerListData.map((li) => {
          li.updateTime = convertDateToString(tsProtoObjToDate(li.updateTime), "YYYYMMDD-HH:mm:ss.SSS");
          li.entryTypeName = getEnumName(type_pb.LedgerEntryType, li.entryType)
          return li;
        });

        const isEOF = resp.getEof();
        const nextPageToken = !isEOF ? resp.getNextPageToken() : null;
        const pageToken = req.getPageToken();

        dispatch(FetchPositionLedgerSucceed({ ledgerList: ledgerListData, pageToken, nextPageToken: nextPageToken }))

        if (cb != null) {
          cb(ledgerListData)
        }
      }
    }

    AccountService.getPositionLedger(req, reqCb)
  }
}

export function downloadPositionLedger(req) {
  return (dispatch) => {
    dispatch(DownloadPositionLedgerStart());
    
    let onSuccess = (data) => {
      dispatch(DownloadPositionLedgerSucceed())
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `positionLedger.csv`);
      document.body.appendChild(link);
      link.click();
    };
    let onError = (error) => {
      console.error(error);
      dispatch(DownloadPositionLedgerFailed(error));
      Notification.error("Could not download report.");
    };

    AccountService.downloadPositionLedger(req, onSuccess, onError)
  }
}

export function setAssociatedAccounts(req, cb) {
  return (dispatch, getState) => {
    dispatch(SetAssociatedAccountsStart());

    const reqCb = (err, resp) => {
      if (err) {
        dispatch(SetAssociatedAccountsFailed(err));
      }

      if (!!resp) {
        dispatch(SetAssociatedAccountsSucceed({ associatedAccounts: {} }))
      }

      if (cb != null) {
        cb(err, resp)
      }
    }

    AccountService.setAssociatedAccounts(req, reqCb)
  }
}

export function listAssociatedAccounts(req, cb) {
  return (dispatch, getState) => {
    dispatch(FetchAssociatedAccountsStart());

    const reqCb = (err, resp) => {
      if (err) {
        dispatch(FetchAssociatedAccountsFailed(err));
        return
      }

      if (!!resp) {
        let accountsList = resp.getAssociatedAccountsList();
        dispatch(FetchAssociatedAccountsSucceed({ associatedAccounts: accountsList }))

        if (cb != null) {
          cb()
        }
      }
    }

    AccountService.getAssociatedAccounts(req, reqCb)
  }
}

export function fetchBalanceLedger(req, cb) {
  return (dispatch, getState) => {
    dispatch(FetchBalanceLedgerStart());

    const reqCb = (err, resp) => {
      if (err) {
        dispatch(FetchBalanceLedgerFailed(err));
        return
      }

      if (!!resp) {
        let ledgerList = resp.toObject();

        const isEOF = resp.getEof();
        const nextPageToken = !isEOF ? resp.getNextPageToken() : null;
        const pageToken = req.getPageToken();

        dispatch(FetchBalanceLedgerSucceed({ balanceLender: ledgerList, pageToken, nextPageToken: nextPageToken }))

        if (cb != null) {
          cb(err, ledgerList)
        }
      }
    }
    AccountService.getAccountBalanceLedger(req, reqCb);
  }
}

export function downloadBalanceLedger(req) {
  return (dispatch) => {
    dispatch(DownloadBalanceLedgerStart());
    
    let onSuccess = (data) => {
      dispatch(DownloadBalanceLedgerSucceed())
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `balanceLedger.csv`);
      document.body.appendChild(link);
      link.click();
    };
    let onError = (error) => {
      console.error(error);
      dispatch(DownloadBalanceLedgerFailed(error));
      Notification.error("Could not download report.");
    };

    AccountService.downloadBalanceLedger(req, onSuccess, onError)
  }
}