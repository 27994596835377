import React from "react";
import PropTypes from "prop-types";
import { ButtonToolbar, Button, ButtonGroup } from "react-bootstrap";

import "./DataGridActions.css";
import { hasWriteAccess } from "../../../services/TokenService";
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import { isNullOrUndefined } from "../../../modules/util"
import { Link } from "react-router-dom";

const DataGridActions = ({
  onView,
  onState,
  onEdit,
  onCopy,
  onApprove,
  onRemove,
  onBook,
  onCorrect,
  onBust,
  onKeys,
  isDeletable,
  deleteMsg,
  onMarketStats,
  nonTradable,
  state,
  onUnDelete,
  onUnDeleteMsg,
  onTransfer,
  viewLink,
  editLink
}) => {

  let title = ""
  if (isNullOrUndefined(isDeletable) || isDeletable)
    title = deleteMsg || "Delete"
  else
    title = deleteMsg

  let crossButtonTooltip = "Bust"

  if (window.location.pathname.includes("/books")) {
    crossButtonTooltip = "Cancel"
  }

  const viewButton = onView ? (
    <Button variant="buttonActions" size="sm" onClick={onView} title="View">
      {viewLink ? (<Link to={viewLink}><i className="fa fa-fw fa-eye orange-icon" /></Link>) :
        <i className="fa fa-fw fa-eye orange-icon" />}
    </Button>
  ) : null;

  const stateButton =
    onState && hasWriteAccess() ? (
      <Button variant="buttonActions" size="sm" onClick={onState} title="State">
        <i className="fa fa-fw fa-exclamation-triangle orange-icon" />
      </Button>
    ) : null;

  const editButton =
    onEdit && hasWriteAccess() ? (
      <Button variant="buttonActions" size="sm" onClick={onEdit} title="Edit">
        {editLink ? (<Link to={editLink}><i className="fa fa-fw fa-edit orange-icon" /></Link>) :
          <i className="fa fa-fw fa-edit orange-icon" />}
      </Button>
    ) : null;

  const copyButton =
    onCopy && hasWriteAccess() ? (
      <Button variant="buttonActions" size="sm" onClick={onCopy} title="Copy">
        <i className="fa fa-fw fa-copy orange-icon" />
      </Button>
    ) : null;

  const approveButton =
    onApprove && hasWriteAccess() ? (
      <Button
        variant="buttonActions"
        size="sm"
        onClick={onApprove}
        title="Approve"
      >
        <i className="fa fa-fw fa-check orange-icon" />
      </Button>
    ) : null;

  const removeButton =
    onRemove && hasWriteAccess() ? (
      <Button
        variant="buttonActions"
        size="sm"
        onClick={onRemove}
        disabled={isDeletable === undefined ? false : !isDeletable}
        title={title}
      >
        <i className="fa fa-fw fa-trash-alt orange-icon" />
      </Button>
    ) : null;

  const bookButton = onBook ? (
    <Button variant="buttonActions" size="sm" onClick={onBook} title="Book">
      <i className="fa fa-fw fa-book orange-icon" />
    </Button>
  ) : null;

  const marketStatsButton =
    onMarketStats ? (
      <Button
        variant="buttonActions"
        size="sm"
        onClick={onMarketStats}
        title="Review Market Stats"
        disabled={state === InstrumentState.INSTRUMENT_STATE_PENDING || state === InstrumentState.INSTRUMENT_STATE_TERMINATED}
      >
        <i className="fa fa-fw fa-cog orange-icon" />
      </Button>
    ) : null;


  const correctButton =
    onCorrect && hasWriteAccess() ? (
      <Button
        variant="buttonActions"
        size="sm"
        onClick={onCorrect}
        title="Correct"
      >
        <i className="fa fa-fw fa-cog orange-icon" />
      </Button>
    ) : null;

  const bustButton =
    onBust && hasWriteAccess() ? (
      <Button variant="buttonActions" size="sm" onClick={onBust} title={crossButtonTooltip}>
        <i className="fa fa-fw fa-times-circle orange-icon" />
      </Button>
    ) : null;

  const keyButton = onKeys ? (
    <Button variant="buttonActions" size="sm" onClick={onKeys} title="API Keys">
      <i className="fa fa-fw fa-key orange-icon" />
    </Button>
  ) : null;

  const unDeleteButton = onUnDelete ? (
    <Button variant="buttonActions" size="sm" onClick={onUnDelete} title={(!!onUnDeleteMsg ? onUnDeleteMsg : "Restore")}>
    <i className="fa fa-fw fa-redo-alt orange-icon" />
  </Button>
  ) : null;

  const transferButton = onTransfer ? (
    <Button variant="buttonActions" size="sm" onClick={onTransfer} title="Transfer">
      <i class="fas fa-random orange-icon"></i>
    </Button>
  ) : null;

  return (
    <ButtonToolbar>
      <ButtonGroup>
        {viewButton}
        {stateButton}
        {editButton}
        {copyButton}
        {approveButton}
        {removeButton}
        {bookButton}
        {marketStatsButton}
        {correctButton}
        {bustButton}
        {keyButton}
        {unDeleteButton}
        {transferButton}
      </ButtonGroup>
    </ButtonToolbar>
  );
};

DataGridActions.propTypes = {
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onState: PropTypes.func,
  onCopy: PropTypes.func,
  onApprove: PropTypes.func,
  onRemove: PropTypes.func,
  onBook: PropTypes.func,
  onKeys: PropTypes.func,
  onMarketStats: PropTypes.func,
  onUnDelete: PropTypes.func,
  onTransfer: PropTypes.func,
};

export default DataGridActions;
