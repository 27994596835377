export const FETCH_ACCOUNTS_START = 'FETCH_ACCOUNTS_START';
export const FETCH_ACCOUNTS_SUCCEED = 'FETCH_ACCOUNTS_SUCCEED';
export const FETCH_ACCOUNTS_FAILED = 'FETCH_ACCOUNTS_FAILED';
export const FETCH_POSITIONS_START = 'FETCH_POSITIONS_START';
export const FETCH_POSITIONS_SUCCEED = 'FETCH_POSITIONS_SUCCEED';
export const FETCH_POSITIONS_FAILED = 'FETCH_POSITIONS_FAILED';
export const DOWNLOAD_POSITIONS_START = 'DOWNLOAD_POSITIONS_START';
export const DOWNLOAD_POSITIONS_SUCCEED = 'DOWNLOAD_POSITIONS_SUCCEED';
export const DOWNLOAD_POSITIONS_FAILED = 'DOWNLOAD_POSITIONS_FAILED';
export const FETCH_PENDING_SETTLEMENTS_START = 'FETCH_PENDING_SETTLEMENTS_START';
export const FETCH_PENDING_SETTLEMENTS_SUCCEED = 'FETCH_PENDING_SETTLEMENTS_SUCCEED';
export const FETCH_PENDING_SETTLEMENTS_FAILED = 'FETCH_PENDING_SETTLEMENTS_FAILED';
export const FETCH_BALANCES_START = 'FETCH_BALANCES_START';
export const FETCH_BALANCES_SUCCEED = 'FETCH_BALANCES_SUCCEED';
export const FETCH_BALANCES_FAILED = 'FETCH_BALANCES_FAILED';
export const FETCH_WITHDRAWALS_START = 'FETCH_WITHDRAWALS_START';
export const FETCH_WITHDRAWALS_SUCCEED = 'FETCH_WITHDRAWALS_SUCCEED';
export const FETCH_WITHDRAWALS_FAILED = 'FETCH_WITHDRAWALS_FAILED';
export const FETCH_ACCOUNT_START = 'FETCH_ACCOUNT_START';
export const FETCH_ACCOUNT_SUCCEED = 'FETCH_ACCOUNT_SUCCEED';
export const FETCH_ACCOUNT_FAILED = 'FETCH_ACCOUNT_FAILED';
export const CLEAN_ACCOUNT = 'CLEAN_ACCOUNT';
export const SAVE_ACCOUNT_START = 'SAVE_ACCOUNT_START';
export const SAVE_ACCOUNT_SUCCEED = 'SAVE_ACCOUNT_SUCCEED';
export const SAVE_ACCOUNT_FAILED = 'SAVE_ACCOUNT_FAILED';
export const CREATE_ACCOUNT_STATE = 'CREATE_ACCOUNT_STATE';
export const FETCH_PARTICIPANTS_LIST_START = 'FETCH_PARTICIPANTS_LIST_START';
export const FETCH_PARTICIPANTS_LIST_SUCCEED = 'FETCH_PARTICIPANTS_LIST_SUCCEED';
export const FETCH_PARTICIPANTS_LIST_FAILED = 'FETCH_PARTICIPANTS_LIST_FAILED';
export const CLEAN_PARTICIPANTS_LIST_GROUP = 'CLEAN_PARTICIPANTS_LIST_GROUP';
export const TOGGLE_SHOW_DELETED_ACCOUNTS = "TOGGLE_SHOW_DELETED_ACCOUNTS";
export const GET_USER_LICENSE_START = "GET_USER_LICENSE_START";
export const GET_USER_LICENSE_SUCCEED = "GET_USER_LICENSE_SUCCEED";
export const GET_USER_LICENSE_FAILED = "GET_USER_LICENSE_FAILED";
export const FETCH_SECURITIES_START = 'FETCH_SECURITIES_START';
export const FETCH_SECURITIES_SUCCEED = 'FETCH_SECURITIES_SUCCEED';
export const FETCH_SECURITIES_FAILED = 'FETCH_SECURITIES_FAILED';
export const FETCH_PENDING_CREDITS_START = 'FETCH_PENDING_CREDITS_START'
export const FETCH_PENDING_CREDITS_SUCCEED = 'FETCH_PENDING_CREDITS_SUCCEED'
export const FETCH_PENDING_CREDITS_FAILED = 'FETCH_PENDING_CREDITS_FAILED'
export const FETCH_POSITION_LEDGER_START = 'FETCH_POSITION_LEDGER_START'
export const FETCH_POSITION_LEDGER_SUCCEED = 'FETCH_POSITION_LEDGER_SUCCEED'
export const FETCH_POSITION_LEDGER_FAILED = 'FETCH_POSITION_LEDGER_FAILED'
export const DOWNLOAD_POSITION_LEDGER_START = 'DOWNLOAD_POSITION_LEDGER_START';
export const DOWNLOAD_POSITION_LEDGER_SUCCEED = 'DOWNLOAD_POSITION_LEDGER_SUCCEED';
export const DOWNLOAD_POSITION_LEDGER_FAILED = 'DOWNLOAD_POSITION_LEDGER_FAILED';
export const FETCH_BALANCE_LEDGER_START = 'FETCH_BALANCE_LEDGER_START'
export const FETCH_BALANCE_LEDGER_SUCCEED = 'FETCH_BALANCE_LEDGER_SUCCEED'
export const FETCH_BALANCE_LEDGER_FAILED = 'FETCH_BALANCE_LEDGER_FAILED'
export const DOWNLOAD_BALANCE_LEDGER_START = 'DOWNLOAD_BALANCE_LEDGER_START'
export const DOWNLOAD_BALANCE_LEDGER_SUCCEED = 'DOWNLOAD_BALANCE_LEDGER_SUCCEED'
export const DOWNLOAD_BALANCE_LEDGER_FAILED = 'DOWNLOAD_BALANCE_LEDGER_FAILED'

export const FETCH_ASSOCIATED_ACCOUNTS_START = 'FETCH_ASSOCIATED_ACCOUNTS_START'
export const FETCH_ASSOCIATED_ACCOUNTS_SUCCEED = 'FETCH_ASSOCIATED_ACCOUNTS_SUCCEED'
export const FETCH_ASSOCIATED_ACCOUNTS_FAILED = 'FETCH_ASSOCIATED_ACCOUNTS_FAILED'

export const SET_ASSOCIATED_ACCOUNTS_START = 'SET_ASSOCIATED_ACCOUNTS_START'
export const SET_ASSOCIATED_ACCOUNTS_SUCCEED = 'SET_ASSOCIATED_ACCOUNTS_SUCCEED'
export const SET_ASSOCIATED_ACCOUNTS_FAILED = 'SET_ASSOCIATED_ACCOUNTS_FAILED'

