import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import "./index.css";
import CheckboxGroup from "../form/CheckboxGroup";
import { FormModes } from "../../../constants/enumerations";

const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

function getTimeFormat(time) {
  if (time.seconds() > 0) {
    return "h:mm:ss a z";
  }
  return "h:mm a z";
}

function getHolidayCalendarTitle(key, holidays) {
  if (!holidays || !holidays.hasOwnProperty(key)) {
    return key
  }
  const ttl = holidays[key].global ? key + " (Global)" : key;
  return ttl + " - " + holidays[key].description;
}

const TradingHours = ({
  scheduleList,
  holidayCalendars,
  addHolidayCalendar,
  removeHolidayCalendar,
  enableEdit,
  addSchedule,
  editSchedule,
  removeSchedule,
  metadata,
  holidays,
  mode
}) => {
  const timezone = metadata && metadata.location ? metadata.location : "UTC";
  const displayedHolidayCalendars = !holidays ? [] : Object.keys(holidays);
  const isViewing = mode === FormModes.view;

  return (
    <React.Fragment>
      <label style={{ width: "262.78px" }}>
        {"Trading Hours (" + timezone + ")"}
      </label>
      {!isViewing && enableEdit && (
        <button
          type="button"
          onClick={() => addSchedule()}
          className="btn btn-secondary-small"
          style={{ paddingLeft: "0px", paddingBottom: "12px" }}
        >
          <i
            className="fa fa-fw fa-plus orange-icon"
            style={{ fontSize: "18px" }}
          />
          Add
        </button>
      )}
      <div style={{ marginBottom: "10px" }}>
        <table cellPadding={0} cellSpacing={0}>
          <tbody>
            {scheduleList &&
              scheduleList.map((schedule, index) => {
                const startDay = schedule.daysOfWeekList[0];
                const endDay =
                  schedule.daysOfWeekList[schedule.daysOfWeekList.length - 1];
                const { hours, minutes, seconds } = schedule.timeOfDay;
                const startTime = moment();
                startTime.hours(hours).minutes(minutes).seconds(seconds);
                const endTime = moment(startTime).add(
                  schedule.duration.seconds,
                  "seconds"
                );
                const sequential = schedule.daysOfWeekList.every(
                  (number, i) =>
                    i === 0 || schedule.daysOfWeekList[i - 1] === number - 1
                );

                return (
                  <tr key={index}>
                    {schedule.daysOfWeekList.length === 1 && (
                      <td className="hoursText">
                        {days[startDay]} &nbsp;
                        {startTime.format(getTimeFormat(startTime))} -{" "}
                        {endTime.format(getTimeFormat(endTime))}
                      </td>
                    )}
                    {schedule.daysOfWeekList.length > 1 && (
                      <td className="hoursText">
                        {sequential && (
                          <span>
                            {days[startDay]} - {days[endDay]} &nbsp;
                          </span>
                        )}
                        {!sequential && (
                          <span>
                            {schedule.daysOfWeekList
                              .map((day) => {
                                return days[day];
                              })
                              .join(", ")}{" "}
                            &nbsp;
                          </span>
                        )}
                        {startTime.format(getTimeFormat(startTime))} -{" "}
                        {endTime.format(getTimeFormat(endTime))}
                      </td>
                    )}
                    <td style={{ marginRight: "10px" }}>
                      {schedule.state && <i>{schedule.state.name}</i>}
                    </td>
                    {!isViewing && enableEdit && (
                      <React.Fragment>
                        <td>
                          <button
                            type="button"
                            onClick={() => editSchedule(schedule, index)}
                            className="btn btn-text btn-sm"
                            style={{ padding: "0px", marginLeft: "5px" }}
                          >
                            <i className="fas fa-edit grey-icon" />
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => removeSchedule(index)}
                            className="btn btn-text btn-sm"
                            style={{ padding: "0px" }}
                          >
                            <i className="fas fa-times-circle grey-icon" />
                          </button>
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label>Holiday Calendars</label>
        <table cellPadding={0} cellSpacing={0}>
          <tbody>
          {Array.isArray(displayedHolidayCalendars) && displayedHolidayCalendars.length > 0 &&
          displayedHolidayCalendars.map((cal, index) => {
            return (
                <tr key={index}>
                  <td className="hoursText">
                      <CheckboxGroup
                          checked={holidays[cal].global || (Array.isArray(holidayCalendars) && holidayCalendars.includes(cal))}
                          onClick={(e) => {
                            if (e.target.checked) {
                              addHolidayCalendar(cal);
                            } else {
                              removeHolidayCalendar(holidayCalendars.indexOf(cal));
                            }
                          }}
                          disabled={!enableEdit || holidays[cal].global}
                          label={getHolidayCalendarTitle(cal, holidays)}
                      />
                  </td>
                </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

TradingHours.propTypes = {
  scheduleList: PropTypes.array.isRequired,
  holidays: PropTypes.object.isRequired,
  enableEdit: PropTypes.bool.isRequired,
  editSchedule: PropTypes.func,
  removeSchedule: PropTypes.func,
  addHolidayCalendar: PropTypes.func,
  removeHolidayCalendar: PropTypes.func,
};

export default TradingHours;
