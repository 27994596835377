import React from 'react';
import ReactTooltip from 'react-tooltip';
import '../../../../src/App.css'

export default function TooltipHtml(props) {
  return (
    <div style={{display: 'inline-block', marginLeft: '5px', fontSize:"12px"}}>
      <i data-tip={props.text} data-for={props.id} className="fa fa-question-circle fa-2" aria-hidden="true"></i>
      <ReactTooltip data-multiline="true" data-html="true" id={props.id} type="info" effect="solid" place={props.place}>
         {props.text}
      </ReactTooltip>
    </div>
  )
}