import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormGroup, FormLabel } from "react-bootstrap";
import DurationControl from "../core/duration/DurationControl";
import Tooltip from './tooltip-html/tooltip-html';
import { isNullOrUndefined } from "../../modules/util";
import { getIn } from 'formik';

const TimeDuration = ({
    onChange,
    id,
    name,
    label,
    tooltip,
    pattern,
    value,
    errors,
    plaintext,
    readonlyValue,
    disabled, 
}) => {
    const [millis, setMillis] = useState(0);

    const error = getIn(errors, name);

    useEffect(() => {
        if (value) setMillis(value);
    }, [value])

    const onHandleChange = (value) => {
        setMillis(value);
    }

    const onHandleBlur = () => {
        onChange(millis);
    }

    return (
        <FormGroup id={id} name={name} >
            {label && <FormLabel>{label}</FormLabel>}

            {!isNullOrUndefined(tooltip) &&
                <Tooltip
                    text={tooltip}
                    id={`fieldGroupTooltip_${name}`}
                ></Tooltip>
            }

            <div className="align-center align-items-baseline">
                <div>
                    {plaintext && <div className="form-readonly-label">{readonlyValue ? readonlyValue : (value ? value : "")}</div>}
                    
                    {!plaintext && <>
                        <DurationControl
                            disabled={disabled}
                            pattern={pattern}
                            value={millis}
                            onChange={onHandleChange}
                            hideSpinner={true}
                            onBlur={onHandleBlur}
                        />
                        {error ? (
                            <div className="form-error">
                                <i
                                    className="fa fa-exclamation-triangle orange-icon"
                                    aria-hidden="true"
                                ></i>{" "}
                                {error}
                            </div>
                        ) : errors && errors[name] && (
                            <div className="form-error">
                                <i
                                    className="fa fa-exclamation-triangle orange-icon"
                                    aria-hidden="true"
                                ></i>{" "}
                                {errors[name]}
                            </div>
                        )}</>
                    }
                </div>
            </div>
        </FormGroup>
    );
};

TimeDuration.defaultProps = {
    pattern: "Hours {hh} Minutes {mm} Seconds {ss} Milliseconds {fff}",
};

TimeDuration.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default TimeDuration;
