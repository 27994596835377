import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TitleBreadcrumb } from '../core/title-breadcrumb/title-breadcrumb';

class TimeSalesHeader extends Component {
  render() {
    return (
      <div>
        <TitleBreadcrumb titles={[{'title': 'Time + Sales', link:`${window.location.origin}/timeandsales`}]} />
      </div>
    )
  }
}

TimeSalesHeader.propTypes = {
  onFilterClick: PropTypes.func,
  onRefreshAutoComplete: PropTypes.func,
  autoCompleteDataSource: PropTypes.array
}

export default TimeSalesHeader;