import React from 'react';
import { Col, Row } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import { Container } from "react-bootstrap";
import AccountService from "../../services/AccountService";
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import Notification from "../../modules/notifications";

const PendingCreditsForm = (props) => {
  const { values, errors, onChange, show, isApproving, isViewing, isDeleting, onComplete, reloadCredits } = props;

  let isReadOnly = isViewing || isApproving || isDeleting;

  return (
    <ConfirmDialog
      show={!!show}
      proceed={() => {
        if (isApproving) {
          AccountService.approvePendingCredits(values.id, null, (err, response) => {
            if (response) {
              Notification.success("Pending credits request approved!");
              reloadCredits();
            }
            if (err) {
              Notification.error(err.message);
            }
          });
        }

        if (isDeleting){
          AccountService.deletePendingCredits(values.id, null, (err, response) => {
            if (response) {
              Notification.success("Pending credits request deleted!");
              reloadCredits();
            }
            if (err) {
              Notification.error(err.message);
            }
          });
        }

        onComplete();
      }}
      cancel={() => {
        onComplete();
      }}
      confirmation={
        <Container>
          <div className="pending-credits-form" >
            <Row>
              <Col>
                <FieldGroup id="Currency" name="Currency" errors={errors} onChange={onChange}
                  value={values.currency || ""} label="Currency" plaintext={isReadOnly} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FieldGroup id="Amount" name="Amount" errors={errors} onChange={onChange}
                  value={values.balance || ""} label="Amount" plaintext={isReadOnly} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FieldGroup id="CorrelationID" name="CorrelationID" errors={errors} onChange={onChange}
                  value={values.correlationId || ""} label="Correlation ID" plaintext={isReadOnly} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FieldGroup id="Description" name="Description" errors={errors} onChange={onChange}
                  value={values.description || ""} label="Description" plaintext={isReadOnly} />
              </Col>
            </Row>
          </div>
        </Container>
      }
      options={{
        title: `${isApproving ? "Approve" : (isViewing ? "View" : (isDeleting ? "Delete" : "Create"))} Pending Credits`,
        okButtonText: isApproving ? "Approve" :  (isDeleting ? "Delete" : (isViewing ? null : "Submit")),
        cancelButtonText: "Cancel",
      }}
    />
  )
}

export default PendingCreditsForm;
