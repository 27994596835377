import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  FetchParticipantGroupsStart,
  FetchParticipantGroupsSucceed,
  FetchParticipantGroupsFailed,
  CleanParticipantGroup
} from "../actions/participantGroups";
import Loader from "../components/core/loader/Loader";
import ParticipantGroup from "../entities/ParticipantGroup";
import "./Content.css";
import ParticipantHeader from "../components/participant/ParticipantHeader";
import ParticipantGroupDataGrid from "../components/participant-group/ParticipantGroupDataGrid";
import { AdminDocumentTitle } from "../constants/strings";
import ParticipantGroupService from "../services/ParticipantGroupService";
import Notification from "../modules/notifications";
import { hasWriteAccess } from "../services/TokenService";
import { getHelmet } from "../modules/util";
import { NavigationManager } from "../components/core/helpers/NavigationHelper";

function mapStateToProps(state) {
  return {
    fetchingParticipantGroups: state.fetchingParticipantGroups,
    participantGroups: state.participantGroups,
    firms: state.firms.firms,
  };
}

const mapDispatchToProps = dispatch => ({
  fetchGroupsStart: () => {
    dispatch(FetchParticipantGroupsStart());
  },
  fetchGroupsSucceed: participantGroupList => {
    dispatch(FetchParticipantGroupsSucceed(participantGroupList));
  },
  fetchGroupsFailed: () => {
    dispatch(FetchParticipantGroupsFailed());
  },
  cleanParticipantGroup: () => {
    dispatch(CleanParticipantGroup());
  }
});

class ParticipantGroups extends Component {
  componentDidMount() {
    document.title = AdminDocumentTitle;

    this.props.cleanParticipantGroup();
    this.loadParticipantGroups();
    window.scrollTo(0, 0);
  }

  static contextTypes = {
    router: PropTypes.object
  };

  openViewParticipantGroup = (participantGroupId, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/participant-groups/${participantGroupId}`);
    } else {
      this.context.router.history.push(`/participant-groups/${participantGroupId}`);
    }
  };

  openEditParticipantGroup = (participantGroupId, actionContext) => {
    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(`/participant-groups/${participantGroupId}/edit`);
    } else {
      this.context.router.history.push(`/participant-groups/${participantGroupId}/edit`);
    }
  };

  openCreateParticipantGroup = () => {
    this.context.router.history.push(`/participant-groups/new`);
  };

  loadParticipantGroups = () => {
    this.props.fetchGroupsStart();
    const cb = (err, response) => {
      if (response) {
        let groupsList = response.getParticipantGroupsList().map(group => {
          return new ParticipantGroup(group, this.props.firms);
        });
        groupsList = groupsList.filter(item => !item.account);
        this.props.fetchGroupsSucceed(groupsList);
      }

      if (err) {
        this.props.fetchGroupsFailed();
        Notification.error("Cannot get participant groups list.");
      }
    };

    ParticipantGroupService.getAll(cb);
  };

  render() {
    return (
      <div className="with-callback">
        <ParticipantHeader
          headerText={getHelmet({})}
          buttonText="ADD NEW GROUP"
          buttonClick={() => {
            this.openCreateParticipantGroup();
          }}
          isParticipantGroupsSelected={true}
          isAddButtonVisible={hasWriteAccess()}
        />
        <Loader
          show={this.props.participantGroups.fetchingParticipantGroups}
        ></Loader>
        <ParticipantGroupDataGrid
          data={this.props.participantGroups.participantGroups}
          onView={(participantGroupId, actionContext) => {
            this.openViewParticipantGroup(participantGroupId, actionContext);
          }}
          onEdit={(participantGroupId, actionContext) => {
            this.openEditParticipantGroup(participantGroupId, actionContext);
          }}
        ></ParticipantGroupDataGrid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantGroups);
