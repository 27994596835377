import React, { useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import { Container, Row, Col, FormLabel } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import DropdownListGroup from "../core/form/DropdownListGroup";
import DateGroup from "../core/form/DateGroup";
import BlockTradeService from "../../services/BlockTradeService";
import { v4 } from "uuid";
import { fetchSymbols } from "../../actions/instruments";
import { blockTradeBrokerUsersUpdate, blockTradeUsersUpdate } from "../../actions/blockTrades";
import Notification from "../../modules/notifications";
import InstrumentService from "../../services/InstrumentService";
import { convertScaledPriceToInt, isNullOrUndefined, isNumber, parsePrice } from "../../modules/util";
import ErrorLabel from "../core/form/ErrorLabel";
import { listBrokerUsers, listFirmAccountUsers } from "./BlockTradeHelper";
import "./BlockTradeOrderTicket.css";
import DropdownTree from "./DropdownTree"

const BlockTradeOrderTicket = (props) => {
  const { users, symbols, brokerUsers, onClose } = props;

  const [symbol, setSymbol] = useState();
  const [buyer, setBuyer] = useState({});
  const [seller, setSeller] = useState();
  const [broker, setBroker] = useState();
  const [transactionBookedTime, setTransactionBookedTime] = useState(new Date());
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    props.loadSymbols();
    listFirmAccountUsers().then((firmAccUsers) => {
      props.updateUsers(firmAccUsers);
    }).catch((err) => {
      Notification.error(`Error when fetching firm account users: ${err?.message}`);
    });

    listBrokerUsers().then((brokerUsers) => {
      props.updateBrokerUsers(brokerUsers);
    }).catch((err) => {
      Notification.error(`Error when fetching broker users: ${err?.message}`);
    });
  }, [])

  const symbolList = useMemo(() => {
    const symbolsList = [];

    symbols.forEach((symbol) => {
      symbolsList.push({ id: v4(), name: symbol });
    });

    return symbolsList;
  }, [symbols])

  const validateValues = () => {
    if (isNullOrUndefined(symbol)) {
      errors.symbol = "Symbol is required"
    } else {
      delete errors.symbol;
    }

    if (isNullOrUndefined(buyer?.user)) {
      errors.buyer = "Buyer is required";
    } else {
      delete errors.buyer;
    }

    if (isNullOrUndefined(seller?.user)) {
      errors.seller = "Seller is required";
    } else {
      delete errors.seller;
    }

    if (!!transactionBookedTime && new Date(transactionBookedTime) > Date.now()) {
      errors.transactionBookedTime = "Transaction booked time cannot be in the future";
    } else {
      delete errors.transactionBookedTime;
    }

    if (!isNumber(price)) {
      errors.price = "Price is required and must be a valid number.";
    } else {
      delete errors.price;
    }

    if (!isNumber(quantity) || Number(quantity) <= 0) {
      errors.quantity = "Quantity is required and must be > 0";
    } else {
      delete errors.quantity;
    }

    setErrors({ ...errors });
    return Object.keys(errors).length === 0;
  }

  const clearAll = () => {
    setSymbol("");
    setBuyer({});
    setSeller({});
    setBroker({});
    setTransactionBookedTime(new Date());
    setPrice(0);
    setQuantity(0);
    setErrors({});
  }

  return <ConfirmDialog
    show={true}
    proceed={async () => {
      if (!validateValues()) return;
      const resp = await InstrumentService.get2(symbol.name);
      const selectedInst = resp.getInstrument();
      const attrs = selectedInst.getAttributes()
      const priceScale = selectedInst.getPriceScale();
      const qtyScale = attrs.getFractionalQtyScale();

      const tickSize = attrs.getTickSize();
      const minTradeQty = attrs.getMinimumTradeQty();

      const scaledTickSize = convertScaledPriceToInt(tickSize, priceScale)
      const scaledMinTradeQty = convertScaledPriceToInt(minTradeQty, qtyScale)

      if (attrs.hasBlockTradeThreshold()) {
        const threshold = attrs.getBlockTradeThreshold()
        let minQty = threshold.getMinimumQuantity();
        let minNotional = threshold.getMinimumNotional()

        minQty = parseFloat(parsePrice(minQty, qtyScale))
        minNotional = parseFloat(parsePrice(minNotional, priceScale))

        if (price < minNotional) {
          errors.price = `Price must be greater than or equal to ${minNotional}`;
        }

        if (quantity < minQty) {
          errors.quantity = `Quantity must be greater than or equal to ${minQty}`;
        }
      }

      const scaledPrice = convertScaledPriceToInt(price, priceScale);
      const scaledQty = convertScaledPriceToInt(quantity, qtyScale);

      if (scaledPrice % scaledTickSize !== 0) {
        errors.price = `Price must be a multiple of ${tickSize}`;
      }

      if (scaledQty < scaledMinTradeQty) {
        errors.quantity = `Quantity must be greater than or equal to ${minTradeQty}`;
      }

      setErrors({ ...errors });

      if (Object.keys(errors).length > 0) return false;

      BlockTradeService.insertTwoSidedBlockTrade({
        symbol: symbol,
        qty: scaledQty,
        price: scaledPrice,
        buyer: buyer,
        seller: seller,
        broker: broker,
        transactionBookedTime: transactionBookedTime
      }, (err, response) => {
        if (err) {
          Notification.error(`Error when inserting block trade: ${err?.message}`);
          return
        }

        Notification.success("Block trade created successfully");
        clearAll();
      }).catch((err) => {
        Notification.error(`Error when inserting block trade: ${err?.message}`);
      });
    }}

    cancel={(evt) => {
      if (evt?.target?.type === 'button') {
        onClose();
      }

      console.log(evt)
    }}

    options={{
      title: "New Block Trade",
      okButtonText: "Submit",
      cancelButtonText: "Cancel",
    }}

    confirmation={
      <Container>
        <Row>
          <Col lg={6} xs={6} md={12}>
            <DropdownListGroup id="symbol" name="symbol" label="Symbol"
              isRequired={true}
              value={symbol}
              errors={errors}
              showFilter={true}
              onChange={(e) => setSymbol(e)}
              data={symbolList}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} xs={6} md={12}>

          </Col>
        </Row>

        <Row>
          <Col lg={6} xs={6} md={12}>
            <FormLabel>Buyer <label className="form-label-required"> Required</label></FormLabel>
            <DropdownTree data={users} onChange={(currentNode, selectedNodes) => {
              if (selectedNodes.length > 0) {
                const buyer = { user: currentNode.value.name, account: currentNode.value.parent };
                setBuyer(buyer)
              } else {
                setBuyer(null)
              }
            }} />

            <ErrorLabel id="buyer" text={errors.buyer} />
          </Col>

          <Col lg={6} xs={6} >
            <FormLabel>Seller <label className="form-label-required"> Required</label></FormLabel>
            <DropdownTree data={users} onChange={(currentNode, selectedNodes) => {
              if (selectedNodes.length > 0) {
                const seller = { user: currentNode.value.name, account: currentNode.value.parent };
                setSeller(seller)
              } else {
                setSeller(null)
              }
            }} />

            <ErrorLabel id="seller" text={errors.seller} />
          </Col>
        </Row>

        <Row>
          <Col lg={6} xs={6} md={12}>
            <FieldGroup id="price" label="Price" name="price"
              isRequired={true}
              errors={errors}
              value={price}
              onChange={(e) => setPrice(e.target.value)} />
          </Col>

          <Col lg={6} xs={6} md={12}>
            <FieldGroup id="quantity" label="Quantity" name="quantity"
              isRequired={true}
              errors={errors}
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)} />
          </Col>
        </Row>

        <Row>
          <Col lg={6} xs={6} md={6}>
            <DateGroup id="transactionBookedTime" name="transactionBookedTime" label="Transaction Booked Time"
              errors={errors}
              value={transactionBookedTime ? new Date(transactionBookedTime) : null}
              onChange={(e) => setTransactionBookedTime(e)}
              enableTime={true}
            />
          </Col>

          <Col lg={6} xs={6} md={12}>
            <FormLabel>Broker</FormLabel>
            <DropdownTree data={brokerUsers} onChange={(currentNode, selectedNodes) => {
              if (selectedNodes.length > 0) {
                const broker = { user: currentNode.value.name, agentFirm: currentNode.value.parent };
                setBroker(broker)
              } else {
                setBroker(null)
              }
            }} />

          </Col>
        </Row>
      </Container>
    }
  />
}

function mapStateToProps(state) {
  return {
    symbols: state.instruments.symbols,
    accounts: state.blockTrades.accounts,
    firmsToUsers: state.blockTrades.firmsToUsers,
    usersToAccounts: state.blockTrades.usersToAccounts,
    firms: state.blockTrades.firms,
    users: state.blockTrades.users,

    brokerUsersToAccounts: state.blockTrades.brokerUsersToAccounts,
    brokerAccounts: state.blockTrades.brokerAccounts,
    brokerUsers: state.blockTrades.brokerUsers,
    brokerFirms: state.blockTrades.brokerFirms,
    brokerFirmsToUsers: state.blockTrades.brokerFirmsToUsers,
  };
}

const mapDispatchToProps = (dispatch) => ({
  loadSymbols: () => {
    dispatch(fetchSymbols());
  },

  updateUsers: (payload) => {
    dispatch(blockTradeUsersUpdate(payload));
  },

  updateBrokerUsers: (payload) => {
    dispatch(blockTradeBrokerUsersUpdate(payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockTradeOrderTicket);
