import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadFirms } from "../../actions/firms";
import { Container, Row, Col } from 'react-bootstrap';

import FieldGroupReadOnly from '../core/form/FieldGroupReadOnly';
import ButtonBorderless from '../core/form/ButtonBorderless';
import ParticipantDataGrid from "../participant/ParticipantDataGrid";
import { NavigationManager } from '../core/helpers/NavigationHelper';

function mapStateToProps(state) {
  return {
    group: state.participantGroups.participantGroup,
    participants: state.participantGroups.participantsList,
    firms: state.firms
  };
}

const mapDispatchToProps = (dispatch) => ({
  loadFirms: () => {
    dispatch(LoadFirms());
  },
});

class ParticipantGroupFormReadOnly extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    this.props.loadFirms();
  }

  openViewParticipant = (name, actionContext) => {
    let viewUrl = `/users/${name}`; //support legacy users
    if (name.startsWith('firms/')) {
      viewUrl = `/${name}`;
    }

    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(viewUrl);
    }
    else {
      this.context.router.history.push(viewUrl);
    }
  };

  openEditParticipant = (name, actionContext) => {
    let viewUrl = `/users/${name}/edit`; //support legacy users
    if (name.startsWith('firms/')) {
      viewUrl = `/${name}/edit`;
    }

    if (!!actionContext && actionContext.key === 'OPEN_IN_NEW_TAB') {
      NavigationManager.openInNewTab(viewUrl);
    }
    else {
      this.context.router.history.push(viewUrl);
    }
  };

  render() {
    const {
      group,
      participants,
      firms
    } = this.props;

    if (!group || group.account) return null;
    return (
      <form>
        <h1>View Participant Group</h1>
        <Container>
          <Row>
            <Col lg={4} xs={4} md={4}>
              <FieldGroupReadOnly
                id="groupName"
                label="Participant Group Name"
                value={group.id} />
            </Col>
          </Row>
          <Row>
            <Col lg={4} xs={4} md={4}>
              <FieldGroupReadOnly
                id="firmName"
                label="Firm"
                value={group.firmDisplayName} />
            </Col>
          </Row>
          <Row>
            <Col lg={4} xs={4} md={4}>
              <FieldGroupReadOnly
                id="participants"
                label="Group participants"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={12} md={12}>
              <ParticipantDataGrid
                data={participants}
                firms={firms}
                onView={(participantId, actionContext) => { this.openViewParticipant(participantId, actionContext) }}
                onEdit={(participantId, actionContext) => { this.openEditParticipant(participantId, actionContext) }}>
              </ParticipantDataGrid>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={12} md={12}>
              <ButtonBorderless
                type="button"
                text="Cancel"
                icon="times-circle"
                customClassName="grey-icon"
                onClick={(e) => {
                  e.preventDefault();
                  this.context.router.history.push('/participant-groups');
                }} />
            </Col>
          </Row>
        </Container>
      </form>
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantGroupFormReadOnly);