import { protoDateToDate } from '../../actions/ptypes';
import { getOrderSideName, getOrderStateName, getOrderTimeInForceName, getOrderTypeName, getOrderCapacityName } from '../../modules/mappers/enumsMapper';
import { parsePrice, convertDateToString } from '../../modules/util';

export default class OrderSearchGridItem {
  constructor(item, priceScale, qtyScale, execution) {
    if (item) {
      this.participant = item.getParticipant();
      this.submittingParticipant = item.getSubmittingParticipant();
      this.account = item.getAccount();
      this.side = getOrderSideName(item.getSide());
      this.symbol = item.getSymbol();
      this.timeInForce = getOrderTimeInForceName(item.getTimeInForce());
      this.ordType = getOrderTypeName(item.getType());
      this.orderCapacity = getOrderCapacityName(item.getOrderCapacity());
      this.orderStatus = getOrderStateName(item.getState());
      this.orderQty = parsePrice(item.getOrderQty(), qtyScale);
      this.cashOrderQty = parsePrice(item.getCashOrderQty(), priceScale, qtyScale);
      this.minQty = parsePrice(item.getMinQty(), qtyScale);
      this.openQty = parsePrice(item.getLeavesQty(), qtyScale);
      this.cumQty = parsePrice(item.getCumQty(), qtyScale);
      this.price = parsePrice(item.getPrice(), priceScale);
      this.stopPrice = parsePrice(item.getStopPrice(), priceScale);
      this.avgPx = parsePrice(item.getAvgPx(), priceScale);
      this.clordid = item.getClordId();
      if (item.getLeavesQty() !== 0) this.id = item.getId();
      this.orderid = item.getId();
      this.crossid = item.getCrossId();
      this.allOrNone = item.getAllOrNone();
      this.strictLimit = item.getStrictLimit();
      this.bestLimit = item.getBestLimit();
      this.immediatelyExecutableLimit = item.getImmediatelyExecutableLimit();
      this.participateDontInitiate = item.getParticipateDontInitiate();
      this.clientaccountid = item.getClientAccountId();
      this.clientparticipantid = item.getClientParticipantId();
      this.parentorderid = item.getParentOrderId();
      this.symbolsubtype = item.getSymbolSubType();
      this.hostcrossid = item.getHostCrossId();
      this.stopTriggerMethod = item.getTriggerMethod();
      this.blockTradeIndicator = item.getBlockTradeIndicator();
      this.collateralMemo = item.getCollateralMemo();
      this.priorityWeight = item.getPriorityWeight();
      this.commissionNotionalTotalCollected = parsePrice(item.getCommissionNotionalTotalCollected(), priceScale, qtyScale);
      this.commissionsBasisPoints = item.getCommissionsBasisPoints();

      const createTime = item.getCreateTime();
      this.createTime = createTime ? convertDateToString(createTime.toDate(), "YYYYMMDD-HH:mm:ss.SSS") : "";

      const insertTime = item.getInsertTime()
      this.insertTime = insertTime ? convertDateToString(insertTime.toDate(), "YYYYMMDD-HH:mm:ss.SSS") : "";

      const goodTillTime = item.getGoodTillTime();
      this.goodTillTime = goodTillTime ? convertDateToString(goodTillTime.toDate(), "YYYYMMDD-HH:mm:ss.SSS") : "";

      const riskCheckTime = item.getRiskCheckTime();
      this.riskCheckTime = riskCheckTime ? convertDateToString(riskCheckTime.toDate(), "YYYYMMDD-HH:mm:ss.SSS") : "";

      const transactionBookedTime = item.getTransactionBookedTime();
      this.transactionBookedTime = transactionBookedTime ? convertDateToString(transactionBookedTime.toDate(), "YYYYMMDD-HH:mm:ss.SSS") : "";

      const lastTransactTime = item.getLastTransactTime(); 
      this.lastTransactTime = lastTransactTime ? convertDateToString(lastTransactTime.toDate(), "YYYYMMDD-HH:mm:ss.SSS") : "";

      this.text = execution?.getText() || "";
      const transactTradeDate = execution?.getTransactTradeDate();
      this.transactTradeDate = !!transactTradeDate ? convertDateToString(protoDateToDate(transactTradeDate), "YYYYMMDD") : "";

      return;
    }
    
    this.participant = null;
    this.submittingParticipant = null;
    this.account = null;
    this.side = null;
    this.symbol = null;
    this.timeInForce = null;
    this.ordType = null;
    this.orderCapacity = null;
    this.orderStatus = null;
    this.orderQty = null;
    this.cashOrderQty = null;
    this.minQty = null;
    this.openQty = null;
    this.cumQty = null;
    this.price = null;
    this.stopPrice = null;
    this.avgPx = null;
    this.clordid = null;
    this.orderid = null;
    this.crossid = null;
    this.allOrNone = null;
    this.strictLimit = null;
    this.bestLimit = null;
    this.immediatelyExecutableLimit = null;
    this.participateDontInitiate = null;
    this.clientaccountid = null;
    this.clientparticipantid = null;
    this.createTime = null;
    this.insertTime = null;
    this.parentorderid = null;
    this.symbolsubtype = null;
    this.hostcrossid = null;
    this.goodTillTime = null;
    this.riskCheckTime = null;
    this.stopTriggerMethod = null;
    this.collateralMemo = null;
    this.priorityWeight = null;
    this.commissionNotionalTotalCollected = null;
    this.commissionsBasisPoints = null;
    this.transactionBookedTime = null;
    this.lastTransactTime = null;
  }
}