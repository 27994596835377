import {
  FETCH_PRODUCTSLIST_START,
  FETCH_PRODUCTSLIST_SUCCEED,
  FETCH_PRODUCTSLIST_FAILED
} from '../constants/productListTypes';
import {
  RESET,
} from '../constants/appTypes';

const initialState = {
  fetchingProductsList: false,
  productsList: []
}

const productsList = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTSLIST_START:
      return {
        ...state,
        fetchingProductsList: true
      };
    case FETCH_PRODUCTSLIST_SUCCEED:
      return {
        ...state,
        fetchingProductsList: false,
        productsList: action.payload
      };
    case FETCH_PRODUCTSLIST_FAILED:
      return {
        ...state,
        fetchingProductsList: false,
        productsList: []
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    default:
      return state;
  }
}

export default productsList
