import {
  FETCH_TRADE_CAPTURE_START,
  FETCH_TRADE_CAPTURE_SUCCEED,
  FETCH_TRADE_CAPTURE_FAILED,
  BUST_TRADE_START,
  BUST_TRADE_SUCCEED,
  BUST_TRADE_FAILED,
} from '../constants/tradeTypes';
import {
  RESET,
} from '../constants/appTypes';
import TimeSalesTradeCaptureFormItem from '../entities/dto/TimeSalesTradeCaptureFormItem';

const initialState = {
  processingData: false,
  tradeCapture: new TimeSalesTradeCaptureFormItem()
}

const trades = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRADE_CAPTURE_START:
      return {
        ...state,
        processingData: true,
        tradeCapture: new TimeSalesTradeCaptureFormItem()
      };
    case FETCH_TRADE_CAPTURE_SUCCEED:
      return {
        ...state,
        processingData: false,
        tradeCapture: action.payload
      };
    case FETCH_TRADE_CAPTURE_FAILED:
      return {
        ...state,
        processingData: false,
        tradeCapture: new TimeSalesTradeCaptureFormItem()
      };
    case BUST_TRADE_START:
      return {
        ...state,
        processingData: true
      };
    case BUST_TRADE_SUCCEED:
      return {
        ...state,
        processingData: false
      };
    case BUST_TRADE_FAILED:
      return {
        ...state,
        processingData: false
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    default:
      return state;
  }
}

export default trades;
