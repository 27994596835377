import { getEnumName, capitalizeWord } from "../modules/util";
import {
  FirmState,
  FirmType,
} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { Firm as typeFirm } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import State from "./dto/State";

export default class Firm {
  constructor(item) {
    if (item) {
      if (item instanceof typeFirm) {
        this.id = item.getId();
        this.name = item.getName();
        this.displayName = item.getDisplayName();

        var state = new State();
        state.id = item.getState();
        state.name = capitalizeWord(getEnumName(FirmState, state.id));
        this.state = state;
        this.isSuspended = state.id === FirmState.FIRM_STATE_SUSPENDED;
        var firmType = new State();
        firmType.id = item.getType();
        firmType.name = capitalizeWord(getEnumName(FirmType, firmType.id));
        this.firmType = firmType;
        this.deleted = item.getDeleted();
        this.applyCommissionsAsSpread = item.getApplyCommissionsAsSpread();
        this.skipCommissionBalanceAdjustment = item.getSkipCommissionBalanceAdjustment();

        item.getAliasesMap().forEach((alias, option) => {
          this.aliases.push({ option, alias });
        });

      } else {
        this.id = item.id;
        this.name = item.name;
        this.displayName = item.displayName;
        this.state = item.state;
        this.isSuspended = item.isSuspended;
        this.firmType = item.firmType;
        this.attributes = item.attributes;
        this.aliases = item.aliases;
        this.applyCommissionsAsSpread = !!item.applyCommissionsAsSpread;
        this.skipCommissionBalanceAdjustment = !!item.skipCommissionBalanceAdjustment;
      }
      return;
    }

    this.id = null;
    this.name = null;
    this.displayName = null;
    this.state = null;
    this.isSuspended = null;
    this.firmType = null;
    this.attributes = null;
    this.deleted = null;
  }

  aliases = []
}
