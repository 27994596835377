import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InstrumentFormItem from "../../entities/dto/InstrumentFormItem";
import InstrumentForm from "./InstrumentForm";
import InstrumentFormMultiState from "./InstrumentFormMultiState";
import InstrumentMarketStats from "./InstrumentMarketStats";

const InstrumentDetails = ({
  stateWarningMessage,
  onSubmitForm,
  onSubmitMultiStateForm,
  onSubmitTradeDayRollForm,
  onProductListChange,
  selection,
  instrument,
  mode,
  metadata,
  holidays,
  onSetInstrumentStats,
  onPerformResolution,
  onUndoResolution,
}) => {
  var form = null;
  switch (mode) {
    case formModes.view:
    case formModes.edition:
    case formModes.creation:
      const isEditing = mode === formModes.edition;
      form = (
        <InstrumentForm
          isEditing={isEditing}
          onSubmitForm={onSubmitForm}
          onProductListChange={onProductListChange}
          metadata={metadata}
          holidays={holidays}
          mode = {mode}
        />
      );
      break;
    case formModes.changeState:
      form = (
        <InstrumentFormMultiState
          stateWarningMessage={stateWarningMessage}
          title={"Edit Instrument State"}
          selection={instrument}
          onSubmitForm={onSubmitMultiStateForm}
          onTradeDayRollForm={onSubmitTradeDayRollForm}
          onPerformResolution={onPerformResolution}
          onUndoResolution={onUndoResolution}
        />
      );
      break;
    case formModes.multiState:
      form = (
        <InstrumentFormMultiState
          stateWarningMessage={stateWarningMessage}
          title={"Edit Multiple Instrument State"}
          selection={selection}
          onSubmitForm={onSubmitMultiStateForm}
          onTradeDayRollForm={onSubmitTradeDayRollForm}
          onPerformResolution={onPerformResolution}
          onUndoResolution={onUndoResolution}
        />
      );
      break;
    case formModes.marketStats:
       form = (
        <InstrumentMarketStats onSetInstrumentStats={onSetInstrumentStats}></InstrumentMarketStats>
       )
      break;
    default:
      break;
  }
  return form;
};

const formModes = {
  view: "view",
  edition: "edition",
  creation: "creation",
  changeState: "changeState",
  multiState: "multiState",
  marketStats: "marketStats"
};

InstrumentDetails.defaultProps = {
  item: new InstrumentFormItem(),
};

InstrumentDetails.formModes = formModes;

InstrumentDetails.propTypes = {
  item: PropTypes.instanceOf(InstrumentFormItem),
  mode: PropTypes.oneOf(Object.keys(formModes)).isRequired,
  stateWarningMessage: PropTypes.string,
  onSubmitForm: PropTypes.func,
  onSubmitStateForm: PropTypes.func,
  onProductListChange: PropTypes.func,
  onStateListChange: PropTypes.func,
  onFollowTradingHoursChange: PropTypes.func,
};

export default connect((state) => ({}), null)(InstrumentDetails);
