import State from "./dto/State";
import { AdminRequestStatus, AdminRequestReturnStatus } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_pb";
import { capitalizeWord, getEnumName } from "../modules/util";
import ProtobufParser from "../modules/protobufParser";
import { convertDateToString } from '../modules/util';

const {
    ListAdminRequestsRequest,
  } = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/admin/v1beta1/admin_api_pb.js');

export default class AdminRequest {
    constructor(item) {
        if (item) {
            this.id = item.getId();
            this.name = item.getRequestName();
            this.user = item.getRequestUser();
            this.method = item.getMethod();
            this.approvalCount = item.getApprovalCount();
            this.requiredApprovals = item.getRequiredApprovals();
            this.approvalUsers = item.getApprovalUsersList();
            var requestStatus = new State();
            requestStatus.id = item.getStatus();
            requestStatus.name = capitalizeWord(getEnumName(AdminRequestStatus, requestStatus.id));
            this.status = requestStatus;

            var requestReturnStatus = new State();
            requestReturnStatus.id = item.getCallReturnStatus();
            requestReturnStatus.name = capitalizeWord(getEnumName(AdminRequestReturnStatus, requestReturnStatus.id));
            this.returnStatus = requestReturnStatus;

            this.message = item.getMessageJson();
            this.returnMessage = item.getCallReturnJson();

            const creationTime = item.getCreationTime();
            this.creationTime = creationTime ? convertDateToString(creationTime.toDate(), "YYYYMMDD-HH:mm:ss.SSS") : "";
            return;
        }

        this.id = null;
        this.name = null;
        this.user = null;
        this.method = null;
        this.approvalCount = null;
        this.requiredApprovals = null;
        this.approvalUsers = null;
        this.status = null;
        this.returnStatus = null;
        this.message = null;
        this.returnMessage = null;
    }
}

export class ListAdminRequest {
    constructor() {
        this.requestName = null;
        this.requestUser = null;
        this.startTime = null;
        this.endTime = null;
        this.statusesList = null;
        this.newestFirst = true;
    }
  
    toProto = () => {
      const request = new ListAdminRequestsRequest();
  
      if (!!this.pageSize) request.setPageSize(this.pageSize);
      if (!!this.pageToken) request.setPageToken(this.pageToken);
      if (!!this.statusesList) request.setStatusesList(this.statusesList);
  
      if (!!this.startTime) {
        const startTime = ProtobufParser.toTimestamp(this.startTime);
        request.setStartTime(startTime);
      }
  
      if (!!this.endTime) {
        const endTime = ProtobufParser.toTimestamp(this.endTime);
        request.setEndTime(endTime);
      }

      if (!!this.requestName) request.setRequestName(this.requestName);
      if (!!this.requestUser) request.setRequestUser(this.requestUser)

      if (!!this.newestFirst) request.setNewestFirst(!!this.newestFirst);
  
      return request;
    }
  }