import {protoDateToDate} from "../actions/ptypes";
import ProtobufParser from "../modules/protobufParser";

export default class Holiday {
  constructor(cal, item) {
    if (cal && item) {
      this.calendar = cal.getId();
      this.global = cal.getGlobal();
      this.description = cal.getDescription();
      this.title = item.getDescription();
      this.start = protoDateToDate(item.getDate(), item.getTimeOfDay());
      const millis = ProtobufParser.fromDurationToMillis(item.getDuration());
      this.end = new Date(this.start.getTime() + millis);
      return;
    }
    this.calendar = null;
    this.global = null;
    this.description = null;
    this.title = null;
    this.start = null;
    this.end = null;
  }
}