import React from 'react';
import PropTypes from 'prop-types';
import './Loader.css';

const spinnerSizeClass = {
  small: 'small',
  medium: 'medium',
  big: 'big'
}

const Loader = ({ show, spinnerSize }) => {
  if (!show) return null;
  const spinnerClass = `loader ${spinnerSize}`;
  return (
    <div className="loader-overlay">
      <div className={spinnerClass}><div></div><div></div><div></div><div></div></div>
    </div>
  )
};

Loader.defaultProps = {
  show: false,
  spinnerSize: spinnerSizeClass.small
}

Loader.spinnerSize = spinnerSizeClass;

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
  spinnerSize: PropTypes.oneOf(Object.keys(spinnerSizeClass)).isRequired
};

export default Loader;