import "@patternfly/react-core/dist/styles/base-no-reset.css";
import React from 'react';
import { LogViewer, LogViewerSearch } from '@patternfly/react-log-viewer';
import {
    Badge,
    Button,
    Select,
    SelectOption,
    Tooltip,
    Toolbar,
    ToolbarContent,
    ToolbarGroup,
    ToolbarItem,
    ToolbarToggleGroup, Checkbox
} from '@patternfly/react-core';
import OutlinedPlayCircleIcon from '@patternfly/react-icons/dist/esm/icons/outlined-play-circle-icon';
import PauseIcon from '@patternfly/react-icons/dist/esm/icons/pause-icon';
import PlayIcon from '@patternfly/react-icons/dist/esm/icons/play-icon';
import ReplyIcon from '@patternfly/react-icons/dist/esm/icons/reply-icon';
import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';
import DownloadIcon from '@patternfly/react-icons/dist/esm/icons/download-icon';
import TooltipIcon from '../core/tooltip-html/tooltip-html'; 

const ComplexToolbarLogViewer = ({jobs, pods, logs, logLive, selectedDataSource, setSelectedDataSource, fromPreviousTermination, setFromPreviousTermination}) => {
    let dataSources = {};
    if (Array.isArray(jobs)) {
        jobs.forEach(job => {
            dataSources[job.name] = { type: 'Job', id: job.name }
        })
    }
    if (Array.isArray(pods)) {
        pods.forEach(pod => {
            dataSources[pod.name] = { type: 'Pod', id: pod.name }
        })
    }
    const [isPaused, setIsPaused] = React.useState(false);
    const [currentItemCount, setCurrentItemCount] = React.useState(0);
    const [renderData, setRenderData] = React.useState('');
    const [selectDataSourceOpen, setSelectDataSourceOpen] = React.useState(false);
    const logViewerRef = React.useRef();

    React.useEffect(() => {
        if ((!isPaused || !logLive) && logs.length > 0) {
            setCurrentItemCount(logs.length);
            setRenderData(logs.slice(-1024*1024));
            if (logViewerRef && logViewerRef.current) {
                logViewerRef.current.scrollToBottom();
            }
        }
    }, [isPaused, logLive, logs]);

    const onDownloadClick = () => {
        const element = document.createElement('a');
        const file = new Blob([logs], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${selectedDataSource}.txt`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const onScroll = ({ scrollOffsetToBottom, _scrollDirection, scrollUpdateWasRequested }) => {
        if (!scrollUpdateWasRequested) {
            if (scrollOffsetToBottom > 0) {
                setIsPaused(true);
            } else {
                setIsPaused(false);
            }
        }
    };

    const selectDataSourceMenu = Object.entries(dataSources).map(([value, { type }]) => (
        <SelectOption
            key={value}
            value={value}
            isSelected={selectedDataSource === value}
            isChecked={selectedDataSource === value}
        >
            <Badge key={value}>{type}</Badge>
            {` ${value}`}
        </SelectOption>
    ));

    const selectDataSourcePlaceholder = (
        <React.Fragment>
            <Badge>{dataSources && dataSources[selectedDataSource] ? dataSources[selectedDataSource].type : ""}</Badge>
            {` ${selectedDataSource}`}
        </React.Fragment>
    );

    const ControlButton = () => {
        if(!selectedDataSource) return null;
        if (!logLive) {
            return (<Button
                variant={'link'}
                onClick={() => {
                    setSelectedDataSource(selectedDataSource);
                }}
            >
                <ReplyIcon/>
                {` Restart Log Stream`}
            </Button>)
        }
        return (<Button
            variant={isPaused ? 'plain' : 'link'}
            onClick={() => {
                setIsPaused(!isPaused);
            }}
        >
            {isPaused ? <PlayIcon/> : <PauseIcon/>}
            {isPaused ? ` Resume Log` : ` Pause Log`}
        </Button>)
    };

    const leftAlignedToolbarGroup = (
        <React.Fragment>
            <ToolbarToggleGroup toggleIcon={<EllipsisVIcon />} breakpoint="md">
                <ToolbarItem variant="search-filter">
                    <Select
                        onToggle={isOpen => setSelectDataSourceOpen(isOpen)}
                        onSelect={(event, selection) => {
                            setSelectDataSourceOpen(false);
                            setSelectedDataSource(selection, false);
                            setCurrentItemCount(0);
                        }}
                        selections={selectedDataSource}
                        isOpen={selectDataSourceOpen}
                        placeholderText={selectDataSourcePlaceholder}
                    >
                        {selectDataSourceMenu}
                    </Select>
                </ToolbarItem>
                <ToolbarItem variant="search-filter">
                    <LogViewerSearch onFocus={_e => setIsPaused(true)} placeholder="Search"  minSearchChars={1}/>
                </ToolbarItem>
                <ToolbarItem>
                    <Checkbox
                        label="Log from Previous Failure"
                        aria-label="previous termination checkbox"
                        isChecked={fromPreviousTermination}
                        id="previous-termination-checkbox"
                        onChange={setFromPreviousTermination}
                    />
                    <TooltipIcon
                        text = {<span>If checked, the log from the most recent failure of the selected log source will be shown. Useful for <br/> retrieving stack traces and other pertinent information in the event of a restart or other such failure.</span>}
                        id="IconTooltip"
                    ></TooltipIcon>
                </ToolbarItem>
            </ToolbarToggleGroup>
            <ToolbarItem>
                <ControlButton />
            </ToolbarItem>
        </React.Fragment>
    );

    const rightAlignedToolbarGroup = (
        <React.Fragment>
            <ToolbarGroup variant="icon-button-group">
                <ToolbarItem>
                    <Tooltip position="top" content={<div>Download</div>}>
                        <Button onClick={onDownloadClick} variant="plain" aria-label="Download current logs">
                            <DownloadIcon />
                        </Button>
                    </Tooltip>
                </ToolbarItem>
            </ToolbarGroup>
        </React.Fragment>
    );

    const FooterButton = () => {
        const handleClick = _e => {
            setIsPaused(false);
        };
        return (
            <Button onClick={handleClick} isBlock>
                <OutlinedPlayCircleIcon />
                {` Resume`}
            </Button>
        );
    };
    return (
        <LogViewer
            data={renderData}
            id="complex-toolbar-log-viewer"
            scrollToRow={currentItemCount}
            innerRef={logViewerRef}
            height={600}
            isTextWrapped={false}
            toolbar={
                <Toolbar>
                    <ToolbarContent>
                        <ToolbarGroup alignment={{ default: 'alignLeft' }}>{leftAlignedToolbarGroup}</ToolbarGroup>
                        <ToolbarGroup alignment={{ default: 'alignRight' }}>{rightAlignedToolbarGroup}</ToolbarGroup>
                    </ToolbarContent>
                </Toolbar>
            }
            overScanCount={10}
            footer={logLive && isPaused && <FooterButton />}
            onScroll={onScroll}
        />
    );
};

export default ComplexToolbarLogViewer;
