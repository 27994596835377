import {
  FETCH_USERS_API_SUCCEED,
  FETCH_USERS_API_FAILED,
  FETCH_USERS_API_START,
  FETCH_USER_API_START,
  FETCH_USER_API_SUCCEED,
  FETCH_USER_API_FAILED,
  CLEAN_USER_API,
  SAVE_USER_API_START,
  SAVE_USER_API_FAILED,
  SAVE_USER_API_SUCCEED,
  CREATE_USER_API_STATE,
  UPDATE_USER_API_ITEM
} from '../constants/userTypes';
import {
  RESET,
} from '../constants/appTypes';
import User from '../entities/User';

const initialState = {
  fetchingUsersAPI: false,
  usersAPI: [],
  fetchingUserAPI: false,
  userAPI: new User(),
  savingUserAPI: false,
  isCreatingUserAPI: false
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_API_START:
      return {
        ...state,
        fetchingUsersAPI: true
      };
    case FETCH_USERS_API_SUCCEED:
      return {
        ...state,
        fetchingUsersAPI: false,
        users: action.payload
      };
    case FETCH_USERS_API_FAILED:
      return {
        ...state,
        fetchingUsersAPI: false,
        users: []
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    case FETCH_USER_API_START:
      return {
        ...state,
        fetchingUserAPI: true
      };
    case FETCH_USER_API_SUCCEED:
      return {
        ...state,
        fetchingUserAPI: false,
        user: action.payload
      };
    case FETCH_USER_API_FAILED:
      return {
        ...state,
        fetchingUserAPI: false
      };
    case CLEAN_USER_API:
      return {
        ...state,
        user: new User()
      };
    case SAVE_USER_API_START:
      return {
        ...state,
        savingParticipant: true
      };
    case SAVE_USER_API_SUCCEED:
      return {
        ...state,
        savingParticipant: false
      };
    case SAVE_USER_API_FAILED:
      return {
        ...state,
        savingParticipant: false
      };
    case CREATE_USER_API_STATE:
      return {
        ...state,
        isCreating: action.value
      };
    case UPDATE_USER_API_ITEM:
      return {
        ...state,
        user: new User({
          ...state.user,
          [action.key]: action.value
        })
      };
    default:
      return state;
  }
}

export default users;
