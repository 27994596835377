import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import FieldGroup from "../../../core/form/FieldGroup";
import { isNullOrUndefined } from "../../../../modules/util";
import { FormModes } from "../../../../constants/enumerations"
import { useEffect } from "react";
import { connect } from "react-redux";
import { fetchInstrumentIdsList } from "../../../../actions/instruments";
import AutoComplete from "../../../core/auto-complete/AutoComplete";
import { FormGroup, FormLabel } from "react-bootstrap";

const BasicMultilegInfo = ({
    values,
    instrumentType,
    setFieldValue,
    touched,
    handleBlur,
    errors,
    mode,
    instrumentsIds,
    fetchInstrumentIdsList
}) => {
    const [open, setOpen] = useState(false);
    const isViewing = mode === FormModes.view;

    useEffect(() => {
        fetchInstrumentIdsList();
    }, [fetchInstrumentIdsList]);

    return (
        <React.Fragment>
            <div onClick={() => setOpen(!open)}>
                <Row className="accordian-style">
                    <Col lg={11} xs={11} md={11}>
                        <h6><strong>Multileg Information</strong></h6>
                    </Col>
                    <Col>
                        <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
                    </Col>
                </Row>
            </div>
            <Collapse in={open}>
                <div className="collapse-content">
                    <Row>
                        <DropdownListGroup id="noLegs" name="noLegs" label="# of Legs"
                            value={Array.isArray(values.legsList) ? values.legsList.length : 0}
                            enabled={values.isEditable}
                            errors={errors}
                            onChange={(e) => {
                                let newList = [];
                                for (let i = 0; i < e; i++) {
                                    newList.push({ symbol: "", ratio: 1, opposingSide: i % 2 !== 0 })
                                }
                                setFieldValue("legsList", newList);
                            }}
                            data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                            plaintext={isViewing}
                        />
                    </Row>
                    {Array.isArray(values.legsList) ? values.legsList.map((leg, idx) => (
                        <Row key={`symbol${idx}`}>
                            <Col lg={3} xs={3} md={3}>
                                <FormGroup id={`symbol${idx}`}>
                                    <FormLabel>{`Leg ${idx}`}</FormLabel>
                                    <AutoComplete id={`symbol${idx}`}
                                        placeholder="Instrument ID"
                                        dataSource={instrumentsIds}
                                        defaultValue={values.legsList[idx].symbol}
                                        value={values.legsList[idx].symbol}
                                        onChange={(e) => {
                                            setFieldValue(`legsList[${idx}].symbol`, e.target.value);
                                        }}
                                        onEnter={(value) => {
                                            setFieldValue(`legsList[${idx}].symbol`, value);
                                        }}
                                        onSelect={(value) => {
                                            setFieldValue(`legsList[${idx}].symbol`, value);
                                        }}
                                        errors={errors}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={3} xs={3} md={3}>
                                <FieldGroup id={`ratio${idx}`} name={`ratio${idx}`} type="textarea" label="Ratio"
                                    value={leg.ratio}
                                    disabled={!values.isEditable}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                    onChange={(e) => {
                                        setFieldValue(`legsList[${idx}].ratio`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    min={1}
                                    max={10}
                                    step={1}
                                    plaintext={isViewing}
                                />
                            </Col>
                            <Col lg={3} xs={3} md={3}>
                                <DropdownListGroup id={`side${idx}`} name={`side${idx}`} label="Side"
                                    value={leg.opposingSide}
                                    enabled={values.isEditable}
                                    errors={errors}
                                    onChange={(e) => {
                                        setFieldValue(`legsList[${idx}].opposingSide`, e.id);
                                    }}
                                    data={[{ id: false, name: 'As Defined' }, { id: true, name: 'Opposing' }]}
                                    textField="name"
                                    valueField="id"
                                    plaintext={isViewing}
                                />
                            </Col>
                        </Row>
                    )) : null}
                </div>
            </Collapse>

        </React.Fragment>
    )
}

export const MultilegInfo = connect(
    (state) => ({
        instrumentsIds: state.instruments.instrumentIdsList,
    }),
    (dispatch) => ({
        fetchInstrumentIdsList: () => {
            dispatch(fetchInstrumentIdsList())
        },
    }))(BasicMultilegInfo)

export const ValidateMultilegInfo = (values, errors) => {
    if (Array.isArray(values.legsList)) {
        for (let idx = 0; idx < values.legsList.length; idx++) {
            const leg = values.legsList[idx];
            if (isNullOrUndefined(leg.ratio) || isNaN(leg.ratio) || leg.ratio === '') {
                errors[`ratio${idx}`] = "Ratio must be a number";
            }
            if (isNullOrUndefined(leg.symbol) || leg.symbol.length === 0) {
                errors[`symbol${idx}`] = "Symbol must be provided";
            }
        }
    }
    return errors;
}