import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, } from "react-bootstrap";
import ConfirmDialog from "../core/confirm-dialog/ConfirmDialog";
import { Container } from "react-bootstrap";
import AccountBankDetails from "./AccountBankDetails"
import { FormModes } from "../../constants/enumerations";
import { isNullOrUndefined } from "../../modules/util";
const { BankDetailsType } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb.js");

export class CreateBankDetailsForm extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);
        this.state = { bankDetails: { fields: {} } }
    };

    validBankAccountType = () => {
        return !!this.state.bankDetails &&
            !!this.state.bankDetails.fields &&
            !isNullOrUndefined(this.state.bankDetails.fields.bankAccountType) && 
            this.state.bankDetails.fields.bankAccountType !== BankDetailsType.BANK_DETAILS_TYPE_UNDEFINED
    }

    render() {
        const { show, mode, onComplete, curBankDetails, createBankDetails, updateBankDetails } = this.props;

        if (!!curBankDetails && curBankDetails !== this.state.bankDetails.fields) {
            this.setState({ bankDetails: { fields: curBankDetails} })
        }
        
        let setBankDetail = (field) => {
           let targetField = field.target;
           this.setState((prevState) => {
               let newState = {};
               newState = Object.assign(newState, prevState);
               newState.bankDetails.fields[targetField.id] = targetField.value
               return newState
           })
        }

        let setBankDetailFieldValue = (fieldName, value) => {
            this.setState((prevState) => {
                let newState = {};
                newState = Object.assign(newState, prevState);
                newState.bankDetails.fields[fieldName] = value
                return newState
            })
        }

        return (
            <ConfirmDialog
                show={!!show}

                proceed={() => {
                    if (mode === FormModes.creation) {
                        createBankDetails(this.state.bankDetails.fields)
                    } else if (mode === FormModes.edition) {
                        updateBankDetails(this.state.bankDetails.fields)
                    }

                    this.setState({ bankDetails: { fields: {} } });
                    onComplete();
                }}

                cancel={() => {
                    this.setState({ bankDetails: { fields: {} } });
                    onComplete();
                }}

                confirmation={
                    <Container>
                        <Row>
                            <Col>
                                <AccountBankDetails
                                    values={(this.state.bankDetails.fields) || {}}
                                    onChange={setBankDetail}
                                    setFieldValue={setBankDetailFieldValue}
                                    mode={mode}
                                    parent="CreateBankDetailsForm"
                                />
                            </Col>
                        </Row>
                    </Container>
                }

                options={{
                    title: mode === FormModes.creation ? `Create Bank Account` : `Bank Account`,
                    okButtonText: ((mode === FormModes.creation || mode === FormModes.edition) && this.validBankAccountType()) ? "Submit" : null,
                    cancelButtonText: (mode === FormModes.creation || mode === FormModes.edition) ? "Cancel" : null,
                }}
            />
        );
    }
}

CreateBankDetailsForm.propTypes = {
    show: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
}

export default CreateBankDetailsForm;