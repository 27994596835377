export default class TimeOfDay {
  constructor(item) {
    if (item) {
      this.hours = item.getHours();
      this.minutes = item.getMinutes();
      this.seconds = item.getSeconds();
      return;
    }
    this.hours = null;
    this.minutes = null;
    this.seconds = null;
  }
}