import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import FieldGroup from "../core/form/FieldGroup";
import PhoneNumber from "../core/form/PhoneNumber";
import DateGroup from "../core/form/DateGroup";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import CheckboxGroup from '../core/form/CheckboxGroup';
import * as toolTips from '../../constants/userToolTip';

const { BeneficialOwnershipAttribute } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/firms/v1beta1/firms_pb");

const UserAttributesForm = ({ isEditable, isNewService, errors, touched, values, setFieldValue, handleChange, handleBlur, parent }) => {
    useEffect(() => {
        if (!!values.beneficialOwnershipAttribute && !!values.beneficialOwnershipAttribute.find(att => att === BeneficialOwnershipAttribute.BENEFICIAL_OWNERSHIP_ATTRIBUTE_OWNER)) {
            setFieldValue("cftcForm102Owner", true)
        }

        if (!!values.beneficialOwnershipAttribute && !!values.beneficialOwnershipAttribute.find(att => att === BeneficialOwnershipAttribute.BENEFICIAL_OWNERSHIP_ATTRIBUTE_CONTROLLER)) {
            setFieldValue("cftcForm102Controller", true)
        }
    }, [values.beneficialOwnershipAttribute])

    return (
        <div>
            <Row>
                <Col lg={6} xs={6} md={6}>
                    {!!isEditable ? (<FieldGroup
                        id="legalName"
                        name="legalName"
                        type="text"
                        label={isNewService ? "Contact Name" : "Legal Name"}
                        placeholder=""
                        isRequired={false}
                        errors={errors}
                        touched={touched}
                        value={values.legalName}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="legalName"
                        label={isNewService ? "Contact Name" : "Legal Name"}
                        value={values.legalName}
                    />)}
                </Col>
            </Row>
            <Row>
                <Col lg={6} xs={6} md={6}>
                    {!!isEditable ? (<FieldGroup
                        id="title"
                        name="title"
                        type="text"
                        label={isNewService ? "Contact Title" : "Title"}
                        placeholder=""
                        isRequired={false}
                        errors={errors}
                        touched={touched}
                        value={values.title}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="title"
                        label={isNewService ? "Contact Title" : "Title"}
                        value={values.title}
                    />)}
                </Col>
            </Row>
            <Row>
                <Col lg={6} xs={6} md={12}>
                    {!!isEditable ? (<FieldGroup
                        id="email"
                        name="email"
                        type="email"
                        label={isNewService ? "Contact Email" : "Email"}
                        placeholder=""
                        isRequired={false}
                        errors={errors}
                        touched={touched}
                        value={values.email}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />) : (
                    <FieldGroupReadOnly
                        id="email"
                        label={isNewService ? "Contact Email" : "Email"}
                        value={values.email}
                    />)}
                </Col>
            </Row>
            <Row>
                <Col lg={6} xs={6} md={12}>
                    {!!isEditable ? (<PhoneNumber
                        id="phoneNumber"
                        name="phoneNumber"
                        label={isNewService ? "Contact Phone Number" : "Phone Number"}
                        value={values.phoneNumber}
                        onChange={(e) => setFieldValue("phoneNumber", e)}
                    />) : (
                    <FieldGroupReadOnly
                        id="phoneNumber"
                        label={isNewService ? "Contact Phone Number" : "Phone Number"}
                        value={values.phoneNumber}
                    />)}
                </Col>
            </Row>
            {!isNewService ? (
                <>
                    <Row>
                        <Col lg={6} xs={6} md={12}>
                            {!!isEditable ? (<DateGroup
                                id="dateOfBirth"
                                name="dateOfBirth"
                                label="Date of Birth (dd/mm/yyyy)"
                                value={
                                    values.dateOfBirth ? new Date(values.dateOfBirth) : null
                                }
                                dropUp={true}
                                errors={errors}
                                touched={touched}
                                onChange={(e) => setFieldValue("dateOfBirth", e)}
                                onBlur={handleBlur}
                            />) : (
                                <FieldGroupReadOnly
                                    id="dob"
                                    label="Date of Birth"
                                    value={
                                        values.dateOfBirth
                                            ? values.dateOfBirth
                                                .toISOString()
                                                .split("T")[0]
                                            : null
                                    }
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} xs={6} md={12}>
                            {!!isEditable ? (<FieldGroup
                                id="registrationNumber"
                                name="registrationNumber"
                                type="text"
                                label="Registration Number"
                                tooltip={toolTips.REGISTRATION_NUMBER}
                                placeholder=""
                                isRequired={false}
                                errors={errors}
                                touched={touched}
                                value={values.registrationNumber}
                                setFieldValue={setFieldValue}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />) : (
                            <FieldGroupReadOnly
                                id="registrationNumber"
                                label={"Registration Number"}
                                value={values.registrationNumber}
                            />)}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} xs={6} md={12}>
                            {!!isEditable ? (<FieldGroup
                                id="oauthId"
                                name="oauthId"
                                type="text"
                                label="OAuth ID"
                                tooltip={toolTips.OAUTH_ID}
                                placeholder=""
                                isRequired={false}
                                errors={errors}
                                touched={touched}
                                value={values.oauthId}
                                setFieldValue={setFieldValue}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />) : (
                            <FieldGroupReadOnly
                                id="oauthId"
                                label={"OAuth ID"}
                                value={values.oauthId}
                            />)}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} xs={6} md={12}>
                            {!!isEditable ? (<FieldGroup
                                id="legalEntityIdentifier"
                                name="legalEntityIdentifier"
                                type="textarea"
                                label="Legal Entity Identifier"
                                placeholder=""
                                isRequired={false}
                                errors={errors}
                                touched={touched}
                                value={values.legalEntityIdentifier}
                                setFieldValue={setFieldValue}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />) : (
                            <FieldGroupReadOnly
                                id="legalEntityIdentifier"
                                label={"Legal Entity Identifier"}
                                value={values.legalEntityIdentifier}
                            />)}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} xs={6} md={12}>
                            {!!isEditable ? (<FieldGroup
                                id="address"
                                name="address"
                                type="textarea"
                                label="Address"
                                placeholder=""
                                isRequired={false}
                                errors={errors}
                                touched={touched}
                                value={values.address}
                                setFieldValue={setFieldValue}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />) : (
                            <FieldGroupReadOnly
                                id="address"
                                label={"Address"}
                                value={values.address}
                            />)}
                        </Col>
                    </Row>
                    {(!!parent && parent === "ACCOUNT_FORM") && <>
                        <Row>
                            <Col lg={6} xs={6} md={12}>
                                <CheckboxGroup
                                    checked={values.cftcForm102Owner}
                                    setFieldValue={setFieldValue}
                                    onClick={(e) => { setFieldValue("cftcForm102Owner", e.target.checked) }}
                                    label="CFTC Form 102 Owner"
                                    disabled={!isEditable}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} xs={6} md={12}>
                                <CheckboxGroup
                                    checked={values.cftcForm102Controller}
                                    onClick={(e) => { setFieldValue("cftcForm102Controller", e.target.checked) }}
                                    label="CFTC Form 102 Controller"
                                    disabled={!isEditable}
                                />
                            </Col>
                        </Row></>} {" "}
                </>
            ) : null}
        </div>
    );
};

UserAttributesForm.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    isNewService: PropTypes.bool,
    isEditable: PropTypes.bool.isRequired,
};

export default UserAttributesForm;
