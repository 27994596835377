import {
  LIST_ALERTS_FAILED,
  LIST_ALERTS_START,
  LIST_ALERTS_SUCCESS,
  HAS_ALERTS_FAILED,
  HAS_ALERTS_SUCCESS,
} from "../constants/AlertTypes"

const initialState = {
  alerts: [],
  hasAlert: false,
  raisingAlert: false,
  updatingAlerts: false,
  listingAlerts: false,
  pageTokens: {},
  nextPageToken: "",
  pageToken: ""
}

const alerts = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ALERTS_SUCCESS:
      const pageTokens = { ...state.pageTokens };
      pageTokens[action.payload.pageToken] = action.payload.nextPageToken;

      return {
        ...state,
        alerts: [...action.payload.alerts],
        listingAlerts: false,
        nextPageToken: action.payload.nextPageToken,
        pageTokens: pageTokens,
        pageToken: action.payload.pageToken,
      }
    case LIST_ALERTS_START:
      return {
        ...state,
        listingAlerts: true
      }
    case LIST_ALERTS_FAILED:
      return {
        ...state,
        listingAlerts: false
      }
    case HAS_ALERTS_SUCCESS:
      return {
        ...state,
        hasAlert: action.payload
      }
    case HAS_ALERTS_FAILED:
      return {
        ...state,
        hasAlert: false
      }
    default:
      return state;
  }
}

export default alerts;