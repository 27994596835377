import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import querystring from 'query-string';
import _ from "lodash"
import moment from "moment";

import "./Content.css";
import SearchNoItemsResult from "../components/core/search-no-items/SearchNoItemsResult";
import QuotesDataGrid from "../components/quotes/QuotesDataGrid";
import Pagination, {
  withPageToken,
} from "../components/core/data-grid/Pagination";

import { CleanAccount, LoadAccounts } from "../actions/accounts";
import { searchQuotes, cleanSearch } from "../actions/quotes";

import { LoadFirms } from "../actions/firms";
import { LoadParticipants } from "../actions/participants";

import QuotesFilter from "../components/core/filter/QuotesFilter";
import QuoteMessageType from "../entities/dto/QuoteMessageType";

function mapStateToProps(state) {
  return {
    noItemsFound: state.quotes.noItemsFound,
    fetchingQuotes: state.quotes.fetchingQuotes,
    quotes: state.quotes.quotes,
    tokens: state.quotes.tokens,
    pageToken: state.quotes.pageToken,
    accounts: state.accounts.accounts,
    participants: state.participants.participants,
    firms: state.firms.firms,
  };
}

const mapDispatchToProps = (dispatch) => ({
  searchQuotes: (filters, pageToken) => {
    dispatch(searchQuotes(filters, pageToken));
  },
  cleanSearch: () => {
    dispatch(cleanSearch());
  },

  cleanAccount: () => {
    dispatch(CleanAccount());
  },
  loadFirms: () => {
    dispatch(LoadFirms());
  },
  loadAccounts: () => {
    dispatch(LoadAccounts());
  },
  loadParticipants: () => {
    dispatch(LoadParticipants());
  },
});

class QuotesSearchPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  static emptyFilterObj = {
    fromDate: null,
    toDate: null,
    instrumentId: null,
    participant: null,
    account: null,
    clientId: null,
    tokens: {},
    firm: null,
    accounts: [],
    quid: null,
    messageType: QuoteMessageType.Quote,
  };

  constructor() {
    super();
    const queryParams = querystring.parseUrl(window.location.href);
    const hasParams = !_.isEmpty(queryParams.query);
    if (hasParams) {
      const searchState = JSON.parse(queryParams.query.filter);
      this.state = { ...QuotesSearchPage.emptyFilterObj, ...searchState };
    } else {
      const fromDt = moment().startOf("day").toDate();
      const toDt = moment().endOf("day").toDate();

      const searchParams = {
        fromDate: {
          id: "fromDate",
          value: fromDt,
          text: "From Date",
          name: fromDt.toString(),
        },
        toDate: {
          id: "toDate",
          value: toDt,
          text: "To Date",
          name: toDt.toString(),
        },
      };
      let initialState = { ...QuotesSearchPage.emptyFilterObj, ...searchParams };
      this.state = initialState;
    }

    this.timer = null;
    this.onPageChange = this.onPageChange.bind(this);
  }

  static getDefaultState = () => {
    const fromDt = new Date().setHours(0, 0, 0, 0);
    const toDt = new Date().setHours(23, 59, 59, 999);

    return {
      ...QuotesSearchPage.emptyFilterObj,
      fromDate: {
        id: "fromDate",
        value: fromDt,
        text: "From Date",
        name: fromDt.toString(),
      },
      toDate: {
        id: "toDate",
        value: toDt,
        text: "To Date",
        name: toDt.toString(),
      },
    }
  }

  prepareReqObj = (filterItems) => {
    let values = {};
    Object.keys(filterItems).forEach((key) => {
      if (filterItems[key]) {
        values[key] = filterItems[key].value;
      }
    });

    values.messageType = QuoteMessageType.Quote;
    return values;
  }

  componentDidMount() {
    this.props.cleanSearch();
    this.props.cleanAccount();
    this.props.loadAccounts();
    this.props.loadFirms();
    this.props.loadParticipants();

    if (!this.props.fetchingQuotes) {
      let reqObj = this.prepareReqObj(this.state)
      this.props.searchQuotes(reqObj);
    }

    window.scrollTo(0, 0);
  }

  search = (values) => {
    let stateObject = { ...QuotesSearchPage.emptyFilterObj, ...values };
    let filterData = { filter: JSON.stringify(stateObject) };
    const parsedString = querystring.stringify(filterData);
    const routeUrl = this.context.router.route.location.pathname;
    this.context.router.history.push(`${routeUrl}?${parsedString}`);
  };

  onPageChange = (pageToken) => {
    let reqObj = this.prepareReqObj(this.state)
    this.props.searchQuotes(reqObj, pageToken);
  };

  getPaginationComponent = () => {
    const pageToken = this.props.pageToken;
    const tokens = this.props.tokens;
    return withPageToken(Pagination, { pageToken, tokens });
  };

  parseDisplayNames = () => {
    let { quotes, accounts, participants, firms } = this.props;
    if (quotes && quotes.length > 0) {
      quotes.forEach((quote) => {
        const matchedAccount = accounts.find(
          (account) => account.name === quote.account
        );
        if (matchedAccount) quote.account = matchedAccount.displayName;

        const matchedParticipant = participants.find(
          (participant) => participant.name === quote.user
        );
        if (matchedParticipant) quote.user = matchedParticipant.displayName;

        const matchedFirm = firms.find((firm) => firm.name === quote.firm);
        if (matchedFirm) quote.firm = matchedFirm.displayName;

        const matchedAgent = participants.find(
          (participant) => participant.name === quote.submittingUser
        );
        if (matchedAgent) {
          quote.submittingUser = matchedAgent.displayName;
          quote.submittingFirm = matchedAgent.firm;
          const matchedAgentFirm = firms.find((firm) => firm.name === quote.submittingFirm);
          if (matchedAgentFirm) quote.submittingFirm = matchedAgentFirm.displayName;
        }
      });
    }
  };

  applyFilter = (filterItems) => {
    this.search(filterItems);
  };

  render() {
    let {
      fetchingRequestForQuotes,
      noItemsFound,
      quotes,
      pageToken,
      firms,
      accounts,
      participants,
    } = this.props;

    this.parseDisplayNames();

    return (
      <div className="with-callback">
        <div className="page-title">Search Quotes</div>

        <QuotesFilter
          onApply={this.applyFilter}
          loading={fetchingRequestForQuotes}
          filters={this.state}
          onSubmitForm={this.search}
          firms={firms}
          allAccounts={accounts}
          allParticipants={participants}
        ></QuotesFilter>

        {noItemsFound && pageToken === undefined ? (
          <SearchNoItemsResult message="Sorry, but we didn't find any matching Request for Quotes." />
        ) : (
          <QuotesDataGrid
            data={quotes}
            linkBack={true}
            paginationComponent={this.getPaginationComponent()}
            onPageChange={this.onPageChange}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesSearchPage);
