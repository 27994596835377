export const FETCH_USERS_API_START = 'FETCH_USERS_API_START';
export const FETCH_USERS_API_SUCCEED = 'FETCH_USERS_API_SUCCEED';
export const FETCH_USERS_API_FAILED = 'FETCH_USERS_API_FAILED';
export const FETCH_USER_API_START = 'FETCH_USER_API_START';
export const FETCH_USER_API_SUCCEED = 'FETCH_USER_API_SUCCEED';
export const FETCH_USER_API_FAILED = 'FETCH_USER_API_FAILED';
export const CLEAN_USER_API = 'CLEAN_USER_API';
export const SAVE_USER_API_START = 'SAVE_USER_API_START';
export const SAVE_USER_API_SUCCEED = 'SAVE_USER_API_SUCCEED';
export const SAVE_USER_API_FAILED = 'SAVE_USER_API_FAILED';
export const CREATE_USER_API_STATE = 'CREATE_USER_API_STATE';
export const UPDATE_USER_API_ITEM = 'UPDATE_USER_API_ITEM';