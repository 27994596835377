import React, { Component } from "react";
import { connect } from "react-redux";
import confirm from "../../modules/confirmDialog";
import ParticipantService from "../../services/ParticipantService";
import PropTypes from "prop-types";
import Notification from "../../modules/notifications";
import DataGrid from "../core/data-grid/DataGrid";
import DataGridActions from "../core/data-grid/DataGridActions";
import { isNullOrUndefined } from "../../modules/util"

function mapStateToProps(state) {
  return {
    firm: state.firms.firm,
    firmServices: state.firms.firmServices,
  };
}

export class FirmServicesGrid extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    let { decodedId, loadFirm, loadFirmServices } = this.props;
    loadFirm(decodedId);
    loadFirmServices(decodedId);
  }

  onView = (firmServiceId) => {
    this.context.router.history.push(
      `/firms/${this.props.decodedId}/services/${firmServiceId}`
    );
  };

  onEdit = (firmServiceId) => {
    this.context.router.history.push(
      `/firms/${this.props.decodedId}/services/${firmServiceId}/edit`
    );
  };

  onRemove = (username) => {
    confirm(<div>Are you sure you wish to remove this Firm Service?</div>, {
      title: "Firm Service Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        const cb = (err, response) => {
          if (response) {
            Notification.success("Firm Service removed.");
            this.props.loadFirmServices(this.props.decodedId);
          }

          if (err) {
            Notification.error("Cannot remove Firm Service.");
          }
        };

        ParticipantService.delete(username, cb);
      },
      () => { }
    );
  };

  onKeys = (firmServiceId) => {
    this.context.router.history.push(
      `/firms/${this.props.decodedId}/services/${firmServiceId}/keys`
    );
  };

  render() {
    let { firmServices } = this.props;
    const columns = [
      {
        Header: "NAME",
        accessor: "id",
        maxWidth: 240,
        minWidth: 240,
      },
      {
        Header: "FIRM",
        id: "firm",
        accessor: (d) => !isNullOrUndefined(d.firm) ? d.firm.toString().substring(d.firm.indexOf("/") + 1) : "",
      },
      {
        Header: "DESCRIPTION",
        id: "desc",
        accessor: (d) => !isNullOrUndefined(d.attributes) ? d.attributes.description : "",
      },
      {
        Header: "STATE",
        id: "state",
        accessor: (d) => (d.isSuspended ? "Suspended" : "Active"),
        maxWidth: 120,
        minWidth: 120,
      },
      {
        Header: "KEYS",
        id: "keys",
        maxWidth: 80,
        minWidth: 80,
        Cell: (row) => (
          <DataGridActions
            onKeys={() => {
              this.onKeys(row.original.id);
            }}
          />
        ),
      },
      {
        Header: "ACTIONS",
        maxWidth: 150,
        minWidth: 150,
        sortable: false,
        Cell: (row) => (
          <DataGridActions
            onView={() => {
              this.onView(row.original.id);
            }}
            onEdit={() => {
              this.onEdit(row.original.id);
            }}
            onRemove={() => {
              this.onRemove(row.original.name);
            }}
            viewLink={`/firms/${this.props.decodedId}/services/${row.original.id}`}
            editLink={`/firms/${this.props.decodedId}/services/${row.original.id}/edit`}
          />
        ),
      },
    ];

    return (
      <DataGrid data={firmServices} columns={columns} filterable={false} />
    );
  }
}

export default connect(mapStateToProps, null)(FirmServicesGrid);
