import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import TradingScheduleDialog from "../core/TradingScheduleDialog";
import {
  INTEREST_RATE_SWAP,
  FORWARD_RATE_AGREEMENT_SWAP,
  FUTURE,
  OPTION,
  MULTILEG,
  FORWARD_STARTING_INTEREST_RATE_SWAP,
  BASIS_SWAP,
  EVENT,
  OVER_NIGHT_INDEX_SWAP,
  SINGLE_PERIOD_SWAP,
  NON_DELIVERABLE_FORWARDS,
  FOREX,
  ZERO_COUPON_INFLATION_SWAP
} from "../../constants/strings";
import { isNullOrUndefined, isNumber } from "../../modules/util";
import FieldGroupReadOnly from "../core/form/FieldGroupReadOnly";
import {
  countDecimals,
  parsePrice,
  convertExponentialToDecimal,
} from "../../modules/util";
import ButtonBorderless from "../core/form/ButtonBorderless";
import ButtonMain from "../core/form/ButtonMain";
import DateCalculator, {
  ValidateDateCalculatorIrs,
} from "./components/irs/DateCalculator";
import { SwapInfo, ValidateSwapInfoIrs } from "./components/irs/SwapInfo";
import {
  FixedLegInfo,
  ValidateFixedLegInfoIrs,
} from "./components/irs/FixedLegInfo";
import {
  FloatingLegInfo,
  ValidateFloatingLegIrs,
} from "./components/irs/FloatingLegInfo";
import {
  TradingInfo,
  ValidateTradingInfo,
  TradingInfoSchemaValidation,
} from "./components/TradingInfo";
import {
  AdditionalDetails,
  ValidateAdditionalDetails,
  AdditionalDetailsSchemaValidation,
} from "./components/AdditionalDetails";
import {
  Header,
  HeaderSchemaValidation,
  ValidateHeaders,
} from "./components/Header";
import { SELECTED_DEFAULT } from "./instrumentFormUtils";
import { SwapInfoFra, ValidateSwapInfoFra } from "./components/fra/SwapInfoFra";
import {
  FixedLegInfoFra,
  ValidateFixedLegInfoFra,
} from "./components/fra/FixedLegInfoFra";
import {
  FloatingLegInfoFra,
  ValidateFloatingLegFra,
} from "./components/fra/FloatingLegInfoFra";
import DateCalculatorFra, {
  ValidateDateCalculatorFra,
} from "./components/fra/DateCalculatorFra";
import State from "../../entities/dto/State";
import {
  DerivativeInfo,
  ValidateDerivativeInfo,
} from "./components/derivatives/DerivativeInfo";
import {
  ForexInfo,
  ValidateForexInfo,
} from "./components/forex/ForexInfo";
import {
  MultilegInfo,
  ValidateMultilegInfo,
} from "./components/multileg/MultilegInfo";
import { FormModes } from "../../constants/enumerations";
import DateCalculatorFsIrs, {
  ValidateDateCalculatorFsIrs,
} from "./components/fsirs/DateCalculatorFsIrs";
import {
  SwapInfoFsIrs,
  ValidateSwapInfoFsIrs,
} from "./components/fsirs/SwapInfoFsIrs";
import {
  FixedLegInfoFsIrs,
  ValidateFixedLegInfoFsIrs,
} from "./components/fsirs/FixedLegInfoFsIrs";
import {
  FloatingLegInfoFsIrs,
  ValidateFloatingLegFsIrs,
} from "./components/fsirs/FloatingLegInfoFsIrs";
import {
  SwapInfoBasis,
  ValidateSwapInfoBasis,
} from "./components/basisSwap/SwapInfoBasis";
import DateCalculatorBasis, {
  ValidateDateCalculatorBasis,
} from "./components/basisSwap/DateCalculatorBasis";
import {
  FloatingLegInfoBasis,
  ValidateFloatingLegBasis,
} from "./components/basisSwap/FloatingLegInfoBasis";
import { EventInfo, ValidateEventInfo } from "./components/event/EventInfo";
import { NDFInfo, ValidateNDFInfo } from "./components/ndf/NDFInfo";
import {
  OisInfoBasis,
  ValidateOisInfoBasis,
} from "./components/ois/OisInfoBasis";
import {
  FixedLegInfoOis,
  ValidateFixedLegInfoOis,
} from "./components/ois/FixedLegSwapInfo";
import {
  FloatingLegInfoOis,
  ValidateFloatingLegOis,
} from "./components/ois/FloatingLegInfo";
import DateCalculatorOis, {
  ValidateDateCalculatorOis,
} from "./components/ois/DateCalculatorOis";
import { fetchInstruments2 } from "../../actions/instruments";
import DateCalculatorSps, {
  ValidateDateCalculatorSps,
} from "./components/sps/DateCalculatorSps";
import { SwapInfoSps, ValidateSwapInfoSps } from "./components/sps/SwapInfoSps";
import {
  FixedLegInfoSps,
  ValidateFixedLegInfoSps,
} from "./components/sps/FixedLegSwapInfo";
import {
  FloatingLegInfoSps,
  ValidateFloatingLegSps,
} from "./components/sps/FloatingLegInfo";
import {
  SwapInfoZCIS,
  ValidateSwapInfoZCIS,
} from "./components/zcis/SwapInfoZCIS";
import DateCalculatorZCIS, {
  ValidateDateCalculatorZCIS,
} from "./components/zcis/DateCalculatorZCIS";
import {
  FloatingLegInfoZCIS,
  ValidateFloatingLegZCIS,
} from "./components/zcis/FloatingLegInfoZCIS";
import { FixedLegInfoZCIS, ValidateFixedLegInfoZCIS } from "./components/zcis/FixedLegInfoZCIS";
import { SubTypesSchemaValidation } from "./components/event/SubTypes";
import { SettlementPriceCalculationInfo, SettlementPriceCalculationValidation } from "./components/SettlementPriceCalculationInfo";
import { CrossOrderInfo, ValidateCrossOrderInfo, CrossOrderInfoSchemaValidation } from "./components/CrossOrderInfo";
import ErrorLabel from "../core/form/ErrorLabel";
import ProtobufParser from "../../modules/protobufParser";
import Metadata from "./components/Metadata";
import { TradingSchedule, ValidateTradingSchedule } from "./components/TradingSchedule";

const initialState = {
  selectedSchedule: null,
  selectedScheduleIndex: null,
  showTradingShedule: false,
  selectedProductId: null,
  isNew: false,
  toggleDeleteTooltip: false,
  selectedTenor: SELECTED_DEFAULT,
  selectedBaseCurrency: SELECTED_DEFAULT,
  selectedRollPayment: SELECTED_DEFAULT,
  type: null,
};

class BasicInstrumentForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.mode = props.mode;

    this.state = { ...initialState, instrumentId: "" };
  }

  toggleSchedule = () => {
    this.setState({ ...initialState });
  };

  cancelAddSchedule = () => {
    const tradingScheduleList = this.props.values.tradingScheduleList;
    tradingScheduleList.splice(this.state.selectedScheduleIndex, 1);
    this.props.setFieldValue("tradingScheduleList", tradingScheduleList);
    this.setState({ ...initialState });
  };

  saveSchedule = (schedule) => {
    const { days, state, from, periodicity, duration } = schedule;

    const fromTime = moment().startOf("day").add(from, "seconds");
    let stateObj = new State();
    stateObj.id = state ? state.id : 0;
    stateObj.name = state ? state.name : "";
    const updatedSchedule = {
      daysOfWeekList: days,
      state: stateObj,
      periodicity: periodicity,
      duration: { seconds: duration, nanos: 0 },
      timeOfDay: {
        hours: fromTime.hours(),
        minutes: fromTime.minutes(),
        seconds: fromTime.seconds(),
      },
    };

    const tradingScheduleList = this.props.values.tradingScheduleList;
    tradingScheduleList[this.state.selectedScheduleIndex] = updatedSchedule;

    this.props.setFieldValue("tradingScheduleList", tradingScheduleList);
    this.toggleSchedule();
  };

  enableStartDate = (isPartialEditable, isEditing, startDate) => {
    if (isEditing) {
      if (startDate && isPartialEditable) {
        return startDate && startDate > new Date();
      }
      return false;
    }
    return true;
  };

  updateState = (prop) => {
    this.setState(prop);
  };

  onEventIdChange = (eventId) => {
    const instruments = this.props.instruments;
    const instrument = instruments.find(inst => inst.type === EVENT && inst.evtAttEventId === eventId);
    if (!!instrument) {
      this.props.setFieldValue("evtAttEventDisplayName", instrument.evtAttEventDisplayName);
      this.props.setFieldValue("evtAttTimeSpecifier", instrument.evtAttTimeSpecifier);
      this.props.setFieldValue("lastTradeDate", instrument.lastTradeDate);
      this.props.setFieldValue("expirationDate", instrument.expirationDate);
    }
  };

  onProductChange = (product) => {
    this.props.onProductListChange(product);
    const productId = product.id;

    //TODO: Need to optimize this call.
    this.props.fetchInstruments("", 100, { "productName": { "id": "productName", "value": productId, } }, (instruments) => {
      const instrument = instruments.filter(inst => inst.type === EVENT);
      if (!!instrument) {
        const eventIds = instrument.map(inst => inst.evtAttEventId)
        this.updateState({ "eventIds": eventIds });
      }
    });
  }

  getTitle = (mode) => {
    let title;
    switch (mode) {
      case FormModes.edition:
        title = "Edit Instrument";
        break;
      case FormModes.creation:
        title = "Create Instrument";
        break;
      default:
        title = "View Instrument";
        break;
    }

    return title;
  };

  metadataOnChange = (values, metadata) => {
    values.metadata = metadata;
  }

  render() {
    const {
      title,
      isEditing,
      values,
      touched,
      errors,
      handleSubmit,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldError,
      metadata,
      holidays,
    } = this.props;
    const instrumentType = this.state.type ? this.state.type : values.type;
    const pageTitle = title || this.getTitle(this.mode);
    const isViewing = this.mode === FormModes.view;
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit} id="instrument-form">
          <h1>{pageTitle}</h1>
          <Container>
            <Header
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              handleBlur={handleBlur}
              metadata={metadata}
              handleChange={handleChange}
              isEditing={isEditing}
              onProductListChange={this.onProductChange}
              onEventIdChange={this.onEventIdChange}
              instrumentType={instrumentType}
              state={this.state}
              updateState={this.updateState}
              errors={errors}
              mode={this.mode}
            />
            <React.Fragment>
              {instrumentType && instrumentType === INTEREST_RATE_SWAP ? (
                <DateCalculator
                  values={values}
                  isEditing={isEditing}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  enableStartDate={this.enableStartDate}
                  errors={errors}
                  mode={this.mode}
                />
              ) : null}

              {instrumentType &&
                instrumentType === FORWARD_RATE_AGREEMENT_SWAP ? (
                <DateCalculatorFra
                  values={values}
                  isEditing={isEditing}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  enableStartDate={this.enableStartDate}
                  errors={errors}
                  mode={this.mode}
                ></DateCalculatorFra>
              ) : null}

              {instrumentType && instrumentType === BASIS_SWAP ? (
                <DateCalculatorBasis
                  values={values}
                  isEditing={isEditing}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  enableStartDate={this.enableStartDate}
                  errors={errors}
                  mode={this.mode}
                ></DateCalculatorBasis>
              ) : null}

              {instrumentType &&
                instrumentType === ZERO_COUPON_INFLATION_SWAP ? (
                <DateCalculatorZCIS
                  values={values}
                  isEditing={isEditing}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  enableStartDate={this.enableStartDate}
                  errors={errors}
                  mode={this.mode}
                ></DateCalculatorZCIS>
              ) : null}

              {instrumentType &&
                instrumentType === FORWARD_STARTING_INTEREST_RATE_SWAP ? (
                <DateCalculatorFsIrs
                  values={values}
                  isEditing={isEditing}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  enableStartDate={this.enableStartDate}
                  errors={errors}
                  mode={this.mode}
                ></DateCalculatorFsIrs>
              ) : null}

              {instrumentType && instrumentType === OVER_NIGHT_INDEX_SWAP && (
                <DateCalculatorOis
                  values={values}
                  isEditing={isEditing}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  enableStartDate={this.enableStartDate}
                  errors={errors}
                  mode={this.mode}
                ></DateCalculatorOis>
              )}

              {instrumentType && instrumentType === SINGLE_PERIOD_SWAP && (
                <DateCalculatorSps
                  values={values}
                  isEditing={isEditing}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  enableStartDate={this.enableStartDate}
                  errors={errors}
                  mode={this.mode}
                ></DateCalculatorSps>
              )}

              <TradingInfo
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                handleBlur={handleBlur}
                metadata={metadata}
                holidays={holidays}
                handleChange={handleChange}
                updateState={this.updateState}
                errors={errors}
                mode={this.mode}
              />

              <CrossOrderInfo
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                handleBlur={handleBlur}
                metadata={metadata}
                holidays={holidays}
                handleChange={handleChange}
                updateState={this.updateState}
                errors={errors}
                mode={this.mode}
              />
            </React.Fragment>

            {instrumentType === FUTURE || instrumentType === OPTION ? (
              <React.Fragment>
                <DerivativeInfo
                  values={values}
                  instrumentType={instrumentType}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />
              </React.Fragment>
            ) : null}

            {instrumentType === FOREX ? (
                <React.Fragment>
                  <ForexInfo
                      values={values}
                      instrumentType={instrumentType}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      handleBlur={handleBlur}
                      errors={errors}
                      metadata={metadata}
                      mode={this.mode}
                  />
                </React.Fragment>
            ) : null}

            {instrumentType === MULTILEG ? (
              <React.Fragment>
                <MultilegInfo
                  values={values}
                  instrumentType={instrumentType}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                />
              </React.Fragment>
            ) : null}

            {instrumentType === INTEREST_RATE_SWAP ? (
              <React.Fragment>
                <SwapInfo
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />

                <FixedLegInfo
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />

                <FloatingLegInfo
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  mode={this.mode}
                />
              </React.Fragment>
            ) : null}

            {instrumentType === FORWARD_RATE_AGREEMENT_SWAP ? (
              <React.Fragment>
                <SwapInfoFra
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />

                <FixedLegInfoFra
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />

                <FloatingLegInfoFra
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  mode={this.mode}
                />
              </React.Fragment>
            ) : null}

            {instrumentType === BASIS_SWAP ? (
              <React.Fragment>
                <SwapInfoBasis
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />

                <FloatingLegInfoBasis
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  mode={this.mode}
                />
              </React.Fragment>
            ) : null}

            {instrumentType === ZERO_COUPON_INFLATION_SWAP ? (
              <React.Fragment>
                <SwapInfoZCIS
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />

                <FixedLegInfoZCIS
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />

                <FloatingLegInfoZCIS
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  mode={this.mode}
                />
              </React.Fragment>
            ) : null}

            {instrumentType === FORWARD_STARTING_INTEREST_RATE_SWAP ? (
              <React.Fragment>
                <SwapInfoFsIrs
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />

                <FixedLegInfoFsIrs
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />

                <FloatingLegInfoFsIrs
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  mode={this.mode}
                />
              </React.Fragment>
            ) : null}

            {instrumentType === NON_DELIVERABLE_FORWARDS && (
              <NDFInfo
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                handleBlur={handleBlur}
                errors={errors}
                mode={this.mode}
                onChange={handleChange}
                metadata={metadata}
              ></NDFInfo>
            )}

            {instrumentType === EVENT && (
              <EventInfo
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                handleBlur={handleBlur}
                errors={errors}
                mode={this.mode}
                onChange={handleChange}
              ></EventInfo>
            )}

            {instrumentType === OVER_NIGHT_INDEX_SWAP && (
              <React.Fragment>
                <OisInfoBasis
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                  handleChange={handleChange}
                />
                <FixedLegInfoOis
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />
                <FloatingLegInfoOis
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                  handleChange={handleChange}
                />
              </React.Fragment>
            )}

            {instrumentType === SINGLE_PERIOD_SWAP && (
              <React.Fragment>
                <SwapInfoSps
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                  handleChange={handleChange}
                />

                <FixedLegInfoSps
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  mode={this.mode}
                />

                <FloatingLegInfoSps
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  mode={this.mode}
                />
              </React.Fragment>
            )}

            <SettlementPriceCalculationInfo
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              isEditing={isEditing}
              errors={errors}
              mode={this.mode}
            />


            <AdditionalDetails
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              instrumentType={instrumentType}
              isEditing={isEditing}
              enableStartDate={this.enableStartDate}
              errors={errors}
              mode={this.mode}
            />

            <TradingSchedule
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              instrumentType={instrumentType}
              isEditing={isEditing}
              enableStartDate={this.enableStartDate}
              errors={errors}
              mode={this.mode}
            />

            <Metadata onChange={(metadata) => this.metadataOnChange(values, metadata)} metadata={values.metadata} readOnly={this.mode === FormModes.view} />

            <Row>
              <Col lg={4} xs={4} md={4}>
                <FieldGroupReadOnly
                  id="state"
                  label="State"
                  value={values.state && values.state.name.toUpperCase()}
                />
              </Col>
            </Row>
            <Row>
              {!isViewing && (
                <Col lg={1} xs={1} md={1}>
                  {values.isPartialEditable ? (
                    <ButtonMain type="submit" text="SAVE" />
                  ) : null}
                </Col>
              )}
              <Col lg={2} xs={2} md={2}>
                <ButtonBorderless
                  type="button"
                  text="Cancel"
                  icon="times-circle"
                  customClassName="grey-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    const history = this.context.router.history;
                    if (history.length > 1) {
                      history.goBack();
                    } else {
                      history.push("/instruments");
                    }
                  }}
                />
              </Col>
              <Col lg={8} xs={8} md={8}>
                {Object.keys(errors).length > 0 && <ErrorLabel text="At least one required field is missing. Please review." />}
              </Col>
            </Row>
          </Container>
        </form>
        <TradingScheduleDialog
          title="Instrument Trading Hours"
          show={this.state.showTradingShedule}
          onCancel={
            this.state.isNew ? this.cancelAddSchedule : this.toggleSchedule
          }
          onSave={this.saveSchedule}
          schedule={this.state.selectedSchedule}
          scheduleIndex={this.state.selectedScheduleIndex}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isEditing: !state.instruments.isCreatingInstrument,
    instrument: state.instruments.instrument,
    instruments: state.instruments.instruments,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchInstruments: (pageToken, pageSize, filterItems, cb) => {
    dispatch(fetchInstruments2(pageToken, pageSize, filterItems, cb));
  },
});

const InstrumentForm = withFormik({
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: (props) => {
    let values = { ...props.instrument };
    
    if (window.location.href.endsWith("/copy")) { 
      values.id = ""
      values.description = ""
      values.isEditable = true
    }

    if (window.location.href.endsWith("/edit")) {
      values.isDisabledOnEdit = true
    } 

    if (isNullOrUndefined(values.minimumPriceIncrement)) {
      values.minimumPriceIncrement = 0;
    }
    if (isNullOrUndefined(values.minimumUnaffiliatedFirms)) {
      values.minimumUnaffiliatedFirms = 0;
    }

    var priceScale = 1;
    var qtyScale = 1;
    if (!isNullOrUndefined(values.minimumPriceIncrement)) {
      values.minimumPriceIncrement = convertExponentialToDecimal(
        values.minimumPriceIncrement
      );
      const scale = Math.pow(
        10,
        countDecimals(values.minimumPriceIncrement)
      );
      if (scale !== 0) priceScale = scale;
    }
    if (!isNullOrUndefined(values.minimumTradeQuantity)) {
      values.minimumTradeQuantity = convertExponentialToDecimal(
          values.minimumTradeQuantity
      );
      const scale = Math.pow(
          10,
          countDecimals(values.minimumTradeQuantity)
      );
      if (scale !== 0) qtyScale = scale;
    }

    if (!isNullOrUndefined(values.highPriceLimit)) {
      let highLimit = convertExponentialToDecimal(values.highPriceLimit);
      values.highPriceLimit = parsePrice(highLimit, priceScale);
    } else {
      values.setHighPriceLimit = false;
    }

    if (!isNullOrUndefined(values.lowPriceLimit)) {
      let lowLimit = convertExponentialToDecimal(values.lowPriceLimit);
      values.lowPriceLimit = parsePrice(lowLimit, priceScale);
    } else {
      values.setLowPriceLimit = false;
    }

    if (!isNumber(values.relativeLow)) {
      values.relativeLow = null;
      values.relativeLowSet = false;
    }

    if (!isNumber(values.relativeHigh)) {
      values.relativeHigh = null;
      values.relativeHighSet = false;
    }

    if (!isNullOrUndefined(values.highOrderLimit)) {
      let highLimit = convertExponentialToDecimal(values.highOrderLimit);
      values.highOrderLimit = parsePrice(highLimit, qtyScale);
    } else {
      values.setHighOrderLimit = false;
    }

    if (!isNullOrUndefined(values.lowOrderLimit)) {
      let lowLimit = convertExponentialToDecimal(values.lowOrderLimit);
      values.lowOrderLimit = parsePrice(lowLimit, qtyScale);
    } else {
      values.setLowOrderLimit = false;
    }

    if (!isNullOrUndefined(values.highTotalNotionalLimit)) {
      let highLimit = convertExponentialToDecimal(values.highTotalNotionalLimit);
      values.highTotalNotionalLimit = parsePrice(highLimit, priceScale * qtyScale);
    } else {
      values.setHighTotalNotionalLimit = false;
    }

    if (!isNullOrUndefined(values.lowTotalNotionalLimit)) {
      let lowLimit = convertExponentialToDecimal(values.lowTotalNotionalLimit);
      values.lowTotalNotionalLimit = parsePrice(lowLimit, priceScale * qtyScale);
    } else {
      values.setLowTotalNotionalLimit = false;
    }

    const now = new Date();
    // now.getMonth() - is 0  based
    values.evtAttTimeSpecifier = !isNullOrUndefined(values.evtAttTimeSpecifier) ? values.evtAttTimeSpecifier : { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };

    if( values.type === FUTURE || values.type === OPTION) {
      values.maturityDate = !isNullOrUndefined(values.maturityDate) ? values.maturityDate : { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
    }

    if (!!values.crossOrderRestingDuration){
      values.crossOrderRestingDuration = ProtobufParser.fromDurationToMillis(values.crossOrderRestingDuration);
    }

    if(values.settlementPeriodStartOffsetFromCloseDuration) {
      values.settlementPeriodStartOffsetFromCloseDuration = ProtobufParser.fromDurationToMillis(values.settlementPeriodStartOffsetFromCloseDuration);
    }

    if(values.settlementPeriodEndOffsetFromCloseDuration) {
      values.settlementPeriodEndOffsetFromCloseDuration = ProtobufParser.fromDurationToMillis(values.settlementPeriodEndOffsetFromCloseDuration);
    }

    if(values.bufferFromEndOfSettlementPeriodDuration) {
      values.bufferFromEndOfSettlementPeriodDuration = ProtobufParser.fromDurationToMillis(values.bufferFromEndOfSettlementPeriodDuration);
    }

    values.settlementPriceCalculationRequiresApproval = !!values.settlementPriceCalculationRequiresApproval;


    values.tradeDayRollSchedule = values.tradeDayRollSchedule || {};

    values.errors = props.errors;
    return values;
  },

  validationSchema: Yup.object().shape({
    ...HeaderSchemaValidation(),
    ...TradingInfoSchemaValidation(),
    ...CrossOrderInfoSchemaValidation(),
    ...AdditionalDetailsSchemaValidation(),
    ...SubTypesSchemaValidation(),
    ...SettlementPriceCalculationValidation(),
  }),

  validate: (values) => {
    let errors = {};

    if (values.type === INTEREST_RATE_SWAP) {
      errors = ValidateFixedLegInfoIrs(values, errors);
      errors = ValidateFloatingLegIrs(values, errors);
      errors = ValidateAdditionalDetails(values, errors, true);
      errors = ValidateSwapInfoIrs(values, errors);
      errors = ValidateDateCalculatorIrs(values, errors);
    } else if (values.type === FORWARD_RATE_AGREEMENT_SWAP) {
      errors = ValidateFixedLegInfoFra(values, errors);
      errors = ValidateFloatingLegFra(values, errors);
      errors = ValidateAdditionalDetails(values, errors, true);
      errors = ValidateSwapInfoFra(values, errors);
      errors = ValidateDateCalculatorFra(values, errors);
    } else if (values.type === FUTURE || values.type === OPTION) {
      errors = ValidateDerivativeInfo(values, errors);
    } else if (values.type === MULTILEG) {
      errors = ValidateMultilegInfo(values, errors);
    } else if (values.type === FOREX) {
      errors = ValidateForexInfo(values, errors);
    } else if (values.type === BASIS_SWAP) {
      errors = ValidateFloatingLegBasis(values, errors);
      errors = ValidateAdditionalDetails(values, errors, true);
      errors = ValidateSwapInfoBasis(values, errors);
      errors = ValidateDateCalculatorBasis(values, errors);
    } else if (values.type === ZERO_COUPON_INFLATION_SWAP) {
      errors = ValidateFloatingLegZCIS(values, errors);
      errors = ValidateFixedLegInfoZCIS(values, errors);
      errors = ValidateAdditionalDetails(values, errors, true);
      errors = ValidateSwapInfoZCIS(values, errors);
      errors = ValidateDateCalculatorZCIS(values, errors);
    } else if (values.type === FORWARD_STARTING_INTEREST_RATE_SWAP) {
      errors = ValidateFixedLegInfoFsIrs(values, errors);
      errors = ValidateFloatingLegFsIrs(values, errors);
      errors = ValidateAdditionalDetails(values, errors, values.type);
      errors = ValidateSwapInfoFsIrs(values, errors);
      errors = ValidateDateCalculatorFsIrs(values, errors);
    } else if (values.type === EVENT) {
      errors = ValidateEventInfo(values, errors);
      errors = ValidateAdditionalDetails(values, errors, false);
    } else if (values.type === OVER_NIGHT_INDEX_SWAP) {
      errors = ValidateOisInfoBasis(values, errors);
      errors = ValidateFixedLegInfoOis(values, errors);
      errors = ValidateFloatingLegOis(values, errors);
      errors = ValidateAdditionalDetails(values, errors, true);
      errors = ValidateDateCalculatorOis(values, errors);
    } else if (values.type === SINGLE_PERIOD_SWAP) {
      errors = ValidateSwapInfoSps(values, errors);
      errors = ValidateFixedLegInfoSps(values, errors);
      errors = ValidateFloatingLegSps(values, errors);
      errors = ValidateAdditionalDetails(values, errors, true);
      errors = ValidateDateCalculatorSps(values, errors);
    } else if (values.type === NON_DELIVERABLE_FORWARDS) {
      errors = ValidateNDFInfo(values, errors);
      errors = ValidateAdditionalDetails(values, errors, false);
    } else {
      errors = ValidateAdditionalDetails(values, errors, false);
    }

    errors = ValidateTradingInfo(values, errors);
    errors = ValidateCrossOrderInfo(values, errors);
    errors = ValidateHeaders(values, errors);
    errors = ValidateTradingSchedule(values, errors, values.type);

    return errors;
  },

  handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
    props.onSubmitForm(values, setFieldError);
  },

  displayName: "instrument-form",
  enableReinitialize: true,
})(BasicInstrumentForm);

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentForm);
