import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import FieldGroupReadOnly from "../../../core/form/FieldGroupReadOnly";
import DropdownListGroup from "../../../core/form/DropdownListGroup";
import InstrumentStaticData from "../../../../modules/instrumentStaticData";
import { validateField, getOptionText, NUMBER_TYPE, STRING_TYPE, ARRAY_TYPE } from '../../instrumentFormUtils'
import FieldGroup from "../../../core/form/FieldGroup";
import DateTimeParser from "../../../../modules/dateTimeParser";
import { FormModes } from "../../../../constants/enumerations";

export const FloatingLegInfoFra = ({
  values,
  errors,
  setFieldValue,
  touched,
  handleBlur,
  handleChange,
  mode
}) => {

  const [open, setOpen] = useState(false);
  const isViewing = mode === FormModes.view

  return (
    <React.Fragment>
      <div onClick={() => setOpen(!open)}>
        <Row className="accordian-style">
          <Col lg={11} xs={11} md={11}>
            <h6><strong>Floating Leg Information</strong></h6>
          </Col>
          <Col>
            <i className={`fa fa-fw ${open ? 'fa-minus' : 'fa-plus'} custom-plus`} />
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        <div className="collapse-content">
          <Row>
            <Col lg={3} xs={3} md={3}>
              <DropdownListGroup id="floatingRateIndex" name="floatingRateIndex" label="Floating Rate Index" isRequired={true}
                value={values.floatingRateIndex}
                enabled={values.isEditable}
                errors={errors}
                onChange={(e) => setFieldValue("floatingRateIndex", e)}
                data={InstrumentStaticData.FloatingRateIndexList}
                plaintext={isViewing}
              ></DropdownListGroup>
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Float Fixing Date Holiday Calendar" value={values.floatFixingDateHolidayCalendarsList ? values.floatFixingDateHolidayCalendarsList.join(", ") : ""} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Fixing Date Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.floatFixingDateBusinessDayConvention)} />
            </Col>
            <Col lg={3} xs={3} md={3}>
            <FieldGroupReadOnly label="Float Fixing Date" value={values.floatFixingDate ? DateTimeParser.toDisplay(values.floatFixingDate) : null} />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Fixing Dates Day Type" value={getOptionText(InstrumentStaticData.FixingDatesDayType, values.floatFixingDatesDayType)} />
            </Col>
            <Col>
              <FieldGroupReadOnly label="Float Fixing Dates Offset" value={values.floatFixingDatesOffset} />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Day Count Convention" value={ getOptionText(InstrumentStaticData.DayCountConventionOptions, values.floatDayCountConvention) } />
            </Col>
            <Col lg={3} xs={3} md={3}>
              <FieldGroupReadOnly label="Business Day Convention" value={getOptionText(InstrumentStaticData.BusinessDayConventionOptions, values.floatBusinessDayConvention)} />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={3} md={3}>
              <FieldGroup id="floatingRateIndexTenor" name="floatingRateIndexTenor" type="textarea" label="Floating Rate Index Tenor" isRequired={true}
                disabled={!values.isEditable}
                setFieldValue={setFieldValue}
                value={values.floatingRateIndexTenor}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                plaintext={isViewing}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

export const ValidateFloatingLegFra = (values, errors) => {
  errors = validateField(values, errors, "floatingRateIndex", "Floating Rate Index", STRING_TYPE);
  errors = validateField(values, errors, "floatDayCountConvention", "Day Count Convention", NUMBER_TYPE);
  errors = validateField(values, errors, "floatingRateIndexTenor", "Floating Rate Index Tenor", STRING_TYPE);
  errors = validateField(values, errors, "floatFixingDateHolidayCalendarsList", "Float Fixing Date Holiday Calendar", ARRAY_TYPE);
  errors = validateField(values, errors, "floatBusinessDayConvention", "Float Business Day Convention", STRING_TYPE);
  errors = validateField(values, errors, "floatFixingDateBusinessDayConvention", "Float Fixing Date Business Day Convention", STRING_TYPE);
  errors = validateField(values, errors, "floatFixingDatesDayType", "Float Fixing Dates Day Type", STRING_TYPE);
  return validateField(values, errors, "floatFixingDatesOffset", "Float Fixing Dates Offset", NUMBER_TYPE);
}