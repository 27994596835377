import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  SaveFirmStart,
  SaveFirmSucceed,
  SaveFirmFailed,
  IsCreatingFirm,
  UpdateFirmItem,
  UpdateFirmFailed,
  UpdateFirmStart,
  UpdateFirmSucceed,
  DeleteFirmFailed,
  DeleteFirmStart,
  DeleteFirmSucceed,
  FetchFirmsStart,
  FetchFirmsSucceed,
  FetchFirmsFailed,
  FetchAssociatedFirmsStart,
  FetchAssociatedFirmsSucceed,
  FetchAffiliatedFirmsSucceed,
  FetchAssociatedRFQFirmsSucceed,
  FetchAssociatedAgentFirmsSucceed,
  FetchAssociatedClearingHousesSucceed,
  FetchAssociatedFirmsFailed,
  UpdateAssociatedFirmStart,
  UpdateAssociatedFirmSucceed,
  UpdateAssociatedFirmFailed,
  LoadFirmAttributes,
  ResetFirms,
} from "../actions/firms";
import {
  FetchParticipantsStart,
  FetchParticipantsSucceed,
  FetchParticipantsFailed,
} from "../actions/participants";
import {
  FetchAccountsStart,
  FetchAccountsSucceed,
  FetchAccountsFailed,
  FetchPositionsStart,
  FetchPositionsSucceed,
  FetchPositionsFailed,
} from "../actions/accounts";
import Notification from "../modules/notifications";
import { StatusCode } from "grpc-web";
import FirmDetails from "../components/firm/FormDetails";
import { FormModes } from "../constants/enumerations";
import * as firmService from "../services/FirmService";
import confirm from "../modules/confirmDialog";
import Loader from "../components/core/loader/Loader";
import { RESET_LOADING_FLAG } from "../constants/firmTypes";
import Firm from "../entities/Firm";
import ParticipantService from "../services/ParticipantService";
import Participant from "../entities/Participant";
import AccountService from "../services/AccountService";
import Account from "../entities/Account";
import FirmAttributesMapper from "../modules/mappers/firmAttributesMapper";
import {isNullOrUndefined} from "../modules/util";

const refreshFrequency = 5; //(seconds)

function mapStateToProps(state) {
  return {
    fetchingFirm: state.firms.fetchingFirm,
    isCreating: state.firms.isCreating,
    isUpdating: state.firms.isUpdating,
    accounts: state.accounts.accounts,
    browserHistory: state.app.browserHistory,
  };
}

const mapDispatchToProps = (dispatch) => ({
  isCreating: (flag) => {
    dispatch(IsCreatingFirm(flag));
  },
  saveFormStart: () => {
    dispatch(SaveFirmStart());
  },
  saveFormSucceed: () => {
    dispatch(SaveFirmSucceed());
  },
  saveFormFailed: () => {
    dispatch(SaveFirmFailed());
  },
  updateParticipantItem(key, value) {
    dispatch(UpdateFirmItem(key, value));
  },
  deleteFirmStarted: () => {
    dispatch(DeleteFirmStart());
  },
  deleteFirmSucceed: () => {
    dispatch(DeleteFirmSucceed());
  },
  deleteFirmFailed: () => {
    dispatch(DeleteFirmFailed());
  },
  updateFirmStarted: () => {
    dispatch(UpdateFirmStart());
  },
  updateFirmSucceed: () => {
    dispatch(UpdateFirmSucceed());
  },
  updateFirmFailed: () => {
    dispatch(UpdateFirmFailed());
  },
  resetLoadingFlag: () => {
    dispatch({ type: RESET_LOADING_FLAG });
  },
  fetchFirmsStart: () => {
    dispatch(FetchFirmsStart());
  },
  fetchFirmsSucceed: (firms) => {
    dispatch(FetchFirmsSucceed(firms));
  },
  fetchFirmsFailed: () => {
    dispatch(FetchFirmsFailed());
  },
  fetchPositionsStart: () => {
    dispatch(FetchPositionsStart());
  },
  fetchPositionsSucceed: (positions) => {
    dispatch(FetchPositionsSucceed(positions));
  },
  fetchPositionsFailed: (err) => {
    dispatch(FetchPositionsFailed(err));
  },
  fetchAssociatedFirmsStart: () => {
    dispatch(FetchAssociatedFirmsStart());
  },
  fetchAffiliatedFirmsSucceed: (affiliatedFirms) => {
    dispatch(FetchAffiliatedFirmsSucceed(affiliatedFirms));
  },
  fetchAssociatedRFQFirmsSucceed: (associatedFirms) => {
    dispatch(FetchAssociatedRFQFirmsSucceed(associatedFirms));
  },
  fetchAssociatedAgentFirmsSucceed: (associatedFirms) => {
    dispatch(FetchAssociatedAgentFirmsSucceed(associatedFirms));
  },
  fetchAssociatedFirmsSucceed: (associatedFirms) => {
    dispatch(FetchAssociatedFirmsSucceed(associatedFirms));
  },
  fetchAssociatedClearingHousesSucceed: (associatedClearingHouses) => {
    dispatch(FetchAssociatedClearingHousesSucceed(associatedClearingHouses));
  },
  fetchAssociatedFirmsFailed: () => {
    dispatch(FetchAssociatedFirmsFailed());
  },
  updateAssociatedFirmStart: (id, associatedFirms) => {
    dispatch(UpdateAssociatedFirmStart(id, associatedFirms));
  },
  updateAssociatedFirmSucceed: () => {
    dispatch(UpdateAssociatedFirmSucceed());
  },
  updateAssociatedFirmFailed: () => {
    dispatch(UpdateAssociatedFirmFailed());
  },
  fetchParticipantsStart: () => {
    dispatch(FetchParticipantsStart());
  },
  fetchParticipantsSucceed: (participanttList) => {
    dispatch(FetchParticipantsSucceed(participanttList));
  },
  fetchParticipantsFailed: () => {
    dispatch(FetchParticipantsFailed());
  },
  fetchAccountsStart: () => {
    dispatch(FetchAccountsStart());
  },
  fetchAccountsSucceed: (accounts) => {
    dispatch(FetchAccountsSucceed(accounts));
  },
  fetchAccountsFailed: () => {
    dispatch(FetchAccountsFailed());
  },
  loadFirmAttributes: (resourceName) => {
    dispatch(LoadFirmAttributes(resourceName));
  },
  ResetFirms: () => {
    dispatch(ResetFirms());
  },
});

export class FirmFormContainer extends Component {
  loadPositionsTimer = 0;
  autoRefreshPositions = false;
  firstLoad = true;

  static contextTypes = {
    router: PropTypes.object,
  };
  componentDidMount() {
    this.props.resetLoadingFlag();
    this.loadPositionsTimer = setInterval(
        () => this.loadAccountsPositions(this.props.accounts.map(acct => acct.name)),
        refreshFrequency * 1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.loadPositionsTimer);
  }

  getPreviousURL = () => {
    if (!!this.props.browserHistory && this.props.browserHistory.length > 0) {
      for (let hIdx = this.props.browserHistory.length - 1; hIdx >= 0; hIdx--) {
        let hUrl = this.props.browserHistory[hIdx];
        if (hUrl !== window.location.pathname) {
          return hUrl;
        }
      }
    }
    return null;
  }

  getNavigateToURL = () => {
    const defaulURL = "/firms";
    const previousURL = this.getPreviousURL();

    if (!!previousURL && previousURL.trim().length > 0) {
      return previousURL;
    }

    return defaulURL;
  }

  saveFirm = (data, setFieldError) => {
    this.props.saveFormStart();
    firmService
      .postPromise(data)
      .then((response) => {
        if (!response) return;
        let firm = new Firm(response.getFirm());
        let firmAttributes = FirmAttributesMapper.mapToFirmAttributes(data);
        return firmService.updateAttributes(firm, firmAttributes);
      })
      .then(() => {
        this.props.saveFormSucceed();
        this.props.ResetFirms();
        this.context.router.history.push(this.getNavigateToURL());
        Notification.success("New firm saved.");
      })
      .catch((err) => {
        this.props.saveFormFailed();
        this.processFormError(err, setFieldError);
      });
  };

  saveFirmServiceAPIKey = (data, setFieldError) => {
    const cb = (err, response) => {
      if (response) {
        this.context.router.history.goBack();
        Notification.success("API Key Created.");
      }
      if (err) {
        Notification.error(`Error when saving new API key: \n ${err.message}`);
      }
    };
    firmService.postServiceKey(data, cb);
  };

  updateFirmServiceAPIKey = (data, setFieldError) => {
    const cb = (err, response) => {
      if (response) {
        this.context.router.history.goBack();
        Notification.success("API Key Updated.");
      }
      if (err) {
        Notification.error(`Error when updating API key: \n ${err.message}`);
      }
    };
    firmService.updateServiceKey(data, cb);
  };

  updateFirm = async (data, firm, setFieldError) => {
    try {
      this.props.updateFirmStarted();

      if (firm && firm.state.id !== data.state.id)
        await firmService.suspend(data);

      let firmAttributes = FirmAttributesMapper.mapToFirmAttributes(data);
      await firmService.updateAttributes(firm, firmAttributes);

      if (!!firm) {
        await firmService.updateFirm(data);
      }

      this.props.saveFormSucceed();
      this.context.router.history.push("/firms");
      Notification.success("Firm updated.");
    } catch (err) {
      this.props.updateFirmFailed();
      this.processFormError(err, setFieldError);
    }
  };

  onDelete = (id) => {
    confirm("Are you sure you want to remove this Firm?", {
      title: "Firm Remove Confirmation",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(() => {
      const cb = (err, response) => {
        if (response) {
          this.props.deleteFirmSucceed();
          this.context.router.history.push("/firms");
          Notification.success("Firm deleted.");
        }

        if (err) {
          this.props.deleteFirmFailed();
          Notification.error(`Error when deleting firm: \n ${err.message}`);
        }
      };
      this.props.deleteFirmStarted();
      firmService.remove(id, cb);
    });
  };

  processFormError = (error, setFieldError) => {
    switch (error.code) {
      case StatusCode.ALREADY_EXISTS:
        setFieldError("id", error.message, false);
        Notification.error(`Failed to save this firm, ID has already been used.`);
        break;
      default:
        Notification.error(`Failed to save this firm. ${error.message}`);
        break;
    }
  };

  getFormMode = () => {
    if (!this.props.location) {
      return FormModes.view;
    }

    let { pathname } = this.props.location;
    let { id, firmServiceId, apiKeyId } = this.props.params;

    if (!id) {
      return FormModes.creation;
    }

    if (firmServiceId) {
      if (apiKeyId) {
        if (pathname.indexOf("/edit") > 0) return FormModes.serviceKeysEdit;
        else return FormModes.serviceKeysView;
      }

      if (pathname.indexOf("/edit") > 0) return FormModes.serviceEdit;
      else if (pathname.indexOf("/new") > 0)
        return FormModes.serviceKeysCreation;
      else if (pathname.indexOf("/keys") > 0) return FormModes.serviceKeys;
      else return FormModes.serviceView;
    } else {
      if (pathname.indexOf("/edit") > 0) return FormModes.edition;
      if (pathname.indexOf("/services/new") > 0)
        return FormModes.serviceCreation;
      if (pathname.indexOf("/users") > 0) return FormModes.users;
      if (pathname.indexOf("/accounts") > 0) return FormModes.accounts;
      if (pathname.indexOf("/association") > 0) return FormModes.association;
      if (pathname.indexOf("/services") > 0) return FormModes.services;
      return FormModes.view;
    }
  };

  loadFirms = (callback) => {
    this.props.fetchFirmsStart();

    const cb = (err, response) => {
      if (response) {
        var firmsList = response.getFirmsList().map((firm) => {
          return new Firm(firm);
        });
        this.props.fetchFirmsSucceed(firmsList);
        if (callback) callback();
      }

      if (err) {
        this.props.fetchFirmsFailed();
        Notification.error("Cannot get the list of available firms");
      }
    };

    firmService.getAll(cb);
  };

  loadAffiliatedFirms = (callback) => {
    let id = this.props.decodedId;
    this.props.fetchAssociatedFirmsStart();

    const cb = (err, response) => {
      if (response) {
        var affiliatedFirmsList = response.getAffiliatedFirmsList();

        this.props.fetchAffiliatedFirmsSucceed(affiliatedFirmsList);
        if (callback) callback();
      }

      if (err) {
        this.props.fetchAssociatedFirmsFailed();
        Notification.error("Cannot get the list of associated firms");
      }
    };

    firmService.getAffiliatedRequest(id, cb);
  };

  loadAssociatedRFQFirms = (callback) => {
    let id = this.props.decodedId;
    this.props.fetchAssociatedFirmsStart();

    const cb = (err, response) => {
      if (response) {
        var associatedFirmsList = response.getAssociatedFirmsList();

        this.props.fetchAssociatedRFQFirmsSucceed(associatedFirmsList);
        if (callback) callback();
      }

      if (err) {
        this.props.fetchAssociatedFirmsFailed();
        Notification.error("Cannot get the list of associated RFQ firms");
      }
    };

    firmService.getAssociatedRFQRequest(id, cb);
  };

  loadAssociatedAgentFirms = (callback) => {
    let id = this.props.decodedId;
    this.props.fetchAssociatedFirmsStart();

    const cb = (err, response) => {
      if (response) {
        var associatedFirmsList = response.getAssociatedFirmsList();

        this.props.fetchAssociatedAgentFirmsSucceed(associatedFirmsList);
        if (callback) callback();
      }

      if (err) {
        this.props.fetchAssociatedFirmsFailed();
        Notification.error("Cannot get the list of associated Agent firms");
      }
    };

    firmService.getAssociatedAgentRequest(id, cb);
  };

  loadAssociatedFirms = (callback) => {
    let id = this.props.decodedId;
    this.props.fetchAssociatedFirmsStart();

    const cb = (err, response) => {
      if (response) {
        var associatedFirmsList = response.getAssociatedFirmsList();

        this.props.fetchAssociatedFirmsSucceed(associatedFirmsList);
        if (callback) callback();
      }

      if (err) {
        this.props.fetchAssociatedFirmsFailed();
        Notification.error("Cannot get the list of associated firms");
      }
    };

    firmService.getClearingMemberRequest(id, cb);
  };

  loadAssociatedClearingHouses = (callback) => {
    let id = this.props.decodedId;
    this.props.fetchAssociatedFirmsStart();

    const cb = (err, response) => {
      if (response) {
        var associatedFirmsList = response.getAssociatedFirmsList();

        this.props.fetchAssociatedClearingHousesSucceed(associatedFirmsList);
        if (callback) callback();
      }

      if (err) {
        this.props.fetchAssociatedFirmsFailed();
        Notification.error("Cannot get the list of associated firms");
      }
    };

    firmService.getClearingHouseRequest(id, cb);
  };

  updateAssociatedFirms = (payload) => {
    let firmId = this.props.decodedId;
    this.props.updateAssociatedFirmStart();

    const cb = (err, response) => {
      if (response) {
        this.props.updateAssociatedFirmSucceed();
        Notification.success("Firm associations updated!");
      }

      if (err) {
        this.props.updateAssociatedFirmFailed();
        Notification.error(`Error updating associated firms - ${err && err.message ? err.message : ''}`);
      }
    };

    let associatedFirmsList = [];

    for (const [id] of Object.entries(payload.list)) {
      associatedFirmsList.push(id);
    }

    firmService.setAssociatedFirms(
      {
        firmId: firmId,
        associatedFirmsList: associatedFirmsList,
        firmType: payload.firmType,
        filterType: payload.filterType,
        dataType: payload.dataType,
      },
      cb
    );
  };

  loadParticipants = () => {
    this.props.fetchParticipantsStart();

    const cb = (err, response) => {
      if (response) {
        var participantsList = response
          .getParticipantsList()
          .map((participant) => {
            return new Participant(participant);
          });
        this.props.fetchParticipantsSucceed(participantsList);
      }

      if (err) {
        this.props.fetchParticipantsFailed();
        Notification.error("Cannot get list of participants.");
      }
    };

    ParticipantService.getAll(cb);
  };

  loadAccounts = () => {
    this.props.fetchAccountsStart();

    const cb = (err, response) => {
      if (response) {
        var accountsList = response.getAccountsList().map((account) => {
          return new Account(account);
        });
        this.loadAccountsPositions(accountsList.map((account) => account.name));
        this.props.fetchAccountsSucceed(accountsList);
      }

      if (err) {
        this.props.fetchAccountsFailed();
        Notification.error("Cannot get list of accounts.");
      }
    };

    AccountService.getAll(cb, "firms/" + this.props.decodedId);
  };

  loadAccountsPositions = (accounts, refresh = false) => {

    const cb = (err, response) => {
      if (response) {
        var positionsList = response.getPositionsList().map((position) => {
          return position.toObject();
        });
        this.props.fetchPositionsSucceed(positionsList);
      }

      if (err) {
        this.props.fetchPositionsFailed(err);
      }
    };
    if (this.autoRefreshPositions || this.firstLoad || refresh) {
      this.props.fetchPositionsStart();
      if(!isNullOrUndefined(accounts) && Array.isArray(accounts) && accounts.length > 0) {
        AccountService.listPositions(accounts, cb);
      } else {
        this.props.fetchPositionsSucceed([]);
      }
      this.firstLoad = false;
    }
  };

  enableAutoRefresh = (value) => {
    this.autoRefreshPositions = value;
    this.loadAccounts();
  }

  render() {
    let {
      isUpdating,
      decodedId,
      loadFirm,
      loadFirmServices,
      loadFirmServiceKeys,
      firmType,
      match,
    } = this.props;
    return (
      <div className="with-callback">
        <Loader show={isUpdating} />
        <FirmDetails
          onSubmitForm={this.saveFirm}
          onSubmitServicesKeyForm={this.saveFirmServiceAPIKey}
          onEditForm={this.updateFirm}
          onEditServicesKeyForm={this.updateFirmServiceAPIKey}
          mode={this.getFormMode()}
          decodedId={decodedId}
          onDelete={this.onDelete}
          loadFirm={loadFirm}
          loadFirms={this.loadFirms}
          loadFirmServices={loadFirmServices}
          loadFirmServiceKeys={loadFirmServiceKeys}
          loadAffiliatedFirms={this.loadAffiliatedFirms}
          loadAssociatedFirms={this.loadAssociatedFirms}
          loadAssociatedRFQFirms={this.loadAssociatedRFQFirms}
          loadAssociatedAgentFirms={this.loadAssociatedAgentFirms}
          loadAssociatedClearingHouses={this.loadAssociatedClearingHouses}
          updateAssociatedFirms={this.updateAssociatedFirms}
          loadParticipants={this.loadParticipants}
          loadAccounts={this.loadAccounts}
          firmType={firmType}
          match={match}
          loadFirmAttributes={this.props.loadFirmAttributes}
          enableAutoRefresh={this.enableAutoRefresh}
          reloadPositions={() => this.loadAccountsPositions(this.props.accounts.map(acct => acct.name), true)}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FirmFormContainer)
);
