import ProtobufParser from '../protobufParser';
import TradingHourMapper from './tradingHourMapper';
import { convertScaledPriceToInt, countDecimals, valueOrDefault } from "../util";
import { dateToProtoDate, dateToProtoTimeOfDay } from "../../actions/ptypes";
import {
  INTEREST_RATE_SWAP,
  FORWARD_STARTING_INTEREST_RATE_SWAP,
  FUTURE,
  OPTION,
  MULTILEG,
  BASIS_SWAP,
  FORWARD_RATE_AGREEMENT_SWAP,
  EVENT,
  OVER_NIGHT_INDEX_SWAP,
  SINGLE_PERIOD_SWAP,
  NON_DELIVERABLE_FORWARDS,
  FOREX,
  ZERO_COUPON_INFLATION_SWAP
} from "../../constants/strings";
import {
  createFutureAtts,
  createOptionAtts,
  createIRSAtts,
  createMultilegAtts,
  createForexAtts,
  createFSIRSAtts,
  createBasisAtts,
  createFRAAtts,
  createEventAtts,
  createOISAtts,
  createSpsAtts,
  createNDFAtts,
  createZeroCouponInflationSwapAtts
} from "../../modules/instrumentUtils"
import { isNullOrUndefined, isNumber } from '../util';
import TradeDayRollMapper from './tradeDayRollMapper.js';

const {
  Product,
  InstrumentAttributes,
  BlockTradeThreshold,
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb.js');

const {
  PriceLimit,
  OrderSizeLimit,
} = require('@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb');

export default class ProductMapper {
  static mapToProduct(data) {

    const priceScale = Math.pow(10, countDecimals(data.minimumPriceIncrement));

    var productToUpdate = new Product();

    if (data.type === INTEREST_RATE_SWAP) {
      let irsAtts = createIRSAtts(data);
      productToUpdate.setInterestRateSwapAttributes(irsAtts);
    } else if (data.type === FORWARD_STARTING_INTEREST_RATE_SWAP) {
      const fsIRSAtts = createFSIRSAtts(data);
      productToUpdate.setForwardStartingInterestRateSwapAttributes(fsIRSAtts);
    } else if (data.type === FUTURE) {
      let futAtts = createFutureAtts(data);
      productToUpdate.setFutureAttributes(futAtts);
    } else if (data.type === OPTION) {
      let optAtts = createOptionAtts(data);
      productToUpdate.setOptionAttributes(optAtts);
    } else if (data.type === MULTILEG) {
      let mlegAtts = createMultilegAtts(data);
      productToUpdate.setMultilegAttributes(mlegAtts);
    } else if (data.type === FOREX) {
      let forexAtts = createForexAtts(data);
      productToUpdate.setForexAttributes(forexAtts);
    } else if (data.type === BASIS_SWAP) {
      let basisAtts = createBasisAtts(data);
      productToUpdate.setBasisSwapAttributes(basisAtts);
    } else if (data.type === FORWARD_RATE_AGREEMENT_SWAP) {
      let frasAtts = createFRAAtts(data)
      productToUpdate.setForwardRateAgreementSwapAttributes(frasAtts)
    } else if (data.type === EVENT) {
      let eventAtts = createEventAtts(data, priceScale)
      productToUpdate.setEventAttributes(eventAtts);
    } else if (data.type === OVER_NIGHT_INDEX_SWAP) {
      let oisAtts = createOISAtts(data)
      productToUpdate.setOvernightIndexSwapAttributes(oisAtts);
    } else if (data.type === SINGLE_PERIOD_SWAP) {
      let spsAtts = createSpsAtts(data)
      productToUpdate.setSinglePeriodSwapAttributes(spsAtts);
    } else if (data.type === NON_DELIVERABLE_FORWARDS) {
      let ndfAtts = createNDFAtts(data)
      productToUpdate.setNonDeliverableForwardAttributes(ndfAtts);
    } else if (data.type === ZERO_COUPON_INFLATION_SWAP) {
      let basisAtts = createZeroCouponInflationSwapAtts(data);
      productToUpdate.setZeroCouponInflationSwapAttributes(basisAtts);
    }

    /* Tradable Instrument Attributes - Starts */
    var tradable = new InstrumentAttributes();
    if (data.minimumPriceIncrement !== null) {
      tradable.setTickSize(ProtobufParser.toDouble(data.minimumPriceIncrement));
    }

    var qtyScale = 1;
    if (data.minimumTradeQuantity !== null) {
      const scale = Math.pow(10, countDecimals(data.minimumTradeQuantity));
      if (scale !== 0) qtyScale = scale;
      tradable.setMinimumTradeQty(
        parseInt(convertScaledPriceToInt(data.minimumTradeQuantity, scale))
      );

      tradable.setFractionalQtyScale(scale)
    }

    if (data.minimumUnaffiliatedFirms !== null) {
      tradable.setMinimumUnaffiliatedFirms(data.minimumUnaffiliatedFirms);
    }

    if (data.crossOrderRestingDuration !== null) {
      const restingDuration = ProtobufParser.millisToDuration(data.crossOrderRestingDuration);
      tradable.setCrossOrderRestingDuration(restingDuration);
    }

    if (Object.keys(valueOrDefault(data.blockTradeThreshold, {})).length > 0) {

      let blockTradeThreshold = null;

      if (!!data.setBlockTradeThreshold) {

        blockTradeThreshold = new BlockTradeThreshold();

        if (!!data.blockTradeThreshold.minimumQuantity) {
          let blockTradeMinimumQuantityScaled = convertScaledPriceToInt(data.blockTradeThreshold.minimumQuantity, qtyScale)
          blockTradeThreshold.setMinimumQuantity(blockTradeMinimumQuantityScaled);
        }

        if (!!data.blockTradeThreshold.minimumNotional) {
          let notionalScale = qtyScale * priceScale;
          let blockTradeMinimumNotionalScaled = convertScaledPriceToInt(data.blockTradeThreshold.minimumNotional, notionalScale)
          blockTradeThreshold.setMinimumNotional(blockTradeMinimumNotionalScaled);
        }

      }

      tradable.setBlockTradeThreshold(blockTradeThreshold);
    }

    if (data.basecurrency !== null) {
      tradable.setBaseCurrency(data.basecurrency);
    }

    if (data.multiplier !== null) {
      tradable.setMultiplier(data.multiplier);
    }

    if (data.clearingHouse !== null) {
      tradable.setClearingHouse(data.clearingHouse);
    }

    if (data.tradeSettlementPeriod !== null) {
      tradable.setTradeSettlementPeriod(data.tradeSettlementPeriod);
    }

    if (Array.isArray(data.holidayCalendars) && data.holidayCalendars.length > 0) {
      tradable.setHolidayCalendarsList(data.holidayCalendars);
    }

    if (!!data.setLowPriceLimit || !!data.setHighPriceLimit || !!data.relativeLowSet || !!data.relativeHighSet) {
      var priceLimit = new PriceLimit();

      if (!!data.setLowPriceLimit && isNumber(data.lowPriceLimit)) {
        priceLimit.setLow(convertScaledPriceToInt(data.lowPriceLimit, priceScale));
        priceLimit.setLowSet(data.setLowPriceLimit);
      }

      if (!!data.setHighPriceLimit && isNumber(data.highPriceLimit)) {
        priceLimit.setHigh(convertScaledPriceToInt(data.highPriceLimit, priceScale));
        priceLimit.setHighSet(data.setHighPriceLimit);
      }

      if (!!data.relativeLowSet && isNumber(data.relativeLow)) {
        priceLimit.setRelativeLow(data.relativeLow / 100);
        priceLimit.setRelativeLowSet(data.relativeLowSet);
      }

      if (data.relativeHighSet && isNumber(data.relativeHigh)) {
        priceLimit.setRelativeHigh(data.relativeHigh / 100);
        priceLimit.setRelativeHighSet(data.relativeHighSet);
      }

      tradable.setPriceLimit(priceLimit);
    }

    if (isNumber(data.lowOrderLimit) || isNumber(data.highOrderLimit) || isNumber(data.highTotalNotionalLimit) || isNumber(data.lowTotalNotionalLimit)) {
      var orderLimit = new OrderSizeLimit();
      if (!!data.setLowOrderLimit && isNumber(data.lowOrderLimit)) {
        orderLimit.setLow(convertScaledPriceToInt(data.lowOrderLimit, qtyScale));
        orderLimit.setLowSet(data.setLowOrderLimit);
      }

      if (!!data.setHighOrderLimit && isNumber(data.highOrderLimit)) {
        orderLimit.setHigh(convertScaledPriceToInt(data.highOrderLimit, qtyScale));
        orderLimit.setHighSet(data.setHighOrderLimit);
      }

      if (!!data.setLowTotalNotionalLimit && isNumber(data.lowTotalNotionalLimit)) {
        orderLimit.setTotalNotionalLow(convertScaledPriceToInt(data.lowTotalNotionalLimit, priceScale * qtyScale));
        orderLimit.setTotalNotionalLowSet(data.setLowTotalNotionalLimit)
      }

      if (!!data.setHighTotalNotionalLimit && isNumber(data.highTotalNotionalLimit)) {
        orderLimit.setTotalNotionalHigh(convertScaledPriceToInt(data.highTotalNotionalLimit, priceScale * qtyScale));
        orderLimit.setTotalNotionalHighSet(data.setHighTotalNotionalLimit)
      }

      tradable.setOrderSizeLimit(orderLimit);
    }

    tradable.setSkipRiskAndCurrencyChecks(data.skipRiskAndCurrencyChecks);

    if (!isNullOrUndefined(data.startDate)) {
      var startDate = dateToProtoDate(data.startDate);
      tradable.setStartDate(startDate);
    }

    if (!isNullOrUndefined(data.expirationDate)) {
      const expirationDate = dateToProtoDate(data.expirationDate);
      tradable.setExpirationDate(expirationDate);

      const expirationTime = dateToProtoTimeOfDay(data.expirationDate);
      tradable.setExpirationTime(expirationTime);
    }

    if (!isNullOrUndefined(data.terminationDate)) {
      const terminationDate = dateToProtoDate(data.terminationDate);
      tradable.setTerminationDate(terminationDate);
    }

    var tradingHours = [];
    if (data.tradingScheduleList) {
      for (var i = 0; data.tradingScheduleList.length > i; i++) {
        tradingHours.push(TradingHourMapper.mapToTradingHour(data.tradingScheduleList[i]));
      }
    }

    tradable.setTradingScheduleList(tradingHours);

    if (data.tradeDayRollSchedule) {
      let mappedData = TradeDayRollMapper.mapTradeDayRollSchedule(data.tradeDayRollSchedule);
      tradable.setTradeDayRollScheduleList(mappedData);
    }

    if (!isNullOrUndefined(data.categoryLevels)) {
      const categoryLevels = Array.from(data.categoryLevels)
      tradable.setCategoryLevelsList(categoryLevels)
    }

    if (!isNullOrUndefined(data.cfiCode)) {
      tradable.setCfiCode(data.cfiCode);
    }

    if (!isNullOrUndefined(data.instrumentProductType)) {
      tradable.setInstrumentProductType(data.instrumentProductType);
    }

    if(!isNullOrUndefined(data.settlementPeriodStartOffsetFromCloseDuration)) {
      tradable.setSettlementPeriodStartOffsetFromCloseDuration(ProtobufParser.millisToDuration(data.settlementPeriodStartOffsetFromCloseDuration))
    }

    if(!isNullOrUndefined(data.settlementPeriodEndOffsetFromCloseDuration)) {
      tradable.setSettlementPeriodEndOffsetFromCloseDuration(ProtobufParser.millisToDuration(data.settlementPeriodEndOffsetFromCloseDuration))
    }

    if(!isNullOrUndefined(data.minimumTradeQtyForVwapSettlementCalculation)) {
      tradable.setMinimumTradeQtyForVwapSettlementCalculation(data.minimumTradeQtyForVwapSettlementCalculation)
    }

    if(!isNullOrUndefined(data.bufferFromEndOfSettlementPeriodDuration)) {
      tradable.setBufferFromEndOfSettlementPeriodDuration(ProtobufParser.millisToDuration(data.bufferFromEndOfSettlementPeriodDuration))
    }

    tradable.setSettlementPriceLogic(data.settlementPriceLogic);

    tradable.setSettlementPriceCalculationRequiresApproval(data.settlementPriceCalculationRequiresApproval);

    tradable.setSettlementPriceCalculationEndingMethod(data.settlementPriceCalculationEndingMethod);

    productToUpdate.setAttributes(tradable);
    /* Tradable Instrument Attributes - Ends */

    productToUpdate.setId(data.name);
    productToUpdate.setDescription(data.description);
    productToUpdate.setExternalSymbol(data.externalSymbol);

    return productToUpdate;
  }
}
