import {
  FETCH_PARTICIPANTS_START,
  FETCH_PARTICIPANTS_SUCCEED,
  FETCH_PARTICIPANTS_FAILED,
  FETCH_PARTICIPANT_START,
  FETCH_PARTICIPANT_SUCCEED,
  FETCH_PARTICIPANT_FAILED,
  CLEAN_PARTICIPANT,
  SAVE_PARTICIPANT_START,
  SAVE_PARTICIPANT_SUCCEED,
  SAVE_PARTICIPANT_FAILED,
  CREATE_PARTICIPANT_STATE,
  UPDATE_PARTICIPANT_ITEM,
  TOGGLE_SHOW_DELETED_PARTICIPANTS,
} from "../constants/participantTypes";
import { RESET } from "../constants/appTypes";
import ParticipantFormItem from "../entities/dto/ParticipantFormItem";

const initialState = {
  fetchingParticipants: false,
  participants: [],
  fetchingParticipant: false,
  participant: new ParticipantFormItem(),
  savingParticipant: false,
  isCreating: false,
  showDeletedParticipants: false,
};

const participants = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTICIPANTS_START:
      return {
        ...state,
        fetchingParticipants: true,
      };
    case FETCH_PARTICIPANTS_SUCCEED:
      return {
        ...state,
        fetchingParticipants: false,
        participants: action.payload,
      };
    case FETCH_PARTICIPANTS_FAILED:
      return {
        ...state,
        fetchingParticipants: false,
        participants: [],
      };
    case RESET:
      state = Object.assign({}, state, initialState);
      return state;
    case FETCH_PARTICIPANT_START:
      return {
        ...state,
        fetchingParticipant: true,
      };
    case FETCH_PARTICIPANT_SUCCEED:
      return {
        ...state,
        fetchingParticipant: false,
        participant: action.payload,
      };
    case FETCH_PARTICIPANT_FAILED:
      return {
        ...state,
        fetchingParticipant: false,
      };
    case CLEAN_PARTICIPANT:
      return {
        ...state,
        participant: new ParticipantFormItem(),
      };
    case SAVE_PARTICIPANT_START:
      return {
        ...state,
        savingParticipant: true,
      };
    case SAVE_PARTICIPANT_SUCCEED:
      return {
        ...state,
        savingParticipant: false,
      };
    case SAVE_PARTICIPANT_FAILED:
      return {
        ...state,
        savingParticipant: false,
      };
    case CREATE_PARTICIPANT_STATE:
      return {
        ...state,
        isCreating: action.value,
      };
    case UPDATE_PARTICIPANT_ITEM:
      return {
        ...state,
        participant: new ParticipantFormItem({
          ...state.participant,
          [action.key]: action.value,
        }),
      };
    case TOGGLE_SHOW_DELETED_PARTICIPANTS:
      return {
        ...state,
        showDeletedParticipants: !state.showDeletedParticipants,
      };
    default:
      return state;
  }
};

export default participants;
